let types = {
  SET_OFFLINE_CALL_STATUS: "SET_OFFLINE_CALL_STATUS",
  SET_DOCUMENT_VIEWED_FLAG: "SET_DOCUMENT_VIEWED_FLAG",
};

export const setOfflineCallStatus = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_OFFLINE_CALL_STATUS, payload: data });
  };
};

export const setDocumentViewedFlag = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_DOCUMENT_VIEWED_FLAG, payload: data });
  };
};
import {uniqBy} from "lodash";
import React from "react";
import store from "../../app/store";
import {searchSnomedCode} from "../../services/snomedCodes";
import {fetchLinksFromKnowledgeURL} from "../../services/symptoms";
import {errorToast, getErrorObject} from "../../utilities/utils";

export default function SysmtopmFetchedLinks({
  symptompFetchedLinks,
  patientId,
  onSetDynamicInput,
  dynamicInputs,
}) {
  //   const [activeID, setActiveID] = useState(null);

  const handleWindowURL = async (record, index) => {
    if (record) {
      store.dispatch({
        type: "SET_SYMPTOMP_FETCHED_LINKS",
        payload: null,
      });
      store.dispatch({
        type: "SET_SYMPTOMP_FETCHING",
        payload: true,
      });
      try {
        let response = await fetchLinksFromKnowledgeURL({
          url: record.knowledge_window_api_url,
          patient_id: patientId,
        });
        console.log("response", record);
        // Object.keys()

        store.dispatch({
          type: "SET_SYMPTOMP_FETCHED_LINKS",
          payload: {
            ...symptompFetchedLinks,
            subDiagnosis: {
              data: response?.data?.data?.data,
              diagnosis: {diagnosis_name: record?.name},
            },
            activeID: index,
          },
        });
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHING",
          payload: false,
        });
        console.log(
          "record?.snomed_diagnosis_id",
          record?.knowledge_window_api_url
        );
        if (record?.snomed_diagnosis_id) {
          let snomedResponse = await searchSnomedCode({
            is_diagnosis: true,
            search: record?.snomed_diagnosis_id,
          });
          let searchedSnomedCodes = snomedResponse?.data?.data;
          let foundSnomedCode = searchedSnomedCodes?.find(
            (d) => d.code === record?.snomed_diagnosis_id
          );

          let payload = {
            ...dynamicInputs,
            selectedSnomedCodesArray: uniqBy(
              [...dynamicInputs?.selectedSnomedCodesArray, foundSnomedCode],
              "code"
            ).filter((item) => {
              return (
                dynamicInputs?.symptompSnomedCode &&
                !dynamicInputs?.symptompSnomedCode.some(
                  (item2) => +item2.id === +item.id
                )
              );
            }),
            selectedCodesArray: uniqBy(
              [...dynamicInputs?.selectedCodesArray, foundSnomedCode],
              "code"
            ).filter((item) => {
              return (
                dynamicInputs?.symptompSnomedCode &&
                !dynamicInputs?.symptompSnomedCode.some(
                  (item2) => +item2.id === +item.id
                )
              );
            }),
            symptompSnomedCode: [foundSnomedCode],
          };

          console.log("onSetDynamicInput payload", payload);
          store.dispatch({
            type: "SET_DYNAMIC_INPUT",
            payload: payload,
          });
          let elem = document.getElementById("diagnosis-fetchedLinks");
          if (elem) {
            elem.scrollIntoView({behavior: "smooth"});
          }
        }
        window.open(record.knowledge_window_api_url, "_blank");
      } catch (e) {
        const {message} = getErrorObject(e);
        errorToast({
          content: message,
        });
        store.dispatch({
          type: "SET_SYMPTOMP_FETCHING",
          payload: false,
        });
      }
    } else {
      store.dispatch({
        type: "SET_SYMPTOMP_FETCHED_LINKS",
        payload: null,
      });
    }
  };
  console.log("symptompFetchedLinks", symptompFetchedLinks);
  return (
    <div id={"diagnosis-fetchedLinks"}>
      <hr />

      <div className="header-links">
        <span className="header-links-content">Professional Resources</span>
      </div>
      <div style={{padding: 6}}>
        <div className="diagnosis-links-main-container">
          <div className="dignosis-name">
            <span className="">
              {symptompFetchedLinks?.diagnosis?.diagnosis_name}
            </span>
          </div>
          {symptompFetchedLinks ? (
            <ul className="sub-diagnosis">
              {symptompFetchedLinks?.diagnosis?.sub_diagnoses?.responsible_sub_diagnosis?.map(
                (d, i) => {
                  return (
                    <li
                      onClick={async () => {
                        if (d) {
                          //   await setActiveID(i);
                          await handleWindowURL(d, i);
                        }
                      }}
                      key={i}
                      className={`sub-diagnosis-content ${
                        symptompFetchedLinks.activeID === i && "active"
                      }`}
                    >
                      {/* <a target="__blank" href={d.knowledge_window_api_url}> */}
                      {d.name}
                      {/* </a> */}
                    </li>
                  );
                }
              )}
            </ul>
          ) : (
            <></>
          )}
        </div>
        <hr />
        {symptompFetchedLinks?.subDiagnosis
          ? Object.keys(symptompFetchedLinks?.subDiagnosis?.data)?.map(
              (key, i) => {
                return (
                  <div className="links-main-container">
                    <div className="header-sub-links">
                      <span className="header-sub-links-content">{key}</span>
                    </div>
                    <div
                      className="links-container"
                      style={{display: "flex", flexDirection: "column"}}
                    >
                      {symptompFetchedLinks?.subDiagnosis?.data[key]?.map(
                        (k) => {
                          return (
                            <div
                              // className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize"
                              className="s-header-link-item"
                            >
                              <a target="__blank" href={k.link}>
                                {k.name}
                              </a>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </div>
                );
              }
            )
          : Object.keys(symptompFetchedLinks?.data)?.map((key, i) => {
              return (
                <div className="links-main-container">
                  <div className="header-sub-links">
                    <span className="header-sub-links-content">{key}</span>
                  </div>
                  <div
                    className="links-container"
                    style={{display: "flex", flexDirection: "column"}}
                  >
                    {symptompFetchedLinks?.data[key]?.map((k) => {
                      return (
                        <div
                          // className="font-12 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize"
                          className="s-header-link-item"
                        >
                          <a target="__blank" href={k.link}>
                            {k.name}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}
      </div>

      <hr id={"diagnosis-fetchedLinks"} />
    </div>
  );
}

import { deletee, get, patch, post } from "services";

export const fetchHealthConditions = (params) => {
  const request = {
    subUrl: `/customer_support/health_conditions.json`,
    params,
  };
  return get(request);
};

export const fetchOutComes = (params) => {
  const request = {
    subUrl: `/pre_consults/list_outcomes`,
    params,
  };
  return get(request);
};

export const sendPreConsultNotification = (data) => {
  const request = {
    subUrl: `/pre_consultation_logs.json`,
    data,
  };
  return post(request);
};

export const fetchPreConsultations = (params) => {
  const request = {
    subUrl: `/pre_consults`,
    params,
  };
  return get(request);
};
export const fetchPreConsultationSubmitLogs = (params) => {
  const request = {
    subUrl: `/pre_consult_submit_logs`,
    params,
  };
  return get(request);
};

export const fetchSubmittedPreConsultations = (params) => {
  const request = {
    subUrl: `/pre_consults/list_submitted_questionnaires`,
    params,
  };
  return get(request);
};

export const fetchPreConsultQuestionnaire = (params) => {
  const request = {
    subUrl: `/pre_consultation_logs/fetch_questionnaires`,
    params,
  };
  return get(request);
};

export const fetchHyperTensiveQuestionnaire = (params) => {
  const request = {
    subUrl: `/health_scan_logs/fetch_questionnaires`,
    params,
  };
  return get(request);
};

export const submitPreConsultQuestionnaire = (data) => {
  const request = {
    subUrl: `/patients/questionnaire_templates/submit_questionnaire.json`,
    data,
  };
  return post(request);
};

export const submitKioskPreConsultQuestionnaire = (data) => {
  const request = {
    subUrl: `/hypertension_answers`,
    data,
  };
  return post(request);
};

export const deletePreConsult = (params) => {
  const request = {
    subUrl: `/pre_consults/destroy_pre_consults.json`,
    params,
  };
  return deletee(request);
};

export const createPreconsultation = (data, customJWToken) => {
  const request = {
    subUrl: `/pre_consults`,
    data,
    customJWToken: customJWToken,
  };
  return post(request);
};

export const createKioskPreconsultationAPI = (data, customJWToken) => {
  const request = {
    subUrl: `/health_scan_logs/review_bp_scan`,
    data,
    customJWToken: customJWToken,
  };
  return post(request);
};

//https://dev-api.alldaydr.com/api/patients/questionnaire_templates/submit_questionnaire.json

export const fetchPreConsultReportAndDownload = (params) => {
  const request = {
    subUrl: `/pre_consults/generate_pre_consult_report.json`,
    params,
  };
  return get(request);
};
export const updatePreconsultation = (data, customJWToken) => {
  const request = {
    subUrl: `/pre_consults/${data.id}`,
    data,
    customJWToken: customJWToken,
  };
  return patch(request);
};

//https://dev-api.alldaydr.com/api/patients/questionnaire_templates/submit_questionnaire.json

// Kiosk PreConsult APIs

export const fetchKioskPreConsultHealthScanLogs = (params) => {
  const request = {
    subUrl: `/health_scan_logs`,
    params,
  };
  return get(request);
};

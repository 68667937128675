import React, {Component, Fragment} from "react";
import {Modal, Checkbox, Button} from "antd";
import {connect} from "react-redux";
import {DownloadOutlined} from "@ant-design/icons";
import validator from "validator";
import moment from "moment";

import {safeguardGuideUrl} from "services/alerts";
import {Input} from "antd";
import {disableCopy, verifyObject} from "utilities/utils";
import {DATE_FORMAT} from "constants/common";

class SafeguardAlertModal extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      alertDetails: {
        concerns: "",
        team_name: "",
        contact_number: "",
        concern_summary: "",
        logged_in_person_name: "",
        office_action_needed: false,
        action_detail: "",
      },
      errors: {},
    };
    ["_onInputChange", "_onFormSubmit", "_validateIp"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  _onFormSubmit = async (_) => {
    let data = {...this.state.alertDetails};
    await this.setState({
      ...this.state,
      errors: {
        ...this.state.errors,
        concerns:
          !data.concerns && validator.isEmpty(data.concerns)
            ? "Please enter concerns"
            : null,
        team_name:
          !data.team_name && validator.isEmpty(data.team_name)
            ? "Please enter team name"
            : null,
        contact_number:
          !data.contact_number &&
          (validator.isEmpty(data.contact_number) ||
            !validator.isNumeric(data.contact_number) ||
            data.contact_number.lentgh < 11)
            ? "Please enter a valid contact number"
            : null,
        concern_summary:
          !data.concern_summary && validator.isEmpty(data.concern_summary)
            ? "Please enter concern summary"
            : null,
        logged_in_person_name:
          !data.logged_in_person_name &&
          validator.isEmpty(data.logged_in_person_name)
            ? "Please enter name of the logged in person"
            : null,
        action_detail:
          data.office_action_needed && validator.isEmpty(data.action_detail)
            ? "Please enter action detail"
            : null,
      },
    });

    let errCount = 0;
    Object.values(this.state.errors).forEach(
      (err) => err !== null && errCount++
    );
    if (errCount === 0) {
      this.props.onSubmit(data);
    }
  };
  _validateIp = (e) => {
    switch (e.target.name) {
      case "concerns":
        this.setState({
          errors: {
            ...this.state.errors,
            concerns: validator.isEmpty(e.target.value)
              ? "Please enter concern"
              : null,
          },
        });
        break;
      case "team_name":
        this.setState({
          errors: {
            ...this.state.errors,
            team_name: validator.isEmpty(e.target.value)
              ? "Please enter team name"
              : null,
          },
        });
        break;
      case "contact_number":
        this.setState({
          errors: {
            ...this.state.errors,
            contact_number:
              validator.isEmpty(e.target.value) ||
              !validator.isNumeric(e.target.value) ||
              e.target.value.lentgh < 11
                ? "Please enter a valid contact number"
                : null,
          },
        });
        break;
      case "concern_summary":
        this.setState({
          errors: {
            ...this.state.errors,
            concern_summary: validator.isEmpty(e.target.value)
              ? "Please enter concern summary"
              : null,
          },
        });
        break;
      case "logged_in_person_name":
        this.setState({
          errors: {
            ...this.state.errors,
            logged_in_person_name: validator.isEmpty(e.target.value)
              ? "Please enter name of the logged in person"
              : null,
          },
        });
        break;
      case "action_detail":
        this.setState({
          errors: {
            ...this.state.errors,
            action_detail:
              this.state.office_action_needed &&
              validator.isEmpty(e.target.value)
                ? "Please enter action details"
                : null,
          },
        });
        break;
      default:
        break;
    }
  };
  _onInputChange = (e) => {
    this.setState({
      alertDetails: {
        ...this.state.alertDetails,
        [e.target.name]:
          e.target.name === "office_action_needed"
            ? e.target.checked
            : e.target.value,
      },
    });
    this._validateIp(e);
  };

  render() {
    const {props} = this;
    const {onClose, patientDetails} = props;
    const {modal} = props.safeguardAlert;
    const {isOpen} = modal;
    const {alertDetails, errors} = this.state;
    console.log("patientDetails-------->", props.patientDetail);
    return (
      <Fragment>
        <Modal
          footer={null}
          title="Safeguard Alert"
          visible={isOpen}
          autoSize={true}
          onCancel={this.props.safeguardAlert.isLoading ? false : onClose}
          width={800}
          maskClosable={false}
        >
          <form action="">
            <p className="font-12 weight-400 text-dark-blue line-1-43">
              All concerns need to be raised with the patients local
              safeguarding team. All teams can be found on the attached
              document. If you are unable to find the correct team or you find
              the number is incorrect please contact the Registered Service
              Manager immediately.{" "}
            </p>
            <a
              href={safeguardGuideUrl}
              target="__blank"
              className="btn btn-primary font-10 btn-custom btn-light-blue push-0-t push-15-b sm-commonbtn line-height-30px-imp"
            >
              Download <DownloadOutlined className="ml-2" />
            </a>

            <div className="margin-top-15 form-group">
              <div className="form-row push-15-b">
                <div className="col-md-12 col-lg-6">
                  <label>Patient Name</label>
                  <div className="col-md-12 p-0">
                    <input
                      onCopy={disableCopy}
                      disabled
                      className="form-control margin-0 placeholder-10 text-capitalize"
                      type="text"
                      placeholder="Patient Name"
                      name="patient_name"
                      value={
                        patientDetails &&
                        patientDetails.first_name &&
                        patientDetails.last_name
                          ? `${patientDetails.first_name} ${patientDetails.last_name}`
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <label>Patient DOB</label>
                  <div className="col-md-12 p-0">
                    <input
                      onCopy={disableCopy}
                      disabled
                      className="form-control margin-0 placeholder-10"
                      type="text"
                      placeholder="Patient DOB"
                      name="patient_dob"
                      value={
                        patientDetails && patientDetails.date_of_birth
                          ? moment(patientDetails.date_of_birth).format(
                              DATE_FORMAT
                            )
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="form-row push-15-b">
                <div className="col-md-12">
                  <label> What are your concerns?</label>
                  <div className="col-md-12 p-0">
                    <Input.TextArea
                      className="form-control margin-0 placeholder-10 height-auto"
                      placeholder="Provide your concerns"
                      name="concerns"
                      value={alertDetails.concerns}
                      onChange={this._onInputChange}
                      onBlur={this._validateIp}
                      rows="1"
                      autoSize={true}
                    />
                    {errors && errors.concerns && (
                      <span className="form-error">{errors.concerns}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row push-15-b">
                <div className="col-md-12 col-lg-6">
                  <label>Team Contacted</label>
                  <div className="col-md-12 p-0">
                    <input
                      className="form-control margin-0 placeholder-10"
                      type="text"
                      placeholder="Team Name"
                      name="team_name"
                      onChange={this._onInputChange}
                      onBlur={this._validateIp}
                      value={alertDetails.team_name}
                    />
                    {errors && errors.team_name && (
                      <span className="form-error">{errors.team_name}</span>
                    )}
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <label>Number Used</label>
                  <div className="col-md-12 p-0">
                    <input
                      className="form-control margin-0 placeholder-10"
                      type="text"
                      placeholder="Number Used"
                      name="contact_number"
                      onChange={this._onInputChange}
                      onBlur={this._validateIp}
                      value={alertDetails.contact_number}
                    />
                    {errors && errors.contact_number && (
                      <span className="form-error">
                        {errors.contact_number}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row push-15-b">
                <div className="col-md-12">
                  <label>Logged in person name</label>
                  <div className="col-md-12 p-0">
                    <input
                      className="form-control margin-0 placeholder-10"
                      placeholder="Logged in person name"
                      name="logged_in_person_name"
                      onChange={this._onInputChange}
                      onBlur={this._validateIp}
                      value={alertDetails.logged_in_person_name}
                    />
                    {errors && errors.logged_in_person_name && (
                      <span className="form-error">
                        {errors.logged_in_person_name}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row push-15-b">
                <div className="col-md-12">
                  <label>Summary of concerns logged</label>
                  <div className="col-md-12 p-0">
                    <Input.TextArea
                      autoSize={true}
                      className="form-control margin-0 placeholder-10 height-auto"
                      placeholder="Provide concerns summary"
                      name="concern_summary"
                      onChange={this._onInputChange}
                      onBlur={this._validateIp}
                      value={alertDetails.concern_summary}
                      rows="1"
                    />
                    {errors && errors.concern_summary && (
                      <span className="form-error">
                        {errors.concern_summary}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="form-row push-15-b">
                <div className="col-md-12">
                  <Checkbox
                    name="office_action_needed"
                    onChange={this._onInputChange}
                    onBlur={this._validateIp}
                    checked={alertDetails.office_action_needed}
                  >
                    Does the office needs to take any immediate actions?
                  </Checkbox>
                </div>
              </div>
              {this.state.alertDetails.office_action_needed && (
                <div className="form-row push-15-b">
                  <div className="col-md-12">
                    <label>Action Detail</label>
                    <div className="col-md-12 p-0">
                      <Input.TextArea
                        autoSize={true}
                        className="form-control margin-0 placeholder-10 height-auto"
                        placeholder="Provide the detail of action"
                        onChange={this._onInputChange}
                        onBlur={this._validateIp}
                        name="action_detail"
                        rows="1"
                      />
                      {errors && errors.action_detail && (
                        <span className="form-error">
                          {errors.action_detail}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="form-row">
                <div className="col-sm-12">
                  <Button
                    type="primary"
                    disabled={this.props.safeguardAlert.isLoading}
                    onClick={this._onFormSubmit}
                    loading={this.props.safeguardAlert.isLoading}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientDetails: verifyObject(state.localStore, "patient_detail_obj", null),
  };
};

export default connect(mapStateToProps, null)(SafeguardAlertModal);

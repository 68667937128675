import {genhsMedicationsApi} from "services/nhsMedications";
import {listingReducer} from "reducers/utils/listingReducer";

const {reducer, actions, types} = listingReducer({
  name: `nhsRepeatMedications`,
  getApi: genhsMedicationsApi,
});

const nhsRepeatMedications = reducer;
export {nhsRepeatMedications, actions, types};

import {listingReducer} from "reducers/utils/listingReducer";
import {getOrders} from "services/orders";

const {reducer, actions, types} = listingReducer({
  name: `pastOrders`,
  getApi: getOrders,
});

const pastOrders = reducer;
export {pastOrders, actions, types};

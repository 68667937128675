import React, {useEffect, useState, Fragment} from "react";
import PatientListContainer from "./PatientListContainer";
// import { Row, Col } from "reactstrap";
// import { Paginate } from "components/common/index";
import "assets/css/pages/verify-otp.css";
import {Row, Col} from "reactstrap";
import PaginationPatient from "components/common/PaginationPatient";

export default function PatientList({
  patients,
  _startAppointment,
  _openAppointment,
  userRole,
  handleView,
  clearSearchedPatients,
  paginationData,
  ids,
  isLoading,
  onPatientPageChange,
}) {
  const [usageType, setLastUsageType] = useState("health_kiosk");
  const [patientsArray, setPatientsArray] = useState(patients);
  const [rowsPerPage, setRecordsPerPage] = useState(10);
  const [state, setState] = useState({
    rowsPerPage: 10,
    selectedPage: 0,
    isloading: false,
    selectAll: false,
    isLoadmore: false,
    isLoadmoreWithSorting: false,
    isActiveLoadMore: false,
  });
  console.log("ON PAGE LOADING---", isLoading);
  const handleLoadMore = () => {
    setState({
      ...state,
      selectAll: false,
      isLoadmore: true,
    });
    setRecordsPerPage(rowsPerPage + 10);
    console.log("ON PAGE---1", 1, rowsPerPage + 10);
    onPatientPageChange(
      {
        page: 1,
        data_count: rowsPerPage + 10,
      },
      true
    ); //!NOTE: passig true for search patient API serialize function to prevent default response
  };
  const onPageChange = (data, other) => {
    // data.selected + 1 cause it starts from 0
    console.log("ON PAGE---- func", data);
    let {page, data_count} = data;
    setState({...state, selectedPage: page, selectAll: false});
    console.log("ON PAGE---2", data.selected, rowsPerPage);

    onPatientPageChange(
      {
        page: page,
        data_count: data_count,
      },
      true
    ); //!NOTE: passig true for search patient API serialize function to prevent default response
  };

  const handlePageSelection = () => {
    setState({
      ...state,
      selectAll: false,
      isLoadmore: true,
      isActiveLoadMore: false,
    });
    setRecordsPerPage(10);
    console.log("ON PAGE---3", 1, 10);

    onPatientPageChange(
      {
        page: 1,
        data_count: 10,
      },
      true
    ); //!NOTE: passig true for search patient API serialize function to prevent default response
  };

  const handleRowsPerPage = (value) => {
    setState((prevState) => {
      return {
        ...prevState,
        rowsPerPage: value === "all" ? 20 : value,
        selectedPage: 0,
        selectAll: false,
        isActiveLoadMore: value === "all" && true,
      };
    });
    setRecordsPerPage(value === "all" ? 20 : value);
    console.log("ON PAGE---4", value);

    onPatientPageChange({data_count: value === "all" ? 20 : value}, true); //!NOTE: passig true for search patient API serialize function to prevent default response
  };
  useEffect(() => {
    if (+rowsPerPage !== 10 && isLoading) {
      setState({...state, isloading: true});
    } else {
      setState({...state, isloading: false});
    }
    return () => {
      setState({...state, isloading: false});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowsPerPage, isLoading]);
  useEffect(() => {
    setPatientsArray(patients);
  }, [patients]);
  const handleReplacePatient = async (patient) => {
    const newArray = patientsArray.map((obj) => {
      if (obj.id === patient.id) {
        return patient;
      } else {
        return obj;
      }
    });

    return setPatientsArray(newArray);
  };

  return (
    <div className="newpatientdetails table-responsive pb-3 patient-search-min-height">
      <div className="tableheader patientnewlistwrap">
        <table className="relative bg-gray border">
          <tr>
            {/* <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black width-4 newp_id">
              <div>
                <span>ID</span>
              </div>
            </th> */}
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12 text-light-black newp_name">
              <div>
                <span>Patient Details/NHS No.</span>
              </div>
            </th>
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black [w-110] newp_age">
              <div>
                <span>Age</span>
              </div>
            </th>
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black [w-80] newp_gender">
              <div>
                <span>Gender</span>
              </div>
            </th>
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black [w-200] newp_addr">
              <div>
                <span>Address</span>
              </div>
            </th>
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black [w-15p] newp_surgery">
              <div>
                <span>GP Surgery</span>
              </div>
            </th>
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black [w-15p] newp_nomiphar">
              <div>
                <span>Nominated Pharmacy</span>
              </div>
            </th>

            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12 text-liaht-black [w-160] newp_info">
              <div>
                <span>Contact Info</span>
              </div>
            </th>
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black [w-140] newp_option">
              <div>
                <span>Marketing Option</span>
              </div>
            </th>
            {/* <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black width-6">
              <div>
                <span>Bio</span>
              </div>
            </th> */}
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12  text-light-black [w-140] newp_usertype text-nowrap">
              <div>
                {/* <span>Last Used</span> */}
                <select
                  onChange={(e) => setLastUsageType(e.target.value)}
                  value={usageType}
                  className="table-dropdown"
                >
                  <option value={"app"}>Last Access App</option>
                  <option value={"health_kiosk"}>Last Used Kiosk</option>
                </select>
              </div>
            </th>
            <th className="font-12 padding-6-t padding-6-b text-left padding-left-12 padding-right-12 [w-90] text-liaht-black newp_action">
              <div className="text-center">
                <span>Action</span>
              </div>
            </th>
          </tr>
        </table>
      </div>
      <div className="newpatientlistdata padding-top-12">
        {/* {isLoading && <CommonLoader />} */}
        {patientsArray.map((patient) => {
          return (
            <PatientListContainer
              patient={patient}
              _startAppointment={_startAppointment}
              _openAppointment={_openAppointment}
              userRole={userRole}
              handleView={handleView}
              clearSearchedPatients={clearSearchedPatients}
              usageType={usageType}
              handleReplacePatient={handleReplacePatient}
            />
          );
        })}
      </div>
      {paginationData ? (
        <div className="usertablefooter d-flex justify-content-center">
          <Fragment>
            {!state.isActiveLoadMore && paginationData.total_pages ? (
              <PaginationPatient
                pageCount={paginationData.total_pages}
                onPageChange={onPageChange}
                noScrolltoTop={true}
                fromFetchUser={true}
                isLoading={isLoading}
                totalCount={paginationData.total ? paginationData.total : 0}
                handleRowsPerPage={handleRowsPerPage}
                selectedPage={state.selectedPage}
                rowsPerPage={rowsPerPage}
              />
            ) : null}

            {state.isActiveLoadMore &&
              ids.length < paginationData.total && (
                <button
                  className="btn blue-btn text-white font-13 font-400 width-104 height-34"
                  onClick={handleLoadMore}
                  disabled={isLoading}
                  id="load_more_btn"
                >
                  {isLoading ? "Loading..." : "Load More"}
                </button>
              )}
            {!isLoading && state.isActiveLoadMore && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "end",
                  marginLeft: 20,
                }}
              >
                <button
                  className="btn blue-btn text-white font-13 font-400 width-104 height-34"
                  onClick={handlePageSelection}
                >
                  {" "}
                  Pages Selection
                </button>
              </div>
            )}
          </Fragment>
        </div>
      ) : null}
    </div>
  );
}

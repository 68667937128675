import React, {useState} from "react";
import {
  // Modal
  Radio,
  DatePicker,
  Button,
} from "antd";
import "antd/dist/antd.css";

import ModalPopUp from "components/common/ModalPopUp";
import moment from "moment";
import {DATE_FORMAT} from "constants/common";
import {fetchPreConsultReportAndDownload} from "services/preConsultConditions";
import {errorToast, getErrorObject, successToast} from "utilities/utils";

const PreConsultReportModal = ({visible, onClose}) => {
  const [selection, setSelection] = useState("date");
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectionChange = (e) => {
    setSelection(e.target.value);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
    setSelectedDateRange(null);
  };

  const handleDateRangeChange = (dates) => {
    setSelectedDateRange(dates);
    setSelectedDate(null);
  };

  const handleOk = async () => {
    // Perform actions on OK button click, such as storing the selected date or range in state
    // For demonstration purposes, log the selected values
    try {
      setIsLoading(true);
      let payload = {};
      if (selection === "date") {
        payload = {
          date: moment(selectedDate).format(DATE_FORMAT),
        };
      } else {
        payload = {
          "date_range[start_date]": moment(selectedDateRange[0]).format(
            DATE_FORMAT
          ),

          "date_range[end_date]": moment(selectedDateRange[1]).format(
            DATE_FORMAT
          ),
        };
      }
      const response = await fetchPreConsultReportAndDownload(payload);
      if (response?.data?.status === 200) {
        if (response?.data?.data) {
          const a = document.createElement("a");
          a.href = response?.data?.data;
          a.download = "downloaded_file.txt"; // Replace with your desired file name
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      }
      setIsLoading(false);
      successToast({content: response?.data?.message});
    } catch (error) {
      const {message} = getErrorObject(error);
      setIsLoading(false);
      errorToast({content: message});
    }
    console.log("Selected Date:", selectedDate);
    console.log("Selected Date Range:", selectedDateRange);
    onClose();
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };
  return (
    <div>
      <ModalPopUp
        visible={visible}
        handleCancel={onClose}
        title="Download Report"
        closable={!isLoading}
      >
        <div className="d-flex ">
          <Radio.Group
            onChange={handleSelectionChange}
            value={selection}
            style={{marginBottom: 16}}
            className="d-flex radiogroupbtn"
          >
            <Radio.Button value="date">Select Date</Radio.Button>
            <Radio.Button value="dateRange">
              Select Date Range
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="row mb-2">
          {selection === "date" && (
            <div className="col-md-6">
              <div className="form-group">
              <DatePicker
                className="w-100"
                onChange={handleDateChange}
                value={selectedDate}
                disabledDate={disabledDate}
              />
              </div>
            </div>
          )}
          {selection === "dateRange" && (
            <div className="col-12">
              <div className="form-group">
                <DatePicker.RangePicker
                  className="w-100"
                  onChange={handleDateRangeChange}
                  value={selectedDateRange}
                  disabledDate={disabledDate}
                />
              </div>
            </div>
          )}
        </div>
        <div className="ant-modal-footer d-flex p-0 border-0">
          <Button
            type="primary"
            className="btn btn-gray ant-btn ant-btn-dangerous cancel_btn"
            onClick={onClose}
            disabled={isLoading}
          >
            Close
          </Button>
          <Button
          type="primary"
            // className="btn text-black font-13 weight-400 width-120 height-34 prl-15 btn ant-btn-primary"
            onClick={handleOk}
            disabled={
              isLoading ||
              (selection === "date" && !selectedDate) ||
              (selection === "dateRange" && !selectedDateRange)
            }
            loading={isLoading}
          >
            Download
          </Button>
        </div>
      </ModalPopUp>
    </div>
  );
};

export default PreConsultReportModal;

import React, { Component, Fragment } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import Gallery from "react-grid-gallery";

export const mockItems = [
  {
    src:
      "https://images.medicinenet.com//images/slideshow/medical-marijuana-s1-what-is-medical-marijuana-2.jpg",
    thumbnail:
      "https://images.medicinenet.com//images/slideshow/medical-marijuana-s1-what-is-medical-marijuana-2.jpg",
    thumbnailWidth: 250,
    thumbnailHeight: 140
  },
  {
    src: "https://images.pexels.com/photos/606506/pexels-photo-606506.jpeg",
    thumbnail:
      "https://images.pexels.com/photos/606506/pexels-photo-606506.jpeg",
    thumbnailWidth: 250,
    thumbnailHeight: 140
  },
  {
    src:
      "http://www.stock-photos.com/wp-content/uploads/2011/12/mushroom-photo.jpg",
    thumbnail:
      "http://www.stock-photos.com/wp-content/uploads/2011/12/mushroom-photo.jpg",
    thumbnailWidth: 250,
    thumbnailHeight: 140
  },
  {
    src:
      "https://visualwilderness.com/wp-content/uploads/2013/04/florida_3659c.jpg",
    thumbnail:
      "https://visualwilderness.com/wp-content/uploads/2013/04/florida_3659c.jpg",
    thumbnailWidth: 250,
    thumbnailHeight: 140
  },
  {
    src: "https://i.ytimg.com/vi/p0MHZj4p0dM/maxresdefault.jpg",
    thumbnail: "https://i.ytimg.com/vi/p0MHZj4p0dM/maxresdefault.jpg",
    thumbnailWidth: 250,
    thumbnailHeight: 140
  },
  {
    src:
      "http://www.hdwallpaperspulse.com/wp-content/uploads/2015/07/08/awesome-full-hd-wallpaper.jpg",
    thumbnail:
      "http://www.hdwallpaperspulse.com/wp-content/uploads/2015/07/08/awesome-full-hd-wallpaper.jpg",
    thumbnailWidth: 250,
    thumbnailHeight: 140
  }
];

type IProps = {
  items: [],
  buttonLabel: string,
  headerText: string,
  contentType: string,
  buttonClassName: string
};

type IState = {
  isOpen: boolean
};

class ImageViewerModal extends Component<IProps, IState> {
  state = {
    isOpen: false
  };

  onToggle = () => {
    if (this.props.onClose) {
      this.props.onClose();
    } else {
      this.setState({
        isOpen: !this.state.isOpen
      });
    }
  };
  adjustImageDimension = () => {
    const items = this.props.items;
    return items.map(item => {
      return {
        ...item,
        thumbnailWidth: 410,
        thumbnailHeight: 300
      };
    });
  };
  render() {
    const { items, contentType, show, buttonClassName = "" } = this.props;
    return (
      <Fragment>
        <Button
          color="link"
          className={buttonClassName}
          onClick={this.onToggle}
        >
          {this.props.buttonLabel}
        </Button>
        <Modal
          isOpen={show || this.state.isOpen}
          toggle={this.onToggle}
          autoFocus={true}
          size="lg"
          style={{ width: "500px" }}
          className="modal-auto-width modal-dialog-centered"
          backdrop="static"
        >
          <ModalHeader toggle={this.onToggle}>
            {this.props.headerText}
          </ModalHeader>
          <ModalBody style={{ width: "500px" }}>
            {items && items.length ? (
              <Gallery
                images={this.adjustImageDimension()}
                margin={10}
                showLightboxThumbnails={false}
              />
            ) : (
              <div>No {contentType} available</div>
            )}
          </ModalBody>
        </Modal>
      </Fragment>
    );
  }
}

export default ImageViewerModal;

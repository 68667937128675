import ModalPopUp from "components/common/ModalPopUp";
import React, {useEffect, useState} from "react";
import mobileicon from "assets/images/common/mobile.svg";

import {Form, FormGroup} from "reactstrap";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import {sendOtpRequestPatient} from "services/patients";
import {isNumeric} from "validator";
import {validateOTP} from "containers/session/validateChangePassword";
import {isEmpty} from "lodash";
import {message} from "antd";

const VerifyOtpPatient = (props) => {
  const [time, setTime] = useState(120); // Initial time value
  const [otpResend, setOtpResend] = useState(false);
  const [formData, setFormData] = useState({
    code: "", // Assuming you have an input with 'name' attribute set to 'code'
  });

  const [errors, setErrors] = useState({});
  const startTimer = () => {
    // setTime(30);
    // Perform actions like calling resendOTP() here
    resendOtp(); // Make sure you have defined this function
  };
  useEffect(() => {
    let timerId;

    if (time > 0) {
      timerId = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);
    }
    if (time === 0) {
      clearInterval(timerId);
    }
    // Cleanup the interval on unmount and when time reaches 0
    return () => {
      clearInterval(timerId);
    };
  }, [time]); // Dependency on 'time'
  const resendOtp = async () => {
    try {
      setOtpResend(true);
      await message.loading("Sending OTP...");

      let payload = {
        user_id: props.patientId,
        contact_type: props.contact_type,
      };
      // let formData = new FormData();
      // formData.set("user_id", props.patientId);
      // formData.set("contact_type", props.contact_type);
      let response = await sendOtpRequestPatient(payload);
      console.log("response---", response);
      if (response.data.status === 200) {
        successToast({
          content: verifyObject(response, "data.message", "Sent successfully"),
        });
        setTime(120);
        setOtpResend(false);
      }

      setOtpResend(false);
    } catch (e) {
      const {message} = getErrorObject(e);
      errorToast({
        content: message,
      });
      setOtpResend(false);
    }
  };
  // console.log("time  --->", time);
  const handleChange = (e) => {
    const {name, value} = e.target;

    // Clone the existing errors object and remove the current field's error
    const newErrors = {...errors};
    delete newErrors[name];

    if (isNumeric(value)) {
      setFormData({
        ...formData,
        [name]: value,
      });

      if (value !== "") {
        const data = {
          [name]: value,
        };

        const validationResult = validateOTP(data);

        if (!validationResult.isValid) {
          setErrors({
            ...newErrors,
            [name]: validationResult.errors[name],
          });
        } else {
          // Clear the error for this field
          setErrors(newErrors);
        }
      }
    } else {
      if (value === "") {
        setFormData({
          ...formData,
          [name]: "",
        });
      } else {
        setFormData({
          ...formData,
          [name]: formData.code, // Assuming 'code' is the default value
        });
      }
    }
  };
  const formatSecondsToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;

    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");

    return `${formattedMinutes}:${formattedSeconds}`;
  };
  // console.log("code-----------", formData.code);
  // const handleEmailPhone = () => {
  //   let text =
  //     props.contact_type === "email"
  //       ? convertText(props.patient)
  //       : convertPhoneNumber(props.patient);
  //   return text ? text : "-";
  // };
  // const convertText = (patient, isEmail) => {
  //   const parts = patient?.email ? patient.email.split("@") : null;
  //   if (parts && parts.length === 2) {
  //     const username = parts[0];
  //     const domain = parts[1];
  //     const hiddenUsername = "x".repeat(username.length);
  //     return `${hiddenUsername}@${domain}`;
  //   }
  //   return patient.email;
  // };
  // const convertPhoneNumber = (patient) => {
  //   // Remove non-numeric characters from the phone number
  //   const numericPhoneNumber = patient?.phone
  //     ? patient.phone.replace(/\D/g, "")
  //     : null;

  //   if (numericPhoneNumber && numericPhoneNumber.length >= 4) {
  //     const lastFourDigits = numericPhoneNumber.slice(-4);
  //     const hiddenDigits = "x".repeat(numericPhoneNumber.length - 4);
  //     return hiddenDigits + lastFourDigits;
  //   }

  //   return numericPhoneNumber; // Return the original number if it has less than 4 digits
  // };
  // console.log("props.patient", props.patient);
  const renderMessage = () => {
    return (
      <p>
        Please enter the verification code we sent to your registered
        {props.contact_type === "email" ? " email" : " mobile number"}-
        {props.contact_type === "email" ? (
          props.patient?.email ? (
            <b> {props.patient?.email}</b>
          ) : (
            "-"
          )
        ) : props.patient?.phone ? (
          <b> {props.patient?.phone}</b>
        ) : (
          "-"
        )}
      </p>
    );
  };
  return (
    <ModalPopUp
      title="Verify OTP"
      width={500}
      visible={props.showVerifyOtpModal}
      handleCancel={() => {
        props.setVerifyOtpModal(false);
      }}
      handleOk={() =>
        props.handleRequestVerifyOtp(props.patientId, formData.code)
      }
      cancelBtnclassName="custom_cancel_btn"
      footer={true}
      // maskClosable={false}
      okText={"Verify"}
      cancelText={"Cancel"}
      className="veriftotp_popup"
      maskClosable={false}
      keyboard={false}
      loading={props.requestingVerifyOtp}
      disabled={
        props.requestingVerifyOtp ||
        otpResend ||
        isEmpty(formData.code) ||
        errors.code
      }
    >
      {/* Request for Otp */}
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          props.handleRequestVerifyOtp(props.patientId, formData.code);
        }}
      >
        <FormGroup>
          <div className="otp-content-wrapper">
            <div className="body-content">
              <div className="body-verify-content d-flex">
                <div className="body-verify-img">
                  <img alt="" src={mobileicon} />
                </div>
                <div className="body-verify-text">{renderMessage()}</div>
              </div>
              {/* <div className="otp-text-input-form">
                {props.contact_type === "email" ? "Email" : "SMS"}
                <p>
                  {
                    props.contact_type === "email"
                      ? props.patient?.email
                        ? props.patient?.email
                        : "-"
                      : props.patient?.phone
                      ? props.patient?.phone
                      : "-"

                    // handleEmailPhone()
                  }
                </p>
              </div> */}
              <div className="otp-form">
                <FormGroup>
                  <div className="otp-text-input-form">
                    <label
                      htmlFor="sel1"
                      className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                    >
                      Enter Verification Code
                    </label>
                    <input
                      className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                      name="code"
                      placeholder="Enter verification code"
                      // style={{
                      //   width: "50%",
                      //   marginLeft: "23%",
                      // }}
                      value={formData.code}
                      style={{marginBottom: 2}}
                      onChange={handleChange}
                      autoFocus
                    />
                    {errors.code && (
                      <div className="error-message font-12 mt-1 text-danger">
                        {errors.code}
                      </div>
                    )}
                    {/* {errors && <span className="text-danger">{errors.code}</span>} */}
                    <p className="rcvd_code font-12">
                      Verification code not received?{" "}
                      {time === 0 ? (
                        <a onClick={startTimer}>
                          <b> Resend Code</b>
                        </a>
                      ) : (
                        <b>
                          Resend code in{" "}
                          {time < 10
                            ? `${formatSecondsToMinutes(time)}`
                            : `${formatSecondsToMinutes(time)}`}
                        </b>
                      )}
                    </p>
                  </div>
                </FormGroup>
              </div>
            </div>
          </div>
        </FormGroup>
      </Form>
    </ModalPopUp>
  );
};
export default VerifyOtpPatient;

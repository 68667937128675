/* eslint-disable react-hooks/exhaustive-deps */
import NoFoundMessage from "components/common/NoFoundMessage";
import {isObject, round} from "lodash";
import moment from "moment";
import React, {Fragment, useEffect} from "react";
import createHealthGraph from "./MyHealthGraph";
// import CreateHealthGraph from "./MyHealthGraph2";

export default function HealthAssessmentDetailContainer({
  record,
  healthSummaryDetail,
  healthSummaryGraphDetail,
}) {
  useEffect(() => {
    if (healthSummaryGraphDetail) {
      let chartProps = {
        type: "area",
      };
      if (healthSummaryGraphDetail.name) {
        chartProps = {
          type: "area",
          name: "blood_pressure",
        };
      }
      createHealthGraph(healthSummaryGraphDetail, record, chartProps);
    }
  }, [healthSummaryGraphDetail]);
  // const createMarkup = () => {  //commented description below graph as required
  //   return {__html: healthSummaryDetail.description};
  // };
  return (
    <Fragment>
      {/* {healthSummaryGraphDetail &&
        createHealthGraph(healthSummaryGraphDetail, record.name)} */}
      <div className="bmi_wrapper">
        <div className="form-row align-items-center">
          <div className="col-md-12">
            <h6 className="font-24 weight-500 text-blue m-0">{record.name}</h6>
          </div>
        </div>

        <div className="form-row margin-top-15 margin-bottom-15">
          <div className="col-md-12">
            {healthSummaryDetail && healthSummaryDetail.reading_date && (
              <p className="font-12 weight-400 text-gray push-5-b">
                {moment(healthSummaryDetail.reading_date).format("DD MMM YYYY")}
              </p>
            )}
            <h6 className="font-24 weight-500 text-black m-0">
              {" "}
              <span>
                {!isObject(record.value) &&
                  `${round(record.value, 2)}  ${
                    record.unit ? record.unit : ""
                  }`}{" "}
              </span>
              {record.point_type === `blood_pressure` && (
                <div className="map_data_wrapper">
                  <div className="map_data">
                    <p className="font-12 weight-400 text-gray push-5-b">
                      Systolic
                    </p>
                    <h6 className="font-24 weight-500 text-black m-0">
                      {isObject(record.value) &&
                        `${
                          record.value && round(record.value.systolic_bp, 2)
                        }  ${record.unit ? record.unit : ""}`}{" "}
                    </h6>
                  </div>
                  <div className="map_data">
                    <p className="font-12 weight-400 text-gray push-5-b">
                      Diastolic
                    </p>
                    <h6 className="font-24 weight-500 text-black m-0">
                      {isObject(record.value) &&
                        `${
                          record.value && round(record.value.diastolic_bp, 2)
                        }  ${record.unit ? record.unit : ""}`}{" "}
                    </h6>
                  </div>
                </div>
              )}
            </h6>
          </div>
        </div>
        {/* <CreateHealthGraph
          healthSummaryGraphDetail={healthSummaryGraphDetail}
        /> */}
        {healthSummaryGraphDetail && healthSummaryGraphDetail.length === 0 && (
          <NoFoundMessage message={"No graph data available"} />
        )}
        {
          <div
            id={`${record.point_type}Graph`}
            // className="health_map_wrapper push-20-b"
          ></div>
        }
        <div className="form-row margin-top-15">
          <div className="col-md-12">
            {/* {record.point_type === `blood_pressure` && (
              <div className="map_data_wrapper">
                <div className="map_data">
                  <p className="font-12 weight-400 text-gray push-5-b">
                    Systolic
                  </p>
                  <h6 className="font-24 weight-500 text-black m-0">
                    {isObject(record.value) &&
                      `${record.value && round(record.value.systolic_bp, 2)}  ${
                        record.unit ? record.unit : ""
                      }`}{" "}
                  </h6>
                </div>
                <div className="map_data">
                  <p className="font-12 weight-400 text-gray push-5-b">
                    Diastolic
                  </p>
                  <h6 className="font-24 weight-500 text-black m-0">
                    {isObject(record.value) &&
                      `${
                        record.value && round(record.value.diastolic_bp, 2)
                      }  ${record.unit ? record.unit : ""}`}{" "}
                  </h6>
                </div>
              </div>
            )} */}
            {/* {record.point_type === `pulse_per_minute` && (
              <div className="map_data_wrapper">
                <div className="map_data">
                  <p className="font-12 weight-400 text-gray push-5-b">
                    Normal Heart Rate Value
                  </p>
                  <h6 className="font-24 weight-500 text-black m-0">
                    60 - 100
                  </h6>
                </div>
              </div>
            )} */}
            {/* blood_oxygen_saturation */}

            {/* {record.point_type === `blood_oxygen_saturation` && (
              <div className="map_data_wrapper">
                <div className="map_data">
                  <p className="font-12 weight-400 text-gray push-5-b">
                    Normal Blood Oxygen Value
                  </p>
                  <h6 className="font-24 weight-500 text-black m-0">96%</h6>
                </div>
              </div>
            )} */}

            {/* basal_metabolism */}
            {/* {record.point_type === `basal_metabolism` && (
              <div className="map_data_wrapper">
                <div className="map_data">
                  <p className="font-12 weight-400 text-gray push-5-b">
                    Normal BMR Value{" "}
                  </p>
                  <h6 className="font-24 weight-500 text-black m-0">
                    Male: 1800 kcal
                  </h6>
                  <h6 className="font-24 weight-500 text-black m-0">
                    Female: 1400 kcal
                  </h6>
                </div>
              </div>
            )} */}
            {/* fat_rate */}
            {/* {record.point_type === `fat_rate` && (
              <div className="map_data_wrapper">
                <div className="map_data">
                  <p className="font-12 weight-400 text-gray push-5-b">
                    Male Fat Rate Range
                  </p>
                  <h6 className="font-24 weight-500 text-black m-0">
                    10 - 19.9%
                  </h6>
                </div>
              </div>
            )} */}
            {/* visceral_fat_index */}
            {/* {record.point_type === `visceral_fat_index` && (
              <div className="map_data_wrapper">
                <div className="map_data">
                  <p className="font-12 weight-400 text-gray push-5-b">
                    Normal Visceral Fat Rating
                  </p>
                  <h6 className="font-24 weight-500 text-black m-0">1 - 12</h6>
                </div>
              </div>
            )} */}
            {/* body_moisture_rate */}
            {/* {record.point_type === `body_moisture_rate` && (
              <div className="map_data_wrapper">
                <div className="map_data">
                  <p className="font-12 weight-400 text-gray push-5-b">
                    Normal Value
                  </p>
                  <h6 className="font-24 weight-500 text-black m-0">
                    Male: 50-65%
                  </h6>
                  <h6 className="font-24 weight-500 text-black m-0">
                    Female:45-60%
                  </h6>
                </div>
              </div>
            )} */}
            {/* skeletal_muscle_score */}
            {/* <p className="font-16 weight-400 text-black m-0">
              {healthSummaryDetail && healthSummaryDetail.description && (  //commented description below graph as required
                <div dangerouslySetInnerHTML={createMarkup()} />
              )}
            </p> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

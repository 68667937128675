/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {previousAppointmentsActions} from "../../reducers/appointments";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";
// import moment from "moment";
// import CommonLoader from "../../components/common/CommonLoader";
import {Pagination} from "../../components/common";
// import NoFoundMessage from "../../components/common/NoFoundMessage";
import {onSetPatient, onSetPatientAppointment} from "../../reducers/patients";
import {navigateTo, appRoutesConst} from "../../app/navigation";
import {
  // Popover,
  Tabs,
} from "antd";
// import LightBoxComponent from "../../components/common/LightBoxComponent";
// import {isEmpty} from "lodash";
import FilterComponent from "../../components/FIlterComponent/FilterComponent";
import {previousFilter} from "../../constants/filterConstants";
import refresh from "assets/images/common/refresh.svg";
import {
  onSetDynamicHealthInput,
  onSetDynamicInput,
} from "../../reducers/dynamicTab";
// import ascendingsort from "assets/images/common/ascending-sort.svg";
// import {
//   Dropdown as DropdownEmdr,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
// import three_vertical_dots from "assets/images/common/payment_option.svg";

// import reasonimg from 'assets/images/common/video_histroy.png'
// import {
//   APPT_TYPE_WALKIN,
//   DATE_FORMAT,
//   // ROLE_CLINICAL_PHARMACIST,
//   T24_HR_FORMAT,
// } from "../../constants/common";
import {
  markAsOOSAppointment,
  softDeleteAppointments,
} from "services/appointments";
import PreviousAppointmentContainerDynamic from "./PreviousAppointmentContainerDynamic";
import {endPrescriberAppointment} from "services/patientConsultation";
import store from "app/store";
import deleteimg from "assets/images/common/delete_icon.svg";
import {isEmpty} from "lodash";

// import {devCorporateArray, prodCorporateArray} from "constants/common";

const {TabPane} = Tabs;

// const allowedRoles = ["doctor", "clinical_pharmacist"];

// You need to import the CSS only once
const sortingParams = [
  "patient_name",
  "corporate_name",
  "date_of_birth",
  "order_id",
  "created_at",
];
// const Prevtextover = ({text}) => (
//   <div className="view_popover">
//     <span className="font-12 weight-400 margin-0 text-black inline-block">
//       {text}
//     </span>
//   </div>
// );

class PreviousAppointmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      page: 1,
      tabKey: "finished",
      isClearedFilters: "",
      isAllowDelete: true,
    };
    ["_onRequestList", "_onPageChange", "_handleNavigation"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const focused = urlParams.get("focused");
    store.dispatch({type: "PREV_APPT_TAB_KEY", payload: this.state.tabKey});

    if (focused) {
      this.setState(
        {
          tabKey: focused,
        },
        () => {
          this._onRequestList();
        }
      );
    } else {
      // this._onRequestList();
    }
    // localStorage.removeItem("previousFilters");
  }

  async _onRequestList() {
    let {previousAppointmentsActions} = this.props;
    let {onRequest} = previousAppointmentsActions;
    let {onSetDynamicInput, onSetDynamicHealthInput} =
      this.props.dynamicTabAction;

    if (
      typeof onSetDynamicInput === "function" ||
      typeof onSetDynamicHealthInput === "function"
    ) {
      onSetDynamicInput(null);
      onSetDynamicHealthInput(null);
    }
    if (typeof onRequest === "function") {
      let filters = JSON.parse(localStorage.getItem("previousFilters"))
        ? JSON.parse(localStorage.getItem("previousFilters"))
        : {};
      await onRequest({
        page: 1,
        ...filters,
        appointment_type: this.state.tabKey,
      });
    }
  }
  _onRefresh = () => {
    localStorage.removeItem("previousFilters");
    let {previousAppointmentsActions} = this.props;
    let {onRequest} = previousAppointmentsActions;
    if (typeof onRequest === "function") {
      onRequest({page: 1, appointment_type: this.state.tabKey});
    }
    let nodes = document.querySelectorAll(".push-3-l");
    nodes.forEach((node) => {
      node.classList.remove("is_active_sorting");
      node.classList.remove("sort-applied");
    });
    sortingParams.map((param) => {
      // if (param !== sort_param) {
      this.setState({
        [`dataSorted${param}`]: 0,
        [`accending${param}`]: undefined,
      });
      // }
      return null;
    });
  };

  _onPageChange(data) {
    // let filters = JSON.parse(localStorage.getItem("previousFilters"))
    //   ? JSON.parse(localStorage.getItem("previousFilters"))
    //   : {};
    let {
      previousAppointmentsActions: {onPageChange},
      previousAppointments: {search, filters},
    } = this.props;
    const {page} = data;
    this.setState({toBeDeleteIds: []});
    if (typeof onPageChange === "function") {
      onPageChange({
        page,
        search,
        ...filters,
        ...this.state.sort_params,
        appointment_type: this.state.tabKey,
      });
    }
  }

  toggleThreeDots = (id) => {
    this.setState((prevState) => {
      return {[`isShow${id}`]: !this.state[`isShow${id}`]};
    });
  };

  _handleNavigation(data, fromUnderReview) {
    let {onSetPatient} = this.props.onSetPatient;
    let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
    let {navigateTo} = this.props.navigateTo;
    let {patient_id, pre_consult_id} = data;
    let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
    let {prev_appt_tab_key} = this.props;
    if (typeof onSetDynamicHealthInput === "function") {
      onSetDynamicHealthInput(null);
    }
    if (
      prev_appt_tab_key === "unfinished" &&
      verifyObject(data, "appointment_type", null) === "face_to_face"
    ) {
      store.dispatch({
        type: "SET_OFFLINE_CALL_STATUS",
        payload: "start",
      });
      if (
        verifyObject(data, "inform_gp_request.inform_gp_request", null) &&
        !isEmpty(data.inform_gp_request.inform_gp_request)
      ) {
        store.dispatch({
          type: "SET_IS_GP_INFORMED",
          payload: true,
        });
      } else {
        store.dispatch({
          type: "SET_IS_GP_INFORMED",
          payload: false,
        });
      }
    }
    if (typeof onSetPatient === "function") {
      onSetPatient({id: patient_id});
    }
    store.dispatch({
      type: "IS_CLICKED_VIEW",
      payload: true,
    });

    if (typeof onSetPatientAppointment === "function") {
      onSetPatientAppointment(data);
    }

    if (typeof navigateTo === "function") {
      let routeName = appRoutesConst.patientConsultationDetail;
      if (fromUnderReview) {
        routeName = `${appRoutesConst.patientConsultationDetail}?isReview=true`;
      }
      if (this.state.tabKey === "finished") {
        navigateTo(
          `${routeName}?pre_consult_id=${pre_consult_id}&fromPreviousAppointment=true`
        );
      } else {
        navigateTo(routeName);
      }
    }
  }
  saveFilters = (filters) => {
    // console.log("Saved filters", filters);

    localStorage.setItem("previousFilters", JSON.stringify(filters));
  };
  // componentWillUnmount() {
  //   localStorage.removeItem("previousFilters");
  // }
  sortRecordBy = (sort_param, id) => {
    this.setState(
      {
        [`accending${sort_param}`]:
          this.state[`accending${sort_param}`] === undefined
            ? true
            : !this.state[`accending${sort_param}`],
        [`dataSorted${sort_param}`]:
          (this.state[`dataSorted${sort_param}`]
            ? this.state[`dataSorted${sort_param}`]
            : 0) + 1,
      },
      () => {
        let filters = JSON.parse(localStorage.getItem("previousFilters"))
          ? JSON.parse(localStorage.getItem("previousFilters"))
          : {};
        let {
          previousAppointmentsActions: {onRequest},
          previousAppointments: {search},
        } = this.props;
        let sort_params = {
          [`sort[${sort_param}]`]: this.state[`accending${sort_param}`]
            ? "ASC"
            : "DESC",
        };

        this.setState({sort_params}, () => {
          onRequest({
            ...filters,
            ...this.state.sort_params,
            search,
            page: 1,
            appointment_type: this.state.tabKey,
            // status: this.state.status,
            // search,
          });
          let element = document.getElementById(id);
          element.classList.add("is_active_sorting");

          if (this.state[`dataSorted${sort_param}`] > 1) {
            let dco = document.getElementById(id);
            if (dco) {
              if (dco.classList.contains("sort-applied")) {
                dco.classList.remove("sort-applied");
              } else {
                console.log("SORT", this.state.accending);
                // if (this.state.accending) {
                dco.classList.add("sort-applied");
                // }
              }
            }

            // this.state["dataSorted"]
          }
          sortingParams.map((param) => {
            if (param !== sort_param) {
              this.setState({
                [`dataSorted${param}`]: 0,
                [`accending${param}`]: undefined,
              });
            }
            return null;
          });

          let nodes = document.querySelectorAll(".push-3-l");
          nodes.forEach((node) => {
            if (node.classList.contains("sort-applied") && node.id !== id) {
              node.classList.remove("sort-applied");
            }
            if (
              node.classList.contains("is_active_sorting") &&
              node.id !== id
            ) {
              node.classList.remove("is_active_sorting");
            }
          });
        });
      }
    );
  };
  // sortRecordBy = (sort_param, id) => {
  //   this.setState({ accending: !this.state.accending });
  //   let filters = JSON.parse(localStorage.getItem("previousFilters"))
  //     ? JSON.parse(localStorage.getItem("previousFilters"))
  //     : {};
  //   let {
  //     previousAppointmentsActions: { onRequest },
  //     previousAppointments: { search },
  //   } = this.props;
  //   let sort_params = {
  //     [`sort[${sort_param}]`]: this.state.accending ? "ASC" : "DESC",
  //   };
  //   this.setState({ sort_params }, () => {
  //     onRequest({
  //       ...filters,
  //       ...this.state.sort_params,
  //       search,
  //     });

  //     let dco = document.getElementById(id);
  //     if (dco) {
  //       if (dco.classList.contains("sort-applied")) {
  //         dco.classList.remove("sort-applied");
  //       } else {
  //         dco.classList.add("sort-applied");
  //       }
  //     }
  //     let nodes = document.querySelectorAll(".push-3-l");
  //     nodes.forEach((node) => {
  //       if (node.classList.contains("sort-applied") && node.id !== id) {
  //         node.classList.remove("sort-applied");
  //       }
  //     });
  //   });
  // };
  handleOOSAppointment = async (id) => {
    try {
      this.setState({loading: true});
      const response = await markAsOOSAppointment({id: id});
      if (response.data.status === 200) {
        successToast({content: response.data.message});
        await this._onRequestList();
        this.setState({loading: false});
      }
    } catch (e) {
      errorToast({
        content: e.data.message,
      });
      this.setState({loading: false});
    }
  };
  handleTabChange = (tabKey) => {
    localStorage.removeItem("previousFilters");
    this.setState(
      {tabKey, toBeDeleteIds: [], isClearedFilters: Math.random()},
      async () => {
        await store.dispatch({type: "PREV_APPT_TAB_KEY", payload: tabKey});
        let {onSetDynamicInput, onSetDynamicHealthInput} =
          this.props.dynamicTabAction;

        if (
          typeof onSetDynamicInput === "function" ||
          typeof onSetDynamicHealthInput === "function"
        ) {
          onSetDynamicInput(null);
          onSetDynamicHealthInput(null);
        }
        this._onRequestList();
      }
    );
  };
  endAppointment = async (id) => {
    let params = {id: id};
    try {
      this.setState({[`endingAppointment${id}`]: true});
      const response = await endPrescriberAppointment(params);
      if (response.data.status === 200) {
        this._onRequestList();
      }
      successToast({content: response.data.message});

      this.setState({[`endingAppointment${id}`]: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({[`endingAppointment${id}`]: false});
      errorToast({content: message});
    }
  };
  handleDeleteCheckbox = (toBeDeleteIds) => {
    this.setState({toBeDeleteIds}, () => {});
  };
  handleDelete = async () => {
    this.setState(
      {
        isDeleting: true,
      },
      async () => {
        try {
          let response = await softDeleteAppointments({
            appointment_ids: this.state.toBeDeleteIds?.toString(),
          });

          if (response?.data?.status === 200) {
            successToast({
              content: verifyObject(
                response,
                "data.message",
                "Deleted successfully"
              ),
            });
            this.setState(
              {
                isDeleting: false,
                toBeDeleteIds: [],
              },
              () => {
                this._onRequestList();
              }
            );
          }
          this.setState({
            isDeleting: false,
          });
        } catch (e) {
          const {message} = getErrorObject(e);
          this.setState({
            isDeleting: false,
          });
          errorToast({
            content: message,
          });
        }
      }
    );
  };
  render() {
    let {
      previousAppointments: {ids, data, isLoading},
    } = this.props;
    const statusArray = [
      "finished_oos",
      "finished",
      "patient_arrived",
      "patient_not_arrived",
      "cancelled",
      "not_attended",
    ];
    // let { visible } = this.state
    console.log("previousAppointments", this.props.previousAppointments);

    // let corporateID = devCorporateArray[2].id;

    // if (process.env.REACT_APP_ENV === "prod") {
    //   corporateID = prodCorporateArray[2].id;
    // }
    // console.log("--->", JSON.parse(localStorage.getItem("previousFilters")));
    return (
      <div className="push-10-t">
        <div className="upcoming_appt_list margin-bottom-15 previous_appt_list">
          <div className="form-row push-20-b">
            <div className="col-md-4">
              <a
                onClick={this._onRefresh}
                className="refresh_wrapper vertical-middle text-light-black margin-top-8 in-block"
                title="Refresh"
              >
                <img
                  src={refresh}
                  className="height-15"
                  alt="Refresh"
                  title="Refresh"
                ></img>{" "}
                <span className="push-2-l font-14 weight-400">Refresh</span>
              </a>
              {this.state.toBeDeleteIds?.length > 0 && (
                <Fragment>
                  {this.state.isDeleting ? (
                    <a className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8 ml-3 disabled-view">
                      <img alt="" src={deleteimg} className="height-15"></img>{" "}
                      <span className="push-2-l font-14 weight-400">
                        Deleting ....
                      </span>
                    </a>
                  ) : (
                    <a
                      onClick={this.handleDelete}
                      className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8 ml-3"
                    >
                      <img alt="" src={deleteimg} className="height-15"></img>{" "}
                      <span className="push-2-l font-14 weight-400">
                        {this.state.isDeleting ? "Deleting..." : "Delete"}
                      </span>
                    </a>
                  )}
                </Fragment>
              )}
            </div>
            {
              <div className="col-md-8">
                {
                  <FilterComponent
                    isLoading={isLoading}
                    defaultRequest={{
                      appointment_type: this.state.tabKey,
                    }}
                    dataArray={ids}
                    onFilterChange={
                      this.props.previousAppointmentsActions.onFilterChange
                    }
                    resetFilters={
                      this.props.previousAppointmentsActions.resetFilterState
                    }
                    filter_report_type="PastAppointment"
                    filterArray={previousFilter}
                    saveFilters={this.saveFilters}
                    selectedFilters={
                      JSON.parse(localStorage.getItem("previousFilters"))
                        ? {
                            ...JSON.parse(
                              localStorage.getItem("previousFilters")
                            ),
                          }
                        : {}
                    }
                    isClearedFilters={this.state.isClearedFilters}
                  />
                }
              </div>
            }
          </div>

          <div className="tableheader">
            <Tabs
              onChange={this.handleTabChange}
              defaultActiveKey="finished"
              activeKey={this.state.tabKey}
            >
              <TabPane
                tab={
                  <div>
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box"></span>
                      <span className="icons_wrapper_title">Finished/NA</span>
                    </span>
                  </div>
                }
                key={"finished"}
                disabled={isLoading}
              >
                <PreviousAppointmentContainerDynamic
                  statusArray={statusArray}
                  data={data}
                  isLoading={isLoading}
                  ids={ids}
                  sortRecordBy={this.sortRecordBy}
                  _handleNavigation={this._handleNavigation}
                  toggleThreeDots={this.toggleThreeDots}
                  handleOOSAppointment={this.handleOOSAppointment}
                  {...this.state}
                  {...this.props}
                  isAllowDelete={this.state.isAllowDelete}
                  handleDeleteCheckbox={this.handleDeleteCheckbox}
                />
              </TabPane>
              <TabPane
                tab={
                  <div>
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box"></span>
                      <span className="icons_wrapper_title">Unfinished</span>
                    </span>
                  </div>
                }
                key={"unfinished"}
                disabled={isLoading}
              >
                <PreviousAppointmentContainerDynamic
                  statusArray={statusArray}
                  data={data}
                  isLoading={isLoading}
                  ids={ids}
                  sortRecordBy={this.sortRecordBy}
                  _handleNavigation={this._handleNavigation}
                  toggleThreeDots={this.toggleThreeDots}
                  handleOOSAppointment={this.handleOOSAppointment}
                  endAppointment={this.endAppointment}
                  {...this.state}
                  {...this.props}
                  isAllowDelete={this.state.isAllowDelete}
                  handleDeleteCheckbox={this.handleDeleteCheckbox}
                />
              </TabPane>
              {/* {corporateID === this.props.corporateID &&
                allowedRoles.includes(this.props.userRole) && (
                  <TabPane
                    tab={
                      <div>
                        <span className="icons_wrapper">
                          <span className="icons_wrapper_img_box"></span>
                          <span className="icons_wrapper_title">In Review</span>
                        </span>
                      </div>
                    }
                    key={"in_review"}
                    disabled={isLoading}
                  >
                    <PreviousAppointmentContainerDynamic
                      statusArray={statusArray}
                      data={data}
                      isLoading={isLoading}
                      ids={ids}
                      sortRecordBy={this.sortRecordBy}
                      _handleNavigation={this._handleNavigation}
                      toggleThreeDots={this.toggleThreeDots}
                      handleOOSAppointment={this.handleOOSAppointment}
                      endAppointment={this.endAppointment}
                      {...this.state}
                      {...this.props}
                      fromUnderReview={true}
                    />
                  </TabPane>
                )} */}
            </Tabs>
          </div>
        </div>

        <div className="col-md-12">
          {!isLoading && (
            <Pagination
              data={this.props.previousAppointments}
              onPageChange={this._onPageChange}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    previousAppointments: verifyObject(state, "previousAppointments", []),
    userRole: verifyObject(state, "localStore.user.role", null),
    corporateID: verifyObject(state, "localStore.user.corporate_id", null),
    corporateName: verifyObject(state, "localStore.user.corporate_name", null),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    previousAppointmentsActions: bindActionCreators(
      previousAppointmentsActions,
      dispatch
    ),
    onSetPatientAppointment: bindActionCreators(
      {onSetPatientAppointment},
      dispatch
    ),
    onSetPatient: bindActionCreators({onSetPatient}, dispatch),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
    dynamicTabAction: bindActionCreators(
      {
        onSetDynamicInput,
        onSetDynamicHealthInput,
      },
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviousAppointmentContainer);

export default ConnectedComponent;

import React, {Fragment} from "react";
// import { Header } from "components/session/index";
// import { Footer } from "components/common/index";
import ChangePasswordContainer from "../../containers/session/ChangePasswordContainer";
import logo from "assets/images/common/alldaydrlogo.png";
import {ErrorBoundary} from "app/ErrorBoundary";

const ChangePasswordPage = (props) => {
  return (
    <Fragment>
      <Fragment>
        <div className="login-wrapper">
          <div className="container-fluid">
            <div className="login-wrapper-logo">
              <a title="healthya.co.uk">
                <img src={logo} alt="ADD logo" className="img-responsive" />
              </a>
            </div>
          </div>

          <div className="login-title-wrapper">
            <div className="container-fluid">
              <h1 className="login-title-h1">Change Password</h1>
            </div>
          </div>
          <ErrorBoundary>
            <div className="container-fluid">
              <div className="login-form-wrapper">
                <ChangePasswordContainer />
              </div>
            </div>
          </ErrorBoundary>

          {/* <div className="login-wrapper-inner">
            <div className="container padding-20-t padding-20-b bg-white">
              <div className="row">
                <div className="col-md-6">
                  <div className="text-left">
                    <div className="in-block no-float max-width-1020">
                      <h3 className="font-32 weight-500 text-left text-black">
                        Change Password
                      </h3>
                    </div>
                  </div>
                  <ChangePasswordContainer />
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </Fragment>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default ChangePasswordPage;

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Upload,
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  DatePicker,
} from "antd";
import { withRouter } from "react-router-dom";
import moment from "moment";

import { actions as profileActions } from "reducers/userProfile";
import { logOut } from "reducers/session";
import { errorToast, getErrorObject, verifyObject } from "utilities/utils";
import CommonLoader from "../common/CommonLoader";
import { DATE_FORMAT } from "constants/common";
import PhoneInput from "react-phone-input-2";

let { Option } = Select;

class CompanyDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.profile.isLoading,
      profileImage: [],
      fileList: [],
      allSpecialities: [],
      allLanguages: [],
      errors: {
        company_details: {},
        bank_details: {},
      },
      company_details: {},
      bank_details: {},
      ...this.props.profile,
    };

    let fns = [
      "_onChange",
      "_onPreview",
      "_onLogOut",
      "_onSubmit",
      "_onInputChange",
      "_onDropdownChange",
      "_onCompanyDetailsUpdate",
      "_onCompanyDetailsAdd",
      "_onCertificateUpload",
      "_onDateChange",
    ];
    fns.map((fn) => (this[fn] = this[fn].bind(this)));
  }

  _onDateChange = (date, dateString) => {
    this.setState({
      company_details: {
        ...this.state.company_details,
        date_of_incorporate: date,
      },
    });
  };

  _onChange = ({ file }) => {
    this.setState({
      profileImage: file,
    });
    this._onSubmit({
      image: file,
      image_file_name: file.name,
    });
  };

  _onCertificateUpload = async ({ file }) => {
    await this.setState({
      company_details: {
        ...this.state.company_details,
        incorporate_certificate: file,
      },
    });

    try {
      this.setState({ isLoading: true });
      await this.props.profileActions.updateCompanyDetails({
        id: this.state.company_details?.id,
        incorporate_certificate:
          this.state.company_details?.incorporate_certificate,
      });
      this.setState({ isLoading: false });
      await this.props.profileActions.getProfile({ id: this.props.user.id });
    } catch (e) {
      this.setState({ isLoading: false });
      const error = getErrorObject(e);
      errorToast({ content: error.message });
    }
  };

  _onLogOut = () => {
    let { logOut } = this.props;
    if (typeof logOut === "function") {
      logOut();
    }
  };

  _onInputChange = (label, name, e) => {
    this.setState({
      [label]:
        e && e?.target
          ? {
              ...this.state[label],
              [e.target.name]:
                e.target.type === "checkbox"
                  ? e.target.checked
                  : e.target.value,
            }
          : {
              ...this.state[label],
              [name]: e,
            },
    });
  };

  _onDropdownChange = (label, name, val) => {
    this.setState({
      [label]: {
        ...this.state[label],
        [name]: val,
      },
    });
  };

  _onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  _onSubmit = async (data) => {
    try {
      this.setState({ isLoading: true });
      await this.props.profileActions.updateProfile({
        id: this.props.user.id,
        ...data,
      });
      // successToast({ content: response.data.message })
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      const error = getErrorObject(e);
      errorToast({ content: error.message });
    }
  };

  _onCompanyDetailsAdd = async (_) => {
    let { company_details } = this.state;
    let data = {
      id: this.props.user.id,
      payment_method: company_details?.payment_method
        ? company_details?.payment_method
        : "",
      umbrella_company: company_details?.umbrella_company
        ? company_details?.umbrella_company
        : "",
      payment_type: company_details?.payment_type
        ? company_details?.payment_type
        : "",
      company_name: company_details?.company_name
        ? company_details?.company_name
        : "",
      ltd_company: company_details?.ltd_company
        ? company_details?.ltd_company
        : false,
      phone: company_details?.phone ? company_details?.phone : "",
      email: company_details?.email ? company_details?.email : "",
      vat_number: company_details?.vat_number
        ? company_details?.vat_number
        : "",
      vat_registered: company_details?.vat_registered
        ? company_details?.vat_registered
        : false,
      vat_on_service_fee: company_details?.vat_on_service_fee
        ? company_details?.vat_on_service_fee
        : false,
      company_reg_number: company_details?.company_reg_number
        ? company_details?.company_reg_number
        : "",

      date_of_incorporate: company_details?.date_of_incorporate
        ? company_details?.date_of_incorporate
        : "",
      incorporate_certificate: company_details?.incorporate_certificate
        ? company_details?.incorporate_certificate
        : "",
      payroll_reference: company_details?.payroll_reference
        ? company_details?.payroll_reference
        : "",
      exp: company_details?.exp ? company_details?.exp : false,
    };

    await this.setState({
      errors: {
        ...this.state.errors,
        company_details: {
          ...this.state.errors.company_details,
          payment_method:
            !data.payment_method || data.payment_method === "0"
              ? "Select a payment method please"
              : null,
          payment_type:
            !data.payment_type || data.payment_type === "0"
              ? "Select a payment type please"
              : null,
          company_name: !data.company_name ? "Enter company name please" : null,
          phone: !data.phone ? "Enter phone number please" : null,
        },
      },
    });

    let errCount = 0;
    Object.values(this.state.errors.company_details).forEach(
      (err) => err !== null && errCount++
    );
    if (errCount === 0) {
      await this.props.profileActions.addCompanyDetails(data);
    }
  };

  _onCompanyDetailsUpdate = async (_) => {
    let { company_details } = this.state;
    let companyID = verifyObject(
      this.props.profile,
      "profile.company_details?.id",
      null
    );
    let data = {
      id: companyID,
      company_name: company_details?.company_name
        ? company_details?.company_name
        : "",
      payment_type: company_details?.payment_type
        ? company_details?.payment_type
        : "",
      payment_method: company_details?.payment_method
        ? company_details?.payment_method
        : "",
      ltd_company: company_details?.ltd_company
        ? company_details?.ltd_company
        : "",
      phone: company_details?.phone ? company_details?.phone : "",
      email: company_details?.email ? company_details?.email : "",
      vat_number: company_details?.vat_number
        ? company_details?.vat_number
        : "",
      vat_registered: company_details?.vat_registered
        ? company_details?.vat_registered
        : false,
      vat_on_service_fee: company_details?.vat_on_service_fee
        ? company_details?.vat_on_service_fee
        : false,
      company_reg_number: company_details?.company_reg_number
        ? company_details?.company_reg_number
        : "",

      date_of_incorporate: company_details?.date_of_incorporate
        ? company_details?.date_of_incorporate
        : "",
      incorporate_certificate: company_details?.incorporate_certificate
        ? company_details?.incorporate_certificate
        : "",
      payroll_reference: company_details?.payroll_reference
        ? company_details?.payroll_reference
        : "",
      exp: company_details?.exp ? company_details?.exp : false,
    };
    await this.setState({
      errors: {
        ...this.state.errors,
        company_details: {
          ...this.state.errors.company_details,
          payment_method:
            !data.payment_method || data.payment_method === "0"
              ? "Select a payment method please"
              : null,
          payment_type:
            !data.payment_type || data.payment_type === "0"
              ? "Select a payment type please"
              : null,
          company_name: !data.company_name ? "Enter company name please" : null,
        },
      },
    });

    let errCount = 0;
    Object.values(this.state.errors.company_details).forEach(
      (err) => err !== null && errCount++
    );
    if (errCount === 0) {
      await this.props.profileActions.updateCompanyDetails(data);
    }
  };

  async componentDidMount() {
    await this.props.profileActions.getProfile({ id: this.props.user.id });
    this.props.profile &&
      this.setState({
        ...this.props.profile.profile,
      });
  }
  render() {
    let { errors, company_details } = this.state;
    console.log("this.props.errors", errors);
    return (
      <div className="personaldetailsmain_wrapper">
        <div className="padding-bottom-60 personaldetailsmain">
          {this.props.profile.isLoading ? (
            <CommonLoader />
          ) : (
            <Form name="company_details_form">
              <div className="container-fluid p-0 company-details-form">
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Payment Method*
                    </label>
                    <Select
                      className="w-100 input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                      // disabled={!!company_details}
                      value={company_details && company_details?.payment_method}
                      name="payment_method"
                      onChange={(val) =>
                        this._onDropdownChange(
                          "company_details",
                          "payment_method",
                          val
                        )
                      }
                    >
                      <Option value="0">Select Payment Method</Option>
                      <Option value="PAYE">PAYE</Option>
                      <Option value="Limited">Limited</Option>
                      <Option value="Umbrella">Umbrella</Option>
                      <Option value="Self Employed">Self Employed</Option>
                      <Option value="Other">Other</Option>
                    </Select>
                    {errors.company_details?.payment_method && (
                      <p className="form-error">
                        {errors.company_details?.payment_method}
                      </p>
                    )}
                  </div>
                  {company_details &&
                    company_details?.payment_method === "Umbrella" && (
                      <div className="col-sm-12 col-md-6 col-lg-4">
                        <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                          Umbrella Company
                        </label>
                        <Select
                          className="w-100 input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                          // disabled={!!company_details}
                          value={
                            company_details
                              ? company_details?.umbrella_company
                              : "0"
                          }
                          name="umbrella_company"
                          onChange={(val) =>
                            this._onDropdownChange(
                              "company_details",
                              "umbrella_company",
                              val
                            )
                          }
                        >
                          <Option value="0">Select Umbrella Company</Option>
                          <Option value="FTP (Toplard Ltd)">
                            FTP (Toplard Ltd)
                          </Option>
                          <Option value="Unified Payroll">
                            Unified Payroll
                          </Option>
                          <Option value="Orange Genie Umbrella">
                            Orange Genie Umbrella
                          </Option>
                        </Select>
                      </div>
                    )}
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Payment Type*
                    </label>
                    <Select
                      className="w-100 input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                      // disabled={!!company_details}
                      value={
                        company_details ? company_details?.payment_type : "0"
                      }
                      name="payment_type"
                      onChange={(val) =>
                        this._onDropdownChange(
                          "company_details",
                          "payment_type",
                          val
                        )
                      }
                    >
                      <Option value="0">Select Payment Type</Option>
                      <Option value="BACS">BACS</Option>
                      <Option value="Printed Cheque">Printed Cheque</Option>
                      <Option value="Manual Cheque">Manual Cheque</Option>
                      <Option value="Cash">Cash</Option>
                    </Select>
                    {errors.company_details?.payment_type && (
                      <p className="form-error">
                        {errors.company_details?.payment_type}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Company Name*
                    </label>
                    <Input
                      name="company_name"
                      // disabled={!!company_details}
                      value={company_details && company_details?.company_name}
                      onChange={(e) =>
                        this._onInputChange("company_details", null, e)
                      }
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    {errors.company_details?.company_name && (
                      <p className="form-error">
                        {errors.company_details?.company_name}
                      </p>
                    )}
                    <div className="mt-2">
                      <Checkbox
                        name="ltd_company"
                        className="mr-2"
                        checked={
                          company_details && company_details?.ltd_company
                        }
                        onChange={(e) =>
                          this._onInputChange(
                            "company_details",
                            "ltd_company",
                            e
                          )
                        }
                      >
                        Is company limited?
                      </Checkbox>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Phone
                    </label>
                    {/* <Input
                      name="phone"
                      onChange={(e) =>
                        this._onInputChange("company_details", null, e)
                      }
                      value={company_details && company_details?.phone}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    /> */}
                    <PhoneInput
                      country={"gb"}
                      limitMaxLength={14}
                  
                      // onlyCountries={["us"]}
                      // preferredCountries={[""]}
                      // inputClass="d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0"
                      inputClass="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                      countryCodeEditable={false}
                      onChange={(d) => {
                        console.log("phone", d);
                        // setFieldValue("phone", `+${d}`);
                        this._onInputChange("company_details", "phone", d);
                      }}
                      // placeholder="+1 (324) 242-3423"
                      // onFocus={() =>
                      //   setState({ ...state, isCountryEditable: false })
                      // }
                      specialLabel=""
                      value={company_details?.phone}
                    />
                    <div className="field-status">
                      {this.state.errors.phone && (
                        <p className="form-error">{this.state.errors.phone}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Email
                    </label>
                    <Input
                      name="email"
                      value={company_details && company_details?.email}
                      onChange={(e) =>
                        this._onInputChange("company_details", null, e)
                      }
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      VAT Number
                    </label>
                    <Input
                      name="vat_number"
                      value={company_details && company_details?.vat_number}
                      onChange={(e) =>
                        this._onInputChange("company_details", null, e)
                      }
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.phone && (
                        <p className="form-error">{this.state.errors.phone}</p>
                      )}
                    </div>
                    <div className="mt-2">
                      <Checkbox
                        checked={
                          company_details && company_details?.vat_registered
                        }
                        name="vat_registered"
                        onChange={(e) =>
                          this._onInputChange(
                            "company_details",
                            "vat_registered",
                            e
                          )
                        }
                        className="m-0"
                      >
                        Is VAT registered?
                      </Checkbox>
                      <Checkbox
                        checked={
                          company_details && company_details?.vat_on_service_fee
                        }
                        name="vat_on_service_fee"
                        onChange={(e) =>
                          this._onInputChange(
                            "company_details",
                            "vat_on_service_fee",
                            e
                          )
                        }
                        className="m-0"
                      >
                        VAT on service fee?
                      </Checkbox>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Company Registered Number
                    </label>
                    <Input
                      value={
                        company_details && company_details?.company_reg_number
                      }
                      onChange={(e) =>
                        this._onInputChange("company_details", null, e)
                      }
                      name="company_reg_number"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Date of Incorporate
                    </label>
                    <DatePicker
                      className="d-block w-100 input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                      defaultValue={
                        company_details &&
                        moment(company_details?.date_of_incorporate)
                      }
                      name="date_of_incorporate"
                      format={DATE_FORMAT}
                      onChange={this._onDateChange}
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    {!company_details ? (
                      <Fragment>
                        <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                          Incorporate Certificate
                        </label>
                        <Upload
                          action="#"
                          name="incorporate_certificate"
                          customRequest={this._onCertificateUpload}
                        >
                          <Button
                          // disabled={
                          // 	company_details &&
                          // 	!!company_details?.incorporate_certificate
                          // }
                          >
                            Upload Certificate
                          </Button>
                        </Upload>
                      </Fragment> 
                    ) : (
                      <Fragment>
                        <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                          Incorporate Certificate
                        </label>
                        <div className="document-container certificate-container">
                          <div className="margin-bottom-15 certificate-single">
                            <img
                              src={
                                this.props.profile &&
                                this.props.profile.profile.company_details &&
                                this.props.profile.profile.company_details
                                  .file &&
                                this.props.profile.profile.company_details?.file
                              }
                              alt=""
                            />
                            <div className="certificate-action">
                              <Upload
                                action="#"
                                name="incorporate_certificate"
                                customRequest={this._onCertificateUpload}
                              >
                                <Button className="ml-1">
                                  Change Certificate
                                </Button>
                              </Upload>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Payroll Reference
                    </label>
                    <Input
                      name="payroll_reference"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                      onChange={(e) =>
                        this._onInputChange("company_details", null, e)
                      }
                      value={
                        company_details && company_details?.payroll_reference
                      }
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12">
                    <Checkbox
                      name="exp"
                      checked={company_details && company_details?.exp}
                      onChange={(e) =>
                        this._onInputChange("company_details", "exp", e)
                      }
                      className="mr-2"
                    >
                      Exp
                    </Checkbox>
                  </div>
                </div>
                <div className="form-row mt-4">
                  <div className="col-sm-12">
                    {this.props.profile &&
                    this.props.profile.profile.company_details ? (
                      <Button
                        className="width-220px height-40px-imp btn btn-blue btn-custom margin-right-10"
                        // loading={this.props.profile.isLoading}
                        onClick={this._onCompanyDetailsUpdate}
                      >
                        Save Changes
                      </Button>
                    ) : (
                      <Button
                        className="width-220px height-40px-imp btn btn-blue btn-custom"
                        // loading={this.props.profile.isLoading}
                        onClick={this._onCompanyDetailsAdd}
                      >
                        Add Company Details
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.localStore.user,
    profile: state.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
    logOut: bindActionCreators(logOut, dispatch),
  };
};
let connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyDetails);
export default withRouter(connectedComponent);

import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Prompt, withRouter} from "react-router-dom";

import Layout from "../../Layouts/PageLayout";
import UserProfile from "./UserProfile";
import CompanyDetails from "./CompanyDetails";
import BankDetails from "./BankDetails";
import personal_details from "assets/images/sidebar/ic-personal_details.svg";
import company_details from "assets/images/sidebar/ic-company-details.svg";
import bank_details from "assets/images/sidebar/ic-bank-details.svg";

import {
  onRequestSetComponent,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import {appRoutesConst, navigateTo} from "../../app/navigation";
import {verifyObject} from "../../utilities/utils";
// import { CalendarOutlined } from "@ant-design/icons";
import {Beforeunload} from "react-beforeunload";
// import {ROLE_CLINICAL_PHARMACIST} from "constants/common";

class ProfileForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: "UserProfile",
    };
    ["_getIndexRoute", "_renderDynamicComponent"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  componentDidMount() {
    this._getIndexRoute();
    let {componentName} = this.props;
    this.setState({componentName});
  }

  _getIndexRoute() {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent("UserProfile");
      onSetSidebarKey(["1"]);
    }
  }

  _renderDynamicComponent() {
    let {componentName} = this.props;
    // if (this.props.userRole === ROLE_CLINICAL_PHARMACIST) {
    //   switch (componentName) {
    //     case "UserProfile":
    //       return <UserProfile />;
    //     default:
    //       return <UserProfile />;
    //   }
    // } else {
    switch (componentName) {
      case "UserProfile":
        return <UserProfile />;

      case "CompanyDetails":
        return <CompanyDetails />;

      case "BankDetails":
        return <BankDetails />;

      default:
        return <UserProfile />;
    }
    // }
  }
  _clearComponents = () => {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(null);
      onSetSidebarKey(null);
    }
  };

  render() {
    let sideBarArray = [
      {
        label: "Personal Details",
        path: appRoutesConst.profile,
        icon: <img alt="Personal Details" src={personal_details}></img>,
        page: "UserProfile",
        key: 1,
        useDynamicComponent: true,
        disabled: false,
      },
      {
        label: "Company Details",
        path: appRoutesConst.profile,
        icon: <img alt="Company Details" src={company_details}></img>,
        key: 2,
        page: "CompanyDetails",
        useDynamicComponent: true,
        disabled: false,
      },
      {
        label: "Bank Details",
        path: appRoutesConst.profile,
        icon: <img alt="Bank Details" src={bank_details}></img>,
        key: 3,
        page: "BankDetails",
        useDynamicComponent: true,
        disabled: this.props.isPharmSmartUser,
      },
    ];

    // if (this.props.userRole === ROLE_CLINICAL_PHARMACIST) {
    //   sideBarArray = [
    //     {
    //       label: "Personal Details",
    //       path: appRoutesConst.profile,
    //       icon: <img alt="Personal Details" src={personal_details}></img>,
    //       page: "UserProfile",
    //       key: 1,
    //       useDynamicComponent: true,
    //     },
    //   ];
    // }
    return (
      <Beforeunload
        onBeforeunload={(event) => {
          this._clearComponents();
        }}
      >
        <Layout title="User Profile" sideBarArray={sideBarArray}>
          <Prompt
            message={(location, action) => {
              if (action === "PUSH") {
                this._clearComponents();
                return true;
              }
              if (action === "POP") {
                this._clearComponents();
                return true;
              }
              return true;
            }}
          />
          <div className="rightmainsection report_review_section">
            <div className="container-fluid right_wrapper p-0">
              <div className="mt-3">
                <div className="col-md-12 selection_container padding-right-10 padding-left-10">
                  {this._renderDynamicComponent()}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Beforeunload>
    );
  }
}

const mapStateProps = (state) => {
  return {
    componentName: verifyObject(
      state.localStore,
      "componentName",
      "UserProfile"
    ),
    userRole: verifyObject(state, "localStore.user.role", null),
    isPharmSmartUser: verifyObject(
      state,
      "localStore.user.is_pharmsmart_user",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {onRequestSetComponent, onSetSidebarKey},
      dispatch
    ),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(ProfileForm);

export default withRouter(ConnectedComponent);

import { isEmpty } from "lodash";
import React from "react";
import dummy_dr from "assets/images/common/dummy_dr.svg";

export default function AudioCallScreen({
  isTalking,
  volume,
  patientImage,
  patientName,
}) {
  return (
    <div className="video-screen  custom-video-screen">
      <div className="default_video_screen">
        {isTalking && volume !== 0 && <div className="audio_pulse"></div>}
        <img
          alt=""
          src={!isEmpty(patientImage) ? patientImage : dummy_dr}
          width="98"
          height="98"
          style={{ borderRadius: "50px" }}
          className="audio-call-avatar-image"
        ></img>
        {patientName && (
          <p className="font-14 weight-500 text-dark-blue text-center push-20-t">
            {patientName}
          </p>
        )}
      </div>
    </div>
  );
}

import React, { PureComponent, Fragment } from "react";
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { actions as rightToWorkActions } from "reducers/rightToWork";
import { verifyObject } from "../../utilities/utils";
// import BreadCrumb from "../../components/common/BreadCrumb";
// import { appRoutesConst } from "../../app/navigation";
import RightToWorkTable from "./RightToWorkTable";
import { Pagination } from "../../components/common";

class RightToWorkContainer extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {

        };
        [
            "_onReuestList",
            "_onPageChange"
        ].map((fn) => this[fn] = this[fn].bind(this));
    }

    componentDidMount() {
        this._onReuestList()
    }

    _onReuestList() {
        let { rightToWorkActions: { onRequest } } = this.props
        if (typeof onRequest === "function") {
            onRequest({ page: 1 })
        }
    }

    _onPageChange(data) {
        let { rightToWorkActions: { onPageChange }, rightToWork: { search } } = this.props
        const { page } = data;
        if (typeof onPageChange === "function") {
            onPageChange({ page, search })
        }
    }

    render() {
        let { rightToWork } = this.props
        // const breadCrumbData = [{
        //     path: appRoutesConst.rightToWork,
        //     label: "Right To Work"
        // }]

        return (
            <Fragment>
                <div className="push-10-t push-20-b ">
                    {/* <div className="row align-items-center breabcrum-with-searchfilter">
                        <div className="col-xl-5 col-sm-4">
                            <nav style={{ padding: 15 }} aria-label="breadcrumb">
                                <BreadCrumb data={breadCrumbData} />
                            </nav>
                        </div>
                    </div> */}
                    <div className="">
                        <RightToWorkTable _onReuestList={this._onReuestList} dataSource={rightToWork} />
                        <Pagination
                            data={rightToWork}
                            onPageChange={this._onPageChange}
                        />
                    </div>
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        rightToWork: verifyObject(state, "rightToWork", [])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        rightToWorkActions: bindActionCreators(rightToWorkActions, dispatch)
    };
};

const ConnectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(RightToWorkContainer);

export default withRouter(ConnectedComponent);

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actions as preConsultationReviewActions} from "../../reducers/kioskPreConsult";
import {Pagination} from "../../components/common";
import refresh from "assets/images/common/refresh.svg";
import deleteimg from "assets/images/common/delete_icon.svg";
import FilterComponent from "../../components/FIlterComponent/FilterComponent";
import {
  kioskPreConsultationFilters,
  preConsultationFiltersInReviewReviewed,
} from "../../constants/filterConstants";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import {appRoutesConst, navigateTo} from "app/navigation";
import store from "app/store";
import {onSetPatient, onSetPatientAppointment} from "../../reducers/patients";
import PreConsultationReviewTable from "./PreConsultationReviewTable";
import {onSetDynamicInput} from "../../reducers/dynamicTab";
import {
  Modal,
  Radio,
  // Checkbox,
  Tabs,
} from "antd";
import {deletePreConsult, fetchOutComes} from "services/preConsultConditions";
import isEmpty from "lodash/isEmpty";
import PreConsultNotification from "components/PreConsult/PreConsultNotification";
import moment from "moment";
import PreConsultBookAppointmentModal from "components/PreConsult/PreConsultBookAppointmentModal";
import { Button } from "antd";

import {
  getTimeSlotsForCorp,
  // getTimeSlots,
  getTimeSlotsForDoctor,
} from "services/rota";
// import {bookAppointment} from "services/allAppointmentUsers";
import {
  bookAppointmentFromPatientAPI,
  // fetchSlotsAPI,
} from "services/appointments";
import KioskPreConsultReviewTable from "./KioskPreConsultReviewTable";
import { DATE_FORMAT } from "constants/common";
import BookAppointmentReuse from "components/PreConsult/BookAppointmentReuse";
import AppinmentBookedModal from "components/PreConsult/AppinmentBookedModal";
// import {DATE_FORMAT} from "constants/common";
// import { localStore } from "../../reducers/localStore";
const sortingParams = [
  "patient_name",
  "corporate_name",
  "date_of_birth",
  "order_id",
  "created_at",
];
const statusArray = [
  "finished_oos",
  "finished",
  "patient_arrived",
  "patient_not_arrived",
  "cancelled",
];
const slotoptions = [
  {
    label: "Slots View",
    value: "slot_view",
  },
  {
    label: "Calendar",
    value: "calendar_view",
  },
];
class KioskPreConsultationContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status:
        props.status === "kiosk_pending"
          ? "pending"
          : props.status === "kiosk_reviewed"
          ? "reviewed"
          : null,
      isAllowDelete: true,
      toBeDeleteIds: [],
      isDeleting: false,
      selectedSlotTime: null,
      selecetdDate: null,
      selectedAptType: "video",
      doctor_id: props.user.id,
      slotType:"slot_view"
    };
    ["_onPageChange"].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  // _onPageChange = (data) => {
  //   let { page } = data;
  //   // let { search } = this.props.scriptReview;
  //   let filters = JSON.parse(localStorage.getItem("intakedFilters"))
  //     ? JSON.parse(localStorage.getItem("intakedFilters"))
  //     : {};
  //   this.props.preConsultationReviewActions.onPageChange({
  //     status: this.state.status,
  //     page,
  //     ...filters,
  //     ...this.state.sort_params,
  //   });
  // };

  componentDidMount() {
    console.log("this.props.status", this.props.status);
    // if (this.props.status === "pending") {
    // this._getOutComes();
    // }
    const tabs = [
      {
        lable: "Normal",
        value: "normal",
        color_code: "#16B250",
        light_color_code: "#D0F0DC",
      },
      {
        lable: "Low BP",
        value: "low",
        color_code: "#0872FB",
        light_color_code: "#CEE3FE",
      },
      {
        lable: "Pre-Hypertension",
        value: "at_risk",
        color_code: "#b0ad6d",
        light_color_code: "#b0ad6d",
      },
      {
        lable: "Hypertension Stage 1",
        value: "high",
        color_code: "#F48918",
        light_color_code: "#FDE7D1",
      },
      {
        lable: "Hypertension Stage 2",
        value: "very_high",
        color_code: "#ED1E24",
        light_color_code: "#FBD2D3",
      },
      {
        lable: "Hypertension Stage 3",
        value: "severe",
        color_code: "#C22026",
        light_color_code: "#FFE8E1",
      },
    ];
    this.setState({
      outComes: tabs,
    });
  }

  componentWillReceiveProps(nextProps) {
    let {status} = nextProps;
    this.setState({status});
  }
  _onRefresh = () => {
    // this.props.preConsultationReviewActions.resetFilterState();
    // localStorage.removeItem("filterState");
    let {preConsultationReviewActions} = this.props;
    let {onRequest} = preConsultationReviewActions;
    if (typeof onRequest === "function") {
      let filters = JSON.parse(localStorage.getItem("filterState"))
        ? JSON.parse(localStorage.getItem("filterState"))
        : {};
      this.setState({tabKey: "All", isClearedFilters: true});
      onRequest({
        page: 1,
        ...filters,
        status:
          this.props.status === "kiosk_pending"
            ? "pending"
            : this.props.status === "kiosk_reviewed"
            ? "reviewed"
            : null,
        data_point_type: "blood_pressure",
      });
    }
    let nodes = document.querySelectorAll(".push-3-l");
    nodes.forEach((node) => {
      node.classList.remove("is_active_sorting");
      node.classList.remove("sort-applied");
    });
    sortingParams.map((param) => {
      // if (param !== sort_param) {
      this.setState({
        [`dataSorted${param}`]: 0,
        [`accending${param}`]: undefined,
      });
      // }
      return null;
    });
  };

  componentDidUpdate(prevProps, nextState) {
    if (prevProps.prev_appt_tab_key !== this.props.prev_appt_tab_key) {
      this.setState({tabKey: "All"});
    }
    if (nextState.status !== this.state.status) {
      // this.props.preConsultationReviewActions.onRequest({
      //   page: 1,
      //   status: this.state.status,
      //   // ...filters,
      // });
      let nodes = document.querySelectorAll(".push-3-l");
      nodes.forEach((node) => {
        node.classList.remove("is_active_sorting");
        node.classList.remove("sort-applied");
      });
      sortingParams.map((param) => {
        // if (param !== sort_param) {
        this.setState({
          [`dataSorted${param}`]: 0,
          [`accending${param}`]: undefined,
        });
        // }
        return null;
      });
    }
  }
  saveFilters = (filters) => {
    localStorage.setItem("intakedFilters", JSON.stringify(filters));
  };

  componentWillUnmount() {
    // localStorage.removeItem("intakedFilters");
  }
  _handleNavigation = (data, fromUnderReview) => {
    if (fromUnderReview) {
      this.setState({
        isVisible: true,
        patientDetails: {
          ...data.patient,
          first_name: data?.patient?.name?.split(" ")[0],
          last_name: data?.patient?.name?.split(" ")[1],
          date_of_birth: data?.patient?.dob,
          questionnaire_token: data?.token,
        },
        health_scan_log_id: data?.id,
        hypertensive: data?.hypertensive,
        systolic_bp: data?.systolic_bp,
        diastolic_bp: data?.diastolic_bp,
        screened_bp_values: {
          systolic_bp: data?.systolic_bp,
          diastolic_bp: data?.diastolic_bp,
          pulse_per_minute: data?.pulse_per_minute,
        },
        pulse_per_minute: data?.pulse_per_minute,

        // selecteddHealthCondition: data?.health_condition?.health_condition,
        // corporate_id: data?.corporate_organization?.corporate_organization?.id,
      });
    } else {
      let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
      let {onSetPatient} = this.props.onSetPatient;
      let on_set_patient_id =
        data && data.patient
          ? data.patient.id
          : data.appointment_obj
          ? data.appointment_obj.patient_id
          : null;
      onSetPatient({id: on_set_patient_id});

      onSetPatientAppointment(data.appointment_obj);

      // let condition_links = verifyObject(
      //   data,
      //   "health_condition.health_condition.condition_links",
      //   []
      // );
      // let { onSetDynamicInput } = this.props.dynamicTabAction;

      // let Inputs = {
      //   ...this.props.dynamicInputs,
      //   condition_links: condition_links,
      // };
      // if (typeof onSetDynamicInput === "function") {
      //   onSetDynamicInput(Inputs);
      // }
      let {navigateTo} = this.props.navigateTo;
      if (typeof navigateTo === "function") {
        console.log("🚀 ~ KioskPreConsultationContainer ~ data:", data);

        let routeName;
        routeName = `${appRoutesConst.patientConsultationDetail}?preConsultReviewed=true&pre_consult_id=${data.id}`;
        store.dispatch({
          type: "PREV_APPT_TAB_KEY",
          payload: "kiosk_reviewed",
        });
        console.log(
          "🚀 ~ KioskPreConsultationContainer ~ routeName:",
          routeName
        );

        if (routeName) {
          navigateTo(routeName);
        }
      }
    }

    // if (typeof onSetPatient === "function") {
    //   onSetPatient({id: patient_id});
    // }

    // if (typeof onSetPatientAppointment === "function") {
    //   if (fromUnderReview === "pending") {
    //     if (
    //       verifyObject(data, "patient.gp_service.id") &&
    //       verifyObject(data, "appointment_obj.id")
    //     ) {
    //       store.dispatch({
    //         type: "SET_IS_GP_INFORMED",
    //         payload: true,
    //       });
    //     }
    //     onSetPatientAppointment(data);
    //   } else {
    //     onSetPatientAppointment(data.appointment_obj);

    //     let condition_links = verifyObject(
    //       data,
    //       "health_condition.health_condition.condition_links",
    //       []
    //     );
    //     let {onSetDynamicInput} = this.props.dynamicTabAction;

    //     let Inputs = {
    //       ...this.props.dynamicInputs,
    //       condition_links: condition_links,
    //     };
    //     if (typeof onSetDynamicInput === "function") {
    //       onSetDynamicInput(Inputs);
    //     }
    //   }
    // }

    // if (typeof navigateTo === "function") {
    //   let routeName;

    //   if (fromUnderReview === "pending") {
    //     store.dispatch({type: "PREV_APPT_TAB_KEY", payload: "pending"});
    //     store.dispatch({
    //       type: "SET_POST_GT_QUESTIONNAIRE",
    //       payload:
    //         data?.health_condition?.health_condition
    //           ?.post_gateway_questionnaire,
    //     });

    //     console.log(
    //       ` data?.health_condition?.health_condition
    //     ?.questionnaire_templates`,
    //       data?.health_condition?.health_condition?.questionnaire_templates
    //     );
    //     routeName = `${appRoutesConst.patientConsultationDetail}?preConsultReview=true&pre_consult_id=${data.id}&consentShared=${data?.consent_shared}`;
    //   } else {
    //     routeName = `${appRoutesConst.patientConsultationDetail}?preConsultReviewed=true&pre_consult_id=${data.id}`;
    //     store.dispatch({
    //       type: "PREV_APPT_TAB_KEY",
    //       payload: "pre_consult_reviewed",
    //     });
    //   }
    //   if (routeName) {
    //     navigateTo(routeName);
    //   }
    // }
  };
  saveFilters = (filters) => {
    // console.log("Saved filters", filters);

    localStorage.setItem("previousFilters", JSON.stringify(filters));
  };
  handleSelectChange = (value, key) => {
    this.setState({[key]: value}, () => {
      if (
        this.state.doctor_id &&
        this.state.selecetdDate &&
        this.state.selectedAptType
      ) {
        this.onGetTimeSlots();
      }
    });
  };
  _onPageChange(data) {
    let filters = JSON.parse(localStorage.getItem("filterState"))
      ? JSON.parse(localStorage.getItem("filterState"))
      : {};
    let {
      preConsultationReviewActions: {onPageChange, onRequest},
      // preConsultationReview: {search, filters},
    } = this.props;
    const {page} = data;
    console.log("filters", filters);
    let filersParams = filters;
    let searchParam = {search: filters.search};
    // delete filersParams["page"];
    if (isEmpty(filters.search)) {
      delete filersParams["search"];
      searchParam = {};
    }
    let searchOutComeParams = {};
    if (this.state.tabKey !== "All") {
      searchOutComeParams = {
        bp_ranges: this.state.tabKey,
      };
    }
    if (typeof onPageChange === "function") {
      console.log("Page", page);
      onRequest({
        status: this.props.status === "kiosk_pending" ? "pending" : "reviewed",
        ...searchParam,
        ...filersParams,
        ...this.state.sort_params,
        page: page,
        data_point_type: "blood_pressure",
        ...searchOutComeParams,
      });
      this.setState({
        toBeDeleteIds: [],
      });
    }
  }
  // componentWillUnmount() {
  //   localStorage.removeItem("previousFilters");
  // }
  // sortRecordBy = (sort_param, id) => {
  //   this.setState(
  //     {
  //       [`accending${sort_param}`]:
  //         this.state[`accending${sort_param}`] === undefined
  //           ? true
  //           : !this.state[`accending${sort_param}`],
  //       [`dataSorted${sort_param}`]:
  //         (this.state[`dataSorted${sort_param}`]
  //           ? this.state[`dataSorted${sort_param}`]
  //           : 0) + 1,
  //     },
  //     () => {
  //       let filters = JSON.parse(localStorage.getItem("previousFilters"))
  //         ? JSON.parse(localStorage.getItem("previousFilters"))
  //         : {};
  //       let {
  //         preConsultationReviewActions: {onRequest},
  //         preConsultationReview: {search},
  //       } = this.props;
  //       let sort_params = {
  //         [`sort[${sort_param}]`]: this.state[`accending${sort_param}`]
  //           ? "ASC"
  //           : "DESC",
  //       };

  //       this.setState({sort_params}, () => {
  //         onRequest({
  //           ...filters,
  //           ...this.state.sort_params,
  //           search,
  //           page: 1,
  //           data_point_type: "blood_pressure",
  //           // status: this.state.status,
  //           // search,
  //         });
  //         let element = document.getElementById(id);
  //         element.classList.add("is_active_sorting");

  //         if (this.state[`dataSorted${sort_param}`] > 1) {
  //           let dco = document.getElementById(id);
  //           if (dco) {
  //             if (dco.classList.contains("sort-applied")) {
  //               dco.classList.remove("sort-applied");
  //             } else {
  //               console.log("SORT", this.state.accending);
  //               // if (this.state.accending) {
  //               dco.classList.add("sort-applied");
  //               // }
  //             }
  //           }

  //           // this.state["dataSorted"]
  //         }
  //         sortingParams.map((param) => {
  //           if (param !== sort_param) {
  //             this.setState({
  //               [`dataSorted${param}`]: 0,
  //               [`accending${param}`]: undefined,
  //             });
  //           }
  //           return null;
  //         });

  //         let nodes = document.querySelectorAll(".push-3-l");
  //         nodes.forEach((node) => {
  //           if (node.classList.contains("sort-applied") && node.id !== id) {
  //             node.classList.remove("sort-applied");
  //           }
  //           if (
  //             node.classList.contains("is_active_sorting") &&
  //             node.id !== id
  //           ) {
  //             node.classList.remove("is_active_sorting");
  //           }
  //         });
  //       });
  //     }
  //   );
  // };

  sortRecordBy = (sort_param, id) => {
    this.setState(
      {
        [`accending${sort_param}`]:
          this.state[`accending${sort_param}`] === undefined
            ? true
            : !this.state[`accending${sort_param}`],
        [`dataSorted${sort_param}`]:
          (this.state[`dataSorted${sort_param}`]
            ? this.state[`dataSorted${sort_param}`]
            : 0) + 1,
      },
      () => {
        let filters = JSON.parse(localStorage.getItem("filterState"))
          ? JSON.parse(localStorage.getItem("filterState"))
          : {};
        let {preConsultationReviewActions} = this.props;
        let {onRequest} = preConsultationReviewActions;
        let sort_params = {
          [`sort[${sort_param}]`]: this.state[`accending${sort_param}`]
            ? "ASC"
            : "DESC",
        };

        this.setState({sort_params}, () => {
          onRequest({
            ...filters,
            ...this.state.sort_params,
            page: 1,
            status: this.state.status,
            data_point_type: "blood_pressure",
            // search,
          });
          let element = document.getElementById(id);
          element.classList.add("is_active_sorting");

          if (this.state[`dataSorted${sort_param}`] > 1) {
            let dco = document.getElementById(id);
            if (dco) {
              if (dco.classList.contains("sort-applied")) {
                dco.classList.remove("sort-applied");
              } else {
                console.log("SORT", this.state.accending);
                // if (this.state.accending) {
                dco.classList.add("sort-applied");
                // }
              }
            }

            // this.state["dataSorted"]
          }
          sortingParams.map((param) => {
            if (param !== sort_param) {
              this.setState({
                [`dataSorted${param}`]: 0,
                [`accending${param}`]: undefined,
              });
            }
            return null;
          });

          let nodes = document.querySelectorAll(".push-3-l");
          nodes.forEach((node) => {
            if (node.classList.contains("sort-applied") && node.id !== id) {
              node.classList.remove("sort-applied");
            }
            if (
              node.classList.contains("is_active_sorting") &&
              node.id !== id
            ) {
              node.classList.remove("is_active_sorting");
            }
          });
        });
      }
    );
  };
  handleTabChange = (key) => {
    this.setState(
      {
        tabKey: key,
      },
      () => {
        console.log("PRE CONSULT REQ ON TAB CHANGE--1", key);
        this.props.preConsultationReviewActions.resetFilterState();
        let filters = JSON.parse(localStorage.getItem("previousFilters"))
          ? JSON.parse(localStorage.getItem("previousFilters"))
          : {};
        console.log("🚀 ~ PreConsultationReviewContainer ~ key:", key);
        if (key === "All") {
          console.log("PRE CONSULT REQ ON TAB CHANGE--2");

          this.props.preConsultationReviewActions.onFilterChange({
            filters: {
              ...filters,
              status:
                this.props.status === "kiosk_pending" ? "pending" : "reviewed",
              data_point_type: "blood_pressure",
            },
          });
        } else {
          console.log("PRE CONSULT REQ ON TAB CHANGE--3");

          this.props.preConsultationReviewActions.onFilterChange({
            filters: {
              ...filters,
              bp_ranges: this.state.tabKey,
              status:
                this.props.status === "kiosk_pending" ? "pending" : "reviewed",
              data_point_type: "blood_pressure",
            },
          });
        }
      }
    );
  };

  // _handleNavigation(data, fromUnderReview) {
  //   let { onSetPatient } = this.props.onSetPatient;
  //   let { onSetPatientAppointment } = this.props.onSetPatientAppointment;
  //   let { navigateTo } = this.props.navigateTo;
  //   let { patient_id } = data;

  //   if (typeof onSetPatient === "function") {
  //     onSetPatient({ id: patient_id });
  //   }
  //   store.dispatch({
  //     type: "IS_CLICKED_VIEW",
  //     payload: true,
  //   });

  //   if (typeof onSetPatientAppointment === "function") {
  //     onSetPatientAppointment(data);
  //   }

  //   if (typeof navigateTo === "function") {
  //     let routeName = appRoutesConst.patientConsultationDetail;
  //     if (fromUnderReview) {
  //       routeName = `${appRoutesConst.patientConsultationDetail}?isReview=true`;
  //     }
  //     navigateTo(routeName);
  //   }
  // }
  // sortRecordBy = async (sort_param, id) => {
  //   this.setState({ accending: !this.state.accending });
  //   let filters = JSON.parse(localStorage.getItem("intakedFilters"))
  //     ? JSON.parse(localStorage.getItem("intakedFilters"))
  //     : {};
  //   let { preConsultationReviewActions } = this.props;
  //   let { onRequest } = preConsultationReviewActions;
  //   let sort_params = {
  //     [`sort[${sort_param}]`]: this.state.accending ? "ASC" : "DESC",
  //   };
  //   this.setState({ sort_params }, () => {
  //     onRequest({
  //       ...filters,
  //       ...this.state.sort_params,
  //       status: this.state.status,
  //       // search,
  //     });
  //     let dco = document.getElementById(id);
  //     if (dco) {
  //       if (dco.classList.contains("sort-applied")) {
  //         dco.classList.remove("sort-applied");
  //       } else {
  //         dco.classList.add("sort-applied");
  //       }
  //     }
  //     let nodes = document.querySelectorAll(".push-3-l");
  //     nodes.forEach((node) => {
  //       if (node.classList.contains("sort-applied") && node.id !== id) {
  //         node.classList.remove("sort-applied");
  //       }
  //     });
  //     // this.setState({
  //     //   accending: true,
  //     // });
  //   });
  // };

  _handleAppointment = (data) => {
    console.log("Book Appointment Called ", data);
    this.setState({
      visibleAppointmentModal: true,
      selectedPatientId: verifyObject(data, "patient.id", null),
      selectedpreConsultID: data?.id,
      newPatientDetail: verifyObject(data, "patient", null),
      health_scan_log_id: data?.id,
      newSelecteddHealthCondition: data,
    });
  };
  handleDatepicker = async (date, dateString) => {
    // let isoDate = new Date(date).toISOString();
    let errors = null;
    errors = Object.assign("", this.state.errors);
    delete errors["date"];
    if (date) {
      this.setState(
        {
          date: moment.utc(dateString),
          errors: errors,
          selecetdDate: date,
        },
        () => {
          this.onGetTimeSlots();
        }
      );
    } else {
      this.setState({date: null, selecetdDate: null});
    }
  };
  onGetTimeSlots = async () => {
    // alert(this.state.doctor_id)
    //
    const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
    let {user} = this.props;
    try {
      await this.setState({
        loadingSlots: true,
        timeSlots: [],
        slotDuration: [],
      });
      let docIdParams = {
        doctor_id: this.state.doctor_id,
      };
      if (this.state.doctor_id === "all") {
        docIdParams = {};
      }
      let patientIdParams = {};

      if (this.state.selectedPatientId) {
        patientIdParams = {
          patient_id: this.state.selectedPatientId,
        };
      }
      let response =
        this.state.doctor_id === "all"
          ? await getTimeSlotsForCorp({
              // date: this.state.selecetdDate,
              // doctor_id: user.id,
              // zone: time_zone,
              // ...docIdParams,
              session_type:
                this.state.selectedAptType === "video"
                  ? "online_appointment"
                  : "offline_appointment",
              date: moment(this.state.selecetdDate).format(DATE_FORMAT),
              corporate_id: user?.corporate_id,
              ...patientIdParams,
              // session_type: this.state.selectedAptType,
            })
          : await getTimeSlotsForDoctor({
              date: this.state.selecetdDate,
              // doctor_id: user.id,
              zone: time_zone,
              ...docIdParams,
              slot_for:
                this.state.selectedAptType === "video"
                  ? "online_appointment"
                  : "offline_appointment",
              ...patientIdParams,
              // date: moment(this.state.selecetdDate).format(DATE_FORMAT),
              // corporate_id: user?.corporate_id ,
              // session_type: this.state.selectedAptType,
            });
      console.log("Slot Response", response);
      if (response.data.common && response.data.common.slot_durations) {
        let slotDuration = response.data.common.slot_durations.split(",");
        console.log("slotDuration", slotDuration);
        this.setState({
          slotDuration: slotDuration,
        });
      }
      if (response.data.data) {
        const transformedArray = verifyObject(response, "data.data", []).map(
          (item) => {
            const label = moment(item.start_time).format("hh:mm A");
            const value = [item.start_time, item.end_time];

            return {label, value};
          }
        );
        this.setState({timeSlots: transformedArray});
      }
      this.setState({loadingSlots: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loadingSlots: false});
      errorToast({content: message});
    }
  };

  // onGetTimeSlots = async () => {
  //   const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
  //   let { user } = this.props;
  //   try {
  //     await this.setState({
  //       loadingSlots: true,
  //       timeSlots: [],
  //       slotDuration: [],
  //     });
  //     let docIdParams = {
  //       doctor_id: user.id,
  //     };
  //     if (this.state.doctor_id === "all") {
  //       docIdParams = {};
  //     }
  //     let response =
  //       this.state.doctor_id === "all"
  //         ? await getTimeSlotsForCorp({
  //             // date: this.state.selecetdDate,
  //             // doctor_id: user.id,
  //             // zone: time_zone,
  //             // ...docIdParams,
  //             session_type:
  //               this.state.selectedAptType === "video"
  //                 ? "online_appointment"
  //                 : "offline_appointment",
  //             date: moment(this.state.selecetdDate).format(DATE_FORMAT),
  //             corporate_id: user?.corporate_id,
  //             // session_type: this.state.selectedAptType,
  //           })
  //         : await getTimeSlotsForDoctor({
  //             date: this.state.selecetdDate,
  //             // doctor_id: user.id,
  //             zone: time_zone,
  //             ...docIdParams,
  //             slot_for:
  //               this.state.selectedAptType === "video"
  //                 ? "online_appointment"
  //                 : "offline_appointment",
  //             // date: moment(this.state.selecetdDate).format(DATE_FORMAT),
  //             // corporate_id: user?.corporate_id ,
  //             // session_type: this.state.selectedAptType,
  //           });
  //     console.log("Slot Response", response);
  //     if (response.data.common && response.data.common.slot_durations) {
  //       let slotDuration = response.data.common.slot_durations.split(",");
  //       console.log("slotDuration", slotDuration);
  //       this.setState({
  //         slotDuration: slotDuration,
  //       });
  //     }
  //     if (response.data.data) {
  //       const transformedArray = verifyObject(response, "data.data", []).map(
  //         (item) => {
  //           const label = moment(item.start_time).format("hh:mm A");
  //           const value = [item.start_time, item.end_time];

  //           return { label, value };
  //         }
  //       );
  //       this.setState({ timeSlots: transformedArray });
  //     }
  //     this.setState({ loadingSlots: false });
  //   } catch (error) {
  //     const { message } = getErrorObject(error);
  //     await this.setState({ loadingSlots: false });
  //     errorToast({ content: message });
  //   }
  // };
  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day").subtract("day", 1);
  };

  handleCloseModal = () => {
    this.setState({
      visibleAppointmentModal: false,
      selecetdDate: null,
      selectedAptType: "video",
      selectedSlotTime: null,
      doctor_id: this.props.user.id,
    });
  };

  onChangeSlots = ({target: {value}}) => {
    console.log(
      "radio3 checked",
      value,
      moment(value).add(15, "minutes").format()
    );
    // setSlotTime(value);
    this.setState({
      selectedSlotTime: value,
    });
  };
  handleAptTypeChange = ({target: {value}}) => {
    this.setState(
      {
        selectedAptType: value,
      },
      () => {
        if (this.state.selecetdDate) {
          this.onGetTimeSlots();
        }
      }
    );
  };
  handleAppointmentSubmission = async () => {
    const {user} = this.props;
    const {
      selectedSlotTime,
      selectedPatientId,
      selectedpreConsultID,
      selectedAptType,
      doctor_id,
    } = this.state;
    console.log("Slot Duration", this.state.slotDuration);
    console.log("Slot selectedSlotTime", this.state.selectedSlotTime);
    console.log("Slot selectedDate", this.state.selecetdDate);
    console.log("Slot user id", user?.id);
    console.log("Slot selectedPatientId", this.state.selectedPatientId);
    // let payload = {
    //   // "appointment[start_time]": selectedSlotTime[0],
    //   // "appointment[end_time]": selectedSlotTime[1],
    //   // "appointment[pre_consult_id]": selectedpreConsultID,
    //   // "appointment[appointment_type]": "video",
    //   appointment: {
    //     appointment_type: "video",
    //     // discount_code: "TEST_CODE_DEC_100",
    //     doctor_id: user?.id,
    //     start_time: selectedSlotTime[0],
    //     end_time: selectedSlotTime[1],
    //     offline_appointment: false,
    //     patient_id: selectedPatientId,
    //   },
    // };
    let formData = new FormData();

    formData.set(`appointment[start_time]`, selectedSlotTime[0]);
    formData.set(`appointment[end_time]`, selectedSlotTime[1]);
    formData.set(`book_via`, selectedAptType === "video" ? "ad_hoc" : "slot");

    if (
      this.props.status === "kiosk_pending" ||
      this.props.status === "kiosk_reviewed"
    ) {
      // For kiosk pre consult selectedpreConsultID is health scan log id
      formData.set(`appointment[health_scan_log_id]`, selectedpreConsultID);
    } else {
      formData.set(`appointment[pre_consult_id]`, selectedpreConsultID);
    }
    formData.set(`appointment[appointment_type]`, selectedAptType);
    formData.set(`duration`, 15);
    formData.set(`corporate_id`, user?.corporate_id);

    // formData.set(`appointment[doc_preference]`, "");
    // formData.set(`appointment[comments]`, "");
    // formData.set(`appointment[file]`, "");
    formData.set(`book_from_dp`, true);
    formData.set(`patient_id`, selectedPatientId);
    if (doctor_id !== "all") {
      formData.set(`doctor_id`, doctor_id);
    }

    // --form 'appointment[doc_preference]=""' \
    // --form 'appointment[comments]=""' \
    // --form 'appointment_file[file]=""' \
    // --form 'appointment_access[pre_consult_id]="1330"' \
    // --form 'corporate_id="66"' \
    // --form 'book_from_dp="true"' \
    // --form 'patient_id="12028"'
    try {
      await this.setState({adding: true});
      let response = await bookAppointmentFromPatientAPI(formData);
      if (response.status === 200) {
        if (response.data.message) {
          await this.setState({adding: false});
          successToast({content: response.data.message});
          if (
            typeof this.props.preConsultationReviewActions.onFilterChange ===
            "function"
          ) {
            let filters = JSON.parse(localStorage.getItem("previousFilters"))
              ? JSON.parse(localStorage.getItem("previousFilters"))
              : {};
            if (this.state.tabKey === "All") {
              this.props.preConsultationReviewActions.onFilterChange({
                filters: {
                  ...filters,
                  status:
                    this.state.status === "kiosk_pending"
                      ? "pending"
                      : "reviewed",
                  page: 1,
                  data_point_type: "blood_pressure",
                },
              });
            } else {
              this.props.preConsultationReviewActions.onFilterChange({
                filters: {
                  ...filters,
                  bp_ranges: this.state.tabKey,
                  status:
                    this.state.status === "kiosk_pending"
                      ? "pending"
                      : "reviewed",
                  page: 1,
                  data_point_type: "blood_pressure",
                },
              });
            }
          }
          this.handleCloseModal();
        }
      }
      await this.setState({adding: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({adding: false});
      errorToast({content: message});
    }
    // let formData = new FormData();

    // selectedSlotTime &&
    //   formData.set(`appointment[start_time]`, selectedSlotTime[0]);
    // selectedSlotTime &&
    //   formData.set(`appointment[end_time]`, selectedSlotTime[1]);
    // pre_consult_id &&
    //   formData.set(`appointment[pre_consult_id]`, pre_consult_id);

    // formData.set(`appointment[appointment_type]`, "video");
  };
  handleDeleteCheckbox = (toBeDeleteIds) => {
    this.setState({toBeDeleteIds}, () => {});
  };
  handleDelete = async () => {
    //pre_consult_ids

    this.setState(
      {
        isDeleting: true,
      },
      async () => {
        try {
          let response = await deletePreConsult({
            record_ids: this.state.toBeDeleteIds?.toString(),
            record_type: "health_scan_log",
          });
          console.log("response deletee", response);
          if (response?.data?.status === 200) {
            this.setState(
              {
                // isAllowDelete: false,
                isDeleting: false,
                toBeDeleteIds: [],
              },
              () => {
                // this._onRefresh();
                console.log("PRE CONSULT REQ ON DELETE--");

                this.props.preConsultationReviewActions.resetFilterState();
                let filters = JSON.parse(
                  localStorage.getItem("previousFilters")
                )
                  ? JSON.parse(localStorage.getItem("previousFilters"))
                  : {};
                console.log(
                  "🚀 ~ PreConsultationReviewContainer ~ key:",
                  this.state.tabKey
                );
                if (this.state.tabKey === "All") {
                  console.log("PRE CONSULT REQ ON DELETE--2");

                  this.props.preConsultationReviewActions.onFilterChange({
                    filters: {
                      ...filters,
                      status:
                        this.props.status === "kiosk_pending"
                          ? "pending"
                          : this.props.status === "kiosk_reviewed"
                          ? "reviewed"
                          : null,
                      page: 1,
                      data_point_type: "blood_pressure",
                    },
                  });
                } else {
                  console.log("PRE CONSULT REQ ON DELETE--3");

                  this.props.preConsultationReviewActions.onFilterChange({
                    filters: {
                      bp_ranges: this.state.tabKey,
                      ...filters,
                      status:
                        this.props.status === "kiosk_pending"
                          ? "pending"
                          : this.props.status === "kiosk_reviewed"
                          ? "reviewed"
                          : null,
                      page: 1,
                      data_point_type: "blood_pressure",
                    },
                  });
                }
              }
            );
          }
        } catch (e) {
          const {message} = getErrorObject(e);
          this.setState({
            isDeleting: false,
          });
          errorToast({
            content: message,
          });
        }
      }
    );
  };

  // const onCheckAll = (e) => {
  //   let checked = e.target.checked;
  //   if (checked) {
  //     let ids = props.ids;
  //     setCheckboxIds(ids);
  //   } else {
  //     setCheckboxIds([]);
  //   }
  // };

  // const handleCheckBox = (e, id) => {
  //   if (e.target.checked) {
  //     setCheckboxIds([...chekdIds, id]);
  //   } else {
  //     let newArray = chekdIds.filter((d) => d !== id);
  //     setCheckboxIds(newArray);
  //   }
  // };

  render() {
    console.log("this.state.isVisible", this.state.isVisible);

    let {
      preConsultationReview: {data, ids, isLoading},
    } = this.props;
    return (
      <Fragment>
        <div className="form-row push-20-b push-10-t">
          <div className="col-md-4">
            <a
              onClick={this._onRefresh}
              className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8"
            >
              <img alt="" src={refresh} className="height-15"></img>{" "}
              <span className="push-2-l font-14 weight-400">Refresh</span>
            </a>
            {/* {this.props.status === "pending" &&
              this.state.toBeDeleteIds?.length === 0 && (
                <Checkbox
                  onChange={(e) => {
                    this.setState({
                      isAllowDelete: e.target.checked,
                    });
                  }}
                  defaultChecked={this.state.isAllowDelete}
                  className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8 ml-3"
                >
                  <span className="push-2-l font-14 weight-400">
                    {!this.state.isAllowDelete ? "Show" : "Hide"} Delete
                  </span>
                </Checkbox>
              )} */}

            {this.state.toBeDeleteIds?.length > 0 && (
              <Fragment>
                {this.state.isDeleting ? (
                  <a className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8 ml-3">
                    <img alt="" src={deleteimg} className="height-15"></img>{" "}
                    <span className="push-2-l font-14 weight-400">
                      Deleting ....
                    </span>
                  </a>
                ) : (
                  <a
                    onClick={this.handleDelete}
                    className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8 ml-3"
                  >
                    <img alt="" src={deleteimg} className="height-15"></img>{" "}
                    <span className="push-2-l font-14 weight-400">
                      {this.state.isDeleting ? "Deleting..." : "Delete"}
                    </span>
                  </a>
                )}
              </Fragment>
            )}
          </div>

          <div className="col-md-8">
            <FilterComponent
              isClearedFilters={this.state.isClearedFilters}
              isLoading={isLoading}
              isBooked={this.state.isBooked}
              defaultRequest={{
                status:
                  this.props.status === "kiosk_pending"
                    ? "pending"
                    : this.props.status === "kiosk_reviewed"
                    ? "reviewed"
                    : null,
                data_point_type: "blood_pressure",
                ...this.state.sort_params,
              }}
              onFilterChange={(prams) => {
                this.setState({tabKey: "All"}, () => {
                  console.log("PRE CONSULT REQ ON FILTER CHANGE");

                  this.props.preConsultationReviewActions.onFilterChange(prams);
                });
              }}

              preConsultStatus={this.props.status}
              resetFilters={() => {
                console.log("PRE CONSULT REQ ON FILTER RESET");

                this.props.preConsultationReviewActions.resetFilterState();
              }}
              filterArray={kioskPreConsultationFilters}
              saveFilters={this.saveFilters}
              selectedFilters={
                JSON.parse(localStorage.getItem("filterState"))
                  ? {
                      ...JSON.parse(localStorage.getItem("filterState")),
                    }
                  : {}
              }
            />
          </div>
        </div>

        <div className="push-20-b push-10-t preconsulttabwrap">
          <Tabs
            onChange={this.handleTabChange}
            defaultActiveKey="All"
            activeKey={this.state.tabKey}
            destroyInactiveTabPane={true}
          >
            <Tabs.TabPane
              tab={
                <div className="tabsbox firsttab">
                  <span
                    style={{
                      // backgroundColor: d.color_code,
                      // color: d.color_code,
                      display: "block",
                      height: "30px",
                      padding: "4px 10px",
                      // borderWidth: "1px",
                      // borderStyle: "solid",
                      // borderColor: "#1445b7",
                    }}
                    className="icons_wrapper"
                  >
                    <span className="icons_wrapper_img_box"></span>
                    <span
                      style={{
                        // color: d.color_code,
                        textTransform: "capitalize",
                        // color: "white",
                      }}
                      className="icons_wrapper_title"
                    >
                      All
                    </span>
                  </span>
                </div>
              }
              key={"All"}
              // disabled={isLoading}
            >
              <KioskPreConsultReviewTable
                statusArray={statusArray}
                data={data}
                isLoading={isLoading}
                ids={ids}
                sortRecordBy={this.sortRecordBy}
                _handleNavigation={this._handleNavigation}
                toggleThreeDots={this.toggleThreeDots}
                handleOOSAppointment={this.handleOOSAppointment}
                endAppointment={this.endAppointment}
                {...this.state}
                {...this.props}
                fromUnderReview={true}
                _handleAppointment={this._handleAppointment}
                isAllowDelete={this.state.isAllowDelete}
                handleDeleteCheckbox={this.handleDeleteCheckbox}
                toBeDeleteIds={this.state.toBeDeleteIds}
              />
            </Tabs.TabPane>
            {this.state?.outComes?.map((d) => {
              return (
                <Tabs.TabPane
                  tab={
                    <div className="tabsbox">
                      <span
                        style={{
                          // backgroundColor: d.color_code,
                          color: d.color_code,
                          display: "block",
                          height: "30px",
                          padding: "4px 10px",
                          borderColor: d.color_code,
                          backgroundColor:
                            this.state.tabKey === d.value ? d.color_code : "",
                        }}
                        className="icons_wrapper"
                      >
                        <span className="icons_wrapper_img_box"></span>
                        <span
                          style={{
                            color: d.color_code,
                            textTransform: "capitalize",
                            // color: "white",
                            fontWeight: 600,
                          }}
                          className="icons_wrapper_title"
                        >
                          {d?.lable}
                        </span>
                      </span>
                    </div>
                  }
                  key={d.value}
                  // disabled={isLoading}
                >
                  <KioskPreConsultReviewTable
                    statusArray={statusArray}
                    data={data}
                    isLoading={isLoading}
                    ids={ids}
                    sortRecordBy={this.sortRecordBy}
                    _handleNavigation={this._handleNavigation}
                    toggleThreeDots={this.toggleThreeDots}
                    handleOOSAppointment={this.handleOOSAppointment}
                    endAppointment={this.endAppointment}
                    {...this.state}
                    {...this.props}
                    fromUnderReview={true}
                    _handleAppointment={this._handleAppointment}
                    isAllowDelete={this.state.isAllowDelete}
                    handleDeleteCheckbox={this.handleDeleteCheckbox}
                    toBeDeleteIds={this.state.toBeDeleteIds}
                  />
                </Tabs.TabPane>
              );
            })}
          </Tabs>
          {!isLoading && (
            <div className="col-md-12">
              <Pagination
                data={this.props.preConsultationReview}
                onPageChange={this._onPageChange}
              />
            </div>
          )}
          {/* {this.state.visibleAppointmentModal && (
            <PreConsultBookAppointmentModal
              disabledDate={this.disabledDate}
              handleCloseModal={this.handleCloseModal}
              handleDatepicker={this.handleDatepicker}
              selecetdDate={this.state.selecetdDate}
              handleSubmission={this.handleAppointmentSubmission}
              visible={this.state.visibleAppointmentModal}
              isFetchingSlot={this.state.loadingSlots}
              slotsArray={this.state.timeSlots}
              onChangeSlots={this.onChangeSlots}
              selectedSlotTime={this.state.selectedSlotTime}
              patient_id={this.state?.patientDetails?.id}
              bookingAppointment={this.state.adding}
              handleAptTypeChange={this.handleAptTypeChange}
              selectedAptType={this.state.selectedAptType}
              health_scan_log_id={this.state.health_scan_log_id}
              doctor_id={this.state.doctor_id || null}
              handleSelectChange={this.handleSelectChange}
            />
          )}
              {this.state.visibleAppointmentModal && ( */}
          {this.state.visibleAppointmentModal && (
            <Modal
              // onClose={this.handleCloseModal}
              visible={this.state.visibleAppointmentModal}
              width={this.state.isFullscreen ? "100%":1149}
              footer={false}
              className={`pre_questionairepopup reviewpopup ${
                this.state?.isFullscreen && "full-height-modal"
              }`}
              closable={false}
            >
              <div className="PreConsultationReviewContainer_Wrapper BookAppointment_Flow">
                <div className="d-flex align-items-center justify-content-between bookingapthead 1">
                  {/* <h3>Book Appointment</h3> */}
                  <Button
                    type="link"
                    className="border-none min-w-inherit p-0 backbtn"
                    onClick={() => {
                      if (this.state.slotType === "calendar_view") {
                        this.setState({
                          slotType: "slot_view",
                          isFullscreen:false
                        });
                      } else {
                        this.setState({
                          visibleAppointmentModal: false,
                        });
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M10.8084 3.17899C11.0639 3.41764 11.0639 3.80458 10.8084 4.04323L6.5 8.06788C6.24452 8.30653 6.24452 8.69347 6.5 8.93212L10.8084 12.9568C11.0639 13.1954 11.0639 13.5824 10.8084 13.821C10.5529 14.0597 10.1387 14.0597 9.88322 13.821L5.57483 9.79636C4.80839 9.0804 4.80839 7.9196 5.57483 7.20364L9.88322 3.17899C10.1387 2.94034 10.5529 2.94034 10.8084 3.17899Z"
                        fill="#808396"
                      />
                    </svg>{" "}
                    Back
                  </Button>
                  <div className="bookingoptionradiogrup">
                    <Radio.Group
                      options={slotoptions}
                      onChange={({ target: { value } }) => {
                        this.setState({
                          slotType: value,
                        });
                      }}
                      value={this.state.slotType}
                      optionType="button"
                      buttonStyle="solid"
                      disabled={this.state?.disabledToggleSlotType}
                    />
                  </div>
                </div>
                <BookAppointmentReuse
                  user={this?.props?.user}
                  patient_id={this.state?.newPatientDetail?.id}
                  doctor_id={this.state.doctor_id}
                  pre_consult_id={this.state.pre_consult_id}
                  handleCloseModal={(isBooked, dateAndTime) => {
                    // setIsShowBookingAppt(false);
                    this.setState({
                      isBooked: isBooked,
                      dateAndTime: dateAndTime,
                    });
                  }}
                  health_scan_log_id={this.state.health_scan_log_id}
                  handleCancelBookingAppt={this.handleCloseModal}
                  handleCloseModal2={this.handleCloseModal}
                  slotType={this.state.slotType}
                  preConsultationReviewActions={preConsultationReviewActions}
                  handleToggle={(isDisabled) => {
                    this.setState({
                      disabledToggleSlotType: isDisabled,
                    });
                  }}
                  changeSlotType={() => {
                    this.setState({
                      slotType: "slot_view",
                    });
                  }}
                  statusParam={this.props.status}
                  fromReviewPreConsult={true}
                  refreshPreConsultList={this._onRefresh}
                  newSelecteddHealthCondition={
                    this.state.newSelecteddHealthCondition
                  }
                  newPatientDetail={this.state.newPatientDetail}
                  isFullscreen={this.state.isFullscreen}
                  toggleFullScreen={ ()=>{
                    this.setState({
                      isFullscreen:!this.state.isFullscreen,
                    },()=>{
                      let elem = document.querySelector(".fc-view-harness-active");
                      if (elem) {
                        if (this.state.isFullscreen) {
                          elem.style.height = "100vh";
                        } else {
                          elem.style.height = "45vh";
                        }
                      }
                    });
                  }}
                />
              </div>
            </Modal>
          )}
          <AppinmentBookedModal
            isBookModalVisible={this.state.isBooked}
            selecteddHealthCondition={this.state.newSelecteddHealthCondition}
            patientDetail={this.state.newPatientDetail}
            hideModal={() => this.setState({ isBooked: false })}
            dateAndTime={this.state.dateAndTime && this.state.dateAndTime}
            fromKiosk={true}
          />
          {this.state.isVisible && (
            <PreConsultNotification
              patient_id={this.state?.patientDetails?.id}
              corporate_id={this.state.corporate_id}
              patientDetail={this.state?.patientDetails}
              hypertensive={this.state?.hypertensive}
              patient_age={this.state?.patientDetails?.age}
              onSetPatient={this.props.onSetPatient}
              onSetPatientAppointment={this.props.onSetPatientAppointment}
              dynamicTabAction={this.props.dynamicTabAction}
              navigateTo={this.props.navigateTo}
              isVisible={this.state.isVisible}
              isFromPreConsultListing={true}
              selecteddHealthCondition={this.state?.selecteddHealthCondition}
              hideModalFromPreConsult={() => {
                this.setState({isVisible: false});
              }}
              screened_bp_values={this.state.screened_bp_values}
              systolic_bp={this.state.systolic_bp}
              diastolic_bp={this.state.diastolic_bp}
              pulse_per_minute={this.state.pulse_per_minute}
              health_scan_log_id={this.state.health_scan_log_id}
              fromReviewPreConsult={true}
              preConsultStatus={this.props.status}
              pre_consult_id={this.state.pre_consult_id}
              doctor_id={this?.props?.user?.id}
              user={this?.props?.user}
              preConsultationReviewActions={
                this.props.preConsultationReviewActions
              }
            />
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clear_pre_consult_filter: verifyObject(
      state,
      "localStore.clear_pre_consult_filter",
      ""
    ),
    user: verifyObject(state, "localStore.user", null),
    preConsultationReview: verifyObject(state, "kioskPreConsult", []),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    preConsultationReviewActions: bindActionCreators(
      preConsultationReviewActions,
      dispatch
    ),
    onSetPatientAppointment: bindActionCreators(
      {onSetPatientAppointment},
      dispatch
    ),
    onSetPatient: bindActionCreators({onSetPatient}, dispatch),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
    dynamicTabAction: bindActionCreators(
      {
        onSetDynamicInput,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(KioskPreConsultationContainer);

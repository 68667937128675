import {get, Irequest, post, patch} from "./index";

export type IReportsParams = {
  patient_id: number,
  search?: string,
  page?: number,
};
export const getQuestionnaireTemplatesApi = (params: IReportsParams) => {
  const request: Irequest = {
    subUrl: `/customer_support/questionnaire_templates/list_questionnaires.json`,
    params,
  };
  return get(request);
};

export const getTemplates = (params: IEmailTemplatesParams) => {
  const request: Irequest = {
    subUrl: `/customer_support/templates.json`,
    params,
  };
  return get(request);
};


export const getNotificationsApi = (params: IReportsParams) => {
  const request: Irequest = {
    subUrl: `/notifications.json`,
    params,
  };
  return get(request);
};
export const markAsReadNotificationApi = (params: IGetAppoinmentsParams) => {
  const request: Irequest = {
    subUrl: `/notifications/${params.id}.json`,
    data: {
      is_read: true,
    },
  };
  return patch(request);
};
export const addReportApi = (params: IGetAppoinmentsParams) => {
  const request: Irequest = {
    subUrl: `/admin/documents.json`,
    data: params,
  };
  return post(request);
};

export const fetchQRCodesAPI = (params: IReportsParams) => {
  const request: Irequest = {
    subUrl: `/corporate_health_condition_qrs/generate_corporate_condition_qrs.json`,
  };
  return post(request);
};

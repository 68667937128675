import {listingReducer} from "reducers/utils/listingReducer";
import {getPatientQuestionnaireReview} from "../services/patientQuestionnaireReview";

const {reducer, actions, types} = listingReducer({
  name: `patientQuestionnaireReview`,
  getApi: getPatientQuestionnaireReview,
});

const patientQuestionnaireReview = reducer;
export {patientQuestionnaireReview, actions, types};

import React from "react";
import Layout from "../../Layouts/PageLayout";
import VideoCallSetupContainer from "../../containers/VideoCallSetup/VideoCallSetupContainer";
class VideoCallSetupPage extends React.Component {
  render() {
    return (
      <Layout title="Right To Work" sideBarArray={[]}>
     
        <div className="rightmainsection videosetup_section">
          <div className="container-fluid right_wrapper">
            <div className="row m-0">
              <div className="col-md-1 col-xs-12 in-block"></div>
              <div className="col-md-10 col-xs-12 in-block selection_container">
                {/* <StaffIntranetContainer /> */}
                <VideoCallSetupContainer />
              </div>
              <div className="col-md-1 col-xs-12 in-block"></div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

export default VideoCallSetupPage;

import React, {Component, Fragment} from "react";
import LoginPage from "pages/Login/LoginPage";
import {appRoutesConst} from "app/navigation";
import {replace} from "react-router-redux";

import {connect} from "react-redux";
import {verifyObject} from "utilities/utils";
import {
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "constants/common";
// import { ROLE_CLINICAL_PHARMACIST } from "constants/common";
// import { ROLE_CLINICAL_PHARMACIST } from "constants/common";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    if (props.token) {
      if (
        props.userRole === ROLE_CLINICAL_PHARMACIST ||
        props.userRole === ROLE_SUPERINTENDENT_PHARMACIST
      ) {
        props.navigateToPatientSearch();
      } else {
        props.navigateToDocotorSelection();
      }
    }
  }
  render() {
    return (
      <Fragment>
        <LoginPage />
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.localStore.token,
    userRole: verifyObject(state, "localStore.user.role", null),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    navigateToDocotorSelection: () =>
      dispatch(replace(appRoutesConst.dashboard)),
    navigateToPatientSearch: () =>
      dispatch(replace(appRoutesConst.patientSearch)),
    navigateTo: () => dispatch(replace(appRoutesConst.signin)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

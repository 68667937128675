import isEmpty from "lodash/isEmpty";
// first_name: "",

//     last_name: "",
//     phone: "",
//     email: "",
//     gender: "",
//     address_name: "",
//     addres_line_1: "",
//     addres_line_2: "",
//     addres_line_3: "",
//     city: "",
//     postal_code: "",
//     weight: "",
//     height: "",
//     dob: "",
export function ValidateInput(data) {
  let errors = {};
  if (data.code !== undefined && isEmpty(data.code)) {
    errors.code = "Please provide the code";
  }

  if (data.code_description !== undefined && isEmpty(data.code_description)) {
    errors.code_description = "Please provide the code description";
  }
  if (data.code_key !== undefined && isEmpty(data.code_key)) {
    errors.code_key = "Please provide the code type";
  }
  return { errors, isValid: isEmpty(errors) };
}

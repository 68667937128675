import React from "react";

export default function chromeSetup() {
  return (
    <div className="crome_setup browser_setup">
      <div className="margin-top-80 pt-45">
        <h3 className="font-16 weight-500 text-default-color margin-bottom-15">Allow AllDayDr to access your camera and microphone</h3>
        <ol className="m-0 font-14 weight-400 text-gray">
          <li className="push-10-b">
            Click the lock icon to the left of your address bar. A pop-up will appear with a list of options.
          </li>
          <li className="push-10-b">
            Click the drop-down list to the right of the <span className="weight-500 text-default-color ">Camera</span> and <span className="weight-500 text-default-color ">Microphone</span> option.
          </li>
          <li className="push-10-b">Select the option <span className="weight-500 text-default-color ">Allow.</span></li>
        </ol>
        <div className="setup-img-container mt-40">
          <img
            alt=""
            src={require("../../assets/images/video_call_setup/chrome.jpg").default}
          />
        </div>
      </div>
    </div>
  );
}

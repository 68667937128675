/* eslint-disable react-hooks/exhaustive-deps */
import { Select } from "antd";
import ModalPopUp from "components/common/ModalPopUp";
import React, { useEffect, useState } from "react";
import { addShortCodeAPI, updateShortCodeAPI } from "services/consultations";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import { ValidateInput } from "./SortCodeAddValidations";

export default function SortCodeEditModal({
  _hideModal,
  visible,
  _onRequestList,
  selectedRecord,
  
}) {
  const [state, setState] = useState({
    changing: false,
    reason: "",
    patient_sought: false,
    code: "",
    code_description: "",
    errors: null,
    code_key: null,
  });

  /**
   * Handles the text change event.
   *
   * @param {object} e - The event object.
   * @return {void}
   */
  const _handleTextChange = (e) => {
    let errors = null;
    if (state.errors) {
      errors = Object.assign("", state.errors);
      delete errors[e.target.name];
    }
    console.log("errors", errors);
    setState({ ...state, [e.target.name]: e.target.value, errors: errors });
  };

  useEffect(() => {
    if (selectedRecord) {
      console.log("Selected Record", selectedRecord);
      setState({
        ...state,
        code: selectedRecord.code,
        code_description: selectedRecord.description,
        code_key: selectedRecord.code_key,
      });
    }
  }, [selectedRecord]);

  useEffect(() => {
    if (state.code || state.code_description || state.code_key) {
      let data = {
        code: state.code,
        code_description: state.code_description,
        code_key: state.code_key,
      };
      const errors2 = ValidateInput(data);
      if (!errors2.isValid) {
        setState({ ...state, errors: errors2.errors });
      }
    }
  }, [state?.code, state?.code_description]);

  const _addShortCode = async () => {
    // alert("in");
    let { code, code_description, code_key } = state;
    // let { _onRequestList } = this.props;

    let dataTobeValidate = {
      code: code,
      code_description: code_description,
      code_key: code_key,
    };
    const errors = ValidateInput(dataTobeValidate);
    if (!errors.isValid) {
      setState({ ...state, errors: errors.errors, adding: false });
    } else {
      let payload = {
        code: code,
        description: code_description,
        code_key: code_key,
      };

      // await this._SaveComponentStateToRedux(datesParams);

      try {
        setState({ ...state, changing: true });
        let response = await addShortCodeAPI({
          short_code: {
            ...payload,
          },
        });

        successToast({ content: verifyObject(response, "data.message", "") });
        _clearData();
        if (_onRequestList) {
          if (typeof _onRequestList === "function") {
            _onRequestList();
          }
        }
      } catch (error) {
        const { message } = getErrorObject(error);
        await setState({ ...state, changing: false });
        errorToast({ content: message });
      }
    }
  };

  const _updateShortCode = async () => {
    // alert("in");
    let { code, code_description, code_key } = state;
    // let { _onRequestList } = this.props;

    let dataTobeValidate = {
      code: code,
      code_description: code_description,
      code_key: code_key,
    };
    const errors = ValidateInput(dataTobeValidate);
    if (!errors.isValid) {
      setState({ ...state, errors: errors.errors, adding: false });
    } else {
      let payload = {
        code: code,
        description: code_description,
        code_key: code_key,
      };

      // await this._SaveComponentStateToRedux(datesParams);

      try {
        setState({ ...state, changing: true });
        let response = await updateShortCodeAPI({
          id: selectedRecord.id,
          short_code: {
            ...payload,
          },
        });

        successToast({ content: verifyObject(response, "data.message", "") });
        _clearData();
        if (_onRequestList) {
          if (typeof _onRequestList === "function") {
            _onRequestList();
          }
        }
      } catch (error) {
        const { message } = getErrorObject(error);
        await setState({ ...state, changing: false });
        errorToast({ content: message });
        _clearData();
      }
    }
  };

  const _clearData = () => {
    setState({
      ...state,
      visible: false,
      changing: false,
      reason: "",
      patient_sought: false,
      code: "",
      code_description: "",
      code_key: "",
      errors: null,
      // benefit_from:[]
    });
    _hideModal();
  };
  let { changing, errors } = state;
  return (
    <ModalPopUp
      title={"Add Short Code"}
      handleCancel={changing ? false : _clearData}
      visible={visible}
      styleProps={{ width: 500 }}
      footer={true}
      closable={false}
      handleOk={() => {
        if (selectedRecord) {
          _updateShortCode();
        } else {
          _addShortCode();
        }
      }}
      okText={selectedRecord ? "Update" : "Save"}
      loading={changing}
      cancelBtnclassName="custom_cancel_btn"
      className="sicknotepopup addshortcodepopup"
      maskClosable={false}
      width={490}
      destroyOnClose={true}
    >
      <div className="sick-modal-container">
        <div className="referral-form">
          <form>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Code</label>
                  <input
                    className="form-control margin-0 font-10"
                    readOnly=""
                    name="code"
                    value={state.code}
                    onChange={_handleTextChange}
                    autoFocus
                  />
                  {errors && (
                    <span className="validate-danger">{errors.code}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Code Type</label>
                  <Select
                    className="form-control margin-0 font-10"
                    name="code_key"
                    value={state.code_key}
                    onChange={(val) =>
                      _handleTextChange({
                        target: {
                          name: "code_key",
                          value: val,
                        },
                      })
                    }
                    placeholder="Please select code type"
                    disabled={selectedRecord !== null}
                  >
                    {/* <Select.Option selected value={null}>Please Select</Select.Option> */}
                    <Select.Option value={"history"}>History</Select.Option>
                    <Select.Option value={"examination"}>
                      Examination
                    </Select.Option>
                    <Select.Option value={"management_plan"}>
                      Management Plan
                    </Select.Option>
                  </Select>
                  {errors && (
                    <span className="validate-danger">{errors.code_key}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Description</label>
                  <textarea
                    className="form-control margin-0 font-10 h-auto"
                    name="code_description"
                    value={state.code_description}
                    onChange={_handleTextChange}
                    rows={"3"}
                  />
                  {errors && (
                    <span className="validate-danger">
                      {errors.code_description}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </ModalPopUp>
  );
}

import {post, get, deletee, Irequest, put, patch} from "./index";

type IloginId = string | number;
type IPassword = string | number;

export type ILoginParams = {
  user: {
    email: IloginId,
    password: IPassword,
  },
  role: string,
};

export type IForgotPasswordParams = {
  email: IloginId,
};
export type IResetPasswordParams = {
  password: IloginId,
  reset_password_token: IPassword,
};

export const loginApi = (data, customJWToken) => {
  const request: Irequest = {
    subUrl: `/users/login.json`,
    data,
    customJWToken: customJWToken,
  };
  return post(request);
};

export const verifyCSToken = (params: ILoginParams) => {
  const request: Irequest = {
    subUrl: `/users/verify_cs_token`,
    params,
    CSToken: params?.cs_token,
  };
  return get(request);
};

export const fetchCorporatesFromEmail = (
  params,
  customJWToken,
  cancelToken
) => {
  const request = {
    subUrl: `/users/fetch_user_corporate.json`,
    params,
    customJWToken: customJWToken,
    cancelToken,
  };
  return get(request);
};

export const verifyPharmaSmartToken = (params: ILoginParams) => {
  const request: Irequest = {
    subUrl: `/users/verify_pharmsmart_sso_token`,
    params,
    CSToken: params?.pharma_token,
  };
  return get(request);
};
export const redirectLoginToDP = (params: ILoginParams) => {
  const request: Irequest = {
    subUrl: `/users/validate_token_and_redirect`,
    params,
  };
  return get(request);
};

export const updatePlayerID = (data) => {
  const request: Irequest = {
    subUrl: `/users/update_player_id.json`,
    data: {
      player_id: data.player_id,
    },
  };
  return patch(request);
};
export const logOutApi = () => {
  const request: Irequest = {
    subUrl: `/users/sign_out`,
  };
  return deletee(request);
};

export const forgotPasswordApi = (data) => {
  const request: Irequest = {
    subUrl: `/users/forgot_password.json`,
    data,
  };
  return post(request);
};
export const resetPasswordApi = (data: IResetPasswordParams) => {
  const request: Irequest = {
    subUrl: `/users/reset_password.json`,
    data,
  };
  return put(request);
};
export const changePasswordApi = (data: IResetPasswordParams) => {
  const request: Irequest = {
    subUrl: `/passwords/change.json`,
    data,
  };
  return post(request);
};
export const sendOTPApi = (params, customJWToken, secretToken) => {
  const request: Irequest = {
    subUrl: `/users/generate_otp.json`,
    params,
    customJWToken: customJWToken,
    secretToken: secretToken,
  };
  return get(request);
};
export const getOTPApi = (data) => {
  const request: Irequest = {
    subUrl: `/users/get_otp.json`,
    data,
  };
  return post(request);
};
export const verifyUserApi = (data, customJWToken, secretToken) => {
  const request: Irequest = {
    subUrl: `/users/validate_doctor.json`,
    data,
    customJWToken: customJWToken,
    secretToken: secretToken,
  };
  return post(request);
};
export const verifyOTPApi = (data) => {
  const request: Irequest = {
    subUrl: `/users/otp_verification.json`,
    data,
  };
  return post(request);
};
export const ResetPasswordWithTokenApi = (data) => {
  const request: Irequest = {
    subUrl: `/users/reset_password_with_token.json`,
    data,
  };
  return put(request);
};

export const signUpDoctor = (data, customJWToken) => {
  const request = {
    subUrl: `/users/registration.json`,
    data,
    customJWToken: customJWToken,
  };
  return post(request);
};
export const updateTokenForSessionExpiration = (params) => {
  const request: Irequest = {
    subUrl: `/device_details/update_token`,
    params,
  };
  return patch(request);
};
// switch between corporate API integration
export const switchCorporateApi = (params) => {
  const request: Irequest = {
    subUrl: `/users/clinician_multiple_corporate_login`,
    params,
  };
  return get(request);
};

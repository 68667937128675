import React from 'react'
import { Fade } from 'react-reveal'
export default function ValidationMessage({ children, when }) {
	return (
		<Fade bottom collapse when={when}>
			<div className='validate-danger' style={{ display: 'block' }}>
				{children}
			</div>
		</Fade>
	)
}

import React, {Fragment, useEffect, useState} from "react";
import {Button, Image, Popover} from "antd";
import {disableCopy, verifyObject} from "../../utilities/utils";
import {DATE_FORMAT, Ethnicity, GenderPreference} from "../../constants/common";
import moment from "moment";
import {isArray} from "lodash";
import {Spin, Space} from "antd";
import {EditOutlined, InfoCircleOutlined} from "@ant-design/icons";
import EditPatientDetail from "./EditPatientDetail";

export default function PatientDetailContainer({
  patientDetail,
  isLoadingDetail,
  _getPatientDetails,
  patientId,
}) {
  let [showEdit, setEditFlag] = useState(false);

  useEffect(() => {
    return () => {
      // store.dispatch({type: "ON_CLICK_NAME_UPCOMING", payload: false});
      // *! commented and placed on patient search id or view detail click function, this for handle start consultation button hide on click from user name in upcoming appointment
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoadingDetail) {
    return (
      <div style={{textAlign: "center", marginTop: 100}}>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </div>
    );
  }
  if (!patientDetail) {
    return (
      <div style={{textAlign: "center", marginTop: 100}}>
        <Space size="middle">
          <Spin size="large" />
        </Space>
      </div>
    );
  }
  const showEditModal = () => {
    setEditFlag(true);
  };

  const hideEditModal = () => {
    setEditFlag(false);
  };
  const toggleEdit = () => {
    setEditFlag(!showEdit);
  };
  const findEthnicity = Ethnicity.find(
    (eth) => eth.value === verifyObject(patientDetail, "ethnicity")
  );
  const findGenderPreference = GenderPreference.find(
    (pre) => pre.value === verifyObject(patientDetail, "gender_preference")
  );
  return (
    <Fragment>
      <div className="padding-55-t push-20-b patient_detailswrapper">
        {/* <h1>Rohan</h1> */}
        <div className="selection_container">
          <div className="patientdetailsmain">
            <div className="margin-bottom-15">
              <div className="in-block width-60px vertical-middle">
                {patientDetail && patientDetail.image !== "" ? (
                  <img
                    src={patientDetail.image}
                    alt="Patient"
                    height="46"
                    width="46"
                    className="border-radius-50"
                  ></img>
                ) : (
                  <img
                    src={
                      require("../../assets/images/common/doctor_profile.svg")
                        .default
                    }
                    alt="Patient"
                    height="46"
                    width="46"
                    className="border-radius-50"
                  ></img>
                )}
              </div>
              <div className="in-block customblock vertical-middle">
                <a
                  onClick={showEditModal}
                  className="text-blue weight-500 font-13"
                >
                  <EditOutlined /> Edit Profile
                </a>
                {verifyObject(patientDetail, "is_child_account", false) ? (
                  <span className="ml-2 custom-bluelabel font-10 in-block text-capitalize">
                    Child Account
                  </span>
                ) : null}
                {showEdit && (
                  <EditPatientDetail
                    customer_id={patientId}
                    hideEditModal={hideEditModal}
                    // showEditModalWithApi={}
                    showEdit={showEdit}
                    toggleEdit={toggleEdit}
                    //   {...data}
                    {...patientDetail}
                    _getPatientDetails={_getPatientDetails}
                    // {...this.props}
                  />
                )}
              </div>
            </div>

            <div className="form-row margin-bottom-15">
              <div className="col-md-6">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  Patient Name
                </p>
                <input
                  onCopy={disableCopy}
                  type="text"
                  readOnly=""
                  disabled
                  className="form-control custom-input input-sm-28 text-capitalize font-11 weight-400 no-focus input-bg-f9f9f9"
                  defaultValue={
                    verifyObject(patientDetail, "first_name", "") !== "" &&
                    verifyObject(patientDetail, "last_name", "") !== ""
                      ? `${patientDetail.first_name} ${patientDetail.last_name}`
                      : ""
                  }
                ></input>
              </div>
              <div className="col-md-6">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  Date of Birth
                </p>
                <input
                  type="text"
                  onCopy={disableCopy}
                  disabled
                  readOnly=""
                  className="form-control custom-input input-sm-28 font-11 weight-400 input-bg-f9f9f9 no-focus input-bg-f9f9f9"
                  defaultValue={
                    verifyObject(patientDetail, "date_of_birth", null) !== null
                      ? moment(patientDetail.date_of_birth).format(DATE_FORMAT)
                      : "--"
                  }
                ></input>
              </div>
            </div>
            {verifyObject(patientDetail, "is_child_account", false) ? (
              <div className="form-row margin-bottom-15">
                <div className="col-md-6">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    Guardian Name
                  </p>
                  <input
                    onCopy={disableCopy}
                    type="text"
                    readOnly=""
                    disabled
                    className="form-control custom-input input-sm-28 text-capitalize font-11 weight-400 no-focus input-bg-f9f9f9"
                    defaultValue={verifyObject(
                      patientDetail,
                      "guardian_name",
                      "-"
                    )}
                  ></input>
                </div>
                <div className="col-md-6">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    Guardian Email
                  </p>
                  <input
                    type="text"
                    onCopy={disableCopy}
                    disabled
                    readOnly=""
                    className="form-control custom-input input-sm-28 font-11 weight-400 input-bg-f9f9f9 no-focus input-bg-f9f9f9"
                    defaultValue={verifyObject(
                      patientDetail,
                      "guardian_email",
                      "-"
                    )}
                  ></input>
                </div>
              </div>
            ) : null}
            {verifyObject(patientDetail, "is_child_account", false) ? (
              <div className="form-row margin-bottom-15">
                <div className="col-md-6">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    Guardian Phone
                  </p>
                  <input
                    onCopy={disableCopy}
                    type="text"
                    readOnly=""
                    disabled
                    className="form-control custom-input input-sm-28 text-capitalize font-11 weight-400 no-focus input-bg-f9f9f9"
                    defaultValue={verifyObject(
                      patientDetail,
                      "guardian_phone",
                      "-"
                    )}
                  ></input>
                </div>
              </div>
            ) : null}
            <div className="form-row margin-bottom-15">
              <div className="col-md-6">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray text-capitalize">
                  Phone number
                  {patientDetail?.user?.reason_for_phone ? (
                    <span className="ml-2">
                      <Popover
                        content={`${patientDetail.user?.reason_for_phone}`}
                        // title="Info"
                      >
                        <sup className="cursor-pointer">
                          <InfoCircleOutlined />
                        </sup>
                      </Popover>
                    </span>
                  ) : null}
                </p>
                <input
                  type="text"
                  onCopy={disableCopy}
                  disabled
                  readOnly=""
                  className="form-control custom-input input-sm-28 font-11 weight-400 input-bg-f9f9f9 no-focus input-bg-f9f9f9"
                  defaultValue={
                    verifyObject(patientDetail, "phone", "-") !== ""
                      ? patientDetail.phone
                        ? patientDetail.phone
                        : "-"
                      : "-"
                  }
                ></input>
              </div>
              <div className="col-md-6">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray text-capitalize">
                  Email
                  {patientDetail?.user?.reason_for_email ? (
                    <span className="ml-2">
                      <Popover
                        content={`${patientDetail.user?.reason_for_email}`}
                        // title="Info"
                      >
                        <sup className="cursor-pointer">
                          <InfoCircleOutlined />
                        </sup>
                      </Popover>
                    </span>
                  ) : null}
                </p>
                <input
                  type="text"
                  onCopy={disableCopy}
                  disabled
                  readOnly=""
                  className="form-control custom-input input-sm-28 font-11 weight-400 input-bg-f9f9f9 no-focus input-bg-f9f9f9"
                  defaultValue={
                    verifyObject(patientDetail, "email", "-") !== ""
                      ? patientDetail.email
                        ? patientDetail.email
                        : "-"
                      : "-"
                  }
                ></input>
              </div>
            </div>

            {verifyObject(patientDetail, "secondary_phone", null) ||
            verifyObject(patientDetail, "secondary_phone", null) ? (
              <div className="form-row margin-bottom-15">
                {verifyObject(patientDetail, "secondary_phone", null) ? (
                  <div className="col-md-6">
                    <div className="border-box border-box-d9d9d9 col-md-12 custom-input font-11 weight-400 input-bg-f9f9f9 no-focus input-bg-f9f9f9">
                      <div className="form-row  align-items-center">
                        <div className="col-md-6">
                          <p
                            onCopy={disableCopy}
                            className="font-11 weight-400 margin-0 black-txt"
                          >
                            {verifyObject(
                              patientDetail,
                              "secondary_phone",
                              null
                            )
                              ? verifyObject(
                                  patientDetail,
                                  "secondary_phone",
                                  null
                                )
                              : "-"}
                          </p>
                        </div>

                        <div
                          onCopy={disableCopy}
                          className="col-md-6 text-right"
                        >
                          <p
                            className="font-11 weight-400 margin-0 custom-bluelabel"
                            onCopy={disableCopy}
                          >
                            NHS-PDS
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {verifyObject(patientDetail, "secondary_email", null) ? (
                  <div className="col-md-6">
                    <div className="border-box border-box-d9d9d9 col-md-12 custom-input font-11 weight-400 input-bg-f9f9f9 no-focus input-bg-f9f9f9">
                      <div className="form-row align-items-center">
                        <div className="col-md-6">
                          <p
                            onCopy={disableCopy}
                            className="font-11 weight-400 margin-0 black-txt"
                          >
                            {verifyObject(
                              patientDetail,
                              "secondary_email",
                              null
                            )
                              ? verifyObject(
                                  patientDetail,
                                  "secondary_email",
                                  null
                                )
                              : "-"}
                          </p>
                        </div>

                        <div
                          onCopy={disableCopy}
                          className="col-md-6 text-right"
                        >
                          <p
                            className="font-11 weight-400 margin-0 custom-bluelabel"
                            onCopy={disableCopy}
                          >
                            NHS-PDS
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            <div className="form-row margin-bottom-15">
              <div className="col-md-6">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray text-capitalize">
                  Gender Preference
                </p>
                <input
                  type="text"
                  onCopy={disableCopy}
                  disabled
                  readOnly=""
                  className="form-control custom-input input-sm-28 font-11 weight-400 input-bg-f9f9f9 no-focus input-bg-f9f9f9"
                  defaultValue={
                    findGenderPreference ? findGenderPreference.label : "-"
                  }
                ></input>
              </div>
              <div className="col-md-6">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray text-capitalize">
                  Ethnicity
                </p>
                <input
                  type="text"
                  onCopy={disableCopy}
                  disabled
                  readOnly=""
                  className="form-control custom-input input-sm-28 font-11 weight-400 input-bg-f9f9f9 no-focus input-bg-f9f9f9"
                  defaultValue={findEthnicity ? findEthnicity.label : "-"}
                ></input>
              </div>
            </div>

            <div className="form-row margin-bottom-15">
              <div className="col-md-6">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  Emergency number
                </p>
                <input
                  onCopy={disableCopy}
                  type="text"
                  readOnly=""
                  disabled
                  className="form-control custom-input input-sm-28 text-capitalize font-11 weight-400 no-focus input-bg-f9f9f9"
                  defaultValue={
                    verifyObject(patientDetail, "emergency_number", null)
                      ? verifyObject(patientDetail, "emergency_number", null)
                      : "-"
                  }
                ></input>
              </div>
              <div className="col-md-6">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  NHS number
                </p>
                <input
                  onCopy={disableCopy}
                  type="text"
                  readOnly=""
                  disabled
                  className="form-control custom-input input-sm-28 text-capitalize font-11 weight-400 no-focus input-bg-f9f9f9"
                  defaultValue={verifyObject(patientDetail, "nhs_number", "-")}
                ></input>
              </div>
            </div>

            <div className="form-row margin-bottom-15">
              <div className="col-md-12">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray text-capitalize">
                  My GP
                </p>
                {verifyObject(patientDetail, "gp_service", null) !== null ? (
                  <input
                    type="text"
                    onCopy={disableCopy}
                    disabled
                    readOnly=""
                    className=" no-focus form-control custom-input input-sm-28 font-10_5 weight-400 input-bg-f9f9f9"
                    defaultValue={`${
                      verifyObject(
                        patientDetail,
                        "gp_service.practice_name",
                        ""
                      ) !== "" &&
                      patientDetail.gp_service.practice_name !== "null" &&
                      patientDetail.gp_service.practice_name !== null
                        ? patientDetail.gp_service.practice_name + ", "
                        : " "
                    }${
                      verifyObject(patientDetail, "gp_service.address", "") !==
                        "" &&
                      patientDetail.gp_service.address !== "null" &&
                      patientDetail.gp_service.address !== null
                        ? patientDetail.gp_service.address + ", "
                        : " "
                    }${
                      verifyObject(patientDetail, "gp_service.city", "") !==
                        "" &&
                      patientDetail.gp_service.city !== "null" &&
                      patientDetail.gp_service.city !== null
                        ? patientDetail.gp_service.city + ", "
                        : " "
                    }${
                      verifyObject(patientDetail, "gp_service.county", "") !==
                        "" &&
                      patientDetail.gp_service.county !== "null" &&
                      patientDetail.gp_service.county !== null
                        ? patientDetail.gp_service.county + ", "
                        : " "
                    }${
                      verifyObject(
                        patientDetail,
                        "gp_service.post_code",
                        ""
                      ) !== "" &&
                      patientDetail.gp_service.post_code !== "null" &&
                      patientDetail.gp_service.post_code !== null
                        ? patientDetail.gp_service.post_code
                        : " "
                    }`}
                  ></input>
                ) : (
                  <input
                    type="text"
                    readOnly=""
                    disabled
                    className="form-control custom-input input-sm-28 font-11 weight-400 input-bg-f9f9f9"
                    defaultValue={"-"}
                  />
                )}
              </div>
            </div>
            {verifyObject(patientDetail, "organization", null) !== null && (
              <div className="form-row margin-bottom-15">
                <div className="col-md-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    My Pharmacy
                  </p>
                  <div className="border-box col-md-12 input-bg-f9f9f9">
                    <div className="form-row">
                      <div className="col-md-6">
                        <p
                          onCopy={disableCopy}
                          className="font-11 weight-400 margin-0 text-uppercase"
                        >
                          {verifyObject(
                            patientDetail.organization,
                            "name",
                            null
                          ) !== null && patientDetail.organization.name}
                          ,{" "}
                          <span onCopy={disableCopy}>
                            {verifyObject(
                              patientDetail.organization,
                              "line1",
                              ""
                            ) !== "" && `${patientDetail.organization.line1}, `}
                            {verifyObject(
                              patientDetail.organization,
                              "line2",
                              ""
                            ) !== "" && `${patientDetail.organization.line2}, `}
                            {verifyObject(
                              patientDetail.organization,
                              "town",
                              ""
                            ) !== "" && `${patientDetail.organization.town}, `}
                            {verifyObject(
                              patientDetail.organization,
                              "pincode",
                              ""
                            ) !== "" && `${patientDetail.organization.pincode}`}
                          </span>
                        </p>
                      </div>
                      {verifyObject(patientDetail.organization, "phone", "") !==
                        "" && (
                        <div
                          onCopy={disableCopy}
                          className="col-md-6 text-right"
                        >
                          <p
                            className="font-11 weight-400 margin-0"
                            onCopy={disableCopy}
                          >
                            {" "}
                            | Phone: {patientDetail.organization.phone}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <hr className="margin-bottom-20 margin-top-25 border-top"></hr>
            <div className="form-row margin-bottom-15">
              <div className="col-md-12">
                <p className="font-14 weight-500 text-left dis-block m-0 text-capitalize text-dark-gray text-capitalize">
                  My Bio Details
                </p>
                {/* <div className="border-box col-md-12 input-bg-f9f9f9">
                  <div className="form-row">
                    <div className="col-md-4">
                      <p className="font-11 weight-400 text-light-black text-left push-5-b">
                        Weight :
                        {verifyObject(
                          patientDetail,
                          "weight_category",
                          null
                        ) !== null &&
                        patientDetail.weight_category === "metric" &&
                        verifyObject(patientDetail, "weight_value1", null) !==
                          null ? (
                          <span className="text-dark-gray ng-binding">
                            {" "}
                            {patientDetail.weight_value1}{" "}
                          </span>
                        ) : (
                          ""
                        )}
                        {verifyObject(
                          patientDetail,
                          "weight_category",
                          null
                        ) !== null &&
                        patientDetail.weight_category === "imperial" &&
                        verifyObject(patientDetail, "weight_value1", null) !==
                          null ? (
                          <span className="text-dark-gray ng-binding">
                            {patientDetail.weight_value1}
                            {patientDetail.weight_value2}
                          </span>
                        ) : (
                          ""
                        )}
                      </p>
                      {
                        // <p className="font-11 weight-400 text-light-black text-left push-5-b">
                        //   Weight :
                        //   {verifyObject(
                        //     patientDetail,
                        //     "weight_category",
                        //     null
                        //   ) !== null &&
                        //   patientDetail.weight_category === "metric" &&
                        //   verifyObject(patientDetail, "weight_value1", null) !==
                        //     null ? (
                        //     <p className="text-dark-gray ng-binding">
                        //       {" "}
                        //       {patientDetail.weight_value1}
                        //     </p>
                        //   ) : (
                        //     ""
                        //   )}
                        //   {verifyObject(
                        //     patientDetail,
                        //     "weight_category",
                        //     null
                        //   ) !== null &&
                        //   patientDetail.weight_category === "imperial" &&
                        //   verifyObject(patientDetail, "weight_value1", null) !==
                        //     null ? (
                        //     <p className="text-dark-gray ng-binding">
                        //       {" "}
                        //       {patientDetail.weight_value1}
                        //       {patientDetail.weight_value2}
                        //     </p>
                        //   ) : (
                        //     ""
                        //   )}
                        // </p>
                      }

                      {
                        <p className="font-11 weight-400 text-light-black text-left push-5-b">
                          Do you smoke :
                          {verifyObject(patientDetail, "smoke", null) !==
                          null ? (
                            <span className="text-dark-gray ng-binding">
                              {patientDetail.smoke === "No smoker"
                                ? "No"
                                : `${patientDetail.smoke} per day`}
                            </span>
                          ) : (
                            " -"
                          )}
                        </p>
                      }
                    </div>
                    <div className="col-md-4">
                      {
                        <p className="font-11 weight-400 text-light-black text-left push-5-b">
                          Height :
                          {verifyObject(
                            patientDetail,
                            "height_value1",
                            null
                          ) !== null &&
                          verifyObject(patientDetail, "height_value2", null) !==
                            null ? (
                            <span className="text-dark-gray ng-binding">
                              {" "}
                              {patientDetail.height_value1}{" "}
                              {patientDetail.height_value2}
                            </span>
                          ) : (
                            " -"
                          )}
                        </p>
                      }
                      {verifyObject(patientDetail, "alcohol", null) !==
                        null && (
                        <p className="font-11 weight-400 text-light-black text-left push-5-b">
                          Do you drink alcohol? :
                          <span className="text-dark-gray ng-binding">
                            {" "}
                            {patientDetail.alcohol === "No alcoholic"
                              ? "No"
                              : `${patientDetail.alcohol} Unit Alcohol`}
                          </span>
                        </p>
                      )}
                    </div>
                    <div className="col-md-4">
                      <p className="font-11 weight-400 text-light-black text-left push-5-b">
                        BMI :
                        {verifyObject(patientDetail, "bmi", null) !== null ? (
                          <span className="text-dark-gray ng-binding">
                            {" "}
                            {patientDetail.bmi}
                          </span>
                        ) : (
                          " -"
                        )}
                      </p>
                    </div>
                    <div className="col-md-12">
                      {
                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                          What is your blood pressure? :
                          {verifyObject(
                            patientDetail,
                            "blood_pressure",
                            null
                          ) !== null ? (
                            <span className="text-dark-gray ng-binding">
                              {patientDetail.blood_pressure}
                            </span>
                          ) : (
                            " -"
                          )}
                        </p>
                      }
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="form-row margin-bottom-15">
              <div className="col-md-4">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  Weight
                </p>

                <span
                  onCopy={disableCopy}
                  className="border-box input-bg-f9f9f9 dis-block text-light-black font-11 input-sm-28 weight-400"
                >
                  {verifyObject(patientDetail, "weight_category", null) !==
                    null &&
                  patientDetail.weight_category === "metric" &&
                  verifyObject(patientDetail, "weight_value1", null) !== null
                    ? patientDetail.weight_value1
                    : verifyObject(patientDetail, "weight_category", null) !==
                        null &&
                      patientDetail.weight_category === "imperial" &&
                      verifyObject(patientDetail, "weight_value1", null) !==
                        null
                    ? `${patientDetail.weight_value1}
                    ${patientDetail.weight_value2}`
                    : "-"}
                </span>
              </div>
              <div className="col-md-4">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  Height
                </p>
                <span
                  onCopy={disableCopy}
                  className="border-box input-bg-f9f9f9 dis-block text-light-black font-11 input-sm-28 weight-400"
                >
                  {verifyObject(patientDetail, "height_value1", null) !==
                    null &&
                  verifyObject(patientDetail, "height_category", null) !==
                    null &&
                  verifyObject(patientDetail, "height_value2", null) !== null
                    ? `${patientDetail.height_value1} ${patientDetail.height_value2}`
                    : "-"}
                </span>
              </div>
              <div className="col-md-4">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  BMI
                </p>
                <span
                  onCopy={disableCopy}
                  className="border-box input-bg-f9f9f9 dis-block text-light-black font-11 input-sm-28 weight-400"
                >
                  {verifyObject(patientDetail, "bmi", null) !== null &&
                  verifyObject(patientDetail, "bmi", null) !== ""
                    ? patientDetail.bmi
                    : "-"}
                </span>
              </div>
            </div>

            <div className="form-row margin-bottom-15">
              <div className="col-md-4">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  Do you smoke
                </p>

                <span
                  onCopy={disableCopy}
                  className="border-box input-bg-f9f9f9 dis-block text-light-black font-11 input-sm-28 weight-400"
                >
                  {verifyObject(patientDetail, "smoke", null)
                    ? `${
                        patientDetail.smoke === "No smoker"
                          ? "No"
                          : `${patientDetail.smoke}`
                      }`
                    : "-"}
                </span>
              </div>
              <div className="col-md-4">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  Do you drink alcohol?
                </p>
                <span
                  onCopy={disableCopy}
                  className="border-box input-bg-f9f9f9 dis-block text-light-black font-11 input-sm-28 weight-400"
                >
                  {" "}
                  {patientDetail.alcohol === "No alcoholic"
                    ? "No"
                    : patientDetail.alcohol
                    ? `${patientDetail.alcohol}`
                    : "-"}
                </span>
              </div>
              <div className="col-md-4">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                  What is your blood pressure?
                </p>

                <span
                  onCopy={disableCopy}
                  className="border-box input-bg-f9f9f9 dis-block text-light-black font-11 input-sm-28 weight-400"
                >
                  {verifyObject(patientDetail, "blood_pressure", null) !==
                    null &&
                  verifyObject(patientDetail, "blood_pressure", null) !== ""
                    ? patientDetail.blood_pressure
                    : "-"}
                </span>
              </div>
            </div>

            <div className="form-row margin-bottom-15">
              <div className="col-md-12">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray text-capitalize">
                  My Address
                </p>
                <div className="border-box col-md-12 input-bg-f9f9f9">
                  <div className="form-row">
                    <div onCopy={disableCopy} className="col-md-6">
                      {verifyObject(patientDetail, "primary_address", null) !==
                      null ? (
                        <Fragment>
                          {verifyObject(
                            patientDetail,
                            "primary_address",
                            null
                          ) !== null && (
                            <Fragment>
                              {verifyObject(
                                patientDetail,
                                "primary_address.line1",
                                null
                              ) !== null && (
                                <p className="font-11 weight-400 margin-0">
                                  {patientDetail.primary_address.line1
                                    ? `${patientDetail.primary_address.line1},`
                                    : "-"}
                                </p>
                              )}
                              {verifyObject(
                                patientDetail,
                                "primary_address.line2",
                                null
                              ) !== null && (
                                <p className="font-11 weight-400 margin-0">
                                  {patientDetail.primary_address.line2
                                    ? `${patientDetail.primary_address.line2},`
                                    : ""}
                                </p>
                              )}
                              {verifyObject(
                                patientDetail,
                                "primary_address.town",
                                null
                              ) !== null && (
                                <p className="font-11 weight-400 margin-0">
                                  {patientDetail.primary_address.town
                                    ? `${patientDetail.primary_address.town},`
                                    : ""}
                                </p>
                              )}
                              {verifyObject(
                                patientDetail,
                                "primary_address.pincode",
                                null
                              ) !== null && (
                                <p className="font-11 weight-400 margin-0">
                                  {patientDetail.primary_address.pincode
                                    ? `${patientDetail.primary_address.pincode}`
                                    : ""}
                                </p>
                              )}
                            </Fragment>
                          )}
                        </Fragment>
                      ) : (
                        <p className="font-11 weight-400 margin-0">-</p>
                      )}
                    </div>
                    <div onCopy={disableCopy} className="col-md-6 text-right">
                      {verifyObject(
                        patientDetail,
                        "primary_address.phone",
                        ""
                      ) !== "" && (
                        <p className="font-11 weight-400 margin-0">
                          {patientDetail.primary_address.phone
                            ? `| Phone:
                          ${patientDetail.primary_address.phone}`
                            : ""}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="form-row pmargin-bottom-15">
              <div className="col-md-12">
                <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray text-capitalize">
                  Photo ID
                </p>
                <div className="border-box col-md-12 input-bg-f9f9f9">
                  <div onCopy={disableCopy} className="photoidimages">
                    {verifyObject(patientDetail, "documents", null) !== null &&
                    isArray(patientDetail.documents) &&
                    patientDetail.documents.length > 0
                      ? patientDetail.documents.map((d) => {
                          return (
                            <li>
                              <Image
                                src={d.file_url}
                                alt={d.file_file_name}
                                className="cursor-pointer patient-detail-img"
                              />
                            </li>
                          );
                        })
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as ConsultationReviewActions } from "../../reducers/consultationReview";
import { Pagination } from "../../components/common";
import refresh from "assets/images/common/refresh.svg";
import FilterComponent from "../../components/FIlterComponent/FilterComponent";
import { scriptReviewListFilter } from "../../constants/filterConstants";
import { verifyObject } from "utilities/utils";
import ConsultationReviewTable from "./ConsultationReviewTable";
import { appRoutesConst, navigateTo } from "app/navigation";
import store from "app/store";
import { onSetPatient, onSetPatientAppointment } from "../../reducers/patients";

// import { localStore } from "../../reducers/localStore";
const sortingParams = [
  "patient_name",
  "corporate_name",
  "date_of_birth",
  "order_id",
  "created_at",
];
const statusArray = [
  "finished_oos",
  "finished",
  "patient_arrived",
  "patient_not_arrived",
  "cancelled",
];
class ConsultationReviewContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status || "new",
    };
    ["_onPageChange"].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  // _onPageChange = (data) => {
  //   let { page } = data;
  //   // let { search } = this.props.scriptReview;
  //   let filters = JSON.parse(localStorage.getItem("intakedFilters"))
  //     ? JSON.parse(localStorage.getItem("intakedFilters"))
  //     : {};
  //   this.props.ConsultationReviewActions.onPageChange({
  //     status: this.state.status,
  //     page,
  //     ...filters,
  //     ...this.state.sort_params,
  //   });
  // };

  componentWillReceiveProps(nextProps) {
    let { status } = nextProps;
    this.setState({ status });
  }

  componentDidMount() {}

  _onRefresh = () => {
    localStorage.removeItem("intakedFilters");
    let { ConsultationReviewActions } = this.props;
    let { onRequest } = ConsultationReviewActions;
    if (typeof onRequest === "function") {
      let filters = JSON.parse(localStorage.getItem("intakedFilters"))
        ? JSON.parse(localStorage.getItem("intakedFilters"))
        : {};
      onRequest({
        page: 1,
        ...filters,
        appointment_type: this.props.appointment_type,
      });
    }
    let nodes = document.querySelectorAll(".push-3-l");
    nodes.forEach((node) => {
      node.classList.remove("is_active_sorting");
      node.classList.remove("sort-applied");
    });
    sortingParams.map((param) => {
      // if (param !== sort_param) {
      this.setState({
        [`dataSorted${param}`]: 0,
        [`accending${param}`]: undefined,
      });
      // }
      return null;
    });
  };

  componentDidUpdate(prevProps, nextState) {
    if (nextState.status !== this.state.status) {
      // this.props.ConsultationReviewActions.onRequest({
      //   page: 1,
      //   status: this.state.status,
      //   // ...filters,
      // });
      let nodes = document.querySelectorAll(".push-3-l");
      nodes.forEach((node) => {
        node.classList.remove("is_active_sorting");
        node.classList.remove("sort-applied");
      });
      sortingParams.map((param) => {
        // if (param !== sort_param) {
        this.setState({
          [`dataSorted${param}`]: 0,
          [`accending${param}`]: undefined,
        });
        // }
        return null;
      });
    }
  }
  saveFilters = (filters) => {
    localStorage.setItem("intakedFilters", JSON.stringify(filters));
  };

  componentWillUnmount() {
    // localStorage.removeItem("intakedFilters");
  }
  _handleNavigation = (data, fromUnderReview) => {
    let { onSetPatient } = this.props.onSetPatient;
    let { onSetPatientAppointment } = this.props.onSetPatientAppointment;
    let { navigateTo } = this.props.navigateTo;
    let { patient_id } = data;

    if (typeof onSetPatient === "function") {
      onSetPatient({ id: patient_id });
    }
    store.dispatch({
      type: "IS_CLICKED_VIEW",
      payload: true,
    });

    if (typeof onSetPatientAppointment === "function") {
      onSetPatientAppointment(data);
    }

    if (typeof navigateTo === "function") {
      let routeName = `${appRoutesConst.patientConsultationDetail}?isReviewed=true`;
      if (fromUnderReview) {
        routeName = `${appRoutesConst.patientConsultationDetail}?isReview=true`;
      }
      navigateTo(routeName);
    }
  };
  saveFilters = (filters) => {
    // console.log("Saved filters", filters);

    localStorage.setItem("previousFilters", JSON.stringify(filters));
  };
  _onPageChange(data) {
    // let filters = JSON.parse(localStorage.getItem("previousFilters"))
    //   ? JSON.parse(localStorage.getItem("previousFilters"))
    //   : {};
    let {
      ConsultationReviewActions: { onPageChange },
      consultationReview: { search, filters },
    } = this.props;
    const { page } = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page,
        search,
        ...filters,
        ...this.state.sort_params,
        appointment_type: this.props.appointment_type,
      });
    }
  }
  // componentWillUnmount() {
  //   localStorage.removeItem("previousFilters");
  // }
  sortRecordBy = (sort_param, id) => {
    this.setState(
      {
        [`accending${sort_param}`]:
          this.state[`accending${sort_param}`] === undefined
            ? true
            : !this.state[`accending${sort_param}`],
        [`dataSorted${sort_param}`]:
          (this.state[`dataSorted${sort_param}`]
            ? this.state[`dataSorted${sort_param}`]
            : 0) + 1,
      },
      () => {
        let filters = JSON.parse(localStorage.getItem("previousFilters"))
          ? JSON.parse(localStorage.getItem("previousFilters"))
          : {};
        let {
          ConsultationReviewActions: { onRequest },
          consultationReview: { search },
        } = this.props;
        let sort_params = {
          [`sort[${sort_param}]`]: this.state[`accending${sort_param}`]
            ? "ASC"
            : "DESC",
        };

        this.setState({ sort_params }, () => {
          onRequest({
            ...filters,
            ...this.state.sort_params,
            search,
            page: 1,
            appointment_type: this.state.tabKey,
            // status: this.state.status,
            // search,
          });
          let element = document.getElementById(id);
          element.classList.add("is_active_sorting");

          if (this.state[`dataSorted${sort_param}`] > 1) {
            let dco = document.getElementById(id);
            if (dco) {
              if (dco.classList.contains("sort-applied")) {
                dco.classList.remove("sort-applied");
              } else {
                console.log("SORT", this.state.accending);
                // if (this.state.accending) {
                dco.classList.add("sort-applied");
                // }
              }
            }

            // this.state["dataSorted"]
          }
          sortingParams.map((param) => {
            if (param !== sort_param) {
              this.setState({
                [`dataSorted${param}`]: 0,
                [`accending${param}`]: undefined,
              });
            }
            return null;
          });

          let nodes = document.querySelectorAll(".push-3-l");
          nodes.forEach((node) => {
            if (node.classList.contains("sort-applied") && node.id !== id) {
              node.classList.remove("sort-applied");
            }
            if (
              node.classList.contains("is_active_sorting") &&
              node.id !== id
            ) {
              node.classList.remove("is_active_sorting");
            }
          });
        });
      }
    );
  };

  sortRecordBy = (sort_param, id) => {
    this.setState(
      {
        [`accending${sort_param}`]:
          this.state[`accending${sort_param}`] === undefined
            ? true
            : !this.state[`accending${sort_param}`],
        [`dataSorted${sort_param}`]:
          (this.state[`dataSorted${sort_param}`]
            ? this.state[`dataSorted${sort_param}`]
            : 0) + 1,
      },
      () => {
        let filters = JSON.parse(localStorage.getItem("intakedFilters"))
          ? JSON.parse(localStorage.getItem("intakedFilters"))
          : {};
        let { ConsultationReviewActions } = this.props;
        let { onRequest } = ConsultationReviewActions;
        let sort_params = {
          [`sort[${sort_param}]`]: this.state[`accending${sort_param}`]
            ? "ASC"
            : "DESC",
        };

        this.setState({ sort_params }, () => {
          onRequest({
            ...filters,
            ...this.state.sort_params,
            page: 1,
            status: this.state.status,
            // search,
          });
          let element = document.getElementById(id);
          element.classList.add("is_active_sorting");

          if (this.state[`dataSorted${sort_param}`] > 1) {
            let dco = document.getElementById(id);
            if (dco) {
              if (dco.classList.contains("sort-applied")) {
                dco.classList.remove("sort-applied");
              } else {
                console.log("SORT", this.state.accending);
                // if (this.state.accending) {
                dco.classList.add("sort-applied");
                // }
              }
            }

            // this.state["dataSorted"]
          }
          sortingParams.map((param) => {
            if (param !== sort_param) {
              this.setState({
                [`dataSorted${param}`]: 0,
                [`accending${param}`]: undefined,
              });
            }
            return null;
          });

          let nodes = document.querySelectorAll(".push-3-l");
          nodes.forEach((node) => {
            if (node.classList.contains("sort-applied") && node.id !== id) {
              node.classList.remove("sort-applied");
            }
            if (
              node.classList.contains("is_active_sorting") &&
              node.id !== id
            ) {
              node.classList.remove("is_active_sorting");
            }
          });
        });
      }
    );
  };
  // _handleNavigation(data, fromUnderReview) {
  //   let { onSetPatient } = this.props.onSetPatient;
  //   let { onSetPatientAppointment } = this.props.onSetPatientAppointment;
  //   let { navigateTo } = this.props.navigateTo;
  //   let { patient_id } = data;

  //   if (typeof onSetPatient === "function") {
  //     onSetPatient({ id: patient_id });
  //   }
  //   store.dispatch({
  //     type: "IS_CLICKED_VIEW",
  //     payload: true,
  //   });

  //   if (typeof onSetPatientAppointment === "function") {
  //     onSetPatientAppointment(data);
  //   }

  //   if (typeof navigateTo === "function") {
  //     let routeName = appRoutesConst.patientConsultationDetail;
  //     if (fromUnderReview) {
  //       routeName = `${appRoutesConst.patientConsultationDetail}?isReview=true`;
  //     }
  //     navigateTo(routeName);
  //   }
  // }
  // sortRecordBy = async (sort_param, id) => {
  //   this.setState({ accending: !this.state.accending });
  //   let filters = JSON.parse(localStorage.getItem("intakedFilters"))
  //     ? JSON.parse(localStorage.getItem("intakedFilters"))
  //     : {};
  //   let { ConsultationReviewActions } = this.props;
  //   let { onRequest } = ConsultationReviewActions;
  //   let sort_params = {
  //     [`sort[${sort_param}]`]: this.state.accending ? "ASC" : "DESC",
  //   };
  //   this.setState({ sort_params }, () => {
  //     onRequest({
  //       ...filters,
  //       ...this.state.sort_params,
  //       status: this.state.status,
  //       // search,
  //     });
  //     let dco = document.getElementById(id);
  //     if (dco) {
  //       if (dco.classList.contains("sort-applied")) {
  //         dco.classList.remove("sort-applied");
  //       } else {
  //         dco.classList.add("sort-applied");
  //       }
  //     }
  //     let nodes = document.querySelectorAll(".push-3-l");
  //     nodes.forEach((node) => {
  //       if (node.classList.contains("sort-applied") && node.id !== id) {
  //         node.classList.remove("sort-applied");
  //       }
  //     });
  //     // this.setState({
  //     //   accending: true,
  //     // });
  //   });
  // };

  render() {
    let {
      consultationReview: { data, ids, isLoading },
    } = this.props;
    return (
      <Fragment>
        <div className="form-row push-20-b push-10-t">
          <div className="col-md-4">
            <a
              onClick={this._onRefresh}
              className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8"
            >
              <img alt="" src={refresh} className="height-15"></img>{" "}
              <span className="push-2-l font-14 weight-400">Refresh</span>
            </a>
          </div>
          <div className="col-md-8">
            <FilterComponent
              isLoading={isLoading}
              defaultRequest={{ appointment_type: this.props.appointment_type }}
              onFilterChange={
                this.props.ConsultationReviewActions.onFilterChange
              }
              resetFilters={
                this.props.ConsultationReviewActions.resetFilterState
              }
              filterArray={scriptReviewListFilter}
              saveFilters={this.saveFilters}
              selectedFilters={
                JSON.parse(localStorage.getItem("intakedFilters"))
                  ? {
                      ...JSON.parse(localStorage.getItem("intakedFilters")),
                    }
                  : {}
              }
            />
            {/* {JSON.parse(localStorage.getItem("intakedFilters")) !== null && (
            
            )}
            {JSON.parse(localStorage.getItem("intakedFilters")) === null && (
              <FilterComponent
                isLoading={scriptReview.isLoading}
                defaultRequest={{ status: this.props.status }}
                onFilterChange={this.props.ConsultationReviewActions.onFilterChange}
                resetFilters={this.props.ConsultationReviewActions.resetFilterState}
                filterArray={scriptReviewListFilter}
                saveFilters={this.saveFilters}
                selectedFilters={{}}
              />
            )} */}
            {/* <div className="search-with-filterdropdown">
                  <div className="search-filter-downdown-input">
                    <Input
                      placeholder="Search"
                      prefix={<SearchOutlined />}
                      className="w-100"
                    />
                    <span className="input-suffix">
                      <CaretUpOutlined />
                    </span>
                  </div>
                    <div className="search-filter-downdown-content search-show">
                        <form>
                            <div className="form-row align-items-center margin-bottom-10">
                                <div className="col-md-3">
                                    <div className="position-relative text-right">
                                        <label className="m-0 font-13">Name:</label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <input placeholder="Enter Name" className="form-control custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9" />
                                </div>
                            </div>

                            <div className="form-row align-items-center margin-bottom-10">
                                <div className="col-md-3">
                                    <div className="position-relative text-right">
                                        <label className="m-0 font-13">Patient ID:</label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <InputNumber placeholder="Enter Patient ID" className="form-control custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9" />
                                </div>
                            </div>

                            <div className="form-row align-items-center margin-bottom-10">
                                <div className="col-md-3">
                                    <div className="position-relative text-right">
                                        <label className="m-0 font-13">Gender:</label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <select className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100" placeholder="Select Gender">
                                        <Select.Option value="Male" className="font-13-imp">Male</Select.Option>
                                        <Select.Option value="Female" className="font-13-imp">Female</Select.Option>
                                        <Select.Option value="Other" className="font-13-imp">Other</Select.Option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-row align-items-center margin-bottom-10">
                                <div className="col-md-3">
                                    <div className="position-relative text-right">
                                        <label className="m-0 font-13">DOB:</label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <DatePicker className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100" />
                                </div>
                            </div>

                            <div className="form-row align-items-center margin-bottom-10">
                                <div className="col-md-3">
                                    <div className="position-relative text-right">
                                        <label className="m-0 font-13">Payee:</label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <select className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100" placeholder="Select Payee">
                                        <Select.Option value="One" className="font-13-imp">One</Select.Option>
                                        <Select.Option value="Two" className="font-13-imp">Two</Select.Option>
                                        <Select.Option value="Three" className="font-13-imp">Three</Select.Option>
                                    </select>
                                </div>
                            </div>

                            <div className="form-row align-items-center margin-bottom-10">
                                <div className="col-md-3">
                                    <div className="position-relative text-right">
                                        <label className="m-0 font-13">NHS Number:</label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <input placeholder="Enter NHS Number" className="form-control custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9" />
                                </div>
                            </div>

                            <div className="form-row align-items-center margin-bottom-10">
                                <div className="col-md-3">
                                    <div className="position-relative text-right">
                                        <label className="m-0 font-13">Session Date:</label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <div className="form-row align-items-center">
                                        <div className="col-md-5">
                                            <DatePicker className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100" />
                                        </div>
                                        <div className="col-md-1 text-center">
                                            <label className="m-0 font-13">to</label>
                                        </div>
                                        <div className="col-md-6">
                                            <DatePicker className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="form-row align-items-center margin-bottom-10">
                                <div className="col-md-3">
                                    <div className="position-relative text-right">
                                        <label className="m-0 font-13">Postcode:</label>
                                    </div>
                                </div>
                                <div className="col-md-9">
                                    <InputNumber placeholder="Postcode" className="form-control custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9" />
                                </div>
                            </div>

                            <div className="form-row align-items-center">
                                <div className="col-md-3">
                                    <div className="position-relative"></div>
                                </div>
                                <div className="col-md-9">
                                    <button htmlType="submit" className="btn-custom btn btn-blue width-105">Search</button>
                                    <button className="btn-custom btn btn-darkgray push-10-l width-105">Reset</button>
                                </div>
                            </div>

                        </form>
                    </div>
                </div> */}
          </div>
        </div>

        <div className="push-20-b push-10-t">
          <ConsultationReviewTable
            statusArray={statusArray}
            data={data}
            isLoading={isLoading}
            ids={ids}
            sortRecordBy={this.sortRecordBy}
            _handleNavigation={this._handleNavigation}
            toggleThreeDots={this.toggleThreeDots}
            handleOOSAppointment={this.handleOOSAppointment}
            endAppointment={this.endAppointment}
            {...this.state}
            {...this.props}
            fromUnderReview={true}
          />
          {!isLoading && (
            <div className="col-md-12">
              <Pagination
                data={this.props.consultationReview}
                onPageChange={this._onPageChange}
              />
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    consultationReview: verifyObject(state, "consultationReview", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    ConsultationReviewActions: bindActionCreators(
      ConsultationReviewActions,
      dispatch
    ),
    onSetPatientAppointment: bindActionCreators(
      { onSetPatientAppointment },
      dispatch
    ),
    onSetPatient: bindActionCreators({ onSetPatient }, dispatch),
    navigateTo: bindActionCreators({ navigateTo }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsultationReviewContainer);


import { get } from "./index";


export const getEmailTemplatesApi = (params) => {
    const request = {
        subUrl: `/admin/documents/list_covid_email_template.json`,
        params
    };
    return get(request);
};




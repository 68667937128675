import React from "react";
import { isChrome, isFirefox ,isSafari,isEdge } from "react-device-detect";
import ChromeSetup from "./chromeSetup";
import EdgeSetup from "./EdgeSetup";
import FireFoxSetup from "./FireFoxSetup";
import SafariSetup from "./SafariSetup";
import "./videoCallInstructions.css";
export default function VideoCallSetupInstructions() {
  return (
    <div>
      {isChrome && <ChromeSetup />}
      {isFirefox && <FireFoxSetup />}
      {isSafari && <SafariSetup />}
      {isEdge && <EdgeSetup />}
    </div>
  );
}

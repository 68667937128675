import { getOrdersPaymentsApi } from 'services/payments'
import { listingReducer } from 'reducers/utils/listingReducer'

const { reducer, actions, types } = listingReducer({
	name: `orderPayments`,
	getApi: getOrdersPaymentsApi,
})

const orderPayments = reducer
export { orderPayments, actions, types }

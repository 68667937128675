import React from "react";
import logo from "assets/images/common/main_logo.svg";
import {ErrorBoundary} from "app/ErrorBoundary";
import {Link} from "react-router-dom";
import ResetOTPContainer from "containers/session/ResetOTPContainer";

export default function ResetPasswordOTPPageWithContainer() {
  return (
    <div className="otp-wrapper">
      <div className="container-fluid">
        <div className="otp-wrapper-logo">
          <Link to="/" title="healthya.co.uk">
            <img src={logo} className="AlldayDrlogo" alt="Healthyalogo" />
          </Link>
        </div>
      </div>
      <div className="login-title-wrapper">
        <div className="container-fluid">
          <h1 className="login-title-h1">Set Password</h1>
        </div>
      </div>
      <ErrorBoundary>
        <ResetOTPContainer />
      </ErrorBoundary>
    </div>
  );
}

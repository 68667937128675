import React, {PureComponent, Fragment} from "react";
import {debounce, isArray} from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actions as patientActions, onPatientSelect} from "reducers/patients";
import moment from "moment";

import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";
// import {Pagination} from "../../components/common";
import "assets/css/pages/patient-search.css";
import {appRoutesConst, navigateTo} from "../../app/navigation";
import store from "../../app/store";
import {onSetPatient, onSetPatientAppointment} from "reducers/patients";
import {
  bookAppointmentFromPatientAPI,
  startAppointment,
  updateAppointmentFromPatientAPI,
} from "services/appointments";
import {DATE_FORMAT} from "constants/common";
import {getTimeSlotsForCorp, getTimeSlotsForDoctor} from "services/rota";
import BookAppointmentForPatient from "./BookAppointmentForPatient";
import {getPatients} from "services/patients";
import isEmpty from "lodash/isEmpty";
import {round} from "lodash";
// import CommonLoader from "components/common/CommonLoader";

class BookAppointmentForPatientContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchedPatients: [],
      paginationData: null,
      search: "",
      patients: null,
      show: false,
      isShowBookAppointment: false,
      patient_id: null,
      isLoading: false,
      selectedSlotTime: null,
      selecetdDate: null,
      selectedAptType: "video",
      appointment_tag: null,
      doctor_id: props.doctor_id,
      allPatients: [],
      isLoadmore: false,
    };
    ["_handleSearch", "_onPageChange", "_showModal"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.searchUpdate = debounce(this._handleSearch, 1000);
  }

  // componentDidUpdate(){

  // }
  componentDidUpdate(prevProps, prevState) {
    // if (this.props.showBookingModal && !prevState.isBooked) {
    //   this.setState({
    //     isShowBookAppointment: true,
    //     // patient_id: item.id,
    //   });
    // }
  }
  _onPageChange = (data) => {
    let {
      patientActions: {onPageChange},
    } = this.props;
    const {page, data_count} = data;
    console.log("ON PAGE CHANGE---", data);
    let pageData = {};
    let dataCountData = {};
    if (page) {
      pageData = {
        page: page,
      };
    }
    if (data_count) {
      dataCountData = {
        data_count: data_count,
      };
    }
    if (typeof onPageChange === "function") {
      onPageChange(
        {
          page,
          search: this.state.search,
          ...pageData,
          ...dataCountData,
        },
        true
      ); //!NOTE: passig true for search patient API serialize function to prevent default response
    }
  };

  _showModal() {
    this.setState({show: true});
  }

  _openAppointment = (item) => {
    this.setState({isShowBookAppointment: true});
  };

  _handleAppointmentNavigation(data, fromPresciber) {
    let {onSetPatient} = this.props.onSetPatient;
    let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
    let {navigateTo} = this.props.navigateTo;
    let {patient_id} = data;

    if (typeof onSetPatient === "function") {
      onSetPatient({id: patient_id});
    }

    if (typeof onSetPatientAppointment === "function") {
      onSetPatientAppointment(data);
    }

    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.walkinAppointment);
    }
  }

  _startAppointment = async (item) => {
    console.log("item==>", item);
    try {
      await this.setState({isLoading: true});
      let response = await startAppointment({
        patient_id: item.id,
        start_time: moment().format(),
      });
      // await this.setState({
      //   referralNote: verifyObject(response, "data.data.referral_note", null),
      //   isLoading: false,
      // });
      this._handleAppointmentNavigation(response.data.data);
      await this.setState({isLoading: false});

      console.log("response", response);
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  };

  _handleNavigation(id, fromSearchListing) {
    // if (fromPrescriber) {
    //   console.log("from prescriber    -----idd--->>", id, fromPrescriber);
    // } else {
    if (fromSearchListing) {
      store.dispatch({type: "IS_CLICKED_VIEW", payload: true});
    }
    let {onPatientSelect} = this.props.onPatientSelect;
    if (typeof onPatientSelect === "function") {
      onPatientSelect({id});
      store.dispatch({type: "ON_CLICK_NAME_UPCOMING", payload: false});
    }
    // }
  }

  clearSearchedPatients = () => {
    this.setState({searchedPatients: [], search: "", patients: null});
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day").subtract("day", 1);
  };

  handleCloseModal = (isCancel) => {
    this.setState({
      isShowBookAppointment: false,
      selecetdDate: null,
      selectedAptType: "video",
      selectedSlotTime: null,
      doctor_id: this.props.doctor_id,
      appointment_tag: null,
    });
    if (!isCancel) {
      if (
        this.props.fetchAllRotas &&
        typeof this.props.fetchAllRotas === "function"
      ) {
        this.props.fetchAllRotas();
      }
    }
    this.props.closeBookingModal();
  };

  handleDatepicker = async (date, dateString) => {
    console.log(
      "🚀 ~ BookAppointmentForPatientContainer ~ handleDatepicker= ~ date:",
      date
    );
    let errors = null;
    errors = Object.assign("", this.state.errors);
    delete errors["date"];
    if (date) {
      this.setState(
        {
          date: moment.utc(dateString),
          errors: errors,
          selecetdDate: date,
        },
        () => {
          this.onGetTimeSlots();
        }
      );
    } else {
      this.setState({date: null, selecetdDate: null, selectedSlotTime: null});
    }
  };

  onGetTimeSlots = async () => {
    const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
    let {corporate_id} = this.props;
    let {doctor_id} = this.state;

    try {
      await this.setState({
        loadingSlots: true,
        timeSlots: [],
        slotDuration: [],
      });
      let docIdParams = {
        doctor_id: doctor_id,
      };
      if (this.state.doctor_id === "all") {
        docIdParams = {};
      }
      let patientIdParams = {};

      if (this.state.patient_id || this.props.patient_id) {
        patientIdParams = {
          patient_id: this.state.patient_id
            ? this.state.patient_id
            : this.props.patient_id,
        };
      }
      let response =
        this.state.doctor_id === "all"
          ? await getTimeSlotsForCorp({
              // date: this.state.selecetdDate,
              // doctor_id: user.id,
              // zone: time_zone,
              // ...docIdParams,
              session_type:
                this.state.selectedAptType === "video"
                  ? "online_appointment"
                  : "offline_appointment",
              date: moment(this.state.selecetdDate).format(DATE_FORMAT),
              corporate_id: corporate_id,
              ...patientIdParams,
              // session_type: this.state.selectedAptType,
            })
          : await getTimeSlotsForDoctor({
              date: this.state.selecetdDate,
              // doctor_id: user.id,
              zone: time_zone,
              ...docIdParams,
              slot_for:
                this.state.selectedAptType === "video"
                  ? "online_appointment"
                  : "offline_appointment",
              ...patientIdParams,
              // date: moment(this.state.selecetdDate).format(DATE_FORMAT),
              // corporate_id: user?.corporate_id ,
              // session_type: this.state.selectedAptType,
            });
      console.log("Slot Response", response);
      if (response.data.common && response.data.common.slot_durations) {
        let slotDuration = response.data.common.slot_durations.split(",");
        console.log("slotDuration", slotDuration);
        this.setState({
          slotDuration: slotDuration,
        });
      }
      if (response.data.data) {
        const transformedArray = verifyObject(response, "data.data", []).map(
          (item) => {
            const label = moment(item.start_time).format("hh:mm A");
            const value = [item.start_time, item.end_time];

            return {label, value};
          }
        );
        this.setState({timeSlots: transformedArray});
      }
      this.setState({loadingSlots: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loadingSlots: false});
      errorToast({content: message});
    }
  };

  handleAppointmentSubmission = async (allowedSlotTypes, booking_reason) => {
    const {selectedSlotTime, selectedAptType, patient_id} = this.state;
    const {corporate_id} = this.props;
    let {doctor_id} = this.state;

    let formData = new FormData();

    if (this.props.forWholebooking) {
      formData.set(`appointment[start_time]`, selectedSlotTime[0]);
      formData.set(`appointment[end_time]`, selectedSlotTime[1]);
    } else {
      console.log("wholeSlot", this.props?.bookingRecord.wholeSlot);
      formData.set(
        `appointment[start_time]`,
        this.props?.bookingRecord.wholeSlot[0]
      );
      formData.set(
        `appointment[end_time]`,
        this.props?.bookingRecord.wholeSlot[1]
      );
    }
    if (allowedSlotTypes?.length === 1) {
      formData.set(
        `appointment[appointment_type]`,
        allowedSlotTypes?.toString()
      );
      formData.set(
        `book_via`,
        allowedSlotTypes.toString() === "video" ? "ad_hoc" : "slot"
      );
    } else {
      formData.set(`appointment[appointment_type]`, selectedAptType);
      formData.set(`book_via`, selectedAptType === "video" ? "ad_hoc" : "slot");
    }
    formData.set(`appointment[appointment_tag]`, this.state.appointment_tag);
    booking_reason &&
      booking_reason.length > 0 &&
      formData.set(
        `appointment[comments]`,
        isArray(booking_reason)
          ? booking_reason.map((item) => item.label).join(",")
          : booking_reason
      );
    formData.set(`duration`, 15);
    formData.set(`corporate_id`, corporate_id);
    formData.set(`book_from_dp`, true);
    if (this.props.fromConsultation || this.props.inEditMode) {
      formData.set(`patient_id`, this.props.patient_id);
    } else {
      formData.set(`patient_id`, patient_id);
    }
    if (doctor_id !== "all") {
      formData.set(`doctor_id`, doctor_id);
    }
    if (this.props.inEditMode) {
      this.eventDrop();
    } else {
      try {
        await this.setState({adding: true});
        let response = await bookAppointmentFromPatientAPI(formData);
        if (response.status === 200) {
          if (response.data.message) {
            await this.setState({adding: false});
            successToast({content: response.data.message});

            this.handleCloseModal();
          }
        }
        await this.setState({adding: false, isBooked: true});
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({adding: false});
        errorToast({content: message});
      }
    }

    // let formData = new FormData();

    // selectedSlotTime &&
    //   formData.set(`appointment[start_time]`, selectedSlotTime[0]);
    // selectedSlotTime &&
    //   formData.set(`appointment[end_time]`, selectedSlotTime[1]);
    // pre_consult_id &&
    //   formData.set(`appointment[pre_consult_id]`, pre_consult_id);

    // formData.set(`appointment[appointment_type]`, "video");
  };

  handleAptTypeChange = ({target: {value}}) => {
    this.setState(
      {
        selectedAptType: value,
        selectedSlotTime: null,
      },
      () => {
        if (this.state.selecetdDate) {
          this.onGetTimeSlots();
        }
      }
    );
  };

  handleSelectChange = (value, key) => {
    console.log("🚀 ~ BookAppointmentForPatientContainer ~ value:", value);
    if (value) {
      this.setState({[key]: value, isDocCleared: false}, () => {
        if (
          this.state.doctor_id &&
          this.state.selecetdDate &&
          this.state.selectedAptType
        ) {
          this.onGetTimeSlots();
        }
      });
    } else {
      this.setState({
        [key]: null,
        selecetdDate: null,
        selectedSlotTime: null,
        isDocCleared: true,
      });
    }
  };

  handleSelectChange1 = (value, key) => {
    this.setState({[key]: value}, () => {
      // if (
      //   this.state.doctor_id &&
      //   this.state.selecetdDate &&
      //   this.state.selectedAptType
      // ) {
      //   this.onGetTimeSlots();
      // }
    });
  };

  eventDrop = async (e) => {
    let reqObj = {
      id: this.props.bookingRecord?.eventId,
      start_time: this.state.selectedSlotTime[0],
      user_id: this.props?.bookingRecord?.resourceID,
    };
    // Modal.de
    try {
      // messageConfig.info({
      //   content: "Updating Appointment...",
      //   closable: false,
      //   cancelButtonProps: {
      //     style: {
      //       display: "none",
      //     },
      //   },
      //   okButtonProps: {
      //     style: {
      //       display: "none",
      //     },
      //   },
      // });
      this.setState({
        updatingApt: true,
        adding: true,
      });
      let response = await updateAppointmentFromPatientAPI(reqObj);
      console.log("response", response);
      // setCalendarEvents(allEvents);
      if (response.data.status === 200) {
        successToast({
          content: response.data.message,
        });
        // this._fetchAppointmentCalendarSlots();
        this.setState({
          updatingApt: false,
          adding: false,
        });
        this.handleCloseModal();
      }
    } catch (e) {
      const {message} = getErrorObject(e);
      // this._fetchAppointmentCalendarSlots();
      errorToast({
        content: message,
      });
      // messageConfig.destroyAll();
      this.setState({
        updatingApt: false,
        adding: false,
      });
    }
    console.log("reqObj", reqObj);
  };

  onChangeSlots = ({target: {value}}) => {
    console.log(
      "radio3 checked",
      value,
      moment(value).add(15, "minutes").format()
    );
    // setSlotTime(value);
    this.setState({
      selectedSlotTime: value,
    });
  };
  async _handleSearch(value) {
    console.log("VALUE", value);
    // debugger;
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          allPatients: [],
          search: value,
        },
        async () => {
          this.GetPatients(false, value);
        }
      );
    } else {
      await this.setState({allPatients: []});
    }
  }

  GetPatients = async (isLoadMore, search) => {
    try {
      await this.setState({loading: true});
      let response = await getPatients({
        page: this.state.page,
        search: search && isArray(search) ? search.join(",") : search,
      });
      await this.setState({searching: false});
      let allPatients = response.data.data;
      if (allPatients && allPatients.length > 0) {
        let pagination =
          verifyObject(response, "headers['x-pagination']", null) !== null
            ? JSON.parse(response.headers["x-pagination"])
            : null;
        this.setState(
          {
            allPatients: isLoadMore
              ? [...this.state.allPatients, ...allPatients]
              : allPatients,

            pagination: pagination,
          },
          () => {
            console.log("allPatients", this.state.allPatients);
          }
        );
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loading: false});
      errorToast({content: message});
    }
  };
  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this.GetPatients(true, this.state.search);
      }
    );
  };
  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;
    console.log("🚀 ~ BookAppointmentForPatientContainer ~ page:", page);
    console.log(
      "🚀 ~ BookAppointmentForPatientContainer ~ total_pages:",
      total_pages
    );
    console.log(
      "🚀 ~ BookAppointmentForPatientContainer ~ scrollHeight - scrollTop:",
      round(scrollHeight - scrollTop)
    );
    console.log(
      "🚀 ~ BookAppointmentForPatientContainer ~ clientHeight:",
      clientHeight
    );

    if (
      round(scrollHeight - scrollTop) === clientHeight &&
      page < total_pages
    ) {
      this.fetchMoreData();
    }
  };

  render() {
    // let {isLoading} = this.props.patients;
    console.log("this.props records", this.props);
    return (
      <Fragment>
        <div className="rota-wrapper patient-search-container">
          <div className="rota-wrapper-row">
            {/* <div className="rota-wrapper-col width-25"></div> */}
            <div className="rota-wrapper-col width-100 padding-bottom-50 padding-left-right-20px margin-bottom-25">
              <div className="patient-results">
                {this.props.showBookingModal && !this.props.forWholebooking && (
                  <BookAppointmentForPatient
                    visible={this.props.showBookingModal}
                    doctor_id={
                      this.props.fromConsultation
                        ? this.props.doctor_id
                        : this.props?.bookingRecord?.resourceID || null
                    }
                    allowedSlotTypes={this.props.allowedSlotTypes}
                    isDocCleared={this.state.isDocCleared}
                    disabledDate={this.disabledDate}
                    handleCloseModal={this.handleCloseModal}
                    handleDatepicker={this.handleDatepicker}
                    selecetdDate={
                      this.props.inEditMode
                        ? this.state.selecetdDate
                        : this.props?.bookingRecord?.apptDate || null
                    }
                    doctorName={this.props?.bookingRecord?.doctor_name || null}
                    handleSubmission={this.handleAppointmentSubmission}
                    isFetchingSlot={this.state.loadingSlots}
                    slotsArray={this.state.timeSlots}
                    onChangeSlots={this.onChangeSlots}
                    selectedSlotTime={this.state.selectedSlotTime}
                    inEditMode={this.props.inEditMode}
                    patient_id={
                      this.props.fromConsultation || this.props.inEditMode
                        ? this.props.patient_id
                        : this.state?.patient_id
                    }
                    bookingAppointment={this.state.adding}
                    handleAptTypeChange={this.handleAptTypeChange}
                    selectedAptType={
                      this.props.inEditMode
                        ? this.props?.bookingRecord?.selectedAptType
                        : this.state.selectedAptType
                    }
                    handleSelectChange={this.handleSelectChange}
                    handleSelectChange1={this.handleSelectChange1}
                    appointment_tag={this.state.appointment_tag}
                    setPatient={(id) => {
                      this.setState({
                        patient_id: id,
                      });
                    }}
                    forWholebooking={this.props.inEditMode}
                    handlePatientSearch={this.searchUpdate}
                    allPatients={this.state.allPatients}
                    handleScroll={this.handleScroll}
                    searching={this.state.searching}
                    isLoadmore={this.state.isLoadmore}
                    passedPatientName={this.props.passedPatientName}
                  />
                )}
                {this.props.showBookingModal && this.props.forWholebooking && (
                  <BookAppointmentForPatient
                    visible={this.props.showBookingModal}
                    doctor_id={
                      this.props.fromConsultation
                        ? this.props.doctor_id
                        : this.state.doctor_id
                    }
                    allowedSlotTypes={this.props.allowedSlotTypes}
                    disabledDate={this.disabledDate}
                    handleCloseModal={this.handleCloseModal}
                    handleDatepicker={this.handleDatepicker}
                    selecetdDate={this.state.selecetdDate}
                    doctorName={this.props?.bookingRecord?.doctor_name || null}
                    handleSubmission={this.handleAppointmentSubmission}
                    isFetchingSlot={this.state.loadingSlots}
                    slotsArray={this.state.timeSlots}
                    onChangeSlots={this.onChangeSlots}
                    selectedSlotTime={this.state.selectedSlotTime}
                    forWholebooking={this.props.forWholebooking}
                    patient_id={
                      this.props.fromConsultation
                        ? this.props.fromConsultation
                        : this.state?.patient_id
                    }
                    isDocCleared={this.state.isDocCleared}
                    bookingAppointment={this.state.adding}
                    handleAptTypeChange={this.handleAptTypeChange}
                    selectedAptType={this.state.selectedAptType}
                    handleSelectChange={this.handleSelectChange}
                    handleSelectChange1={this.handleSelectChange1}
                    appointment_tag={this.state.appointment_tag}
                    setPatient={(id) => {
                      this.setState({
                        patient_id: id,
                      });
                    }}
                    fromConsultation={this.props.fromConsultation}
                    handlePatientSearch={this.searchUpdate}
                    allPatients={this.state.allPatients}
                    handleScroll={this.handleScroll}
                    searching={this.state.searching}
                    isLoadmore={this.state.isLoadmore}
                    passedPatientName={this.props.passedPatientName}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: verifyObject(state, "patients", []),
    userRole: verifyObject(state, "localStore.user.role", null),
    doctor_id: verifyObject(state, "localStore.user.id", null),
    corporate_id: verifyObject(state, "localStore.user.corporate_id", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    patientActions: bindActionCreators(patientActions, dispatch),
    navigationAction: bindActionCreators({navigateTo}, dispatch),
    onPatientSelect: bindActionCreators({onPatientSelect}, dispatch),
    onSetPatientAppointment: bindActionCreators(
      {onSetPatientAppointment},
      dispatch
    ),
    onSetPatient: bindActionCreators({onSetPatient}, dispatch),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BookAppointmentForPatientContainer);

export default ConnectedComponent;
import React, { Fragment } from 'react'
import { Table, Space } from 'antd';
import {  verifyObject, getErrorObject, errorToast, modifiedDataObjectToArrayByIDs } from '../../utilities/utils';
import { DownloadOutlined } from '@ant-design/icons';
import { setPolicyDownloadForDoctor } from '../../services/staffIntranet';

export default function StaffIntranetTable({ dataSource, _onRequestList }) {

    // let dataObject = verifyObject(dataSource, "data", {})
    let loading = verifyObject(dataSource, "isLoading", false)
    let staffIntranetArray = modifiedDataObjectToArrayByIDs(dataSource)

    const _handleDownload = async (policy_id) => {
        try {
            await setPolicyDownloadForDoctor({ policy_id });
            await _onRequestList()
        } catch (error) {
            const { message } = getErrorObject(error);
            errorToast({ content: message })
        }
    }

    const columns = [
        {
            title: 'File Name',
            key: 'file_name',
            dataIndex: 'file_name',
            className: 'min-height-30 width-90',
            render: (text, item) =>
                <Fragment>
                    {item.file_name ? <a target='_blank'rel="noreferrer" className="font-12 text-dark-blue text-capitalize font-weight-400 hover-blue" href={verifyObject(item, "url", "#")}>{verifyObject(item, "file_name", "--")}</a> : "--"}
                </Fragment>
        },
        {
            title: 'Action',
            dataIndex: "url",
            align: 'right',
            key: 'url',
            className: 'min-height-30 width-10',
            render: (url, record) => {
                let { id, is_downloaded } = record
                return (
                    <Space size="middle">
                        <a rel="noreferrer" onClick={() => _handleDownload(id)} target="_blank" href={url ? url : "#"} download title="Download">
                            <DownloadOutlined style={{ fontSize: "26px", color: is_downloaded ? "#52df9a" : "#8c8c8c" }} />
                        </a>
                    </Space >
                )
            },
        },
    ];



    return (
        <Fragment>
            <Table pagination={false} loading={loading} columns={columns} dataSource={staffIntranetArray} />
        </Fragment>
    )
}

import { getQuestionnaireApi } from 'services/questionnaireReview'
import { listingReducer } from 'reducers/utils/listingReducer'

const { reducer, actions, types } = listingReducer({
	name: `questionnaireReview`,
	getApi: getQuestionnaireApi,
})

const questionnaireReview = reducer
export { questionnaireReview, actions, types }

import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Form, Input, Button, Checkbox } from "antd";
import { withRouter } from "react-router-dom";

import { actions as profileActions } from "reducers/userProfile";
import { logOut } from "reducers/session";
import { errorToast, getErrorObject } from "utilities/utils";
import CommonLoader from "../common/CommonLoader";

class BankDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: this.props.profile.isLoading,
      profileImage: [],
      fileList: [],
      allSpecialities: [],
      allLanguages: [],
      errors: {
        company_details: {},
        bank_details: {},
      },
      company_details: {},
      bank_details: {},
      ...this.props.profile,
    };

    let fns = [
      "_onChange",
      "_onPreview",
      "_onLogOut",
      "_onSubmit",
      "_onInputChange",
      "_onDropdownChange",
      "_onBankDetailsUpdate",
      "_onBankDetailsAdd",
      "_onCertificateUpload",
      "_onDateChange",
    ];
    fns.map((fn) => (this[fn] = this[fn].bind(this)));
  }

  _handleLanguages = (val) => {
    this.setState({
      profile: {
        ...this.state.profile,
        languages: val,
      },
    });
  };

  _onDateChange = (date, dateString) => {
    this.setState({
      company_details: {
        ...this.state.company_details,
        date_of_incorporate: date,
      },
    });
  };

  _onChange = ({ file }) => {
    this.setState({
      profileImage: file,
    });
    this._onSubmit({
      image: file,
      image_file_name: file.name,
    });
  };

  _onCertificateUpload = ({ file }) => {
    this.setState({
      company_details: {
        ...this.state.company_details,
        incorporate_certificate: file,
      },
    });
  };

  _onLogOut = () => {
    let { logOut } = this.props;
    if (typeof logOut === "function") {
      logOut();
    }
  };

  _onInputChange = (label, name, e) => {
    this.setState({
      [label]:
        e && e.target
          ? {
              ...this.state[label],
              [e.target.name]:
                e.target.type === "checkbox"
                  ? e.target.checked
                  : e.target.value,
            }
          : {
              ...this.state[label],
              [name]: e,
            },
    });
  };

  _onDropdownChange = (label, name, val) => {
    this.setState({
      [label]: {
        ...this.state[label],
        [name]: val,
      },
    });
  };

  _onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  _onSubmit = async (data) => {
    try {
      this.setState({ isLoading: true });
      await this.props.profileActions.updateProfile({
        id: this.props.user.id,
        ...data,
      });
      // successToast({ content: response.data.message })
      this.setState({ isLoading: false });
    } catch (e) {
      this.setState({ isLoading: false });
      const error = getErrorObject(e);
      errorToast({ content: error.message });
    }
  };

  _onBankDetailsAdd = async (_) => {
    let { bank_details } = this.state;
    console.log("Bank Details", this.props.user);
    let data = {
      user_id: this.props.user.id,
      bank_name:
        bank_details && bank_details.bank_name ? bank_details.bank_name : "",
      address: bank_details && bank_details.address ? bank_details.address : "",
      postcode:
        bank_details && bank_details.postcode ? bank_details.postcode : "",
      name_on_bank_account:
        bank_details && bank_details.name_on_bank_account
          ? bank_details.name_on_bank_account
          : "",
      sort_code:
        bank_details && bank_details.sort_code ? bank_details.sort_code : "",
      account_number:
        bank_details && bank_details.account_number
          ? bank_details.account_number
          : "",
      payment_reference:
        bank_details && bank_details.payment_reference
          ? bank_details.payment_reference
          : "",
      supplier_reference:
        bank_details && bank_details.supplier_reference
          ? bank_details.supplier_reference
          : "",
      iban: bank_details && bank_details.iban ? bank_details.iban : "",
      swift_code:
        bank_details && bank_details.swift_code ? bank_details.swift_code : "",
      roll_number:
        bank_details && bank_details.roll_number
          ? bank_details.roll_number
          : "",
      exp: bank_details && bank_details.exp ? bank_details.exp : false,
      additional_information:
        bank_details && bank_details.additional_information
          ? bank_details.additional_information
          : "",
      website_profile:
        bank_details && bank_details.website_profile
          ? bank_details.website_profile
          : "",
      payroll_information_authorized:
        bank_details && bank_details.payroll_information_authorized
          ? bank_details.payroll_information_authorized
          : false,
    };

    await this.setState({
      errors: {
        ...this.state.errors,
        bank_details: {
          ...this.state.errors.bank_details,
          bank_name: !data.bank_name ? "Please enter bank name" : null,
          name_on_bank_account: !data.name_on_bank_account
            ? "Please enter the name on bank account"
            : null,
          account_number: !data.account_number
            ? "Please enter account number"
            : null,
        },
      },
    });

    let errCount = 0;
    Object.values(this.state.errors.bank_details).forEach(
      (err) => err !== null && errCount++
    );
    if (errCount === 0) {
      await this.props.profileActions.addBankDetails(data);
    }
  };

  _onBankDetailsUpdate = async (_) => {
    let { bank_details } = this.state;
    let data = {
      id: bank_details.id,
      bank_name:
        bank_details && bank_details.bank_name ? bank_details.bank_name : "",
      account_number:
        bank_details && bank_details.account_number
          ? bank_details.account_number
          : "",
      address: bank_details.address ? bank_details.address : "",
      postcode: bank_details.postcode ? bank_details.postcode : "",
      sort_code: bank_details.sort_code ? bank_details.sort_code : "",
      payment_reference: bank_details.payment_reference
        ? bank_details.payment_reference
        : "",
      name_on_bank_account:
        bank_details && bank_details.name_on_bank_account
          ? bank_details.name_on_bank_account
          : "",
      supplier_reference: bank_details.supplier_reference
        ? bank_details.supplier_reference
        : "",
      iban: bank_details.iban ? bank_details.iban : "",
      swift_code: bank_details.swift_code ? bank_details.swift_code : "",
      roll_number: bank_details.roll_number ? bank_details.roll_number : "",
      exp: bank_details.exp ? bank_details.exp : false,
      additional_information: bank_details.additional_information
        ? bank_details.additional_information
        : "",
      website_profile: bank_details.website_profile
        ? bank_details.website_profile
        : "",
      payroll_information_authorized: bank_details.payroll_information_authorized
        ? bank_details.payroll_information_authorized
        : false,
    };

    await this.setState({
      errors: {
        ...this.state.errors,
        bank_details: {
          ...this.state.errors.bank_details,
          bank_name: !data.bank_name ? "Please enter bank name" : null,
          name_on_bank_account: !data.name_on_bank_account
            ? "Please enter the name on bank account"
            : null,
          account_number: !data.account_number
            ? "Please enter account number"
            : null,
        },
      },
    });

    let errCount = 0;
    Object.values(this.state.errors.bank_details).forEach(
      (err) => err !== null && errCount++
    );
    if (errCount === 0) {
      await this.props.profileActions.updateBankDetails(data);
    }
  };

  async componentDidMount() {
    await this.props.profileActions.getProfile({ id: this.props.user.id });
    this.props.profile &&
      this.setState({
        ...this.props.profile.profile,
      });
  }
  render() {
    let { errors, bank_details } = this.state;

    return (
      <div className="personaldetailsmain_wrapper">
        <div className="padding-bottom-60 personaldetailsmain">
          {this.props.profile.isLoading ? (
            <CommonLoader />
          ) : (
            <Form name="bank_details_form">
              <div className="container-fluid p-0 bank-details-form">
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Bank Name*
                    </label>
                    <Input
                      name="bank_name"
                      // disabled={!!bank_details}
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.bank_name}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    {errors.bank_details.bank_name && (
                      <p className="form-error">
                        {errors.bank_details.bank_name}
                      </p>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Address
                    </label>
                    <Input
                      name="address"
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.address}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Post Code
                    </label>
                    <Input
                      name="postcode"
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.postcode}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Name on Bank Account*
                    </label>
                    <Input
                      // disabled={!!bank_details}
                      name="name_on_bank_account"
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.name_on_bank_account}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    {errors.bank_details.name_on_bank_account && (
                      <p className="form-error">
                        {errors.bank_details.name_on_bank_account}
                      </p>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Sort Code
                    </label>
                    <Input
                      name="sort_code"
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.sort_code}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.phone && (
                        <p className="form-error">{this.state.errors.phone}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Account Number*
                    </label>
                    <Input
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      // disabled={!!bank_details}
                      value={bank_details && bank_details.account_number}
                      name="account_number"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    {errors.bank_details.account_number && (
                      <p className="form-error">
                        {errors.bank_details.account_number}
                      </p>
                    )}
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Payment Reference
                    </label>
                    <Input
                      name="payment_reference"
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.payment_reference}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.phone && (
                        <p className="form-error">{this.state.errors.phone}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Supplier Reference
                    </label>
                    <Input
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.supplier_reference}
                      name="supplier_reference"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      IBAN
                    </label>
                    <Input
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.iban}
                      name="iban"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Swift Code
                    </label>
                    <Input
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.swift_code}
                      name="swift_code"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Roll Number
                    </label>
                    <Input
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.roll_number}
                      name="roll_number"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Additional Information
                    </label>
                    <Input
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={
                        bank_details && bank_details.additional_information
                      }
                      name="additional_information"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Website Profile
                    </label>
                    <Input
                      // className='field-status'
                      onChange={(e) =>
                        this._onInputChange("bank_details", null, e)
                      }
                      value={bank_details && bank_details.website_profile}
                      name="website_profile"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div
                      className="field-status"
                      //   onChange={(e) =>
                      //     this._onInputChange("bank_details", null, e)
                      //   }
                      //   value={bank_details && bank_details.website_profile}
                      //   name="website_profile"
                    >
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12">
                    <Checkbox
                      name="payroll_information_authorized"
                      onChange={(e) =>
                        this._onInputChange(
                          "bank_details",
                          "payroll_information_authorized",
                          e
                        )
                      }
                      checked={
                        bank_details &&
                        bank_details.payroll_information_authorized
                      }
                      className="mr-2"
                    >
                      Is payroll information authorized?
                    </Checkbox>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12">
                    <Checkbox
                      onChange={(e) =>
                        this._onInputChange("bank_details", "exp", e)
                      }
                      checked={bank_details && bank_details.exp}
                      name="exp"
                      className="mr-2"
                    >
                      Exp
                    </Checkbox>
                  </div>
                </div>
                <div className="form-row mt-4">
                  <div className="col-sm-12">
                    {this.props.profile &&
                    this.props.profile.profile.bank_details ? (
                      <Button
                        className="width-220px height-40px-imp btn btn-blue btn-custom margin-right-10"
                        // loading={this.props.profile.isLoading}
                        onClick={this._onBankDetailsUpdate}
                      >
                        Save Changes
                      </Button>
                    ) : (
                      <Button
                        className="width-220px height-40px-imp btn btn-blue btn-custom"
                        // loading={this.props.profile.isLoading}
                        onClick={this._onBankDetailsAdd}
                      >
                        Add Bank Details
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.localStore.user,
    profile: state.userProfile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
    logOut: bindActionCreators(logOut, dispatch),
  };
};
let connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BankDetails);
export default withRouter(connectedComponent);

import React from 'react'
import { Table } from 'antd'
import moment from 'moment'

import { currencies, modifiedDataObjectToArray, verifyObject } from '../../utilities/utils'
import imguser from '../../assets/images/common/doctor_profile.svg'
import ascendingsort from 'assets/images/common/ascending-sort.svg'
import calender_icon from 'assets/images/common/calander.svg'
import card_icon from 'assets/images/common/card_icon.svg'
import { DATE_FORMAT } from 'constants/common'

export default function OrderPaymentList({ dataSource, _onReuestList }) {
	let dataObject = verifyObject(dataSource, 'data', {})
	let loading = verifyObject(dataSource, 'isLoading', false)
	let reportReviews = modifiedDataObjectToArray(dataObject)
	const columns = [
		{
			key: 'name',
			title: (filters, sortOrder) => {
				return (
					<div>
						<span>Patient Name</span>{' '}
						<img
							src={ascendingsort}
							alt='Ascending Sort'
							className='push-3-l'
							title=''
						/>
					</div>
				)
			},
			dataIndex: 'name',
			className:
				'width-35 padding-top-4 padding-bottom-4 padding-left-10 padding-right-10 min-height-30 text-light-black',
			render: (text, item) => {
				return (
					<div className='d-flex report-user align-items-center'>
						<div className='up_doctor_profile'>
							<img
								alt=''
								className='mr-2'
								src={
									item.order &&
									item.order.created_by &&
									item.order.created_by.image
										? item.order.created_by.image
										: imguser
								}
							/>
						</div>
						<p className='padding-left-10 font-12 weight-400 text-light-black margin-0 text-capitalize in-block doctor_profile_text'>
							{item.order &&
							item.order.created_by &&
							item.order.created_by.first_name &&
							item.order.created_by.last_name
								? `${item.order.created_by.first_name} ${item.order.created_by.last_name}`
								: '-'}
						</p>
					</div>
				)
			},
		},
		{
			key: 'id',
			title: (filters, sortOrder) => {
				return (
					<div>
						<span>ID</span>{' '}
						<img
							src={ascendingsort}
							alt='Ascending Sort'
							className='push-3-l'
							// alt='Ascending Sort'
							title=''
						/>
					</div>
				)
			},
			dataIndex: 'id',
			className:
				'width-28 padding-top-4 padding-bottom-4 padding-left-10 padding-right-10 min-height-30 text-light-black',
			render: (text) => {
				return (
					<p className='font-12 weight-400 margin-0 text-light-black text-capitalize'>
						#{text}
					</p>
				)
			},
		},
		{
			key: 'created_at',
			title: (filters, sortOrder) => {
				return (
					<div>
						<span>Created At</span>{' '}
						<img
							src={ascendingsort}
							alt='Ascending Sort'
							className='push-3-l'
							// alt='Ascending Sort'
							title=''
						/>
					</div>
				)
			},
			dataIndex: 'created_at',
			className:
				'width-29 padding-top-4 padding-bottom-4 padding-left-10 padding-right-10 min-height-30 text-dark-gray',
			render: (text) => {
				return (
					<p className='font-12 weight-500 margin-0 text-dark-gray'>
						<img
							alt='Calender'
							src={calender_icon}
							className='vertical-text-bottom push-5-r'
						></img>
						{moment(text).format(DATE_FORMAT)}
					</p>
				)
			},
		},
		{
			key: 'amount',
			title: (filters, sortOrder) => {
				return (
					<div>
						<span>Amount</span>{' '}
						<img
							src={ascendingsort}
							alt='Ascending Sort'
							className='push-3-l'
							// alt='Ascending Sort'
							title=''
						/>
					</div>
				)
			},
			dataIndex: 'amount',
			className:
				'width-115px padding-top-4 padding-bottom-4 padding-left-0 min-height-30 padding-right-10 text-light-black',
			render: (text, item) => {
				return (
					<p className='font-12 weight-400 margin-0 text-light-black'>
						<img
							alt='Aamount'
							src={card_icon}
							className='vertical-text-bottom push-5-r'
						></img>
						{text ? `${currencies.pound.symbol}${parseInt(text,10).toFixed(2, 10)}` : '-'}
					</p>
				)
			},
		},
	]

	return (
		<div className='w-100'>
			<Table
				pagination={false}
				loading={loading}
				columns={columns}
				dataSource={reportReviews}
			/>
		</div>
	)
}

export const types = {
  SET_VIDEO_CALL_SETUP: "SET_VIDEO_CALL_SETUP",
  IS_VIDEO_CALL_STARTED: "IS_VIDEO_CALL_STARTED",
  IS_SUBSCRIBER_CONNECTED: "IS_SUBSCRIBER_CONNECTED",
};

export const onRequestSetVideoCallSetup = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_VIDEO_CALL_SETUP, payload: data });
  };
};

export const onRequestSetVideoCallStatus = (data) => {
  return (dispatch) => {
    dispatch({ type: types.IS_VIDEO_CALL_STARTED, payload: data });
  };
};



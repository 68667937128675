import {get, Irequest, post} from "./index";
export type IUsersParams = {
  role: string,
  search: string,
  sort: {first_name: string},
  is_phone_verified: boolean,
  page?: number,
  per_page: number,
};
export const allAppointmentUsersApi = (params: IUsersParams) => {
  const request: Irequest = {
    subUrl: `/doctor/appointments/fetch_clinical_users_for_appointment`,
    params,
  };
  return get(request);
};
//! NOTE: Above api is new only for booking appointmnet and below is for informational outcome using old api as per ADD-12645
export const allAppointmentUsersOldApi = (params: IUsersParams) => {
  const request: Irequest = {
    subUrl: `/doctor/appointments/list_users_for_appointment.json`,
    params,
  };
  return get(request);
};

export const getTimeSlots = (params: IUsersParams) => {
  const request: Irequest = {
    subUrl: `/doctor/appointments/slot_for_clinicians.json`,
    params,
  };
  return get(request);
};
export const bookAppointment = (params: IUsersParams) => {
  const request: Irequest = {
    subUrl: `/doctor/appointments.json`,
    data: params,
  };
  return post(request);
};

import React from "react";
import { Button, message, Modal, Tag } from "antd";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { CheckCircleOutlined } from "@ant-design/icons";
import { isArray } from "lodash";
import store from "app/store";

const AppointmentBookedModal = ({
  isBookModalVisible,
  selecteddHealthCondition,
  patientDetail,
  hideModal,
  dateAndTime,
  history,
  onSetSidebarKey,
  fromKiosk,
  // setIsBookModalVisible,
}) => {
  console.log("🚀 ~ dateAndTime:", dateAndTime);
  // const router = useRouter();
  // const [isBookModalVisible, setIsBookModalVisible] = useState(false);

  // const showBookModal = () => {
  //   setIsBookModalVisible(true);
  // };

  // const handleBookCancel = () => {
  //   setIsBookModalVisible(false);
  // };
  console.log("patientDetail", patientDetail);
  console.log("selecteddHealthCondition", selecteddHealthCondition);

  return (
    <div className="d-flex justify-content-end">
      {/* <Button type="primary" onClick={showBookModal}>
        Sucess Modal
      </Button> */}
      <Modal
        visible={isBookModalVisible} // Use `visible` instead of `open`
        keyboard={false} // Prevents closing on Esc key
        maskClosable={false} // Prevents closing on outside click
        // title="Booked"
        width={392}
        footer={null} // Hides the footer
        destroyOnClose
        className="successmodal"
        onCancel={hideModal}
      >
        <div className="d-flex flex-column gapy-3 mt-4">
          <div className="successhead d-flex flex-wrap gapx-2 align-items-center justify-content-center mb-1">
            <CheckCircleOutlined className="font-22 text-success" />
            <b className="text-success">Appointment Booked Successfully</b>
          </div>
          <div className="d-flex flex-wrap align-items-center gapx-2 contentinfo info-parent">
            <b>Patient Info:</b>
            <span>{patientDetail?.name}</span>
            <span>({patientDetail?.age} yrs)</span>
            <span className={`gender ${patientDetail?.gender}`}>
              {patientDetail?.gender === "male" ? "M" : "F"}
            </span>
          </div>
          <div className="d-flex flex-wrap alingn-items-center gapx-1 contentinfo">
            <b>Phone:</b>
            {patientDetail?.phone}
          </div>
          <div className="d-flex flex-wrap align-items-center gapx-2 contentinfo">
            <b>Email:</b>
            <span id="emailText">{patientDetail?.email}</span>
            <a
              className="d-flex align-items-center gapx-1"
              onClick={async () => {
                let text = document.getElementById("emailText").innerHTML;
                try {
                  await navigator.clipboard.writeText(text);
                  // console.log('Content copied to clipboard');
                  message.success("Email copied to clipboard");
                } catch (err) {
                  console.error("Failed to copy: ", err);
                }
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17"
                height="17"
                viewBox="0 0 17 17"
                fill="none"
                className="push-l-4"
              >
                <path
                  d="M12.2266 2.5C12.1779 2.5 7.51227 2.5 7.51227 2.5C6.68098 2.5 5.94085 3.22788 5.94085 4.00001L5.48671 4.01013C4.65581 4.01013 3.97656 4.72787 3.97656 5.5V13C3.97656 13.7721 4.71671 14.5 5.548 14.5H11.4409C12.2721 14.5 13.0123 13.7721 13.0123 13H13.4051C14.2364 13 14.9766 12.2721 14.9766 11.5V5.50901L12.2266 2.5ZM11.4409 13.75H5.548C5.1355 13.75 4.76229 13.3822 4.76229 13V5.5C4.76229 5.11788 5.09819 4.76237 5.51069 4.76237L5.94085 4.75V11.5C5.94085 12.2721 6.68098 13 7.51227 13H12.2266C12.2266 13.3821 11.8533 13.75 11.4409 13.75ZM14.1909 11.5C14.1909 11.8821 13.8176 12.25 13.4051 12.25H7.51227C7.09977 12.25 6.72656 11.8821 6.72656 11.5V4.00001C6.72656 3.61789 7.09977 3.25001 7.51227 3.25001H11.4409C11.4345 4.11364 11.4409 4.75939 11.4409 4.75939C11.4409 5.53864 12.1755 6.25001 13.0123 6.25001C13.0123 6.25001 13.4295 6.25001 14.1909 6.25001V11.5ZM13.0123 5.5C12.5939 5.5 12.2266 4.77438 12.2266 4.38513C12.2266 4.38513 12.2266 3.99062 12.2266 3.26163V3.26087L14.1909 5.5H13.0123ZM12.2266 7.75676H8.69085C8.474 7.75676 8.298 7.92439 8.298 8.13138C8.298 8.33838 8.474 8.506 8.69085 8.506H12.2266C12.4434 8.506 12.6194 8.33837 12.6194 8.13138C12.6194 7.92439 12.4434 7.75676 12.2266 7.75676ZM12.2266 9.62988H8.69085C8.474 9.62988 8.298 9.79751 8.298 10.0045C8.298 10.2115 8.474 10.3791 8.69085 10.3791H12.2266C12.4434 10.3791 12.6194 10.2115 12.6194 10.0045C12.6194 9.79751 12.4434 9.62988 12.2266 9.62988Z"
                  fill="#808396"
                />
              </svg>
            </a>
          </div>
          {!fromKiosk && (
            <div className="d-flex flex-wrap align-items-center gapx-2 contentinfo">
              <b>Condition:</b>
              <span> {selecteddHealthCondition?.name}</span>
            </div>
          )}
          <div className="d-flex flex-wrap align-items-center gapx-2 contentinfo">
            <b>Date:</b>
            <span>
              {moment(
                new Date(
                  isArray(dateAndTime) && dateAndTime?.length > 0
                    ? new Date(dateAndTime[0])
                    : dateAndTime
                )
              )?.format("dddd DD MMM")}
            </span>
            <span>
              {moment(
                new Date(
                  isArray(dateAndTime) && dateAndTime?.length > 0
                    ? dateAndTime[0]
                    : dateAndTime
                )
              )?.format("hh:mm A")}
            </span>
            <Tag className="ant-tag-has-color">
              15 Min {/* {selecteddHealthCondition?.created_at} */}
            </Tag>
          </div>
        </div>
        <div className="mt-4 modalactionbuttons d-flex justify-content-center">
          <Button
            onClick={() => {
              // onSetSidebarKey(["APPT1"]);
              store.dispatch({
                type: "SET_SIDEBAR_KEY",
                payload: ["APPT1"],
              });
              history?.push("/doctor/dashboard");
            }}
            type="primary"
            className="push-r-24 w-50"
          >
            View Appointments
          </Button>
          {/* <Button
            onClick={() => history?.push("/doctor/dashboard")}
            className="w-50"
          >
            Back Home
          </Button> */}
        </div>
      </Modal>
    </div>
  );
};

export default withRouter(AppointmentBookedModal);

import { get, post } from "./index";


export const gettestInvestigationsApi = (params) => {
    const request = {
        subUrl: `/investigations.json`,
        params
    };
    return get(request);
};

export const addtestInvestigationsApi = (data) => {
    const request = {
        subUrl: `/investigations.json`,
        data
    };
    return post(request);
};



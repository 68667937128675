import React, { Fragment, useEffect, useRef } from "react";
// import moment from "moment";
import { isArray, map } from "lodash";
import { Alert, Image } from "antd";
// import { isEmpty } from "lodash";
// import { DATE_FORMAT } from "constants/common";

export default function AnswerItem({ d, index, gender }) {
  // console.log("Answer Item", d);
  const questionDivRef = useRef(null);

  useEffect(() => {
    const questionDiv = questionDivRef.current;

    if (questionDiv) {
      const nodeList = questionDiv.querySelectorAll("ul li");

      nodeList.forEach((element) => {
        // Remove the existing inline style with !important
        element.style.removeProperty("font-size");

        // Add your new style without !important
        element.style.fontSize = "16px"; // Set your desired font size
      });
    }
  }, []);

  return (
    <Fragment key={d.id}>
      {d.answer !== undefined && d.answer !== null && d.answer !== "" && (
        <div className="question_wrapper">
          {d.category === "information_text" ? (
            <p
              className="font-14 weight-500 margin-bottom-5 question"
              key={d.id}
              ref={questionDivRef}
            >
              <Alert message={d.title} type="info" />
            </p>
          ) : (
            <p
              className="font-14 weight-500 margin-bottom-5 question"
              key={d.id}
              ref={questionDivRef}
            >
              <div className="d-flex">
                <span className="font-16">Q{`${index + 1}`}. </span>
                {
                  <span
                    dangerouslySetInnerHTML={{ __html: d.title }}
                    className="ml-1"
                  />
                }
              </div>
            </p>
          )}
          <p className="font-14 weight-400 margin-bottom-0 text-default answertxt">
            {/* Ans.{d.category !== "file" && <p>{d.answer}</p>} */}
            {(d.category === "text" ||
              d.category === "single_choice" ||
              d.category === "text_area" ||d.category === "multi_choice"||
              d.category === "numerical") &&
              d.answer}
            {d.category === "drop_down" &&
              d?.pre_consult_answer?.actual_answer.map((v) => {
                return <li>{v?.selected_value}</li>;
              })}
              {}
            {/* {d.category === "multi_choice" &&
              d.answer?.split(",").map((v) => {
                return <li>{v}</li>;
              })} */}
            {d.category === "date" && d.answer}
            {d.category === "check_agree" && d.answer}

            {d.category === "file" && <Image width={100} src={d.answer} />}
            {d.imageURL}
            {d.category === "date_range" && isArray(d.answer)
              ? `${d.answer[0]}- 
                    ${d.answer[1]}`
              : " "}
          </p>
        </div>
      )}
    </Fragment>
  );
}

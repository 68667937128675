import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { actions as OrderPaymentsActions } from "reducers/orderPayments";

import OrderPaymentList from "../../components/Payments/OrderPaymentList";
import { Pagination } from "components/common";
import refresh from "assets/images/common/refresh.svg";
import { currencies, verifyObject } from "../../utilities/utils";

class OrderPaymentsContainer extends Component {
  async componentDidMount() {
    this.props.OrderPaymentsActions.onRequest({});
  }
  render() {
    let { orderPayments } = this.props;
    let earnedMoney = verifyObject(
      orderPayments,
      "extraData.common.total",
      null
    );
    return (
      <Fragment>
        <div className="custom-col-10px padding-10-t">
          <div className="form-row push-20-b">
            <div className="col-md-8">
              <a
                className="refresh_wrapper vertical-middle text-light-black margin-top-8 in-block"
                title="Refresh"
              >
                <img
                  src={refresh}
                  className="height-15"
                  alt="Refresh"
                  title="Refresh"
                ></img>{" "}
                <span className="push-2-l font-14 weight-400">Refresh</span>
              </a>
            </div>
            {earnedMoney ? (
              <div
                style={{ textAlign: "right" }}
                className="col-md-4 font-14 weight-400 margin-top-8"
              >
                Earned Money :{" "}
                {earnedMoney
                  ? `${currencies.pound.symbol}${
                      earnedMoney && earnedMoney.toFixed(2, 10)
                    }`
                  : "--"}
              </div>
            ) : (
              <div
                style={{ textAlign: "right" }}
                className="col-md-4 font-14 weight-400 margin-top-8"
              >
                Earned Money : 0
              </div>
            )}
          </div>

          <div className="payment_wrapper">
            <OrderPaymentList
              _onReuestList={this.props.OrderPaymentsActions.onRequest}
              dataSource={this.props.orderPayments}
            />
            <div className="col-md-12">
              <Pagination
                data={this.props.orderPayments}
                onPageChange={this.props.OrderPaymentsActions.onPageChange}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orderPayments: state.orderPayments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    OrderPaymentsActions: bindActionCreators(OrderPaymentsActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderPaymentsContainer);

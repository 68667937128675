import { OtherlistingReducer } from "reducers/utils/otherlistingReducer";
import { listingReducer } from "reducers/utils/listingReducer";


import { getUpcomingAppointmentsApi, getPreviousAppointmentsApi, getApproveRejectAppointmentsApi } from "../services/appointments";


//upcoming
const upcomingAppointmentsReducer = OtherlistingReducer({
    name: `upcomingAppointments`,
    getApi: getUpcomingAppointmentsApi,
});

const upcomingAppointments = upcomingAppointmentsReducer.reducer;
const upcomingAppointmentsActions = upcomingAppointmentsReducer.actions
export { upcomingAppointments, upcomingAppointmentsActions };

//previous
const previousAppointmentsReducer = listingReducer({
    name: `previousAppointments`,
    getApi: getPreviousAppointmentsApi,
});

const previousAppointments = previousAppointmentsReducer.reducer;
const previousAppointmentsActions = previousAppointmentsReducer.actions
export { previousAppointments, previousAppointmentsActions };

//approve/reject 
const approveRejectAppointmentsReducer = listingReducer({
    name: `approveRejectAppointments`,
    getApi: getApproveRejectAppointmentsApi,
});

const approveRejectAppointments = approveRejectAppointmentsReducer.reducer;
const approveRejectAppointmentsActions = approveRejectAppointmentsReducer.actions
export { approveRejectAppointments, approveRejectAppointmentsActions };
import { get, post } from "./index";

export const getFamilyRecordsApi = (params) => {
  const request = {
    subUrl: `/family_histories.json`,
    params,
   
  };
  return get(request);
};

export const addfamilyRecordsApi = (data) => {
  const request = {
    subUrl: `/family_histories.json`,
    data,
  };
  return post(request);
};

import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as nhsRepeatMedicationsActions} from "../../../reducers/nhsRepeatMedications";
import {actions as nhsAcuteMedicationsActions} from "../../../reducers/nhsAcuteMedications";
import {actions as privatepsqActions} from "../../../reducers/pastOrders";

import {actions as privateacuteActions} from "../../../reducers/refferalNotes";

import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../../utilities/utils";
import {
  // isArray,
  debounce,
  isEmpty,
  // uniqueId,
} from "lodash";
// import moment from "moment";
// import CommonLoader from "../../../components/common/CommonLoader";
// import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {
  // Table,
  //  Select,
  // Spin,
  Tabs,
} from "antd";
// import {DATE_FORMAT} from "../../../constants/common";
import {
  // getAcuteMedicationsApi,
  addAcuteMedicationsApi,
} from "../../../services/acuteMedications";
// import ModalPopUp from "../../../components/common/ModalPopUp";
// import Highlighter from "react-highlight-words";
import {getDrugsApi} from "../../../services/drugs";
import PrivatePSQContainer from "./PrivatePSQContainer";
import PrivateAcuteContainer from "./PrivateAcuteContainer";
import NHSAcuteContainer from "./NHSAcuteContainer";
import NHSContainer from "../NHSContainer/NHSContainer";

// import search_blue from "assets/images/common/search-blue.svg";
// import { Input } from "antd";
// import {PlusSquareOutlined} from "@ant-design/icons";

// const {Option} = Select;
class AcuteMedicationsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      message: "",
      accuteMedications: null,
      isLoading: false,
      drugsArray: [],
      drugId: null,
      search: "",
    };
    [
      // "_onRequestList",
      "_onPageChange",
      "_handleSearch",
      "_handleCancel",
      "_hideModal",
      "_handleDropDownChange",
      "_handleSubmit",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.searchDrugUpdate = debounce(this._handleDrugSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, nhsMedicationsActions} = this.props;
    let {onSearch} = nhsMedicationsActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({search: value, patient_id: patientId, page: 1});
    }
  }

  async _handleDrugSearch(value) {
    if (!isEmpty(value)) {
      if (!isEmpty(value)) {
        this.setState(
          {
            drugsArray: [],
            page: 1,
            searching: true,
            isLoadmore: false,
            search: value,
          },
          async () => {
            this._getDrugs(false);
          }
        );
      } else {
        await this.setState({snomedCodes: []});
      }
      // try {
      //     this.setState({ searching: true, search: value }, async () => {
      //         let response = await getDrugsApi({ page: 1, term: value });
      //         let drugsArray = verifyObject(response, "data.data", [])
      //         this.setState({ drugsArray: drugsArray, searching: false })
      //     });

      // } catch (error) {
      //     const { message } = getErrorObject(error);
      //     await this.setState({ searching: false });
      //     errorToast({ content: message });
      // }
    } else {
      await this.setState({searching: false, drugsArray: []});
    }
  }
  _getDrugs = async (isLoadMore) => {
    try {
      let response = await getDrugsApi({
        page: this.state.page,
        term: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let drugsArray = verifyObject(response, "data.data", []);
      this.setState({
        drugsArray: isLoadMore
          ? [...this.state.drugsArray, ...drugsArray]
          : drugsArray,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  };

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getDrugs(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  componentDidMount() {
    // this._onRequestList();
  }

  // _onRequestList() {
  //     let { patientId, nhsMedicationsActions } = this.props
  //     let { onRequest } = nhsMedicationsActions
  //     if (typeof onRequest === "function") {
  //         onRequest({ patient_id: patientId, page: 1 })
  //     }
  // }

  // async _onRequestList() {
  //   let {patientId} = this.props;
  //   try {
  //     await this.setState({isLoading: true});
  //     let response = await getAcuteMedicationsApi({user_id: patientId});
  //     let accuteMedications1 = verifyObject(response, "data.data.current", []);
  //     let accuteMedications = [];
  //     accuteMedications1.map((d) =>
  //       accuteMedications.push({...d, key: uniqueId("ACCUTE_MED_")})
  //     );
  //     await this.setState({
  //       isLoading: false,
  //       accuteMedications: accuteMedications,
  //     });
  //   } catch (error) {
  //     const {message} = getErrorObject(error);
  //     await this.setState({isLoading: false});
  //     errorToast({content: message});
  //   }
  // }
  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    this.setState({[key]: value ? value : "", errors: errors}, () => {});
  }
  _onPageChange(data) {
    let {
      patientId,
      nhsMedicationsActions: {onPageChange},
      nhsMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, patient_id: patientId, search: search});
    }
  }

  _handleCancel() {
    this.setState({
      visible: false,
      message: "",
      adding: false,
      drugsArray: [],
    });
  }

  _hideModal() {
    this.setState({visible: false, drugsArray: [], drugId: null});
  }

  async _handleSubmit(e) {
    e.preventDefault();
    let {drugId} = this.state;
    let {patientId} = this.props;
    let payload = {
      drug_id: drugId,
      user_id: patientId,
    };
    try {
      await this.setState({changing: true});
      let response = await addAcuteMedicationsApi({
        medication: {...payload},
      });
      await this._clearData();
      await successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      await this._onRequestList();
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({changing: false});
      errorToast({content: message});
    }
  }

  _clearData = () => {
    this.setState({
      visible: false,
      message: "",
      accuteMedications: null,
      isLoading: false,
      drugsArray: [],
      drugId: null,
      changing: false,
    });
  };
  handleTabChange = (tabKey) => {
    this.setState({tabKey, isClearedFilters: Math.random()}, async () => {
      if (tabKey === "privateacuteActions" || tabKey === "privatepsqActions") {
        let {patientId} = this.props;
        let {onRequest} = this.props[tabKey];
        if (typeof onRequest === "function") {
          onRequest({patient_id: patientId, page: 1});
        } else if (tabKey === "nhsAcuteMedicationsActions") {
        } else {
        }
      }
      // this._onRequestList();
    });
  };
  render() {
    let {
      // accuteMedications,
      // changing,
      isLoading,
      // searching,
      // drugId,
      // drugsArray,
      // visible,
      // isLoadmore,
    } = this.state;

    return (
      <Fragment>
        <div className="push-20-b mainspace">
          <Tabs
            // onChange={this.handleTabChange}
            defaultActiveKey="privatepsqActions"
            activeKey={this.state.tabKey}
            destroyInactiveTabPane={true}
          >
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">Private PSQ</span>
                  </span>
                </div>
              }
              key={"privatepsqActions"}
              disabled={isLoading}
            >
              <PrivatePSQContainer />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">Private Acute</span>
                  </span>
                </div>
              }
              key={"privateacuteActions"}
              disabled={isLoading}
            >
              <PrivateAcuteContainer />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">
                      NHS Acute Medication
                    </span>
                  </span>
                </div>
              }
              key={"nhsAcuteMedicationsActions"}
              disabled={isLoading}
            >
              <NHSAcuteContainer />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">
                      NHS Repeat Medication
                    </span>
                  </span>
                </div>
              }
              key={"nhsRepeatMedicationsActions"}
              disabled={isLoading}
            >
              <NHSContainer />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    // nhsMedications: verifyObject(state, "nhsMedications", []),
    privatepsq: verifyObject(state, "pastOrders", []),
    privateAcute: verifyObject(state, "referralNotes", []),
    nhsAcuteMedications: verifyObject(state, "nhsAcuteMedications", []),
    nhsRepeatMedications: verifyObject(state, "nhsRepeatMedications", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nhsAcuteMedicationsActions: bindActionCreators(
      nhsAcuteMedicationsActions,
      dispatch
    ),
    nhsRepeatMedicationsActions: bindActionCreators(
      nhsRepeatMedicationsActions,
      dispatch
    ),
    privateacuteActions: bindActionCreators(privateacuteActions, dispatch),
    privatepsqActions: bindActionCreators(privatepsqActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AcuteMedicationsContainer);

export default ConnectedComponent;

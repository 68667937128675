import React, { useEffect, useState } from "react";
import CommonLoader from "../../components/common/CommonLoader";
import NoFoundMessage from "../../components/common/NoFoundMessage";
// import ascendingsort from "assets/images/common/ascending-sort.svg";
// import three_vertical_dots from "assets/images/common/payment_option.svg";
import {
  renderHealthAssessmentInputColor,
  verifyObject,
} from "utilities/utils";
import moment from "moment";
import { isArray, isEmpty } from "lodash";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { DATE_FORMAT, DATE_FORMAT_12_HR } from "constants/common";
// import ModalPopUp from "components/common/ModalPopUp";
import ascendingsort from "assets/images/common/ascending-sort.svg";
import { Popover, Button, Tooltip, Checkbox, Typography } from "antd";
const { Text } = Typography;

const colorCodesForHyperTensionValue = {
  normal: "#16b250",
  low: "#16a4fa ",
  at_risk: "#d4ca01",
  high: "#f48918",
  very_high: "#ed1e24",
  severe: "#c22026",
};
//   { lable: "Normal", value: "normal" },
//   { lable: "Low BP", value: "low" },
//   { lable: "Pre-Hypertension", value: "at_risk" },
//   { lable: "Hypertension Stage 1", value: "high" },
//   { lable: "Hypertension Stage 2", value: "very_high" },
//   { lable: "Hypertension Stage 3", value: "severe" },
// ]

function KioskPreConsultReviewTable(props) {
  const [chekdIds, setCheckboxIds] = useState([]);
  const [state, setState] = useState({});
  const parseAddress = (addressString) => {
    const line1 = addressString?.line1 ? addressString?.line1 : "-";
    const town = addressString?.town ? addressString?.town : "-";
    const pincode = addressString?.pincode ? addressString?.pincode : "-";

    const addressNewLine = [town, pincode].filter((d) => d).join(", ");
    if (!addressString) {
      return "-";
    }
    return { line1, line2: addressNewLine };
  };
  const addresscontent = (line1, line2) => (
    <div className="address_contentbox">
      <span>{line1 ? line1 : "-"}</span>
      <span>{line2 ? line2 : "-"}</span>
    </div>
  );
  const patientcontent = (line1, email, line2, line3, date) => {
    // console.log("LINE2---", line2);
    const emailContent = email ? <Text copyable>{email}</Text> : null;

    return (
      <div className="patient_contentbox">
        <span className="mr-1 d-block">
          <b className="weight-400 mr-1">Email:</b>{" "}
          {emailContent ? emailContent : null}
        </span>
        {/* <span className="mr-1 d-block">
          <b className="weight-400 mr-1">Name:</b> {line1 ? line1 : "-"}{" "}
          {emailContent && <span> ( {emailContent} )</span>}
        </span>
        <span className="mr-1 d-block">
          <b className="weight-400 mr-1"> Age:</b>
          {line2 && line2 !== "-" ? `${line2}y` : "-"}
        </span>
        <span className="d-block">
          <b className="weight-400 mr-1">Phone number:</b>{" "}
          {line3 ? <Text copyable>{line3}</Text> : "-"}
        </span> */}
      </div>
    );
  };
  // const renderSystolicBpColor = (value) => {
  // if (value && value < 60) {
  //   return "ha-lowBP-text";
  // } else if (value >= 60 && value < 80) {
  //   return "ha-normalBP-text";
  // } else if (value >= 80 && value < 90) {
  //   return "ha-preHyperBP text-white custom-input-number";
  // } else if (value >= 90 && value < 100) {
  //   return "ha-stage1BP text-white custom-input-number";
  // } else if (value >= 100 && value < 120) {
  //   return "ha-stage2BP text-white custom-input-number";
  // } else if (value >= 120) {
  //   return "ha-stage3BP text-white custom-input-number";
  // } else {
  //   return "custom-input-number";
  // }
  // if (_bp && !isEmpty(_bp.toString())) {
  //   if (_bp < 90) return "#0872FB"; //!LOW
  //   if (_bp > 90 && _bp <= 120) return "#16B250"; //!NORMAL
  //   if (_bp > 120 && _bp <= 140) return "#D4CA01"; //!AT RISK
  //   if (_bp > 140 && _bp <= 160) return "#F48918"; //!HIGH
  //   if (_bp > 160 && _bp <= 180) return "#ED1E24"; //!VERY HIGH
  //   if (_bp > 190) return "#C22026"; //!SEVERE
  // } else {
  //   return "black";
  // }
  // };

  // const renderDiastolicBpColor = (_bp) => {
  //   if (_bp && !isEmpty(_bp.toString())) {
  //     if (_bp < 60) return "#0872FB"; //!LOW
  //     if (_bp > 60 && _bp <= 80) return "#16B250"; //!NORMAL
  //     if (_bp > 80 && _bp <= 90) return "#D4CA01"; //!AT RISK
  //     if (_bp > 90 && _bp <= 100) return "#F48918"; //!HIGH
  //     if (_bp > 100 && _bp <= 110) return "#ED1E24"; //!VERY HIGH
  //     if (_bp > 110) return "#C22026"; //!SEVERE
  //   } else {
  //     return "black";
  //   }
  // };
  const bpcontent = (systolic, diastolic, heartRate, range) => {
    // console.log("LINE2---", line2);
    return (
      <div
        // style={{
        //   border: `2px soild ${
        //    colorCodesForHyperTensionValue[range]
        //   }}`,
        // }}
        style={{
          border: "2px solid",
          borderColor: colorCodesForHyperTensionValue[range],
        }}
        className="patient_contentbox"
      >
        <span className="mr-1 d-block">
          <b className="weight-400 mr-1">Systolic:</b>{" "}
          <span
            className={renderHealthAssessmentInputColor(
              systolic,
              "systolic_bp",
              true
            )}
            style={{
              fontWeight: 600,
            }}
          >
            {" "}
            {systolic ? systolic : "-"}
          </span>
        </span>
        <span className="mr-1 d-block">
          <b className="weight-400 mr-1"> Diastolic:</b>
          <span
            className={renderHealthAssessmentInputColor(
              diastolic,
              "diastolic_bp",
              true
            )}
            style={{
              fontWeight: 600,
            }}
          >
            {diastolic ? diastolic : "-"}
          </span>
        </span>
        <span className="d-block">
          <b className="weight-400 mr-1">Pulse Rate:</b>{" "}
          {heartRate ? heartRate : "-"}
        </span>
      </div>
    );
  };
  const videoAppointmentcontent = (
    id,
    serialNumber,
    date,
    type,
    name,
    role,
    bookedAt,
    bookedBy
  ) => {
    return (
      <div className="patient_contentbox">
        <span className="mr-1 d-block">
          <b className="weight-400 mr-1">Date:</b> {date ? date : "-"}{" "}
          {type && type !== "-" ? (
            <span className="ant-tag ant-tag-blue margin-right-0-imp text-capitalize ml-2">
              {type.replace(/_/g, " ")}
            </span>
          ) : (
            ""
          )}
        </span>
        <span className="d-block">
          <b className="weight-400 mr-1">Clinician info:</b>{" "}
          {name ? (
            <>
              {name}{" "}
              {role && role !== "-" ? (
                <span className="text-capitalize">
                  {" ("}
                  {role.replace("_", " ")}
                  {")"}
                </span>
              ) : null}
            </>
          ) : (
            "-"
          )}
        </span>
        <div
          className={`secondrowdiv ${
            state && state[`showBookedDetails_${id}`]
              ? "up-arrow"
              : "down-arrow"
          } mt-4 mb-2`}
        >
          <div className="dragbtn">
            <a className="d-flex btn-updown" onClick={() => handleButtonClick(id)}>
              <span className="uprrow">
                <CaretUpOutlined />
              </span>
              <span className="downarrow">
                <CaretDownOutlined />
              </span>
            </a>
          </div>
          {state && state[`showBookedDetails_${id}`] && (
            <div className="mt-2 d-block">
              <span className="d-block">
                <b className="weight-400 mr-1">Booked by:</b>{" "}
                {bookedBy ? <>{bookedBy}</> : "-"}
              </span>
              <span className="mr-1 d-block">
                <b className="weight-400 mr-1">Booked at:</b>{" "}
                {bookedAt ? bookedAt : "-"}
              </span>
            </div>
          )}
        </div>
        {serialNumber > 1 && null}
      </div>
    );
  };
  const handleButtonClick = (id) => {
    setState({
      ...state,
      [`showBookedDetails_${id}`]: !state[`showBookedDetails_${id}`],
    });
  };

  const onCheckAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      let ids = props.ids;
      setCheckboxIds(ids);
      props.handleDeleteCheckbox(ids);
    } else {
      setCheckboxIds([]);
      props.handleDeleteCheckbox([]);
    }
  };
  useEffect(() => {
    if (
      props.toBeDeleteIds &&
      isArray(props.toBeDeleteIds) &&
      props.toBeDeleteIds.length === 0
    ) {
      setCheckboxIds([]);
    }
  }, [props.toBeDeleteIds]);

  const handleCheckBox = (e, id) => {
    if (e.target.checked) {
      console.log("ON CHANGE ", id);

      setCheckboxIds([...chekdIds, id]);
      props.handleDeleteCheckbox([...chekdIds, id]);
    } else {
      console.log("ON CHANGE DELETE", id);
      let newArray = chekdIds.filter((d) => d !== id);
      console.log("ON CHANGE NEW", newArray);

      setCheckboxIds(newArray);
      props.handleDeleteCheckbox(newArray);
    }
  };
  // const urlParams = new URLSearchParams(window.location.search);
  // const isReviewed = urlParams.get("reviewed");
  console.log("CEHCKED IDS---", chekdIds);
  return (
    <div className="KioskPreConsultReviewTable_Wrapper">
      <table className="w-100 upcoming_booking_tableheader relative bg-gray border border-bottom-none-imp preconsulttable">
        <thead>
          <tr>
            {!props.isLoading && props.isAllowDelete && (
              <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-2 md-td-2 text-light-black">
                <Checkbox
                  checked={
                    chekdIds.length !== 0 &&
                    chekdIds.length === props?.ids?.length
                  }
                  onChange={onCheckAll}
                  className="m-0"
                ></Checkbox>
              </th>
            )}
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-14 md-td-14 text-light-black">
              Patient Name
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-14 md-td-14 text-light-black">
              Contact
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-14 md-td-14 text-light-black">
              Address
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-20 md-td-20 text-light-black">
              Blood Pressure
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left lg-td-11 md-td-11 text-light-black">
              Hypertensive
            </th>
            {/* <th className="font-12 padding-10-t padding-10-b text-left lg-td-14 md-td-14 text-light-black">
              Consent Shared ?
            </th> */}
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-13  md-td-13 text-light-black">
              Date
              <img
                src={ascendingsort}
                alt="Ascending Sort"
                className="push-3-l cursor-pointer sort-icon"
                // alt="Ascending Sort"
                title=""
                id="1"
                onClick={() => props.sortRecordBy("created_at", "1")}
              />
            </th>
            <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-12 md-td-12 text-light-black">
              Action
            </th>
          </tr>
        </thead>
      </table>
      <div className="upcoming_appt_data preconsulttable">
        {props.isLoading && <CommonLoader />}
        {!props.isLoading && props.ids && props.ids.length === 0 && (
          <NoFoundMessage message="No pre consults available" />
        )}
        {!props.isLoading &&
          props.ids.map((id) => {
            const phoneContent = verifyObject(
              props.data[id],
              "patient.phone",
              null
            ) ? (
              <span className="d-flex align-items-center">
                <Text copyable className="text-black font-14-imp">
                  {verifyObject(props.data[id], "patient.phone", null)}
                </Text>
              </span>
            ) : null;
            return (
              <table key={id} className="w-100 upcoming_booking_table relative">
                <tbody>
                  <tr>
                    {props.isAllowDelete && (
                      <td className="text-left padding-left-10 lg-td-2 md-td-2 overflow_td">
                        <Checkbox
                          onChange={(e) => handleCheckBox(e, id)}
                          checked={chekdIds.includes(id)}
                        />
                      </td>
                    )}
                    <td className="text-left padding-left-10 lg-td-14 md-td-14 overflow_td">
                      <div className="d-flex align-items-center gap-6px justify-content-between ">
                        <div className="text_col patient_name_info d-flex flex-column gap-6px">
                          {verifyObject(props.data[id], "patient.age", null) ? (
                            <span className="ellipsis-text font-14-imp text-black">
                              {verifyObject(
                                props.data[id],
                                "patient.child_user.is_child_account",
                                false
                              ) ? (
                                <Tooltip placement="top" title="Child Account">
                                  <span className="mr-1 custom-bluelabel font-10 in-block text-capitalize">
                                    C
                                  </span>
                                </Tooltip>
                              ) : null}
                              {`${verifyObject(
                                props.data[id],
                                "patient.name",
                                "-"
                              )}`}
                            </span>
                          ) : (
                            "-"
                          )}
                          <div className="d-flex align-items-center gap-6px">
                            {verifyObject(
                              props.data[id],
                              "patient.age",
                              "-"
                            ) ? (
                              <span>
                                {verifyObject(
                                  props.data[id],
                                  "patient.age",
                                  "-"
                                )}
                                yrs
                              </span>
                            ) : (
                              ""
                            )}
                            {verifyObject(
                              props.data[id],
                              "patient.gender",
                              "-"
                            ) === "male" ? (
                              <span className="gender_span male">M</span>
                            ) : verifyObject(
                                props.data[id],
                                "patient.gender",
                                "-"
                              ) === "female" ? (
                              <span className="gender_span female">F</span>
                            ) : (
                              "-"
                            )}
                            |{" "}
                            {verifyObject(
                              props.data[id],
                              "patient.dob",
                              null
                            ) ? (
                              <span className="weight-400 font-14-imp text-black">
                                {moment(
                                  verifyObject(
                                    props.data[id],
                                    "patient.dob",
                                    null
                                  )
                                ).format(DATE_FORMAT)}
                              </span>
                            ) : (
                              "-"
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="text-left padding-left-10 lg-td-14 md-td-14 overflow_td">
                      <div className="d-flex align-items-center gap-6px justify-content-between ">
                        <div className="text_col patient_name_info d-flex flex-column gap-6px">
                          {verifyObject(
                            props.data[id],
                            "patient.phone",
                            null
                          ) ? (
                            <span className="ellipsis-text">
                              {phoneContent}
                            </span>
                          ) : (
                            "-"
                          )}
                          {verifyObject(
                            props.data[id],
                            "patient.email",
                            null
                          ) ? (
                            <span className="ellipsis-text email-ellipsis">
                              <Text copyable>
                                {verifyObject(
                                  props.data[id],
                                  "patient.email",
                                  null
                                )}
                              </Text>
                            </span>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </td>
                    <td className="text-left padding-left-10 lg-td-14 md-td-14 overflow_td">
                      <Popover
                        overlayClassName="address_contentbox_popover"
                        content={() =>
                          addresscontent(
                            parseAddress(props.data[id]?.patient?.address)
                              ?.line1,
                            parseAddress(props.data[id]?.patient?.address)
                              ?.line2
                          )
                        }
                      >
                        <p className="padding-left-0 cursor-pointer font-14-imp tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block custom-text-width address_td">
                          {!isEmpty(props.data[id]?.patient?.address?.line1) ? (
                            <span className="ellipsis-text">
                              {
                                parseAddress(props.data[id]?.patient?.address)
                                  ?.line1
                              }
                            </span>
                          ) : (
                            "-"
                          )}
                          {!isEmpty(
                            props.data[id]?.patient?.address?.pincode
                          ) ? (
                            <span className="ellipsis-text">
                              {
                                parseAddress(props.data[id]?.patient?.address)
                                  ?.line2
                              }
                            </span>
                          ) : (
                            "-"
                          )}
                        </p>
                      </Popover>
                    </td>
                    {/* <td className="text-left padding-left-10 lg-td-20 md-td-20">
                      <div className="d-flex">
                        <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                          {props.data[id]?.health_condition?.health_condition
                            ?.name === "Uncomplicated Urinary Tract Infection"
                            ? "UTI"
                            : props.data[id]?.health_condition?.health_condition
                                ?.name
                            ? props.data[id]?.health_condition?.health_condition
                                ?.name
                            : "-"}
                        </p>
                        {!isEmpty(
                          verifyObject(
                            props.data[id],
                            "video_f2f_appointments",
                            []
                          )
                        ) ? (
                          <div className="infoicon ml-2">
                            {props.data[id].video_f2f_appointments.map(
                              (appointment, index) => (
                                <Popover
                                  key={index}
                                  content={() =>
                                    videoAppointmentcontent(
                                      index + 1,
                                      appointment.start_time
                                        ? moment(appointment.start_time).format(
                                            DATE_FORMAT_12_HR
                                          )
                                        : null,
                                      appointment.appointment_type || "-",
                                      appointment.clinician_info.name || null,
                                      appointment.clinician_info.designation
                                        ? appointment.clinician_info.designation
                                        : appointment.clinician_info.role ||
                                            null
                                    )
                                  }
                                >
                                  <Button className="infobtn">
                                    <InfoCircleOutlined />
                                  </Button>
                                </Popover>
                              )
                            )}
                          </div>
                        ) : null}
                      </div>
                    </td> */}
                    <td className="text-left padding-left-10 lg-td-20 md-td-20">
                      <div className="d-flex align-items-center py-1">
                        <p className="font-14-imp tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                          {bpcontent(
                            props.data[id]?.systolic_bp,
                            props.data[id]?.diastolic_bp,
                            props.data[id]?.pulse_per_minute,
                            props.data[id]?.range
                          )}
                        </p>
                        {props.data[id].video_f2f_appointments?.length > 0 && (
                          <div className="infoicon ml-4">
                            <Popover
                              overlayClassName="patient_contentbox_popover"
                              content={
                                <div className="overflow-y-scroll max-height-550">
                                  {props.data[id].video_f2f_appointments.map(
                                    (appointment, index) => {
                                      // console.log("---->", appointment);
                                      return (
                                        <div key={index}>
                                          {videoAppointmentcontent(
                                            appointment.id,
                                            index + 1,
                                            appointment.start_time
                                              ? moment(
                                                  appointment.start_time
                                                ).format(DATE_FORMAT_12_HR)
                                              : null,
                                            appointment.appointment_type || "-",
                                            appointment.clinician_info.name ||
                                              null,
                                            appointment.clinician_info
                                              .designation
                                              ? appointment.clinician_info
                                                  .designation
                                              : appointment.clinician_info
                                                  .role || null,
                                            verifyObject(
                                              appointment,
                                              "booked_at",
                                              null
                                            )
                                              ? moment(
                                                  verifyObject(
                                                    appointment,
                                                    "booked_at",
                                                    null
                                                  )
                                                ).format(DATE_FORMAT_12_HR)
                                              : null,
                                            verifyObject(
                                              appointment,
                                              "booked_by",
                                              null
                                            ) || null
                                          )}
                                          {index !==
                                            props.data[id]
                                              .video_f2f_appointments.length -
                                              1 && ""}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              }
                            >
                              <Button className="infobtn">
                                <InfoCircleOutlined />
                              </Button>
                            </Popover>
                          </div>
                        )}
                      </div>
                    </td>

                    <td
                      className={`text-left padding-left-10 lg-td-11 md-td-11 text-black`}
                      // style={{
                      //   backgroundColor:
                      //     verifyObject(
                      //       props.data[id],
                      //       "outcome.pre_consult_outcome.color_code",
                      //       null
                      //     ) &&
                      //     (props.prev_appt_tab_key === "pending" ||
                      //       props.prev_appt_tab_key === "pre_consult_reviewed")
                      //       ? verifyObject(
                      //           props.data[id],
                      //           "outcome.pre_consult_outcome.color_code",
                      //           null
                      //         )
                      //       : "",
                      //   color:
                      //     verifyObject(
                      //       props.data[id],
                      //       "outcome.pre_consult_outcome.color_code",
                      //       null
                      //     ) &&
                      //     (props.prev_appt_tab_key === "pending" ||
                      //       props.prev_appt_tab_key === "pre_consult_reviewed")
                      //       ? "white"
                      //       : "",
                      // }}
                    >
                      <p className="font-14-imp tablet-font-9 weight-400 margin-0 text-capitalize in-block">
                        {props.data[id]?.hypertensive
                          ? props.data[id]?.hypertensive === "yes"
                            ? "Yes"
                            : props.data[id]?.hypertensive === "no" && "No"
                          : "-"}
                      </p>
                    </td>
                    {/* <td className="text-left padding-left-10 lg-td-14 md-td-14">
                      {props.data[id]?.consent_shared ? "Yes" : "No"}
                    </td> */}
                    <td className="text-left padding-left-10 lg-td-13 md-td-13">
                      <p className="font-14 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {props.prev_appt_tab_key === "pre_consult_reviewed"
                          ? moment(props.data[id]?.updated_at).format("lll")
                          : moment(props.data[id]?.created_at).format("lll")}
                      </p>
                    </td>

                    {/* )} */}
                    <td className="text-center lg-td-12 md-td-12 autoheightbtn p-2">
                      {props.status === "kiosk_pending" ? (
                        <button
                          onClick={() =>
                            props._handleNavigation(props.data[id], true)
                          }
                          className="arrive_btn btn btn-custom btn-blue w-100 font-14-imp weight-400 text-white tablet-font-10"
                        >
                          Review
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            props._handleNavigation(props.data[id], false)
                          }
                          className="arrive_btn btn btn-custom btn-blue w-100 font-14-imp weight-400 text-white tablet-font-10"
                        >
                          View
                        </button>
                      )}
                      {(props.status === "kiosk_pending" ||
                        props.prev_appt_tab_key === "kiosk_reviewed") && (
                        <button
                          onClick={() =>
                            props._handleAppointment(props.data[id], false)
                          }
                          style={{
                            fontSize: 11,
                          }}
                          className="arrive_btn mt-2 btn btn-custom btn-blue w-100 font-14-imp weight-400 text-white tablet-font-10"
                        >
                          Book An Appointment
                        </button>
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
      </div>
    </div>
  );
}

export default KioskPreConsultReviewTable;

import React, {Component} from "react";
import AppRoutes from "./AppRoutes";
import {ToastContainer} from "react-toastify";
// import { ErrorBoundary } from "app/ErrorBoundary";
import {SnackbarProvider} from "notistack";

// import "https://cdn.onesignal.com/sdks/OneSignalSDKWorker.js";

class App extends Component {
  componentDidMount() {}
  render() {
    return (
      <SnackbarProvider
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        maxSnack={3}
      >
        <ToastContainer
          enableMultiContainer={true}
          containerId={"container_main"}
          position="top-center"
        />
        <AppRoutes />
      </SnackbarProvider>
    );
  }
}
export default App;

import React, {Component, Fragment} from "react";
import {Route, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {replace} from "react-router-redux";
import {Switch} from "react-router-dom";
import RedirectComponent from "../../components/RedirectComponent/RedirectComponent";
// import { mainAppRoutesConstants } from "../../constants/appRoutesConstants";
import RightToWorkPage from "../RightToWork/RightToWorkPage";
import PrivateRoutes from "../../hoc/PrivateRoutes";
import StaffintranetPage from "../StaffIntranet/StaffIntranetPage";
import RotaPage from "../Rota/RotaPage";
import {Header} from "../../components/session";
import AppointmentsPage from "../Appointments/AppointmentsPage";
import PaymentsPage from "../Payments/PaymentsPage";
import OrderPaymentsPage from "../Payments/OrderPaymentsPage";
import PaymentSettingsPage from "../Payments/PaymentSettingsPage";
import ProfilePage from "../Profile/ProfilePage";
import WalkinAppointmentPage from "../WalkinAppointments/WalkinAppointmentPage";
// import ReportsReviewPage from "../ReportsReview/ReportsReviewPage";
// import ReportsReviewDetailPage from "../ReportsReview/ReportsReviewDetailPage";
// import QuestionnaireReviewPage from "../QuestionnaireReview/QuestionnaireReviewPage";
// import QuestionnaireReviewDetailPage from "../QuestionnaireReview/QuestionnaireReviewDetailPage";
// import DynamicQuestionnaireReviewDetailPage from "../DynamicQuestionnaireReview/DynamicQuestionnaireReviewDetailPage";
// import DynamicQuestionnaireReviewPage from "../DynamicQuestionnaireReview/DynamicQuestionnaireReviewPage";
import VideoCallSetupPage from "../VideoCallSetup/VideoCallSetupPage";
// import VideoAppointmentPage from "../WalkinAppointments/VideoAppointmentPage";
import VideoAppointmentMainPage from "../WalkinAppointments/VideoAppointmentMainPage";
import ScriptReviewPage from "../ScriptReview/ScriptReviewPage";
import ScriptReviewDetailPage from "../ScriptReview/ScriptReviewDetailPage";
import ChangePasswordLoggedInPage from "../Login/ChangePasswordLoggedInPage";
import {appRoutesConst} from "../../app/navigation";
import {verifyObject} from "../../utilities/utils";
import MessagesPage from "../Messages/MessagesPage";
import NetworkTestPage from "../NetworkTestPage/NetworkTestPage";

import {
  ROLE_CS_EXECUTIVE,
  ROLE_DOCTOR,
  ROLE_HCSW,
  ROLE_NURSE,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
  // ROLE_CLINICAL_PHARMACIST,
} from "../../constants/common";
import {ErrorBoundary} from "app/ErrorBoundary";
import PatientSearchPage from "pages/PatientSearch/PatientSearchPage";
import ConsultationReviewPage from "pages/ConsultationReview/ConsultationReviewPage";
import PreConsultationReviewPage from "pages/PreConsultationReview/PreConsultationMainPage";
import CommonLoader from "components/common/CommonLoader";
const components = {
  AppointmentsPage: AppointmentsPage,
  RightToWork: RightToWorkPage,
  StaffIntranet: StaffintranetPage,
  Rota: RotaPage,
  ConsultationReviewPage: ConsultationReviewPage,
  PreConsultationReviewPage: PreConsultationReviewPage,

  // ReportsReviewDetailPage: ReportsReviewDetailPage,
  // QuestionnaireReviewPage: QuestionnaireReviewPage,
  // QuestionnaireReviewDetailPage: QuestionnaireReviewDetailPage,
  // DynamicQuestionnaireReviewPage: DynamicQuestionnaireReviewPage,
  // DynamicQuestionnaireReviewDetailPage: DynamicQuestionnaireReviewDetailPage,
  PaymentsPage: PaymentsPage,
  OrderPaymentsPage: OrderPaymentsPage,
  PaymentSettingsPage: PaymentSettingsPage,
  ProfilePage: ProfilePage,
  WalkinAppointmentPage: WalkinAppointmentPage,
  VideoAppointmentPage: VideoAppointmentMainPage,
  VideoCallSetupPage: VideoCallSetupPage,
  ScriptReviewPage: ScriptReviewPage,
  ScriptReviewDetailPage: ScriptReviewDetailPage,
  ChangePasswordLoggedInPage: ChangePasswordLoggedInPage,
  MessagesPage: MessagesPage,
  NetworkTestPage: NetworkTestPage,
  PatientSearchPage: PatientSearchPage,
};

class DoctorRouter extends Component {
  constructor(props) {
    super(props);
    if (!props.token) {
      props.navigateToIndex();
    }
  }
  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      // document.oncontextmenu = document.oncontextmenu = function () {
      //   alert("Copying is not allowed on this webpage");
      //   return false;
      // };
      // document.oncopy = document.oncopy = function () {
      //   alert("Copying is not allowed on this webpage");
      //   return false;
      // };
    }
  }
  componentWillUnmount() {}

  render() {
    let {user, userRole} = this.props;
    const isPharmSmartUser = user?.is_pharmsmart_user === true ? true : false;

    console.log("isPharmSmartUser", isPharmSmartUser);
    console.log(
      "this.props.switching_corporate------",
      this.props.switching_corporate
    );

    let mainAppRoutesConstants = [
      // Campaign
      {
        path: appRoutesConst.dashboard,
        exact: true,
        component: "AppointmentsPage",
        disabled: false,
      },
      {
        path: appRoutesConst.rightToWork,
        exact: true,
        component: "RightToWork",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.staffInfranet,
        exact: true,
        component: "StaffIntranet",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.rota,
        exact: true,
        component: "Rota",
        disabled: false,
      },

      // {
      //   path: appRoutesConst.reportsReview,
      //   exact: true,
      //   component:
      //     user &&
      //     (user.role === ROLE_DOCTOR || user.role === ROLE_CLINICAL_PHARMACIST)
      //       ? "ReportsReviewPage"
      //       : "AppointmentsPage",
      //   disabled: false,
      // },
      // {
      //   path: appRoutesConst.reportsReviewDetail,
      //   exact: true,
      //   component:
      //     user &&
      //     (user.role === ROLE_DOCTOR || user.role === ROLE_CLINICAL_PHARMACIST)
      //       ? "ReportsReviewDetailPage"
      //       : "AppointmentsPage",
      //   disabled: false,
      // },
      // {
      //   path: appRoutesConst.questionnaireReview,
      //   exact: true,
      //   component:
      //     user &&
      //     (user.role === ROLE_DOCTOR || user.role === ROLE_CLINICAL_PHARMACIST)
      //       ? "QuestionnaireReviewPage"
      //       : "AppointmentsPage",
      //   disabled: isPharmSmartUser,
      // },
      {
        path: appRoutesConst.dynamicQuestionnaireReview,
        exact: true,
        component:
          user &&
          (user.role === ROLE_DOCTOR ||
            user.role === ROLE_CLINICAL_PHARMACIST ||
            user.role === ROLE_SUPERINTENDENT_PHARMACIST)
            ? "DynamicQuestionnaireReviewPage"
            : "AppointmentsPage",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.scriptReview,
        exact: true,
        component:
          user &&
          (user.role === ROLE_DOCTOR ||
            user.role === ROLE_CLINICAL_PHARMACIST ||
            user.role === ROLE_SUPERINTENDENT_PHARMACIST)
            ? "ScriptReviewPage"
            : "AppointmentsPage",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.scriptReviewDetail,
        exact: true,
        component:
          user &&
          (user.role === ROLE_DOCTOR || user.role === ROLE_CLINICAL_PHARMACIST)
            ? "ScriptReviewDetailPage"
            : "AppointmentsPage",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.consultationReview,
        exact: true,
        component:
          user && user.role !== ROLE_SUPERINTENDENT_PHARMACIST
            ? "ConsultationReviewPage"
            : "AppointmentsPage",
        disabled: false,
      },
      {
        path: appRoutesConst.preconsultationReview,
        exact: true,
        component:
          user &&
          (user.role === ROLE_DOCTOR ||
            user.role === ROLE_CLINICAL_PHARMACIST ||
            user.role === ROLE_SUPERINTENDENT_PHARMACIST)
            ? "PreConsultationReviewPage"
            : "AppointmentsPage",
        disabled: false, // enabling for all non child and any corporate
      },
      {
        path: appRoutesConst.questionnaireReviewDetail,
        exact: true,
        component:
          user &&
          (user.role === ROLE_DOCTOR ||
            user.role === ROLE_CLINICAL_PHARMACIST ||
            user.role === ROLE_SUPERINTENDENT_PHARMACIST)
            ? "QuestionnaireReviewDetailPage"
            : "AppointmentsPage",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.dynamicQuestionnaireReviewDetail,
        exact: true,
        component:
          user &&
          (user.role === ROLE_DOCTOR ||
            user.role === ROLE_CLINICAL_PHARMACIST ||
            user.role === ROLE_SUPERINTENDENT_PHARMACIST)
            ? "DynamicQuestionnaireReviewDetailPage"
            : "AppointmentsPage",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.payments,
        exact: true,
        component:
          userRole !== ROLE_HCSW &&
          userRole !== ROLE_CS_EXECUTIVE &&
          userRole !== ROLE_NURSE
            ? "PaymentsPage"
            : "",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.profile,
        exact: true,
        component: "ProfilePage",
        disabled: false,
      },
      {
        path: appRoutesConst.walkinAppointment,
        exact: true,
        component: "WalkinAppointmentPage",
        disabled: false,
      },
      {
        path: appRoutesConst.videoAppointment,
        exact: true,
        component: "VideoAppointmentPage",
        disabled: false,
      },
      {
        path: appRoutesConst.callSetup,
        exact: true,
        component: "VideoCallSetupPage",
        disabled: false,
      },
      {
        path: appRoutesConst.doctorchangepassword,
        exact: true,
        component: "ChangePasswordLoggedInPage",
        disabled: isPharmSmartUser,
      },
      {
        path: appRoutesConst.messages,
        exact: true,
        component: "MessagesPage",
        disabled: false,
      },
      {
        path: appRoutesConst.callNetworkTest,
        exact: true,
        component: "NetworkTestPage",
        disabled: false,
      },
    ];

    // if(userRole=== ROLE_CLINICAL_PHARMACIST){
    //   mainAppRoutesConstants = [
    //     // Campaign
    //     {
    //       path: appRoutesConst.dashboard,
    //       exact: true,
    //       component: "AppointmentsPage",
    //     },
    //     {
    //       path: appRoutesConst.patientSearch,
    //       exact: true,
    //       component: "PatientSearchPage",
    //     },
    //     // {
    //     //   path: appRoutesConst.rightToWork,
    //     //   exact: true,
    //     //   component: "RightToWork",
    //     // },
    //     // {
    //     //   path: appRoutesConst.staffInfranet,
    //     //   exact: true,
    //     //   component: "StaffIntranet",
    //     // },
    //     // {
    //     //   path: appRoutesConst.rota,
    //     //   exact: true,
    //     //   component: "Rota",
    //     // },
    //     // {
    //     //   path: appRoutesConst.reportsReview,
    //     //   exact: true,
    //     //   component:
    //     //     user && (user.role === ROLE_DOCTOR||user.role === ROLE_CLINICAL_PHARMACIST)
    //     //       ? "ReportsReviewPage"
    //     //       : "AppointmentsPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.reportsReviewDetail,
    //     //   exact: true,
    //     //   component:
    //     //     user && (user.role === ROLE_DOCTOR||user.role === ROLE_CLINICAL_PHARMACIST)
    //     //       ? "ReportsReviewDetailPage"
    //     //       : "AppointmentsPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.questionnaireReview,
    //     //   exact: true,
    //     //   component:
    //     //     user && (user.role === ROLE_DOCTOR||user.role === ROLE_CLINICAL_PHARMACIST)
    //     //       ? "QuestionnaireReviewPage"
    //     //       : "AppointmentsPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.dynamicQuestionnaireReview,
    //     //   exact: true,
    //     //   component:
    //     //     user && (user.role === ROLE_DOCTOR||user.role === ROLE_CLINICAL_PHARMACIST)
    //     //       ? "DynamicQuestionnaireReviewPage"
    //     //       : "AppointmentsPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.scriptReview,
    //     //   exact: true,
    //     //   component:
    //     //     user && (user.role === ROLE_DOCTOR||user.role === ROLE_CLINICAL_PHARMACIST)
    //     //       ? "ScriptReviewPage"
    //     //       : "AppointmentsPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.scriptReviewDetail,
    //     //   exact: true,
    //     //   component:
    //     //     user && (user.role === ROLE_DOCTOR||user.role === ROLE_CLINICAL_PHARMACIST)
    //     //       ? "ScriptReviewDetailPage"
    //     //       : "AppointmentsPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.questionnaireReviewDetail,
    //     //   exact: true,
    //     //   component:
    //     //     user && (user.role === ROLE_DOCTOR||user.role === ROLE_CLINICAL_PHARMACIST)
    //     //       ? "QuestionnaireReviewDetailPage"
    //     //       : "AppointmentsPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.dynamicQuestionnaireReviewDetail,
    //     //   exact: true,
    //     //   component:
    //     //     user && (user.role === ROLE_DOCTOR||user.role === ROLE_CLINICAL_PHARMACIST)
    //     //       ? "DynamicQuestionnaireReviewDetailPage"
    //     //       : "AppointmentsPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.payments,
    //     //   exact: true,
    //     //   component:
    //     //     userRole !== ROLE_HCSW &&
    //     //     userRole !== ROLE_CS_EXECUTIVE &&
    //     //     userRole !== ROLE_NURSE
    //     //       ? "PaymentsPage"
    //     //       : "",
    //     // },
    //     {
    //       path: appRoutesConst.profile,
    //       exact: true,
    //       component: "ProfilePage",
    //     },
    //     {
    //       path: appRoutesConst.walkinAppointment,
    //       exact: true,
    //       component: "WalkinAppointmentPage",
    //     },
    //     {
    //       path: appRoutesConst.videoAppointment,
    //       exact: true,
    //       component: "VideoAppointmentPage",
    //     },
    //     {
    //       path: appRoutesConst.callSetup,
    //       exact: true,
    //       component: "VideoCallSetupPage",
    //     },
    //     {
    //       path: appRoutesConst.doctorchangepassword,
    //       exact: true,
    //       component: "ChangePasswordLoggedInPage",
    //     },
    //     // {
    //     //   path: appRoutesConst.messages,
    //     //   exact: true,
    //     //   component: "MessagesPage",
    //     // },
    //     // {
    //     //   path: appRoutesConst.callNetworkTest,
    //     //   exact: true,
    //     //   component: "NetworkTestPage",
    //     // },
    //   ];
    // }
    return (
      this.props.token && (
        <Fragment>
          {this.props.switching_corporate && (
            <CommonLoader text="Switching corporate..." />
          )}

          {!this.props.switching_corporate && <Header />}
          {!this.props.switching_corporate && (
            <ErrorBoundary>
              <Switch>
                {mainAppRoutesConstants.map(({component, ...rest}, key) => {
                  const RouteComponent = components[component] || "";
                  if (
                    typeof RouteComponent !== "undefined" &&
                    RouteComponent !== "" &&
                    rest.disabled === false
                  ) {
                    return (
                      <PrivateRoutes
                        key={key}
                        {...rest}
                        component={RouteComponent}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
                <Route exact path="/*" component={RedirectComponent} />
              </Switch>
            </ErrorBoundary>
          )}
        </Fragment>
      )
    );
  }
}
const mapStateToProps = (state) => {
  return {
    is_detail_disabled: state.localStore.is_detail_disabled,
    token: state.localStore.token,
    user: verifyObject(state, "localStore.user", null),
    userRole: verifyObject(state.localStore, "user.role", null),
    switching_corporate: verifyObject(
      state.localStore,
      "switching_corporate",
      false
    ),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    navigateToIndex: () => dispatch(replace("/")),
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(DoctorRouter));

import { getMedicalHistoryApi } from "services/patientDetail";
import { listingReducer } from "reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: `medicalHistory`,
  getApi: getMedicalHistoryApi,
});

const medicalHistory = reducer;
export { medicalHistory, actions, types };

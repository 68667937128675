import { listingReducer } from "reducers/utils/listingReducer";
import { fetchKioskPreConsultHealthScanLogs } from "services/preConsultConditions";

const { reducer, actions, types } = listingReducer({
  name: `kioskPreConsult`,
  getApi: fetchKioskPreConsultHealthScanLogs,
});

const kioskPreConsult = reducer;
export { kioskPreConsult, actions, types };

import React, { Component } from "react";
import VideoAppointmentPage from "./VideoAppointmentPage";

export default class VideoAppointmentMainPage extends Component {

    componentDidMount(){

    }
    
  render() {
    return (
      <div>
        <VideoAppointmentPage />
      </div>
    );
  }
}

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "react-router-redux";
import App from "./app/App";
import ScrollToTop from "utilities/ScrollToTop";
import * as ServiceWorker from "./registerServiceWorker";
import store, { history } from "./app/store";
import "bootstrap/dist/css/bootstrap.css";
import 'react-perfect-scrollbar/dist/css/styles.css';
import "font-awesome/css/font-awesome.css";
import "assets/css/common.css";
import "assets/css/responsive.css";
import "assets/css/ant-override.css";
import "assets/css/components/header.css";
import "assets/css/index.css";
import "antd/dist/antd.css";
console.log("ENV", process.env.REACT_APP_ENV);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <ScrollToTop>
        <App />
      </ScrollToTop>
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
if (module.hot) {
  module.hot.accept("./app/App", () => {
    ReactDOM.render(
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </ConnectedRouter>
      </Provider>,
      document.getElementById("root")
    );
  });
}
// ServiceWorker.unregister();
ServiceWorker.register();

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import {Modal, Select, Spin} from "antd";
import React, {Fragment, useEffect, useRef, useState} from "react";
import {Radio, DatePicker} from "antd";
import {
  DATE_FORMAT,
  DATE_FORMAT_12_HR,
  appointmentTags,
} from "constants/common";
import moment from "moment";
import {errorToast, getErrorObject, verifyObject} from "utilities/utils";
import {allAppointmentUsersApi} from "services/allAppointmentUsers";
// import {getPatients} from "services/patients";
// import {actions as patientActions, onPatientSelect} from "reducers/patients";

import {isArray} from "highcharts";
import {MailOutlined} from "@ant-design/icons";
import {IconPhone} from "assets/icon/IconPhone";

import "../../assets/css/components/react-tagsinput.css";

import {fetchSymptomsApi} from "services/symptoms";

import CustomCreatableSelect from "components/common/CustomCreatableSelect";
import Asterisk from "components/common/AsteriskComponent/Asterisk";

export default function BookAppointmentForPatient({
  visible,
  handleCloseModal,
  handleSubmission,
  selecetdDate,
  selectedSlotTime,
  bookingAppointment,
  handleAptTypeChange,
  selectedAptType,
  doctor_id,
  appointment_tag,
  handleSelectChange1,
  doctorName,
  setPatient,
  handlePatientSearch,
  allPatients,
  handleSelectChange,
  disabledDate,
  handleDatepicker,
  isFetchingSlot,
  slotsArray,
  onChangeSlots,
  patient_id,
  isLoadmore,
  forWholebooking,
  searching,
  handleScroll,
  fromConsultation,
  passedPatientName,
  inEditMode,
  isDocCleared,
  allowedSlotTypes,
}) {
  console.log("🚀 ~ allowedSlotTypes:", allowedSlotTypes);
  console.log("🚀 ~ doctor_id:", doctor_id);
  const timer = useRef(null);
  const [clinicians, setClinicians] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  // const [isLoadMore, setIsLoadMore] = useState(false);
  const [pagination, setPagination] = useState(null);
  // const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  // const [selectedTags, setSelectedTags] = useState([]);
  useEffect(() => {
    if (forWholebooking) {
      onGetAllClinicians(false);
    }
  }, [forWholebooking]);
  // const [searchInput, setSearchInput] = useState("");
  const [tags, setTags] = useState([]);

  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false); // State to control dropdown visibility

  const [booking_reason, setBookingReason] = useState([]);
  const [symptomsData, setSymptomsData] = useState([]);

  const [searchingSymptom, setSearchingSymptom] = useState(false);
  // Function to call the API

  const onGetAllClinicians = async () => {
    try {
      await setLoading(true);
      let response = await allAppointmentUsersApi({
        search: search,
        page,
      });
      if (response.data.data) {
        let pagination =
          verifyObject(response, "headers['x-pagination']", null) !== null
            ? JSON.parse(response.headers["x-pagination"])
            : null;

        let doctors = response?.data?.data;
        let found = doctors.find((d) => d.id === doctor_id);
        let newArra = doctors.filter((d) => d.id !== doctor_id);
        console.log("New Array", newArra);
        setClinicians([found, ...newArra]);
        setPagination(pagination);
      }
      await setLoading(false);
      // await setSearching(false);
      await setLoading(false);
    } catch (error) {
      const {message} = getErrorObject(error);
      await setLoading(false);
      errorToast({content: message});
    }
  };

  useEffect(() => {
    if (tags) {
      handlePatientSearch(tags);
      setInputValue("");
    }
  }, [tags]);
  useEffect(() => {
    if (inputValue && inputValue.length > 0) {
      if (timer.current) {
        clearTimeout(timer.current);
      }

      // Set up a timer to delay the tag creation by 1 second
      timer.current = setTimeout(() => {
        const value = inputValue.trim();
        if (value && !tags.includes(value)) {
          setTags((prevTags) => [...prevTags, value]);
        }
        setInputValue("");
      }, 1000);
    }

    // Clear timer on unmount or when inputValue changes
    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [inputValue, tags]);
  // Handle tag creation and option select
  const handleChange = (value) => {
    if (value) {
      setTags(value);
      setInputValue("");
    } else {
      setTags([]);
    }
    if (!dropdownOpen) {
      setDropdownOpen(true);
    }
    // setInputValue("");
  };
  useEffect(() => {
    if (
      allPatients &&
      isArray(allPatients) &&
      allPatients.length > 0 &&
      !dropdownOpen &&
      !patient_id
    ) {
      setDropdownOpen(true);
    }
  }, [allPatients, dropdownOpen, patient_id]);
  // Handle option select
  const handleSelect = (value) => {
    if (isNaN(value)) return;
    const selected = allPatients.find((option) => +option.id === +value);
    if (selected) {
      setSelectedOption(selected.id);
      setPatient(selected.id);
      setTags([]);
    }
    setDropdownOpen(false);
  };

  // Handle clearing the selected option
  const handleClear = () => {
    setSelectedOption(null);
    setTags([]);
    setInputValue("");
    setDropdownOpen(false);
    setPatient(null);
  };

  // Track when the dropdown opens or closes
  const handleDropdownVisibleChange = (open) => {
    setDropdownOpen(open);
  };
  const filterOptions = (input, option) => {
    return !tags.includes(option.label);
  };
  const onGetSymptoms = async (value) => {
    try {
      setSearchingSymptom(true);
      let response = await fetchSymptomsApi({search: value});

      let newSymptomsData = verifyObject(response, "data.data", []);
      if (response.data.data) {
        setSymptomsData(
          newSymptomsData.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
      setSearchingSymptom(false);
    } catch (error) {
      const {message} = getErrorObject(error);
      setSearchingSymptom(false);
      errorToast({content: message});
    }
  };
  return (
    <div>
      <Modal
        visible={visible}
        keyboard={false} // Prevents closing on Esc key
        maskClosable={false} // Prevents closing on outside click
        onCancel={() => handleCloseModal(true)}
        onOk={() => handleSubmission(allowedSlotTypes, booking_reason)}
        okButtonProps={{
          disabled: inEditMode
            ? bookingAppointment === true ||
              selecetdDate === null ||
              selectedAptType === null ||
              selectedSlotTime === null
            : forWholebooking
            ? bookingAppointment === true ||
              selecetdDate === null ||
              selectedAptType === null ||
              selectedSlotTime === null ||
              appointment_tag === null ||
              patient_id === null
            : bookingAppointment === true ||
              selecetdDate === null ||
              selectedAptType === null ||
              appointment_tag === null ||
              patient_id === null,
          loading: bookingAppointment === true,
        }}
        okText="Book an Appointment"
        title={"Book Appointment"}
        width={900}
        destroyOnClose
        className="newpre_questionairepopup"
      >
        <div>
          {!forWholebooking && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">
                    Book With
                    <Asterisk />{" "}
                  </label>
                  <input
                    type="text"
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    defaultValue={doctorName}
                    disabled
                  />
                  {/* <b>{doctorName}</b> */}
                </div>
              </div>
            </div>
          )}
          {forWholebooking && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">
                    Book With
                    <Asterisk />{" "}
                  </label>{" "}
                  {loading ? (
                    <div className="text-center">
                      <Spin size="small" />
                    </div>
                  ) : (
                    <Select
                      showSearch
                      className="form-control margin-0"
                      placeholder="Select doctor name"
                      optionFilterProp="children"
                      defaultValue={doctor_id}
                      onChange={(value) =>
                        handleSelectChange(value, "doctor_id")
                      }
                      loading={loading}
                      // onPopupScroll={handleScroll}
                      onSearch={(value) => setSearch(value)}
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      allowClear
                      dropdownRender={(menus) => {
                        return (
                          <div>
                            {menus}
                            {searching && (
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: 10,
                                }}
                              >
                                <Spin size="small" />
                              </div>
                            )}
                          </div>
                        );
                      }}
                    >
                      <Select.Option value={"all"}>{`All`}</Select.Option>
                      {clinicians.map((user) => {
                        if (user) {
                          return (
                            <Select.Option key={user.id} value={user.id}>{`${
                              user.name
                            } (${user.role?.replaceAll(
                              "_",
                              " "
                            )})`}</Select.Option>
                          );
                        }
                        // return null;
                      })}
                    </Select>
                  )}
                  {/* {errors && (
                  <span className="validate-danger">
                    {errors.last_name}
                  </span>
                )} */}
                </div>
              </div>
            </div>
          )}
          {!fromConsultation && !passedPatientName && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12 patient-search-tag">
                  <label htmlFor="sel1">
                    Select Patient
                    <Asterisk />
                  </label>{" "}
                  {selectedOption ? (
                    <Select
                      open={false}
                      value={selectedOption}
                      style={{width: "100%"}}
                      allowClear
                      onClear={handleClear} // Handle clear event
                    >
                      {/* Showing the selected option only */}
                      {allPatients.map((pt) => {
                        return (
                          <Select.Option
                            key={pt.id}
                            value={pt.id}
                            className="multi-data"
                          >
                            <div className="d-flex flex-column info-parent">
                              <div className="d-flex flex-wrap gapx-2">
                                {pt.name && (
                                  <div className="d-flex flex-wrap gapx-2">
                                    <span className="flex-1">{pt.name}</span> |
                                  </div>
                                )}
                                {pt.dob && (
                                  <div className="d-flex flex-wrap gapx-2">
                                    <span className="flex-1">
                                      {moment(pt.dob).format(DATE_FORMAT)}
                                    </span>
                                  </div>
                                )}
                                {verifyObject(pt, "gender", null) ? (
                                  <span
                                    className={`gender ${verifyObject(
                                      pt,
                                      "gender",
                                      null
                                    )}`}
                                  >
                                    {verifyObject(pt, "gender", null) === "male"
                                      ? "M"
                                      : "F"}
                                  </span>
                                ) : null}
                              </div>
                              {pt.email && (
                                <div className="d-flex flex-wrap gapx-2">
                                  <MailOutlined className="text-blue mt-1" />
                                  <span className="flex-1">{pt.email}</span>
                                </div>
                              )}
                              {pt.phone && (
                                <div className="d-flex flex-wrap gapx-2">
                                  <span className="text-blue ">
                                    <IconPhone className="mt-1" />
                                  </span>
                                  <span className="flex-1">{pt.phone}</span>
                                </div>
                              )}
                              {/* <div className="d-flex flex-wrap gapx-2">
                              {pt.nhs_number && <div className="d-flex flex-wrap gapx-2">
                                <span className="flex-1">{pt.nhs_number}</span> |
                              </div>}
                            </div> */}
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  ) : (
                    <Select
                      autoFocus
                      open={dropdownOpen} // Control dropdown visibility
                      onDropdownVisibleChange={handleDropdownVisibleChange} // Track dropdown visibility
                      showSearch
                      className="form-control margin-0"
                      dropdownClassName="patient-search-tag-dropdown"
                      placeholder="Select Patient"
                      optionFilterProp="children"
                      suffixIcon={
                        searching &&
                        !isLoadmore && <Spin size="small" spinning={true} />
                      }
                      searchValue={inputValue}
                      onPopupScroll={handleScroll}
                      mode="tags"
                      value={tags}
                      // onKeyUp={debounce((e) => {
                      //   const value = e.target.value.trim();
                      //   if (value) {
                      //     if (value && !tags.includes(value)) {
                      //       setTags((prevTags) => [...prevTags, value]);
                      //     }
                      //     setInputValue("");
                      //     e.target.value = "";
                      //   }
                      // }, 1000)}
                      onSearch={(value) => {
                        setInputValue(value);
                      }}
                      onChange={handleChange}
                      onSelect={handleSelect}
                      style={{width: "100%"}}
                      notFoundContent={
                        !searching &&
                        allPatients.length === 0 && (
                          <span style={{textAlign: "center"}}>
                            No patients available
                          </span>
                        )
                      }
                      onClear={handleClear} // Detect 'Enter' or 'Tab' key press
                      filterOption={filterOptions}
                      allowClear={!searching}
                      disabled={inEditMode}
                      loading={searching}
                      dropdownRender={(menus) => {
                        return (
                          <div>
                            {menus}
                            {searching && allPatients.length > 0 && (
                              <div
                                style={{
                                  textAlign: "center",
                                  padding: 10,
                                }}
                              >
                                <Spin size="small" />
                              </div>
                            )}
                          </div>
                        );
                      }}
                    >
                      {allPatients.map((pt) => {
                        return (
                          <Select.Option
                            key={pt.id}
                            value={pt.id}
                            className="multi-data patient-search-tag-items"
                          >
                            <div className="d-flex flex-column info-parent">
                              <div className="d-flex flex-wrap gapx-2">
                                {pt.name && (
                                  <div className="d-flex flex-wrap gapx-2">
                                    <span className="flex-1">{pt.name}</span> |
                                  </div>
                                )}
                                {pt.dob && (
                                  <div className="d-flex flex-wrap gapx-2">
                                    <span className="flex-1">
                                      {moment(pt.dob).format(DATE_FORMAT)}
                                    </span>
                                  </div>
                                )}
                                {verifyObject(pt, "gender", null) ? (
                                  <span
                                    className={`gender ${verifyObject(
                                      pt,
                                      "gender",
                                      null
                                    )}`}
                                  >
                                    {verifyObject(pt, "gender", null) === "male"
                                      ? "M"
                                      : "F"}
                                  </span>
                                ) : null}
                              </div>
                              {pt.email && (
                                <div className="d-flex flex-wrap gapx-2">
                                  <MailOutlined className="text-blue mt-1" />
                                  <span className="flex-1">{pt.email}</span>
                                </div>
                              )}
                              {pt.phone && (
                                <div className="d-flex flex-wrap gapx-2">
                                  <span className="text-blue ">
                                    <IconPhone className="mt-1" />
                                  </span>
                                  <span className="flex-1">{pt.phone}</span>
                                </div>
                              )}
                              {/* <div className="d-flex flex-wrap gapx-2">
                              {pt.nhs_number && <div className="d-flex flex-wrap gapx-2">
                                <span className="flex-1">{pt.nhs_number}</span> |
                              </div>}
                            </div> */}
                            </div>
                          </Select.Option>
                        );
                      })}
                    </Select>
                  )}
                </div>
              </div>
            </div>
          )}
          {(fromConsultation || passedPatientName) && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">
                    Select Patient
                    <Asterisk />
                  </label>{" "}
                  <Select
                    showSearch
                    className="form-control margin-0"
                    placeholder="Select Patient"
                    optionFilterProp="children"
                    value={passedPatientName}
                    disabled={inEditMode || fromConsultation}
                  >
                    <Select.Option value={passedPatientName}>
                      {passedPatientName}
                    </Select.Option>
                  </Select>
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <div className="form-row ">
              <div className="col-md-12">
                <label htmlFor="sel1" className="dis-block">
                  Type
                  <Asterisk />
                </label>
                <Radio.Group
                  className="d-flex radiogroupbtn"
                  value={
                    allowedSlotTypes?.length === 1
                      ? allowedSlotTypes[0]
                      : selectedAptType
                  }
                  onChange={handleAptTypeChange}
                  disabled={inEditMode || allowedSlotTypes?.length === 1}
                >
                  {allowedSlotTypes &&
                  // allowedSlotTypes.length > 1 &&
                  isArray(allowedSlotTypes) ? (
                    <Fragment>
                      {allowedSlotTypes.map((d) => {
                        return (
                          <Radio.Button value={d}>
                            {
                              <span className="text-capitalize">
                                {d.replaceAll("_", " ")}
                              </span>
                            }
                          </Radio.Button>
                        );
                      })}
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Radio.Button value={"video"}>Video</Radio.Button>
                      <Radio.Button value="face_to_face">
                        Face to Face
                      </Radio.Button>
                    </Fragment>
                  )}
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row ">
              <div className="col-md-12">
                <label htmlFor="sel1" className="dis-block">
                  Date & Time
                  <Asterisk />
                </label>
                {!forWholebooking && (
                  <input
                    type="text"
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    defaultValue={moment(selecetdDate).format(
                      DATE_FORMAT_12_HR
                    )}
                    disabled
                  />
                )}

                {/* <b>{moment(selecetdDate).format(DATE_FORMAT_12_HR)}</b> */}
                {forWholebooking && (
                  <DatePicker
                    placeholder="Select Date"
                    className="form-control"
                    format={DATE_FORMAT}
                    value={selecetdDate && moment(selecetdDate, DATE_FORMAT)}
                    disabledDate={disabledDate}
                    onChange={handleDatepicker}
                    disabled={isDocCleared}
                  />
                )}
              </div>
            </div>
          </div>

          {forWholebooking && selecetdDate && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <Spin spinning={isFetchingSlot}>
                    <div className="commonfield select-slot-group">
                      <label>
                        Select Time Slot 
                        <Asterisk />
                      </label>

                      {!isFetchingSlot &&
                      slotsArray &&
                      slotsArray.length > 0 ? (
                        <Radio.Group
                          options={slotsArray || []}
                          onChange={onChangeSlots}
                          value={selectedSlotTime}
                          optionType="button"
                        />
                      ) : (
                        <div className="noslotdiv bg-gray rounded border rounded flex-1 d-flex align-items-center justify-content-center py-3">
                          <p className="text-center font-weight-700 text-black-70">
                            {isFetchingSlot
                              ? ""
                              : !slotsArray &&
                                "No time slots available"}
                          </p>
                        </div>
                      )}
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          )}
          {!inEditMode && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Appointment Reason</label>
                  <div className="custom-tag-dropdown">
                    <CustomCreatableSelect
                      placeholder="Search and select or create reason"
                      handleUpdateBookingReason={(booking_reason) =>
                        setBookingReason(booking_reason)
                      }
                      optionData={symptomsData}
                      onSearchFunction={onGetSymptoms}
                      isLoading={searchingSymptom}
                      selectClassName="custom-tag-dropdown"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {!inEditMode && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">
                    Appointment For
                    <Asterisk />
                  </label>
                  <Select
                    className="form-control margin-0"
                    placeholder="Select appointment for"
                    value={appointment_tag}
                    onChange={(value) => {
                      // const value = e.target.value;
                      handleSelectChange1(value, "appointment_tag");
                    }}
                  >
                    {appointmentTags.map((d, index) => {
                      return (
                        <Select.Option key={index} value={d.value}>
                          {d.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Prompt, withRouter } from "react-router-dom";

import Layout from "../../Layouts/PageLayout";
import { appRoutesConst } from "../../app/navigation";
import {
  onRequestSetComponent,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import { navigateTo } from "../../app/navigation";
import { verifyObject } from "../../utilities/utils";
import PaymentsContainer from "containers/Payments/PaymentsContainer";
import OrderPaymentsContainer from "../../containers/Payments/OrderPaymentsContainer";
import PaymentsSettingsContainer from "../../containers/Payments/PaymentsSettingsContainer";
import "assets/css/pages/payment.css";
import { ROLE_HCSW, ROLE_NURSE } from "../../constants/common";
import { Beforeunload } from "react-beforeunload";
import { CalendarOutlined } from "@ant-design/icons";
import appointments from "assets/images/sidebar/ic-appointments.svg";
import orders from "assets/images/sidebar/ic-orders.svg";
import settings from "assets/images/sidebar/ic-settings.svg";

class PaymentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: "PaymentsContainer",
    };
    ["_getIndexRoute", "_renderDynamicComponent"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }
  _getIndexRoute() {
    let {
      onRequestSetComponent,
      onSetSidebarKey,
    } = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent("PaymentsContainer");
      onSetSidebarKey([`1`]);
    }
  }

  _renderDynamicComponent() {
    let { componentName } = this.state;

    switch (componentName) {
      case "PaymentsContainer":
        return <PaymentsContainer />;

      case "OrderPaymentsContainer":
        return <OrderPaymentsContainer />;

      case "PaymentsSettingsContainer":
        return <PaymentsSettingsContainer />;

      default:
        return <PaymentsContainer />;
    }
  }

  componentDidMount() {
    this._getIndexRoute();
    let { componentName } = this.props;
    this.setState({ componentName });
  }

  componentWillReceiveProps(nextProps) {
    let { componentName } = nextProps;
    this.setState({ componentName });
  }

  _clearComponents = () => {
    let {
      onRequestSetComponent,
      onSetSidebarKey,
    } = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(null);
      onSetSidebarKey(null);
    }
  };

  render() {
    let { userRole } = this.props;
    let sideBarArray = [
      {
        label: "Appointments",
        path: appRoutesConst.payments,
        key: 1,
        page: "PaymentsContainer",
        useDynamicComponent: true,
        icon: <img alt="Appointments" src={appointments}></img>,
      },
      {
        label: "Orders",
        path: appRoutesConst.orderPayments,
        key: 2,
        page: "OrderPaymentsContainer",
        useDynamicComponent: true,
        icon: <img alt="Orders" src={orders}></img>,
      },
      {
        label: "Settings",
        path: appRoutesConst.paymentSettings,
        icon: <img alt="Settings" src={settings}></img>,
        key: 3,
        page: "PaymentsSettingsContainer",
        useDynamicComponent: true,
      },
    ];
    if (userRole === ROLE_HCSW || userRole === ROLE_NURSE) {
      sideBarArray = [
        {
          label: "Appointments",
          path: appRoutesConst.payments,
          icon: <CalendarOutlined />,
          key: 1,
          page: "PaymentsContainer",
          useDynamicComponent: true,
        },
        {
          label: "Settings",
          path: appRoutesConst.paymentSettings,
          icon: <CalendarOutlined />,
          key: 3,
          page: "PaymentsSettingsContainer",
          useDynamicComponent: true,
        },
      ];
    }
    return (
      <Beforeunload
        onBeforeunload={(event) => {
          this._clearComponents();
        }}
      >
        <Layout title="Payments" sideBarArray={sideBarArray}>
          <Prompt
            message={(location, action) => {
              if (action === "PUSH") {
                this._clearComponents();
                return true;
              }
              if (action === "POP") {
                this._clearComponents();
                return true;
              }
              return true;
            }}
          />
          <div className="rightmainsection payment_apointment">
            <div className="container-fluid right_wrapper p-0">
              {/* <div className='row'> */}
              <div className="col-md-12 selection_container p-0">
                {this._renderDynamicComponent()}
              </div>
              {/* </div> */}
            </div>
          </div>
        </Layout>
      </Beforeunload>
    );
  }
}

const mapStateProps = (state) => {
  return {
    componentName: verifyObject(
      state.localStore,
      "componentName",
      "PaymentsContainer"
    ),
    userRole: verifyObject(state.localStore, "user.role", null),
    selectedKey: verifyObject(state.dynamicTab, "sidebar_key", null),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      { onRequestSetComponent, onSetSidebarKey },
      dispatch
    ),
    navigateTo: bindActionCreators({ navigateTo }, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(PaymentsPage);

export default withRouter(ConnectedComponent);

import React from "react";
import CommonLoader from "../common/CommonLoader";

export default function TableLoader({ style, className, message, isNormal }) {
  const loaderStyle = {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  };
  return (
    <div style={style} className={`lds-facebook ${className ? className : ""}`}>
      {!isNormal && (
        <div className="lds-facebook-wrapper">
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      <div style={loaderStyle}>
        {isNormal && <CommonLoader />}
        {isNormal && message && (
          <span style={{ marginTop: 10 }}>{message}</span>
        )}
      </div>
    </div>
  );
}

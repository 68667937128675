import { get, Irequest ,deletee} from "./index";

export const getQuestionnaireApi = (params) => {
  const request: Irequest = {
    subUrl: `/new_questionnaire/questionnaires.json`,
    params,
  };
  return get(request);
};
export const deleteQuestionnaireApi = (params) => {
  const request: Irequest = {
    subUrl: `/new_questionnaire/questions/${params.id}.json`,
  };
  return deletee(request);
};


export const getQuestionnairDetailApi = (params) => {
  const request={
    subUrl: `/patients/questionnaire_templates/${params.id}.json`,
  };
  return get(request);
};



/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, Fragment} from "react";
import moment from "moment";
import {Spin} from "antd";
import {acceptRejectAppointments} from "../../services/appointments";
import {
  successToast,
  verifyObject,
  getErrorObject,
  errorToast,
} from "../../utilities/utils";
import ModalPopUp from "../../components/common/ModalPopUp";
import {isEmpty} from "lodash";
import {Popover} from "antd";
import LightBoxComponent from "../../components/common/LightBoxComponent";
import {DATE_FORMAT, T24_HR_FORMAT} from "../../constants/common";
export default function ApproveRejectAppointmentsItem({
  data,
  id,
  _onRequestList,
  _handleNavigation,
}) {
  const [changing, setLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [isOpen, setLighbox] = useState(false);

  const [comment, setComment] = useState("");
  const [appointmentId, setAppointmentId] = useState(null);

  const Prevtextover = ({text}) => (
    <div className="view_popover">
      <span className="font-12 weight-400 margin-0 text-black inline-block">
        {text}
      </span>
    </div>
  );

  const _acceptAppointment = async (appointmentId) => {
    let payload = {
      acceptance: "true",
      comment: "",
      id: appointmentId,
    };

    try {
      await setLoader(true);
      let response = await acceptRejectAppointments(payload);
      await _hideModal();
      await _onRequestList();
      await successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await setLoader(false);
      errorToast({content: message});
    }
  };

  const _rejectAppointment = async () => {
    let payload = {
      acceptance: "false",
      comment: comment,
      id: appointmentId,
    };

    try {
      await setLoader(true);
      let response = await acceptRejectAppointments(payload);
      await _onRequestList();
      await successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await setLoader(false);
      errorToast({content: message});
    }
  };

  const _showModal = (appointmentId) => {
    setVisible(true);
    setAppointmentId(appointmentId);
  };

  const _hideModal = () => {
    setVisible(false);
    setComment("");
    setAppointmentId(null);
  };

  const _handleTextChange = (e) => {
    setComment(e.target.value);
  };

  return (
    <Fragment>
      <table className="w-100 upcoming_booking_table relative aprove_rejecttable">
        <tbody>
          <tr>
            <td className="text-left padding-left-10 lg-td-21 md-td-20">
              <div className="d-flex report-user align-items-center">
                <div className="up_doctor_profile in-block vertical-middle">
                  {data[id].patient_image ? (
                    <img alt="" src={data[id].patient_image}></img>
                  ) : (
                    <img
                      alt=""
                      src={
                        require("../../assets/images/common/doctor_profile.svg")
                          .default
                      }
                    ></img>
                  )}
                </div>
                <p
                  onClick={() => {}}
                  className="padding-left-10 text-break cursor-pointer font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block custom-text-width"
                >
                  {data[id].booked_by}
                </p>
                &nbsp;
                <span className="ant-tag ant-tag-blue margin-right-0-imp">
                  {data[id].offline_appointment ? "Walk-In" : "Video"}
                </span>
              </div>
            </td>
            <td className="text-left padding-left-10 lg-td-9 md-td-10">
              <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                {verifyObject(data[id], "corporate_name", "-")}
              </p>
            </td>
            <td className="text-left padding-left-10 lg-td-7 md-td-8">
              <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                {verifyObject(data[id], "patient_profile.gender", "") ===
                  "male" && "M"}
                {verifyObject(data[id], "patient_profile.gender", "") ===
                  "female" && "F"}
                {verifyObject(data[id], "patient_profile.gender", "") === "" &&
                  "-"}
              </p>
            </td>
            <td className="text-left padding-left-10 lg-td-8 md-td-9">
              <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                {data[id].patient_profile &&
                  data[id].patient_profile.date_of_birth &&
                  moment(data[id].patient_profile.date_of_birth).format(
                    DATE_FORMAT
                  )}
              </p>
            </td>
            <td className="text-left padding-left-10 lg-td-21 md-td-20 ellipsis">
              {data[id].reason_image ? (
                <a
                  className="view_btn font-12 text-link-blue weight-400 text-underline text-decoration-hover text-center"
                  onClick={() => setLighbox(true)}
                >
                  View
                </a>
              ) : (
                <span className="lb-p">-</span>
              )}
              <LightBoxComponent
                visible={isOpen}
                onClose={() => setLighbox(false)}
                image={verifyObject(data[id], "reason_image", "")}
              />
              {/* {verifyObject(data[id], "reason_image", "") !== "" ? (
                <Popover
                  content={
                    <Previmgover
                      image={verifyObject(data[id], "reason_image", "")}
                    />
                  }
                  trigger="click"
                  placement="bottom"
                  className="prev_imgover"
                >
                  <Button className="p-0 view_btn border-0 font-12 text-link-blue weight-400 text-underline text-decoration-hover text-center">
                    View
                  </Button>
                </Popover>
              ) : (
                "-"
              )} */}
              {verifyObject(data[id], "reason_text", "") !== "" ? (
                <Popover
                  content={
                    <Prevtextover
                      text={verifyObject(data[id], "reason_text", "")}
                    />
                  }
                  trigger="hover"
                  placement="bottomLeft"
                  className="prev_imgover ellipsis-text"
                >
                  {verifyObject(data[id], "reason_text", "") !== "" ? (
                    data[id].reason_text
                  ) : (
                    <span className="lb-p">-</span>
                  )}
                </Popover>
              ) : (
                <span className="lb-p">-</span>
              )}
            </td>
            <td className="text-left padding-left-10 lg-td-8 md-td-9">
              <p className="font-12 tablet-font-9 weight-400 margin-0 text-black">
                {moment(data[id].start_time).format(DATE_FORMAT)}
              </p>
            </td>
            <td className="text-left padding-left-10 lg-td-9 md-td-9">
              <p className="font-12 tablet-font-9 weight-400 margin-0 text-light-black">
                {moment(data[id].start_time).format(T24_HR_FORMAT)} -{" "}
                {moment(data[id].end_time).format(T24_HR_FORMAT)}
              </p>
            </td>
            <td className="text-left padding-left-10 padding-right-10 lg-td-15 md-td-16">
              <div className="aprove_reject_btns">
                <div className="aprove_btn">
                  <button
                    disabled={changing}
                    onClick={() => _acceptAppointment(id)}
                    className="arrive_btn btn btn-custom btn-green w-100 font-13-imp tablet-font-10 weight-400 text-white"
                  >
                    {changing ? <Spin size="small" /> : "Accept"}
                  </button>
                </div>
                <div className="reject_btn">
                  <button
                    onClick={() => _showModal(id)}
                    className="arrive_btn btn btn-custom btn-danger w-100 font-13-imp tablet-font-10 weight-400 text-white"
                  >
                    Reject
                  </button>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <ModalPopUp
        title={"Reason for appointment rejection"}
        handleCancel={_hideModal}
        visible={visible}
        footer={true}
        handleOk={_rejectAppointment}
        okText={"Reject"}
        loading={changing}
        cancelBtnclassName="display-none"
        okButtonProps={{disabled: isEmpty(comment)}}
        maskClosable={false}
      >
        <form>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12">
                <input
                  className="form-control margin-0"
                  name="comment"
                  value={comment}
                  placeholder="Enter Reason"
                  onChange={_handleTextChange}
                  autoFocus
                />
              </div>
            </div>
          </div>
        </form>
      </ModalPopUp>
    </Fragment>
  );
}

import { SPLIT_SYMBOL } from "constants/common";
import { isArray } from "lodash";
import isEmpty from "lodash/isEmpty";
/**
 * Validates the fill-up answer for a given set of questions.
 *
 * @param {object} data - The data containing the question details.
 * @param {function} handleErrors - The function to handle errors.
 * @param {string} gender - The gender preference.
 * @return {object} An object containing the errors and whether the validation is valid.
 */
export function ValidateFillUpAnswer(data, handleErrors, gender) {
  const questionsDetails = data?.question_details;
  console.log("Fill Up date to be validate", questionsDetails);

  let errors = {};
  let errMsg = {};

  questionsDetails?.forEach((q) => {
    if (q) {
      if (
        q.gender_preference === null ||
        q.gender_preference === gender ||
        q.gender_preference === "both"
      ) {
        if (q.is_required && q.category === "check_agree") {
          if (q.answer === "No i dont agree with this" || !q.answer) {
            errMsg = `Please select the checkbox`;
            handleErrors(errMsg, q);
          }
        }
        if (q.is_required && isEmpty(q.answer)) {
          console.log("QQQQQQQ ANSWER", q.answer);
          if (q.category === "single_choice" || q.category === "multi_choice") {
            errMsg = `Please select one option from above`;
            handleErrors(errMsg, q);
          }
          if (
            q.category === "text_area" ||
            q.category === "text" ||
            q.category === "numerical"
          ) {
            errMsg = `Please enter answer`;
            handleErrors(errMsg, q);
          }
          if (q.category === "file" && !q.answer) {
            console.log(" In Children condition....", q.category);
            errMsg = `Please choose file`;
            handleErrors(errMsg, q);
          }
          if (q.category === "date") {
            console.log(" In Children condition....", q.category);
            errMsg = `Please select date`;
            handleErrors(errMsg, q);
          }
          if (q.category === "date_range") {
            console.log(" In Children condition....", q.category);
            errMsg = `Please choose date range`;
            handleErrors(errMsg, q);
          }
        }
        if (q.category === "numerical") {
          let questionValidations = q?.question_validation?.find(
            (d) => d.constraint_with === "numerical"
          );
          if (questionValidations) {
            let { max_value, min_value } = questionValidations;
            const answer = parseInt(q.answer, 10);
            if (answer < min_value || answer > max_value) {
              errMsg = `Please enter a value between ${min_value} and ${max_value}`;
              handleErrors(errMsg, q);
            }
          }
        }
      }

      q?.children?.map((k) => {
        if (
          k.gender_preference === null ||
          k.gender_preference === gender ||
          k.gender_preference === "both"
        ) {
          console.log("Child Answer___", k.answer);
          if (!k.is_conditional) {
            if (!k.answer && k.category === "date") {
              errMsg = `Please select date `;
              handleErrors(errMsg, q, k);
            }
            if (!k.answer && k.is_required) {
              if (
                k.category === "text" ||
                k.category === "text_area" ||
                k.category === "numerical"
              ) {
                errMsg = `Please enter answer`;
                handleErrors(errMsg, q, k);
              }
              if (k.category === "file" && !k.answer) {
                console.log(" In Children condition....", k.category);
                errMsg = `Please choose file`;
                handleErrors(errMsg, q, k);
              }
              if (k.category === "date") {
                console.log(" In Children condition....", k.category);
                errMsg = `Please select date`;
                handleErrors(errMsg, q, k);
              }
              if (k.category === "date_range") {
                console.log(" In Children condition....", k.category);
                errMsg = `Please choose date range`;
                handleErrors(errMsg, q, k);
              }
              if (k.category === "check_agree") {
                console.log(" In Children condition....", k.category);
                errMsg = `Please select the checkbox`;
                handleErrors(errMsg, q, k);
              }
            }

            if (k.category === "numerical") {
              let questionValidations = k?.question_validation?.find(
                (d) => d.constraint_with === "numerical"
              );
              if (questionValidations) {
                let { max_value, min_value } = questionValidations;
                const answer = parseInt(k.answer, 10);
                if (answer < min_value || answer > max_value) {
                  errMsg = `Please enter a value between ${min_value} and ${max_value}`;
                  handleErrors(errMsg, q, k);
                }
              }
            }
          }
          // if (k.category === "single_choice" || k.category === "multi_choice") {
          //   errMsg = `Please select one option from above`;
          //   handleErrors(errMsg, q, k);
          // }
          if (k.is_conditional && k.is_required && isEmpty(k.answer)) {
            if (
              !k.is_conditional &&
              (k.category === "single_choice" || k.category === "multi_choice")
            ) {
              if (k.is_conditional) {
                if (
                  k.conditional_text_values &&
                  q.answer === k.conditional_text_values[0]
                ) {
                  if (isEmpty(k.answer)) {
                    errMsg = `Please select one option from above`;
                    handleErrors(errMsg, q, k);
                  }
                }
                let selectedValue = parseInt(q.answer, 10);
                const { conditional_max_value, conditional_min_value } = k;

                if (
                  selectedValue <= conditional_max_value &&
                  selectedValue >= conditional_min_value
                ) {
                  if (isEmpty(k.answer)) {
                    errMsg = `Please select one option from above`;
                    handleErrors(errMsg, q, k);
                  }
                }
              } else {
                if (!k.is_conditional) {
                  errMsg = `Please select one option from above`;
                  handleErrors(errMsg, q, k);
                }
              }
            } else {
              //? If condtional question is there then compare its constional value with parent answer

              if (k.is_conditional) {
                // if (
                //   isEmpty(k.answer) &&
                //   (k.category === "single_choice" ||
                //     k.category === "multi_choice")
                // ) {
                //   errMsg = `Please select option from above`;
                //   handleErrors(errMsg, q, k);
                // }

                if (
                  k.category === "single_choice" &&
                  k.conditional_text_values &&
                  q.answer === k.conditional_text_values[0]
                ) {
                  if (isEmpty(k.answer) && k.category === "single_choice") {
                    errMsg = `Please select option from above`;
                    handleErrors(errMsg, q, k);
                  }
                }
                if (k.category === "multi_choice") {
                  let selectedOption = q.answer;
                  console.log(
                    "🚀 ~ q?.children?.map ~ selectedOption:",
                    selectedOption
                  );
                  let AllOptions =
                    selectedOption &&
                    isArray(selectedOption) &&
                    (selectedOption || []).map((d) => d.split(SPLIT_SYMBOL)[0]);
                  console.log(
                    "🚀 ~ ~ file: ValidateFillupAnswers.js:151 ~ All option",
                    AllOptions,
                    k.id
                  );
                  console.log(
                    "🚀 ~ file: ValidateFillupAnswers.js:151 ~ q?.children?.map ~ k.is_conditional:",
                    k.conditional_text_values,
                    k.id
                  );
                  console.log(
                    "🚀 ~ file: ValidateFillupAnswers.js:153 ~ q?.children?.map ~ q.answer:",
                    q.answer,
                    k.id
                  );

                  if (
                    AllOptions &&
                    k.category === "multi_choice" &&
                    k.conditional_text_values
                    // AllOptions[0] !== k?.conditional_text_values[0] &&
                    // AllOptions.toString() ===
                    //   k?.conditional_text_values?.toString()
                  ) {
                    console.log("ALL OPTIONS INNNNNN ", AllOptions);
                    console.log("ALL OPTIONS ", AllOptions);
                    console.log("CONDITIONAL", k?.conditional_text_values);
                    console.log("CONDITIONAL ID", k?.id);
                    console.log("AllOptions.toString()",AllOptions.toString())
                    console.log("k?.conditional_text_values.toString()",k?.conditional_text_values.toString())

                    if (AllOptions[0] !== k?.conditional_text_values[0]) {
                      if (
                        AllOptions.reverse().toString() ===
                        k?.conditional_text_values.toString()
                      ) {
                        if (k.is_required && isEmpty(k.answer)) {
                          errMsg = `Please select option from above`;
                          handleErrors(errMsg, q, k);
                        }
                      } 
                    }else{
                      if (
                        AllOptions.toString() ===
                        k?.conditional_text_values.toString()
                      ) {
                        if (k.is_required && isEmpty(k.answer)) {
                          errMsg = `Please select option from above`;
                          handleErrors(errMsg, q, k);
                        }
                      }
                    }
                  } else {
                    if (selectedOption === k?.conditional_text_values[0]) {
                      if (isEmpty(k.answer)) {
                        errMsg = `Please select option from above 122`;
                        handleErrors(errMsg, q, k);
                      }
                    }
                  }
                }

                if (
                  k.conditional_text_values &&
                  q.answer === k.conditional_text_values[0]
                ) {
                  if (
                    isEmpty(k.answer) &&
                    k.category !== "single_choice" &&
                    k.category !== "multi_choice" &&
                    k.category !== "file"
                  ) {
                    errMsg = `Please enter answer`;
                    handleErrors(errMsg, q, k);
                  }
                  if (!k.answer && k.category === "file") {
                    errMsg = `Please choose file`;
                    handleErrors(errMsg, q, k);
                  }
                }
                let selectedValue = parseInt(q.answer, 10);
                const { conditional_max_value, conditional_min_value } = k;

                if (
                  selectedValue <= conditional_max_value &&
                  selectedValue >= conditional_min_value &&
                  q.category === "numerical"
                ) {
                  if (isEmpty(k.answer)) {
                    errMsg = `Please enter answer`;
                    handleErrors(errMsg, q, k);
                  }
                }
              } else {
                if (
                  isEmpty(k.answer) &&
                  !k.is_conditional &&
                  k.category !== "single_choice" &&
                  k.category !== "multi_choice"
                ) {
                  errMsg = `Please enter answer 444`;
                  handleErrors(errMsg, q, k);
                }
              }
            }
          }
          if (!k.is_conditional && k.is_required && isEmpty(k.answer)) {
            if (
              k.category === "single_choice" ||
              k.category === "multi_choice"
            ) {
              errMsg = `Please select one option from above`;
              handleErrors(errMsg, q, k);
            } else {
              console.log("innnnnnnnn");
              if (
                k.category !== "file" &&
                isEmpty(k.answer) &&
                k.category !== "multi_choice" &&
                k.category !== "single_choice" &&
                k.category !== "date"
              ) {
                errMsg = `Please enter answer`;
                handleErrors(errMsg, q, k);
              }
              if (isEmpty(k.answer) && k.category === "date") {
                errMsg = `Please select date `;
                handleErrors(errMsg, q, k);
              }
            }
          }
          // if (!k.is_conditional && k.is_required && isEmpty(k.answer)) {
          //   if (
          //     k.category === "single_choice" ||
          //     k.category === "multi_choice"
          //   ) {
          //     errMsg = `Please select one option from above`;
          //     handleErrors(errMsg, q, k);
          //   } else {
          //     errMsg = `Please enter answer`;
          //     handleErrors(errMsg, q, k);
          //   }
          // }
          if (q.category === "numerical" && q.answer) {
            let selectedValue = parseInt(q.answer, 10); //parent answer
            const { conditional_max_value, conditional_min_value } = k; //allowed min and max values
            console.log("selectedValue", selectedValue);
            console.log("Max", conditional_max_value);
            console.log("Min", conditional_min_value);

            if (
              k.is_conditional &&
              selectedValue <= conditional_max_value &&
              selectedValue >= conditional_min_value &&
              k.is_required &&
              isEmpty(k.answer)
            ) {
              console.log("In  5555---->");
              if (
                k.category === "single_choice" ||
                k.category === "multi_choice"
              ) {
                errMsg = `Please select one option from above`;
                handleErrors(errMsg, q, k);
              } else {
                errMsg = `Please enter answer`;
                handleErrors(errMsg, q, k);
              }
            }
          } else {
          }
        }
        if (k.answer) {
          k?.children?.map((j) => {
            if (
              j.gender_preference === null ||
              j.gender_preference === gender ||
              j.gender_preference === "both"
            ) {
              if (!j.is_conditional && j.is_required && isEmpty(j.answer)) {
                if (
                  j.category === "single_choice" ||
                  j.category === "multi_choice"
                ) {
                  errMsg = `Please select one option from above`;
                  handleErrors(errMsg, q, k, j);
                } else {
                  errMsg = `Please enter answer`;
                  handleErrors(errMsg, q, k, j);
                }
              }

              if (j.category === "numerical") {
                let questionValidations = j?.question_validation?.find(
                  (d) => d.constraint_with === "numerical"
                );
                if (questionValidations) {
                  let { max_value, min_value } = questionValidations;
                  const answer = parseInt(j.answer, 10);
                  if (answer < min_value || answer > max_value) {
                    errMsg = `Please enter a value between ${min_value} and ${max_value}`;
                    handleErrors(errMsg, q, k);
                  }
                }
              }
            }
            return j;
          });
        }
        return k;
      });
    }
  });

  // if (
  //   data.is_required !== undefined &&
  //   validator.isEmpty(data.existing_password)
  // ) {
  //   errors.existing_password = "Enter old password";
  // }
  console.log("error message ------------------------------------> ", errMsg);

  return { errors, isValid: isEmpty(errMsg) };
}

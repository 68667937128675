/* eslint-disable react-hooks/exhaustive-deps */
import CommonLoader from "components/common/CommonLoader";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { verifyCSToken } from "services/session";
import { errorToast, getErrorObject } from "utilities/utils";
import {
  navigateToCustomerSelectionViaOTPF2F,
  navigateToCustomerSelectionViaOTP,
} from "reducers/session";
import { onSetPatient, onSetPatientAppointment } from "reducers/patients";
import { appRoutesConst, navigateTo } from "app/navigation";
import store from "app/store";

function CallFromCS(props) {
  let cs_token = props?.match?.params?.cs_token;
  let query = new URLSearchParams(props.location.search);
  const patientQueryData = query.get("direct_f2f_patient");
  const patientData = JSON.parse(patientQueryData);
  useEffect(() => {
    console.log("patientData: ", patientData);
    _verifyCSToken();
  }, []);

  const _verifyCSToken = async () => {
    try {
      console.log("Before verifyCSToken");
      let response = await verifyCSToken({
        switch_from: "customer_support",
        cs_token: cs_token,
      });
      console.log("After verifyCSToken", response);
      if (response.status === 200) {
        console.log("Inside response.data.message", patientData);
        if (patientData) {
          console.log("Inside patientData");
          store.dispatch({
            type: "CLEAR_LOCAL_STATE",
          });
          localStorage.clear();
          console.log("Before navigateToCustomerSelectionViaOTPF2F");
          props.actions.navigateToCustomerSelectionViaOTPF2F(
            response,
            patientData
          );
          window.location.href = appRoutesConst.patientDetail;
        } else {
          console.log("Inside else");
          store.dispatch({
            type: "CLEAR_LOCAL_STATE",
          });
          localStorage.clear();
          console.log("Before navigateToCustomerSelectionViaOTP");
          props.actions.navigateToCustomerSelectionViaOTP(response);
          console.log("After navigateToCustomerSelectionViaOTP");
          window.location.href = "/";
        }
      }
    } catch (err) {
      let { message } = getErrorObject(err);
      console.log("Error:", message);
      errorToast({
        content: message,
      });
    }
  };

  return (
    <div className="rightmainsection videosetup_section">
      <div className="container-fluid right_wrapper">
        <div className="row m-0">
          <div className="col-md-1 col-xs-12 in-block"></div>
          <div className="col-md-10 col-xs-12 in-block selection_container">
            <CommonLoader text={"Logging in ..."} />
          </div>
          <div className="col-md-1 col-xs-12 in-block"></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    user: state.localStore.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        navigateToCustomerSelectionViaOTPF2F,
        navigateToCustomerSelectionViaOTP,
      },
      dispatch
    ),
    onSetPatientAppointment: bindActionCreators(
      { onSetPatientAppointment },
      dispatch
    ),
    onSetPatient: bindActionCreators({ onSetPatient }, dispatch),
    navigateTo: bindActionCreators({ navigateTo }, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallFromCS);
export default withRouter(ConnectedComponent);

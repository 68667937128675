/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useState} from "react";
import ReactPaginate from "react-paginate";
import {Select} from "antd";
const {Option} = Select;

const PaginationPatient = (props) => {
  const {pageCount, isLoading, totalCount, page} = props;

  const [state, setState] = useState({
    rowsPerPage: 10,
    selectedPage: 0,
    isloading: false,
  });
  const onPageChange = (data, other) => {
    // data.selected + 1 cause it starts from 0
    if (props.fromFetchUser) {
      setState((prevState) => {
        return {
          ...prevState,
          selectedPage: data.selected + 1,
        };
      });
      props.onPageChange({
        page: data.selected + 1,
        data_count: state.rowsPerPage,
      });
      if (!props.noScrolltoTop) {
        window.scroll(0, 0);
      }
    } else {
      props.onPageChange({page: data.selected + 1});
      if (!props.noScrolltoTop) {
        window.scroll(0, 0);
      }
    }
  };
  const handleRowsPerPage = (value) => {
    setState((prevState) => {
      return {
        ...prevState,
        rowsPerPage: value,
        selectedPage: 0,
      };
    });
    props.onPageChange({data_count: value});
  };
  useEffect(() => {
    if (+state.rowsPerPage !== 10 && isLoading) {
      setState({...state, isloading: true});
    } else {
      setState({...state, isloading: false});
    }
    return () => {
      setState({...state, isloading: false});
    };
  }, [state.rowsPerPage, isLoading]);

  let paginate = null;
  if (
    pageCount > 0 &&
    !state.isloading &&
    totalCount > 10 &&
    props.fromFetchUser
  ) {
    paginate = (
      <Fragment>
        <div className="usertabledropdown">
          <div>
            <Select
              className="custom-arrow"
              onChange={handleRowsPerPage}
              value={state.rowsPerPage}
            >
              <Option value={10}>10/page</Option>
              <Option value={20}>20/page</Option>
              <Option value={30}>30/page</Option>
              <Option value={40}>40/page</Option>
              <Option value={50}>50/page</Option>
            </Select>
          </div>
        </div>

        <div className="userpagination">
          <ReactPaginate
            pageCount={pageCount}
            disableInitialCallback={true}
            onPageChange={onPageChange}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            breakClassName={"page-item"}
            initialPage={state.selectedPage > 0 ? state.selectedPage - 1 : 0}
            breakLabel={<a className="page-link">...</a>}
            previousLabel={"previous"}
            nextLabel={"next"}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
          />
        </div>
      </Fragment>
    );
  } else if (pageCount > 1) {
    paginate = (
      <Fragment>
        {props.fromFetchUser && (
          <Fragment>
            <div className="usertabledropdown">
              <div>
                <Select
                  className="custom-arrow"
                  onChange={handleRowsPerPage}
                  value={state.rowsPerPage}
                >
                  <Option value={10}>10/page</Option>
                  <Option value={20}>20/page</Option>
                  <Option value={30}>30/page</Option>
                  <Option value={40}>40/page</Option>
                  <Option value={50}>50/page</Option>
                </Select>
              </div>
            </div>
            <div className="userpagination">
              <ReactPaginate
                pageCount={pageCount}
                disableInitialCallback={true}
                onPageChange={onPageChange}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                breakClassName={"page-item"}
                initialPage={
                  state.selectedPage > 0 ? state.selectedPage - 1 : 0
                }
                breakLabel={<a className="page-link">...</a>}
                previousLabel={"previous"}
                nextLabel={"next"}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
                pageClassName="page-item"
                previousClassName="page-item"
                nextClassName="page-item"
                pageLinkClassName="page-link"
                previousLinkClassName="page-link"
                nextLinkClassName="page-link"
              />
            </div>
          </Fragment>
        )}
        {!props.fromFetchUser && (
          <ReactPaginate
            pageCount={pageCount}
            disableInitialCallback={true}
            onPageChange={onPageChange}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            breakClassName={"page-item"}
            initialPage={page > 0 ? page - 1 : 0}
            breakLabel={<a className="page-link">...</a>}
            previousLabel={"previous"}
            nextLabel={"next"}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            pageClassName="page-item"
            previousClassName="page-item"
            nextClassName="page-item"
            pageLinkClassName="page-link"
            previousLinkClassName="page-link"
            nextLinkClassName="page-link"
          />
        )}
      </Fragment>
    );
  }
  return paginate;
};

export default PaginationPatient;

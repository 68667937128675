import React, { Fragment } from "react";

const Section = (props) => {
  return (
    <Fragment>
      <div style={props.style} className="container-fluid">
        <div className="row">{props.children}</div>
      </div>
    </Fragment>
  );
};

export default Section;

import React from "react";
import Layout from "Layouts/PageLayout";
import {connect} from "react-redux";
import {appRoutesConst} from "../../app/navigation";
import {bindActionCreators} from "redux";
import {
  onRequestSetComponent,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import {actions as patientDetailActions} from "../../reducers/patientDetail";
import {navigateTo} from "../../app/navigation";
import {verifyObject} from "../../utilities/utils";
import {Prompt, withRouter} from "react-router-dom";
import {refreshAppointments} from "../../reducers/actionCableData";
import {
  upcomingAppointmentsActions,
  previousAppointmentsActions,
  approveRejectAppointmentsActions,
} from "../../reducers/appointments";
import UpcomingAppointmentContainer from "../../containers/AppointmentsContainers/UpcomingAppointmentContainer";
import PreviousAppointmentContainer from "../../containers/AppointmentsContainers/PreviousAppointmentContainer";
import ApproveRejectAppointmentContainer from "../../containers/AppointmentsContainers/ApproveRejectAppointmentContainer";
import "../../assets/css/pages/appointment.css";
import store from "../../app/store";
// import { CalendarOutlined } from "@ant-design/icons";
// import upcoming from "assets/images/sidebar/ic-upcoming.svg";
// import previous from "assets/images/sidebar/ic-previous.svg";
// import approve_reject from "assets/images/sidebar/ic-approve-reject.svg";
import {
  ROLE_CS_EXECUTIVE,
  ROLE_DOCTOR,
  ROLE_HCSW,
  ROLE_NURSE,
  // ROLE_CLINICAL_PHARMACIST,
} from "../../constants/common";
import {Beforeunload} from "react-beforeunload";
// import { ProgressBar } from "react-bootstrap";
// import { Scrollbars } from 'react-custom-scrollbars';
//svg images start
export const UpcomingmenuIcon = (props) => {
  const {width, fill} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={fill} fill-rule="nonzero">
          <g>
            <g>
              <g transform="translate(-10 -65) translate(0 56) translate(1) translate(9 9)">
                <circle cx="8.8" cy="5.2" r="1" />
                <path d="M10.125.938h-.61v-.47C9.516.21 9.306 0 9.048 0c-.259 0-.469.21-.469.469v.469H6.445v-.47C6.445.21 6.235 0 5.977 0c-.26 0-.47.21-.47.469v.469H3.399v-.47C3.398.21 3.188 0 2.93 0c-.26 0-.47.21-.47.469v.469h-.585C.841.938 0 1.777 0 2.813v7.312C0 11.159.841 12 1.875 12h3.586c.259 0 .469-.21.469-.469s-.21-.469-.47-.469H1.876c-.517 0-.938-.42-.938-.937V2.812c0-.516.421-.937.938-.937h.586v.469c0 .259.21.469.469.469s.468-.21.468-.47v-.468h2.11v.469c0 .259.21.469.469.469.258 0 .468-.21.468-.47v-.468h2.133v.469c0 .259.21.469.469.469s.469-.21.469-.47v-.468h.609c.517 0 .938.42.938.938v2.671c0 .26.21.47.468.47.26 0 .469-.21.469-.47V2.813c0-1.034-.841-1.876-1.875-1.876z" />
                <path d="M9.2 6.4c-1.544 0-2.8 1.256-2.8 2.8 0 1.544 1.256 2.8 2.8 2.8 1.544 0 2.8-1.256 2.8-2.8 0-1.544-1.256-2.8-2.8-2.8zm0 4.674c-1.034 0-1.874-.84-1.874-1.874 0-1.034.84-1.874 1.874-1.874 1.034 0 1.874.84 1.874 1.874 0 1.034-.84 1.874-1.874 1.874z" />
                <path d="M9.936 8.612h-.208v-.518c0-.273-.208-.494-.464-.494s-.464.221-.464.494v1.012c0 .273.208.494.464.494h.672c.256 0 .464-.221.464-.494s-.208-.494-.464-.494z" />
                <circle cx="6.8" cy="5.2" r="1" />
                <circle cx="4.8" cy="7.2" r="1" />
                <circle cx="2.8" cy="5.2" r="1" />
                <circle cx="2.8" cy="7.2" r="1" />
                <circle cx="2.8" cy="9.2" r="1" />
                <circle cx="4.8" cy="9.2" r="1" />
                <circle cx="4.8" cy="5.2" r="1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const PreviousmenuIcon = (props) => {
  const {width, fill} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={fill} fill-rule="nonzero">
          <g>
            <g>
              <g transform="translate(-10 -95) translate(0 56) translate(0 30) translate(10 9)">
                <path d="M6.54 8.531c0 .517.42.938.937.938h1.101c.517 0 .938-.42.938-.938V7.43c0-.517-.42-.938-.938-.938H7.477c-.517 0-.938.42-.938.938V8.53zm.937-1.101h1.101V8.53H7.477V7.43z" />
                <circle cx="9.047" cy="4.922" r="1" />
                <circle cx="7.008" cy="4.922" r="1" />
                <path d="M11.531 10.65c.26 0 .469-.21.469-.469V2.813c0-1.034-.841-1.876-1.875-1.876h-.61V.47c0-.26-.21-.469-.468-.469-.259 0-.469.21-.469.469v.469H6.445v-.47C6.445.21 6.235 0 5.977 0c-.26 0-.47.21-.47.469v.469H3.399v-.47C3.398.21 3.188 0 2.93 0c-.26 0-.47.21-.47.469v.469h-.585C.841.938 0 1.777 0 2.813v7.312C0 11.159.841 12 1.875 12h8.25C11.159 12 12 11.159 12 10.125c0-.259-.21-.469-.469-.469s-.469.21-.469.469c0 .517-.42.938-.937.938h-8.25c-.517 0-.938-.421-.938-.938V2.812c0-.516.421-.937.938-.937h.586v.469c0 .259.21.469.469.469s.468-.21.468-.47v-.468h2.11v.469c0 .259.21.469.469.469.258 0 .468-.21.468-.47v-.468h2.133v.469c0 .259.21.469.469.469s.469-.21.469-.47v-.468h.609c.517 0 .938.42.938.938v7.368c0 .26.21.469.468.469z" />
                <circle cx="2.93" cy="9" r="1" />
                <circle cx="2.93" cy="4.922" r="1" />
                <circle cx="2.93" cy="6.961" r="1" />
                <circle cx="4.969" cy="6.961" r="1" />
                <circle cx="4.969" cy="4.922" r="1" />
                <circle cx="4.969" cy="9" r="1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const ApproverejectmenuIcon = (props) => {
  const {width, fill} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={fill} fill-rule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M11.531 10.65c.26 0 .469-.21.469-.469V2.813c0-1.034-.841-1.876-1.875-1.876h-.61V.47c0-.26-.21-.469-.468-.469-.259 0-.469.21-.469.469v.469H6.445v-.47C6.445.21 6.235 0 5.977 0c-.26 0-.47.21-.47.469v.469H3.399v-.47C3.398.21 3.188 0 2.93 0c-.26 0-.47.21-.47.469v.469h-.585C.841.938 0 1.777 0 2.813v7.312C0 11.159.841 12 1.875 12h8.25C11.159 12 12 11.159 12 10.125c0-.259-.21-.469-.469-.469s-.469.21-.469.469c0 .517-.42.938-.937.938h-8.25c-.517 0-.938-.421-.938-.938V2.812c0-.516.421-.937.938-.937h.586v.469c0 .259.21.469.469.469s.468-.21.468-.47v-.468h2.11v.469c0 .259.21.469.469.469.258 0 .468-.21.468-.47v-.468h2.133v.469c0 .259.21.469.469.469s.469-.21.469-.47v-.468h.609c.517 0 .938.42.938.938v7.368c0 .26.21.469.468.469z"
                  transform="translate(-10 -125) translate(0 56) translate(0 60) translate(10 9)"
                />
                <g>
                  <path
                    d="M.332 4h3.324c.183 0 .332-.15.332-.333V.333C3.988.15 3.84 0 3.656 0H.332C.15 0 0 .15 0 .333v3.334C0 3.85.149 4 .332 4zM.665.667h2.659v2.666H.664V.667z"
                    transform="translate(-10 -125) translate(0 56) translate(0 60) translate(10 9) translate(6.154 4.333)"
                  />
                  <path
                    d="M2.894 1.098C2.832 1.035 2.747 1 2.659 1s-.173.035-.235.098l-.43.43-.43-.43c-.084-.085-.206-.117-.32-.087-.115.031-.205.121-.236.236-.03.115.002.238.086.322l.43.431-.43.431c-.13.13-.13.341 0 .471.13.13.34.13.47 0l.43-.43.43.43c.13.13.34.13.47 0 .13-.13.13-.34 0-.471L2.464 2l.43-.431c.062-.062.097-.147.097-.236 0-.088-.035-.173-.097-.235z"
                    transform="translate(-10 -125) translate(0 56) translate(0 60) translate(10 9) translate(6.154 4.333)"
                  />
                </g>
                <g>
                  <path
                    d="M.332 4h3.324c.183 0 .332-.15.332-.333V.333C3.988.15 3.84 0 3.656 0H.332C.15 0 0 .15 0 .333v3.334C0 3.85.149 4 .332 4zM.665.667h2.659v2.666H.664V.667z"
                    transform="translate(-10 -125) translate(0 56) translate(0 60) translate(10 9) translate(1.662 4.333)"
                  />
                  <path
                    d="M2.858 1.067c-.07-.053-.159-.076-.246-.064-.087.013-.166.06-.219.13l-.674.902-.092-.184c-.083-.163-.282-.229-.445-.147-.163.082-.23.28-.15.445l.333.667c.051.103.152.172.267.183l.03.001c.104 0 .203-.05.266-.133l.997-1.334c.053-.07.075-.16.063-.247-.013-.087-.06-.166-.13-.22z"
                    transform="translate(-10 -125) translate(0 56) translate(0 60) translate(10 9) translate(1.662 4.333)"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
class AppointmentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: props.componentName || "UpcomingAppointmentContainer",
      refreshAppointmentState: null,
      totalSeconds: 0,
      seconds: 0,
    };
    [
      "_getIndexRoute",
      "_renderDynamicComponent",
      "_getPatientDetails",
      "_handleNavigation",
      "_handleBack",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.upcomingRef = React.createRef();
    // this.interval = setInterval(() => this.tick(), 1000);
  }

  componentDidMount() {
    this.componentInitial = true;
    // if (this.props.userRole === ROLE_CLINICAL_PHARMACIST) {
    // console.log("userRole", this.props.userRole);

    //   navigateTo(appRoutesConst.patientSearch);
    // }
    const urlParams = new URLSearchParams(window.location.search);
    const focused = urlParams.get("focused");
    if (focused) {
      this._getIndexRoute(focused);
    } else {
      this._getIndexRoute();
    }

    let {componentName} = this.props;
    this.setState({componentName});
    store.dispatch({
      type: "IS_VIDEO_CALL_STARTED",
      payload: false,
    });

    store.dispatch({
      type: "ACTUAL_CALL_STARTED",
      payload: false,
    });

    store.dispatch({
      type: "SET_INTIAL_SOCKET_DATA",
      payload: null,
    });

    store.dispatch({
      type: "ON_SET_PATIENT_APPOINTMENT",
      payload: null,
    });
    store.dispatch({
      type: "IS_SCREENSHOT_TAKEN",
      payload: 0,
    });
    store.dispatch({
      type: "ON_SET_PRECONSULT_ID",
      payload: null,
    });
    let {refreshAppointments} = this.props.actionCableActions;
    if (typeof refreshAppointments === "function") {
      refreshAppointments();
    }
  }
  tick = () => {
    if (this.state.seconds < 600) {
      this.setState((prevState) => ({
        seconds: prevState.seconds + 1,
      }));
    } else {
      clearInterval(this.interval);
    }
  };
  /**
   * Retrieves the index route based on the value of the 'focused' parameter.
   *
   * @param {boolean} focused - Indicates whether the route is focused or not.
   * here focused means the route is focused to previous appointments
   * @return {void}
   */
  _getIndexRoute(focused) {
    if (focused) {
      let {onRequestSetComponent, onSetSidebarKey} =
        this.props.dynamicTabAction;
      if (typeof onRequestSetComponent === "function") {
        onRequestSetComponent("PreviousAppointmentContainer");
        onSetSidebarKey(["APPT2"]);
      }
    } else {
      let {onRequestSetComponent, onSetSidebarKey} =
        this.props.dynamicTabAction;
      if (typeof onRequestSetComponent === "function") {
        onRequestSetComponent(
          this.props.componentName || "UpcomingAppointmentContainer"
        );
        onSetSidebarKey(this.props.selectedKey || ["APPT1"]);
      }
    }

    // }
  }

  _getPatientDetails() {
    let {
      patientId,
      patientDetailActions: {onRequest},
    } = this.props;
    if (typeof onRequest === "function") {
      onRequest({id: patientId});
    }
  }

  componentWillReceiveProps(nextProps) {
    let {componentName} = nextProps;
    let {refreshAppointmentData} = nextProps;
    this.setState({
      componentName: componentName,
      refreshAppointmentState: verifyObject(
        refreshAppointmentData,
        "data.code",
        null
      ),
    });
  }

  // componentDidUpdate(prevProps, nextState) {
  //   if (
  //     this.state.refreshAppointmentState !== nextState.refreshAppointmentState
  //   ) {
  //     this._onRequestUpcomingList();
  //   }

  // }

  componentDidUpdate(prevProps, nextState) {
    console.log("nextState", nextState.refreshAppointmentState);
    console.log(
      "this.state.refreshAppointmentState",
      this.state.refreshAppointmentState
    );

    if (
      this.state.refreshAppointmentState === 604 ||
      this.state.refreshAppointmentState === 700
    ) {
      console.log("In if refresh appointment");
      if (
        this.state.refreshAppointmentState !== nextState.refreshAppointmentState
      ) {
        this._onRequestUpcomingList();
      }
    }
  }
  _onRequestUpcomingList() {
    let {upcomingAppointmentsActions} = this.props;
    let {onRequest} = upcomingAppointmentsActions;
    if (typeof onRequest === "function") {
      onRequest({page: 1});
    }
  }

  _renderDynamicComponent() {
    let {componentName} = this.state;

    switch (componentName) {
      case "UpcomingAppointmentContainer":
        return (
          <UpcomingAppointmentContainer
            ref={this.upcomingRef}
            refreshAppointmentState={this.state.refreshAppointmentState}
          />
        );

      case "PreviousAppointmentContainer":
        return <PreviousAppointmentContainer />;

      case "ApproveRejectAppointmentContainer":
        return <ApproveRejectAppointmentContainer />;
      default:
        return <UpcomingAppointmentContainer />;
    }
  }

  _handleNavigation() {
    let {navigateTo} = this.props.navigateTo;
    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.patientSearch);
    }
  }
  _handleBack() {
    let {
      history: {goBack},
    } = this.props;
    if (typeof goBack === "function") {
      goBack();
    }
  }
  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }
  _clearComponents = () => {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(null);
      onSetSidebarKey(null);
    }
    // if (this.componentInitial) {
    //   onSetSidebarKey(["APPT1"]);
    // }
  };
  componentWillUnmount() {
    store.dispatch({
      type: "REFRESH_APPOINTMENTS_CLEAR",
    });
  }

  render() {
    let {userRole} = this.props;
    let sideBarArray = [
      {
        label: "Upcoming",
        path: appRoutesConst.dashboard,
        // icon: <img alt="Upcoming" src={upcoming}></img>,
        icon: (
          <UpcomingmenuIcon
            width="12px"
            fill="#757575"
            className="ant-menu-item-icon"
          />
        ),
        key: `APPT1`,
        page: "UpcomingAppointmentContainer",
        useDynamicComponent: true,
        sKey: `APPT1`,
      },
      {
        label: "Previous",
        path: appRoutesConst.dashboard,
        // icon: <img alt="Previous" src={previous}></img>,
        icon: <PreviousmenuIcon width="12px" fill="#757575" />,
        key: `APPT2`,
        page: "PreviousAppointmentContainer",
        useDynamicComponent: true,
      },

      // {
      //   label: "Calendar View",
      //   path: appRoutesConst.dashboard,
      //   icon: <ApproverejectmenuIcon width="12px" fill="#757575" />,
      //   key: `APPT34`,
      //   page: "AppointmentsFullCalendarContainer",
      //   useDynamicComponent: true,
      // },
    ];
    if (userRole === ROLE_DOCTOR) {
      sideBarArray = [
        ...sideBarArray,
        {
          label: "Approve / Reject",
          path: appRoutesConst.approveRejectAppointments,
          // icon: <img alt="Approve / Reject" src={approve_reject}></img>,
          icon: <ApproverejectmenuIcon width="12px" fill="#757575" />,
          key: `APPT3`,
          page: "ApproveRejectAppointmentContainer",
          useDynamicComponent: true,
        },
      ];
    }
    if (
      userRole === ROLE_NURSE ||
      userRole === ROLE_HCSW ||
      userRole === ROLE_CS_EXECUTIVE
    ) {
      sideBarArray = [
        {
          label: "Upcoming",
          path: appRoutesConst.dashboard,
          // icon: <img alt="" src={upcoming}></img>,
          icon: <UpcomingmenuIcon width="12px" fill="#757575" />,
          key: `APPT1`,
          page: "UpcomingAppointmentContainer",
          useDynamicComponent: true,
          sKey: `APPT1`,
        },
        {
          label: "Previous",
          path: appRoutesConst.dashboard,
          // icon: <img alt="Previous" src={previous}></img>,
          icon: <PreviousmenuIcon width="12px" fill="#757575" />,
          key: `APPT2`,
          page: "PreviousAppointmentContainer",
          useDynamicComponent: true,
        },
      ];
    }
    // if (userRole === ROLE_CLINICAL_PHARMACIST) {
    //   sideBarArray = [
    //     {
    //       label: "Previous",
    //       path: appRoutesConst.dashboard,
    //       // icon: <img alt="Previous" src={previous}></img>,
    //       icon: <PreviousmenuIcon width="12px" fill="#757575" />,
    //       key: `APPT2`,
    //       page: "PreviousAppointmentContainer",
    //       useDynamicComponent: true,
    //     },
    //   ];
    // }
    return (
      <Beforeunload
        onBeforeunload={(event) => {
          this._clearComponents();
        }}
      >
        <Layout sideBarArray={sideBarArray}>
          <Prompt
            message={(location, action) => {
              if (action === "PUSH") {
                this._clearComponents();
                return true;
              }
              return true;
            }}
          />
          <div className="rightmainsection">
            <div className="container-fluid padding-0">
              <div className="row margin-0">
                <div className="container-fluid selection_container padding-left-10 padding-right-10">
                  {/* <ProgressBar
                    min={0}
                    max={600}
                    now={this.state.seconds}
                    status="active"
                    variant="success"
                  /> */}
                  {/* <Scrollbars>
                    
                  </Scrollbars> */}
                  {
                    // this.props.userRole === ROLE_CLINICAL_PHARMACIST ? (
                    //   <PreviousAppointmentContainer />
                    // ) : (
                    this._renderDynamicComponent()
                    // )
                  }
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Beforeunload>
    );
  }
}

const mapStateProps = (state) => {
  return {
    componentName: verifyObject(
      state.localStore,
      "componentName",
      "PatientDetailContainer"
    ),
    userRole: verifyObject(state.localStore, "user.role", null),
    selectedKey: verifyObject(state.dynamicTab, "sidebar_key", null),
    savedSelectedKey: verifyObject(state.localStore, "savedSelectedKey", null),
    // patientId: verifyObject(state.localStore, "patientId", null),
    patientId: verifyObject(state.localStore, "patientId", null),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    isLoadingDetail: verifyObject(state.patientDetails, "isLoading", false),
    refreshAppointmentData: verifyObject(
      state.actionCableData,
      "refreshAppointment",
      false
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    upcomingAppointmentsActions: bindActionCreators(
      upcomingAppointmentsActions,
      dispatch
    ),
    approveRejectAppointmentsActions: bindActionCreators(
      approveRejectAppointmentsActions,
      dispatch
    ),
    previousAppointmentsActions: bindActionCreators(
      previousAppointmentsActions,
      dispatch
    ),
    dynamicTabAction: bindActionCreators(
      {onRequestSetComponent, onSetSidebarKey},
      dispatch
    ),
    patientDetailActions: bindActionCreators(patientDetailActions, dispatch),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
    actionCableActions: bindActionCreators({refreshAppointments}, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(AppointmentsPage);

export default withRouter(ConnectedComponent);

import { get, patch } from "./index";

export const genhsMedicationsApi = (params) => {
  const request = {
    subUrl: `/repeat_medications.json`,
    params,
  };
  return get(request);
};

export const getPastMedications = (params) => {
  const request = {
    subUrl: `/orders/past_medication_orders.json`,
    params,
  };
  return get(request);
};

export const getOrders = (params) => {
  const request = {
    subUrl: `/orders.json`,
    params,
  };
  return get(request);
};

export const reviewScript = (data) => {
  const request = {
    subUrl: `/doctor_orders/${data.id}.json`,
    data,
  };
  return patch(request);
};

export const searchTemplates = (params) => {
  const request = {
    subUrl: `/doc_templates/search.json`,
    params,
  };
  return get(request);
};

/* eslint-disable eqeqeq */
import { Radio } from "antd";
import React, { Fragment, useEffect, useRef } from "react";
import QuestionsAdditionalInformation from "../QuestionsAdditionalInformation";
import { SPLIT_SYMBOL } from "constants/common";

export default function SingleChoiceQuestion({
  handleAnswerChange,
  childQuestions,
  parentQuestion,
  question,
  childOfQuestions,
  gender,
}) {
  // console.log
  const questionDivRef = useRef(null);

  useEffect(() => {
    const questionDiv = questionDivRef.current;

    if (questionDiv) {
      const nodeList = questionDiv.querySelectorAll("ul li");

      nodeList.forEach((element) => {
        // Remove the existing inline style with !important
        element.style.removeProperty("font-size");

        // Add your new style without !important
        element.style.fontSize = "16px"; // Set your desired font size
      });
    }
  }, []);

  if (
    question.is_conditional &&
    childQuestions &&
    (parentQuestion.category === "single_choice" ||
      parentQuestion.category === "multi_choice" ||
      parentQuestion.category === "numerical") &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    //TODO: Handle conditional
    let selectedOption = parentQuestion.answer;
    if (parentQuestion?.category === "single_choice") {
      if (
        question?.conditional_text_values &&
        question?.conditional_text_values[0] === selectedOption
      ) {
        // Render the question and options if the conditional value matches the selected option
        return (
          <Fragment>
            <div className="margin-bottom-10">
              <div
                className={`form-row ${
                  question.question_options.length > 2
                    ? "full_box"
                    : "radiobtnrow"
                }`}
              >
                <div className="col-md-12">
                  {/* Question title */}
                  <div className="weight-500 font-14 d-flex">
                    <span className="dot"></span>{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: question.title }}
                      className="Questions"
                      ref={questionDivRef}
                    />{" "}
                    {question.is_required ? (
                      <sup className="text-danger">*</sup>
                    ) : null}
                  </div>
                </div>
                {/* Radio group for selecting options */}
                <div className="col-md-12">
                  <div className="radiopbtn d-flex push-b-10">
                    <Radio.Group
                      onChange={(e) => {
                        const val = e.target.value;
                        // Handle the change in answer
                        handleAnswerChange(val, parentQuestion, childQuestions);
                      }}
                      value={question.answer}
                      style={{ minWidth: "unset" }}
                    >
                      {/* Render each option */}
                      {question.question_options.map((d, index) => {
                        return (
                          <Radio
                            style={{ minWidth: "unset" }}
                            key={index}
                            value={`${d.title}`}
                          >
                            {d.title}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </div>
                  {/* Display error message if any */}
                </div>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
                <QuestionsAdditionalInformation className="push-12-l" question={question} />
              </div>
            </div>
          </Fragment>
        );
      } else {
        // Return an empty fragment if the conditional value doesn't match the selected option
        return <></>;
      }
    }
    if (parentQuestion?.category === "multi_choice") {
      console.log("Please selectedOption", selectedOption && selectedOption);
      let AllOptions =
        selectedOption && selectedOption.map((d) => d.split(SPLIT_SYMBOL)[0]);

      console.log("Please All options", AllOptions);
      console.log(
        "question?.conditional_text_values",
        question?.conditional_text_values
      );

      if (
        question?.conditional_text_values &&
        AllOptions &&
        AllOptions[0] === question?.conditional_text_values[0] &&
        AllOptions.toString() === question?.conditional_text_values?.toString()
      ) {
        return (
          <Fragment>
            <div className="margin-bottom-10">
              <div
                className={`form-row ${
                  question.question_options.length > 2
                    ? "full_box"
                    : "radiobtnrow"
                }`}
              >
                <div className="col-md-12">
                  {/* Question title */}
                  <div className="weight-500 font-14 d-flex">
                    <span className="dot"></span>{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: question.title }}
                      className="Questions"
                      ref={questionDivRef}
                    />{" "}
                    {question.is_required ? (
                      <sup className="text-danger">*</sup>
                    ) : null}
                  </div>
                </div>
                {/* Radio group for selecting options */}
                <div className="col-md-12">
                  <div className="radiopbtn d-flex push-b-10">
                    <Radio.Group
                      onChange={(e) => {
                        const val = e.target.value;
                        // Handle the change in answer
                        handleAnswerChange(val, parentQuestion, childQuestions);
                      }}
                      value={question.answer}
                      style={{ minWidth: "unset" }}
                    >
                      {/* Render each option */}
                      {question.question_options.map((d, index) => {
                        return (
                          <Radio
                            style={{ minWidth: "unset" }}
                            key={index}
                            value={`${d.title}`}
                          >
                            {d.title}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </div>
                  {/* Display error message if any */}
                </div>
              </div>
              {question.error && (
                <div className="errorandinfo">
                  {question.error && (
                    <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                      {question.error}
                    </span>
                  )}
                </div>
              )}
              <QuestionsAdditionalInformation className="push-12-l" question={question} />
            </div>
          </Fragment>
        );
      } else if (
        question?.conditional_text_values &&
        AllOptions &&
        AllOptions[0] !== question?.conditional_text_values[0] &&
        AllOptions.reverse().toString() ===
          question?.conditional_text_values?.toString()
      ) {
        return (
          <Fragment>
            <div className="margin-bottom-10">
              <div
                className={`form-row ${
                  question.question_options.length > 2
                    ? "full_box"
                    : "radiobtnrow"
                }`}
              >
                <div className="col-md-12">
                  {/* Question title */}
                  <div className="weight-500 font-14 d-flex">
                    <span className="dot"></span>{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: question.title }}
                      className="Questions"
                      ref={questionDivRef}
                    />{" "}
                    {question.is_required ? (
                      <sup className="text-danger">*</sup>
                    ) : null}
                  </div>
                </div>
                {/* Radio group for selecting options */}
                <div className="col-md-12">
                  <div className="radiopbtn d-flex push-b-10">
                    <Radio.Group
                      onChange={(e) => {
                        const val = e.target.value;
                        // Handle the change in answer
                        handleAnswerChange(val, parentQuestion, childQuestions);
                      }}
                      value={question.answer}
                      style={{ minWidth: "unset" }}
                    >
                      {/* Render each option */}
                      {question.question_options.map((d, index) => {
                        return (
                          <Radio
                            style={{ minWidth: "unset" }}
                            key={index}
                            value={`${d.title}`}
                          >
                            {d.title}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </div>
                  {/* Display error message if any */}
                </div>
              </div>
              {question.error && (
                <div className="errorandinfo">
                  {question.error && (
                    <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                      {question.error}
                    </span>
                  )}
                </div>
              )}
              <QuestionsAdditionalInformation className="push-12-l" question={question} />
            </div>
          </Fragment>
        );
      } else {
        return <div></div>;
      }
      // if (
      //   question?.conditional_text_values &&
      //   question?.conditional_text_values[0] === selectedOption
      // ) {
      //   // Render the question and options if the conditional value matches the selected option
      // return (
      //   <Fragment>
      //     <div className="margin-bottom-10">
      //       <div
      //         className={`form-row ${
      //           question.question_options.length > 2
      //             ? "full_box"
      //             : "radiobtnrow"
      //         }`}
      //       >
      //         <div className="col-md-12">
      //           {/* Question title */}
      //           <div className="weight-500 font-14 d-flex">
      //             <span className="dot"></span>{" "}
      //             <span
      //               dangerouslySetInnerHTML={{ __html: question.title }}
      //               className="Questions"
      //             />{" "}
      //             {question.is_required ? (
      //               <sup className="text-danger">*</sup>
      //             ) : null}
      //           </div>
      //         </div>
      //         {/* Radio group for selecting options */}
      //         <div className="col-md-12">
      //           <div className="radiopbtn d-flex push-b-10">
      //             <Radio.Group
      //               onChange={(e) => {
      //                 const val = e.target.value;
      //                 // Handle the change in answer
      //                 handleAnswerChange(val, parentQuestion, childQuestions);
      //               }}
      //               value={question.answer}
      //               style={{ minWidth: "unset" }}
      //             >
      //               {/* Render each option */}
      //               {question.question_options.map((d, index) => {
      //                 return (
      //                   <Radio
      //                     style={{ minWidth: "unset" }}
      //                     key={index}
      //                     value={`${d.title}`}
      //                   >
      //                     {d.title}
      //                   </Radio>
      //                 );
      //               })}
      //             </Radio.Group>
      //           </div>
      //           {/* Display error message if any */}
      //         </div>
      //       </div>
      //       {question.error && (
      //         <div className="errorandinfo">
      //           {question.error && (
      //             <span className="text-danger margin-top-10 weight-400 font-12 in-block">
      //               {question.error}
      //             </span>
      //           )}
      //         </div>
      //       )}
      //       <QuestionsAdditionalInformation question={question} />
      //     </div>
      //   </Fragment>
      // );
      // } else {
      //   // Return an empty fragment if the conditional value doesn't match the selected option
      //   return <></>;
      // }
    }

    if (parentQuestion?.category === "numerical") {
      let selectedValue = parseInt(parentQuestion.answer, 10);
      const { conditional_max_value, conditional_min_value } = question;
      console.log("selectedValue", selectedValue);
      console.log("Max", conditional_max_value);
      console.log("Min", conditional_min_value);
      if (
        selectedValue <= conditional_max_value &&
        selectedValue >= conditional_min_value
      ) {
        console.log("you are good to go");
        return (
          <Fragment>
            <div className="margin-bottom-10">
              <div
                className={`form-row ${
                  question.question_options.length > 2
                    ? "full_box"
                    : "radiobtnrow"
                }`}
              >
                <div className="col-md-12">
                  <div className="weight-500 font-14  d-flex">
                    <span className="dot"></span>{" "}
                    <span
                      dangerouslySetInnerHTML={{ __html: question.title }}
                      className="Questions"
                      ref={questionDivRef}
                    />
                    {question.is_required ? (
                      <sup className="text-danger">*</sup>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="radiopbtn d-flex push-b-10">
                    <Radio.Group
                      onChange={(e) => {
                        const val = e.target.value;
                        handleAnswerChange(val, parentQuestion, childQuestions);
                      }}
                      value={question.answer}
                      style={{ minWidth: "unset" }}
                    >
                      {question.question_options.map((d, index) => {
                        return (
                          <Radio
                            style={{ minWidth: "unset" }}
                            key={index}
                            value={`${d.title}`}
                          >
                            {d.title}
                          </Radio>
                        );
                      })}
                    </Radio.Group>
                  </div>
                  <div className="">
                    {question.error && (
                      <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                        {question.error}
                      </span>
                    )}
                    <QuestionsAdditionalInformation className="push-12-l" question={question} />
                  </div>
                </div>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
                <QuestionsAdditionalInformation className="push-12-l" question={question} />
              </div>
            </div>
          </Fragment>
        );
      } else {
        console.log("you are not allowed");
        return <></>;
      }
    }
  } else if (
    !childOfQuestions &&
    childQuestions &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 margin-bottom-10">
          <div
            className={`form-row   ${
              question.question_options.length > 2 ? "full_box" : "radiobtnrow"
            }`}
          >
            <div className="col-md-12">
              <div className="weight-500 font-14 d-flex">
                <span className="dot"></span>{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: question.title }}
                  className="Questions"
                  ref={questionDivRef}
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              <div className="radiopbtn d-flex">
                <Radio.Group
                  onChange={(e) => {
                    const val = e.target.value;
                    handleAnswerChange(val, parentQuestion, childQuestions);
                  }}
                  value={question.answer}
                  style={{ minWidth: "unset" }}
                >
                  {question.question_options.map((d, index) => {
                    return (
                      <Radio
                        style={{ minWidth: "unset" }}
                        key={index}
                        value={`${d.title}`}
                      >
                        {d.title}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
            <QuestionsAdditionalInformation className="push-12-l" question={question} />
          </div>
        </div>
      </Fragment>
    );
  } else if (
    childOfQuestions &&
    childQuestions.answer &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 margin-bottom-10">
          <div
            className={`form-row ${
              question.question_options.length > 2 ? "full_box" : "radiobtnrow"
            }`}
          >
            <div className="col-md-12">
              <div className="weight-500 font-14  d-flex">
                <span className="dot"></span>{" "}
                <span
                  dangerouslySetInnerHTML={{ __html: question.title }}
                  className="Questions"
                  ref={questionDivRef}
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
            </div>
            <div className="col-md-12">
              <div className="radiopbtn d-flex push-b-10">
                <Radio.Group
                  onChange={(e) => {
                    const val = e.target.value;
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions
                    );
                  }}
                  value={question.answer}

                  //   value={questions.answer}
                >
                  {question.question_options.map((d, index) => {
                    return (
                      <Radio
                        style={{ minWidth: "unset" }}
                        key={index}
                        value={`${d.title}`}
                      >
                        {d.title}
                      </Radio>
                    );
                  })}
                </Radio.Group>
              </div>
              <div className="">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
                <QuestionsAdditionalInformation className="push-12-l" question={question} />
              </div>
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
            <QuestionsAdditionalInformation className="push-12-l" question={question} />
          </div>
        </div>
      </Fragment>
    );
  } else if (
    !childOfQuestions &&
    !childQuestions &&
    question &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="single-choice-question_wrapper">
          <div
            className={`${
              question.question_options.length > 2 ? "full_box" : "radiobtnrow"
            }`}
          >
            <div className="weight-500 font-14  d-flex">
              <span className="dot"></span>{" "}
              <span
                dangerouslySetInnerHTML={{ __html: question.title }}
                className="Questions"
                ref={questionDivRef}
              />
              {question.is_required ? (
                <sup className="text-danger">*</sup>
              ) : null}
            </div>
            <div className="radiopbtn d-flex push-b-10 mt-2 push-12-l">
              <Radio.Group
                onChange={(e) => {
                  const val = e.target.value;
                  handleAnswerChange(
                    val,
                    parentQuestion,
                    childQuestions,
                    childOfQuestions
                  );
                }}
                value={question.answer}

                //   value={questions.answer}
              >
                {question.question_options.map((d, index) => {
                  return (
                    <Radio
                      style={{ minWidth: "unset" }}
                      key={index}
                      value={`${d.title}`}
                    >
                      {d.title}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
          <QuestionsAdditionalInformation className="push-12-l" question={question} />
        </div>
      </Fragment>
    );
  } else {
    return <div></div>;
  }

  return <div></div>;
}

import React, { Fragment } from "react";
import search_icon from "assets/images/common/search-gray.svg";
import DebouncedInput from "components/common/DebouncedInput";
import crossIcon from "assets/images/common/close_small.svg";
const inputProps = {
  type: "text",
  className: "search-input",
  name: "search",
  placeholder: "Search"
};

type IProps = {
  onUpdate: () => {}
};

class SearchInput extends React.Component<IProps> {
  render() {
    return (
      <div className="search-bar relative search-clear">
        <DebouncedInput
          {...this.props}
          inputProps={inputProps}
          render={(value, onClear) => (
            <Fragment>
              <i className="search-icon">
                <img alt="search" src={search_icon} />
              </i>
              {value.length > 0 && (
                <img
                  src={crossIcon}
                  alt="x"
                  onClick={onClear}
                  className="search-icon-clear clear-1"
                />
              )}
            </Fragment>
          )}
        />
      </div>
    );
  }
}
export default SearchInput;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import PreConsultationReviewPage from "./PreConsultationReviewPage";
import {connect} from "react-redux";
import {verifyObject} from "utilities/utils";
import {actions as preConsultationReviewActions} from "../../reducers/preConsultationReview";
import {actions as kioskPreConsultReviewActions} from "../../reducers/kioskPreConsult";

import {actions as preConsultationSubmitLogsActions} from "../../reducers/preConsultationSubmitLogs";
import {bindActionCreators} from "redux";
function PreConsultationMainPage({
  preConsultationReviewLoading,
  preConsultationSubmitLogsLoading,
  pre_consult_tab_key,
  preConsultationReviewActions,
  preConsultationSubmitLogsActions,
  kioskPreConsultReviewActions,
}) {
  console.log("preConsultationReviewLoading", preConsultationReviewLoading);
  console.log(
    "preConsultationSubmitLogsLoading",
    preConsultationSubmitLogsLoading
  );
  //   useEffect(()=>{
  //     console.log("pre_consult_tab_key---------->", pre_consult_tab_key);

  //     // if (pre_consult_tab_key === "link_sent") {
  //     //     preConsultationSubmitLogsActions.onRequest({
  //     //       status: "link_sent",
  //     //       page: 1,
  //     //     });
  //     //   }
  //     //   if (pre_consult_tab_key === "in_progress") {
  //     //     preConsultationSubmitLogsActions.onRequest({
  //     //       status: "in_progress",
  //     //       page: 1,
  //     //     });
  //     //   }
  //     //   if (pre_consult_tab_key === "pending") {
  //     //     preConsultationReviewLoading.onRequest({
  //     //       status: "pending",
  //     //       page: 1,
  //     //     });
  //     //   }
  //     //   if (pre_consult_tab_key === "pre_consult_reviewed") {
  //     //     preConsultationReviewLoading.onRequest({
  //     //       status: "reviewed",
  //     //       page: 1,
  //     //     });
  //     //   }
  //   },[])

  useEffect(() => {
    // alert(pre_consult_tab_key);
    const urlParams = new URLSearchParams(window.location.search);
    const isPending = urlParams.get("pending");
    if (pre_consult_tab_key === "link_sent") {
      preConsultationSubmitLogsActions.onRequest({
        status: "link_sent",
        page: 1,
      });
    }
    if (pre_consult_tab_key === "in_progress") {
      preConsultationSubmitLogsActions.onRequest({
        status: "in_progress",
        page: 1,
      });
    }
    if (pre_consult_tab_key === "pending") {
      preConsultationReviewActions.onRequest({
        status: "pending",
        page: 1,
      });
    }
    if (!isPending && pre_consult_tab_key === "pre_consult_reviewed") {
      preConsultationReviewActions.onRequest({
        status: "reviewed",
        page: 1,
      });
    }
    if (pre_consult_tab_key === "kiosk_pending") {
      kioskPreConsultReviewActions.onRequest({
        status: "pending",
        // bp_ranges: "normal",
        // hypertensive: "yes",
        data_point_type: "blood_pressure",
        page: 1,
      });
    }
    if (pre_consult_tab_key === "kiosk_reviewed") { 
      kioskPreConsultReviewActions.onRequest({
        status: "reviewed",
        // bp_ranges: "normal",
        // hypertensive: "yes",
        data_point_type: "blood_pressure",
        page: 1,
      });
    }
  }, [pre_consult_tab_key]);

  return (
    <div>
      <PreConsultationReviewPage />
    </div>
  );
}
const mapStateProps = (state) => {
  return {
    preConsultationReviewLoading: verifyObject(
      state,
      "preConsultationReview.isLoading",
      null
    ),
    preConsultationSubmitLogsLoading: verifyObject(
      state,
      "preConsultationSubmitLogs.isLoading",
      null
    ),
    pre_consult_tab_key: verifyObject(
      state,
      "localStore.pre_consult_tab_key",
      "finished"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    preConsultationReviewActions: bindActionCreators(
      preConsultationReviewActions,
      dispatch
    ),
    kioskPreConsultReviewActions: bindActionCreators(
      kioskPreConsultReviewActions,
      dispatch
    ),

    preConsultationSubmitLogsActions: bindActionCreators(
      preConsultationSubmitLogsActions,
      dispatch
    ),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(PreConsultationMainPage);

export default ConnectedComponent;

import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as nhsMedicationsActions} from "../../../reducers/nhsRepeatMedications";
import {actions as shortCodesActions} from "../../../reducers/shortCodes";
import {Pagination} from "../../../components/common";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../../utilities/utils";
import {debounce, isEmpty} from "lodash";
// import moment from "moment";

// import { Collapse } from "antd";
// import {
//   Collapse,
// } from "antd";

// import {DATE_FORMAT} from "../../../constants/common";
import {addAcuteMedicationsApi} from "../../../services/acuteMedications";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {createCustomAbbrivations, getDrugsApi} from "../../../services/drugs";
// import {getOrders} from "../../../services/orders";

import {PlusSquareOutlined} from "@ant-design/icons";

import SortCodeEditModal from "./SortCodeEditModal";
import {deleteShortCodeAPI} from "services/consultations";
import FilterComponent from "components/FIlterComponent/FilterComponent";
import {Tabs} from "antd";
import ShortCodesTable from "./ShortCodesTable";
import AbbreviationListingModal from "../PrescriptionContainer/AbbreviationListingModal";
import EditAbbreviationModal from "../PrescriptionContainer/EditAbbreviationModal";
// import PastScriptReviewDeatailContainer from "./PastScriptReviewDeatailContainer";
import {actions as prescriptionAbbreviationActions} from "reducers/prescriptionAbbreviation";

// import { SearchOutlined } from "@ant-design/icons";
// import { Input } from "antd";

// const { Panel } = Collapse;
class SortCodesContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      message: "",
      shortCodes: null,
      isLoading: false,
      drugsArray: [],
      drugId: null,
      activeKey: [""],
      search: "",
      pastCoreDrugId: "",
      selectedRecord: null,
      deleteID: null,
      isClearedFilters: "",
      tabKey: "all",
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleSearch",
      "_handleCancel",
      "_hideModal",
      "_handleDropDownChange",
      "_handleSubmit",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.searchDrugUpdate = debounce(this._handleDrugSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, nhsMedicationsActions} = this.props;
    let {onSearch} = nhsMedicationsActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({search: value, patient_id: patientId, page: 1});
    }
  }

  async _handleDrugSearch(value) {
    if (!isEmpty(value)) {
      if (!isEmpty(value)) {
        this.setState(
          {
            drugsArray: [],
            page: 1,
            searching: true,
            isLoadmore: false,
            search: value,
          },
          async () => {
            this._getDrugs(false);
          }
        );
      } else {
        await this.setState({snomedCodes: []});
      }
      // try {
      //     this.setState({ searching: true, search: value }, async () => {
      //         let response = await getDrugsApi({ page: 1, term: value });
      //         let drugsArray = verifyObject(response, "data.data", [])
      //         this.setState({ drugsArray: drugsArray, searching: false })
      //     });

      // } catch (error) {
      //     const { message } = getErrorObject(error);
      //     await this.setState({ searching: false });
      //     errorToast({ content: message });
      // }
    } else {
      await this.setState({searching: false, drugsArray: []});
    }
  }
  _getDrugs = async (isLoadMore) => {
    try {
      let response = await getDrugsApi({
        page: this.state.page,
        term: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let drugsArray = verifyObject(response, "data.data", []);
      this.setState({
        drugsArray: isLoadMore
          ? [...this.state.drugsArray, ...drugsArray]
          : drugsArray,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  };

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getDrugs(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };

  // _onRequestList() {
  //     let { patientId, nhsMedicationsActions } = this.props
  //     let { onRequest } = nhsMedicationsActions
  //     if (typeof onRequest === "function") {
  //         onRequest({ patient_id: patientId, page: 1 })
  //     }
  // }

  async _onRequestList() {
    let {shortCodesActions} = this.props;
    let {onRequest} = shortCodesActions;
    if (typeof onRequest === "function") {
      if (this.props.tabKey === "all") {
        onRequest({});
      } else {
        onRequest({code_key: this.props.tabKey});
      }
    }
  }
  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    this.setState({[key]: value ? value : "", errors: errors}, () => {});
  }
  _onPageChange(data) {
    this.setState({activeKey: [""]});
    let {
      shortCodesActions: {onPageChange},
      // nhsMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page});
    }
  }
  prescriptionAbbreviationPageChange = async (data) => {
    let {
      prescriptionAbbreviationActions: {onPageChange},
      // nhsMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page});
    }
  };
  _handleCancel() {
    this.setState({
      visible: false,
      message: "",
      adding: false,
      drugsArray: [],
    });
  }

  _hideModal() {
    this.setState({visible: false, selectedRecord: null});
  }
  handleEdit = (selectedRecord) => {
    this.setState({selectedRecord});
  };

  async _handleSubmit(e) {
    e.preventDefault();
    let {drugId} = this.state;
    let {patientId} = this.props;
    let payload = {
      drug_id: drugId,
      user_id: patientId,
    };
    try {
      await this.setState({changing: true});
      let response = await addAcuteMedicationsApi({
        medication: {...payload},
      });
      await this._clearData();
      await successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      await this._onRequestList();
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({changing: false});
      errorToast({content: message});
    }
  }

  _submitDelete = async () => {
    try {
      await this.setState({deleting: true});
      let response = await deleteShortCodeAPI({
        id: this.state.deleteID,
      });
      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      this._handleCancel();
      this._onRequestList();
      this._clearData();
      await this.setState({deleting: false, deleteID: null});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({deleting: false, deleteID: null});
      errorToast({content: message});
    }
  };

  // handleTabChange = (key) => {
  //   this.setState({ tabKey: key }, () => {
  //     this._onRequestList();
  //   });
  // };
  _handleDelete = (record) => {
    this.setState({
      showDelete: true,
      deleteID: record.id,
    });
  };

  _clearData = () => {
    this.setState({
      visible: false,
      message: "",
      shortCodes: null,
      isLoading: false,
      drugsArray: [],
      drugId: null,
      changing: false,
      selectedRecord: null,
      showDelete: false,
      deleteID: null,
    });
  };
  onRequestListAbbreviation = async () => {
    let {
      prescriptionAbbreviationActions: {onRequest},
      // nhsMedications: {search},
    } = this.props;

    if (typeof onRequest === "function") {
      await onRequest({});
    }
  };
  handleAddAbbreviation = async (data) => {
    try {
      let formData = new FormData();

      formData.set("prescription_abbrevation[abbreviation]", data.abbreviation);
      formData.set("prescription_abbrevation[meaning]", data.meaning);
      formData.set(
        "prescription_abbrevation[numeric_meaning]",
        data.numeric_meaning
      );

      this.setState({addingAbbriviation: true});
      const response = await createCustomAbbrivations(formData);
      await this.onRequestListAbbreviation();
      successToast({
        content: verifyObject(response, "data.message", "Added successfully"),
      });
      this.setState({addingAbbriviation: false, visible: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      errorToast({content: message});
      this.setState({addingAbbriviation: false});
    }
  };

  render() {
    let {
      // shortCodes,

      visible,
    } = this.state;
    let {data, isLoading, ids} = this.props.shortCodesProps;
    let {
      data: prescriptionData,
      isLoading: prescriptionIsLoading,
      ids: prescriptionIds,
    } = this.props.prescriptionAbbreviation;
    let shortCodes = data && Object.values(data);
    // console.log("showShortCodeModal::::", this.props.showShortCodeModal);
    console.log("active key::::", this.props.activeKey);
    return (
      <Fragment>
        <div className="testinvest_wrapper shortcodewrap">
          <div className="shortcodefiltermain">
            <div
              style={{display: "flex", justifyContent: "end"}}
              className="shortcodefilter"
            >
              {/* {this.props.tabKey !== "prescription_abbreviation" && ( */}
              <FilterComponent
                isLoading={
                  this.props.tabKey !== "prescription_abbreviation"
                    ? isLoading
                    : prescriptionIsLoading
                }
                searchOnly={true}
                onFilterChange={
                  this.props.tabKey !== "prescription_abbreviation"
                    ? this.props.shortCodesActions.onFilterChange
                    : this.props.prescriptionAbbreviationActions.onFilterChange
                }
                resetFilters={
                  this.props.tabKey !== "prescription_abbreviation"
                    ? this.props.shortCodesActions.resetFilterState
                    : this.props.prescriptionAbbreviationActions
                        .resetFilterState
                }
                isClearedFilters={this.props.isClearedFilters}
                defaultRequest={
                  this.props.tabKey !== "prescription_abbreviation"
                    ? {
                        code_key:
                          this.props.tabKey === "all" ? "" : this.props.tabKey,
                      }
                    : null
                }
                tabKey={
                  this.props.tabKey === "prescription_abbreviation" &&
                  "prescription_abbreviation"
                }
              />
              {/* )} */}

              <div style={{padding: 8}} className="shortaddbtn">
                <a
                  onClick={() =>
                    this.setState({
                      visible: true,
                      selectedRecord: null,
                    })
                  }
                  className="font-13 weight-500 text-blue"
                >
                  <PlusSquareOutlined
                    style={{
                      cursor: "pointer",
                      color: "#1445b7",
                      fontSize: "15px",
                    }}
                  />{" "}
                  Add{" "}
                </a>
                {this.props.tabKey === "prescription_abbreviation" && (
                  <EditAbbreviationModal
                    isEditModalOpen={visible}
                    handleEdit={this.handleAddAbbreviation}
                    closeEditModal={this._hideModal}
                    selectedItem={this.state.selectedRecord}
                    isLoading={this.state.addingAbbriviation}
                    forAddAbbreviation={true}
                  />
                )}
                {this.props.tabKey !== "prescription_abbreviation" && (
                  <SortCodeEditModal
                    _onRequestList={this._onRequestList}
                    visible={visible}
                    _hideModal={this._hideModal}
                    selectedRecord={this.state.selectedRecord}
                  />
                )}
              </div>
            </div>
          </div>

          <Tabs
            onChange={this.props.handleTabChange}
            defaultActiveKey="all"
            activeKey={this.props.tabKey}
            destroyInactiveTabPane={true}
          >
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">All</span>
                  </span>
                </div>
              }
              key={"all"}
              disabled={isLoading}
            >
              <ShortCodesTable
                _handleDelete={this._handleDelete}
                isLoading={isLoading}
                shortCodes={shortCodes?.reverse()}
                state={this.state}
                ids={ids}
                selectedRecord={this.props.selectedRecord}
                _onRequestList={this._onRequestList}
                _clearSelectedRecords={this.props._clearSelectedRecords}
                handleEdit={this.props._handleEdit}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">History</span>
                  </span>
                </div>
              }
              key={"history"}
              disabled={isLoading}
            >
              <ShortCodesTable
                _handleDelete={this._handleDelete}
                isLoading={isLoading}
                shortCodes={shortCodes}
                state={this.state}
                ids={ids}
                selectedRecord={this.props.selectedRecord}
                _onRequestList={this._onRequestList}
                _clearSelectedRecords={this.props._clearSelectedRecords}
                handleEdit={this.props._handleEdit}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">Examination</span>
                  </span>
                </div>
              }
              key={"examination"}
              disabled={isLoading}
            >
              <ShortCodesTable
                _handleDelete={this._handleDelete}
                isLoading={isLoading}
                shortCodes={shortCodes?.reverse()}
                state={this.state}
                ids={ids}
                selectedRecord={this.props.selectedRecord}
                _onRequestList={this._onRequestList}
                _clearSelectedRecords={this.props._clearSelectedRecords}
                handleEdit={this.props._handleEdit}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">Management Plan</span>
                  </span>
                </div>
              }
              key={"management_plan"}
              disabled={isLoading}
            >
              <ShortCodesTable
                _handleDelete={this._handleDelete}
                isLoading={isLoading}
                shortCodes={shortCodes?.reverse()}
                state={this.state}
                ids={ids}
                selectedRecord={this.state.selectedRecord}
                _onRequestList={this._onRequestList}
                _clearSelectedRecords={this.props._clearSelectedRecords}
                handleEdit={this.props._handleEdit}
              />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={
                <div>
                  <span className="icons_wrapper">
                    <span className="icons_wrapper_img_box"></span>
                    <span className="icons_wrapper_title">
                      Prescription Abbreviation
                    </span>
                  </span>
                </div>
              }
              key={"prescription_abbreviation"}
              disabled={isLoading}
            >
              <AbbreviationListingModal
                // items={
                //   // prescriptionData
                //   [
                //     {
                //       abbreviation: "Sample",
                //       numeric_meaning: 3,
                //       meaning: "KYA KAROGE",
                //       id: 1,
                //     },
                //   ]
                // }
                isLoading={prescriptionIsLoading}
                activeKey={this.props.tabKey}
                data={prescriptionData}
                ids={prescriptionIds}
                onRequestListAbbreviation={this.onRequestListAbbreviation}
              />
              {!isLoading && this.props.prescriptionAbbreviation && (
                <div className="col-md-12">
                  <Pagination
                    data={this.props.prescriptionAbbreviation}
                    onPageChange={this.prescriptionAbbreviationPageChange}
                  />
                </div>
              )}
              {/* <ShortCodesTable
                _handleDelete={this._handleDelete}
                isLoading={isLoading}
                shortCodes={shortCodes?.reverse()}
                state={this.state}
                ids={ids}
                selectedRecord={this.state.selectedRecord}
                _onRequestList={this._onRequestList}
                _clearSelectedRecords={this.props._clearSelectedRecords}
                handleEdit={this.props._handleEdit}
                activeKey={this.props.tabKey}
              /> */}
            </Tabs.TabPane>
          </Tabs>
        </div>

        {!isLoading &&
          this.props.shortCodesProps &&
          this.props.tabKey !== "prescription_abbreviation" && (
            <div className="col-md-12">
              <Pagination
                data={this.props.shortCodesProps}
                onPageChange={this._onPageChange}
              />
            </div>
          )}
        <ModalPopUp
          title="Are you sure want to delete this record"
          visible={this.state.showDelete}
          handleCancel={() => {
            this.setState({
              showDelete: false,
              deleteID: null,
            });
          }}
          loading={this.state.deleting}
          closable={true}
          footer={true}
          handleOk={this._submitDelete}
          okBtnClass="btn-danger"
          okText={"Delete"}
        ></ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("past ordere state", state);
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    nhsMedications: verifyObject(state, "nhsMedications", []),
    shortCodesProps: verifyObject(state, "shortCodes", []),
    prescriptionAbbreviation: verifyObject(
      state,
      "prescriptionAbbreviation",
      []
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nhsMedicationsActions: bindActionCreators(nhsMedicationsActions, dispatch),
    shortCodesActions: bindActionCreators(shortCodesActions, dispatch),
    prescriptionAbbreviationActions: bindActionCreators(
      prescriptionAbbreviationActions,
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SortCodesContainer);

export default ConnectedComponent;

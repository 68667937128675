import React, {Fragment} from "react";

import logo from "assets/images/common/main_logo.svg";

// import ChangePasswordContainer from "../../containers/session/ChangePasswordContainer";
import ResetPasswordWithTokenContainer from "../../containers/session/ResetPasswordWithTokenContainer";
import {Link} from "react-router-dom";
import {ErrorBoundary} from "app/ErrorBoundary";
const ResetPasswordWithTokenPage = (props) => {
  return (
    <Fragment>
      <div className="otp-wrapper">
        <div className="container-fluid">
          <div className="otp-wrapper-logo">
            <Link to="/" title="healthya.co.uk">
              <img src={logo} className="AlldayDrlogo" alt="Healthyalogo" />
            </Link>
          </div>
        </div>

        <div className="login-title-wrapper">
          <div className="container-fluid">
            <h1 className="login-title-h1">Reset Password</h1>
          </div>
        </div>
        <ErrorBoundary>
          <div className="container-fluid">
            <div className="otp-content-wrapper">
              <div className="body-content">
                <ResetPasswordWithTokenContainer />
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </Fragment>
  );
};

export default ResetPasswordWithTokenPage;

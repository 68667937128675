/* eslint-disable no-unused-expressions */
import {verifyObject} from "utilities/utils";
import Highcharts from "highcharts/highstock";
import {isArray} from "lodash";

export default function createHealthGraph(data, record, chartProps) {
  // let Highcharts = window.Highcharts;
  Highcharts.getOptions().colors.push("#1445b7");

  //   console.log("GRAPH NAME", Highcharts.getOptions().colors.push("#1445b7"));
  console.log("GRAPH NAME", Highcharts.getOptions().colors);
  let name = record.name;
  let unit = `${record.unit}`;
  var easeOutBounce = function (pos) {
    if (pos < 1 / 2.75) {
      return 7.5625 * pos * pos;
    }
    if (pos < 2 / 2.75) {
      return 7.5625 * (pos -= 1.5 / 2.75) * pos + 0.75;
    }
    if (pos < 2.5 / 2.75) {
      return 7.5625 * (pos -= 2.25 / 2.75) * pos + 0.9375;
    }
    return 7.5625 * (pos -= 2.625 / 2.75) * pos + 0.984375;
  };
  Math.easeOutBounce = easeOutBounce;

  let systolicData = verifyObject(data, "systolic[0]", []);

  console.log(
    "marker===>",
    data,
    data &&
      systolicData &&
      systolicData.length !== 0 &&
      systolicData.map((d, index) =>
        d && d < 120
          ? "#2072b9"
          : d && d > 120 && d < 130
          ? "#16b250"
          : d && d > 130 && d < 140
          ? "#f5eb1a"
          : d && d > 140 && d < 160
          ? "#fdc60d"
          : d && d > 160 && d < 180
          ? "#ed1e24"
          : "#c22026"
      )
  );

  // let colors =
  //   data &&
  //   systolicData &&
  //   systolicData.length !== 0 &&
  //   systolicData.map((d, index) =>
  //     d && d < 120
  //       ? "#2072b9"
  //       : d && d > 120 && d < 130
  //       ? "#16b250"
  //       : d && d > 130 && d < 140
  //       ? "#f5eb1a"
  //       : d && d > 140 && d < 160
  //       ? "#fdc60d"
  //       : d && d > 160 && d < 180
  //       ? "#ed1e24"
  //       : "#c22026"
  //   );
  //  Highcharts.scroller.scrollbarGroup.hide();

  console.log("Current time in New York", data);
  if (chartProps.name === "blood_pressure") {
    const chart = new Highcharts.Chart(`${record.point_type}Graph`, {
      chart: {
        type: chartProps.type,
        animation: {
          duration: 2000,
        },
        zoomType: false,
        // events: {
        //   load: function () {
        //     this.xAxis[0].setExtremes(moment(data[1][0]).unix()*1000);
        //     // if(this.scroller)
        //     console.log("this", moment(data[1][0]).unix()*1000);
        //     //  this.scroller.scrollbarGroup.hide();
        //   },
        // },
        //   width: 800,
      },
      title: {
        text: "",
      },
      rangeSelector: {
        enabled: false,
        selected: 1,
      },
      navigator: {
        enabled: true,

        adaptToUpdatedData: true,
        maskFill: "rgb(20, 69, 183,0.1)",
        maskInside: true,
        outlineWidth: 0,
        handles: {
          backgroundColor: "#1445b7",
          // borderColor: "#d4eefd",
        },
        series: {
          type: "areaspline",
          fillOpacity: 1,
          lineWidth: 0,
        },
        xAxis: {
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          plotBands: [
            {
              color: "rgba(212, 238, 253, 0)",
              from: 0,
              to: 1,
            },
          ],
        },
      },

      legend: {
        enabled: false,
        // layout: "vertical",
        // align: "left",
        // verticalAlign: "top",
        // x: 150,
        // y: 100,
        // floating: true,
        // borderWidth: 1,
        // backgroundColor:
        //   Highcharts.defaultOptions.legend.backgroundColor || "#FFFFFF",
      },
      xAxis: {
        type: "datetime",
        categories: verifyObject(data, "systolic[1]", []),
        min: 0,
        max:
          verifyObject(data, "systolic[1]", []).length >= 20
            ? 20
            : verifyObject(data, "systolic[1]", []).length - 1,
        opposite: false,
        title: {
          text: "Date",
        },
        gridLineWidth: 1,
        gridLineColor: "#f7f7f7",
      },
      yAxis: [
        {
          opposite: false,
          title: {
            text: name,
          },
          // tickInterval: 20,
          gridLineColor: "",

          plotLines: [
            {
              color: "#808396",
              width: 1,
              value: 80,
              dashStyle: "Dash",
            },
            {
              color: "#808396",
              width: 1,
              value: 140,
              dashStyle: "Dash",
              tickInterval: 10,
            },
          ],
        },
      ],
      plotOptions: {
        series: {
          turboThreshold: 5000,
          dataLabels: {
            enabled: true,
            color: "#1445b7",
            style: {
              textOutline: "none",
              strokeWidth: 0,
            },
          },
        },
        area: {
          // dataLabels: {
          //   enabled: true,
          //   color: "#1445b7",
          // },
          animation: {
            duration: 2000,
          },
          enableMouseTracking: true,
          tooltip: {
            valueSuffix: `${unit === "null" ? "" : unit}`,
          },
        },
      },
      credits: {
        enabled: false,
      },
      scrollbar: {
        enabled: false,
        // data && data["systolic"] && data["systolic"][0].length > 21
        //   ? true
        //   : false,
        barBackgroundColor: "#1445b7",
        barBorderRadius: 4,
        barBorderWidth: 0,
        buttonBackgroundColor: "#d4eefd",
        showFull: true,
        buttonBorderWidth: 0,
        buttonArrowColor: "#d4eefd",
        buttonBorderRadius: 7,
        rifleColor: "#d4eefd",
        trackBackgroundColor: "#d4eefd",
        trackBorderWidth: 1,
        // trackBorderColor: "#d4eefd",
        trackBorderRadius: 7,
        height: 5,
        useHtml: true,
      },
      series: [
        {
          // type: "coloredline",
          name: "systolic",
          // data: verifyObject(data, "systolic[0]", []),
          data: verifyObject(data, "systolic[0]", [])
            .slice(0, 1000)
            .map((d, i) => {
              return {
                y: d,
                segmentColor:
                  d && d < 120
                    ? "#2072b9"
                    : d && d > 120 && d < 130
                    ? "#16b250"
                    : d && d > 130 && d < 140
                    ? "#f5eb1a"
                    : d && d > 140 && d < 160
                    ? "#fdc60d"
                    : d && d > 160 && d < 180
                    ? "#ed1e24"
                    : "#c22026",
                color:
                  d && d < 120
                    ? "#2072b9"
                    : d && d > 120 && d < 130
                    ? "#16b250"
                    : d && d > 130 && d < 140
                    ? "#f5eb1a"
                    : d && d > 140 && d < 160
                    ? "#fdc60d"
                    : d && d > 160 && d < 180
                    ? "#ed1e24"
                    : "#c22026",

                line: {
                  color:
                    d && d < 120
                      ? "#2072b9"
                      : d && d > 120 && d < 130
                      ? "#16b250"
                      : d && d > 130 && d < 140
                      ? "#f5eb1a"
                      : d && d > 140 && d < 160
                      ? "#fdc60d"
                      : d && d > 160 && d < 180
                      ? "#ed1e24"
                      : "#c22026",
                },
                marker: {
                  fillColor:
                    d && d < 120
                      ? "#2072b9"
                      : d && d > 120 && d < 130
                      ? "#16b250"
                      : d && d > 130 && d < 140
                      ? "#f5eb1a"
                      : d && d > 140 && d < 160
                      ? "#fdc60d"
                      : d && d > 160 && d < 180
                      ? "#ed1e24"
                      : "#c22026",
                },
              };
            }),
          animation: {
            duration: 2000,
            defer: 40,
            // easing: "easeOutBounce",
          },

          // yAxis: 1,
          // type: "area",
          // tooltip: {
          //   valueDecimals: 2,
          // },

          // threshold: null,
          turboThreshold: 5000,
          lineColor: "#1445b7",
          lineWidth: "0.8",
          marker: {
            symbol: "dot",
            fillColor: "#2072b9",
            // data &&
            // systolicData &&
            // systolicData.length !== 0 &&
            // systolicData
            //   .map((d, index) =>
            //     d && d < 120
            //       ? "#2072b9"
            //       : d && d > 120 && d < 130
            //       ? "#16b250"
            //       : d && d > 130 && d < 140
            //       ? "#f5eb1a"
            //       : d && d > 140 && d < 160
            //       ? "#fdc60d"
            //       : d && d > 160 && d < 180
            //       ? "#ed1e24"
            //       : "#c22026"
            //   )
            //   .map((color) => color),

            //   animation: true,
            //   enabledThreshold:false
          },
          dataLabels: {
            enabled: true,
            color: "#1445b7",
            style: {
              textOutline: "none",
              strokeWidth: 0,
            },
            formatter: function () {
              let d = this.y;
              let color = "#c22026";
              if (d && d < 120) {
                color = "#2072b9";
              } else if (d && d > 120 && d < 130) {
                color = "#16b250";
              } else if (d && d > 130 && d < 140) {
                color = "#f5eb1a";
              } else if (d && d > 140 && d < 160) {
                color = "#fdc60d";
              } else if (d && d > 160 && d < 180) {
                color = "#ed1e24";
              }

              return `<span style="color:${color}" >${this.y}</span>`;
            },
          },

          fillOpacity: 0.5,
          // fillColor: {
          //   linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          //   stops: [
          //     [
          //       0,
          //       Highcharts.color(Highcharts.getOptions().colors[10])
          //         .setOpacity(0.2)
          //         .get(),
          //     ],
          //     [
          //       1,
          //       Highcharts.color(Highcharts.getOptions().colors[10])
          //         .setOpacity(0)
          //         .get(),
          //     ],
          //     // [
          //     //   1,
          //     //   Highcharts.color(Highcharts.getOptions().colors[10])
          //     //     .setOpacity(0)
          //     //     .get(),
          //     // ],
          //   ],
          // },

          // color: Highcharts.getOptions().colors[1],
        },

        {
          // type: "coloredline",
          name: "diastolic",
          // data: verifyObject(data, "diastolic[0]", []),
          data: verifyObject(data, "diastolic[0]", [])
            .slice(0, 1000)
            .map((d, i) => {
              return {
                y: d,
                segmentColor:
                  d && d < 60
                    ? "#2072b9"
                    : d && d > 60 && d < 80
                    ? "#16b250"
                    : d && d > 80 && d < 90
                    ? "#f5eb1a"
                    : d && d > 90 && d < 100
                    ? "#fdc60d"
                    : d && d > 100 && d < 120
                    ? "#ed1e24"
                    : "#c22026",
                color:
                  d && d < 60
                    ? "#2072b9"
                    : d && d > 60 && d < 80
                    ? "#16b250"
                    : d && d > 80 && d < 90
                    ? "#f5eb1a"
                    : d && d > 90 && d < 100
                    ? "#fdc60d"
                    : d && d > 100 && d < 120
                    ? "#ed1e24"
                    : "#c22026",

                marker: {
                  fillColor:
                    d && d < 60
                      ? "#2072b9"
                      : d && d > 60 && d < 80
                      ? "#16b250"
                      : d && d > 80 && d < 90
                      ? "#f5eb1a"
                      : d && d > 90 && d < 100
                      ? "#fdc60d"
                      : d && d > 100 && d < 120
                      ? "#ed1e24"
                      : "#c22026",
                },
              };
            }),
          dataLabels: {
            enabled: true,
            color: "#1445b7",
            style: {
              textOutline: "none",
              strokeWidth: 0,
            },
            formatter: function () {
              let d = this.y;
              let color = "#c22026";
              if (d && d < 60) {
                color = "#2072b9";
              } else if (d && d > 60 && d < 80) {
                color = "#16b250";
              } else if (d && d > 80 && d < 90) {
                color = "#f5eb1a";
              } else if (d && d > 90 && d < 100) {
                color = "#fdc60d";
              } else if (d && d > 100 && d < 120) {
                color = "#ed1e24";
              }

              return `<span style="color:${color}" >${this.y}</span>`;
            },
          },
          animation: {
            duration: 2000,
            defer: 40,
            // easing: "easeOutBounce",
          },
          fillOpacity: 0.5,

          // type: "area",
          // tooltip: {
          //   valueDecimals: 2,
          // },

          turboThreshold: 5000,
          lineColor: "#1445b7",
          lineWidth: "0.8",
          // marker: {
          //   symbol: "dot",
          //   fillColor: "#FFF",
          //   lineWidth: 1.4,
          //   lineColor: "#1445b7",
          //   //   animation: true,
          //   //   enabledThreshold:false
          // },
          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [
                0,
                Highcharts.color(Highcharts.getOptions().colors[10])
                  .setOpacity(0.2)
                  .get(),
              ],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[10])
                  .setOpacity(0.0)
                  .get(),
              ],
            ],
          },

          // color: Highcharts.getOptions().colors[1],
        },
      ],
    });
    const xAxis = chart.xAxis[0];
    if (xAxis.dataMax > 20) {
      xAxis.setExtremes(xAxis.dataMax - 20, xAxis.dataMax, true);
    }
    return chart;
  } else {
    // if (record.point_type !== "height" && record.point_type !== "weight") {
    let elemt = document.querySelector(".highcharts-scrollbar");
    if (elemt) {
      console.log("elemt", elemt);
      elemt.classList.add("scrolllllll");
    }
    // }
    const chart = new Highcharts.Chart(`${record.point_type}Graph`, {
      chart: {
        type: chartProps.type,
        scrollablePlotArea: {
          minwidth: 300,
        },
        animation: {
          duration: 2000,
        },
        zoomType: false,
        // events: {
        //   load: function () {
        //     this.xAxis[0].setExtremes(moment(data[1][0]).unix()*1000);
        //     // if(this.scroller)
        //     console.log("this", moment(data[1][0]).unix()*1000);
        //     //  this.scroller.scrollbarGroup.hide();
        //   },
        // },
        //   width: 800,
      },
      title: {
        text: "",
      },
      rangeSelector: {
        enabled: false,
        selected: 1,
      },
      navigator: {
        enabled: true,
        // record.point_type === "height" || record.point_type === "weight"
        //   ? true
        //   : false,
        adaptToUpdatedData: false,

        maskFill: "rgb(20, 69, 183,0.1)",
        maskInside: true,
        outlineWidth: 0,
        handles: {
          backgroundColor: "#d4eefd",
          // borderColor: "#d4eefd",
        },
        series: {
          type: "areaspline",
          fillOpacity: 1,
          lineWidth: 0,
        },
        xAxis: {
          gridLineWidth: 0,
          labels: {
            enabled: false,
          },
        },
        yAxis: {
          plotBands: [
            {
              color: "rgba(212, 238, 253, 0)",

              from: 0,
              to: 1,
            },
          ],
        },
      },

      legend: {
        enabled: false,
      },
      xAxis: {
        type: "datetime",
        categories: data[1],
        min: 0,
        max:
          data && data[1] && data[1].length >= 20
            ? 20
            : data[1]
            ? data[1].length - 1
            : 1,
        opposite: false,

        title: {
          text: "Date",
        },
        gridLineWidth: 1,
        gridLineColor: "#f7f7f7",
      },
      yAxis: [
        {
          opposite: false,
          title: {
            text: name,
          },
          // tickInterval: 20,
          gridLineColor: "",
        },
      ],
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            color: "#1445b7",
            style: {
              textOutline: "none",
              strokeWidth: 0,
            },
          },
        },
        area: {
          // dataLabels: {
          //   enabled: false,
          //   color: "#1445b7",
          // },
          animation: {
            duration: 2000,
          },
          enableMouseTracking: true,
          tooltip: {
            valueSuffix: unit === "null" ? "" : unit,
          },
          fillOpacity: 0.5,
          // fillColor: {
          //   linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
          //   stops: [
          //     [
          //       0,
          //       Highcharts.color(Highcharts.getOptions().colors[10])
          //         .setOpacity(0.5)
          //         .get(),
          //     ],
          //     [
          //       1,
          //       Highcharts.color(Highcharts.getOptions().colors[10])
          //         .setOpacity(0.1)
          //         .get(),
          //     ],
          //   ],
          // },
        },
      },
      credits: {
        enabled: false,
      },
      scrollbar: {
        enabled:
          data &&
          data[0] !== undefined &&
          isArray(data[0]) &&
          data[0].length > 21
            ? record.point_type === "height" || record.point_type === "weight"
              ? false
              : true
            : false,
        barBackgroundColor: "#1445b7",
        barBorderRadius: 4,
        barBorderWidth: 0,
        buttonBackgroundColor: "#d4eefd",
        buttonBorderWidth: 0,
        buttonArrowColor: "#d4eefd",
        buttonBorderRadius: 7,
        rifleColor: "#d4eefd",
        trackBackgroundColor: "#d4eefd",
        trackBorderWidth: 1,
        // trackBorderColor: "#d4eefd",
        trackBorderRadius: 7,
        height: 5,
        className: "rohan",

        useHtml: true,
      },
      series: [
        {
          name: name,
          data: data[0],
          animation: {
            duration: 2000,
            defer: 40,
            // easing: "easeOutBounce",
          },

          // type: "area",
          // tooltip: {
          //   valueDecimals: 2,
          // },

          threshold: null,
          lineColor: "#1445b7",
          lineWidth: "0.8",
          marker: {
            symbol: "dot",
            fillColor: "#FFF",
            lineWidth: 1.4,
            lineColor: "#1445b7",
            //   animation: true,
            //   enabledThreshold:false
          },

          fillColor: {
            linearGradient: {
              x1: 0,
              y1: 0,
              x2: 0,
              y2: 1,
            },
            stops: [
              [
                0,
                Highcharts.color(Highcharts.getOptions().colors[10])
                  .setOpacity(0.5)
                  .get(),
              ],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[10])
                  .setOpacity(0.1)
                  .get(),
              ],
            ],
          },

          // color: Highcharts.getOptions().colors[1],
        },
      ],
    });
    const xAxis = chart.xAxis[0];
    if (xAxis.dataMax > 20) {
      xAxis.setExtremes(xAxis.dataMax - 20, xAxis.dataMax, true);
    }
    return chart;
  }
}

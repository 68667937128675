/* eslint-disable react-hooks/exhaustive-deps */
import CommonLoader from "components/common/CommonLoader";
import React, {useEffect} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {bindActionCreators} from "redux";
// import { verifyCSToken, verifyPharmaSmartToken } from "services/session";
import {errorToast, getErrorObject} from "utilities/utils";
import {
  navigateToCustomerSelectionViaOTPF2F,
  navigateToCustomerSelectionViaOTP,
} from "reducers/session";
import {onSetPatient, onSetPatientAppointment} from "reducers/patients";
import {appRoutesConst, navigateTo} from "app/navigation";
import {verifyPharmaSmartToken} from "services/session";
import store from "app/store";

function CallFromPharmaSmart(props) {
  let cs_token = props?.match?.params?.cs_token;
  let query = new URLSearchParams(props.location.search);
  const patientQueryData = query.get("direct_f2f_patient");
  const patientData = JSON.parse(patientQueryData);
  useEffect(() => {
    console.log("patientData: ", patientData);
    _verifyPharmaSmartToken();
  }, []);

  const _verifyPharmaSmartToken = async () => {
    try {
      let response = await verifyPharmaSmartToken({
        pharma_token: cs_token,
      });

      console.log("response: ", response);
      if (response.data.message) {
        if (patientData) {
          store.dispatch({
            type: "CLEAR_LOCAL_STATE",
          });
          localStorage.clear();
          props.actions.navigateToCustomerSelectionViaOTPF2F(
            response,
            patientData
          );
          window.location.href = appRoutesConst.patientDetail;
        } else {
          store.dispatch({
            type: "CLEAR_LOCAL_STATE",
          });
          localStorage.clear();
          props.actions.navigateToCustomerSelectionViaOTP(response, null, true);
          window.location.href = "/patient/search";
        }
      }
    } catch (err) {
      let {message} = getErrorObject(err);
      errorToast({
        content: message,
      });
    }
  };
  return (
    <div className="rightmainsection videosetup_section">
      <div className="container-fluid right_wrapper">
        <div className="row m-0">
          <div className="col-md-1 col-xs-12 in-block"></div>
          <div className="col-md-10 col-xs-12 in-block selection_container">
            <CommonLoader text={"Logging in ..."} />
          </div>
          <div className="col-md-1 col-xs-12 in-block"></div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    user: state.localStore.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {
        navigateToCustomerSelectionViaOTPF2F,
        navigateToCustomerSelectionViaOTP,
      },
      dispatch
    ),
    onSetPatientAppointment: bindActionCreators(
      {onSetPatientAppointment},
      dispatch
    ),
    onSetPatient: bindActionCreators({onSetPatient}, dispatch),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(CallFromPharmaSmart);
export default withRouter(ConnectedComponent);

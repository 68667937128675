import React, { Component, Fragment } from "react";
import { Form, FormGroup } from "reactstrap";
import imgEyeClosed from "assets/images/login/security-uncheck.svg";
import imgEyeOpen from "assets/images/login/security-check.svg";
import { ValidateResetPassInput } from "./validateChangePassword";
import { getErrorObject, errorToast, successToast, verifyObject } from "../../utilities/utils";
import { ResetPasswordWithTokenApi } from "../../services/session";
import { withRouter } from "react-router-dom";
import { IndexLink } from "../../app/navigation";
// import ResetPasswordWithTokenPage from "../../pages/Login/ResetPasswordWithTokenPage";

class ResetPasswordWithToken extends Component {
  state = {
    passwordVisible: false,
    confirmpasswordVisible: false,
    oldpasswordVisible: false,
    existing_password: "",
    password: "",
    confirmpassword: "",
    submitting: false,
    errors: null,
  };

  handleChange = (e) => {
    let errors = null;
    let name = e.target.name;
    let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({ [e.target.name]: e.target.value, errors: errors }, () => {
      if (this.state[name] !== "") {
        let data = {
          [name]: value,
        };
        const errors = ValidateResetPassInput(data);
        if (!errors.isValid) {
          this.setState({ errors: errors.errors });
        }
      }
    });
  };
  togglePasswordVisiblity = () => {
    this.setState((prevState) => {
      return { passwordVisible: !prevState.passwordVisible };
    });
  };
  toggleConfirmPasswordVisiblity = () => {
    this.setState((prevState) => {
      return { confirmpasswordVisible: !prevState.confirmpasswordVisible };
    });
  };
  toggleOldPasswordVisiblity = () => {
    this.setState((prevState) => {
      return { oldpasswordVisible: !prevState.oldpasswordVisible };
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      password: this.state.password,
      confirmpassword: this.state.confirmpassword,
    };
    const errors = ValidateResetPassInput(data);

    if (!errors.isValid) {
      this.setState({ errors: errors.errors, submitting: false });
    } else {
      let reset_type = "mobile";
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("isEmail");
      if (myParam) {
        reset_type = "email";
      }

      let reqObj = {
        reset_password_token: this.props.resetToken,
        password: data.password,
        reset_with: reset_type,
      };
      try {
        await this.setState({ submitting: true });
        const response = await ResetPasswordWithTokenApi(reqObj)
        await this.setState({ submitting: false, isOpen: false })
				await successToast({ content: verifyObject(response,"data.message","Password changed successfully") })
        await this.props.history.push("/");
      } catch (error) {
        const { message } = getErrorObject(error);
        await this.setState({ submitting: false });
        errorToast({ content: message });
      }
    }
  };
  render() {
    const { confirmpassword, password, submitting } = this.state;
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup>
            <label className="font-12 weight-400 text-left push-5-b dis-block text-dark-gray">
              Set New Password
            </label>
            <div className="relative">
              <input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                type={this.state.passwordVisible ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                label="Password"
                onChange={this.handleChange}
                value={password}
              />
              <img
                src={this.state.passwordVisible ? imgEyeOpen : imgEyeClosed}
                alt=""
                className="cursor password_show"
                onClick={this.togglePasswordVisiblity}
              />
            </div>
            {this.state.errors && (
              <span className="validate-danger font-14">
                {this.state.errors.password}
              </span>
            )}
          </FormGroup>

          <FormGroup>
            <label className="font-12 weight-400 text-left push-5-b dis-block text-dark-gray">
              Re-enter Password
            </label>
            <div className="relative">
              <input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                type={this.state.confirmpasswordVisible ? "text" : "password"}
                name="confirmpassword"
                placeholder=" Re Enter Password"
                onChange={this.handleChange}
                label="Password"
                value={confirmpassword}
              />
              <img
                src={
                  this.state.confirmpasswordVisible ? imgEyeOpen : imgEyeClosed
                }
                alt=""
                className="cursor password_show"
                onClick={this.toggleConfirmPasswordVisiblity}
              />
            </div>
            {this.state.errors && (
              <span className="validate-danger font-14">
                {this.state.errors.confirmpassword}
              </span>
            )}
          </FormGroup>
          <FormGroup className="align-items-center push-10-t margin-bottom-0">
            <button
              type="submit"
              className="btn btn-custom btn-blue font-14 weight-400 height-big width-255px"
              //   disabled={submitting}
              onClick={this.handleSubmit}
            >
              Reset Password&nbsp;
              {submitting && <i className="fa fa-spinner fa-spin" />}
            </button>
          </FormGroup>
          <div className="push-20-t text-left">
            <IndexLink>
              <span className="font-12 weight-500 text-left text-blue hover-blue hide_outlinetext-blue ">
                Back to Login
              </span>
            </IndexLink>
          </div>
        </Form>
      </Fragment>
    );
  }
}

export default withRouter(ResetPasswordWithToken);

import validator from "validator";
import isEmpty from "lodash/isEmpty";

export function ValidateInput(data) {
  let errors = {};
  if (
    validator.isEmpty(data.snomedCodeText) &&
    data.snomedCodeId !== undefined &&
    validator.isEmpty(data.snomedCodeId)
  ) {
    errors.snomedCodeId = "Please select diseases name";
  }

  if (
    validator.isEmpty(data.snomedCodeId) &&
    data.snomedCodeText !== undefined &&
    validator.isEmpty(data.snomedCodeText)
  ) {
    errors.snomedCodeId = "Please select diseases name";
  }

  if (
    validator.isEmpty(data.snomedCodeId) &&
    data.snomedCodeText !== undefined &&
    !validator.isEmpty(data.snomedCodeText) &&
    /^(?=.*\S).+$/.test(data.snomedCodeText) === false
  ) {
    errors.snomedCodeId = "Please select diseases name";
  }

  if (
    !validator.isEmpty(data.snomedCodeText) &&
    !validator.isEmpty(data.snomedCodeId)
  ) {
    errors.snomedCodeId = "You cant select both";
  }
  if (isEmpty(data.taken_on)) {
    errors.taken_on = "Please select date";
  }
  if (validator.isEmpty(data.description)) {
    errors.description = "Please add the description";
  }
  if (
    !validator.isEmpty(data.description) &&
    /^(?=.*\S).+$/.test(data.description) === false
  ) {
    errors.description = "Please add the description";
  }

  return { errors, isValid: isEmpty(errors) };
}

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, resetFormState, forgotPassword } from "reducers/session";
import ForgotPassword from "containers/session/ForgotPassword";
import validator from "validator";
class ForgotPasswordContainer extends Component {
  componentDidMount() {
    this.props.actions.resetFormState();
  }
  onSubmit = (values) => {
    const username = values;
    let datas = {};
    if (validator.isEmail(username.email)) {
      datas = { email: username.email };
      return this.props.actions.forgotPassword(datas);
    } else {
      let phone = username.email;

      datas = { email: phone };
      return this.props.actions.forgotPassword(datas);
    }

    // if (isEmail) {
    //   // dispatch(navigateToIndex());
    // } else {
    //   let phone = data.email;
    //   let newphone = phone.replace("+91", "");
    //   newphone = "+91" + newphone;
    //   localStorage.setItem("otp-phone", JSON.stringify(newphone));
    //   dispatch(navigateToOTPScreen());
    // }
    // let phone = data.email;
    // let newphone = phone.replace("+91", "");
    // newphone = "+91" + newphone;
  };
  render() {
    return (
      <Fragment>
        <ForgotPassword {...this.props} onSubmit={this.onSubmit} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
    isLoading: state.session.isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { login, resetFormState, forgotPassword },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);

import Highcharts from "highcharts";

export default function createChart(mediaType) {
  return new Highcharts.Chart({
    chart: {
      type: "areaspline",
      renderTo: mediaType + "Graph",
    },
    title: {
      text: mediaType + " bitrate stability",
      style: {
        fontSize: "14px",
      },
    },
    subtitle: {
      text: "",
      style: {
        fontSize: "12px",
      },
    },
    xAxis: {
      title: {
        text: "Time elapsed (sec)",
        style: {
          fontSize: "8px",
        },
      },
      labels: {
        style: {
          fontSize: "8px",
        },
      },
    },
    yAxis: {
      title: {
        text: "Bitrate (KBps)",
        style: {
          fontSize: "8px",
        },
      },
      min: 0,
      minorGridLineWidth: 0,
      gridLineWidth: 0,
      alternateGridColor: null,
      plotBands: null,
      labels: {
        style: {
          fontSize: "8px",
        },
      },
    },
    tooltip: {
      headerFormat: "<b>{series.name}</b><br>",
      pointFormat: "{point.y:.2f} kBps",
    },
    plotOptions: {
      areaspline: {
        lineWidth: 2,
        states: {
          hover: {
            lineWidth: 3,
          },
        },
        marker: {
          enabled: false,
        },
        pointInterval: 1, // one hour
        color: "#1445b7",
        fillOpacity: 0.5,
        // fillColor: "red",
      },
    },
    series: [
      {
        name: "bitrate",
        data: [0],
        animation: false,
      },
    ],
    legend: false,
  });
}

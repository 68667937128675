import {get, post} from "./index";

export const getPatientQuestionnaireReview = (params) => {
  console.log("patient id params", params);
  // let bodyFromData = new FormData();
  // bodyFromData.set("patient_id", params.patient_id);
  const request = {
    subUrl: `/customer_support/patient_answers/list_submitted_questionnaires_with_details`,
    params,
  };
  return get(request);
};

export const dynamicQuestionnaireReviewApi = (data) => {
  const request = {
    subUrl: `/dynamic_questionnaire_reviews.json`,
    data,
  };
  return post(request);
};

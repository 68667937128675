import ModalPopUp from "components/common/ModalPopUp";
import React from "react";
import {Radio} from "antd";
const SendOtpPatient = (props) => {
  return (
    <ModalPopUp
      title="Send OTP"
      visible={props.showOtpModal}
      handleCancel={() => {
        props.setOtpModal(false);
        props.setContactType(null);
      }}
      handleOk={() => {
        props.handleRequestOtp(props.patientId);
      }}
      width={500}
      cancelBtnclassName="custom_cancel_btn"
      footer={true}
      // maskClosable={false}
      loading={props.requesting}
      disabled={props.requesting || props.contact_type === null}
      okText={"Send"}
      cancelText={"Cancel"}
      maskClosable={false}
    >
      {/* Request for Otp */}
      <div className="requestotpmain mb-2">
        <Radio.Group
          className="d-flex"
          value={props.contact_type}
          name="radiogroup"
          onChange={(e) => props.setContactType(e.target.value)}
        >
          {!props.missing_phone && <Radio value={"phone"}>via SMS</Radio>}
          {!props.missing_email && <Radio value={"email"}>via Email</Radio>}
        </Radio.Group>
      </div>
    </ModalPopUp>
  );
};
export default SendOtpPatient;

import React, {Fragment} from "react";
import "assets/css/pages/verify-otp.css";
import logo from "assets/images/common/main_logo.svg";
import {Link} from "react-router-dom";
import {appRoutesConst} from "app/navigation";
import ForgotPasswordContainer from "containers/session/ForgotPasswordContainer";
import {ErrorBoundary} from "app/ErrorBoundary";
const ForgotPasswordPage = () => {
  return (
    <Fragment>
      {/* <Header /> */}

      <Fragment>
        <div className="otp-wrapper">
          <div className="container-fluid">
            <div className="login-wrapper-logo">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <Link to="/" title="healthya.co.uk">
                    <img src={logo} alt="ADD logo" className="img-responsive" />
                  </Link>
                </div>

                <div className="col-md-8 col-lg-8 d-flex align-items-center justify-content-end">
                  <div className="in-block margin-right-30 ">
                    <span className="font-14 weight-400 text-blue">
                      Don't have an account?
                    </span>
                  </div>
                  <div className="in-block">
                    <Link
                      to={appRoutesConst.signup}
                      className="btn btn-custom btn-blue height-big weight-500 text-white width-220px line-1-imp"
                    >
                      Create Account
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="login-title-wrapper">
            <div className="container-fluid">
              <h1 className="login-title-h1">Reset Password</h1>
            </div>
          </div>
          <ErrorBoundary>
            <div className="container-fluid">
              <div className="otp-content-wrapper">
                <div className="body-content">
                  <ForgotPasswordContainer />
                </div>
              </div>
            </div>
          </ErrorBoundary>
        </div>
      </Fragment>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default ForgotPasswordPage;

import React, { Component, Fragment } from "react";
import debounce from "lodash/debounce";

type IProps = {
  onUpdate: () => {},
};

type IState = {
  text: string,
};

class DebouncedInput extends Component<IProps, IState> {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };

    this.onUpdate = debounce(this.props.onUpdate, 1000);
  }

  handleChange = (e) => {
    let value = e.target.value || "";

    // if (e.which === 32) {
    // return false;
    // }

    let regx = /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s-]+$/;
    if (regx.test(value) === false) {
      this.setState({ text: "" });
      return false;
    } else {
      this.setState({ text: value });
      value = value || null;
      if (value) {
        value = value.trim();
      }
      this.onUpdate(value);
    }
  };
  onClear = () => {
    this.onUpdate(null);
    this.setState({
      text: "",
    });
    this.input.focus();
  };

  render() {
    return (
      <Fragment>
        <input
          autoFocus
          ref={(node) => (this.input = node)}
          {...this.props.inputProps}
          onChange={this.handleChange}
          value={this.state.text}
        />
        {/* {this.props.render(this.state.text, this.onClear)} */}
      </Fragment>
    );
  }
}

export default DebouncedInput;

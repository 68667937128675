import React from "react";

import Layout from "../../Layouts/PageLayout";
import { appRoutesConst } from "../../app/navigation";
import StaffIntranetContainer from "../../containers/StaffIntranet/StaffIntranetContainer";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  onRequestSetComponent,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import { Prompt } from "react-router-dom";
import staff_intranet from "assets/images/sidebar/ic-staff-intranet.svg";

const sideBarArray = [
  {
    label: "Staff Intranet",
    path: appRoutesConst.staffInfranet,
    icon: <img alt="Staff Intranet" src={staff_intranet}></img>,
    key: 1,
  },
];
class StaffintranetPage extends React.Component {
  componentDidMount() {
    let { onSetSidebarKey } = this.props.dynamicTabAction;
    if (typeof onSetSidebarKey === "function") {
      onSetSidebarKey(["1"]);
    }
  }
  render() {
    return (
      <Layout title="Right To Work" sideBarArray={sideBarArray}>
        <Prompt
          message={(location, action) => {
            if (action === "PUSH") {
              let {
                onRequestSetComponent,
                onSetSidebarKey,
              } = this.props.dynamicTabAction;
              if (typeof onRequestSetComponent === "function") {
                onRequestSetComponent(null);
                onSetSidebarKey(null);
              }
              return true;
            }
            if (action === "POP") {
              let {
                onRequestSetComponent,
                onSetSidebarKey,
              } = this.props.dynamicTabAction;
              if (typeof onRequestSetComponent === "function") {
                onRequestSetComponent(null);
                onSetSidebarKey(null);
              }
              return true;
            }
            return true;
          }}
        />
        <div className="rightmainsection staffintra_section">
          <div className="container-fluid right_wrapper p-0">
            <div className="row m-0">
              <div className="col-md-12 selection_container padding-right-10 padding-left-10">
                <StaffIntranetContainer />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
const mapStateProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      { onRequestSetComponent, onSetSidebarKey },
      dispatch
    ),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(StaffintranetPage);
export default ConnectedComponent;

import React, {Component} from "react";
import mobileicon from "assets/images/common/mobile.svg";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {validateOTP} from "./validateChangePassword";
import {isNumeric} from "validator";
import {
  login,
  resetFormState,
  //   navigateToCustomerSelectionViaOTP,
  navigateToResetPassword,
  forgotPassword,
} from "reducers/session";
import {successToast, getErrorObject, errorToast} from "../../utilities/utils";
import {
  //   sendOTPApi,
  getOTPApi,
  verifyOTPApi,
  sendOTPApi,
} from "../../services/session";
import {get, isEmpty} from "lodash";
import {withRouter} from "react-router-dom";
class ResetOTPContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      verifying: false,
      code: "",
      errorMessage: "",
      errors: null,
      time: 30,
    };
    ["_startTimer"].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  componentDidMount() {
    if (process.env.NODE_ENV === "development") {
      // this.getOTP();
    }
    this.timerId = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.timerId);
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.time !== this.state.time) {
      if (this.state.time === 0) {
        clearInterval(this.timerId);
      }
    }
  }

  tick = () => {
    this.setState((prevState) => ({
      time: prevState.time - 1,
    }));

    if (this.state.time <= 0) {
      clearInterval(this.timerId);
    }
  };

  async _startTimer() {
    this.setState({time: 30}, () => {
      this.resendOTP();
    });
  }
  getOTP = async () => {
    try {
      let phone = JSON.parse(localStorage.getItem("otp-phone"));
      let response = await getOTPApi({
        phone: phone,
        role: this.props.session.role,
      });
      await this.setState({verifying: true}, async () => {
        // if (response.data.message) {
        successToast({content: response.data.message});
        this.setState({code: response.data.data}, () => {
          // localStorage.removeItem("otp-phone");
        });
        // }
      });
      //   }
    } catch (error) {
      const {message} = getErrorObject(error);
      errorToast({content: message});
      this.setState({verifying: false});
    }
  };
  verifyUser = async (e) => {
    e.preventDefault();
    let data = {
      code: this.state.code,
    };
    const errors = validateOTP(data);

    if (!errors.isValid) {
      this.setState({errors: errors.errors, submitting: false});
    } else {
      try {
        let phone = JSON.parse(localStorage.getItem("otp-phone"));
        let response = await verifyOTPApi({
          otp: this.state.code,
          phone: phone,
          role: this.props.session.role,
        });

        await this.setState({verifying: true}, async () => {
          if (response.data.message) {
            successToast({content: response.data.message});
            this.setState({verifying: false}, async () => {
              let reset_password_token =
                response.data.data && response.data.data.reset_password_token
                  ? response.data.data.reset_password_token
                  : null;
              await this.props.actions.navigateToResetPassword(
                reset_password_token
              );
            });
          }
        });
        //   }
      } catch (error) {
        const {message} = getErrorObject(error);
        errorToast({content: message});
        this.setState({verifying: false});
      }
    }
  };
  handleChange = (e) => {
    let errors = null;
    let name = e.target.name;
    let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    if (isNumeric(e.target.value)) {
      this.setState({[e.target.name]: e.target.value, errors: errors}, () => {
        if (this.state[name] !== "") {
          let data = {
            [name]: value,
          };
          const errors = validateOTP(data);
          if (!errors.isValid) {
            this.setState({errors: errors.errors});
          }
        }
      });
    } else {
      if (value === "") {
        this.setState({[name]: ""});
      } else {
        this.setState({[name]: this.state.code});
      }
    }
  };
  resendOTP = async () => {
    try {
      await this.setState({sending: true});
      this.timerId = this.timerId = setInterval(this.tick, 1000);

      let phone = JSON.parse(localStorage.getItem("otp-phone"));
      if (phone) {
        this.props.actions.forgotPassword({email: phone, role: "doctor"}, true); //passing true for not redirect, just call API
        this.setState({sending: true});
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      errorToast({content: message});
      this.setState({sending: false});
    } finally {
      // clearInterval is called in the lifecycle method componentWillUnmount
    }
  };

  render() {
    console.log("MB TIME----", this.state.time);
    let phone = JSON.parse(localStorage.getItem("otp-phone"));
    return (
      <div className="container-fluid">
        <div className="otp-content-wrapper">
          <div className="body-content">
            <div className="body-verify-content">
              <div className="body-verify-img">
                <img alt="" src={mobileicon} />
              </div>
              <div className="body-verify-text">
                <p> You're almost done</p>
                <p> Secure Your Account with a password</p>
                {/* <p> Secure Your Account with a password</p> */}

                <p>{phone && <b>{phone}</b>}</p>
              </div>
            </div>
            <form onSubmit={this.verifyUser}>
              <div className="otp-form">
                <div className="otp-text-input-form">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-dark-gray"
                  >
                    Enter Verification Code
                  </label>
                  <input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="code"
                    placeholder="Enter Code"
                    // style={{
                    //   width: "50%",
                    //   marginLeft: "23%",
                    // }}
                    style={{
                      marginBottom: 2,
                    }}
                    value={this.state.code}
                    onChange={this.handleChange}
                    autoFocus
                  />
                  {this.state.errors && (
                    <span className="text-danger">
                      {this.state.errors.code}
                    </span>
                  )}

                  <p className="rcvd_code">
                    Did'nt received code yet?{" "}
                    {this.state.time === 0 ? (
                      <a onClick={this._startTimer}>
                        <b> Resend Code</b>
                      </a>
                    ) : (
                      <b>
                        Resend code in{" "}
                        {this.state.time < 10
                          ? `00:0${this.state.time}`
                          : `00:${this.state.time}`}
                      </b>
                    )}
                  </p>
                </div>

                <button
                  disabled={isEmpty(this.state.code)}
                  onClick={this.verifyUser}
                  type="submit"
                  className="btn weight-500 text-white btn-custom btn-blue height-big width-255px"
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    session: state.session,
    user: state.localStore.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {login, resetFormState, navigateToResetPassword, forgotPassword},
      dispatch
    ),
  };
};
const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetOTPContainer);
export default withRouter(ConnectedComponent);

import React, {Component, Fragment} from "react";
import {reduxForm} from "redux-form";
// import { renderTextField } from "components/form/renderField";
import {Form, FormGroup} from "reactstrap";
import validator from "validator";
// import imgEyeClosed from "assets/images/login/security-uncheck.svg";
// import imgEyeOpen from "assets/images/login/security-check.svg";
import {IndexLink} from "app/navigation";
const validate = (values) => {
  const errors = {};
  if (!values.username) {
    errors.username = "Required";
  }

  return errors;
};

class ForgotPassword extends Component {
  state = {
    passwordVisible: false,
    email: "",
    submitting: false,
  };

  //   togglePasswordVisiblity = () => {
  //     this.setState(prevState => {
  //       return { passwordVisible: !prevState.passwordVisible };
  //     });
  //   };
  handleSubmit = (e) => {
    e.preventDefault();
    let datas = {};
    const username = {email: this.state.email};
    if (this.state.email === "") {
      this.setState({
        error:
          "Please enter valid email or mobile number to reset the password",
      });
    } else if (
      !validator.isMobilePhone(this.state.email) &&
      !validator.isEmail(this.state.email)
    ) {
      //   let { email, phone } = this.state;

      if (!validator.isEmail(this.state.email)) {
        if (
          this.state.email !== undefined &&
          !validator.isMobilePhone(this.state.email)
        ) {
          this.setState({
            error: "Please enter a valid phone.",
          });
        } else {
          this.setState({
            error: "Please enter a valid email.",
          });
        }
      }
      if (
        this.state.email !== undefined &&
        !validator.isMobilePhone(this.state.email)
      ) {
        this.setState({
          error: "Please enter a valid phone or email",
        });
      }
    } else {
      this.setState({
        error: "",
      });
      if (validator.isEmail(username.email)) {
        let {email} = this.state;
        if (email.length > 60) {
          this.setState({
            error: "Email should be less than  of 60 characters only",
          });
        } else {
          datas = {email: username.email};
          return this.props.actions.forgotPassword(datas);
        }
      } else {
        let phone = username.email;
        datas = {email: phone};
        if (phone !== undefined && validator.isEmpty(phone) === false) {
          if (!validator.isLength(phone, {min: 13, max: 13})) {
            this.setState({
              error: "Mobile no should be of 13 digit",
            });
          } else if (phone.includes("+") === false) {
            this.setState({
              error: "Enter country code starting with  +",
            });
          } else {
            return this.props.actions.forgotPassword(datas);
          }
        } else {
          return this.props.actions.forgotPassword(datas);
        }
      }
    }
  };
  render() {
    return (
      <Fragment>
        <Form onSubmit={this.handleSubmit}>
          <FormGroup className="push-30-b">
            <label className="font-12 weight-400 text-left push-5-b dis-block text-dark-gray">
              Email / Phone number
            </label>
            <input
              className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
              type="text"
              name="email"
              onChange={(e) => {
                this.setState({[e.target.name]: e.target.value});
              }}
              placeholder="Enter Email or Phone number"
              label="email"
              autoFocus
            />
            {this.state.error && (
              <span className="validate-danger">{this.state.error}</span>
            )}
          </FormGroup>
          <FormGroup className="align-items-center push-10-t margin-bottom-0">
            <button
              type="submit"
              className="btn btn-custom btn-blue height-big weight-500 text-white forget_btn width-255px"
              disabled={this.props.isLoading || this.state.email.trim() === ""}
            >
              Reset Password &nbsp;
              {this.props.isLoading && <i className="fa fa-spinner fa-spin" />}
            </button>

            {/* <a
            className="font-14 weight-500 push-20-l text-left text-blue hover-blue hide_outline"
            href="/"
          > */}
            <div className="push-20-t text-left">
              <IndexLink>
                <span className="font-12 weight-500 text-left text-blue hover-blue hide_outlinetext-blue ">
                  Back to Login
                </span>
              </IndexLink>
            </div>
          </FormGroup>

          {/* </a> */}
        </Form>
      </Fragment>
    );
  }
}

export default reduxForm({
  form: "forgot",
  validate,
})(ForgotPassword);

import {listingReducer} from "reducers/utils/listingReducer";
import {getAbbrivations} from "services/drugs";

const {reducer, actions, types} = listingReducer({
  name: `prescriptionAbbreviation`,
  getApi: getAbbrivations,
});

const prescriptionAbbreviation = reducer;
export {prescriptionAbbreviation, actions, types};

import React, {Component, Fragment} from "react";
import ConnectionStatus from "./ConnectionStatus";
import store from "../../app/store";
// import header_logo from "assets/images/header/logo-circle.png";
import bg1 from "assets/images/common/bg1.jpg";
import bg2 from "assets/images/common/bg2.jpg";
import bg3 from "assets/images/common/bg3.jpg";
import bg4 from "assets/images/common/bg4.jpeg";

import {
  Input,
  Upload,
  Drawer,
  message as AntdMessage,
  Modal,
  Space,
  Button,
  Progress,
  Switch,
  Radio,
} from "antd";
import isURL from "validator/lib/isURL";
import {getBaseUrl} from "../../services";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";
import {
  uploadScreenShots,
  addChatVideoHistoryAPI,
  startEndOfflineCall,
} from "../../services/CallingServices";
import {isEmpty, uniqueId} from "lodash";
import Scrollbars from "react-perfect-scrollbar";
import Linkify from "react-linkify";
import {ResizableBox} from "react-resizable";
import "react-resizable/css/styles.css";
import "./vlayout.css";
import {Rnd} from "react-rnd";
import confirmation_video from "assets/images/common/confirmation_video.svg";
import {getOnlyChatHistory} from "services/consultations";
import {APPT_TYPE_AUDIO, APPT_TYPE_VIDEO, spaceRegex} from "constants/common";
import AudioCallScreen from "./LiveCall/AudioCallScreen";
import DoctorVideoControls from "./LiveCall/DoctorVideoControls";
import UserItem from "./UserItem";
import AudioTestFile from "../../assets/sounds/auditest.wav";
import { IconVideoResize } from "assets/icon/IconVideoResize";

const confirmModal = Modal;
const confirmModalAskReview = Modal;

const {Search} = Input;
const OT = window.OT;
const style = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "solid 1px #ddd",
  // background: "#f0f0f0",
  zIndex: 111,
};
const style2 = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: "solid 1px #ddd",
  // background: "#f0f0f0",
  zIndex: 111,
  marginTop: "10px",
};
const allowedFiletypes = "application/pdf,image/jpeg,image/jpg,image/png";
// const allowedFiletypes =
//   "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/pdf,application/rtf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/x-rar-compressed,application/vnd.ms-powerpoint,image/jpeg,image/jpg,image/png";

// import config from "../config";

let publisher;
let publisher2;

class VideoAppInit extends Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      connected: false,
      connecting: true,
      text: "",
      chatArray: [],
      screenShotImage: "",
      duration: this.props.durationParams,
      screenShotTakenCount: this.props.screenShotTakenCount,
      isTalking: false,
      resizeStarted: null,
      audio: true,
      video: true,
      sessionObj: null,
      subscriberObj: null,
      publisherObj: null,
      showPublisher: true,
      volume: 50,
      intialTime: 0,
      isExtended: false,
      isStreamCreated: false,
      isFullScreenActive: false,
      unreadCount: 0,
      showChat: false,
      pubVideoAxis: {
        x: 0,
        y: 0,
      },
      uploading: false,
      isStreamStarted: false,
      screenShareActive: false,
      streamIds: [],
      showAudio: true,
      showFile: true,
      activeStreamsArray: [],
      isDailougeClose: false,
      streamConnecting: true,
      x: 0,
      y: 0,
      isOpen: false,
      isChecked:
        this.props.callSettings && this.props.callSettings.isChecked
          ? this.props.callSettings.isChecked
          : false,

      audioDeviceId:
        this.props.callSettings && this.props.callSettings.audioDeviceId
          ? this.props.callSettings.audioDeviceId
          : "default",
      videoDeviceId:
        this.props.callSettings && this.props.callSettings.videoDeviceId
          ? this.props.callSettings.videoDeviceId
          : "default",
      blurType:
        this.props.callSettings && this.props.callSettings.blurType
          ? this.props.callSettings.blurType
          : "low",
      changeBackground:
        this.props.callSettings && this.props.callSettings.changeBackground
          ? this.props.callSettings.changeBackground
          : false,
      bgImage:
        this.props.callSettings && this.props.callSettings.bgImage
          ? this.props.callSettings.bgImage
          : bg1,
      audioFileState: new Audio(AudioTestFile),
      playing: false,
      audioOutputDevice: [],
      currentAudioOutputDevice:
        this.props.callSettings &&
        this.props.callSettings.currentAudioOutputDevice
          ? this.props.callSettings.currentAudioOutputDevice
          : false,
      isWebMediaProcessorSupport:
        this.props.callSettings &&
        this.props.callSettings.isWebMediaProcessorSupport
          ? this.props.callSettings.isWebMediaProcessorSupport
          : false,
      speakerSupport:
        this.props.callSettings && this.props.callSettings.speakerSupport
          ? this.props.callSettings.speakerSupport
          : false,
    };
    // this.otSubscriber = React.createRef();
    // this.otSession = React.createRef();
  }
  toggleAudioPlaying = () => {
    let {audioFileState} = this.state;
    this.setState({playing: !this.state.playing}, () => {
      if (this.state.playing) {
        audioFileState.play();
      } else {
        audioFileState.pause();
      }
    });
  };

  componentDidMount() {
    this._onRequestList();
    this.setState({screenShotTakenCount: this.props.screenShotTakenCount});
    // let { callCounterData } = this.props;
    this.state.audioFileState.addEventListener("ended", () =>
      this.setState({playing: false})
    );
    this.setState({
      duration: this.props.durationParams,
      intialTime: this.props.remainingTime,
    });
    this.initializeSession();
  }
  async _onRequestList() {
    let {appointmentId} = this.props;
    try {
      await this.setState({isLoadingChat: true});
      let response = await getOnlyChatHistory({
        id: appointmentId,
      });
      this.setState({
        chatArray: verifyObject(response, "data.data", []),
      });
      await this.setState({isLoadingChat: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }
  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps", nextProps.remainingTime);

    this.setState(
      {
        duration: nextProps.durationParams,
        intialTime: nextProps.remainingTime,
      },
      () => {}
    );
  }

  handleError = (error) => {
    console.log("ERROR VANILLA JS", error);
    if (error && error.name) {
      switch (error.name) {
        case "OT_NOT_CONNECTED":
          errorToast({
            content:
              "Publishing your video failed. You are not connected to the internet or your bandwidth is low.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_DISCONNECTED":
          errorToast({
            content:
              "Publishing your video failed. You are not connected to the internet or your bandwidth is low.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_STREAM_DESTROYED":
          errorToast({
            content:
              "Publishing your video failed. The stream was destroyed before it could be subscribed to patient",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;

        case "OT_CREATE_PEER_CONNECTION_FAILED":
          errorToast({
            content:
              "Publishing your video failed. This could be due to a restrictive firewall.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_CHROME_MICROPHONE_ACQUISITION_ERROR":
          errorToast({
            content:
              "Failed to acquire microphone. This is a known Chrome Bug. Please completely quit and restart you browser.",
          });

          break;
        case "OT_TIMEOUT":
          errorToast({
            content:
              "Failed to acquire microphone. This is a known Chrome Bug. Please completely quit and restart you browser.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        default:
          errorToast({
            content:
              "An unknown error occurred while trying to publish your video. Please try again later.",
          });
      }
    }
  };
  async initializeSession(deviceChangeId) {
    let {
      socketData: {
        data: {session_id, token},
      },
    } = this.props;
    let API_KEY = "45833712";
    if (process.env.REACT_APP_ENV === "prod") {
      API_KEY = "46193392";
    }
    if (process.env.REACT_APP_ENV === "stage") {
      API_KEY = "45833712";
    }

    // OT.setLogLevel(OT.DEBUG);
    // OT.log("my session ID: " + session_id);

    OT.reportIssue(function (error, issueId) {
      console.log("In REPORT ISSUE....");
      if (error) {
        console.log(error);
      } else {
        console.log(issueId);
        // let payload = {
        //   error_generator: "tokbox",
        //   platform: "web",
        //   error_log: issueId,
        // };
        // reportIssueAPI(payload)
        //   .then((response) => {
        //     console.log("Reported an issue...", response);
        //   })
        //   .catch((error) => {
        //     console.log("Error while reporting an issue...", error);
        //   });
        // You may want to use XMLHttpRequest to report this issue ID to a server
        // that can store it in a database for later reference.
      }
    });

    var layoutContainer = document.getElementById("layoutContainerV");

    // Initialize the layout container and get a reference to the layout method
    const options = {
      // The widest ratio that will be used (default 16x9)
      maxRatio: 3 / 2, // The narrowest ratio that will be used (default 2x3)
      minRatio: 9 / 16, // The widest ratio that will be used (default 16x9)
      fixedRatio: false, // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
      alignItems: "center", // Can be 'start', 'center' or 'end'. Determines where to place items when on a row or column that is not full
      bigClass: "OT_big", // The class to add to elements that should be sized bigger
      bigPercentage: 0.8, // The maximum percentage of  space the big ones should take up
      bigFixedRatio: false, // fixedRatio for the big ones
      bigAlignItems: "center", // How to align the big items
      smallAlignItems: "center", // How to align the small row or column of items if there is a big one
      maxWidth: Infinity, // The maximum width of the elements
      maxHeight: Infinity, // The maximum height of the elements
      smallMaxWidth: Infinity, // The maximum width of the small elements
      smallMaxHeight: Infinity, // The maximum height of the small elements
      bigMaxWidth: Infinity, // The maximum width of the big elements
      bigMaxHeight: Infinity, // The maximum height of the big elements
      bigMaxRatio: 3 / 2, // The narrowest ratio to use for the big elements (default 2x3)
      bigMinRatio: 9 / 16, // The widest ratio to use for the big elements (default 16x9)
      bigFirst: true, // Whether to place the big one in the top left (true) or bottom right (false).
      // You can also pass 'column' or 'row' to change whether big is first when you are in a row (bottom) or a column (right) layout
      animate: true, // Whether you want to animate the transitions using jQuery (not recommended, use CSS transitions instead)
      window: window, // Lets you pass in your own window object which should be the same window that the element is in
      ignoreClass: "OT_ignore", // If this is true then the aspect ratio of the video is maintained and minRatio and maxRatio are ignored (default false)
    };
    let initLayoutContainer = window.initLayoutContainer;
    var layout = initLayoutContainer(layoutContainer, options).layout;
    this.setState({layout: layout});
    var session = OT.initSession(API_KEY, session_id);
    // OT.des
    session.on("connectionCreated", (event) => {
      console.log("Connection Created", event);
    });

    session.on("streamCreated", (event) => {
      console.log("streamCreated", event);
      // alert("Both Party joined")
      this.setState({isStreamStarted: true}, () => {
        store.dispatch({
          type: "ON_STREAM_CONNECTED",
          payload: true,
        });
      });
      if (
        event.stream.connection.connectionId === session.connection.connectionId
      ) {
        return;
      }
      if (this.state.subscriberObj) {
        // session.unsubscribe(this.state.subscriberObj);
      }
      let alreadySubscribed = false;
      //before reconnecting check whether there's already a subscriber with same connection Id
      var subscribers = session.getSubscribersForStream(event.stream);
      console.log("subscribers", alreadySubscribed);

      for (let subscriber of subscribers) {
        console.log("subscriber==>", subscriber);
        if (
          subscriber.stream.connection.connectionId ===
          event.stream.connection.connectionId
        ) {
          alreadySubscribed = true;
        }
        AntdMessage.info(`${event.stream.name} has joined the call.`);
      }
      this.setState(
        {
          streamIds: this.state.streamIds.concat(event.stream.streamId),
          activeStreamsArray: this.state.activeStreamsArray.concat({
            streamId: event.stream.streamId,
            connectionId: event.stream.connection.id,
            connection: event.stream.connection,
            name: event.stream.name,
          }),
        },
        () => {
          var subscriberelement = document.createElement("div");
          subscriberelement.setAttribute("id", event.stream.streamId);
          document
            .getElementById("layoutContainerV")
            .appendChild(subscriberelement);
          let subscriberObj = session.subscribe(
            event.stream,
            subscriberelement,
            {
              insertMode: "append",
              width: "100%",
              height: "100%",
              subscribeToAudio: true,
              // name: name[0],
              subscribeToVideo:
                appointmentType === APPT_TYPE_AUDIO ? false : true,
              style: {
                audioLevelDisplayMode: "off",
                audioBlockedDisplayMode: "off",
                nameDisplayMode: "on",
                videoDisabledDisplayMode: "off",
                buttonDisplayMode: "off",
              },
            },
            (error) => {
              if (error) {
                console.log("an error");
              } else {
                layout();
                console.log("we're subscribed");
                var resizeTimeout;
                window.onresize = function () {
                  clearTimeout(resizeTimeout);
                  resizeTimeout = setTimeout(function () {
                    console.log("Hello Resize");
                    layout();
                  }, 20);
                };
              }
            }
          );

          subscriberelement.addEventListener("dblclick", function () {
            if (subscriberelement.classList.contains("OT_big")) {
              subscriberelement.classList.remove("OT_big");
            } else {
              subscriberelement.classList.add("OT_big");
            }
            layout();
          });

          this.setState({subscriberObj});
          subscriberObj.on("audioLevelUpdated", (event) => {
            let movingAvg = null;
            if (movingAvg === null || movingAvg <= event.audioLevel) {
              movingAvg = event.audioLevel;
            } else {
              movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
            }
            // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
            var logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
            logLevel = Math.min(Math.max(logLevel, 0), 1);
            this.setState({audiLevel: logLevel * 100}, () => {
              // console.log("audiLevel", this.state.audiLevel);
              if (this.state.audiLevel > 0) {
                // setIsTalking(true);
                this.setState({isTalking: true});
              } else {
                // setIsTalking(false);
                this.setState({isTalking: false});
              }
            });
            var activity = null;
            var now = Date.now();
            if (event.audioLevel > 0.2) {
              if (!activity) {
                activity = {timestamp: now, talking: false};
              } else if (activity.talking) {
                activity.timestamp = now;
              } else if (now - activity.timestamp > 1000) {
                activity.talking = true;
                this.setState({isTalking: true});
              }
            } else if (activity && now - activity.timestamp > 3000) {
              if (activity.talking) {
                this.setState({isTalking: false});
              }
              activity = null;
            }
          });
        }
      );
    });

    session.on("sessionDisconnected", (e) => {
      if (this.state.chatArray.length !== 0) {
        this.submitChatHistory();
      }
    });

    session.on("sessionConnected", (e) => {
      layout();
      this.setState({connected: true, connecting: false});
    });

    session.on("connectionDestroyed", (e) => {
      console.log("connection destoryed", e);
      this.setState({
        activeStreamsArray: this.state.activeStreamsArray.filter(
          (d) => d.connectionId !== e.connection.id
        ),
      });
      layout();
      if (this.props.isCallEndedFromPatient) {
        console.log("Call ended from patient");
        this.callEndFromPatient();
      } else {
        // this.reconnectCall();
      }
      // let { onCallEnd, callStartEnd } = this.props;
      // this.setState({ connected: false, connecting: false }, () => {});
      // onCallEnd();
      // callStartEnd("finish");

      // store.dispatch({
      //   type: "IS_VIDEO_CALL_STARTED",
      //   payload: false,
      // });

      // store.dispatch({
      //   type: "SET_OFFLINE_CALL_STATUS",
      //   payload: "finish",
      // });

      // store.dispatch({
      //   type: "ACTUAL_CALL_STARTED",
      //   payload: false,
      // });

      // store.dispatch({
      //   type: "SET_INTIAL_SOCKET_DATA",
      //   payload: null,
      // });
      // store.dispatch({
      //   type: "SET_APPOINTMENT_LOCATION_DATA",
      //   payload: null,
      // });
      // if (this.state.chatArray.length !== 0) {
      //   this.submitChatHistory();
      // }
      // session.disconnect();
    });

    session.on("signal:chat", (event) => {
      let {userObj, patientName, appointmentId} = this.props;

      let msg_content;
      if (!event.data.text) {
        event.data = JSON.parse(event.data);
      }
      if (session.connection.connectionId === event.from.id) {
        msg_content = {
          msg_data: event.data.text,
          msg_send: "right",
          user_name: userObj.first_name + userObj.last_name,
          date_time: new Date(),
          user_type: "doctor",
          msg_type: event.data.type,
          appointment_id: appointmentId,
        };
        this.scrollToBottom("right", event.data.text, event.data.type);
      } else {
        var audio = new Audio(require("../../assets/sounds/new_message.mp3"));

        audio.play();
        msg_content = {
          msg_data: event.data.text,
          msg_send: "left",
          user_name: patientName,
          date_time: new Date(),
          user_type: "patient",
          msg_type: event.data.type,
          appointment_id: appointmentId,
        };
        this.scrollToBottom("left", event.data.text, event.data.type);
      }
      if (msg_content) {
        if (msg_content.msg_type === "image") {
          var testString = msg_content.msg_data;
          var result = testString.search(".pdf");
          if (result !== "-1") {
            msg_content = {
              ...msg_content,
              is_image: false,
              dummy_url:
                "https://d3valvanfp3ti4.cloudfront.net/app/images/file_upload.svg",
            };
          } else {
            msg_content = {
              ...msg_content,
              is_image: true,
            };
          }
        }
        if (!this.state.showChat) {
          this.setState({unreadCount: this.state.unreadCount + 1});
        }
        this.setState(
          {
            chatArray: this.state.chatArray.concat(msg_content),
          },
          () => {
            // localStorage.setItem()
            store.dispatch({
              type: "SET_V_CALL_CHAT_HISTORY",
              payload: this.state.chatArray,
            });
          }
        );
      }
    });
    session.on("signal:other", (event) => {
      // console.log("EVENT", event);
      // console.log("this._scrollRef", this._scrollRef);
      if (!event.data.type) {
        event.data = JSON.parse(event.data);
      }
      // let doctor_call_obj = {};
      if (session.connection.connectionId === event.from.id) {
      } else {
        if (event.data.type === "appointment_duration") {
          // if (event.data.time === "start_call") {
          //   this.setState({ duration: event.data.data });
          // }

          if (event.data.time === "extend" && this._scrollRef !== null) {
            // this.setState({
            //   duration: this.state.duration + event.data.data,
            // });
            // console.log("Event Data Data", event.data.data);
            let newDuration = event.data.data * 60;

            this.setState((prevState) => {
              return {
                isExtended: true,
                duration: prevState.duration + newDuration,
              };
            });
          }
        } else if (event.data.type === "screenshot") {
          if (
            event.data.type === "screenshot" &&
            event.data.data === "accepted"
          ) {
            // if (this._scrollRef) {
            this.submitScreenShot(event.data);
            // }
          } else {
            if (event.data.data === "accepted") {
              successToast({
                content: "User accepted the request",
              });
            } else {
              if (this._scrollRef !== null) {
                errorToast({
                  content: "User declined the request to take screenshot",
                });
              }
            }
          }
        }
      }
    });

    session.on("streamDestroyed", (event) => {
      console.log("Stream Destroyed", event);
      console.log("this.props.reconnectCall", this.props.reconnectCall);

      if (this.props.reconnectCall) {
        this.reconnectCall();
      }
      // if(event.streams && isArray())
      let element = document.getElementById(event.stream.streamId);
      if (element) {
        let parent = document.getElementById("layoutContainerV");
        parent.removeChild(element);
        layout();
      }

      if (
        event?.streams?.connection?.connectionId &&
        event?.streams?.connection.connectionId ===
          session?.connection?.connectionId
      ) {
        event.preventDefault(); // Don't remove the Publisher from the DOM.
      }

      session.getSubscribersForStream(event.stream).forEach((subscriber) => {
        console.log("Destroyed", subscriber);
        subscriber.element.classList.remove("ot-layout");
        setTimeout(() => {
          subscriber.destroy();
          layout();
        }, 200);
      });
      var Streamarray = [...this.state.streamIds]; // make a separate copy of the array
      // var activeStreamsArr = [...this.state.activeStreamsArray];
      console.log("Streamarray", Streamarray);
      var index = Streamarray.indexOf(event.stream.streamId);
      // var index1 = activeStreamsArr.indexOf(event.stream.streamId);

      if (index !== -1) {
        Streamarray.splice(index, 1);
        // activeStreamsArr.splice(index, 1);
        this.setState(
          {
            streamIds: Streamarray,
          },
          () => {
            console.log("Stream ids", this.state.streamIds);
            if (
              event.reason === "clientDisconnected" ||
              event.reason === "networkDisconnected"
            ) {
              this.reconnectCall();
            }

            if (this.state.streamIds.length === 0) {
              console.log("ALL Streams Gone", this.state.streamIds);
              session.destroy();
              session.disconnect();
            }
          }
        );
      }
    });
    let {appointmentType, userObj} = this.props;
    let {audio} = this.state;
    // let videoFilter =
    //   appointmentType === APPT_TYPE_AUDIO
    //     ? false
    //     : this.state.isChecked
    //     ? {
    //         type: "backgroundBlur",
    //         blurStrength: this.state.blurType,
    //       }
    //     : this.state.changeBackground
    //     ? {
    //         type: "backgroundReplacement",
    //         backgroundImgUrl: this.state.bgImage,
    //       }
    //     : false;
    console.log("has media access====>", OT.hasMediaProcessorSupport());
    this.setState({
      isWebMediaProcessorSupport: await OT.hasMediaProcessorSupport(),
    });

    publisher = OT.initPublisher(
      "publisher",
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        publishAudio: audio,
        id: "priory_vid_publisher",
        publishVideo:
          appointmentType === APPT_TYPE_AUDIO ? false : this.state.video,
        videoSource:
          appointmentType === APPT_TYPE_AUDIO
            ? false
            : this.state.videoDeviceId && appointmentType !== APPT_TYPE_AUDIO
            ? this.state.videoDeviceId
            : this.state.videoSource === "screen" &&
              appointmentType !== APPT_TYPE_AUDIO
            ? "screen"
            : undefined,
        audioSource: this.state.audioDeviceId
          ? this.state.audioDeviceId
          : "default",
        showControls: true,
        resolution: "1280x720",
        name:
          userObj && userObj.first_name
            ? `${userObj.first_name} ${userObj.last_name}`
            : "",
        style: {
          audioLevelDisplayMode: "off",
          buttonDisplayMode: "off",
          nameDisplayMode: "on",
          videoDisabledDisplayMode: "on",
          showArchiveStatus: false,
        },
        // ...videoFilter,
        // videoFilter:
        //   appointmentType === APPT_TYPE_AUDIO
        //     ? false
        //     : this.state.isChecked &&
        //       this.state.isWebMediaProcessorSupport &&
        //       OT.hasMediaProcessorSupport()
        //     ? {
        //         type: "backgroundBlur",
        //         blurStrength: this.state.blurType,
        //       }
        //     : this.state.changeBackground &&
        //       this.state.isWebMediaProcessorSupport &&
        //       OT.hasMediaProcessorSupport()
        //     ? {
        //         type: "backgroundReplacement",
        //         backgroundImgUrl: this.state.bgImage,
        //       }
        //     : false,
      },
      this.handlePublisherError
    );
    // if (this.state.isChecked) {
    //   publisher.applyVideoFilter({
    //     type: "backgroundBlur",
    //     blurStrength: this.state.blurType,
    //   });
    // }
    // if (this.state.changeBackground) {
    //   publisher.applyVideoFilter({
    //     type: "backgroundReplacement",
    //     backgroundImgUrl: this.state.bgImage,
    //   });
    // }
    // publisher
    //   .setVideoSource(this.state.videoDeviceId)
    //   .then(() => console.log("video source set"))
    //   .catch((error) => console.error(error.name));

    //for speaker start
    const audioOutputs = await OT.getAudioOutputDevices();
    console.log("out put deviceee==>", audioOutputs);
    if (audioOutputs.length === 0) {
      this.setState({speakerSupport: false});
    } else {
      this.setState({speakerSupport: true});
    }
    const currentOutputDevice = await OT.getActiveAudioOutputDevice();
    console.log("out put deviceee==>", currentOutputDevice);
    if (audioOutputs.length !== 0) {
      this.setState({audioOutputDevice: audioOutputs}, () => {});
    }
    if (currentOutputDevice.deviceId) {
      this.setState({currentAudioOutputDevice: currentOutputDevice.deviceId});
    }

    //for speaker end

    publisher.on({
      accessAllowed: (event) => {
        this.setState({error: false});
        var audioInputDevices;
        var videoInputDevices;
        OT.getDevices((error, devices) => {
          console.log("geted devicesss==>", devices);
          audioInputDevices = devices.filter(function (element) {
            return element.kind === "audioInput";
          });
          videoInputDevices = devices.filter((element) => {
            return element.kind === "videoInput";
          });

          this.setState({
            audioInputDevices: audioInputDevices,
            videoInputDevices: videoInputDevices,
            audioDeviceId:
              audioInputDevices.length !== 0
                ? this.state.audioDeviceId
                  ? this.state.audioDeviceId
                  : audioInputDevices[0].deviceId
                : null,
            videoDeviceId:
              videoInputDevices.length !== 0
                ? this.state.videoDeviceId
                  ? this.state.videoDeviceId
                  : videoInputDevices[0].deviceId
                : null,
          });
        });
      },
      accessDenied: (event) => {
        this.setState({error: true, showSteps: true});
        errorToast({
          options: {
            autoClose: 10000,
          },
          content:
            "Please allow access to the Camera and Microphone and try publishing again.",
        });
      },
      audioLevelUpdated: (event) => {
        let movingAvg = null;
        if (movingAvg === null || movingAvg <= event.audioLevel) {
          movingAvg = event.audioLevel;
        } else {
          movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
        }
        // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
        var logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
        logLevel = Math.min(Math.max(logLevel, 0), 1);
        this.setState({audiLevel: logLevel * 100});
      },
    });
    publisher.on("mediaStopped", (event) => {
      console.log("Media Stopped", event);
      // let {appointmentType} = this.props;
      // function getLocalStream(type) {
      //   navigator.mediaDevices
      //     .getUserMedia({
      //       video: type === "audio" ? false : true,
      //       audio: true,
      //     })
      //     .then((stream) => {
      //       if (stream) {
      //         window.localStream = stream; // A
      //         window.localAudio.srcObject = stream; // B
      //         window.localAudio.autoplay = true; // C
      //       }
      //     })
      //     .catch((err) => {
      //       console.error(`you got an error: ${err}`);
      //     });
      // }
      // if (appointmentType === APPT_TYPE_AUDIO) {
      //   getLocalStream("audio");
      // } else {
      //   getLocalStream("video");
      // }
    });

    session.on("sessionReconnecting", (e) => {
      console.log("Subscriber video sessionReconnecting!", e);
    });
    session.on("sessionReconnected", (e) => {
      console.log("Subscriber video sessionReconnected!", e);
    });

    session.connect(token, (error) => {
      if (error) {
        this.handleSessionError(error);
      } else {
        session.publish(publisher, this.handleError);
        layout();
      }
    });
    this.setState({
      sessionObj: session,
      publisherObj: publisher,
      // screenPublisherObj: screenSharePublisher,
    });
  }

  callEndFromPatient = (session) => {
    console.log(
      "-------------Patient Ends The Call (callEndFromPatient) ---------------"
    );
    let {onCallEnd, callStartEndFromPatient} = this.props;
    onCallEnd();
    callStartEndFromPatient("finish");

    store.dispatch({
      type: "SET_OFFLINE_CALL_STATUS",
      payload: "finish",
    });

    store.dispatch({
      type: "IS_VIDEO_CALL_STARTED",
      payload: false,
    });

    store.dispatch({
      type: "ACTUAL_CALL_STARTED",
      payload: false,
    });

    store.dispatch({
      type: "SET_INTIAL_SOCKET_DATA",
      payload: null,
    });
    store.dispatch({
      type: "SET_APPOINTMENT_LOCATION_DATA",
      payload: null,
    });
    store.dispatch({
      type: "ON_STREAM_CONNECTED",
      payload: null,
    });
    if (this.state.subscriberObj && session) {
      console.log("unsubscribe the subscriber from stream ");
      session.unsubscribe(this.state.subscriberObj);
    }
    session.disconnect();
    if (this.state.chatArray.length !== 0) {
      this.submitChatHistory();
    }
    var audio = new Audio(require("../../assets/sounds/call_end.mp3").default);
    audio.play();
    document.querySelector("body").classList.remove("full-screen-active");
    let element = document.querySelector(".video-call-panelinit");
    if (element) {
      element.style.display = "block";
    }
    if (
      publisher2 &&
      publisher2.destroy &&
      typeof publisher2.destroy === "function"
    ) {
      publisher2.destroy();
    }
    store.dispatch({
      type: "ON_CALL_AGAIN",
      payload: false,
    });
  };
  handleSessionError = (err) => {
    if (err && err.name) {
      store.dispatch({
        type: "SHOW_OSS_LINK",
        payload: true,
      });
      switch (err.name) {
        case "OT_CONNECT_FAILED":
          errorToast({
            content:
              "Publishing your video failed. Please check your internet.",
          });
          if (this.state.subscriberObj && this.state.sessionObj) {
            console.log("unsubscribe the subscriber from stream ");
            this.state.sessionObj.unsubscribe(this.state.subscriberObj);
            store.dispatch({
              type: "ON_STREAM_CONNECTED",
              payload: null,
            });
          }
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_NOT_CONNECTED":
          errorToast({
            content:
              "Publishing your video failed. Couldn't perfoarm action because you are not connected to the network..",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_INVALID_SESSION_ID":
          errorToast({
            content: "Publishing your video failed. Session ID is invalid.",
          });
          break;
        default:
          errorToast({
            content:
              "An unknown error occurred while trying to publish your video. Please try again later.",
          });
      }
    }
  };
  handlePublisherError = (err) => {
    if (err && err.name) {
      switch (err.name) {
        case "OT_NOT_CONNECTED":
          errorToast({
            content:
              "Publishing your video failed. You are not connected to the internet or your bandwidth is low.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_DISCONNECTED":
          errorToast({
            content:
              "Publishing your video failed. You are not connected to the internet or your bandwidth is low.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_STREAM_DESTROYED":
          errorToast({
            content:
              "Publishing your video failed. The stream was destroyed before it could be subscribed to patient",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;

        case "OT_CREATE_PEER_CONNECTION_FAILED":
          errorToast({
            content:
              "Publishing your video failed. This could be due to a restrictive firewall.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_CHROME_MICROPHONE_ACQUISITION_ERROR":
          errorToast({
            content:
              "Failed to acquire microphone. This is a known Chrome Bug. Please completely quit and restart you browser.",
          });

          break;
        case "OT_TIMEOUT":
          errorToast({
            content:
              "Failed to acquire microphone. This is a known Chrome Bug. Please completely quit and restart you browser.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        default:
          errorToast({
            content:
              "An unknown error occurred while trying to publish your video. Please try again later.",
          });
      }
    }
  };
  handleSubscriberError = (err) => {
    if (err && err.name) {
      switch (err.name) {
        case "OT_NOT_CONNECTED":
          errorToast({
            content:
              "Publishing your video failed. You are not connected to the internet or your bandwidth is low.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_DISCONNECTED":
          errorToast({
            content:
              "Publishing your video failed. You are not connected to the internet or your bandwidth is low.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_STREAM_DESTROYED":
          errorToast({
            content:
              "Publishing your video failed. The stream was destroyed before it could be subscribed to patient",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;

        case "OT_CREATE_PEER_CONNECTION_FAILED":
          errorToast({
            content:
              "Publishing your video failed. This could be due to a restrictive firewall.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        case "OT_CHROME_MICROPHONE_ACQUISITION_ERROR":
          errorToast({
            content:
              "Failed to acquire microphone. This is a known Chrome Bug. Please completely quit and restart you browser.",
          });

          break;
        case "OT_TIMEOUT":
          errorToast({
            content:
              "Failed to acquire microphone. This is a known Chrome Bug. Please completely quit and restart you browser.",
          });
          this.reconnectCall();
          // this.callEndFromPatient();

          break;
        default:
          errorToast({
            content:
              "An unknown error occurred while trying to publish your video. Please try again later.",
          });
      }
    }
  };

  submitScreenShot = async (event) => {
    let {appointmentId} = this.props;

    let image_data = "";
    // console.log("his.state.screenShotImage", this.state.screenShotImage);
    if (this.state.screenShotImage) {
      image_data = `data:image/png;base64,${this.state.screenShotImage}`;
    }
    console.log("this._scrollRef", this._scrollRef);

    if (
      event &&
      event.type === "screenshot" &&
      event.data === "accepted" &&
      this._scrollRef !== null
    ) {
      try {
        await this.setState({isLoading: true});
        let response = await uploadScreenShots({
          appointment_id: appointmentId,
          file: image_data,
        });
        successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
        await this.props.OnSetComponent();
        this.setState(
          {screenShotTakenCount: this.state.screenShotTakenCount + 1},
          () => {
            store.dispatch({
              type: "IS_SCREENSHOT_TAKEN",
              payload: this.state.screenShotTakenCount,
            });
          }
        );

        // this.setState({
        //   screenshots: verifyObject(response, "data.data", []),
        // });
        await this.setState({isLoading: false});
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({isLoading: false});
        console.log("SCFEEN SHOT ERROR ====>", error);
        errorToast({content: message});
      }
    }
  };
  submitChatHistory = async () => {
    let {appointmentId, vCallChatHistory} = this.props;
    try {
      await this.setState({isLoading: true});
      await addChatVideoHistoryAPI({
        appointment_id: appointmentId,
        chat_transcript: vCallChatHistory,
      });
      // successToast({
      //   content: verifyObject(response, "data.message", "Success"),
      // });
      await this.setState({isLoading: false});
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({isLoading: false});
      // errorToast({ content: message });
    }
  };

  reconnectCall = () => {
    store.dispatch({
      type: "ACTUAL_CALL_STARTED",
      payload: false,
    });
    store.dispatch({
      type: "IS_VIDEO_CALL_STARTED",
      payload: true,
    });
    store.dispatch({
      type: "ON_CALL_AGAIN",
      payload: true,
    });

    let element = document.querySelector(".video-call-panelinit");
    if (element) {
      element.style.display = "block";
    }
    var audio = new Audio(require("../../assets/sounds/call_end.mp3"));
    console.log("HERE IT IS CALLED  2");

    audio.play();
    let {sessionObj} = this.state;
    if (sessionObj) {
      sessionObj.off("connectionCreated", () => {
        console.log("OFF stream connectionCreated");
      });
      sessionObj.off("streamCreated", () => {
        console.log("OFF stream created");
      });
      sessionObj.off("streamDestroyed", () => {
        console.log("OFF stream destroyed");
      });
      sessionObj.disconnect();
    }
    if (this.state.subscriberObj && this.state.sessionObj) {
      console.log("unsubscribe the subscriber from stream ");
      // this.state.sessionObj.unsubscribe(this.state.subscriberObj);
    }
    console.log("THIS>SESSION OBJ", this.state.sessionObj);
    this.state.sessionObj.unpublish(this.state.publisherObj);
    if (this.state.sessionObj) {
      this.state.sessionObj.disconnect();
      // this.props.action
    }

    if (
      this.props.resetReconnectState &&
      typeof this.props.resetReconnectState === "function"
    ) {
      this.props.resetReconnectState();
    }
    if (this.state.sessionObj) {
      this.state.sessionObj.destroy();
      // this.state.sessionObj.forceDisconnect();
    }
    if (
      this.state.publisherObj &&
      this.state.publisherObj.destroy &&
      typeof this.state.publisherObj.destroy === "function"
    ) {
      this.state.publisherObj.destroy();
      if (
        publisher2 &&
        publisher2.destroy &&
        typeof publisher2.destroy === "function"
      ) {
        publisher2.destroy();
      }
      this.state.publisherObj.disconnect();
    }

    if (
      this.state.screenSharePublisher &&
      this.state.screenSharePublisher.destroy &&
      typeof this.state.screenSharePublisher.destroy === "function"
    ) {
      this.state.screenSharePublisher.destroy();
      if (
        publisher2 &&
        publisher2.destroy &&
        typeof publisher2.destroy === "function"
      ) {
        publisher2.destroy();
      }
    }
    if (
      this.state.videoSharePublisher &&
      this.state.videoSharePublisher.destroy &&
      typeof this.state.videoSharePublisher.destroy === "function"
    ) {
      this.state.videoSharePublisher.destroy();
      if (
        publisher2 &&
        publisher2.destroy &&
        typeof publisher2.destroy === "function"
      ) {
        publisher2.destroy();
      }
    }
    document.querySelector("body").classList.remove("full-screen-active");
    // let { connectWaitingRoomChannel } = this.props;
    // if (
    //   connectWaitingRoomChannel &&
    //   typeof connectWaitingRoomChannel === "function"
    // ) {
    //   console.log("IN connectWaitingRoomChannel");
    //   connectWaitingRoomChannel();
    // }
    this.setState({intialTime: 0, activeStreamsArray: []}, () => {});
    this.props.resetRemainingTime();
  };
  terminateCall = (isFromSubscriber, reason, isSubmitForReview) => {
    // console.log("INNNNN callEndFromDoctor");
    let {onCallEnd, callStartEnd} = this.props;
    onCallEnd();
    callStartEnd("finish", isSubmitForReview);
    store.dispatch({
      type: "IS_VIDEO_CALL_STARTED",
      payload: false,
    });
    store.dispatch({
      type: "VIDEO_CALL_SETTINGS",
      payload: null,
    });

    store.dispatch({
      type: "ACTUAL_CALL_STARTED",
      payload: false,
    });

    store.dispatch({
      type: "SET_INTIAL_SOCKET_DATA",
      payload: null,
    });
    store.dispatch({
      type: "SET_APPOINTMENT_LOCATION_DATA",
      payload: null,
    });
    if (!isFromSubscriber) {
      if (this.state.chatArray.length !== 0) {
        this.submitChatHistory();
      }
    }
    if (
      publisher2 &&
      publisher2.destroy &&
      typeof publisher2.destroy === "function"
    ) {
      publisher2.destroy();
    }

    let {appointmentId} = this.props;

    let payload = {
      appointment_id: appointmentId,
      call_token: "",
      status: "finish",
      time: new Date().toISOString(),
      is_submitted_for_review: isSubmitForReview,
    };
    startEndOfflineCall(payload);
    console.log("this.state.subscriberObj", this.state.subscriberObj);
    if (this.state.subscriberObj) {
      // console.log("unsubscribe the subscriber from stream ");
      // alert("unsubscribing")
      this.state.sessionObj.unsubscribe(this.state.subscriberObj);
      store.dispatch({
        type: "ON_STREAM_CONNECTED",
        payload: null,
      });
    }
    if (this.state.sessionObj) {
      this.state.sessionObj.disconnect();
    }
    document.querySelector("body").classList.remove("full-screen-active");
    let element = document.querySelector(".video-call-panelinit");
    if (element) {
      element.style.display = "block";
    }
  };

  callEndFromDoctor = (isFromSubscriber, reason) => {
    confirmModal.confirm({
      keyboard: false,
      title: "Would you like to End Call or Join the call again ?",
      icon: <img alt="Confirmation" src={confirmation_video}></img>,
      onOk: () => {
        // let {
        //   appointmentObj: {book_via},
        // } = this.props;
        // if (book_via === "ad_hoc") {
        //   this.terminateCall(isFromSubscriber, reason, false);
        // } else {
        // confirmModalAskReview.confirm({
        //   title: "Would you like to submit this consultation for review ?",
        //   // icon: <img alt="Confirmation" src={confirmation_video}></img>,
        //   onOk: () => {
        //     this.terminateCall(isFromSubscriber, reason, true);
        //   },
        //   onCancel: () => {
        //     this.terminateCall(isFromSubscriber, reason, false);
        //   },
        //   okText: "Yes",
        //   cancelText: "No",
        // });
        this.terminateCall(isFromSubscriber, reason, false);
        // }
      },
      onCancel: () => {
        let payload = {
          videoDeviceId: this.state.videoDeviceId
            ? this.state.videoDeviceId
            : false,
          audioDeviceId: this.state.audioDeviceId
            ? this.state.audioDeviceId
            : false,
          currentAudioOutputDevice: this.state.currentAudioOutputDevice
            ? this.state.currentAudioOutputDevice
            : false,
          blurType: this.state.blurType ? this.state.blurType : false,
          changeBackground: this.state.changeBackground
            ? this.state.changeBackground
            : false,
          isChecked: this.state.isChecked ? this.state.isChecked : false,
          bgImage:
            this.state.changeBackground && this.state.bgImage
              ? this.state.bgImage
              : false,
        };

        if (
          publisher2 &&
          publisher2.destroy &&
          typeof publisher2.destroy === "function"
        ) {
          publisher2.destroy();
        }

        this.state.sessionObj.signal(
          {
            type: "disconnect-all-from-doctor",
            data: true,
          },
          (error) => {
            store.dispatch({
              type: "VIDEO_CALL_SETTINGS",
              payload,
            });

            if (error) {
              console.log(
                "signal error (" + error.name + "): " + error.message
              );
            } else {
              console.log("signal sent.");
              console.log(
                "this.state.subscriberObj ",
                this.state.subscriberObj
              );

              if (this.state.subscriberObj && this.state.sessionObj) {
                console.log("unsubscribe the subscriber from stream ");
                // this.state.sessionObj.unsubscribe(this.state.subscriberObj);
              }
              this.setState({
                activeStreamsArray: [],
              });
              if (this.state.sessionObj) {
                this.state.sessionObj.destroy();
                this.state.sessionObj.disconnect();
                // this.state.sessionObj.forceDisconnect();
              }
              if (
                this.state.publisherObj &&
                this.state.publisherObj.destroy &&
                typeof this.state.publisherObj.destroy === "function"
              ) {
                this.state.publisherObj.destroy();
              }

              if (
                this.state.screenSharePublisher &&
                this.state.screenSharePublisher.destroy &&
                typeof this.state.screenSharePublisher.destroy === "function"
              ) {
                this.state.screenSharePublisher.destroy();
              }
              if (
                this.state.videoSharePublisher &&
                this.state.videoSharePublisher.destroy &&
                typeof this.state.videoSharePublisher.destroy === "function"
              ) {
                this.state.videoSharePublisher.destroy();
              }
            }
          }
        );
        if (this.state.sessionObj) {
          this.state.sessionObj.disconnect();
        }
        // let { subscribedChannel } = this.props;
        // if (
        //   subscribedChannel &&
        //   typeof subscribedChannel.unsubscribe === "function"
        // ) {
        //   subscribedChannel.unsubscribe();
        // }

        this.props.unsubscribeSocketOnRejoin(this.reconnectCall);
      },
      okText: "End Call",
      cancelText: "Rejoin",
      cancelButtonProps: {
        style: {
          backgroundColor: "#1445b7",
        },
      },
    });
  };

  scrollToBottom = (type, message, messageType) => {
    // console.log("this._scrollRef", this._scrollRef);
    if (this._scrollRef && !this.state.isFullScreenActive) {
      if (type === "left") {
        if (messageType === "image") {
          AntdMessage.info(`Shared an Attachment`);
        } else {
          if (isURL(message)) {
            AntdMessage.info(`Shared a URL`);
          } else {
            AntdMessage.info(`New Message ${message}`);
          }
        }
      }
      this._scrollRef.scrollTop = Number.MAX_SAFE_INTEGER;
      // this._scrollRef2.scrollTop = Number.MAX_SAFE_INTEGER;
    }

    if (this._scrollRef2 && this.state.isFullScreenActive) {
      if (type === "left") {
        if (messageType === "image") {
          AntdMessage.info(`Shared an Attachment`);
        } else {
          if (isURL(message)) {
            AntdMessage.info(`Shared a URL`);
          } else {
            AntdMessage.info(`New Message ${message}`);
          }
        }
      }
      this._scrollRef2.scrollTop = Number.MAX_SAFE_INTEGER;
    }
  };

  setAudioToggle = () => {
    this.setState({audio: !this.state.audio}, () => {
      if (this.state.publisherObj) {
        this.state.publisherObj.publishAudio(this.state.audio);
      }
    });
  };

  setVideoToggle = () => {
    this.setState({video: !this.state.video}, () => {
      if (this.state.publisherObj) {
        this.state.publisherObj.publishVideo(this.state.video);
      }
    });
  };
  onScreenShotTake = () => {
    // console.log(
    //   "this.state.subscriberObj out",
    //   this.state.subscriberObj,
    //   this.state.sessionObj
    // );

    if (this.state.subscriberObj) {
      // console.log("this.state.subscriberObj", this.state.subscriberObj);
      let imageData = this.state.subscriberObj.getImgData();
      let msg = {
        type: "screenshot",
      };
      this.setState({screenShotImage: imageData});
      if (this.state.sessionObj) {
        this.state.sessionObj.signal({
          type: "other",
          data: msg,
        });
      }
    }
  };

  onSignalSend = (value, type) => {
    this.scrollToBottom();
    if (this.state.sessionObj) {
      this.state.sessionObj.signal(
        {
          type: "chat",
          data: {
            text: value,
            type: type,
          },
        },
        (error) => {
          if (error) {
          } else {
            this.setState({text: ""});
          }
        }
      );
    }
  };

  renderFile(fileUrl) {
    let url1 = fileUrl.split("?");
    let fileType = url1[0].split(/[#?]/)[0].split(".").pop().trim();
    let docFiles = ["pdf", "doc", "docx", "csv", "xl", "xls", "xlsx"];
    let imageFiles = ["jpg", "jpeg", "png", "svg", "gif", "bmp", "webp", ""];

    if (docFiles.includes(fileType.toLowerCase())) {
      return (
        <a href={fileUrl} target="__blank">
          <img
            width={50}
            alt=""
            src={require("../../assets/images/common/file_upload.svg").default}
          />
        </a>
      );
    } else if (imageFiles.includes(fileType.toLowerCase())) {
      return (
        <a href={fileUrl} target="__blank">
          <img width={100} alt="" src={fileUrl} />
        </a>
      );
    } else {
      return (
        <a
          style={{
            color: "white",
            textDecoration: "underline",
            fontWeight: 600,
          }}
          target="__blank"
          href={fileUrl}
        >
          {" "}
          {fileUrl}
        </a>
      );
    }
  }
  handleChange = (volume) => {
    this.setState({volume}, () => {
      // publisher.applyVideoFilter({
      //   type: "backgroundBlur",
      //   blurStrength: volume > 50 ? "high" : "low",
      // });
      if (this.state.subscriberObj) {
        this.state.subscriberObj.setAudioVolume(volume);
      }
    });
  };
  componentWillUnmount() {
    //cleanup code
    if (this.state.publisherObj) {
      this.state.publisherObj.destroy();

      if (
        publisher2 &&
        publisher2.destroy &&
        typeof publisher2.destroy === "function"
      ) {
        publisher2.destroy();
      }
    }
    let {sessionObj} = this.state;
    if (sessionObj) {
      sessionObj.off("connectionCreated", () => {
        console.log("OFF stream connectionCreated");
      });
      sessionObj.off("streamCreated", () => {
        console.log("OFF stream created");
      });
      sessionObj.off("streamDestroyed", () => {
        console.log("OFF stream destroyed");
      });
      sessionObj.disconnect();
    }
    if (this.state.sessionObj && this.state.subscriberObj) {
      this.state.sessionObj.unsubscribe(this.state.subscriberObj);
      store.dispatch({
        type: "ON_STREAM_CONNECTED",
        payload: null,
      });
    }
    // alert("Refreshed called")
  }
  publishScreenShare = () => {
    console.log("Toggling share mode screen");
    let {appointmentType, userObj} = this.props;

    let {sessionObj, audio, publisherObj} = this.state;
    publisherObj.publishVideo(false);
    let screenSharePublisher = OT.initPublisher(
      "publisher",
      {
        insertMode: "append",
        width: "100%",
        height: "100%",
        publishAudio: audio,
        id: "priory_vid_publisher",
        publishVideo: appointmentType === APPT_TYPE_AUDIO ? false : true,
        videoSource: "screen",
        audioSource: this.state.audioDeviceId
          ? this.state.audioDeviceId
          : "default",
        showControls: true,
        resolution: "1280x720",
        name: userObj && userObj.first_name ? userObj.first_name : "",
        style: {
          audioLevelDisplayMode: "off",
          buttonDisplayMode: "off",
          nameDisplayMode: "on",
          videoDisabledDisplayMode: "on",
          showArchiveStatus: false,
        },
      },
      this.handlePublisherError
    );

    screenSharePublisher.on("mediaStopped", (event) => {
      event.preventDefault();
      // console.log("Media Stopped", event);
      this.setState({screenShareActive: false}, () => {
        this.republishVideo();
      });
    });

    screenSharePublisher.on("accessDenied", (e) => {
      this.setState({screenShareActive: false}, () => {
        this.republishVideo();
      });
    });

    screenSharePublisher.on("streamDestroyed", function (event) {
      event.preventDefault();
    });

    sessionObj.signal(
      {
        type: "screen-share",
        data: {
          text: "on",
          // type: "screen-share"
        },
      },
      (error) => {
        if (error) {
          // console.log("signal error (" + error.name + "): " + error.message);
        } else {
          if (this.state.videoSharePublisher) {
            sessionObj.unpublish(this.state.videoSharePublisher);
          }
          if (publisherObj) {
            sessionObj.unpublish(publisherObj);
          }
          sessionObj.publish(screenSharePublisher, this.handleError);
          this.setState({
            screenSharePublisher: screenSharePublisher,
          });
          // console.log("signal sent.");
        }
      }
    );
  };

  republishVideo = () => {
    let {sessionObj, audio, publisherObj} = this.state;

    sessionObj.signal(
      {
        type: "screen-share",
        data: {
          text: "off",
          // type: "screen-share",
        },
      },
      (error) => {
        if (error) {
          console.log("signal error (" + error.name + "): " + error.message);
        } else {
          console.log("signal sent.");
          // sessionObj.unpublish(
          //   screenSharePublisher
          // );
          let {appointmentType, userObj} = this.props;
          console.log(
            "this.state.screenSharePublisher",
            this.state.screenSharePublisher
          );
          // screenSharePublisher.destroy();
          sessionObj.unpublish(this.state.screenSharePublisher);
          this.state.screenSharePublisher.destroy();
          publisherObj.publishVideo(false);
          let videoSharePublisher = OT.initPublisher(
            "publisher",
            {
              insertMode: "append",
              width: "100%",
              height: "100%",
              publishAudio: audio,
              id: "priory_vid_publisher",
              publishVideo:
                appointmentType === APPT_TYPE_AUDIO ? false : this.state.video,
              videoSource: undefined,
              audioSource: this.state.audioDeviceId
                ? this.state.audioDeviceId
                : "default",
              showControls: true,
              name: userObj && userObj.first_name ? userObj.first_name : "",
              resolution: "1280x720",
              style: {
                audioLevelDisplayMode: "off",
                buttonDisplayMode: "off",
                nameDisplayMode: "on",
                videoDisabledDisplayMode: "on",
                showArchiveStatus: false,
              },
            },
            this.handlePublisherError
          );
          videoSharePublisher.on("mediaStopped", (event) => {
            event.preventDefault();
            console.log("Media Stopped", event);
          });

          videoSharePublisher.on("accessDenied", (e) => {
            console.log("Access Denied 2", e);
          });
          sessionObj.publish(videoSharePublisher, this.handleError);
          this.setState({
            videoSharePublisher: videoSharePublisher,
          });
        }
      }
    );
  };

  handleScreenShare = async () => {
    // OT.getUserMedia({
    //   videoSource: 'screen'
    // }).then
    OT.checkScreenSharingCapability((response) => {
      if (!response.supported || response.extensionRegistered === false) {
        // This browser does not support screen sharing.
      } else if (response.extensionInstalled === false) {
        // Prompt to install the extension.
      } else {
        // Screen sharing is available. Publish the screen.
        this.setState(
          {
            screenShareActive: !this.state.screenShareActive,
          },
          async () => {
            console.log("Toggling share mode");

            if (this.state.screenShareActive) {
              this.publishScreenShare();
            } else {
              this.republishVideo();
            }
          }
        );
      }
    });
  };

  handleFullScreen = () => {
    this.setState(
      {
        isFullScreenActive: !this.state.isFullScreenActive,
        pubVideoAxis: {
          x: 0,
          y: 0,
        },
        x: 0,
        y: 0,
      },
      () => {
        if (this.state.isFullScreenActive === false) {
        }
        //  if(this.state.isFullScreenActive){
        let blank_div = document.getElementById("blank_div");
        if (blank_div) {
          blank_div.classList.toggle("full-screen-active");
        }
        let v_call_panel = document.getElementById("v_call_panel");
        if (v_call_panel) {
          v_call_panel.classList.toggle("full-screen-active");
        }
        if (this.state.layout && typeof this.state.layout === "function") {
          console.log("layout function called");
          this.state.layout();
        }
        document.querySelector("body").classList.toggle("full-screen-active");
      }
    );
  };

  componentDidUpdate(prevProps, nextState) {
    if (prevProps.isCallEndFromPatient) {
      confirmModal.destroyAll();
    }
  }
  callEndUsingTimer = () => {
    console.log("INNNNN callEndFromDoctor");
    let {onCallEnd, callStartEnd} = this.props;
    onCallEnd();
    callStartEnd("finish");
    store.dispatch({
      type: "IS_VIDEO_CALL_STARTED",
      payload: false,
    });

    store.dispatch({
      type: "ACTUAL_CALL_STARTED",
      payload: false,
    });

    store.dispatch({
      type: "SET_INTIAL_SOCKET_DATA",
      payload: null,
    });
    store.dispatch({
      type: "SET_APPOINTMENT_LOCATION_DATA",
      payload: null,
    });
    if (this.state.chatArray.length !== 0) {
      this.submitChatHistory();
    }
    let {appointmentId} = this.props;
    if (
      publisher2 &&
      publisher2.destroy &&
      typeof publisher2.destroy === "function"
    ) {
      publisher2.destroy();
    }

    let payload = {
      appointment_id: appointmentId,
      call_token: "",
      status: "finish",
      time: new Date().toISOString(),
    };
    startEndOfflineCall(payload);
    if (typeof this.props.callEndedUsingTimer === "function") {
      this.props.callEndedUsingTimer();
    }
    console.log("this.state.sessionObj", this.state.sessionObj);
    if (this.state.subscriberObj && this.state.sessionObj) {
      console.log("unsubscribe the subscriber from stream ");
      this.state.sessionObj.unsubscribe(this.state.subscriberObj);
      store.dispatch({
        type: "ON_STREAM_CONNECTED",
        payload: null,
      });
    }
    if (this.state.sessionObj) {
      this.state.sessionObj.disconnect();
    }
    document.querySelector("body").classList.remove("full-screen-active");
  };

  // reconnectVideo = () => {
  //   let {
  //     socketData: {
  //       data: { token },
  //     },
  //   } = this.props;

  //   if (this.otSession) {
  //     this.otSession.current.sessionHelper.session.connect(token);
  //   }
  // };
  renderUploadControl = () => {
    let {
      // socketData: {
      //   data: { session_id, token },
      // },
      appointmentId,
      userObj,
    } = this.props;
    let baseURL = getBaseUrl();
    let action = `${baseURL}/chats/save_attchments.json`;
    let uploadProps = {
      action: action,
      data: {appointment_id: appointmentId},
      headers: {
        AUTH_TOKEN: userObj.auth_token,
      },
      onStart: (file) => {
        this.setState({
          uploading: true,
          file_name: file.name,
          uploadPercent: 30,
        });
      },
      onSuccess: async (res, file) => {
        await successToast({content: "Successfully Uploaded Image"});
        await this.setState({uploading: false});
        var file_url = res.data;
        await this.onSignalSend(file_url, "image");
      },
      onError: (err) => {
        this.setState({uploading: false});
        errorToast({
          content:
            "There has been some issue while uploading the file, please upload again",
        });
      },
      onProgress: ({percent}, file) => {
        // this.setState({ uploadPercent: percent, uploading: true });
      },
    };

    return (
      <Upload accept={allowedFiletypes} {...uploadProps} showUploadList={false}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="27"
          height="27"
          viewBox="0 0 27 27"
        >
          <g fill="none" fill-rule="evenodd">
            <g stroke="#1445b7">
              <g>
                <g>
                  <g>
                    <g>
                      <path
                        d="M.875 17.063c0 2.416 1.959 4.375 4.375 4.375s4.375-1.96 4.375-4.375"
                        transform="translate(-1201 -1116) translate(967 109) translate(1 988) translate(233 20) rotate(45 7.752 18.93)"
                      />
                      <path
                        stroke-linecap="round"
                        d="M12.25 5.25V14M.875 17.5V5.25M9.625 5.25V17.5m-6.125 0V5.25"
                        transform="translate(-1201 -1116) translate(967 109) translate(1 988) translate(233 20) rotate(45 7.752 18.93)"
                      />
                      <path
                        d="M12.25 5.688C12.25 2.546 9.704 0 6.562 0 3.422 0 .875 2.546.875 5.688"
                        transform="translate(-1201 -1116) translate(967 109) translate(1 988) translate(233 20) rotate(45 7.752 18.93)"
                      />
                      <path
                        d="M9.625 5.688c0-1.692-1.371-3.063-3.063-3.063-1.69 0-3.062 1.371-3.062 3.063"
                        transform="translate(-1201 -1116) translate(967 109) translate(1 988) translate(233 20) rotate(45 7.752 18.93)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </Upload>
    );
  };
  onDeviceChange = async (value, deviceType) => {
    console.log("device iddd on change===>", value);
    if (value && this.state.isChecked) {
      await publisher
        .setVideoSource(value)
        .then(() => console.log("video source set====>yes===>"))
        .catch((error) => console.error(error.name));
      if (this.state.isWebMediaProcessorSupport) {
        await publisher.applyVideoFilter({
          type: "backgroundBlur",
          blurStrength: this.state.blurType,
        });
      }
      // publisher2
      //   .setVideoSource(value)
      //   .then(() => console.log("video source2 set====>yes===>"))
      //   .catch((error) => console.error(error.name));
      this.setState({videoDeviceId: value, isOpen: false}, async () => {
        if (
          publisher2 &&
          publisher2.destroy &&
          typeof publisher2.destroy === "function"
        ) {
          publisher2.destroy();
        }
      });
    } else if (value && !this.state.isChecked) {
      await publisher
        .setVideoSource(value)
        .then(() => console.log("video source set====>yes===>"))
        .catch((error) => console.error(error.name));
      if (
        this.state.changeBackground &&
        this.state.isWebMediaProcessorSupport
      ) {
        await publisher.applyVideoFilter({
          type: "backgroundReplacement",
          backgroundImgUrl: this.state.bgImage,
        });
        if (
          publisher2 &&
          publisher2.destroy &&
          typeof publisher2.destroy === "function"
        ) {
          publisher2.destroy();
        }
      } else {
        if (this.state.isWebMediaProcessorSupport) {
          await publisher.clearVideoFilter();
        }
        if (
          publisher2 &&
          publisher2.destroy &&
          typeof publisher2.destroy === "function"
        ) {
          publisher2.destroy();
        }
      }

      this.setState({videoDeviceId: value, isOpen: false}, async () => {
        if (
          publisher2 &&
          publisher2.destroy &&
          typeof publisher2.destroy === "function"
        ) {
          publisher2.destroy();
        }
      });
    } else if (!value && !this.state.isChecked) {
      this.setState({blurType: ""}, async () => {
        if (this.state.isWebMediaProcessorSupport) {
          await publisher.clearVideoFilter();
          await publisher2.clearVideoFilter();
        }
        this.setState({isOpen: false}, async () => {
          if (
            publisher2 &&
            publisher2.destroy &&
            typeof publisher2.destroy === "function"
          ) {
            publisher2.destroy();
          }
        });
      });
    } else if (
      value &&
      this.state.changeBackground &&
      this.state.isWebMediaProcessorSupport
    ) {
      await publisher.applyVideoFilter({
        type: "backgroundReplacement",
        backgroundImgUrl: this.state.bgImage,
      });
      if (
        publisher2 &&
        publisher2.destroy &&
        typeof publisher2.destroy === "function"
      ) {
        publisher2.destroy();
      }
    }
    this.state.audioFileState.pause();
  };
  handleOk = async () => {
    await this.onDeviceChange(this.state.videoDeviceId);
  };
  handleCancel = async () => {
    this.setState({isOpen: false}, () => {
      this.state.audioFileState.pause();

      if (
        publisher2 &&
        publisher2.destroy &&
        typeof publisher2.destroy === "function"
      ) {
        publisher2.destroy();
      }
    });
  };
  handleClick = async (click) => {
    let {appointmentType} = this.props;

    if (click) {
      this.setState({isOpen: true}, async () => {
        let {audio} = this.state;
        // let currentIndex = 0;

        // audioOutputs.forEach((device, index) => {
        //   if (device.label === currentOutputDevice.label) {
        //     currentIndex = index;
        //   }
        // });

        // const cycleAudioOutput = async () => {
        //   currentIndex += 1;
        //   let deviceId = audioOutputs[currentIndex % audioOutputs.length].deviceId;
        //   await OT.setAudioOutputDevice(deviceId);
        // };
        // if (appointmentType !== APPT_TYPE_AUDIO) {
        publisher2 = await OT.initPublisher(
          "publisher2",
          {
            insertMode: "append",
            width: "100%",
            height: "100%",
            publishAudio: audio,
            id: "priory_vid_publisher",
            publishVideo:
              appointmentType === APPT_TYPE_AUDIO ? false : this.state.video,
            videoSource:
              appointmentType === APPT_TYPE_AUDIO
                ? null
                : this.state.videoDeviceId &&
                  appointmentType !== APPT_TYPE_AUDIO
                ? this.state.videoDeviceId
                : this.state.videoSource === "screen" &&
                  appointmentType !== APPT_TYPE_AUDIO
                ? "screen"
                : undefined,
            audioSource: this.state.audioDeviceId
              ? this.state.audioDeviceId
              : "default",
            showControls: true,
            resolution: "1280x720",
            // name: userObj && userObj.first_name ? userObj.first_name : "",
            // style: {
            //   audioLevelDisplayMode: "off",
            //   buttonDisplayMode: "off",
            //   nameDisplayMode:
            //     appointmentType === APPT_TYPE_AUDIO ? "off" : "on",
            //   videoDisabledDisplayMode:
            //     appointmentType === APPT_TYPE_AUDIO ? "off" : "on",
            //   showArchiveStatus: false,
            // },
            videoFilter:
              appointmentType === APPT_TYPE_AUDIO
                ? false
                : this.state.isChecked
                ? {
                    type: "backgroundBlur",
                    blurStrength: this.state.blurType,
                  }
                : this.state.changeBackground
                ? {
                    type: "backgroundReplacement",
                    backgroundImgUrl: this.state.bgImage,
                  }
                : false,
          },
          this.handlePublisherError
        );
        if (appointmentType === APPT_TYPE_AUDIO) {
          publisher2.publishVideo(false);
        }
        if (this.state.isChecked) {
          await publisher2.applyVideoFilter({
            type: "backgroundBlur",
            blurStrength: this.state.blurType,
          });
        }
        if (this.state.changeBackground) {
          await publisher2.applyVideoFilter({
            type: "backgroundReplacement",
            backgroundImgUrl: this.state.bgImage,
          });
        }
        // }
        // publisher2
        //   .setVideoSource(this.state.videoDeviceId)
        //   .then(() => console.log("video source set"))
        //   .catch((error) => console.error(error.name));
        // this.setState({publisherObj: publisher});
      });
    }
  };
  // let  audioOutputs = await OT.getAudioOutputDevices();
  // const currentOutputDevice =
  //   await OT.getActiveAudioOutputDevice();
  // audioOutputs.forEach((device, index) => {
  //   if (device.label === currentOutputDevice.label) {
  //     currentIndex = index;
  //   }
  // });

  cycleAudioOutput = async () => {
    // currentIndex += 1;
    // let deviceId =
    //   audioOutputs[currentIndex % audioOutputs.length]
    //     .deviceId;
    await OT.setAudioOutputDevice(this.state.currentAudioOutputDevice);
    console.log("setteddd=======>");
  };

  render() {
    let {appointmentType, appointmentId} = this.props;
    let {showPublisher, chatArray, isFullScreenActive} = this.state;
    let minusHeight = window.innerHeight - 210;
    let {
      appointmentObj: {book_via},
    } = this.props;

    return (
      <div id="blank_div">
        <div className="parent-div video-call-chat-panel" id="v_call_panel">
          {!this.state.connecting &&
            this.state.duration > 0 &&
            this.state.intialTime !== null &&
            this.state.intialTime > 0 &&
            this.state.isStreamStarted && (
              <ConnectionStatus
                connecting={this.state.connecting}
                connected={this.state.connected}
                duration={parseInt(this.state.duration, 10) / 60}
                sessionObj={this.state.sessionObj}
                appointmentId={appointmentId}
                intialTime={parseInt(this.state.intialTime, 10)}
                callEndFromDoctor={this.callEndFromDoctor}
                callEndUsingTimer={this.callEndUsingTimer}
                handleSpeakFunction={this.props.handleSpeakFunction}
                socket_apt_end_time={this.props.socket_apt_end_time}
                appointment_end_time={verifyObject(
                  this.props,
                  "appointmentObj.end_time",
                  null
                )}
              />
            )}
          {/* {this.state.activeStreamsArray.length !== 0 &&
            book_via === "ad_hoc" && (
              <div className="show_member_btn">
                <button
                  onClick={() => {
                    this.setState({ visibleDrawer: true });
                  }}
                  className="ant-btn ant-btn-primary custom_ok_btn"
                >
                  Show Members
                </button>
              </div>
            )} */}
          <div
            // style={{}}
            className={`video_widget ${
              this.props.appointmentType === APPT_TYPE_AUDIO
                ? "audio-only-call"
                : "video-only-call"
            }`}
            // id="layoutContainerV"
          >
            <ResizableBox
              width={346}
              height={330}
              minConstraints={[346, 330]}
              resizeHandles={["sw"]} //"e", "s", "se"
              onResize={() => {
                if (this.state.layout && typeof this.state.layout) {
                  this.state.layout();
                }
                let videoBoxHeight =
                  document.querySelector(".video_widget").offsetHeight;
                this.setState({resizeStarted: videoBoxHeight});
              }}
            >
              <div id="layoutContainerV"></div>
              {/* {appointmentType !== APPT_TYPE_AUDIO && ( */}
              <Rnd
                style={{
                  ...style,
                  display:
                    appointmentType === APPT_TYPE_AUDIO ? "none" : "block",
                }}
                default={{
                  x: 195,
                  y: 0,
                  width: 150,
                  height: 100,
                }}
                position={{x: this.state.x, y: this.state.y}}
                onDragStop={(e, d) => {
                  this.setState({x: d.x, y: d.y});
                }}
                id="publisher"
                bounds=".react-resizable"
                dragAxis="both"
              ></Rnd>
              {/* )} */}
              <div style={{zIndex: 3}} className="publisher-video-controls">
                {appointmentType === APPT_TYPE_AUDIO && (
                  <AudioCallScreen {...this.state} {...this.props} />
                )}
                <DoctorVideoControls
                  bookVia={book_via}
                  renderFile={this.renderFile}
                  blur={this.state.volume}
                  handleClick={this.handleClick}
                  onDeviceChange={this.onDeviceChange}
                  videoInputDevices={this.state.videoInputDevices}
                  videoDeviceId={this.state.videoDeviceId}
                  hidePublisher={() => {
                    this.setState({showPublisher: !showPublisher});
                    let element = document.querySelector("#publisher");
                    if (element) {
                      element.style.display = "none";
                    }
                  }}
                  showPublihser={() => {
                    this.setState({showPublisher: !showPublisher});
                    let element = document.querySelector("#publisher");
                    if (element) {
                      element.style.display = "block";
                    }
                  }}
                  handleChatCount={() => {
                    this.setState({
                      showChat: !this.state.showChat,
                      unreadCount: 0,
                    });
                  }}
                  handleScreenShare={this.handleScreenShare}
                  screenShareActive={this.state.screenShareActive}
                  onScreenShotTake={this.onScreenShotTake}
                  setAudioToggle={this.setAudioToggle}
                  setVideoToggle={this.setVideoToggle}
                  handleChange={this.handleChange}
                  callEndFromDoctor={() => this.callEndFromDoctor(false)}
                  {...this.props}
                  {...this.state}
                  resizeStarted={this.state.resizeStarted}
                  minusHeight={minusHeight}
                  handleFullScreen={this.handleFullScreen}
                  onSignalSend={(v) => this.onSignalSend(v, "text")}
                  handleMessageChange={(e) => {
                    this.setState({text: e.target.value});
                  }}
                  renderUploadControl={this.renderUploadControl}
                  _scrollRef={this._scrollRef}
                />
              </div>
            </ResizableBox>
          </div>

          {book_via !== "ad_hoc" && (
            <Fragment>
              <div
                className={`video-chatmainsection ${
                  this.state.showChat ? "opened-chat" : "closed-chat"
                }`}
              >
                <div
                  onClick={() =>
                    this.setState({
                      showChat: !this.state.showChat,
                      unreadCount: 0,
                    })
                  }
                  className="chatbot_header"
                >
                  <p className="m-0 weight-400 font-16">
                    {" "}
                    Chat {this.state.uploading && `( Uploading...)`}
                  </p>
                  <span className="close_chat">×</span>
                </div>
                <Scrollbars
                  containerRef={(ref) => {
                    this._scrollRef = ref;
                  }}
                  className="video_chat_scrolling"
                  style={{
                    height: `calc(100vh - 210px - ${
                      this.state.resizeStarted ? this.state.resizeStarted : 330
                    }px)`,
                  }}
                >
                  {this.state.resizeStarted < minusHeight && (
                    <div className="chat-bubble-container">
                      {chatArray.map((chat) => {
                        if (chat.msg_send === "right") {
                          return (
                            <div className="chat-bubble-right chat-right">
                              {isURL(chat.msg_data) ? (
                                this.renderFile(chat.msg_data)
                              ) : (
                                <Linkify>
                                  <span> {chat.msg_data}</span>
                                </Linkify>
                              )}
                            </div>
                          );
                        } else {
                          return (
                            <div className="chat-bubble-left chat-left">
                              {isURL(chat.msg_data) ? (
                                this.renderFile(chat.msg_data)
                              ) : (
                                <Linkify>
                                  <span> {chat.msg_data}</span>
                                </Linkify>
                              )}
                            </div>
                          );
                        }
                      })}
                      <div style={{padding: 15}}></div>
                    </div>
                  )}
                </Scrollbars>
                {this.state.showChat && (
                  <Search
                    placeholder="Type your message here..."
                    enterButton={"Enter"}
                    size="large"
                    suffix={this.renderUploadControl()}
                    name="text"
                    value={this.state.text}
                    onSearch={(value) => {
                      if (spaceRegex.test(value) === true) {
                        console.log("Value 2", !isEmpty(value));

                        this.onSignalSend(value, "text");
                      }
                    }}
                    onChange={(e) => {
                      this.setState({text: e.target.value});
                    }}
                  />
                )}

                {appointmentType === APPT_TYPE_VIDEO && isFullScreenActive && (
                  <div
                    className={`video-chatmainsection
                ${
                  this.state.showChat
                    ? "opened-chat full-open-chat"
                    : "closed-chat full-close-chat"
                }`}
                  >
                    <div
                      onClick={() =>
                        this.setState({
                          showChat: !this.state.showChat,
                          unreadCount: 0,
                        })
                      }
                      className="chatbot_header"
                    >
                      <p className="m-0 weight-400 font-16">
                        Chat {this.state.uploading && `( Uploading...)`}
                      </p>
                      <span className="close_chat">×</span>
                    </div>
                    <Scrollbars
                      containerRef={(ref) => {
                        this._scrollRef2 = ref;
                      }}
                      className="video_chat_scrolling"
                    >
                      {
                        <div className="chat-bubble-container">
                          {chatArray.map((chat) => {
                            if (chat.msg_send === "right") {
                              return (
                                <div className="chat-bubble-right chat-right">
                                  {isURL(chat.msg_data) ? (
                                    this.renderFile(chat.msg_data)
                                  ) : (
                                    <Linkify>
                                      <span> {chat.msg_data}</span>
                                    </Linkify>
                                  )}
                                </div>
                              );
                            } else {
                              return (
                                <div className="chat-bubble-left chat-left">
                                  {isURL(chat.msg_data) ? (
                                    this.renderFile(chat.msg_data)
                                  ) : (
                                    <Linkify>
                                      <span> {chat.msg_data}</span>
                                    </Linkify>
                                  )}
                                </div>
                              );
                            }
                          })}
                          <div style={{padding: 15}}></div>
                        </div>
                      }
                    </Scrollbars>

                    {
                      <Search
                        placeholder="Type your message here..."
                        enterButton={"Enter"}
                        size="large"
                        suffix={this.renderUploadControl()}
                        name="text"
                        value={this.state.text}
                        onSearch={(value) => {
                          if (!isEmpty(value)) {
                            this.onSignalSend(value, "text");
                          }
                        }}
                        onChange={(e) => {
                          this.setState({text: e.target.value});
                        }}
                      />
                    }
                  </div>
                )}
                {!isFullScreenActive &&
                  this.state.resizeStarted < minusHeight && (
                    <div
                      onClick={() =>
                        this.setState({showChat: !this.state.showChat})
                      }
                      id="chat-circle"
                      className={`btn-raised ${
                        this.state.showChat
                          ? "chat-icon-opened"
                          : "chat-icon-closed"
                      }`}
                    >
                      <span className="font-14 weight-400">Chat</span>
                      {this.state.unreadCount !== 0 && (
                        <span className="chatbox_unread weight-500">
                          {this.state.unreadCount}
                        </span>
                      )}
                    </div>
                  )}
              </div>
            </Fragment>
          )}

          {book_via !== "ad_hoc" &&
            !isFullScreenActive &&
            this.state.resizeStarted < minusHeight && (
              <div
                onClick={() =>
                  this.setState({showChat: !this.state.showChat}, () => {
                    // this._onRequestList();
                  })
                }
                id="chat-circle"
                className={`btn-raised ${
                  this.state.showChat ? "chat-icon-opened" : "chat-icon-closed"
                }`}
              >
                <span className="font-14 weight-400">Chat</span>
                {this.state.unreadCount !== 0 && (
                  <span className="chatbox_unread weight-500">
                    {this.state.unreadCount}
                  </span>
                )}
              </div>
            )}
        </div>
        {this.state.isOpen && (
          <Drawer
            visible={this.state.isOpen}
            onClose={this.handleCancel}
            closable={true}
            title="Settings"
            width={315}
            // extra={
            //   <Space>
            //     <Button onClick={this.handleCancel}>Cancel</Button>
            //     <Button type="primary" onClick={this.handleOk}>
            //       OK
            //     </Button>
            //   </Space>
            // }
          >
            <div>
              {appointmentType === APPT_TYPE_VIDEO && (
                <div className="col-md-12">
                  <p className="text-center font-14 text-light-black margin-top-15 weight-500 ">
                    1. Set different camera
                  </p>
                  <select
                    onChange={(v) =>
                      this.setState({videoDeviceId: v.target.value}, () => {
                        publisher2
                          .setVideoSource(this.state.videoDeviceId)
                          .then(() => console.log("video source set"))
                          .catch((error) => console.error(error.name));
                      })
                    }
                    className="form-control"
                    value={this.state.videoDeviceId}
                  >
                    {this.state.isOpen &&
                      this.state.videoInputDevices &&
                      this.state.videoInputDevices.length > 1 &&
                      this.state.videoInputDevices.map((v) => {
                        return (
                          <option key={uniqueId("VS")} value={v.deviceId}>
                            {v.label}
                          </option>
                        );
                      })}
                  </select>
                </div>
              )}

              <div
                style={{
                  ...style2,
                  display:
                    appointmentType === APPT_TYPE_AUDIO ? "none" : "block",
                  zIndex: appointmentType === APPT_TYPE_AUDIO ? 0 : 111,
                }}
                default={{
                  x: 195,
                  y: 0,
                  width: 150,
                  height: 100,
                }}
                className="col-md-12"
                id="publisher2"
              ></div>
              {appointmentType === APPT_TYPE_VIDEO &&
                this.state.isWebMediaProcessorSupport && (
                  <div style={{marginTop: "10px"}}>
                    <Switch
                      disabled={this.state.changeBackground}
                      checked={this.state.isChecked}
                      onChange={(v) =>
                        this.setState({isChecked: v}, () => {
                          if (this.state.isChecked) {
                            publisher2.applyVideoFilter({
                              type: "backgroundBlur",
                              blurStrength: this.state.blurType,
                            });
                          } else {
                            this.setState({blurType: "low"}, () => {
                              publisher2.clearVideoFilter();
                            });
                          }
                        })
                      }
                      id="bg_blur"
                    />
                    <label for="bg_blur">Blur background</label>
                    {/* </Switch> */}
                    <div>
                      {this.state.isChecked && (
                        <Radio.Group
                          value={this.state.blurType}
                          onChange={(v) =>
                            //  console.log("radio====>", v)
                            this.setState({blurType: v.target.value}, () => {
                              publisher2.applyVideoFilter({
                                type: "backgroundBlur",
                                blurStrength: this.state.blurType,
                              });
                            })
                          }
                        >
                          <Radio value="low">Low</Radio>
                          <Radio value="high">High</Radio>
                        </Radio.Group>
                      )}
                    </div>
                  </div>
                )}
              {appointmentType === APPT_TYPE_VIDEO &&
                this.state.isWebMediaProcessorSupport && (
                  <div style={{marginTop: "10px"}}>
                    <Switch
                      disabled={this.state.isChecked}
                      checked={this.state.changeBackground}
                      onChange={(v) =>
                        this.setState({changeBackground: v}, () => {
                          if (this.state.changeBackground) {
                            publisher2.applyVideoFilter({
                              type: "backgroundReplacement",
                              backgroundImgUrl: this.state.bgImage,
                            });
                          } else {
                            this.setState({blurType: "low"}, () => {
                              publisher2.clearVideoFilter();
                            });
                          }
                        })
                      }
                      id="bg_change"
                    />
                    <label for="bg_change">Change background</label>
                    {/* </Switch> */}
                    <div>
                      {this.state.changeBackground && (
                        <Radio.Group
                          value={this.state.bgImage}
                          onChange={(v) =>
                            //  console.log("radio====>", v)
                            this.setState({bgImage: v.target.value}, () => {
                              publisher2.applyVideoFilter({
                                type: "backgroundReplacement",
                                backgroundImgUrl: this.state.bgImage,
                              });
                            })
                          }
                        >
                          <Radio value={bg1}>Bg1</Radio>
                          <Radio value={bg2}>Bg2</Radio>
                          <Radio value={bg3}>Bg3</Radio>
                          <Radio value={bg4}>Bg4</Radio>
                        </Radio.Group>
                      )}
                    </div>
                  </div>
                )}
              {appointmentType === APPT_TYPE_VIDEO && (
                <div className="col-md-12" style={{marginTop: "10px"}}>
                  <Space>
                    <Button onClick={this.handleCancel}>Cancel</Button>
                    <Button type="primary" onClick={this.handleOk}>
                      Apply
                    </Button>
                  </Space>
                </div>
              )}
            </div>

            <div style={{marginTop: "20px"}}>
              <div className="col-md-12">
                <p className="text-center font-14 text-light-black margin-top-15 weight-500 ">
                  {appointmentType === APPT_TYPE_AUDIO ? "1." : "2."} Set
                  different microphone
                </p>
                <select
                  onChange={(v) =>
                    this.setState({audioDeviceId: v.target.value}, () => {
                      console.log("audio devide===>", this.state.audioDeviceId);

                      publisher2
                        .setAudioSource(this.state.audioDeviceId)
                        .then(() => console.log("audio source set"))
                        .catch((error) => console.error(error.name));
                    })
                  }
                  className="form-control"
                  value={this.state.audioDeviceId}
                >
                  {this.state.isOpen &&
                    this.state.audioInputDevices &&
                    this.state.audioInputDevices.length > 1 &&
                    this.state.audioInputDevices.map((v) => {
                      return (
                        <option key={uniqueId("VS")} value={v.deviceId}>
                          {v.label}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div
                style={{marginTop: "10px"}}
                // className="height-180 push-20-b push-50-t padding-60-t text-center"
              >
                <Progress
                  percent={this.state.audiLevel}
                  // percent={50}
                  steps={40}
                  size="large"
                  strokeColor="rgb(46 164 250)"
                  showInfo={false}
                  className="audio-visulizer"
                />
              </div>
              <div className="col-md-12" style={{marginTop: "10px"}}>
                <Space>
                  <Button
                    onClick={() =>
                      this.setState({isOpen: false}, () => {
                        if (
                          publisher2 &&
                          publisher2.destroy &&
                          typeof publisher2.destroy === "function"
                        ) {
                          publisher2.destroy();
                        }
                        this.state.audioFileState.pause();
                      })
                    }
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() =>
                      this.setState({isOpen: false}, () => {
                        publisher
                          .setAudioSource(this.state.audioDeviceId)
                          .then(() => console.log("audio source set"))
                          .catch((error) => console.error(error.name));
                        if (
                          publisher2 &&
                          publisher2.destroy &&
                          typeof publisher2.destroy === "function"
                        ) {
                          publisher2.destroy();
                        }
                        this.state.audioFileState.pause();

                        // console.log("clicked okkkkkkkkkkkkkk==>")
                      })
                    }
                  >
                    Apply
                  </Button>
                </Space>
              </div>
            </div>
            <div style={{marginTop: "20px"}}>
              <p className="text-center font-14 text-light-black margin-top-15 weight-500 ">
                {appointmentType === APPT_TYPE_AUDIO ? "2." : "3."}Test Speakers
              </p>
              {this.state.speakerSupport && (
                <select
                  onChange={(v) =>
                    this.setState(
                      {currentAudioOutputDevice: v.target.value},
                      async () => {
                        console.log(
                          "audio out devide===>",
                          this.state.currentAudioOutputDevice
                        );
                        // await OT.setAudioOutputDevice(
                        //   this.state.currentAudioOutputDevice
                        // );

                        // this.cycleAudioOutput();
                        // publisher2
                        //   .setAudioSource(v.target.value)
                        //   .then(() => console.log("audio out source set"))
                        //   .catch((error) => console.error(error.name));
                      }
                    )
                  }
                  className="form-control"
                  value={this.state.currentAudioOutputDevice}
                >
                  {this.state.isOpen &&
                    this.state.audioOutputDevice &&
                    this.state.audioOutputDevice.length > 1 &&
                    this.state.audioOutputDevice.map((v) => {
                      return (
                        <option key={uniqueId("VS")} value={v.deviceId}>
                          {v.label}
                        </option>
                      );
                    })}
                </select>
              )}
              <div className="height-80 push-20-b push-50-t padding-0-t text-center">
                <button
                  className="btn btn-custom btn-gray width-255 font-14 height-big"
                  onClick={this.toggleAudioPlaying}
                >
                  {this.state.playing ? "Playing..." : "Click To Test"}
                </button>
                <p className="font-13 weight-400 text-light-black text-center push-20-t">
                  Can you hear the sound?
                </p>
              </div>
              {this.state.speakerSupport && (
                <div className="col-md-12" style={{marginTop: "10px"}}>
                  <Space>
                    <Button
                      onClick={() =>
                        this.setState({isOpen: false}, () => {
                          if (
                            publisher2 &&
                            publisher2.destroy &&
                            typeof publisher2.destroy === "function"
                          ) {
                            publisher2.destroy();
                          }
                          this.state.audioFileState.pause();
                        })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      type="primary"
                      onClick={() =>
                        this.setState({isOpen: false}, async () => {
                          await OT.setAudioOutputDevice(
                            this.state.currentAudioOutputDevice
                          );
                          // publisher
                          //   .setAudioSource(this.state.audioDeviceId)
                          //   .then(() => console.log("audio source set"))
                          //   .catch((error) => console.error(error.name));
                          if (
                            publisher2 &&
                            publisher2.destroy &&
                            typeof publisher2.destroy === "function"
                          ) {
                            publisher2.destroy();
                          }
                          this.state.audioFileState.pause();

                          // console.log("clicked okkkkkkkkkkkkkk==>")
                        })
                      }
                    >
                      Apply
                    </Button>
                  </Space>
                </div>
              )}
            </div>
          </Drawer>
        )}

        {this.state.activeStreamsArray.length !== 0 &&
          this.state.visibleDrawer && (
            <Drawer
              title="Members"
              placement={"right"}
              closable={true}
              onClose={() => {
                this.setState({visibleDrawer: false});
              }}
              getContainer={false}
              visible={this.state.visibleDrawer}
              key={"right"}
              className="members_drawer"
              width={315}
            >
              {this.state.activeStreamsArray &&
                this.state.activeStreamsArray.length !== 0 &&
                this.state.activeStreamsArray.map((stream) => {
                  return (
                    <UserItem
                      stream={stream}
                      session={this.state.sessionObj}
                      handleAudioSignal={this.handleAudioSignal}
                      handleScreenShareSignal={this.handleScreenShareSignal}
                      handleChatSignals={this.handleChatSignals}
                    />
                  );
                })}
            </Drawer>
          )}
      </div>
    );
  }
}

export default VideoAppInit;

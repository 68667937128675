import { get } from "./index";

export const getHealthSummary = (params) => {
  const request = {
    subUrl: `/health_summaries`,
    params,
    // isEMDR: true,
    // params: {
    //   authToken: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0aW1lc3RhbXAiOiIyMDIyLTA4LTIzVDA3OjUxOjIzLjUzNyswMDowMCIsImVtYWlsIjoiYWF6aWIuZ2FuZGhpQHlvcG1haWwuY29tIn0.Q4Eh_ytVjdIRdPlwQyCtbkUhclWsl9X850N4S0qsL4I`,
    // },
    // baseURL: addBaseURL,
  };
  return get(request);
};

export const getHealthSummaryDetails = (params) => {
  const request = {
    subUrl: `/show_details`,
    params,
    // isEMDR: true,
    // params: {
    //   ...params,
    //   authToken: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0aW1lc3RhbXAiOiIyMDIyLTA4LTIzVDA3OjUxOjIzLjUzNyswMDowMCIsImVtYWlsIjoiYWF6aWIuZ2FuZGhpQHlvcG1haWwuY29tIn0.Q4Eh_ytVjdIRdPlwQyCtbkUhclWsl9X850N4S0qsL4I`,
    // },
    // baseURL: addBaseURL,
  };
  return get(request);
};

export const getHealthSummaryGraph = (params) => {
  const request = {
    subUrl: `/health_summary_data_points/${params.id}/generate_graph`,
    params,
    // isEMDR: true,
    // params: {
    //   ...params,
    //   authToken: `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0aW1lc3RhbXAiOiIyMDIyLTA4LTIzVDA3OjUxOjIzLjUzNyswMDowMCIsImVtYWlsIjoiYWF6aWIuZ2FuZGhpQHlvcG1haWwuY29tIn0.Q4Eh_ytVjdIRd/PlwQyCtbkUhclWsl9X850N4S0qsL4I`,
    // },
    // baseURL: addBaseURL,
  };
  return get(request);
};

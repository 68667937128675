import React from "react";
import ModalPopUp from "components/common/ModalPopUp";

export default function DeleteAbbreviationModal({
  confirmationModalOpen,
  closeConfirmationModal,
  handleDelete,
  isLoading,
  selectedItem,
}) {
  return (
    <ModalPopUp
      title="Are you sure want to delete abbreviation"
      visible={confirmationModalOpen}
      handleCancel={closeConfirmationModal}
      handleOk={() => handleDelete(selectedItem.id)}
      footer={true}
      loading={isLoading}
      disabled={isLoading}
      okText={"Delete"}
      closable={true}
    ></ModalPopUp>
  );
}

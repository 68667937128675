import { post ,get} from "./index";

export const startEndOfflineCall = (data) => {
  const request = {
    subUrl: `/calls/call_log.json`,
    data,
  };
  return post(request);
};

export const uploadScreenShots = (data) => {
  const request = {
    subUrl: `/screenshots.json`,
    data,
  };
  return post(request);
};

export const addChatVideoHistoryAPI = (data) => {
  const request = {
    subUrl: `/chats.json`,
    data,
  };
  return post(request);
};


export const generateOpentokTokenAPI = (params) => {
  const request = {
    subUrl: `/calls/generate_tokens.json`,
    params,
  };
  return get(request);
};



import React from 'react';

const IconVideoResize = (props) => {
    const { className } = props
    return (
        <svg className={className}  version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path fillRule="evenodd" d="m18 9v6c0 2.8-2.2 5-5 5h-8c-2.8 0-5-2.2-5-5v-6c0-2.8 2.2-5 5-5h8c2.8 0 5 2.2 5 5zm-5.3-0.7c-0.4-0.4-1-0.4-1.4 0l-4.3 4.3v-2.6c0-0.6-0.4-1-1-1-0.6 0-1 0.4-1 1v3c0 1.7 1.3 3 3 3h3c0.6 0 1-0.4 1-1 0-0.6-0.4-1-1-1h-2.6l4.3-4.3c0.4-0.4 0.4-1 0-1.4zm11.3 0v7.3c0 0.8-0.4 1.5-1.1 1.8q-0.4 0.2-0.9 0.2-0.6 0-1.2-0.4c0 0-0.8-0.8-0.8-0.8v-8.9c0 0 0.8-0.7 0.8-0.8 0.6-0.4 1.4-0.5 2.1-0.2 0.7 0.4 1.1 1.1 1.1 1.8z"/>
        </svg>
    )
}

export { IconVideoResize }

import { get, Irequest, post, patch } from "./index";

export type GetProfileParams = {
  id: number,
};

export const getProfileApi = (params: GetProfileParams) => {
  let { id } = params;
  const request: Irequest = {
    subUrl: `/profiles/${id}.json`,
  };
  return get(request);
};

export const updateProfileImageApi = (data) => {
  let { id } = data;

  let formData = new FormData();

  formData.set("id", id);
  formData.set("user[image]", data.image);
  formData.set("user[image_file_name]", data.image_file_name);

  const request: Irequest = {
    subUrl: `/users/${id}.json`,
    data: formData,
  };
  return patch(request);
};

export const updateSignatureApi = (data) => {
  let { id } = data;

  let formData = new FormData();

  formData.set("id", id);
  data.signature && formData.set("profile[signature]", data.signature);

  const request: Irequest = {
    subUrl: `/users/${id}.json`,
    data: formData,
  };
  return patch(request);
};

export const uploadCertificatesApi = (data) => {
  let { id } = data;

  let formData = new FormData();

  if (data.documents && data.documents.length !== 0) {
    data.documents.forEach((doc, index) => {
      formData.set(`document[${index}][file]`, doc.originFileObj);
    });
  }

  formData.set("id", id);

  const request: Irequest = {
    subUrl: `/users/${id}.json`,
    data: formData,
  };
  return patch(request);
};

export const updateProfileApi = (data) => {
  let { id } = data;
  console.log("data", data);
  let reqObj = {
    id: id,
    ...(data.selected_specialities && {
      doctor_speciality_id: data.selected_specialities,
    }),
    user: {
      ...(data.first_name && { first_name: data.first_name }),
      ...(data.last_name && { last_name: data.last_name }),
      ...(data.email && { email: data.email }),
      ...(data.phone && { phone: data.phone }),
      ...(data.languages && { language_ids: data.languages }),
      ...(data.gender && { gender: data.gender }),
    },
    profile: {
      ...(data.designation && { designation: data.designation }),
      ...(data.bio && { bio: data.bio }),
      ...(data.gmc && { gmc: data.gmc }),
      ...(data.nmc && { nmc: data.nmc }),
    },
    address: [
      {
        ...((data.usual_line1 ||
          data.usual_line2 ||
          data.usual_line3 ||
          data.usual_town ||
          data.usual_pincode) && { name: "Usual Address" }),
        ...(data.usual_line1 && { line1: data.usual_line1 }),
        ...(data.usual_line2 && { line2: data.usual_line2 }),
        ...(data.usual_line3 && { line3: data.usual_line3 }),
        ...(data.usual_town && { town: data.usual_town }),
        ...(data.usual_pincode && { pincode: data.usual_pincode }),
        ...(data.usual_country && { country: data.usual_country }),
        ...(data.usual_county && { county: data.usual_county }),
      },
      {
        ...((data.primary_line1 ||
          data.primary_line2 ||
          data.primary_line3 ||
          data.primary_town ||
          data.primary_pincode) && { name: "Permanent Address" }),
        ...(data.primary_line1 && { line1: data.primary_line1 }),
        ...(data.primary_line2 && { line2: data.primary_line2 }),
        ...(data.primary_line3 && { line3: data.primary_line3 }),
        ...(data.primary_town && { town: data.primary_town }),
        ...(data.primary_pincode && { pincode: data.primary_pincode }),
        ...(data.primary_country && { country: data.primary_country }),
        ...(data.primary_county && { county: data.primary_county }),
      },
    ],
  };

  const request: Irequest = {
    subUrl: `/users/${id}.json`,
    data: reqObj,
  };
  return patch(request);
};

export const addCompanyDetailsApi = (data) => {
  let formData = new FormData();

  data.id && formData.set("user_id", data.id);
  data.payment_method && formData.set("payment_method", data.payment_method);
  data.umbrella_company &&
    formData.set("umbrella_company", data.umbrella_company);
  data.payment_type && formData.set("payment_type", data.payment_type);
  data.company_name && formData.set("company_name", data.company_name);
  data.phone && formData.set("phone", data.phone);
  data.email && formData.set("email", data.email);
  formData.set(
    "vat_registered",
    data.vat_registered ? data.vat_registered : false
  );
  formData.set(
    "vat_on_service_fee",
    data.vat_on_service_fee ? data.vat_on_service_fee : false
  );
  data.vat_number && formData.set("vat_number", data.vat_number);
  formData.set("ltd_company", data.ltd_company ? data.ltd_company : false);
  data.company_reg_number &&
    formData.set("company_reg_number", data.company_reg_number);
  data.date_of_incorporate &&
    formData.set("date_of_incorporate", data.date_of_incorporate);
  data.incorporate_certificate &&
    formData.set("incorporate_certificate", data.incorporate_certificate);
  data.payroll_reference &&
    formData.set("payroll_reference", data.payroll_reference);
  formData.set("exp", data.exp ? data.exp : false);

  const request: Irequest = {
    subUrl: `/payment/user_payments/add_user_company_details.json`,
    data: formData,
  };
  return post(request);
};

export const updateCompanyDetailsApi = (data) => {
  let formData = new FormData();

  formData.set("id", data.id);
  data.company_name && formData.set("company_name", data.company_name);
  data.payment_type && formData.set("payment_type", data.payment_type);
  data.payment_method && formData.set("payment_method", data.payment_method);
  data.phone && formData.set("phone", data.phone);
  data.email && formData.set("email", data.email);
  formData.set(
    "vat_registered",
    data.vat_registered ? data.vat_registered : false
  );
  formData.set(
    "vat_on_service_fee",
    data.vat_on_service_fee ? data.vat_on_service_fee : false
  );
  data.vat_number && formData.set("vat_number", data.vat_number);
  formData.set("ltd_company", data.ltd_company ? data.ltd_company : false);
  data.company_reg_number &&
    formData.set("company_reg_number", data.company_reg_number);
  data.date_of_incorporate &&
    formData.set("date_of_incorporate", data.date_of_incorporate);
  data.incorporate_certificate &&
    formData.set("incorporate_certificate", data.incorporate_certificate);
  data.payroll_reference &&
    formData.set("payroll_reference", data.payroll_reference);
  formData.set("exp", data.exp ? data.exp : false);

  const request: Irequest = {
    subUrl: `/payment/user_payments/update_user_company_details.json`,
    data: formData,
  };
  return patch(request);
};

export const addBankDetailsApi = (data) => {
  let formData = new FormData();

  data.user_id && formData.set("user_id", data.user_id);
  data.bank_name && formData.set("bank_name", data.bank_name);
  data.address && formData.set("address", data.address);
  data.postcode && formData.set("postcode", data.postcode);
  data.name_on_bank_account &&
    formData.set("name_on_bank_account", data.name_on_bank_account);
  data.sort_code && formData.set("sort_code", data.sort_code);
  data.account_number && formData.set("account_number", data.account_number);
  data.payment_reference &&
    formData.set("payment_reference", data.payment_reference);
  data.supplier_reference &&
    formData.set("supplier_reference", data.supplier_reference);
  data.iban && formData.set("iban", data.iban);
  data.swift_code && formData.set("swift_code", data.swift_code);
  data.roll_number && formData.set("roll_number", data.roll_number);
  formData.set("exp", data.exp ? data.exp : false);
  data.additional_information &&
    formData.set("additional_information", data.additional_information);
  data.website_profile && formData.set("website_profile", data.website_profile);
  formData.set(
    "payroll_information_authorized",
    data.payroll_information_authorized
      ? data.payroll_information_authorized
      : false
  );

  const request: Irequest = {
    subUrl: `/payment/user_payments/add_user_bank_details.json`,
    data: formData,
  };
  return post(request);
};

export const updateBankDetailsApi = (data) => {
  let formData = new FormData();

  data.id && formData.set("id", data.id);
  data.bank_name && formData.set("bank_name", data.bank_name);
  data.name_on_bank_account &&
    formData.set("name_on_bank_account", data.name_on_bank_account);
  data.account_number && formData.set("account_number", data.account_number);
  data.address && formData.set("address", data.address);
  data.postcode && formData.set("postcode", data.postcode);
  data.sort_code && formData.set("sort_code", data.sort_code);
  data.payment_reference &&
    formData.set("payment_reference", data.payment_reference);
  data.supplier_reference &&
    formData.set("supplier_reference", data.supplier_reference);
  data.iban && formData.set("iban", data.iban);
  data.swift_code && formData.set("swift_code", data.swift_code);
  data.roll_number && formData.set("roll_number", data.roll_number);
  formData.set("exp", data.exp ? data.exp : false);
  data.additional_information &&
    formData.set("additional_information", data.additional_information);
  data.website_profile && formData.set("website_profile", data.website_profile);
  formData.set(
    "payroll_information_authorized",
    data.payroll_information_authorized
      ? data.payroll_information_authorized
      : false
  );

  const request: Irequest = {
    subUrl: `/payment/user_payments/update_user_bank_details.json`,
    data: formData,
  };
  return patch(request);
};

export const listDoctorsSpecialitiesApi = (params) => {
  const request: Irequest = {
    subUrl: `/doctor/doctors/list_specialities.json`,
  };
  return get(request);
};

export const listLanguagesApi = (params) => {
  const request: Irequest = {
    subUrl: `/languages.json`,
    params,
  };
  return get(request);
};

export const getCompanyBankDetailsApi = (params) => {
  const request: Irequest = {
    subUrl: `/payment/user_payments/get_user_company_and_bank_detail.json`,
    params,
  };

  return get(request);
};
export const getAllUsers = (params) => {
  const request: Irequest = {
    subUrl: `/doctor/patients.json`,
    params,
  };
  return get(request);
};
// Pinkal 
// Request URL: https://dev-api.alldaydr.com/api/doctor/patients.json?search=sdf
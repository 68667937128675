import PDFViewer from "components/PdfViewer/PDFViewer";
import React, { Component } from "react";
export default class ComponentToPrint extends Component {
  render() {
    // console.log("this.props.btnRef?.current", this.props.btnRef);
    return (
      <div>
        <div>
          <PDFViewer
            // printPdf={this.props.btnRef}
            pdf={this.props.pdfURL}
            handlePrint={this.props.handlePrint}
          />
        </div>
      </div>
    );
  }
}

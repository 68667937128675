/* eslint-disable jsx-a11y/alt-text */
import {isEmpty} from "lodash";
import React, {Component} from "react";
import {connect} from "react-redux";
import {getConsultationChatAndVideoHistory} from "services/consultations";
import CommonLoader from "../../../components/common/CommonLoader";
import {
  errorToast,
  getErrorObject,
  verifyObject,
} from "../../../utilities/utils";
import isURL from "validator/lib/isURL";
import ReactPlayer from "react-player";
import {Button} from "antd";
import VideoImageThumb from "../../../assets/images/common/video_histroy.png";
import playbtn from "assets/images/common/video_play.svg";
import ModalPopUp from "../../../components/common/ModalPopUp";
import search_blue from "assets/images/common/search-blue.svg";
import upload_blue from "assets/images/common/file_upload.svg";
import {CloseCircleOutlined} from "@ant-design/icons";
import {Scrollbars} from "react-custom-scrollbars";
import {getOnlyChatHistory} from "../../../services/consultations";
class ChatVideoHistoryContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chatHistory: "",
      videoHistory: "",
      chatHistoryArray: [],
      chatHistoryArray2: [],
      videoHistoryArray: [],
      visible: false,
      search: "",
    };
  }
  componentDidMount() {
    this._onRequestList();
  }
  async _onRequestList() {
    let {appointmentId, prev_appt_tab_key} = this.props;
    try {
      await this.setState({isLoading: true});
      let consultationReviewParams = {};
      if (
        prev_appt_tab_key === "reviewed" ||
        prev_appt_tab_key === "in_review"
      ) {
        consultationReviewParams = {
          from_review_section: true,
        };
      }
      let response = await getConsultationChatAndVideoHistory({
        appointment_id: appointmentId,
        ...consultationReviewParams,
      });

      let response2 = await getOnlyChatHistory({
        id: appointmentId,
      });

      this.setState({
        chatHistoryArray: verifyObject(response2, "data.data", []),
        chatHistoryArray2: verifyObject(response2, "data.data", []),
        videoHistory: verifyObject(response, "data.data.video_history", []),
      });

      await this.setState({isLoading: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }
  ref = (player) => {
    this.player = player;
  };

  render() {
    let {
      chatHistory,
      videoHistory,
      isLoading,
      chatHistoryArray,
      chatHistoryArray2,
      visible,
    } = this.state;
    return (
      <div className="padding-40-t">
        <div className="selection_container">
          <div className="chathistorymain">
            {isLoading && <CommonLoader />}

            {!isLoading && (
              <div className="col-md-12 in-block text-left height-100 p-0">
                <div className="row height-100 m-0">
                  <div className="chat-div chathistoryleftsection padding-10-t padding-right-10 border-right padding-15-b ">
                    {
                      <div className="chat-bubble-container">
                        {!isEmpty(chatHistory) && (
                          <div className="relative chatbox_search searchbar_dropdown m-0">
                            <input
                              type="text"
                              name="search"
                              onChange={(e) => {
                                if (!isEmpty(e.target.value)) {
                                  this.setState({
                                    chatHistoryArray:
                                      this.state.chatHistoryArray.filter((d) =>
                                        d.msg_data.includes(e.target.value)
                                      ),
                                  });
                                } else {
                                  this.setState({
                                    chatHistoryArray: chatHistoryArray2,
                                  });
                                }
                              }}
                              className="form-control search-input font-12-imp weight-400 text-dark-gray"
                              placeholder="Search message history"
                            ></input>
                            <img alt="" src={search_blue}></img>
                          </div>
                        )}
                        <Scrollbars className="inner_chathistory">
                          <div
                            // style={{ height: 700 }}
                            className="chat-bubble-content"
                          >
                            {chatHistoryArray &&
                              chatHistoryArray.length === 0 && (
                                <p className="text-dark-blue font-12 text-center weight-400 push-20-t push-20-b">
                                  No chat history available
                                </p>
                              )}

                            {chatHistoryArray.length !== 0 &&
                              chatHistoryArray.map((chat) => {
                                if (chat.msg_send === "right") {
                                  return (
                                    <div className="chat-bubble-right">
                                      {isURL(chat.msg_data) ? (
                                        <a
                                          href={chat.msg_data}
                                          target="__blank"
                                        >
                                          <img
                                            width={100}
                                            src={upload_blue}
                                            alt=""
                                          />
                                        </a>
                                      ) : (
                                        <p>{chat.msg_data}</p>
                                      )}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div className="chat-bubble-left">
                                      {isURL(chat.msg_data) ? (
                                        <a
                                          href={chat.msg_data}
                                          target="__blank"
                                        >
                                          <img
                                            width={100}
                                            src={upload_blue}
                                            alt=""
                                          />
                                        </a>
                                      ) : (
                                        <p>{chat.msg_data}</p>
                                      )}
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </Scrollbars>
                      </div>
                    }
                  </div>

                  <div className="video-div chathistoryrightsection padding-10-t padding-right-10 padding-left-10 padding-15-b">
                    {isEmpty(videoHistory) && (
                      <p className="text-dark-blue font-12 text-center weight-400 push-20-t">
                        No consultation video available
                      </p>
                    )}
                    {!isEmpty(videoHistory) && (
                      <div className="video-div-wrapper">
                        <Button
                          className="video-play-button cursor-pointer no-background"
                          onClick={() => {
                            this.setState({visible: true});
                            if (this.player) {
                              this.player.seekTo(0, "seconds");
                            }
                          }}
                        >
                          <img src={playbtn}></img>
                        </Button>
                        <img src={VideoImageThumb} className="video_image" />
                      </div>
                    )}
                    <ModalPopUp
                      visible={visible}
                      handleCancel={() => this.setState({visible: false})}
                      footer={false}
                      closable={true}
                      closeIcon={
                        <CloseCircleOutlined
                          style={{
                            color: "white",
                            fontSize: "20px",
                            marginBottom: "20px",
                          }}
                          color={"#FFF"}
                        />
                      }
                      className="video_popup"
                      maskClosable={false}
                    >
                      <div>
                        <ReactPlayer
                          ref={this.ref}
                          // pip={false}
                          controls={true}
                          url={videoHistory}
                          playing={visible}
                          height={515}
                          width={680}
                        />
                      </div>
                    </ModalPopUp>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  null
)(ChatVideoHistoryContainer);
export default ConnectedComponent;

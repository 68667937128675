import { get, Irequest } from './index'

export const getAppointmentPaymentsApi = (params) => {
	const request: Irequest = {
		subUrl: `/payments.json`,
		params,
	}
	return get(request)
}

export const getOrdersPaymentsApi = (params) => {
	const request: Irequest = {
		subUrl: `/order_payments.json`,
		params,
	}
	return get(request)
}

export const getDoctorSummaryApi = (params) => {
	const request: Irequest = {
		subUrl: `/payments/doctor_summary.json`,
		params,
	}

	return get(request)
}

import { get, post } from "./index";

export const getSymptompsChecker = (params) => {
  const request = {
    subUrl: `/symptom_checkers/list_for_doctors.json`,
    params,
  };
  return get(request);
};

export const symptompsCheckerReviewByClinician = (data) => {
  const request = {
    subUrl: `/sc_reviews/review_by_clinician`,
    data,
  };
  return post(request);
};

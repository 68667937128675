import React from "react";

export default function FireFoSetup() {
  return (
    <div className="browser_setup firefox_setup">
      <div className="margin-top-80 pt-45">
        <h3 className="font-16 weight-500 text-default-color margin-bottom-15">Allow AllDayDr to access your camera and microphone</h3>
        <ol className="m-0 font-14 weight-400 text-gray">
          <li className="push-10-b">
            Select the lock icon to the left of your address bar. A pop-up will
            appear with a list of options. Select the arrow to the right of
            doctor.alldaydr.com
          </li>
          <li className="push-10-b">Click <span className="weight-500 text-default-color ">More Information.</span> A pop-up will appear.</li>
          <li className="push-10-b">On the top of the pop-up, select button <span className="weight-500 text-default-color ">Permissions.</span></li>
          <li className="push-10-b">
            Find the <span className="weight-500 text-default-color ">Camera</span> and <span className="weight-500 text-default-color ">Microphone</span> option. Uncheck <span className="weight-500 text-default-color ">“Use Default”</span> and
            select <span className="weight-500 text-default-color ">“Allow”</span>.
          </li>
        </ol>
        <div className="setup-img-container mt-40">
          <img
            alt=""
            src={require("../../assets/images/video_call_setup/firefox-1.jpg").default}
          />
        </div>
        {/* <div className="setup-img-container">
          <img
            alt=""
            src={require("../../assets/images/video_call_setup/firefox-2.png")}
          />
        </div>
        <div className="setup-img-container">
          <img
            alt=""
            src={require("../../assets/images/video_call_setup/firefox-3.png")}
          />
        </div> */}
      </div>
    </div>
  );
}

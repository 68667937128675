/* eslint-disable eqeqeq */
import React, {Component, Fragment} from "react";
import {isEmpty, debounce, isArray} from "lodash";
import {
  TYPE_DROPDOWN,
  TYPE_DATEPICKER,
  TYPE_DATE_RANGE_PICKER,
  TYPE_TEXTBOX,
  TYPE_AGE_PICKER,
  TYPE_AGE_RANGE_PICKER,
  TYPE_CORPORATE,
  TYPE_MULTI_DROPDOWN,
  TYPE_DISCOUNT_RULES,
  TYPE_SESSION_PICKER,
  TYPE_HEALTH_CONDITION,
  TYPE_SEARCH_OUTCOME,
} from "../../constants/filterConstants";
import {Multiselect} from "multiselect-react-dropdown";
import {
  CaretUpOutlined,
  SearchOutlined,
  CaretDownOutlined,
} from "@ant-design/icons";
import OnOutsideClick from "react-outclick";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import DatePicker from "react-datepicker";
import moment from "moment";
import {map} from "lodash";
import "react-datepicker/dist/react-datepicker.css";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";
import {withRouter} from "react-router";
import {Button, Slider, Spin} from "antd";
import {Input, Tag, Select} from "antd";
import {CloseCircleFilled} from "@ant-design/icons";
import {history} from "../../app/store";
import {getOrganizationApi} from "../../services/patients";
import {generateFilterReportsAPI} from "services/reports";
import Highlighter from "react-highlight-words";
import {
  fetchHealthConditions,
  fetchOutComes,
} from "services/preConsultConditions";

const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
class FilterComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      filters: {},
      dependantKey: false,
      clearing: false,
      isDateRange: false,
      organizations: [],
      genratingreports: false,
      isFilterApplied: false,
      rules: [],
      selectedRules: [],
      selectedValues: [],
      filteringRecords: false,
      healthConditions: [],
      health_conditionsisLoadmore: false,
      search_outcomesearching: false,
      outComes: [],
      health_conditionsSearch: null,
      search_outcomeSearch: null,
    };

    this.onSearchUpdate = debounce(this.handleSubmit, 1000);
    this.multiselectRef = React.createRef();
    this.multiselectRef2 = React.createRef();
    this.multiselectRefRules = React.createRef();
    this.searchHealthConditionUpdate = debounce(
      this._handlePreConsultSearch,
      1000
    );
  }

  handleModal = () => {
    this.setState({isOpen: true});
  };

  componentDidMount() {
    this.setState(
      {
        isFilterApplied:
          this.props.selectedFilters &&
          Object.keys(this.props.selectedFilters).length !== 0
            ? true
            : false,
        filters: this.props.selectedFilters ? this.props.selectedFilters : {},
      },
      () => {
    console.log("this.props.selectedFilters",this.props.selectedFilters);

      }
    );
    let {setClick} = this.props;
    if (setClick && typeof setClick === "function") {
      setClick(this.clearFormParent);
    }
    if (
      this.props.selectedFilters &&
      "corporate_id" in this.props.selectedFilters
    ) {
      this.GetOrganizations();
    }

    // if (
    //   this.props.selectedFilters &&
    //   "corporate_id" in this.props.selectedFilters
    // ) {
    //   this.GetOrganizations();
    // }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isClearedFilters !== this.props.isClearedFilters) {
      let filters = this.state.filters;

      delete filters["search"];
      delete filters["term"];

      this.setState({filters: filters}, () => {
        this.clearFromIsCleared();
      });
    }
    if(this.props.isBooked !== prevProps.isBooked){
    console.log("prevProps.selectedFilters",prevProps.selectedFilters,this.props.selectedFilters);
       this.setState({filters: this.props.selectedFilters})
    }
    if (prevProps?.preConsultStatus !== this.props.preConsultStatus) {
      let filters = this.state.filters;

      delete filters["search"];
      delete filters["term"];

      this.setState({filters: filters}, () => {
        this.clearFromIsCleared();
      });
    }
    // console.log("")
    if(Object.keys(this.state.filters).length > 0){
      localStorage.setItem("filterState", JSON.stringify(this.state.filters));
    }
    history.listen((record, action) => {
      if (!record.pathname.includes(prevProps.match.path)) {
        localStorage.setItem("filterState", JSON.stringify({}));
      }
    });

    // this.setState({
    //   filters: localStorage.getItem("filterState")
    //     ? { ...JSON.parse(localStorage.getItem("filterState")) }
    //     : {},
    // });
  }
  clearFromIsCleared = () => {
    console.log(
      "when called----------------------------------------------------------------"
    );

    this.setState(
      {
        filters: {},
        discount_rule: [],
        selectedRules: [],
        selectedOrganizations: [],
        isFilterApplied: false,
        selectedValues: [],
        face_to_face: false,
        video: false,
        phone_call: false,
      },
      () => {
        if (this.multiselectRef && this.multiselectRef.current) {
          this.multiselectRef.current.resetSelectedValues();
        }
        if (this.multiselectRef2 && this.multiselectRef2.current) {
          this.multiselectRef2.current.resetSelectedValues();
        }
        if (this.multiselectRefRules && this.multiselectRefRules.current) {
          this.multiselectRefRules.current.resetSelectedValues();
        }
        this.props.resetFilters();
        // this.handleSubmit();
        localStorage.removeItem("intakedFilters");
        localStorage.removeItem("upcomingFilters");
        localStorage.removeItem("previousFilters");
        localStorage.removeItem("approveRejectFilters");
        localStorage.removeItem("intakedFiltersNewQuestionnarie");
        localStorage.removeItem("intakedFiltersPastQuestionnarie");
        localStorage.removeItem("intakedFiltersNewReports");
        localStorage.removeItem("intakedFiltersPastReports");
      }
    );
  };
  componentWillUnmount() {}

  clearFormParent = () => {
    this.setState(
      {
        filters: {},
        discount_rule: [],
        selectedRules: [],
        selectedOrganizations: [],
      },
      async () => {
        if (this.multiselectRef && this.multiselectRef.current) {
          await this.multiselectRef.current.resetSelectedValues();
        }
        if (this.multiselectRef2 && this.multiselectRef2.current) {
          await this.multiselectRef2.current.resetSelectedValues();
        }
        if (this.multiselectRefRules && this.multiselectRefRules.current) {
          await this.multiselectRefRules.current.resetSelectedValues();
        }
        await this.props.resetFilters();
        let filters = this.state.filters;
        delete filters["search"];
        delete filters["term"];

        this.setState({filters});
      }
    );
  };

  // componentWillMount() {
  //   document.addEventListener("mousedown", this.handleOutsideClick, false);
  // }
  // componentWillUnmount() {
  //   document.addEventListener("mousedown", this.handleOutsideClick, false);
  // }
  // handleOutsideClick = (e) => {
  //   if (this.node.contains(e.target)) {
  //     alert("in")
  //   }
  // };
  onClose = () => {
    this.setState({isOpen: false}, () => {
      this.setState({[this.state.dependantKey]: false, filters: {}}, () => {
        this.props.resetFilters();
      });
    });
  };

  GetOrganizations = async () => {
    try {
      await this.setState({loading: true});
      let response = await getOrganizationApi();
      await this.setState({loading: false});
      let organizations = response.data.data;
      if (organizations && organizations.length > 0) {
        this.setState({organizations});
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loading: false});

      errorToast({content: message});
    }
  };

  onFilterChange = (e, key) => {
    if (isEmpty(e.target.value)) {
      let filters = this.state.filters;
      delete filters[key];
      this.setState({
        filters: filters,
      });
      this.handleSubmit();
    } else {
      this.setState({
        isOpen: true,
        filters: {...this.state.filters, [key]: e.target.value},
      });
    }
  };
  _getSessionTypes = () => {
    let {video, face_to_face, phone_call} = this.state;
    let types = [];

    if (video) {
      types.push("video");
    }

    if (face_to_face) {
      types.push("face_to_face");
    }

    if (phone_call) {
      types.push("phone_call");
    }

    return types;
  };
  _updateSessionTypes = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.checked,
        ...(e.target.name === "phone_call" && {
          video: false,
          face_to_face: false,
        }),
        ...(e.target.name === "video" && {
          phone_call: false,
          face_to_face: false,
        }),
        ...(e.target.name === "face_to_face" && {
          phone_call: false,
          video: false,
        }),
      },
      (_) => {
        let sessionTypes = this._getSessionTypes();
        this.setState({
          filters: {
            ...this.state.filters,
            session_type: sessionTypes.toString(),
          },
        });
      }
    );
  };

  handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
    }
    await this.setState({
      filteringRecords: true,
    });

    if (this.props.defaultRequest) {
      await this.setState({isOpen: false, isDateRange: false});
      let patient_id_param = {};
      if (this.props.match.params.pateint_id) {
        if (this.props.fromPayment) {
          patient_id_param = {user_id: this.props.match.params.pateint_id};
        } else {
          if (this.props.fromClinical) {
            patient_id_param = {
              patient_id: this.props.match.params.pateint_id,
              user_id: this.props.match.params.pateint_id,
            };
          } else {
            patient_id_param = {
              patient_id: this.props.match.params.pateint_id,
            };
          }
        }
      }
      let search = {};

      if (this.state.filters.search) {
        if (this.props.tabKey === "prescription_abbreviation") {
          search = {
            term: this.state.filters.search.trim(),
          };
        } else {
          search = {
            search: this.state.filters.search.trim(),
          };
        }
      }

      await this.props.onFilterChange({
        // ,
        filters: {
          ...this.state.filters,
          ...search,
          ...this.props.defaultRequest,
        },
        ...patient_id_param,
      });

      let filterLength = Object.keys(this.state.filters).length;
      await this.setState({
        isFilterApplied: filterLength > 0 ? true : false,
        filters: this.state.filters,
        filteringRecords: false,
      });
    } else {
      // console.log("IN FILTERS 2", this.state.filters)
      await this.setState({isOpen: false, isDateRange: false}, () => {
        // this.onClose();
      });
      let patient_id_param = {};

      if (this.props.match.params.pateint_id) {
        if (this.props.fromPayment) {
          patient_id_param = {user_id: this.props.match.params.pateint_id};
        } else {
          if (this.props.fromClinical) {
            patient_id_param = {
              patient_id: this.props.match.params.pateint_id,
              user_id: this.props.match.params.pateint_id,
            };
          } else {
            patient_id_param = {
              patient_id: this.props.match.params.pateint_id,
            };
          }
        }
      }

      let search = {};
      if (this.state.filters.search) {
        if (this.props.tabKey === "prescription_abbreviation") {
          search = {
            term: this.state.filters.search.trim(),
          };
        } else {
          search = {
            search: this.state.filters.search.trim(),
          };
        }
      }
      let reqObj = {
        filters: {...this.state.filters, ...search},
        ...patient_id_param,
      };

      if (this.props.planId) {
        reqObj = {
          filters: {
            ...reqObj.filters,
            subscription_plan_id: this.props.planId,
          },
        };
      }

      if (this.props.corporateUserType) {
        reqObj = {
          filters: {
            ...reqObj.filters,
            subscription_plan_id: this.props.planId,
            user_type: this.props.corporateUserType,
          },
        };
      }

      await this.props.onFilterChange(reqObj);
      let filterLength = Object.keys(this.state.filters).length;
      await this.setState({
        isFilterApplied: filterLength > 0 ? true : false,
        filteringRecords: false,
      });
    }
    if (
      this.props.saveFilters &&
      typeof this.props.saveFilters === "function"
    ) {
      this.props.saveFilters(this.state.filters);
    }
    //clearing sorting active class
    let nodes = document.querySelectorAll(".push-3-l");
    nodes.forEach((node) => {
      node.classList.remove("is_active_sorting");
    });
  };
  handleAgeSlider = (value, key) => {
    this.setState({
      filters: {
        ...this.state.filters,
        "age_range[min]": value[0],
        "age_range[max]": value[1],
      },
    });
  };

  handleDate = (date, key) => {
    // console.log("DATA", date)

    // console.log("DATE __", date);
    let zoneFilter = {};
    // if (this.props.isApplyZoneFilter) {
    zoneFilter = {zone: time_zone};
    // }
    let filters = this.state.filters;
    if (filters) {
      filters["date_period[start_date]"] &&
        delete filters["date_period[start_date]"];
      filters["date_period[end_date]"] &&
        delete filters["date_period[end_date]"];
      this.setState({filters: filters});
    }
    if (date) {
      this.setState(
        {
          filters: {
            ...this.state.filters,
            [key]: date ? moment(date).format("YYYY-MM-DD") : null,
            ...zoneFilter,
          },
        },
        () => {
          // console.log("state open ------------====>", this.state);
          let filters = this.state.filters;

          delete filters["dob_range[start_date]"];
          delete filters["dob_range[end_date]"];
          delete filters["date_period[start_date]"];
          delete filters["date_period[end_date]"];

          this.setState({filters}, () => {
            console.log("state open ------------====>", this.state);
          });
        }
      );
    } else {
      let filters = this.state.filters;
      delete filters["dob_range[start_date]"];
      delete filters["dob_range[end_date]"];
      delete filters["date_period[start_date]"];
      delete filters["date_period[end_date]"];
      delete filters[key];
      delete filters["zone"];

      this.setState(
        {
          filters: filters,
        },
        () => {
          this.handleSubmit();
        }
      );
    }
  };
  handeDateRange = (date, params, filterKey) => {
    let filters = this.state.filters;
    if (filters) {
      filters["created_at"] && delete filters["created_at"];

      this.setState({filters: filters});
    }
    let zoneFilter = {};
    if (this.props.isApplyZoneFilterForRange) {
      zoneFilter = {zone: time_zone};
    }
    if (date) {
      let element = document.getElementById(
        `filter-date-range-picker${filterKey}`
      );
      if (element) {
        element.classList.add("datepicker-custom");
      }
      // if () {
      if (date) {
        if (date[0]) {
          this.setState(
            {
              filters: {
                // ...this.state.filters,
                [params[0]]: moment(date[0]).format("YYYY-MM-DD"),
                // [params[1]]: moment(date[1]).format("YYYY-MM-DD"),
                ...zoneFilter,
              },
              isDateRange: true,
              // rangeIsNotValid: false,
            },
            () => {
              let filters = this.state.filters;
              delete filters["created_at"];
              this.setState({filters: filters});
            }
          );
        }
        if (date[1]) {
          if (date[0]) {
            this.setState(
              {
                filters: {
                  ...this.state.filters,
                  [params[1]]: moment(date[1]).format("YYYY-MM-DD"),
                  // [params[1]]: moment(date[1]).format("YYYY-MM-DD"),
                  ...zoneFilter,
                },
                isDateRange: true,
                // rangeIsNotValid: false,
              },
              () => {
                let filters = this.state.filters;
                delete filters["created_at"];
                this.setState({filters: filters});
              }
            );
          }
        }
        if (date[0] && date[1]) {
          let date1 = moment(date[0]).format("YYYY-MM-DD");
          let date2 = moment(date[1]).format("YYYY-MM-DD");
          if (moment(date1).isSameOrAfter(date2)) {
            let filters = this.state.filters;
            delete filters[params[0]];
            delete filters[params[1]];

            // errorToast({
            //   content: "Please select valid dates",
            // });
            // console.log("FILTERS", this.state.filters);
            this.setState(
              {
                filters: filters,
                isDateRange: true,
                rangeIsNotValid: true,
              },
              () => {
                let filters = this.state.filters;
                delete filters["created_at"];
                this.setState({filters: filters});
              }
            );
          }
        }
        if (
          date[0] &&
          date[1]
          // moment(date[0]).format("DD-MM-YYYY") >
          // moment(date[1]).format("DD-MM-YYYY")
        ) {
          this.setState(
            {
              filters: {
                ...this.state.filters,
                [params[0]]: moment(date[0]).format("YYYY-MM-DD"),
                [params[1]]: moment(date[1]).format("YYYY-MM-DD"),
                ...zoneFilter,
              },
              isDateRange: true,
              // rangeIsNotValid: false,
            },
            () => {
              let filters = this.state.filters;
              delete filters["created_at"];
              this.setState({filters: filters});
            }
          );
        }
        //   let zoneFilter = {};
        //   if (this.props.isApplyZoneFilterForRange) {
        //     zoneFilter = { zone: time_zone };
        //   }
        //   this.setState(
        //     {
        //       filters: {
        //         ...this.state.filters,
        //         [params[0]]: moment(date[0]).format("YYYY-MM-DD"),
        //         [params[1]]: moment(date[1]).format("YYYY-MM-DD"),
        //         ...zoneFilter,
        //       },
        //       isDateRange: true,
        //     },
        //     () => {
        //       let filters = this.state.filters;
        //       this.setState({ filters: filters });
        //     }
        //   );
        // }
        // else {
        //   // alert("Select greated date");

        //   // errorToast({
        //   //   content: "Please select end date grater than start date",
        //   // });
        // }
        // }
      } else {
        // errorToast({
        //   content: "Please select valid date from selection  ",
        // });
      }
    } else {
      let element = document.getElementById(
        `filter-date-range-picker${filterKey}`
      );
      if (element) {
        element.classList.remove("datepicker-custom");
      }
      let filters = this.state.filters;
      delete filters[params[0]];
      delete filters[params[1]];
      delete filters[`date_period[start_date]`];
      delete filters[`date_period[end_date]`];
      delete filters["scheduled_date"];
      delete filters["created_at"];
      delete filters["taken_on"];
      delete filters["patient_dob"];
      delete filters["options[start_date]"];
      delete filters["options[end_date]"];
      delete filters["zone"];
      this.setState(
        {
          filters: filters,
          isDateRange: true,
        },
        () => {
          this.handleSubmit();
        }
      );
    }

    ////// NEW CODE----
    // if (date) {
    //   console.log("DATE", isDate(date));

    //   if (
    //     date[0] &&
    //     date[1] &&
    //     moment(date[0]).format("DD-MM-YYYY") !==
    //       moment(date[1]).format("DD-MM-YYYY")
    //   ) {
    //     let zoneFilter = {};
    //     // if (this.props.isApplyZoneFilterForRange) {
    //     zoneFilter = { zone: time_zone };
    //     // }
    //     this.setState(
    //       {
    //         filters: {
    //           ...this.state.filters,
    //           [params[0]]: moment(date[0]).format("YYYY-MM-DD"),
    //           [params[1]]: moment(date[1]).format("YYYY-MM-DD"),
    //           ...zoneFilter,
    //         },
    //         isDateRange: true,
    //       },
    //       () => {
    //         let filters = this.state.filters;
    //         delete filters["scheduled_date"];
    //         delete filters["created_at"];
    //         delete filters["taken_on"];
    //         delete filters["patient_dob"];
    //         this.setState({ filters: filters });
    //       }
    //     );
    //   } else {
    //     // alert("Select greated date");

    //     errorToast({
    //       content: "Please select end date grater than start date",
    //     });
    //   }
    // } else {
    //   let filters = this.state.filters;
    //   delete filters[`date_period[start_date]`];
    //   delete filters[`date_period[end_date]`];
    //   delete filters["scheduled_date"];
    //   delete filters["created_at"];
    //   delete filters["taken_on"];
    //   delete filters["patient_dob"];
    //   delete filters["options[start_date]"];
    //   delete filters["options[end_date]"];
    //   delete filters["zone"];
    //   this.setState(
    //     {
    //       filters: filters,
    //       isDateRange: true,
    //     },
    //     () => {
    //       // this.handleSubmit();
    //     }
    //   );
    // }
  };
  renderDropdown = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title} :</label>
            </div>
          </div>
          <div className="col-md-9">
            <select
              onChange={(e) => this.onFilterChange(e, filter.filter_key)}
              className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100"
              placeholder="Select Gender"
              name={filter.filter_key}
              value={
                this.state.filters[filter.filter_key]
                  ? this.state.filters[filter.filter_key]
                  : ""
              }
            >
              <option value="" className="font-13-imp">
                Select {filter.filter_title}
              </option>
              {filter.filter_values &&
                filter.filter_values.map((options, index) => {
                  return (
                    <option value={options.value} key={index}>
                      {options.lable}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </Fragment>
    );
  };

  onMultipleFilterChange = (values, filter_key) => {
    if (values.length === 0) {
      let filters = this.state.filters;
      delete filters[filter_key];
      this.setState({
        filters: filters,
        isOpen: true,
      });
      // console.log("ON Removed");
      // this.handleSubmit();
    } else {
      this.setState({
        filters: {...this.state.filters, [filter_key]: values.toString()},
      });
    }
    this.setState({[filter_key]: values, isOpen: true});
  };
  renderOrganizations = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title}:</label>
            </div>
          </div>
          <div className="col-md-9">
            <Multiselect
              options={this.state.organizations} // Options to display in the dropdown
              // className="form-control"
              name={filter.filter_key}
              ref={this.multiselectRef}
              selectedValues={this.state.selectedOrganizations} // Preselected value to persist in dropdown
              placeholder={
                this.state.filters[filter.filter_key] === undefined
                  ? "Select Corporate"
                  : ""
              }
              className="form-control custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9"
              onSelect={(selectedI, SelectedV) => {
                // console.log("Sele", selectedI, SelectedV)
                let ids = map(selectedI, "id");
                this.setState({selectedOrganizations: selectedI});
                this.onMultipleFilterChange(ids, filter.filter_key);
              }} // Function will trigger on select event
              onRemove={(selectedI, SelectedV) => {
                // console.log("REMo", selectedI, SelectedV)
                let ids = map(selectedI, "id");
                this.setState({selectedOrganizations: selectedI});

                this.setState({isOpen: true});
                this.onMultipleFilterChange(ids, filter.filter_key);
              }} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
        </div>
      </Fragment>
    );
  };

  renderRules = (filter) => {
    let modifiedRules = [];
    this.state.rules.map((d) =>
      modifiedRules.push({id: d.id, name: d.rule_description})
    );
    return (
      <Fragment>
        <div className="form-row align-items-center">
          <div className="col-md-2">
            <div className="position-relative form-group">
              <label htmlFor=""> {filter.filter_title}</label>
            </div>
          </div>
          <div className="col-md-10">
            <div className="position-relative form-group">
              <Multiselect
                options={modifiedRules} // Options to display in the dropdown
                // className="form-control"
                name={filter.filter_key}
                ref={this.multiselectRefRules}
                selectedValues={this.state.selectedRules} // Preselected value to persist in dropdown
                placeholder={`Select ${filter.filter_title}`}
                onSelect={(selectedI, SelectedV) => {
                  let ids = map(selectedI, "id");
                  this.setState({selectedRules: selectedI});
                  this.onMultipleFilterChange(ids, filter.filter_key);
                }}
                // Function will trigger on select event
                onRemove={(selectedI, SelectedV) => {
                  // console.log("REMo", selectedI, SelectedV)
                  let ids = map(selectedI, "id");
                  this.setState({isOpen: true});
                  this.onMultipleFilterChange(ids, filter.filter_key);
                }} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
              />
              {/* <Select
                onChange={(e) => this.onMultipleFilterChange(e, filter.filter_key)}
                className="form-control"
                name={filter.filter_key}
                value={
                  this.state.filters[filter.filter_key]
                    ? this.state.filters[filter.filter_key]
                    : ""
                }
                mode="multiple"
              >
                <Option value={""}>Select {filter.filter_title}</Option>

                {this.state.organizations &&
                  this.state.organizations.map((options) => {
                    return <Option value={options.id}>{options.name}</Option>;
                  })}
              </Select> */}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  renderMultipleDropDown = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title}:</label>
            </div>
          </div>
          <div className="col-md-9">
            <Multiselect
              options={filter.filter_values} // Options to display in the dropdown
              // className="form-control"
              name={filter.filter_key}
              ref={this.multiselectRef2}
              placeholder={
                this.state.filters[filter.filter_key] === undefined
                  ? `Select ${filter.filter_title}`
                  : ""
              }
              selectedValues={this.state.selectedValues}
              onSelect={(selectedI, SelectedV) => {
                let ids = map(selectedI, "id");
                this.setState({selectedValues: selectedI});
                this.onMultipleFilterChange(ids, filter.filter_key);
              }} // Function will trigger on select event
              onRemove={(selectedI, SelectedV) => {
                let ids = map(selectedI, "id");
                this.setState({selectedValues: selectedI});
                this.setState({isOpen: true});
                this.onMultipleFilterChange(ids, filter.filter_key);
              }} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
        </div>
      </Fragment>
    );
  };
  renderAgeDropdown = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title}:</label>
            </div>
          </div>
          <div className="col-md-9">
            <select
              onChange={(e) => this.onFilterChange(e, filter.filter_key)}
              className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100"
              name={filter.filter_key}
              value={
                this.state.filters[filter.filter_key]
                  ? this.state.filters[filter.filter_key]
                  : ""
              }
            >
              <option value={""}>Select {filter.filter_title}</option>
              {filter.filter_values &&
                filter.filter_values.map((options, index) => {
                  return (
                    <option value={options} key={index}>
                      {options}
                    </option>
                  );
                })}
            </select>
          </div>
        </div>
      </Fragment>
    );
  };
  renderAgeRangeSlider = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title}:</label>
            </div>
          </div>
          <div className="col-md-9">
            {/* <div className="form-row align-items-center">
              <div className="col-md-5"> */}
            <Slider
              // tooltipVisible="always"
              range
              min={1}
              max={100}
              value={
                this.state.filters["age_range[min]"] && [
                  this.state.filters["age_range[min]"],
                  this.state.filters["age_range[max]"],
                ]
              }
              defaultValue={[20, 40]}
              onChange={(value) =>
                this.handleAgeSlider(value, filter.filter_key)
              }
            />

            {this.state.filters["age_range[min]"] && (
              <div className="age-min-max-container">
                <span>{this.state.filters["age_range[min]"]} Years</span> -{" "}
                <span>{this.state.filters["age_range[max]"]} Years</span>
              </div>
            )}
            {/* </div> */}
            {/* <div className="col-md-1 text-center">
                <label className="m-0 font-12">to</label>
              </div>
              <div className="col-md-6">
                <DatePicker className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100" />
              </div> */}
            {/* </div> */}
          </div>
        </div>
      </Fragment>
    );
  };
  renderDependantDropdown = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center">
          <div className="col-md-2">
            <div className="position-relative form-group">
              <label htmlFor=""> {filter.filter_title}</label>
            </div>
          </div>
          <div className="col-md-10">
            <div className="position-relative form-group">
              <select
                onChange={(e) => {
                  if (!isEmpty(e.target.value)) {
                    this.setState({
                      [`showDependant${filter.filter_key}`]: true,
                      dependantKey: `showDependant${filter.filter_key}`,
                    });
                  } else {
                    this.setState({
                      [`showDependant${filter.filter_key}`]: false,
                    });
                  }
                  this.onFilterChange(e, filter.filter_key);
                }}
                className="form-control"
                name="doctor"
                value={
                  this.state.filters[filter.filter_key]
                    ? this.state.filters[filter.filter_key]
                    : ""
                }
              >
                <option value="">Select {filter.filter_title}</option>

                {filter.filter_values &&
                  filter.filter_values.map((options, index) => {
                    return (
                      <option value={options.value} key={index}>
                        {options.lable}
                      </option>
                    );
                  })}
              </select>
            </div>
          </div>
        </div>

        {this.state[`showDependant${filter.filter_key}`] &&
          filter.childOptions &&
          filter.childOptions.map((childFilter) => {
            switch (childFilter.element_type) {
              case TYPE_DATE_RANGE_PICKER:
                return this.renderDateRangePicker(childFilter);
              default:
                break;
            }
            return null;
          })}
      </Fragment>
    );
  };

  renderDatePicker = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title}:</label>
            </div>
          </div>
          <div className="col-md-9">
            <DatePicker
              showYearDropdown
              showMonthDropdown
              todayButton="Today"
              dateFormat="dd-MM-yyyy"
              isClearable={true}
              placeholderText={`DD-MM-YYYY`}
              // className="date-range-1111"
              className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100"
              selected={
                this.state.filters[filter.filter_key] &&
                new Date(this.state.filters[filter.filter_key])
              }
              onMonthChange={() => {
                this.setState({isOpen: true});
              }}
              onYearChange={() => {
                this.setState({isOpen: true});
              }}
              onBlur={(date) => {
                // console.log("DATA", date)
              }}
              onChange={(date) => {
                this.handleDate(date, filter.filter_key);
              }}
            />
            {/* <DatePicker className="custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9 w-100" /> */}
          </div>
        </div>
      </Fragment>
    );
  };

  renderTextBox = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title}:</label>
            </div>
          </div>
          <div className="col-md-9">
            <Input
              type="text"
              placeholder={`Enter ${filter.filter_title}`}
              name={filter.filter_key}
              value={
                this.state.filters[filter.filter_key]
                  ? this.state.filters[filter.filter_key]
                  : ""
              }
              onChange={(e) => {
                if (isEmpty(e.target.value)) {
                  let filters = this.state.filters;
                  delete filters[filter.filter_key];
                  this.setState(
                    {
                      filters: filters,
                    },
                    () => {
                      // this.handleSubmit();
                    }
                  );
                } else {
                  this.setState({
                    filters: {
                      ...this.state.filters,
                      [filter.filter_key]: e.target.value,
                    },
                  });
                }
              }}
              className="form-control custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9"
            />
          </div>
        </div>
      </Fragment>
    );
  };
  renderDateRangePicker = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title}:</label>
            </div>
          </div>
          <div className="col-md-9">
            <div id={`filter-date-range-picker${filter.filter_key}`}>
              <DateRangePicker
                // clearIcon={null}
                clearIcon={
                  this.state.filters[`${filter.filter_key}[start_date]`] ===
                  undefined ? null : (
                    <CloseCircleFilled
                      style={{
                        marginBottom: "4px",
                        marginRight: "1px",
                        color: "#2072bb",
                      }}
                    />
                  )
                }
                dayPlaceholder="DD"
                monthPlaceholder="MM"
                yearPlaceholder="YYYY"
                rangeDivider="To"
                dateDisplayFormat="DD-MM-YYYY"
                format="dd-MM-yyyy"
                showLeadingZeros={true}
                onClickMonth={async () => {
                  await this.setState({isDateRange: true});
                }}
                // disableCalendar={true}
                onClickYear={() => {
                  this.setState({isDateRange: true});
                }}
                style={{
                  width: "100%",
                  background: "#f7f7f7",
                }}
                onChange={(date) =>
                  this.handeDateRange(date, filter.params, filter.filter_key)
                }
                className="date-range-1111"
                value={[
                  this.state.filters[`${filter.filter_key}[start_date]`]
                    ? new Date(
                        this.state.filters[`${filter.filter_key}[start_date]`]
                      )
                    : null,
                  this.state.filters[`${filter.filter_key}[end_date]`]
                    ? new Date(
                        this.state.filters[`${filter.filter_key}[end_date]`]
                      )
                    : null,
                ]}
              />
            </div>
          </div>
        </div>
        {/* <div
          ref={(node) => (this.node = node)}
          className="form-row align-items-center"
        >
          <div className="col-md-2">
            <div className="position-relative form-group">
              <label htmlFor=""> {filter.filter_title}</label>
            </div>
          </div>
          <div className="col-md-10">
            <div className="position-relative form-group">
             

        
            </div>
          </div>
        </div> */}
        {/* {"".to} */}
        {/* <div className="row ">
          <div className="font-16 weight-500 text-dark-gray  push-20-b padding-right-15 ">
            {filter.filter_title}
          </div>
        </div>
        <div className="row">
          <div className="push-20-b ">
            <RangePicker
              style={{
                width: "428px",
                height: "49px",
              }}
              defaultValue={
                this.state.filters[`${filter.filter_key}[start_date]`] && [
                  moment(
                    Date.parse(
                      this.state.filters[`${filter.filter_key}[start_date]`]
                    )
                  ),
                  moment(
                    Date.parse(
                      this.state.filters[`${filter.filter_key}[end_date]`]
                    )
                  ),
                ]
              }
              value={
                this.state.filters[`${filter.filter_key}[start_date]`] && [
                  moment(
                    Date.parse(
                      this.state.filters[`${filter.filter_key}[start_date]`]
                    )
                  ),
                  moment(
                    Date.parse(
                      this.state.filters[`${filter.filter_key}[end_date]`]
                    )
                  ),
                ]
              }
              onChange={(date, dateString) =>
                this.handeDateRange(date, dateString, filter.params)
              }
            />
          </div>
        </div> */}
      </Fragment>
    );
  };
  // onSearch = (e) => {
  //   let { value } = e.target;

  //   this.setState({ filters: { ...this.state.filters, search: value } });
  // };
  clearFilters = () => {
    this.setState(
      {
        filters: {},
        selectedOrganizations: [],
        selectedRules: [],
        discount_rule: [],
      },
      async () => {
        await this.props.resetFilters();
        let filters = this.state.filters;
        delete filters["search"];
        delete filters["term"];
        this.setState({filters: filters}, () => {
          this.handleSubmit();
        });
      }
    );
  };
  // GenrateFilterReports = async (e) => {
  //   e.preventDefault();

  //   let filters = this.state.filters;
  //   if (this.props.defaultRequest) {
  //     await this.setState({ isOpen: false, isDateRange: false }, () => {});
  //     let search = {};
  //     if (this.state.filters.search) {
  //       search = {
  //         search: this.state.filters.search.trim(),
  //       };
  //     }

  //     try {
  //       await this.setState({ genratingreports: true });
  //       let patientRoleParams = {};
  //       if (this.props.filter_report_type === "User") {
  //         patientRoleParams = {
  //           role: "patient",
  //         };
  //       }
  //       // const response = await generateFilterReportsAPI({
  //       //   ...filters,
  //       //   ...search,
  //       //   ...patientRoleParams,
  //       //   filter_type: this.props.filter_report_type
  //       //     ? this.props.filter_report_type
  //       //     : "",
  //       //   ...this.props.defaultRequest,
  //       // });

  //       await this.setState({ genratingreports: false });
  //       await successToast({ content: response.data.message });
  //     } catch (error) {
  //       const { message } = getErrorObject(error);
  //       await this.setState({ genratingreports: false });

  //       errorToast({ content: message });
  //     }
  //     // await this.props.onFilterChange({
  //     //   ...this.props.defaultRequest,
  //     //   filters: { ...filters, ...search },
  //     //   ...patient_id_param,
  //     // });
  //   } else {
  //     await this.setState({ isOpen: false, isDateRange: false }, () => {
  //       // this.onClose();
  //     });
  //     // let patient_id_param = {};

  //     // if (this.props.match.params.pateint_id) {
  //     //   if (this.props.fromPayment) {
  //     //     patient_id_param = { user_id: this.props.match.params.pateint_id };
  //     //   } else {
  //     //     if (this.props.fromClinical) {
  //     //       patient_id_param = {
  //     //         patient_id: this.props.match.params.pateint_id,
  //     //         user_id: this.props.match.params.pateint_id,
  //     //       };
  //     //     } else {
  //     //       patient_id_param = {
  //     //         patient_id: this.props.match.params.pateint_id,
  //     //       };
  //     //     }
  //     //   }
  //     // }

  //     let search = {};
  //     if (this.state.filters.search) {
  //       search = { search: this.state.filters.search.trim() };
  //     }
  //     try {
  //       await this.setState({ genratingreports: true });
  //       const response = await generateFilterReportsAPI({
  //         ...filters,
  //         ...search,
  //         filter_type: this.props.filter_report_type
  //           ? this.props.filter_report_type
  //           : "",
  //       });

  //       await this.setState({ genratingreports: false });
  //       await successToast({ content: response.data.message });
  //     } catch (error) {
  //       const { message } = getErrorObject(error);
  //       await this.setState({ genratingreports: false });

  //       errorToast({ content: message });
  //     }
  //     // await this.props.onFilterChange({
  //     //   filters: { ...filters, ...search },
  //     //   ...patient_id_param,
  //     // });
  //   }
  // };
  GenrateFilterReports = async (e) => {
    e.preventDefault();

    let filters = this.state.filters;
    if (this.props.defaultRequest) {
      await this.setState({isOpen: false, isDateRange: false}, () => {});
      let search = {};
      if (this.state.filters.search) {
        if (this.props.tabKey === "prescription_abbreviation") {
          search = {
            term: this.state.filters.search.trim(),
          };
        } else {
          search = {
            search: this.state.filters.search.trim(),
          };
        }
      }

      try {
        await this.setState({genratingreports: true});
        let patientRoleParams = {};
        if (this.props.filter_report_type === "User") {
          patientRoleParams = {
            role: "patient",
          };
        }
        const response = await generateFilterReportsAPI({
          ...this.props.defaultRequest,
          ...filters,
          ...search,
          ...patientRoleParams,
          filter_type: this.props.filter_report_type
            ? this.props.filter_report_type
            : "",
        });

        await this.setState({genratingreports: false});
        await successToast({content: response.data.message});
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({genratingreports: false});

        errorToast({content: message});
      }
      // await this.props.onFilterChange({
      //   ...this.props.defaultRequest,
      //   filters: { ...filters, ...search },
      //   ...patient_id_param,
      // });
    } else {
      await this.setState({isOpen: false, isDateRange: false}, () => {
        // this.onClose();
      });
      // let patient_id_param = {};

      // if (this.props.match.params.pateint_id) {
      //   if (this.props.fromPayment) {
      //     patient_id_param = { user_id: this.props.match.params.pateint_id };
      //   } else {
      //     if (this.props.fromClinical) {
      //       patient_id_param = {
      //         patient_id: this.props.match.params.pateint_id,
      //         user_id: this.props.match.params.pateint_id,
      //       };
      //     } else {
      //       patient_id_param = {
      //         patient_id: this.props.match.params.pateint_id,
      //       };
      //     }
      //   }
      // }

      let search = {};
      if (this.state.filters.search) {
        if (this.props.tabKey === "prescription_abbreviation") {
          search = {
            term: this.state.filters.search.trim(),
          };
        } else {
          search = {
            search: this.state.filters.search.trim(),
          };
        }
      }
      try {
        await this.setState({genratingreports: true});
        const response = await generateFilterReportsAPI({
          ...filters,
          ...search,
          filter_type: this.props.filter_report_type
            ? this.props.filter_report_type
            : "",
        });

        await this.setState({genratingreports: false});
        await successToast({content: response.data.message});
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({genratingreports: false});

        errorToast({content: message});
      }
      // await this.props.onFilterChange({
      //   filters: { ...filters, ...search },
      //   ...patient_id_param,
      // });
    }
  };
  renderSessionPicker = (filter) => {
    return (
      <Fragment>
        <div className="form-row align-items-center margin-bottom-10">
          <div className="col-md-3">
            <div className="position-relative text-right">
              <label className="m-0 font-12">{filter.filter_title} :</label>
            </div>
          </div>

          {/* { lable: 'Face-2-Face', value: 'face_to_face' },
			{ lable: 'Video Call', value: 'video' },
			{ lable: 'Phone Call', value: 'phone_call' }, */}
          <div className="col-md-9">
            <div className="session_btn">
              <label
                className={`font-14 btn ${
                  this.state.video ? "btn-blue" : "btn-border"
                } btn-custom d-inline-block`}
                htmlFor="video"
              >
                <input
                  type="checkbox"
                  name="video"
                  id="video"
                  onClick={this._updateSessionTypes}
                  className="d-none"
                />
                Video Call
              </label>
              <label
                className={`font-14 btn ${
                  this.state.phone_call ? "btn-blue" : "btn-border"
                } btn-custom d-inline-block`}
                htmlFor="phone_call"
              >
                <input
                  type="checkbox"
                  name="phone_call"
                  id="phone_call"
                  className="d-none"
                  onClick={this._updateSessionTypes}
                />
                Phone Call
              </label>
              <label
                className={`font-14 btn ${
                  this.state.face_to_face ? "btn-blue" : "btn-border"
                } btn-custom d-inline-block`}
                htmlFor="face_to_face"
              >
                <input
                  onClick={this._updateSessionTypes}
                  type="checkbox"
                  name="face_to_face"
                  className="d-none"
                  id="face_to_face"
                />
                Face to Face
              </label>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  _handleTagClose = async (key) => {
    if (key === "corporate_id") {
      await this.setState(
        (state) => {
          let allFilters = state.filters;
          delete allFilters[key];
          return {filters: allFilters, selectedOrganizations: []};
        },
        () => {
          this.handleSubmit();
        }
      );
    } else if (
      key === "date_period[start_date]" ||
      key === "date_period[end_date]" ||
      key === "date_of_birth[start_date]" ||
      key === "date_of_birth[end_date]"
    ) {
      await this.setState(
        (state) => {
          let allFilters = state.filters;
          delete allFilters["date_period[start_date]"];
          delete allFilters["date_period[end_date]"];
          delete allFilters["date_of_birth[end_date]"];
          delete allFilters["date_of_birth[start_date]"];
          delete allFilters["zone"];

          return {filters: allFilters};
        },
        () => {
          this.handleSubmit();
        }
      );
    } else {
      await this.setState(
        (state) => {
          let allFilters = state.filters;
          delete allFilters[key];
          delete allFilters["zone"];

          return {filters: allFilters};
        },
        () => {
          this.handleSubmit();
        }
      );
    }
  };

  _getHealthConditions = async (isLoadMore, type) => {
    try {
      let response = await fetchHealthConditions({});
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let healthConditions = verifyObject(response, "data.data", []);
      this.setState({
        healthConditions: isLoadMore
          ? [...this.state.healthConditions, ...healthConditions]
          : healthConditions,
        searching: false,
        pagination: pagination,
        [`${type}searching`]: false,
        [`${type}isLoadmore`]: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({
        searching: false,
        [`${type}searching`]: false,
        [`${type}isLoadmore`]: false,
      });
      errorToast({content: message});
    }
  };

  _getOutComes = async (isLoadMore, type) => {
    try {
      let response = await fetchOutComes({});
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let outComes = verifyObject(response, "data.data", []);
      this.setState({
        outComes: isLoadMore
          ? [...this.state.healthConditions, ...outComes]
          : outComes,
        searching: false,
        pagination: pagination,
        [`${type}searching`]: false,
        [`${type}isLoadmore`]: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({
        searching: false,
        [`${type}searching`]: false,
        [`${type}isLoadmore`]: false,
      });
      errorToast({content: message});
    }
  };
  _handlePreConsultSearch = async (value, type) => {
    console.log("🚀 ~ file: FilterComponent.js:1679 ~ type:", type);

    if (!isEmpty(value)) {
      this.setState(
        {
          page: 1,
          [`${type}searching`]: true,
          [`${type}isLoadmore`]: false,
          [`${type}Search`]: value,
        },
        async () => {
          if (type === "health_conditions") {
            this._getHealthConditions(false, type);
          } else {
            this._getOutComes(false, type);
          }
        }
      );
    } else {
      await this.setState({healthConditions: [], outComes: []});
    }
  };
  fetchMoreData = async (type) => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          [`${type}searching`]: true,
          [`${type}isLoadmore`]: true,
        };
      },
      async () => {
        if (type === "health_conditions") {
          this._getHealthConditions(false, type);
        } else {
          this._getOutComes(false, type);
        }
      }
    );
  };

  handleScroll = (e, type) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    let {
      page,
      pagination: {total_pages},
    } = this.state;
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData(type);
    }
  };

  _handleMultiDropDownChange = (values, filter_key) => {
    console.log(
      "🚀 ~ file: FilterComponent.js:1736 ~ values:",
      values,
      filter_key
    );
    let ids = values?.map((d) => d.split("*")[0]);
    console.log("🚀 ~ file: FilterComponent.js:1738 ~ ids:", ids);
    let lables = values?.map((d) => d.split("*")[1]);
    console.log("🚀 ~ file: FilterComponent.js:1740 ~ lables:", lables);

    if (values.length === 0) {
      let filters = this.state.filters;
      delete filters[filter_key];
      this.setState({
        filters: filters,
        isOpen: true,
      });
      // console.log("ON Removed");
      // this.handleSubmit();
    } else {
      this.setState({
        [`${filter_key}_labels`]: lables.toString(),
        filters: {
          ...this.state.filters,
          [filter_key]: ids.toString(),
        },
      });
    }
    this.setState({[filter_key]: values, isOpen: true});
  };

  renderHealthConditions = (filter) => {
    return (
      <div className="form-row align-items-center margin-bottom-10">
        <div className="col-md-3">
          <div className="position-relative text-right">
            <label className="m-0 font-12">{filter.filter_title}:</label>
          </div>
        </div>
        <div className="col-md-9">
          <Select
            className="form-control custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9"
            name="healthConditions"
            // onPopupScroll={this.handleScroll}
            placeholder="Select Health Condition"
            allowClear={
              this.state[`${filter.filter_key}searching`]
                ? false
                : this.state.healthConditions.length === 0
                ? false
                : true
            }
            value={this.props.snomedCodeId}
            mode="multiple"
            showSearch={true}
            onChange={(value) =>
              this._handleMultiDropDownChange(value, filter.filter_key)
            }
            onSearch={(v) =>
              // this.searchHealthConditionUpdate(v, filter.filter_key)
              {
                if (isEmpty(v)) {
                  this._getHealthConditions();
                } else {
                  this.setState({
                    healthConditions: this.state.healthConditions.filter((d) =>
                      d.name.includes(v)
                    ),
                  });
                }
              }
            }
            listItemHeight={10}
            listHeight={150}
            suffixIcon={
              !this.state[`${filter.filter_key}isLoadmore`] &&
              this.state[`${filter.filter_key}searching`] && (
                <Spin size="small" />
              )
            }
            notFoundContent={
              !this.state[`${filter.filter_key}isLoadmore`] &&
              this.state.healthConditions.length === 0 && (
                <span>No conditions available</span>
              )
            }
            filterOption={false}
            loading={this.state[`${filter.filter_key}searching`]}
            dropdownRender={(menus) => {
              return (
                <div>
                  {menus}
                  {this.state[`${filter.filter_key}isLoadmore`] &&
                    !this.state[`${filter.filter_key}searching`] && (
                      <div
                        style={{
                          textAlign: "center",
                          padding: 10,
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                </div>
              );
            }}
          >
            {this.state.healthConditions.map((k) => {
              return (
                <Select.Option value={`${k.id}*${k.name}`} key={k.id}>
                  <Highlighter
                    highlightClassName="search-highlighter"
                    searchWords={[this.state[`${filter.filter_key}Search`]]}
                    autoEscape={true}
                    textToHighlight={
                      verifyObject(k, "name", null) !== null ? `${k.name}` : `-`
                    }
                  />
                </Select.Option>
              );
            })}
          </Select>
        </div>
      </div>
    );
  };
  renderOutComes = (filter) => {
    console.log("FILTERS---", this.state.filters);
    return (
      <div className="form-row align-items-center margin-bottom-10">
        <div className="col-md-3">
          <div className="position-relative text-right">
            <label className="m-0 font-12">{filter.filter_title}:</label>
          </div>
        </div>
        <div className="col-md-9">
          <Select
            className="form-control custom-input font-13-imp margin-0 input-sm-30 input-bg-f9f9f9"
            name="outComes"
            // onPopupScroll={this.handleScroll}
            placeholder="Select OutCome"
            allowClear={
              this.state[`${filter.filter_key}searching`]
                ? false
                : this.state.healthConditions.length === 0
                ? false
                : true
            }
            // value={this.props.snomedCodeId}
            // multiple={true}
            showSearch={true}
            onChange={(value) =>
              this._handleMultiDropDownChange(value, filter.filter_key)
            }
            onSearch={(v) =>
              // this.searchHealthConditionUpdate(v, filter.filter_key)
              {
                if (isEmpty(v)) {
                  this._getOutComes();
                } else {
                  this.setState({
                    outComes: this.state.outComes.filter((d) =>
                      d.pre_consult_outcome.includes(v)
                    ),
                  });
                }
              }
            }
            // onSearch={(v) =>
            //   this.searchHealthConditionUpdate(v, filter.filter_key)
            // }
            mode="multiple"
            listItemHeight={10}
            listHeight={150}
            suffixIcon={
              !this.state[`${filter.filter_key}isLoadmore`] &&
              this.state[`${filter.filter_key}searching`] && (
                <Spin size="small" />
              )
            }
            notFoundContent={
              !this.state[`${filter.filter_key}searching`] &&
              this.state.outComes.length === 0 && (
                <span>No outcome(s) available</span>
              )
            }
            filterOption={false}
            loading={this.state[`${filter.filter_key}searching`]}
            dropdownRender={(menus) => {
              return (
                <div>
                  {menus}
                  {this.state[`${filter.filter_key}isLoadmore`] &&
                    !this.state[`${filter.filter_key}searching`] && (
                      <div
                        style={{
                          textAlign: "center",
                          padding: 10,
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                </div>
              );
            }}
          >
            {this.state.outComes.map((k) => {
              if (!isEmpty(k.pre_consult_outcome)) {
                return (
                  <Select.Option
                    value={`${k.pre_consult_outcome}`}
                    key={k.id}
                    style={{
                      backgroundColor: k.color_code,
                      color: k.color_code ? "#fff" : "black",
                    }}
                  >
                    <Highlighter
                      highlightClassName="search-highlighter"
                      searchWords={[this.state[`${filter.filter_key}Search`]]}
                      autoEscape={true}
                      textToHighlight={
                        verifyObject(k, "pre_consult_outcome", null) !== null
                          ? `${k.pre_consult_outcome}`
                          : `-`
                      }
                    />
                  </Select.Option>
                );
              }
              return null;
            })}
          </Select>
        </div>
      </div>
    );
  };
  render() {
    const isLoading = this.props.isLoading;
    let {filterArray} = this.props;
    console.log("FILTERS----", this.state.filters);
    let filtersTobeDisplayed = [];
    if (this.state.filters && Object.keys(this.state.filters).length !== 0) {
      Object.keys(this.state.filters).map((key) => {
        let corporateNames = [];
        if (this.state.filters["corporate_id"]) {
          let splitedArray = this.state.filters["corporate_id"].split(",");
          if (splitedArray && splitedArray.length !== 0) {
            splitedArray.map((id) => {
              let found = this.state.organizations.find((d) => d.id == id);
              if (found) {
                corporateNames.push(found.name);
              }
              return null;
            });
          }
        }
        if (key === "corporate_id") {
          filtersTobeDisplayed.push({corporate_id: corporateNames});
        }
        return null;
      });
    }
    // console.log("FILTERS");
    return (
      <div className="filter_with_btn">
        {Object.keys(this.state.filters).length !== 0 &&
          this.props.filter_report_type &&
          /^(?!\s+$)[A-Za-zăâîșțĂÂÎȘȚ\s-]+$/.test(
            this.state.filters && this.state.filters.search
          ) && (
            <button
              onClick={(e) => {
                this.setState({isOpen: true});
                this.GenrateFilterReports(e);
              }}
              disabled={
                this.state.genratingreports ||
                this.state.filteringRecords ||
                (this.props.dataArray && this.props.dataArray.length === 0)
              }
              className="font-13 blue-btn weight-400 height-34 btn btn-primary min-width-180px push-10-r"
            >
              {this.state.genratingreports
                ? "Generating Reports..."
                : "Generate Filter Reports"}
            </button>
          )}
        <OnOutsideClick
          onOutsideClick={(ev) => {
            if (this.state.isDateRange) {
              this.setState({
                isOpen: true,
                // isDateRange: false
              });
            } else {
              // this.setState({isOpen: false}, () => {
              //   console.log(
              //     "called from outside condition-================================================================"
              //   );
              // });
            }
          }}
        >
          {/* {filterArray.length} */}
          <div
            // className={`dis-flex flex-wrap justify-content-end align-items-center ${
            //   filterArray.length > 2 ? "filter-full-width" : "filter-half-width"
            // }`}
            className="search-with-filterdropdown"
          >
            {this.state.isFilterApplied && (
              <button
                onClick={(e) =>
                  this.setState(
                    {
                      filters: {},
                      discount_rule: [],
                      selectedRules: [],
                      selectedOrganizations: [],
                      isFilterApplied: false,
                      selectedValues: [],
                      face_to_face: false,
                      video: false,
                      phone_call: false,
                    },
                    () => {
                      if (this.multiselectRef && this.multiselectRef.current) {
                        this.multiselectRef.current.resetSelectedValues();
                      }
                      if (
                        this.multiselectRef2 &&
                        this.multiselectRef2.current
                      ) {
                        this.multiselectRef2.current.resetSelectedValues();
                      }
                      if (
                        this.multiselectRefRules &&
                        this.multiselectRefRules.current
                      ) {
                        this.multiselectRefRules.current.resetSelectedValues();
                      }
                      this.props.resetFilters();
                      this.handleSubmit();
                      localStorage.removeItem("intakedFilters");
                      localStorage.removeItem("upcomingFilters");
                      localStorage.removeItem("previousFilters");
                      localStorage.removeItem("approveRejectFilters");
                      localStorage.removeItem("intakedFiltersNewQuestionnarie");
                      localStorage.removeItem(
                        "intakedFiltersPastQuestionnarie"
                      );
                      localStorage.removeItem("intakedFiltersNewReports");
                      localStorage.removeItem("intakedFiltersPastReports");
                    }
                  )
                }
                className="clear_btn"
              >
                Clear
              </button>
            )}
            <div
              className={`search-filter-downdown-wrapper ${
                this.props.searchOnly ? "search-only" : ""
              }`}
            >
              <div className="search-filter-downdown-input">
                {/* <Popover
                  content={<span>Search By Name,Email and Phone No</span>}
                  trigger="hover"
                  placement="left"
                  className="prev_imgover"
                >
                  <InfoCircleFilled
                    style={{
                      color: "#1445b7",
                      marginTop: "8px",
                      marginRight: "7px",
                      fontSize: "18px",
                    }}
                  />
                </Popover> */}
                {/* {this.state.filters["search"] || ""} */}
                <Input
                  // className="w-100"
                  placeholder={
                    this.props.searchPlaceholder
                      ? this.props.searchPlaceholder
                      : "Search Here"
                  }
                  // suffix={<div></div>}
                  prefix={
                    <div>
                      <SearchOutlined />
                      {this.state.isFilterApplied &&
                        Object.keys(this.state.filters).length !== 0 &&
                        Object.keys(this.state.filters).map((key) => {
                          console.log(
                            "🚀 ~ file: FilterComponent.js:2200 ~ Object.keys ~ key:",
                            key
                          );

                          if (
                            key !== "search" &&
                            key !== "zone" &&
                            key !== "term"
                          ) {
                            if (key === "date_of_birth[start_date]") {
                              return (
                                <Tag
                                  onClose={async (e) => {
                                    await e.preventDefault();
                                    await this._handleTagClose(key);
                                  }}
                                  closable
                                  className="text-capitalize custom-tag-filter"
                                  color="blue"
                                >
                                  Date Of birth Period :{" "}
                                  {
                                    this.state.filters[
                                      `date_of_birth[start_date]`
                                    ]
                                  }{" "}
                                  ,{" "}
                                  {
                                    this.state.filters[
                                      `date_of_birth[end_date]`
                                    ]
                                  }
                                </Tag>
                              );
                            } else if (key === "date_period[start_date]") {
                              return (
                                <Tag
                                  onClose={async (e) => {
                                    await e.preventDefault();
                                    await this._handleTagClose(key);
                                  }}
                                  closable
                                  className="text-capitalize custom-tag-filter"
                                  color="blue"
                                >
                                  Date Period :{" "}
                                  {
                                    this.state.filters[
                                      `date_period[start_date]`
                                    ]
                                  }{" "}
                                  ,{" "}
                                  {this.state.filters[`date_period[end_date]`]}
                                </Tag>
                              );
                            } else if (
                              key === "health_conditions" ||
                              key === "search_outcome"
                            ) {
                              // ${filter_key}_labels
                              console.log(
                                "RENDER INPUT---",
                                this.state?.[`${key}_labels`],
                                this.state
                              );
                              return (
                                <Tag
                                  onClose={async (e) => {
                                    await e.preventDefault();
                                    await this._handleTagClose(key);
                                  }}
                                  closable
                                  className="text-capitalize custom-tag-filter"
                                  color="blue"
                                >
                                  {key.replace("_", " ").toLowerCase()} :{" "}
                                  {key === "search_outcome"
                                    ? this.state.filters[key]
                                    : this.state?.[`${key}_labels`]}
                                </Tag>
                              );
                            } else if (
                              key !== "date_period[end_date]" &&
                              key !== "date_of_birth[end_date]"
                            ) {
                              return (
                                <Tag
                                  onClose={async (e) => {
                                    await e.preventDefault();
                                    await this._handleTagClose(key);
                                  }}
                                  closable
                                  className="text-capitalize custom-tag-filter"
                                  color="blue"
                                >
                                  {key === "corporate_id"
                                    ? "Corporate"
                                    : key.replace("_", " ").toLowerCase()}{" "}
                                  :{" "}
                                  {key === "corporate_id"
                                    ? isArray(filtersTobeDisplayed) &&
                                      filtersTobeDisplayed.length > 0 &&
                                      filtersTobeDisplayed[0][`corporate_id`] &&
                                      filtersTobeDisplayed[0][
                                        `corporate_id`
                                      ].toString()
                                    : this.state.filters[key]}{" "}
                                </Tag>
                              );
                            }
                          }
                          return null;
                        })}
                    </div>
                  }
                  // defaultValue={this.state.filters["search"] || ""}
                  value={
                    this.props.tabKey === "prescription_abbreviation"
                      ? this.state.filters["term"] || ""
                      : this.state.filters["search"] || ""
                  }
                  onChange={async (e) => {
                    let value = e.target.value || "";
                    let regx =
                      /^(?:[A-Za-zăâîșțĂÂÎȘȚ\s-]+|[^\s@]+@[^\s@]+\.[^\s@]+)$/;
                    console.log(
                      "e.target.value ",
                      e.target.value,
                      regx.test(value.trim().length !== 0)
                    );
                    if (regx.test(value.trim())) {
                      console.log("IN 1");
                      if (this.props.tabKey === "prescription_abbreviation") {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters,
                              term: value.trim(),
                            },
                          },
                          () => {
                            this.props.searchOnSubmit
                              ? !this.state.isOpen && this.onSearchUpdate(e)
                              : this.onSearchUpdate(e);
                          }
                        );
                      } else {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters,
                              search: value,
                            },
                          },
                          () => {
                            this.props.searchOnSubmit
                              ? !this.state.isOpen && this.onSearchUpdate(e)
                              : this.onSearchUpdate(e);
                          }
                        );
                      }

                      return false;
                    } else {
                      console.log("IN 2");
                      if (this.props.tabKey === "prescription_abbreviation") {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters,
                              term: value,
                            },
                          },
                          () => {
                            if (isEmpty(value)) {
                              let filters = this.state.filters;

                              filters && delete filters["term"];
                              this.setState({filters}, () => {
                                // this.handleSubmit(e);
                                this.props.searchOnSubmit
                                  ? !this.state.isOpen && this.onSearchUpdate(e)
                                  : this.onSearchUpdate(e);
                              });
                            } else {
                              // this.handleSubmit(e);
                              this.props.searchOnSubmit
                                ? !this.state.isOpen && this.onSearchUpdate(e)
                                : this.onSearchUpdate(e);
                            }
                          }
                        );
                      } else {
                        this.setState(
                          {
                            filters: {
                              ...this.state.filters,
                              search: value,
                            },
                          },
                          () => {
                            if (isEmpty(value)) {
                              let filters = this.state.filters;

                              filters && delete filters["search"];
                              filters && delete filters["term"];
                              this.setState({filters}, () => {
                                // this.handleSubmit(e);
                                this.props.searchOnSubmit
                                  ? !this.state.isOpen && this.onSearchUpdate(e)
                                  : this.onSearchUpdate(e);
                              });
                            } else {
                              //TODO:NOTE Added number search for pre consult review due to NHS number search integration
                              if (
                                window.location.pathname ===
                                "/doctor/pre_consultation_review"
                              ) {
                                // this.handleSubmit(e);
                                this.props.searchOnSubmit
                                  ? !this.state.isOpen && this.onSearchUpdate(e)
                                  : this.onSearchUpdate(e);
                              }
                            }
                          }
                        );
                      }
                    }
                  }}
                />

                {!this.props.searchOnly && (
                  <>
                    {this.state.isOpen ? (
                      <span
                        onClick={() =>
                          this.setState(
                            {
                              isOpen: false,
                              selectedOrganizations:
                                this.state.selectedOrganizations,
                            },
                            () => {
                              let keys = map(filterArray, "filter_key");
                              if (keys.includes("corporate_id")) {
                                this.GetOrganizations();
                              }
                            }
                          )
                        }
                        className="input-suffix cursor-pointer"
                      >
                        <CaretUpOutlined />
                      </span>
                    ) : (
                      <span
                        onClick={() =>
                          this.setState(
                            {
                              isOpen: true,
                              selectedOrganizations:
                                this.state.selectedOrganizations,
                            },
                            () => {
                              let keys = map(filterArray, "filter_key");
                              if (keys.includes("corporate_id")) {
                                this.GetOrganizations();
                              }
                              if (keys.includes("health_conditions")) {
                                this._getHealthConditions();
                              }
                              if (keys.includes("search_outcome")) {
                                this._getOutComes();
                              }
                              // let locationName = window.location.pathname;
                              // if (locationName === "/doctor/pre_consultation_review") {
                              //   // this._getOutComes();
                              // }
                            }
                          )
                        }
                        className="input-suffix cursor-pointer"
                      >
                        <CaretDownOutlined />
                      </span>
                    )}
                  </>
                )}
              </div>

              {this.state.isOpen && (
                <div className={`search-filter-downdown-content search-show`}>
                  <form>
                    {filterArray &&
                      filterArray.map((filter) => {
                        switch (filter.element_type) {
                          case TYPE_DROPDOWN:
                            if (filter.is_dependant) {
                              return this.renderDependantDropdown(filter);
                            } else {
                              return this.renderDropdown(filter);
                            }
                          case TYPE_DATEPICKER:
                            return this.renderDatePicker(filter);
                          case TYPE_DATE_RANGE_PICKER:
                            return this.renderDateRangePicker(filter);
                          case TYPE_TEXTBOX:
                            return this.renderTextBox(filter);
                          case TYPE_AGE_PICKER:
                            return this.renderAgeDropdown(filter);
                          case TYPE_AGE_RANGE_PICKER:
                            return this.renderAgeRangeSlider(filter);
                          case TYPE_CORPORATE:
                            return this.renderOrganizations(filter);
                          case TYPE_MULTI_DROPDOWN:
                            return this.renderMultipleDropDown(filter);
                          case TYPE_DISCOUNT_RULES:
                            return this.renderRules(filter);
                          case TYPE_SESSION_PICKER:
                            return this.renderSessionPicker(filter);
                          case TYPE_HEALTH_CONDITION:
                            return this.renderHealthConditions(filter);
                          case TYPE_SEARCH_OUTCOME:
                            return this.renderOutComes(filter);
                          default:
                            break;
                        }
                        return null;
                      })}
                    <div className="form-row align-items-center">
                      <div className="col-md-3">
                        <div className="position-relative"></div>
                      </div>
                      <div className="col-md-9">
                        <Button
                          htmlType="submit"
                          className="btn-custom btn btn-blue width-80px"
                          disabled={
                            isLoading ||
                            Object.keys(this.state.filters).length === 0
                          }
                          onClick={this.handleSubmit}
                        >
                          Search
                        </Button>
                        <Button
                          onClick={async (e) => {
                            if (
                              this.multiselectRef &&
                              this.multiselectRef.current
                            ) {
                              await this.multiselectRef.current.resetSelectedValues();
                            }
                            if (
                              this.multiselectRef2 &&
                              this.multiselectRef2.current
                            ) {
                              await this.multiselectRef2.current.resetSelectedValues();
                            }
                            if (
                              this.multiselectRef3 &&
                              this.multiselectRef3.current
                            ) {
                              await this.multiselectRef3.current.resetSelectedValues();
                            }
                            this.setState(
                              {filters: {}, clearing: true},
                              async () => {
                                await this.props.resetFilters();
                                await this.handleSubmit(e);
                                await this.setState({clearing: false});
                              }
                            );
                          }}
                          disabled={
                            isLoading ||
                            Object.keys(this.state.filters).length === 0
                          }
                          className="btn-custom btn btn-darkgray push-10-l width-80px"
                        >
                          Reset
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </div>
          </div>
        </OnOutsideClick>
      </div>
    );
  }
}
export default withRouter(FilterComponent);

import React, { Fragment } from "react";
import { APPT_TYPE_VIDEO } from "../../../constants/common";
import {
  EyeFilled,
  EyeInvisibleFilled,
  SoundTwoTone,
  FullscreenOutlined,
  FullscreenExitOutlined,
  // InteractionOutlined,
} from "@ant-design/icons";
import { MessageOutlined, MessageFilled } from "@ant-design/icons";
import Scrollbars from "react-perfect-scrollbar";

import { Slider, Input } from "antd";
import isURL from "validator/lib/isURL";
import Linkify from "react-linkify";
import { isEmpty } from "lodash";

const { Search } = Input;

export default function DoctorVideoControls({
  appointmentType,
  screenShareActive,
  onScreenShotTake,
  showPublisher,
  video,
  audio,
  setVideoToggle,
  setAudioToggle,
  volume,
  handleChange,
  callEndFromDoctor,
  hidePublisher,
  showPublihser,
  handleScreenShare,
  handleFullScreen,
  isFullScreenActive,
  resizeStarted,
  minusHeight,
  unreadCount,
  showChat,
  handleChatCount,
  _scrollRef2,
  uploading,
  chatArray,
  onSignalSend,
  handleMessageChange,
  text,
  renderUploadControl,
  bookVia,
  renderFile,
  handleClick,
  blur,
  videoInputDevices,
  onDeviceChange,
  videoDeviceId,
  clickedScreenShotIcon,
  switchViewToMiniPlayer,
  switchViewToFullScreenView,
}) {
  // const [isOpen, setIsOpen] = useState(false);
  // const handleOk = () => {
  //   setIsOpen(false);
  // };
  // const handleCancel = () => {
  //   setIsOpen(false);
  // };
  return (
    <Fragment>
      <div className="video_btns_wrapper">
        <span
            onClick={() => handleFullScreen(false)}
            className="vmute_icon"
            id='non-full-screen'
          >
            {!isFullScreenActive ? (
              <FullscreenOutlined
                style={{ color: "#1445b7", cursor: "pointer" }}
              />
            ) : (
              <FullscreenExitOutlined
                style={{ color: "#1445b7", cursor: "pointer" }}
              />
            )}
          </span>
        {/* {appointmentType === APPT_TYPE_VIDEO && (
          <>
            <span
              onClick={() => handleFullScreen(false)}
              className="fullscreen_icon"
              id="full_screen_btn"
            >
              {!isFullScreenActive ? (
                <FullscreenOutlined
                  style={{ color: "#1445b7", cursor: "pointer" }}
                />
              ) : (
                <FullscreenExitOutlined
                  style={{ color: "#1445b7", cursor: "pointer" }}
                />
              )}
            </span>
          </>
        )} */}
         {/* {
              <span
                onClick={onScreenShotTake}
                className={`vscreen_shot_icon ${
                  clickedScreenShotIcon ? "disabled-screnshot_icon" : ""
                }`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                  >
                    <defs>
                      <path
                        id="a"
                        d="M10.333 8l.211-.632A2 2 0 0 1 12.442 6h7.116a2 2 0 0 1 1.898 1.368l.21.632H25a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h3.333zM16 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm0-3a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"
                      />
                    </defs>
                    <g fill="none" fill-rule="evenodd">
                      <use fill="#FFF" xlinkHref="#a" />
                      <path
                        stroke="#FFF"
                        d="M11.018 7.526l-.324.974H7A1.5 1.5 0 0 0 5.5 10v12A1.5 1.5 0 0 0 7 23.5h18a1.5 1.5 0 0 0 1.5-1.5V10A1.5 1.5 0 0 0 25 8.5h-3.694l-.324-.974A1.5 1.5 0 0 0 19.558 6.5h-7.116a1.5 1.5 0 0 0-1.424 1.026zM16 20.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1z"
                      />
                      <path
                        fill="#FFF"
                        d="M3 4h3.5a.5.5 0 0 1 0 1H3v3.5a.5.5 0 0 1-1 0V4h1zM29 4h-3.5a.5.5 0 1 0 0 1H29v3.5a.5.5 0 1 0 1 0V4h-1zM3 28h3.5a.5.5 0 1 0 0-1H3v-3.5a.5.5 0 1 0-1 0V28h1zM29 28h-3.5a.5.5 0 1 1 0-1H29v-3.5a.5.5 0 1 1 1 0V28h-1z"
                      />
                    </g>
                  </svg>
                </svg>
              </span>
            } */}
            {appointmentType === APPT_TYPE_VIDEO && (
            <span onClick={setVideoToggle} className="vno_video_icon">
              {!video ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  className="no_vdo"
                >
                  <path
                    fill="#FFF"
                    fill-rule="evenodd"
                    d="M5.803 8l-1.22-1.718a.993.993 0 0 1 .24-1.388 1.007 1.007 0 0 1 1.398.241l15.15 21.321a.993.993 0 0 1-.24 1.389 1.007 1.007 0 0 1-1.398-.242L17.173 24H4a2 2 0 0 1-2-2V10a2 2 0 0 1 2-2h1.803zm4.454 0H21a2 2 0 0 1 2 2v12a2 2 0 0 1-1.432 1.918L10.257 8zm14.58 3.402l4.582-3.273A1 1 0 0 1 31 8.943v14.114a1 1 0 0 1-1.581.814l-4.581-3.273A2 2 0 0 1 24 18.971v-5.942a2 2 0 0 1 .838-1.627z"
                  />
                </svg>
              ) : (
                <svg
                  width="29px"
                  height="17px"
                  viewBox="0 0 29 17"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlnsXlink="http://www.w3.org/1999/xlink"
                  className="ys_vdo"
                >
                  <title>video</title>
                  <desc>Created with Sketch.</desc>
                  <defs></defs>
                  <g
                    id="Page-1"
                    stroke="none"
                    stroke-width="1"
                    fill="none"
                    fill-rule="evenodd"
                  >
                    <g id="video" fill="#FFFFFF">
                      <path
                        d="M2,0 L19,0 C20.1045695,-2.02906125e-16 21,0.8954305 21,2 L21,14 C21,15.1045695 20.1045695,16 19,16 L2,16 C0.8954305,16 1.3527075e-16,15.1045695 0,14 L0,2 C-1.3527075e-16,0.8954305 0.8954305,2.02906125e-16 2,0 Z M22.837,3.5997832 L27.419,0.371007164 C27.7238494,0.156332688 28.1247482,0.127711512 28.4577546,0.296847867 C28.7907609,0.465984221 29.0000361,0.804517249 29,1.1740086 L29,15.0973037 C29.0000361,15.4667951 28.7907609,15.8053281 28.4577546,15.9744644 C28.1247482,16.1436008 27.7238494,16.1149796 27.419,15.9003052 L22.838,12.6715291 C22.3123615,12.3013754 22.0002607,11.7036105 22,11.0665127 L22,5.20479957 C22.0002607,4.56770185 22.3123615,3.96993695 22.838,3.5997832 L22.837,3.5997832 Z"
                        id="Shape"
                      ></path>
                    </g>
                  </g>
                </svg>
              )}
            </span>
          )}
           <div
          onClick={callEndFromDoctor}
          className="end_call-icon cursor-pointer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="46"
            height="46"
            viewBox="0 0 46 46"
          >
            <g fill="none" fill-rule="evenodd">
              <g>
                <g transform="translate(-1103 -338) translate(1103 338)">
                  <circle cx="23" cy="23" r="23" fill="#E52C2C" />
                  <g fill="#FFF" fill-rule="nonzero">
                    <path
                      d="M19.41 14.629l-2.787-2.782c-.996-.993-2.688-.596-3.086.696-.299.894-1.295 1.39-2.19 1.192-1.992-.497-4.68-3.08-5.178-5.166-.298-.894.3-1.888 1.195-2.186 1.294-.397 1.693-2.086.697-3.08L5.273.522c-.796-.696-1.99-.696-2.688 0L.694 2.409c-1.892 1.987.199 7.253 4.878 11.922 4.68 4.67 9.956 6.855 11.947 4.868l1.892-1.888c.697-.794.697-1.987 0-2.682z"
                      transform="rotate(135 13.784 19.183)"
                    />
                  </g>
                </g>
              </g>
            </g>
          </svg>
        </div>
        <span onClick={setAudioToggle} className="vmute_icon">
          {audio ? (
            <img
              src={require("../../../assets/images/common/un_mute.svg").default}
              alt=""
            />
          ) : (
            <img
              src={require("../../../assets/images/common/mute.svg").default}
              alt=""
            />
          )}
        </span>
        {appointmentType === APPT_TYPE_VIDEO && (
          <Fragment>
            {showPublisher ? (
              <span onClick={hidePublisher} className="veye_icon">
                <EyeFilled style={{ fontSize: 25, color: "#1445b7" }} />
              </span>
            ) : (
              <span onClick={showPublihser} className="veye_icon">
                <EyeInvisibleFilled
                  style={{ fontSize: 25, color: "#1445b7" }}
                />
              </span>
            )}
            {/* {bookVia === "ad_hoc" && (
              <span onClick={handleScreenShare} className="screenshare_icon">

                {screenShareActive ? (
                  <img
                    src={
                      require("../../../assets/images/common/screen_screen_ff.svg")
                        .default
                    }
                    width="25"
                    alt=""
                  />
                ) : (
                  <img
                    src={
                      require("../../../assets/images/common/screen_screen_on.svg")
                        .default
                    }
                    width="25"
                    alt=""
                  />
                )}
              </span>
            )} */}




          </Fragment>
        )}



        {/* {!isFullScreenActive && (
          <span
            onClick={switchViewToMiniPlayer}
            className="vmute_icon ytp-svg-shadow"
          >
            <svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%">
              <use class="ytp-svg-shadow" xlinkHref="#ytp-id-21"></use>
              <path
                d="M25,17 L17,17 L17,23 L25,23 L25,17 L25,17 Z M29,25 L29,10.98 C29,9.88 28.1,9 27,9 L9,9 C7.9,9 7,9.88 7,10.98 L7,25 C7,26.1 7.9,27 9,27 L27,27 C28.1,27 29,26.1 29,25 L29,25 Z M27,25.02 L9,25.02 L9,10.97 L27,10.97 L27,25.02 L27,25.02 Z"
                fill="#fff"
                id="ytp-id-21"
              ></path>
            </svg>
          </span>
        )} */}
        {appointmentType === APPT_TYPE_VIDEO &&
          bookVia !== "ad_hoc" &&
          isFullScreenActive && (
            <span
              // onClick={() => this.handleFullScreen(false)}
              className="fullscreen_icon message_icon"
            >
              {unreadCount !== 0 && (
                <span className="chatbox_unread weight-500 chat-unread-full">
                  {unreadCount}
                </span>
              )}

              {!showChat ? (
                <MessageOutlined
                  onClick={handleChatCount}
                  style={{ color: "#3369e7", cursor: "pointer" }}
                />
              ) : (
                <MessageFilled
                  onClick={handleChatCount}
                  style={{ color: "#3369e7", cursor: "pointer" }}
                />
              )}
            </span>
          )}
      </div>

      <div className="icon-wrapper volume_slider">
        <Slider
          style={{ height: 130 }}
          onChange={handleChange}
          value={volume}
          vertical
        />
        <SoundTwoTone />
      </div>
      {appointmentType === APPT_TYPE_VIDEO &&
        isFullScreenActive &&
        bookVia !== "ad_hoc" && (
          <div
            className={`video-chatmainsection
                  ${showChat ? "full-open-chat" : "full-close-chat"}`}
          >
            <div onClick={handleChatCount} className="chatbot_header">
              <p className="m-0 weight-400 font-16">
                Chat {uploading && `( Uploading...)`}
              </p>
              <span className="close_chat">×</span>
            </div>
            <Scrollbars
              containerRef={(ref) => {
                _scrollRef2 = ref;
              }}
              className="video_chat_scrolling"
            >
              {
                <div className="chat-bubble-container">
                  {chatArray.map((chat) => {
                    if (chat.msg_send === "right") {
                      return (
                        <div className="chat-bubble-right chat-right">
                          {isURL(chat.msg_data) ? (
                            renderFile(chat.msg_data)
                          ) : (
                            <Linkify>
                              <span> {chat.msg_data}</span>
                            </Linkify>
                          )}
                        </div>
                      );
                    } else {
                      return (
                        <div className="chat-bubble-left chat-left">
                          {isURL(chat.msg_data) ? (
                            renderFile(chat.msg_data)
                          ) : (
                            <Linkify>
                              <span> {chat.msg_data}</span>
                            </Linkify>
                          )}
                        </div>
                      );
                    }
                  })}
                  <div style={{ padding: 15 }}></div>
                </div>
              }
            </Scrollbars>

            {
              <Search
                placeholder="Type your message here..."
                enterButton={"Enter"}
                size="large"
                suffix={renderUploadControl()}
                name="text"
                value={text}
                onSearch={(value) => {
                  if (!isEmpty(value)) {
                    onSignalSend(value, "text");
                  }
                }}
                onChange={(e) => {
                  handleMessageChange(e);
                }}
                autoFocus
              />
            }
          </div>
        )}

      {/* {
        <Modal
          visible={isOpen}
          onCancel={handleCancel}
          onOk={handleOk}
          title="Select camera"
        >
          <div className="col-md-12">
            <select
              onChange={(v) => onDeviceChange(v.target.value, "video")}
              className="form-control"
              value={videoDeviceId}
            >
              {isOpen &&
                videoInputDevices &&
                videoInputDevices.length > 1 &&
                videoInputDevices.map((v) => {
                  return (
                    <option key={uniqueId("VS")} value={v.deviceId}>
                      {v.label}
                    </option>
                  );
                })}
            </select>
          </div>
          <div id="camera-change-setup" className="push-20-b push-50-t"></div>
        </Modal>
      } */}
    </Fragment>
  );
}

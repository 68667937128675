import {types as sessionTypes} from "./session";
import {currencies} from "utilities/utils";
const initialState = {
  token: null,
  user: null,
  patientId: null,
  currency: currencies.pound,
  is_detail_disabled: false,
  otp_phone: null,
  reset_pass_token: null,
  common_counts: null,
  patient_detail_obj: null,
  is_from_patient_listing: true,
  componentName: null,
  componentId: null,
  appointment_obj: null,
  consultation_obj: null,
  offline_call_status: null,
  is_documents_viewed: false,
  is_video_call_setup: false,
  is_video_call_start: false,
  initialSocketData: null,
  showCallDetailModal: false,
  is_actual_call_started: false,
  screenShotTakenCount: 0,
  appointmenReasonData: null,
  vCallChatHistory: [],
  appointmentLocationData: null,
  savedSelectedKey: null,
  clinicalToken: null,
  isCallAgain: false,
  isCallEndFromPatient: false,
  callCounterData: null,
  isInErrorBoundry: false,
  showOSSLink: false,
  callSettings: null,
  is_prescription_added: false,
  is_from_patient_search_listing: null,
  is_autosave_consultation_data: false,
  is_clicked_start_call: false,
  is_clicked_view: false,
  prev_appt_tab_key: "finished",
  on_click_name_upcoming: false,
  consultation_tab_key: null,
  otp_email: null,
  is_gp_informed: false,
  from_past_consultation_trigger: null,
  fetching_patient: false,
  post_gt_questionnaire: null,
  pending_pre_consult_count: null,
  not_started_pre_consult_count: null,
  in_review_pre_consult_count: null,
  reviewed_pre_consult_count: null,
  clear_pre_consult_filter: null,
  pre_consult_id: null,
  switching_corporate: false,
  pre_consult_tab_key: "pending",
  stream_connected: null,
  // dynamicInputs: null,
};
const actionTypes = {
  ON_PATIENT_SELECT: "ON_PATIENT_SELECT",
  SET_COMPONENT: "SET_COMPONENT",
  SET_COMPONENT_ID: "SET_COMPONENT_ID",
  SET_PHONE: "SET_PHONE",
  UNSET_PHONE: "UNSET_PHONE",
  RESET_TOKEN: "RESET_TOKEN",
  FETCHING_PATIENT: "FETCHING_PATIENT",
  SET_PATIENT_DETAIL: "SET_PATIENT_DETAIL",
  UNSET_PATIENT_DETAIL: "UNSET_PATIENT_DETAIL",
  CLEAR_LOCAL_STATE: "CLEAR_LOCAL_STATE",
  ON_SET_PATIENT_APPOINTMENT: "ON_SET_PATIENT_APPOINTMENT",
  SET_CONSULTATION_OBJ: "SET_CONSULTATION_OBJ",
  SET_OFFLINE_CALL_STATUS: "SET_OFFLINE_CALL_STATUS",
  SET_DOCUMENT_VIEWED_FLAG: "SET_DOCUMENT_VIEWED_FLAG",
  SET_VIDEO_CALL_SETUP: "SET_VIDEO_CALL_SETUP",
  IS_VIDEO_CALL_STARTED: "IS_VIDEO_CALL_STARTED",
  WAITING_ROOM_ENTER_SUCCESS: "WAITING_ROOM_ENTER_SUCCESS",
  IS_SCREENSHOT_TAKEN: "IS_SCREENSHOT_TAKEN",
  SET_APPOINTMENT_REASON_DATA: "SET_APPOINTMENT_REASON_DATA",
  SET_V_CALL_CHAT_HISTORY: "SET_V_CALL_CHAT_HISTORY",
  SAVE_SELECTED_KEY: "SAVE_SELECTED_KEY",
  SIGNUP_SUCCESS: "SIGNUP_SUCCESS",
  ON_CALL_AGAIN: "ON_CALL_AGAIN",
  IS_CALL_END_FROM_PATIENT: "IS_CALL_END_FROM_PATIENT",
  SET_CALL_COUNTER_DATA: "SET_CALL_COUNTER_DATA",
  IN_ERROR_BOUNDRY: "IN_ERROR_BOUNDRY",
  ON_CALL_TIME_FINISHED: "ON_CALL_TIME_FINISHED",
  VIDEO_CALL_SETTINGS: "VIDEO_CALL_SETTINGS",
  ON_PRESCRIPTION_ADDED: "ON_PRESCRIPTION_ADDED",
  ON_CALL_FROM_PATIENT_SEARCH_LISTING: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
  IS_CLICKED_START_CALL: "IS_CLICKED_START_CALL",
  IS_CLICKED_VIEW: "IS_CLICKED_VIEW",
  PREV_APPT_TAB_KEY: "PREV_APPT_TAB_KEY",
  ON_CLICK_NAME_UPCOMING: "ON_CLICK_NAME_UPCOMING",
  CONSULTATION_TAB_KEY: "CONSULTATION_TAB_KEY",
  OTP_EMAIL: "OTP_EMAIL",
  SET_DYNAMIC_INPUT: "SET_DYNAMIC_INPUT",
  SET_IS_GP_INFORMED: "SET_IS_GP_INFORMED",
  FROM_PAST_CONSULTATION_TRIGGER: "FROM_PAST_CONSULTATION_TRIGGER",
  SET_POST_GT_QUESTIONNAIRE: "SET_POST_GT_QUESTIONNAIRE",
  PENDING_PRE_CONSULT_COUNT: "PENDING_PRE_CONSULT_COUNT",
  NOT_STARTED_PRE_CONSULT_COUNT: "NOT_STARTED_PRE_CONSULT_COUNT",
  IN_REVIEW_PRE_CONSULT_COUNT: "IN_REVIEW_PRE_CONSULT_COUNT",
  REVIEWED_PRE_CONSULT_COUNT: "REVIEWED_PRE_CONSULT_COUNT",
  CLEAR_PRE_CONSULT_FILTER: "CLEAR_PRE_CONSULT_FILTER",
  ON_SET_PRECONSULT_ID: "ON_SET_PRECONSULT_ID",
  SWITCHING_CORPORATE: "SWITCHING_CORPORATE",
  PRE_CONSULT_TAB_KEY: "PRE_CONSULT_TAB_KEY",
};
export const localStore = (state = initialState, action) => {
  switch (action.type) {
    case sessionTypes.LOGIN_SUCCESS: {
      const {token, user, reset_pass_token} = action.payload;
      return {...state, user, token, reset_pass_token};
    }
    case sessionTypes.ON_SET_CLINICAL_TOKEN: {
      return {...state, clinicalToken: action.payload};
    }
    case "IS_AUTOSAVE_CONSULTATION_DATA": {
      return {...state, is_autosave_consultation_data: action.payload};
    }
    case "PRE_CONSULT_TAB_KEY": {
      return {...state, pre_consult_tab_key: action.payload};
    }
    case "ON_STREAM_CONNECTED": {
      return {...state, stream_connected: action.payload};
    }
    case "SIGNUP_SUCCESS": {
      const {user} = action.payload;
      console.log("IN REDUCER", action.payload);
      return {...state, user};
    }
    case actionTypes.ON_PRESCRIPTION_ADDED: {
      // const { user } = action.payload;
      // console.log("IN REDUCER", action.payload);
      return {...state, is_prescription_added: action.payload};
    }
    case actionTypes.SET_POST_GT_QUESTIONNAIRE: {
      // const { user } = action.payload;
      // console.log("IN REDUCER", action.payload);
      return {...state, post_gt_questionnaire: action.payload};
    }
    case actionTypes.VIDEO_CALL_SETTINGS: {
      return {...state, callSettings: action.payload};
    }
    case "SET_CONSULTATION_OBJ": {
      return {...state, consultation_obj: action.payload};
    }
    case "SWITCHING_CORPORATE": {
      return {...state, switching_corporate: action.payload};
    }
    case actionTypes.IN_ERROR_BOUNDRY: {
      return {...state, isInErrorBoundry: action.payload};
    }
    case sessionTypes.LOG_OUT:
      return initialState;
    case actionTypes.ON_CALL_TIME_FINISHED: {
      return {...state, isTimeFinished: action.payload};
    }
    case actionTypes.ON_PATIENT_SELECT: {
      const {id} = action.payload;
      return {...state, patientId: id};
    }

    case actionTypes.SET_PHONE:
      const {phone} = action.payload;
      return {...state, otp_phone: phone};
    case "SHOW_OSS_LINK":
      return {...state, showOSSLink: action.payload};
    case actionTypes.UNSET_PHONE:
      return {...state, otp_phone: null};
    case actionTypes.ON_SET_PRECONSULT_ID:
      return {...state, pre_consult_id: action.payload};
    case actionTypes.IS_CALL_END_FROM_PATIENT:
      return {...state, isCallEndFromPatient: action.payload};
    case actionTypes.RESET_TOKEN:
      return {...state, reset_pass_token: null};
    case actionTypes.SET_DYNAMIC_INPUT:
      return {...state, dynamicInputs: action.payload};
    case actionTypes.SET_COMPONENT:
      return {...state, componentName: action.payload};

    case actionTypes.SET_COMPONENT_ID:
      return {...state, component_id: action.payload};

    case actionTypes.SET_PATIENT_DETAIL:
      return {
        ...state,
        patient_detail_obj: action.payload,
      };
    case actionTypes.FETCHING_PATIENT:
      return {...state, fetching_patient: action.payload};

    case actionTypes.UNSET_PATIENT_DETAIL:
      return {...state, patient_detail_obj: null};

    case actionTypes.ON_SET_PATIENT_APPOINTMENT:
      return {...state, appointment_obj: action.payload};

    case actionTypes.SET_OFFLINE_CALL_STATUS:
      return {...state, offline_call_status: action.payload};

    case actionTypes.SET_DOCUMENT_VIEWED_FLAG:
      return {...state, is_documents_viewed: action.payload};
    case actionTypes.SET_IS_GP_INFORMED:
      return {...state, is_gp_informed: action.payload};
    case actionTypes.SET_VIDEO_CALL_SETUP:
      return {...state, is_video_call_setup: action.payload};
    case actionTypes.SET_V_CALL_CHAT_HISTORY:
      return {...state, vCallChatHistory: action.payload};
    case "SET_INTIAL_SOCKET_DATA":
      return {...state, initialSocketData: action.payload};
    case "SET_APPOINTMENT_REASON_DATA":
      return {...state, appointmenReasonData: action.payload};
    case "ACTUAL_CALL_STARTED":
      return {...state, is_actual_call_started: action.payload};
    case "SET_APPOINTMENT_LOCATION_DATA":
      return {...state, appointmentLocationData: action.payload};
    case "ON_CALL_FROM_PATIENT_SEARCH_LISTING":
      return {...state, is_from_patient_search_listing: action.payload};
    case "ON_CLICK_NAME_UPCOMING":
      return {...state, on_click_name_upcoming: action.payload};
    case "IS_CLICKED_START_CALL":
      return {...state, is_clicked_start_call: action.payload};
    case "IS_CLICKED_VIEW":
      return {...state, is_clicked_view: action.payload};
    case "PREV_APPT_TAB_KEY":
      return {...state, prev_appt_tab_key: action.payload};
    case "CONSULTATION_TAB_KEY":
      return {...state, consultation_tab_key: action.payload};
    case "FROM_PAST_CONSULTATION_TRIGGER":
      return {...state, from_past_consultation_trigger: action.payload};
    // pre consult count
    case "PENDING_PRE_CONSULT_COUNT":
      return {...state, pending_pre_consult_count: action.payload};
    case "NOT_STARTED_PRE_CONSULT_COUNT":
      return {...state, not_started_pre_consult_count: action.payload};
    case "IN_REVIEW_PRE_CONSULT_COUNT":
      return {...state, in_review_pre_consult_count: action.payload};
    case "REVIEWED_PRE_CONSULT_COUNT":
      return {...state, reviewed_pre_consult_count: action.payload};
    case "CLEAR_PRE_CONSULT_FILTER":
      return {...state, clear_pre_consult_filter: action.payload};
    // pre consult count

    case "OTP_EMAIL":
      console.log("email payload-->", action.payload);
      return {...state, otp_email: action.payload};
    case actionTypes.IS_VIDEO_CALL_STARTED:
      return {...state, is_video_call_start: action.payload};
    case actionTypes.IS_SCREENSHOT_TAKEN:
      return {...state, screenShotTakenCount: action.payload};
    case actionTypes.SAVE_SELECTED_KEY:
      return {...state, savedSelectedKey: action.payload};
    case "ON_CALL_AGAIN":
      return {...state, isCallAgain: action.payload};
    case actionTypes.SET_CALL_COUNTER_DATA:
      return {...state, callCounterData: action.payload};
    case actionTypes.CLEAR_LOCAL_STATE:
      return {...initialState};

    default:
      return state;
  }
};

import React, {Component, Fragment} from "react";
// import { Modal } from "antd";

import {ValidateInput} from "./EditProfileVaidator";
import moment from "moment";
import {Radio, Select, Spin, Modal, Button, Checkbox} from "antd";
import "react-phone-input-2/lib/style.css";
// import { getOrganizationApi } from "../../services/organizations";
import {getSurgeriesApi} from "../../services/surgeries";
import {
  getErrorObject,
  errorToast,
  successToast,
  // calculateBMI,
  verifyObject,
  disableCopy,
  reasonEmailOptions,
  reasonPhoneOptions,
  //   verifyObject,
} from "../../utilities/utils";
import {
  getOrganizationApi2 as getOrganizationApi,
  getpostalCodesData,
  updatePatientData,
} from "../../services/patients";
import store from "../../app/store";
// import ModalPopUp from "../../components/common/ModalPopUp";
import {debounce, isArray, isEmpty} from "lodash";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  DATE_FORMAT,
  Ethnicity,
  GenderPreference,
  // ROLE_DOCTOR,
  // ROLE_CLINICAL_PHARMACIST,
} from "constants/common.js";

import "react-phone-number-input/style.css";
// import DatePicker from "react-date-picker";
import MaskDateInputPicker from "components/patientAddForm/MaskDateInputPicker";

// import store from "../../app/store";
const {Option} = Select;

class EditPatientDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_child_account: this.props.is_child_account || false,
      guardian_name: this.props.guardian_name || "",
      guardian_email: this.props.guardian_email || "",
      guardian_phone: this.props.guardian_phone || "",
      emergency_number: this.props.emergency_number || "",
      gender_preference: this.props.gender_preference || null,
      ethnicity: this.props.ethnicity || null,
      patient_id: this.props.id || "",
      first_name: this.props.first_name || "",
      last_name: this.props.last_name || "",
      phone: this.props.phone || "",
      email: this.props.email || "",
      gender: this.props.gender || "",
      dob: this.props.date_of_birth || "",
      height: this.props.height_category || "",
      weight: this.props.weight_category || "",
      address_name: this.props.address || "",
      // organizations: [],
      organization_id: verifyObject(this.props, "organization.id", ""),
      // security_answer:
      //   this.props.user && this.props.user.security_answer
      //     ? this.props.user.security_answer
      //     : "",
      // security_question:
      //   this.props.user && this.props.user.security_question
      //     ? this.props.user.security_question
      //     : "",
      isLoadMore: false,
      organizations: this.props.organization ? [this.props.organization] : [],

      address_line_1: this.props.primary_address
        ? this.props.primary_address.line1
        : "",
      address_line_2:
        (this.props.primary_address && this.props.primary_address.line2) || "",
      address_line_3:
        (this.props.primary_address && this.props.primary_address.line3) || "",
      city:
        (this.props.primary_address && this.props.primary_address.town) || "",
      postal_code:
        (this.props.primary_address && this.props.primary_address.pincode) ||
        "",
      country:
        (this.props.primary_address && this.props.primary_address.country) ||
        "",
      county:
        (this.props.primary_address && this.props.primary_address.county) || "",

      // organization_id:null,
      bmi: this.props.bmi || "",
      blood_pressure: this.props.blood_pressure || "",
      do_you_drink: this.props.alcohol || "",
      do_you_smoke: this.props.smoke || "",
      errors: null,
      surgeries: !!this.props.surgery
        ? [
            {
              id: this.props.surgery.id,
              practice_name: this.props.surgery.practice_name,
            },
          ]
        : [],
      surgery_id: this.props.surgery?.id || null,
      height_category: this.props.height_category
        ? this.props.height_category
        : "",
      height_value1:
        this.props.height_value1 !== null ? this.props.height_value1 : "",
      height_value2:
        this.props.height_value2 !== null ? this.props.height_value2 : "",
      weight_category: this.props.weight_category
        ? this.props.weight_category
        : "",
      weight_value1:
        this.props.weight_value1 !== null ? this.props.weight_value1 : "",
      weight_value2:
        this.props.weight_value2 !== null ? this.props.weight_value2 : "",
      loading: false,
      gp_service: !!this.props.gp_service
        ? [
            {
              id: this.props.gp_service.id,
              practice_name: this.props.gp_service.practice_name,
            },
          ]
        : [],
      userRole: "",
      reasonCheckedEmail: this.props.email
        ? false
        : this.props.user?.reason_for_email !== "" &&
          this.props.user?.reason_for_email,
      reasonCheckedPhone: this.props.phone
        ? false
        : this.props.user?.reason_for_phone !== "" &&
          this.props.user?.reason_for_phone,
      reasonEmailText: this.props.email
        ? ""
        : this.props.user?.reason_for_email,
      reasonPhoneText: this.props.phone
        ? ""
        : this.props.user?.reason_for_phone,
      reasonEmailOption: this.props.email
        ? null
        : reasonEmailOptions.find(
            (option) => option.value === this.props.user?.reason_for_email
          )?.value || "other",
      reasonPhoneOption: this.props.phone
        ? null
        : reasonPhoneOptions.find(
            (option) => option.value === this.props.user?.reason_for_phone
          )?.value || "other",
    };
    this.initialState = {
      emergency_number: this.props.emergency_number || "",
      gender_preference: this.props.gender_preference || null,
      ethnicity: this.props.ethnicity || null,
      first_name: this.props.first_name || "",
      last_name: this.props.last_name || "",
      phone: this.props.phone || "",
      email: this.props.email || "",
      gender: this.props.gender || "",
      dob: this.props.date_of_birth || "",
      organization_id: verifyObject(this.props, "organization.id", ""),

      address_line_1: this.props.primary_address
        ? this.props.primary_address.line1
        : "",
      address_line_2:
        (this.props.primary_address && this.props.primary_address.line2) || "",
      address_line_3:
        (this.props.primary_address && this.props.primary_address.line3) || "",
      city:
        (this.props.primary_address && this.props.primary_address.town) || "",
      postal_code:
        (this.props.primary_address && this.props.primary_address.pincode) ||
        "",
      country:
        (this.props.primary_address && this.props.primary_address.country) ||
        "",
      county:
        (this.props.primary_address && this.props.primary_address.county) || "",

      surgery_id: this.props.surgery?.id || null,

      reasonCheckedEmail: this.props.email
        ? false
        : this.props.user?.reason_for_email !== "" &&
          this.props.user?.reason_for_email,
      reasonCheckedPhone: this.props.phone
        ? false
        : this.props.user?.reason_for_phone !== "" &&
          this.props.user?.reason_for_phone,
      reasonEmailText: this.props.email
        ? ""
        : this.props.user?.reason_for_email,
      reasonPhoneText: this.props.phone
        ? ""
        : this.props.user?.reason_for_phone,
      reasonEmailOption: this.props.email
        ? null
        : this.props.user?.reason_for_email,
      reasonPhoneOption: this.props.phone
        ? null
        : this.props.user?.reason_for_phone,
    };
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.getPostalCodeDatatDebounced = debounce(this.getPostalCodes, 1000);
  }
  getUpdatedFields = () => {
    const updatedFields = {};
    Object.keys(this.initialState).forEach((key) => {
      if (
        key !== "organizations" &&
        key !== "pharmacies" &&
        key !== "surgeries" &&
        this.state[key] !== this.initialState[key]
      ) {
        if (
          (key === "reasonPhoneText" || key === "reasonEmailText") &&
          this.state[key] !== this.initialState[key]
        ) {
          const extracted = key.replace(/reason|Text/g, "");

          updatedFields[`reason${extracted}Option`] =
            this.state[`reason${extracted}Option`];
        }
        updatedFields[key] = this.state[key];
      }
    });
    return updatedFields;
  };
  getPostalCodes = async (value) => {
    try {
      await this.setState({loading: true});
      let response = await getpostalCodesData({search_code: value});
      await this.setState({
        postcodesValues: response.data.result,
        loading: false,
      });
      // if (data) {
      //   this.setState({ postal_code: data[0].postcode, address_line_1: data[0].line_1, address_line_2: data[0].line_2, city: data[0].post_town })
      // }
    } catch (error) {
      //   // const { message } = getErrorObject(error);
      await this.setState({loading: false});
      errorToast({content: "No postal code found"});
    }
  };
  // handleChange = (e) => {
  //   let errors = null;
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   if (this.state.errors) {
  //     errors = Object.assign("", this.state.errors);
  //     delete errors[e.target.name];
  //   }
  //   this.setState({[e.target.name]: e.target.value, errors: errors}, () => {
  //     //BMI calculation
  //     let fac_obj = {
  //       height_obj: {
  //         selected_feet: this.state.height_value1,
  //         selected_centimeter: this.state.height_value2,
  //         selected_unit_type: this.state.height_category,
  //         selected_inch: this.state.height_value2,
  //         selected_meter: this.state.height_value1,
  //       },
  //       weight_obj: {
  //         selected_stone: this.state.weight_value1,
  //         selected_kilogram: this.state.weight_value1,
  //         selected_unit_type: this.state.weight_category,
  //         selected_pound: this.state.weight_value2,
  //       },
  //     };

  //     let bmi = calculateBMI(fac_obj);
  //     this.setState({bmi});

  //     if (this.state[name] !== "") {
  //       let data = {
  //         [name]: value,
  //       };
  //       const errors = ValidateInput(data, false, this.state.userRole);
  //       if (!errors.isValid) {
  //         this.setState({errors: errors.errors});
  //       }
  //     }
  //   });
  // };
  handleChange = (e, reason) => {
    let errors = null;
    let name = reason ? reason : e.target.name;
    let value = reason ? e : e.target.value;
    if (this.state.errors) {
      errors = this.state.errors;
      delete errors[reason ? reason : e.target.name];
    }
    console.log("name", name);
    console.log("value", value);

    this.setState(
      {
        [reason ? reason : e.target.name]: reason ? e : e.target.value,
        errors: errors,
      },
      () => {
        if (reason === "reasonPhoneOption" && e === "other") {
          this.setState({reasonPhoneText: ""});
        }
        if (reason === "reasonEmailOption" && e === "other") {
          this.setState({reasonEmailText: ""});
        }
        if (this.state[name] !== "") {
          let data = {
            [name]: value,
          };
          const errors = ValidateInput(data, false, this.state.userRole);
          if (!errors.isValid) {
            this.setState({errors: errors.errors});
          }
        }
      }
    );
  };

  async componentDidMount() {
    // let org_name = verifyObject(this.props, "organization.name", "");
    // console.log("sliced==>,", org_name.slice(0, 3));
    // await this.GetOrganizations(false, org_name.slice(0, 3));
    let userRole = verifyObject(store.getState(), "localStore.user.role", null);
    this.setState({userRole});

    // await this.getSurgeries();
  }

  getSurgeries = async (searchString) => {
    try {
      await this.setState({loading: true});
      let response = await getSurgeriesApi({
        search: searchString,
      });
      await this.setState({loading: false});
      let surgeries = verifyObject(response, "data.data", []);
      // let surgeriesCodes = surgeries.map((surgery) => {
      //   return {
      //     id: surgery.id,
      //     practice_name: surgery.practice_name,
      //   };
      // });

      await this.setState({
        surgeries: surgeries,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loading: false});
      errorToast({content: message});
    }
  };

  // handleDatepicker = (date) => {
  //   // let isoDate = ;
  //   let errors = null;
  //   errors = Object.assign("", this.state.errors);
  //   delete errors["dob"];
  //   this.setState({dob: date, errors: errors});
  // };
  handleDatepicker = (date, b) => {
    // let isoDate = new Date(date).toISOString();

    let errors = null;
    errors = Object.assign("", this.state.errors);
    delete errors["dob"];
    // if (data && data.target && data.target.value) {
    this.setState({dob: date, errors: errors}, () => {
      if (this.state.dob !== "") {
        let condition = moment(this.state.dob).isAfter(moment());
        if (condition) {
          errors.dob = "DOB cannot be a future date.";

          this.setState({errors: errors});
        }
      }
    });
    // }
  };
  async _handleSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          organizations: [],
          search: value,
        },
        async () => {
          this.GetOrganizations(false, value);
        }
      );
    } else {
      await this.setState({organizations: []});
    }
  }
  GetOrganizations = async (isLoadMore, search) => {
    try {
      await this.setState({loading: true});
      let response = await getOrganizationApi({
        page: this.state.page,
        search: search,
        is_default_org_needed: true,
        status: "active",

        // is_default_org_needed: true,
        // is_near_by_address_needed: true,
        // page: 1,
      });
      await this.setState({loading: false});
      let organizations = response.data.data;
      if (organizations && organizations.length > 0) {
        this.setState(
          {
            organizations: isLoadMore
              ? [...this.state.organizations, ...organizations]
              : organizations,
          }
          // () => {
          //   let foundRecord = this.state.organizations.find(
          //     (d) => d.name === this.props.corporate_name
          //   );
          //   if (foundRecord) {
          //     this.setState({organization_id: foundRecord.id});
          //   }
          // }
        );
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loading: false});
      errorToast({content: message});
    }
  };
  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this.GetOrganizations(true);
      }
    );
  };
  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  // disabledDate = (current) => {
  //   // Can not select days before today and today
  //   if (
  //     this.state.userRole === ROLE_CLINICAL_PHARMACIST ||
  //     this.state.userRole === ROLE_DOCTOR
  //   ) {
  //     return current && current > moment().subtract(1, "d");
  //   } else {
  //     return current && current > moment().subtract(18, "years");
  //   }
  // };
  handleSelect = (value, name) => {
    this.setState({[name]: value});
  };
  // handlePhone = (value1, phoneData, e, formattedValue) => {
  //   console.log("all values==>", e.target.value, phoneData);
  //   // if (e.target) {
  //   let errors = null;
  //   let name = "phone";
  //   let value =
  //     e.target.value === 0
  //       ? this.state.phone
  //       : e.target.value
  //       ? e.target.value
  //       : "+44";
  //   if (this.state.errors) {
  //     errors = Object.assign("", this.state.errors);
  //     delete errors["phone"];
  //   }
  //   this.setState(
  //     {
  //       phone: value,
  //       county_code: phoneData.dialCode,
  //       errors: errors,
  //     },
  //     () => {
  //       if (this.state[name] !== "") {
  //         console.log("check 1============");
  //         let data = {
  //           [name]: value ? value.substr(4).toString().trim() : "",
  //           is_onchange_phone: true,
  //         };
  //         const errors = ValidateInput(data);
  //         if (!errors.isValid) {
  //           if (errors["phone"] !== "") {
  //             this.setState({errors: errors.errors});
  //           } else {
  //             // this.setState({  : errors.errors });
  //             delete errors["phone"];
  //             this.setState({errors: errors});
  //           }
  //         }
  //       }
  //     }
  //   );
  //   // }
  // };
  handlePhone = (val, phoneName) => {
    if (val) {
      let errors = null;
      let name = phoneName;
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors[phoneName];
      }
      this.setState(
        {
          [name]: value,
          errors: errors,
        },
        () => {
          if (this.state[name] !== "") {
            let data = {
              [name]: value,
            };
            const errors = ValidateInput(data, "first", this.state.userRole);
            if (!errors.isValid) {
              if (errors[phoneName] !== "") {
                this.setState({errors: errors.errors});
              } else {
                // this.setState({  : errors.errors });
                delete errors[phoneName];
                this.setState({errors: errors});
              }
            }
          }
        }
      );
    } else {
      let errors = null;
      let name = phoneName;
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors[phoneName];
      }
      this.setState({
        errors: errors,
        [name]: value,
      });
    }
  };
  // handlePhone = (value1, phoneData, e, formattedValue) => {
  //   if (e.target) {
  //     let errors = null;
  //     let name = "phone";
  //     let value = e.target.value;
  //     if (this.state.errors) {
  //       errors = Object.assign("", this.state.errors);
  //       delete errors["phone"];
  //     }
  //     this.setState(
  //       {
  //         phone: value,
  //         county_code: phoneData.dialCode,
  //         errors: errors,
  //       },
  //       () => {
  //         if (this.state[name] !== "") {
  //           let data = {
  //             [name]: value.substr(4).trim(),
  //             is_onchange_phone: true,
  //           };
  //           const errors = ValidateInput(data);
  //           if (!errors.isValid) {
  //             if (errors["phone"] !== "") {
  //               this.setState({errors: errors.errors});
  //             } else {
  //               // this.setState({  : errors.errors });
  //               delete errors["phone"];
  //               this.setState({errors: errors});
  //             }
  //           }
  //         }
  //       }
  //     );
  //   }
  // };

  handleUpdate = async (e) => {
    e.preventDefault();
    let surgery_params = {};
    if (this.state.surgery_id) {
      surgery_params = {
        surgery_id: this.state.surgery_id,
      };
    }
    let pharmacy_params = {};
    if (this.state.organization_id) {
      pharmacy_params = {
        organization_id: this.state.organization_id,
      };
    }
    // console.log("phone==>", this.state.phone);
    let data = {
      emergency_number: this.state.emergency_number,
      is_child_account: this.state.is_child_account,
      guardian_name: this.state.guardian_name,
      guardian_email: this.state.guardian_email,
      guardian_phone: this.state.guardian_phone,
      first_name: this.state.first_name !== null ? this.state.first_name : "",
      last_name: this.state.last_name !== null ? this.state.last_name : "",
      email: this.state.email !== null ? this.state.email : "",
      phone: this.state.phone,
      gender_preference: this.state.gender_preference
        ? this.state.gender_preference
        : "",
      ethnicity: this.state.ethnicity ? this.state.ethnicity : "",
      gender: this.state.gender !== null ? this.state.gender : "",
      dob:
        //  !isDate(
        // this.state.dob,
        // ? ""
        moment(this.state.dob).format(DATE_FORMAT),

      city: this.state.city ? this.state.city : "",
      town: this.state.city ? this.state.city : "",
      address_line_1: this.state.address_line_1
        ? this.state.address_line_1
        : "",
      address_line_2: this.state.address_line_2
        ? this.state.address_line_2
        : "",
      address_line_3: this.state.address_line_3
        ? this.state.address_line_3
        : "",
      postal_code: this.state.postal_code ? this.state.postal_code : "",
      country: this.state.country ? this.state.country : "",
      county: this.state.county ? this.state.county : "",
      // image: this.state.ProfileImageFileList
      //   ? this.state.ProfileImageFileList
      //   : "",
      // documents: this.state.DocFileList ? this.state.DocFileList : "",
      // security_question:
      //   this.state.security_question !== null
      //     ? this.state.security_question
      //     : "",
      // security_answer:
      //   this.state.security_answer !== null ? this.state.security_answer : "",
      organization_id: this.state.organization_id,
      id: this.props.fromPrescriber
        ? this.props.customer_id
        : verifyObject(store.getState(), "localStore.patientId", null),
      height_category: this.state.height_category,
      height_value1:
        this.state.height_value1 !== null ? this.state.height_value1 : "",
      height_value2:
        this.state.height_value2 !== null ? this.state.height_value2 : "",
      weight_category: this.state.weight_category,
      weight_value1:
        this.state.weight_value1 !== null ? this.state.weight_value1 : "",
      weight_value2:
        this.state.weight_value2 !== null ? this.state.weight_value2 : "",
      bmi: parseFloat(this.state.bmi) || "",
      blood_pressure: this.state.blood_pressure || "",
      alcohol: this.state.do_you_drink || "",
      smoke: this.state.do_you_smoke || "",
      ...surgery_params,
      ...pharmacy_params,
      userRole: this.state.userRole,
      reason_phone:
        this.state.reasonPhoneOption === "other"
          ? this.state.reasonPhoneText
          : this.state.reasonPhoneOption,
      reason_email:
        this.state.reasonEmailOption === "other"
          ? this.state.reasonEmailText
          : this.state.reasonEmailOption,
      reasonCheckedEmail: this.state.reasonCheckedEmail,
      reasonCheckedPhone: this.state.reasonCheckedPhone,
      reasonPhoneOption: this.state.reasonPhoneOption,
      reasonPhoneText: this.state.reasonPhoneText,
      reasonEmailOption: this.state.reasonEmailOption,
      reasonEmailText: this.state.reasonEmailText,
    };

    const errors = ValidateInput(data, false, this.state.userRole);
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      const updatedFields = this.getUpdatedFields();
      if (
        updatedFields.reasonPhoneOption === "other" &&
        this.state.reasonPhoneText === this.initialState.reasonPhoneText
      ) {
        delete updatedFields.reasonPhoneOption;
      }
      if (
        updatedFields.reasonEmailOption === "other" &&
        this.state.reasonEmailText === this.initialState.reasonEmailText
      ) {
        delete updatedFields.reasonEmailOption;
      }
      updatedFields.id =
        verifyObject(store.getState(), "localStore.patientId", null) || data.id;
      updatedFields.first_name = this.state.first_name;
      updatedFields.last_name = this.state.last_name;
      updatedFields.dob = this.state.dob;
      updatedFields.gender = this.state.gender;
      console.log("UPDAted fields---", updatedFields);

      try {
        await this.setState({isUpdating: true});
        let response = await updatePatientData(
          updatedFields,
          this.state.userRole
        );
        successToast({content: response.data.message});
        let {_getPatientDetails} = this.props;
        await this.props.hideEditModal();

        console.log("_getPatientDetails", _getPatientDetails);

        if (typeof _getPatientDetails === "function") {
          await _getPatientDetails();
        }
        await this.setState({isUpdating: false});
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({isUpdating: false});
        errorToast({content: message});
      }
    }
  };
  handleDate = (date, dateString) => {
    this.setState({dob: date});
  };

  handleCancel = () => this.setState({previewVisible: false});

  handlePreview = (file) => {
    this.setState({
      previewImage: file.thumbUrl,
      previewVisible: true,
    });
  };

  handleDocUpload = ({fileList}) => {
    //---------------^^^^^----------------
    // this is equivalent to your "const img = event.target.files[0]"
    // here, antd is giving you an array of files, just like event.target.files
    // but the structure is a bit different that the original file
    // the original file is located at the `originFileObj` key of each of this files
    // so `event.target.files[0]` is actually fileList[0].originFileObj
    // you store them in state, so that you can make a http req with them later
    this.setState({DocFileList: fileList});
  };
  handleProfileImageUpload = ({fileList, file}) => {
    //---------------^^^^^----------------
    // this is equivalent to your "const img = event.target.files[0]"
    // here, antd is giving you an array of files, just like event.target.files
    // but the structure is a bit different that the original file
    // the original file is located at the `originFileObj` key of each of this files
    // so `event.target.files[0]` is actually fileList[0].originFileObj
    // you store them in state, so that you can make a http req with them later
    // let reader = new FileReader();

    if (fileList && fileList[0]) {
      this.setState({
        ProfileImageFileList: fileList[0].originFileObj,
      });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let formData = new FormData();
    // add one or more of your files in FormData
    // again, the original file is located at the `originFileObj` key
    formData.append("file", this.state.fileList[0].originFileObj);
  };

  onSurgeryChange = (val) => {
    let foundSurgery = this.state.surgeries.filter((s) => s.id === val);
    this.setState({
      surgery_id: val,
      surgeries: foundSurgery,
    });
  };
  _renderImperialHeight = () => {
    let feetArray = [];
    let inchesArray = [];
    for (let i = 3; i <= 8; i++) {
      feetArray.push(`${i} ft`);
    }
    for (let i = 0; i <= 11; i++) {
      inchesArray.push(`${i} in`);
    }
    return (
      <div>
        <div className="push-10-t">
          <Select
            onChange={(val) =>
              this.handleChange({
                target: {name: "height_value1", value: val},
              })
            }
            className="form-control custom-arrow margin-bottom-15-imp"
            name={"height_value1"}
            value={this.state.height_value1}
          >
            <Option value={""}>{"Feet"}</Option>
            {feetArray &&
              feetArray.map((d) => {
                return <Option value={d}>{d}</Option>;
              })}
          </Select>
        </div>
        <div className="push-10-t">
          <Select
            onChange={(val) =>
              this.handleChange({
                target: {name: "height_value2", value: val},
              })
            }
            className="form-control custom-arrow"
            name={"height_value2"}
            value={this.state.height_value2}
          >
            <Option value={""}>{"Inches"}</Option>
            {inchesArray &&
              inchesArray.map((d) => {
                return <Option value={d}>{d}</Option>;
              })}
          </Select>
        </div>
      </div>
    );
  };
  handleRadioChange = (e) => {
    let errors = null;
    let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    if (name === "weight_category") {
      this.setState({
        weight_value1: "",
        weight_value2: "",
      });
    }
    if (name === "height_category") {
      this.setState({
        height_value1: "",
        height_value2: "",
      });
    }
    this.setState({[e.target.name]: e.target.value, errors: errors, bmi: ""});
  };
  _renderMetricHeight = () => {
    let meterArray = [];
    let centimeterArray = [];
    for (let i = 1; i <= 2; i++) {
      meterArray.push(`${i}m`);
    }
    if (this.state.height_value1 === "1m") {
      for (let i = 20; i <= 99; i++) {
        centimeterArray.push(`${i}cm`);
      }
    }

    if (this.state.height_value1 === "2m") {
      for (let i = 0; i <= 50; i++) {
        centimeterArray.push(`${i}cm`);
      }
    }
    return (
      <div>
        <div className="push-10-t">
          <Select
            onChange={(val) =>
              this.handleChange({
                target: {name: "height_value1", value: val},
              })
            }
            className="form-control custom-arrow margin-bottom-15-imp"
            name={"height_value1"}
            value={this.state.height_value1}
          >
            <Option value={""}>{"Meter"}</Option>
            {meterArray &&
              meterArray.map((d) => {
                return <Option value={d}>{d}</Option>;
              })}
          </Select>
        </div>
        <div className="push-10-t">
          <Select
            onChange={(val) =>
              this.handleChange({
                target: {name: "height_value2", value: val},
              })
            }
            className="form-control custom-arrow"
            name={"height_value2"}
            value={this.state.height_value2}
          >
            <Option value={""}>{"Centimeter"}</Option>
            {centimeterArray &&
              centimeterArray.map((d) => {
                return <Option value={d}>{d}</Option>;
              })}
          </Select>
        </div>
      </div>
    );
  };
  _renderImperialWeight = () => {
    // let { isLoading } = this.props.patientCRUD;
    let stoneArray = [];
    let pounArray = [];
    for (let i = 6; i <= 40; i++) {
      stoneArray.push(`${i} st`);
    }
    for (let i = 0; i <= 13; i++) {
      pounArray.push(`${i} lbs`);
    }
    return (
      <div>
        <div className="push-10-t">
          <Select
            onChange={(val) =>
              this.handleChange({
                target: {name: "weight_value1", value: val},
              })
            }
            className="form-control custom-arrow margin-bottom-15-imp"
            name={"weight_value1"}
            value={this.state.weight_value1}
          >
            <Option value={""}>{"Stone"}</Option>
            {stoneArray &&
              stoneArray.map((d) => {
                return <Option value={d}>{d}</Option>;
              })}
          </Select>
        </div>
        <div className="push-10-t">
          <Select
            onChange={(val) =>
              this.handleChange({
                target: {name: "weight_value2", value: val},
              })
            }
            className="form-control custom-arrow"
            name={"weight_value2"}
            value={this.state.weight_value2}
          >
            <Option value={""}>{"Pound"}</Option>
            {pounArray &&
              pounArray.map((d) => {
                return <Option value={d}>{d}</Option>;
              })}
          </Select>
        </div>
      </div>
    );
  };

  _renderMetircWeight = () => {
    // let { isLoading } = this.props.patientCRUD;
    let kgsArray = [];
    for (let i = 38; i <= 250; i++) {
      kgsArray.push(`${i} kg`);
    }

    return (
      <div>
        <div className="push-10-t">
          <Select
            onChange={(val) =>
              this.handleChange({
                target: {name: "weight_value1", value: val},
              })
            }
            className="form-control custom-arrow"
            name={"weight_value1"}
            value={this.state.weight_value1}
          >
            <Option value={""}>{"Kilogram"}</Option>
            {kgsArray &&
              kgsArray.map((d) => {
                return <Option value={d}>{d}</Option>;
              })}
          </Select>
        </div>
      </div>
    );
  };
  handleNextFormOne = (next, page) => {
    let data = {
      first_name: this.state.first_name !== null ? this.state.first_name : "",
      last_name: this.state.last_name !== null ? this.state.last_name : "",
      email: this.state.email !== null ? this.state.email : "",
      phone: this.state.phone,
      gender: this.state.gender !== null ? this.state.gender : "",
      dob:
        //  !
        //   this.state.dob,
        // //  ? "" :
        moment(this.state.dob).format(DATE_FORMAT),
    };
    const errors = ValidateInput(data, page, this.state.userRole);
    console.log("errors", errors);
    if (!errors.isValid) {
      this.setState({errors: errors.errors});
    } else {
      next();
    }
  };
  handleFormTwo = (next, page) => {
    let data = {
      security_question: this.state.security_question,
      security_answer: this.state.security_answer,
    };
    const errors = ValidateInput(data, page, this.state.userRole);
    if (!errors.isValid) {
      this.setState({errors: errors.errors});
    } else {
      next();
    }
  };
  render() {
    // let {userRole} = this.state;
    // const optionalFieldCondition =
    //   (userRole && userRole === ROLE_CLINICAL_PHARMACIST) ||
    //   (userRole && userRole === ROLE_DOCTOR);
    // console.log("Patiend Detail", this.props);
    console.log("STATE-------):", this.state);
    return (
      // <p>Test</p>
      <Fragment>
        <Modal
          title="Update Patient"
          visible={this.props.showEdit}
          maskClosable={false}
          onCancel={(event) => {
            console.log("event==>", event);
            event.persist();
            if (event.type === "keydown" && event.keyCode === 27) return;
            this.props.hideEditModal();
          }}
          ref={(ref) => (this.emailRef = ref)}
          footer={false}
          width={1000}
        >
          <div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    First Name
                    <sup className="text-danger font-15 top-2">*</sup>
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.first_name &&
                      "danger-border"
                    }`}
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.handleChange}
                    placeholder="First name"
                    autoFocus
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.first_name}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Last Name
                    <sup className="text-danger font-15 top-2">*</sup>
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.last_name &&
                      "danger-border"
                    }`}
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.handleChange}
                    placeholder="Last name"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.last_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-between gapx-1 mb-2">
                    <label htmlFor="sel1" className="font-10 mb-0">
                      Mobile Number
                      {/* {!optionalFieldCondition ? (
                        <sup className="text-danger font-15 top-2">*</sup>
                      ) : (
                        " (Optional)"
                      )} */}
                    </label>
                    <div className="labelcheckbox">
                      <Checkbox
                        checked={this.state.reasonCheckedPhone}
                        disabled={this.initialState.phone}
                        onChange={(e) =>
                          this.setState(
                            {reasonCheckedPhone: e.target.checked},
                            () => {
                              if (!this.state.reasonCheckedPhone) {
                                let errors = null;

                                if (this.state.errors) {
                                  errors = this.state.errors;

                                  delete errors["checkbocReasonPhone"];
                                }
                                this.setState({
                                  reasonPhoneOption: null,
                                  reasonPhoneText: "",
                                  errors,
                                });
                              } else {
                                let errors = null;

                                if (this.state.errors) {
                                  errors = this.state.errors;

                                  delete errors["checkbocReasonPhone"];
                                  delete errors["phone"];
                                }
                                this.setState(
                                  {
                                    errors: errors,
                                    reasonPhoneOption: null,
                                    reasonPhoneText: "",
                                    phone: "",
                                  },
                                  () => {}
                                );
                              }
                            }
                          )
                        }
                        className="font-10 m-0 d-flex flex-wrap align-items-center"
                      >
                        No phone
                      </Checkbox>
                      {this.state.errors &&
                        this.state.errors.checkbocReasonPhone && (
                          <span className="validate-danger">
                            {this.state.errors.checkbocReasonPhone}
                          </span>
                        )}
                    </div>
                  </div>
                  {!this.state.reasonCheckedPhone && (
                    <PhoneInput
                      // name="phone"
                      onChange={(d) => this.handlePhone(d, "phone")}
                      value={this.state.phone}
                      placeholder="+44 070 3298 1042"
                      defaultCountry="GB"
                      // country="GB"
                      // countries={["GB"]}
                      countryCallingCodeEditable={false}
                      international
                      addInternationalOption={false}
                      internationalIcon={null}
                      error={
                        this.state.phone
                          ? isValidPhoneNumber(this.state.phone)
                            ? undefined
                            : "Invalid mobile number"
                          : "Mobile number required"
                      }
                      limitMaxLength={14}
                      numberInputProps={{
                        className: `d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0`,
                        onCopy: disableCopy,
                        onCut: disableCopy,
                      }}
                    />
                  )}
                  {this.state.reasonCheckedPhone && (
                    <div>
                      <Select
                        onCopy={disableCopy}
                        className={`form-control margin-0 placeholder-10 ${
                          this.state.errors &&
                          this.state.errors.reasonPhoneOption &&
                          "danger-border"
                        }`}
                        value={this.state.reasonPhoneOption}
                        onChange={(value) =>
                          this.handleChange(value, "reasonPhoneOption")
                        }
                        placeholder="Select reason"
                      >
                        {reasonPhoneOptions.map((option, index) => (
                          <Option key={index} value={option.value}>
                            {option.value}
                          </Option>
                        ))}
                      </Select>
                      {this.state.errors && (
                        <span className="validate-danger">
                          {this.state.errors.reasonPhoneOption}
                        </span>
                      )}
                      {this.state.reasonPhoneOption === "other" && (
                        <div className="mt-1">
                          <input
                            onCopy={disableCopy}
                            className={`form-control margin-0 placeholder-10 mt-1 ${
                              this.state.errors &&
                              this.state.errors.reasonPhoneText &&
                              "danger-border"
                            }`}
                            name="reasonPhoneText"
                            value={this.state.reasonPhoneText}
                            onChange={(e) => this.handleChange(e)}
                            placeholder="Enter reason"
                            p
                          />
                          {this.state.errors && (
                            <span className="validate-danger">
                              {this.state.errors.reasonPhoneText}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.phone}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <div className="d-flex flex-wrap align-items-center justify-content-between gapx-2 mb-2">
                    <label htmlFor="sel1" className="font-10 mb-0">
                      Email
                      {/* {!optionalFieldCondition ? (
                                <sup className="text-danger font-15 top-2">*</sup>
                              ) : (
                                " (Optional)"
                              )} */}
                    </label>
                    <div className="labelcheckbox">
                      <Checkbox
                        checked={this.state.reasonCheckedEmail}
                        disabled={this.initialState.email ? true : false}
                        onChange={(e) =>
                          this.setState(
                            {reasonCheckedEmail: e.target.checked},
                            () => {
                              if (!this.state.reasonCheckedEmail) {
                                let errors = null;

                                if (this.state.errors) {
                                  errors = this.state.errors;

                                  delete errors["checkbocReasonEmail"];
                                }
                                this.setState({
                                  reasonEmailOption: null,
                                  reasonEmailText: "",
                                  errors,
                                });
                              } else {
                                let errors = null;

                                if (this.state.errors) {
                                  errors = this.state.errors;

                                  delete errors["checkbocReasonEmail"];
                                  delete errors["email"];
                                }
                                this.setState(
                                  {
                                    errors: errors,
                                    reasonEmailOption: null,
                                    reasonEmailText: "",
                                    email: "",
                                  },
                                  () => {}
                                );
                              }
                            }
                          )
                        }
                        className="font-10 m-0 d-flex flex-wrap align-items-center"
                      >
                        No email
                      </Checkbox>
                      {this.state.errors &&
                        this.state.errors.checkbocReasonEmail && (
                          <span className="validate-danger">
                            {this.state.errors.checkbocReasonEmail}
                          </span>
                        )}
                    </div>
                  </div>
                  {!this.state.reasonCheckedEmail && (
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        this.state.errors &&
                        this.state.errors.email &&
                        "danger-border"
                      }`}
                      name="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                      placeholder="Enter email address"
                      p
                    />
                  )}
                  {this.state.reasonCheckedEmail && (
                    <div>
                      <Select
                        onCopy={disableCopy}
                        className={`form-control margin-0 placeholder-10 mt-2 ${
                          this.state.errors &&
                          this.state.errors.reasonEmailOption &&
                          "danger-border"
                        }`}
                        value={this.state.reasonEmailOption}
                        onChange={(value) =>
                          this.handleChange(value, "reasonEmailOption")
                        }
                        placeholder="Select reason"
                      >
                        {reasonEmailOptions.map((option, index) => (
                          <Option key={index} value={option.value}>
                            {option.value}
                          </Option>
                        ))}
                      </Select>
                      {this.state.errors && (
                        <span className="validate-danger">
                          {this.state.errors.reasonEmailOption}
                        </span>
                      )}
                      {this.state.reasonEmailOption === "other" && (
                        <div className="mt-1">
                          <input
                            onCopy={disableCopy}
                            className={`form-control margin-0 placeholder-10 ${
                              this.state.errors &&
                              this.state.errors.reasonEmailText &&
                              "danger-border"
                            }`}
                            name="reasonEmailText"
                            value={this.state.reasonEmailText}
                            onChange={(e) => this.handleChange(e)}
                            placeholder="Enter reason"
                            p
                          />
                          {this.state.errors && (
                            <span className="validate-danger">
                              {this.state.errors.reasonEmailText}
                            </span>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.email}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Gender
                  </label>
                  <div className="div gender_wrapper">
                    <Radio.Group
                      onChange={this.handleChange}
                      name="gender"
                      value={this.state.gender}
                    >
                      <Radio value={"male"}>Male</Radio>
                      <Radio value={"female"}>Female</Radio>
                    </Radio.Group>
                  </div>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.gender}
                    </span>
                  )}
                </div>

                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Gender Preference
                  </label>
                  <div className="">
                    <Select
                      onChange={(value) =>
                        this.setState({gender_preference: value})
                      }
                      name="gender_preference"
                      value={this.state.gender_preference}
                      className="custom-arrow form-control"
                      placeholder="Select preference"
                    >
                      {GenderPreference.map((prefrence) => {
                        return (
                          <Select.Option
                            value={prefrence.value}
                            key={prefrence.value}
                          >
                            {prefrence.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* {this.state.errors && (
                            <span className="validate-danger">
                              {this.state.errors.gender}
                            </span>
                          )} */}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Ethnicity
                  </label>
                  <div className="">
                    <Select
                      onChange={(value) => {
                        console.log("value-->", value, this.state.ethnicity);
                        this.setState({ethnicity: value});
                      }}
                      name="ethnicity"
                      value={
                        this.state.ethnicity === 0
                          ? "white_english"
                          : this.state.ethnicity
                          ? this.state.ethnicity
                          : null
                      }
                      className="custom-arrow form-control"
                      placeholder="Select ethnicity"
                    >
                      {Ethnicity.map((prefrence) => {
                        return (
                          <Select.Option
                            value={prefrence.value}
                            key={prefrence.value}
                          >
                            {prefrence.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </div>
                  {/* {this.state.errors && (
                            <span className="validate-danger">
                              {this.state.errors.gender}
                            </span>
                          )} */}
                </div>

                <div className="col-md-6 react-date-picker-wrapper">
                  <label htmlFor="sel1" className="font-10">
                    Date Of Birth (e.g 01-01-2001)
                    <sup className="text-danger font-15 top-2">*</sup>
                  </label>
                  <MaskDateInputPicker
                    {...this.props}
                    handleDatepicker={this.handleDatepicker}
                    selectedDate={new Date(this.state.dob)}
                    maxDate={new Date(moment(moment()).subtract(1, "d"))}
                  />

                  {/* <DatePicker
                            // placeholder="DD-MM-YYYY"
                            className={`form-control placeholder-10 ${
                              this.state.errors &&
                              this.state.errors.dob &&
                              "danger-border"
                            }`}
                            dayPlaceholder="DD"
                            monthPlaceholder="MM"
                            yearPlaceholder="YYYY"
                            allowClear={true}
                            format={"dd-MM-y"}
                            onChange={this.handleDatepicker}
                            // disabledDate={this.disabledDate}
                            maxDate={
                              new Date(moment(moment()).subtract(1, "d"))
                            }
                            defaultPickerValue={
                              this.state.dob
                                ? new Date(this.state.dob)
                                : new Date(moment(), DATE_FORMAT)
                            }
                            value={
                              this.state.dob ? new Date(this.state.dob) : null
                            }
                          /> */}
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.dob}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <Checkbox
                    checked={this.state.is_child_account}
                    // disabled={this.state.reasonCheckedEmail}
                    onChange={(e) =>
                      this.setState(
                        {is_child_account: e.target.checked},
                        () => {
                          if (!this.state.is_child_account) {
                            let errors = null;

                            if (this.state.errors) {
                              errors = this.state.errors;

                              delete errors["is_child_account"];
                            }
                            this.setState({
                              guardian_name: "",
                              guardian_email: "",
                              guardian_phone: "",

                              errors,
                            });
                          } else {
                            let errors = null;

                            if (this.state.errors) {
                              errors = this.state.errors;

                              delete errors["is_child_account"];
                              delete errors["phone"];
                            }
                            this.setState(
                              {
                                errors: errors,
                                guardian_name: "",
                                guardian_email: "",
                                guardian_phone: "",
                              },
                              () => {}
                            );
                          }
                        }
                      )
                    }
                    className="font-10 m-0 mt-18"
                  >
                    Is child account ?
                  </Checkbox>
                </div>
                {this.state.is_child_account && (
                  <div className="col-md-6">
                    <label htmlFor="sel1" className="font-10">
                      Guardian Phone
                    </label>
                    <PhoneInput
                      // name="phone"
                      onChange={(d) => this.handlePhone(d, "guardian_phone")}
                      value={this.state.guardian_phone}
                      placeholder="+44 070 3298 1042"
                      defaultCountry="GB"
                      limitMaxLength={14}
                      // country="GB"
                      // countries={["GB"]}
                      countryCallingCodeEditable={false}
                      international
                      addInternationalOption={false}
                      error={
                        this.state.guardian_phone
                          ? isValidPhoneNumber(this.state.guardian_phone)
                            ? undefined
                            : "Invalid mobile number"
                          : "Mobile number required"
                      }
                      numberInputProps={{
                        className:
                          "d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0",
                        onCopy: disableCopy,
                        onCut: disableCopy,
                      }}
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.guardian_phone}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div> */}
            {/* {this.state.is_child_account && (
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-6">
                    <label htmlFor="sel1" className="font-10">
                      Guardian Name
                    </label>
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        this.state.errors &&
                        this.state.errors.guardian_name &&
                        "danger-border"
                      }`}
                      name="guardian_name"
                      value={this.state.guardian_name}
                      onChange={this.handleChange}
                      placeholder="Enter guardian name"
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.guardian_name}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="sel1" className="font-10">
                      Guardian Email
                    </label>
                    <input
                      onCopy={disableCopy}
                      className={`form-control margin-0 placeholder-10 ${
                        this.state.errors &&
                        this.state.errors.guardian_email &&
                        "danger-border"
                      }`}
                      name="guardian_email"
                      value={this.state.guardian_email}
                      onChange={this.handleChange}
                      placeholder="Enter guardian email"
                    />
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.guardian_email}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )} */}

            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Search Postcode (type min 5 char)
                  </label>

                  <Select
                    showSearch
                    className={`form-control margin-0 ${
                      this.state.errors &&
                      this.state.errors.address_name &&
                      "danger-border"
                    }`}
                    placeholder="Search Postcode (type min 5 char)"
                    // optionFilterProp="children"
                    loading={this.state.loading}
                    value={this.state.postcodeSearchValue}
                    onChange={(value) => {
                      this.setState({postcodeSearchValue: value});
                      let foundValue = this.state.postcodesValues.find(
                        (d) => d.udprn === value
                      );

                      if (foundValue) {
                        this.setState({
                          postal_code: foundValue.postcode,
                          address_line_1: foundValue.line_1,
                          address_line_2: foundValue.line_2,
                          address_line_3: foundValue.line_3,
                          city: foundValue.post_town,
                          country: foundValue.country,
                          county: foundValue.county,
                        });
                      }
                    }}
                    onSearch={(value) =>
                      this.setState({search: value}, () => {
                        if (!isEmpty(value) && value.trim().length >= 5) {
                          this.getPostalCodeDatatDebounced(value);
                        }
                      })
                    }
                    filterOption={false}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                  >
                    {this.state.postcodesValues &&
                      this.state.postcodesValues.length > 0 &&
                      this.state.postcodesValues.map((record, index) => {
                        return (
                          <Option
                            value={record.udprn}
                          >{`${record.line_1}  ${record.postal_county} ${record.postcode}`}</Option>
                        );
                      })}
                  </Select>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.address_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Address Line 1
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.address_line_1 &&
                      "danger-border"
                    }`}
                    name="address_line_1"
                    value={this.state.address_line_1}
                    onChange={this.handleChange}
                    placeholder="Enter address line 1"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.address_line_1}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Address Line 2 (Optional)
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.address_line_2 &&
                      "danger-border"
                    }`}
                    name="address_line_2"
                    value={this.state.address_line_2}
                    onChange={this.handleChange}
                    placeholder="Enter address line 2"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.address_line_2}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Address Line 3 (Optional)
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.address_line_3 &&
                      "danger-border"
                    }`}
                    name="address_line_3"
                    value={this.state.address_line_3}
                    onChange={this.handleChange}
                    placeholder="Enter address line 3"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.address_line_3}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Town/City
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.city &&
                      "danger-border"
                    }`}
                    name="city"
                    value={this.state.city}
                    onChange={this.handleChange}
                    placeholder="Town/City"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.city}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Postal Code
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.postal_code &&
                      "danger-border"
                    }`}
                    name="postal_code"
                    value={this.state.postal_code}
                    onChange={this.handleChange}
                    placeholder="Post Code"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.postal_code}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Country (Optional)
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.country &&
                      "danger-border"
                    }`}
                    name="country"
                    value={this.state.country}
                    onChange={this.handleChange}
                    placeholder="Country"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.country}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    County (Optional)
                  </label>
                  <input
                    onCopy={disableCopy}
                    className={`form-control margin-0 placeholder-10 ${
                      this.state.errors &&
                      this.state.errors.county &&
                      "danger-border"
                    }`}
                    name="county"
                    value={this.state.county}
                    onChange={this.handleChange}
                    placeholder="County"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.county}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label htmlFor="sel1">Select Pharmacy (Optional)</label>
                  <Select
                    onChange={(value) => {
                      // console.log("change pharmacy==>", value);
                      this.setState({organization_id: value});
                    }}
                    onSearch={(value) =>
                      this.setState({search: value}, () => {
                        this.searchUpdate(value);
                      })
                    }
                    optionFilterProp={"option"}
                    filterOption={(input, option) =>
                      option.title
                        ?.toLowerCase()
                        ?.indexOf(input?.toLowerCase()) >= 0
                    }
                    className="form-control custom-arrow select_pharmacy"
                    name={"organization_id"}
                    value={this.state.organization_id}
                    showSearch={true}
                    // filterOption={(input, option) =>
                    //   option.children
                    //     .toLowerCase()
                    //     .indexOf(input.toLowerCase()) >= 0
                    // }
                    notFoundContent={
                      !this.state.searching &&
                      this.state.organizations &&
                      this.state.organizations.length === 0 && (
                        <span>No pharmacy available</span>
                      )
                    }
                    dropdownRender={(menus) => {
                      return (
                        <div ref={this.scrollDiv}>
                          {menus}
                          {this.state.isLoadmore && this.state.searching && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: 10,
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          )}
                        </div>
                      );
                    }}

                    // placeholder="Select Pharmacy"
                  >
                    {this.state.organizations &&
                      this.state.organizations.map((options) => {
                        const address = [
                          options.line1,
                          options.line2,
                          options.line3,
                          options.town,
                          options.pincode,
                        ]
                          .filter((d) => d)
                          .join(", ");
                        return (
                          <Option
                            value={options.id}
                            key={options.id}
                            className="full_txt"
                            title={options.name}
                          >
                            <div>
                              <b>{options.name}</b>
                              <br />
                              <span>
                                {address}
                                {/* {`${
                                  verifyObject(options, "line1", "") !== ""
                                    ? `${verifyObject(options, "line1", "")},`
                                    : ""
                                }
                                    
                                    ${
                                      verifyObject(options, "line2", "") !== ""
                                        ? `${verifyObject(
                                            options,
                                            "line2",
                                            ""
                                          )},`
                                        : ""
                                    }${
                                  verifyObject(options, "line3", "") !== ""
                                    ? `${verifyObject(options, "line3", "")},`
                                    : ""
                                }${
                                  verifyObject(options, "town", "") !== ""
                                    ? `${verifyObject(options, "town", "")},`
                                    : ""
                                }
                                      ${
                                        verifyObject(options, "pincode", "") !==
                                        ""
                                          ? `${verifyObject(
                                              options,
                                              "pincode",
                                              ""
                                            )}`
                                          : ""
                                      }
                                    `} */}
                              </span>
                            </div>
                          </Option>
                        );
                      })}
                  </Select>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <label htmlFor="select_surgery">
                    Select Surgery (Optional)
                  </label>
                  <Select
                    showSearch={true}
                    // loading={this.state.loading}
                    placeholder="Select Surgery"
                    optionFilterProp="children"
                    className="form-control custom-arrow"
                    defaultValue={
                      this.state.surgeries.length > 0
                        ? this.state.surgeries[0].practice_name
                        : this.state.gp_service.length > 0
                        ? this.state.gp_service[0].practice_name
                        : null
                    }
                    onChange={async (val) => await this.onSurgeryChange(val)}
                    allowClear={true}
                    onSearch={async (val) => await this.getSurgeries(val)}
                    filterOption={false}
                  >
                    {isArray(this.state.surgeries) &&
                      this.state.surgeries.length > 0 &&
                      this.state.surgeries.map((surgery, index) => {
                        const address = [
                          surgery.address,
                          surgery.city,
                          surgery.county,
                          surgery.post_code,
                        ]
                          .filter((d) => d)
                          .join(", ");
                        return (
                          <Option key={index} value={surgery.id}>
                            <b
                              className="practicename"
                              style={{textTransform: "capitalize"}}
                            >
                              {surgery.practice_name}
                            </b>
                            <br />
                            <span className="address">{address}</span>
                          </Option>
                        );
                      })}
                  </Select>
                </div>
                <div className="col-md-6">
                  <label htmlFor="sel1" className="font-10">
                    Emergency Number (Optional)
                  </label>
                  <PhoneInput
                    // name="phone"
                    onChange={(d) => this.handlePhone(d, "emergency_number")}
                    value={this.state.emergency_number}
                    placeholder="+44 070 3298 1042"
                    defaultCountry="GB"
                    limitMaxLength={14}
                    // country="GB"
                    // countries={["GB"]}
                    countryCallingCodeEditable={false}
                    international
                    addInternationalOption={false}
                    error={
                      this.state.emergency_number
                        ? isValidPhoneNumber(this.state.emergency_number)
                          ? undefined
                          : "Invalid mobile number"
                        : "Mobile number required"
                    }
                    numberInputProps={{
                      className:
                        "d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0",
                      onCopy: disableCopy,
                      onCut: disableCopy,
                    }}
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.emergency_number}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex ant-modal-footer p-0 border-0">
              <Button
                className="btn text-black font-13 weight-400 width-104 height-34 prl-15 btn ant-btn-primary"
                onClick={this.handleUpdate}
                disabled={this.state.isUpdating}
              >
                Update
              </Button>
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}
// const mapStateToProps = (state) => {q//   return {
//     customers: state.customers,
//     localStore: state.localStore,
//   };
// };

// const mapDispatchToProps = (dispatch) => {
//   return {
//     actions: bindActionCreators({ onCustomerEdit }, dispatch),
//   };
// };

export default EditPatientDetail;

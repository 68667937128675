import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as preEncountersActions} from "../../../reducers/preEnccounter";
import {
  verifyObject,
  // modifiedDataObjectToArray,
  modifiedDataObjectToArrayByIDs,
  successToast,
  getErrorObject,
  errorToast,
} from "../../../utilities/utils";
import {
  // Button,
  Col,
  Collapse,
  Input,
  // Select,
  // Spin,
  // Spin
} from "antd";
import {
  isArray,
  debounce,
  uniqueId,
  isEmpty,
  uniq,
  map,
  // isEmpty
} from "lodash";
import moment from "moment";
import {
  DATE_FORMAT,
  DATE_FORMAT_24_HR,
  ROLE_DOCTOR,
  ROLE_CLINICAL_PHARMACIST,
  T24_HR_FORMAT,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "../../../constants/common";
import CommonLoader from "../../../components/common/CommonLoader";
import {Pagination} from "../../../components/common";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
// import { SearchOutlined } from "@ant-design/icons";
import {preEncounterFilters} from "../../../constants/filterConstants";
import FilterComponent from "../../../components/FIlterComponent/FilterComponent";
import store from "app/store";
import {TYPE_DROPDOWN} from "../../../constants/filterConstants";
import {TYPE_DATE_RANGE_PICKER} from "../../../constants/filterConstants";
import {EditOutlined, CloseOutlined} from "@ant-design/icons";
// import addImage from "../../../assets/images/common/add-ic.png";
import deleteImage from "../../../assets/images/common/delete-ic.png";
// import verifiedImage from "../../../assets/images/common/ic_right.svg";
// import unverifiedImage from "../../../assets/images/common/ic_cancel.svg";
// import ModalPopUp from "components/common/ModalPopUp";
import {
  // addConsultationDetailAPI,
  updateConsultationDetailAPI,
} from "services/patientConsultation";
import {searchSnomedCode} from "services/snomedCodes";
import InformationalOutcomeModal from "containers/PatientConsultationConatainers/InformationalOutcomeModal";
// import PrescriptionAddModal from "containers/PatientConsultationConatainers/PrescriptionContainer/PrescriptionAddModal";
import {
  onRequestSetComponent,
  onRequestSetComponentID,
  onSetDynamicInput,
  onSetSidebarKey,
} from "../../../reducers/dynamicTab";
import CreatableSelect from "react-select/creatable";
// import {Scrollbars} from "react-custom-scrollbars";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {Axios} from "axios";
// import {ValidateInput} from "containers/PatientConsultationConatainers/SickNoteContainer/SickNoteAddValidations";
let {Panel} = Collapse;
class PreEncountersContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: this.props.fromConsultation ? null : ["1"],
      userRole: "",
      preEncounterFiltersState: preEncounterFilters,

      // editShow: true,
    };
    this.prescriptionModal = React.createRef();

    [
      "_onRequestList",
      "_onPageChange",
      "_handleSearch",
      "_handleSnomedCodeSearch",
      "handleScroll",
      "fetchMoreData",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.searchUpdateSnomedCode = debounce(this._handleSnomedCodeSearch, 1000);

    //Test
  }
  async _handleSnomedCodeSearch(value, id) {
    if (!isEmpty(value)) {
      this.setState(
        {
          // [`snomedCodes${id}`]: [],
          // [`snomedCodeId${id}`]: "",
          [`page${id}`]: 1,
          [`searching${id}`]: true,
          [`isLoadmore${id}`]: false,
          [`search${id}`]: value,
        },
        async () => {
          this._getSnomedCodes(false, id);
        }
      );
    } else {
      await this.setState({[`snomedCodes${id}`]: []});
    }
  }
  async _getSnomedCodes(isLoadMore, id) {
    try {
      let response = await searchSnomedCode({
        page: this.state[`page${id}`],
        search: this.state[`search${id}`],
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      let newSnomedCodes = snomedCodes.map((k) => {
        return {
          value: k.code,
          label: k.description,
          id: k.id,
        };
      });

      this.setState({
        [`snomedCodes${id}`]: isLoadMore
          ? [...this.state[`snomedCodes${id}`], ...newSnomedCodes]
          : newSnomedCodes,
        [`searching${id}`]: false,
        [`pagination${id}`]: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({[`searching${id}`]: false});
      errorToast({content: message});
    }
  }

  fetchMoreData = async (id) => {
    await this.setState(
      (prevState) => {
        return {
          [`page${id}`]: prevState[`page${id}`] + 1,
          [`readMarked${id}`]: false,
          [`searching${id}`]: true,
          [`isLoadmore${id}`]: true,
        };
      },
      async () => {
        await this._getSnomedCodes(true, id);
      }
    );
  };

  handleScroll = (e, id) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    console.log(
      "pagination---->",
      this.state[`page${id}`],
      this.state[`pagination${id}`]
    );
    // let {
    //   page,
    //   pagination: {total_pages},
    // } = this.state;
    if (
      scrollHeight - scrollTop === clientHeight &&
      this.state[`page${id}`] < this.state[`pagination${id}`] &&
      this.state[`pagination${id}`].total_pages
    ) {
      this.fetchMoreData(id);
    }
  };
  _handleCustomCodeAdd(value, id) {
    // let {snomedCodeText} = this.state;
    let Obj = {
      id: "",
      code: "",
      description: value,
    };
    this.setState(
      {
        [`snomedCodeText${id}`]: "",
        [`snomedCodeId${id}`]: "",
        [`selectedCodesArray${id}`]: this.state[`selectedCodesArray${id}`]
          ? this.state[`selectedCodesArray${id}`].concat(Obj)
          : [Obj],
      },
      () => {
        if (this.state[`consultationDetail${id}`] === null) {
          this.setState({
            [`showCodeModal${id}`]: false,
            [`selectedSnomedCodesArray${id}`]: uniq([
              ...this.state.selectedSnomedCodesArray,
              ...this.state.selectedCodesArray,
            ]),
          });
        } else {
          // this._handleSnomedCodeUpdate(id);
        }
      }
    );
  }
  _handleDropDownChange(value, key, id) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = this.state[`snomedCodes${id}`].find((d) => d.id == value.id);
    let ids = map(this.state[`selectedCodesArray${id}`], "id");

    if (found) {
      if (ids.includes(found.id) === false) {
        this.setState(
          {
            [`selectedCodesArray${id}`]: this.state[`selectedCodesArray${id}`]
              ? this.state[`selectedCodesArray${id}`].concat({
                  id: found.id,
                  code: found.value,
                  description: found.label,
                })
              : [
                  {
                    id: found.id,
                    code: found.value,
                    description: found.label,
                  },
                ],
            [`snomedCodeId${id}`]: "",
          },
          () => {
            // if (this.state[`consultationDetail${id}`] === null) {
            console.log("not called-----------------", this.state, found, ids);
            this.setState({
              [`showCodeModal${id}`]: false,
              [`selectedSnomedCodesArray${id}`]: uniq([
                ...(this.state[`selectedSnomedCodesArray${id}`]
                  ? this.state[`selectedSnomedCodesArray${id}`]
                  : []),
                ...this.state[`selectedCodesArray${id}`],
              ]),
            });
            // } else {
            //   console.log(" called-----------------");

            //   // this._handleSnomedCodeUpdate(id);
            // }
          }
        );
      } else {
        this.setState(
          {
            [`selectedCodesArray${id}`]: this.state[`selectedCodesArray${id}`]
              ? this.state[`selectedCodesArray${id}`].concat({
                  id: found.id,
                  code: found.value,
                  description: found.label,
                })
              : [
                  {
                    id: found.id,
                    code: found.value,
                    description: found.label,
                  },
                ],
            [`snomedCodeId${id}`]: "",
          },
          () => {
            // if (this.state[`consultationDetail${id}`] === null) {

            this.setState({
              [`showCodeModal${id}`]: false,
              [`selectedSnomedCodesArray${id}`]: uniq([
                ...(this.state[`selectedSnomedCodesArray${id}`]
                  ? this.state[`selectedSnomedCodesArray${id}`]
                  : []),
                ...this.state[`selectedCodesArray${id}`],
              ]),
            });
            // } else {
            //   console.log(" called-----------------");

            //   // this._handleSnomedCodeUpdate(id);
            // }
          }
        );
      }
    }
  }
  _removeCode = (record, id) => {
    this.setState(
      {
        [`selectedCodesArray${id}`]: this.state[
          `selectedCodesArray${id}`
        ].filter(
          (d) => d.id !== record.id || d.description !== record.description
        ),
      },
      () => {
        // if (this.state.consultationDetail === null) {

        this.setState({
          showCodeModal: false,
          [`selectedSnomedCodesArray${id}`]: uniq([
            ...this.state[`selectedSnomedCodesArray${id}`],
            ...this.state[`selectedCodesArray${id}`],
          ]),
        });
        // } else {
        //   console.log(" called-----------------");

        // this._handleSnomedCodeUpdate();
        // }
      }
    );
  };
  async _handleSearch(value) {
    let {patientId, preEncountersActions, appointmentObj} = this.props;
    let {onSearch} = preEncountersActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({
        search: value,
        patient_id: verifyObject(appointmentObj, "patient_id")
          ? verifyObject(appointmentObj, "patient_id")
          : verifyObject(appointmentObj, "patient.id")
          ? verifyObject(appointmentObj, "patient.id")
          : patientId,
        page: 1,
      });
    }
  }

  componentDidMount() {
    let userRole = verifyObject(store.getState(), "localStore.user.role", null);
    this.setState({userRole});
    if (
      userRole === ROLE_CLINICAL_PHARMACIST ||
      userRole === ROLE_DOCTOR ||
      userRole === ROLE_SUPERINTENDENT_PHARMACIST
    ) {
      const preEncounterFilters = [
        // {
        // 	filter_title: 'Name',
        // 	filter_key: 'name',
        // 	element_type: TYPE_TEXTBOX,
        // 	params: null,
        // },
        {
          filter_title: "Session Date",
          filter_key: "date_period",
          element_type: TYPE_DATE_RANGE_PICKER,
          params: ["date_period[start_date]", "date_period[end_date]"],
          filter_values: null,
        },
        // {
        // 	filter_title: 'Session Type',
        // 	filter_key: 'session_type',
        // 	element_type: TYPE_SESSION_PICKER,
        // },
        {
          filter_title: "Appointment Type",
          filter_key: "session_type",
          element_type: TYPE_DROPDOWN,
          params: null,
          filter_values: [
            {lable: "Face-2-Face", value: "face_to_face"},
            // {lable: "Video Call", value: "video"},
            // {lable: "Phone Call", value: "phone_call"},
          ],
        },
      ];
      this.setState({preEncounterFiltersState: preEncounterFilters});
    }

    if (this.props.prev_appt_tab_key === "pending") {
      let pathName =
        window.location.pathname === "/doctor/pre_consultation_review";
      if (!pathName) {
        this._onRequestList();
      }
    } else {
      this._onRequestList();
    }
  }
  componentDidUpdate() {
    let {
      preEncounters: {search},
    } = this.props;
    this.setState({search});
  }

  _onRequestList() {
    console.log("--------CALLING PRE ENC--------");
    let {patientId, preEncountersActions, appointmentObj} = this.props;
    let {onRequest} = preEncountersActions;
    if (typeof onRequest === "function") {
      if (this.state.cancelToken && this.state.cancelToken.token) {
        this.state.cancelToken.cancel("Canceling previous request");
      }
      let cancelToken = Axios?.CancelToken?.source();

      let patient_id = verifyObject(appointmentObj, "patient_id")
        ? verifyObject(appointmentObj, "patient_id")
        : verifyObject(appointmentObj, "patient.id")
        ? verifyObject(appointmentObj, "patient.id")
        : patientId;
      if (patient_id) {
        this.setState(
          {
            cancelToken: cancelToken,
          },
          () => {
            onRequest(
              {
                patient_id,
                page: 1,
              },
              cancelToken
            );
          }
        );
      }
    }
  }

  _onPageChange(data) {
    let {
      patientId,
      preEncountersActions: {onPageChange},
      preEncounters: {search},
      appointmentObj,
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page: page,
        patient_id: verifyObject(appointmentObj, "patient_id")
          ? verifyObject(appointmentObj, "patient_id")
          : verifyObject(appointmentObj, "patient.id")
          ? verifyObject(appointmentObj, "patient.id")
          : patientId,
        search: search,
      });
    }
  }
  handleEditPreEnc = (toDo, id) => {
    this.setState({[`editShow${id}`]: !this.state[`editShow${id}`]});
    if (toDo === "edit") {
      console.log("clicked edit------");
    } else {
      console.log("clicked save------");
    }
  };
  _handleEdit(state, id) {
    // if (state === "editPrescriptionPlan") {
    //   if (this.prescriptionModal && this.prescriptionModal.current) {
    //     this.prescriptionModal.current._handleShowModal();
    //   }
    // }
    // else { // commented prescription popup open on click of edit, now need to shoe input box same as other fields
    this.setState({[`${state}${id}`]: !this.state[`${state}${id}`]}, () => {
      if (this.state.editOutcome) {
        this.setState({visible: true});
      }
    });
    // }
  }
  _handleTextChange(e, id) {
    let errors = null;

    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState(
      {[`${e.target.name}${id}`]: e.target.value, errors: errors},
      () => {
        // console.log("state------", this.state);
      }
    );
  }
  async _handleUpdate(id, plan) {
    // console.log("update------", id, plan);
    // let {
    //   dynamicInputs: {prescriptionPlan},
    // } = this.props;
    let payload = {
      consultation_id: id,
    };

    if (
      this.state[`history${id}`] &&
      this.handleDynamicCondition(this.state[`history${id}`])
    ) {
      payload = {
        ...payload,
        history: this.state[`history${id}`],
      };
    }
    if (
      this.state[`examination${id}`] &&
      this.handleDynamicCondition(this.state[`examination${id}`])
    ) {
      payload = {
        ...payload,
        examination: this.state[`examination${id}`],
      };
    }
    if (
      this.state[`management${id}`] &&
      this.handleDynamicCondition(this.state[`management${id}`])
    ) {
      payload = {
        ...payload,
        management_plan: this.state[`management${id}`],
      };
    }
    if (
      this.state[`prescription_plan${id}`] &&
      this.handleDynamicCondition(this.state[`prescription_plan${id}`])
    ) {
      payload = {
        ...payload,
        prescription_plan: this.state[`prescription_plan${id}`],
      };
    }
    // if (plan && this.handleDynamicCondition(plan, true)) {
    //   payload = {
    //     ...payload,
    //     prescription_plan: plan,
    //   };
    // }
    if (
      this.state[`barcode${id}`] &&
      this.handleDynamicCondition(this.state[`barcode${id}`])
    ) {
      payload = {
        ...payload,
        consultation: {
          barcode: this.state[`barcode${id}`],
        },
      };
    }

    if (
      this.state[`selectedCodesArray${id}`] &&
      isArray(this.state[`selectedCodesArray${id}`])
    ) {
      payload = {
        ...payload,
        snomed_codes: uniq([
          ...this.state[`selectedSnomedCodesArray${id}`],
          ...this.state[`selectedCodesArray${id}`],
        ]),
      };
    }
    if (
      (this.state[`treatment${id}`] &&
        this.handleDynamicCondition(this.state[`treatment${id}`])) ||
      (this.state[`followUp${id}`] &&
        this.handleDynamicCondition(this.state[`followUp${id}`])) ||
      (this.state[`referral${id}`] &&
        this.handleDynamicCondition(this.state[`referral${id}`])) ||
      (this.state[`comment${id}`] &&
        this.handleDynamicCondition(this.state[`comment${id}`]))
    ) {
      payload = {
        ...payload,

        outcome: {
          treatment: this.state[`treatment${id}`],
          follow_up: this.state[`followUp${id}`],
          referral: this.state[`referral${id}`],
          comment: this.state[`comment${id}`],
        },
      };
    }
    // if (param === "managementPlan") {
    //   payload = {
    //     consultation: {
    //       management_plan: this.state.managementPlan,
    //     },
    //     consultation_id: consultation_id,
    //   };
    // }
    // console.log("payload-------", payload, prescriptionPlan);
    try {
      this.setState({[`updatingDetail${id}`]: true});
      if (payload && Object.keys(payload).length === 1) {
        this.setState({
          [`updatingDetail${id}`]: false,
          [`editShow${id}`]: !this.state[`editShow${id}`],
          // [view]: false,
        });
        this._clearData(id);
      } else {
        let response = await updateConsultationDetailAPI(payload);
        if (response.data && response.data.message) {
          if (typeof this.props._getPatientConultationDetails === "function") {
            this.props._getPatientConultationDetails(true);
          }
          successToast({
            content: `Updated Consultation Record.`,
          });
          this.setState({
            [`updatingDetail${id}`]: false,
            consultationDetail: response.data.data,
            [`editShow${id}`]: !this.state[`editShow${id}`],
            // [view]: false,
          });
        }
        // *#  condition added for reload on update if from ongoing consultation
        if (this.props.fromConsultation) {
          this._onRequestList();
        }

        // this._getPatientConultationDetails();
        this._clearData(id);
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({[`updatingDetail${id}`]: false});
      errorToast({content: message});
    }
  }
  _hideModal(id) {
    this.setState({
      [`editOutcome${id}`]: false,
      [`errors${id}`]: null,
      [`treatment${id}`]: "",
      [`followUp${id}`]: "",
      [`referral${id}`]: "",
      [`comment${id}`]: "",
      [`editOutcome${id}`]: false,
      [`changing${id}`]: false,
    });
  }
  async _handleModalSubmission(id) {
    return this.setState({
      [`editOutcome${id}`]: false,
    });
  }
  _clearData(id) {
    this.setState({
      [`history${id}`]: "",
      [`editHistory${id}`]: false,
      [`examination${id}`]: "",
      [`editExamination${id}`]: false,
      [`editDiagnosis${id}`]: false,
      [`management${id}`]: "",
      [`editManagement${id}`]: false,
      [`editPrescriptionPlan${id}`]: false,
      [`barcode${id}`]: "",
      [`editBarcode${id}`]: false,
      [`editOutcome${id}`]: false,
      [`treatment${id}`]: "",
      [`followUp${id}`]: "",
      [`referral${id}`]: "",
      [`comment${id}`]: "",
      [`snomedCodes${id}`]: [],
      [`searching${id}`]: false,
      [`isLoadmore${id}`]: false,
      [`search${id}`]: false,
      [`snomedCodeText${id}`]: "",
      [`showCodeModal${id}`]: false,
      [`addingDetail${id}`]: false,
      [`updatingDetail${id}`]: false,
      [`visible${id}`]: false,
      [`errors${id}`]: null,
      [`treatment${id}`]: "",
      [`followUp${id}`]: "",
      [`referral${id}`]: "",
      [`comment${id}`]: "",
      [`editOutcome${id}`]: false,
      [`changing${id}`]: false,
    });
    // store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
    // store.dispatch({
    //   type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
    //   payload: null,
    // });
  }
  handleDynamicCondition = (param, forPrescrptionPLan) => {
    let condition = forPrescrptionPLan
      ? param && param !== undefined && param !== ""
      : param &&
        param !== undefined &&
        param !== "" &&
        param.trim().length !== 0;
    return condition;
  };
  showCodeModal = (id) => {
    this.setState({[`showCodeModal${id}`]: true});
  };
  _hideCodeModal = (id) => {
    this.setState({[`showCodeModal${id}`]: false});
  };
  render() {
    let {preEncounters, userId} = this.props;
    // *# Below condition for show edit option only in past appointment and only during consultation
    let editViewCondition =
      window.location.pathname === "/patient/consultation_detail" ||
      window.location.pathname === "/doctor/walkin-appointment" ||
      window.location.pathname === "/doctor/video-appointment";

    let {activeKey} = this.state;

    let {
      // data,
      isLoading,
      // pagination: {total},
    } = preEncounters;
    let preEncountersArray = modifiedDataObjectToArrayByIDs(preEncounters);

    return (
      <Fragment>
        <div className="push-20-b preencounter_wrapper">
          <div className="form-row align-items-center push-10-b left-right-space">
            <div className="col-md-4">
              {/* <h6 className="font-12 text-light-black margin-0 weight-500">
                Pre Encounters {isLoading ? "" : `(${total})`}{" "}
              </h6> */}
            </div>
            <div className="col-md-8">
              <div className="d-flex align-items-center justify-content-end stickfilter">
                {/* <div className="dragbtn">
                    <a>
                      <span className="uprrow">
                        <CaretUpOutlined />
                      </span>
                      <span className="downarrow">
                        <CaretDownOutlined />
                      </span>
                    </a>
                  </div> */}
                <div className="filtercomponent">
                  <FilterComponent
                    isLoading={isLoading}
                    defaultRequest={{
                      // appointments_type: "past",
                      patient_id: verifyObject(
                        this.props.appointmentObj,
                        "patient_id"
                      )
                        ? verifyObject(this.props.appointmentObj, "patient_id")
                        : verifyObject(this.props.appointmentObj, "patient.id")
                        ? verifyObject(this.props.appointmentObj, "patient.id")
                        : this.props.patientId,
                    }}
                    onFilterChange={
                      this.props.preEncountersActions.onFilterChange
                    }
                    resetFilters={
                      this.props.preEncountersActions.resetFilterState
                    }
                    filterArray={this.state.preEncounterFiltersState}
                  />
                </div>
              </div>
              {/* <img alt="" src={search_blue}></img> */}
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          <div className="sectionsubscroll">
            <PerfectScrollbar>
              <div className="prescription-array">
                {!isLoading &&
                  preEncountersArray &&
                  isArray(preEncountersArray) &&
                  preEncountersArray.length === 0 && (
                    <NoFoundMessage message="No previous encounters found." />
                  )}

                <Collapse
                  expandIconPosition="right"
                  onChange={(key) => {
                    this.setState({activeKey: key});
                  }}
                  activeKey={activeKey}
                  className="pre_quitionare"
                >
                  {!isLoading &&
                    preEncountersArray &&
                    isArray(preEncountersArray) &&
                    preEncountersArray.map((k, index) => {
                      return (
                        <Panel
                          key={[index + 1]}
                          header={
                            <div className="pre-encounter-item-header font-12 weight-400 text-left d-flex flex-wrap align-items-center justify-content-between gapx-2 gapy-1 margin-0 text-capitalize padding-5">
                              <div className="d-flex flex-wrap align-items-center gapx-2 gapy-1">
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "clinician", "") !== ""
                                      ? `${k.clinician}`
                                      : "-"
                                  }
                                />{" "}
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "consultation_date", "") !==
                                    ""
                                      ? `On ${moment(
                                          k.consultation_date
                                        ).format(DATE_FORMAT)}`
                                      : "-"
                                  }
                                />
                                {/* {this.props.fromConsultation && ( */}
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "appointment_reason", "")
                                      ? ` - ${verifyObject(
                                          k,
                                          "appointment_reason",
                                          ""
                                        )}`
                                      : ""
                                  }
                                />
                                {/* )} */}
                              </div>
                              <div className="d-flex flex-wrap align-items-center gapx-2 gapy-1">
                                {!k.pre_consult_id &&
                                verifyObject(
                                  k,
                                  "appointment.appointment_tag",
                                  null
                                ) ? (
                                  <span className="custom-bluelabel mr-3">
                                    {verifyObject(
                                      k,
                                      "appointment.appointment_tag",
                                      ""
                                    ).replace(/_/g, " ")}
                                  </span>
                                ) : null}
                                {k.pre_consult_id &&
                                verifyObject(k, "pre_consult_tag", null) ? (
                                  <span className="custom-bluelabel mr-3">
                                    {verifyObject(
                                      k,
                                      "pre_consult_tag",
                                      ""
                                    ).replace(/_/g, " ")}
                                  </span>
                                ) : null}
                                {verifyObject(k, "prescription_code", null) ? (
                                  <span className="mr-3">
                                    Prescription code:{" "}
                                    {verifyObject(k, "prescription_code", "")}
                                  </span>
                                ) : null}

                                <span className="startendtime">
                                  {verifyObject(
                                    k,
                                    "appointment.start_time",
                                    ""
                                  ) !== "" &&
                                    verifyObject(
                                      k,
                                      "appointment.end_time",
                                      ""
                                    ) !== "" &&
                                    `${moment(k.appointment.start_time).format(
                                      T24_HR_FORMAT
                                    )} - ${moment(
                                      k.appointment.end_time
                                    ).format(T24_HR_FORMAT)}`}
                                </span>
                                {editViewCondition &&
                                  userId === k.clinician_id && (
                                    <div className="ml-3">
                                      {this.state[`updatingDetail${k.id}`] ? (
                                        <span className="styled-link weight-500 text-whilte">
                                          Updating...
                                        </span>
                                      ) : (
                                        <span
                                          className="styled-link weight-500 text-whilte"
                                          onClick={(event) => {
                                            event.stopPropagation();
                                            if (
                                              !this.state[`editShow${k.id}`]
                                            ) {
                                              this.handleEditPreEnc(
                                                this.state[`editShow${k.id}`],
                                                k.id
                                              );
                                            } else {
                                              this._handleUpdate(k.id);
                                            }
                                          }}
                                        >
                                          {!this.state[`editShow${k.id}`]
                                            ? "Edit"
                                            : "Save"}
                                        </span>
                                      )}
                                    </div>
                                  )}
                              </div>
                            </div>
                          }
                        >
                          <div className="col-md-12 p-0">
                            <Col span={24}>
                              <div style={{}} className="">
                                {verifyObject(k, "pre_consult_tag", null) ===
                                  "pharmacy_first" && (
                                  <div className="encounter_wrap">
                                    <div className="form-row push-10-b">
                                      <div className="col-md-12 consultant_review">
                                        <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                                          Pre Consult
                                        </p>

                                        <div className="div-border consultant_data input-bg-f9f9f9">
                                          {k !== null &&
                                          verifyObject(
                                            k,
                                            "consultation_log.pre_consult_note",
                                            null
                                          ) ? (
                                            <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                              {verifyObject(
                                                k,
                                                "consultation_log.pre_consult_note",
                                                ""
                                              ) !== "" ? (
                                                <Highlighter
                                                  highlightClassName="search-highlighter"
                                                  searchWords={[
                                                    this.state.search,
                                                  ]}
                                                  autoEscape={true}
                                                  textToHighlight={verifyObject(
                                                    k,
                                                    "consultation_log.pre_consult_note",
                                                    "-"
                                                  ).replaceAll("&nbsp;", "")}
                                                />
                                              ) : (
                                                "-"
                                              )}
                                            </p>
                                          ) : (
                                            "-"
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="encounter_wrap">
                                  <div className="form-row push-10-b">
                                    <div className="col-md-12 consultant_review">
                                      <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                                        History
                                      </p>
                                      {this.state[`editHistory${k.id}`] && (
                                        <span
                                          className="cursor font-12 weight-400 text-whilte text-red d-inline-flex align-items-center add-coding"
                                          onClick={(event) => {
                                            event.stopPropagation();

                                            this.setState({
                                              [`editHistory${k.id}`]:
                                                !this.state[
                                                  `editHistory${k.id}`
                                                ],
                                              [`history${k.id}`]: "",
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                          <span>Clear</span>
                                        </span>
                                      )}
                                      {this.state[`editShow${k.id}`] &&
                                        !this.state[`editHistory${k.id}`] &&
                                        verifyObject(
                                          k,
                                          "consultation_log.history",
                                          null
                                        ) !== null && (
                                          <a
                                            onClick={() =>
                                              this._handleEdit(
                                                "editHistory",
                                                k.id
                                              )
                                            }
                                            className="add-coding cursor font-12 weight-400"
                                          >
                                            {" "}
                                            <EditOutlined /> Edit
                                          </a>
                                        )}

                                      {/* <div className="border-box col-md-12 input-bg-f9f9f9"> */}
                                      <div className="div-border consultant_data input-bg-f9f9f9">
                                        {k !== null && (
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                            {verifyObject(
                                              k,
                                              "consultation_log.history",
                                              ""
                                            ) !== "" ? (
                                              <Highlighter
                                                highlightClassName="search-highlighter"
                                                searchWords={[
                                                  this.state.search,
                                                ]}
                                                autoEscape={true}
                                                textToHighlight={verifyObject(
                                                  k,
                                                  "consultation_log.history",
                                                  "-"
                                                )}
                                              />
                                            ) : (
                                              "-"
                                            )}
                                          </p>
                                        )}
                                        {verifyObject(
                                          k,
                                          "consultation_log.edited_history",
                                          []
                                        ).map((j) => {
                                          if (j.history !== null) {
                                            return (
                                              <div className="highlightedtext push-5-t normal-lineheight padding-5-t">
                                                {" "}
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext">
                                                  {/* {j.history} */}
                                                  <Highlighter
                                                    highlightClassName="search-highlighter"
                                                    searchWords={[
                                                      this.state.search,
                                                    ]}
                                                    autoEscape={true}
                                                    textToHighlight={j.history}
                                                  />
                                                </p>
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                  Edited by {j.edited_by} On{" "}
                                                  {moment(j.date).format(
                                                    DATE_FORMAT_24_HR
                                                  )}
                                                </p>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                      </div>
                                      {/* <div className="form-row">
                                      <div className="col-md-12">
                                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "consultation_log.history",
                                                null
                                              ) !== null
                                                ? `${k.consultation_log.history}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div> */}
                                      {/* </div> */}
                                      {this.state[`editHistory${k.id}`] && (
                                        <div className="addtextdiv push-10-t relative">
                                          <Fragment>
                                            <Input.TextArea
                                              autoSize={true}
                                              type="textarea"
                                              className="form-control custom-input font-11 weight-400 no-focus padding-right-55"
                                              name="history"
                                              value={
                                                this.state[`history${k.id}`]
                                              }
                                              onChange={(e) =>
                                                this._handleTextChange(e, k.id)
                                              }
                                              placeholder="Enter Text Here..."
                                              rows="2"
                                            />
                                            {/* {!isEmpty(
                                          this.state[`history${k.id}`]
                                        ) && (
                                          <div className="actionicons">
                                            <a
                                              onClick={() => {
                                                this._handleUpdate(
                                                  "history",
                                                  "editHistory",
                                                  k.id
                                                );
                                              }}
                                              className="margin-right-10 text-right in-block vertical-middle text-dark-blue weight-400 hide_outline normal-lineheight cursor"
                                            >
                                              {this.state[
                                                `updatingDetail${k.id}`
                                              ] ? (
                                                <Spin size="small" />
                                              ) : (
                                                <img
                                                  src={verifiedImage}
                                                  alt="Verified"
                                                  height="17"
                                                  width="17"
                                                />
                                              )}
                                            </a>
                                            <a
                                              onClick={() => {
                                                this.setState({
                                                  [`history${k.id}`]: "",
                                                });
                                                this._handleEdit(
                                                  "editHistory",
                                                  k.id
                                                );
                                              }}
                                              className="margin-right-5 text-right in-block vertical-middle text-dark-blue weight-400 hide_outline normal-lineheight cursor"
                                            >
                                              <img
                                                src={unverifiedImage}
                                                alt="Unverified"
                                                height="12"
                                                width="12"
                                              />
                                            </a>
                                          </div>
                                        )} */}
                                          </Fragment>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="encounter_wrap">
                                  <div className="form-row push-10-b">
                                    <div className="col-md-12 consultant_review">
                                      <p className="font-12 weight-400 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                        Examination
                                      </p>
                                      {this.state[`editExamination${k.id}`] && (
                                        <span
                                          className="cursor font-12 weight-400 text-whilte text-red d-inline-flex align-items-center add-coding"
                                          onClick={(event) => {
                                            event.stopPropagation();

                                            this.setState({
                                              [`editExamination${k.id}`]:
                                                !this.state[
                                                  `editExamination${k.id}`
                                                ],
                                              [`examination${k.id}`]: "",
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                          <span>Clear</span>
                                        </span>
                                      )}
                                      {this.state[`editShow${k.id}`] &&
                                        !this.state[`editExamination${k.id}`] &&
                                        verifyObject(
                                          k,
                                          "consultation_log.examination",

                                          null
                                        ) !== null && (
                                          <a
                                            onClick={() =>
                                              this._handleEdit(
                                                "editExamination",
                                                k.id
                                              )
                                            }
                                            className="add-coding cursor font-12 weight-400"
                                          >
                                            {" "}
                                            <EditOutlined /> Edit
                                          </a>
                                        )}

                                      {/* <div className="border-box col-md-12 input-bg-f9f9f9">
                                    <div className="form-row">
                                      <div className="col-md-12">
                                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "consultation_log.examination",
                                                ""
                                              ) !== ""
                                                ? `${k.consultation_log.examination}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </div> */}
                                      <div className="div-border consultant_data input-bg-f9f9f9">
                                        {k !== null && (
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                            {verifyObject(
                                              k,
                                              "consultation_log.examination",
                                              ""
                                            ) !== "" ? (
                                              <Highlighter
                                                highlightClassName="search-highlighter"
                                                searchWords={[
                                                  this.state.search,
                                                ]}
                                                autoEscape={true}
                                                textToHighlight={verifyObject(
                                                  k,
                                                  "consultation_log.examination",
                                                  "-"
                                                )}
                                              />
                                            ) : (
                                              "-"
                                            )}
                                          </p>
                                        )}
                                        {verifyObject(
                                          k,
                                          "consultation_log.edited_examination",
                                          []
                                        ).map((j) => {
                                          if (j.examination !== null) {
                                            return (
                                              <div className="highlightedtext push-5-t normal-lineheight padding-5-t">
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext">
                                                  <Highlighter
                                                    highlightClassName="search-highlighter"
                                                    searchWords={[
                                                      this.state.search,
                                                    ]}
                                                    autoEscape={true}
                                                    textToHighlight={
                                                      j.examination
                                                    }
                                                  />
                                                </p>
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                  Edited by {j.edited_by} On{" "}
                                                  {moment(j.date).format(
                                                    DATE_FORMAT_24_HR
                                                  )}
                                                </p>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                      </div>
                                      {this.state[`editExamination${k.id}`] && (
                                        <div className="addtextdiv push-10-t relative">
                                          <Fragment>
                                            <Input.TextArea
                                              autoSize={true}
                                              type="textarea"
                                              className="form-control custom-input font-11 weight-400 no-focus padding-right-55"
                                              name="examination"
                                              value={
                                                this.state[`examination${k.id}`]
                                              }
                                              onChange={(e) =>
                                                this._handleTextChange(e, k.id)
                                              }
                                              placeholder="Enter Text Here..."
                                              rows="2"
                                              disabled={
                                                !this.state[`editShow${k.id}`]
                                              }
                                            />
                                            {/* {!isEmpty(
                                          this.state[`examination${k.id}`]
                                        ) && (
                                          <div className="actionicons">
                                            <a
                                              onClick={() => {
                                                this._handleUpdate(
                                                  "examination",
                                                  "editExamination",
                                                  k.id
                                                );
                                              }}
                                              className="margin-right-10 text-right in-block vertical-middle text-dark-blue weight-400 hide_outline normal-lineheight cursor"
                                            >
                                              {this.state[
                                                `updatingDetail${k.id}`
                                              ] ? (
                                                <Spin size="small" />
                                              ) : (
                                                <img
                                                  src={verifiedImage}
                                                  alt="Verified"
                                                  height="17"
                                                  width="17"
                                                />
                                              )}
                                            </a>
                                            <a
                                              onClick={() => {
                                                this.setState({
                                                  [`examination${k.id}`]: "",
                                                });
                                                this._handleEdit(
                                                  "editExamination",
                                                  k.id
                                                );
                                              }}
                                              className="margin-right-5 text-right in-block vertical-middle text-dark-blue weight-400 hide_outline normal-lineheight cursor"
                                            >
                                              <img
                                                src={unverifiedImage}
                                                alt="Unverified"
                                                height="12"
                                                width="12"
                                              />
                                            </a>
                                          </div>
                                        )} */}
                                          </Fragment>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="encounter_wrap">
                                  <div className="form-row push-10-b">
                                    <div className="col-md-12 consultant_review">
                                      <p className="font-12 weight-400 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                        Diagnosis / Clinical Impressions
                                      </p>
                                      {this.state[`editDiagnosis${k.id}`] && (
                                        <span
                                          className="cursor font-12 weight-400 text-whilte text-red d-inline-flex align-items-center add-coding"
                                          onClick={(event) => {
                                            event.stopPropagation();

                                            this.setState({
                                              [`editDiagnosis${k.id}`]:
                                                !this.state[
                                                  `editDiagnosis${k.id}`
                                                ],
                                              [`snomedCodes${k.id}`]: [],
                                              [`selectedCodesArray${k.id}`]: [],
                                              [`selectedSnomedCodesArray${k.id}`]:
                                                [],
                                              [`search${k.id}`]: "",
                                              [`searching${k.id}`]: false,
                                              [`isLoadmore${k.id}`]: false,
                                              [`snom  edCodeText${k.id}`]: "",
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                          <span>Clear</span>
                                        </span>
                                      )}
                                      {this.state[`editShow${k.id}`] &&
                                        !this.state[`editDiagnosis${k.id}`] &&
                                        k.snomed_codes &&
                                        isArray(k.snomed_codes) && (
                                          <a
                                            onClick={() =>
                                              this._handleEdit(
                                                "editDiagnosis",
                                                k.id
                                              )
                                            }
                                            className="add-coding cursor font-12 weight-400"
                                          >
                                            {" "}
                                            <EditOutlined /> Edit
                                          </a>
                                        )}
                                      {/* <a
                                    onClick={() => this._showCodeModal(k.id)}
                                    ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                    role="button"
                                    tabIndex="0"
                                    className="in-block height-auto-imp font-12-imp weight-400 padding-5-imp btn-custom btn-blue"
                                  >

                                    Add Code
                                  </a> */}
                                      {/* {this.state[`editShow${k.id}`] &&
                                    k.snomed_codes &&
                                    isArray(k.snomed_codes) && (
                                      <a
                                        onClick={() =>
                                          this._handleEdit(
                                            "editDiagnosis",
                                            k.id
                                          )
                                        }
                                        className="add-coding cursor font-12 weight-400"
                                      >
                                        {" "}
                                        <EditOutlined /> Edit
                                      </a>
                                    )} */}
                                      <div className="border-box col-md-12 input-bg-f9f9f9">
                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={
                                            k.snomed_codes &&
                                            isArray(k.snomed_codes) &&
                                            k.snomed_codes &&
                                            "-"
                                          }
                                        />
                                        {verifyObject(
                                          k,
                                          "snomed_codes",
                                          []
                                        ).map((j) => {
                                          return (
                                            <div
                                              key={uniqueId("PRE_EN_")}
                                              className="form-row"
                                            >
                                              <div className="col-md-2">
                                                <p className="font-11 weight-500 text-light-black text-left margin-0">
                                                  <Highlighter
                                                    highlightClassName="search-highlighter"
                                                    searchWords={[
                                                      this.state[
                                                        `search${k.id}`
                                                      ],
                                                    ]}
                                                    autoEscape={true}
                                                    textToHighlight={
                                                      verifyObject(
                                                        j,
                                                        "code",
                                                        null
                                                      )
                                                        ? `${j.code}`
                                                        : "-"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                              <div className="col-md-10">
                                                <p className="font-11 weight-400 text-dark-gray text-left margin-0">
                                                  <Highlighter
                                                    highlightClassName="search-highlighter"
                                                    searchWords={[
                                                      this.state[
                                                        `search${k.id}`
                                                      ],
                                                    ]}
                                                    autoEscape={true}
                                                    textToHighlight={
                                                      verifyObject(
                                                        j,
                                                        "description",
                                                        ""
                                                      ) !== ""
                                                        ? `${j.description}`
                                                        : "-"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      {this.state[`editDiagnosis${k.id}`] && (
                                        <form>
                                          <div className="form-group">
                                            <div className="form-row">
                                              <div className="col-md-12 pt-2">
                                                <CreatableSelect
                                                  placeholder="Search and select..."
                                                  isClearable
                                                  onChange={(value) =>
                                                    this._handleDropDownChange(
                                                      value,
                                                      "snomedCodeId",
                                                      k.id
                                                    )
                                                  }
                                                  components={{
                                                    DropdownIndicator: null,
                                                  }}
                                                  onInputChange={(value) => {
                                                    this.setState({
                                                      [`snomedCodeId${k.id}`]:
                                                        value,
                                                    });
                                                    this.searchUpdateSnomedCode(
                                                      value,
                                                      k.id
                                                    );
                                                  }}
                                                  onCreateOption={(value) =>
                                                    this._handleCustomCodeAdd(
                                                      value,
                                                      k.id
                                                    )
                                                  }
                                                  value={
                                                    this.state[
                                                      `snomedCodeId${k.id}`
                                                    ]
                                                      ? this.state[
                                                          `snomedCodeId${k.id}`
                                                        ]
                                                      : ""
                                                  }
                                                  inputValue={
                                                    this.state[
                                                      `snomedCodeId${k.id}`
                                                    ]
                                                      ? this.state[
                                                          `snomedCodeId${k.id}`
                                                        ]
                                                      : ""
                                                  }
                                                  name="snomedCodeId"
                                                  options={
                                                    this.state[
                                                      `snomedCodes${k.id}`
                                                    ] &&
                                                    this.state[
                                                      `snomedCodes${k.id}`
                                                    ].length > 0
                                                      ? this.state[
                                                          `snomedCodes${k.id}`
                                                        ]
                                                      : []
                                                  }
                                                  isLoading={
                                                    this.state[
                                                      `searching${k.id}`
                                                    ]
                                                  }
                                                  loadingMessage={() => (
                                                    <span>
                                                      Fetching Snomed ...
                                                    </span>
                                                  )}
                                                  onMenuScrollToBottom={
                                                    this.handleScroll
                                                  }
                                                />
                                                {/* <Select
                                              className={`form-control margin-0 ${
                                                this.state[`errors${k.id}`] &&
                                                this.state[`errors${k.id}`]
                                                  .snomedCodeId &&
                                                "danger-border"
                                              } indication-dropdown`}
                                              name="snomedCodeId"
                                              onPopupScroll={(e) =>
                                                this.handleScroll(e, k.id)
                                              }
                                              allowClear={
                                                this.state[`searching${k.id}`]
                                                  ? false
                                                  : true
                                              }
                                              value={
                                                this.state[
                                                  `snomedCodeId${k.id}`
                                                ]
                                              }
                                              showSearch={true}
                                              onChange={(value) =>
                                                this._handleDropDownChange(
                                                  value,
                                                  "snomedCodeId",
                                                  k.id
                                                )
                                              }
                                              onSearch={(value) => {
                                                let searchValue = value;
                                                this.searchUpdateSnomedCode(
                                                  searchValue,
                                                  k.id
                                                );
                                              }}
                                              suffixIcon={
                                                !this.state[
                                                  `isLoadmore${k.id}`
                                                ] &&
                                                this.state[
                                                  `searching${k.id}`
                                                ] && (
                                                  <Spin
                                                    className="custom-dropdown-spinner"
                                                    size="small"
                                                  />
                                                )
                                              }
                                              notFoundContent={
                                                !this.state[
                                                  `searching${k.id}`
                                                ] &&
                                                this.state[
                                                  `snomedCodes${k.id}`
                                                ] &&
                                                this.state[`snomedCodes${k.id}`]
                                                  .length === 0 && (
                                                  <span>
                                                    No codes available
                                                  </span>
                                                )
                                              }
                                              filterOption={false}
                                              dropdownRender={(menus) => {
                                                return (
                                                  <div ref={this.scrollDiv}>
                                                    {menus}
                                                    {this.state[
                                                      `isLoadmore${k.id}`
                                                    ] &&
                                                      this.state[
                                                        `searching${k.id}`
                                                      ] && (
                                                        <div
                                                          style={{
                                                            textAlign: "center",
                                                            padding: 10,
                                                          }}
                                                        >
                                                          <Spin size="small" />
                                                        </div>
                                                      )}
                                                  </div>
                                                );
                                              }}
                                            >
                                              {this.state[
                                                `snomedCodes${k.id}`
                                              ] &&
                                                this.state[
                                                  `snomedCodes${k.id}`
                                                ].map((k) => {
                                                  return (
                                                    <Select.Option
                                                      value={k.id}
                                                      key={k.id}
                                                    >
                                                      <Highlighter
                                                        highlightClassName="search-highlighter"
                                                        searchWords={[
                                                          this.state[
                                                            `search${k.id}`
                                                          ],
                                                        ]}
                                                        autoEscape={true}
                                                        textToHighlight={
                                                          verifyObject(
                                                            k,
                                                            "full_code",
                                                            null
                                                          ) !== null
                                                            ? `${k.full_code}`
                                                            : `-`
                                                        }
                                                      />
                                                    </Select.Option>
                                                  );
                                                })}
                                            </Select> */}
                                              </div>
                                            </div>
                                          </div>
                                          <div className="col-xs-12 bg-white push-10-b coding_modal_table push-10-t">
                                            <table className="table live-talk2-table">
                                              <thead>
                                                <tr>
                                                  <th className="font-10 weight-500 text-black">
                                                    Code
                                                  </th>
                                                  <th className="font-10 weight-500 text-black">
                                                    Description
                                                  </th>
                                                  <th className="font-10 weight-500 text-black"></th>
                                                </tr>
                                              </thead>
                                              <tbody>
                                                {this.state[
                                                  `selectedCodesArray${k.id}`
                                                ] &&
                                                  this.state[
                                                    `selectedCodesArray${k.id}`
                                                  ].length === 0 && (
                                                    <tr className="ng-hide">
                                                      <td
                                                        colSpan="3"
                                                        className="text-center text-dark-gray font-10 weight-400 default-padding"
                                                      >
                                                        No codes selected
                                                      </td>
                                                    </tr>
                                                  )}
                                                {this.state[
                                                  `selectedCodesArray${k.id}`
                                                ] &&
                                                  this.state[
                                                    `selectedCodesArray${k.id}`
                                                  ].map((code) => (
                                                    <tr>
                                                      <td className="font-10 weight-400 default-padding text-light-black">
                                                        {code.code}
                                                      </td>
                                                      <td className="font-10 weight-400 default-padding text-light-black">
                                                        {code.description}
                                                      </td>
                                                      <td className="font-10 weight-400 default-padding text-light-black text-right relative">
                                                        {" "}
                                                        <span
                                                          onClick={() =>
                                                            this._removeCode(
                                                              code,
                                                              k.id
                                                            )
                                                          }
                                                          className="text-right text-dark-blue weight-400 hide_outline"
                                                          ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                                          role="button"
                                                          tabIndex="0"
                                                        >
                                                          <img
                                                            src={deleteImage}
                                                            alt="Add Coding"
                                                            height="12"
                                                            width="12"
                                                          />
                                                        </span>
                                                      </td>
                                                    </tr>
                                                  ))}
                                              </tbody>
                                            </table>
                                          </div>

                                          {/* <div className="form-group">
                                        <div className="form-row">
                                          <div className="col-md-12">
                                            <label htmlFor="sel1">
                                              Feel free to freetext your
                                              Clinical Impressions
                                            </label>
                                            <Input
                                              className={`form-control margin-0 placeholder-10 addcomment ${
                                                this.state[`errors${k.id}`] &&
                                                this.state[`errors${k.id}`]
                                                  .snomedCodeId &&
                                                "danger-border"
                                              }`}
                                              name="snomedCodeText"
                                              value={
                                                this.state[
                                                  `snomedCodeText${k.id}`
                                                ]
                                              }
                                              onChange={(e) =>
                                                this._handleTextChange(e, k.id)
                                              }
                                              suffix={
                                                <img
                                                  width={12}
                                                  className={`cursor-pointer ${
                                                    isEmpty(
                                                      this.state[
                                                        `snomedCodeText${k.id}`
                                                      ]
                                                    )
                                                      ? "d-none"
                                                      : ""
                                                  }`}
                                                  src={addImage}
                                                  alt="Add"
                                                  onClick={
                                                    this._handleCustomCodeAdd
                                                  }
                                                />
                                              }
                                            />
                                            {this.state.errors && (
                                              <span className="validate-danger font-14">
                                                {this.state.errors.snomedCodeId}
                                              </span>
                                            )}
                                          </div>
                                        </div>
                                      </div> */}
                                        </form>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="encounter_wrap">
                                  <div className="form-row push-10-b">
                                    <div className="col-md-12 consultant_review">
                                      <p className="font-12 weight-400 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                        Management Plan
                                      </p>
                                      {this.state[`editManagement${k.id}`] && (
                                        <span
                                          className="cursor font-12 weight-400 text-whilte text-red d-inline-flex align-items-center add-coding"
                                          onClick={(event) => {
                                            event.stopPropagation();

                                            this.setState({
                                              [`editManagement${k.id}`]:
                                                !this.state[
                                                  `editManagement${k.id}`
                                                ],
                                              [`management${k.id}`]: "",
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                          <span>clear</span>
                                        </span>
                                      )}
                                      {this.state[`editShow${k.id}`] &&
                                        !this.state[`editManagement${k.id}`] &&
                                        verifyObject(
                                          k,
                                          "consultation_log.management_plan",

                                          null
                                        ) !== null && (
                                          <a
                                            onClick={() =>
                                              this._handleEdit(
                                                "editManagement",
                                                k.id
                                              )
                                            }
                                            className="add-coding cursor font-12 weight-400"
                                          >
                                            {" "}
                                            <EditOutlined /> Edit
                                          </a>
                                        )}

                                      <div className="div-border consultant_data input-bg-f9f9f9">
                                        {k !== null && (
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                            {verifyObject(
                                              k,
                                              "consultation_log.management_plan",
                                              ""
                                            ) !== "" ? (
                                              <Highlighter
                                                highlightClassName="search-highlighter"
                                                searchWords={[
                                                  this.state.search,
                                                ]}
                                                autoEscape={true}
                                                textToHighlight={verifyObject(
                                                  k,
                                                  "consultation_log.management_plan",
                                                  "-"
                                                )}
                                              />
                                            ) : (
                                              "-"
                                            )}
                                          </p>
                                        )}
                                        {verifyObject(
                                          k,
                                          "consultation_log.edited_managment_plan",
                                          []
                                        ).map((j) => {
                                          if (j.management_plan !== null) {
                                            return (
                                              <div className="highlightedtext push-5-t normal-lineheight padding-5-t">
                                                {" "}
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70">
                                                  <Highlighter
                                                    highlightClassName="search-highlighter"
                                                    searchWords={[
                                                      this.state.search,
                                                    ]}
                                                    autoEscape={true}
                                                    textToHighlight={
                                                      j.management_plan
                                                    }
                                                  />
                                                </p>
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                  Edited by {j.edited_by} On{" "}
                                                  {moment(j.date).format(
                                                    DATE_FORMAT_24_HR
                                                  )}
                                                </p>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                      </div>

                                      {this.state[`editManagement${k.id}`] && (
                                        <div className="addtextdiv push-10-t relative">
                                          <Fragment>
                                            <Input.TextArea
                                              autoSize={true}
                                              type="textarea"
                                              className="form-control custom-input font-11 weight-400 no-focus padding-right-55"
                                              name="management"
                                              value={
                                                this.state[`management${k.id}`]
                                              }
                                              onChange={(e) =>
                                                this._handleTextChange(e, k.id)
                                              }
                                              placeholder="Enter Text Here..."
                                              rows="2"
                                            />
                                          </Fragment>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="encounter_wrap">
                                  <div className="form-row push-10-b">
                                    <div className="col-md-12 consultant_review">
                                      <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                                        Prescription Plan
                                      </p>
                                      {this.state[
                                        `editPrescriptionPlan${k.id}`
                                      ] && (
                                        <span
                                          className="cursor font-12 weight-400 text-whilte text-red d-inline-flex align-items-center add-coding"
                                          onClick={(event) => {
                                            event.stopPropagation();

                                            this.setState({
                                              [`editPrescriptionPlan${k.id}`]:
                                                !this.state[
                                                  `editPrescriptionPlan${k.id}`
                                                ],
                                              [`prescription_plan${k.id}`]: "",
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                          <span>Clear</span>
                                        </span>
                                      )}
                                      {this.state[`editShow${k.id}`] &&
                                        !this.state[
                                          `editPrescriptionPlan${k.id}`
                                        ] &&
                                        this.props.userRole !==
                                          ROLE_SUPERINTENDENT_PHARMACIST && (
                                          <a
                                            onClick={() =>
                                              this._handleEdit(
                                                "editPrescriptionPlan",
                                                k.id
                                              )
                                            }
                                            className="add-coding cursor font-12 weight-400"
                                          >
                                            {" "}
                                            <EditOutlined /> Edit
                                          </a>
                                        )}

                                      {/* <div className="border-box col-md-12 input-bg-f9f9f9"> */}
                                      <div className="div-border consultant_data input-bg-f9f9f9">
                                        {k !== null && (
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                            {verifyObject(
                                              k,
                                              "consultation_log.prescription_plan",
                                              ""
                                            ) !== "" &&
                                            verifyObject(
                                              k,
                                              "consultation_log.prescription_plan",
                                              ""
                                            ) !== null ? (
                                              <Highlighter
                                                highlightClassName="search-highlighter"
                                                searchWords={[
                                                  this.state.search,
                                                ]}
                                                autoEscape={true}
                                                textToHighlight={verifyObject(
                                                  k,
                                                  "consultation_log.prescription_plan",
                                                  "-"
                                                )}
                                              />
                                            ) : (
                                              "-"
                                            )}
                                          </p>
                                        )}
                                        {verifyObject(
                                          k,
                                          "consultation_log.edited_prescription_plan",
                                          []
                                        ).map((j) => {
                                          if (j.prescription_plan !== null) {
                                            return (
                                              <div className="highlightedtext push-5-t normal-lineheight padding-5-t">
                                                {" "}
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 prelinetext">
                                                  {/* {j.history} */}
                                                  <Highlighter
                                                    highlightClassName="search-highlighter"
                                                    searchWords={[
                                                      this.state.search,
                                                    ]}
                                                    autoEscape={true}
                                                    textToHighlight={
                                                      j.prescription_plan
                                                    }
                                                  />
                                                </p>
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                  Edited by {j.edited_by} On{" "}
                                                  {moment(j.date).format(
                                                    DATE_FORMAT_24_HR
                                                  )}
                                                </p>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                      </div>

                                      {this.state[
                                        `editPrescriptionPlan${k.id}`
                                      ] && (
                                        <div className="addtextdiv push-10-t relative">
                                          <Fragment>
                                            <Input.TextArea
                                              autoSize={true}
                                              type="textarea"
                                              className="form-control custom-input font-11 weight-400 no-focus padding-right-55"
                                              name="prescription_plan"
                                              value={
                                                this.state[
                                                  `prescription_plan${k.id}`
                                                ]
                                              }
                                              onChange={(e) =>
                                                this._handleTextChange(e, k.id)
                                              }
                                              placeholder="Enter Text Here..."
                                              rows="2"
                                            />
                                          </Fragment>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="encounter_wrap">
                                  <div className="form-row push-10-b">
                                    <div className="col-md-12 consultant_review">
                                      <p className="font-12 weight-400 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                        BarCode{" "}
                                      </p>
                                      {this.state[`editBarcode${k.id}`] && (
                                        <span
                                          className="cursor font-12 weight-400 text-whilte text-red d-inline-flex align-items-center add-coding"
                                          onClick={(event) => {
                                            event.stopPropagation();

                                            this.setState({
                                              [`editBarcode${k.id}`]:
                                                !this.state[
                                                  `editBarcode${k.id}`
                                                ],
                                              [`barcode${k.id}`]: "",
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                          <span>clear</span>
                                        </span>
                                      )}
                                      {this.state[`editShow${k.id}`] &&
                                        !this.state[`editBarcode${k.id}`] && (
                                          // verifyObject(
                                          //   k,
                                          //   "consultation_log.barcode",
                                          //   ""
                                          // )
                                          //  &&
                                          <a
                                            onClick={() =>
                                              this._handleEdit(
                                                "editBarcode",
                                                k.id
                                              )
                                            }
                                            className="add-coding cursor font-12 weight-400"
                                          >
                                            {" "}
                                            <EditOutlined /> Edit
                                          </a>
                                        )}

                                      <div className="div-border consultant_data input-bg-f9f9f9">
                                        {k !== null && (
                                          <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight prelinetext">
                                            {verifyObject(
                                              k,
                                              "consultation_log.barcode",
                                              ""
                                            ) !== "" &&
                                            verifyObject(
                                              k,
                                              "consultation_log.barcode",
                                              ""
                                            ) !== null ? (
                                              <Highlighter
                                                highlightClassName="search-highlighter"
                                                searchWords={[
                                                  this.state.search,
                                                ]}
                                                autoEscape={true}
                                                textToHighlight={verifyObject(
                                                  k,
                                                  "consultation_log.barcode",
                                                  "-"
                                                )}
                                              />
                                            ) : (
                                              "-"
                                            )}
                                          </p>
                                        )}
                                        {verifyObject(
                                          k,
                                          "consultation_log.edited_barcode",
                                          null
                                        ) !== null &&
                                          k.consultation_log.edited_barcode.map(
                                            (j) => {
                                              if (k.barcode !== null) {
                                                return (
                                                  <div className="highlightedtext push-5-t padding-5-t normal-lineheight">
                                                    {" "}
                                                    <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70">
                                                      {/* {} */}

                                                      <Highlighter
                                                        highlightClassName="search-highlighter"
                                                        searchWords={[
                                                          this.state.search,
                                                        ]}
                                                        autoEscape={true}
                                                        textToHighlight={
                                                          j.barcode
                                                        }
                                                      />
                                                    </p>
                                                    <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5">
                                                      Edited by {j.edited_by} On{" "}
                                                      {moment(j.date).format(
                                                        DATE_FORMAT_24_HR
                                                      )}
                                                    </p>
                                                  </div>
                                                );
                                              }
                                              return null;
                                            }
                                          )}
                                      </div>
                                      {/* <div className="border-box col-md-12 input-bg-f9f9f9">
                                    <div className="form-row">
                                      <div className="col-md-12">
                                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "consultation_log.barcode",
                                                null
                                              ) !== null
                                                ? `${k.consultation_log.barcode}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </div> */}
                                      {this.state[`editBarcode${k.id}`] && (
                                        <div className="addtextdiv push-10-t relative">
                                          <Fragment>
                                            <Input.TextArea
                                              autoSize={true}
                                              type="textarea"
                                              className="form-control custom-input font-11 weight-400 no-focus padding-right-55"
                                              name="barcode"
                                              value={
                                                this.state[`barcode${k.id}`]
                                              }
                                              onChange={(e) =>
                                                this._handleTextChange(e, k.id)
                                              }
                                              placeholder="Enter Text Here..."
                                              rows="2"
                                            />
                                            {/* {!isEmpty(
                                          this.state[`barcode${k.id}`]
                                        ) && (
                                          <div className="actionicons">
                                            <a
                                              onClick={() => {
                                                this._handleUpdate(
                                                  "barcode",
                                                  "editBarcode",
                                                  k.id
                                                );
                                              }}
                                              className="margin-right-10 text-right in-block vertical-middle text-dark-blue weight-400 hide_outline normal-lineheight cursor"
                                            >
                                              {this.state[
                                                `updatingDetail${k.id}`
                                              ] ? (
                                                <Spin size="small" />
                                              ) : (
                                                <img
                                                  src={verifiedImage}
                                                  alt="Verified"
                                                  height="17"
                                                  width="17"
                                                />
                                              )}
                                            </a>
                                            <a
                                              onClick={() => {
                                                this.setState({
                                                  [`barcode${k.id}`]: "",
                                                });
                                                this._handleEdit(
                                                  "editBarcode",
                                                  k.id
                                                );
                                              }}
                                              className="margin-right-5 text-right in-block vertical-middle text-dark-blue weight-400 hide_outline normal-lineheight cursor"
                                            >
                                              <img
                                                src={unverifiedImage}
                                                alt="Unverified"
                                                height="12"
                                                width="12"
                                              />
                                            </a>
                                          </div>
                                        )} */}
                                          </Fragment>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                                <div className="encounter_wrap">
                                  <div className="form-row push-10-b">
                                    <div className="col-md-12 consultant_review">
                                      <p className="font-12 weight-400 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                        Informational Outcomes{" "}
                                      </p>
                                      {this.state[`editOutcome${k.id}`] && (
                                        <span
                                          className="cursor font-12 weight-400 text-whilte text-red d-inline-flex align-items-center add-coding"
                                          onClick={(event) => {
                                            event.stopPropagation();

                                            this.setState({
                                              [`editOutcome${k.id}`]:
                                                !this.state[
                                                  `editOutcome${k.id}`
                                                ],
                                              [`treatment${k.id}`]: "",
                                              [`followUp${k.id}`]: "",
                                              [`referral${k.id}`]: "",
                                              [`comment${k.id}`]: "",
                                            });
                                          }}
                                        >
                                          <CloseOutlined />
                                          <span>clear</span>
                                        </span>
                                      )}
                                      {this.state[`editShow${k.id}`] &&
                                        !this.state[`editOutcome${k.id}`] &&
                                        verifyObject(
                                          k,
                                          "consultation_log.outcome.treatment",
                                          ""
                                        ) && (
                                          <a
                                            onClick={() =>
                                              //TODO: need to complete this //complted done
                                              this._handleEdit(
                                                "editOutcome",
                                                k.id
                                              )
                                            }
                                            className="add-coding cursor font-12 weight-400"
                                          >
                                            {" "}
                                            <EditOutlined /> Edit
                                          </a>
                                        )}

                                      <div className="div-border consultant_data input-bg-f9f9f9">
                                        <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                          Treatment :{" "}
                                          {verifyObject(
                                            k,
                                            "consultation_log.outcome.treatment",
                                            "-"
                                          )}
                                        </p>
                                        <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                          Follow Up :{" "}
                                          {verifyObject(
                                            k,
                                            "consultation_log.outcome.follow_up",
                                            "-"
                                          )}
                                        </p>
                                        <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                          Referral :{" "}
                                          {verifyObject(
                                            k,
                                            "consultation_log.outcome.referral",
                                            "-"
                                          )}
                                        </p>
                                        <p className="margin-0 font-11 weight-400 text-dark-gray normal-lineheight">
                                          Comment :{" "}
                                          {verifyObject(
                                            k,
                                            "consultation_log.outcome.comment",
                                            "-"
                                          )}
                                        </p>
                                      </div>

                                      <div className="div-border consultant_data push-10-t input-bg-f9f9f9">
                                        {verifyObject(
                                          k,
                                          "consultation_log.edited_outcome",
                                          []
                                        ).map((j) => {
                                          if (
                                            Object.keys(j.outcome).length !== 0
                                          ) {
                                            return (
                                              <div className="edited-outcome">
                                                {" "}
                                                <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                  Treatment :{" "}
                                                  {verifyObject(
                                                    j,
                                                    "outcome.treatment",
                                                    "-"
                                                  )}
                                                </p>
                                                <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                  Follow Up :{" "}
                                                  {verifyObject(
                                                    j,
                                                    "outcome.follow_up",
                                                    "-"
                                                  )}
                                                </p>
                                                <p className="margin-0 font-11 weight-400 pink-text normal-lineheight">
                                                  Referral :{" "}
                                                  {verifyObject(
                                                    j,
                                                    "outcome.referral",
                                                    "-"
                                                  )}
                                                </p>
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-left width-70 margin-top--5-imp">
                                                  Comment :{" "}
                                                  {verifyObject(
                                                    j,
                                                    "outcome.comment",
                                                    "-"
                                                  )}
                                                </p>
                                                <p className="margin-0 font-11 normal-lineheight weight-400 pink-text in-block vertical-middle text-right width-30-minus-5 margin-top--5-imp">
                                                  Edited by {j.edited_by} On{" "}
                                                  {moment(j.date).format(
                                                    DATE_FORMAT_24_HR
                                                  )}
                                                </p>
                                              </div>
                                            );
                                          }
                                          return null;
                                        })}
                                      </div>
                                      <InformationalOutcomeModal
                                        _handleSubmit={() =>
                                          this._handleModalSubmission(k.id)
                                        }
                                        _hideModal={() => this._hideModal(k.id)}
                                        visible={
                                          this.state[`editOutcome${k.id}`]
                                        }
                                        changing={
                                          this.state[`addingDetail${k.id}`]
                                        }
                                        submitting={
                                          this.state[`addingDetail${k.id}`]
                                        }
                                        _handleTextChange={(e) =>
                                          this._handleTextChange(e, k.id)
                                        }
                                        {...this.state}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              {/*NOTE: commented add code as it's taken on screen, if requir in popup again need to uncomment popup and need some code revert for on dropdown selection of snomed code save button click functionality performed directly on selction */}

                              {/* <ModalPopUp
                            title={"Code Selection"}
                            handleCancel={this._hideCodeModal}
                            visible={this.state[`showCodeModal${k.id}`]}
                            footer={true}
                            closable={false}
                            handleOk={() => {
                              if (consultationDetail === null) {
                                this.setState({
                                  showCodeModal: false,
                                  selectedSnomedCodesArray: uniq([
                                    ...selectedSnomedCodesArray,
                                    ...selectedCodesArray,
                                  ]),
                                });
                              } else {
                                this._handleSnomedCodeUpdate();
                              }
                            }}
                            okText={"Save"}
                            okButtonProps={{
                              disabled: selectedCodesArray.length === 0,
                            }}
                            loading={changing}
                            cancelBtnclassName="custom_cancel_btn"
                            maskClosable={false}
                          >
                            <form>
                              <div className="form-group">
                                <div className="form-row">
                                  <div className="col-md-12">
                                    <label htmlFor="sel1">Indication</label>
                                    <Select
                                      className={`form-control margin-0 ${
                                        errors &&
                                        errors.snomedCodeId &&
                                        "danger-border"
                                      } indication-dropdown`}
                                      name="snomedCodeId"
                                      onPopupScroll={this.handleScroll}
                                      allowClear={searching ? false : true}
                                      value={this.state.snomedCodeId}
                                      showSearch={true}
                                      onChange={(value) =>
                                        this._handleDropDownChange(
                                          value,
                                          "snomedCodeId"
                                        )
                                      }
                                      onSearch={(value) => {
                                        let searchValue = value;
                                        this.searchUpdate(searchValue);
                                      }}
                                      suffixIcon={
                                        !isLoadmore &&
                                        searching && (
                                          <Spin
                                            className="custom-dropdown-spinner"
                                            size="small"
                                          />
                                        )
                                      }
                                      notFoundContent={
                                        !searching &&
                                        snomedCodes.length === 0 && (
                                          <span>No codes available</span>
                                        )
                                      }
                                      filterOption={false}
                                      dropdownRender={(menus) => {
                                        return (
                                          <div ref={this.scrollDiv}>
                                            {menus}
                                            {isLoadmore && searching && (
                                              <div
                                                style={{
                                                  textAlign: "center",
                                                  padding: 10,
                                                }}
                                              >
                                                <Spin size="small" />
                                              </div>
                                            )}
                                          </div>
                                        );
                                      }}
                                    >
                                      {snomedCodes.map((k) => {
                                        return (
                                          <Option value={k.id}>
                                            <Highlighter
                                              highlightClassName="search-highlighter"
                                              searchWords={[this.state.search]}
                                              autoEscape={true}
                                              textToHighlight={
                                                verifyObject(
                                                  k,
                                                  "full_code",
                                                  null
                                                ) !== null
                                                  ? `${k.full_code}`
                                                  : `-`
                                              }
                                            />
                                          </Option>
                                        );
                                      })}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                              <div className="col-xs-12 bg-white push-10-b coding_modal_table push-10-t">
                                <table className="table live-talk2-table">
                                  <thead>
                                    <tr>
                                      <th className="font-10 weight-500 text-black">
                                        Code
                                      </th>
                                      <th className="font-10 weight-500 text-black">
                                        Description
                                      </th>
                                      <th className="font-10 weight-500 text-black"></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {selectedCodesArray.length === 0 && (
                                      <tr className="ng-hide">
                                        <td
                                          colSpan="3"
                                          className="text-center text-dark-gray font-10 weight-400 default-padding"
                                        >
                                          No codes selected
                                        </td>
                                      </tr>
                                    )}
                                    {selectedCodesArray.map((code) => (
                                      <tr>
                                        <td className="font-10 weight-400 default-padding text-light-black">
                                          {code.code}
                                        </td>
                                        <td className="font-10 weight-400 default-padding text-light-black">
                                          {code.description}
                                        </td>
                                        <td className="font-10 weight-400 default-padding text-light-black text-right relative">
                                          {" "}
                                          <span
                                            onClick={() =>
                                              this._removeCode(code)
                                            }
                                            className="text-right text-dark-blue weight-400 hide_outline"
                                            ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                            role="button"
                                            tabIndex="0"
                                          >
                                            <img
                                              src={deleteImage}
                                              alt="Add Coding"
                                              height="12"
                                              width="12"
                                            />
                                          </span>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>

                              <div className="form-group">
                                <div className="form-row">
                                  <div className="col-md-12">
                                    <label htmlFor="sel1" className="">
                                      Feel free to freetext your Clinical
                                      Impressions
                                    </label>
                                    <Input
                                      className={`form-control placeholder-10 addcomment margin-0 ${
                                        errors &&
                                        errors.snomedCodeId &&
                                        "danger-border"
                                      }`}
                                      name="snomedCodeText"
                                      placeholder="Add custom code"
                                      value={snomedCodeText}
                                      onChange={this._handleTextChange}
                                      suffix={
                                        !isEmpty(snomedCodeText) && (
                                          <img
                                            width={12}
                                            className="cursor-pointer"
                                            src={addImage}
                                            alt="Add"
                                            onClick={this._handleCustomCodeAdd}
                                          />
                                        )
                                      }
                                    />
                                    {this.state.errors && (
                                      <span className="validate-danger font-14">
                                        {this.state.errors.snomedCodeId}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </form>
                          </ModalPopUp> */}
                            </Col>
                          </div>
                        </Panel>
                      );
                    })}
                </Collapse>
              </div>

              <div className="col-md-12">
                {!isLoading && (
                  <Pagination
                    data={preEncounters}
                    onPageChange={this._onPageChange}
                  />
                )}
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    userId: verifyObject(state.localStore, "user.id", false),
    preEncounters: verifyObject(state, "preEnccounter", []),
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", false),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    selectedSidebarKey: verifyObject(state, "dynamicTab.sidebar_key", ["1"]),
    userRole: verifyObject(state, "localStore.user.role", null),
    prev_appt_tab_key: verifyObject(
      state.localStore,
      "prev_appt_tab_key",
      "finished"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    preEncountersActions: bindActionCreators(preEncountersActions, dispatch),
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onRequestSetComponentID,
        onSetDynamicInput,
        onSetSidebarKey,
      },
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreEncountersContainer);

export default ConnectedComponent;

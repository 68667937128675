import {get, post, patch, deletee} from "./index";

export const getConsultationDetailAPI = (params) => {
  const request = {
    subUrl: `/consultations/consultation_details.json`,
    params,
  };
  return get(request);
};

export const addConsultationDetailAPI = (data) => {
  const request = {
    subUrl: `/consultations.json`,
    data,
  };
  return post(request);
};
export const addHealthAssessmentPatientAPI = (data) => {
  const request = {
    subUrl: `/health_summaries/create_health_summary`,
    data,
  };
  return post(request);
};

export const endPrescriberAppointment = (data, id) => {
  const request = {
    subUrl: `/prescribers/appointments/${id ? id : data.id}/end_appointment`,
    data,
  };
  return post(request);
};

export const markAsReviewedAPI = (data) => {
  const request = {
    subUrl: `/consultations/mark_consultation_as_reviewed`,
    data,
  };
  return post(request);
};
// prescribers/appointments/47970/end_appointment

export const updateConsultationDetailAPI = (data, fromCurrent) => {
  console.log("data--------", data);
  let formData = new FormData();
  if (fromCurrent) {
    data.from_pre_consultation &&
      formData.set("from_pre_consultation", data.from_pre_consultation);

    data.consultation_id &&
      formData.set("consultation_id", data.consultation_id);
    data.consultation.history &&
      formData.set("consultation[history]", data.consultation.history);
    data.consultation.examination &&
      formData.set("consultation[examination]", data.consultation.examination);
    data.consultation.barcode &&
      formData.set("consultation[barcode]", data.consultation.barcode);
    (data.consultation.from_review_section || data.from_review_section) &&
      formData.set(
        "from_review_section",
        data.consultation.from_review_section
          ? data.consultation.from_review_section
          : data.from_review_section
      );
    data.consultation.management_plan &&
      formData.set(
        "consultation[management_plan]",
        data.consultation.management_plan
      );
    data.consultation.prescription_plan &&
      formData.set(
        "consultation[prescription_plan]",
        data.consultation.prescription_plan
      );
    data.consultation.outcome &&
      data.consultation.outcome.treatment &&
      formData.set(
        "consultation[outcome][treatment]",
        data.consultation.outcome.treatment
      );
    data.consultation.outcome &&
      data.consultation.outcome.follow_up &&
      formData.set(
        "consultation[outcome][follow_up]",
        data.consultation.outcome.follow_up
      );
    data.consultation.outcome &&
      data.consultation.outcome.referral &&
      formData.set(
        "consultation[outcome][referral]",
        data.consultation.outcome.referral
      );
    data.consultation.outcome &&
      data.consultation.outcome.comment &&
      formData.set(
        "consultation[outcome][comment]",
        data.consultation.outcome.comment
      );
    data.snomed_codes &&
      data.snomed_codes.length > 0 &&
      // eslint-disable-next-line array-callback-return
      data.snomed_codes.map((item, index) => {
        item.id && formData.set(`snomed_codes[${index}][id]`, item.id);
        formData.set(`snomed_codes[${index}][code]`, item.code);
        formData.set(`snomed_codes[${index}][description]`, item.description);
      });
  } else {
    console.log("Data to be updated", data);
    data.from_pre_consultation &&
      formData.set("from_pre_consultation", data.from_pre_consultation);
    data.consultation_id &&
      formData.set("consultation_id", data.consultation_id);
    data.from_review_section &&
      formData.set("from_review_section", data.from_review_section);
    data.history && formData.set("consultation[history]", data.history);
    data.examination &&
      formData.set("consultation[examination]", data.examination);
    data.barcode && formData.set("consultation[barcode]", data.barcode);
    data.management_plan &&
      formData.set("consultation[management_plan]", data.management_plan);
    data.prescription_plan &&
      formData.set("consultation[prescription_plan]", data.prescription_plan);
    data.barcode && formData.set("consultation[barcode]", data.barcode);
    data.outcome &&
      data.outcome.treatment &&
      formData.set("consultation[outcome][treatment]", data.outcome.treatment);
    data.outcome &&
      data.outcome.follow_up &&
      formData.set("consultation[outcome][follow_up]", data.outcome.follow_up);
    data.outcome &&
      data.outcome.referral &&
      formData.set("consultation[outcome][referral]", data.outcome.referral);
    data.outcome &&
      data.outcome.comment &&
      formData.set("consultation[outcome][comment]", data.outcome.comment);
    data.snomed_codes &&
      data.snomed_codes.length > 0 &&
      // eslint-disable-next-line array-callback-return
      data.snomed_codes.map((item, index) => {
        item.id && formData.set(`snomed_codes[${index}][id]`, item.id);
        formData.set(`snomed_codes[${index}][code]`, item.code);
        formData.set(`snomed_codes[${index}][description]`, item.description);
      });
  }

  console.log("formdata=-------", formData);
  const request = {
    subUrl: `/consultations/update_consultation.json`,
    data: formData,
  };
  return patch(request);
};

export const deletePrescriptionAPI = (params) => {
  const request = {
    subUrl: `/prescriptions/delete_prescription.json`,
    params,
  };
  return deletee(request);
};

export const getPatientHealthAssessmentDetailApi = (params) => {
  const request = {
    subUrl: `/health_summaries/list_health_summary_details?patient_id=${params}`,
    // params,
  };
  return get(request);
};
export const updateAppointmentReasonApi = (params) => {
  console.log("params----------------------", params);
  let formData = new FormData();
  params.reason && formData.set("reason", params.reason);
  const request = {
    subUrl: `/prescribers/appointments/${params.id}/update_reason.json`,
    data: formData,
  };
  return patch(request);
};
export const handleAutoSaveConsultationAPI = (data) => {
  const request = {
    subUrl: `/temp_consultation_infos`,
    data,
  };
  return post(request);
};

import { deletee, get, patch, post } from "./index";

export const getConsultationPrescription = (params) => {
  const request = {
    subUrl: `/prescriptions.json`,
    params,
  };
  return get(request);
};

export const addConsultationPrescription = (data) => {
  const request = {
    subUrl: `/prescriptions.json`,
    data,
  };
  return post(request);
};

export const calculatePriceAndPackSize = (data) => {
  const request = {
    subUrl: `/drugs/calculate_price.json`,
    data,
  };
  return post(request);
};

export const fetchBNFMetaData = (params) => {
  const request = {
    subUrl: `/drugs/${params.id}/bnf_meta_data.json`,
  };
  return get(request);
};

export const getConsultationSickNote = (params) => {
  const request = {
    subUrl: `/sick_notes/appointment_sick_note.json`,
    params,
  };
  return get(request);
};

export const addConsultationSickNote = (data) => {
  const request = {
    subUrl: `/consultations/add_sick_note.json`,
    data,
  };
  return post(request);
};

export const addShortCodeAPI = (data) => {
  const request = {
    subUrl: `/doctor/short_codes`,
    data,
  };
  return post(request);
};

export const updateShortCodeAPI = (data) => {
  const request = {
    subUrl: `/doctor/short_codes/${data.id}.json`,
    data,
  };
  return patch(request);
};

export const deleteShortCodeAPI = (data) => {
  const request = {
    subUrl: `/doctor/short_codes/${data.id}.json`,
    data,
  };
  return deletee(request);
};

export const getConsultationRefferalNotes = (params) => {
  const request = {
    subUrl: `/referral_notes/get_referral_note.json`,
    params,
  };
  return get(request);
};

export const getReferralSpecialities = (params) => {
  const request = {
    subUrl: `/referral_notes/list_specialties.json`,
    params,
  };
  return get(request);
};

export const addReferralNote = (data) => {
  const request = {
    subUrl: `/referral_notes.json`,
    data,
  };
  return post(request);
};

export const getConsultationChatAndVideoHistory = (params) => {
  const request = {
    subUrl: `/chats/chat_video_history.json`,
    params,
  };
  return get(request);
};

export const getConsultationScreenshots = (params) => {
  const request = {
    subUrl: `/screenshots.json`,
    params,
  };
  return get(request);
};

export const getOnlyChatHistory = (params) => {
  const request = {
    subUrl: `/chats/${params.id}.json`,
    params,
  };
  return get(request);
};

export const updatePackPrice = (data,id) => {
  const request = {
    subUrl: `/actual_drug_prices/${id}`,
    data,
  };
  return patch(request);
};

import React, {Fragment, useState} from "react";
import {Select} from "antd";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  modifiedDataObjectToArrayByIDs,
} from "../../utilities/utils";
import ModalPopUp from "../../components/common/ModalPopUp";
import {isArray} from "lodash";
import NoFoundMessage from "../../components/common/NoFoundMessage";
import CommonLoader from "../../components/common/CommonLoader";
import "react-phone-input-2/lib/style.css";
import {useFormFields} from "../../hooks/formHook";
import {addMemberContact} from "../../services/membersContacts";
import validator from "validator";
import {spaceRegex} from "../../constants/common";

export default function MembersContactTable({
  dataSource,
  _onReuestList,
  appointmentId,
}) {
  let {isLoading} = dataSource;
  console.log("Data Source", dataSource);
  let membersList = modifiedDataObjectToArrayByIDs(dataSource);
  const [modalOpen, setModalOpen] = useState(false);
  const [contryCode, setContryCode] = useState("+91");
  const [errors, setErrors] = useState(false);
  const [fields, handleFieldChange] = useFormFields({
    memberName: "",
    memberContact: "",
  });
  const [IsLoadingData, setLoading] = useState(false);

  const _handleModal = (record) => {
    setModalOpen(!modalOpen);
  };

  const _handleCancel = () => {
    setLoading(false);
    handleFieldChange({
      target: {name: "memberName", value: ""},
    });
    handleFieldChange({
      target: {name: "memberContact", value: ""},
    });
  };
  const validate = (inputs) => {
    const errors = {};
    if (
      inputs.memberName !== undefined &&
      !validator.isEmpty(inputs.memberName) &&
      spaceRegex.test(inputs.memberName) === false
    ) {
      errors.memberName = "Name  is invalid";
    }
    if (
      inputs.memberContact !== undefined &&
      validator.isEmpty(inputs.memberContact) === false
    ) {
      if (!validator.isLength(inputs.memberContact, {min: 10, max: 10})) {
        errors.memberContact = "Mobile no should be of 10 digit";
      }
    }
    setLoading(false);

    return errors;
  };
  const _handleSubmit = async (e) => {
    console.log("INNN");
    e.preventDefault();
    await setLoading(true);
    let payload = {
      appointment_id: appointmentId,
      member: {
        name: fields.memberName,
        contact: `${contryCode}${fields.memberContact}`,
      },
    };
    const validationErrors = validate(fields);
    const noErrors = Object.keys(validationErrors).length === 0;
    console.log("Errors", validationErrors);
    setErrors(validationErrors);
    if (noErrors) {
      try {
        await addMemberContact(payload);
        setModalOpen(false);
        await setLoading(false);

        _onReuestList();
        _handleCancel();
        //   successToast({ content: response.data.message });
      } catch (e) {
        const err = getErrorObject(e);
        errorToast({content: err.message});
        _handleCancel();
        await setLoading(false);
      }
    }
  };

  // let { mem} = fields
  return (
    <Fragment>
      <div className="padding-20-t push-20-b familyrecords_wrapper border-top padding-right-10 padding-left-10 push-5-t">
        <div className="form-row align-items-center">
          <div className="col-md-6">
            <h6 className="font-14 weight-500 text-default-color m-0">
              Members Contact List
            </h6>
          </div>
        </div>

        <div className="form-row margin-bottom-10 push-10-t">
          <div className="col-md-12 text-right">
            <a
              onClick={() => setModalOpen(true)}
              className="font-14 weight-500 text-blue"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="12"
                height="12"
                viewBox="0 0 12 12"
                className="push-5-r"
              >
                <g fill="none" fill-rule="evenodd">
                  <g fill="#1445b7" fill-rule="nonzero">
                    <g>
                      <g>
                        <path
                          d="M9.854 0H2.142C.96.001.002.96 0 2.142v7.712c.001 1.183.96 2.141 2.142 2.143h7.712c1.183-.002 2.141-.96 2.143-2.143V2.142C11.995.96 11.037.002 9.854 0zm1.286 9.854c0 .71-.576 1.286-1.286 1.286H2.142c-.71 0-1.285-.576-1.285-1.286V2.142c0-.71.575-1.285 1.285-1.285h7.712c.71 0 1.286.575 1.286 1.285v7.712z"
                          transform="translate(-1214 -167) translate(242 121) translate(972 46)"
                        />
                        <path
                          d="M8.14 5.57H6.428V3.856c0-.237-.192-.428-.429-.428-.236 0-.428.191-.428.428V5.57H3.856c-.237 0-.428.192-.428.428 0 .237.191.429.428.429H5.57V8.14c0 .236.192.428.428.428.237 0 .429-.192.429-.428V6.427H8.14c.236 0 .428-.192.428-.429 0-.236-.192-.428-.428-.428z"
                          transform="translate(-1214 -167) translate(242 121) translate(972 46)"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </svg>

              <span className="vertical-middle">Add</span>
            </a>
          </div>
        </div>

        {isLoading && (
          <div>
            <CommonLoader />
          </div>
        )}

        <div className=" family_record_content">
          {!isLoading &&
            membersList &&
            isArray(membersList) &&
            membersList.map((k, index) => {
              return (
                <table className="w-100 border custom_table push-5-b bg-f9f9f9 border-radius-2">
                  <tbody>
                    <tr>
                      <td>
                        <p className="padding-5-t padding-5-b padding-left-10 padding-right-10 font-14 weight-400 margin-0 text-gray">
                          {verifyObject(k, "name", "")}
                        </p>
                        <p className="padding-3-t padding-5-b padding-left-10 padding-right-10 font-14 weight-400 margin-0 text-gray">
                          {verifyObject(k, "contact", "")}
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              );
            })}
          {!isLoading &&
            membersList &&
            isArray(membersList) &&
            membersList.length === 0 && (
              <NoFoundMessage message="No members found." />
            )}
        </div>

        <div className="col-md-12">
          {/* {!isLoading && (
            <Paginatio
              data={familyRecords}
              onPageChange={this._onPageChange}
            />
          )} */}
        </div>
        <ModalPopUp
          title={"Add Member"}
          handleCancel={_handleModal}
          visible={modalOpen}
          footer={true}
          handleOk={_handleSubmit}
          okText={"Save"}
          loading={IsLoadingData}
          okButtonProps={{
            disabled: fields.memberContact === "" || fields.memberName === "",
          }}
          cancelBtnClass="custom_cancel_btn"
          closable={false}
          maskClosable={false}
        >
          <form>
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label for="sel1" className="margin-bottom-6 ">
                    Name
                  </label>
                  <input
                    className={`form-control margin-0`}
                    name="memberName"
                    value={fields.memberName}
                    onChange={handleFieldChange}
                    placeholder="Enter Name"
                    autoFocus
                  />
                  {errors.memberName && (
                    <span className="validate-danger">{errors.memberName}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label for="sel1" className="margin-bottom-6 ">
                    Phone
                  </label>
                  <div style={{display: "flex"}}>
                    <Select
                      className="border-none country-code-dropdown custom-arrow member_countrycode"
                      value={contryCode}
                      name="contryCode"
                      onChange={(val) => setContryCode(val)}
                      style={{paddingTop: "2px", marginRight: "1%"}}
                    >
                      <Select.Option value="+44">+44</Select.Option>
                      <Select.Option value="+91">+91</Select.Option>
                    </Select>
                    <div className="seperator"></div>
                    <input
                      className={`form-control margin-0`}
                      name="memberContact"
                      value={fields.memberContact}
                      onChange={handleFieldChange}
                      placeholder="Enter Number"
                    />
                  </div>

                  {errors.memberContact && (
                    <span className="validate-danger">
                      {errors.memberContact}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="form-group">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <label for="sel1">Feel free to freetext your Clinical Impressions</label>
                                    <input
                                        className="form-control margin-0"
                                        name="snomedCodeText"
                                        value={snomedCodeText}
                                        onChange={this._handleTextChange}
                                    />
                                    {this.state.errors && (
                                        <span className="validate-danger">
                                            {this.state.errors.snomedCodeId}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div> */}
          </form>
        </ModalPopUp>
      </div>
    </Fragment>
  );
}

import { get, Irequest, patch } from './index'

export const getPreQuestionnaireApi = (params) => {
	let { patient_id } = params
	const request: Irequest = {
		subUrl: `/new_questionnaire/questions/${patient_id}.json`,
		params,
	}
	return get(request)
}

export const updatePreQuestionnaireApi = (data) => {
	let { id } = data
	const request: Irequest = {
		subUrl: `/new_questionnaire/questions/${id}.json`,
		data,
	}
	return patch(request)
}

export const getCovidEmailTemplatesApi = (params) => {
	const request: Irequest = {
		subUrl: `/admin/documents/list_covid_email_template.json`,
		params,
	}
	return get(request)
}

import { get, post } from "./index";


export const getSpecialNotesApi = (params) => {
    const request = {
        subUrl: `/special_notes.json`,
        params
    };
    return get(request);
};

export const addSpecialNotesApi = (data) => {
    const request = {
        subUrl: `/special_notes.json`,
        data
    };
    return post(request);
};
import validator from "validator";
import isEmpty from "lodash/isEmpty";
import {passwordRegex, spaceRegex} from "../../constants/common";
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  formatPhoneNumber,
} from "react-phone-number-input";
export function ValidateSignupInput(data) {
  console.log("DATA--->", data);
  let errors = {};
  if (
    data.organization_name !== "" &&
    data.organization_name !== undefined &&
    data.organization_name !== null
  ) {
    //town validation
    if (data.town !== undefined && validator.isEmpty(data.town)) {
      errors.town = "Town name  is required";
    } else if (data.town !== undefined && data.town.trim().length === 0) {
      errors.town = "Town name  is required";
    }
    //town validation
    if (data.line1 !== undefined && validator.isEmpty(data.line1)) {
      errors.line1 = "Address line1  is required";
    } else if (data.line1 !== undefined && data.line1.trim().length === 0) {
      errors.line1 = "Address line1  is required";
    }
    if (data.line2 !== undefined && validator.isEmpty(data.line2)) {
      errors.line2 = "Address line2  is required";
    } else if (data.line2 !== undefined && data.line2.trim().length === 0) {
      errors.line2 = "Address line2  is required";
    }
    //address validation
    if (data.town !== undefined && validator.isEmpty(data.town)) {
      errors.town = "Town name  is required";
    } else if (data.town !== undefined && data.town.trim().length === 0) {
      errors.town = "Town name  is required";
    }
    if (data.county !== undefined && validator.isEmpty(data.county)) {
      errors.county = "County name  is required";
    } else if (data.county !== undefined && data.county.trim().length === 0) {
      errors.county = "County name  is required";
    }
    if (data.country !== undefined && validator.isEmpty(data.country)) {
      errors.country = "Country name  is required";
    } else if (data.country !== undefined && data.country.trim().length === 0) {
      errors.country = "Country name  is required";
    }
    //pharmacy phone/email validation
    if (
      data.organization_phone !== undefined &&
      validator.isEmpty(data.organization_phone)
    ) {
      errors.organization_phone = "Please enter valid mobile number";
    }
    if (
      data.organization_email !== undefined &&
      validator.isEmpty(data.organization_email)
    ) {
      errors.organization_email = "Email is required";
    }
    //premesis validation
    if (
      data.pharmacy_registration_number !== undefined &&
      validator.isEmpty(data.pharmacy_registration_number)
    ) {
      errors.pharmacy_registration_number =
        "Pharmacy premesis number is required";
    }
    if (
      data.pharmacy_registration_number !== undefined &&
      !validator.isEmpty(data.pharmacy_registration_number) &&
      !validator.isNumeric(data.pharmacy_registration_number)
    ) {
      errors.pharmacy_registration_number =
        "Enter a valid pharmacy premesis number";
    }

    if (
      data.pharmacy_registration_number !== undefined &&
      !validator.isEmpty(data.pharmacy_registration_number) &&
      validator.isNumeric(data.pharmacy_registration_number) &&
      data.pharmacy_registration_number.length !== 7
    ) {
      console.log(
        "data.pharmacy_registration_number.length",
        data.pharmacy_registration_number.length
      );
      errors.pharmacy_registration_number =
        "Enter a valid pharmacy premesis number";
    }
  }
  if (
    data.role !== undefined &&
    data.professional_registration_number !== null &&
    data.professional_registration_number !== undefined &&
    validator.isEmpty(data.professional_registration_number)
  ) {
    errors.professional_registration_number =
      "Professional Registration Number is required";
  }
  if (
    data.professional_registration_number &&
    !validator.isNumeric(data.professional_registration_number)
  ) {
    errors.professional_registration_number =
      "Enter a valid Professional Registration Number";
  }
  if (data.first_name !== undefined && validator.isEmpty(data.first_name)) {
    errors.first_name = "First name  is required";
  }

  if (
    data.professional_registration_number !== undefined &&
    validator.isEmpty(data.professional_registration_number)
  ) {
    errors.professional_registration_number =
      "Professional registration number is required";
  }

  if (
    data.professional_registration_number !== undefined &&
    !validator.isEmpty(data.professional_registration_number) &&
    !validator.isNumeric(data.professional_registration_number)
  ) {
    errors.professional_registration_number =
      "Enter a valid Professional Registration Number";
  }

  if (
    data.professional_registration_number !== undefined &&
    !validator.isEmpty(data.professional_registration_number) &&
    validator.isNumeric(data.professional_registration_number) &&
    data.professional_registration_number.length !== 7
  ) {
    console.log(
      "data.professional_registration_number.length",
      data.professional_registration_number.length
    );
    errors.professional_registration_number =
      "Enter a valid Professional Registration Number";
  }

  if (
    data.professional_registration_number !== undefined &&
    !validator.isEmpty(data.professional_registration_number) &&
    validator.isNumeric(data.professional_registration_number) &&
    data.professional_registration_number.startsWith("2") === false
  ) {
    console.log(
      "data.professional_registration_number.length",
      data.professional_registration_number.length
    );
    errors.professional_registration_number =
      "Enter a valid Professional Registration Number";
  }

  if (
    data.pharmacy_registration_number !== undefined &&
    !validator.isEmpty(data.pharmacy_registration_number) &&
    !validator.isNumeric(data.pharmacy_registration_number)
  ) {
    errors.pharmacy_registration_number =
      "Enter a valid Pharmacy Premesis Number";
  }

  if (
    data.pharmacy_registration_number !== undefined &&
    !validator.isEmpty(data.pharmacy_registration_number) &&
    validator.isNumeric(data.pharmacy_registration_number) &&
    data.pharmacy_registration_number.length !== 7
  ) {
    console.log(
      "data.pharmacy_registration_number.length",
      data.pharmacy_registration_number.length
    );
    errors.pharmacy_registration_number =
      "Enter a valid Pharmacy Premesis Number";
  }

  if (
    data.first_name !== undefined &&
    !validator.isEmpty(data.first_name) &&
    data.first_name.length > 40
  ) {
    errors.first_name = "First name should be less than  of 40 characters only";
  }

  if (
    data.first_name !== undefined &&
    !validator.isEmpty(data.first_name) &&
    spaceRegex.test(data.first_name) === false
  ) {
    errors.first_name = "First name  is invalid";
  }

  if (data.last_name !== undefined && validator.isEmpty(data.last_name)) {
    errors.last_name = "Last name  is required";
  }
  if (
    data.last_name !== undefined &&
    !validator.isEmpty(data.last_name) &&
    data.last_name.length > 40
  ) {
    errors.last_name = "Last name should be less than  of 40 characters only";
  }

  if (
    data.last_name !== undefined &&
    !validator.isEmpty(data.last_name) &&
    spaceRegex.test(data.last_name) === false
  ) {
    errors.last_name = "Last name  is invalid";
  }

  if (data.phone !== undefined && validator.isEmpty(data.phone)) {
    errors.phone = "Please enter valid mobile number";
  }

  if (
    data.phone !== undefined &&
    validator.isEmpty(formatPhoneNumber(data.phone))
  ) {
    errors.phone = "Please enter valid mobile number";
  }
  // if (data.phone !== undefined && validator.isEmpty(data.phone) === false) {
  //   if (!validator.isNumeric(data.phone)) {
  //     errors.phone = "Please enter valid mobile number";
  //   }
  // }
  if (
    data.phone !== undefined &&
    !validator.isEmpty(data.phone) &&
    (isValidPhoneNumber(data.phone) === false ||
      isPossiblePhoneNumber(data.phone) === false)
  ) {
    errors.phone = "Please enter valid mobile number";
  } else {
  }

  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = "Email is required";
  }
  if (data.role === null) {
    errors.role = "Role is required";
  }

  if (data.email !== undefined && validator.isEmpty(data.email) === false) {
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is invalid";
    }
  }

  if (
    data.email !== undefined &&
    !validator.isEmpty(data.email) &&
    data.email.length > 40
  ) {
    errors.email = "Email should be less than  of 40 characters only";
  }
  // organization_email
  // if (
  //   data.organization_phone !== undefined &&
  //   validator.isEmpty(formatPhoneNumber(data.organization_phone))
  // ) {
  //   errors.organization_phone = "Please provide valid landline number";
  // }

  if (
    data.organization_phone !== undefined &&
    !validator.isEmpty(formatPhoneNumber(data.organization_phone)) &&
    isPossiblePhoneNumber(data.organization_phone) === false
  ) {
    errors.organization_phone = "Please provide valid landline number";
  } else {
  }

  // organization_email
  // if (
  //   data.organization_email !== undefined &&
  //   validator.isEmpty(data.organization_email)
  // ) {
  //   errors.organization_email = "Please enter email";
  // }

  if (
    data.organization_email !== undefined &&
    validator.isEmpty(data.organization_email) === false
  ) {
    if (!validator.isEmail(data.organization_email)) {
      errors.organization_email = "Email is invalid";
    }
  }

  if (
    data.organization_email !== undefined &&
    !validator.isEmpty(data.organization_email) &&
    data.organization_email.length > 40
  ) {
    errors.organization_email =
      "Email should be less than  of 40 characters only";
  }

  if (data.gender === null) {
    errors.gender = "Gender is required";
  }

  // if (data.role === null) {
  //   errors.role = "Role is required";
  // }

  // if (data.town !== undefined && validator.isEmpty(data.town)) {
  //   errors.town = "Town is required";
  // }
  // if (data.pincode !== undefined && validator.isEmpty(data.pincode)) {
  //   errors.pincode = "Pincode is required";
  // }
  // if (data.line1 !== undefined && validator.isEmpty(data.line1)) {
  //   errors.line1 = "Address line 1   is required";
  // }
  // if (data.line2 !== undefined && validator.isEmpty(data.line2)) {
  //   errors.line2 = "Address line 2 is required";
  // }
  // // if (data.line3 !== undefined && validator.isEmpty(data.line3)) {
  // //   errors.line3 = "Address line 3   is required";
  // // }
  // if(data.country !== undefined && validator.isEmpty(data.country)){
  //   errors.country = "Country is required";
  // }
  // if(data.county !== undefined && validator.isEmpty(data.county)){
  //   errors.county = "County is required";
  // }
  // if (
  //   data.line1 !== undefined &&
  //   !validator.isEmpty(data.line1) &&
  //   data.line1.length > 120
  // ) {
  //   errors.line1 = "Address line 1 should be less than  of 120 characters only";
  // }

  // if (
  //   data.line2 !== undefined &&
  //   !validator.isEmpty(data.line2) &&
  //   data.line2.length > 120
  // ) {
  //   errors.line2 = "Address line 2 should be less than  of 120 characters only";
  // }

  // if (
  //   data.line3 !== undefined &&
  //   !validator.isEmpty(data.line3) &&
  //   data.line3.length > 120
  // ) {
  //   errors.line2 = "Address line 3 should be less than  of 120 characters only";
  // }
  // if (data.corporate_organization_id === null) {
  // 	console.log('data.corporate_organization_id', data.corporate_organization_id)
  // 	errors.corporate_organization_id = 'Coroprate is required'
  // }

  if (data.password !== undefined && validator.isEmpty(data.password)) {
    errors.password = "Password is required";
  }

  if (
    data.password !== undefined &&
    validator.isEmpty(data.password) === false
  ) {
    let test = passwordRegex.test(data.password);
    if (!test) {
      errors.password =
        "Password should be atleast 8 characters including a number,an uppercase,one special character and lowercase letter";
    }
  }

  if (data.password !== undefined && data.password.length > 25) {
    errors.password = "Password should be max 25 characters";
  }

  if (
    data.password !== undefined &&
    !validator.isEmpty(data.password) &&
    spaceRegex.test(data.password) === false
  ) {
    errors.password = "Password  is invalid";
  }

  if (
    data.confirm_password !== undefined &&
    !validator.isEmpty(data.confirm_password) &&
    spaceRegex.test(data.confirm_password) === false
  ) {
    errors.confirm_password = "Confirm password  is invalid";
  }

  if (
    data.confirm_password !== undefined &&
    validator.isEmpty(data.confirm_password)
  ) {
    errors.confirm_password = "Enter Confirm password";
  }

  if (
    data.password !== undefined &&
    data.confirm_password &&
    data.password !== data.confirm_password
  ) {
    errors.confirm_password = "Confirm password should be same as New Password";
  }

  // if (
  //   data.postCode !== undefined &&
  //   !isEmpty(data.postCode) &&
  //   spaceRegex.test(data.postCode) === false
  // ) {
  //   errors.postCode = "Post code  is invalid";
  // }

  // if (
  //   data.line1 !== undefined &&
  //   !validator.isEmpty(data.line1) &&
  //   spaceRegex.test(data.line1) === false
  // ) {
  //   errors.line1 = "Address line 1  is invalid";
  // }

  // if (
  //   data.line2 !== undefined &&
  //   !validator.isEmpty(data.line2) &&
  //   spaceRegex.test(data.line2) === false
  // ) {
  //   errors.line2 = "Address line 2  is invalid";
  // }

  // if (
  //   data.town !== undefined &&
  //   !validator.isEmpty(data.town) &&
  //   spaceRegex.test(data.town) === false
  // ) {
  //   errors.town = "Town/City is invalid";
  // }

  // if (
  //   data.town !== undefined &&
  //   !validator.isEmpty(data.town) &&
  //   data.town.length > 40
  // ) {
  //   errors.town = "Town/City should be less than  of 40 characters only";
  // }

  if (data.tc_accepted === false) {
    errors.tc_accepted = "Please accept terms and conditions";
  }
  console.log("ERRORS--->", errors);

  return {errors, isValid: isEmpty(errors)};
}

import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import {actions as nhsMedicationsActions} from "../../../reducers/nhsMedications";
import {actions as pastOrdersActions} from "../../../reducers/pastOrders";

import {verifyObject} from "../../../utilities/utils";
import // isArray,
// debounce,

// uniqueId
"lodash";
// import moment from "moment";
// import CommonLoader from "../../../components/common/CommonLoader";
// import NoFoundMessage from "../../../components/common/NoFoundMessage";
// import {Table} from "antd";
// import {DATE_FORMAT} from "../../../constants/common";
import PastOrdersContainer from "../PastOrdersContainer/PastOrdersContainer";
// import ModalPopUp from "../../../components/common/ModalPopUp";
// import Highlighter from "react-highlight-words";

// import search_blue from "assets/images/common/search-blue.svg";
// import { Input } from "antd";
// import {PlusSquareOutlined} from "@ant-design/icons";

// const {Option} = Select;
class PrivatePSQContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };
    [
      // "_onRequestList",
      "_onPageChange",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  componentDidMount() {
    // this._onRequestList();
  }

  // _onRequestList() {
  //     let { patientId, nhsMedicationsActions } = this.props
  //     let { onRequest } = nhsMedicationsActions
  //     if (typeof onRequest === "function") {
  //         onRequest({ patient_id: patientId, page: 1 })
  //     }
  // }
  async _onRequestList() {
    let {patientId, pastOrdersActions} = this.props;
    let {onRequest} = pastOrdersActions;
    if (typeof onRequest === "function") {
      onRequest({user_id: patientId, page: 1});
    }
  }
  // async _onRequestList() {
  //   let {patientId} = this.props;
  //   try {
  //     await this.setState({isLoading: true});
  //     let response = await getAcuteMedicationsApi({user_id: patientId});
  //     let accuteMedications1 = verifyObject(response, "data.data.current", []);
  //     let accuteMedications = [];
  //     accuteMedications1.map((d) =>
  //       accuteMedications.push({...d, key: uniqueId("ACCUTE_MED_")})
  //     );
  //     await this.setState({
  //       isLoading: false,
  //       accuteMedications: accuteMedications,
  //     });
  //   } catch (error) {
  //     const {message} = getErrorObject(error);
  //     await this.setState({isLoading: false});
  //     errorToast({content: message});
  //   }
  // }

  _onPageChange(data) {
    let {
      patientId,
      pastOrdersActions: {onPageChange},
      accuteMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, user_id: patientId, search: search});
    }
  }

  render() {
    // let {data, isLoading: acuteLoading} = this.props.accuteMedications;

    // let acuteData = data ? Object.values(data) : [];

    // const columns = [
    //   {
    //     title: "",
    //     key: "drug_name",
    //     dataIndex: "drug_name",
    //     className: "width-70 text-black",
    //     render: (drug_name, item) => (
    //       <Fragment>{drug_name ? drug_name : "-"}</Fragment>
    //     ),
    //   },
    //   {
    //     title: "",
    //     key: "created_at",
    //     dataIndex: "created_at",
    //     className: "width-15 text-dark-gray",
    //     render: (created_at, item) => (
    //       <Fragment>
    //         {created_at ? moment(created_at).format(DATE_FORMAT) : "-"}
    //       </Fragment>
    //     ),
    //   },
    //   {
    //     title: "",
    //     key: "quantity",
    //     dataIndex: "quantity",
    //     className: "width-15",
    //     render: (quantity, item) => (
    //       <Fragment>
    //         {quantity ? (
    //           <span className="custom-bluelabel">{quantity} Tablets</span>
    //         ) : (
    //           "-"
    //         )}
    //       </Fragment>
    //     ),
    //   },
    // ];

    return (
      <Fragment>
        <div className="push-20-b nospace">
          <PastOrdersContainer fromPrivatePSQ={true} />
          {/* {acuteLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          {!acuteLoading &&
            acuteData &&
            isArray(acuteData) &&
            acuteData.length > 0 && (
              <div className="prescription-array">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={acuteData}
                  className="font-12 no_table_header border-spacing-5"
                />
              </div>
            )}
          {!acuteLoading &&
            acuteData &&
            isArray(acuteData) &&
            acuteData.length === 0 && (
              <NoFoundMessage message="No private psq found" />
            )} */}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    // nhsMedications: verifyObject(state, "nhsMedications", []),
    accuteMedications: verifyObject(state, "pastOrders", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // nhsMedicationsActions: bindActionCreators(nhsMedicationsActions, dispatch),
    pastOrdersActions: bindActionCreators(pastOrdersActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivatePSQContainer);

export default ConnectedComponent;

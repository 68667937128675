import React from "react";
import {Select, Switch, Spin} from "antd";
import Highlighter from "react-highlight-words";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
import {errorToast, getErrorObject, verifyObject} from "utilities/utils";
const {getTemplates} = require("services/notifications");
const {Option} = Select;

class TemplateDropDown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      adding: false,
      users: [],
      notification_type: null,
      // For Push Notifications
      user_selection_type: null,
      patient_list: [],
      filter_selection: null,
      // For Email Notifications
      role: null,
      user_list: [],
      roles: [],

      // Commoan
      notification_title: "",
      notification_message: "",
      type: null,
      sub_type: null,
      errors: null,
      isOpen: false,
      filters: {},
      dependantKey: false,
      clearing: false,
      isDateRange: false,
      organizations: [],
      genratingreports: false,
      isFilterApplied: false,
      rules: [],
      email: [],
      template_body: "",
      template_name: "",
      template_subject: "",
      DocFileList: [],
      filteredUsers: [],
      radioValue: "no",
      template_title: "",
      page1: 1,
      pagination1: null,
      snomedCodes: [],
      searching: false,
      templates: [],
      //   template_id: null,
      selectedTemplate: null,
      selectedDateTime: null,
      isScheduled: "no",
    };
    this.searchUpdate = debounce(this._handleTemplateSearch, 1000);
  }

  componentDidMount() {
    this._getTamplates();
  }
  _handleTemplateSearch = async (value) => {
    if (!isEmpty(value)) {
      this.setState(
        {
          templates: [],
          page1: 1,
          searching: true,
          isLoadmore: false,
          isLoadmoreDrugs: false,
          search: value,
        },
        async () => {
          this._getTamplates(false);
        }
      );
    } else {
      await this.setState({templates: []});
    }
  };
  _getTamplates = async (isLoadMore) => {
    let filterParams = {
      notification_type: this.props.fromConsultation
        ? "consultation"
        : this.props.preConsultStatus === "kiosk_pending"
        ? "health_kiosk"
        : "pre_consult",
    };

    try {
      let response = await getTemplates({
        page: this.state.page1,
        search: this.state.search,
        ...filterParams,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let templates = verifyObject(response, "data.data", []);
      this.setState({
        templates: isLoadMore
          ? [...this.state.templates, ...templates]
          : templates,
        searching: false,
        pagination1: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  };
  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page1: prevState.page1 + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getTamplates(true);
      }
    );
  };
  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page1,
      pagination1: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page1 < total_pages) {
      this.fetchMoreData();
    }
  };
  render() {
    let {templates, isLoadmore, searching} = this.state;

    return (
      <div className="form-group">
        <div className="form-row">
          <div className="col-md-12">
            <label htmlFor="sel1">Template</label>
            <Select
              className="form-control margin-0 indication-dropdown input-sm-28 placeholder-10"
              name="snomedCodeId"
              placeholder="Search Templates"
              onPopupScroll={this.handleScroll}
              allowClear={searching ? false : true}
              value={this.props.template_id}
              showSearch={true}
              onChange={(value) =>
                this.props._handleTemplateChange(value, "template_id")
              }
              onSearch={(value) => {
                let searchValue = value;
                this.searchUpdate(searchValue);
              }}
              suffixIcon={
                !isLoadmore &&
                searching && (
                  <Spin className="custom-dropdown-spinner" size="small" />
                )
              }
              notFoundContent={
                !searching &&
                templates.length === 0 && <span>No codes available</span>
              }
              filterOption={false}
              dropdownRender={(menus) => {
                return (
                  <div ref={this.scrollDiv}>
                    {menus}
                    {isLoadmore && searching && (
                      <div
                        style={{
                          textAlign: "center",
                          padding: 10,
                        }}
                      >
                        <Spin size="small" />
                      </div>
                    )}
                  </div>
                );
              }}
            >
              {templates.map((k) => {
                return <Option value={k.id}>{k.template_name}</Option>;
              })}
            </Select>
          </div>
        </div>
      </div>
    );
  }
}

export default TemplateDropDown;

import React, { Fragment } from "react";
import { Upload } from "antd";
import PropTypes from "prop-types";

const { Dragger } = Upload;

export default function DragAndDropUpload({
  children,
  className,
  style,
  listType,
  accept,
  name,
  multiple,
  handleFileUpload,
  fileList,
}) {
  return (
    <Fragment>
      <Dragger
        listType={listType}
        fileList={fileList}
        showUploadList={true}
        multiple={multiple}
        name={name}
        accept={accept}
        onChange={handleFileUpload}
        beforeUpload={() => false}
        className={className}
        style={style}
      >
        {children || ""}
      </Dragger>
    </Fragment>
  );
}
DragAndDropUpload.defaultProps = {
  listType: "picture-card",
  multiple: false,
};

DragAndDropUpload.propTypes = {
  fileList: PropTypes.object.isRequired,
  handleFileUpload: PropTypes.func.isRequired,
  listType: PropTypes.string,
  children: PropTypes.node,
};

/* eslint-disable eqeqeq */
import {Input} from "antd";
import React, {Fragment} from "react";
import QuestionsAdditionalInformation from "../QuestionsAdditionalInformation";

export default function NumericalQuestion({
  handleAnswerChange,
  childQuestions,
  parentQuestion,
  question,
  childOfQuestions,
  gender,
}) {
  // console.log
  if (
    question.is_conditional &&
    childQuestions &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    //TODO: Handle conditional
    let selectedOption = parentQuestion.answer;

    if (parentQuestion?.category === "single_choice") {
      if (question?.conditional_text_values && question?.conditional_text_values[0] === selectedOption) {
        return (
          <Fragment>
            <div className="margin-t-15 margin-bottom-10">
              <div className="form-row">
                <div className="col-md-10">
                  <div className="weight-500 font-14  d-flex">
                    <span className="dot"></span>
                    <span
                      dangerouslySetInnerHTML={{__html: question.title}}
                      className="Questions"
                    />
                    {question.is_required ? (
                      <sup className="text-danger">*</sup>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <Input
                    // name={`selectedAnswer${questions.id}`}
                    // value={this.state[`selectedAnswer${index}`]}
                    onChange={(e) => {
                      const val = e.target.value;
                      handleAnswerChange(
                        val,
                        parentQuestion,
                        childQuestions,
                        childOfQuestions
                      );
                    }}
                    type="number"
                    placeholder={
                      question.placeholder !== null ? question.placeholder : ""
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
                <QuestionsAdditionalInformation question={question} />
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }
    if (parentQuestion?.category === "numerical") {
      let selectedValue = parseInt(parentQuestion.answer, 10);
      const {conditional_max_value, conditional_min_value} = question;
      console.log("selectedValue", selectedValue);
      console.log("Max", conditional_max_value);
      console.log("Min", conditional_min_value);
      if (
        selectedValue <= conditional_max_value &&
        selectedValue >= conditional_min_value
      ) {
        return (
          <Fragment>
            <div className="margin-t-15  margin-bottom-10 ">
              <div className="form-row">
                <div className="col-md-10">
                  <div className="weight-500 font-14 d-flex">
                    <span className="dot"></span>
                    <span
                      dangerouslySetInnerHTML={{__html: question.title}}
                      className="Questions"
                    />
                    {question.is_required ? (
                      <sup className="text-danger">*</sup>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <Input
                    // name={`selectedAnswer${questions.id}`}
                    // value={this.state[`selectedAnswer${index}`]}
                    onChange={(e) => {
                      const val = e.target.value;
                      handleAnswerChange(
                        val,
                        parentQuestion,
                        childQuestions,
                        childOfQuestions
                      );
                    }}
                    type="number"
                    placeholder={
                      question.placeholder !== null ? question.placeholder : ""
                    }
                    className="form-control"
                  />
                </div>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
                <QuestionsAdditionalInformation question={question} />
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }
  } else if (
    !childOfQuestions &&
    childQuestions &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 margin-bottom-10">
          <div className="form-row">
            <div className="col-md-10">
              <div className="weight-500 font-14  d-flex">
                <span className="dot"></span>
                <span
                  dangerouslySetInnerHTML={{__html: question.title}}
                  className="Questions"
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
            </div>
            <div className="col-md-2">
              <Input
                // name={`selectedAnswer${questions.id}`}
                // value={this.state[`selectedAnswer${index}`]}
                onChange={(e) => {
                  const val = e.target.value;
                  handleAnswerChange(
                    val,
                    parentQuestion,
                    childQuestions,
                    childOfQuestions
                  );
                }}
                type="number"
                placeholder={
                  question.placeholder !== null ? question.placeholder : ""
                }
                className="form-control"
              />
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
            <QuestionsAdditionalInformation question={question} />
          </div>
        </div>
      </Fragment>
    );
  } else if (
    childOfQuestions &&
    childQuestions.answer &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 margin-bottom-10">
          <div className="form-row">
            <div className="col-md-10">
              <div className="weight-500 font-14  d-flex">
                <span className="dot"></span>
                <span
                  dangerouslySetInnerHTML={{__html: question.title}}
                  className="Questions"
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
            </div>
            <div className="col-md-2">
              <Input
                // name={`selectedAnswer${questions.id}`}
                // value={this.state[`selectedAnswer${index}`]}
                onChange={(e) => {
                  const val = e.target.value;
                  handleAnswerChange(
                    val,
                    parentQuestion,
                    childQuestions,
                    childOfQuestions
                  );
                }}
                type="number"
                placeholder={
                  question.placeholder !== null ? question.placeholder : ""
                }
                className="form-control"
              />
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
            <QuestionsAdditionalInformation question={question} />
          </div>
        </div>
      </Fragment>
    );
  } else if (
    !childOfQuestions &&
    !childQuestions &&
    question &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 margin-bottom-10">
          <div className="form-row">
            <div className="col-md-10">
              <div className="weight-500 font-14  d-flex">
                <span className="dot"></span>
                <span
                  dangerouslySetInnerHTML={{__html: question.title}}
                  className="Questions"
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}

                {/* {questionValidations && (
            <b className="text-danger font-12 ml-2">
              (Max:{questionValidations?.max_value} ,Min:{" "}
              {questionValidations?.min_value})
            </b>
          )} */}
              </div>
            </div>
            <div className="col-md-2">
              <Input
                // name={`selectedAnswer${questions.id}`}
                // value={this.state[`selectedAnswer${index}`]}
                onChange={(e) => {
                  const val = e.target.value;
                  handleAnswerChange(
                    val,
                    parentQuestion,
                    childQuestions,
                    childOfQuestions
                  );
                }}
                value={question.answer}
                //   max={70}
                type="number"
                placeholder={
                  question.placeholder !== null ? question.placeholder : ""
                }
                className="form-control"
              />
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
            <QuestionsAdditionalInformation question={question} />
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <></>;
  }
}

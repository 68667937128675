
import { get,post } from "./index";

export const getMembersList = (params) => {
  const request = {
    subUrl: `/priory/patient/appointment_members.json`,
    params,
  };
  return get(request);
};

export const addMemberContact = (data) => {
  const request = {
    subUrl: `/priory/patient/appointment_members.json`,
    data,
  };
  return post(request);
};

import React, {Fragment} from "react";
import {Table} from "antd";
import moment from "moment";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withRouter} from "react-router-dom";
import NoFoundMessage from "../../components/common/NoFoundMessage";
import {
  actions as patientActions,
  onPatientSelectScriptReview,
} from "reducers/patients";
import {actions as patientDetailActions} from "../../reducers/patientDetail";
import {onSetSidebarKey} from "../../reducers/dynamicTab";
import {navigateTo} from "../../app/navigation";
import {
  errorToast,
  // modifiedDataObjectToArray,
  modifiedDataObjectToArrayByIDs,
  verifyObject,
} from "../../utilities/utils";

import imguser from "../../assets/images/common/doctor_profile.svg";
// import scripttime from 'assets/images/common/script_time.svg'
// import scripttime1 from 'assets/images/common/script_time_1.svg'
import ascendingsort from "assets/images/common/ascending-sort.svg";
import {DATE_FORMAT, DATE_FORMAT_24_HR} from "../../constants/common";
// import { isArray } from 'lodash'
// import { getPatientDetail } from '../../services/patientDetail'

function ScriptReviewTable({
  dataSource,
  _onReuestList,
  status,
  sortRecordBy,
  ...rest
}) {
  let scriptReviews = modifiedDataObjectToArrayByIDs(dataSource);
  let loading = verifyObject(dataSource, "isLoading", false);

  const columns = [
    {
      key: "name",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Patient Name</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l cursor-pointer sort-icon"
              // alt="Ascending Sort"
              title=""
              id="1"
              onClick={() => sortRecordBy("patient_name", "1")}
            />
          </div>
        );
      },
      dataIndex: "name",
      className:
        "min-height-30 width-20 padding-left-10 padding-right-10 sort-pad-0",
      render: (text, item) => {
        return (
          <div className="d-flex report-user align-items-center">
            <div className="up_doctor_profile">
              <img
                alt="c"
                src={
                  item && item.patient_profile && item.patient_profile.image
                    ? item.patient_profile.image
                    : imguser
                }
              />
            </div>
            <p
              onClick={async (e) => {
                console.log("item", item);
                await localStorage.setItem(
                  "patient_review_duration",
                  item.review_duration ? item.review_duration : 0
                );
                await localStorage.setItem(
                  "script_review_obj",
                  JSON.stringify(item)
                );
                await _handleNavigation(item.patient_id);
                await _getPatientDetails(item.patient_id);
                let {onSetSidebarKey} = rest.dynamicTabAction;
                if (typeof onSetSidebarKey === "function") {
                  await onSetSidebarKey(["1"]);
                }
              }}
              className="padding-left-10 font-12 weight-400 text-light-black margin-0 text-capitalize in-block doctor_profile_text"
            >
              {verifyObject(item, "patient_profile.first_name", "")}{" "}
              {verifyObject(item, "patient_profile.last_name", "")}
            </p>
          </div>
        );
      },
    },
    {
      key: "corporate",
      dataIndex: "corporate",
      title: () => {
        return (
          <div>
            <span>corporate</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l cursor-pointer sort-icon"
              title=""
              id="2"
              onClick={() => sortRecordBy("corporate_name", "2")}
            />
          </div>
        );
      },
      className:
        "min-height-30 width-16 padding-left-10 padding-right-10 sort-pad-0",
      render: (text, item) => (
        <p className="font-12 weight-400 text-dark-gray line-1-75 m-0">
          {verifyObject(item, "corporate_name", "-")}
        </p>
      ),
    },
    {
      key: "gender",
      dataIndex: "gender",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Gender</span>{" "}
            {/* <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l"
              title=''
            /> */}
          </div>
        );
      },
      className:
        "min-height-30 width-7 padding-left-10 padding-right-10 sort-pad-0",
      render: (text, item) => (
        <p className="font-12 weight-400 text-dark-gray line-1-75 m-0">
          {verifyObject(item, "patient_profile.gender", "") === "male" && "M"}
          {verifyObject(item, "patient_profile.gender", "") === "female" && "F"}
          {verifyObject(item, "patient_profile.gender", "") === "" && "-"}
        </p>
      ),
    },
    {
      key: "dob",
      dataIndex: "dob",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>DOB</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l cursor-pointer sort-icon"
              title=""
              id="3"
              onClick={() => sortRecordBy("date_of_birth", "3")}
            />
          </div>
        );
      },
      className:
        "min-height-30 width-10 padding-left-10 padding-right-10 sort-pad-0",
      render: (text, item) => (
        <p className="font-12 weight-400 text-dark-gray line-1-75 m-0">
          {verifyObject(item, "patient_profile.date_of_birth", null) !== null
            ? moment(item.patient_profile.date_of_birth).format(DATE_FORMAT)
            : "-"}
        </p>
      ),
    },
    {
      key: "id",
      dataIndex: "order_id",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Order ID</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l cursor-pointer sort-icon"
              title=""
              id="4"
              onClick={() => sortRecordBy("order_id", "4")}
            />
          </div>
        );
      },
      className:
        "min-height-30 width-10 padding-left-10 padding-right-10 sort-pad-0",
      render: (text, item) => (
        <p className="font-12 weight-400 text-dark-gray line-1-75 m-0">
          #{text}
        </p>
      ),
    },
    {
      key: "assigned_date",
      dataIndex: "assigned_date",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Drug Name</span>{" "}
            {/* <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l"
              title=''
             
            /> */}
          </div>
        );
      },
      className:
        "min-height-30 width-14 padding-left-10 padding-right-10 sort-pad-0",
      render: (text, item) => (
        <span className="font-12 weight-400 margin-0 text-light-black">
          {item && item.order_item ? item.order_item.drug : "--"}
        </span>
      ),
    },
    {
      key: "ordered_date",
      dataIndex: "ordered_date",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Order Date</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l cursor-pointer sort-icon"
              title=""
              id="5"
              onClick={() => sortRecordBy("created_at", "5")}
            />
          </div>
        );
      },
      className:
        "min-height-30 width-14 padding-left-10 padding-right-10 sort-pad-0",
      render: (text, item) => (
        <span className="font-12 weight-400 margin-0 text-light-black">
          {/* <img src={scripttime} alt='Date' title='Date' className='push-5-r'></img> */}
          {item && item.ordered_date
            ? moment(item.ordered_date).format(DATE_FORMAT_24_HR)
            : "--"}
        </span>
      ),
    },

    {
      key: "action",
      align: "left",
      title: "Action",
      className: "min-height-30 width-9 padding-left-10 padding-right-10",
      render: (text, record) => (
        <button
          onClick={async () => {
            if (!record.patient_profile) {
              errorToast({
                content: "No profile found associated with this ID",
              });
            } else {
              localStorage.setItem(
                "patient_review_duration",
                record.review_duration ? record.review_duration : 0
              );
              await localStorage.setItem(
                "script_review_obj",
                JSON.stringify(record)
              );
              await _handleNavigation(record.patient_id);
              await _getPatientDetails(record.patient_id);
              let {onSetSidebarKey} = rest.dynamicTabAction;
              if (typeof onSetSidebarKey === "function") {
                await onSetSidebarKey(["1"]);
              }
            }
          }}
          className="height-30 p-0 btn btn-custom btn-blue w-100 font-14 weight-400 text-white tablet-font-10"
        >
          View
        </button>
      ),
    },
  ];

  const _handleNavigation = (id) => {
    let {onPatientSelectScriptReview} = rest.onPatientSelectScriptReview;
    if (typeof onPatientSelectScriptReview === "function") {
      onPatientSelectScriptReview({id});
    }
  };
  const _getPatientDetails = (id) => {
    let {
      patientDetailActions: {onRequest},
    } = rest;
    if (typeof onRequest === "function") {
      onRequest({id});
    }
  };

  return (
    <Fragment>
      <Table
        id="questionnaire_table"
        locale={{
          emptyText: !loading ? (
            <NoFoundMessage message={`No  script review found`} />
          ) : null,
        }}
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={scriptReviews}
        showHeader={true}
      />
    </Fragment>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    patientActions: bindActionCreators(patientActions, dispatch),
    navigationAction: bindActionCreators({navigateTo}, dispatch),
    onPatientSelectScriptReview: bindActionCreators(
      {onPatientSelectScriptReview},
      dispatch
    ),
    dynamicTabAction: bindActionCreators({onSetSidebarKey}, dispatch),
    patientDetailActions: bindActionCreators(patientDetailActions, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    patients: verifyObject(state, "patients", []),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScriptReviewTable);

export default withRouter(ConnectedComponent);

import React from "react";
import moment from "moment";
import CommonLoader from "../../components/common/CommonLoader";
import NoFoundMessage from "../../components/common/NoFoundMessage";
import {Popover, Typography} from "antd";

import LightBoxComponent from "../../components/common/LightBoxComponent";
// import ascendingsort from "assets/images/common/ascending-sort.svg";
import {isEmpty} from "lodash";
import "assets/css/responsive.css";
// import {Dropdown as DropdownEmdr} from "reactstrap";
// import three_vertical_dots from "assets/images/common/payment_option.svg";
import {
  // APPT_TYPE_WALKIN,
  DATE_FORMAT,
  // ROLE_CLINICAL_PHARMACIST,
  T24_HR_FORMAT,
} from "../../constants/common";
import {verifyObject} from "utilities/utils";
const {Text} = Typography;

const Prevtextover = ({text}) => (
  <div className="view_popover">
    <span className="font-12 weight-400 margin-0 text-black inline-block">
      {text}
    </span>
  </div>
);
function ConsultationReviewTable(props) {
  return (
    <div>
      <table className="w-100 upcoming_booking_tableheader relative bg-gray border border-bottom-none-imp">
        <thead>
          <tr>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 md-td-18 text-light-black">
              Patient Name{" "}
              {/* <img
                src={ascendingsort}
                className="push-3-l cursor-pointer sort-icon"
                alt="Ascending Sort"
                title=""
                id="1"
                onClick={() => props.sortRecordBy("patient_name", "1")}
              ></img> */}
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-13 md-td-10 text-light-black">
              Corporate{" "}
              {/* <img
                src={ascendingsort}
                className="push-3-l cursor-pointer sort-icon"
                alt="Ascending Sort"
                title=""
                id="2"
                onClick={() => props.sortRecordBy("corporate_name", "2")}
              ></img> */}
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-13 md-td-10 text-light-black">
              Clinician Info.
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-7 md-td-8 text-light-black">
              Gender{" "}
              {/* <img
                    src={ascendingsort}
                    className="push-3-l"
                    alt="Ascending Sort"
                    title=''
                  ></img> */}
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-8 md-td-8 text-light-black">
              DOB{" "}
              {/* <img
                src={ascendingsort}
                className="push-3-l cursor-pointer sort-icon"
                alt="Ascending Sort"
                title=""
                id="4"
                onClick={() => props.sortRecordBy("date_of_birth", "4")}
              ></img> */}
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-24 md-td-17 text-light-black">
              Reason for Call{" "}
              {/* <img
                    src={ascendingsort}
                    className="push-3-l"
                    alt="Ascending Sort"
                    title=''
                  ></img> */}
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-9 md-td-9 text-light-black">
              Date{" "}
              {/* <img
                src={ascendingsort}
                className="push-3-l cursor-pointer sort-icon"
                alt="Ascending Sort"
                title=""
                id="6"
                onClick={() => props.sortRecordBy("created_at", "6")}
              ></img> */}
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-9 md-td-10 text-light-black">
              Time{" "}
              {/* <img
                    src={ascendingsort}
                    className="push-3-l"
                    alt="Ascending Sort"
                    title=''
                  ></img> */}
            </th>
            <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-8 md-td-10 text-light-black">
              Action
            </th>
          </tr>
        </thead>
      </table>
      <div className="upcoming_appt_data">
        {props.isLoading && <CommonLoader />}
        {!props.isLoading && props.ids && props.ids.length === 0 && (
          <NoFoundMessage message="No previous appointments available" />
        )}
        {!props.isLoading &&
          props.ids.map((id) => {
            const phoneContent = verifyObject(
              props.data[id],
              "clinician_info.phone",
              null
            ) ? (
              <Text copyable>
                {verifyObject(props.data[id], "clinician_info.phone", null)}
              </Text>
            ) : verifyObject(props.data[id], "gp_details.phone", null) ? (
              <Text copyable>
                {verifyObject(props.data[id], "gp_details.phone", null)}
              </Text>
            ) : (
              "-"
            );

            return (
              <table key={id} className="w-100 upcoming_booking_table relative">
                <tbody>
                  <tr>
                    <td className="text-left padding-left-10 lg-td-22 md-td-18">
                      <div className="d-flex report-user align-items-center">
                        <div className="up_doctor_profile in-block vertical-middle">
                          {!isEmpty(props.data[id].patient_image) ? (
                            <img
                              src={verifyObject(
                                props.data[id],
                                "patient_image",
                                require("../../assets/images/common/doctor_profile.svg")
                                  .default
                              )}
                              alt=""
                              className="width-30px"
                            ></img>
                          ) : (
                            <img
                              src={
                                require("../../assets/images/common/doctor_profile.svg")
                                  .default
                              }
                              alt=""
                              className="width-30px"
                            ></img>
                          )}
                        </div>
                        <p className="padding-left-10 cursor-pointer font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block custom-text-width">
                          {props.data[id].booked_by}
                        </p>
                        &nbsp;
                        <span className="ant-tag ant-tag-blue margin-right-0-imp">
                          {props.data[id].appointment_type === "face_to_face"
                            ? "Walk-In"
                            : props.data[id].appointment_type === "phone_call"
                            ? "Phone"
                            : "Video"}
                        </span>
                      </div>
                    </td>
                    <td className="text-left padding-left-10 lg-td-13 md-td-10">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {verifyObject(props.data[id], "corporate_name", "-")}
                      </p>
                    </td>
                    <td className="text-left padding-left-10 lg-td-13 md-td-10">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {verifyObject(props.data[id], "clinician_info", null)
                          ? `${verifyObject(
                              props.data[id],
                              "clinician_info.name",
                              null
                            )}
                            ${
                              verifyObject(
                                props.data[id],
                                "clinician_info.designation",
                                null
                              )
                                ? `(${verifyObject(
                                    props.data[id],
                                    "clinician_info.designation",
                                    null
                                  ).replaceAll("_", " ")})`
                                : verifyObject(
                                    props.data[id],
                                    "clinician_info.role",
                                    null
                                  )
                                ? `(${verifyObject(
                                    props.data[id],
                                    "clinician_info.role",
                                    null
                                  ).replaceAll("_", " ")})`
                                : ""
                            }`
                          : `${verifyObject(
                              props.data[id],
                              "gp_details.first_name",
                              "-"
                            )} ${verifyObject(
                              props.data[id],
                              "gp_details.last_name",
                              "-"
                            )} ${
                              verifyObject(
                                props.data[id],
                                "gp_designation",
                                null
                              )
                                ? `(${verifyObject(
                                    props.data[id],
                                    "gp_designation",
                                    null
                                  ).replaceAll("_", " ")})`
                                : verifyObject(
                                    props.data[id],
                                    "gp_details.role",
                                    null
                                  )
                                ? `(${verifyObject(
                                    props.data[id],
                                    "gp_details.role",
                                    null
                                  ).replaceAll("_", " ")})`
                                : ""
                            }`}{" "}
                        <p className="">{phoneContent ? phoneContent : "-"}</p>
                      </p>
                    </td>
                    <td className="text-left padding-left-10 lg-td-7 md-td-8">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {verifyObject(
                          props.data[id],
                          "patient_profile.gender",
                          ""
                        ) === "male" && "M"}
                        {verifyObject(
                          props.data[id],
                          "patient_profile.gender",
                          ""
                        ) === "female" && "F"}
                        {verifyObject(
                          props.data[id],
                          "patient_profile.gender",
                          ""
                        ) === "" && "-"}
                      </p>
                    </td>
                    <td className="text-left padding-left-10 lg-td-8 md-td-8">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {props.data[id].patient_profile &&
                          props.data[id].patient_profile.date_of_birth &&
                          moment(
                            props.data[id].patient_profile.date_of_birth
                          ).format(DATE_FORMAT)}
                      </p>
                    </td>
                    <td className="text-left padding-left-10 lg-td-24 md-td-17 ellipsis">
                      {!isEmpty(props.data[id].reason_image) ? (
                        // <a
                        // 	className='p-0 view_btn border-0 font-12 text-link-blue weight-400 text-underline text-decoration-hover text-center'
                        // 	onClick={() =>
                        // 		props.setState({
                        // 			[`visible${id}`]: true,
                        // 		})
                        // 	}
                        // >
                        // 	View
                        // </a>
                        <img
                          src={props.data[id].reason_image}
                          onClick={() =>
                            props.setState({
                              [`visible${id}`]: true,
                            })
                          }
                          alt="]"
                          className="cursor-pointer width-30px height-30px object-fit-cover border-radius-4"
                        ></img>
                      ) : (
                        <span className="lb-p"> -</span>
                      )}
                      <LightBoxComponent
                        visible={props[`visible${id}`]}
                        // onClose={() =>
                        //   props.setState({
                        //     [`visible${id}`]: false,
                        //   })
                        // }
                        image={verifyObject(props.data[id], "reason_image", "")}
                      />
                      {/* {verifyObject(props.data[id], "reason_image", "") !== "" ? (
                            <Popover
                              content={
                                <Previmgover
                                  image={verifyObject(
                                    props.data[id],
                                    "reason_image",
                                    ""
                                  )}
                                />
                              }
                              trigger="click"
                              placement="bottom"
                              className="prev_imgover"
                            >
                              <Button className="p-0 view_btn border-0 font-12 text-link-blue weight-400 text-underline text-decoration-hover text-center">
                                View
                              </Button>
                            </Popover>
                          ) : (
                            "-"
                          )} */}
                      {verifyObject(props.data[id], "reason_text", "") !==
                      "" ? (
                        <Popover
                          content={
                            <Prevtextover
                              // text={verifyObject(
                              //   props.data[id],
                              //   "reason_text",
                              //   ""
                              // )}

                              text={
                                props.data[id] &&
                                props.data[id].reason_text &&
                                props.data[id].reason_text
                                  .split("\n")
                                  .map((text) => {
                                    return <p>{text}</p>;
                                  })
                              }
                            />
                          }
                          trigger="hover"
                          placement="bottom"
                          className="prev_imgover ellipsis-text"
                        >
                          {verifyObject(props.data[id], "reason_text", "") !==
                          "" ? (
                            props.data[id].reason_text
                          ) : (
                            <span className="lb-p">-</span>
                          )}
                        </Popover>
                      ) : (
                        <span className="lb-p">-</span>
                      )}
                    </td>
                    <td className="text-left padding-left-10 lg-td-9 md-td-9">
                      <p className="font-12 tablet-font-9 weight-400 margin-0 text-black">
                        {moment(props.data[id].start_time).format(DATE_FORMAT)}
                      </p>
                    </td>
                    {}
                    {/* {props.props.userRole === ROLE_CLINICAL_PHARMACIST && (
                          <td className="text-left padding-left-10 lg-td-9 md-td-10">
                            <p className="font-12 tablet-font-9 weight-400 margin-0 text-light-black">
                              {moment(props.data[id].start_time).format(
                                T24_HR_FORMAT
                              )}{" "}
                            </p>
                          </td>
                        )} */}
                    {/* {props.props.userRole !== ROLE_CLINICAL_PHARMACIST && ( */}
                    <td className="text-left padding-left-10 lg-td-9 md-td-10">
                      <p className="font-12 tablet-font-9 weight-400 margin-0 text-light-black">
                        {moment(props.data[id].start_time).format(
                          T24_HR_FORMAT
                        )}{" "}
                        -{" "}
                        {moment(props.data[id].end_time).format(T24_HR_FORMAT)}
                      </p>
                    </td>
                    {/* )} */}
                    <td className="text-center padding-left-10 padding-right-10 lg-td-8 md-td-10">
                      {props.appointment_type === "in_review" ? (
                        <button
                          onClick={() =>
                            props._handleNavigation(
                              props.data[id],
                              props.fromUnderReview
                            )
                          }
                          className="arrive_btn btn btn-custom btn-blue w-100 font-13-imp weight-400 text-white tablet-font-10"
                        >
                          Review
                        </button>
                      ) : (
                        <button
                          onClick={() =>
                            props._handleNavigation(props.data[id], false)
                          }
                          className="arrive_btn btn btn-custom btn-blue w-100 font-13-imp weight-400 text-white tablet-font-10"
                        >
                          View
                        </button>
                      )}
                    </td>
                    {/* <td className="text-center padding-left-5 padding-5-r width-18px min-width-18px">
                      {props.statusArray.includes(props.data[id].status) ===
                        false &&
                        props.data[id].appointment_type !==
                          APPT_TYPE_WALKIN && (
                          <DropdownEmdr
                            isOpen={props[`isShow${id}`]}
                            toggle={() => props.toggleThreeDots(id)}
                            className="text-right in-block"
                          >
                             <DropdownToggle
                              tag="span"
                              data-toggle="dropdown"
                              //className="font-14 text-gray text-center width-40-p d-flex cursor-pointer action-dots"
                              // aria-expanded={dropdownOpen}
                            >
                              <button
                                className="padding-0 no-background border-0 no-focus"
                                onClick={(e) => e.preventDefault()}
                              >
                                <img
                                  src={three_vertical_dots}
                                  className="opacity-05"
                                  alt=""
                                ></img>
                              </button>
                            </DropdownToggle> */}
                    {/* <DropdownMenu
                              right
                              className="width-115px font-12 p-0-imp border border-radius-0-imp"
                            >
                              {props.statusArray.includes(
                                props.data[id].status
                              ) === false && (
                                <DropdownItem
                                  onClick={() => props.handleOOSAppointment(id)}
                                  className="font-12 margin-0 padding-6-imp weight-400"
                                >
                                  {"Mark As OOS"}
                                </DropdownItem>
                              )}
                            </DropdownMenu>
                          </DropdownEmdr>
                        )}
                    </td>*/}
                  </tr>
                </tbody>
              </table>
            );
          })}
      </div>
    </div>
  );
}

export default ConsultationReviewTable;

import { post, Irequest } from "./index";

export type IEmergencyAlertParams = {
  emergency_alert: {
    patient_id: number,
    problem: string,
    immediate_action_needed: boolean,
    action_detail?: string,
  },
};

export const createEmergencyAlertApi = (data: IEmergencyAlertParams) => {
  const request: Irequest = {
    subUrl: `/emergency_alerts`,
    data,
  };
  return post(request);
};

export type ISafeguardAlertParams = {
  safeguard_alert: {
    patient_id: number,
    concerns: string,
    team_name: string,
    contact_number: string,
    concern_summary: string,
    office_action_needed: boolean,
    action_detail?: string,
  },
};

export const createSafeguardAlertApi = (data: ISafeguardAlertParams) => {
  const request: Irequest = {
    subUrl: `/safeguard_alerts`,
    data,
  };
  return post(request);
};

// export const safeguardGuideUrl =
//   "https://s3.eu-west-2.amazonaws.com/alldaydr.dev/CCG's+in+England+01042017.xls";

export const safeguardGuideUrl =
  "https://www.england.nhs.uk/safeguarding/nhs-england-safeguarding-app";
 

import validator from "validator";
import isEmpty from "lodash/isEmpty";
// first_name: "",
//     last_name: "",
//     phone: "",
//     email: "",
//     gender: "",
//     address_name: "",
//     addres_line_1: "",
//     addres_line_2: "",
//     addres_line_3: "",
//     city: "",
//     postal_code: "",
//     weight: "",
//     height: "",
//     dob: "",
export function ValidateInput(data) {
  // discount_code: this.state.discount_code,
  // discount_cost: this.state.discount_cost,
  // discount_unit: this.state.discount_unit,
  // start_date: this.state.start_date,
  // expiry_date: this.state.expiry_date,
  // min_bill_amount: this.state.min_bill_amount,
  // max_usage_limit: this.state.max_usage_limit,
  // discount_rule_ids: this.state.discount_rule_ids,
  let errors = {};
  if (
    data.existing_password !== undefined &&
    validator.isEmpty(data.existing_password)
  ) {
    errors.existing_password = "Enter old password";
  }
  if (data.password !== undefined && validator.isEmpty(data.password)) {
    errors.password = "Enter  password";
  }
  if (
    data.password !== undefined &&
    validator.isEmpty(data.password) === false
  ) {
    var reg = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[!@#$%^&*])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
    );
    let test = reg.test(data.password);
    if (!test) {
      errors.password =
        "Password should be atleast 8 characters including a number,an uppercase,one special character and lowercase letter";
    }
  }
  if (
    data.confirmpassword !== undefined &&
    validator.isEmpty(data.confirmpassword)
  ) {
    errors.confirmpassword = "Enter Confirm password";
  }
  if (
    data.password !== undefined &&
    data.confirmpassword &&
    data.password !== data.confirmpassword
  ) {
    errors.confirmpassword = "Confirm password should be same as New Password";
  }
  return { errors, isValid: isEmpty(errors) };
}

export function validateOTP(data) {
  let errors = {};

  if (data.code !== undefined && validator.isEmpty(data.code) === false) {
        var reg = new RegExp("^(?=.*?[0-9]).{6,6}$");
        let test = reg.test(data.code);
    if (!test) {
      errors.code = "Please enter 6 digit otp";
    }
  }
  return { errors, isValid: isEmpty(errors) };
}
export function ValidateResetPassInput(data) {
  let errors = {};

  if (data.password !== undefined && validator.isEmpty(data.password)) {
    errors.password = "Enter  password";
  }

  if (
    data.password !== undefined &&
    validator.isEmpty(data.password) === false
  ) {
    var reg = new RegExp(
      "^(?=.*?[A-Z])(?=.*?[!@#$%^&*])(?=.*?[a-z])(?=.*?[0-9]).{8,}$"
    );

    let test = reg.test(data.password);

    if (!test) {
      errors.password =
        "Password should be atleast 8 characters including a number,an uppercase,one special character and lowercase letter";
    }
  }

  if (
    data.confirmpassword !== undefined &&
    validator.isEmpty(data.confirmpassword)
  ) {
    errors.confirmpassword = "Enter Confirm password";
  }

  if (
    data.password !== undefined &&
    data.confirmpassword &&
    data.password !== data.confirmpassword
  ) {
    errors.confirmpassword = "Confirm password should be same as New Password";
  }

  return { errors, isValid: isEmpty(errors) };
}

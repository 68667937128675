import { gettestInvestigationsApi } from "services/testInvestigations";
import { listingReducer } from "reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
    name: `testInvestigations`,
    getApi: gettestInvestigationsApi
});

const testInvestigations = reducer;
export { testInvestigations, actions, types };

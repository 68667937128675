/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {Fragment} from "react";
import {verifyObject} from "../../../utilities/utils";
import photo_id from "assets/images/profile_header/id-card.svg";
import location from "assets/images/profile_header/ic-location.svg";

export default function AppointmentDetailHeader({appointmentDetail}) {
  if (!appointmentDetail) {
    return <span>No Data Found</span>;
  }

  return (
    <div>
      <div className="collapseprofiledetail">
        <div className="font-12 weight-400 text-dark-gray patient_dropdown_data">
          <div className="patient_dropdown_inner">
            <span className="text-capitalize">
              <span className="text-black weight-300">ID :</span>{" "}
              {verifyObject(appointmentDetail, "patient_id", "-")}
            </span>
          </div>
          <div className="patient_dropdown_inner">
            <span className="">
              <span className="text-black weight-300">Email :</span>
              {verifyObject(appointmentDetail, "email", "-")}
            </span>
          </div>
          <div className="patient_dropdown_inner">
            <span className="text-capitalize">
              <span className="text-black weight-300">Mobile :</span>
              {verifyObject(appointmentDetail, "email", "-")}
            </span>
          </div>
          {/* <div className="patient_dropdown_inner">
            {verifyObject(appointmentDetail, "patient_profile.gender", null) !==
            null ? (
              <Fragment>
                <p className="profile_status active in-block"></p>
                <span className="text-capitalize">
                  {appointmentDetail.patient_profile.gender}
                </span>
              </Fragment>
            ) : (
              "--"
            )}
          </div> */}
          <div className="patient_dropdown_inner">
            {verifyObject(
              appointmentDetail,
              "patient_profile.height_value1",
              null
            ) !== null &&
              verifyObject(
                appointmentDetail,
                "patient_profile.height_value2",
                null
              ) !== null && (
                <Fragment>
                  {/* <p className="profile_status active in-block"></p> */}
                  <span className="text-capitalize">
                    <span className="text-black weight-300">Height :</span>{" "}
                    {appointmentDetail.patient_profile.height_value1}{" "}
                    {appointmentDetail.patient_profile.height_value2}
                  </span>
                </Fragment>
              )}
          </div>
          <div className="patient_dropdown_inner">
            {verifyObject(
              appointmentDetail,
              "patient_profile.weight_value1",
              null
            ) !== null &&
              verifyObject(
                appointmentDetail,
                "patient_profile.weight_value2",
                null
              ) !== null && (
                <Fragment>
                  {/* <p className="profile_status active in-block"></p> */}
                  <span className="text-capitalize">
                    <span className="text-black weight-300">Weight :</span>{" "}
                    {appointmentDetail.patient_profile.weight_value1}{" "}
                    {appointmentDetail.patient_profile.weight_value2}
                  </span>
                </Fragment>
              )}

            {verifyObject(
              appointmentDetail,
              "patient_profile.weight_category",
              null
            ) !== null &&
              appointmentDetail.patient_profile.weight_category === "metric" &&
              verifyObject(
                appointmentDetail,
                "patient_profile.weight_value1",
                null
              ) !== null && (
                <Fragment>
                  {/* <p className="profile_status active in-block"></p> */}
                  <span className="text-capitalize">
                    <span className="text-black weight-300">Weight :</span>{" "}
                    {appointmentDetail.patient_profile.weight_value1}
                  </span>
                </Fragment>
              )}
          </div>
          <div className="patient_dropdown_inner">
            {verifyObject(appointmentDetail, "patient_profile.bmi", null) !==
              null && (
              <Fragment>
                {/* <p className="profile_status active in-block"></p> */}
                <span className="text-capitalize">
                  <span className="text-black weight-300">BMI ss:</span>{" "}
                  {appointmentDetail.patient_profile.bmi}
                </span>
              </Fragment>
            )}
          </div>
          <div className="patient_dropdown_inner">
            {verifyObject(appointmentDetail, "patient_profile.smoke", null) !==
              null && (
              <Fragment>
                {/* <p className="profile_status active in-block"></p> */}
                <span className="text-capitalize">
                  {appointmentDetail.smoke}
                  {verifyObject(
                    appointmentDetail,
                    "patient_profile.smoke",
                    null
                  ) !== "No smoker" && " per day"}
                </span>
              </Fragment>
            )}
          </div>
          <div className="patient_dropdown_inner">
            {verifyObject(
              appointmentDetail,
              "patient_profile.alcohol",
              null
            ) !== null && (
              <Fragment>
                {/* <p className="profile_status active in-block"></p> */}
                <span className="text-capitalize">
                  <span className="text-black weight-300">Alcohol :</span>{" "}
                  {appointmentDetail.patient_profile.alcohol}{" "}
                  {verifyObject(
                    appointmentDetail,
                    "patient_profile.alcohol",
                    null
                  ) !== "No alcoholic" && " Unit alcohol"}
                </span>
              </Fragment>
            )}
          </div>
        </div>
        <div className="patient_dropdown_dataright">
          <div className="in-block">
            <a
              href="#"
              className="text-black weight-400 text-capitalize photo_id font-12 cursor-pointer hover-blue"
            >
              <img alt="Photo ID" title="Photo ID" src={photo_id}></img> Photo
              ID
            </a>
          </div>
          <div className="in-block margin-left-30">
            <a
              href="#"
              className="text-black weight-400 text-capitalize location_id font-12 cursor-pointer hover-blue"
            >
              <img alt="Location" title="Location" src={location}></img>{" "}
              Location
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

import validator from "validator";
import isEmpty from "lodash/isEmpty";
// import {ROLE_DOCTOR, ROLE_CLINICAL_PHARMACIST} from "constants/common";
import moment from "moment";
import {
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  formatPhoneNumber,
} from "react-phone-number-input";

// import {isDate} from "lodash";
// first_name: "",
//     last_name: "",
//     phone: "",
//     email: "",
//     gender: "",
//     address_name: "",
//     addres_line_1: "",
//     addres_line_2: "",
//     addres_line_3: "",
//     city: "",
//     postal_code: "",
//     weight: "",
//     height: "",
//     dob: "",
export function ValidateInput(data, page, role, forPDS) {
  // console.log("DATA-----", data);
  let dateEmptyConsition = forPDS
    ? !(data.dob_pds === undefined) && data.dob_pds === ""
    : !(data.dob === undefined) && data.dob === "";
  console.log("dateEmptyConsition-----", dateEmptyConsition);

  let filledDateCOndition = forPDS
    ? data.dob_pds !== "" &&
      moment(data.dob_pds, "DD-MM-YYYY").isAfter(moment())
    : data.dob !== "" && moment(data.dob, "DD-MM-YYYY").isAfter(moment());

  // const optionalFieldCondition =
  //   role === ROLE_CLINICAL_PHARMACIST || role === ROLE_DOCTOR;
  let errors = {};
  if (data.consentGiven === null) {
    errors.consentGiven = "Please select consent";
  }
  if (data.consentGiven === false) {
    errors.consentGiven = "Please provide consent";
  }
  if (data.first_name !== undefined && validator.isEmpty(data.first_name)) {
    errors.first_name = "First name  is required";
  }
  if (
    data.nhs_number &&
    data.nhs_number !== undefined &&
    !validator.isEmpty(data.nhs_number) &&
    data.nhs_number.length !== 10
  ) {
    errors.nhs_number = "NHS number length should be 10 digits only";
  }
  if (forPDS) {
    if (
      data.postCodeNHS_pds !== undefined &&
      validator.isEmpty(data.postCodeNHS_pds)
    ) {
      errors.postCodeNHS_pds = "Postcode is required";
    }
    if (
      data.first_name_pds !== undefined &&
      validator.isEmpty(data.first_name_pds)
    ) {
      errors.first_name_pds = "First name  is required";
    }
    if (
      data.last_name_pds !== undefined &&
      validator.isEmpty(data.last_name_pds)
    ) {
      errors.last_name_pds = "Last name  is required";
    }
    if (data.NHSNumber !== undefined && validator.isEmpty(data.NHSNumber)) {
      errors.NHSNumber = "NHS number is required";
    }

    console.log("dateEmptyConsition", dateEmptyConsition);
    if (dateEmptyConsition) {
      // errors.dob = "DOB cannot be a future date.";
      errors.dob_pds = "Date of birth is required";
    } else if (filledDateCOndition) {
      // errors.dob = "Date of birth is required";
      errors.dob_pds = "DOB cannot be a future date.";
    }
  }
  if (data.last_name !== undefined && validator.isEmpty(data.last_name)) {
    errors.last_name = "Last name  is required";
  }
  // if (
  //   data.phone !== undefined &&
  //   data.phone === "+44" &&
  //   !optionalFieldCondition
  // ) {
  //   errors.phone = "Phone is required";
  // }
  console.log("DATA-------", data);
  if (
    (data.phone !== undefined ||
      data.phone === undefined ||
      data.phone === null) &&
    validator.isEmpty(formatPhoneNumber(data.phone)) &&
    !forPDS
  ) {
    if (data.reasonCheckedPhone) {
      if (data.reasonPhoneOption === null) {
        errors.reasonPhoneOption = "Select reason";
      } else if (
        data.reasonPhoneOption === "other" &&
        data.reasonPhoneText !== undefined &&
        (validator.isEmpty(data.reasonPhoneText) ||
          data.reasonPhoneText.trim().length === 0)
      ) {
        errors.reasonPhoneText = "Reason required";
      }
    } else {
      if (data.phone || data.phone === null) {
        errors.phone = "Phone is required";
      }
    }
  }
  if (
    (data.email !== undefined || data.email === undefined) &&
    data.email !== undefined &&
    isEmpty(data.email)
    // &&
    // !forPDS
  ) {
    if (data.reasonCheckedEmail) {
      if (data.reasonEmailOption === null) {
        errors.reasonEmailOption = "Select reason";
      } else if (
        (data.reasonEmailOption === "other" && data.reasonEmailText === "") ||
        (data.reasonEmailOption === "other" &&
          data.reasonPhoneText !== undefined &&
          (validator.isEmpty(data.reasonEmailText) ||
            data.reasonEmailText.trim().length === 0))
      ) {
        errors.reasonEmailText = "Reason required";
      }
    } else {
      if (data.email || data.email === null) {
        errors.email = "Email is required";
      }
    }
  }
  // child account validation start
  if (data.is_child_account) {
    if (
      data.guardian_name !== undefined &&
      validator.isEmpty(data.guardian_name)
    ) {
      errors.guardian_name = "Guardian name  is required";
    }
    if (
      (data.guardian_phone !== undefined ||
        data.guardian_phone === undefined) &&
      validator.isEmpty(formatPhoneNumber(data.guardian_phone))
    ) {
      errors.guardian_phone = "Phone is required";
    }
    if (
      data.guardian_phone !== undefined &&
      data.guardian_phone !== null &&
      !validator.isEmpty(data.guardian_phone) &&
      (isValidPhoneNumber(data.guardian_phone) === false ||
        isPossiblePhoneNumber(data.guardian_phone) === false)
    ) {
      errors.guardian_phone = "Please enter valid mobile number";
    } else {
    }
    if (
      data.guardian_email !== undefined &&
      validator.isEmpty(data.guardian_email)
    ) {
      errors.guardian_email = "Email is required";
    }
    if (
      data.guardian_email !== undefined &&
      validator.isEmpty(data.guardian_email) === false
    ) {
      if (!validator.isEmail(data.guardian_email)) {
        errors.guardian_email = "Email is invalid";
      }
    }
  }
  if (
    data.emergency_number !== undefined &&
    data.emergency_number !== null &&
    !validator.isEmpty(data.emergency_number) &&
    (isValidPhoneNumber(data.emergency_number) === false ||
      isPossiblePhoneNumber(data.emergency_number) === false)
  ) {
    errors.emergency_number = "Please enter valid mobile number";
  }
  // child account validation end

  // if (
  //   data.phone !== undefined &&
  //   validator.isEmpty(formatPhoneNumber(data.phone)) &&
  //   !data.reasonChecked
  // ) {
  //   errors.phone = "Mobile no is required";
  // }
  // if (
  //   data.phone === undefined &&
  //   validator.isEmpty(formatPhoneNumber(data.phone)) &&
  //   data.reasonChecked
  // ) {
  //   errors.phone = "Mobile no is required";
  // }
  if (
    !data.reasonChecked &&
    data.phone !== undefined &&
    data.phone !== null &&
    !validator.isEmpty(data.phone) &&
    (isValidPhoneNumber(data.phone) === false ||
      isPossiblePhoneNumber(data.phone) === false)
  ) {
    console.log(
      "isValidPhoneNumber(data.phone)",
      isValidPhoneNumber(data.phone)
    );
    console.log(
      "isPossiblePhoneNumber(data.phone)",
      isPossiblePhoneNumber(data.phone)
    );

    errors.phone = "Please enter valid mobile number";
  } else {
  }
  // if (data.phone !== undefined && validator.isEmpty(data.phone) === false) {
  //   if (!validator.isLength(data.phone, { min: 13, max: 13 })) {
  //     errors.phone = "Phone no should be of 10 digit";
  //   }
  // }
  if (data.reasonChecked) {
    if (
      data.reason !== undefined &&
      (data.reason === null ||
        validator.isEmpty(data.reason) ||
        data.reason.trim().length === 0)
    ) {
      if (
        !(data.phone !== undefined || data.phone === undefined) &&
        validator.isEmpty(formatPhoneNumber(data.phone)) &&
        !(data.email !== undefined && validator.isEmpty(data.email))
      ) {
      } else {
        errors.reason = "Reason is required";
      }
    }
    // if (
    //   data.phone !== undefined &&
    //   validator.isEmpty(formatPhoneNumber(data.phone))
    // ) {
    //   errors.phone = "Mobile no is required";
    // }
    // if (data.email !== undefined && validator.isEmpty(data.email)) {
    //   errors.email = "Email is required";
    // }
  }
  if (
    data.email !== undefined &&
    isEmpty(data.email) &&
    !data.reasonCheckedEmail
    // !optionalFieldCondition
  ) {
    errors.email = "Email is required";
  }
  if (
    data.email &&
    data.email !== undefined &&
    validator.isEmpty(data.email) === false
  ) {
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is invalid";
    }
  }
  console.log("dateEmptyConsition", dateEmptyConsition);
  if (dateEmptyConsition) {
    // errors.dob = "DOB cannot be a future date.";
    errors.dob = "Date of birth is required";
  } else if (filledDateCOndition) {
    // errors.dob = "Date of birth is required";
    errors.dob = "DOB cannot be a future date.";
  } else if (data.dob === "Invalid date") {
    errors.dob = "Please enter valid DOB";
  }

  // if (data.gender !== undefined && validator.isEmpty(data.gender)) {
  //   errors.gender = "Gender is required";
  // }
  // if (data.city !== undefined && validator.isEmpty(data.city)) {
  //   errors.city = "City is required";
  // }
  // if (data.postal_code !== undefined && validator.isEmpty(data.postal_code)) {
  //   errors.postal_code = "Postal code is required";
  // }
  // if (data.weight !== undefined && validator.isEmpty(data.weight)) {
  //   errors.weight = "Weight is required";
  // }
  // if (data.height !== undefined && validator.isEmpty(data.height)) {
  //   errors.height = "Height is required";
  // }

  // if (
  //   data.security_question !== undefined &&
  //   validator.isEmpty(data.security_question)
  // ) {
  //   errors.security_question = "Please select security question.";
  // }

  // if (
  //   data.security_answer !== undefined &&
  //   validator.isEmpty(data.security_answer)
  // ) {
  //   errors.security_answer = "Please select security answer.";
  // }
  if (
    data.address_line_1 !== undefined &&
    validator.isEmpty(data.address_line_1)
  ) {
    errors.address_line_1 = "Line 1 is required";
  }
  if (data.city !== undefined && validator.isEmpty(data.city)) {
    errors.city = "City is required";
  }

  if (data.postal_code !== undefined && validator.isEmpty(data.postal_code)) {
    errors.postal_code = "Postal Code is required";
  }
  // if (
  //   data.address_line_1 !== undefined &&
  //   validator.isEmpty(data.address_line_1)
  // ) {
  //   errors.address_line_1 = "Line 1 is required";
  // }
  // if (data.city !== undefined && validator.isEmpty(data.city)) {
  //   errors.city = "City is required";
  // }

  // if (data.postal_code !== undefined && validator.isEmpty(data.postal_code)) {
  //   errors.postal_code = "Postal Code is required";
  // }

  // if (data.surgery_id !== undefined && data.surgery_id === null) {
  //   errors.surgery_id = "Please select GP Surgery.";
  // }
  // if (data.do_you_drink !== undefined && validator.isEmpty(data.do_you_drink)) {
  //   errors.do_you_drink = "Do you Drink?";
  // }
  // if (data.do_you_smoke !== undefined && validator.isEmpty(data.do_you_smoke)) {
  //   errors.do_you_smoke = "Do you smoke?";
  // }

  // if (
  //   data.blood_pressure !== undefined &&
  //   validator.isEmpty(data.blood_pressure)
  // ) {
  //   errors.blood_pressure = "Blood Pressure is required";
  // }
  console.log("ERRORSS-->", errors);
  return {errors, isValid: isEmpty(errors)};
}

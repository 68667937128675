import {get, Irequest} from "./index";

export const getDynamicQuestionnaireApi = (params) => {
  const request: Irequest = {
    subUrl: `/customer_support/patient_answers.json`,
    params,
  };
  return get(request);
};

export const getDynamicQuestionnaireDetailsApi = (params) => {
  const request: Irequest = {
    subUrl: `/customer_support/patient_answers/${params.id}.json`,
  };
  return get(request);
};

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {Component} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {
  Upload,
  Form,
  Input,
  Button,
  Menu,
  Dropdown,
  Select,
  Spin,
  Image,
  Modal,
} from "antd";
import ImgCrop from "antd-img-crop";
import {EllipsisOutlined, InboxOutlined} from "@ant-design/icons";
import _, {isEmpty} from "lodash";
import {withRouter} from "react-router-dom";

import {actions as profileActions} from "reducers/userProfile";
import {logOut} from "reducers/session";
import {
  listDoctorsSpecialitiesApi,
  listLanguagesApi,
  uploadCertificatesApi,
} from "../../services/userProfile";
import {errorToast, getErrorObject} from "utilities/utils";

import userIcon from "../../assets/images/common/new_user.svg";
import CommonLoader from "../common/CommonLoader";
import {verifyObject} from "../../utilities/utils";
import {
  ROLE_DOCTOR,
  // ROLE_CLINICAL_PHARMACIST
} from "../../constants/common";
import ModalPopUp from "../common/ModalPopUp";
import {ValidateInput} from "./userProfileValidations";
import store from "../../app/store";
import {getpostalCodesData} from "services/patients";
import SignaturePad from "react-signature-canvas";

import styles from "./new.module.css";
import PhoneInput from "react-phone-number-input";

let {Option} = Select;
let {Dragger} = Upload;
class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isLoading: this.props.profile.isLoading,
      profileImage: [],
      files: [],
      uploading: false,
      allSpecialities: [],
      allLanguages: [],
      errors: {},
      company_details: {},
      bank_details: {},
      permanentPostCode: null,
      permanentPostCodesValues: [],
      permanentPostCodeSearchValue: null,
      usualPostCode: null,
      usualPostCodesValues: [],
      usualPostCodeSearchValue: null,
      signaturePadOpen: false,
      trimmedSignURL: null,
      fileName: null,
      ...this.props.profile,
    };
    this.sigPad = this.sigPad.bind(this, this.sigPad);
    this.clear = this.clear.bind(this, this.clear);
    this.trim = this.trim.bind(this, this.trim);

    let fns = [
      "_onChange",
      "_onPreview",
      "_onLogOut",
      "_onSubmit",
      "_onInputChange",
      "_onDropdownChange",
      "_updateCertificates",
      "_handleSpecialities",
      "_handleCertificateUpload",
      "_handleLanguages",
      "_getLanguages",
      "_onDateChange",
      "_updateProfilePic",
      "_updateSignature",
      "_onAddressChange",
      "_updateSignatureUsignPad",
    ];
    fns.map((fn) => (this[fn] = this[fn].bind(this)));
  }
  sigPad = () => {};
  clear = () => {
    this.sigPad.clear();
  };
  trim = () => {
    this.setState(
      {
        trimmedSignURL: this.sigPad.getTrimmedCanvas().toDataURL("image/png"),
      },
      () => {
        let fileName = this.dataURLtoFile(
          this.state.trimmedSignURL,
          "signature.png"
        );
        this.setState({fileName});
      }
    );
  };

  dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, {type: mime});
  };
  _handleLanguages = (val) => {
    console.log("val :>> ", val);
    this.setState({
      profile: {
        ...this.state.profile,
        languages: val,
      },
    });
  };

  _handleCertificateUpload = async (_) => {
    let documents = this.state.files;
    try {
      this.setState({uploading: true});
      // await this.props.profileActions.uploadCertificates({
      //   id: this.props.user.id,
      //   documents,
      // });
      let response = await uploadCertificatesApi({
        id: this.props.user.id,
        documents,
      });
      if (response.status === 200) {
        this.setState({uploading: false, isModalOpen: false, files: []}, () => {
          this.props.profileActions.getProfile({id: this.props.user.id});
        });
      }
      console.log("response :>> ", response);
    } catch (e) {
      this.setState({uploading: false});
      const error = getErrorObject(e);
      errorToast({content: error.message});
      console.log("error :>> ", error);
    }
  };

  // _onAddressChange = (e, i) => {
  // 	let updatedAddresses = [...this.state.profile.address]

  // 	updatedAddresses[i][e.target.name] = e.target.value

  // 	this.setState({
  // 		profile: {
  // 			...this.state.profile,
  // 			address: updatedAddresses,
  // 		},
  // 	})
  // }

  _onAddressChange = (e, i) => {
    let {address} = this.state.profile;
    let updatedAddresses =
      address && address !== null && address !== "" && address[0] && address[1]
        ? [...this.state.profile.address]
        : [{name: "Usual Address"}, {name: "Primary Address"}];

    updatedAddresses[i][e.target.name] = e.target.value;

    this.setState({
      profile: {
        ...this.state.profile,
        address: updatedAddresses,
      },
    });
  };

  _onDateChange = (date, dateString) => {
    this.setState({
      company_details: {
        ...this.state.company_details,
        date_of_incorporate: date,
      },
    });
  };

  _updateSignature = async ({file}) => {
    console.log("filte===>", file);
    this.setState({
      profileSignature: file,
    });

    try {
      this.setState({isLoading: true});
      await this.props.profileActions.updateSignature({
        id: this.props.user.id,
        signature: file,
      });

      this.setState({isLoading: false});
      await this.props.profileActions.getProfile({id: this.props.user.id});
    } catch (e) {
      this.setState({isLoading: false});
      const error = getErrorObject(e);
      errorToast({content: error.message});
    }
  };

  _updateSignatureUsignPad = async () => {
    // let image = new Image();
    // image.src = this.state.trimmedSignURL;
    // console.log("trimm image==", image);
    try {
      this.setState({addingSign: true});
      await this.props.profileActions.updateSignature({
        id: this.props.user.id,
        signature: this.state.fileName,
      });
      this.setState({addingSign: false, signaturePadOpen: false});
      await this.props.profileActions.getProfile({id: this.props.user.id});
    } catch (e) {
      this.setState({addingSign: false});
      const error = getErrorObject(e);
      errorToast({content: error.message});
    }
  };

  _handleSpecialities = (val) => {
    this.setState({
      profile: {
        ...this.state.profile,
        selected_specialities: val,
      },
    });
  };

  _getLanguages = async (val) => {
    try {
      this.setState({
        search: val,
        isLoading: true,
      });
      let langResponse = await listLanguagesApi({search: val});
      await this.setState({
        allLanguages: langResponse.data.data.data,
        isLoading: false,
      });
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      console.log(error);
    }
  };

  _updateProfilePic = async (data) => {
    try {
      this.setState({isLoading: true});
      await this.props.profileActions.updateProfilePic({
        id: this.props.user.id,
        ...data,
      });
      this.setState({isLoading: false});
      await this.props.profileActions.getProfile({id: this.props.user.id});
    } catch (e) {
      this.setState({isLoading: false});
      const error = getErrorObject(e);
      errorToast({content: error.message});
    }
  };

  _onChange = ({file}) => {
    this.setState({
      profileImage: file,
    });
    this._updateProfilePic({
      image: file,
      image_file_name: file.name,
    });
  };

  _onLogOut = () => {
    let {logOut} = this.props;
    store.dispatch({
      type: "SET_SYMPTOMP_FETCHED_LINKS",
      payload: null,
    });
    if (typeof logOut === "function") {
      logOut();
    }
  };

  _onInputChange = (label, name, e) => {
    this.setState({
      [label]:
        e && e.target
          ? {
              ...this.state[label],
              [e.target.name]:
                e.target.type === "checkbox"
                  ? e.target.checked
                  : e.target.value,
            }
          : {
              ...this.state[label],
              [name]: e,
            },
    });
  };

  _onDropdownChange = (label, name, val) => {
    this.setState({
      [label]: {
        ...this.state[label],
        [name]: val,
      },
    });
  };

  _updateCertificates = async (fileObj) => {
    this.setState({
      files: [...this.state.files, ...fileObj.fileList],
    });
  };

  _onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  _onSubmit = async (data) => {
    const errors = ValidateInput(data, this.props.isPharmSmartUser);
    if (!errors.isValid) {
      this.setState({errors: errors.errors});
    } else {
      try {
        this.setState({isLoading: true});
        await this.props.profileActions.updateProfile({
          id: this.props.user.id,
          ...data,
        });
        let user = this.props.user;
        user.first_name = this.state.profile.first_name;
        user.last_name = this.state.profile.last_name;
        console.log("USER====>", user);
        store.dispatch({
          type: "LOGIN_SUCCESS",
          payload: {user, token: user.auth_token},
        });
        store.dispatch({
          type: "UPDATE_USER_SUCCESS",
          payload: {user: user},
        });
        // store.dispatch()
        await this.props.profileActions.getProfile({id: this.props.user.id});
        this.setState({isLoading: false, errors: {}});
      } catch (e) {
        this.setState({isLoading: false});
        const error = getErrorObject(e);
        errorToast({content: error.message});
      }
    }
  };

  _onFormUpdate = (_) => {
    let {profile} = this.state;

    let primaryAddress =
      profile && profile.address && profile.address[1]
        ? profile.address[1]
        : {};
    let usualAddress =
      profile && profile.address && profile.address[0]
        ? profile.address[0]
        : {};
    let data = {
      first_name:
        profile.first_name && profile.status !== "verified"
          ? profile.first_name
          : null,
      last_name:
        profile.last_name && profile.status !== "verified"
          ? profile.last_name
          : null,
      email:
        profile.email && profile.status !== "verified" ? profile.email : null,
      bio: profile.bio ? profile.bio : null,
      gender: profile.gender ? profile.gender : null,
      ...(this.props.user.role === ROLE_DOCTOR
        ? {
            gmc:
              profile.professional_registration_number &&
              profile.status !== "verified"
                ? profile.professional_registration_number
                : null,
          }
        : {}),
      ...(this.props.user.role !== ROLE_DOCTOR
        ? {
            nmc:
              profile.professional_registration_number &&
              profile.status !== "verified"
                ? profile.professional_registration_number
                : null,
          }
        : {}),
      phone:
        profile.phone && profile.status !== "verified" ? profile.phone : null,
      selected_specialities: profile.selected_specialities
        ? profile.selected_specialities.toString()
        : null,
      languages: profile.languages ? profile.languages : null,
      usual_line1: usualAddress.line1 ? usualAddress.line1 : null,
      usual_line2: usualAddress.line2 ? usualAddress.line2 : null,
      usual_line3: usualAddress.line3 ? usualAddress.line3 : null,
      usual_town: usualAddress.town ? usualAddress.town : null,
      usual_pincode: usualAddress.pincode ? usualAddress.pincode : null,
      usual_country: usualAddress.country ? usualAddress.country : null,
      usual_county: usualAddress.county ? usualAddress.county : null,
      primary_line1: primaryAddress.line1 ? primaryAddress.line1 : null,
      primary_line2: primaryAddress.line2 ? primaryAddress.line2 : null,
      primary_line3: primaryAddress.line3 ? primaryAddress.line3 : null,
      primary_town: primaryAddress.town ? primaryAddress.town : null,
      primary_pincode: primaryAddress.pincode ? primaryAddress.pincode : null,
      primary_country: primaryAddress.country ? primaryAddress.country : null,
      primary_county: primaryAddress.county ? primaryAddress.county : null,
      designation: profile.designation ? profile.designation : null,
    };

    this._onSubmit(data);
  };

  getPostalCodes = async (value, type) => {
    if (value.length > 5) {
      let state =
        type === "permanent"
          ? "permanentPostCodesValues"
          : "usualPostCodesValues";
      try {
        await this.setState({[`loading_${type}`]: true});
        let response = await getpostalCodesData({search_code: value});
        await this.setState({
          [state]: response.data.result,
          [`loading_${type}`]: false,
        });
      } catch (error) {
        await this.setState({[`loading_${type}`]: false});
        errorToast({content: "No postal code found"});
      }
    }
  };

  async componentDidMount() {
    await this.props.profileActions.getProfile({id: this.props.user.id});
    this.props.profile &&
      this.setState({
        ...this.props.profile.profile,
      });
    try {
      let response = await listDoctorsSpecialitiesApi();
      let langResponse = await listLanguagesApi();
      await this.setState({
        allSpecialities: response.data.data,
        allLanguages: langResponse.data.data.data,
        profile: {
          ...this.state.profile,
          languages:
            verifyObject(this.state, "profile.languages", []).length !== 0
              ? _.map(this.state.profile.languages, "id")
              : [],
          selected_specialities:
            this.props.profile.profile.profile &&
            this.props.profile.profile.profile.doctor_specialities &&
            this.props.profile.profile.profile.doctor_specialities.length !== 0
              ? this.props.profile.profile.profile.doctor_specialities.map(
                  (s) => s.id
                )
              : [],
        },
      });
    } catch (e) {
      console.log(e);
    }
  }
  render() {
    console.log("this.state-------->", this.state);
    let {profile} = this.state;
    let {user} = this.props;
    const isPharmSmartUser = user?.is_pharmsmart_user === true ? true : false;

    let localUserObj = verifyObject(store.getState(), "localStore.user", null);
    let logOutButton = (
      <Menu className="p-0">
        <Menu.Item className="p-0" key="logout">
          <Button onClick={(_) => this._onLogOut()} danger>
            Log out
          </Button>
        </Menu.Item>
      </Menu>
    );
    return (
      <div className="personaldetailsmain_wrapper">
        <div className="padding-bottom-60 personaldetailsmain">
          {this.props.profile.isLoading ? (
            <CommonLoader />
          ) : (
            <Form name="profile_form">
              <div className="container-fluid p-0 profile-details-form">
                <div className="form-row user-image-row">
                  <div className="col-sm-12">
                    {/* {isLoading ? (
											<Spin />
										) : ( */}
                    <div className="profile-head">
                      <img
                        src={
                          this.props.profile.profile.profile &&
                          this.props.profile.profile.profile.image &&
                          this.props.profile.profile.profile.image
                            ? this.props.profile.profile.profile.image
                            : localUserObj.image
                            ? localUserObj.image
                            : userIcon
                        }
                        alt={
                          this.props.profile.profile.profile &&
                          this.props.profile.profile.profile.image &&
                          this.props.profile.profile.profile.image
                            ? `${profile.first_name} ${profile.last_name}`
                            : "Default User Icon"
                        }
                        width="50"
                        height="50"
                        className="mr-2 user-profile-image"
                      />
                      <ImgCrop
                        beforeCrop={(data) => {
                          if (!data.type.includes("image")) {
                            errorToast({
                              content: "Select an image, please.",
                            });
                          }
                          return data.type.includes("image");
                        }}
                        grid
                      >
                        <Upload
                          action="#"
                          customRequest={this._onChange}
                          onPreview={this._onPreview}
                          accept="image/png, image/jpeg, image/webp"
                          className="my_profile_pc"
                        >
                          {profile && profile.image
                            ? "Change Photo"
                            : "Upload Photo"}
                        </Upload>
                      </ImgCrop>
                      <Dropdown overlay={logOutButton} trigger={["click"]}>
                        <a
                          className="ant-dropdown-link"
                          onClick={(e) => e.preventDefault()}
                        >
                          <EllipsisOutlined className="ml-2 logout-link" />
                        </a>
                      </Dropdown>
                    </div>
                    {/* )} */}
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      First Name
                    </label>
                    <Input
                      name="first_name"
                      disabled={
                        profile.status === "verified" || isPharmSmartUser
                      }
                      value={profile && profile.first_name}
                      defaultValue={
                        profile && profile.first_name
                          ? profile.first_name
                          : localUserObj.first_name
                      }
                      // disabled={isPharmSmartUser}
                      onChange={(e) =>
                        this._onInputChange("profile", "first_name", e)
                      }
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    {this.state.errors.first_name && (
                      <p className="form-error">
                        {this.state.errors.first_name}
                      </p>
                    )}
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Last Name
                    </label>
                    <Input
                      name="last_name"
                      disabled={
                        profile.status === "verified" || isPharmSmartUser
                      }
                      // value={profile && profile.last_name}
                      value={
                        profile && profile.last_name
                          ? profile.last_name
                          : localUserObj.last_name
                      }
                      onChange={(e) =>
                        this._onInputChange("profile", "last_name", e)
                      }
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    {this.state.errors.last_name && (
                      <p className="form-error">
                        {this.state.errors.last_name}
                      </p>
                    )}
                  </div>
                  {user && (
                    // (user.role === ROLE_DOCTOR ||
                    //   user.role === ROLE_CLINICAL_PHARMACIST) &&
                    <div className="col-sm-12 col-md-6 col-lg-4">
                      <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                        Professional Registration Number
                      </label>
                      <Input
                        name="professional_registration_number"
                        // disabled={profile.status === 'active'}
                        value={_.get(
                          profile,
                          "professional_registration_number",
                          ""
                        )}
                        onChange={(e) =>
                          this._onInputChange(
                            "profile",
                            "professional_registration_number",
                            e
                          )
                        }
                        disabled={isPharmSmartUser}
                        className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                      />
                      {this.state.errors.professional_registration_number && (
                        <p className="form-error">
                          {this.state.errors.professional_registration_number}
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="form-row mt-3">
                  {/* {user &&
                    (user.role !== ROLE_DOCTOR ||
                      user.role !== ROLE_CLINICAL_PHARMACIST) && (
                      <div className="col-sm-12 col-md-4 col-lg-4">
                        <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                          Professional Registration Number
                        </label>
                        <Input
                          name="professional_registration_number"
                          // disabled={profile.status === 'active'}
                          value={_.get(
                            profile,
                            "professional_registration_number",
                            ""
                          )}
                          onChange={(e) =>
                            this._onInputChange(
                              "profile",
                              "professional_registration_number",
                              e
                            )
                          }
                          className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                        />
                        {this.state.errors.professional_registration_number && (
                          <p className="form-error">
                            {this.state.errors.professional_registration_number}
                          </p>
                        )}
                      </div>
                    )} */}
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Specialities
                    </label>
                    <Select
                      className="w-100 font-11 text-light-black-imp weight-400 text-capitalize custom-input input-sm-30 input-bg-f9f9f9"
                      showSearch
                      name="doctor_speciality_id"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onChange={this._handleSpecialities}
                      value={profile.selected_specialities}
                      mode="multiple"
                    >
                      {!isEmpty(this.state.allSpecialities)
                        ? this.state.allSpecialities.map((s) => (
                            <Option key={s.id} value={s.id}>
                              {s.speciality}
                            </Option>
                          ))
                        : null}
                    </Select>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Languages
                    </label>
                    <Select
                      className="w-100 font-11 text-light-black-imp weight-400 text-capitalize custom-input input-sm-30 input-bg-f9f9f9"
                      showSearch
                      name="languages"
                      mode="multiple"
                      loading={this.state.isLoading}
                      onSearch={this._getLanguages}
                      onChange={this._handleLanguages}
                      value={
                        profile.languages && profile.languages.length !== 0
                          ? profile.languages.map((l) =>
                              l.language_name ? l.language_name : l
                            )
                          : []
                      }
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                    >
                      {!isEmpty(this.state.allLanguages)
                        ? this.state.allLanguages.map((l) => {
                            return (
                              <Option key={l.id} value={l.id}>
                                {l.language_name}
                              </Option>
                            );
                          })
                        : null}
                    </Select>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Designation
                    </label>
                    <Input
                      value={
                        profile.designation && profile.designation.toString()
                      }
                      name="designation"
                      onChange={(e) =>
                        this._onInputChange("profile", "designation", e)
                      }
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Phone
                    </label>
                    <PhoneInput
                      name="phone"
                      onChange={(e) =>
                        this._onInputChange("profile", "phone", e)
                      }
                      limitMaxLength={14}
                      disabled={
                        this.props.user.is_phone_verified || isPharmSmartUser
                      }
                      value={profile && profile.phone}
                      defaultValue={
                        profile && profile.phone
                          ? profile.phone
                          : localUserObj.phone
                      }
                      placeholder="Enter Organization phone number"
                      defaultCountry="GB"
                      country="GB"
                      countries={["GB"]}
                      countryCallingCodeEditable={false}
                      // international={true}

                      // error={
                      //   this.state.organization_phone
                      //     ? isValidPhoneNumber(this.state.organization_phone)
                      //       ? undefined
                      //       : "Invalid phone number"
                      //     : "Phone number required"
                      // }
                      numberInputProps={{
                        className:
                          "input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input",
                      }}
                    />
                    <div className="field-status">
                      {this.state.errors.phone && (
                        <p className="form-error">{this.state.errors.phone}</p>
                      )}
                      {profile.phone_verified ? (
                        <p className="form-success verification-badge margin-bottom-0">
                          Verified
                        </p>
                      ) : (
                        <p className="form-error verification-badge margin-bottom-0">
                          Unverified
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Email
                    </label>
                    <Input
                      name="email"
                      disabled={
                        this.props.user.email_verified || isPharmSmartUser
                      }
                      // value={_.get(profile, "email", "")}
                      defaultValue={
                        profile && profile.email
                          ? profile.email
                          : localUserObj.email
                      }
                      value={profile && profile.email}
                      onChange={(e) =>
                        this._onInputChange("profile", "email", e)
                      }
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.email && (
                        <p className="form-error">{this.state.errors.email}</p>
                      )}
                      {this.props.user.email_verified ? (
                        <p className="form-success verification-badge margin-bottom-0">
                          Verified
                        </p>
                      ) : (
                        <p className="form-error verification-badge margin-bottom-0">
                          Unverified
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-4">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Gender
                    </label>
                    <Select
                      name="gender"
                      placeholder="Select Gender"
                      // value={_.get(profile, "gender", null)}
                      value={
                        profile && profile.gender
                          ? profile.gender
                          : localUserObj.gender
                      }
                      onChange={(e) =>
                        this._onInputChange("profile", "gender", e)
                      }
                      className="input-sm-30 d-block input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    >
                      <Option value="male">Male</Option>
                      <Option value="female">Female</Option>
                      <Option value="transgender">Transgender</Option>
                      <Option value="gender_neutral">Gender Neutral</Option>
                      <Option value="gender_fluid">Gender Fluid</Option>
                      <Option value="prefer_not_to_say">
                        Prefer Not To Say
                      </Option>
                      <Option value="other">Other</Option>
                    </Select>
                    <div className="field-status">
                      {this.state.errors.gender && (
                        <p className="form-error">{this.state.errors.gender}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-2">
                  <div className="col-sm-12 col-md-12 col-lg-12">
                    <label className="font-12 weight-400 text-light-black text-left push-5-b dis-block text-capitalize">
                      Bio
                    </label>
                    <Input.TextArea
                      name="bio"
                      rows="4"
                      value={_.get(profile, "bio", "")}
                      autoSize={true}
                      className="font-11 text-light-black-imp weight-400 custom-input w-100"
                      onChange={(e) => this._onInputChange("profile", "bio", e)}
                    />
                    <div className="field-status">
                      {this.state.errors.bio && (
                        <p className="form-error">{this.state.errors.bio}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Permanent Address{" "}
                      {this.props.isPharmSmartUser ? "(optional)" : ""}
                    </label>
                  </div>
                </div>
                <div className="form-row push-20-b">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <label className="font-12 text-default-color margin-bottom-10 weight-400 line-1 dis-block">
                      Search postcode
                    </label>
                    <div className="relative w-100">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        className="searchicon"
                      >
                        <g fill="none" fillRule="evenodd">
                          <g fill="#808396" fillRule="nonzero">
                            <g>
                              <path
                                d="M26.006 42.805h-.633l-.224-.216c.785-.913 1.257-2.097 1.257-3.386 0-2.874-2.33-5.203-5.203-5.203C18.329 34 16 36.33 16 39.203c0 2.874 2.33 5.203 5.203 5.203 1.289 0 2.473-.472 3.386-1.257l.216.224v.633L28.807 48 30 46.807l-3.994-4.002zm-4.803 0c-1.993 0-3.602-1.609-3.602-3.602 0-1.993 1.609-3.602 3.602-3.602 1.993 0 3.602 1.609 3.602 3.602 0 1.993-1.609 3.602-3.602 3.602z"
                                transform="translate(-231 -675) translate(215 641)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>

                      <Select
                        showSearch
                        placeholder="Search postcode"
                        className={`custom-profile-postcode form-control height-36 input-bg-f9f9f9 margin-0 weight-400 custom-arrow postcode-input`}
                        loading={this.state[`loading_permanent`]}
                        suffixIcon={
                          this.state[`loading_permanent`] && (
                            <Spin size="small" />
                          )
                        }
                        // value={this.state.permanentPostCodeSearchValue}
                        onChange={(value) => {
                          this.setState({
                            permanentPostCodeSearchValue: value,
                          });
                          let foundValue =
                            this.state.permanentPostCodesValues.find(
                              (d) => d.udprn === value
                            );

                          if (foundValue) {
                            let updatedAddresses = [{address: []}];
                            if (this.state.profile?.address?.length > 0) {
                              updatedAddresses = [
                                ...this.state.profile.address,
                              ];
                            }

                            if (updatedAddresses) {
                              // updatedAddresses.push({
                              //   line1: foundValue.line_1,
                              //   line2: foundValue.line_2,
                              //   line3: foundValue.line_3,
                              //   pincode: foundValue.postcode,
                              //   town: foundValue.post_town,
                              //   county: foundValue.county,
                              //   country: foundValue.country,
                              // })
                              // // updatedAddresses[0].line1 = foundValue.line_1;
                              // updatedAddresses[0].line2 = foundValue.line_2;
                              // updatedAddresses[0].line3 = foundValue.line_3;
                              // updatedAddresses[0].pincode = foundValue.postcode;
                              // updatedAddresses[0].town = foundValue.post_town;
                              // updatedAddresses[0].county = foundValue.county;
                              // updatedAddresses[0].country = foundValue.country;
                            }
                            console.log("updatedAddresses", updatedAddresses);

                            if (updatedAddresses && updatedAddresses[1]) {
                              updatedAddresses[1].line1 = foundValue.line_1;
                              updatedAddresses[1].line2 = foundValue.line_2;
                              updatedAddresses[1].line3 = foundValue.line_3;
                              updatedAddresses[1].pincode = foundValue.postcode;
                              updatedAddresses[1].town = foundValue.post_town;
                              updatedAddresses[1].county = foundValue.county;
                              updatedAddresses[1].country = foundValue.country;
                            } else {
                              updatedAddresses.push({
                                line1: foundValue.line_1,
                                line2: foundValue.line_2,
                                line3: foundValue.line_3,
                                pincode: foundValue.postcode,
                                town: foundValue.post_town,
                                county: foundValue.county,
                                country: foundValue.country,
                              });
                            }

                            this.setState({
                              profile: {
                                ...this.state.profile,
                                address: updatedAddresses,
                              },
                            });
                          }
                        }}
                        onSearch={(value) =>
                          this.setState({search: value}, () => {
                            this.getPostalCodes(value, "permanent");
                          })
                        }
                        filterOption={false}
                      >
                        {!isEmpty(this.state.permanentPostCodesValues)
                          ? this.state.permanentPostCodesValues.map(
                              (record, index) => {
                                return (
                                  <Select.Option
                                    value={record.udprn}
                                  >{`${record.line_1}  ${record.postal_county} ${record.postcode}`}</Select.Option>
                                );
                              }
                            )
                          : null}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        (profile.address &&
                          profile.address[1] &&
                          profile.address[1].line1 &&
                          profile.address[1].line1) ||
                        (profile.address &&
                          profile.address[0] &&
                          profile.address[0].line1 &&
                          profile.address[0].line1)
                      }
                      defaultValue={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].line1 &&
                        profile.address[1].line1
                          ? profile.address[1].line1
                          : verifyObject(
                              localUserObj,
                              "address_detail.address.line1",
                              ""
                            )
                      }
                      name="line1"
                      placeholder="Address Line 1"
                      onChange={(e) => this._onAddressChange(e, 1)}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.primary_line1 && (
                        <p className="form-error">
                          {this.state.errors.primary_line1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].line2 &&
                        profile.address[1].line2
                      }
                      defaultValue={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].line2 &&
                        profile.address[1].line2
                          ? profile.address[1].line2
                          : verifyObject(
                              localUserObj,
                              "address_detail.address.line2",
                              ""
                            )
                      }
                      onChange={(e) => this._onAddressChange(e, 1)}
                      name="line2"
                      placeholder="Address Line 2"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.primary_line2 && (
                        <p className="form-error">
                          {this.state.errors.primary_line2}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].line3 &&
                        profile.address[1].line3
                      }
                      defaultValue={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].line3 &&
                        profile.address[1].line3
                          ? profile.address[1].line3
                          : verifyObject(
                              localUserObj,
                              "address_detail.address.line3",
                              ""
                            )
                      }
                      onChange={(e) => this._onAddressChange(e, 1)}
                      name="line3"
                      placeholder="Address Line 3"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.primary_line3 && (
                        <p className="form-error">
                          {this.state.errors.primary_line3}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].town &&
                        profile.address[1].town
                      }
                      defaultValue={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].town &&
                        profile.address[1].town
                          ? profile.address[1].town
                          : verifyObject(
                              localUserObj,
                              "address_detail.address.town",
                              ""
                            )
                      }
                      onChange={(e) => this._onAddressChange(e, 1)}
                      name="town"
                      placeholder="Town"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.primary_town && (
                        <p className="form-error">
                          {this.state.errors.primary_town}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].pincode &&
                        profile.address[1].pincode
                      }
                      defaultValue={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].pincode &&
                        profile.address[1].pincode
                          ? profile.address[1].pincode
                          : verifyObject(
                              localUserObj,
                              "address_detail.address.pincode",
                              ""
                            )
                      }
                      onChange={(e) => this._onAddressChange(e, 1)}
                      name="pincode"
                      placeholder="Pincode"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.primary_pincode && (
                        <p className="form-error">
                          {this.state.errors.primary_pincode}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].country &&
                        profile.address[1].country
                      }
                      defaultValue={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].country &&
                        profile.address[1].country
                          ? profile.address[1].country
                          : verifyObject(
                              localUserObj,
                              "address_detail.address.country",
                              ""
                            )
                      }
                      onChange={(e) => this._onAddressChange(e, 1)}
                      name="country"
                      placeholder="Country"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.primary_country && (
                        <p className="form-error">
                          {this.state.errors.primary_country}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].county &&
                        profile.address[1].county
                      }
                      defaultValue={
                        profile.address &&
                        profile.address[1] &&
                        profile.address[1].county &&
                        profile.address[1].county
                          ? profile.address[1].county
                          : verifyObject(
                              localUserObj,
                              "address_detail.address.county",
                              ""
                            )
                      }
                      onChange={(e) => this._onAddressChange(e, 1)}
                      name="county"
                      placeholder="County"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.primary_county && (
                        <p className="form-error">
                          {this.state.errors.primary_county}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                      Usual Clinic Address
                    </label>
                  </div>
                </div>
                <div className="form-row push-20-b">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <label className="font-12 text-default-color margin-bottom-10 weight-400 line-1 dis-block">
                      Search postcode
                    </label>
                    <div className="relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        className="searchicon"
                      >
                        <g fill="none" fillRule="evenodd">
                          <g fill="#808396" fillRule="nonzero">
                            <g>
                              <path
                                d="M26.006 42.805h-.633l-.224-.216c.785-.913 1.257-2.097 1.257-3.386 0-2.874-2.33-5.203-5.203-5.203C18.329 34 16 36.33 16 39.203c0 2.874 2.33 5.203 5.203 5.203 1.289 0 2.473-.472 3.386-1.257l.216.224v.633L28.807 48 30 46.807l-3.994-4.002zm-4.803 0c-1.993 0-3.602-1.609-3.602-3.602 0-1.993 1.609-3.602 3.602-3.602 1.993 0 3.602 1.609 3.602 3.602 0 1.993-1.609 3.602-3.602 3.602z"
                                transform="translate(-231 -675) translate(215 641)"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                      <Select
                        showSearch
                        placeholder="Search postcode"
                        className={`custom-profile-postcode form-control height-36 input-bg-f9f9f9 margin-0 weight-400 custom-arrow postcode-input`}
                        loading={this.state[`loading_usual`]}
                        suffixIcon={
                          this.state[`loading_usual`] && <Spin size="small" />
                        }
                        value={this.state.usualPostCodeSearchValue}
                        onChange={(value) => {
                          this.setState({
                            usualPostCodeSearchValue: value.postcode,
                          });
                          let foundValue = this.state.usualPostCodesValues.find(
                            (d) => d.udprn === value
                          );

                          if (foundValue) {
                            let updatedAddresses = [
                              ...this.state.profile.address,
                            ];
                            if (updatedAddresses && updatedAddresses[0]) {
                              updatedAddresses[0].line1 = foundValue.line_1;
                              updatedAddresses[0].line2 = foundValue.line_2;
                              updatedAddresses[0].line3 = foundValue.line_3;
                              updatedAddresses[0].pincode = foundValue.postcode;
                              updatedAddresses[0].town = foundValue.post_town;
                              updatedAddresses[0].county = foundValue.county;
                              updatedAddresses[0].country = foundValue.country;
                            }

                            this.setState({
                              profile: {
                                ...this.state.profile,
                                address: updatedAddresses,
                              },
                            });
                          }
                        }}
                        onSearch={(value) =>
                          this.setState({search: value}, () => {
                            this.getPostalCodes(value, "usual");
                          })
                        }
                        filterOption={false}
                      >
                        {!isEmpty(this.state.usualPostCodesValues)
                          ? this.state.usualPostCodesValues.map(
                              (record, index) => {
                                return (
                                  <Select.Option
                                    value={record.udprn}
                                  >{`${record.line_1}  ${record.postal_county} ${record.postcode}`}</Select.Option>
                                );
                              }
                            )
                          : null}
                      </Select>
                    </div>
                  </div>
                </div>
                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[0] &&
                        profile.address[0].line1 &&
                        profile.address[0].line1
                      }
                      name="line1"
                      placeholder="Address Line 1"
                      onChange={(e) => this._onAddressChange(e, 0)}
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.usual_line1 && (
                        <p className="form-error">
                          {this.state.errors.usual_line1}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[0] &&
                        profile.address[0].line2 &&
                        profile.address[0].line2
                      }
                      onChange={(e) => this._onAddressChange(e, 0)}
                      name="line2"
                      placeholder="Address Line 2"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input"
                    />
                    <div className="field-status">
                      {this.state.errors.usual_line2 && (
                        <p className="form-error">
                          {this.state.errors.usual_line2}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[0] &&
                        profile.address[0].line3 &&
                        profile.address[0].line3
                      }
                      onChange={(e) => this._onAddressChange(e, 0)}
                      name="line3"
                      placeholder="Address Line 3"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.usual_line3 && (
                        <p className="form-error">
                          {this.state.errors.usual_line3}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[0] &&
                        profile.address[0].town &&
                        profile.address[0].town
                      }
                      onChange={(e) => this._onAddressChange(e, 0)}
                      name="town"
                      placeholder="Town"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.usual_town && (
                        <p className="form-error">
                          {this.state.errors.usual_town}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[0] &&
                        profile.address[0].pincode &&
                        profile.address[0].pincode
                      }
                      onChange={(e) => this._onAddressChange(e, 0)}
                      name="pincode"
                      placeholder="Pincode"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.usual_pincode && (
                        <p className="form-error">
                          {this.state.errors.usual_pincode}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[0] &&
                        profile.address[0].country &&
                        profile.address[0].country
                      }
                      onChange={(e) => this._onAddressChange(e, 0)}
                      name="country"
                      placeholder="Country"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.usual_country && (
                        <p className="form-error">
                          {this.state.errors.usual_country}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-12 col-lg-6">
                    <Input
                      value={
                        profile.address &&
                        profile.address[0] &&
                        profile.address[0].county &&
                        profile.address[0].county
                      }
                      onChange={(e) => this._onAddressChange(e, 0)}
                      name="county"
                      placeholder="County"
                      className="input-sm-30 input-bg-f9f9f9 font-11 text-light-black-imp weight-400 text-capitalize custom-input mt-1"
                    />
                    <div className="field-status">
                      {this.state.errors.usual_county && (
                        <p className="form-error">
                          {this.state.errors.usual_county}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-row mt-3">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-row">
                      <div className="col-sm-6 col-md-6 col-lg-6">
                        <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                          Certificates
                        </label>
                      </div>
                      <div className="col-sm-6 col-md-6 col-lg-6 text-right">
                        <button
                          className="m-0 border-0 no-background text-blue weight-500 font-12"
                          onClick={(e) => this.setState({isModalOpen: true})}
                        >
                          Upload Certificates
                        </button>
                      </div>
                    </div>
                    <div className="document-container profile-info-box">
                      <div className="certificate_box float-left push-15-b">
                        {this.props.profile.profile.profile &&
                        this.props.profile.profile.profile.documents &&
                        !isEmpty(this.props.profile.profile.profile.documents)
                          ? this.props.profile.profile.profile.documents.map(
                              (d) => (
                                <div className="doc-single upload_img_box">
                                  <Image src={d.file_url} alt="" />
                                  <p className="doc-badge verified">Verified</p>
                                </div>
                              )
                            )
                          : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="form-row">
                      <div className="col-sm-4 col-md-4 col-lg-4">
                        <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                          Signature
                        </label>
                      </div>

                      {profile.status !== "verified" && (
                        <div className="col-sm-4 col-md-4 col-lg-4 text-right">
                          <Upload
                            action="#"
                            accept="image/png, image/jpeg, image/webp"
                            customRequest={this._updateSignature}
                          >
                            <button className="m-0 border-0 no-background text-blue weight-500 font-12">
                              Update Signature
                            </button>
                          </Upload>
                        </div>
                      )}
                      <div className="col-sm-4 col-md-4 col-lg-4 text-right">
                        <button
                          className="m-0 border-0 no-background text-blue weight-500 font-12"
                          onClick={() =>
                            this.setState({signaturePadOpen: true})
                          }
                        >
                          Using Pad
                        </button>
                      </div>
                    </div>

                    <div className="document-container">
                      {this.props.profile.profile.profile &&
                        this.props.profile.profile.profile.signature && (
                          <div className="doc-single">
                            <Image
                              src={this.props.profile.profile.profile.signature}
                              alt=""
                            />
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div className="form-row mt-4">
                  <div className="col-sm-12">
                    <Button
                      className="width-255px height-40px-imp btn btn-blue btn-custom"
                      // loading={this.props.profile.isLoading}
                      onClick={this._onFormUpdate}
                    >
                      Save Changes
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </div>
        <ModalPopUp
          title="Upload Certificates"
          visible={this.state.isModalOpen}
          handleOk={this._handleCertificateUpload}
          okText={"Upload Certificates"}
          okButtonProps={{
            loading: this.state.uploading,
            disabled: this.state.files.length === 0,
          }}
          handleCancel={() => {
            this.setState({
              isModalOpen: false,
              files: [],
            });
          }}
          loading={this.state.uploading}
          width={500}
          cancelBtnclassName="custom_cancel_btn"
          footer={true}
          maskClosable={false}
        >
          <div className="form-group">
            <div className="col-sm-12 p-0 mb-4">
              <Dragger
                name="file"
                multiple={true}
                accept=".pdf,.jpg,.png,.jpeg"
                onChange={this._updateCertificates}
                beforeUpload={(_) => false}
                className="max-w-100"
                fileList={this.state.files}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </div>
          </div>
        </ModalPopUp>
        <Modal
          visible={this.state.signaturePadOpen}
          title="Update signature using pad"
          onOk={this._updateSignatureUsignPad}
          onCancel={() =>
            this.setState(
              {
                signaturePadOpen: false,
                trimmedSignURL: null,
                fileName: [],
              },
              () => {
                this.clear();
              }
            )
          }
          okText={"Upload Signature"}
          okButtonProps={{
            loading: this.state.addingSign,
            disabled:
              this.state.trimmedSignURL === null || this.state.addingSign,
          }}
          className="update_signature"
          width={800}
        >
          <div className="signcontainermain">
            {/* // {`main-section ${HomeBannerCSS.HomeMain}` */}
            <div className="signcontainer">
              <SignaturePad
                canvasProps={{
                  className: styles.sigPad,
                  width: 770,
                  height: 340,
                }}
                ref={(ref) => {
                  this.sigPad = ref;
                }}
              />
            </div>
            <div className="clearbtn">
              <button className={styles.buttons} onClick={this.clear}>
                Clear
              </button>
              <button
                className="prev_btn btn-white text-blue margin-left-10"
                onClick={this.trim}
              >
                Confirm
              </button>
            </div>
            {this.state.trimmedSignURL ? (
              <img
                className={styles.sigImage}
                src={this.state.trimmedSignURL}
                alt="signUrl"
              />
            ) : null}
          </div>
        </Modal>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: verifyObject(state.localStore, "user", null),
    profile: state.userProfile,
    isPharmSmartUser: verifyObject(
      state,
      "localStore.user.is_pharmsmart_user",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    profileActions: bindActionCreators(profileActions, dispatch),
    logOut: bindActionCreators(logOut, dispatch),
  };
};
let connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);
export default withRouter(connectedComponent);

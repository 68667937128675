import React from 'react'
import { Empty } from "antd"
export default function NoFoundMessage({ message, className }) {
    return (
        <div style={{ textAlign: "center"}} className="appointment_nodata">
            <Empty
                className={className}
                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                imageStyle={{
                    height: 40,
                }}
                description={
                    <span className="weight-400">
                        {message}
                    </span>
                }
            >
            </Empty>
        </div>
    )
}

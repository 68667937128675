/* eslint-disable react-hooks/exhaustive-deps */
import store from "app/store";
import React, {useEffect, useState, useRef} from "react";
import {connect} from "react-redux";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import actionCable from "actioncable";
import {baseWebSocketURL} from "services";
import ModalPopUp from "components/common/ModalPopUp";
import Timer from "react-compound-timer";
import {round} from "lodash";
import {logOut} from "reducers/session";
import {bindActionCreators} from "redux";
import {updateTokenForSessionExpiration} from "../../services/session";
import session_timeout from "assets/images/video_call_setup/session-timeout.svg";

const SessionExpirationModal = ({
  ApiCallTimerMins,
  sessionActions,
  timerSocket,
  selectedSidebarKey,
  // isActualCallStarted,
}) => {
  let sessionExpirationNotification = actionCable.createConsumer(
    `${baseWebSocketURL}/cable`
  );

  // let [connectedSocket, setConnectedSocket] = useState(null);
  let [remainingTime, setRemainingTime] = useState(null);
  let [visible, setVisible] = useState(false);
  let [loading, setLoading] = useState(false);
  // let [isStartedTimer, setTimerStatus] = useState(false);
  // let [socketTimer, setSocketTimer] = useState(null);
  let socketTimer = null;
  const timerRef = useRef(null);

  const ConnectSessionExpiryNotificationChannel = async (parmas) => {
    // console.log("SessionExpiryNotificationChannel Socket going to connect");
    let timerSocketChannle =
      await sessionExpirationNotification.subscriptions.create(
        {
          channel: "SessionExpiryNotificationChannel",
          auth_token: store.getState().localStore.token,
        },
        {
          connected: (d) => {
            // console.log("SessionExpiryNotificationChannel Socket connected", d);
          },
          received: (data) => {
            // console.log(
            //   "------- SessionExpiryNotificationChannel Socket Recevied-------",
            //   data
            // );
            let is_actual_call_started = verifyObject(
              store.getState(),
              "localStore.is_actual_call_started",
              false
            );
            let remainingTime = verifyObject(data, "data", null);
            if (round(remainingTime) <= 60.12343 && !is_actual_call_started) {
              if (
                timerRef &&
                timerRef.current &&
                typeof timerRef.current.stop === "function"
              ) {
                timerRef.current.start();
              }
              setRemainingTime(remainingTime);
              setVisible(true);
            } else {
              let timOutTimer = round(remainingTime) - 120;
              timOutTimer = timOutTimer * 1000;
              if (!is_actual_call_started && ApiCallTimerMins === 1) {
                socketTimer = setTimeout(() => {
                  // console.log(
                  //   "Call the Socket method  in else wehre ",
                  //   ApiCallTimerMins,
                  //   timerSocketChannle
                  // );
                  if (
                    timerSocketChannle &&
                    typeof timerSocketChannle.remaining_time_to_expire ===
                      "function"
                  ) {
                    timerSocketChannle.remaining_time_to_expire();
                  }
                  // setTimerStatus(true);
                }, timOutTimer);
                store.dispatch({
                  type: "CALL_API_TIMER",
                  payload: 0,
                });
              }
            }
          },
          remaining_time_to_expire: function () {
            // console.log("remaining_time_to_expire Called ");
            return this.perform("remaining_time_to_expire");
          },
        }
      );
    // await setConnectedSocket(timerSocketChannle);
    store.dispatch({
      type: "SET_TIMER_SOCKET",
      payload: timerSocketChannle,
    });
  };

  useEffect(() => {
    // console.log("Mounted Component .................");
    ConnectSessionExpiryNotificationChannel();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // let socketTimer = null;
    // console.log("ApiCallTimerMins------------------------>", ApiCallTimerMins);

    if (ApiCallTimerMins === 1) {
      // console.log(`IN APP CALL Timer Started`, isStartedTimer);

      // if (!isStartedTimer) {
      setTimeout(() => {
        // console.log("Call the Socket method ", ApiCallTimerMins, timerSocket);
        if (
          timerSocket &&
          typeof timerSocket.remaining_time_to_expire === "function"
        ) {
          timerSocket.remaining_time_to_expire();
        }
        // setTimerStatus(true);
      }, 120000);
      store.dispatch({
        type: "CALL_API_TIMER",
        payload: 0,
      });
      // }
    }
  }, [ApiCallTimerMins]);
  useEffect(() => {
    // setTimerStatus(false);
  }, [selectedSidebarKey]);
  // useEffect(() => {
  //   // componentDidMount events
  //   return () => {
  //     // componentWillUnmount events
  //     // alert("IN OUT");
  //   };
  // }, []);

  const extendExpirationTime = async () => {
    // console.log("Extended Expiration TIme");
    setLoading(true);
    try {
      let response = await updateTokenForSessionExpiration({
        expire_flag: true,
      });
      successToast({
        content: verifyObject(
          response,
          "data.message",
          "Expiration time is extended"
        ),
      });
      // console.log("Timer Ref", timerRef);
      if (
        timerRef &&
        timerRef.current &&
        typeof timerRef.current.stop === "function"
      ) {
        timerRef.current.stop();
      }
      if (
        timerRef &&
        timerRef.current &&
        typeof timerRef.current.reset === "function"
      ) {
        timerRef.current.reset();
      }
      setVisible(false);
      setLoading(false);
      clearTimeout(socketTimer);
      // setConnectedSocket(null);
      setRemainingTime(null);
      // setTimerStatus(false);
      // setTimeout(() => {
      //   // console.log("Call the Socket method2 ", ApiCallTimerMins, timerSocket);
      //   // if (
      //   //   timerSocket &&
      //   //   typeof timerSocket.remaining_time_to_expire === "function"
      //   // ) {
      //   //   timerSocket.remaining_time_to_expire();
      //   // }
      //   // setTimerStatus(true);
      // }, 60000);
      // store.dispatch({
      //   type: "CALL_API_TIMER",
      //   payload: 0,
      // });
    } catch (error) {
      const {message} = getErrorObject(error);
      errorToast({content: message});
      setLoading(false);
    }
  };
  const logOutFromSession = () => {
    // console.log("Logout From Session");
    let {logOut} = sessionActions;

    if (typeof logOut === "function") {
      logOut();
      // document.oncontextmenu = document.oncontextmenu = function () {
      //   return true;
      // };
      // document.oncopy = document.oncopy = function () {
      //   return true;
      // };
      // document.ondragstart = document.ondragstart = function (e) {
      //   e.preventDefault();
      // };
    }
    if (
      timerRef &&
      timerRef.current &&
      typeof timerRef.current.stop === "function"
    ) {
      timerRef.current.stop();
    }
    if (
      timerRef &&
      timerRef.current &&
      typeof timerRef.current.reset === "function"
    ) {
      timerRef.current.reset();
    }
    localStorage.removeItem("scriptReviewStatus");
    if (timerSocket && typeof timerSocket.unsubscribe === "function") {
      // console.log("Disconnected Timer Socket");
      timerSocket.unsubscribe();
    }
    // setConnectedSocket(null);
    setVisible(false);
    setRemainingTime(null);

    store.dispatch({
      type: "SET_TIMER_SOCKET",
      payload: null,
    });
  };

  return (
    <div>
      <ModalPopUp
        visible={visible}
        footer={false}
        className="session_modal"
        closable={false}
        zIndex={1505}
        maskClosable={false}
      >
        <div className="session-timer-wrapper">
          <div className="session-timer-container">
            {" "}
            <div className="session_img">
              <img alt="" src={session_timeout}></img>
            </div>
            <div className="session_popuptitle">
              Session Timeout <br></br>Your online session will expire in
            </div>
            <div className="session-timer">
              <Timer
                direction="backward"
                initialTime={round(remainingTime) * 1000}
                checkpoints={[
                  {
                    time: 0,
                    callback: () => {
                      // console.log("logout the session");
                      //   alert("Its zero....");
                      logOutFromSession();
                    },
                  },
                ]}
                ref={timerRef}
              >
                <React.Fragment>
                  <div className="timer_min">
                    <span>Minutes </span>
                    <div className="timer_min_inner">
                      <Timer.Minutes
                        formatValue={(v) =>
                          `${v.toString().length > 1 ? v : `0${v}`}`
                        }
                      />
                    </div>
                  </div>
                  <span className="timerdot">:</span>
                  <div className="timer_sec">
                    <span>Seconds </span>
                    <div className="timer_min_inner">
                      <Timer.Seconds
                        formatValue={(v) =>
                          `${v.toString().length > 1 ? v : `0${v}`}`
                        }
                      />
                    </div>
                  </div>
                </React.Fragment>{" "}
              </Timer>
            </div>
            <div className="session_popuptitle">
              Please, click “Continue” to keep working or click <br></br>“Sign
              off” to end your session now.
            </div>
          </div>
          <div className="session-timer-btn-container">
            <button
              className="continue_btn"
              disabled={loading}
              onClick={extendExpirationTime}
            >
              {loading ? "Loading..." : "Continue"}
            </button>
            <button className="signoff_bn" onClick={logOutFromSession}>
              Sign Off
            </button>
          </div>
        </div>
      </ModalPopUp>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ApiCallTimerMins: verifyObject(state, "dynamicTab.ApiCallTimerMins", null),
    timerSocket: verifyObject(state, "dynamicTab.timerSocket", null),
    selectedSidebarKey: verifyObject(state, "dynamicTab.sidebar_key", ["1"]),
    isActualCallStarted: verifyObject(
      state,
      "localStore.is_actual_call_started",
      ["1"]
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sessionActions: bindActionCreators({logOut}, dispatch),
    //   navigationAction: bindActionCreators({ navigateTo }, dispatch),
    //   actionCableActions: bindActionCreators(actionCableActions, dispatch),
    //   handleSidebar: bindActionCreators({ onCollapseExpandSidebar }, dispatch),
  };
};

const connectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SessionExpirationModal);
export default connectedComponent;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useState} from "react";
// import imgnew_user from "assets/images/common/new_user.svg";
import imginfo from "assets/images/common/info.svg";
import {Popover, Button, Tooltip} from "antd";
import "assets/css/pages/patient-search.css";
import {Checkbox} from "antd";
import {InfoCircleOutlined} from "@ant-design/icons";
// import store from "../../app/store";
// import { CheckboxChangeEvent } from "antd/es/checkbox";
import moment from "moment";
import {
  ROLE_DOCTOR,
  ROLE_CLINICAL_PHARMACIST,
  // T12_HR_FORMAT,
  DATE_FORMAT_12_HR,
  Ethnicity,
  GenderPreference,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "constants/common";
import {
  disableCopy,
  errorToast,
  getErrorObject,
  renderHealthAssessmentInputColor,
  successToast,
  verifyObject,
} from "utilities/utils";
import {
  sendOtpRequestPatient,
  updateMarketingOptions,
  verifyOtpRequestPatient,
} from "services/patients";
import EditPatientDetail from "containers/PatientDetailContainer/EditPatientDetail";
import {getPatientDetail} from "services/patientDetail";
import {isEmpty} from "lodash";
import {Dropdown, DropdownToggle, DropdownMenu, DropdownItem} from "reactstrap";
import {max} from "lodash";
import VerifyOtpPatient from "./VerifyOtpPatient";
import SendOtpPatient from "./SendOtpPatient";
// import three_vertical_dots from "assets/images/common/payment_option.svg";

export default function PatientListContainer({
  patient,
  _startAppointment,
  _openAppointment,
  userRole,
  handleView,
  clearSearchedPatients,
  usageType,
  handleReplacePatient,
}) {
  const [state, setState] = useState({
    is_notify_by_email: false,
    is_notify_by_sms: false,
    is_notify_by_push_notification: false,
    isLoading: false,
  });
  let [showEdit, setEditFlag] = useState(false);
  // let [code, setCode] = useState("");
  let [contact_type, setContactType] = useState(null);
  let [showOtpModal, setOtpModal] = useState(false);
  let [showVerifyOtpModal, setVerifyOtpModal] = useState(false);
  let [requesting, setRequesting] = useState(false);
  let [requestingVerifyOtp, setRequestingVerifyOtp] = useState(false);
  let [patientDetails, setPatientDetails] = useState(null);

  // setting marketing options for intial page load
  useEffect(() => {
    console.log(" patient.marketingOption", patient);

    setState({
      ...state,
      is_notify_by_email:
        patient.marketing_options &&
        patient.marketing_options.is_notify_by_email
          ? patient.marketing_options.is_notify_by_email
          : false,
      is_notify_by_sms:
        patient.marketing_options &&
        patient.marketing_options.is_notify_by_phone
          ? patient.marketing_options.is_notify_by_phone
          : false,
      is_notify_by_push_notification:
        patient.marketing_options &&
        patient.marketing_options.is_notify_by_push_notification
          ? patient.marketing_options.is_notify_by_push_notification
          : false,
    });
  }, [patient]);
  useEffect(() => {
    // _getPatientDetails();
  }, []);

  const Addresscontent = ({gp_surgery}) => {
    const firstAddress = [gp_surgery.address].filter((d) => d).join(", ");
    const secondAddress = [gp_surgery.city, gp_surgery.post_code]
      .filter((d) => d)
      .join(", ");
    return (
      <div className="adress_popup">
        {gp_surgery ? (
          <>
            <b className="d-block">Address:</b>
            {gp_surgery.address ? (
              <>
                <span className="d-block">
                  {
                    firstAddress
                    // gp_surgery.address
                  }
                  ,
                </span>
                <span className="d-block">
                  {secondAddress}
                  {/* {gp_surgery.city} {gp_surgery.post_code} */}
                </span>
              </>
            ) : (
              " "
            )}
          </>
        ) : (
          " "
        )}
      </div>
    );
  };
  const Pharmacycontent = ({address}) => {
    const firstAddress = [address.line1, address.line2, address.line3]
      .filter((d) => d)
      .join(", ");
    const secondAddress = [address.town, address.pincode]
      .filter((d) => d)
      .join(", ");
    return (
      <div className="adress_popup">
        {address && (
          <>
            <b className="d-block">Address:</b>
            <span className="d-block">
              {
                firstAddress
                // `${address.line1}, ${address.line2}, ${address.line3},`
              }
            </span>
            <span className="d-block">
              {
                secondAddress
                // `${address.town}, ${address.pincode}`
              }
            </span>
          </>
        )}
      </div>
    );
  };
  const Mainaddresscontent = ({patient_address}) => {
    const firstAddress = [patient_address.line1].filter((d) => d).join(", ");
    const secondAddress = [patient_address.town, patient_address.pincode]
      .filter((d) => d)
      .join(", ");
    return (
      <div className="adress_popup">
        {patient_address && (
          <Fragment>
            <b className="d-block">Address:</b>
            <span className="d-block">
              {
                firstAddress
                // `${patient_address.line1}`
              }
              ,
            </span>
            <span className="d-block">
              {
                secondAddress
                // `${patient_address.town} , ${patient_address.pincode}`
              }
            </span>
          </Fragment>
        )}
      </div>
    );
  };
  // handle marketing options
  const Pushnotify = (e) => {
    console.log(e.target.checked);
    setState({...state, is_notify_by_push_notification: e.target.checked});
  };
  const EmailCheck = (e) => {
    setState({...state, is_notify_by_email: e.target.checked});
  };
  const MsgCheck = (e) => {
    setState({...state, is_notify_by_sms: e.target.checked});
  };
  const showEditModal = () => {
    if (
      userRole === ROLE_CLINICAL_PHARMACIST ||
      userRole === ROLE_SUPERINTENDENT_PHARMACIST ||
      userRole === ROLE_DOCTOR
    ) {
      _getPatientDetails();
    }
  };

  const hideEditModal = () => {
    setEditFlag(false);
  };
  const toggleEdit = () => {
    setEditFlag(!showEdit);
  };

  const handleMarketing = async () => {
    try {
      setState({...state, isLoading: true});
      let payload = {
        patient_id: patient.id,
        is_notify_by_push_notification: state.is_notify_by_push_notification,
        is_notify_by_email: state.is_notify_by_email,
        is_notify_by_phone: state.is_notify_by_sms,
      };
      let response = await updateMarketingOptions(payload);
      successToast({
        content: response.data.message,
      });
      clearSearchedPatients();
      setState({...state, isLoading: false});
    } catch (e) {
      const {message} = getErrorObject(e);
      errorToast({
        content: message,
      });
      setState({...state, isLoading: false});
    }
  };
  useEffect(() => {
    if (!isEmpty(patientDetails)) {
      setEditFlag(true);
    }
  }, [patientDetails]);

  const _getPatientDetails = async () => {
    try {
      setState({...state, isLoading: true});

      const response = await getPatientDetail({id: patient.id});
      console.log(response);
      setPatientDetails(verifyObject(response, "data.data.patient", {}));
      setState({...state, isLoading: false});
    } catch (e) {
      const {message} = getErrorObject(e);
      setState({...state, isLoading: false});

      errorToast({
        content: message,
      });
    }
  }; //? USE BELOW DATA FOR REFRENCE

  // const renderSystolicBpColor = (_bp) => {
  //   if (_bp && !isEmpty(_bp.toString())) {
  //     if (_bp < 90) return "#0872FB"; //!LOW
  //     if (_bp > 90 && _bp <= 120) return "#16B250"; //!NORMAL
  //     if (_bp > 120 && _bp <= 140) return "#D4CA01"; //!AT RISK
  //     if (_bp > 140 && _bp <= 160) return "#F48918"; //!HIGH
  //     if (_bp > 160 && _bp <= 180) return "#ED1E24"; //!VERY HIGH
  //     if (_bp > 190) return "#C22026"; //!SEVERE
  //   } else {
  //     return "black";
  //   }
  // };

  // const renderDiastolicBpColor = (_bp) => {
  //   if (_bp && !isEmpty(_bp.toString())) {
  //     if (_bp < 60) return "#0872FB"; //!LOW
  //     if (_bp > 60 && _bp <= 80) return "#16B250"; //!NORMAL
  //     if (_bp > 80 && _bp <= 90) return "#D4CA01"; //!AT RISK
  //     if (_bp > 90 && _bp <= 100) return "#F48918"; //!HIGH
  //     if (_bp > 100 && _bp <= 110) return "#ED1E24"; //!VERY HIGH
  //     if (_bp > 110) return "#C22026"; //!SEVERE
  //   } else {
  //     return "black";
  //   }
  // };

  const renderBMIColor = (_bmif) => {
    const _bmi = parseInt(_bmif, 10);
    if (_bmi) {
      if (_bmi < 18.5) return `#0497e6`;
      if (_bmi > 18.5 && _bmi < 24.9) return `#38b407`;
      if (_bmi > 25 && _bmi < 29.9) return `#fcc202`;
      if (_bmi > 30 && _bmi < 39.9) return `#fa6f03`;
      if (_bmi >= 40) return `#ea1b06`;
    } else {
      return `black`;
    }
  };
  const renderSmokingValColor = (_smoke) => {
    if (_smoke && !isEmpty(_smoke.toString())) {
      if (_smoke === "Non smoker") {
        // smokehighest({ _p: 0, _c: "#0497e6" });
        return "#0497e6";
      }
      if (
        _smoke === "1-5 cigarettes per day" ||
        _smoke === "6-10 cigarettes per day"
      ) {
        // setHighest(1);
        // smokehighest({ _p: 1, _c: "#38b407" });
        return "#38b407";
      }
      if (
        _smoke === "11-15 cigarettes per day" ||
        _smoke === "16-20 cigarettes per day"
      ) {
        // smokehighest({ _p: 2, _c: "#fcc202" });
        return "#fcc202";
      }
      if (
        _smoke === "21-25 cigarettes per day" ||
        _smoke === "26-30 cigarettes per day"
      ) {
        // smokehighest({ _p: 3, _c: "#fa6f03" });
        return "#fa6f03";
      }
      if (
        _smoke === "31-35 cigarettes per day" ||
        _smoke === "36-40 cigarettes per day"
      ) {
        // smokehighest({ _p: 4, _c: "#ea1b06" });
        return "#ea1b06";
      }
      if (_smoke === "41+ cigarettes per day") {
        // smokehighest({ _p: 5, _c: "#bf2422" });
        return "#bf2422";
      }
    } else {
      return "black";
    }
  };
  // const renderPulseColor = (_pulse) => {
  //   if (_pulse) {
  //     if (_pulse < 59 && _pulse > 40) return "#0497e6";
  //     if (_pulse < 100 && _pulse > 60) return "#38b407";
  //     if (_pulse < 200 && _pulse > 101) return "#fcc202";
  //   } else {
  //     return "black";
  //   }
  // };
  const renderAlcoholValColor = (_alcohol) => {
    if (_alcohol && !isEmpty(_alcohol.toString())) {
      if (_alcohol === "Teetotal") return "#0497e6";
      if (_alcohol === "1-5 units per week") return "#38b407";
      if (_alcohol === "11-15 units per week") return "#fcc202";
      if (_alcohol === "26-30 units per week") return "#fa6f03";
      if (
        _alcohol === "36-40 units per week" ||
        _alcohol === "31-35 units per week"
      )
        return "#ea1b06";
      if (
        _alcohol === "41+ units per week" ||
        _alcohol ===
          "Binge drinking (drinking an excessive amount of alcohol in a short period of time)"
      )
        return "#bf2422";
    } else {
    }
  };
  const renderHighest = (_d, _id) => {
    if (_d) {
      let priority = {};
      // let obj = {
      //   systolic_bp: _d.systolic_bp,
      //   diastolic_bp: _d.diastolic_bp,
      //   bmi: _d.bmi,
      //   smoke: _d.smoke,
      //   alcohol: _d.alcohol,
      //   pulse: _d.pulse_per_minute,
      // };
      // BMI
      const _bmi = parseFloat(_d.bmi === null ? 0 : _d.bmi);
      // BMI
      if (_bmi > 0) {
        if (_bmi < 18.5) priority = {...priority, bmi: 1};
        if (_bmi > 18.5 && _bmi < 24.9) priority = {...priority, bmi: 2};
        if (_bmi > 25 && _bmi < 29.9) priority = {...priority, bmi: 3};
        if (_bmi > 30 && _bmi < 39.9) priority = {...priority, bmi: 4};
        if (_bmi >= 40) priority = {...priority, bmi: 5};
      } else {
        priority = {...priority, bmi: 0};
      }
      // Systolic priority
      const _sys_bp = parseInt(_d.systolic_bp, 10);

      if (_sys_bp) {
        if (_sys_bp < 90 && _sys_bp > 70) priority = {...priority, sys: 1};
        if (_sys_bp < 120 && _sys_bp > 90) priority = {...priority, sys: 2};
        if (_sys_bp < 140 && _sys_bp > 120) priority = {...priority, sys: 3};
        if (_sys_bp < 160 && _sys_bp > 140) priority = {...priority, sys: 4};
        if (_sys_bp < 180 && _sys_bp > 160) priority = {...priority, sys: 5};
        if (_sys_bp < 190 && _sys_bp > 180) priority = {...priority, sys: 6};
      } else {
        priority = {...priority, sys: 0};
      }

      const _dia_bp = parseInt(_d.diastolic_bp, 10);

      if (_dia_bp) {
        if (_dia_bp < 90 && _dia_bp > 70) priority = {...priority, _dia_bp: 1};
        if (_dia_bp < 120 && _dia_bp > 90) priority = {...priority, _dia_bp: 2};
        if (_dia_bp < 140 && _dia_bp > 120)
          priority = {...priority, _dia_bp: 3};
        if (_dia_bp < 160 && _dia_bp > 140)
          priority = {...priority, _dia_bp: 4};
        if (_dia_bp < 180 && _dia_bp > 160)
          priority = {...priority, _dia_bp: 5};
        if (_dia_bp < 190 && _dia_bp > 180)
          priority = {...priority, _dia_bp: 6};
      } else {
        priority = {...priority, _dia_bp: 0};
      }
      const _pulse = parseInt(_d.pulse_per_minute, 10);

      if (_pulse) {
        if (_pulse < 59 && _pulse > 40) priority = {...priority, _pulse: 1};
        if (_pulse < 100 && _pulse > 60) priority = {...priority, _pulse: 2};
        if (_pulse < 200 && _pulse > 101) priority = {...priority, _pulse: 3};
      } else {
        priority = {...priority, _pulse: 0};
      }

      const _smoke = _d.smoke;

      if (_smoke) {
        if (_smoke === "Non smoker") {
          // smokehighest({ _p: 0, _c: "#0497e6" });
          priority = {...priority, _smoke: 1};
          // return "#0497e6";
        }
        if (
          _smoke === "1-5 cigarettes per day" ||
          _smoke === "6-10 cigarettes per day"
        ) {
          // setHighest(1);
          // smokehighest({ _p: 1, _c: "#38b407" });
          priority = {...priority, _smoke: 2};
        }
        if (
          _smoke === "11-15 cigarettes per day" ||
          _smoke === "16-20 cigarettes per day"
        ) {
          // smokehighest({ _p: 2, _c: "#fcc202" });
          priority = {...priority, _smoke: 3};
        }
        if (
          _smoke === "21-25 cigarettes per day" ||
          _smoke === "26-30 cigarettes per day"
        ) {
          // smokehighest({ _p: 3, _c: "#fa6f03" });
          priority = {...priority, _smoke: 4};
        }
        if (
          _smoke === "31-35 cigarettes per day" ||
          _smoke === "36-40 cigarettes per day"
        ) {
          // smokehighest({ _p: 4, _c: "#ea1b06" });
          priority = {...priority, _smoke: 5};
        }
        if (_smoke === "41+ cigarettes per day") {
          // smokehighest({ _p: 5, _c: "#bf2422" });
          priority = {...priority, _smoke: 6};
          // return "#bf2422";
        }
      } else {
        priority = {...priority, _smoke: 0};
      }
      const _alcohol = _d.alcohol;

      if (_alcohol && !isEmpty(_alcohol.toString())) {
        if (_alcohol === "Teetotal") {
          priority = {...priority, _alcohol: 1};
        }
        if (_alcohol === "1-5 units per week")
          priority = {...priority, _alcohol: 2};
        if (_alcohol === "11-15 units per week")
          priority = {...priority, _alcohol: 3};
        if (_alcohol === "26-30 units per week")
          priority = {...priority, _alcohol: 4};
        if (
          _alcohol === "36-40 units per week" ||
          _alcohol === "31-35 units per week"
        )
          priority = {...priority, _alcohol: 5};
        if (
          _alcohol === "41+ units per week" ||
          _alcohol ===
            "Binge drinking (drinking an excessive amount of alcohol in a short period of time)"
        )
          priority = {...priority, _alcohol: 6};
      } else {
        priority = {...priority, _alcohol: 0};
      }

      console.log("priority", priority);
      let highestVal = max(Object.values(priority));
      console.log("highestVal", highestVal);
      const highestColor = _getHighestColor(highestVal);
      return (
        <InfoCircleOutlined
          style={{
            color: highestColor,
            fontSize: "20px",
          }}
          className="ml-2"
        />
      );
    }

    //  setState({..})
  };
  const _getHighestColor = (highestVal) => {
    // console.log("")
    if (highestVal) {
      if (highestVal === 1) return "#0497e6";
      if (highestVal === 2) return "#38b407";
      if (highestVal === 3) return "#fcc202";
      if (highestVal === 4) return "#fa6f03";
      if (highestVal === 5) return "#ea1b06";
      if (highestVal === 6) return "#bf2422";
    } else {
      return "black";
    }
  };
  const ViewOptions = ({marketingOption}) => {
    console.log("marketingOption", marketingOption);
    return (
      <div className="adress_popup cheboxpopover">
        <b className="d-block">Marketing Options</b>
        <div className="checkboxgroup">
          <div className="checkbox_main">
            <Checkbox
              checked={state.is_notify_by_push_notification}
              defaultChecked={marketingOption.is_notify_by_push_notification}
              onChange={Pushnotify}
            >
              Push Notification
            </Checkbox>
          </div>
          <div className="checkbox_main">
            <Checkbox
              defaultChecked={marketingOption.is_notify_by_email}
              checked={state.is_notify_by_email}
              onChange={EmailCheck}
            >
              Email
            </Checkbox>
          </div>
          <div className="checkbox_main">
            <Checkbox
              defaultChecked={marketingOption.is_notify_by_phone}
              checked={state.is_notify_by_sms}
              onChange={MsgCheck}
            >
              Message
            </Checkbox>
          </div>
          <div className="saveoptionsbtn">
            <Button
              onClick={handleMarketing}
              disabled={state.isLoading}
              loading={state.isLoading}
              className="height-30 p-0 btn btn-custom btn-blue w-100 font-12 weight-500 text-white tablet-font-10"
            >
              {state.isLoading ? "Saving..." : "Save Options"}
            </Button>
          </div>
        </div>
      </div>
    );
  };

  // bio js
  const ViewBio = ({bio, patient}) => {
    const findEthnicity = Ethnicity.find(
      (eth) => eth.value === verifyObject(patient, "ethnicity")
    );
    const findGenderPreference = GenderPreference.find(
      (pre) => pre.value === verifyObject(patient, "gender_preference")
    );
    return (
      <div className="adress_popup biopopover">
        {bio && (
          <>
            <b className="d-block">Bio</b>
            <span className="d-block">
              Weight: {bio.weight_value1 ? bio.weight_value1 : "-"}
            </span>
            <span className="d-block">
              Height:{" "}
              {bio.height_value1
                ? `${verifyObject(bio, "height_value1", "")} ${verifyObject(
                    bio,
                    "height_value2",
                    ""
                  )}`
                : "-"}{" "}
            </span>
            <span className="d-block">
              Gender Preference:{" "}
              {findGenderPreference ? `${findGenderPreference.label}` : "-"}{" "}
            </span>{" "}
            <span className="d-block">
              Ethnicity: {findEthnicity ? `${findEthnicity.label}` : "-"}{" "}
            </span>
            {bio.bmi && (
              <span
                style={{
                  color: renderBMIColor(bio.bmi),
                }}
                className="d-block"
              >
                BMI: {bio.bmi ? bio.bmi : "-"}
              </span>
            )}
            {bio.smoke && (
              <span
                style={{
                  color: renderSmokingValColor(bio.smoke),
                }}
                className="d-block"
              >
                Smoking : {bio.smoke ? bio.smoke : "-"}
              </span>
            )}
            {bio.alcohol && (
              <span
                style={{
                  color: renderAlcoholValColor(bio.alcohol),
                }}
                className="d-block"
              >
                Alcohol : {bio.alcohol ? bio.alcohol : "-"}
              </span>
            )}
            {console.log(
              "---->",
              renderHealthAssessmentInputColor(
                bio.systolic_bp,
                "systolic_bp",
                true
              )
            )}
            {bio.systolic_bp && (
              <span
                className={`d-block ${renderHealthAssessmentInputColor(
                  bio.systolic_bp,
                  "systolic_bp",
                  true
                )}`}
              >
                Systolic BP : {bio.systolic_bp ? bio.systolic_bp : "-"}
              </span>
            )}
            {bio.diastolic_bp && (
              <span
                // style={{
                //   color: renderDiastolicBpColor(bio.diastolic_bp),
                // }}
                className={`d-block ${renderHealthAssessmentInputColor(
                  bio.systolic_bp,
                  "diastolic_bp",
                  true
                )}`}
              >
                Diastolic BP : {bio.diastolic_bp ? bio.diastolic_bp : "-"}
              </span>
            )}
          </>
        )}
      </div>
    );
  };
  // const ViewUsage = ({ bio }) => (
  //   // console.log("===========>", bio);
  //   <div className="adress_popup biopopover">
  //     {bio && (
  //       <>
  //         <b className="d-block">Last Used</b>
  //         <span className="d-block">
  //           App:
  //           {bio[0] ? moment(bio[0]).format(DATE_FORMAT_24_HR) : "-"}
  //         </span>
  //         <span className="d-block">
  //           Health Summary :
  //           {bio[1] ? moment(bio[1]).format(DATE_FORMAT_24_HR) : "-"}
  //         </span>
  //       </>
  //     )}
  //   </div>
  // );
  // const ViewUsage = ({ bio }) => (
  //   // console.log("===========>", bio);
  //   <div className="adress_popup biopopover">
  //     {bio && (
  //       <>
  //         <span className="d-block">
  //           {bio[0] ? moment(bio[0]).format(DATE_FORMAT_24_HR) : "-"}
  //         </span>
  //         <span className="d-block">
  //           {bio[1] ? moment(bio[1]).format(DATE_FORMAT_24_HR) : " "}
  //         </span>
  //       </>
  //     )}
  //   </div>
  // );
  const toggleThreeDots = (id) => {
    setState((prevState) => {
      return {[`isShow${id}`]: !state[`isShow${id}`]};
    });
  };
  // const handleEditModal = async (id) => {
  //   store.dispatch({type: "ON_PATIENT_SELECT", payload: id});
  // };
  const isPharmacyMatchClass =
    patient?.is_pharmacy_matched === false &&
    (userRole === ROLE_CLINICAL_PHARMACIST ||
      userRole === ROLE_SUPERINTENDENT_PHARMACIST)
      ? true
      : false;
  const handleOtpModal = (patientId, patient) => {
    setOtpModal(true);
  };
  console.log("contact type---", contact_type);
  const handleRequestOtp = async (patientId) => {
    try {
      setRequesting(true);
      let payload = {
        user_id: patientId,
        contact_type: contact_type,
      };
      // let formData = new FormData();
      // formData.set("user_id", patientId);
      // formData.set("contact_type", contact_type);
      let response = await sendOtpRequestPatient(payload);
      console.log("response---", response);
      if (response.data.status === 200) {
        successToast({
          content: verifyObject(response, "data.message", "Sent successfully"),
        });

        setOtpModal(false);
        setVerifyOtpModal(true);
      }

      setRequesting(false);
    } catch (e) {
      const {message} = getErrorObject(e);
      errorToast({
        content: message,
      });
      setRequesting(false);
    }
  };
  const handleRequestVerifyOtp = async (patineId, code) => {
    try {
      setRequestingVerifyOtp(true);
      let formData = new FormData();
      formData.set("user_id", patineId);
      formData.set("otp", code);
      // formData.set("contact_type", contact_type);
      let response = await verifyOtpRequestPatient(formData);
      let requestedPatient = verifyObject(response, "data.data.patient", {});
      successToast({
        content: verifyObject(response, "data.message", "Patient verified"),
      });
      await handleReplacePatient(requestedPatient);
      console.log("response---", response);
      setVerifyOtpModal(false);
      setRequestingVerifyOtp(false);
    } catch (e) {
      const {message} = getErrorObject(e);
      errorToast({
        content: message,
      });
      setRequestingVerifyOtp(false);
    }
  };

  return (
    <Fragment>
      <table className="relative border w-100">
        <tr>
          {/* <td
            className={
              `text-left font-12 padding-6-t padding-6-b text-left padding-left-12 width-4 newp_id`

            }
            style={{
              cursor: isPharmacyMatchClass ? "not-allowed" : "pointer",
            }}
          >
            <div
              className="view_option_drop"
              style={{
                cursor: isPharmacyMatchClass ? "not-allowed" : "pointer",
              }}
            >
              <span
                onClick={() =>
                  isPharmacyMatchClass ? {} : handleView(patient, true)
                }
                onCopy={disableCopy}
                style={{
                  cursor: isPharmacyMatchClass ? "not-allowed" : "pointer",
                }}
              >
                {patient.id}
              </span>
            </div>
          </td> */}
          <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 newp_name`}>
            <div className="patient_profile">
              <div className="d-flex justify-content-center report-user flex-column">
                {/* <span className="ant-avatar ant-avatar-circle ant-avatar-icon">
                  <img alt="" src={imgnew_user} />
                </span> */}
                <div class="doctor_profile_text padding-left-10">
                  <a>
                    <span
                      onCopy={disableCopy}
                      className="d-block"
                      onClick={() =>
                        isPharmacyMatchClass ? {} : handleView(patient, true)
                      }
                    >
                      {verifyObject(patient, "is_child_account", false) ? (
                        <Tooltip placement="top" title="Child Account">
                          <span className="mr-1 custom-bluelabel font-10 in-block text-capitalize">
                            C
                          </span>
                        </Tooltip>
                      ) : null}
                      <span className="text-blue-imp">{patient.name}</span>
                      <div className="mt-1">
                        <b onCopy={disableCopy}>
                          {/* {moment(patient.dob).format("DD/MM/YYYY")}({patient.age} yrs) */}
                          {patient.nhs_number ? patient.nhs_number : ""}
                        </b>
                      </div>
                    </span>
                  </a>

                  <div className="view_option_drop">
                    <Popover
                      placement="bottom"
                      content={
                        <ViewBio
                          bio={verifyObject(patient, "profile_progress", null)}
                          patient={patient}
                        />
                      }
                      className="address_pop"
                    >
                      <span className="cursor-pointer" onCopy={disableCopy}>
                        {/* <img src={imginfo} alt="" className="cursor-pointer" /> */}
                        {/* <InfoCircleOutlined /> */}
                        {renderHighest(
                          verifyObject(patient, "profile_progress", null)
                        )}
                      </span>
                    </Popover>
                  </div>
                </div>
              </div>
            </div>
          </td>
          <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 [w-110] newp_age`}>
            {patient.dob && verifyObject(patient, "dob", "") !== "" ? (
              <div className="mt-1">
                <span onCopy={disableCopy}>
                  {/* {moment(patient.dob).format("DD/MM/YYYY")}({patient.age} yrs) */}
                  {moment(patient.dob).format("DD/MM/YYYY")}
                </span>
              </div>
            ) : (
              ""
            )}
            {patient.age ? <span>{patient.age} yrs</span> : <span>-</span>}
          </td>
          <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 [w-80] newp_gender`}
          >
            {/* <span>{patient.gender}</span> */}
            <a>
              <span
                class={`gender ${
                  patient.gender === "male"
                    ? "male"
                    : patient.gender === "female"
                    ? "female"
                    : "other"
                }`}
              >
                {patient.gender === "male"
                  ? "M"
                  : patient.gender === "female"
                  ? "F"
                  : "O"}
              </span>
            </a>
          </td>
          <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 [w-200] newp_addr ${
              isPharmacyMatchClass ? "disable-listing" : ""
            }`}
          >
            <div className="address_txt" onCopy={disableCopy}>
              <span className="d-block">
                {verifyObject(patient, "patient_address.line1") ||
                verifyObject(patient, "patient_address.town") ||
                verifyObject(patient, "patient_address.pincode") ? (
                  <>
                    <div className="d-flex align-items-center gapx-1">
                      <span className="ellipsis-1-line" title={verifyObject(patient, "patient_address.line1")}>
                        {verifyObject(patient, "patient_address.line1")}
                      </span>
                      {patient.patient_address && (
                        <div className="address_popover">
                          <Popover
                            placement="bottom"
                            content={
                              <Mainaddresscontent
                                patient_address={verifyObject(
                                  patient,
                                  "patient_address",
                                  ""
                                )}
                              />
                            }
                            className="address_pop"
                          >
                            <img
                              src={imginfo}
                              alt=""
                              className="cursor-pointer"
                            />
                          </Popover>
                        </div>
                      )}
                    </div>
                    <span className="d-block mt-1">
                      {verifyObject(patient, "patient_address.town")}, {verifyObject(patient, "patient_address.pincode")}
                    </span>
                  </>
                ) : patient?.is_pharmacy_matched === false ? (
                  <>
                    <div className="d-flex align-items-center gapx-1">
                      <span className="flex-1">
                        <p className="ellipsis-1-line">
                          80 Laurel Avenue
                        </p>
                      </span>

                      <div className="address_popover">
                        <Popover placement="bottom" className="address_pop">
                          <img
                            src={imginfo}
                            alt=""
                            className="cursor-pointer"
                          />
                        </Popover>
                      </div>
                    </div>
                    <p className="ellipsis-1-line">
                      BOLTON BL3 1AS
                    </p>
                  </>
                ) : (
                  ""
                )}
              </span>
            </div>
          </td>

          <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 [w-15p] newp_surgery ${
              isPharmacyMatchClass ? "disable-listing" : ""
            }`}
          >
            <div className="address_txt d-flex align-items-center">
              <span className="" onCopy={disableCopy}>
                <p className="d-flex align-items-center gapx-1">
                  <span className="ellipsis-1-line" title={verifyObject(patient, "gp_surgery.practice_name", "")}>
                    {patient.gp_surgery
                      ? verifyObject(patient, "gp_surgery.practice_name", "")
                      : patient?.is_pharmacy_matched === false
                      ? "SPECIALIST NURSE"
                      : "-"}
                  </span>

                  {patient.gp_surgery ? (
                    <div className="address_popover">
                      <Popover
                        placement="bottom"
                        content={
                          <Addresscontent
                            gp_surgery={verifyObject(patient, "gp_surgery", "")}
                          />
                        }
                        className="address_pop"
                      >
                        <img src={imginfo} alt="" className="cursor-pointer" />
                      </Popover>
                    </div>
                  ) : patient?.is_pharmacy_matched === false ? (
                    <div className="address_popover">
                      <Popover placement="bottom" className="address_pop">
                        <img src={imginfo} alt="" className="cursor-pointer" />
                      </Popover>
                    </div>
                  ) : (
                    "-"
                  )}
                </p>
                <p className="ellipsis-1-line mt-1"
                  title={`${verifyObject(patient, "gp_surgery.address")}${verifyObject(patient, "gp_surgery.address") &&
                    verifyObject(patient, "gp_surgery.post_code")
                      ? ", "
                      : ""}${verifyObject(patient, "gp_surgery.post_code")}`}>
                  {verifyObject(patient, "gp_surgery.address")}
                  {verifyObject(patient, "gp_surgery.address") &&
                    verifyObject(patient, "gp_surgery.post_code") && (
                      <>
                        {verifyObject(patient, "gp_surgery.address") &&
                        verifyObject(patient, "gp_surgery.post_code")
                          ? ", "
                          : ""}
                        {verifyObject(patient, "gp_surgery.post_code")
                          ? verifyObject(patient, "gp_surgery.post_code")
                          : "-"}
                      </>
                    )}
                </p>
              </span>
            </div>
          </td>
          <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 [w-15p] newp_nomiphar ${
              isPharmacyMatchClass ? "disable-listing" : ""
            }`}
          >
            <div
              className="address_txt d-flex align-items-center gapx-1"
              onCopy={disableCopy}
            >
              <span className="ellipsis-1-line" title={verifyObject(patient, "pharmacy.name", "")}>
                <p className="pharmacy_name">
                  {patient.pharmacy
                    ? verifyObject(patient, "pharmacy.name", "")
                    : patient?.is_pharmacy_matched === false
                    ? "ADD pharmacy"
                    : "-"}
                </p>
              </span>

              {patient.pharmacy ? (
                <div className="pharmacyadress">
                  <div className="address_popover ml-2">
                    <Popover
                      placement="bottom"
                      content={
                        <Pharmacycontent
                          address={verifyObject(
                            patient,
                            "pharmacy_address",
                            ""
                          )}
                        />
                      }
                      className="address_pop"
                    >
                      <img src={imginfo} alt="" className="cursor-pointer" />
                    </Popover>
                  </div>
                </div>
              ) : patient?.is_pharmacy_matched === false ? (
                <div className="pharmacyadress">
                  <div className="address_popover ml-2">
                    <Popover placement="bottom" className="address_pop">
                      <img src={imginfo} alt="" className="cursor-pointer" />
                    </Popover>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <p className="ellipsis-1-line mt-1 pharmacy_address" title={`${verifyObject(patient, "pharmacy_address.line1")} ${verifyObject(patient, "pharmacy_address.pincode")}`}>
              {patient.pharmacy ? (
                <>
                  {verifyObject(patient, "pharmacy_address.line1") ||
                  verifyObject(patient, "pharmacy_address.pincode") ? (
                    <>
                      {verifyObject(patient, "pharmacy_address.line1")}
                      {verifyObject(patient, "pharmacy_address.line1") &&
                      verifyObject(patient, "pharmacy_address.pincode")
                        ? ","
                        : ""}
                    </>
                  ) : patient?.is_pharmacy_matched === false ? (
                    "ADDVantage Technologies, Clive House,BL11ET"
                  ) : (
                    "-"
                  )}
                  {verifyObject(patient, "pharmacy_address.pincode")}
                </>
              ) : patient?.is_pharmacy_matched === false ? (
                "ADDVantage Technologies, Clive House,BL11ET"
              ) : (
                "-"
              )}
            </p>
          </td>
          <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 [w-160] newp_info ${
              isPharmacyMatchClass ? "disable-listing" : ""
            }`}
          >
            <div className="contact_info" onCopy={disableCopy}>
              <span className="d-block ellipsis-1-line" title={verifyObject(patient, "email", "")}>
                {verifyObject(patient, "email", "")
                  ? verifyObject(patient, "email", "")
                  : patient?.is_pharmacy_matched === false
                  ? "samsu.b@gmail.com"
                  : ""}
              </span>
              <span className="ellipsis-1-line" title={verifyObject(patient, "phone", "")}>
                {" "}
                {verifyObject(patient, "phone", "")
                  ? verifyObject(patient, "phone", "")
                  : patient?.is_pharmacy_matched === false
                  ? "+919737865233"
                  : ""}
              </span>
            </div>
          </td>
          <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 [w-140] newp_option ${
              isPharmacyMatchClass ? "disable-listing" : ""
            }`}
          >
            <div className="view_option_drop">
              {patient.marketing_options ? (
                <Popover
                  placement="bottom"
                  content={() => (
                    <ViewOptions marketingOption={patient.marketing_options} />
                  )}
                  className="address_pop"
                >
                  <span className="cursor-pointer">View Options</span>
                </Popover>
              ) : patient?.is_pharmacy_matched === false ? (
                <Popover placement="bottom" className="address_pop">
                  <span className="cursor-pointer">View Options</span>
                </Popover>
              ) : (
                "-"
              )}
            </div>
          </td>
          {/* <td className="text-left width-6 font-12 padding-6-t padding-6-b text-left padding-left-12"></td> */}
          <td className={`text-left [w-140] font-12 padding-6-t padding-6-b text-left padding-left-12 newp_usertype text-nowrap ${
              isPharmacyMatchClass ? "disable-listing" : ""
            }`}
          >
            <div className="view_option_drop">
              <span
                className={`d-block ${
                  (usageType === "app" &&
                    (!patient.last_usage_date ||
                      patient.last_usage_date === null)) ||
                  (usageType === "health_kiosk" &&
                    (!patient.last_health_summary_update ||
                      patient.last_health_summary_update === null))
                    ? "nullfield"
                    : ""
                }`}
              >
                {usageType === "app"
                  ? patient.last_usage_date && patient.last_usage_date !== null
                    ? moment(patient.last_usage_date).format(DATE_FORMAT_12_HR)
                    : "-"
                  : patient.last_health_summary_update &&
                    patient.last_health_summary_update !== null
                  ? moment(patient.last_health_summary_update).format(
                      DATE_FORMAT_12_HR
                    )
                  : patient?.is_pharmacy_matched === false
                  ? "25-10-2023 05:30 AM"
                  : "-"}
              </span>
            </div>
          </td>
          {isPharmacyMatchClass ? (
            <td className={`text-left font-12 padding-6-t padding-6-b text-left padding-left-12 padding-right-12 newp_action`}
            >
              <Button
                type="primary"
                onClick={() => handleOtpModal(patient.id, patient)}
                className="request_otp"
                disabled={patient?.phone && patient?.email ? false : true}
              >
                Request Access
              </Button>
            </td>
          ) : (
            <td className={`[w-90] text-left font-12 padding-6-t padding-6-b text-left padding-left-12 padding-right-12  newp_action ${
                isPharmacyMatchClass ? "disable-listing" : ""
              }`}
            >
              <div className="d-flex justify-content-center">
                <Fragment>
                  <Dropdown
                    isOpen={state[`isShow${patient.id}`]}
                    toggle={() => toggleThreeDots(patient.id)}
                    className="text-right in-block"
                  >
                    <DropdownToggle tag="span" data-toggle="dropdown">
                      <button
                        className="padding-0 no-background border-0 no-focus"
                        onClick={(e) => e.preventDefault()}
                      >
                        {/* <img
                        src={three_vertical_dots}
                        className="opacity-05"
                        alt="three_v_dots"
                      ></img> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="8"
                          height="20"
                          viewBox="0 0 8 20"
                        >
                          <path
                            fill="#1445b7"
                            fill-rule="evenodd"
                            d="M4 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                          />
                        </svg>
                      </button>
                    </DropdownToggle>
                    <DropdownMenu
                      right
                      className="width-115px font-12 p-0-imp border border-radius-0-imp"
                    >
                      <DropdownItem
                        onClick={() => handleView(patient, true)}
                        className="font-12 margin-0 padding-6-imp weight-400"
                      >
                        View Detail
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => _openAppointment(patient)}
                        className="font-12 margin-0 padding-6-imp weight-400"
                      >
                        Book Appointment
                      </DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          showEditModal(patient);
                        }}
                        disabled={state.isLoading}
                        loading={state.isLoading}
                        className="font-12 margin-0 padding-6-imp weight-400"
                      >
                        Edit Patient
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </Fragment>

                {showEdit && (
                  <EditPatientDetail
                    customer_id={patient.id}
                    hideEditModal={hideEditModal}
                    showEdit={showEdit}
                    fromPrescriber={true}
                    toggleEdit={toggleEdit}
                    {...patientDetails}
                    _getPatientDetails={() => {
                      clearSearchedPatients();
                    }}
                  />
                )}
              </div>
            </td>
          )}
        </tr>
      </table>
      {showOtpModal && (
        <SendOtpPatient
          showOtpModal={showOtpModal}
          setOtpModal={setOtpModal}
          handleRequestOtp={handleRequestOtp}
          requesting={requesting}
          patientId={patient?.id}
          missing_email={patient?.email ? false : true}
          missing_phone={patient?.phone ? false : true}
          contact_type={contact_type}
          setContactType={setContactType}
        />
      )}
      {showVerifyOtpModal && (
        <VerifyOtpPatient
          showVerifyOtpModal={showVerifyOtpModal}
          setVerifyOtpModal={setVerifyOtpModal}
          handleRequestVerifyOtp={handleRequestVerifyOtp}
          requestingVerifyOtp={requestingVerifyOtp}
          patientId={patient?.id}
          contact_type={contact_type}
          patient={patient}
        />
      )}
    </Fragment>
  );
}

import { get, Irequest, post } from "./index";

export type PoliciesParams = {
  page?: number,
  category_id?: number,
  status?: string,
  from_date?: string,
  to_date?: string,
  download_data?: string
}

export const getPoliciesApi = (params: PoliciesParams) => {
  const request: Irequest = {
    subUrl: `/doctor_policies/list_all_policies.json`,
    params,
  };
  return get(request);
};


export const uploadNewPolicyApi = (data) => {
  let bodyFromData = new FormData()
  bodyFromData.set("policy_file", data.policy_file);
  bodyFromData.set("sub_category_id", data.sub_category_id);

  const request: Irequest = {
    subUrl: `/doctor_policies/upload_policy.json`,
    data: bodyFromData,
  };
  return post(request);
}

export const getCategoriesApi = params => {
  const request: Irequest = {
    subUrl: `/doctor_policies/policy_category.json`,
    params
  };
  return get(request);
}


export const setPolicyDownloadForDoctor = data => {
  const request = {
    subUrl: `/doctor_policies/set_policy_downloaded_for_doctor.json`,
    data
  };
  return post(request);
}



/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { Fragment } from "react";
import Layout from "Layouts/PageLayout";
import { connect } from "react-redux";
import { appRoutesConst } from "../../app/navigation";
import { bindActionCreators } from "redux";
import {
  onRequestSetComponent,
  onSetSidebarKey,
  onSetDynamicHealthInput,
  onSetDynamicInput,
} from "../../reducers/dynamicTab";
import { actions as patientDetailActions } from "../../reducers/patientDetail";
import { navigateTo } from "../../app/navigation";
import { setOfflineCallStatus } from "../../reducers/onlineOfflineCall";
import PatientDetailContainer from "../../containers/PatientDetailContainer/PatientDetailContainer";
import {
  disableCopy,
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";
import ScreenshotContainer from "containers/PatientConsultationConatainers/ScreenshotContainer/ScreenshotContainer";
import MedicalHistoryContainer from "../../containers/PatientDetailContainer/MedicalHistoryContainer/MedicalHistoryContainer";
import AllergiesContainer from "../../containers/PatientDetailContainer/AllergiesContainer/AllergiesContainer";
import AcuteMedicationsContainer from "../../containers/PatientDetailContainer/AcuteMedicationsContainer/AcuteMedicationsContainer";
import NHSContainer from "../../containers/PatientDetailContainer/NHSContainer/NHSContainer";
import FamilyRecordsContainer from "../../containers/PatientDetailContainer/FamilyRecordsContainer/FamilyRecordsContainer";
import TestInvestigationContainer from "../../containers/PatientDetailContainer/TestInvestigationContainer/TestInvestigationContainer";
import PreQuestionnaireContainer from "../../containers/PatientDetailContainer/PreQuestionnaireContainer/PreQuestionnaireContainer";
import ReportsContainer from "../../containers/PatientDetailContainer/ReportsContainer/ReportsContainer";
import SpecialNotesContainer from "../../containers/PatientDetailContainer/SpecialNotesContainer/SpecialNotesContainer";
import PreEncountersContainer from "../../containers/PatientDetailContainer/PreEncountersContainer/PreEncountersContainer";
import PrescriptionsContainer from "../../containers/PatientDetailContainer/PrescriptionsContainer/PrescriptionsContainer";
import SickNotesContainer from "../../containers/PatientDetailContainer/SickNotesContainer/SickNotesContainer";
import ReferralNotesContainer from "../../containers/PatientDetailContainer/ReferralNotesContainer/ReferralNotesContainer";
import PastOrdersContainer from "../../containers/PatientDetailContainer/PastOrdersContainer/PastOrdersContainer";
// import doctor_profile from "assets/images/header/doctor_profile.svg";
import { Prompt, withRouter } from "react-router-dom";
import {
  DATE_FORMAT,
  ROLE_DOCTOR,
  ROLE_HCSW,
  ROLE_NURSE,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "../../constants/common";
// import patientdetails from "assets/images/sidebar/ic-patient-details.svg";
// import medicalhistory from "assets/images/sidebar/ic-medical-history.svg";
// import testinvestigation from "assets/images/sidebar/ic-test.svg";
// import allergies from "assets/images/sidebar/ic-allergies-sensitivities.svg";
// import currentmedication from "assets/images/sidebar/ic-medication-history.svg";
// import reports from "assets/images/sidebar/ic-reports.svg";
// import specialnotes from "assets/images/sidebar/ic-specialnotes.svg";
// import familyhistory from "assets/images/sidebar/ic-family-history.svg";
// import previousencounters from "assets/images/sidebar/ic-previous-encounters.svg";
// import nhs from "assets/images/sidebar/ic-nhs.svg";
// import prescription from "assets/images/sidebar/ic-prescription.svg";
// import pastorders from "assets/images/sidebar/ic-past-orders.svg";
// import referral from "assets/images/sidebar/ic-referral.svg";
// import sicknote from "assets/images/sidebar/ic-sicknote.svg";
// import prescreeningquestionnaire from "assets/images/sidebar/ic-prescreeningquestionnaire.svg";
import topbarback from "assets/images/profile_header/topbar-back.svg";
// import screenshot from "assets/images/sidebar/ic-screenshots-uploads.svg";
import CollapsiableSubHeader from "../../components/common/CollapsiableSubHeader/CollapsiableSubHeader";
import HealthAssessmentContainer from "../../containers/PatientDetailContainer/HealthAssessmentContainer/HealthAssessmentContainer";
// import healthassessment from "../../assets/images/sidebar/ic_Health Assessments_blue.svg";

// import QuestionnaireReviewTabContainer from "../../containers/QuestionnaireReviewContainter/QuestionnaireReviewTabContainer";

import moment from "moment";
import "assets/css/pages/profile-detail.css";
import { startAppointment } from "services/appointments";
import { onSetPatient, onSetPatientAppointment } from "reducers/patients";
import store from "app/store";
import { getPatientHealthAssessmentDetailApi } from "services/patientConsultation";
// import {Modal} from "antd";
// import store from "app/store";
//svg icons changes
import history from "assets/images/sidebar/ic-history.svg";
import WalkinAppointmentContainer from "../../containers/WalkinAppointment/WalkinAppointmentContainer";
import CommonLoader from "components/common/CommonLoader";
import PreConsultNotification from "components/PreConsult/PreConsultNotification";
import {
  AllergicmenuIcon,
  FamilyhistorymenuIcon,
  HealthassessmentIcon,
  MediacalhistorymenuIcon,
  MedicationHistorymenuIcon,
  NhsmenuIcon,
  PatientdetailsIcon,
  PreviousencounterIcon,
  SpecialnotemenuIcon,
  TestinvestigatemenuIcon,
} from "pages/WalkinAppointments/VideoAppointmentPage";
import { HistorymenuIcon } from "pages/QuestionnaireReview/QuestionnaireReviewDetailPage";
import { Button, Tooltip } from "antd";
import photo_id from "assets/images/profile_header/id-card.svg";
import ImageSliderModal from "pages/WalkinAppointments/ImageSliderModal";
import { addCallNotesAPI, verifyPatientAPI } from "services/patients";
import BookAppointmentForPatientContainer from "containers/AppointmentsContainers/BookAppointmentForPatientContainer";

//sidebar svg menu icon functions

class PatientDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: "PatientDetailContainer",
      isOpenReasonModal: false,
      reason: "",
      isLoading: false,
      enableReasonSave: true,
      startingConsultation: false,
    };
    [
      "_getIndexRoute",
      "_renderDynamicComponent",
      "_getPatientDetails",
      "_handleNavigation",
      "_handleBack",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  componentDidMount() {
    let { componentName } = this.props;
    // if (is_clicked_view) {
    // } else {
    this._getIndexRoute();
    this._getPatientDetails();
    console.log("COMPONENT NANE---", componentName);
    // this.setState({componentName});
    // }

    this.setState({
      componentName: componentName ? componentName : "PatientDetailContainer",
    });
    // store.dispatch();
  }

  _getIndexRoute() {
    let { onRequestSetComponent, onSetSidebarKey } =
      this.props.dynamicTabAction;
    // let {is_clicked_view} = this.props;

    if (typeof onRequestSetComponent === "function") {
      // if (is_clicked_view) {
      //   onRequestSetComponent("WalkinAppointmentContainer");
      // } else {
      onRequestSetComponent("PatientDetailContainer");
      // }
    }
    if (typeof onSetSidebarKey === "function") {
      // if (is_clicked_view) {
      //   onSetSidebarKey([`90`]);
      // } else {
      onSetSidebarKey([`1`]);
      // }
    }
  }

  _getPatientDetails(patient_id) {
    let {
      patientId,
      patientDetailActions: { onRequest },
    } = this.props;
    if (typeof onRequest === "function") {
      onRequest({ id: patient_id ? patient_id : patientId });
    }
  }

  componentWillReceiveProps(nextProps) {
    let { componentName } = nextProps;
    this.setState({ componentName });
  }

  componentDidUpdate(prevProps, nextState) {}

  _renderDynamicComponent() {
    let { componentName } = this.state;

    let { patientDetail, isLoadingDetail } = this.props;
    switch (componentName) {
      case "PatientDetailContainer":
        return (
          <PatientDetailContainer
            isLoadingDetail={isLoadingDetail}
            patientDetail={patientDetail}
            _getPatientDetails={this._getPatientDetails}
            patientId={this.props.patientId}
          />
        );
      case "HealthAssessmentContainer":
        return <HealthAssessmentContainer />;
      case "MedicalHistoryContainer":
        return <MedicalHistoryContainer />;

      case "AllergiesContainer":
        return <AllergiesContainer />;

      case "AcuteMedicationsContainer":
        return <AcuteMedicationsContainer />;

      case "NHSContainer":
        return <NHSContainer />;

      case "FamilyRecordsContainer":
        return <FamilyRecordsContainer />;

      case "TestInvestigationContainer":
        return <TestInvestigationContainer />;
      case "WalkinAppointmentContainer":
        return (
          <div>
            <WalkinAppointmentContainer />
          </div>
        );
      case "PreQuestionnaireContainer":
        return <PreQuestionnaireContainer />;

      case "ReportsContainer":
        return <ReportsContainer />;

      case "SpecialNotesContainer":
        return <SpecialNotesContainer />;

      case "PreEncountersContainer":
        return <PreEncountersContainer />;

      case "PrescriptionsContainer":
        return <PrescriptionsContainer />;

      case "SickNotesContainer":
        return <SickNotesContainer />;
      case "ReferralNotesContainer":
        return <ReferralNotesContainer />;
      case "PastOrdersContainer":
        return <PastOrdersContainer />;
      case "ScreenshotContainer":
        return <ScreenshotContainer />;
      // case "QuestionnaireReviewContainer":
      //   return (
      //     <QuestionnaireReviewTabContainer
      //       patientId={this.props.patientId}
      //       isFromPatient={true}
      //     />
      //   );

      default:
        return (
          <PatientDetailContainer
            patientDetail={patientDetail}
            _getPatientDetails={this._getPatientDetails}
            patientId={this.props.patientId}
            isLoadingDetail={isLoadingDetail}
          />
        );
    }
    // }
  }

  _handleNavigation() {
    let { navigateTo } = this.props.navigateTo;
    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.patientSearch);
    }
  }
  async _handleBack() {
    let {
      history: { goBack },
    } = this.props;
    if (typeof goBack === "function") {
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("q");
      store.dispatch({
        type: "IS_CLICKED_START_CALL",
        payload: false,
      });
      if (myParam && myParam === "from_appointments") {
        let { onRequestSetComponent } = this.props.dynamicTabAction;
        if (typeof onRequestSetComponent === "function") {
          await onRequestSetComponent("ApproveRejectAppointmentContainer");
        }
      }
      await goBack();
      store.dispatch({ type: "ON_CLICK_NAME_UPCOMING", payload: false });
    }
  }
  _handleAppointmentNavigation(data, fromPresciber) {
    let { onSetPatient } = this.props.onSetPatient;
    let { onSetPatientAppointment } = this.props.onSetPatientAppointment;
    let { navigateTo } = this.props.navigateTo;
    // let {patient_id} = data;
    let { setOfflineCallStatus } = this.props.offlineCallActions;
    if (typeof setOfflineCallStatus === "function") {
      setOfflineCallStatus("start");
    }
    if (typeof onSetPatient === "function") {
      onSetPatient({ id: fromPresciber });
    }
    store.dispatch({
      type: "IS_CLICKED_START_CALL",
      payload: true,
    });

    store.dispatch({
      type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
      payload: true,
    });
    store.dispatch({
      type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
      payload: null,
    });
    store.dispatch({ type: "SHOW_SUBMIT_FROM_NOTES", payload: false });

    if (typeof onSetPatientAppointment === "function") {
      onSetPatientAppointment(data);
    }
    // let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
    // if (typeof onSetDynamicHealthInput === "function") {
    //   onSetDynamicHealthInput(null);
    // }
    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.walkinAppointment);
    }
    let { onSetSidebarKey, onRequestSetComponent } =
      this.props.dynamicTabAction;
    let key = 90;
    let page = "WalkinAppointmentContainer";
    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];
        console.log("PAYLOAD", payload);

        onSetSidebarKey(payload);
      }
    }

    // if (typeof onRequestSetComponentID === "function") {
    //   onRequestSetComponentID(componentId);
    // }
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }
    this.setState({
      isLoading: false,
      isOpenReasonModal: false,
      startingConsultation: false,
    });
  }
  getPatientHealthAssessmentDetail = async () => {
    console.log(
      "called----------------------------------------------------------------"
    );
    let { patientId } = this.props;
    try {
      this.setState({ isLoadingHealthAssessment: true });
      let response = await getPatientHealthAssessmentDetailApi(patientId);
      let data = verifyObject(response, "data.data", []);
      // verifyObject(response, "data.data", []).length > 0 &&
      //   verifyObject(response, "data.data", []).map((item, index) => {
      //     return
      this.setState(
        {
          pulseObject: this.getObjectFromData(data, "pulse_per_minute"),
          heightObject: this.getObjectFromData(data, "height"),
          weightObject: this.getObjectFromData(data, "weight"),
          body_temperatureObject: this.getObjectFromData(
            data,
            "body_temperature"
          ),
          blood_oxygen_saturationObject: this.getObjectFromData(
            data,
            "blood_oxygen_saturation"
          ),
          systolic_bpObject: this.getObjectFromData(data, "systolic_bp"),
          diastolic_bpObject: this.getObjectFromData(data, "diastolic_bp"),
          bmiObject: this.getObjectFromData(data, "bmi"),
        },
        async () => {
          let {
            pulseObject,
            heightObject,
            weightObject,
            blood_oxygen_saturationObject,
            body_temperatureObject,
            bmiObject,
            systolic_bpObject,
            diastolic_bpObject,
          } = this.state;
          let { onSetDynamicHealthInput } = this.props.dynamicTabAction;
          if (typeof onSetDynamicHealthInput === "function") {
            let Inputs = {
              ...this.props.dynamicHealthInputs,
              pulseObject,
              heightObject,
              weightObject,
              blood_oxygen_saturationObject,
              body_temperatureObject,
              bmiObject,
              systolic_bpObject,
              diastolic_bpObject,
            };
            await onSetDynamicHealthInput(Inputs);
          }
        }
      );

      await this.setState({ isLoadingHealthAssessment: false });
    } catch (error) {
      // const {message} = getErrorObject(error);
      await this.setState({ isLoadingHealthAssessment: false });
      // errorToast({content: message});
    }
  };
  getObjectFromData = (data, value) => {
    let found;
    if (data && data.length > 0) {
      // eslint-disable-next-line array-callback-return
      data.find((item, index) => {
        // console.log("checked=========>", item.name, value, item.name === value);
        if (item.name === value) return (found = item);
      });
    }
    console.log("found--------------------", found);
    return found;
  };
  handleReasonModal = () => {
    return this.setState({ isOpenReasonModal: false }, () => {
      let { patientDetail } = this.props;
      let condition =
        this.state.reason !== "" &&
        this.state.reason !== undefined &&
        this.state.reason.trim().length !== 0;
      if (condition) {
        this._startAppointment(patientDetail);
      }
    });
  };

  _onChangeReason = (e) => {
    if (e) {
      console.log("reason----", e.target.value);
      this.setState(
        {
          reason: e.target.value,
        },
        () => {
          let condition =
            this.state.reason !== "" &&
            this.state.reason !== undefined &&
            this.state.reason.trim().length !== 0;
          if (condition) {
            this.setState({ enableReasonSave: false });
          } else {
            this.setState({ enableReasonSave: true });
          }
        }
      );
    }
  };

  _startAppointment = async (item) => {
    store.dispatch({
      type: "IS_CONSULTATION_API_STARTED",
      payload: true,
    });
    try {
      this.setState({ isLoading: true, startingConsultation: true });
      let response = await startAppointment({
        patient_id: item.user.id,
        // start_time: moment().format(), //* * Commenting start time as req. from BE
      });
      let { onSetDynamicHealthInput } = this.props.dynamicTabAction;
      if (typeof onSetDynamicHealthInput === "function") {
        onSetDynamicHealthInput(null);
      }
      this.setState(
        { isLoading: false, startingConsultation: false },
        async () => {
          await this.getPatientHealthAssessmentDetail();
          this._handleAppointmentNavigation(response.data.data, item.user.id);
        }
      );

      console.log("response", response);
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({ isLoading: false, startingConsultation: false });
      errorToast({ content: message });
    }
  };
  renderStatus = (patientDetail) => {
    const status = verifyObject(patientDetail, "user.status");

    switch (status) {
      case "active":
        return "Verified";
      case "unverified":
        return "Unverified";
      case "rejected":
        return "Rejected";
      default:
        return "";
    }
  };
  verifyPatientFunction = async (patient_id) => {
    try {
      this.setState({ verifyingPatient: true });
      let verifyParam = { user: { status: "active" } };

      let callNoteParam = {
        patient_id,
        note: "Verification",
        note_type: "Verification",
        end_call: true,
        call_id: patient_id,
        call_type: "User",
        call_flag: 1,
      };
      const responsePatient = await verifyPatientAPI(verifyParam, patient_id);
      const responseNote = await addCallNotesAPI(callNoteParam);
      this._getPatientDetails(patient_id);

      successToast({
        content: verifyObject(
          responsePatient,
          "data.message",
          "Verified Succsess"
        ),
      });
      successToast({
        content: verifyObject(responseNote, "data.message", "Call notes saved"),
      });
      console.log("BOTH REPONSE---", responsePatient, responseNote);
      this.setState({ verifyingPatient: false, visible: false });
    } catch (error) {
      this.setState({ verifyingPatient: false });
      const { message } = getErrorObject(error);

      errorToast({ content: message });
    }
  };
  render() {
    let { patientDetail, userRole } = this.props;

    let sideBarArray = [
      {
        label: "Patient Details",
        path: appRoutesConst.patientDetail,
        icon: (
          // <img
          //   alt="Patient Details"
          //   title="Patient Details"
          //   src={patientdetails}
          // ></img>
          <PatientdetailsIcon width="12" fill="757575" />
        ),
        key: 1,
        page: "PatientDetailContainer",
        useDynamicComponent: true,
      },
      {
        label: "Summary",
        path: appRoutesConst.dynamicQuestionnaireReviewDetail,
        icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

        key: 6,
        page: "FamilyRecordsContainer",
        useDynamicComponent: true,
        hasSubmenu: true,
        submenu: [
          {
            label: "Medication History",
            path: appRoutesConst.patientDetail,
            icon: <MedicationHistorymenuIcon width="14" fill="#757575" />,
            key: 4,
            page: "AcuteMedicationsContainer",
            useDynamicComponent: true,
          },
          {
            label: "Problems / Conditions",
            path: appRoutesConst.patientDetail,
            icon: <MediacalhistorymenuIcon width="14" fill="#757575" />,
            key: 2,
            page: "MedicalHistoryContainer",
            useDynamicComponent: true,
          },
          {
            label: "Family Records",
            path: appRoutesConst.patientDetail,
            icon: <FamilyhistorymenuIcon width="14" fill="#757575" />,
            key: 6,
            page: "FamilyRecordsContainer",
            useDynamicComponent: true,
          },
          {
            label: "Allergies and Sensitivities",
            path: appRoutesConst.patientDetail,
            icon: <AllergicmenuIcon width="14" fill="#757575" />,
            page: "AllergiesContainer",
            key: 3,
            useDynamicComponent: true,
          },
          {
            label: "Test/Investigation",
            path: appRoutesConst.patientDetail,
            icon: <TestinvestigatemenuIcon width="14" fill="#757575" />,
            page: "TestInvestigationContainer",
            key: 7,
            useDynamicComponent: true,
          },
        ],
      },
      {
        label: "Health Assessments",
        path: appRoutesConst.patientConsultationDetail,
        icon: <HealthassessmentIcon width="14" fill="#757575" />,
        page: "HealthAssessmentContainer",
        key: 112,
        useDynamicComponent: true,
      },
      {
        label: "Consultations",
        path: appRoutesConst.walkinAppointment,
        icon: <HistorymenuIcon width="12px" fill="#757575" />,
        key: 17,
        page: "WalkinAppointmentContainer",
        component_id: "patient_detail",
        useDynamicComponent: true,
        hasSubmenu: true,
        submenu: [
          {
            label: "Consultation Detail",
            component_id: "history",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="History" title="History" src={history}></img>,
            key: 11,
            page: "WalkinAppointmentContainer",
            useDynamicComponent: true,
          },
        ],
      },
      // {
      //   label: "Previous Consultation",
      //   path: appRoutesConst.patientDetail,
      //   icon: <PreviousconsultIcon width="14" fill="#757575" />,
      //   key: 17,
      //   page: "PatientDetailContainer",
      //   useDynamicComponent: true,
      //   hasSubmenu: true,
      //   submenu: [
      //     {
      //       label: "Pre. Encounters",
      //       path: appRoutesConst.patientDetail,
      //       icon: <PreviousencounterIcon width="14" fill="#757575" />,
      //       page: "PreEncountersContainer",
      //       key: 11,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Prescriptions",
      //       path: appRoutesConst.patientDetail,
      //       icon: <PrescriptionsIcon width="14" fill="#757575" />,
      //       page: "PrescriptionsContainer",
      //       key: 12,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Sick Notes",
      //       path: appRoutesConst.patientDetail,
      //       icon: <SicknotemenuIcon width="14" fill="#757575" />,
      //       page: "SickNotesContainer",
      //       key: 13,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Referral Notes",
      //       path: appRoutesConst.patientDetail,
      //       icon: <ReferralcodemenuIcon width="14" fill="#757575" />,
      //       page: "ReferralNotesContainer",
      //       key: 14,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Screenshot",
      //       path: appRoutesConst.patientConsultationDetail,
      //       icon: <ScreenshotmenuIcon width="14" fill="#757575" />,
      //       page: "ScreenshotContainer",
      //       key: 5,
      //       useDynamicComponent: true,
      //     },
      //   ],
      // },

      // {
      //   label: "Pre-Screening Questionnaire",
      //   path: appRoutesConst.patientDetail,
      //   icon: <QuestionnairemenuIcon width="14" fill="#757575" />,
      //   page: "PreQuestionnaireContainer",
      //   key: 8,
      //   useDynamicComponent: true,
      // },
      // {
      //   label: "Reports",
      //   path: appRoutesConst.patientDetail,
      //   icon: <ReportmenuIcon width="14" fill="#757575" />,
      //   page: "ReportsContainer",
      //   key: 9,
      //   useDynamicComponent: true,
      // },
      {
        label: (
          <span>
            Special Notes
            <span className="sidebar-notification-badge">
              {patientDetail && patientDetail.special_notes_count}
            </span>
          </span>
        ),
        path: appRoutesConst.patientDetail,
        icon: <SpecialnotemenuIcon width="14" fill="#757575" />,
        page: "SpecialNotesContainer",
        key: 10,
        useDynamicComponent: true,
      },

      // {
      //   label: "Past Orders",
      //   path: appRoutesConst.patientDetail,
      //   icon: <PastordermenuIcon width="14" fill="#757575" />,
      //   page: "PastOrdersContainer",
      //   key: 15,
      //   useDynamicComponent: true,
      // },
      // {
      //   label: "Questionnaire",
      //   path: appRoutesConst.patientDetail,
      //   icon: <img alt="Questionnaire" src={pastorders}></img>,
      //   page: "QuestionnaireReviewContainer",
      //   key: 16,
      //   useDynamicComponent: true,
      // },
    ];

    if (userRole === ROLE_HCSW || userRole === ROLE_NURSE) {
      sideBarArray = [
        {
          label: "Patient Details",
          path: appRoutesConst.patientDetail,
          icon: <PatientdetailsIcon width="12px" fill="#757575" />,
          key: 1,
          page: "PatientDetailContainer",
          useDynamicComponent: true,
        },
        {
          label: "Summary",
          path: appRoutesConst.dynamicQuestionnaireReviewDetail,
          icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

          key: 6,
          page: "FamilyRecordsContainer",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Problems / Conditions",
              path: appRoutesConst.patientDetail,
              icon: <MediacalhistorymenuIcon width="12px" fill="#757575" />,
              key: 2,
              page: "MedicalHistoryContainer",
              useDynamicComponent: true,
            },
            {
              label: "Family Records",
              path: appRoutesConst.patientDetail,
              icon: <FamilyhistorymenuIcon width="12px" fill="#757575" />,
              key: 6,
              page: "FamilyRecordsContainer",
              useDynamicComponent: true,
            },
            {
              label: "Allergies and Sensitivities",
              path: appRoutesConst.patientDetail,
              icon: <AllergicmenuIcon width="12px" fill="#757575" />,
              page: "AllergiesContainer",
              key: 3,
              useDynamicComponent: true,
            },
            {
              label: "Test/Investigation",
              path: appRoutesConst.patientDetail,
              icon: <TestinvestigatemenuIcon width="12px" fill="#757575" />,
              page: "TestInvestigationContainer",
              key: 7,
              useDynamicComponent: true,
            },
          ],
        },

        {
          label: "Current Acute Medications",
          path: appRoutesConst.patientDetail,
          icon: <MedicationHistorymenuIcon width="12px" fill="#757575" />,
          key: 4,
          page: "AcuteMedicationsContainer",
          useDynamicComponent: true,
        },
        {
          label: "NHS Repeat Medication",
          path: appRoutesConst.patientDetail,
          icon: <NhsmenuIcon width="12px" fill="#757575" />,
          page: "NHSContainer",
          useDynamicComponent: true,
        },

        // {
        //   label: "Pre-Screening Questionnaire",
        //   path: appRoutesConst.patientDetail,
        //   icon: null,
        //   page: "PreQuestionnaireContainer",
        //   key: 8,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Reports",
        //   path: appRoutesConst.patientDetail,
        //   icon: null,
        //   page: "ReportsContainer",
        //   key: 9,
        //   useDynamicComponent: true,
        // },
        {
          label: (
            <span>
              Special Notes
              <span className="sidebar-notification-badge">
                {patientDetail && patientDetail.special_notes_count}
              </span>
            </span>
          ),
          path: appRoutesConst.patientDetail,
          icon: <SpecialnotemenuIcon width="12px" fill="#757575" />,
          page: "SpecialNotesContainer",
          key: 10,
          useDynamicComponent: true,
        },
        {
          label: "Pre. Encounters",
          path: appRoutesConst.patientDetail,
          icon: <PreviousencounterIcon width="12px" fill="#757575" />,
          page: "PreEncountersContainer",
          key: 11,
          useDynamicComponent: true,
        },
        // {
        //   label: "Prescriptions",
        //   path: appRoutesConst.patientDetail,
        //   icon: null,
        //   page: "PrescriptionsContainer",
        //   key: 12,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Sick Notes",
        //   path: appRoutesConst.patientDetail,
        //   icon: null,
        //   page: "SickNotesContainer",
        //   key: 13,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Referral Notes",
        //   path: appRoutesConst.patientDetail,
        //   icon: null,
        //   page: "ReferralNotesContainer",
        //   key: 14,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Past Orders",
        //   path: appRoutesConst.patientDetail,
        //   icon: <PastordermenuIcon width="12px" fill="#757575" />,
        //   page: "PastOrdersContainer",
        //   key: 15,
        //   useDynamicComponent: true,
        // },
      ];
    }

    return (
      <Layout sideBarArray={sideBarArray}>
        <Prompt
          message={(location, action) => {
            // if (action === "PUSH") {
            //   this._clearComponents();
            //   return true;
            // }
            if (action === "PUSH") {
              let { onRequestSetComponent, onSetSidebarKey } =
                this.props.dynamicTabAction;
              if (typeof onRequestSetComponent === "function") {
                onRequestSetComponent(null);
                onSetSidebarKey(null);
              }
              return true;
            }
            if (action === "POP") {
              // this._clearComponents();
              // this._handleBack();
              let { onRequestSetComponent, onSetSidebarKey } =
                this.props.dynamicTabAction;
              if (typeof onRequestSetComponent === "function") {
                onRequestSetComponent("UpcomingAppointmentContainer");
              }

              if (typeof onSetSidebarKey === "function") {
                onSetSidebarKey([`APPT1`]);
              }
              return true;
            }
            return true;
          }}
        />
        <div className="rightmainsection patientdetailpage">
          <div className="container-fluid padding-0">
            <div className="row margin-0">
              <div className="container-fluid selection_container padding-left-10 padding-right-10">
                <div className="patient_detail_header padding-1-b">
                  <div
                    className="patient_detail_right relative"
                    onCopy={disableCopy}
                  >
                    <div className="patient_backarrow push-10-r in-block">
                      <img
                        className="cursor-pointer"
                        onClick={this._handleBack}
                        src={topbarback}
                        alt="Go Back"
                        title="Go Back"
                      ></img>
                    </div>
                    <div className="consultant_profile push-5-r in-block">
                      {patientDetail && patientDetail.image !== "" ? (
                        <img
                          src={patientDetail.image}
                          alt=""
                          className="width-30px"
                        ></img>
                      ) : (
                        <img
                          src={
                            require("../../assets/images/common/doctor_profile.svg")
                              .default
                          }
                          alt="Patient"
                          className="border-radius-50"
                        ></img>
                      )}
                    </div>
                    <div className="in-block padding-right-10">
                      <div className="push-5-l d-flex flex-wrap align-items-center gapx-1">
                        <div className="font-12 weight-400 text-capitalize in-block text-black d-flex flex-wrap align-items-center gapx-1">
                          {" "}
                          {verifyObject(
                            patientDetail,
                            "is_child_account",
                            false
                          ) ? (
                            <Tooltip placement="top" title="Child Account">
                              <span className="mr-1 custom-bluelabel font-10 in-block text-capitalize">
                                C
                              </span>
                            </Tooltip>
                          ) : null}
                          <span>
                            {verifyObject(patientDetail, "first_name", "-- ")}
                          </span>
                          <span>
                            {verifyObject(patientDetail, "last_name", "-- ")}
                          </span>
                          {verifyObject(patientDetail, "user.status") ? (
                            <span
                              className={`custom-${
                                verifyObject(patientDetail, "user.status") ===
                                "unverified"
                                  ? "danger"
                                  : "bluelabel"
                              } font-10 in-block text-capitalize`}
                            >
                              {this.renderStatus(patientDetail)}
                            </span>
                          ) : null}
                          <div
                            className="in-block"
                            onClick={() => {
                              this.setState({ visible: true });
                            }}
                          >
                            <a className="text-black weight-400 text-capitalize photo_id font-12 cursor-pointer hover-blue">
                              <img
                                alt="Photo ID"
                                title="Photo ID"
                                src={photo_id}
                              ></img>{" "}
                              Photo ID
                            </a>
                          </div>
                        </div>
                        <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                          |
                        </div>

                        {verifyObject(patientDetail, "age", null) !== null ? (
                          <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                            {patientDetail.age} Yr
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap padding-right-10">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray push-5-r">
                        <span className="text-light-black">Gender:</span>{" "}
                        {verifyObject(patientDetail, "gender", null) !== null
                          ? `${patientDetail.gender}`
                          : ""}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap padding-right-10">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray push-5-r">
                        <span className="text-light-black">DOB:</span>{" "}
                        {verifyObject(patientDetail, "date_of_birth", null) !==
                        null
                          ? `${moment(patientDetail.date_of_birth).format(
                              DATE_FORMAT
                            )}`
                          : ""}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap flex-grow-1">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray push-5-r">
                        <span className="text-light-black">Corporate:</span>{" "}
                        {verifyObject(
                          patientDetail,
                          "corporate_name",
                          undefined
                        ) !== undefined
                          ? `${patientDetail.corporate_name}`
                          : ""}
                      </div>
                    </div>
                    <div className="walkin_collapse in-block float-right">
                      <CollapsiableSubHeader patientDetail={patientDetail} />
                      <ImageSliderModal
                        verifyPatientFunction={this.verifyPatientFunction}
                        visible={this.state.visible}
                        notFromOngoingConsultation={true}
                        patientDetail={patientDetail}
                        handleCancel={() => this.setState({ visible: false })}
                        verifyingPatient={this.state.verifyingPatient}

                        // checkIdentity={this._SaveComponentStateToRedux}
                        // isDocumentViewed={isDocumentViewed}
                      />
                    </div>
                  </div>

                  {!this.props.appointmentObj && (
                    <div className="patient_detail_left flex-grow-1">
                      {(this.props.userRole === ROLE_CLINICAL_PHARMACIST ||
                        this.props.userRole ===
                          ROLE_SUPERINTENDENT_PHARMACIST ||
                        this.props.userRole === ROLE_DOCTOR) &&
                        window.location.pathname !==
                          "/doctor/walkin-appointment" &&
                        !this.props.on_click_name_upcoming && (
                          <Fragment>
                            <div className="in-block push-5-l">
                              <button
                                className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-100px p-0 height-30 new_btnwidth"
                                onClick={
                                  () => this._startAppointment(patientDetail)
                                  // this.setState({isOpenReasonModal: true})
                                }
                                disabled={
                                  this.state.isLoading || patientDetail === null
                                }
                              >
                                Start Consultation
                              </button>
                            </div>
                          </Fragment>
                        )}

                      {!this.props.on_click_name_upcoming && (
                        <PreConsultNotification
                          patient_id={this.props.patientId}
                          corporate_id={this.props.corporate_id}
                          patientDetail={this.props.patientDetail}
                          patient_age={this.props.patient_age}
                          onSetPatient={this.props.onSetPatient}
                          onSetPatientAppointment={
                            this.props.onSetPatientAppointment
                          }
                          dynamicTabAction={this.props.dynamicTabAction}
                          navigateTo={this.props.navigateTo}
                          disabledPreConsultButton={
                            this.state.isLoading || patientDetail === null
                          }
                        />
                      )}
                    </div>
                  )}
                  <Button
                    type="primary"
                    className="font-14 weight-500 text-dark-blue text-center"
                    onClick={() => {
                      this.setState({
                        showBookingModal: true,
                      });
                    }}
                  >
                    Book Appointment
                  </Button>
                </div>
                {this.state.startingConsultation && (
                  <CommonLoader text="Starting Consultation..." />
                )}

                {/* / common patient header / */}
                {!this.state.startingConsultation &&
                  //   !this.state.isLoading &&
                  //   patientDetail !== null &&
                  this._renderDynamicComponent()}
              </div>
            </div>
          </div>
        </div>
        {/* {this.state.isOpenReasonModal && (
          <Modal
            visible={this.state.isOpenReasonModal}
            onCancel={() => this.setState({isOpenReasonModal: false})}
            onOk={this.handleReasonModal}
            title="Presenting Complaint"
            okButtonProps={{
              disabled: this.state.enableReasonSave,
              loading: this.state.isLoading,
            }}
          >
            <div className="peresenting_com">
              <input
                type="text"
                value={this.state.reason}
                className="d-block font-10 placeholder-10 form-control margin-0"
                placeholder="Please write in your reason"
                onChange={this._onChangeReason}
                autoFocus
              />
            </div>
          </Modal>
        )} */}
        {/* reason modal removed as changed to upfront input box */}
        {this.state.showBookingModal && (
          <BookAppointmentForPatientContainer
            showBookingModal={this.state.showBookingModal}
            doctor_id={this.props?.userObj?.id}
            patient_id={verifyObject(patientDetail, "user.id", "--")}
            forWholebooking={true}
            bookingRecord={this.state.bookingRecord}
            closeBookingModal={(isCancel) => {
              this.setState({
                isOutFromFullscreen: false,
                resourceID: null,
                showBookingModal: false,
                bookingRecord: null,
              });
            }}
            fromConsultation={true}
            passedPatientName={`${verifyObject(
              patientDetail,
              "first_name",
              "--"
            )} ${verifyObject(patientDetail, "last_name", "--")}`}
          />
        )}
      </Layout>
    );
  }
}

const mapStateProps = (state) => {
  return {
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    componentName: verifyObject(
      state.localStore,
      "componentName",
      "PatientDetailContainer"
    ),
    patientId: verifyObject(state.localStore, "patientId", null),
    patient_age: verifyObject(state.localStore, "patient_detail_obj.age", null),

    corporate_id: verifyObject(state.localStore, "user.corporate_id", null),

    dynamicHealthInputs: verifyObject(
      state,
      "dynamicTab.dynamicHealthInputs",
      null
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    on_click_name_upcoming: verifyObject(
      state.localStore,
      "on_click_name_upcoming",
      null
    ),
    userRole: verifyObject(state.localStore, "user.role", null),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    isLoadingDetail: verifyObject(state.patientDetails, "isLoading", false),
    is_clicked_view: verifyObject(state, "localStore.is_clicked_view", false),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onSetSidebarKey,
        onSetDynamicHealthInput,
        onSetDynamicInput,
      },
      dispatch
    ),
    patientDetailActions: bindActionCreators(patientDetailActions, dispatch),
    navigateTo: bindActionCreators({ navigateTo }, dispatch),
    onSetPatientAppointment: bindActionCreators(
      { onSetPatientAppointment },
      dispatch
    ),
    onSetPatient: bindActionCreators({ onSetPatient }, dispatch),
    offlineCallActions: bindActionCreators({ setOfflineCallStatus }, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(PatientDetailPage);

export default withRouter(ConnectedComponent);

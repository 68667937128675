/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react";
// import {errorToast, getErrorObject} from "utilities/utils";
// import {fetchSubmittedPreConsultations} from "services/preConsultConditions";
import {isEmpty} from "lodash";
import {isArray} from "highcharts";

export default function PreConsultSubmittedQuestionnaire({
  handlePreconsultNoteChange,
  gender,
  disabled,
  dynamicInputs,
  submitedQuestionnairesArray,
  // symptompSnomedCode,
}) {
  const [submittedQuestionnaires, setSubmitedQuestionnaires] = useState([]);
  useEffect(() => {
    console.log("submitedQuestionnairesArray", submitedQuestionnairesArray);
    if (
      submitedQuestionnairesArray &&
      isArray(submitedQuestionnairesArray) &&
      submitedQuestionnairesArray.length > 0
    ) {
      setSubmitedQuestionnaires(submitedQuestionnairesArray);
    }
  }, [submitedQuestionnairesArray]);

  const handleChange = (value) => {
    let questionAnswerString = "Questionnaire Answers \n";
    const regex = /(<([^>]+)>)/gi;
    const urlParams = new URLSearchParams(window.location.search);
    const consentShared = urlParams.get("consentShared");
    submittedQuestionnaires?.forEach((d) => {
      d.detail_answers.forEach((j) => {
        if (!isEmpty(j.actual_answer)) {
          questionAnswerString +=
            " " +
            j.question_title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
            "-" +
            " " +
            j.actual_answer +
            "\n";
        }
        //  else {
        //   questionAnswerString +=
        //     " " +
        //     j.question_title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
        //     "-" +
        //     " " +
        //     "Not Answered" +
        //     "\n";
        // }

        j?.children?.forEach((k) => {
          if (!isEmpty(k.actual_answer)) {
            questionAnswerString +=
              "   " +
              k.question_title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
              "-" +
              "   " +
              k.actual_answer +
              "\n";
          }
          // else {
          //   questionAnswerString +=
          //     "   " +
          //     k.question_title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
          //     "-" +
          //     "   " +
          //     "Not Answered" +
          //     "\n";
          // }

          k?.children?.forEach((l) => {
            if (!isEmpty(l.actual_answer)) {
              questionAnswerString +=
                "       " +
                l.question_title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
                "-" +
                "       " +
                l.actual_answer +
                "\n";
            }
            //  else {
            //   questionAnswerString +=
            //     "      " +
            //     l.question_title?.replace(regex, "")?.replaceAll("&nbsp;", "") +
            //     "-" +
            //     "     " +
            //     "Not Answered" +
            //     "\n";
            // }
          });
        });
      });
    });
    let preConsultNoteParams = ``;
    if (consentShared === "true") {
      preConsultNoteParams = `\n\n\n` + "Safety netting provided to patient";
    }
    console.log("handlePreconsultNoteChange", questionAnswerString);
    handlePreconsultNoteChange(
      `${questionAnswerString}${preConsultNoteParams}`
    );
    resizeTextArea();
  };

  useEffect(() => {
    if (submittedQuestionnaires.length > 0) {
      handleChange();
    }
  }, [submittedQuestionnaires]);

  const resizeTextArea = () => {
    let textarea = document.querySelector(`#pre_consult_note`);

    //pre_consult_lbl
    if (textarea) {
      console.log("textarea", textarea);
      textarea.addEventListener("change", autoResize, false);
      textarea.addEventListener("input", autoResize, false);
    }
  };

  const autoResize = (e) => {
    console.log("e.target", e);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };
  /**
   * Handles the form submission for the questionnaire.
   */
  return <div className="consultant_review push-10-b questionary_select"></div>;
}

import React from 'react';

const IconPhone = (props) => {
    const { className } = props
    return (
        <svg className={className} xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="currentColor">
            <path d="M2.42859 2.98417L2.99725 2.49141C3.65256 1.8362 4.71406 1.8362 5.36937 2.49141C5.38562 2.50765 6.38754 3.81266 6.38754 3.81266C7.00494 4.46246 7.00494 5.48589 6.38754 6.13027L5.75931 6.92086C6.55001 8.71322 7.7794 9.94783 9.51245 10.6843L10.3032 10.0507C10.9476 9.42799 11.9766 9.42799 12.6211 10.0507C12.6211 10.0507 13.9263 11.0525 13.9426 11.0687C14.5979 11.7239 14.5979 12.7853 13.9697 13.4134L13.4281 14.0361C12.8053 14.6589 11.9658 15 11.056 15C6.91829 15 1.4375 9.51463 1.4375 5.38301C1.4375 4.4787 1.7787 3.63397 2.42859 2.98959V2.98417ZM11.056 13.9116C11.6734 13.9116 12.242 13.6842 12.632 13.2889L13.1735 12.6661C13.3956 12.4441 13.4064 12.0813 13.1952 11.8485C13.1952 11.8485 11.9008 10.8521 11.8846 10.8359C11.6625 10.6139 11.2726 10.6139 11.0451 10.8359C11.0289 10.8521 9.9403 11.7239 9.9403 11.7239C9.78866 11.8431 9.58828 11.8756 9.40956 11.8052C7.16742 10.9496 5.55892 9.34677 4.63282 7.03457C4.56242 6.85588 4.58949 6.65011 4.71406 6.49308C4.71406 6.49308 5.586 5.39925 5.59683 5.38842C5.82971 5.15558 5.82971 4.78194 5.59683 4.5491C5.58059 4.53285 4.58408 3.23868 4.58408 3.23868C4.3512 3.02749 3.98834 3.03291 3.73921 3.282L3.17056 3.77476C2.75354 4.19171 2.52066 4.76028 2.52066 5.37759C2.52066 9.14641 7.81189 13.9116 11.056 13.9116Z" />
        </svg>
    )
}

export { IconPhone }

/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Result, Button } from "antd";
import ALLDAYDR from "../assets/images/common/alldayDr.svg";
import { withRouter } from "react-router-dom";
import { appRoutesConst } from "./navigation";
function ErrorUI(props) {
  return (
    <div>
      <Result
        // status="error"
        icon={<img src={ALLDAYDR} alt="" />}
        title="Something Went Wrong here"
        // subTitle="Please check and modify the following information before resubmitting."
        extra={[
          <Button
            onClick={() => props.history.push(appRoutesConst.dashboard)}
            type="primary"
            key="console"
          >
            Go Home
          </Button>,
          <Button onClick={() => window.location.reload()} key="buy">
            Refresh
          </Button>,
        ]}
      >
        {/* <div className="desc">
          <Paragraph>
            <Text
              strong
              style={{
                fontSize: 16,
              }}
            >
              The content you submitted has the following error:
            </Text>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
            account has been frozen. <a>Thaw immediately &gt;</a>
          </Paragraph>
          <Paragraph>
            <CloseCircleOutlined className="site-result-demo-error-icon" /> Your
            account is not yet eligible to apply. <a>Apply Unlock &gt;</a>
          </Paragraph>
        </div> */}
      </Result>
    </div>
  );
}
export default withRouter(ErrorUI);

import { get, Irequest, post, deletee } from './index'

export const getReportsApi = (params) => {
	const request: Irequest = {
		subUrl: `/admin/documents.json`,
		params,
	}
	return get(request)
}

export const deleteReportsApi = (params) => {
	let { id } = params
	const request: Irequest = {
		subUrl: `/admin/documents/${id}.json`,
	}
	return deletee(request)
}

export const getDetailReportsApi = (params) => {
	const request: Irequest = {
		subUrl: `/admin/documents/list_corona_virus_report.json`,
		params,
	}
	
	return get(request)
}

export const getPatientDetailsApi = (params) => {
	let { id } = params
	const request: Irequest = {
		subUrl: `/patients/${id}.json`,
		params,
	}
	
	return get(request)
}

export const getMedicalHistoriesApi = (params) => {
	const request: Irequest = {
		subUrl: `/medical_histories.json`,
		params,
	}
	return get(request)
}

export const addMedicalHistoriesApi = (data) => {
	const request: Irequest = {
		subUrl: `/medical_histories.json`,
		data,
	}
	return post(request)
}

export const getUserAllergiesApi = (params) => {
	const request: Irequest = {
		subUrl: `/user_allergies.json`,
		params,
	}
	return get(request)
}

export const addUserAllergiesApi = (data) => {
	const request: Irequest = {
		subUrl: `/user_allergies.json`,
		data,
	}
	return post(request)
}

export const getAcuteMedicationsApi = (params) => {
	const request: Irequest = {
		subUrl: `/medications/acute_medications.json`,
		params,
	}
	return get(request)
}

export const addAcuteMedicationsApi = (data) => {
	const request: Irequest = {
		subUrl: `/medications.json`,
		data,
	}
	return post(request)
}

export const getRepeatMedicationsApi = (params) => {
	const request: Irequest = {
		subUrl: `/repeat_medications.json`,
		params,
	}
	return get(request)
}

export const getFamilyRecordsApi = (params) => {
	const request: Irequest = {
		subUrl: `/family_histories.json`,
		params,
	}
	return get(request)
}

export const addFamilyRecordsApi = (data) => {
	const request: Irequest = {
		subUrl: `/family_histories.json`,
		data,
	}
	return post(request)
}

export const getTestsApi = (params) => {
	const request: Irequest = {
		subUrl: `/investigations.json`,
		params,
	}
	return get(request)
}

export const addTestsApi = (data) => {
	const request: Irequest = {
		subUrl: `/investigations.json`,
		data,
	}
	return post(request)
}

export const getPreScreeningQuestionnaireApi = (params) => {
	let { id } = params
	const request: Irequest = {
		subUrl: `/new_questionnaire/questions/${id}.json`,
		params,
	}
	return get(request)
}

export const getSpecialNotesApi = (params) => {
	const request: Irequest = {
		subUrl: `/special_notes.json`,
		params,
	}
	return get(request)
}

export const addSpecialNotesApi = (data) => {
	const request: Irequest = {
		subUrl: `/special_notes.json`,
		data,
	}
	return post(request)
}

export const getPrevEncountersApi = (params) => {
	const request: Irequest = {
		subUrl: `/consultations.json`,
		params,
	}
	return get(request)
}

import {get, Irequest} from "./index";

export type GetSurgeriesParams = {
  search?: string,
  page?: number,
};

export const fetchSymptomsApi = (params: GetSurgeriesParams) => {
  const request: Irequest = {
    subUrl: `/doctor/symptom_checkers/fetch_symptoms`,
    params,
  };
  return get(request);
};
export const fetchDiagnosisApi = (params: GetSurgeriesParams) => {
  const request: Irequest = {
    subUrl: `/doctor/symptom_checkers/fetch_diagnoses`,
    params,
  };
  return get(request);
};
export const fetchWhyKnowledgeAPI = (params: GetSurgeriesParams) => {
  const request: Irequest = {
    subUrl: `/doctor/symptom_checkers/fetch_reason_for_diagnosis`,
    params,
  };
  return get(request);
};
export const fetchRegionsAPI = (params: GetSurgeriesParams) => {
  const request: Irequest = {
    subUrl: `/doctor/symptom_checkers/fetch_regions`,
  };
  return get(request);
};

export const fetchLinksFromKnowledgeURL = (params: GetSurgeriesParams) => {
  const request: Irequest = {
    subUrl: `/doctor/symptom_checkers/fetch_links`,
    params,
  };
  return get(request);
};
import React from "react";
import { Checkbox } from "antd";
import PropTypes from "prop-types";

export default function SingleCheckBox({
  onChange,
  name,
  label,
  checked,
  disabled,
  classNames,
}) {
  return (
    <div>
      <Checkbox
        name={name}
        className={classNames}
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      >
        {label}
      </Checkbox>
    </div>
  );
}

SingleCheckBox.propTypes = {
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

import React, { Component } from "react";
import ErrorUI from "./ErrorUI";
import store from "./store";
export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState(
      {
        error: error,
        errorInfo: errorInfo,
      },
      () => {
        store.dispatch({
          type: "IN_ERROR_BOUNDRY",
          payload: true,
        });
      }
    );
    console.log("ERROR BOUNDRY CHANGE", error);
    // window.location.reload()
  }

  render() {
    if (this.state.errorInfo) {
      return <ErrorUI />;
    } else {
      return this.props.children;
    }
  }
}

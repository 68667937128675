import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as SpecialNotesActions} from "../../../reducers/specialNotes";
import {
  verifyObject,
  // modifiedDataObjectToArray,
  successToast,
  getErrorObject,
  errorToast,
  modifiedDataObjectToArrayByIDs,
} from "../../../utilities/utils";
import {isArray, debounce} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import {Pagination} from "../../../components/common";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {addSpecialNotesApi} from "../../../services/specialNotes";
import {Collapse} from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";
import {DATE_FORMAT} from "constants/common";
let {Panel} = Collapse;

class SpecialNotesContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      activeKey: ["1"],
      message: "",
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleSearch",
      "_handleCancel",
      "_handleAddSpecialNote",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    //Test
  }

  async _handleSearch(value) {
    let {appointmentObj, patientDetail, SpecialNotesActions} = this.props;
    let {onSearch} = SpecialNotesActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      let patient_id = verifyObject(appointmentObj, "patient.id")
        ? verifyObject(appointmentObj, "patient.id")
        : verifyObject(appointmentObj, "patient_id")
        ? verifyObject(appointmentObj, "patient_id")
        : verifyObject(patientDetail, "user.id");
      onSearch({search: value, user_id: patient_id, page: 1});
    }
  }

  componentDidMount() {
    this._onRequestList();
  }

  _onRequestList() {
    let {SpecialNotesActions, appointmentObj, patientDetail} = this.props;
    let {onRequest} = SpecialNotesActions;
    let patient_id = verifyObject(appointmentObj, "patient.id")
      ? verifyObject(appointmentObj, "patient.id")
      : verifyObject(appointmentObj, "patient_id")
      ? verifyObject(appointmentObj, "patient_id")
      : verifyObject(patientDetail, "user.id");
    if (typeof onRequest === "function") {
      onRequest({user_id: patient_id, page: 1});
    }
  }
  _onPageChange(data) {
    let {
      appointmentObj,
      patientDetail,
      SpecialNotesActions: {onPageChange},
      specialNotes: {search},
    } = this.props;
    let patient_id = verifyObject(appointmentObj, "patient.id")
      ? verifyObject(appointmentObj, "patient.id")
      : verifyObject(appointmentObj, "patient_id")
      ? verifyObject(appointmentObj, "patient_id")
      : verifyObject(patientDetail, "user.id");
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, user_id: patient_id, search: search});
    }
  }

  _handleCancel() {
    this.setState({visible: false, message: "", adding: false});
  }

  async _handleAddSpecialNote() {
    try {
      let {message} = this.state;
      let {appointmentObj, patientDetail} = this.props;
      let patient_id = verifyObject(appointmentObj, "patient.id")
        ? verifyObject(appointmentObj, "patient.id")
        : verifyObject(appointmentObj, "patient_id")
        ? verifyObject(appointmentObj, "patient_id")
        : verifyObject(patientDetail, "user.id");
      await this.setState({adding: true});
      let ReqObj = {
        message: message,
        user_id: patient_id,
      };
      if (message.trim().length !== 0) {
        let response = await addSpecialNotesApi({
          special_note: {...ReqObj},
        });
        successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
        this._handleCancel();
        this._onRequestList();
      } else {
        errorToast({
          content: "Enter valid Special note",
        });
        // this.setState({disa})
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({adding: false});
      errorToast({content: message});
    }
  }

  render() {
    let {specialNotes} = this.props;
    let {
      // data,
      isLoading,
      pagination: {total},
    } = specialNotes;
    let {visible, message, adding, activeKey} = this.state;
    let specialNotesArray = modifiedDataObjectToArrayByIDs(specialNotes);

    return (
      <Fragment>
        <div className="padding-55-t push-20-b splnotes_wrapper">
          <div className="form-row align-items-center">
            <div className="col-md-6">
              <h6 className="font-14 weight-500 text-light-black m-0">
                Special Notes {isLoading ? "" : `(${total})`}{" "}
              </h6>
            </div>
            {/* <div className="col-md-6 text-right">
                <div className="relative searchbar_dropdown medical_Search">
                  <Input allowClear={true} size="large" placeholder="Search" prefix={<SearchOutlined />}/>
                </div>
            </div> */}
          </div>

          <div className="form-row margin-bottom-10 push-10-t">
            <div className="col-md-12 text-right">
              <a
                onClick={() =>
                  this.setState({
                    visible: true,
                  })
                }
                className="font-13 weight-500 text-blue"
              >
                <PlusSquareOutlined
                  style={{
                    cursor: "pointer",
                    color: "#1445b7",
                    fontSize: "15px",
                  }}
                />{" "}
                Add{" "}
              </a>
              {/* <img
                alt=""
                src={plus_blue}
                width="14"
                height="14"
                onClick={() =>
                  this.setState({
                    visible: true,
                  })
                }
                style={{ cursor: "pointer" }}
              ></img> */}
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}

          <div className="prescription-array">
            {!isLoading &&
              specialNotesArray &&
              isArray(specialNotesArray) &&
              specialNotesArray.length === 0 && (
                <NoFoundMessage message="No special notes found." />
              )}
            <Collapse
              expandIconPosition="right"
              onChange={(key) => {
                this.setState({activeKey: key});
              }}
              activeKey={activeKey}
              className="pre_quitionare"
            >
              {!isLoading &&
                specialNotesArray &&
                isArray(specialNotesArray) &&
                specialNotesArray.map((k, index) => {
                  return (
                    <Panel
                      key={[index + 1]}
                      header={
                        <div>
                          <p className="font-12 weight-400 line-1-43 margin-bottom-0 text-fff padding-5">
                            <Highlighter
                              highlightClassName="search-highlighter"
                              searchWords={[this.state.search]}
                              autoEscape={true}
                              textToHighlight={
                                verifyObject(k, "by", "") !== ""
                                  ? `Reported by ${k.by}`
                                  : "-"
                              }
                            />
                            <Highlighter
                              highlightClassName="search-highlighter"
                              searchWords={[this.state.search]}
                              autoEscape={true}
                              textToHighlight={
                                verifyObject(k, "created_at", "") !== ""
                                  ? ` on ${moment(k.created_at).format(
                                      DATE_FORMAT
                                    )}`
                                  : "-"
                              }
                            />
                          </p>
                        </div>
                      }
                    >
                      <div className="">
                        <p className="font-12 weight-400 text-black line-1-43 text-capitalize margin-bottom-0">
                          <span>
                            <Highlighter
                              highlightClassName="search-highlighter"
                              searchWords={[this.state.search]}
                              autoEscape={true}
                              textToHighlight={
                                verifyObject(k, "message", "") !== ""
                                  ? `${k.message}`
                                  : "-"
                              }
                            />
                          </span>
                        </p>
                      </div>
                    </Panel>
                  );
                })}
            </Collapse>
          </div>

          <div className="col-md-12">
            {!isLoading && (
              <Pagination
                data={specialNotes}
                onPageChange={this._onPageChange}
              />
            )}
          </div>
        </div>
        <ModalPopUp
          title="Add Note"
          handleCancel={this._handleCancel}
          visible={visible}
          closable={false}
          handleOk={this._handleAddSpecialNote}
          okButtonProps={{
            disabled: message.trim().length === 0,
          }}
          okText={"Add"}
          cancelText="Cancel"
          loading={adding}
          footer={true}
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
        >
          <div className="form-group">
            {/* <label htmlFor="sel1">Message</label> */}
            <textarea
              className="form-control height-68 placeholder-10"
              name="start_time"
              value={message}
              placeholder="Write note here"
              onChange={(e) => this.setState({message: e.target.value})}
              autoFocus
            />
          </div>
        </ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    specialNotes: verifyObject(state, "specialNotes", []),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SpecialNotesActions: bindActionCreators(SpecialNotesActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SpecialNotesContainer);

export default ConnectedComponent;

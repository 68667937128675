// import { patch } from "fetch-mock";s
import { get, post, patch } from "./index";

export const getAllChatGroups = (params) => {
  const request = {
    subUrl: `/chat/message_channels.json`,
    params,
  };
  return get(request);
};

export const getAllMessagesOfUser = (params) => {
  const request = {
    subUrl: `/chat/messages.json`,
    params,
  };
  return get(request);
};

export const disableChatServiceForPatient = (params) => {
  const request = {
    subUrl: `/cs/message_channels/disable_service.json`,
    params,
  };
  return get(request);
};

export const sendFirstMessageAPI = (data) => {
  const request = {
    subUrl: `/chat/messages.json`,
    data,
  };
  return post(request);
};

export const initiateChat = (data) => {
  const request = {
    subUrl: `/chat/message_channels.json`,
    data,
  };
  return post(request);
};

export const endChat = (data) => {
  const request = {
    subUrl: `/chat/message_channels/${data.id}/archive_channel.json`,
    data,
  };
  return patch(request);
};
export const endChatCS = (data) => {
  const request = {
    subUrl: `/cs/message_channels/${data.id}/archive_channel.json`,
    data,
  };
  return patch(request);
};
export const sendFirstCSMessageAPI = (data) => {
  const request = {
    subUrl: `/cs/messages.json`,
    data,
  };
  return post(request);
};

export const getAllCSMessagesOfUser = (params) => {
  const request = {
    subUrl: `/cs/messages.json`,
    params,
  };
  return get(request);
};
export const initiateCSChat = (data) => {
  const request = {
    subUrl: `/cs/message_channels.json`,
    data,
  };
  return post(request);
};
//@flow
import axios from "axios";
import { loadState } from "../app/localStorage";
import store from "app/store";
import { errorToast } from "utilities/utils";

export const authTokenKey = "AUTH_TOKEN";
// const dstore = store.dis
export const responseHeaderKeys = {
  pagination: "x-pagination",
};

const apiBaseUrls = {
  prod: "https://api.alldaydr.com/api",
  dev: "https://dev-api.healthya.co.uk/api",
  // dev: "https://alldaydr.hereokuapp.com/api",
  // stage: 'http://alb-main-alldaydr-dev-248981017.eu-west-2.elb.amazonaws.com/api',
  stage: "https://api-staging.alldaydr.com/api",
};
// https://staging-api.alldaydr.com

const webSocketURL = {
  prod: "wss://api.alldaydr.com",
  dev: "wss://dev-api.healthya.co.uk",
  // stage: 'wss://alb-main-alldaydr-dev-248981017.eu-west-2.elb.amazonaws.com',
  stage: "wss://api-staging.alldaydr.com",
};

export const appConfig = {
  mockEnv: false,
};

const react_app_env = {
  prod: "prod",
  dev: "dev",
  stage: "stage",
};

let baseUrl = apiBaseUrls.dev;
let baseWebSocketURL = webSocketURL.dev;
if (process.env.REACT_APP_ENV === react_app_env.stage) {
  baseUrl = apiBaseUrls.stage;
  baseWebSocketURL = webSocketURL.stage;
} else if (process.env.REACT_APP_ENV === react_app_env.prod) {
  baseUrl = apiBaseUrls.prod;
  baseWebSocketURL = webSocketURL.prod;
}
export type Irequest = {
  subUrl: string,
  method?: string,
  data?: object,
  params?: object,
  headers?: object,
};
let axiosInstances = {};

export const getInstance = (pathname) => {
  if (
    axiosInstances[pathname] &&
    !axiosInstances[pathname].controller.signal.aborted
  ) {
    return axiosInstances[pathname].instance;
  } else {
    const customAxiosInstance = createInstance();
    axiosInstances[pathname] = customAxiosInstance;
    return axiosInstances[pathname].instance;
  }
};

const createInstance = () => {
  const controller = new AbortController();
  const customAxiosInstance = {
    controller,
    instance: axios.create({
      signal: controller.signal,
    }),
  };
  return customAxiosInstance;
};

export const get = (request) => {
  return commonFetch({ method: "get", ...request });
};

export const post = (request) => {
  return commonFetch({ method: "post", ...request });
};

export const patch = (request) => {
  return commonFetch({ method: "patch", ...request });
};

export const put = (request) => {
  return commonFetch({ method: "put", ...request });
};

export const deletee = (request) => {
  return commonFetch({ method: "delete", ...request });
};
export const get2 = (request) => {
  return commonFetch2({ method: "get", ...request });
};

export const AxiosInstance = axios;
export const abortSignal = AxiosInstance.CancelToken.source();
export const cancelPreviousPageRequests = (previousPath) => {
  if (
    axiosInstances &&
    axiosInstances[previousPath] &&
    axiosInstances[previousPath].controller
  ) {
    console.log(
      "🚀 ~ cancelPreviousPageRequests ~ axiosInstances:",
      axiosInstances
    );

    axiosInstances[previousPath].controller.abort();
  }
};
const commonFetch = (request) => {
  // let tabName = null;
  // const loadedState = store.getState();
  // if (
  //   loadedState &&
  //   loadedState.dynamicTab &&
  //   loadedState.dynamicTab.sidebar_key
  // ) {
  //   tabName = loadedState.dynamicTab.sidebar_key?.toString();
  // }
  const instance = getInstance(window.location.pathname);

  const {
    subUrl,
    method,
    data = {},
    params = {},
    headers = {},
    isOnlyURL,
    CSToken,
    customJWToken,
    cancelToken,
    secretToken,
  } = request;
  let url = getFullUrl(subUrl);
  let passedHeaders = headers;
  let secretHeaders = null;

  let commonHeaders = getCommonHeaders();
  if (customJWToken) {
    commonHeaders = getCommonHeaders(customJWToken, "ACCESS_TOKEN");
    console.log("CUSTOM JW token");
  } else if (CSToken) {
    commonHeaders = getCommonHeaders(CSToken);
  }
  if (secretToken) {
    secretHeaders = getSecretCommonHeaders(secretToken, "SECRET_TOKEN");
  }

  if (isOnlyURL) {
    url = subUrl;
    passedHeaders = {};
    commonHeaders = {};
  }

  // if (subUrl === "/appointments.json") {
  //   commonHeaders = getCommonHeaders(true);
  // }

  // var arrStr = encodeURIComponent(JSON.stringify(params))
  console.log("cancelToken", cancelToken);
  const axiosInstance = method !== "get" ? axios : instance;
  return axiosInstance({
    method,
    url,
    data,
    cancelToken: cancelToken ? cancelToken?.token : null,
    // timeout:2000,
    headers: { ...commonHeaders, ...passedHeaders, ...secretHeaders },
    params,
  })
    .then((response) => {
      if (isOnlyURL) {
        if (response) {
          return Promise.resolve(response);
        } else {
          console.log("RESSSSSS", response);
          return Promise.reject(response);
        }
      } else {
        if (handleResponseStatus(response)) {
          return Promise.resolve(response);
        } else {
          // handleUnauthorizedResponses(response);

          return Promise.reject(response);
        }
      }
    })
    .catch((error) => {
      console.log("Request canceled:", error);
      console.log("ERROR CODE MESSAGE", error);
      if (
        error &&
        error.data &&
        error.data.status &&
        (error.data.status === 950 ||
          error.data.status === 419 ||
          error.data.status === 955)
      ) {
        if (error.data.status === 422) {
          errorToast({
            content: "Your session has expired. Please sign in again.",
          });
          return null;
        }
        store.dispatch({
          type: "notes/ON_ADD_NOTE_MODAL_CLOSE",
        });
        // history.push("/");
        window.location.href = "/";
        localStorage.clear();
        store.dispatch({ type: "CLEAR_LOCAL_STATE" });
      }

      return Promise.reject(error ? error : "REQ_CANCELLED");
    });
};
const commonFetch2 = (request: Irequest) => {
  // axios.interceptors.request.use(
  //   (config) => {
  //     // perform a task before the request is sent

  //     return config;
  //   },
  //   (error) => {
  //     // handle the error

  //     return Promise.reject(error);
  //   }
  // );
  // axios.interceptors.response.use((response) => {
  //   // do something with the response data
  //   const { data } = response;
  //   if (data.status === 950 || data.status === 419) {
  //     localStorage.clear();
  //     // history.push("/");
  //     window.location.href ="/signin"
  //     // navigateToIndex();
  //     // store.dispatch(navigateToIndex());
  //     // this.props.history.push("/");
  //     // return false
  //     // return Promise.resolve(response);
  //   }
  //   return response;
  // });

  const { subUrl, method, data = {} } = request;

  // const url = getFullUrl(subUrl);
  // let commonHeaders = getCommonHeaders();

  // if (subUrl === "/appointments.json") {
  //   commonHeaders = getCommonHeaders(true);
  // }

  // var arrStr = encodeURIComponent(JSON.stringify(params))

  return axios({
    method,
    subUrl,
    data,
    // headers: { ...commonHeaders, ...headers },
    // params,
  }).then((response) => {
    if (handleResponseStatus(response)) {
      return Promise.resolve(response);
    } else {
      // handleUnauthorizedResponses(response);
      return Promise.reject(response);
    }
  });
};

// const handleUnauthorizedResponses = (response) => {
//   if (response.data.status === 950 || response.data.status === 419) {
//     localStorage.clear();
//     history.push("/signin");
//     // history.
//     // store.dispatch(navigateToIndex());
//     // navigateToindexFromToken();
//   }
// };
const handleResponseStatus = (response) => {
  if (response && response.data && response.data.success) {
    return true;
  }
  return false;
};

const getCommonHeaders = (CSToken, PassedKey) => {
  const state = loadState();
  let token = null;
  if (state && state.localStore && state.localStore.token) {
    token = state.localStore.token;
  }
  if (state && state.localStore && state.localStore.reset_pass_token) {
    token = state.localStore.reset_pass_token;
  }
  console.log("CSToken: ", CSToken);
  return {
    "Content-Type": "application/json",
    [PassedKey ? PassedKey : authTokenKey]: CSToken ? CSToken : token,
  };
};
const getSecretCommonHeaders = (Token, PassedKey) => {
  return {
    [PassedKey]: Token,
  };
};
const getFullUrl = (url) => {
  return `${baseUrl}${url}`;
};

export const getAnyTokenFromState = (state) => {
  const { localStore, session } = state;
  return {
    [authTokenKey]: localStore.token || session.token,
  };
};

export const getBaseUrl = () => {
  let baseUrl = apiBaseUrls.dev;
  if (process.env.REACT_APP_ENV === react_app_env.stage) {
    baseUrl = apiBaseUrls.stage;
  } else if (process.env.REACT_APP_ENV === react_app_env.prod) {
    baseUrl = apiBaseUrls.prod;
  }

  return baseUrl;
};
export { baseWebSocketURL };

import React from "react";
import {Modal, Button} from "antd";
import PropTypes from "prop-types";
export default function ModalPopUp({
  className,
  extraButtons,
  styleProps,
  cancelBtnClass,
  title,
  okBtnClass,
  loading,
  okText,
  cancelText,
  footer,
  visible,
  handleOk,
  handleCancel,
  okButtonProps,
  cancelButtonProps,
  children,
  buttonWrapperStyle,
  closable,
  closeIcon,
  width,
  maskClosable,
  fromInformGP,
  destroyOnClose,
  disabled,
}) {
  return (
    <div>
      <Modal
        title={title}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText={okText}
        cancelText={cancelText}
        footer={null}
        className={className ? className : ""}
        style={styleProps}
        closable={closable}
        closeIcon={closeIcon}
        width={width}
        zIndex={1001}
        maskClosable={maskClosable}
        cancelButtonProps={cancelButtonProps}
        destroyOnClose={destroyOnClose}
      >
        {children}
        {footer && (
          <div className="btn-container">
            <div style={buttonWrapperStyle} className="btn-item-wrapper">
              <Button
                className={`cancel_btn ${cancelBtnClass ? cancelBtnClass : ""}`}
                onClick={handleCancel}
                danger
                disabled={fromInformGP && !closable}
              >
                {cancelText}
              </Button>
              <Button
                className={`custom_ok_btn ${okBtnClass ? okBtnClass : ""}`}
                {...okButtonProps}
                loading={loading}
                onClick={handleOk}
                type="primary"
                disabled={loading || disabled}
              >
                {okText}
              </Button>
              {extraButtons && extraButtons}
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
}
ModalPopUp.defaultProps = {
  visible: false,
  okText: "Ok",
  cancelText: "Cancel",
  footer: null,
};

ModalPopUp.propTypes = {
  title: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  okButtonProps: PropTypes.object,
  cancelButtonProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

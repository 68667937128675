import React, {Fragment, useState} from "react";
import {Table, Space} from "antd";
import {
  // modifiedDataObjectToArray,
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
  modifiedDataObjectToArrayByIDs,
} from "../../utilities/utils";
import ModalPopUp from "../../components/common/ModalPopUp";
import {Spinner} from "../../components/common";
import {DatePicker, Dropdown, Menu} from "antd";
import moment from "moment";
import {updateDocumentApi} from "../../services/rightToWork";
import DragAndDropUpload from "../../components/common/controls/DragAndDropUpload";
import {InboxOutlined} from "@ant-design/icons";
import three_dot from "assets/images/common/three-dot.svg";
import {DATE_FORMAT} from "constants/common";

const docStats = {
  approved: {
    name: "Approved",
  },
  rejected: {
    name: "Rejected",
  },
  expired: {
    name: "Expired",
  },
  processing: {
    name: "Processing",
  },
  not_uploaded: {
    name: "Not Uploaded",
  },
};
export default function RightToWorkTable({dataSource, _onReuestList}) {
  // let dataObject = verifyObject(dataSource, "data", {});
  let loading = verifyObject(dataSource, "isLoading", false);
  let rightToworkArray = modifiedDataObjectToArrayByIDs(dataSource);
  const [modalOpen, setModalOpen] = useState(false);
  const [expiryDate, setExpiryDate] = useState(null);
  const [documentStatus, setDocumentStatus] = useState("");
  const [files, setFileList] = useState([]);
  const [Id, setId] = useState(null);
  const [IsLoading, setLoading] = useState(false);

  const _handleModal = (record) => {
    let {category_id, document_status} = record;
    // setExpiryDate(expiry_date);
    setId(category_id);
    setDocumentStatus(document_status);
    setModalOpen(true);
  };

  const _handleCancel = () => {
    setModalOpen(false);
    setExpiryDate(null);
    setDocumentStatus("");
    setId(null);
    setLoading(false);
    setFileList([]);
  };

  const updateDocument = async (e) => {
    e.preventDefault();
    setLoading(true);

    let formData = new FormData();
    formData.set("expiry_date", expiryDate);
    formData.set("document", verifyObject(files[0], "originFileObj", null));
    formData.set("category_id", Id);

    try {
      let response = await updateDocumentApi(formData);
      setModalOpen(false);
      _onReuestList();
      _handleCancel();
      successToast({content: response.data.message});
    } catch (e) {
      const err = getErrorObject(e);
      errorToast({content: err.message});
      _handleCancel();
    }
  };

  const onChange = (date, dateString) => {
    setExpiryDate(date);
  };

  const handleDocUpload = async ({fileList}) => {
    let files = [...fileList];
    files = fileList.slice(-1); // limits only one file to be selected
    setFileList(files);
  };

  const renderActionMenus = (record) => {
    return (
      <Menu className="width-190">
        {verifyObject(record, "file_url", null) !== null && (
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={verifyObject(record, "file_url", "#")}
          >
            <Menu.Item>View</Menu.Item>
          </a>
        )}
        <Menu.Item onClick={() => _handleModal(record)}>Upload</Menu.Item>
      </Menu>
    );
  };

  const columns = [
    {
      title: "Document",
      key: "document_status",
      dataIndex: "document_status",
      className:
        "width-40 min-height-30 text-capitalize font-weight-400 font-12",
      render: (text, item) => (
        <Fragment>
          {item.file_name ? (
            <a
              target="_blank"
              className="text-dark-blue"
              rel="noreferrer"
              href={verifyObject(item, "file_url", "#")}
            >
              {verifyObject(item, "file_name", "-")}
            </a>
          ) : item.category ? (
            item.category
          ) : (
            "-"
          )}
        </Fragment>
      ),
    },
    {
      title: "Expiry Date",
      key: "expiry_date",
      dataIndex: "expiry_date",
      align: "center",
      className: "width-30 min-height-30",
      render: (expiry_date) => (
        <Fragment>
          <p className="font-12">{expiry_date ? expiry_date : "--"}</p>
        </Fragment>
      ),
    },
    {
      title: "Status",
      key: "document_status",
      dataIndex: "document_status",
      className: "width-15 min-height-30",
      align: "right",
      render: (text, item) => {
        let status = verifyObject(item, "document_status", "--");
        return (
          <Fragment>
            <p className="text-capitalize font-12">
              {verifyObject(docStats[status], "name", "--")}
            </p>
          </Fragment>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      align: "right",
      className: "width-15 min-height-30",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            trigger={["click"]}
            overlay={() => renderActionMenus(record)}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {/* <EllipsisOutlined style={{ fontSize: "30px"}} /> */}
              <img alt="" src={three_dot} className="opacity-05"></img>
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  return (
    <Fragment>
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={rightToworkArray}
      />
      <ModalPopUp
        title="Update Document"
        handleCancel={_handleCancel}
        visible={modalOpen}
        maskClosable={false}
      >
        <form onSubmit={updateDocument}>
          <div className="form-group">
            <label htmlFor="expiry_date">Expiry Date</label>
            <DatePicker
              onChange={onChange}
              name="expiry_date"
              format={DATE_FORMAT}
              className="d-block w-100"
              value={expiryDate ? moment(expiryDate, DATE_FORMAT) : null}
            />
          </div>
          <div className="form-group push-20-t">
            <DragAndDropUpload
              accept=".pdf,.jpg,.jpeg,.png"
              fileList={files}
              name="file"
              multiple={false}
              handleFileUpload={handleDocUpload}
              style={{
                marginBottom: 10,
              }}
            >
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Select File or Drop File to Upload
              </p>
            </DragAndDropUpload>
          </div>
          <button
            className={`font-13 btn-custom btn-blue weight-400 sm-commonbtn btn text-white mt-2 ${
              !documentStatus || IsLoading ? "disabled disabled-btn" : ""
            }`}
            disabled={files.length === 0}
            type="submit"
          >
            {IsLoading && <Spinner />} Save
          </button>
        </form>
      </ModalPopUp>
    </Fragment>
  );
}

import React from "react";

export default function QuestionsAdditionalInformation({ question, className }) {
  const questionsValidations = question?.question_validation?.filter(
    (k) => k?.constraint_with === "information_text"
  );
  return (
    <div>
      {questionsValidations.map((d) => {
        if (
          question.category === "single_choice" ||
          question.category === "multi_choice"
        )
          if (d.information_message) {
            if (d.option_title === question.answer) {
              if (d.is_blocker) {
                return (
                  <span className={`text-danger margin-top-10 weight-400 font-12 in-block${className ? ` ${className}` : ''}`}>
                    {d.information_message}
                  </span>
                );
              } else {
                return (
                  <span className={`text-primary margin-top-10 weight-400 font-12 in-block${className ? ` ${className}` : ''}`}>
                    {d.information_message}
                  </span>
                );
              }
            }
            if (question.category === "numerical") {
              let enteredAnswer = parseInt(question?.answer, 10);
              if (
                enteredAnswer >= d?.min_range &&
                enteredAnswer <= d?.max_range
              ) {
                if (d.is_blocker) {
                  return (
                    <span className={`text-danger margin-top-10 weight-400 font-12 in-block${className ? ` ${className}` : ''}`}>
                      {d.information_message}
                    </span>
                  );
                } else {
                  return (
                    <span className={`text-primary margin-top-10 weight-400 font-12 in-block${className ? ` ${className}` : ''}`}>
                      {d.information_message}
                    </span>
                  );
                }
              }

              return <></>;
            }
          } else {
            return <></>;
          }
        return <></>;
      })}
    </div>
  );
}

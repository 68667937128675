/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
import {Image} from "antd";
import {isArray} from "lodash";
import React, {Fragment} from "react";
import {verifyObject} from "../../utilities/utils";
import moment from "moment";
import {DATE_FORMAT, DATE_FORMAT_24_HR} from "../../constants/common";

export default function CoreQuestions({questionAnswerArray, isLoading}) {
  console.log("question arraay--->", questionAnswerArray);
  return (
    <div className="readOnly_div script_review_que">
      {!isLoading &&
        questionAnswerArray &&
        questionAnswerArray &&
        questionAnswerArray.map((list) => {
          if (
            list.answer_type === "gender" ||
            list.answer_type === "radio" ||
            list.answer_type === "radio_and_pagelink" ||
            list.answer_type === "radio_hyperlink" ||
            list.answer_type === "yes_with_image"
          ) {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p
                    className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray"
                    dangerouslySetInnerHTML={{__html: list.text}}
                  ></p>

                  {list.answers &&
                    isArray(list.answers) &&
                    list.answers.map((msg) => {
                      if (
                        list.user_answer &&
                        msg.placeholder === list.user_answer.content.value &&
                        msg.message !== null
                      ) {
                        if (msg.message !== null) {
                          return (
                            <div className="alert alert-success custom-alert blue-bg-alert push-10-t push-10-b">
                              <p className="font-12-imp margin-0 weight-400 text-dark-blue">
                                {msg.message}
                              </p>
                            </div>
                          );
                        }
                      }
                    })}
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                    defaultValue={verifyObject(
                      list,
                      "user_answer.content.value",
                      "-"
                    )}
                    readOnly
                  />
                  {list.answer_type == "yes_with_image" &&
                    list.user_answer &&
                    (list.user_answer.content.value == "Yes" ||
                      list.user_answer.file !== "") && (
                      <Image
                        className="push-20-t hide_outline"
                        // ng-click="$ctrl.reviewObj.funcs.openModal('open_image',list.user_answer.file);"
                        src={list.user_answer.file}
                        height="100"
                        width="100"
                        alt=""
                        title="Image"
                      />
                    )}
                </div>
              </div>
            );
          }

          if (
            list.answer_type === "dropdown" ||
            list.answer_type === "country" ||
            list.answer_type === "number"
          ) {
            return (
              <Fragment>
                <div className="script_review_inner push-10-t">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                      {list.text}
                    </p>

                    {list.answers &&
                      list.user_answer &&
                      list.user_answer.content &&
                      list.answers[0] &&
                      list.answers[0].options &&
                      list.answers[0].options.data.map((data) => {
                        if (list.answer_type === "dropdown") {
                          if (data === list.user_answer.content.value) {
                            return (
                              <p className="font-12 margin-0 weight-400 text-dark-blue">
                                {list.answers[0].options.message}
                              </p>
                            );
                          }
                        }
                      })}
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    {list.answer_type !== "country" && (
                      <input
                        type="text"
                        className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                        defaultValue={
                          list.user_answer
                            ? list.user_answer.content.value
                            : "-"
                        }
                        readOnly
                      />
                    )}
                    {list.answer_type === "country" && (
                      <div
                        className="border_div input-bg-f9f9f9"
                        ng-if="list.answer_type == 'country'"
                      >
                        {list.user_answer &&
                          isArray(list.user_answer.content) &&
                          list.user_answer.content.map((data) => {
                            return (
                              <p
                                ng-repeat="data in list.user_answer.content"
                                className="push-b-0 last_child_nomargin font-12-imp"
                              >
                                <img
                                  src={
                                    require("../../assets/images/common/bullet_true.svg")
                                      .default
                                  }
                                  width="12"
                                  height="12"
                                  className="img-responsive center-block margin-top-2 margin-right-10 float-left"
                                  alt="Bullet"
                                  title="Bullet"
                                />
                                <span>{data}</span>
                              </p>
                            );
                          })}
                      </div>
                    )}
                    {list.user_answer &&
                      list.user_answer.description &&
                      list.answer_type === "dropdown" && (
                        <textarea
                          ng-if=""
                          className="form-control custom-input push-10-t"
                          rows="1"
                          defaultValue={list.user_answer.description}
                          readOnly
                        ></textarea>
                      )}
                  </div>
                </div>
              </Fragment>
            );
          }

          if (list.answer_type === "height") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                    defaultValue={`${
                      list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.value1
                        ? list.user_answer.content.value1
                        : ""
                    } ${
                      list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.value2
                        ? list.user_answer.content.value2
                        : ""
                    }`}
                    readOnly
                  />
                </div>
              </div>
            );
          }

          if (list.answer_type === "weight") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                    defaultValue={`${
                      list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.value1
                        ? list.user_answer.content.value1
                        : ""
                    } ${
                      list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.value2
                        ? list.user_answer.content.value2
                        : ""
                    }`}
                    readOnly
                  />
                </div>
              </div>
            );
          }
          if (list.answer_type === "bmi") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  {list.user_answer && (
                    <input
                      type="text"
                      className="form-control custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                      defaultValue={`${
                        list.user_answer.content.value
                          ? list.user_answer.content.value
                          : ""
                      }`}
                      readOnly
                    />
                  )}
                </div>
              </div>
            );
          }

          if (list.answer_type === "month_year") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  {list.user_answer && (
                    <input
                      type="text"
                      className="form-control custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                      defaultValue={`${
                        list.user_answer.content.year
                          ? list.user_answer.content.year
                          : ""
                      }`}
                      readOnly
                    />
                  )}
                </div>
              </div>
            );
          }

          if (
            list.answer_type === "yes_with_description" ||
            list.answer_type === "no_with_description"
          ) {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>

                  {list.answers &&
                    list.answers.map((msg) => {
                      if (
                        list.user_answer &&
                        msg.placeholder == list.user_answer.content.value &&
                        msg.message !== null
                      ) {
                        return (
                          <div
                            className="alert alert-success custom-alert push-10-t"
                            ng-repeat="msg in list.answers"
                            ng-if="msg.placeholder == list.user_answer.content.value && msg.message"
                          >
                            <p className="font-12 margin-0 weight-400 text-dark-blue">
                              {msg.message}
                            </p>
                          </div>
                        );
                      }
                    })}
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <input
                    type="text"
                    className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                    defaultValue={
                      list.user_answer ? list.user_answer.content.value : "-"
                    }
                    readOnly
                  />
                  {list.user_answer && list.user_answer.description && (
                    <textarea
                      className="form-control custom-input push-10-t"
                      rows="1"
                      defaultValue={
                        list.user_answer ? list.user_answer.description : "-"
                      }
                      readOnly
                    ></textarea>
                  )}
                </div>
              </div>
            );
          }

          if (list.answer_type === "yes_with_options") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  {list.user_answer &&
                    list.user_answer.content &&
                    list.user_answer.content.radio === "Yes" && (
                      <input
                        type="text"
                        defaultValue={list.user_answer.content.description}
                        className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                        readOnly
                      />
                    )}
                  {list.user_answer &&
                    list.user_answer.content &&
                    list.user_answer.content.radio === "No" && (
                      <input
                        type="text"
                        ng-if="list.user_answer.content.radio == 'No'"
                        className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                        defaultValue="No"
                        readOnly
                      />
                    )}
                </div>
              </div>
            );
          }
          if (list.answer_type == "yes_with_tags") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  {list.user_answer &&
                    list.user_answer.content &&
                    list.user_answer.content.radio == "Yes" &&
                    list.answers[0].options.tag_type == "allergy" && (
                      <div className="border_div  input-bg-f9f9f9" ng-if="">
                        {list.user_answer &&
                          list.user_answer.content &&
                          list.user_answer.content.description.map(
                            (list, index) => {
                              return (
                                <span
                                  className="font-12 weight-400 text-light-black"
                                  ng-repeat="list in list.user_answer.content.description"
                                >
                                  {verifyObject(
                                    list,
                                    "snomed_code.description",
                                    "-"
                                  )}
                                  {list.user_answer &&
                                  list.user_answer.content &&
                                  list.user_answer.content.description.length -
                                    1 ===
                                    index
                                    ? ""
                                    : ", "}
                                </span>
                              );
                            }
                          )}
                      </div>
                    )}

                  {list.user_answer &&
                    list.user_answer.content &&
                    list.user_answer.content.radio == "Yes" &&
                    list.answers[0].options.tag_type == "disorder_history" && (
                      <div className="border_div  input-bg-f9f9f9" ng-if="">
                        {list.user_answer &&
                          list.user_answer.content &&
                          list.user_answer.content.description.map(
                            (list, index) => {
                              return (
                                <span
                                  className="font-12 weight-400 text-light-black"
                                  ng-repeat="list in list.user_answer.content.description"
                                >
                                  {list}
                                  {list.user_answer &&
                                  list.user_answer.content &&
                                  list.user_answer.content.description.length -
                                    1 ===
                                    index
                                    ? ""
                                    : ", "}{" "}
                                </span>
                              );
                            }
                          )}
                      </div>
                    )}
                  {list.user_answer &&
                    list.user_answer.content &&
                    list.user_answer.content.radio === "No" && (
                      <input
                        type="text"
                        className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                        defaultValue="No"
                        readOnly
                      />
                    )}
                </div>
              </div>
            );
          }

          if (
            list.answer_type == "agree" ||
            list.answer_type == "promotion_offer"
          ) {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p
                    className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray"
                    dangerouslySetInnerHTML={{__html: list.text}}
                  ></p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  {list.user_answer &&
                    list.user_answer.content &&
                    list.user_answer.content.value && (
                      <input
                        type="text"
                        className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                        defaultValue="Yes"
                        readOnly
                      />
                    )}
                  {list.user_answer &&
                    list.user_answer.content &&
                    !list.user_answer.content.value && (
                      <input
                        type="text"
                        className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                        defaultValue="No"
                        readOnly
                      />
                    )}
                </div>
              </div>
            );
          }

          if (
            list.answer_type == "checkbox" ||
            list.answer_type == "checkbox_with_medical_problem_form"
          ) {
            return (
              <Fragment>
                <div className="script_review_inner push-10-t">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <p
                      className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray"
                      dangerouslySetInnerHTML={{
                        __html: list.text,
                      }}
                    ></p>
                  </div>
                  {list.user_answer &&
                    list.user_answer.content &&
                    list.user_answer.content.length && (
                      <div
                        className="col-md-12 col-sm-12 col-xs-12"
                        ng-if="list.user_answer.content.length"
                      >
                        <div className="border_div  input-bg-f9f9f9">
                          {list.user_answer &&
                            list.user_answer.content &&
                            isArray(list.user_answer.content) &&
                            list.user_answer.content.map((data) => {
                              return (
                                <p
                                  ng-repeat="data in list.user_answer.content"
                                  className="push-b-8 last_child_nomargin font-12-imp"
                                >
                                  <img
                                    src={
                                      require("../../assets/images/common/bullet_true.svg")
                                        .default
                                    }
                                    width="12"
                                    height="12"
                                    alt="Bullet"
                                    title="Bullet"
                                    className="img-responsive center-block margin-top-2 margin-right-10 float-left"
                                  />
                                  {data.is_checked && (
                                    <span ng-if="data.is_checked">
                                      {data.placeholder}
                                    </span>
                                  )}
                                  <br />
                                  {/* <span className="weight-400" ng-if="data.problem.start_month">{{data.problem.start_month | limitTo : 3 }}</span> */}
                                  {data.problem && data.problem.start_month && (
                                    <span
                                      className="weight-400"
                                      ng-if="data.problem.start_month"
                                    >
                                      {data.problem.start_month}
                                    </span>
                                  )}
                                  {data.problem && data.problem.start_year && (
                                    <span
                                      className="weight-400"
                                      ng-if="data.problem.start_year"
                                    >
                                      {data.problem.start_year}
                                    </span>
                                  )}
                                  {/* <span className="weight-400" ng-if="data.problem.end_month"> - {{data.problem.end_month | limitTo : 3 }}</span> */}
                                  {data.problem && data.problem.end_month && (
                                    <span
                                      className="weight-400"
                                      ng-if="data.problem.end_month"
                                    >
                                      {" "}
                                      - {data.problem.end_month}
                                    </span>
                                  )}

                                  {data.problem && data.problem.end_year && (
                                    <span
                                      className="weight-400"
                                      ng-if="data.problem.end_year"
                                    >
                                      {data.problem.end_year}
                                    </span>
                                  )}
                                  <br />
                                  {data.is_checked &&
                                    list.user_answer &&
                                    list.user_answer.description && (
                                      <textarea
                                        ng-if=""
                                        className="form-control custom-input push-10-t"
                                        rows="3"
                                        defaultValue={
                                          list.user_answer.description
                                        }
                                        readOnly
                                      ></textarea>
                                    )}
                                </p>
                              );
                            })}
                        </div>
                      </div>
                    )}
                </div>
              </Fragment>
            );
          }

          if (list.answer_type == "yes_with_surgery") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p
                    className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray"
                    dangerouslySetInnerHTML={{__html: list.text}}
                  ></p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="border_div  input-bg-f9f9f9">
                    {list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.radio == "No" && (
                        <p
                          className="font-12 weight-400 push-b-0"
                          ng-show="list.user_answer.content.radio == 'No'"
                        >
                          {list.user_answer.content.radio}{" "}
                        </p>
                      )}
                    {list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.radio == "Yes" && (
                        <span ng-show="list.user_answer.content.radio == 'Yes'">
                          <p className="font-12 weight-400">
                            <span className="weight-400 text-light-black">
                              Practice Code:
                            </span>{" "}
                            <span className="weight-400 text-dark-gray">
                              {
                                list.user_answer.content.description
                                  .practice_code
                              }
                            </span>
                          </p>
                          <p className="font-12 weight-400">
                            <span className="weight-400 text-light-black">
                              Practice Name:
                            </span>
                            <span className="weight-400 text-dark-gray">
                              {
                                list.user_answer.content.description
                                  .practice_name
                              }
                            </span>
                          </p>
                          <p className="font-12 weight-400">
                            <span className="weight-400 text-light-black">
                              Practice Address:
                            </span>
                            <span className="weight-400 text-dark-gray">
                              {list.user_answer.content.description.address}
                            </span>
                          </p>
                          <p className="font-12 weight-400">
                            <span className="weight-400 text-light-black">
                              City:
                            </span>{" "}
                            <span className="weight-400 text-dark-gray">
                              {list.user_answer.content.description.city}
                            </span>
                          </p>
                          <p className="font-12 weight-400">
                            <span className="weight-400 text-light-black">
                              County:
                            </span>{" "}
                            <span className="weight-400 text-dark-gray">
                              {list.user_answer.content.description.county}
                            </span>
                          </p>
                          <p className="font-12 weight-400">
                            <span className="weight-400 text-light-black">
                              Postal Code:
                            </span>{" "}
                            <span className="weight-400 text-dark-gray">
                              {list.user_answer.content.description.post_code}
                            </span>
                          </p>
                          <p className="font-12 weight-400">
                            <span className="weight-400 text-light-black">
                              Phone:
                            </span>{" "}
                            <span className="weight-400 text-dark-gray">
                              {list.user_answer.content.description.phone}
                            </span>{" "}
                          </p>
                        </span>
                      )}
                  </div>
                </div>
              </div>
            );
          }

          if (
            list.answer_type == "dob" ||
            list.answer_type == "past_date" ||
            list.answer_type == "future_date"
          ) {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12 relative radio-section datepicker_ui full_datepicker">
                  <input
                    type="text"
                    defaultValue={
                      list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.value
                        ? moment(list.user_answer.content.value).format(
                            DATE_FORMAT
                          )
                        : ""
                    }
                    className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                    readOnly
                  />

                  {/* <md-datepicker
                      ng-model="list.user_answer.content.value"
                      className="input-sm-28"
                      name="scheduled_date"
                      ng-disabled="true"
                      md-placeholder="DD/MM/YYYY"
                    ></md-datepicker> */}
                  {}
                </div>
              </div>
            );
          }

          if (list.answer_type == "textarea") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <textarea
                    className="form-control custom-input"
                    rows="1"
                    defaultValue={
                      list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.value
                        ? list.user_answer.content.value
                        : ""
                    }
                    readOnly
                  ></textarea>
                </div>
              </div>
            );
          }

          if (list.answer_type == "treatment_form") {
            return (
              <Fragment>
                <div className="script_review_inner push-10-t">
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                      {list.text}
                    </p>
                  </div>
                  <div className="col-md-12 col-sm-12 col-xs-12">
                    {list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.value === "Yes" && (
                        <div ng-if="list.user_answer.content.value == 'Yes'">
                          <input
                            type="text"
                            className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                            defaultValue={list.user_answer.content.value}
                            readOnly
                          />
                        </div>
                      )}
                    {list.user_answer &&
                      list.user_answer.content &&
                      list.user_answer.content.value == "No" && (
                        <div ng-if="list.user_answer.content.value == 'No'">
                          <input
                            type="text"
                            className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                            defaultValue={list.user_answer.content.value}
                            readOnly
                          />
                        </div>
                      )}
                  </div>
                </div>
                {list.user_answer &&
                  list.user_answer.content &&
                  list.user_answer.content.value == "Yes" && (
                    <div
                      className="script_review_inner push-10-t"
                      ng-if="list.user_answer.content.value == 'Yes'"
                    >
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        {list.user_answer.content.value == "Yes" && (
                          <div
                            ng-if="list.user_answer.content.value == 'Yes'"
                            ng-repeat="questions in list.internal_questions"
                            className=""
                          >
                            {list.internal_questions &&
                              list.internal_questions.map((question) => {
                                return (
                                  <div className="form-row push-10-t">
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                      <p
                                        className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray"
                                        // style="min-height: 40px;"
                                        // style={{ minHeight: 40 }}
                                        // ng-bind-html="question.text"
                                        dangerouslySetInnerHTML={{
                                          __html: question.text,
                                        }}
                                      ></p>
                                    </div>
                                    <div className="col-md-12 col-sm-12 col-xs-12">
                                      {question.user_answer &&
                                        question.user_answer.content && (
                                          <div ng-if="list.user_answer.content.value == 'No'">
                                            {question.answer_type ===
                                              "past_date" && (
                                              <input
                                                type="text"
                                                className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                                                defaultValue={moment(
                                                  question.user_answer.content
                                                    .value
                                                ).format(DATE_FORMAT_24_HR)}
                                                readOnly
                                              />
                                            )}
                                            {question.answer_type ===
                                              "dropdown" && (
                                              <input
                                                type="text"
                                                className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                                                defaultValue={
                                                  question.user_answer.content
                                                    .value
                                                }
                                                readOnly
                                              />
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                        )}
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        {list.user_answer &&
                          list.user_answer.content &&
                          list.user_answer.content.value == "Yes" && (
                            <div ng-if="list.user_answer.content.value == 'Yes'">
                              <treatment className="push-20-t">
                                {list.internal_questions.map((answers) => {
                                  if (answers.answer_type == "past_date") {
                                    return (
                                      <div ng-repeat="answers in list.internal_questions">
                                        <div
                                          className="relative radio-section datepicker_ui full_datepicker"
                                          style={{
                                            minHeight: 40,
                                          }}
                                          ng-if="answers.answer_type == 'past_date'"
                                        >
                                          {/* <md-datepicker
                                  ng-model="answers.user_answer.content.value"
                                  name="scheduled_date"
                                  ng-disabled="true"
                                  md-placeholder="DD/MM/YYYY"
                                  className="input-sm-28"
                                ></md-datepicker> */}
                                        </div>
                                        {(answers.answer_type ==
                                          "yes_with_description" ||
                                          answers.answer_type ==
                                            "no_with_description" ||
                                          answers.answer_type == "radio" ||
                                          answers.answer_type ==
                                            "radio_and_pagelink" ||
                                          answers.answer_type == "textarea" ||
                                          answers.answer_type ==
                                            "dropdown") && (
                                          <input
                                            type="text"
                                            className="form-control resize custom-input font-12-imp weight-400 input-sm-28 input-bg-f9f9f9"
                                            // ng-if="answers.answer_type == 'yes_with_description' || answers.answer_type == 'no_with_description' || answers.answer_type == 'radio' || answers.answer_type == 'radio_and_pagelink' || answers.answer_type == 'textarea' || answers.answer_type == 'dropdown'"
                                            defaultValue={
                                              answers.user_answer.content.value
                                            }
                                            readOnly
                                          />
                                        )}
                                        {(answers.answer_type ==
                                          "yes_with_description" ||
                                          answers.answer_type ==
                                            "no_with_description") &&
                                          answers.user_answer.content
                                            .description && (
                                            <textarea
                                              // ng-if="(answers.answer_type == 'yes_with_description' || answers.answer_type == 'no_with_description') && answers.user_answer.content.description"
                                              className="form-control custom-input push-10-t"
                                              rows="1"
                                              defaultValue={
                                                answers.user_answer.content
                                                  .description
                                              }
                                              readOnly
                                            ></textarea>
                                          )}
                                      </div>
                                    );
                                  }
                                })}
                              </treatment>
                            </div>
                          )}
                      </div>
                    </div>
                  )}
              </Fragment>
            );
          }

          if (
            list.answer_type == "medical_problem_form" ||
            list.answer_type == "operation_form"
          ) {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  {/* {verifyObject(
                    list,
                    "user_answer.content.length",
                    undefined
                  ) === undefined && (
                    <p
                      ng-if="!list.user_answer.content.length"
                      className="push-10-t"
                    >
                      -
                    </p>
                  )} */}

                  {list.user_answer &&
                    list.user_answer.content &&
                    isArray(list.user_answer.content) &&
                    list.user_answer.content.map((data) => {
                      return (
                        <Fragment>
                          <div
                            className="medication_table margin-bottom-15 dis-table input-bg-f9f9f9"
                            ng-repeat="data in list.user_answer.content"
                          >
                            <div className="cell width-70">
                              {data.read_code && (
                                <p
                                  className="font-12 weight-400 text-light-black margin-0"
                                  ng-if="data.read_code"
                                >
                                  {data.read_code.description}
                                </p>
                              )}
                              {data.history_problem && (
                                <p
                                  className="font-12 weight-400 text-light-black margin-0"
                                  ng-if="data.history_problem"
                                >
                                  {data.history_problem.description}
                                </p>
                              )}
                            </div>
                            <div className="cell width-30 text-right">
                              <p className="font-12 weight-400 text-light-black margin-0">
                                {data.start_month}, {data.start_year}
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            );
          }

          if (list.answer_type == "medications_form") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  {/* {list.user_answer &&
                      list.user_answer.content.length} */}
                  {verifyObject(
                    list,
                    "user_answer.content.length",
                    undefined
                  ) === undefined && (
                    <p
                      ng-if="!list.user_answer.content.length"
                      className="push-10-t"
                    >
                      -
                    </p>
                  )}

                  {list.user_answer &&
                    list.user_answer.content &&
                    isArray(list.user_answer.content) &&
                    list.user_answer.content.map((data) => {
                      return (
                        <Fragment>
                          <div
                            className="medication_table medication_img margin-bottom-15 dis-table input-bg-f9f9f9"
                            ng-repeat="data in list.user_answer.content"
                          >
                            <div className="cell text-left width-30">
                              <div className="in-block width-75 push-0-t">
                                <p className="font-12 weight-400 text-light-black margin-0">
                                  {data.medication_name}
                                </p>
                              </div>
                            </div>
                            <div className="cell text-right width-40">
                              <p className="font-12 weight-400 text-dark-black margin-0 text-capitalize">
                                {data.strength}{" "}
                                {verifyObject(data, "formulation")
                                  ? verifyObject(data, "formulation")
                                  : "Tablets"}
                              </p>
                            </div>
                            <div className="cell text-right width-30">
                              <p className="font-12 weight-400 text-light-black margin-0">
                                {data.no}
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            );
          }

          if (list.answer_type == "malaria_country_list") {
            return (
              <div className="script_review_inner push-10-t">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    {list.text}
                  </p>
                </div>
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="margin-bottom-15 dis-table">
                    <div className="cell width-20 text-left padding-left-20">
                      <div className="in-block">
                        <p className="font-16 weight-400 text-light-black margin-0">
                          Country
                        </p>
                      </div>
                    </div>
                    <div className="cell width-50 text-left padding-right-10 padding-left-15">
                      <p className="font-16 weight-400 text-light-black margin-0">
                        Region
                      </p>
                    </div>
                    <div className="cell width-30 text-left">
                      <p className="font-16 weight-400 text-light-black margin-0">
                        Travel Arrival & Return Date
                      </p>
                    </div>
                  </div>
                  {verifyObject(
                    list,
                    "user_answer.content.length",
                    undefined
                  ) === undefined && (
                    <p
                      ng-if="!list.user_answer.content.length"
                      className="push-10-t"
                    >
                      -
                    </p>
                  )}

                  {list.user_answer &&
                    list.user_answer.content &&
                    isArray(list.user_answer.content) &&
                    list.user_answer.content.map((data) => {
                      return (
                        <Fragment>
                          <div
                            className="medication_table margin-bottom-15 dis-table input-bg-f9f9f9"
                            ng-repeat="data in list.user_answer.content"
                          >
                            <div className="cell width-20 text-left">
                              <div className="in-block">
                                <p className="font-16 weight-400 text-light-black margin-0">
                                  {data.country}
                                </p>
                              </div>
                            </div>
                            <div className="cell width-50 text-left padding-right-10 padding-left-0">
                              <p className="font-12 weight-400 text-dark-black margin-0 line-1-72">
                                {data.region}
                              </p>
                            </div>
                            <div className="cell width-30 text-left padding-left-10">
                              {/* <p className="font-12 weight-400 text-light-black margin-0">{sata.travel_start_date | date : 'dd MMM yyyy'}} */}
                              <p className="font-12 weight-400 text-light-black margin-0">
                                {data.travel_start_date}-
                                {/* {{data.travel_end_date | date : 'dd MMM yyyy'}}</p> */}
                                {data.travel_end_date}
                              </p>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            );
          }
        })}
    </div>
  );
}

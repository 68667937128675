import { getDocumentsApi } from "services/rightToWork";
import { listingReducer } from "reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: `rightToWork`,
  getApi: getDocumentsApi
});

const rightToWork = reducer;
export { rightToWork, actions, types };

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, resetFormState, resetPassword } from "reducers/session";
// import ResetPassword from "containers/session/ResetPassword";
// import ChangePassword from "./ChangePassword";
import ResetPasswordWithToken from "./ResetPasswordWithToken";
import { withRouter } from "react-router-dom";

class ResetPasswordWithTokenContainer extends Component {
  render() {
    let reset_token = null;
    if (this.props.match.params.reset_token) {
      reset_token = this.props.match.params.reset_token;
    }

    return (
      <Fragment>
        <ResetPasswordWithToken resetToken={reset_token} {...this.props} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { login, resetFormState, resetPassword },
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordWithTokenContainer);

export default withRouter(ConnectedComponent);

import React from "react";
import Lightbox from "react-awesome-lightbox";

export default function LightBoxComponent({
  visible,
  image,
  images,
  title,
  onClose,
}) {
  return (
    <div>
      {visible && (
        <Lightbox image={image} title={title} onClose={onClose}></Lightbox>
      )}
    </div>
  );
}

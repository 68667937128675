import React, { Fragment } from "react";
// import { Checkbox, Input, DatePicker } from "antd";
import { isArray } from "lodash";
// import moment from "moment";
// import { DATE_FORMAT } from "constants/common";
import SingleChoiceQuestion from "./QuestionsTypes/SingleChoiceQuestion";
import NumericalQuestion from "./QuestionsTypes/NumericalQuestion";
import DateRangeQuestion from "./QuestionsTypes/DateRangeQuestion";
import TextBoxQuestion from "./QuestionsTypes/TextBoxQuestion";
import CheckboxGroupQuestion from "./QuestionsTypes/TextBoxQuestion";
import DatePickerQuestion from "./QuestionsTypes/DatePickerQuestion";
import CheckAgreeQuestion from "./QuestionsTypes/CheckAgreeQuestion";
import TextAreaQuestion from "./QuestionsTypes/TextAreaQuestion";

export default function QuestionsChildOfChild({
  childQuestions,
  handleAnswerChange,
  parentQuestion,
  childOfQuestions,
  gender,
}) {
  return (
    <div className="form-row child-questions">
      <div className="col-md-12">
        {childOfQuestions.category === "text" && (
          <TextBoxQuestion
            handleAnswerChange={handleAnswerChange}
            childQuestions={childQuestions}
            childOfQuestions={childOfQuestions}
            parentQuestion={parentQuestion}
            question={childOfQuestions}
            gender={gender}
          />
        )}
        {childOfQuestions.category === "text_area" && (
          <div>
            {/* Render TextBoxQuestion component */}
            <TextAreaQuestion
              handleAnswerChange={handleAnswerChange}
              childQuestions={childQuestions}
              parentQuestion={parentQuestion}
              question={childOfQuestions}
              childOfQuestions={childOfQuestions}
              gender={gender}
            />
          </div>
        )}
        {childOfQuestions.category === "check_agree" && (
          <Fragment>
            <CheckAgreeQuestion
              handleAnswerChange={handleAnswerChange}
              childQuestions={childQuestions}
              parentQuestion={parentQuestion}
              childOfQuestions={childOfQuestions}
              question={childOfQuestions}
              gender={gender}
            />
          </Fragment>
        )}

        {childOfQuestions.category === "single_choice" &&
          isArray(childOfQuestions.question_options) && (
            <SingleChoiceQuestion
              handleAnswerChange={handleAnswerChange}
              childQuestions={childQuestions}
              parentQuestion={parentQuestion}
              childOfQuestions={childOfQuestions}
              question={childOfQuestions}
              gender={gender}
            />
          )}

        {childOfQuestions.category === "multi_choice" &&
          childOfQuestions.question_options &&
          isArray(childOfQuestions.question_options) && (
            <Fragment>
              <div className="margin-t-15">
                <CheckboxGroupQuestion
                  handleAnswerChange={handleAnswerChange}
                  childQuestions={childQuestions}
                  parentQuestion={parentQuestion}
                  childOfQuestions={childOfQuestions}
                  question={childOfQuestions}
                  gender={gender}
                />
              </div>
            </Fragment>
          )}
        {childOfQuestions.category === "file" && (
          <div className="margin-t-15">
            <input type="file" accept="image/*,.pdf" />
          </div>
        )}
        {childOfQuestions.category === "date" && (
          <DatePickerQuestion
            handleAnswerChange={handleAnswerChange}
            childQuestions={childQuestions}
            parentQuestion={parentQuestion}
            childOfQuestions={childOfQuestions}
            question={childOfQuestions}
            gender={gender}
          />
        )}
        {childOfQuestions.category === "date_range" && (
          <DateRangeQuestion
            handleAnswerChange={handleAnswerChange}
            childQuestions={childQuestions}
            parentQuestion={parentQuestion}
            childOfQuestions={childOfQuestions}
            question={childOfQuestions}
            gender={gender}
          />
        )}
        {childOfQuestions.category === "numerical" && (
          <NumericalQuestion
            handleAnswerChange={handleAnswerChange}
            childQuestions={childQuestions}
            parentQuestion={parentQuestion}
            childOfQuestions={childOfQuestions}
            question={childOfQuestions}
            gender={gender}
          />
        )}
      </div>
    </div>
  );
}

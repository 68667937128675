import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as sickNotesActions} from "../../../reducers/refferalNotes";
import {
  verifyObject,
  // modifiedDataObjectToArray,
  modifiedDataObjectToArrayByIDs,
} from "../../../utilities/utils";
import {Collapse, Input} from "antd";
import {isArray, debounce} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import {Pagination} from "../../../components/common";
// import search_blue from "assets/images/common/search-blue.svg";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {DATE_FORMAT} from "../../../constants/common";
import {SearchOutlined} from "@ant-design/icons";
// import Scrollbars from "react-custom-scrollbars";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
let {Panel} = Collapse;

class SickNotesContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: this.props.fromConsultation ? null : ["1"],
    };
    ["_onRequestList", "_onPageChange", "_handleSearch"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.searchUpdate = debounce(this._handleSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, sickNotesActions} = this.props;
    let {onSearch} = sickNotesActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({
        search: value,
        patient_id: patientId,
        page: 1,
        document_type: "SickNote",
      });
    }
  }

  componentDidMount() {
    this._onRequestList();
  }

  _onRequestList() {
    let {patientId, sickNotesActions} = this.props;
    // let { activeKey } = this.state;
    let {onRequest} = sickNotesActions;
    if (typeof onRequest === "function") {
      onRequest({patient_id: patientId, page: 1, document_type: "SickNote"});
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      sickNotesActions: {onPageChange},
      sickNotes: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page: page,
        patient_id: patientId,
        search: search,
        document_type: "SickNote",
      });
    }
  }

  render() {
    let {sickNotes} = this.props;
    let {activeKey} = this.state;
    let {
      // data,
      isLoading,
      // pagination: { total },
    } = sickNotes;
    let sickNotesArray = modifiedDataObjectToArrayByIDs(sickNotes);

    return (
      <Fragment>
        <div className="push-20-b">
          <div className="form-row align-items-center push-10-b left-right-space">
            <div className="col-md-4">
              {/* <h6 className="font-14 text-light-black margin-0 weight-500">
                Sick Note {isLoading ? "" : `(${total})`}{" "}
              </h6> */}
            </div>
            <div className="col-md-8 text-right">
              <div className="d-flex align-items-center justify-content-end stickfilter">
                {/* <div className="dragbtn">
                  <a>
                    <span className="uprrow">
                      <CaretUpOutlined />
                    </span>
                    <span className="downarrow">
                      <CaretDownOutlined />
                    </span>
                  </a>
                </div> */}
                <div className="filtercomponent">
                  <div className="relative searchbar_dropdown medical_Search">
                    <Input
                      onChange={(e) => {
                        let value = e.target.value;
                        this.searchUpdate(value);
                      }}
                      size="large"
                      allowClear={true}
                      placeholder="Search Sick Note"
                      prefix={<SearchOutlined />}
                    />
                    {/* <img alt="" src={search_blue}></img> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          <div className="sectionsubscroll">
            <PerfectScrollbar>
              <div className="left-right-space">
                {!isLoading &&
                  sickNotesArray &&
                  isArray(sickNotesArray) &&
                  sickNotesArray.length === 0 && (
                    <NoFoundMessage message="No sick notes found." />
                  )}

                <Collapse
                  expandIconPosition="right"
                  onChange={(key) => {
                    this.setState({activeKey: key});
                  }}
                  activeKey={activeKey}
                  className="pre_quitionare"
                >
                  {!isLoading &&
                    sickNotesArray &&
                    isArray(sickNotesArray) &&
                    sickNotesArray.map((k, index) => {
                      return (
                        <Panel
                          key={[index + 1]}
                          header={
                            <div>
                              <p className="font-12 weight-400 line-1-43 margin-bottom-0 text-fff padding-5">
                                Given by{" "}
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "doctor_name", "") !== ""
                                      ? `${k.doctor_name}`
                                      : "-"
                                  }
                                />{" "}
                                On{" "}
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "case_from", "") !== ""
                                      ? `${moment(k.case_from).format(
                                          DATE_FORMAT
                                        )}`
                                      : "-"
                                  }
                                />
                              </p>
                            </div>
                          }
                        >
                          <div className="form-row">
                            <div className="col-md-12 sicknote_cotent">
                              <div className="form-row">
                                <div className="col-md-12">
                                  <div className="form-row push-5-t">
                                    <div className="col-md-4">
                                      <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                        I assessed your case on
                                      </p>
                                      <div className="border-box col-md-12 input-bg-f9f9f9">
                                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "assessed_on",
                                                null
                                              ) !== null
                                                ? `${moment(
                                                    k.assessed_on
                                                  ).format(DATE_FORMAT)}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                        and beacause of the following
                                        condition(s)
                                      </p>
                                      <div className="border-box col-md-12 input-bg-f9f9f9">
                                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "assessment_reason",
                                                ""
                                              ) !== ""
                                                ? `${k.assessment_reason}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-md-4">
                                      <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                        I advice you that
                                      </p>
                                      <div className="border-box col-md-12 input-bg-f9f9f9">
                                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(k, "advise", "") !==
                                              ""
                                                ? `${k.advise}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="clearfix"></div>

                                  <div className="form-row push-10-t">
                                    <div className="col-md-6">
                                      <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                        If available , and with your employer's
                                        agreement, you may benifit from
                                      </p>
                                      <div className="border-box col-md-12 input-bg-f9f9f9">
                                        <div className="form-row">
                                          <div className="col-md-12">
                                            <p className="font-11 weight-400 text-light-black text-left margin-0">
                                              <Highlighter
                                                highlightClassName="search-highlighter"
                                                searchWords={[
                                                  this.state.search,
                                                ]}
                                                autoEscape={true}
                                                textToHighlight={
                                                  verifyObject(
                                                    k,
                                                    "benefit_from",
                                                    null
                                                  ) !== null
                                                    ? `${k.benefit_from}`
                                                    : "-"
                                                }
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                        Comments , including functional effects
                                        of your condition(s)
                                      </p>
                                      <div className="border-box col-md-12 input-bg-f9f9f9">
                                        <div className="form-row">
                                          <div className="col-md-12">
                                            <p className="font-11 weight-400 text-light-black text-left margin-0">
                                              <Highlighter
                                                highlightClassName="search-highlighter"
                                                searchWords={[
                                                  this.state.search,
                                                ]}
                                                autoEscape={true}
                                                textToHighlight={
                                                  verifyObject(
                                                    k,
                                                    "comment",
                                                    ""
                                                  ) !== null
                                                    ? `${k.comment}`
                                                    : "-"
                                                }
                                              />
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="clearfix"></div>

                                  <div className="form-row push-10-t">
                                    <div className="col-lg-4 col-md-5 col-sm-5">
                                      <div className="form-row">
                                        <div className="col-md-6">
                                          <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                            Start Date
                                          </p>
                                          <div className="border-box col-md-12 input-bg-f9f9f9">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <p className="font-11 weight-400 text-light-black text-left margin-0">
                                                  <Highlighter
                                                    highlightClassName="search-highlighter"
                                                    searchWords={[
                                                      this.state.search,
                                                    ]}
                                                    autoEscape={true}
                                                    textToHighlight={
                                                      verifyObject(
                                                        k,
                                                        "case_from",
                                                        null
                                                      ) !== null
                                                        ? `${moment(
                                                            k.case_from
                                                          ).format(
                                                            DATE_FORMAT
                                                          )}`
                                                        : "-"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                            End Date
                                          </p>
                                          <div className="border-box col-md-12 input-bg-f9f9f9">
                                            <div className="row">
                                              <div className="col-md-12">
                                                <p className="font-11 weight-400 text-light-black text-left margin-0">
                                                  <Highlighter
                                                    highlightClassName="search-highlighter"
                                                    searchWords={[
                                                      this.state.search,
                                                    ]}
                                                    autoEscape={true}
                                                    textToHighlight={
                                                      verifyObject(
                                                        k,
                                                        "case_to",
                                                        null
                                                      ) !== null
                                                        ? `${moment(
                                                            k.case_to
                                                          ).format(
                                                            DATE_FORMAT
                                                          )}`
                                                        : "-"
                                                    }
                                                  />
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="clearfix"></div>
                                  <span className="font-12 weight-400 text-dark-gray line-1-43 push-5-t dis-block">
                                    {verifyObject(
                                      k,
                                      "need_fitness_assessment",
                                      null
                                    ) !== null
                                      ? `${k.need_fitness_assessment}`
                                      : "-"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Panel>
                      );
                    })}
                </Collapse>

                <div className="col-md-12">
                  {!isLoading && (
                    <Pagination
                      data={sickNotes}
                      onPageChange={this._onPageChange}
                    />
                  )}
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    sickNotes: verifyObject(state, "referralNotes", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sickNotesActions: bindActionCreators(sickNotesActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SickNotesContainer);

export default ConnectedComponent;

import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as nhsRepeatMedicationsActions} from "../../../reducers/nhsRepeatMedications";
import {
  verifyObject,
  getErrorObject,
  errorToast,
} from "../../../utilities/utils";
import {isArray, debounce} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {genhsMedicationsApi} from "../../../services/nhsMedications";
import {Table} from "antd";
import {DATE_FORMAT} from "../../../constants/common";

// import { SearchOutlined } from "@ant-design/icons";
// import { Input } from "antd";

class NHSContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      message: "",
      nhsRepeatMedications: null,
      isLoading: false,
    };
    ["_onRequestList", "_onPageChange", "_handleSearch", "_handleCancel"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.searchUpdate = debounce(this._handleSearch, 1000);
    //Test
  }

  async _handleSearch(value) {
    let {patientId, nhsRepeatMedicationsActions} = this.props;
    let {onSearch} = nhsRepeatMedicationsActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({
        search: value,
        patient_id: patientId,
        page: 1,
        patient_record: true,
      });
    }
  }

  componentDidMount() {
    this._onRequestList();
  }

  // _onRequestList() {
  //   let {patientId, nhsRepeatMedicationsActions} = this.props;
  //   let {onRequest} = nhsRepeatMedicationsActions;
  //   if (typeof onRequest === "function") {
  //     onRequest({patient_id: patientId, patient_record: true});
  //   }
  // }

  async _onRequestList() {
    let {patientId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await genhsMedicationsApi({
        patient_id: patientId,
        patient_record: true,
      });
      let nhsRepeatMedications = verifyObject(
        response,
        "data.data.nhs_repeat_medications",
        []
      );
      await this.setState({
        isLoading: false,
        nhsRepeatMedications: nhsRepeatMedications,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      nhsRepeatMedicationsActions: {onPageChange},
      nhsRepeatMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page: page,
        patient_id: patientId,
        search: search,
        patient_record: true,
      });
    }
  }

  _handleCancel() {
    this.setState({visible: false, message: "", adding: false});
  }

  render() {
    let {nhsRepeatMedications, isLoading} = this.state;
    let activeMedications = verifyObject(nhsRepeatMedications, ["active"], []);
    let pastMedications = verifyObject(nhsRepeatMedications, ["past"], []);
    // let {data} = this.props.nhsRepeatMedications;
    // let repeatData =
    //   data && data.nhs_repeat_medications ? data.nhs_repeat_medications : [];
    const columns = [
      {
        title: "",
        key: "drug",
        dataIndex: "drug",
        className: "width-40 text-black",
        render: (drug, item) => (
          <Fragment>
            {verifyObject(drug, "name", null) !== null ? drug.name : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "dosage_description",
        dataIndex: "dosage_description",
        className: "width-40 text-gray",
        render: (dosage_description, item) => (
          <Fragment>{dosage_description ? dosage_description : "-"}</Fragment>
        ),
      },
      {
        title: "",
        key: "created_at",
        dataIndex: "created_at",
        className: "width-10 text-gray",
        render: (created_at, item) => (
          <Fragment>
            {created_at ? moment(created_at).format(DATE_FORMAT) : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "quantity",
        dataIndex: "quantity",
        className: "width-10",
        render: (quantity, item) => (
          <Fragment>
            {quantity ? (
              <span className="custom-bluelabel">Qty: {quantity}</span>
            ) : (
              "-"
            )}
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="push-20-b nhscon_wrapper">
          {/* <h6> NHS Repeat Medicatons {isLoading ? "" : `(${total})`} </h6>
                    <PlusOutlined onClick={() => this.setState({
                        visible: true
                    })} /> */}

          <div className="form-row align-items-center margin-bottom-10">
            <div className="col-md-6">
              <h6 className="font-14 weight-500 text-light-black m-0">
                Active Medication
              </h6>
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}

          {!isLoading &&
            activeMedications &&
            isArray(activeMedications) &&
            activeMedications.length > 0 && (
              <div className="prescription-array">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={activeMedications}
                  className="font-12 no_table_header border-spacing-5"
                />
              </div>
            )}

          {!isLoading &&
            activeMedications &&
            isArray(activeMedications) &&
            activeMedications.length === 0 && (
              <NoFoundMessage message="No active repeat medications found" />
            )}
          <br />

          <div className="form-row align-items-center margin-bottom-10">
            <div className="col-md-6">
              <h6 className="font-14 weight-500 text-light-black m-0">
                Past Medication
              </h6>
            </div>
          </div>
          {!isLoading &&
            pastMedications &&
            isArray(pastMedications) &&
            pastMedications.length > 0 && (
              <div className="prescription-array">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={pastMedications}
                  className="font-12 no_table_header border-spacing-5"
                />
              </div>
            )}
          {!isLoading &&
            pastMedications &&
            isArray(pastMedications) &&
            pastMedications.length === 0 && (
              <NoFoundMessage message="No past repeat medications found" />
            )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    nhsRepeatMedications: verifyObject(state, "nhsRepeatMedications", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nhsRepeatMedicationsActions: bindActionCreators(
      nhsRepeatMedicationsActions,
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(NHSContainer);

export default ConnectedComponent;

import {getDynamicQuestionnaireApi} from "../services/dynamicQuestionnaireReview";
import {listingReducer} from "reducers/utils/listingReducer";

const {reducer, actions, types} = listingReducer({
  name: `dynamicQuestionnaireReview`,
  getApi: getDynamicQuestionnaireApi,
});

const dynamicQuestionnaireReview = reducer;
export {dynamicQuestionnaireReview, actions, types};

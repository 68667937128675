import { getPoliciesApi } from "services/staffIntranet";
import { listingReducer } from "reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: `staffIntranet`,
  getApi: getPoliciesApi
});

const staffIntranet = reducer;
export { staffIntranet, actions, types };

import React from "react";

import RightToWorkContainer from "containers/RightToWork/RightToWork";
import Layout from "../../Layouts/PageLayout";
import { appRoutesConst } from "../../app/navigation";
import { Prompt } from "react-router-dom";
import {
  onRequestSetComponent,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import right_to_work from "assets/images/sidebar/ic-right-to-work.svg";

const sideBarArray = [
  {
    label: "Right To Work",
    path: appRoutesConst.rightToWork,
    icon: <img alt="Right To Work" src={right_to_work}></img>,
    key: 1,
  },
];
class RightToWorkPage extends React.Component {
  componentDidMount() {
    let { onSetSidebarKey } = this.props.dynamicTabAction;
    if (typeof onSetSidebarKey === "function") {
      onSetSidebarKey(["1"]);
    }
  }
  render() {
    return (
      <Layout title="Right To Work" sideBarArray={sideBarArray}>
        <div className="rightmainsection right_towrok_section">
          <Prompt
            message={(location, action) => {
              if (action === "PUSH") {
                let {
                  onRequestSetComponent,
                  onSetSidebarKey,
                } = this.props.dynamicTabAction;
                if (typeof onRequestSetComponent === "function") {
                  onRequestSetComponent(null);
                  onSetSidebarKey(null);
                }
                return true;
              }
              if (action === "POP") {
                let {
                  onRequestSetComponent,
                  onSetSidebarKey,
                } = this.props.dynamicTabAction;
                if (typeof onRequestSetComponent === "function") {
                  onRequestSetComponent(null);
                  onSetSidebarKey(null);
                }
                return true;
              }
              return true;
            }}
          />
          <div className="container-fluid right_wrapper p-0">
            <div className="row m-0">
              <div className="col-md-12 selection_container padding-right-10 padding-left-10">
                <RightToWorkContainer />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      { onRequestSetComponent, onSetSidebarKey },
      dispatch
    ),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(RightToWorkPage);
export default ConnectedComponent;

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actions as preConsultationReviewActions} from "../../reducers/preConsultationSubmitLogs";
import {Pagination} from "../../components/common";
import refresh from "assets/images/common/refresh.svg";
import FilterComponent from "../../components/FIlterComponent/FilterComponent";
import {preConsultationFiltersNotStartedPending} from "../../constants/filterConstants";
import {
  errorToast,
  getErrorObject,
  successToast,
  // errorToast, getErrorObject,
  verifyObject,
} from "utilities/utils";
import {navigateTo} from "app/navigation";
import {onSetPatient, onSetPatientAppointment} from "../../reducers/patients";
import PreConsultationReviewTable from "./PreConsultationReviewTable";
import {onSetDynamicInput} from "../../reducers/dynamicTab";
import PreConsultNotification from "components/PreConsult/PreConsultNotification";
import {deletePreConsult} from "services/preConsultConditions";
// import refresh from "assets/images/common/refresh.svg";
import deleteimg from "assets/images/common/delete_icon.svg";
import {isEmpty} from "lodash";
const sortingParams = [
  "patient_name",
  "corporate_name",
  "date_of_birth",
  "order_id",
  "created_at",
];
const statusArray = [
  "finished_oos",
  "finished",
  "patient_arrived",
  "patient_not_arrived",
  "cancelled",
];
class PreConsultationNotStartedPendingContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: props.status || "new",
      outComes: [],
      isAllowDelete: true,
    };
    ["_onPageChange"].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  // _onPageChange = (data) => {
  //   let { page } = data;
  //   // let { search } = this.props.scriptReview;
  //   let filters = JSON.parse(localStorage.getItem("intakedFilters"))
  //     ? JSON.parse(localStorage.getItem("intakedFilters"))
  //     : {};
  //   this.props.preConsultationReviewActions.onPageChange({
  //     status: this.state.status,
  //     page,
  //     ...filters,
  //     ...this.state.sort_params,
  //   });
  // };

  componentWillReceiveProps(nextProps) {
    let {status} = nextProps;
    this.setState({status});
  }

  _onRefresh = () => {
    localStorage.removeItem("intakedFilters");
    let {preConsultationReviewActions} = this.props;
    let {onRequest} = preConsultationReviewActions;
    if (typeof onRequest === "function") {
      let filters = JSON.parse(localStorage.getItem("intakedFilters"))
        ? JSON.parse(localStorage.getItem("intakedFilters"))
        : {};
      onRequest({
        page: 1,
        ...filters,
        status: this.props.status,
      });
    }
    let nodes = document.querySelectorAll(".push-3-l");
    nodes.forEach((node) => {
      node.classList.remove("is_active_sorting");
      node.classList.remove("sort-applied");
    });
    sortingParams.map((param) => {
      // if (param !== sort_param) {
      this.setState({
        [`dataSorted${param}`]: 0,
        [`accending${param}`]: undefined,
      });
      // }
      return null;
    });
  };

  componentDidUpdate(prevProps, nextState) {
    if (nextState.status !== this.state.status) {
      // this.props.preConsultationReviewActions.onRequest({
      //   page: 1,
      //   status: this.state.status,
      //   // ...filters,
      // });
      let nodes = document.querySelectorAll(".push-3-l");
      nodes.forEach((node) => {
        node.classList.remove("is_active_sorting");
        node.classList.remove("sort-applied");
      });
      sortingParams.map((param) => {
        // if (param !== sort_param) {
        this.setState({
          [`dataSorted${param}`]: 0,
          [`accending${param}`]: undefined,
        });
        // }
        return null;
      });
    }
  }
  saveFilters = (filters) => {
    localStorage.setItem("intakedFilters", JSON.stringify(filters));
  };

  componentWillUnmount() {
    // localStorage.removeItem("intakedFilters");
  }
  _handleNavigation = (data) => {
    console.log(
      "----------------------------ON CLICK REVIEW ------------------",
      data
    );
    this.setState({
      isVisible: true,
      patientDetails: {
        ...data.patient,
        first_name: data?.patient?.name?.split(" ")[0],
        last_name: data?.patient?.name?.split(" ")[1],
        date_of_birth: data?.patient?.dob,
        questionnaire_token: data?.token,
      },
      selecteddHealthCondition: data?.health_condition?.health_condition,
      corporate_id: data?.corporate_organization?.corporate_organization?.id,
    });
  };
  saveFilters = (filters) => {
    localStorage.setItem("previousFilters", JSON.stringify(filters));
  };
  _onPageChange(data) {
    // let filters = JSON.parse(localStorage.getItem("previousFilters"))
    //   ? JSON.parse(localStorage.getItem("previousFilters"))
    //   : {};
    // let {
    //   preConsultationReviewActions: { onPageChange ,onRequest},
    //   preConsultationReview: { search, filters },
    // } = this.props;
    // const { page } = data;
    // console.log("🚀 ~ PreConsultationNotStartedPendingContainer ~ _onPageChange ~ page:", page)
    // if (typeof onPageChange === "function") {
    //   onPageChange({
    //     page,
    //     status: this.props.prev_appt_tab_key,
    //     search,
    //     ...filters,
    //     ...this.state.sort_params,
    //     // status: this.props.status,
    //   });
    // }

    let filters = JSON.parse(localStorage.getItem("filterState"))
      ? JSON.parse(localStorage.getItem("filterState"))
      : {};
    let {
      preConsultationReviewActions: {onPageChange, onRequest},
      // preConsultationReview: {search, filters},
    } = this.props;
    const {page} = data;
    console.log("filters", filters);
    let filersParams = filters;
    let searchParam = {search: filters.search};
    // delete filersParams["page"];
    if (isEmpty(filters.search)) {
      delete filersParams["search"];
      searchParam = {};
    }

    if (typeof onPageChange === "function") {
      console.log("Page", page);
      onRequest({
        page: page,
        status: this.props.prev_appt_tab_key,
        ...searchParam,
        ...filersParams,
        // ...this.state.sort_params,
      });
      this.setState({
        toBeDeleteIds: [],
      });
    }
  }
  // _onPageChange(data) {
  //   // let filters = JSON.parse(localStorage.getItem("previousFilters"))
  //   //   ? JSON.parse(localStorage.getItem("previousFilters"))
  //   //   : {};
  //   let {
  //     preConsultationReviewActions: { onPageChange ,onRequest},
  //     preConsultationReview: { search, filters },
  //   } = this.props;
  //   const { page } = data;
  //   if (typeof onPageChange === "function") {
  //     onRequest({
  //       page,
  //       status: this.props.prev_appt_tab_key,
  //       search,
  //       ...filters,
  //       ...this.state.sort_params,
  //       // status: this.props.status,
  //     });
  //   }
  // }
  // componentWillUnmount() {
  //   localStorage.removeItem("previousFilters");
  // }
  sortRecordBy = (sort_param, id) => {
    this.setState(
      {
        [`accending${sort_param}`]:
          this.state[`accending${sort_param}`] === undefined
            ? true
            : !this.state[`accending${sort_param}`],
        [`dataSorted${sort_param}`]:
          (this.state[`dataSorted${sort_param}`]
            ? this.state[`dataSorted${sort_param}`]
            : 0) + 1,
      },
      () => {
        let filters = JSON.parse(localStorage.getItem("previousFilters"))
          ? JSON.parse(localStorage.getItem("previousFilters"))
          : {};
        let {
          preConsultationReviewActions: {onRequest},
          preConsultationReview: {search},
        } = this.props;
        let sort_params = {
          [`sort[${sort_param}]`]: this.state[`accending${sort_param}`]
            ? "ASC"
            : "DESC",
        };

        this.setState({sort_params}, () => {
          onRequest({
            ...filters,
            ...this.state.sort_params,
            search,
            page: 1,
            appointment_type: this.state.tabKey,
            // status: this.state.status,
            // search,
          });
          let element = document.getElementById(id);
          element.classList.add("is_active_sorting");

          if (this.state[`dataSorted${sort_param}`] > 1) {
            let dco = document.getElementById(id);
            if (dco) {
              if (dco.classList.contains("sort-applied")) {
                dco.classList.remove("sort-applied");
              } else {
                console.log("SORT", this.state.accending);
                // if (this.state.accending) {
                dco.classList.add("sort-applied");
                // }
              }
            }

            // this.state["dataSorted"]
          }
          sortingParams.map((param) => {
            if (param !== sort_param) {
              this.setState({
                [`dataSorted${param}`]: 0,
                [`accending${param}`]: undefined,
              });
            }
            return null;
          });

          let nodes = document.querySelectorAll(".push-3-l");
          nodes.forEach((node) => {
            if (node.classList.contains("sort-applied") && node.id !== id) {
              node.classList.remove("sort-applied");
            }
            if (
              node.classList.contains("is_active_sorting") &&
              node.id !== id
            ) {
              node.classList.remove("is_active_sorting");
            }
          });
        });
      }
    );
  };

  sortRecordBy = (sort_param, id) => {
    this.setState(
      {
        [`accending${sort_param}`]:
          this.state[`accending${sort_param}`] === undefined
            ? true
            : !this.state[`accending${sort_param}`],
        [`dataSorted${sort_param}`]:
          (this.state[`dataSorted${sort_param}`]
            ? this.state[`dataSorted${sort_param}`]
            : 0) + 1,
      },
      () => {
        let filters = JSON.parse(localStorage.getItem("intakedFilters"))
          ? JSON.parse(localStorage.getItem("intakedFilters"))
          : {};
        let {preConsultationReviewActions} = this.props;
        let {onRequest} = preConsultationReviewActions;
        let sort_params = {
          [`sort[${sort_param}]`]: this.state[`accending${sort_param}`]
            ? "ASC"
            : "DESC",
        };

        this.setState({sort_params}, () => {
          onRequest({
            ...filters,
            ...this.state.sort_params,
            page: 1,
            status: this.state.status,
            // search,
          });
          let element = document.getElementById(id);
          element.classList.add("is_active_sorting");

          if (this.state[`dataSorted${sort_param}`] > 1) {
            let dco = document.getElementById(id);
            if (dco) {
              if (dco.classList.contains("sort-applied")) {
                dco.classList.remove("sort-applied");
              } else {
                console.log("SORT", this.state.accending);
                // if (this.state.accending) {
                dco.classList.add("sort-applied");
                // }
              }
            }

            // this.state["dataSorted"]
          }
          sortingParams.map((param) => {
            if (param !== sort_param) {
              this.setState({
                [`dataSorted${param}`]: 0,
                [`accending${param}`]: undefined,
              });
            }
            return null;
          });

          let nodes = document.querySelectorAll(".push-3-l");
          nodes.forEach((node) => {
            if (node.classList.contains("sort-applied") && node.id !== id) {
              node.classList.remove("sort-applied");
            }
            if (
              node.classList.contains("is_active_sorting") &&
              node.id !== id
            ) {
              node.classList.remove("is_active_sorting");
            }
          });
        });
      }
    );
  };
  // _handleNavigation(data, fromUnderReview) {
  //   let { onSetPatient } = this.props.onSetPatient;
  //   let { onSetPatientAppointment } = this.props.onSetPatientAppointment;
  //   let { navigateTo } = this.props.navigateTo;
  //   let { patient_id } = data;

  //   if (typeof onSetPatient === "function") {
  //     onSetPatient({ id: patient_id });
  //   }
  //   store.dispatch({
  //     type: "IS_CLICKED_VIEW",
  //     payload: true,
  //   });

  //   if (typeof onSetPatientAppointment === "function") {
  //     onSetPatientAppointment(data);
  //   }

  //   if (typeof navigateTo === "function") {
  //     let routeName = appRoutesConst.patientConsultationDetail;
  //     if (fromUnderReview) {
  //       routeName = `${appRoutesConst.patientConsultationDetail}?isReview=true`;
  //     }
  //     navigateTo(routeName);
  //   }
  // }
  // sortRecordBy = async (sort_param, id) => {
  //   this.setState({ accending: !this.state.accending });
  //   let filters = JSON.parse(localStorage.getItem("intakedFilters"))
  //     ? JSON.parse(localStorage.getItem("intakedFilters"))
  //     : {};
  //   let { preConsultationReviewActions } = this.props;
  //   let { onRequest } = preConsultationReviewActions;
  //   let sort_params = {
  //     [`sort[${sort_param}]`]: this.state.accending ? "ASC" : "DESC",
  //   };
  //   this.setState({ sort_params }, () => {
  //     onRequest({
  //       ...filters,
  //       ...this.state.sort_params,
  //       status: this.state.status,
  //       // search,
  //     });
  //     let dco = document.getElementById(id);
  //     if (dco) {
  //       if (dco.classList.contains("sort-applied")) {
  //         dco.classList.remove("sort-applied");
  //       } else {
  //         dco.classList.add("sort-applied");
  //       }
  //     }
  //     let nodes = document.querySelectorAll(".push-3-l");
  //     nodes.forEach((node) => {
  //       if (node.classList.contains("sort-applied") && node.id !== id) {
  //         node.classList.remove("sort-applied");
  //       }
  //     });
  //     // this.setState({
  //     //   accending: true,
  //     // });
  //   });
  // };
  handleDeleteCheckbox = (toBeDeleteIds) => {
    this.setState({toBeDeleteIds}, () => {});
  };
  handleDelete = async () => {
    //pre_consult_ids

    this.setState(
      {
        isDeleting: true,
      },
      async () => {
        try {
          let response = await deletePreConsult({
            record_ids: this.state.toBeDeleteIds?.toString(),
            record_type: "pre_consult_submit_log",
          });
          console.log("response deletee", response);
          if (response?.data?.status === 200) {
            successToast({
              content: verifyObject(
                response,
                "data.message",
                "Deleted successfully"
              ),
            });
            this.setState(
              {
                // isAllowDelete: false,
                isDeleting: false,
                toBeDeleteIds: [],
              },
              () => {
                // this._onRefresh();
                console.log("PRE CONSULT REQ ON DELETE--");

                this.props.preConsultationReviewActions.resetFilterState();
                let filters = JSON.parse(
                  localStorage.getItem("previousFilters")
                )
                  ? JSON.parse(localStorage.getItem("previousFilters"))
                  : {};
                // console.log(
                //   "🚀 ~ PreConsultationReviewContainer ~ key:",
                //   this.state.tabKey
                // );
                // if (this.state.tabKey === "All") {
                console.log("PRE CONSULT REQ ON DELETE--2");

                this.props.preConsultationReviewActions.onFilterChange({
                  filters: {
                    ...filters,
                    status: this.state.status,
                    page: 1,
                  },
                });
                // }
                //  else {
                //   console.log("PRE CONSULT REQ ON DELETE--3");

                //   this.props.preConsultationReviewActions.onFilterChange({
                //     filters: {
                //       search_outcome: this.state.tabKey,
                //       ...filters,
                //       status: this.state.status,
                //       page: 1,
                //     },
                //   });
                // }
              }
            );
          }
        } catch (e) {
          const {message} = getErrorObject(e);
          this.setState({
            isDeleting: false,
          });
          errorToast({
            content: message,
          });
        }
      }
    );
  };

  render() {
    let {
      preConsultationReview: {data, ids, isLoading},
    } = this.props;
    return (
      <Fragment>
        <div className="form-row push-20-b push-10-t">
          <div className="col-md-4">
            <a
              onClick={this._onRefresh}
              className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8"
            >
              <img alt="" src={refresh} className="height-15"></img>{" "}
              <span className="push-2-l font-14 weight-400">Refresh</span>
            </a>
            {this.state.toBeDeleteIds?.length > 0 && (
              <Fragment>
                {this.state.isDeleting ? (
                  <a className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8 ml-3">
                    <img alt="" src={deleteimg} className="height-15"></img>{" "}
                    <span className="push-2-l font-14 weight-400">
                      Deleting ....
                    </span>
                  </a>
                ) : (
                  <a
                    onClick={this.handleDelete}
                    className="refresh_wrapper vertical-middle text-light-black in-block margin-top-8 ml-3"
                  >
                    <img alt="" src={deleteimg} className="height-15"></img>{" "}
                    <span className="push-2-l font-14 weight-400">
                      {this.state.isDeleting ? "Deleting..." : "Delete"}
                    </span>
                  </a>
                )}
              </Fragment>
            )}
          </div>
          <div className="col-md-8">
            <FilterComponent
              isClearedFilters={this.props.clear_pre_consult_filter}
              isLoading={isLoading}
              defaultRequest={{status: this.props.status}}
              onFilterChange={
                this.props.preConsultationReviewActions.onFilterChange
              }
              resetFilters={
                this.props.preConsultationReviewActions.resetFilterState
              }
              preConsultStatus={this.props.status}
              filterArray={preConsultationFiltersNotStartedPending}
              saveFilters={this.saveFilters}
              selectedFilters={
                JSON.parse(localStorage.getItem("intakedFilters"))
                  ? {
                      ...JSON.parse(localStorage.getItem("intakedFilters")),
                    }
                  : {}
              }
            />
          </div>
        </div>

        <div className="push-20-b push-10-t">
          <PreConsultationReviewTable
            statusArray={statusArray}
            data={data}
            isLoading={isLoading}
            ids={ids}
            sortRecordBy={this.sortRecordBy}
            _handleNavigation={this._handleNavigation}
            toggleThreeDots={this.toggleThreeDots}
            handleOOSAppointment={this.handleOOSAppointment}
            endAppointment={this.endAppointment}
            isAllowDelete={this.state.isAllowDelete}
            handleDeleteCheckbox={this.handleDeleteCheckbox}
            {...this.state}
            {...this.props}
            fromUnderReview={true}
          />
          {!isLoading && (
            <div className="col-md-12">
              <Pagination
                data={this.props.preConsultationReview}
                onPageChange={this._onPageChange}
              />
            </div>
          )}
        </div>
        {this.state.isVisible && (
          <PreConsultNotification
            patient_id={this.state?.patientDetails?.id}
            corporate_id={this.state.corporate_id}
            patientDetail={this.state?.patientDetails}
            patient_age={this.state?.patientDetails?.age}
            onSetPatient={this.props.onSetPatient}
            onSetPatientAppointment={this.props.onSetPatientAppointment}
            dynamicTabAction={this.props.dynamicTabAction}
            navigateTo={this.props.navigateTo}
            isVisible={this.state.isVisible}
            isFromPreConsultListing={true}
            selecteddHealthCondition={this.state?.selecteddHealthCondition}
            hideModalFromPreConsult={() => {
              this.setState({isVisible: false});
            }}
            refreshPreConsultList={this._onRefresh}
            preConsultStatus={this.props.status}
            preConsultationReviewActions={
              this.props.preConsultationReviewActions
            }
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clear_pre_consult_filter: verifyObject(
      state,
      "localStore.clear_pre_consult_filter",
      ""
    ),
    preConsultationReview: verifyObject(state, "preConsultationSubmitLogs", []),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    preConsultationReviewActions: bindActionCreators(
      preConsultationReviewActions,
      dispatch
    ),
    onSetPatientAppointment: bindActionCreators(
      {onSetPatientAppointment},
      dispatch
    ),
    onSetPatient: bindActionCreators({onSetPatient}, dispatch),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
    dynamicTabAction: bindActionCreators(
      {
        onSetDynamicInput,
      },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreConsultationNotStartedPendingContainer);

import { get, Irequest } from "./index";

export type GetSurgeriesParams = {
  search?: string,
  page?: number,
 };

export const getSurgeriesApi = (params: GetSurgeriesParams) => {
  const request: Irequest = {
    subUrl: `/surgeries/search.json`,
    params
  };
  return get(request);
};
export const getCommunitySurgeriesApi = (params: GetSurgeriesParams) => {
  const request: Irequest = {
    subUrl: `/healthya/customer_support/community_surgeries.json`,
    params,
  };
  return get(request);
};
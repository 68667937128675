import {
  createSafeguardAlertApi,
  ISafeguardAlertParams
} from "services/alerts";
import { getErrorObject, errorToast, successToast } from "utilities/utils";

export const types = {
  ON_CREATE_SAFEGUARD_MODAL_OPEN:
    "safeguardAlert/ON_CREATE_SAFEGUARD_MODAL_OPEN",
  ON_CREATE_SAFEGUARD_MODAL_CLOSE:
    "safeguardAlert/ON_CREATE_SAFEGUARD_MODAL_CLOSE",
  CREATE_SAFEGUARD_REQUEST: "safeguardAlert/CREATE_SAFEGUARD_REQUEST",
  CREATE_SAFEGUARD_SUCCESS: "safeguardAlert/CREATE_SAFEGUARD_SUCCESS",
  CREATE_SAFEGUARD_FAILURE: "safeguardAlert/CREATE_SAFEGUARD_FAILURE",
  RESET_STATE: "safeguardAlert/RESET_STATE"
};

const initialState = {
  modal: {
    isOpen: false
  },
  isLoading: false,
  error: null
};

export const safeguardAlert = (state = initialState, action) => {
  switch (action.type) {
    case types.ON_CREATE_SAFEGUARD_MODAL_OPEN: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: true
        }
      };
    }
    case types.ON_CREATE_SAFEGUARD_MODAL_CLOSE: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: false
        }
      };
    }
    case types.CREATE_SAFEGUARD_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case types.CREATE_SAFEGUARD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        modal: {
          ...state.modal,
          isOpen: false
        }
      };
    }
    case types.CREATE_SAFEGUARD_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error
      };
    }
    case types.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

const onCreateSafeguard = (params: ISafeguardAlertParams) => {
  return async (dispatch, getState) => {
    const { customerId } = getState().localStore;
    dispatch({
      type: types.CREATE_SAFEGUARD_REQUEST,
      payload: {}
    });
    try {
      await createSafeguardAlertApi({
        safeguard_alert: { patient_id: customerId, ...params.safeguard_alert }
      });
      dispatch({
        type: types.CREATE_SAFEGUARD_SUCCESS,
        payload: {}
      });
      successToast({ content: "Safeguard Alert created!" });
    } catch (error) {
      const { message } = getErrorObject(error);
      dispatch({
        type: types.CREATE_SAFEGUARD_FAILURE,
        payload: { error: message }
      });
      errorToast({ content: message });
    }
  };
};

const onOpenModal = () => {
  return {
    type: types.ON_CREATE_SAFEGUARD_MODAL_OPEN
  };
};

const onCloseModal = () => {
  return {
    type: types.ON_CREATE_SAFEGUARD_MODAL_CLOSE
  };
};

const resetState = () => {
  return {
    type: types.RESET_STATE
  };
};

export const actions = {
  onCreateSafeguard,
  onOpenModal,
  onCloseModal,
  resetState
};

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {Fragment} from "react";
import Layout from "Layouts/PageLayout";
import {connect} from "react-redux";
import {appRoutesConst} from "../../app/navigation";
import {bindActionCreators} from "redux";
import {
  onRequestSetComponent,
  onRequestSetComponentID,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import {actions as patientDetailActions} from "../../reducers/patientDetail";
import {navigateTo} from "../../app/navigation";

import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../utilities/utils";

import PatientDetailContainer from "../../containers/PatientDetailContainer/PatientDetailContainer";
import MedicalHistoryContainer from "../../containers/PatientDetailContainer/MedicalHistoryContainer/MedicalHistoryContainer";
import AllergiesContainer from "../../containers/PatientDetailContainer/AllergiesContainer/AllergiesContainer";
import AcuteMedicationsContainer from "../../containers/PatientDetailContainer/AcuteMedicationsContainer/AcuteMedicationsContainer";
import NHSContainer from "../../containers/PatientDetailContainer/NHSContainer/NHSContainer";
import FamilyRecordsContainer from "../../containers/PatientDetailContainer/FamilyRecordsContainer/FamilyRecordsContainer";
import TestInvestigationContainer from "../../containers/PatientDetailContainer/TestInvestigationContainer/TestInvestigationContainer";
import PreQuestionnaireContainer from "../../containers/PatientDetailContainer/PreQuestionnaireContainer/PreQuestionnaireContainer";
import ReportsContainer from "../../containers/PatientDetailContainer/ReportsContainer/ReportsContainer";
import SpecialNotesContainer from "../../containers/PatientDetailContainer/SpecialNotesContainer/SpecialNotesContainer";
import PreEncountersContainer from "../../containers/PatientDetailContainer/PreEncountersContainer/PreEncountersContainer";
import PrescriptionsContainer from "../../containers/PatientDetailContainer/PrescriptionsContainer/PrescriptionsContainer";
import SickNotesContainer from "../../containers/PatientDetailContainer/SickNotesContainer/SickNotesContainer";
import ReferralNotesContainer from "../../containers/PatientDetailContainer/ReferralNotesContainer/ReferralNotesContainer";
import PreConsultDetailContainer from "../../containers/PreConsultationReview/PreConsultDetailContainer";
// import { Collapse } from "antd";
import "assets/css/pages/profile-detail.css";
import topbarback from "assets/images/profile_header/topbar-back.svg";
import {Prompt, withRouter} from "react-router-dom";
import PatientConsultationDetailContainer from "../../containers/PatientConsultationConatainers/PatientConsultationContainer";
import PatientConsultationContainerForInReview from "../../containers/PatientConsultationConatainers/PatientConsultationContainerForInReview";
import {getPatientDetail} from "../../services/patientDetail";
import moment from "moment";
import ChatVideoHistoryContainer from "containers/PatientConsultationConatainers/ChatVideoHistoryContainer/ChatVideoHistoryContainer";
import PrescriptionContainer from "containers/PatientConsultationConatainers/PrescriptionContainer/PrescriptionContainer";
import SickNoteContainer from "containers/PatientConsultationConatainers/SickNoteContainer/SickNoteContainer";
import ReferralContainer from "containers/PatientConsultationConatainers/ReferralContainer/ReferralContainer";
import ScreenshotContainer from "containers/PatientConsultationConatainers/ScreenshotContainer/ScreenshotContainer";
import PastOrdersContainer from "../../containers/PatientDetailContainer/PastOrdersContainer/PastOrdersContainer";
import SortCodesContainer from "../../containers/PatientConsultationConatainers/SortCodesContainer/SortCodesContainer";

// import calender_icon from "assets/images/common/calander.svg";
// import clock_icon from "assets/images/common/time.svg";
// import card_icon from "assets/images/common/card_icon.svg";
import {isEmpty} from "lodash";
import {
  ChatHistorymenuIcon,
  DATE_FORMAT,
  HealthassessmentIcon,
  PatientdetailsIcon,
  PrescriptionsIcon,
  PreviousencounterIcon,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_CS_EXECUTIVE,
  ROLE_DOCTOR,
  ROLE_HCSW,
  ROLE_NURSE,
  ROLE_SUPERINTENDENT_PHARMACIST,
  ReferralcodemenuIcon,
  ScreenshotmenuIcon,
  // ROLE_CLINICAL_PHARMACIST,
  T24_HR_FORMAT,
  TestinvestigatemenuIcon,
} from "../../constants/common";
import CollapsiableSubHeader from "../../components/common/CollapsiableSubHeader/CollapsiableSubHeader";
import store from "../../app/store";
import Timer from "react-compound-timer";

// import patientdetails from "assets/images/sidebar/ic-patient-details.svg";
// import consultation from "assets/images/sidebar/ic-consultation.svg";
// import medicalhistory from "assets/images/sidebar/ic-medical-history.svg";
// import testinvestigation from "assets/images/sidebar/ic-test.svg";
// import allergies from "assets/images/sidebar/ic-allergies-sensitivities.svg";
// import currentmedication from "assets/images/sidebar/ic-medication-history.svg";
// import reports from "assets/images/sidebar/ic-reports.svg";
// import specialnotes from "assets/images/sidebar/ic-specialnotes.svg";
// import familyhistory from "assets/images/sidebar/ic-family-history.svg";
// import previousencounters from "assets/images/sidebar/ic-previous-encounters.svg";
// import nhs from "assets/images/sidebar/ic-nhs.svg";
// import prescription from "assets/images/sidebar/ic-prescription.svg";
// import referral from "assets/images/sidebar/ic-referral.svg";
// import sicknote from "assets/images/sidebar/ic-sicknote.svg";
// import prescreeningquestionnaire from "assets/images/sidebar/ic-prescreeningquestionnaire.svg";
// import pastorders from "assets/images/sidebar/ic-past-orders.svg";
// import screenshot from "assets/images/sidebar/ic-screenshots-uploads.svg";
// import chat from "assets/images/sidebar/ic-chat.svg";
// import { Scrollbars } from "react-custom-scrollbars";
import AppointmentReasonContainer from "../../containers/WalkinAppointment/AppointmentReasonContainer";
import HealthAssessmentContainer from "../../containers/PatientDetailContainer/HealthAssessmentContainer/HealthAssessmentContainer";
import {Button, Tooltip} from "antd";
import {
  AllergicmenuIcon,
  FamilyhistorymenuIcon,
  MediacalhistorymenuIcon,
  MedicationHistorymenuIcon,
  PreviousconsultIcon,
  SicknotemenuIcon,
  SpecialnotemenuIcon,
} from "pages/WalkinAppointments/VideoAppointmentPage";
// import healthassessment from "../../assets/images/sidebar/ic_Health Assessments_blue.svg";
// import OnlineConsultationTabContainer from "../../containers/OnlineConsultationsReviewContainer/OnlineConsultationTabContainer";
//svg icons changes
import photo_id from "assets/images/profile_header/id-card.svg";
import ImageSliderModal from "pages/WalkinAppointments/ImageSliderModal";
import {addCallNotesAPI, verifyPatientAPI} from "services/patients";

class PatientConsultationDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName:
        this.props.prev_appt_tab_key === "pending"
          ? "PreConsultDetailContainer"
          : "PatientConsultationContainer",
      patientDetail: null,
      isLoading: false,
      showInformGP: false,
      reviewDuration: 0,
    };
    [
      "_getIndexRoute",
      "_renderDynamicComponent",
      "_getPatientDetails",
      "_handleNavigation",
      "_handleBack",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  componentDidMount() {
    let {
      componentName,
      prev_appt_tab_key,
      // dynamicInputs,
      offline_call_status,
      is_gp_informed,
      // patientDetail,
      appointmentDetail,
    } = this.props;

    this._getIndexRoute(prev_appt_tab_key === "pending");
    this._getPatientDetails();
    let isConsentGiven = verifyObject(
      this.props.localStore,
      "dynamicInputs.isConsentGiven"
    )
      ? true
      : false;
    if (isConsentGiven) {
      store.dispatch({
        type: "SET_IS_GP_INFORMED",
        payload: true,
      });
    }
    if (
      !isConsentGiven &&
      !is_gp_informed &&
      prev_appt_tab_key === "pending" &&
      !offline_call_status &&
      !verifyObject(appointmentDetail, "patient.gp_service.id", false)
    ) {
      this.setState({showInformGP: true}, () => {});
    }
    this.setState({
      componentName:
        prev_appt_tab_key === "pending"
          ? "PreConsultDetailContainer"
          : componentName,
      showInformGP:
        !isConsentGiven &&
        prev_appt_tab_key === "pending" &&
        !verifyObject(appointmentDetail, "patient.gp_service.id", false),
    });
  }

  _getIndexRoute(preConsultReview) {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;

    if (typeof onRequestSetComponent === "function") {
      if (preConsultReview) {
        onRequestSetComponent("PreConsultDetailContainer");
      } else {
        onRequestSetComponent("PatientConsultationContainer");
      }
    }
    if (typeof onSetSidebarKey === "function") {
      onSetSidebarKey(["1"]);
    }
  }

  async _getPatientDetails(patient_id) {
    let {patientId, appointmentDetail} = this.props;
    try {
      store.dispatch({type: "FETCHING_PATIENT", payload: true});

      this.setState({isLoading: true});
      let response = await getPatientDetail({
        id: patient_id
          ? patient_id
          : patientId
          ? patientId
          : verifyObject(appointmentDetail, "patient_id")
          ? verifyObject(appointmentDetail, "patient_id")
          : verifyObject(appointmentDetail, "patient.id"),
      });
      let patientDetail = verifyObject(response, "data.data.patient", null);
      this.setState({patientDetail: patientDetail, isLoading: false});
      store.dispatch({
        type: "SET_PATIENT_DETAIL",
        payload: patientDetail,
      });
      store.dispatch({type: "FETCHING_PATIENT", payload: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({isLoading: false});
      store.dispatch({type: "FETCHING_PATIENT", payload: false});

      errorToast({content: message});
    }
  }

  componentWillReceiveProps(nextProps) {
    let {componentName, prev_appt_tab_key} = nextProps;
    this.setState({
      componentName:
        prev_appt_tab_key === "pending" &&
        componentName === "PatientConsultationContainer"
          ? "PreConsultDetailContainer"
          : componentName,
    });
  }

  componentDidUpdate(prevProps, nextState) {
    let {
      // dynamicInputs,
      prev_appt_tab_key,
      offline_call_status,
      // patientDetail,
      appointmentDetail,
      // is_gp_informed,
    } = this.props;

    let isConsentGiven = verifyObject(
      this.props.localStore,
      "dynamicInputs.isConsentGiven"
    )
      ? true
      : false;

    if (
      !isConsentGiven &&
      !this.state.showInformGP &&
      prev_appt_tab_key === "pending" &&
      !offline_call_status &&
      !verifyObject(appointmentDetail, "patient.gp_service.id", false)

      // &&
      // !is_gp_informed
    ) {
      this.setState({showInformGP: true}, () => {});
    }
    if (
      this.state.componentName === "PatientDetailContainer" &&
      this.state.patientDetail === null &&
      this.state.isLoading === false
    ) {
      this._getPatientDetails();
    }
  }

  _renderDynamicComponent() {
    let {componentName, patientDetail, isLoading} = this.state;
    let {isLoadingDetail} = this.props;
    const urlParams = new URLSearchParams(window.location.search);
    const isReview = urlParams.get("isReview");
    const isReviewed = urlParams.get("isReviewed");
    const preConsultReview =
      this.props.prev_appt_tab_key === "pending" ||
      urlParams.get("preConsultReview");

    switch (componentName) {
      case "PatientConsultationContainer":
        return (
          <div>
            {!preConsultReview && (
              <AppointmentReasonContainer
                isFromConsultation={true}
                prev_appt_tab_key={this.props.prev_appt_tab_key}
              />
            )}
            {isReview === "true" ? (
              <PatientConsultationContainerForInReview
                isLoadingDetail={isLoading}
                patientDetail={patientDetail}
              />
            ) : isReviewed ? (
              <PatientConsultationDetailContainer
                isLoadingDetail={isLoading}
                patientDetail={patientDetail}
              />
            ) : preConsultReview ? null : (
              <PatientConsultationDetailContainer
                isLoadingDetail={isLoading}
                patientDetail={patientDetail}
              />
            )}
          </div>
        );
      case "PreConsultDetailContainer":
        return (
          <PreConsultDetailContainer
            isLoadingDetail={isLoading}
            patientDetail={patientDetail}
          />
        );
      case "PatientDetailContainer":
        return (
          <PatientDetailContainer
            isLoadingDetail={isLoadingDetail || isLoading}
            patientDetail={patientDetail}
            _getPatientDetails={this._getPatientDetails}
            patientId={this.props.patientId}
          />
        );
      case "HealthAssessmentContainer":
        return <HealthAssessmentContainer />;
      case "MedicalHistoryContainer":
        return <MedicalHistoryContainer />;

      case "AllergiesContainer":
        return <AllergiesContainer />;

      case "AcuteMedicationsContainer":
        return <AcuteMedicationsContainer />;

      case "NHSContainer":
        return <NHSContainer />;

      case "FamilyRecordsContainer":
        return <FamilyRecordsContainer />;

      case "TestInvestigationContainer":
        return <TestInvestigationContainer />;

      case "PreQuestionnaireContainer":
        return <PreQuestionnaireContainer />;

      case "ReportsContainer":
        return <ReportsContainer />;

      case "SpecialNotesContainer":
        return <SpecialNotesContainer />;

      case "PreEncountersContainer":
        return <PreEncountersContainer />;

      case "PrescriptionsContainer":
        return <PrescriptionsContainer />;

      case "SickNotesContainer":
        return <SickNotesContainer />;

      case "ReferralNotesContainer":
        return <ReferralNotesContainer />;

      case "ChatVideoHistoryContainer":
        return <ChatVideoHistoryContainer />;

      case "PrescriptionContainer":
        return <PrescriptionContainer />;

      case "SickNoteContainer":
        return <SickNoteContainer />;

      case "ReferralContainer":
        return <ReferralContainer />;

      case "ScreenshotContainer":
        return <ScreenshotContainer />;
      case "PastOrdersContainer":
        return <PastOrdersContainer />;
      case "SortCodesContainer":
        return <SortCodesContainer />;

      default:
        return preConsultReview ? (
          <PreConsultDetailContainer
            isLoadingDetail={isLoading}
            patientDetail={patientDetail}
          />
        ) : (
          <PatientDetailContainer
            isLoadingDetail={isLoadingDetail || isLoading}
            patientDetail={patientDetail}
            _getPatientDetails={this._getPatientDetails}
            patientId={this.props.patientId}
          />
        );
    }
  }

  _handleNavigation() {
    let {navigateTo} = this.props.navigateTo;
    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.patientSearch);
    }
  }
  async _handleBack() {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    let {prev_appt_tab_key} = this.props;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent("PreviousAppointmentContainer");
    }
    //for past appointment new changes
    store.dispatch({
      type: "FROM_PAST_CONSULTATION_TRIGGER",
      payload: null,
    });

    store.dispatch({
      type: "SET_SYMPTOMP_FETCHED_LINKS",
      payload: null,
    });
    store.dispatch({
      type: "SET_DYNAMIC_INPUT",
      payload: null,
    });

    //for past appointment new changes

    if (typeof onSetSidebarKey === "function") {
      onSetSidebarKey([`APPT2`]);
      store.dispatch({
        type: "SAVE_SELECTED_KEY",
        payload: ["APPT2"],
      });
    }
    let {onRequestSetComponentID} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponentID === "function") {
      onRequestSetComponentID(2);
    }

    let {replace} = this.props.history;
    if (
      prev_appt_tab_key === "finished" ||
      prev_appt_tab_key === "unfinished"
    ) {
      store.dispatch({type: "PREV_APPT_TAB_KEY", payload: "finished"});

      replace(appRoutesConst.dashboard);
    } else if (
      prev_appt_tab_key === "reviewed" ||
      prev_appt_tab_key === "in_review"
    ) {
      replace(appRoutesConst.consultationReview);
    } else if (
      prev_appt_tab_key === "pending" ||
      prev_appt_tab_key === "pre_consult_reviewed"
    ) {
      replace(`${appRoutesConst.preconsultationReview}?pending=true`);
    } else if (prev_appt_tab_key === "kiosk_reviewed") {
      replace(`${appRoutesConst.preconsultationReview}?kiosk_reviewed=true`);
    } else {
      replace(appRoutesConst.dashboard);
    }
    // window.location.href = appRoutesConst.dashboard;
    // window.location.reload();
    // let { navigateTo } = this.props.navigateTo;
    // if (typeof navigateTo === "function") {
    //   await navigateTo(appRoutesConst.dashboard);
    // }
  }

  componentWillUnmount() {}
  _clearComponents = () => {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(null);
      onSetSidebarKey(null);
    }
  };
  renderStatus = (patientDetail) => {
    console.log("RENDER FOR STATUS----", patientDetail);
    const status = verifyObject(patientDetail, "user.status");

    switch (status) {
      case "active":
        return "Verified";
      case "unverified":
        return "Unverified";
      case "rejected":
        return "Rejected";
      default:
        return "";
    }
  };
  verifyPatientFunction = async (patient_id) => {
    try {
      this.setState({verifyingPatient: true});
      let verifyParam = {user: {status: "active"}};

      let callNoteParam = {
        patient_id,
        note: "Verification",
        note_type: "Verification",
        end_call: true,
        call_id: patient_id,
        call_type: "User",
        call_flag: 1,
      };
      const responsePatient = await verifyPatientAPI(verifyParam, patient_id);
      const responseNote = await addCallNotesAPI(callNoteParam);
      this._getPatientDetails(patient_id);
      successToast({
        content: verifyObject(
          responsePatient,
          "data.message",
          "Verified Succsess"
        ),
      });
      successToast({
        content: verifyObject(responseNote, "data.message", "Call notes saved"),
      });
      console.log("BOTH REPONSE---", responsePatient, responseNote);
      this.setState({verifyingPatient: false, visible: false});
    } catch (error) {
      this.setState({verifyingPatient: false});
      const {message} = getErrorObject(error);

      errorToast({content: message});
    }
  };
  render() {
    console.log("RENDER FOR---", this.state.patientDetail);
    let {appointmentDetail, userRole, patientDetail, prev_appt_tab_key} =
      this.props;
    let havePharmacistDesignationCondition =
      (prev_appt_tab_key === "pending" ||
        prev_appt_tab_key === "pre_consult_reviewed") &&
      ((this.props.designation &&
        this.props.designation.toLowerCase() === "pharmacist") ||
        userRole === ROLE_DOCTOR ||
        userRole === ROLE_CLINICAL_PHARMACIST)
        ? true
        : false;
    // const urlParams = new URLSearchParams(window.location.search);
    // const preConsultReview = urlParams.get("preConsultReview");
    let sidBarArray2 = [];
    let sideBarArray = [
      {
        label: "Patient Details",
        path: appRoutesConst.patientConsultationDetail,
        icon: <PatientdetailsIcon width="12" fill="757575" />,
        key: 2,
        page: "PatientDetailContainer",
        useDynamicComponent: true,
      },
      {
        label: "Summary",
        path: appRoutesConst.dynamicQuestionnaireReviewDetail,
        icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

        key: 7,
        page: "FamilyRecordsContainer",
        useDynamicComponent: true,
        hasSubmenu: true,
        submenu: [
          {
            label: "Medication History",
            path: appRoutesConst.patientConsultationDetail,
            icon: <MedicationHistorymenuIcon width="14" fill="#757575" />,
            page: "AcuteMedicationsContainer",
            key: 104,
            useDynamicComponent: true,
          },
          {
            label: "Problems / Conditions",
            path: appRoutesConst.patientConsultationDetail,
            icon: <MediacalhistorymenuIcon width="14" fill="#757575" />,
            key: 3,
            page: "MedicalHistoryContainer",
            useDynamicComponent: true,
          },
          {
            label: "Family Records",
            path: appRoutesConst.patientConsultationDetail,
            icon: <FamilyhistorymenuIcon width="14" fill="#757575" />,
            key: 7,
            page: "FamilyRecordsContainer",
            useDynamicComponent: true,
          },
          {
            label: "Allergies and Sensitivities",
            path: appRoutesConst.patientConsultationDetail,
            icon: <AllergicmenuIcon width="14" fill="#757575" />,
            page: "AllergiesContainer",
            key: 4,
            useDynamicComponent: true,
          },
          {
            label: "Test/Investigation",
            path: appRoutesConst.patientConsultationDetail,
            icon: <TestinvestigatemenuIcon width="14" fill="#757575" />,
            page: "TestInvestigationContainer",
            key: 8,
            useDynamicComponent: true,
          },
        ],
      },
      {
        label: "Health Assessments",
        path: appRoutesConst.patientConsultationDetail,
        icon: <HealthassessmentIcon width="14" fill="#757575" />,
        page: "HealthAssessmentContainer",
        key: 112,
        useDynamicComponent: true,
      },
      {
        label: "Consultations",
        path: appRoutesConst.patientConsultationDetail,
        icon: <PatientdetailsIcon width="12" fill="757575" />,
        key: 102,
        page: "PatientConsultationContainer",
        useDynamicComponent: true,
        hasSubmenu: true,
        submenu: [
          {
            label: "Consultation Detail",
            path: appRoutesConst.patientConsultationDetail,
            icon: <PreviousencounterIcon width="14" fill="#757575" />,
            key: 1,
            page:
              prev_appt_tab_key === "pending"
                ? "PreConsultDetailContainer"
                : "PatientConsultationContainer",
            useDynamicComponent: true,
          },
          {
            label: "Prescription",
            path: appRoutesConst.patientConsultationDetail,
            icon: <PrescriptionsIcon width="14" fill="#757575" />,
            page: "PrescriptionContainer",
            key: 202,
            useDynamicComponent: true,
            isDisabled:
              userRole === ROLE_SUPERINTENDENT_PHARMACIST &&
              prev_appt_tab_key !== "pending" &&
              prev_appt_tab_key !== "pre_consult_reviewed",
          },
          {
            label: "Sick Note",
            path: appRoutesConst.patientConsultationDetail,
            icon: <SicknotemenuIcon width="14" fill="#757575" />,
            page: "SickNoteContainer",
            key: 103,
            useDynamicComponent: true,
            isDisabled: userRole === ROLE_SUPERINTENDENT_PHARMACIST,
          },
          {
            label: "Referral",
            path: appRoutesConst.patientConsultationDetail,
            icon: <ReferralcodemenuIcon width="14" fill="#757575" />,
            page: "ReferralContainer",
            key: 402,
            useDynamicComponent: true,
          },
          // {
          //   label: "Reports",
          //   path: appRoutesConst.patientConsultationDetail,
          //   icon: <ReportmenuIcon width="14" fill="#757575" />,
          //   page: "ReportsContainer",
          //   key: 10891,
          //   useDynamicComponent: true,
          // },
          {
            label: "Chat / Video History",
            path: appRoutesConst.patientConsultationDetail,
            icon: <ChatHistorymenuIcon width="12px" fill="#757575" />,
            page: "ChatVideoHistoryContainer",
            key: 302,
            useDynamicComponent: true,
            isDisabled:
              verifyObject(appointmentDetail, "appointment_type", false) ===
              "face_to_face",
          },
          {
            label: "Screenshot",
            path: appRoutesConst.patientConsultationDetail,
            icon: <ScreenshotmenuIcon width="14" fill="#757575" />,
            page: "ScreenshotContainer",
            key: 5,
            useDynamicComponent: true,
          },
        ],
      },

      // {
      //   label: "Pre-Screening Questionnaire",
      //   path: appRoutesConst.patientConsultationDetail,
      //   icon: <QuestionnairemenuIcon width="14" fill="#757575" />,
      //   page: "PreQuestionnaireContainer",
      //   key: 9,
      //   useDynamicComponent: true,
      // },
      // {
      //   label: "Reports",
      //   path: appRoutesConst.patientDetail,
      //   icon: <ReportmenuIcon width="14" fill="#757575" />,
      //   page: "ReportsContainer",
      //   key: 1089,
      //   useDynamicComponent: true,
      // },
      {
        label: (
          <span>
            Special Notes
            <span className="sidebar-notification-badge">
              {patientDetail && patientDetail.special_notes_count}
            </span>
          </span>
        ),
        path: appRoutesConst.patientConsultationDetail,
        icon: <SpecialnotemenuIcon width="14" fill="#757575" />,
        page: "SpecialNotesContainer",
        key: 11,
        useDynamicComponent: true,
      },
      // {
      //   label: "Online Consultation",
      //   path: appRoutesConst.patientDetail,
      //   icon: <img alt="Past Orders" src={pastorders}></img>,
      //   page: "OnlineConsultationsPage",
      //   key: 216,
      //   useDynamicComponent: true,
      // },
      // {
      //   label: "Short Codes",
      //   path: appRoutesConst.patientDetail,
      //   icon: <PastordermenuIcon width="14" fill="#757575" />,
      //   page: "SortCodesContainer",
      //   key: 16,
      //   useDynamicComponent: true,
      // },
    ];

    if (
      appointmentDetail &&
      (verifyObject(appointmentDetail, "appointment_type", false) ===
        "face_to_face" ||
        verifyObject(appointmentDetail, "appointment_type", false) ===
          "phone_call")
    ) {
      sideBarArray = [
        {
          label: "Patient Details",
          path: appRoutesConst.patientConsultationDetail,
          icon: <PatientdetailsIcon width="12" fill="757575" />,
          key: 2,
          page: "PatientDetailContainer",
          useDynamicComponent: true,
        },
        {
          label: "Summary",
          path: appRoutesConst.dynamicQuestionnaireReviewDetail,
          icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

          key: 7,
          page: "FamilyRecordsContainer",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Medication History",
              path: appRoutesConst.patientConsultationDetail,
              icon: <MedicationHistorymenuIcon width="14" fill="#757575" />,
              page: "AcuteMedicationsContainer",
              key: 104,
              useDynamicComponent: true,
            },
            {
              label: "Problems / Conditions",
              path: appRoutesConst.patientConsultationDetail,
              icon: <MediacalhistorymenuIcon width="14" fill="#757575" />,
              key: 3,
              page: "MedicalHistoryContainer",
              useDynamicComponent: true,
            },
            {
              label: "Family Records",
              path: appRoutesConst.patientConsultationDetail,
              icon: <FamilyhistorymenuIcon width="14" fill="#757575" />,
              key: 7,
              page: "FamilyRecordsContainer",
              useDynamicComponent: true,
            },
            {
              label: "Allergies and Sensitivities",
              path: appRoutesConst.patientConsultationDetail,
              icon: <AllergicmenuIcon width="14" fill="#757575" />,
              page: "AllergiesContainer",
              key: 4,
              useDynamicComponent: true,
            },
            {
              label: "Test/Investigation",
              path: appRoutesConst.patientConsultationDetail,
              icon: <TestinvestigatemenuIcon width="14" fill="#757575" />,
              page: "TestInvestigationContainer",
              key: 8,
              useDynamicComponent: true,
            },
          ],
        },
        {
          label: "Health Assessments",
          path: appRoutesConst.patientConsultationDetail,
          icon: <HealthassessmentIcon width="14" fill="#757575" />,
          page: "HealthAssessmentContainer",
          key: 112,
          useDynamicComponent: true,
        },
        {
          label: "Consultations",
          path: appRoutesConst.patientConsultationDetail,
          icon: <PatientdetailsIcon width="12" fill="757575" />,
          key: 102,
          page: "PatientConsultationContainer",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Consultation Detail",
              path: appRoutesConst.patientConsultationDetail,
              icon: <PreviousencounterIcon width="14" fill="#757575" />,
              key: 1,
              page:
                prev_appt_tab_key === "pending"
                  ? "PreConsultDetailContainer"
                  : "PatientConsultationContainer",

              useDynamicComponent: true,
            },
            {
              label: "Prescription",
              path: appRoutesConst.patientConsultationDetail,
              icon: <PrescriptionsIcon width="14" fill="#757575" />,
              page: "PrescriptionContainer",
              key: 202,
              useDynamicComponent: true,
              isDisabled:
                userRole === ROLE_SUPERINTENDENT_PHARMACIST &&
                prev_appt_tab_key !== "pending" &&
                prev_appt_tab_key !== "pre_consult_reviewed",
            },
            {
              label: "Sick Note",
              path: appRoutesConst.patientConsultationDetail,
              icon: <SicknotemenuIcon width="14" fill="#757575" />,
              page: "SickNoteContainer",
              key: 103,
              useDynamicComponent: true,
              isDisabled: userRole === ROLE_SUPERINTENDENT_PHARMACIST,
            },
            {
              label: "Referral",
              path: appRoutesConst.patientConsultationDetail,
              icon: <ReferralcodemenuIcon width="14" fill="#757575" />,
              page: "ReferralContainer",
              key: 402,
              useDynamicComponent: true,
            },
          ],
        },

        // {
        //   label: "Pre-Screening Questionnaire",
        //   path: appRoutesConst.patientConsultationDetail,
        //   icon: <QuestionnairemenuIcon width="14" fill="#757575" />,
        //   page: "PreQuestionnaireContainer",
        //   key: 9,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Reports",
        //   path: appRoutesConst.patientDetail,
        //   icon: <ReportmenuIcon width="14" fill="#757575" />,
        //   page: "ReportsContainer",
        //   key: 1089,
        //   useDynamicComponent: true,
        // },
        {
          label: (
            <span>
              Special Notes
              <span className="sidebar-notification-badge">
                {patientDetail && patientDetail.special_notes_count}
              </span>
            </span>
          ),
          path: appRoutesConst.patientConsultationDetail,
          icon: <SpecialnotemenuIcon width="14" fill="#757575" />,
          page: "SpecialNotesContainer",
          key: 11,
          useDynamicComponent: true,
        },
        // {
        //   label: "Online Consultation",
        //   path: appRoutesConst.patientDetail,
        //   icon: <img alt="Past Orders" src={pastorders}></img>,
        //   page: "OnlineConsultationsPage",
        //   key: 216,
        //   useDynamicComponent: true,
        // },
        // {
        //   label: "Short Codes",
        //   path: appRoutesConst.patientDetail,
        //   icon: <PastordermenuIcon width="14" fill="#757575" />,
        //   page: "SortCodesContainer",
        //   key: 16,
        //   useDynamicComponent: true,
        // },
      ];
    }
    if (
      userRole === ROLE_NURSE ||
      userRole === ROLE_HCSW ||
      userRole === ROLE_CS_EXECUTIVE
    ) {
      sidBarArray2 = [];
      sideBarArray = [
        {
          label: "Patient Details",
          path: appRoutesConst.patientConsultationDetail,
          icon: <PatientdetailsIcon width="12" fill="757575" />,
          key: 2,
          page: "PatientDetailContainer",
          useDynamicComponent: true,
        },
        {
          label: "Summary",
          path: appRoutesConst.dynamicQuestionnaireReviewDetail,
          icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

          key: 7,
          page: "FamilyRecordsContainer",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Medication History",
              path: appRoutesConst.patientConsultationDetail,
              icon: <MedicationHistorymenuIcon width="14" fill="#757575" />,
              page: "AcuteMedicationsContainer",
              key: 104,
              useDynamicComponent: true,
            },
            {
              label: "Problems / Conditions",
              path: appRoutesConst.patientConsultationDetail,
              icon: <MediacalhistorymenuIcon width="14" fill="#757575" />,
              key: 3,
              page: "MedicalHistoryContainer",
              useDynamicComponent: true,
            },
            {
              label: "Family Records",
              path: appRoutesConst.patientConsultationDetail,
              icon: <FamilyhistorymenuIcon width="14" fill="#757575" />,
              key: 7,
              page: "FamilyRecordsContainer",
              useDynamicComponent: true,
            },
            {
              label: "Allergies and Sensitivities",
              path: appRoutesConst.patientConsultationDetail,
              icon: <AllergicmenuIcon width="14" fill="#757575" />,
              page: "AllergiesContainer",
              key: 4,
              useDynamicComponent: true,
            },
            {
              label: "Test/Investigation",
              path: appRoutesConst.patientConsultationDetail,
              icon: <TestinvestigatemenuIcon width="14" fill="#757575" />,
              page: "TestInvestigationContainer",
              key: 8,
              useDynamicComponent: true,
            },
          ],
        },
        {
          label: "Health Assessments",
          path: appRoutesConst.patientConsultationDetail,
          icon: <HealthassessmentIcon width="14" fill="#757575" />,
          page: "HealthAssessmentContainer",
          key: 112,
          useDynamicComponent: true,
        },
        {
          label: "Consultations",
          path: appRoutesConst.patientConsultationDetail,
          icon: <PatientdetailsIcon width="12" fill="757575" />,
          key: 102,
          page: "PatientConsultationContainer",
          useDynamicComponent: true,
          hasSubmenu: true,
          submenu: [
            {
              label: "Consultation Detail",
              path: appRoutesConst.patientConsultationDetail,
              icon: <PreviousconsultIcon width="14" fill="#757575" />,
              key: 1,
              page:
                prev_appt_tab_key === "pending"
                  ? "PreConsultDetailContainer"
                  : "PatientConsultationContainer",

              useDynamicComponent: true,
            },

            {
              label: "Chat / Video History",
              path: appRoutesConst.patientConsultationDetail,
              icon: <ChatHistorymenuIcon width="12" fill="#757575" />,
              page: "ChatVideoHistoryContainer",
              key: 302,
              useDynamicComponent: true,
              isDisabled:
                verifyObject(appointmentDetail, "appointment_type", false) ===
                "face_to_face",
            },
            {
              label: "Screenshot",
              path: appRoutesConst.patientConsultationDetail,
              icon: <ScreenshotmenuIcon width="14" fill="#757575" />,
              page: "ScreenshotContainer",
              key: 5,
              useDynamicComponent: true,
            },
          ],
        },
        // {
        //   label: "Previous Consultations",
        //   path: appRoutesConst.patientConsultationDetail,
        //   icon: <PreviousconsultIcon width="14" fill="#757575" />,
        //   key: 1002,
        //   page: "PatientConsultationContainer",
        //   useDynamicComponent: true,
        //   hasSubmenu: true,
        //   submenu: [
        //     {
        //       label: "Pre. Encounters",
        //       path: appRoutesConst.patientConsultationDetail,
        //       icon: <PreviousencounterIcon width="14" fill="#757575" />,
        //       page: "PreEncountersContainer",
        //       key: 12,
        //       useDynamicComponent: true,
        //     },
        //     {
        //       label: "Prescriptions",
        //       path: appRoutesConst.patientConsultationDetail,
        //       icon: <PrescriptionsIcon width="14" fill="#757575" />,
        //       page: "PrescriptionsContainer",
        //       key: 13,
        //       useDynamicComponent: true,
        //     },
        //     {
        //       label: "Sick Notes",
        //       path: appRoutesConst.patientConsultationDetail,
        //       icon: <SicknotemenuIcon width="14" fill="#757575" />,
        //       page: "SickNotesContainer",
        //       key: 14,
        //       useDynamicComponent: true,
        //     },
        //     {
        //       label: "Referral Notes",
        //       path: appRoutesConst.patientConsultationDetail,
        //       icon: <ReferralcodemenuIcon width="14" fill="#757575" />,
        //       page: "ReferralNotesContainer",
        //       key: 15,
        //       useDynamicComponent: true,
        //     },
        //   ],
        // },

        // {
        //   label: "Pre-Screening Questionnaire",
        //   path: appRoutesConst.patientConsultationDetail,
        //   icon: <QuestionnairemenuIcon width="14" fill="#757575" />,
        //   page: "PreQuestionnaireContainer",
        //   key: 9,
        //   useDynamicComponent: true,
        // },

        {
          label: (
            <span>
              Special Notes
              <span className="sidebar-notification-badge">
                {patientDetail && patientDetail.special_notes_count}
              </span>
            </span>
          ),
          path: appRoutesConst.patientConsultationDetail,
          icon: <SpecialnotemenuIcon width="14" fill="#757575" />,
          page: "SpecialNotesContainer",
          key: 11,
          useDynamicComponent: true,
        },

        // {
        //   label: "Past Orders",
        //   path: appRoutesConst.patientDetail,
        //   icon: <PastordermenuIcon width="14" fill="#757575" />,
        //   page: "PastOrdersContainer",
        //   key: 16,
        //   useDynamicComponent: true,
        // },
      ];
    }
    if (
      prev_appt_tab_key === "pending" ||
      prev_appt_tab_key === "pre_consult_reviewed"
    ) {
      if (!havePharmacistDesignationCondition) {
        sideBarArray = [
          {
            label: "Patient Details",
            path: appRoutesConst.patientConsultationDetail,
            icon: <PatientdetailsIcon width="12" fill="757575" />,
            key: 2,
            page: "PatientDetailContainer",
            useDynamicComponent: true,
          },
          {
            label: "Summary",
            path: appRoutesConst.dynamicQuestionnaireReviewDetail,
            icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

            key: 7,
            page: "FamilyRecordsContainer",
            useDynamicComponent: true,
            hasSubmenu: true,
            submenu: [
              {
                label: "Medication History",
                path: appRoutesConst.patientConsultationDetail,
                icon: <MedicationHistorymenuIcon width="14" fill="#757575" />,
                page: "AcuteMedicationsContainer",
                key: 104,
                useDynamicComponent: true,
              },
              {
                label: "Problems / Conditions",
                path: appRoutesConst.patientConsultationDetail,
                icon: <MediacalhistorymenuIcon width="14" fill="#757575" />,
                key: 3,
                page: "MedicalHistoryContainer",
                useDynamicComponent: true,
              },
              {
                label: "Family Records",
                path: appRoutesConst.patientConsultationDetail,
                icon: <FamilyhistorymenuIcon width="14" fill="#757575" />,
                key: 7,
                page: "FamilyRecordsContainer",
                useDynamicComponent: true,
              },
              {
                label: "Allergies and Sensitivities",
                path: appRoutesConst.patientConsultationDetail,
                icon: <AllergicmenuIcon width="14" fill="#757575" />,
                page: "AllergiesContainer",
                key: 4,
                useDynamicComponent: true,
              },
              {
                label: "Test/Investigation",
                path: appRoutesConst.patientConsultationDetail,
                icon: <TestinvestigatemenuIcon width="14" fill="#757575" />,
                page: "TestInvestigationContainer",
                key: 8,
                useDynamicComponent: true,
              },
            ],
          },
          {
            label: "Health Assessments",
            path: appRoutesConst.patientConsultationDetail,
            icon: <HealthassessmentIcon width="14" fill="#757575" />,
            page: "HealthAssessmentContainer",
            key: 112,
            useDynamicComponent: true,
          },
          {
            label: "Consultations",
            path: appRoutesConst.patientConsultationDetail,
            icon: <PatientdetailsIcon width="12" fill="757575" />,
            key: 102,
            page: "PatientConsultationContainer",
            useDynamicComponent: true,
            hasSubmenu: true,
            submenu: [
              {
                label: "Consultation Detail",
                path: appRoutesConst.patientConsultationDetail,
                icon: <PreviousencounterIcon width="14" fill="#757575" />,
                key: 1,
                page:
                  prev_appt_tab_key === "pending"
                    ? "PreConsultDetailContainer"
                    : "PatientConsultationContainer",
                useDynamicComponent: true,
              },

              {
                label: "Sick Note",
                path: appRoutesConst.patientConsultationDetail,
                icon: <SicknotemenuIcon width="14" fill="#757575" />,
                page: "SickNoteContainer",
                key: 103,
                useDynamicComponent: true,
                isDisabled: userRole === ROLE_SUPERINTENDENT_PHARMACIST,
              },
              {
                label: "Referral",
                path: appRoutesConst.patientConsultationDetail,
                icon: <ReferralcodemenuIcon width="14" fill="#757575" />,
                page: "ReferralContainer",
                key: 402,
                useDynamicComponent: true,
              },

              // {
              //   label: "Chat / Video History",
              //   path: appRoutesConst.patientConsultationDetail,
              //   icon: <ChatHistorymenuIcon width="12px" fill="#757575" />,
              //   page: "ChatVideoHistoryContainer",
              //   key: 302,
              //   useDynamicComponent: true,
              // },
              // {
              //   label: "Screenshot",
              //   path: appRoutesConst.patientConsultationDetail,
              //   icon: <ScreenshotmenuIcon width="14" fill="#757575" />,
              //   page: "ScreenshotContainer",
              //   key: 5,
              //   useDynamicComponent: true,
              // },
            ],
          },

          {
            label: (
              <span>
                Special Notes
                <span className="sidebar-notification-badge">
                  {patientDetail && patientDetail.special_notes_count}
                </span>
              </span>
            ),
            path: appRoutesConst.patientConsultationDetail,
            icon: <SpecialnotemenuIcon width="14" fill="#757575" />,
            page: "SpecialNotesContainer",
            key: 11,
            useDynamicComponent: true,
          },
        ];
      }
    }
    return (
      <Layout
        sideBarArray2={sidBarArray2}
        sideBarArray={sideBarArray}
        showInformGP={this.state.showInformGP}
      >
        <Prompt
          message={(location, action) => {
            if (action === "PUSH") {
              this._clearComponents();
              return true;
            }
            if (action === "POP") {
              // this._clearComponents();
              // this._handleBack();
              let {onRequestSetComponent, onSetSidebarKey} =
                this.props.dynamicTabAction;
              if (typeof onRequestSetComponent === "function") {
                onRequestSetComponent("PreviousAppointmentContainer");
              }

              if (typeof onSetSidebarKey === "function") {
                store.dispatch({
                  type: "PREV_APPT_TAB_KEY",
                  payload: "finished",
                });

                onSetSidebarKey([`APPT2`]);
              }
              // this.props.history.push(appRoutesConst.dashboard);
              // window.location.reload();
              return true;
            }
            return true;
          }}
        />
        <div className="rightmainsection patientconsultation">
          <div className="container-fluid padding-0">
            <div className="row margin-0">
              <div className="selection_container">
                <div className="patient_detail_header padding-1-b consultant_header">
                  <div className="patient_detail_right">
                    {window.location.pathname !==
                      appRoutesConst.patientConsultationDetail && (
                      <div className="patient_backarrow push-10-r in-block">
                        <img
                          className="cursor-pointer"
                          onClick={this._handleBack}
                          src={topbarback}
                          alt="Go Back"
                          title="Go Back"
                        ></img>
                      </div>
                    )}
                    <div className="consultant_profile push-5-r in-block">
                      {appointmentDetail &&
                      !isEmpty(appointmentDetail.patient_image) ? (
                        <img
                          src={verifyObject(
                            appointmentDetail,
                            "patient_image",
                            require("../../assets/images/common/doctor_profile.svg")
                              .default
                          )}
                          alt=""
                          className="width-30px"
                        ></img>
                      ) : (
                        <img
                          src={
                            require("../../assets/images/common/doctor_profile.svg")
                              .default
                          }
                          alt=""
                          className="width-30px"
                        ></img>
                      )}
                    </div>
                    <div className="in-block padding-right-15">
                      <div className="push-5-l d-flex flex-wrap align-items-center gapx-1">
                        <div className="font-12 weight-400 text-capitalize d-flex flex-wrap align-items-center gapx-1 text-black">
                          {" "}
                          {verifyObject(
                            patientDetail,
                            "is_child_account",
                            false
                          ) ? (
                            <Tooltip placement="top" title="Child Account">
                              <span className="custom-bluelabel font-10 in-block text-capitalize">
                                C
                              </span>
                            </Tooltip>
                          ) : null}
                          <span>{verifyObject(appointmentDetail, "booked_by", "-- ")}</span>
                          {verifyObject(patientDetail, "user.status") ? (
                            <span
                              className={`custom-${
                                verifyObject(patientDetail, "user.status") ===
                                "unverified"
                                  ? "danger"
                                  : "bluelabel"
                              } font-10 in-block text-capitalize`}
                            >
                              {this.renderStatus(patientDetail)}
                            </span>
                          ) : null}
                          <div
                            className="in-block"
                            onClick={() => {
                              this.setState({visible: true});
                            }}
                          >
                            <a className="text-black weight-400 text-capitalize photo_id font-12 cursor-pointer hover-blue">
                              <img
                                alt="Photo ID"
                                title="Photo ID"
                                src={photo_id}
                              ></img>{" "}
                              Photo ID
                            </a>
                          </div>
                        </div>
                        <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                          |
                        </div>

                          {verifyObject(
                            appointmentDetail,
                            "patient_profile.age",
                            null
                          ) !== null
                            ? <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">{appointmentDetail.patient_profile.age} Yr</div>
                            : ""}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap padding-right-15">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                        <span className="text-light-black">Gender:</span>{" "}
                        {verifyObject(patientDetail, "gender", "-")}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap padding-right-15">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                        <span className="text-light-black">DOB:</span>{" "}
                        {this.state.patientDetail &&
                        this.state.patientDetail.date_of_birth
                          ? moment(
                              this.state.patientDetail.date_of_birth
                            ).format(DATE_FORMAT)
                          : this.state.patientDetail?.user?.profile
                              ?.date_of_birth
                          ? moment(
                              this.state.patientDetail?.user?.profile
                                ?.date_of_birth
                            ).format(DATE_FORMAT)
                          : "-"}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap padding-right-15">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                        <span className="text-light-black">Corporate:</span>{" "}
                        {appointmentDetail?.corporate_name
                          ? verifyObject(
                              appointmentDetail,
                              "corporate_name",
                              ""
                            )
                          : this.state.patientDetail?.corporate_name
                          ? this.state.patientDetail?.corporate_name
                          : "-"}
                      </div>
                    </div>
                  </div>
                  <div className="patient_detail_left">
                    <div className="d-flex justify-content-between align-items-center ml-2">
                      <div className="consultleft">
                        <div className="in-block padding-right-15">
                          {window.location.pathname ===
                            appRoutesConst.patientConsultationDetail &&
                            this.props.prev_appt_tab_key !== "pending" && (
                              <Button type="danger" onClick={this._handleBack}>
                                Exit
                              </Button>
                            )}
                          {this.props.prev_appt_tab_key === "pending" && (
                            <div className="walkinheaderrt">
                              <div className="timer_counter">
                                <div className="timer-notification"></div>
                                <Timer
                                  initialTime={
                                    parseInt(this.state.reviewDuration, 10) *
                                    1000
                                  }
                                >
                                  <Timer.Minutes
                                    formatValue={(value) =>
                                      `${value < 10 ? `0${value}` : value}`
                                    }
                                  />
                                  :
                                  <Timer.Seconds
                                    formatValue={(value) =>
                                      `${value < 10 ? `0${value}` : value}`
                                    }
                                  />
                                </Timer>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="consulrightsec d-flex flex-wrap align-items-center">
                        <div className="in-block padding-right-15">
                          <p className="m-0 font-12 weight-500 d-flex flex-wrap gapx-1">
                            {/* <img
                            alt=""
                            src={calender_icon}
                            className="vertical-text-bottom"
                            width="15"
                          ></img> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              className="vertical-text-bottom"
                            >
                              <g fill="none" fill-rule="evenodd">
                                <rect
                                  width="15"
                                  height="12"
                                  x=".5"
                                  y="3.5"
                                  stroke="#1445b7"
                                  rx="2"
                                />
                                <path
                                  stroke="#1445b7"
                                  d="M.5 7.5h15V5A1.5 1.5 0 0 0 14 3.5H2A1.5 1.5 0 0 0 .5 5v2.5z"
                                />
                                <path
                                  fill="#1445b7"
                                  d="M4.5 1a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0v-2a.5.5 0 0 1 .5-.5zM11.5 1a.5.5 0 0 1 .5.5v2a.5.5 0 1 1-1 0v-2a.5.5 0 0 1 .5-.5z"
                                />
                              </g>
                            </svg>
                            {appointmentDetail?.actual_start_time
                              ? moment(
                                  verifyObject(
                                    appointmentDetail,
                                    "actual_start_time",
                                    "--"
                                  )
                                ).format(DATE_FORMAT)
                              : appointmentDetail?.start_time
                              ? moment(
                                  verifyObject(
                                    appointmentDetail,
                                    "start_time",
                                    "--"
                                  )
                                ).format(DATE_FORMAT)
                              : "-"}
                          </p>
                        </div>
                        <div className="in-block padding-right-15">
                          <p className="m-0 font-12 weight-400 d-flex flex-wrap gapx-1">
                            {/* <img
                            alt=""
                            src={clock_icon}
                            className="vertical-text-bottom"
                            width="15"
                          ></img> */}
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              className="vertical-text-bottom"
                            >
                              <g fill="none" fill-rule="evenodd">
                                <circle
                                  cx="8"
                                  cy="8"
                                  r="7.5"
                                  stroke="#1445b7"
                                />
                                <path
                                  fill="#1445b7"
                                  d="M8 8.296V5.5a.5.5 0 0 0-1 0v3c0 .032.003.064.009.094.014.132.08.258.192.346l2.373 1.86a.49.49 0 0 0 .694-.091.51.51 0 0 0-.092-.708L8 8.296z"
                                />
                              </g>
                            </svg>
                            {appointmentDetail?.actual_start_time &&
                            appointmentDetail?.actual_end_time ? (
                              <Fragment>
                                {moment(
                                  verifyObject(
                                    appointmentDetail,
                                    "actual_start_time",
                                    "--"
                                  )
                                ).format(T24_HR_FORMAT)}{" "}
                                -{" "}
                                {moment(
                                  verifyObject(
                                    appointmentDetail,
                                    "actual_end_time",
                                    "--"
                                  )
                                ).format(T24_HR_FORMAT)}
                              </Fragment>
                            ) : (
                              appointmentDetail?.start_time &&
                              appointmentDetail?.end_time && (
                                <Fragment>
                                  {moment(
                                    verifyObject(
                                      appointmentDetail,
                                      "start_time",
                                      "--"
                                    )
                                  ).format(T24_HR_FORMAT)}{" "}
                                  -{" "}
                                  {moment(
                                    verifyObject(
                                      appointmentDetail,
                                      "end_time",
                                      "--"
                                    )
                                  ).format(T24_HR_FORMAT)}
                                </Fragment>
                              )
                            )}
                          </p>
                        </div>
                        {appointmentDetail?.appointment_price && (
                          <div className="in-block padding-right-10">
                            <p className="m-0 font-12 weight-500">
                              {/* <img
                            alt=""
                            src={card_icon}
                            className="vertical-text-bottom"
                            width="15"
                          ></img> */}
                              &nbsp; £
                              {verifyObject(
                                appointmentDetail,
                                "appointment_price",
                                ""
                              )}
                            </p>
                          </div>
                        )}
                        <CollapsiableSubHeader
                          patientDetail={this.state.patientDetail}
                        />
                        <ImageSliderModal
                          verifyPatientFunction={this.verifyPatientFunction}
                          visible={this.state.visible}
                          notFromOngoingConsultation={true}
                          patientDetail={patientDetail}
                          handleCancel={() => this.setState({visible: false})}
                          verifyingPatient={this.state.verifyingPatient}
                          // checkIdentity={this._SaveComponentStateToRedux}
                          // isDocumentViewed={isDocumentViewed}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* / common patient header / */}
                {/* <div style={{ height: 700 }}>
                  <Scrollbars>{this._renderDynamicComponent()}</Scrollbars>
                </div> */}

                {this._renderDynamicComponent()}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateProps = (state) => {
  return {
    componentName: verifyObject(state.localStore, "componentName", ""),
    localStore: verifyObject(state, "localStore", ""),
    patientId: verifyObject(state.localStore, "patientId", null),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    appointmentDetail: verifyObject(state.localStore, "appointment_obj", null),
    isLoadingDetail: verifyObject(state.patientDetails, "isLoading", false),
    userRole: verifyObject(state.localStore, "user.role", null),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    offline_call_status: verifyObject(
      state,
      "localStore.offline_call_status",
      null
    ),
    is_gp_informed: verifyObject(state, "localStore.is_gp_informed", null),
    designation: verifyObject(
      state,
      "localStore.user.profile.designation",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {onRequestSetComponent, onRequestSetComponentID, onSetSidebarKey},
      dispatch
    ),
    patientDetailActions: bindActionCreators(patientDetailActions, dispatch),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(PatientConsultationDetailPage);

export default withRouter(ConnectedComponent);

import { get, post, patch, deletee } from "./index";

export const getTimeSlots = (params) => {
  const request = {
    subUrl: `/time_slots/doctor_time_slots.json`,
    params,
  };
  return get(request);
};

export const getTimeSlotsForDoctor = (params) => {
  const request = {
    subUrl: `/doctor/appointments/slot_for_clinicians.json`,
    params,
  };
  return get(request);
};
export const getTimeSlotsForCorp = (params) => {
  const request = {
    subUrl: `/patients/time_slots/get_corporate_slots`,
    params,
  };
  return get(request);
};
// selectedAptType

export const addRotaAPI = (data) => {
  const request = {
    subUrl: `/time_slots.json`,
    data,
  };
  return post(request);
};

export const addMultipleRotaApi = (params) => {
  const request = {
    subUrl: `/time_slots/create_time_slot_by_cs.json`,
    data: params,
  };

  return post(request);
};

export const updateRotaAPI = (data) => {
  const request = {
    subUrl: `/time_slots/${data.time_slot.id}.json`,
    data,
  };
  return patch(request);
};

export const deleteRotaAPI = (data) => {
  const request = {
    subUrl: `/time_slots/${data.id}.json`,
    data,
  };
  return deletee(request);
};

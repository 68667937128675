/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/alt-text */
import React, {Component, Fragment} from "react";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";
import {bindActionCreators} from "redux";
import {sendAppointmentInviteAPI} from "../../services/appointments";

import {connect} from "react-redux";
import {upcomingAppointmentsActions} from "../../reducers/appointments";
import {groupBy, isEmpty, uniqueId} from "lodash";
import moment from "moment";
import {Pagination} from "../../components/common";
import CommonLoader from "../../components/common/CommonLoader";
import NoFoundMessage from "../../components/common/NoFoundMessage";
import {onPatientSelect} from "reducers/patients";
import {onRequestSetVideoCallSetup} from "../../reducers/videoCall";
import {Popover} from "antd";
import three_vertical_dots from "assets/images/common/payment_option.svg";
import {Menu, Dropdown} from "antd";
import LightBoxComponent from "../../components/common/LightBoxComponent";
import {onSetPatient, onSetPatientAppointment} from "reducers/patients";
import {navigateTo, appRoutesConst} from "app/navigation";
import {actions as actionCableActions} from "reducers/actionCableData";
import {
  cancelAppointmentAPI,
  updateAppointmentStatusAPI,
  markAsOOSAppointment,
} from "../../services/appointments";
import store from "../../app/store";
import FilterComponent from "../../components/FIlterComponent/FilterComponent";
import {upcomingApptFilters} from "../../constants/filterConstants";
import refresh from "assets/images/common/refresh.svg";
import ascendingsort from "assets/images/common/ascending-sort.svg";
import {
  APPT_TYPE_VIDEO,
  APPT_TYPE_WALKIN,
  DATE_FORMAT,
  T24_HR_FORMAT,
} from "../../constants/common";
import {
  Dropdown as DropdownEmdr,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import MembersContactTable from "containers/MemebersContact/MembersContactTable";
// import {UpOutlined, DownOutlined} from "@ant-design/icons";
import {actions as MembersContactActions} from "../../reducers/MembersContacts";
import {Checkbox} from "antd";
import ModalPopUp from "components/common/ModalPopUp";
import {IconThreeDotsVertical} from "assets/icon/IconThreeDotsVertical";

const sortingParams = [
  "patient_name",
  "corporate_name",
  "date_of_birth",
  "order_id",
  "created_at",
];
const statusArray = [
  "finished_oos",
  "finished",
  "patient_arrived",
  "patient_not_arrived",
  "cancelled",
];
const Prevtextover = ({text}) => (
  <div className="view_popover">
    <span className="font-12 weight-400 margin-0 text-black inline-block">
      {text}
    </span>
  </div>
);
class UpcomingAppointmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataArray: [],
      visible: false,
      accending: true,
      selectedIds: [],
      selectedAll: false,
      showNotificationModal: false,
      loadingSMS: false,
      loading: false,
      isCancellingAppointment: false,
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleNavigation",
      "_goToCallSetupScreen",
      // "_handleSearch"
    ].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  componentDidMount() {
    // this._onRequestList();
    // if(window.performance.navigation.type === performance.navigation.TYPE_RELOAD){
    //   localStorage.removeItem("upcomingFilters")
    // }
    store.dispatch({
      type: "SET_SYMPTOMP_FETCHED_LINKS",
      payload: null,
    });
    // this._onRequestList();
  }

  _onRequestList() {
    console.log(
      "isAppointmentFilterApplied",
      this.props.isAppointmentFilterApplied
    );
    let {upcomingAppointmentsActions} = this.props;
    let {onRequest} = upcomingAppointmentsActions;
    if (typeof onRequest === "function") {
      let filters = JSON.parse(localStorage.getItem("upcomingFilters"))
        ? JSON.parse(localStorage.getItem("upcomingFilters"))
        : {};
      onRequest({page: 1, ...filters});
    }
  }

  _onRefresh = () => {
    localStorage.removeItem("upcomingFilters");
    let {upcomingAppointmentsActions} = this.props;
    let {onRequest} = upcomingAppointmentsActions;
    if (typeof onRequest === "function") {
      onRequest({page: 1});
    }
    let nodes = document.querySelectorAll(".push-3-l");
    nodes.forEach((node) => {
      node.classList.remove("is_active_sorting");
      node.classList.remove("sort-applied");
    });
    sortingParams.map((param) => {
      // if (param !== sort_param) {
      this.setState({
        [`dataSorted${param}`]: 0,
        [`accending${param}`]: undefined,
      });
      // }
      return null;
    });
  };

  _onPageChange(data) {
    // let filters = JSON.parse(localStorage.getItem("upcomingFilters"))
    //   ? JSON.parse(localStorage.getItem("upcomingFilters"))
    //   : {};
    let {
      upcomingAppointmentsActions: {onPageChange},
      upcomingAppointments: {search, filters},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page, search, ...filters, ...this.state.sort_params});
    }
  }

  sortRecordBy = (sort_param, id) => {
    this.setState(
      {
        [`accending${sort_param}`]:
          this.state[`accending${sort_param}`] === undefined
            ? true
            : !this.state[`accending${sort_param}`],
        [`dataSorted${sort_param}`]:
          (this.state[`dataSorted${sort_param}`]
            ? this.state[`dataSorted${sort_param}`]
            : 0) + 1,
      },
      () => {
        let filters = JSON.parse(localStorage.getItem("upcomingFilters"))
          ? JSON.parse(localStorage.getItem("upcomingFilters"))
          : {};
        let {
          upcomingAppointmentsActions: {onRequest},
          upcomingAppointments: {search},
        } = this.props;
        let sort_params = {
          [`sort[${sort_param}]`]: this.state[`accending${sort_param}`]
            ? "ASC"
            : "DESC",
        };

        this.setState({sort_params}, () => {
          onRequest({
            ...filters,
            ...this.state.sort_params,
            search,
            page: 1,
            // status: this.state.status,
            // search,
          });
          let element = document.getElementById(id);
          element.classList.add("is_active_sorting");

          if (this.state[`dataSorted${sort_param}`] > 1) {
            let dco = document.getElementById(id);
            if (dco) {
              if (dco.classList.contains("sort-applied")) {
                dco.classList.remove("sort-applied");
              } else {
                console.log("SORT", this.state.accending);
                // if (this.state.accending) {
                dco.classList.add("sort-applied");
                // }
              }
            }

            // this.state["dataSorted"]
          }
          sortingParams.map((param) => {
            if (param !== sort_param) {
              this.setState({
                [`dataSorted${param}`]: 0,
                [`accending${param}`]: undefined,
              });
            }
            return null;
          });

          let nodes = document.querySelectorAll(".push-3-l");
          nodes.forEach((node) => {
            if (node.classList.contains("sort-applied") && node.id !== id) {
              node.classList.remove("sort-applied");
            }
            if (
              node.classList.contains("is_active_sorting") &&
              node.id !== id
            ) {
              node.classList.remove("is_active_sorting");
            }
          });
        });
      }
    );
  };
  // sortRecordBy = (sort_param, id) => {
  //   this.setState({ accending: !this.state.accending });
  //   let filters = JSON.parse(localStorage.getItem("upcomingFilters"))
  //     ? JSON.parse(localStorage.getItem("upcomingFilters"))
  //     : {};
  //   let {
  //     upcomingAppointmentsActions: { onRequest },
  //     upcomingAppointments: { search },
  //   } = this.props;
  //   // console.log("PAGE", this.props);
  //   let sort_params = {
  //     [`sort[${sort_param}]`]: this.state.accending ? "ASC" : "DESC",
  //   };
  //   this.setState({ sort_params }, () => {
  //     onRequest({
  //       ...filters,
  //       ...this.state.sort_params,
  //       search,
  //     });
  //     console.log("ID", id);

  //     let dco = document.getElementById(id);
  //     if (dco) {
  //       if (dco.classList.contains("sort-applied")) {
  //         dco.classList.remove("sort-applied");
  //       } else {
  //         dco.classList.add("sort-applied");
  //       }
  //     }
  //     let nodes = document.querySelectorAll(".push-3-l");
  //     nodes.forEach((node) => {
  //       if (node.classList.contains("sort-applied") && node.id !== id) {
  //         node.classList.remove("sort-applied");
  //       }
  //     });
  //   });
  // };

  // _handleNavigation(id) {
  //   let { onPatientSelect } = this.props.onPatientSelect;
  //   if (typeof onPatientSelect === "function") {
  //     onPatientSelect({ id });
  //   }
  // }
  _handleNavigation(data, forf2f) {
    let {onSetPatient} = this.props.onSetPatient;
    let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
    let {navigateTo} = this.props.navigateTo;
    let {patient_id} = data;

    if (typeof onSetPatient === "function") {
      onSetPatient({id: patient_id});
    }

    if (typeof onSetPatientAppointment === "function") {
      onSetPatientAppointment(data);
    }
    if (data.appointment_type === APPT_TYPE_WALKIN) {
      store.dispatch({
        type: "SET_OFFLINE_CALL_STATUS",
        payload: "start",
      });
      if (
        verifyObject(data, "inform_gp_request.inform_gp_request", null) &&
        !isEmpty(data.inform_gp_request.inform_gp_request)
      ) {
        store.dispatch({
          type: "SET_IS_GP_INFORMED",
          payload: true,
        });
      }
    }

    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.walkinAppointment);
    }
  }

  _goToCallSetupScreen(data) {
    let {onSetPatient} = this.props.onSetPatient;
    let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
    let {navigateTo} = this.props.navigateTo;
    let {onRequestSetVideoCallSetup} = this.props.videoCallActions;
    let {patient_id, pre_consult_id} = data;
    // let { waitingRoomChannle } = this.props.actionCableActions;

    if (typeof onSetPatient === "function") {
      onSetPatient({id: patient_id});
    }

    if (typeof onSetPatientAppointment === "function") {
      onSetPatientAppointment(data);
    }

    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.callSetup);
    }
    if (typeof onRequestSetVideoCallSetup === "function") {
      onRequestSetVideoCallSetup(true);
    }

    store.dispatch({
      type: "actionCable/REFRESH_APPOINTMENTS_CLEAR",
    });
    store.dispatch({
      type: "IS_VIDEO_CALL_STARTED",
      payload: false,
    });
    store.dispatch({
      type: "ON_CALL_AGAIN",
      payload: false,
    });
    store.dispatch({
      type: "ON_SET_PRECONSULT_ID",
      payload: pre_consult_id,
    });
  }

  _handleNavigation2 = (data) => {
    let {onSetPatient} = this.props.onSetPatient;
    let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
    let {navigateTo} = this.props.navigateTo;
    let {patient_id} = data;

    if (typeof onSetPatient === "function") {
      onSetPatient({id: patient_id});
      store.dispatch({type: "ON_CLICK_NAME_UPCOMING", payload: true});
    }

    if (typeof onSetPatientAppointment === "function") {
      onSetPatientAppointment(data);
    }

    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.patientDetail);
    }
  };

  renderMenu = (id, item) => {
    return (
      <Menu className="width-190 dropdown_appointment-walkin_wrapper">
        <a
          onClick={() => this.updateAppointmentStatus("patient_arrived", id)}
          className="font-12 margin-0"
        >
          <Menu.Item key="0">Arrive</Menu.Item>
        </a>

        {item.activate_waiting_room ? (
          <a
            onClick={() =>
              this.updateAppointmentStatus("patient_not_arrived", id)
            }
            className="font-12 margin-0"
          >
            {" "}
            <Menu.Item key="1">DNA</Menu.Item>
          </a>
        ) : null}
        {!item.activate_waiting_room ? (
          <a
            onClick={(e) => {
              e.preventDefault();
              this.cancelAppointment(id);
            }}
            href="#"
            className="font-12 margin-0"
          >
            <Menu.Item key="2">Cancel</Menu.Item>
          </a>
        ) : null}
      </Menu>
    );
  };
  toggleThreeDots = (id) => {
    this.setState((prevState) => {
      return {[`isShow${id}`]: !this.state[`isShow${id}`]};
    });
  };
  updateAppointmentStatus = async (status, id) => {
    try {
      await updateAppointmentStatusAPI({
        id: id,
        appointment: {
          status: status,
        },
      });
      await this._onRequestList();
    } catch (error) {
      const {message} = getErrorObject(error);
      errorToast({content: message});
    }
  };

  onCheckIndividual = (e, id) => {
    let checked = e.target.checked;
    if (checked) {
      this.setState({selectedIds: [...this.state.selectedIds, id]});
    } else {
      this.setState({selectedIds: []});
    }
  };
  cancelAppointment = async (id) => {
    try {
      this.setState({isCancellingAppointment: true});
      let response = await cancelAppointmentAPI({
        id: id,
      });
      successToast({
        content: verifyObject(
          response,
          "data.message",
          "Cancelled successfully"
        ),
      });
      this.setState({isCancellingAppointment: false});

      this._onRequestList();
    } catch (error) {
      const {message} = getErrorObject(error);
      errorToast({content: message});
    }
  };
  saveFilters = (filters) => {
    localStorage.setItem("upcomingFilters", JSON.stringify(filters));
  };
  componentWillUnmount() {
    // localStorage.removeItem("upcomingFilters");
  }

  handleExpandMenu = (appointmentId) => {
    this._onRequestMemberList();
  };
  handleSendInvite = async (inviteType) => {
    let {
      upcomingAppointments: {filters},
    } = this.props;
    if (inviteType === "email") {
      this.setState({loading: true});
    } else {
      this.setState({loadingSMS: true});
    }
    try {
      let filtersPrams = {};
      let filteredOrManualParams = {
        record_type: "manual",
      };
      if (Object.keys(filters).length > 0) {
        filtersPrams = {...filters};
      }
      if (Object.keys(filters).length > 0) {
        filteredOrManualParams = {
          record_type: "filtered",
        };
      }
      let payload = {
        ids: this.state.selectedIds.toString(),
        notification_type: inviteType,
        ...filteredOrManualParams,
        ...filtersPrams,
      };
      console.log("PAYLOAD", payload);
      let response = await sendAppointmentInviteAPI(payload);
      await successToast({content: response.data.message});

      if (inviteType === "email") {
        this.setState({loading: false});
      } else {
        this.setState({loadingSMS: false});
      }
      this.setState({
        showNotificationModal: false,
        selectedIds: [],
        selectedAll: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      if (inviteType === "email") {
        this.setState({loading: false});
      } else {
        this.setState({loadingSMS: false});
      }
      this.setState({
        showNotificationModal: false,
        selectedIds: [],
        selectedAll: false,
      });
      errorToast({content: message});
    }
  };
  setNotificatioModal = (v) => {
    this.setState({showNotificationModal: v});
  };
  _onRequestMemberList = (id) => {
    let {
      MembersContactActions: {onRequest},
    } = this.props;
    console.log("Appointment ID", this.props.appointmentId);
    if (typeof onRequest === "function") {
      onRequest({page: 1, appointment_id: this.state.actionId});
    }
  };

  handleOOSAppointment = async (id) => {
    try {
      this.setState({loading: true});
      const response = await markAsOOSAppointment({id: id});
      if (response.data.status === 200) {
        successToast({content: response.data.message});
        await this._onRequestList();
        this.setState({loading: false});
      }
    } catch (e) {
      errorToast({
        content: e.data.message,
      });
      this.setState({loading: false});
    }
  };

  renderDropDownEMDR = (d, id) => {
    if (d.appointment_type === APPT_TYPE_VIDEO) {
      if (
        d.is_emdr_session !== true &&
        this.props.user &&
        this.props.user.has_emdr_access
      ) {
        return (
          <DropdownEmdr
            isOpen={this.state[`isShow${id}`]}
            toggle={() => this.toggleThreeDots(id)}
            className="text-right in-block"
          >
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              //className="font-14 text-gray text-center width-40-p d-flex cursor-pointer action-dots"
              // aria-expanded={dropdownOpen}
            >
              <button
                className="padding-0 d-flex no-background border-0 no-focus"
                onClick={(e) => e.preventDefault()}
              >
                <IconThreeDotsVertical className="icon-three-dots-vertical" />
              </button>
            </DropdownToggle>
            <DropdownMenu
              right
              className="width-115px font-12 p-0-imp border border-radius-0-imp DropdownEmdr_is_emdr_session_wrapper"
            >
              {/* {d.is_emdr_session !== true && this.props.user.has_emdr_access && (
                <DropdownItem
                  onClick={() => this.handlemarkEMDRsession(d.id)}
                  className="font-12 margin-0 padding-6-imp weight-400"
                >
                  {d.is_emdr_session !== true && "Enable EMDR session"}
                </DropdownItem>
              )} */}
              {d.activate_waiting_room === true &&
                statusArray.includes(d.status) === false && (
                  <DropdownItem
                    onClick={() => this.handleOOSAppointment(d.id)}
                    className="font-12 margin-0 padding-6-imp weight-400"
                  >
                    {"Mark As OOS"}
                  </DropdownItem>
                )}
            </DropdownMenu>
          </DropdownEmdr>
        );
      } else if (d.activate_waiting_room === true) {
        return (
          <DropdownEmdr
            isOpen={this.state[`isShow${id}`]}
            toggle={() => this.toggleThreeDots(id)}
            className="text-right in-block"
          >
            <DropdownToggle
              tag="span"
              data-toggle="dropdown"
              //className="font-14 text-gray text-center width-40-p d-flex cursor-pointer action-dots"
              // aria-expanded={dropdownOpen}
            >
              <button
                className="padding-0 d-flex no-background border-0 no-focus"
                onClick={(e) => e.preventDefault()}
              >
                <IconThreeDotsVertical className="icon-three-dots-vertical" />
              </button>
            </DropdownToggle>
            <DropdownMenu
              right
              className="width-115px font-12 p-0-imp border border-radius-0-imp DropdownEmdr_activate_waiting_room_wrapper"
            >
              {statusArray.includes(d.status) === false && (
                <DropdownItem
                  onClick={() => this.handleOOSAppointment(d.id)}
                  className="font-12 margin-0 padding-6-imp weight-400"
                >
                  {"Mark As OOS"}
                </DropdownItem>
              )}
            </DropdownMenu>
          </DropdownEmdr>
        );
      } else {
        return <span></span>;
      }
    }
  };

  render() {
    let {
      upcomingAppointments: {data, ids, isLoading, filters},
    } = this.props;
    // let { visible } = this.state
    console.log("DATA====>", ids);
    let arr1 = [];
    ids.map((id) => {
      arr1.push(data[id]);
      return null;
    });
    // Object.keys(data).map((key) => {
    // 	arr1.push(data[key])
    // 	return null
    // })

    let groupedArray = groupBy(arr1, (d) =>
      moment(d.start_time).format(DATE_FORMAT)
    );

    //changes
    return (
      <div className="push-10-t">
        <div className="upcoming_appt_list margin-bottom-15">
          <div className="form-row push-20-b">
            <div className="col-md-4">
              <div className="d-flex align-items-center margin-top-8">
                <a
                  // href="javascript:;"
                  onClick={this._onRefresh}
                  className="refresh_wrapper text-light-black d-flex align-items-center"
                >
                  <img
                    src={refresh}
                    className="height-15 mr-1"
                    alt="Refresh"
                    title="Refresh"
                  ></img>
                  <span className="push-2-l font-14 weight-400">Refresh</span>
                </a>
                <div className="commoncheckbox ml-2">
                  {/* {Object.keys(filters).length > 0 && ids.length > 0 && (
                    <Checkbox
                      checked={this.state.selectedAll}
                      onChange={this.onCheckAll}
                      className="vertical-middle in-block margin-left-15-imp"
                    >
                      Select All
                    </Checkbox>
                  )} */}
                </div>
              </div>
            </div>
            <div className="col-md-8">
              <div className="dis-flex align-items-center justify-content-end with_invitebtn">
                {this.state.selectedIds.length !== 0 && ids.length !== 0 && (
                  <button
                    style={{
                      marginRight: 10,
                    }}
                    onClick={() => {
                      this.setNotificatioModal(true);
                      // Modal.confirm({
                      //   title:"Send invite via email or sms"?
                      // })
                    }}
                    className="arrive_btn btn-height-32 btn btn-blue btn-custom font-12-imp tablet-font-10 weight-500 text-white"
                  >
                    Send Invite
                  </button>
                )}
                <FilterComponent
                  isLoading={isLoading}
                  defaultRequest={{}}
                  dataArray={ids}
                  onFilterChange={
                    this.props.upcomingAppointmentsActions.onFilterChange
                  }
                  resetFilters={
                    this.props.upcomingAppointmentsActions.resetFilterState
                  }
                  filter_report_type="UpcomingAppointment"
                  filterArray={upcomingApptFilters}
                  saveFilters={this.saveFilters}
                  selectedFilters={
                    JSON.parse(localStorage.getItem("upcomingFilters"))
                      ? {
                          ...JSON.parse(
                            localStorage.getItem("upcomingFilters")
                          ),
                        }
                      : {}
                  }
                  storedFilters={this.props.storedFilters}
                />
                {/* )} */}
                {/* {JSON.parse(localStorage.getItem("upcomingFilters")) === null && (
                <FilterComponent
                  isLoading={isLoading}
                  defaultRequest={{}}
                  dataArray={ids}
                  onFilterChange={
                    this.props.upcomingAppointmentsActions.onFilterChange
                  }
                  resetFilters={
                    this.props.upcomingAppointmentsActions.resetFilterState
                  }
                  filter_report_type="Appointment"
                  filterArray={upcomingApptFilters}
                  saveFilters={this.saveFilters}
                  selectedFilters={{}}
                />
              )} */}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <div className="tableheader">
              <table className="w-100 upcoming_booking_tableheader relative bg-gray border table-responsive-1100">
                <thead>
                  <tr>
                    <th className="pl-5 [w-250] font-12 padding-10-t padding-10-b text-light-black padding-left-10 text-left">
                      Patient Name{" "}
                      <img
                        src={ascendingsort}
                        className="push-3-l cursor-pointer sort-icon"
                        id="1"
                        alt="Ascending Sort"
                        title="Ascending Sort "
                        onClick={() => this.sortRecordBy("patient_name", "1")}
                      ></img>
                    </th>
                    <th className="[w-180] font-12 padding-10-t padding-10-b text-light-black padding-left-10 text-left">
                      Corporate{" "}
                      <img
                        src={ascendingsort}
                        className="push-3-l cursor-pointer sort-icon"
                        alt="Ascending Sort"
                        id="2"
                        title=""
                        onClick={() => this.sortRecordBy("corporate_name", "2")}
                      ></img>
                    </th>
                    <th className="[w-80] font-12 padding-10-t padding-10-b text-light-black padding-left-10 text-left">
                      Gender{" "}
                      {/* <img
                      src={ascendingsort}
                      className="push-3-l"
                      alt="Ascending Sort"
                      id="3"
                      title=''
                    ></img> */}
                    </th>
                    <th className="[w-150] font-12 padding-10-t padding-10-b text-light-black padding-left-10 text-left ">
                      DOB{" "}
                      <img
                        src={ascendingsort}
                        className="push-3-l cursor-pointer sort-icon"
                        alt="Ascending Sort"
                        id="4"
                        title=""
                        onClick={() => this.sortRecordBy("date_of_birth", "4")}
                      ></img>
                    </th>
                    <th className="[w-150] font-12 padding-10-t padding-10-b text-light-black ellipsis padding-left-10 text-left">
                      Reason for Call{" "}
                      {/* <img
                      src={ascendingsort}
                      className="push-3-l"
                      alt="Ascending Sort"
                      id="5"
                      title=''
                    ></img> */}
                    </th>
                    <th className="[w-150] font-12 padding-10-t padding-10-b text-light-black padding-left-10 text-left">
                      Date{" "}
                      <img
                        src={ascendingsort}
                        className="push-3-l cursor-pointer sort-icon"
                        alt="Ascending Sort "
                        id="6"
                        title=""
                        onClick={() => this.sortRecordBy("created_at", "6")}
                      ></img>
                    </th>
                    <th className="[w-150] font-12 padding-10-t padding-10-b text-light-black padding-left-10 text-left">
                      Time{" "}
                      {/* <img
                      src={ascendingsort}
                      className="push-3-l"
                      alt="Ascending Sort"
                      id="7"
                      title=''
                    ></img> */}
                    </th>
                    <th className="[w-150] font-12 padding-left-10 padding-10-t padding-10-b text-light-black">
                      Action
                    </th>
                    <th className="[w-25] font-12 padding-10-t padding-10-b text-light-black text-right vertical-top"></th>
                  </tr>
                  <tr>
                    <td className="text-right padding-right-10"></td>
                  </tr>
                </thead>
              </table>
            </div>

            {(isLoading || this.state.isCancellingAppointment) && (
              <CommonLoader />
            )}
            {!isLoading &&
              !this.state.isCancellingAppointment &&
              ids &&
              ids.length === 0 && (
                <NoFoundMessage message="No upcoming appointments available" />
              )}

            {!isLoading &&
              !this.state.isCancellingAppointment &&
              Object.keys(groupedArray).length !== 0 &&
              Object.keys(groupedArray).map((key) => {
                return (
                  <Fragment key={key}>
                    <div className="upcoming_appt_header push-5-b push-5-t">
                      <p className="margin-0 normal-lineheight font-11 text-light-black weight-400">
                        {moment(
                          verifyObject(groupedArray[key][0], "start_time", "")
                        ).format(DATE_FORMAT)}
                      </p>
                    </div>
                    <div className="upcoming_appt_data mb-2">
                      {groupedArray[key].map((d) => {
                        return (
                          <table
                            key={uniqueId("UPCOMING")}
                            className="w-100 upcoming_booking_table relative table-responsive-1100"
                          >
                            <tbody>
                              <tr>
                                {/* {d && d.book_via && d.book_via === "ad_hoc" ? (
                                  <td className="padding-left-10 md-td-2 lg-td-2">
                                    <Checkbox
                                      onChange={(e) =>
                                        this.onCheckIndividual(e, d.id)
                                      }
                                      checked={this.state.selectedIds.includes(
                                        d.id
                                      )}
                                    />
                                  </td>
                                ) : null} */}
                                <td className="text-left padding-left-10 [w-250]">
                                  <div className="d-flex report-user align-items-center">
                                    <div className="up_doctor_profile in-block vertical-middle">
                                      {d.patient_image ? (
                                        <img alt="" src={d.patient_image}></img>
                                      ) : (
                                        <img
                                          alt=""
                                          src={
                                            require("../../assets/images/common/doctor_profile.svg")
                                              .default
                                          }
                                        ></img>
                                      )}
                                    </div>
                                    <p
                                      onClick={() => this._handleNavigation2(d)}
                                      className="padding-left-10 text-break cursor-pointer font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block custom-text-width"
                                    >
                                      {d.booked_by}
                                    </p>
                                    &nbsp;
                                    <span className="ant-tag ant-tag-blue margin-right-0-imp">
                                      {d.appointment_type === "face_to_face"
                                        ? "Walk-In"
                                        : d.appointment_type === "phone_call"
                                        ? "Phone"
                                        : "Video"}
                                    </span>
                                  </div>
                                </td>
                                <td className="text-left padding-left-10 [w-180]">
                                  <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                                    {verifyObject(d, "corporate_name", "-")}
                                  </p>
                                </td>
                                <td className="text-left padding-left-10 [w-80]">
                                  <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                                    {verifyObject(
                                      d,
                                      "patient_profile.gender",
                                      ""
                                    ) === "male" && "M"}
                                    {verifyObject(
                                      d,
                                      "patient_profile.gender",
                                      ""
                                    ) === "female" && "F"}
                                    {verifyObject(
                                      d,
                                      "patient_profile.gender",
                                      ""
                                    ) === "" && "-"}
                                  </p>
                                </td>
                                <td className="text-left padding-left-10 [w-150]">
                                  <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                                    {/* 11 Jun 2020 */}
                                    {d.patient_profile &&
                                      d.patient_profile.date_of_birth &&
                                      moment(
                                        d.patient_profile.date_of_birth
                                      ).format(DATE_FORMAT)}
                                    {/* {verifyObject(
                                      d,
                                      "patient_profile.date_of_birth",
                                      "-"
                                    )} */}
                                  </p>
                                </td>
                                <td className="text-left padding-left-10 [w-150] ellipsis">
                                  {d.reason_image ? (
                                    <a
                                      className="p-0 view_btn border-0 font-12 text-link-blue weight-400 text-underline text-decoration-hover text-center"
                                      onClick={() =>
                                        this.setState({
                                          [`visible${d.id}`]: true,
                                        })
                                      }
                                    >
                                      View
                                    </a>
                                  ) : (
                                    <span className="lb-p">-</span>
                                  )}
                                  <LightBoxComponent
                                    visible={this.state[`visible${d.id}`]}
                                    onClose={() =>
                                      this.setState({
                                        [`visible${d.id}`]: false,
                                      })
                                    }
                                    image={verifyObject(d, "reason_image", "")}
                                  />
                                  {/* {verifyObject(d, "reason_image", "") !== "" ? (
                                  <Popover
                                    content={
                                      <Previmgover
                                        image={verifyObject(
                                          d,
                                          "reason_image",
                                          ""
                                        )}
                                      />
                                    }
                                    trigger="click"
                                    placement="bottom"
                                    className="prev_imgover"
                                  >
                                    <Button className="p-0 view_btn border-0 font-12 text-link-blue weight-400 text-underline text-decoration-hover text-center">
                                      View
                                    </Button>
                                  </Popover>
                                ) : (
                                  "-"
                                )} */}
                                  {verifyObject(d, "reason_text", "") !== "" ? (
                                    <Popover
                                      content={
                                        <Prevtextover
                                          text={
                                            d &&
                                            d.reason_text &&
                                            d.reason_text
                                              .split("\n")
                                              .map((text) => {
                                                return <p>{text}</p>;
                                              })
                                          }
                                        />
                                      }
                                      trigger="hover"
                                      placement="bottom"
                                      className="prev_imgover ellipsis-text"
                                    >
                                      {verifyObject(d, "reason_text", "") !==
                                      "" ? (
                                        d.reason_text
                                      ) : (
                                        <span className="lb-p">-</span>
                                      )}
                                    </Popover>
                                  ) : (
                                    <span className="lb-p">-</span>
                                  )}
                                </td>
                                <td className="text-left padding-left-10 [w-150]">
                                  <p className="font-12 tablet-font-9 weight-400 margin-0 text-black">
                                    {moment(d.start_time).format(DATE_FORMAT)}{" "}
                                    {/* TEST */}
                                  </p>
                                </td>
                                <td className="text-left padding-left-10 [w-150]">
                                  <p className="font-12 tablet-font-9 weight-400 margin-0 text-light-black">
                                    {moment(d.start_time).format(T24_HR_FORMAT)}{" "}
                                    - {moment(d.end_time).format(T24_HR_FORMAT)}
                                  </p>
                                </td>
                                <td className="text-left padding-left-10 [w-150]">
                                  {/* {d.offline_appointment &&
                                    d.status === "patient_arrived" && (
                                      <button
                                        onClick={() => {
                                          this._handleNavigation(d);
                                        }}
                                        disabled={
                                          !d.activate_waiting_room &&
                                          d.status !== "patient_arrived"
                                        }
                                        className="arrive_btn btn btn-custom dispense_btn w-100 font-13-imp tablet-font-10 weight-400 text-white"
                                      >
                                        Arrived
                                      </button>
                                    )} */}
                                  {/* {!d.offline_appointment &&
                                    d.status === "ready_for_call" && (
                                      <button
                                        onClick={() =>
                                          this._goToCallSetupScreen(d)
                                        }
                                        disabled={!d.activate_waiting_room}
                                        className="arrive_btn btn btn-blue btn-custom dispense_btn  w-100 font-13-imp tablet-font-10 weight-400 text-white push-10-t"
                                      >
                                        {d.has_patient_join ? "Arrived" : "Join"}
                                      </button>
                                    )} */}
                                  <div className="d-flex flex-column align-items-center gapy-1 py-1">
                                    {!d.offline_appointment &&
                                      d.appointment_type !==
                                        APPT_TYPE_WALKIN && (
                                        <button
                                          onClick={() =>
                                            this._goToCallSetupScreen(d)
                                          }
                                          disabled={!d.activate_waiting_room}
                                          className="arrive_btn btn btn-blue btn-custom w-100 font-13-imp tablet-font-10 weight-400 text-white"
                                        >
                                          {d.has_patient_join
                                            ? "Arrived"
                                            : "Join"}
                                        </button>
                                      )}
                                    {(d.offline_appointment ||
                                      d.appointment_type ===
                                        APPT_TYPE_WALKIN) &&
                                      (d.status === "booked" ||
                                        (!d.offline_appointment &&
                                          d.status === "on_call") ||
                                        (d.appointment_type ===
                                          APPT_TYPE_WALKIN &&
                                          d.status === "patient_arrived")) && (
                                        <button
                                          disabled={
                                            !d.activate_waiting_room &&
                                            d.status !== "patient_arrived" &&
                                            d.status !== "on_call"
                                          }
                                          onClick={() =>
                                            this._handleNavigation(
                                              d

                                              // d.status === "on_call" ||
                                              // d.status === "patient_arrived"
                                            )
                                          }
                                          className="arrive_btn btn btn-blue btn-custom w-100 font-13-imp tablet-font-10 weight-400 text-white"
                                        >
                                          {d.status === "booked"
                                            ? "Join"
                                            : "Arrived"}
                                        </button>
                                      )}
                                    {!d.offline_appointment &&
                                      d.status === "booked" && (
                                        <span className="font-10 weight-400 text-blue line-1 dis-block text-center margin-top-3 push-3-b w-100">
                                          Booked
                                        </span>
                                      )}
                                    {!d.offline_appointment &&
                                      d.status === "on_call" && (
                                        <span className="font-10 weight-400 text-blue line-1 dis-block text-center margin-top-3 push-3-b w-100">
                                          On Call
                                        </span>
                                      )}
                                    {!d.offline_appointment &&
                                      d.status === "ready_for_call" &&
                                      d.has_patient_join === true && (
                                        <span className="font-10 weight-400 text-blue line-1 dis-block text-center margin-top-3 push-3-b w-100">
                                          Ready For Call
                                        </span>
                                      )}
                                  </div>
                                </td>
                                <td className="text-right vertical-top [w-25]">
                                  <div className="dis-flex mt-2 py-1">
                                    {(d.book_via === "slot" ||
                                      d.book_via === "ad_hoc") &&
                                    d.appointment_type === APPT_TYPE_WALKIN &&
                                    // d.status !== "patient_arrived" && ( //commenting old condition and as per new requirement doing changes

                                    d.status === "booked" ? (
                                      <Dropdown
                                        overlay={() => this.renderMenu(d.id, d)}
                                        trigger={["click"]}
                                        disabled={
                                          this.state.isCancellingAppointment
                                        }
                                      >
                                        <button
                                          className="d-flex padding-0 no-background border-0 no-focus"
                                          onClick={(e) => e.preventDefault()}
                                        >
                                          <IconThreeDotsVertical className="icon-three-dots-vertical" />
                                        </button>
                                      </Dropdown>
                                    ) : null}
                                    {this.renderDropDownEMDR(d, d.id)}
                                    {/* {d.appointment_type !== APPT_TYPE_WALKIN &&
                                          d.book_via === "ad_hoc" &&
                                          process.env.REACT_APP_ENV !== "prod" && (
                                            <div className="text-right in-block">
                                              {d.id === this.state.actionId ? (
                                                <DownOutlined
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#1445b7",
                                                  }}
                                                  onClick={() => {
                                                    this.setState(
                                                      {actionId: null},
                                                      () => {}
                                                    );
                                                  }}
                                                />
                                              ) : (
                                                <UpOutlined
                                                  style={{
                                                    fontSize: 14,
                                                    color: "#1445b7",
                                                  }}
                                                  onClick={() => {
                                                    this.setState(
                                                      {actionId: d.id},
                                                      () => {
                                                        this.handleExpandMenu(d.id);
                                                      }
                                                    );
                                                  }}
                                                />
                                              )}
                                            </div>
                                          )} */}
                                  </div>
                                </td>
                              </tr>
                              {d.id === this.state.actionId &&
                                process.env.REACT_APP_ENV !== "prod" &&
                                d.book_via === "ad_hoc" && (
                                  <tr className="Expandable ID">
                                    <td colspan={11}>
                                      <MembersContactTable
                                        _onReuestList={
                                          this._onRequestMemberList
                                        }
                                        dataSource={this.props.membersContacts}
                                        {...this.props}
                                        appointmentId={d.id}
                                      />
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        );
                      })}
                    </div>
                  </Fragment>
                );
              })}
          </div>
          {this.state.showNotificationModal && (
            <ModalPopUp
              visible={this.state.showNotificationModal}
              footer={false}
              title="Send invite via Email or SMS?"
              handleCancel={() => {
                this.setNotificatioModal(false);
              }}
              maskClosable={false}
            >
              <button
                className="btn btn-blue btn-custom font-12-imp tablet-font-10 weight-500 text-white width-100px"
                onClick={() => this.handleSendInvite("email")}
                style={{marginRight: 15}}
                disabled={this.state.loading}
              >
                {this.state.loading ? "Loading..." : "Email"}
              </button>
              <button
                className="btn btn-blue btn-custom font-12-imp tablet-font-10 weight-500 text-white width-100px"
                onClick={() => this.handleSendInvite("sms")}
                disabled={this.state.loadingSMS}
              >
                {this.state.loadingSMS ? "Loading..." : "SMS"}
              </button>
            </ModalPopUp>
          )}
          <div className="col-md-12">
            {!isLoading && (
              <Pagination
                data={this.props.upcomingAppointments}
                onPageChange={this._onPageChange}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    upcomingAppointments: verifyObject(state, "upcomingAppointments", []),
    membersContacts: verifyObject(state, "membersContacts", []),
    user: verifyObject(state, "localStore.user", null),
    isAppointmentFilterApplied: verifyObject(
      state,
      "dynamicTab.isAppointmentFilterApplied",
      false
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    upcomingAppointmentsActions: bindActionCreators(
      upcomingAppointmentsActions,
      dispatch
    ),
    onPatientSelect: bindActionCreators({onPatientSelect}, dispatch),
    onSetPatientAppointment: bindActionCreators(
      {onSetPatientAppointment},
      dispatch
    ),
    onSetPatient: bindActionCreators({onSetPatient}, dispatch),
    videoCallActions: bindActionCreators(
      {onRequestSetVideoCallSetup},
      dispatch
    ),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
    actionCableActions: bindActionCreators(actionCableActions, dispatch),
    MembersContactActions: bindActionCreators(MembersContactActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpcomingAppointmentContainer);

export default ConnectedComponent;

import React from "react";
import { Table } from "antd";
import moment from "moment";

import {
  currencies,
  // modifiedDataObjectToArray,
  modifiedDataObjectToArrayByIDs,
  verifyObject,
} from "../../utilities/utils";
import imguser from "../../assets/images/common/doctor_profile.svg";
import calender_icon from "assets/images/common/calander.svg";
import clock_icon from "assets/images/common/time.svg";
import card_icon from "assets/images/common/card_icon.svg";
import ascendingsort from "assets/images/common/ascending-sort.svg";
import { DATE_FORMAT, T24_HR_FORMAT } from "constants/common";

export default function AppointmentPaymentList({ dataSource, _onReuestList }) {
  // let dataObject = verifyObject(dataSource, 'data', {})
  let loading = verifyObject(dataSource, "isLoading", false);
  let reportReviews = modifiedDataObjectToArrayByIDs(dataSource)
    ? modifiedDataObjectToArrayByIDs(dataSource)
    : null;

  const columns = [
    {
      key: "name",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Patient Name</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l"
              title=""
            />
          </div>
        );
      },
      dataIndex: "name",
      className:
        "width-30 padding-left-10 padding-right-10 padding-top-4 padding-bottom-4 min-height-30 text-light-black",
      render: (text, item) => {
        return (
          <div className="d-flex report-user align-items-center">
            <div className="up_doctor_profile">
              <img
                alt=""
                src={
                  item.appointment && item.appointment.patient_image
                    ? item.appointment.patient_image
                    : imguser
                }
              />
            </div>
            <p className="padding-left-10 text-break font-12 weight-400 text-light-black margin-0 text-capitalize in-block doctor_profile_text">
              {item.appointment.booked_by ? item.appointment.booked_by : "--"}
            </p>
          </div>
        );
      },
    },
    {
      key: "created_at",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Created At</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l"
              // alt='Ascending Sort'
              title=""
            />
          </div>
        );
      },
      dataIndex: "created_at",
      className:
        "width-20 padding-top-4 padding-bottom-4 min-height-30 padding-left-10 padding-right-10 text-dark-gray",
      render: (text, item) => {
        return (
          <p className="font-12 weight-400 margin-0 text-dark-gray">
            <img
              alt=""
              src={calender_icon}
              className="vertical-text-bottom push-5-r"
            />
            {moment(text).format(DATE_FORMAT)}
          </p>
        );
      },
    },
    {
      key: "start_time",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Duration</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l"
              // alt='Ascending Sort'
              title=""
            />
          </div>
        );
      },
      dataIndex: "start_time",
      className:
        "width-22 padding-top-4 padding-bottom-4 min-height-30 padding-left-10 padding-right-10 text-light-black",
      render: (text, item) => {
        return (
          <p className="font-12 weight-400 margin-0 text-light-black">
            <img
              alt=""
              src={clock_icon}
              className="vertical-text-bottom push-5-r"
            />
            {item.appointment.actual_start_time &&
            item.appointment.actual_end_time
              ? `${moment(item.appointment.actual_start_time).format(
                   T24_HR_FORMAT
                )} - ${moment(item.appointment.actual_end_time).format(
					T24_HR_FORMAT
                )}`
              : "-"}
          </p>
        );
      },
    },
    {
      key: "amount",
      title: (filters, sortOrder) => {
        return (
          <div>
            <span>Amount</span>{" "}
            <img
              src={ascendingsort}
              alt="Ascending Sort"
              className="push-3-l"
              // alt='Ascending Sort'
              title=""
            />
          </div>
        );
      },
      dataIndex: "amount",
      className:
        "width-15 padding-top-4 padding-bottom-4 min-height-30 padding-left-10 padding-right-10 text-light-black",
      render: (text, item) => {
        return (
          <p className="font-12 weight-400 margin-0 text-light-black">
            <img
              alt=""
              src={card_icon}
              className="vertical-text-bottom push-5-r"
            />
            {text
              ? `${currencies.pound.symbol}${text && text.toFixed(2, 10)}`
              : "--"}
          </p>
        );
      },
    },
    {
      title: "",
      key: "",
      dataIndex: "is_failed",
      className:
        "width-20 padding-top-4 padding-bottom-4 min-height-30 padding-right-10 padding-left-10",
      align: "left",
      render: (text, item) => {
        return text ? <div className="custom-label danger inline">Failed</div> : "";
      },
    },
  ];

  return (
    <div className="w-100">
      <Table
        pagination={false}
        loading={loading}
        columns={columns}
        dataSource={reportReviews}
      />
    </div>
  );
}

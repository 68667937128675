// import { Modal } from "antd";
import axios from "axios";
import { appRoutesConst } from "../app/navigation";
// import { push } from "react-router-redux";

// import { navigateToIndex } from "../app/navigation";
import Appstore from "../app/store";
// import {
//   PHARAM_SMART_DEV_URL,
//   PHARAM_SMART_PROD_URL,
// } from "../constants/common";
import { errorToast, verifyObject } from "./utils";

const interCeptors = {
  setupInterceptors: (store, history, localStore) => {
    // Add a response interceptor
    return axios.interceptors.response.use(
      (response) => {
        // do something with the response data
        let ApiCallTimerMins = verifyObject(
          store.getState(),
          "dynamicTab.ApiCallTimerMins",
          null
        );
        let is_pharmsmart_user = verifyObject(
          store.getState(),
          "localStore.user.is_pharmsmart_user",
          null
        );
        console.log("Tabs", ApiCallTimerMins);

        if (ApiCallTimerMins === 0) {
          Appstore.dispatch({ type: "CALL_API_TIMER", payload: 1 });
        }
        const { data } = response;
        if (data.status === 950 || data.status === 419) {
          if (!is_pharmsmart_user) {
            history.push("/");
            localStorage.clear();
            Appstore.dispatch({ type: "CLEAR_LOCAL_STATE" });
          } else {
            // Modal.warning({
            //   closable: false,
            //   title: "Session Expired",
            //   okText: "Login PharmSmart",
            //   okButtonProps: {
            //     style: {
            //       paddingLeft: "5pt",
            //     },
            //   },
            //   maskClosable: false,
            //   onOk: () => {
            //     if (process.env.REACT_APP_ENV === "dev") {
            //       window.location.href = PHARAM_SMART_DEV_URL;
            //     } else {
            //       window.location.href = PHARAM_SMART_PROD_URL;
            //     }
            //   },
            //   maskStyle: { background: "black !important" },
            //   mask: true,
            //   content:
            //     "Your session has been expired, Please Login Again from PharmSmart",
            // });
            localStorage.clear();
            Appstore.dispatch({ type: "CLEAR_LOCAL_STATE" });
            history.push(appRoutesConst.pharmSmartLogout);
          }
          // history.push("/");
          // localStorage.clear();
          // Appstore.dispatch({ type: "CLEAR_LOCAL_STATE" });
          // document.oncontextmenu = document.oncontextmenu = function () {
          //   return true;
          // };
          // document.oncopy = document.oncopy = function () {
          //   return true;
          // };
          //     document.ondragstart = document.ondragstart = function (e) {
          //       e.preventDefault();
          //     };
          return response;
        }
        if (data.status === 304) {
          errorToast({ content: data.message || "Error" });
        }
        return {
          ...response,
          // cancelToken: new axios.CancelToken((cancel) => cancel('Cancel repeated request'))
        };
      },
      (error) => {
        if (error.message === "Network Error") {
        }
      }
    );
  },
};

export default interCeptors;

export const bpQuestions = [
  {
    id: 773,
    name: "UTI Preconsult Exclusion",
    created_by: "Zaeem Patel",
    created_at: "2024-01-15T13:16:03.709Z",
    updated_at: "2024-01-15T14:09:49.728Z",
    snomed_code: null,
    description: null,
    image: null,
    question_details: [
      {
        id: 26251,
        title: "Are you known hypertensive ?",
        priority: 1,
        is_formula: null,
        category: "single_choice",
        is_visible: true,
        created_at: "2024-01-15T13:16:03.720Z",
        is_conditional: false,
        is_required: true,
        placeholder: null,
        question_validation: [
          {
            id: 2453,
            min_value: null,
            max_value: null,
            constraint_with: "information_text",
            information_message: null,
            option_title: "Yes",
            min_range: null,
            max_range: null,
            is_blocker: null,
          },
          {
            id: 2454,
            min_value: null,
            max_value: null,
            constraint_with: "information_text",
            information_message: null,
            option_title: "No",
            min_range: null,
            max_range: null,
            is_blocker: null,
          },
        ],
        conditional_text_values: null,
        conditional_max_value: null,
        conditional_min_value: null,
        fe_id: "Q1",
        is_profile_updateable: false,
        profile_update_field: null,
        gender_preference: "both",
        health_wallet_actual_answer: null,
        children: [],
        pre_consult_answer: {
          id: 56151,
          actual_answer: ["Yes"],
        },
        parent_id: null,
        question_options: [
          {
            id: 2981,
            question_detail_id: 2625,
            title: "Yes",
            description: null,
            snomed_code_id: null,
            value: 1,
            priority: 1,
            created_at: "2024-01-15T13:16:03.728Z",
            updated_at: "2024-01-15T13:16:03.728Z",
            deleted_at: null,
          },
          {
            id: 2982,
            question_detail_id: 2625,
            title: "No",
            description: null,
            snomed_code_id: null,
            value: 0,
            priority: 1,
            created_at: "2024-01-15T13:16:03.734Z",
            updated_at: "2024-01-15T13:16:03.734Z",
            deleted_at: null,
          },
        ],
      },
      // {
      //   id: 26252,
      //   title: "Fitted with ABPM ?",
      //   priority: 2,
      //   is_formula: null,
      //   category: "single_choice",
      //   is_visible: true,
      //   created_at: "2024-01-15T13:16:03.720Z",
      //   is_conditional: false,
      //   is_required: true,
      //   placeholder: null,
      //   question_validation: [
      //     {
      //       id: 24531,
      //       min_value: null,
      //       max_value: null,
      //       constraint_with: "information_text",
      //       information_message: null,
      //       option_title: "Yes",
      //       min_range: null,
      //       max_range: null,
      //       is_blocker: null,
      //     },
      //     {
      //       id: 24541,
      //       min_value: null,
      //       max_value: null,
      //       constraint_with: "information_text",
      //       information_message: null,
      //       option_title: "No",
      //       min_range: null,
      //       max_range: null,
      //       is_blocker: null,
      //     },
      //   ],
      //   conditional_text_values: null,
      //   conditional_max_value: null,
      //   conditional_min_value: null,
      //   fe_id: "Q1",
      //   is_profile_updateable: false,
      //   profile_update_field: null,
      //   gender_preference: "both",
      //   health_wallet_actual_answer: null,
      //   children: [],
      //   pre_consult_answer: {
      //     id: 56151,
      //     actual_answer: ["Yes"],
      //   },
      //   parent_id: null,
      //   question_options: [
      //     {
      //       id: 2982,
      //       question_detail_id: 2625,
      //       title: "Yes",
      //       description: null,
      //       snomed_code_id: null,
      //       value: 1,
      //       priority: 1,
      //       created_at: "2024-01-15T13:16:03.728Z",
      //       updated_at: "2024-01-15T13:16:03.728Z",
      //       deleted_at: null,
      //     },
      //     {
      //       id: 2982,
      //       question_detail_id: 2625,
      //       title: "No",
      //       description: null,
      //       snomed_code_id: null,
      //       value: 0,
      //       priority: 1,
      //       created_at: "2024-01-15T13:16:03.734Z",
      //       updated_at: "2024-01-15T13:16:03.734Z",
      //       deleted_at: null,
      //     },
      //   ],
      // },
    ],
    health_walllet_answers: null,
    health_wallet_questions: null,
    health_wallet: null,
    health_wallet_status: null,
    questionnaire_types: [
      {
        id: 5,
        name: "PreConsult Questionnaire",
        created_at: "2023-12-13T05:20:56.413Z",
        updated_at: "2023-12-13T05:20:56.413Z",
      },
    ],
    questionnaire_summary: null,
    questionnaire_label: "primary",
    patient_answer_id: 10470,
    question_formulas: [
      {
        id: 208,
        questionnaire_template_id: 773,
        formula: "Q1+Q2+Q3",
        question_ids: [2625, 2626, 2627],
        title: "redirect",
        formula_info: [
          {
            id: 222,
            min_value: 0,
            max_value: 0,
            snomed_code_id: null,
            title: null,
            description: null,
            color_code: null,
            next_questionnaire: {
              id: 774,
              name: "UTI Preconsult Emergency Symptoms",
            },
            pre_consult_outcome: null,
          },
          {
            id: 223,
            min_value: 1,
            max_value: 3,
            snomed_code_id: null,
            title: "Speak to your GP",
            description:
              "Sorry, your pharmacist is unable to provide you this service under their NHS contract. Please visit your GP or out of hours service.",
            color_code: "#f48918",
            next_questionnaire: null,
            pre_consult_outcome: "DNQ",
          },
        ],
      },
    ],
  },
];

import React, {Component, Fragment} from "react";
import {
  addConsultationDetailAPI,
  addHealthAssessmentPatientAPI,
  getConsultationDetailAPI,
  // getPatientHealthAssessmentDetailApi,
  updateAppointmentReasonApi,
  updateConsultationDetailAPI,
} from "../../services/patientConsultation";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
  heightRangeArray,
} from "../../utilities/utils";
import {connect} from "react-redux";
import {Spin, Input, Tooltip, InputNumber} from "antd";
import {searchSnomedCode} from "../../services/snomedCodes";
import {isEmpty, debounce, map, uniq, uniqBy, isArray} from "lodash";
import CommonLoader from "../../components/common/CommonLoader";
import store from "../../app/store";
// import addImage from "../../assets/images/common/add-ic.png";
import deleteImage from "../../assets/images/common/delete-ic.png";
import {
  onRequestSetComponent,
  onRequestSetComponentID,
  onSetSidebarKey,
  onSetDynamicInput,
  onSetDynamicHealthInput,
} from "../../reducers/dynamicTab";
import {bindActionCreators} from "redux";
// import ModalPopUp from "../../components/common/ModalPopUp";
// import Highlighter from "react-highlight-words";
import {ValidateInput} from "../PatientConsultationConatainers/PatientConsultationValidations";
import CreatableSelect from "react-select/creatable";

import {appRoutesConst, navigateTo} from "../../app/navigation";
import InformationalOutcomeModal from "../PatientConsultationConatainers/InformationalOutcomeModal";
import {
  DATE_FORMAT_24_HR,
  ROLE_DOCTOR,
  ROLE_CLINICAL_PHARMACIST,
} from "constants/common";
import PreEncountersContainer from "../../containers/PatientDetailContainer/PreEncountersContainer/PreEncountersContainer";
import moment from "moment";
// import {ValidateInputHA} from "./ValidateInputHA";

// const {Option} = Select;
const SET_CONSULTATION_OBJ = "SET_CONSULTATION_OBJ";

class ExaminationContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultationDetail: null,
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      managementPlan: "",
      editManagementPlan: false,
      barcodeNumber: "",
      editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      selectedCodesArray: [],
      selectedSnomedCodesArray: [],
      addingDetail: false,
      updatingDetail: false,
      isLoading: false,
      isShowSubmit: false,
      componentId: null,
      showAddSkipbutton: true,
      isLoadingHealthAssessment: false,
      pulseObject: {},
      pulse_per_minute: "",
      height: "",
      heightObject: {},
      weight: "",
      weightObject: {},
      body_temperature: "",
      body_temperatureObject: {},
      systolic_bp: "",
      systolic_bpObject: {},
      diastolic_bp: "",
      diastolic_bpObject: {},
      blood_oxygen_saturation: "",
      blood_oxygen_saturationObject: {},
      bmiObject: {},
      bmi: "",
      height_filters: heightRangeArray(122, 199, null, null, "cm"),
      snomedCodeId: "",
      reason_text: "",
    };
    [
      // "_getPatientConultationDetails",
      "_handleSubmit",
      "_handleTextChange",
      "_hideModal",
      "_showCodeModal",
      "_hideCodeModal",
      "_handleSnomedCodeSearch",
      "_getSnomedCodes",
      "_handleDropDownChange",
      "_handleCustomCodeAdd",
      "_handleModalSubmission",
      "_handleEdit",
      "_handleUpdateoutcome",
      "_handleUpdate",
      // "getObjectFromData",
      "handleHealthTextChange",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSnomedCodeSearch, 1000);
  }

  componentDidMount() {
    let {dynamicInputs, dynamicHealthInputs} = this.props;

    this.setState({
      ...dynamicInputs,
      ...dynamicHealthInputs,
      selectedCodesArray:
        dynamicInputs && dynamicInputs.selectedSnomedCodesArray !== null
          ? dynamicInputs.selectedSnomedCodesArray
          : [],
    });
    // this.getPatientHealthAssessmentDetail();
    // this._getPatientConultationDetails();
  }
  // getPatientHealthAssessmentDetail = async () => {
  //   let {patientId} = this.props;
  //   try {
  //     this.setState({isLoadingHealthAssessment: true});
  //     let response = await getPatientHealthAssessmentDetailApi(patientId);
  //     let data = verifyObject(response, "data.data", []);
  //     // verifyObject(response, "data.data", []).length > 0 &&
  //     //   verifyObject(response, "data.data", []).map((item, index) => {
  //     //     return
  //     this.setState(
  //       {
  //         pulseObject: this.getObjectFromData(data, "pulse_per_minute"),
  //         heightObject: this.getObjectFromData(data, "height"),
  //         weightObject: this.getObjectFromData(data, "weight"),
  //         body_temperatureObject: this.getObjectFromData(
  //           data,
  //           "body_temperature"
  //         ),
  //         blood_oxygen_saturationObject: this.getObjectFromData(
  //           data,
  //           "blood_oxygen_saturation"
  //         ),
  //         systolic_bpObject: this.getObjectFromData(data, "systolic_bp"),
  //         diastolic_bpObject: this.getObjectFromData(data, "diastolic_bp"),
  //         bmiObject: this.getObjectFromData(data, "bmi"),
  //       },
  //       () => {
  //         let {
  //           pulseObject,
  //           heightObject,
  //           weightObject,
  //           blood_oxygen_saturationObject,
  //           body_temperatureObject,
  //           bmiObject,
  //           systolic_bpObject,
  //           diastolic_bpObject,
  //         } = this.state;
  //         let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
  //         if (typeof onSetDynamicHealthInput === "function") {
  //           let Inputs = {
  //             ...this.props.dynamicHealthInputs,
  //             pulseObject,
  //             heightObject,
  //             weightObject,
  //             blood_oxygen_saturationObject,
  //             body_temperatureObject,
  //             bmiObject,
  //             systolic_bpObject,
  //             diastolic_bpObject,
  //           };
  //           onSetDynamicHealthInput(Inputs);
  //         }
  //       }
  //     );

  //     await this.setState({isLoadingHealthAssessment: false});
  //   } catch (error) {
  //     const {message} = getErrorObject(error);
  //     await this.setState({isLoadingHealthAssessment: false});
  //     errorToast({content: message});
  //   }
  // };
  // getObjectFromData = (data, value) => {
  //   let found;
  //   if (data && data.length > 0) {
  //     // eslint-disable-next-line array-callback-return
  //     data.find((item, index) => {
  //       // console.log("checked=========>", item.name, value, item.name === value);
  //       if (item.name === value) return (found = item);
  //     });
  //   }
  //   console.log("found--------------------", found);
  //   return found;
  // };
  async _handleSnomedCodeSearch(value) {
    console.log("values on changes----", value);
    if (!isEmpty(value)) {
      this.setState(
        {
          // snomedCodes: [],
          snomedCodeId: value,
          page: 1,
          searching: true,
          isLoadmore: false,
          search: value,
        },
        async () => {
          await this._getSnomedCodes(false, value);
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  _getSnomedCodes = async (isLoadMore, search) => {
    this.setState({searching: true});
    try {
      let response = await searchSnomedCode({
        page: this.state.page,
        search: search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      let newSnomedCodes = snomedCodes.map((k) => {
        return {
          value: k.code,
          label: k.description,
          id: k.id,
        };
      });

      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...newSnomedCodes]
          : newSnomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  };
  _handleCustomCodeAdd(value) {
    // let {snomedCodeText} = this.state;

    let Obj = {
      id: "",
      code: "",
      description: value,
    };
    this.setState(
      {
        snomedCodeText: "",
        snomedCodeId: "",

        // snomedCodeText: "",
        selectedCodesArray: this.state.selectedCodesArray.concat(Obj),
      },
      () => {
        this.setState(
          {
            selectedSnomedCodesArray: uniq([
              ...this.state.selectedSnomedCodesArray,
              ...this.state.selectedCodesArray,
            ]),
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;

            let {
              history,
              examination,
              managementPlan,
              barcodeNumber,
              isShowSubmit,
              // selectedSnomedCodesArray,
            } = this.state;

            let Inputs = {
              ...this.props.dynamicInputs,

              history: history,
              examination: examination,
              managementPlan: managementPlan,
              barcodeNumber: barcodeNumber,
              selectedSnomedCodesArray: this.state.selectedCodesArray,
              isShowSubmit: isShowSubmit,
            };
            onSetDynamicInput(Inputs);
          }
        );
      }
    );
  }
  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getSnomedCodes(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    let {
      page,
      pagination: {total_pages},
    } = this.state;
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };

  async _getPatientConultationDetails() {
    let {appointmentId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getConsultationDetailAPI({
        appointment_id: appointmentId,
      });
      let consultationDetail = verifyObject(response, "data.data", null);
      store.dispatch({
        type: SET_CONSULTATION_OBJ,
        payload: consultationDetail,
      });
      await this.setState({
        consultationDetail: consultationDetail,
        selectedSnomedCodesArray:
          verifyObject(consultationDetail, "snomed_codes", []).length !== 0
            ? uniqBy(consultationDetail.snomed_codes, "description")
            : [],
        isLoading: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({isLoading: false});
      errorToast({content: message});
    }
  }

  _handleTextChange(e) {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors}, () => {
      console.log("this.state.managementPlan", this.state.managementPlan);
      if (
        isEmpty(this.state.history) ||
        isEmpty(this.state.examination) ||
        isEmpty(this.state.managementPlan)
      ) {
        console.log("IS SHOW SUBMIT");
        this.setState({isShowSubmit: false}, () => {
          this._SaveComponentStateToRedux();
        });
      } else {
        this._SaveComponentStateToRedux();
      }
    });
  }

  _SaveComponentStateToRedux = () => {
    let {onSetDynamicInput} = this.props.dynamicTabAction;
    if (typeof onSetDynamicInput === "function") {
      let {
        history,
        examination,
        managementPlan,
        barcodeNumber,
        selectedSnomedCodesArray,
        isShowSubmit,
        isConsentGiven,
      } = this.state;
      let Inputs = {
        ...this.props.dynamicInputs,

        history: history,
        examination: examination,
        managementPlan: managementPlan,
        barcodeNumber: barcodeNumber,
        selectedSnomedCodesArray: selectedSnomedCodesArray,
        isShowSubmit: isShowSubmit,
        isConsentGiven: isConsentGiven,
      };
      onSetDynamicInput(Inputs);
    }
  };

  // _handleCustomCodeAdd() {
  //   let {snomedCodeText} = this.state;
  //   let Obj = {
  //     id: "",
  //     code: "",
  //     description: snomedCodeText,
  //   };
  //   this.setState({
  //     snomedCodeText: "",
  //     selectedCodesArray: this.state.selectedCodesArray.concat(Obj),
  //   });
  // }

  async _handleSubmit(e) {
    // let { history, examination, managementPlan, barcodeNumber } = this.state;

    // let toBeValidateObj = {
    //   history: history,
    //   examination: examination,
    //   managementPlan: managementPlan,
    //   barcodeNumber: barcodeNumber,
    // };

    // const errors = ValidateInput(toBeValidateObj);
    // if (!errors.isValid) {
    //   this.setState({ errors: errors.errors, adding: false });
    // } else {
    this.setState(
      {
        visible: true,
      },
      () => {}
    );
    // }
  }

  async _handleModalSubmission() {
    // if (!this.props.isPrescriptionAdded) {
    //   this.setState(
    //     {
    //       visible: false,
    //     },
    //     () => {
    //       Modal.warn({ content: "Please add prescription to proceed further" });
    //     }
    //   );
    // } else {
    let {
      treatment,
      followUp,
      referral,
      comment,
      history,
      examination,
      managementPlan,
      barcodeNumber,
      presriptionPlan,
      reason_text,
    } = this.state;

    let toBeValidateObj2 = {
      treatment: treatment,
      followUp: followUp,
      referral: referral,
      comment: comment,
      visible: true,
    };
    const errors = ValidateInput(toBeValidateObj2);

    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let {
        appointmentId,
        patientId,
        pulse_per_minute,
        systolic_bp,
        diastolic_bp,
        body_temperature,
        blood_oxygen_saturation,
        height,
        weight,
        bmi,
      } = this.props;
      let {selectedSnomedCodesArray} = this.state;

      let payload = {
        consultation: {
          appointment_id: appointmentId,
          history: history,
          examination: examination,
          management_plan: managementPlan,
          prescription_plan: presriptionPlan,
          barcode: barcodeNumber,
          patient_id: patientId,
          outcome: {
            treatment: treatment,
            follow_up: followUp,
            referral: referral,
            comment: comment,
          },
        },
        snomed_codes: selectedSnomedCodesArray,
      };
      let health_summaryPayload = {
        health_summary: {},
      };
      if (pulse_per_minute && pulse_per_minute !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            pulse_per_minute,
          },
        };
      }
      if (systolic_bp && systolic_bp !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            systolic_bp,
          },
        };
      }
      if (diastolic_bp && diastolic_bp !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            diastolic_bp,
          },
        };
      }
      if (blood_oxygen_saturation && blood_oxygen_saturation !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            blood_oxygen_saturation,
          },
        };
      }
      if (body_temperature && body_temperature !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            body_temperature,
          },
        };
      }
      if (weight && weight !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            weight,
          },
        };
      }
      if (height && height !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            height,
          },
        };
      }
      if (bmi && bmi !== "") {
        health_summaryPayload = {
          health_summary: {
            ...health_summaryPayload.health_summary,
            bmi,
          },
        };
      }
      // console.log("ja summary-------------------", health_summaryPayload);
      let healthPayload = {
        appointment_id: appointmentId,
        ...health_summaryPayload,
      };
      let reasonP = {};
      if (
        (this.props.dynamicInputs && this.props.dynamicInputs.reason_text) ||
        reason_text
      ) {
        reasonP = {
          reason: this.props.dynamicHealthInputs.reason_text
            ? this.props.dynamicHealthInputs.reason_text
            : reason_text,
        };
      }
      let payloadReason = {
        id: appointmentId,
        ...reasonP,
      };
      try {
        this.setState({addingDetail: true});
        if (reason_text && !isEmpty(reason_text)) {
          let reasonResponse = await updateAppointmentReasonApi(payloadReason);

          successToast({
            content: verifyObject(reasonResponse, "data.message", "Success"),
          });
          if (
            healthPayload &&
            Object.keys(healthPayload.health_summary).length > 0
          ) {
            let healthResponse = await addHealthAssessmentPatientAPI(
              healthPayload
            );
            successToast({
              content: verifyObject(healthResponse, "data.message", "Success"),
            });
          }
          let response = await addConsultationDetailAPI(payload);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
        } else if (
          healthPayload &&
          Object.keys(healthPayload.health_summary).length > 0
        ) {
          let healthResponse = await addHealthAssessmentPatientAPI(
            healthPayload
          );
          successToast({
            content: verifyObject(healthResponse, "data.message", "Success"),
          });
          let response = await addConsultationDetailAPI(payload);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
        } else {
          let response = await addConsultationDetailAPI(payload);

          successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
        }
        this.setState({
          addingDetail: false,
        });

        console.log("this.props.isOwnPharamcy", this.props.isOwnPharamcy);
        let {navigateTo} = this.props.navigationActions;

        if (
          (this.props.userRole === ROLE_CLINICAL_PHARMACIST ||
            this.props.userRole === ROLE_DOCTOR) &&
          this.props.is_from_patient_search_listing
        ) {
          store.dispatch({
            type: "ON_CONSULTATION_SUBMITTED_BY_PRESCRIBER",
            payload: true,
          });
        } else {
          if (typeof navigateTo === "function") {
            await navigateTo(appRoutesConst.dashboard);
          }
        }
        // if (typeof navigateTo === "function") {
        //   await navigateTo(appRoutesConst.dashboard);
        // }
        this._clearData();
        // this._clearComponents();
      } catch (error) {
        console.log("this.props.isOwnPharamcy", this.props.isOwnPharamcy);
        const {message} = getErrorObject(error);
        this.setState({addingDetail: false});
        errorToast({content: message});
      }
    }
    // }
  }

  _clearData() {
    this.setState({
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      managementPlan: "",
      editManagementPlan: false,
      barcodeNumber: "",
      editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      // p@
      addingDetail: false,
      updatingDetail: false,
      visible: false,
    });
    store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
    store.dispatch({
      type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
      payload: null,
    });
    let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
    if (typeof onSetDynamicHealthInput === "function") {
      onSetDynamicHealthInput(null);
    }
  }

  async _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }
    console.log("drop down value==>", value);

    // eslint-disable-next-line eqeqeq
    let found = this.state.snomedCodes.find((d) => d.id == value.id);
    let ids =
      this.state.selectedCodesArray.length > 0 &&
      map(this.state.selectedCodesArray, "id");
    console.log(
      "drop down chnge--->,",
      this.state.selectedCodesArray,
      ids,
      found
      // ids.includes(found.id) === false
    );
    if (found) {
      if (ids === false) {
        this.setState(
          {
            snomedCodeText: "",

            snomedCodeId: "",

            selectedCodesArray: this.state.selectedCodesArray.concat({
              id: found.id,
              code: found.value,
              description: found.label,
            }),
            // snomedCodeId: null,
          },
          () => {
            this.setState(
              {
                snomedCodes: [],

                selectedSnomedCodesArray: uniq([
                  ...this.state.selectedSnomedCodesArray,
                  ...this.state.selectedCodesArray,
                ]),
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;

                let {
                  history,
                  examination,
                  managementPlan,
                  barcodeNumber,
                  isShowSubmit,
                  // selectedSnomedCodesArray,
                } = this.state;

                let Inputs = {
                  ...this.props.dynamicInputs,

                  history: history,
                  examination: examination,
                  managementPlan: managementPlan,
                  barcodeNumber: barcodeNumber,
                  selectedSnomedCodesArray: this.state.selectedCodesArray,
                  isShowSubmit: isShowSubmit,
                };
                onSetDynamicInput(Inputs);
              }
            );
          }
        );
      } else {
        this.setState(
          {
            snomedCodeText: "",
            snomedCodeId: "",

            // snomedCodes: this.state.snomedCodes,
            selectedCodesArray: this.state.selectedCodesArray.concat({
              id: found.id,
              code: found.value,
              description: found.label,
            }),
          },
          () => {
            this.setState(
              {
                showCodeModal: false,
                selectedSnomedCodesArray: uniq([
                  ...this.state.selectedSnomedCodesArray,
                  ...this.state.selectedCodesArray,
                ]),
              },
              () => {
                let {onSetDynamicInput} = this.props.dynamicTabAction;

                let {
                  history,
                  examination,
                  managementPlan,
                  barcodeNumber,
                  isShowSubmit,
                  // selectedSnomedCodesArray,
                } = this.state;

                let Inputs = {
                  ...this.props.dynamicInputs,

                  history: history,
                  examination: examination,
                  managementPlan: managementPlan,
                  barcodeNumber: barcodeNumber,
                  selectedSnomedCodesArray: this.state.selectedCodesArray,
                  isShowSubmit: isShowSubmit,
                };
                onSetDynamicInput(Inputs);
              }
            );
          }
        );
      }
    }
  }

  _hideModal() {
    this.setState({
      visible: false,
      errors: null,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      editOutcome: false,
      changing: false,
    });
  }
  _showCodeModal() {
    this.setState({showCodeModal: true});
  }
  _hideCodeModal() {
    this.setState({showCodeModal: false});
  }
  _removeCode = (record) => {
    console.log("record delete->,", record);
    this.setState(
      {
        selectedCodesArray: this.state.selectedCodesArray.filter(
          (d) => d.id !== record.id || d.description !== record.description
        ),
      },
      () => {
        this.setState(
          {
            selectedSnomedCodesArray: uniq([
              ...this.state.selectedSnomedCodesArray,
              ...this.state.selectedCodesArray,
            ]),
          },
          () => {
            let {onSetDynamicInput} = this.props.dynamicTabAction;

            let {
              history,
              examination,
              managementPlan,
              barcodeNumber,
              isShowSubmit,
              // selectedSnomedCodesArray,
            } = this.state;

            let Inputs = {
              ...this.props.dynamicInputs,

              history: history,
              examination: examination,
              managementPlan: managementPlan,
              barcodeNumber: barcodeNumber,
              selectedSnomedCodesArray: this.state.selectedCodesArray,
              isShowSubmit: isShowSubmit,
            };
            onSetDynamicInput(Inputs);
          }
        );
      }
    );
  };

  _handleEdit(state) {
    this.setState({[state]: !this.state[state]}, () => {
      if (this.state.editOutcome) {
        this.setState({visible: true});
      }
    });
  }

  async _handleUpdateoutcome() {
    let {
      treatment,
      followUp,
      referral,
      comment,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;

    let payload = {
      consultation: {
        outcome: {
          treatment: treatment,
          follow_up: followUp,
          referral: referral,
          comment: comment,
        },
      },
      consultation_id: consultation_id,
    };

    try {
      this.setState({changing: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Outcome.`,
        });
        this.setState({
          changing: false,
          consultationDetail: response.data.data,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }
  async _handleSnomedCodeUpdate() {
    let {
      selectedCodesArray,
      selectedSnomedCodesArray,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;
    let payload = {
      snomed_codes: uniq([...selectedSnomedCodesArray, ...selectedCodesArray]),
      consultation_id: consultation_id,
    };
    try {
      this.setState({updatingDetail: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Snomed Codes.`,
        });
        this.setState({
          updatingDetail: false,
          consultationDetail: response.data.data,
          selectedSnomedCodesArray:
            verifyObject(response, "data.data.snomed_codes", []).length !== 0
              ? uniqBy(response.data.data.snomed_codes, "description")
              : [],
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }

  handleDynamicKey = async (key, page, submenu) => {
    let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;

    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];
        onSetSidebarKey(payload);
      }
      if (key === null) {
        this.setState({isShowSubmit: true});
      }
    }
    store.dispatch({
      type: "SAVE_SELECTED_KEY",
      payload: [key],
    });
    // if (typeof onRequestSetComponentID === "function") {
    //   onRequestSetComponentID(null);
    // }
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }
  };

  async _handleUpdate(param, state) {
    let {
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;
    let payload = {
      consultation: {
        [param]: this.state[param],
      },
      consultation_id: consultation_id,
    };

    if (param === "barcode") {
      payload = {
        consultation: {
          barcode: this.state.barcodeNumber,
        },
        consultation_id: consultation_id,
      };
    }

    if (param === "managementPlan") {
      payload = {
        consultation: {
          management_plan: this.state.managementPlan,
        },
        consultation_id: consultation_id,
      };
    }
    try {
      this.setState({updatingDetail: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated ${param}`,
        });
        this.setState({
          updatingDetail: false,
          consultationDetail: response.data.data,
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }
  componentWillReceiveProps(nextProps) {
    let {dynamicInputs} = nextProps;
    this.setState({
      ...dynamicInputs,
    });
  }
  handleAddPrescription = () => {
    if (this.props.show_prescriotion_modal_from_notes === null) {
      // this.handleDynamicKey(97, "PrescriptionsContainer"); //?NOTE: on next frommanagement it should redirect to prescription modal open,
      return store.dispatch({
        type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
        payload: true,
      });
    }
  };
  handleSkip = () => {
    this.setState({isShowSubmit: true, showAddSkipbutton: false}, () => {
      this._SaveComponentStateToRedux();
    });
  };
  componentDidUpdate(prevProps) {}
  handleHealthTextChange = (e, name) => {
    let errors = null;

    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[name];
    }
    this.setState({[name]: e, errors: errors}, () => {
      let {
        pulse_per_minute,
        height,
        weight,
        blood_oxygen_saturation,
        body_temperature,
        bmi,
        systolic_bp,
        diastolic_bp,
      } = this.state;
      // let data = {
      //   [name]: e.target.value,
      // };
      // const errors = ValidateInputHA(data);
      // console.log("errors of hA---->", errors);
      // if (!errors.isValid) {
      //   this.setState({errors: errors.errors});
      // } else {
      let {onSetDynamicHealthInput} = this.props.dynamicTabAction;
      if (typeof onSetDynamicHealthInput === "function") {
        let Inputs = {
          ...this.props.dynamicHealthInputs,

          pulse_per_minute,
          height,
          weight,
          blood_oxygen_saturation,
          body_temperature,
          bmi,
          systolic_bp,
          diastolic_bp,
        };
        onSetDynamicHealthInput(Inputs);
      }
      // }
    });
  };
  renderInputColor = (value, field) => {
    if (value && !isEmpty(value.toString())) {
      // if (_bp < 60) return "#0872FB"; //!LOW
      // if (_bp > 60 && _bp <= 80) return "#16B250"; //!NORMAL
      // if (_bp > 80 && _bp <= 90) return "#D4CA01"; //!AT RISK
      // if (_bp > 90 && _bp <= 100) return "#F48918"; //!HIGH
      // if (_bp > 100 && _bp <= 110) return "#ED1E24"; //!VERY HIGH
    } //!SEVERE
    if (value > 110 && field === "diastolic_bp") {
      return "bg-danger text-white";
    } else if (value > 190 && field === "systolic_bp") {
      return "bg-danger text-white";
    } else if (value > 100 && field === "pulse_per_minute") {
      return "bg-danger text-white";
    } else if (value > 37.2 && field === "body_temperature") {
      return "bg-danger text-white";
    } else if (value > 100 && field === "blood_oxygen_saturation") {
      return "bg-danger text-white";
    } else {
      return "";
    }
  };
  render() {
    let {
      // consultationDetail,
      history,
      examination,
      managementPlan,
      presriptionPlan,
      barcodeNumber,
      isLoading,
      errors,
      // isShowSubmit,
      // selectedSnomedCodesArray,
      // showCodeModal,
      selectedCodesArray,
      // changing,
      searching,
      // isLoadmore,
      // snomedCodeText,
      snomedCodes,
      visible,
      addingDetail,
    } = this.state;
    let {dynamicInputs} = this.props;

    // console.log("DYnamic Inputs", dynamicInputs);
    console.log("state--------------------", this.state);
    // let { componentId } = this.props;
    // let isAllEmpty =
    //   isEmpty(history) ||
    //   // isEmpty(managementPlan) ||  // commented as required add prescription and skip button under mngmnt plan box
    //   isEmpty(examination);
    if (isLoading) {
      return <CommonLoader />;
    }
    return (
      <Fragment>
        <div className="push-20-b examination_wrapper padding-50-t">
          <div className="selection_container">
            <div className="form-group push-10-b relative">
              {/* {------History-----} */}
              {/* {!isEmpty(history) && ( */}
              <div className="form-row push-10-b">
                <div className="col-md-6 consultant_review">
                  <p className="font-11 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    History
                  </p>

                  <Input.TextArea
                    autoSize={true}
                    type="text"
                    className={`form-control-textarea input-bg-f9f9f9 form-control ${
                      errors && errors.history && "danger-border"
                    } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus min-height-92`}
                    name="history"
                    value={history}
                    onChange={this._handleTextChange}
                    placeholder="Write patient history here"
                  />
                  {errors && (
                    <span className="validate-danger font-14">
                      {errors.history}
                    </span>
                  )}
                </div>
                {/* <div className="col-md-6 d-flex align-items-center mt-3 pt-2">
                    <div className="relative bg-gray border p-2 pt-1">
                      <table>
                        <tbody>
                          <tr>
                            <td className="padding-5-b">
                              <span
                                className="font-11 weight-400 text-left text-high-dark-blue-imp in-block push-2-b text-capitalize"
                                onClick={() =>
                                  this.handleDynamicKey(
                                    6,
                                    "FamilyRecordsContainer"
                                  )
                                }
                                style={{cursor: "pointer"}}
                              >
                                Family History,
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="highlightedtext padding-5-b ">
                              <span
                                className="font-11 weight-400 text-left text-high-dark-blue-imp in-block push-2-b text-capitalize"
                                onClick={() =>
                                  this.handleDynamicKey(3, "AllergiesContainer")
                                }
                                style={{cursor: "pointer"}}
                              >
                                Allergies,
                              </span>
                            </td>
                          </tr>
                          <tr>
                            <td className="highlightedtext">
                              <span
                                className="font-11 weight-400 text-left text-high-dark-blue-imp in-block push-2-b text-capitalize"
                                onClick={() =>
                                  this.handleDynamicKey(
                                    2,
                                    "MedicalHistoryContainer"
                                  )
                                }
                                style={{cursor: "pointer"}}
                              >
                                Medical History
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div> */}
              </div>
              {/* )} */}
              {
                <>
                  <div className="form-row push-10-b">
                    <div className="col-md-6 consultant_review">
                      <p className="font-11 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                        Examination
                      </p>

                      <Input.TextArea
                        autoSize={true}
                        type="text"
                        className={`form-control-textarea input-bg-f9f9f9 form-control h-75 ${
                          errors && errors.examination && "danger-border"
                        } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus min-height-180`}
                        name="examination"
                        value={examination}
                        onChange={this._handleTextChange}
                        placeholder="Write examination detail here"
                        autoFocus
                      />
                      {errors && (
                        <span className="validate-danger font-14">
                          {errors.examination}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 consultantion-info-table-wrapper">
                      <Spin spinning={this.state.isLoadingHealthAssessment}>
                        <div className="table-wrapper">
                          <table className="border">
                            <thead className="bg-gray">
                              <th>Key</th>
                              <th>Value</th>
                              <th></th>
                            </thead>
                            <tbody>
                              <tr className="highlightedtext">
                                <td>Pulse</td>
                                <td>
                                  <Tooltip
                                    placement="top"
                                    title={` ${
                                      verifyObject(
                                        this.state.pulseObject,
                                        "created_at",
                                        ""
                                      ) !== ""
                                        ? moment(
                                            verifyObject(
                                              this.state.pulseObject,
                                              "created_at",
                                              ""
                                            )
                                          ).format(DATE_FORMAT_24_HR)
                                        : ""
                                    }`}
                                  >
                                    {verifyObject(
                                      this.state.pulseObject,
                                      "value",
                                      ""
                                    ) !== ""
                                      ? verifyObject(
                                          this.state.pulseObject,
                                          "value",
                                          ""
                                        )
                                      : "-"}
                                  </Tooltip>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <InputNumber
                                      className={this.renderInputColor(
                                        this.state.pulse_per_minute,
                                        "pulse_per_minute"
                                      )}
                                      min={30}
                                      max={200}
                                      type="number"
                                      value={this.state.pulse_per_minute}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(
                                          e,
                                          "pulse_per_minute"
                                        )
                                      }
                                    />
                                    {errors && (
                                      <span className="validate-danger font-14">
                                        {errors.pulse_per_minute}
                                      </span>
                                    )}
                                    {/* <Select
                                      allowClear={true}
                                      placeholder="Select Value"
                                      onChange={(e) =>
                                        this.handleHealthTextChange(
                                          e,
                                          "pulse_per_minute"
                                        )
                                      }
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      showSearch={true}
                                      optionFilterProp="children"
                                      name="pulse_per_minute"
                                      value={this.state.pulse_per_minute}
                                    >
                                      {arrayRange(40, 151, 1).map(
                                        (item, index) => {
                                          return (
                                            <Option
                                              value={item}
                                              name="height_filters"
                                              key={item}
                                            >
                                              {item}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select> */}
                                    <span className="ml-1">bpm</span>
                                  </div>
                                </td>
                              </tr>

                              <tr className="highlightedtext">
                                <td>
                                  BP (systolic) <br />
                                  /(diastolic)
                                </td>
                                <td>
                                  <Tooltip
                                    placement="top"
                                    title={` ${
                                      verifyObject(
                                        this.state.systolic_bpObject,
                                        "created_at",
                                        ""
                                      ) !== ""
                                        ? moment(
                                            verifyObject(
                                              this.state.systolic_bpObject,
                                              "created_at",
                                              ""
                                            )
                                          ).format(DATE_FORMAT_24_HR)
                                        : ""
                                    }`}
                                  >
                                    {verifyObject(
                                      this.state.systolic_bpObject,
                                      "value",
                                      ""
                                    ) !== ""
                                      ? `${verifyObject(
                                          this.state.systolic_bpObject,
                                          "value",
                                          ""
                                        )} /`
                                      : "-"}
                                  </Tooltip>
                                  <br />
                                  <Tooltip
                                    placement="top"
                                    title={` ${
                                      verifyObject(
                                        this.state.diastolic_bpObject,
                                        "created_at",
                                        ""
                                      ) !== ""
                                        ? moment(
                                            verifyObject(
                                              this.state.diastolic_bpObject,
                                              "created_at",
                                              ""
                                            )
                                          ).format(DATE_FORMAT_24_HR)
                                        : ""
                                    }`}
                                  >
                                    {verifyObject(
                                      this.state.diastolic_bpObject,
                                      "value",
                                      ""
                                    ) !== ""
                                      ? verifyObject(
                                          this.state.diastolic_bpObject,
                                          "value",
                                          ""
                                        )
                                      : "-"}
                                  </Tooltip>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center two_input">
                                    <div className="input-wrapper">
                                      <InputNumber
                                        className={this.renderInputColor(
                                          this.state.systolic_bp,
                                          "systolic_bp"
                                        )}
                                        min={40}
                                        max={300}
                                        type="number"
                                        value={this.state.systolic_bp}
                                        onChange={(e) =>
                                          this.handleHealthTextChange(
                                            e,
                                            "systolic_bp"
                                          )
                                        }
                                      />
                                      {errors && (
                                        <span className="validate-danger font-14">
                                          {errors.systolic_bp}
                                        </span>
                                      )}
                                      {/* <Select
                                        allowClear={true}
                                        placeholder="Select Value"
                                        filterOption={(input, option) =>
                                          option.children
                                            .toLowerCase()
                                            .indexOf(input.toLowerCase()) >= 0
                                        }
                                        showSearch={true}
                                        optionFilterProp="children"
                                        name="dropDown"
                                        value={this.state.systolic_bp}
                                        onChange={(e) =>
                                          this.handleHealthTextChange(
                                            e,
                                            "systolic_bp"
                                          )
                                        }
                                      >
                                        {arrayRange(50, 300, 1).map(
                                          (item, index) => {
                                            return (
                                              <Option
                                                value={item}
                                                key={item}
                                                name="height_filters"
                                              >
                                                {item}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select> */}
                                      <span>/</span>
                                      <InputNumber
                                        className={this.renderInputColor(
                                          this.state.diastolic_bp,
                                          "diastolic_bp"
                                        )}
                                        min={20}
                                        max={200}
                                        type="number"
                                        value={this.state.diastolic_bp}
                                        onChange={(e) =>
                                          this.handleHealthTextChange(
                                            e,
                                            "diastolic_bp"
                                          )
                                        }
                                      />
                                      {errors && (
                                        <span className="validate-danger font-14">
                                          {errors.diastolic_bp}
                                        </span>
                                      )}
                                      {/* <Select
                                        allowClear={true}
                                        placeholder="Select Value"
                                        filterOption={(input, option) =>
                                          option.value >= input
                                        }
                                        showSearch={true}
                                        optionFilterProp="children"
                                        name="dropDown"
                                        value={this.state.diastolic_bp}
                                        onChange={(e) =>
                                          this.handleHealthTextChange(
                                            e,
                                            "diastolic_bp"
                                          )
                                        }
                                      >
                                        {arrayRange(20, 200, 1).map(
                                          (item, index) => {
                                            return (
                                              <Option
                                                value={item}
                                                key={item}
                                                name="height_filters"
                                              >
                                                {item}
                                              </Option>
                                            );
                                          }
                                        )}
                                      </Select> */}
                                    </div>
                                    <span className="ml-1">mmHg</span>
                                  </div>
                                </td>
                              </tr>

                              <tr className="highlightedtext">
                                <td>SpO2</td>
                                <td>
                                  <Tooltip
                                    placement="top"
                                    title={` ${
                                      verifyObject(
                                        this.state
                                          .blood_oxygen_saturationObject,
                                        "created_at",
                                        ""
                                      ) !== ""
                                        ? moment(
                                            verifyObject(
                                              this.state
                                                .blood_oxygen_saturationObject,
                                              "created_at",
                                              ""
                                            )
                                          ).format(DATE_FORMAT_24_HR)
                                        : ""
                                    }`}
                                  >
                                    {verifyObject(
                                      this.state.blood_oxygen_saturationObject,
                                      "value",
                                      ""
                                    ) !== ""
                                      ? verifyObject(
                                          this.state
                                            .blood_oxygen_saturationObject,
                                          "value",
                                          ""
                                        )
                                      : "-"}
                                  </Tooltip>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <InputNumber
                                      className={this.renderInputColor(
                                        this.state.blood_oxygen_saturation,
                                        "blood_oxygen_saturation"
                                      )}
                                      min={50}
                                      max={100}
                                      type="number"
                                      value={this.state.blood_oxygen_saturation}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(
                                          e,
                                          "blood_oxygen_saturation"
                                        )
                                      }
                                    />
                                    {errors && (
                                      <span className="validate-danger font-14">
                                        {errors.blood_oxygen_saturation}
                                      </span>
                                    )}
                                    {/* <Select
                                      allowClear={true}
                                      placeholder="Select Value"
                                      filterOption={(input, option) =>
                                        option.value >= input
                                      }
                                      showSearch={true}
                                      optionFilterProp="children"
                                      name="dropDown"
                                      value={this.state.blood_oxygen_saturation}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(
                                          e,
                                          "blood_oxygen_saturation"
                                        )
                                      }
                                    >
                                      {arrayRange(80, 100, 1).map(
                                        (item, index) => {
                                          return (
                                            <Option
                                              value={item}
                                              key={item}
                                              name="height_filters"
                                            >
                                              {item}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select> */}
                                    <span className="ml-1">%</span>
                                  </div>
                                </td>
                              </tr>
                              <tr className="highlightedtext">
                                <td>Temp</td>
                                <td>
                                  <Tooltip
                                    placement="top"
                                    title={` ${
                                      verifyObject(
                                        this.state.body_temperatureObject,
                                        "created_at",
                                        ""
                                      ) !== ""
                                        ? moment(
                                            verifyObject(
                                              this.state.body_temperatureObject,
                                              "created_at",
                                              ""
                                            )
                                          ).format(DATE_FORMAT_24_HR)
                                        : ""
                                    }`}
                                  >
                                    {verifyObject(
                                      this.state.body_temperatureObject,
                                      "value",
                                      ""
                                    ) !== ""
                                      ? verifyObject(
                                          this.state.body_temperatureObject,
                                          "value",
                                          ""
                                        )
                                      : "-"}
                                  </Tooltip>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <InputNumber
                                      className={this.renderInputColor(
                                        this.state.body_temperature,
                                        "body_temperature"
                                      )}
                                      min={25}
                                      max={60}
                                      type="number"
                                      value={this.state.body_temperature}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(
                                          e,
                                          "body_temperature"
                                        )
                                      }
                                    />
                                    {errors && (
                                      <span className="validate-danger font-14">
                                        {errors.body_temperature}
                                      </span>
                                    )}
                                    {/* <Select
                                      allowClear={true}
                                      placeholder="Select Value"
                                      filterOption={(input, option) =>
                                        option.value >= input
                                      }
                                      showSearch={true}
                                      optionFilterProp="children"
                                      name="dropDown"
                                      value={this.state.body_temperature}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(
                                          e,
                                          "body_temperature"
                                        )
                                      }
                                    >
                                      {arrayRange(20, 100, 1).map(
                                        (item, index) => {
                                          return (
                                            <Option
                                              value={item}
                                              key={item}
                                              name="height_filters"
                                            >
                                              {item}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select> */}
                                    <span className="ml-1">°C</span>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <table className="border">
                            <thead className="bg-gray">
                              <th>Key</th>
                              <th>Value</th>
                              <th></th>
                            </thead>
                            <tbody>
                              <tr className="highlightedtext">
                                <td>Height </td>
                                <td>
                                  <Tooltip
                                    placement="top"
                                    title={` ${
                                      verifyObject(
                                        this.state.heightObject,
                                        "created_at",
                                        ""
                                      ) !== ""
                                        ? moment(
                                            verifyObject(
                                              this.state.heightObject,
                                              "created_at",
                                              ""
                                            )
                                          ).format(DATE_FORMAT_24_HR)
                                        : ""
                                    }`}
                                  >
                                    {verifyObject(
                                      this.state.heightObject,
                                      "value",
                                      ""
                                    ) !== ""
                                      ? verifyObject(
                                          this.state.heightObject,
                                          "value",
                                          ""
                                        ) * 0.1 //multiplied by 0.1 as per BE for convert to cm from milimeter, same need to do while passing to API
                                      : "-"}
                                  </Tooltip>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <InputNumber
                                      min={100}
                                      max={250}
                                      type="number"
                                      value={this.state.height}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(e, "height")
                                      }
                                    />
                                    {errors && (
                                      <span className="validate-danger font-14">
                                        {errors.height}
                                      </span>
                                    )}
                                    {/* <Select
                                      allowClear={true}
                                      placeholder="Select Value"
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      showSearch={true}
                                      optionFilterProp="children"
                                      value={this.state.height}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(e, "height")
                                      }
                                    >
                                      {heightRangeArray(
                                        122,
                                        199,
                                        null,
                                        null
                                      ).map((item, index) => {
                                        return (
                                          <Option
                                            value={item.value}
                                            name="height_filters"
                                            key={item.value}
                                          >
                                            {`${item.label}`}
                                          </Option>
                                        );
                                      })}
                                    </Select> */}
                                    <span className="ml-1">cm</span>
                                  </div>
                                </td>
                              </tr>
                              <tr className="highlightedtext">
                                <td>Weight</td>
                                <td>
                                  <Tooltip
                                    placement="top"
                                    title={` ${
                                      verifyObject(
                                        this.state.weightObject,
                                        "created_at",
                                        ""
                                      ) !== ""
                                        ? moment(
                                            verifyObject(
                                              this.state.weightObject,
                                              "created_at",
                                              ""
                                            )
                                          ).format(DATE_FORMAT_24_HR)
                                        : ""
                                    }`}
                                  >
                                    {verifyObject(
                                      this.state.weightObject,
                                      "value",
                                      ""
                                    ) !== ""
                                      ? verifyObject(
                                          this.state.weightObject,
                                          "value",
                                          ""
                                        )
                                      : "-"}
                                  </Tooltip>
                                </td>
                                <td>
                                  <div className="d-flex align-items-center">
                                    <InputNumber
                                      min={25}
                                      max={250}
                                      type="number"
                                      value={this.state.weight}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(e, "weight")
                                      }
                                    />
                                    {errors && (
                                      <span className="validate-danger font-14">
                                        {errors.weight}
                                      </span>
                                    )}
                                    {/* <Select
                                      allowClear={true}
                                      placeholder="Select Weight"
                                      filterOption={(input, option) =>
                                        option.children
                                          .toLowerCase()
                                          .indexOf(input.toLowerCase()) >= 0
                                      }
                                      showSearch={true}
                                      optionFilterProp="children"
                                      value={this.state.weight}
                                      onChange={(e) =>
                                        this.handleHealthTextChange(e, "weight")
                                      }
                                      name="dropDown"
                                    >
                                      {arrayRange(40, 151, 1).map(
                                        (item, index) => {
                                          return (
                                            <Option
                                              value={item}
                                              key={item}
                                              name="height_filters"
                                            >
                                              {item}
                                            </Option>
                                          );
                                        }
                                      )}
                                    </Select> */}
                                    <span className="ml-1">kg</span>
                                  </div>
                                </td>
                              </tr>
                              {/* <tr className="highlightedtext">
                                <td>BMI</td>
                                <td>
                                  <Tooltip
                                    placement="top"
                                    title={` ${
                                      verifyObject(
                                        this.state.bmiObject,
                                        "created_at",
                                        ""
                                      ) !== ""
                                        ? moment(
                                            verifyObject(
                                              this.state.bmiObject,
                                              "created_at",
                                              ""
                                            )
                                          ).format(DATE_FORMAT_24_HR)
                                        : ""
                                    }`}
                                  >
                                    {verifyObject(
                                      this.state.bmiObject,
                                      "value",
                                      ""
                                    ) !== ""
                                      ? verifyObject(
                                          this.state.bmiObject,
                                          "value",
                                          ""
                                        )
                                      : "-"}
                                  </Tooltip>
                                </td>
                                <td>
                                  <Select
                                    allowClear={true}
                                    placeholder="Select BMI"
                                    filterOption={(input, option) =>
                                      option.children
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                    }
                                    showSearch={true}
                                    optionFilterProp="children"
                                    value={this.state.bmi}
                                    onChange={(e) =>
                                      this.handleHealthTextChange(e, "bmi")
                                    }
                                  >
                                    {arrayRange(10, 50, 1).map(
                                      (item, index) => {
                                        return (
                                          <Option
                                            value={item}
                                            key={item}
                                            name="height_filters"
                                          >
                                            {`${item}`}
                                          </Option>
                                        );
                                      }
                                    )}
                                  </Select>
                                </td>
                              </tr> */}
                            </tbody>
                          </table>
                        </div>
                      </Spin>
                    </div>
                  </div>
                </>
              }
              {dynamicInputs &&
                dynamicInputs.isConsentGiven &&
                isArray(dynamicInputs.isConsentGiven) &&
                dynamicInputs.isConsentGiven.map((code) => {
                  return (
                    <div className="padding-5 margin-right-10 margin-bottom-6 input-bg-f9f9f9 in-block div-border line-1">
                      <p className="margin-bottom-0 font-11 weight-500 in-block text-light-black margin-right-5">
                        <span>
                          {verifyObject(code, "code", null) !== null
                            ? code.code
                            : "--"}
                        </span>
                      </p>
                      <p className="margin-0 font-11 weight-400 in-block text-dark-gray">
                        {code.description}
                      </p>
                    </div>
                  );
                })}

              {/* {selectedSnomedCodesArray.length !== 0 && ( */}
              <div className="form-row push-10-b indication-input-wrapper">
                <div className="col-md-12">
                  <p className="font-11 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                    Diagnosis / Clinical Impressions
                  </p>
                  {dynamicInputs &&
                    dynamicInputs.isConsentGiven &&
                    isArray(dynamicInputs.isConsentGiven) &&
                    dynamicInputs.isConsentGiven.map((code) => {
                      return (
                        <div className="padding-5 margin-right-10 margin-bottom-6 input-bg-f9f9f9 in-block div-border line-1">
                          <p className="margin-bottom-0 font-11 weight-500 in-block text-light-black margin-right-5">
                            <span>
                              {verifyObject(code, "code", null) !== null
                                ? code.code
                                : "--"}
                            </span>
                          </p>
                          <p className="margin-0 font-11 weight-400 in-block text-dark-gray">
                            {code.description}
                          </p>
                        </div>
                      );
                    })}
                  <div className="consultaiondetail-main">
                    {/* //commented poup flow, extarct to main screen */}

                    {/* {selectedSnomedCodesArray.length !== 0 &&
                        selectedSnomedCodesArray &&
                        isArray(selectedSnomedCodesArray) &&
                        selectedSnomedCodesArray.map((code) => {
                          return (
                            <div className="padding-5 margin-right-10 margin-bottom-6 input-bg-f9f9f9 in-block div-border line-1">
                              <p className="margin-bottom-0 font-11 weight-500 in-block text-light-black margin-right-5">
                                <span>
                                  {verifyObject(code, "code", null) !== null
                                    ? code.code
                                    : "--"}
                                </span>
                              </p>
                              <p className="margin-0 font-11 weight-400 in-block text-dark-gray">
                                {code.description}
                              </p>
                            </div>
                          );
                        })} */}
                    <div className="in-block d-flex">
                      {/* <a
                          onClick={this._showCodeModal}
                          ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                          role="button"
                          tabIndex="0"
                          className="in-block height-auto-imp font-12-imp weight-400 padding-5-imp btn-custom btn-blue"
                        >
                          Add Code
                        </a> */}
                      {/* //commented poup flow, extarct to main screen */}

                      <form className="createtableform">
                        <div className="form-group">
                          <div className="form-row">
                            <div className="col-md-12">
                              <CreatableSelect
                                // isMulti
                                // className={`form-control margin-0 ${
                                //   errors && errors.snomedCodeId && "danger-border"
                                // } indication-dropdown`}
                                placeholder="Search and select..."
                                isClearable
                                onChange={(value) =>
                                  this._handleDropDownChange(
                                    value,
                                    "snomedCodeId"
                                  )
                                }
                                components={{DropdownIndicator: null}}
                                onInputChange={(value) => {
                                  this.setState({snomedCodeId: value});
                                  this.searchUpdate(value);
                                }}
                                onCreateOption={this._handleCustomCodeAdd}
                                value={
                                  this.state.snomedCodeId
                                    ? this.state.snomedCodeId
                                    : ""
                                }
                                inputValue={
                                  this.state.snomedCodeId
                                    ? this.state.snomedCodeId
                                    : ""
                                }
                                name="snomedCodeId"
                                options={snomedCodes}
                                isLoading={searching}
                                loadingMessage={() => (
                                  <span>Fetching Snomed ...</span>
                                )}
                              />
                              {/* <Select
                                  className={`form-control margin-0 ${
                                    errors &&
                                    errors.snomedCodeId &&
                                    "danger-border"
                                  } indication-dropdown`}
                                  name="snomedCodeId"
                                  onPopupScroll={this.handleScroll}
                                  allowClear={searching ? false : true}
                                  value={this.state.snomedCodeId}
                                  showSearch={true}
                                  onChange={(value) =>
                                    this._handleDropDownChange(
                                      value,
                                      "snomedCodeId"
                                    )
                                  }
                                  onSearch={(value) => {
                                    let searchValue = value;
                                    this.searchUpdate(searchValue);
                                  }}
                                  suffixIcon={
                                    !isLoadmore &&
                                    searching && (
                                      <Spin
                                        className="custom-dropdown-spinner"
                                        size="small"
                                      />
                                    )
                                  }
                                  notFoundContent={
                                    !searching &&
                                    snomedCodes.length === 0 && (
                                      <span>No codes available</span>
                                    )
                                  }
                                  filterOption={false}
                                  dropdownRender={(menus) => {
                                    return (
                                      <div ref={this.scrollDiv}>
                                        {menus}
                                        {isLoadmore && searching && (
                                          <div
                                            style={{
                                              textAlign: "center",
                                              padding: 10,
                                            }}
                                          >
                                            <Spin size="small" />
                                          </div>
                                        )}
                                      </div>
                                    );
                                  }}
                                  autoFocus
                                >
                                  {snomedCodes.map((k) => {
                                    return (
                                      <Option value={k.id} key={k.id}>
                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={
                                            verifyObject(
                                              k,
                                              "full_code",
                                              null
                                            ) !== null
                                              ? `${k.full_code}`
                                              : `-`
                                          }
                                        />
                                      </Option>
                                    );
                                  })}
                                </Select> */}
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 bg-white push-10-b coding_modal_table push-10-t">
                          <table className="table live-talk2-table">
                            <thead>
                              <tr>
                                <th className="font-10 weight-500 text-black">
                                  Code
                                </th>
                                <th className="font-10 weight-500 text-black">
                                  Description
                                </th>
                                <th className="font-10 weight-500 text-black"></th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedCodesArray &&
                                selectedCodesArray.length === 0 && (
                                  <tr className="ng-hide">
                                    <td
                                      colSpan="3"
                                      className="text-center text-dark-gray font-10 weight-400 default-padding"
                                    >
                                      No codes selected
                                    </td>
                                  </tr>
                                )}
                              {selectedCodesArray &&
                                selectedCodesArray.length > 0 &&
                                selectedCodesArray.map((code) => {
                                  console.log("map snomed codes", code);
                                  return (
                                    <tr>
                                      <td className="font-10 weight-400 default-padding text-light-black">
                                        {code.code}
                                      </td>
                                      <td className="font-10 weight-400 default-padding text-light-black">
                                        {code.description}
                                      </td>
                                      <td className="font-10 weight-400 default-padding text-light-black text-right relative">
                                        {" "}
                                        <span
                                          onClick={() => this._removeCode(code)}
                                          className="text-right text-dark-blue weight-400 hide_outline"
                                          ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                                          role="button"
                                          tabIndex="0"
                                        >
                                          <img
                                            src={deleteImage}
                                            alt="Add Coding"
                                            height="12"
                                            width="12"
                                          />
                                        </span>
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>

                        {/* <div className="form-group">
                            <div className="form-row">
                              <div className="col-md-12">
                                <label htmlFor="sel1">
                                  Feel free to freetext your Clinical
                                  Impressions
                                </label>
                                <Input
                                  className={`form-control margin-0 placeholder-10 addcomment ${
                                    errors &&
                                    errors.snomedCodeId &&
                                    "danger-border"
                                  }`}
                                  name="snomedCodeText"
                                  value={snomedCodeText}
                                  onChange={this._handleTextChange}
                                  suffix={
                                    <img
                                      width={12}
                                      className={`cursor-pointer ${
                                        isEmpty(snomedCodeText) ? "d-none" : ""
                                      }`}
                                      src={addImage}
                                      alt="Add"
                                      onClick={this._handleCustomCodeAdd}
                                    />
                                  }
                                />
                                {this.state.errors && (
                                  <span className="validate-danger font-14">
                                    {this.state.errors.snomedCodeId}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              {/* )} */}

              {/* {!isEmpty(managementPlan) && ( */}
              <div className="form-row push-10-b">
                <div className="col-md-12 consultant_review">
                  <p className="font-11 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    Management Plan
                  </p>

                  <Input.TextArea
                    autoSize={true}
                    type="text"
                    className={`form-control-textarea input-bg-f9f9f9 form-control ${
                      errors && errors.managementPlan && "danger-border"
                    } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus`}
                    name="managementPlan"
                    value={managementPlan}
                    onChange={this._handleTextChange}
                    placeholder="Add your Management Plan"
                  />
                  {errors && (
                    <span className="validate-danger font-14">
                      {errors.managementPlan}
                    </span>
                  )}
                </div>
              </div>
              {/* )} */}
              {presriptionPlan && presriptionPlan !== "" && (
                <div className="form-row push-10-b">
                  <div className="col-md-12 consultant_review">
                    <p className="font-11 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                      Prescription Plan
                    </p>

                    <Input.TextArea
                      autoSize={true}
                      type="text"
                      className={`form-control-textarea input-bg-f9f9f9 form-control ${
                        errors && errors.managementPlan && "danger-border"
                      } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus`}
                      name="presriptionPlan"
                      value={presriptionPlan}
                      // onChange={this._handleTextChange}
                      // disabled
                      placeholder="Add your Management Plan"
                      // autoFocus
                    />
                    {errors && (
                      <span className="validate-danger font-14">
                        {errors.managementPlan}
                      </span>
                    )}
                  </div>
                </div>
              )}
              {!isEmpty(barcodeNumber) && (
                <div className="form-row push-10-b">
                  <div className="col-md-12 consultant_review">
                    <p className="font-11 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                      BARCODE NUMBER
                    </p>
                    <Input.TextArea
                      autoSize={true}
                      // multiple
                      type="text"
                      className={`form-control-textarea input-bg-f9f9f9 form-control ${
                        errors && errors.barcodeNumber && "danger-border"
                      } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus`}
                      name="barcodeNumber"
                      value={barcodeNumber}
                      onChange={this._handleTextChange}
                      placeholder="Enter BARCODE Number"
                    />
                    {errors && (
                      <span className="validate-danger font-14">
                        {errors.barcodeNumber}
                      </span>
                    )}
                  </div>
                </div>
              )}
            </div>
            {/* {(isAllEmpty || isShowSubmit === false || isShowSubmit === "") &&
              !this.props.show_submit_from_notes &&
              !this.state.showAddSkipbutton && (
                <div className="push-10-t">
                  <button
                    onClick={() => {
                      // if (isEmpty(history)) {
                      //   this.handleDynamicKey(90, "WalkinAppointmentContainer");
                      // }

                      if (isEmpty(history) && isEmpty(examination)) {
                        this.handleDynamicKey(90, "WalkinAppointmentContainer");
                      } else if (isEmpty(examination)) {
                        this.handleDynamicKey(91, "ExaminationContainer");
                      } else if (
                        (!isEmpty(examination) && isEmpty(managementPlan)) ||
                        !isEmpty(managementPlan)
                      ) {
                        this.handleDynamicKey(92, "DignosisContainer");
                      } else if (
                        isEmpty(managementPlan) &&
                        isEmpty(examination)
                      ) {
                        this.handleDynamicKey(93, "ManagementPlanContainer");
                        this.setState({ showAddSkipbutton: true });
                      } else {
                        // if (
                        //   this.props.show_prescriotion_modal_from_notes === null
                        // ) {
                        //   this.handleDynamicKey(97, "PrescriptionsContainer"); //?NOTE: on next frommanagement it should redirect to prescription modal open,
                        //   store.dispatch({
                        //     type: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
                        //     payload: true,
                        //   });
                        // }
                        // this.setState({showAddSkipbutton: true});
                      }
                      if (
                        !isEmpty(examination) &&
                        !isEmpty(history) &&
                        !isEmpty(managementPlan)
                      ) {
                        // this.setState({isShowSubmit: true}, () => {
                        //   this._SaveComponentStateToRedux();
                        // });
                        this.setState({ showAddSkipbutton: true });
                      }
                    }}
                    // onClick={() => this.handleDynamicKey(93, "management_plan")} //91 is sidebar menu key
                    disabled={isEmpty(examination)}
                    className="btn btn-custom btn-blue font-10 weight-500 submitBtn width-100px height-30 p-0"
                  >
                    Next
                  </button>
                </div>
              )} */}
            {/* {this.state.showAddSkipbutton &&
              (this.props.show_prescriotion_modal_from_notes ||
                this.props.show_prescriotion_modal_from_notes === null) && ( */}
            <div className="push-10-t">
              {(this.props.show_prescriotion_modal_from_notes ||
                this.props.show_prescriotion_modal_from_notes === null) && (
                <button
                  onClick={this.handleAddPrescription}
                  // disabled={
                  //   managementPlan.trim() === "" || managementPlan === ""
                  // }
                  className="btn btn-custom btn-blue font-10 weight-500 submitBtn width-100px height-30 p-0  mr-1"
                >
                  Add Prescription
                </button>
              )}
              {(this.props.userRole === ROLE_DOCTOR ||
                this.props.userRole === ROLE_CLINICAL_PHARMACIST) &&
                // (isShowSubmit || this.props.show_submit_from_notes) &&
                (!this.props.is_from_patient_search_listing ||
                  this.props.is_from_patient_search_listing === null) && (
                  // !isEmpty(history) &&
                  // !isEmpty(examination) &&
                  // !isEmpty(managementPlan) &&(
                  // <div className="push-10-t">
                  <button
                    disabled={this.props.offlineCallStatus !== "finish"}
                    onClick={this._handleSubmit}
                    className="btn btn-custom btn-blue font-10 weight-500 submitBtn width-100px height-30 p-0"
                  >
                    Submit
                  </button>
                  // </div>
                )}
              {(this.props.userRole === ROLE_CLINICAL_PHARMACIST ||
                this.props.userRole === ROLE_DOCTOR) &&
                this.props.is_from_patient_search_listing && (
                  // (isShowSubmit || this.props.show_submit_from_notes) &&
                  // !isEmpty(history) &&
                  // !isEmpty(examination) &&
                  // !isEmpty(managementPlan) &&
                  // <div className="push-10-t">
                  <button
                    // disabled={this.props.offlineCallStatus !== "finish"}
                    onClick={this._handleSubmit}
                    className="btn btn-custom btn-blue font-10 weight-500 submitBtn width-100px height-30 p-0"
                  >
                    Submit
                  </button>
                  // </div>
                )}
            </div>
            {/* )} */}
            {
              <div className="commnspace">
                <PreEncountersContainer fromConsultation={true} />
              </div>
            }
          </div>
          <InformationalOutcomeModal
            _handleSubmit={this._handleModalSubmission}
            _hideModal={this._hideModal}
            visible={visible}
            changing={addingDetail}
            _handleTextChange={this._handleTextChange}
            _handleUpdateoutcome={this._handleUpdateoutcome}
            {...this.state}
          />
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    userRole: verifyObject(state.localStore, "user.role", null),
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    isPrescriptionAdded: verifyObject(
      state.localStore,
      "is_prescription_added.is_presription_added",
      null
    ),
    isOwnPharamcy: verifyObject(
      state.localStore,
      "is_prescription_added.is_own_pharamcy",
      false
    ),
    prescriptionCode: verifyObject(
      state.localStore,
      "is_prescription_added.prescription_code",
      null
    ),
    patientDOB: verifyObject(
      state.localStore,
      "patient_detail_obj.date_of_birth",
      null
    ),
    show_submit_from_notes: verifyObject(
      state,
      "dynamicTab.show_submit_from_notes",
      false
    ),
    // dynamicInputs: verifyObject(state.localStore, "dynamicInputs", null),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    dynamicHealthInputs: verifyObject(
      state,
      "dynamicTab.dynamicHealthInputs",
      null
    ),
    systolic_bp: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.systolic_bp",
      ""
    ),
    diastolic_bp: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.diastolic_bp",
      ""
    ),
    pulse_per_minute: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.pulse_per_minute",
      ""
    ),
    blood_oxygen_saturation: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.blood_oxygen_saturation",
      ""
    ),
    body_temperature: verifyObject(
      state.dynamicTab,
      "dynamicHealthInputs.body_temperature",
      ""
    ),
    height: verifyObject(state.dynamicTab, "dynamicHealthInputs.height", ""),
    weight: verifyObject(state.dynamicTab, "dynamicHealthInputs.weight", ""),
    bmi: verifyObject(state.dynamicTab, "dynamicHealthInputs.bmi", ""),
    offlineCallStatus: verifyObject(
      state.localStore,
      "offline_call_status",
      null
    ),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    show_prescriotion_modal_from_notes: verifyObject(
      state,
      "dynamicTab.show_prescriotion_modal_from_notes",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onSetSidebarKey,
        onSetDynamicInput,
        onSetDynamicHealthInput,
        onRequestSetComponentID,
      },
      dispatch
    ),
    navigationActions: bindActionCreators({navigateTo}, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExaminationContainer);

export default ConnectedComponent;

/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {Fragment} from "react";
import Layout from "Layouts/PageLayout";
import {connect} from "react-redux";
import {Collapse, Spin, Tag, Tooltip} from "antd";
import Timer from "react-compound-timer";

import {appRoutesConst} from "../../app/navigation";
import {bindActionCreators} from "redux";
import {
  onRequestSetComponent,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import {actions as patientDetailActions} from "../../reducers/patientDetail";
import {actions as actionCableDataActions} from "../../reducers/actionCableData";
// import prescreeningquestionnaire from "assets/images/sidebar/ic-prescreeningquestionnaire.svg";

import {navigateTo} from "../../app/navigation";

import PatientDetailContainer from "../../containers/PatientDetailContainer/PatientDetailContainer";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";

import MedicalHistoryContainer from "../../containers/PatientDetailContainer/MedicalHistoryContainer/MedicalHistoryContainer";
import AllergiesContainer from "../../containers/PatientDetailContainer/AllergiesContainer/AllergiesContainer";
import AcuteMedicationsContainer from "../../containers/PatientDetailContainer/AcuteMedicationsContainer/AcuteMedicationsContainer";
import NHSContainer from "../../containers/PatientDetailContainer/NHSContainer/NHSContainer";
import FamilyRecordsContainer from "../../containers/PatientDetailContainer/FamilyRecordsContainer/FamilyRecordsContainer";
import TestInvestigationContainer from "../../containers/PatientDetailContainer/TestInvestigationContainer/TestInvestigationContainer";
import ReportsContainer from "../../containers/PatientDetailContainer/ReportsContainer/ReportsContainer";
import SpecialNotesContainer from "../../containers/PatientDetailContainer/SpecialNotesContainer/SpecialNotesContainer";
import PreEncountersContainer from "../../containers/PatientDetailContainer/PreEncountersContainer/PreEncountersContainer";
import PastOrdersContainer from "../../containers/PatientDetailContainer/PastOrdersContainer/PastOrdersContainer";

import "assets/css/pages/profile-detail.css";
import doctor_profile from "assets/images/header/doctor_profile.svg";
import topbarback from "assets/images/profile_header/topbar-back.svg";
import {Prompt, withRouter} from "react-router-dom";
import ScriptReviewContainer from "../../containers/ScriptReviewDetailContainer/ScriptReviewDetailContainer";
import moment from "moment";
// import plus_blue from "assets/images/common/add-ic.png";
// import minus_blue from "assets/images/common/delete-ic.png";
import calendar_image from "assets/images/common/calender.png";
import time_image from "assets/images/common/time.svg";

import {getPastMedications} from "../../services/orders";
import {isArray} from "lodash";
import ScriptReviewDetailContainer from "../../containers/ScriptReviewDetailContainer/ScriptReviewDetailContainer";
import CollapsiableSubHeader from "../../components/common/CollapsiableSubHeader/CollapsiableSubHeader";
import {
  DATE_FORMAT,
  DATE_FORMAT_24_HR,
  T24_HR_FORMAT,
} from "../../constants/common";
// import screeningquestionnaire from "assets/images/sidebar/ic-prescreeningquestionnaire.svg";
// import patientdetails from "assets/images/sidebar/ic-patient-details.svg";
// import medicalhistory from "assets/images/sidebar/ic-medical-history.svg";
// import testinvestigation from "assets/images/sidebar/ic-test.svg";
// import allergies from "assets/images/sidebar/ic-allergies-sensitivities.svg";
// import currentmedication from "assets/images/sidebar/ic-medication-history.svg";
// import reports from "assets/images/sidebar/ic-reports.svg";
// import specialnotes from "assets/images/sidebar/ic-specialnotes.svg";
// import familyhistory from "assets/images/sidebar/ic-family-history.svg";
// import previousencounters from "assets/images/sidebar/ic-previous-encounters.svg";
// import nhs from "assets/images/sidebar/ic-nhs.svg";
// import pastorders from "assets/images/sidebar/ic-past-orders.svg";
import PreQuestionnaireContainer from "../../containers/PatientDetailContainer/PreQuestionnaireContainer/PreQuestionnaireContainer";
// import prescription from "assets/images/sidebar/ic-prescription.svg";
// import referral from "assets/images/sidebar/ic-referral.svg";
// import sicknote from "assets/images/sidebar/ic-sicknote.svg";
// import consultation from "assets/images/sidebar/ic-consultation.svg";
// import screenshot from "assets/images/sidebar/ic-screenshots-uploads.svg";
import ScreenshotContainer from "containers/PatientConsultationConatainers/ScreenshotContainer/ScreenshotContainer";
import SickNotesContainer from "../../containers/PatientDetailContainer/SickNotesContainer/SickNotesContainer";
import PrescriptionsContainer from "../../containers/PatientDetailContainer/PrescriptionsContainer/PrescriptionsContainer";
import ReferralNotesContainer from "../../containers/PatientDetailContainer/ReferralNotesContainer/ReferralNotesContainer";
import {
  InfoCircleOutlined,
  PlusOutlined,
  MinusOutlined,
} from "@ant-design/icons";
import history from "assets/images/sidebar/ic-history.svg";
import WalkinAppointmentContainer from "../../containers/WalkinAppointment/WalkinAppointmentContainer";
import HealthAssessmentContainer from "containers/PatientDetailContainer/HealthAssessmentContainer/HealthAssessmentContainer";
import {
  AllergicmenuIcon,
  FamilyhistorymenuIcon,
  HealthassessmentIcon,
  MediacalhistorymenuIcon,
  MedicationHistorymenuIcon,
  PatientdetailsIcon,
  QuestionnairemenuIcon,
  SpecialnotemenuIcon,
  TestinvestigatemenuIcon,
} from "pages/WalkinAppointments/VideoAppointmentPage";
import {HistorymenuIcon} from "pages/QuestionnaireReview/QuestionnaireReviewDetailPage";
import photo_id from "assets/images/profile_header/id-card.svg";
import ImageSliderModal from "pages/WalkinAppointments/ImageSliderModal";
import {addCallNotesAPI, verifyPatientAPI} from "services/patients";

const {Panel} = Collapse;
//svg images for sidebar
//sidebar svg menu icon functions

//sidebar svg menu icon functions

// function callback(key) {}
const status = {
  rejected: "Rejected",
  system_rejected: "System Rejected",
  approved: "Approved",
};
class ScriptReviewDetailPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: "ScriptReviewDetailContainer",
      reviewDuration: localStorage.getItem("patient_review_duration"),
      recordId: localStorage.getItem("record_id"),
      pastMedications: [],
    };
    [
      "_getIndexRoute",
      "_renderDynamicComponent",
      "_handleNavigation",
      "_handleBack",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  async componentDidMount() {
    this._getIndexRoute();
    let {componentName} = this.props;
    this.setState({componentName});
    let scriptReviewStatus = localStorage.getItem("scriptReviewStatus");

    if (
      scriptReviewStatus !== "doctor_approved" &&
      scriptReviewStatus !== "doctor_declined"
    ) {
      await this.props.actionCableDataActions.ScriptReviewChannel({
        id: this.state.recordId,
      });
    }
  }

  async componentWillUnmount() {
    await this.props.actionCableDataActions.unsubscribe();
    // await this.props.patientDetailActions.resetLocalState();
    // this._pageBlocks();
  }

  _getIndexRoute() {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent("ScriptReviewDetailContainer");
      onSetSidebarKey(["1"]);
    }
  }

  setDynamicRoute = (componentName) => {
    let {onRequestSetComponent} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(componentName);
    }
  };

  componentWillReceiveProps(nextProps) {
    let {componentName} = nextProps;
    this.setState({componentName});
  }

  _getPatientDetails = (patient_id) => {
    let {
      patientId,
      patientDetailActions: {onRequest},
    } = this.props;
    if (typeof onRequest === "function") {
      onRequest({id: patient_id ? patient_id : patientId});
    }
  };

  _renderDynamicComponent() {
    let {componentName} = this.state;
    let scriptReviewData = JSON.parse(
      localStorage.getItem("script_review_obj")
    );
    switch (componentName) {
      case "PatientDetailContainer":
        let {patientDetail, isLoadingDetail} = this.props;
        return (
          <PatientDetailContainer
            isLoadingDetail={isLoadingDetail}
            patientDetail={patientDetail}
            _getPatientDetails={this._getPatientDetails}
            patientId={this.props.patientId}
          />
        );
      case "WalkinAppointmentContainer":
        return (
          <div>
            <WalkinAppointmentContainer />
          </div>
        );
      case "HealthAssessmentContainer":
        return <HealthAssessmentContainer />;
      case "MedicalHistoryContainer":
        return <MedicalHistoryContainer />;

      case "AllergiesContainer":
        return <AllergiesContainer />;

      case "AcuteMedicationsContainer":
        return <AcuteMedicationsContainer />;

      case "NHSContainer":
        return <NHSContainer />;

      case "FamilyRecordsContainer":
        return <FamilyRecordsContainer />;

      case "TestInvestigationContainer":
        return <TestInvestigationContainer />;

      case "ScriptReviewContainer":
        return <ScriptReviewContainer />;

      case "ReportsContainer":
        return <ReportsContainer />;
      case "PrescriptionsContainer":
        return <PrescriptionsContainer />;
      case "ScreenshotContainer":
        return <ScreenshotContainer />;
      case "SickNotesContainer":
        return <SickNotesContainer />;
      case "SpecialNotesContainer":
        return <SpecialNotesContainer />;
      case "PreQuestionnaireContainer":
        return <PreQuestionnaireContainer />;
      case "PreEncountersContainer":
        return <PreEncountersContainer />;
      case "PastOrdersContainer":
        return <PastOrdersContainer />;
      case "ReferralNotesContainer":
        return <ReferralNotesContainer />;
      case "ScriptReviewDetailContainer":
        return (
          <ScriptReviewDetailContainer
            recordId={scriptReviewData ? scriptReviewData.id : null}
          />
        );

      default:
        return (
          <ScriptReviewDetailContainer
            recordId={scriptReviewData ? scriptReviewData.id : null}
          />
        );
    }
  }

  _handleNavigation() {
    let {navigateTo} = this.props.navigateTo;
    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.patientSearch);
    }
  }

  async _handleBack() {
    let {
      history: {goBack},
    } = this.props;
    let status = localStorage.getItem("scriptReviewStatus");
    let {onSetSidebarKey} = this.props.dynamicTabAction;
    // alert("ScriptReviewContainerApproved")
    if (status === "doctor_queried") {
      onRequestSetComponent("ScriptReviewContainerNew");
      if (typeof onSetSidebarKey === "function") {
        onSetSidebarKey([`SR1`]);
      }
    } else if (status === "doctor_approved") {
      onRequestSetComponent("ScriptReviewContainerNew");
      if (typeof onSetSidebarKey === "function") {
        onSetSidebarKey([`SR1`]);
      }
    } else if (status === "doctor_declined") {
      onRequestSetComponent("ScriptReviewContainerNew");
      if (typeof onSetSidebarKey === "function") {
        onSetSidebarKey([`SR1`]);
      }
    } else if (status === "new") {
      onRequestSetComponent("ScriptReviewContainerNew");
      if (typeof onSetSidebarKey === "function") {
        onSetSidebarKey([`SR1`]);
      }
    }
    goBack();
  }

  handleCollapse = async (e) => {
    let scriptReviewData = JSON.parse(
      localStorage.getItem("script_review_obj")
    );
    if (scriptReviewData) {
      let payload = {
        user_id: verifyObject(scriptReviewData, "patient_id", null),
        order_type: "private",
        drug_id: verifyObject(
          scriptReviewData,
          "order_item.core_drug_id",
          null
        ),
      };
      if (e && e.length !== 0) {
        // alert("Call Api");
        try {
          let response = await getPastMedications(payload);
          this.setState({
            pastMedications: verifyObject(response, "data.data", []),
          });
        } catch (error) {
          const {message} = getErrorObject(error);
          await this.setState({searching: false});
          errorToast({content: message});
        }
      }
    }
  };
  _clearComponents = () => {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(null);
      onSetSidebarKey(null);
    }
  };
  renderStatus = (patientDetail) => {
    console.log("RENDER FOR STATUS----", patientDetail);
    const status = verifyObject(patientDetail, "user.status");

    switch (status) {
      case "active":
        return "Verified";
      case "unverified":
        return "Unverified";
      case "rejected":
        return "Rejected";
      default:
        return "";
    }
  };
  verifyPatientFunction = async (patient_id) => {
    try {
      this.setState({verifyingPatient: true});
      let verifyParam = {user: {status: "active"}};

      let callNoteParam = {
        patient_id,
        note: "Verification",
        note_type: "Verification",
        end_call: true,
        call_id: patient_id,
        call_type: "User",
        call_flag: 1,
      };
      const responsePatient = await verifyPatientAPI(verifyParam, patient_id);
      const responseNote = await addCallNotesAPI(callNoteParam);
      this._getPatientDetails(patient_id);

      successToast({
        content: verifyObject(
          responsePatient,
          "data.message",
          "Verified Succsess"
        ),
      });
      successToast({
        content: verifyObject(responseNote, "data.message", "Call notes saved"),
      });
      console.log("BOTH REPONSE---", responsePatient, responseNote);
      this.setState({verifyingPatient: false, visible: false});
    } catch (error) {
      this.setState({verifyingPatient: false});
      const {message} = getErrorObject(error);

      errorToast({content: message});
    }
  };
  render() {
    let {patientDetail, isLoadingDetail} = this.props;

    const sideBarArray = [
      {
        label: "Patient Details",
        path: appRoutesConst.patientDetail,
        icon: (
          // <img
          //   alt="Patient Details"
          //   title="Patient Details"
          //   src={patientdetails}
          // ></img>
          <PatientdetailsIcon width="12px" fill="#757575" />
        ),
        key: 2,
        page: "PatientDetailContainer",
        useDynamicComponent: true,
      },
      {
        label: "Summary",
        path: appRoutesConst.dynamicQuestionnaireReviewDetail,
        icon: <FamilyhistorymenuIcon width="13px" fill="#757575" />,

        key: 7,
        page: "FamilyRecordsContainer",
        useDynamicComponent: true,
        hasSubmenu: true,
        submenu: [
          {
            label: "Medication History",
            path: appRoutesConst.patientDetail,
            icon: <MedicationHistorymenuIcon width="12px" fill="#757575" />,
            key: 510,
            page: "AcuteMedicationsContainer",
            useDynamicComponent: true,
          },
          {
            label: "Problems / Conditions",
            path: appRoutesConst.patientDetail,
            icon: <MediacalhistorymenuIcon width="12px" fill="#757575" />,
            key: 3,
            page: "MedicalHistoryContainer",
            useDynamicComponent: true,
          },
          {
            label: "Family Records",
            path: appRoutesConst.patientDetail,
            icon: <FamilyhistorymenuIcon width="12px" fill="#757575" />,
            key: 7,
            page: "FamilyRecordsContainer",
            useDynamicComponent: true,
          },
          {
            label: "Allergies and Sensitivities",
            path: appRoutesConst.patientDetail,
            icon: <AllergicmenuIcon width="12px" fill="#757575" />,
            page: "AllergiesContainer",
            key: 4,
            useDynamicComponent: true,
          },
          {
            label: "Test/Investigation",
            path: appRoutesConst.patientDetail,
            icon: <TestinvestigatemenuIcon width="12px" fill="#757575" />,
            page: "TestInvestigationContainer",
            key: 8,
            useDynamicComponent: true,
          },
        ],
      },
      {
        label: "Health Assessments",
        path: appRoutesConst.patientConsultationDetail,
        icon: <HealthassessmentIcon width="14" fill="#757575" />,
        page: "HealthAssessmentContainer",
        key: 112,
        useDynamicComponent: true,
      },
      {
        label: "Script Review Questionnaire",
        path: appRoutesConst.patientDetail,
        icon: (
          // <img
          //   alt="Script Review Questionnaire"
          //   src={screeningquestionnaire}
          // ></img>
          <QuestionnairemenuIcon width="12px" fill="#757575" />
        ),
        page: "ScriptReviewDetailContainer",
        key: 1,
        useDynamicComponent: true,
      },

      {
        label: "Consultations",
        path: appRoutesConst.walkinAppointment,
        icon: <HistorymenuIcon width="12px" fill="#757575" />,
        key: 1002,
        page: "WalkinAppointmentContainer",
        component_id: "patient_detail",
        useDynamicComponent: true,
        hasSubmenu: true,
        submenu: [
          {
            label: "Consultation Detail",
            component_id: "history",
            path: appRoutesConst.walkinAppointment,
            icon: <img alt="History" title="History" src={history}></img>,
            key: 12,
            page: "WalkinAppointmentContainer",
            useDynamicComponent: true,
          },
        ],
      },
      // {
      //   label: "Previous Consultations",
      //   path: appRoutesConst.patientConsultationDetail,
      //   icon: (
      //     // <img
      //     //   alt="Consultation Detail"
      //     //   title="Consultation Detail"
      //     //   src={consultation}
      //     // />
      //     <PreviousconsultIcon width="12px" fill="#757575" />
      //   ),
      //   key: 1002,
      //   page: "PatientConsultationContainer",
      //   useDynamicComponent: true,
      //   hasSubmenu: true,
      //   submenu: [
      //     {
      //       label: "Pre. Encounters",
      //       path: appRoutesConst.patientConsultationDetail,
      //       icon: <PreviousencounterIcon width="12px" fill="#757575" />,
      //       page: "PreEncountersContainer",
      //       key: 12,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Prescriptions",
      //       path: appRoutesConst.patientConsultationDetail,
      //       icon: <PrescriptionsIcon width="8px" fill="#757575" />,
      //       page: "PrescriptionsContainer",
      //       key: 13,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Sick Notes",
      //       path: appRoutesConst.patientConsultationDetail,
      //       icon: <SicknotemenuIcon width="12px" fill="#757575" />,
      //       page: "SickNotesContainer",
      //       key: 14,
      //       useDynamicComponent: true,
      //     },
      //     {
      //       label: "Referral Notes",
      //       path: appRoutesConst.patientConsultationDetail,
      //       icon: <ReferralcodemenuIcon width="12px" fill="#757575" />,
      //       page: "ReferralNotesContainer",
      //       key: 15,
      //       useDynamicComponent: true,
      //     },

      //     {
      //       label: "Screenshot",
      //       path: appRoutesConst.patientConsultationDetail,
      //       icon: <ScreenshotmenuIcon width="12px" fill="#757575" />,
      //       page: "ScreenshotContainer",
      //       key: 5,
      //       useDynamicComponent: true,
      //     },
      //   ],
      // },

      // {
      //   label: "Pre-Screening Questionnaire",
      //   path: appRoutesConst.patientDetail,
      //   icon: (
      //     // <img
      //     //   alt="Pre-Screening Questionnaire"
      //     //   src={prescreeningquestionnaire}
      //     // ></img>
      //     <QuestionnairemenuIcon width="12px" fill="#757575" />
      //   ),
      //   page: "PreQuestionnaireContainer",
      //   key: 13,
      //   useDynamicComponent: true,
      // },
      // {
      //   label: "Reports",
      //   path: appRoutesConst.patientDetail,
      //   icon: <ReportmenuIcon width="12px" fill="#757575" />,
      //   page: "ReportsContainer",
      //   key: 9,
      //   useDynamicComponent: true,
      // },
      {
        label: (
          <span>
            Special Notes
            <span className="sidebar-notification-badge">
              {patientDetail && patientDetail.special_notes_count}
            </span>
          </span>
        ),
        path: appRoutesConst.patientDetail,
        icon: <SpecialnotemenuIcon width="12px" fill="#757575" />,
        page: "SpecialNotesContainer",
        key: 10,
        useDynamicComponent: true,
      },
      // {
      // 	label: 'Pre. Encounters',
      // 	path: appRoutesConst.patientDetail,
      // 	icon: <img alt='Pre. Encounters' src={previousencounters}></img>,
      // 	page: 'PreEncountersContainer',
      // 	key: 11,
      // 	useDynamicComponent: true,
      // },
      // {
      //   label: "Past Orders",
      //   path: appRoutesConst.patientDetail,
      //   icon: <PastordermenuIcon width="12px" fill="#757575" />,
      //   page: "PastOrdersContainer",
      //   key: 1256,
      //   useDynamicComponent: true,
      // },
    ];
    let {pastMedications} = this.state;
    let scriptReviewData = JSON.parse(
      localStorage.getItem("script_review_obj")
    );
    let scriptReviewStatus = localStorage.getItem("scriptReviewStatus");

    return (
      <Layout sideBarArray={sideBarArray}>
        <Prompt
          message={async (location, action) => {
            if (action === "PUSH") {
              let {onRequestSetComponent, onSetSidebarKey} =
                this.props.dynamicTabAction;
              if (typeof onRequestSetComponent === "function") {
                onRequestSetComponent(null);
                onSetSidebarKey(null);
              }
              return true;
            }
            if (action === "POP") {
              let status = localStorage.getItem("scriptReviewStatus");
              let {onSetSidebarKey} = this.props.dynamicTabAction;
              if (status === "doctor_queried") {
                await this.setDynamicRoute("ScriptReviewContainerNew");
                if (typeof onSetSidebarKey === "function") {
                  await onSetSidebarKey([`SR1`]);
                }
                await localStorage.removeItem("scriptReviewStatus");
                // await goBack();
              } else if (status === "doctor_approved") {
                await this.setDynamicRoute("ScriptReviewContainerNew");
                if (typeof onSetSidebarKey === "function") {
                  await onSetSidebarKey([`SR1`]);
                }
                await localStorage.removeItem("scriptReviewStatus");
                // await goBack();
              } else if (status === "doctor_declined") {
                await this.setDynamicRoute("ScriptReviewContainerNew");
                if (typeof onSetSidebarKey === "function") {
                  await onSetSidebarKey([`SR1`]);
                }
                await localStorage.removeItem("scriptReviewStatus");
                // await goBack();
              } else {
                await this.setDynamicRoute("ScriptReviewContainerNew");
                if (typeof onSetSidebarKey === "function") {
                  await onSetSidebarKey([`SR1`]);
                }
                await localStorage.removeItem("scriptReviewStatus");
                // await goBack();
              }
              return true;
            }
            return true;
          }}
        />
        <div className="rightmainsection scriptreviewmain_wrapper">
          <div className="container-fluid padding-0">
            <div className="row margin-0">
              <div className="container-fluid selection_container padding-left-10 padding-right-10">
                <div className="scriptreview_detail_header padding-1-b">
                  <div className="scriptreviewheaderleft relative">
                    <div className="patient_backarrow push-10-r in-block push-5-t">
                      <img
                        className="cursor-pointer"
                        onClick={this._handleBack}
                        src={topbarback}
                        alt="Go Back"
                        title="Go Back"
                      ></img>
                    </div>
                    <div className="consultant_profile push-5-r in-block">
                      {patientDetail && patientDetail.image ? (
                        <img
                          src={verifyObject(
                            patientDetail,
                            "image",
                            doctor_profile
                          )}
                          alt=""
                          className="width-30px"
                        ></img>
                      ) : (
                        <img
                          src={
                            require("../../assets/images/common/doctor_profile.svg")
                              .default
                          }
                          alt=""
                          className="width-30px"
                        ></img>
                      )}
                    </div>

                    <div className="in-block padding-right-15">
                      {isLoadingDetail ? (
                        <Spin size="small" />
                      ) : (
                        <div className="push-5-l d-flex flex-wrap align-items-center gapx-1">
                          <div className="font-12 weight-400 text-capitalize in-block text-black d-flex flex-wrap align-items-center gapx-1">
                            {verifyObject(patientDetail, "first_name", "-- ")}
                            {verifyObject(patientDetail, "last_name", "-- ")}
                            {verifyObject(patientDetail, "user.status") ? (
                              <span
                                className={`custom-${
                                  verifyObject(patientDetail, "user.status") ===
                                  "unverified"
                                    ? "danger"
                                    : "bluelabel"
                                } font-10 in-block text-capitalize`}
                              >
                                {this.renderStatus(patientDetail)}
                              </span>
                            ) : null}
                            <div
                              className="in-block"
                              onClick={() => {
                                this.setState({visible: true});
                              }}
                            >
                              <a className="text-black weight-400 text-capitalize photo_id font-12 cursor-pointer hover-blue d-flex align-items-center gapx-1">
                                <img
                                  alt="Photo ID"
                                  title="Photo ID"
                                  src={photo_id}
                                ></img>{" "}
                                Photo ID
                              </a>
                            </div>
                          </div>
                          <div className="font-12 weight-400 text-capitalize in-block text-dark-gray">
                            |
                          </div>
                          <div className="font-12 weight-400 text-capitalize in-block text-dark-">
                            {verifyObject(patientDetail, "age", null) !== null
                              ? `${patientDetail.age} Yr`
                              : ""}
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="in-block padding-right-15">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray push-5-t">
                        <span className="text-light-black">Gender:</span> Male
                      </div>
                    </div>
                    <div className="in-block padding-right-15">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray push-5-t">
                        <span className="text-light-black">DOB:</span>{" "}
                        {verifyObject(patientDetail, "date_of_birth", null) !==
                        null
                          ? `${moment(patientDetail.date_of_birth).format(
                              DATE_FORMAT
                            )}`
                          : ""}
                      </div>
                    </div>
                    <div className="in-block">
                      <div className="font-12 weight-400 text-capitalize in-block text-dark-gray push-5-t">
                        <span className="text-light-black">Corporate:</span>{" "}
                        {verifyObject(patientDetail, "corporate_name", null) !==
                        null
                          ? `${patientDetail.corporate_name}`
                          : ""}
                      </div>
                    </div>

                    <div className="scriptreview_collapse in-block float-right push-5-t">
                      <CollapsiableSubHeader patientDetail={patientDetail} />
                      <ImageSliderModal
                        verifyPatientFunction={this.verifyPatientFunction}
                        visible={this.state.visible}
                        notFromOngoingConsultation={true}
                        patientDetail={patientDetail}
                        handleCancel={() => this.setState({visible: false})}
                        verifyingPatient={this.state.verifyingPatient}

                        // checkIdentity={this._SaveComponentStateToRedux}
                        // isDocumentViewed={isDocumentViewed}
                      />
                      {/* <Collapse onChange={callback} ghost>
                        <Panel key="1">
                          {isLoadingDetail ? (
                            <Spin size="small" />
                          ) : (
                            <div className="collapseprofiledetail">
                              <div className="margin-top-5 font-12 weight-400 text-dark-gray">
                                <div className="in-block push-10-r age">
                                  {verifyObject(patientDetail, "age", null) !==
                                  null ? (
                                    <Fragment>
                                      <p className="profile_status active in-block"></p>
                                      <span className="text-capitalize">
                                        {`${patientDetail.age} Yr`}
                                      </span>
                                    </Fragment>
                                  ) : (
                                    "--"
                                  )}
                                </div>
                                <div className="in-block push-10-r gender">
                                  {verifyObject(
                                    patientDetail,
                                    "gender",
                                    null
                                  ) !== null ? (
                                    <Fragment>
                                      <p className="profile_status active in-block"></p>
                                      <span className="text-capitalize">
                                        {patientDetail.gender}
                                      </span>
                                    </Fragment>
                                  ) : (
                                    "--"
                                  )}
                                </div>
                                <div className="in-block push-10-r height_category">
                                  {verifyObject(
                                    patientDetail,
                                    "height_value1",
                                    null
                                  ) !== null &&
                                    verifyObject(
                                      patientDetail,
                                      "height_value2",
                                      null
                                    ) !== null && (
                                      <Fragment>
                                        <p className="profile_status active in-block"></p>
                                        <span className="text-capitalize">
                                          <span className="text-black weight-300">
                                            Height :
                                          </span>{" "}
                                          {patientDetail.height_value1}{" "}
                                          {patientDetail.height_value2}
                                        </span>
                                      </Fragment>
                                    )}
                                </div>
                                <div className="in-block push-10-r height_category">
                                  {verifyObject(
                                    patientDetail,
                                    "weight_value1",
                                    null
                                  ) !== null &&
                                    verifyObject(
                                      patientDetail,
                                      "weight_value2",
                                      null
                                    ) !== null && (
                                      <Fragment>
                                        <p className="profile_status active in-block"></p>
                                        <span className="text-capitalize">
                                          <span className="text-black weight-300">
                                            Weight :
                                          </span>{" "}
                                          {patientDetail.weight_value1}{" "}
                                          {patientDetail.weight_value2}
                                        </span>
                                      </Fragment>
                                    )}

                                  {verifyObject(
                                    patientDetail,
                                    "weight_category",
                                    null
                                  ) !== null &&
                                    patientDetail.weight_category ===
                                      "metric" &&
                                    verifyObject(
                                      patientDetail,
                                      "weight_value1",
                                      null
                                    ) !== null && (
                                      <Fragment>
                                        <p className="profile_status active in-block"></p>
                                        <span className="text-capitalize">
                                          <span className="text-black weight-300">
                                            Weight :
                                          </span>{" "}
                                          {patientDetail.weight_value1}
                                        </span>
                                      </Fragment>
                                    )}
                                </div>
                                <div className="in-block push-10-r height_category">
                                  {verifyObject(patientDetail, "bmi", null) !==
                                    null && (
                                    <Fragment>
                                      <p className="profile_status active in-block"></p>
                                      <span className="text-capitalize">
                                        <span className="text-black weight-300">
                                          BMI :
                                        </span>{" "}
                                        {patientDetail.bmi}
                                      </span>
                                    </Fragment>
                                  )}
                                </div>
                                <div className="in-block push-10-r height_category">
                                  {verifyObject(
                                    patientDetail,
                                    "smoke",
                                    null
                                  ) !== null && (
                                    <Fragment>
                                      <p className="profile_status active in-block"></p>
                                      <span className="text-capitalize">
                                        {patientDetail.smoke}
                                        {verifyObject(
                                          patientDetail,
                                          "smoke",
                                          null
                                        ) !== "No smoker" && " per day"}
                                      </span>
                                    </Fragment>
                                  )}
                                </div>
                                <div className="in-block push-10-r height_category">
                                  {verifyObject(
                                    patientDetail,
                                    "alcohol",
                                    null
                                  ) !== null && (
                                    <Fragment>
                                      <p className="profile_status active in-block"></p>

                                      <span className="text-capitalize">
                                        {patientDetail.alcohol}{" "}
                                        {verifyObject(
                                          patientDetail,
                                          "alcohol",
                                          null
                                        ) !== "No alcoholic" && " Unit alcohol"}
                                      </span>
                                    </Fragment>
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </Panel>
                      </Collapse> */}
                    </div>
                  </div>

                  <div className="scriptreviewheaderright relative dis-flex align-items-center justify-content-between">
                    {scriptReviewStatus !== "doctor_approved" &&
                      scriptReviewStatus !== "doctor_declined" && (
                        <div className="timer_div push-5-t push-5-b">
                          <div className="timer_counter">
                            <div className="timer-notification"></div>
                            <Timer
                              initialTime={
                                parseInt(this.state.reviewDuration, 10) * 1000
                              }
                            >
                              <Timer.Minutes
                                formatValue={(value) =>
                                  `${value < 10 ? `0${value}` : value}`
                                }
                              />
                              :
                              <Timer.Seconds
                                formatValue={(value) =>
                                  `${value < 10 ? `0${value}` : value}`
                                }
                              />
                            </Timer>
                          </div>
                        </div>
                      )}
                    <div className="scriptrvwdate push-5-t push-5-b in-block push-5-l">
                      {scriptReviewData && (
                        <p className="m-0 weight-500 font-12">
                          <img
                            alt="Time Icon"
                            title="Time"
                            width="21"
                            height="21"
                            src={
                              require("../../assets/images/common/script_time_1.svg")
                                .default
                            }
                          />{" "}
                          {scriptReviewData &&
                            scriptReviewData.ordered_date &&
                            moment(scriptReviewData.ordered_date).format(
                              DATE_FORMAT_24_HR
                            )}
                        </p>
                      )}
                      <p className="m-0 weight-500 font-12">
                        <img
                          alt="User Icon"
                          title="User"
                          width="21"
                          height="21"
                          src={
                            require("../../assets/images/common/script_time_2.svg")
                              .default
                          }
                        />{" "}
                        {scriptReviewData &&
                          scriptReviewData.assigned_date &&
                          moment(scriptReviewData.assigned_date).format(
                            DATE_FORMAT_24_HR
                          )}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="scriptreview_bottomheader">
                  <Collapse
                    expandIconPosition="right"
                    // expandIcon={(expanded) => {
                    //   return expanded.isActive ? (
                    //     <img style={{ width: 19 }} alt="" src={minus_blue} />
                    //   ) : (
                    //     <img style={{ width: 19 }} alt="" src={plus_blue} />
                    //   );
                    // }}
                    onChange={this.handleCollapse}
                    // ghost
                  >
                    <Panel
                      header={
                        scriptReviewData && (
                          <div className="dis-table">
                            <div className="cell">
                              <p className="word-break margin-0 font-12 weight-400 text-black">
                                {verifyObject(
                                  scriptReviewData,
                                  "order_item.drug",
                                  "-"
                                )}
                              </p>
                            </div>
                            <div className="cell text-right width-16">
                              <p className="margin-0 font-12 weight-400 text-light-black">
                                {verifyObject(
                                  scriptReviewData,
                                  "order_item.dose",
                                  "-"
                                )}
                              </p>
                            </div>
                            <div className="cell text-right width-16">
                              <p className="margin-0 font-12 weight-400 text-light-black text-capitalize">
                                {verifyObject(
                                  scriptReviewData,
                                  "order_item.quantity",
                                  "-"
                                )}{" "}
                                {verifyObject(
                                  scriptReviewData,
                                  "order_item.formulation",
                                  "-"
                                )}
                              </p>
                            </div>
                            <div className="cell text-right width-22">
                              <MinusOutlined
                                className="minus_blue"
                                style={{width: 15}}
                              />
                              <PlusOutlined
                                className="plus_blue"
                                style={{width: 15}}
                              />
                            </div>
                          </div>
                        )
                      }
                      key="1"
                    >
                      <div>
                        <p className="font-12 weight-400 text-black">
                          Past Orders
                        </p>
                        {pastMedications &&
                          isArray(pastMedications) &&
                          pastMedications.map((d) => {
                            return (
                              <div className="dis-table push-10-t">
                                <div className="cell width-22">
                                  <p className="margin-0 font-12 text-light-black weight-500">
                                    #{d.order_id}
                                  </p>
                                </div>
                                <div className="cell width-22">
                                  {d.order_item_status.status !== "approved" ? (
                                    <Fragment>
                                      <Tag color="red">
                                        <p className="margin-0 font-12  weight-500">
                                          {status[d.order_item_status.status]}
                                        </p>
                                      </Tag>

                                      <Tooltip
                                        title={
                                          d.order_item_status.reason !== null
                                            ? d.order_item_status.reason
                                            : "No reason available"
                                        }
                                      >
                                        <span>
                                          <InfoCircleOutlined />
                                        </span>
                                      </Tooltip>
                                    </Fragment>
                                  ) : (
                                    <Tag color="blue">
                                      <p className="margin-0 font-12  weight-500">
                                        {status[d.order_item_status.status]}
                                      </p>
                                    </Tag>
                                  )}
                                </div>
                                <div className="cell text-left width-20">
                                  <p className="margin-0 font-12 line-1-43 weight-400 text-light-black">
                                    <img
                                      style={{width: 16}}
                                      className="push-10-r vertical-sub"
                                      alt="Calander"
                                      title="Calendar"
                                      src={calendar_image}
                                    />
                                    {d.date
                                      ? moment(d.date).format(DATE_FORMAT)
                                      : "-"}
                                  </p>
                                </div>
                                <div className="cell text-left width-15 overright-width-24">
                                  <p className="margin-0 font-12 line-1-43 weight-400 text-light-black">
                                    <img
                                      style={{width: 16}}
                                      className="push-10-r vertical-sub"
                                      alt="Time"
                                      title="Time"
                                      src={time_image}
                                    />
                                    {d.date
                                      ? moment(d.date).format(T24_HR_FORMAT)
                                      : "-"}
                                  </p>
                                </div>
                                <div className="cell text-left width-16">
                                  <p className="margin-0 font-12 weight-400 text-light-black">
                                    {verifyObject(d, "dose", "-")}
                                  </p>
                                </div>
                                <div className="cell text-left width-16">
                                  <p className="margin-0 font-12 weight-400 text-light-black text-capitalize">
                                    {verifyObject(d, "quantity", "-")}{" "}
                                    {verifyObject(
                                      d,
                                      "order_item_status.formulation"
                                    )
                                      ? verifyObject(
                                          d,
                                          "order_item_status.formulation"
                                        )
                                      : "Tablets"}
                                  </p>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </Panel>
                  </Collapse>
                </div>
                {/* / common patient header / */}
                {this._renderDynamicComponent()}
              </div>
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}

const mapStateProps = (state) => {
  return {
    componentName: verifyObject(
      state.localStore,
      "componentName",
      "PreQuestionnaireContainer	"
    ),
    // patientId: verifyObject(state.localStore, "patientId", null),
    patientId: verifyObject(state.localStore, "patientId", null),
    patientDetail: verifyObject(state.localStore, "patient_detail_obj", null),
    isLoadingDetail: verifyObject(state.patientDetails, "isLoading", false),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {onRequestSetComponent, onSetSidebarKey},
      dispatch
    ),
    patientDetailActions: bindActionCreators(patientDetailActions, dispatch),
    actionCableDataActions: bindActionCreators(
      actionCableDataActions,
      dispatch
    ),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(ScriptReviewDetailPage);

export default withRouter(ConnectedComponent);

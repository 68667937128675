import React, { useEffect, useMemo, useRef, useState } from "react";
// import '@fullcalendar/react/dist/vdom'
import FullCalendar from "@fullcalendar/react";
// import timeGridPlugin from "@fullcalendar/timegrid";
import scrollgridPlugin from "@fullcalendar/scrollgrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
import {
  bookAppointmentFromPatientAPI,
  fetchAppointmentCalendarSlots,
  updateAppointmentFromPatientAPI,
} from "services/appointments";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import moment from "moment";
import { DATE_FORMAT, DATE_FORMAT_12_HR } from "constants/common";
import { Button, message, Modal, Popover, Radio, Tag } from "antd";
import { map, uniq, uniqBy } from "lodash";
import "./FullCalendar.css";
import TableLoader from "components/TableLoader/TableLoader";
import FullScreenHook from "components/FullScreenHook/FullScreenHook";
import ModalPopUp from "components/common/ModalPopUp";
import MordenCalendar from "components/common/controls/MordenCalendar";
import { IconChevronDown } from "assets/icon/IconChevronDown";
import { v4 } from "uuid";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";
import {
  EditFilled,
  CopyFilled,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import isEmpty from "lodash/isEmpty";
const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
// const events = [{ title: "Meeting", start: new Date() }];
// function renderEventContent(eventInfo) {
//   return (
//     <>
//       <b>{eventInfo.timeText}</b>
//       <p
//         className="text-capitalize ellipsis-2-line"
//         title={eventInfo.event.title?.replaceAll("_", " ").replace(/^\s+|\s+$/gm,'')}
//       >
//         {eventInfo.event.title?.replaceAll("_", " ")}
//       </p>
//     </>
//   );
// }
const bgColors = [
  "#eeeeee",
  "#fff1d5",
  "#e2f0ff",
  "#fce4f6",
  "#ffdfdf",
  "#dfe3e8",
  "#d6f3eb",
  "#d6defe",
  "#e2ffff",
];
const textColor = [
  "#003162",
  "#4a3710",
  "#1c4665",
  "#82266c",
  "#671818",
  "#343638",
  "#124c3b",
  "#172043",
  "#0271bd",
];

export const appointmentsStatus = {
  attended: {
    key: "attended",
    value: "Attended",
    color: "success",
  },
  not_attended: {
    key: "not_attended",
    value: "Not attended",
    color: "danger",
  },
  upcoming: {
    key: "upcoming",
    value: "Upcoming",
    color: "info",
  },
  cancelled: {
    key: "cancelled",
    value: "Cancelled",
    color: "danger",
  },
  booked: {
    key: "booked",
    value: "Booked",
    color: "primary",
  },
  ready_for_call: {
    key: "ready_for_call",
    value: "Ready for call",
    color: "warning",
  },
  on_call: {
    key: "on_call",
    value: "On call",
    color: "primary",
  },
  finished: {
    key: "finished",
    value: "Finished",
    color: "success",
  },
  patient_arrived: {
    key: "patient_arrived",
    value: "Patient Arrived",
    color: "success",
  },
  patient_not_arrived: {
    key: "patient_not_arrived",
    value: "Patient Not Arrived",
    color: "warning",
  },
  null: {
    key: "null",
    value: "-",
    color: "light",
  },
  unavailable: {
    key: "null",
    value: "-",
    color: "light",
  },
  available: {
    key: "available",
    value: "-",
    color: "info",
  },
  unavailable_days: {
    key: "null",
    value: "Day Not Available",
    color: "info",
  },
  finished_oos: {
    key: "finished_oos",
    value: "Finished OOS",
    color: "danger",
  },
  submitted_to_review: {
    key: "submitted_to_review",
    value: "In Review",
    color: "warning",
  },
  call_ended: {
    key: "call_ended",
    value: "Call Ended",
    color: "success",
  },
};
const ModalConfig = Modal;
export default class BookAppointmentFullCalendar extends React.Component {
  calendarComponentRef = React.createRef();

  fullScreenHookRef = React.createRef();
  state = {
    dateStart: "",
    dateEnd: "",
    fullClear: "",
    changedYes: "",
    images: null,
    appDate: new Date(),
    finalDoctorList: [],
    updatedEvent: [],
    evented_resourceIds: [],
    doctors: [],
    ids: null,
    date: null,
    selectedEvent: null,
    passedDate: null,
    updating: false,
    corporate_ids: null,
    organizations: [],
    dates: [],
    for_dates: null,
    for_dates2: null,
    is_range: null,
    isFilterOpen: false,
    isPopoverOpen: false,
    isFullScreen: false,
    bookAppointmentModalOpen: false,
    hideEventModal: false,
    editAppointmentDetails: null,
    eventDetail: null,
    info: null,
    isClear: "",
    gridDayState: "resourceTimeGridDay",
    calendarType: "day",
    isOutFromFullscreen: false,
    selectedDay: {
      year: parseInt(moment().format("YYYY"), 10),
      month: parseInt(moment().format("MM"), 10),
      day: parseInt(moment().format("DD"), 10),
    },
  };

  componentDidMount() {
    this._fetchAppointmentCalendarSlots();
  }

  _fetchAppointmentCalendarSlots = async (passedate, dates) => {
    console.log(
      "🚀 ~ BookAppointmentFullCalendar ~ _fetchAppointmentCalendarSlots= ~ passedate:",
      moment(passedate).format("DD-MM-YYYY")
    );
    try {
      // setLoadingCalendarAPI(true);

      this.setState(
        {
          loadingCalendarAPI: true,
        },
        async () => {
          let response = await fetchAppointmentCalendarSlots({
            date: dates
              ? dates
              : moment(passedate ? passedate : this.props.selecetdDate).format(
                  DATE_FORMAT
                ),
            zone: time_zone,
            is_zone: true,
            doctor_ids: uniq(this.props.doctor_ids.split(",")).toString(),
            session_type:
              this.props.selectedAptType === "video"
                ? "online_appointment"
                : "offline_appointment",
          });
          console.log(
            "🚀 ~ const_fetchAppointmentCalendarSlots= ~ response:",
            response?.data?.data
          );
          let allEvents = [];
          let finalDoctorList = [];
          let id = "";
          let title = "";
          let start = "";
          let newKey = 0;
          await Object.keys(response?.data?.data).map((key) => {
            // console.log("response")
            response?.data?.data[key].map((evt) => {
              if (evt.resourceId == null) {
                id = "all";
                title = "Unassigned Appointments";
              } else {
                const CounterValues = map(
                  Object.values(response?.data?.data[key]).filter((d) => {
                    if (evt?.resourceId) {
                      return (
                        d.resourceId === evt.resourceId &&
                        d.max_appointment_counter !== undefined &&
                        d.max_appointment_counter !== null
                      );
                    }
                  }),
                  "max_appointment_counter"
                );
                console.log("MAXVAL", Object.values(response?.data?.data[key]));
                const maxCounterValues = Math.max(...CounterValues);
                console.log("🚀 ~ CounterValues:", CounterValues);
                console.log("🚀 ~ maxCounterValues:", maxCounterValues);

                id = evt.resourceId;
                title =
                  evt.max_appointment_counter !== undefined
                    ? `${evt.doctor_name} - ${maxCounterValues}`
                    : `${evt.doctor_name}`;
                start = evt.start;
              }
              if (!finalDoctorList.some((doc) => doc.title === title)) {
                finalDoctorList.push({
                  id: id,
                  title: title,
                  eventTextColor: textColor[newKey],
                  eventBackgroundColor: bgColors[newKey],
                  eventBorderColor: bgColors[newKey],
                  extendedProps: {
                    appointment_status: evt.status,
                    gender_preference: evt.doctor_preference,
                    is_event_rendered:
                      start !== "" && start !== undefined ? start : null,
                  },
                });
                newKey >= textColor.length - 1 ? (newKey = 0) : newKey++;
              }
              console.log(
                "🚀 ~ const_fetchAppointmentCalendarSlots= ~ finalDoctorList:",
                finalDoctorList
              );
            });
          });
          await Object.keys(response?.data?.data).map((key) => {
            response?.data?.data[key].map((evt) => {
              if (evt?.status) {
                if (evt.resourceId == null) {
                  id = "all";
                  title = "Unassigned Appointments";
                } else {
                  const CounterValues = [];
                  const maxCounterValues = Math.max(...CounterValues);
                  id = evt.resourceId;
                  title =
                    evt.max_appointment_counter !== undefined
                      ? `${evt.doctor_name} - ${maxCounterValues}`
                      : `${evt.doctor_name}`;
                  start = evt.start;
                }
                // if (evt?.id) {
                console.log("EEVENT START", evt);
                // }
                let eventObj = {
                  resourceId: evt.resourceId ? evt.resourceId : "all",
                  title: evt.title
                    ? `${evt.title} - ${
                        appointmentsStatus[evt.status] &&
                        appointmentsStatus[evt.status].value
                          ? appointmentsStatus[evt.status].value
                          : appointmentsStatus[evt.status].replaceAll(/_/g, " ")
                      }
              ${
                evt.corporate_name
                  ? `(${evt.corporate_name}) \n #${evt.id}`
                  : ""
              }
              `
                    : "",
                  id: evt.id ? evt.id : v4(),
                  start: new Date(evt.start),
                  end: new Date(evt.end),
                  editable:
                    evt.status === "unavailable" ||
                    evt.status === "unavailable_days"
                      ? false
                      : true,
                  // disable:true,
                  eventTextColor: textColor[newKey],
                  eventBackgroundColor: bgColors[newKey],
                  eventBorderColor: bgColors[newKey],
                  eventDurationEditable: true,
                  recordId: `${v4()}_${Math.random()}`,
                  className:
                    (evt.status === "unavailable" ||
                      evt.status === "unavailable_days") &&
                    "disbaled-event",
                  durationEditable: false,
                  resourceEditable: true,
                  startStr: evt.start
                    ? moment(evt.start).format(DATE_FORMAT_12_HR)
                    : null,
                  endStr: evt.start
                    ? moment(evt.end).format(DATE_FORMAT_12_HR)
                    : null,
                  overlap:
                    evt.status === "unavailable" ||
                    evt.status === "unavailable_days"
                      ? false
                      : true,

                  extendedProps: {
                    appointment_status: evt?.status
                      ? appointmentsStatus[evt.status].value
                      : null,
                    gender_preference: evt.doctor_preference,
                    is_event_rendered: evt.start,
                    start: evt.start,
                    end: evt.end,
                    title: evt.title,
                    corporate_name: evt.corporate_name
                      ? `(${evt.corporate_name})`
                      : null,
                    appointment_type: evt.appointment_type
                      ? evt.appointment_type
                      : null,
                      doctor_name: evt?.doctor_name,
                      doctor_id: evt.resourceId,
                      eventId: evt.id,
                      patient_joining_url: evt.patient_joining_url,
                      reason_text: evt?.reason_text,
                      apptStatus: evt.status,
                  },
                };
                if (eventObj.className === "disbaled-event") {
                  console.log("eventObj", eventObj);
                }
                newKey >= textColor.length - 1 ? (newKey = 0) : newKey++;
                allEvents.push(eventObj);
                // return eventObj;
              }
            });
            // console.log("updatedEvent", updatedEvent);
          });
          this.setState(
            {
              updatedEvent:
                allEvents.length > 0
                  ? [
                      ...uniqBy(allEvents, "id"),
                      // ...uniqBy(
                      //   allEvents.filter(
                      //     (d) =>
                      //       d.id === null &&
                      //       d.end_time !== null &&
                      //       d.start_time !== null &&
                      //       d.className === false
                      //   ),
                      //   "endStr"
                      // ),
                      // ...uniqBy(
                      //   allEvents.filter(
                      //     (d) => d.className === "disbaled-event"
                      //   ),
                      //   "recordId"
                      // ),
                    ]
                  : [
                      {
                        resourceId: "all",
                        id: null,
                        start: new Date().setUTCHours(0, 0, 0, 0),
                        end: new Date().setUTCHours(23, 59, 59, 999),
                        editable: false,
                        eventTextColor: textColor[newKey],
                        eventBackgroundColor: bgColors[newKey],
                        eventBorderColor: bgColors[newKey],
                        eventDurationEditable: true,
                        className: "disbaled-event",
                        durationEditable: false,
                        resourceEditable: true,
                        overlap: false,
                        title: "No Rotas Available",
                      },
                    ],
              finalDoctorList:
                finalDoctorList.length > 0
                  ? finalDoctorList
                  : [
                      {
                        id: "all",
                        title: "No Clinician Available",
                        eventTextColor: `rgba(220, 53, 69, 0.7)`,
                        eventBackgroundColor: `#ffd9db`,
                        eventBorderColor: `#ffd9db`,
                      },
                    ],
              loadingCalendarAPI: false,
            },
            () => {
              console.log("updatedEvent", this.state.updatedEvent);
              const allClasess = document.querySelectorAll(
                ".fc-timegrid-slots > table > tbody"
              );
              allClasess.forEach((clasess) => {
                clasess?.childNodes?.forEach((d) => {
                  d.childNodes.forEach((v) => {
                    console.log(
                      "this.state.calendarType",
                      this.state.calendarType
                    );
                    console.log("this.state.finalDoctorList",this.state.finalDoctorList)
                    if (
                     ( this.state.calendarType === "days" ||
                      this.state.calendarType === "day" ) &&  uniqBy(this.state.finalDoctorList,"id").length === 1 // only one resource is there
                    ) {
                      v?.classList?.add("empty-slot-event");
                    } else {
                      v?.classList?.add("empty-slot-event-week");
                      v?.classList?.remove("empty-slot-event");
                    }
                  });
                });
              });
            }
          );
        }
      );
    } catch (e) {
      console.log("🚀 ~ const_fetchAppointmentCalendarSlots= ~ e:", e);
      const { message } = getErrorObject(e);
      errorToast({
        content: message,
      });
      // setLoadingCalendarAPI(false);
    }
  };

  handleDirection = async (direction) => {
    const calendarAPI = this.calendarComponentRef?.current.getApi();
    if (direction === "next") {
      await calendarAPI.next();
      await calendarAPI.changeView(this.state.gridDayState);

      let defaultDates = [];

      let currDate = moment(calendarAPI.view.currentStart);
      let lastDate = moment(calendarAPI.view.currentEnd);
      if (
        this.state.calendarType === "week" ||
        this.state.calendarType === "days"
      ) {
        if (this.state.calendarType === "days") {
          defaultDates.push(calendarAPI.view.currentStart);
        } else {
          defaultDates.push(currDate.format(DATE_FORMAT));
        }
      }
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        defaultDates.push(currDate.format(DATE_FORMAT));
      }
      if (this.state.calendarType === "week_days") {
        defaultDates.pop();
      }
      console.log("defaultDates", defaultDates);
      if (
        this.state.calendarType === "week_days" ||
        this.state.calendarType === "week"
      ) {
        if (this.state.defaultDates.length === 1) {
          this._fetchAppointmentCalendarSlots(defaultDates.toString(), null);
        } else {
          this._fetchAppointmentCalendarSlots(null, defaultDates.toString());
        }
        this.setState({
          defaultDates: defaultDates,
        });
      } else {
        console.log("currDate", moment(currDate._d).format(DATE_FORMAT));
        const nextDate = calendarAPI.getCurrentData().currentDate;
        this._fetchAppointmentCalendarSlots(
          null,
          moment(nextDate).format(DATE_FORMAT)
        );
        this.setState({
          defaultDates: [moment(nextDate).format(DATE_FORMAT)],
          calendarType:
            moment(nextDate).format(DATE_FORMAT) ===
            moment().format(DATE_FORMAT)
              ? "day"
              : undefined,
        });
      }
    } else {
      await calendarAPI.prev();
      await calendarAPI.changeView(this.state.gridDayState);

      let defaultDates = [];

      let currDate = moment(calendarAPI.view.currentStart);
      let lastDate = moment(calendarAPI.view.currentEnd);

      if (
        this.state.calendarType === "week" ||
        this.state.calendarType === "days"
      ) {
        if (this.state.calendarType === "days") {
          defaultDates.push(calendarAPI.view.currentStart);
        } else {
          defaultDates.push(currDate.format(DATE_FORMAT));
        }
      }
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        defaultDates.push(currDate.format(DATE_FORMAT));
      }
      if (this.state.calendarType === "week_days") {
        defaultDates.pop();
      }
      if (
        this.state.calendarType === "week_days" ||
        this.state.calendarType === "week"
      ) {
        if (this.state.defaultDates.length === 1) {
          this._fetchAppointmentCalendarSlots(defaultDates.toString(), null);
        } else {
          this._fetchAppointmentCalendarSlots(null, defaultDates.toString());
        }
        this.setState({
          defaultDates: defaultDates,
        });
      } else {
        console.log("currDate", moment(lastDate._d).format(DATE_FORMAT));
        const preVDate = calendarAPI.getCurrentData().currentDate;
        this._fetchAppointmentCalendarSlots(
          null,
          moment(preVDate).format(DATE_FORMAT)
        );
        this.setState({
          defaultDates: [moment(preVDate).format(DATE_FORMAT)],
          calendarType:
            moment(preVDate).format(DATE_FORMAT) ===
            moment().format(DATE_FORMAT)
              ? "day"
              : undefined,
        });
      }
    }
  };

  handleCalendarType = ({ target: { value } }) => {
    console.log("🚀 ~ handleCalendarType ~ type:", value);
    const calendarAPI = this.calendarComponentRef?.current.getApi();

    // setCalendarType(value);
    this.setState(
      {
        calendarType: value,
      },
      () => {
        switch (value) {
          case "day":
            calendarAPI?.changeView("resourceTimeGridDay");
            let currDate = moment(calendarAPI.view.currentStart);
            console.log(
              "🚀 ~ BookAppointmentFullCalendar ~ currDate:",
              currDate
            );
            // let lastDate = moment(calendarAPI.view.currentEnd);
            // let defaultDates = [];

            // defaultDates.push(currDate.format(DATE_FORMAT));
            // while (currDate.add(1, "days").diff(lastDate) < 0) {
            //   defaultDates.push(currDate.clone().format(DATE_FORMAT));
            // }
            this.setState(
              {
                gridDayState: "resourceTimeGridDay",
              },
              () => {
                this._fetchAppointmentCalendarSlots(null, currDate?.toString());
              }
            );
            break;
          case "week_days":
            calendarAPI?.changeView("resourceTimeGridWeek");
            let currDateWeekDays = moment(calendarAPI.view.currentStart);
            let lastDateWeekDays = moment(calendarAPI.view.currentEnd);
            console.log(
              "🚀 ~ handleCalendarType ~ lastDateWeek:",
              lastDateWeekDays
            );
            console.log(
              "🚀 ~ handleCalendarType ~ currDateWeek:",
              currDateWeekDays
            );

            let defaultDatesWeekDays = [];
            let arrayWeekDays = new Array(5);
            console.log("🚀 ~ handleCalendarType ~ array:", arrayWeekDays);
            // defaultDates.push(currDateWeek.format(DATE_FORMAT));
            // defaultDatesWeekDays.push(currDateWeekDays.format(DATE_FORMAT));
            for (let index = 0; index < 5; index++) {
              defaultDatesWeekDays.push(
                currDateWeekDays.add(1, "days").format(DATE_FORMAT)
              );
            }
            console.log("defaultDatesWeekDays", defaultDatesWeekDays);
            this.setState(
              {
                gridDayState: "resourceTimeGridWeek",
              },
              () => {
                this._fetchAppointmentCalendarSlots(
                  null,
                  defaultDatesWeekDays?.toString()
                );
              }
            );

            break;
          case "week":
            calendarAPI?.changeView("resourceTimeGridWeek");
            let currDateWeek = moment(calendarAPI.view.currentStart);
            let lastDateWeek = moment(calendarAPI.view.currentEnd);
            console.log(
              "🚀 ~ handleCalendarType ~ lastDateWeek:",
              lastDateWeek
            );
            console.log(
              "🚀 ~ handleCalendarType ~ currDateWeek:",
              currDateWeek
            );

            let defaultDates = [];
            let array = new Array(7);
            console.log("🚀 ~ handleCalendarType ~ array:", array);
            // defaultDates.push(currDateWeek.format(DATE_FORMAT));
            defaultDates.push(currDateWeek.format(DATE_FORMAT));
            for (let index = 0; index < 6; index++) {
              defaultDates.push(
                currDateWeek.add(1, "days").format(DATE_FORMAT)
              );
            }
            console.log("defaultDates", defaultDates);
            this.setState(
              {
                gridDayState: "resourceTimeGridWeek",
              },
              () => {
                this._fetchAppointmentCalendarSlots(
                  null,
                  defaultDates?.toString()
                );
              }
            );
            break;
          default:
            break;
        }
      }
    );
  };

  handleAppointmentSubmissionFromCalendar = async (
    doctor_id,
    selectedSlotTime
  ) => {
    // console.log("patient id", patient_id);
    this.setState({
      isBookingFromCalendar: true,
    });
    let formData = new FormData();
    formData.set(`appointment[start_time]`, selectedSlotTime[0]);
    formData.set(`appointment[end_time]`, selectedSlotTime[1]);
    formData.set(`appointment[pre_consult_id]`, this.props.pre_consult_id);
    formData.set(`appointment[appointment_type]`, this.props.selectedAptType);
    formData.set(`duration`, 15);
    formData.set(`corporate_id`, this.props.corporate_id);
    formData.set(`book_from_dp`, true);
    formData.set(`patient_id`, this.props.patient_id);
    formData.set(
      `book_via`,
      this.props.selectedAptType === "video" ? "ad_hoc" : "slot"
    );

    if (doctor_id !== "all") {
      console.log("DOCTOR ID", doctor_id);
      if (doctor_id?.length > 1) {
        formData.set(`doctor_ids`, doctor_id);
      } else {
        formData.set(`doctor_id`, doctor_id);
      }
      // }
      try {
        let response = await bookAppointmentFromPatientAPI(formData);
        if (response.status === 200) {
          if (response.data.message) {
            if (
              typeof this.props.preConsultationReviewActions.onRequest ===
              "function"
            ) {
              this.props.preConsultationReviewActions.onRequest({
                page: 1,
                status: statusParam,
              });
            }
          }
        }
      } catch (error) {
        const { message } = getErrorObject(error);
        errorToast({ content: message });
      }
    }
  };

  eventDrop = async (e) => {
    let reqObj = {
      id: e.event.id,
      start_time: e.event.start,
      user_id: e.event?._def?.resourceIds[0],
    };
    let messageConfig = Modal;
    // Modal.de
    console.log("e.event", e.event);
    try {
      // messageConfig.info({
      //   content: "Updating Appointment...",
      //   closable: false,
      //   cancelButtonProps: {
      //     style: {
      //       display: "none",
      //     },
      //   },
      //   okButtonProps: {
      //     style: {
      //       display: "none",
      //     },
      //   },
      // });
      this.setState({ updatingApt: true });
      let response = await updateAppointmentFromPatientAPI(reqObj);
      console.log("response", response);
      // setCalendarEvents(allEvents);
      if (response.data.status === 200) {
        successToast({
          content: response.data.message,
        });
        messageConfig.destroyAll();
        this._fetchAppointmentCalendarSlots();
        this.setState({ updatingApt: false });
      }
    } catch (e) {
      const { message } = getErrorObject(e);
      this._fetchAppointmentCalendarSlots();
      errorToast({
        content: message,
      });
      // messageConfig.destroyAll();
      this.setState({ updatingApt: false });
    }
    console.log("reqObj", reqObj);
  };
  _handleDate = (dates) => {
    console.log("dates", dates);
    // if (this.state.calendarType === "day") {
    let day =
      dates.day.toString().length >= 2 ? dates.day.toString() : `0${dates.day}`;
    let month =
      dates.month.toString().length >= 2
        ? dates.month.toString()
        : `0${dates.month}`;

    let fullDate = `${dates.year}-${month}-${day}`.toString();
    this.setState(
      {
        selectedDay: dates,
        date:
          verifyObject(moment(fullDate), "_d", null) !== ""
            ? moment(fullDate)._d.toString()
            : null,
        calendarType:
          moment().format(DATE_FORMAT) === `${day}-${month}-${dates.year}`
            ? "day"
            : undefined,
        defaultDates: [`${day}-${month}-${dates.year}`],
        gridDayState: "resourceTimeGridDay",
      },
      () => {
        const calendarAPI = this.calendarComponentRef?.current.getApi();
        calendarAPI.changeView("resourceTimeGridDay");
        calendarAPI.gotoDate(new Date(this.state.date));
        this._fetchAppointmentCalendarSlots(this.state.date);
      }
    );
    // } else if (this.state.calendarType === "week_days") {
    // } else {
    //   //week
    // }
  };

  // componentDidUpdate(prevProps, prevState) {
  //   console.log("🚀 ~ componentDidUpdate ~ prevProps:", prevProps.isBookedFrom);
  //   console.log(
  //     "🚀 ~ componentDidUpdate ~ prevProps:",
  //     this.props.isBookedFrom
  //   );

  //   if (prevProps.isBookedFrom) {
  //     this.setState({ bookingRecord: undefined }, () => {
  //       if (
  //         this.props.resetBooked &&
  //         typeof this.props.resetBooked === "function"
  //       ) {
  //         this.props.resetBooked();
  //       }
  //     });
  //   }
  // }
  // Rendering Events
  renderEventDetais = (eventObj) => (
    <div>
      <ul className="list-unstyled d-flex flex-column gapy-1">
        <li className="d-flex flex-wrap align-items-center justify-content-between gapx-2">
          <div className="d-flex flex-wrap gapx-2">
            <b>Appointment ID:</b>
            <span className="flex-1">#{eventObj.eventId}</span>
          </div>
          <div className="d-flex flex-wrap gapx-2">
            <CloseOutlined
              onClick={() => {
                this.setState({
                  openPopover: false,
                });
              }}
              className="cursor-pointer text-gray"
            />
          </div>
        </li>
        <li className="d-flex flex-wrap gapx-2">
          <b>Patient Name:</b>
          <span className="flex-1">{eventObj.title}</span>
        </li>
        <li className="d-flex flex-wrap gapx-2">
          <b>Healthcare Provider:</b>
          <span className="flex-1">
            {eventObj.corporate_name?.replaceAll("(", "")?.replaceAll(")", "")}
          </span>
        </li>
        <li className="d-flex flex-wrap justify-content-between gapx-2">
          <span className="d-flex flex-wrap gapx-2">
            <b>Date/Time:</b>
            <span className="d-flex flex-wrap gapx-2 flex-1">
              <span>{moment(eventObj.start).format(DATE_FORMAT)}</span>
              <span>{moment(eventObj.start).format("hh:mm A")}</span>
              <span>-</span>
              <span>{moment(eventObj.end).format("hh:mm A")}</span>
            </span>
          </span>
          {/* {eventObj.appointment_status === "Booked" &&
            eventObj.appointment_type === "video" && (
              <EditFilled
                className="text-blue"
                size={"large"}
                onClick={() =>
                  this.setState({
                    resourceID: eventObj.doctor_id,
                    showBookingModal: true,
                    selectedPatientId: 12990,
                    selectedPatientName: eventObj.title,
                    inEditMode: true,

                    bookingRecord: {
                      // apptDate: eventObj.start,
                      selectedAptType: eventObj.appointment_type,
                      doctor_name: eventObj.doctor_name,
                      resourceID: eventObj.doctor_id,
                      wholeSlot: [eventObj.start, eventObj.end],
                      eventId: eventObj.eventId,
                    },
                    openPopover: false,
                    eventId: null,
                  })
                }
              />
            )} */}
        </li>
        <li className="d-flex flex-wrap gapx-2">
          <b>Duration:</b>
          <span className="flex-1">
            {moment
              .duration(moment(eventObj.end).diff(moment(eventObj.start)))
              .asMinutes()}{" "}
            mins
          </span>
        </li>
        <li className="d-flex flex-wrap gapx-2">
          <b>Type:</b>
          <Tag className="text-capitalize ant-tag-blue">
            {eventObj.appointment_type?.replaceAll("_", " ")}
          </Tag>
        </li>
        {!isEmpty(eventObj?.reason_text) && (
          <li className="d-flex flex-wrap gapx-2">
            <b>Reason:</b>
            <span className="flex-1">{eventObj?.reason_text}</span>
          </li>
        )}

        {(eventObj.apptStatus === "booked" ||
          eventObj.apptStatus === "ready_for_call") &&
          // eventObj.appointment_type === "video" &&
          (
            <li className="d-flex flex-wrap gapx-2">
              <b>Patient Link:</b>
              {/*This is a dummy link for UI purpose*/}
              <a
                href={eventObj.patient_joining_url}
                target="__blank"
                className="flex-1 popup-text-overflow"
              >
                {eventObj.patient_joining_url}
              </a>
              {!this.state.isCopied && (
                <CopyFilled
                  onClick={(e) =>
                    this.handleCopy(e, eventObj.patient_joining_url)
                  }
                  className="cursor-pointer"
                />
              )}
              {this.state.isCopied && (
                <CheckCircleFilled className="text-success" />
              )}
            </li>
          )}
        <li className="d-flex flex-wrap gapx-2">
          <b>Clinician Name:</b>
          <span className="flex-1">{eventObj.doctor_name}</span>
        </li>
      </ul>
    </div>
  );
  renderEventContent = (eventInfo) => {
    console.log("eventInfo", eventInfo.event._def?.extendedProps);
    console.log("eventInfo", eventInfo);

    const eventObj = eventInfo.event._def?.extendedProps;
    console.log("eventObj.title", eventObj.title);
    // if (eventObj.isEmptyEvent) {
    //   return (
    //     <div
    //       className="empty-slot-event"
    //       style={{ display: "flex", justifyContent: "center", paddingTop: 10 }}
    //       onClick={() => this.bookAppointmentFromEmptySlot(eventObj)}
    //       // onMouseEnter={() => {
    //       //   this.setState({
    //       //     isShowBookNow: !this.state.isShowBookNow,
    //       //     eventId: eventObj.eventId,
    //       //   });
    //       // }}
    //       // onMouseLeave={() => {
    //       //   this.setState({
    //       //     isShowBookNow: false,
    //       //   });
    //       // }}
    //     >
    //       {
    //         // <Button
    //         //   type="primary"
    //         //   style={{
    //         //     marginTop: "10px",
    //         //     width: 105,
    //         //   }}
    //         //   onClick={() => this.bookAppointmentFromEmptySlot(eventObj)}
    //         // >
    //         //   Book Now
    //         // </Button>
    //       }
    //     </div>
    //   );
    // } else {
    return (
      <Popover
        // visible={
        //   this.state.openPopover && eventObj.eventId === this.state.eventId
        // }
        // trigger={"hover"}
        overlayClassName="appointment-calendar-popover"
        content={this.renderEventDetais(eventObj)}
        // autoAdjustOverflow
      >
        <div
          style={{ height: "inherit" }}
          onClick={() => {
            this.setState({
              openPopover: true,
              eventId: eventObj.eventId,
              isOutFromFullscreen: true,
            });
          }}
          className="d-flex flex-column gapy-1 event-slot"
        >
          <b
            title={eventObj.title}
            className="d-flex flex-wrap align-items-center gapx-1"
          >
            <span
              className={`event-slote-title${
                eventObj.appointment_status
                  ? ` ${eventObj.appointment_status
                      .toLowerCase()
                      .replace(/\s/g, "-")}`
                  : ""
              }`}
            >
              <span className="ellipsis-1-line">{eventObj.title}</span>
            </span>
            <span>-</span>
            <span className="event-slote-status">{eventObj.appointment_status}</span>
            {/* {eventObj.appointment_status === "Booked" &&
              eventObj.appointment_type === "video" && (
                <EditFilled
                  className="text-blue"
                  size={"large"}
                  onClick={() =>
                    this.setState({
                      resourceID: eventObj.doctor_id,
                      showBookingModal: true,
                      selectedPatientId: 12990,
                      selectedPatientName: eventObj.title,
                      inEditMode: true,

                      bookingRecord: {
                        // apptDate: eventObj.start,
                        selectedAptType: eventObj.appointment_type,
                        doctor_name: eventObj.doctor_name,
                        resourceID: eventObj.doctor_id,
                        wholeSlot: [eventObj.start, eventObj.end],
                        eventId: eventObj.eventId,
                      },
                      openPopover: false,
                      eventId: null,
                    })
                  }
                />
              )} */}
          </b>
          <div className="tag-parent">
            <Tag className="text-capitalize ant-tag-blue">
              {eventObj.appointment_type?.replaceAll("_", " ")}
            </Tag>
            {/* {""} */}
            {/* {!isEmpty(eventObj?.reason_text) && (
              <Fragment>
                | <b>{eventObj?.reason_text}</b>
              </Fragment>
            )} */}
          </div>
        </div>
      </Popover>
    );
    //
  };

  render() {
    let { loadingCalendarAPI, selectedDay, finalDoctorList, updatedEvent } =
      this.state;

    const calendarAPI = this.calendarComponentRef?.current?.getApi();
    console.log(
      "🚀 ~ BookAppointmentFullCalendar ~ render ~ isBookingFromCalendar:",
      calendarAPI?.getCurrentData()
    );
    console.log("🚀 ~ render ~ updatedEvent:", updatedEvent);

    return (
      <div className="bookaptcalenderwrap h-100 d-flex flex-column">
        {/* <FullScreenHook
          CloseFullScreen={() => {
            this.setState({ isOutFromFullscreen: false });
          }}
          isOutFromFullscreen={this.state.isOutFromFullscreen}
        > */}
          <div className="d-flex flex-wrap align-items-center justify-content-end gapx-1 mb-3">
            {calendarAPI && (
              <Popover
                content={
                  <MordenCalendar
                    selectedDay={selectedDay}
                    onsetSelectedDay={this._handleDate}
                    date={this.state.date}
                    className="morden-cl-wrapper"
                  />
                }
              >
                <a className="mr-auto d-flex flex-wrap align-items-center gapx-2">
                  <span className="calendar-title">
                    {this.state.calendarType === "week" ||
                    this.state.calendarType === "week_days"
                      ? `${
                          calendarAPI
                            ?.getCurrentData()
                            ?.viewTitle?.split(",")?.[0]
                        } ${calendarAPI
                          ?.getCurrentData()
                          ?.viewTitle?.slice(12)}`
                      : moment(calendarAPI?.getCurrentData()?.viewTitle).format(
                          "Do MMM , YYYY"
                        )}
                  </span>
                  <IconChevronDown className="calendar-icon" />
                </a>
              </Popover>
            )}
               <div className="refresh-btn">
              {!this.props.isShowFullScreen && (
                <span className="calendercontrols full-screen-parent d-inline-flex">
                  <button
                    type="button"
                    className="ant-btn full-screen-button"
                    title="Enter Full Screen"
                    onClick={() => {
                       this.props.toggleFullScreen()
                    }}
                  >
                    <FullscreenOutlined />
                  </button>
                </span>
              )}

              {this.props.isShowFullScreen && (
                <span className="calendercontrols full-screen-parent  d-inline-flex">
                  <button
                    type="button"
                    className="ant-btn full-screen-button"
                    title="Exit Full Screen"
                    onClick={() => {
                      this.props.toggleFullScreen()
                   }}
                  >
                    <FullscreenExitOutlined />
                  </button>

                </span>
              )}
              </div>
            <div className="d-flex flex-wrap align-items-center gapx-1 calendercontrols">
              <Button
                title="Previous"
                onClick={() => this.handleDirection("prev")}
                className="push-r-12"
              >
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.87007 0.214784C8.17664 0.501169 8.17664 0.96549 7.87007 1.25187L2.7 6.08145C2.39342 6.36784 2.39342 6.83216 2.7 7.11854L7.87007 11.9481C8.17664 12.2345 8.17664 12.6988 7.87007 12.9852C7.56349 13.2716 7.06643 13.2716 6.75986 12.9852L1.58979 8.15563C0.670068 7.29648 0.670066 5.90352 1.58979 5.04436L6.75986 0.214784C7.06644 -0.0716 7.56349 -0.0716001 7.87007 0.214784Z"
                    fill="#808396"
                  />
                </svg>
              </Button>
              <Button title="Next" onClick={() => this.handleDirection("next")}>
                <svg
                  width="9"
                  height="14"
                  viewBox="0 0 9 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.12993 0.214784C0.82335 0.501169 0.82335 0.96549 1.12993 1.25187L6.3 6.08145C6.60657 6.36784 6.60657 6.83216 6.29999 7.11854L1.12993 11.9481C0.823351 12.2345 0.823351 12.6988 1.12993 12.9852C1.4365 13.2716 1.93356 13.2716 2.24013 12.9852L7.4102 8.15563C8.32992 7.29648 8.32993 5.90352 7.4102 5.04436L2.24013 0.214784C1.93356 -0.0716 1.4365 -0.0716001 1.12993 0.214784Z"
                    fill="#808396"
                  />
                </svg>
              </Button>
            </div>

            <Radio.Group
              onChange={this.handleCalendarType}
              defaultValue="day"
              className="change_calendar_view_btns"
              value={this.state.calendarType}
            >
              <Radio.Button value="day">Today</Radio.Button>
              <Radio.Button value="week_days">Week Days</Radio.Button>
              <Radio.Button value="week">Week</Radio.Button>
            </Radio.Group>
          </div>
          {(loadingCalendarAPI || this.state.updatingApt) && (
            <div className="full-loader-div text-center mt-40 flex-1 d-flex align-items-center justify-content-center">
              <TableLoader isNormal={true} />
            </div>
          )}

          {
            <FullCalendar
              plugins={
                moment(this.props.selecetdDate).format("YYYY-MM-DD") >=
                moment(new Date()).format("YYYY-MM-DD")
                  ? [
                      resourceTimeGridPlugin,
                      interactionPlugin,
                      scrollgridPlugin,
                    ]
                  : [resourceTimeGridPlugin, scrollgridPlugin]
              }
              initialView="resourceTimeGridDay"
              // loading={true}
              weekends={this.state.calendarType !== "week_days"}
              events={uniqBy(this.state.updatedEvent, "recordId")}
              allDaySlot={false}
              eventContent={this.renderEventContent}
              headerToolbar={{
                left: "",
                right: "",
                center: "",
                end: "",
              }}
              initialDate={new Date(this.props.selecetdDate)}
              ref={this.calendarComponentRef}
              className="appoinmentcalender"
              droppable={true}
              editable={true}
              dropAccept={true}
              eventDrop={this.eventDrop}
              resourcesInitiallyExpanded={true}
              selectable={true}
              timeZone="local"
              slotMinWidth={200}
              dayMinWidth={200}
              eventMinHeight={60}
              resources={this.state.finalDoctorList}
              views={{
                week: {
                  titleFormat: {
                    dateStyle: "medium",
                  },
                },
              }}
              // eventStartEditable={true}
              slotDuration="00:15:00"
              slotLabelInterval={10}
              slotLabelFormat={{
                hour: "2-digit",
                minute: "2-digit",
              }}
              slotMinTime="8:00 AM"
              slotMaxTime="22:00 PM"
              resourceLabelDidMount={async (resourceObj, lableTds, bodyTds) => {
                if (resourceObj.resource.id === "all") {
                  resourceObj.el.style.backgroundColor = "grey";
                  resourceObj.el.style.color = "#fff";
                } else {
                  resourceObj.el.style.backgroundColor =
                    resourceObj.resource.eventBackgroundColor;
                  resourceObj.el.style.color =
                    resourceObj.resource.eventTextColor;
                }
              }}
              // resourcesInitiallyExpanded={true}
              // slotMinTime="8:00 AM"
              // slotMaxTime="22:00 PM"
              // eventClick={}
              dateClick={(e) => {
                const slotTime = moment(e?.date).format(`hh:mm A`);
                const slotTimeStart = e?.dateStr;
                console.log("slotTimeStart:", e);
                const now = new Date();
                const passedDate = new Date(e.dateStr);
                if (now > passedDate) {
                  message.destroy();
                  message.info({
                    content: "You can't book an appointment for past date/time",
                    duration: 1,
                  });
                  return;
                }

                if (e?.resource._resource.id !== "all") {
                  const slotTimeEnd = moment(e?.date)
                    .add(15, "minutes")
                    .toISOString();
                  const wholeSlot = [slotTimeStart, slotTimeEnd];

                  // console.log(
                  //   "🚀 ~ BookAppointmentFullCalendar ~ render ~ slotTime:",
                  //   slotTime
                  // );
                  const resourceName = e.resource?._resource?.title;

                  const resourceID = e.resource?._resource?.id;

                  this.setState({
                    isOutFromFullscreen: true,
                    resourceID: resourceID,
                    bookingRecord: {
                      apptDate: e.date,
                      apptTime: slotTime,
                      selectedAptType: this.props.selectedAptType,
                      doctor_name: resourceName?.split("-")[0],
                      resourceID: resourceID,
                      wholeSlot: wholeSlot,
                    },
                  });

                  // ModalConfig.info({
                  //   content: (
                  //     <div className="text-left font-weight-normal font-14">
                  //       <div className="d-flex flex-wrap align-items-center gapx-2">
                  //         <p className="mb-2" style={{ marginTop: "2px" }}>
                  //           <b>Date:</b> {moment(e.date).format(DATE_FORMAT)}{" "}
                  //           {slotTime}
                  //         </p>
                  //         <p className="text-capitalize mb-2">
                  //           <Tag className="ant-tag-has-color">
                  //             {this.props.selectedAptType}
                  //           </Tag>
                  //         </p>
                  //       </div>
                  //       <p className="">
                  //         <b>Clinician:</b> {resourceName?.split("-")[0]}
                  //       </p>
                  //       {/* <p>Resource ID: {resourceID}</p> */}
                  //       {/* {JSON.stringify(this.props)} */}
                  //       <div className="mt-4">
                  //         <Button
                  //           type="primary"
                  //           disabled={this.state.isBookingFromCalendar}
                  //           loading={this.state.isBookingFromCalendar}
                  //           onClick={() => {
                  //             this.handleAppointmentSubmissionFromCalendar(
                  //               wholeSlot,
                  //               resourceID,
                  //               ModalConfig
                  //             );
                  //           }}
                  //         >
                  //           Book Appointment
                  //         </Button>
                  //         <Button
                  //           onClick={() => {
                  //             ModalConfig.destroyAll();
                  //           }}
                  //           type="primary"
                  //           className="ml-2 cancel_btn"
                  //         >
                  //           Cancel
                  //         </Button>
                  //       </div>
                  //     </div>
                  //   ),
                  //   okButtonProps: {
                  //     style: {
                  //       display: "none",
                  //     },
                  //   },

                  //   getContainer: "body",
                  //   centered: {
                  //     autoCenter: true,
                  //   },
                  // });
                }
              }}
              dayHeaderDidMount={(d) => {
                console.log("🚀 ~ dayHeaderDidMount ~ render ~ d:", d);
                d.el.innerText = moment(d.date).format("ddd DD/MM");
              }}
            />
          }
          <ModalPopUp
            footer={false}
            visible={this.state.bookingRecord !== undefined}
            handleCancel={() => this.setState({ bookingRecord: undefined })}
          >
            <div className="text-left font-weight-normal font-14 pt-3">
              <div className="d-flex flex-wrap align-items-center gapx-2">
                <p className="mb-2" style={{ marginTop: "2px" }}>
                  <b>Date:</b>{" "}
                  {moment(this.state?.bookingRecord?.apptDate).format(
                    DATE_FORMAT
                  )}{" "}
                  {this.state?.bookingRecord?.apptTime}
                </p>
                <p className="text-capitalize mb-2">
                  <Tag className="ant-tag-has-color">
                    {this.props.selectedAptType?.replaceAll("_", " ")}
                  </Tag>
                </p>
              </div>
              <p className="">
                <b>Clinician:</b>{" "}
                {this.state?.bookingRecord?.doctor_name?.split("-")[0]}
              </p>

              <div className="mt-4">
                <Button
                  type="primary"
                  disabled={this.props.isBooking}
                  loading={this.props.isBooking}
                  onClick={() => {
                    this.props.handleAppointmentSubmission(
                      this.state?.bookingRecord?.wholeSlot,
                      this.state?.bookingRecord?.resourceID
                    );
                  }}
                >
                  Book Appointment
                </Button>
                <Button
                  onClick={() => {
                    this.setState({ bookingRecord: undefined });
                  }}
                  type="primary"
                  className="ml-2 cancel_btn"
                >
                  Cancel
                </Button>
              </div>
            </div>
          </ModalPopUp>
        {/* </FullScreenHook> */}
      </div>
    );
  }
}

import { Collapse } from "antd";
import React, { Component } from "react";
// import { verifyObject } from "../../../utilities/utils";
import AppointmentDetailHeader from "./AppointmentDetailHeader";
import PatientDetailHeader from "./PatientDetailHeader";
const { Panel } = Collapse;

export default class CollapsiableSubHeader extends Component {
  render() {
    let {
      appointmentDetail,
      patientDetail,
      imageModalParams,
      openPhotoID,
      openLocation,
    } = this.props;
    return (
      <div className="CollapsiableSubHeader">
        <Collapse ghost className="in-block">
          <Panel key="1">
            {appointmentDetail ? (
              <AppointmentDetailHeader appointmentDetail={appointmentDetail} />
            ) : (
              <PatientDetailHeader
                patientDetail={patientDetail}
                imageModalParams={imageModalParams}
                openPhotoID={openPhotoID}
                openLocation={openLocation}
              />
            )}
          </Panel>
        </Collapse>
      </div>
    );
  }
}

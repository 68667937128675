import {listingReducer} from "reducers/utils/listingReducer";
import {getAcuteMedicationsApi} from "services/acuteMedications";

const {reducer, actions, types} = listingReducer({
  name: `nhsAcuteMedications`,
  getApi: getAcuteMedicationsApi,
});

const nhsAcuteMedications = reducer;
export {nhsAcuteMedications, actions, types};

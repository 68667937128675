import { get, post, patch, deletee } from "./index";

export const  getPatientDetail = (params) => {
    const request = {
        subUrl: `/patients/${params.id}.json`,
        params,
    };
    return get(request);
};
    
export const getMedicalHistoryApi = (params) => {
    const request = {
        subUrl: `/medical_histories.json`,
        params,
    };
    return get(request);
};

export const getUserMedicalHistoryApi = (data) => {
    const request = {
        subUrl: `/medical_histories/${data.id}.json`,
        data,
    };
    return get(request);
};

export const addMedicalHistories = (data) => {
    const request = {
        subUrl: `/medical_histories.json`,
        data,
    };
    return post(request);
};

export const updateMedicalHistories = (data) => {
    const request = {
        subUrl: `/medical_histories/${data.id}.json`,
        data,
    };
    return patch(request);
};

export const deleteMedicalHistories = (data) => {
    const request = {
        subUrl: `/medical_histories/${data.id}.json`,
    };
    return deletee(request);
};

import React, {Component, Fragment} from "react";
import {
  Layout,
  Menu,
  Input,
  Button,
  Spin,
  Select,
  Modal,
  Image,
  Result,
  Tag,
  // message,
} from "antd";
import {connect} from "react-redux";
import {Prompt, withRouter} from "react-router-dom";
import {onRequestSetComponent, onSetSidebarKey} from "reducers/dynamicTab";
import {bindActionCreators} from "redux";
import {
  getAllChatGroups,
  getAllMessagesOfUser,
  getAllCSMessagesOfUser,
  sendFirstMessageAPI,
  sendFirstCSMessageAPI,
  initiateChat,
  initiateCSChat,
  endChat,
  endChatCS,
} from "../../services/messages";
import {
  disableCopy,
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";
import "./Messages.css";
import {
  debounce,
  isEmpty,
  isObject,
  uniqueId,
  uniqBy,
  map,
  reduce,
  isArray,
  // identity,
} from "lodash";
import ModalPopUp from "../../components/common/ModalPopUp";
// import Highlighter from "react-highlight-words";
// import { getAllUsers } from "../../services/userProfile";
import {chatMessagesChannel} from "../../reducers/actionCableData";
import store from "../../app/store";
import {baseWebSocketURL} from "services/index";
import actionCable from "actioncable";
import file_upload from "assets/images/common/attached.svg";
import doctorProfile from "assets/images/common/doctor_profile.svg";
import Linkify from "react-linkify";
import Scrollbars from "react-custom-scrollbars";
import {SearchOutlined} from "@ant-design/icons";
import moment from "moment";
import {spaceRegex} from "../../constants/common";
import CommonLoader from "../../components/common/CommonLoader";
import {getPatients} from "../../services/patients";
import {Radio} from "antd";
const {Sider} = Layout;

const {Search} = Input;
const {Option} = Select;
const allowedFiletypes =
  "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/pdf,application/pdf,application/rtf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/zip,application/x-rar-compressed,application/vnd.ms-powerpoint,image/jpeg,image/jpg,image/png";
class MessagesContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      chatGroups: [],
      selectedKey: null,
      isFirstMessage: false,
      visible: false,
      patients: [],
      receiver_id: null, // used for  intilize chat
      receiverId: null, // used for get message
      reason: null,
      messages: [],
      chat_attachments: [],
      intiating: false,
      selectedUser: null,
      chatGroups2: [],
      hasAttachment: false,
      gettingAllgroups: false,
      isCountRead: false,
      subscribedChannel: null,
      chatStatus: null,
      chatLoading: false,
      chatType: "Patient",
      chatType2: "",
    };
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.chatCable = null;
    this.fileRef = React.createRef();

    // this.handleEvent = this.handleEvent.bind(this)
  }

  componentDidMount() {
    this.GetMessagesGroups();
    this.chatCable = actionCable.createConsumer(`${baseWebSocketURL}/cable`);
    this.connectMessageList();
  }
  componentDidUpdate(prevState) {
    // console.log("pre props-->", prevState, this.state.chatGroups);
  }
  connectMessageList = async () => {
    await this.chatCable.subscriptions.create(
      {
        channel: "MessageChannelList",
        auth_token: store.getState().localStore.token,
        // ...params,
      },
      {
        connected: function (d) {
          console.log("MessageChannelList Socket connected");
        },
        received: (data) => {
          let messageChannel = data.data.message_channel;
          console.log("Received Data MessageChannelList", messageChannel);
          let {selectedUser} = this.state;
          console.log("selectedUser", selectedUser);
          // console.log("data.message_content", data.message_content);
          // this.setState({
          //   [`lastMessage${selectedUser.id}`]: messageChannel.last_message,
          // });
          this.setState(
            (state) => {
              let chatGroups = state.chatGroups;
              let index = "";
              chatGroups.forEach((d, i) => {
                if (d.id === messageChannel.id) {
                  index = i;
                  return true;
                }
              });
              console.log("Index--->", index, chatGroups[index]);
              if (chatGroups && chatGroups[index]) {
                chatGroups[index].last_message = messageChannel.last_message;
                chatGroups[index].last_message_at =
                  messageChannel.last_message_at;
                chatGroups[index].last_unread_message =
                  messageChannel.last_unread_message;
                chatGroups[index].unread_count = messageChannel.unread_count;
                if (this.state.selectedUser) {
                  this.state.selectedUser.status = messageChannel.status;
                }
              }

              // chatGroups[index] = messageChannel;

              // chatGroups.map((d, index) => {
              //   console.log("D", d);
              //   if (d.id === messageChannel.id) {
              //     d[index] = data.message_channel;
              //   }
              //   return null;
              // });
              console.log(
                "sorted--->",
                chatGroups
                  .map((obj) => {
                    return {...obj, date: new Date(obj.last_message_at)};
                  })
                  .sort((a, b) => b.date - a.date)
              );
              console.log(
                "chatGroups",
                reduce(map(chatGroups, "unread_count"), (prev, index) => {
                  // console.log("PREV", prev, index);
                  return prev + index;
                })
              );
              let totalCount = reduce(
                map(chatGroups, "unread_count"),
                (prev, index) => {
                  // console.log("PREV", prev, index);
                  return prev + index;
                }
              );
              store.dispatch({
                type: "SET_GLOBAL_MESSAGE_COUNT",
                payload: totalCount,
              });
              let sorted = chatGroups
                .map((obj) => {
                  return {
                    ...obj,
                    date: new Date(
                      obj.status === "active" &&
                      obj.last_message_at !== null &&
                      obj.last_message !== null
                        ? obj.last_message_at
                        : obj.created_at
                    ),
                  };
                })
                .sort((a, b) => b.date - a.date);
              chatGroups = sorted;
              // if (chatGroups !== sorted) {
              //   console.log("not equal----->");
              //   return sorted;
              // } else {
              //   console.log("-------> equal");

              //   return chatGroups;
              // }

              return {chatGroups};
            },
            () => {
              console.log("-------> latest", this.state.chatGroups);
            }
          );

          // self.setState(
          //   {
          //     hasAttachment: !isEmpty(data.attachment) ? true : false,
          //     messages: !isEmpty(data.message_content)
          //       ? this.state.messages.concat({
          //           message: data.message_content,
          //           is_owner: data.sender_id === user.id,
          //         })
          //       : !isEmpty(data.attachment)
          //       ? this.state.messages.concat({
          //           message: null,
          //           attachments: data.attachment_url,
          //           is_owner: data.sender_id === user.id,
          //         })
          //       : this.state.messages,
          //   },
          //   () => {
          //     this.scrollToBottom();
          //   }
          // );
        },
      }
    );
  };

  connecteSocket = async (params) => {
    let {user} = this.props;
    let self = this;
    let channel = await this.chatCable.subscriptions.create(
      {
        channel: "DirectMessageChannel",
        auth_token: store.getState().localStore.token,
        ...params,
      },
      {
        connected: function (d) {
          console.log("chatMessagesChannel Socket connected", d);
          if (params.unread_count > 0) {
            console.log("params", params, channel);
            channel.read({
              id: params.id,
              auth_token: store.getState().localStore.token,
            });
          }
        },
        received: async (data) => {
          console.log("Call Red", data);

          await self.setState(
            {
              hasAttachment: !isEmpty(data.attachment) ? true : false,

              messages: !isEmpty(data.message_content)
                ? uniqBy(
                    this.state.messages.concat({
                      message_id: uniqueId("MSG"),
                      message: data.message_content,
                      is_owner: data.sender_id === user.id,
                      last_message: data.message_content,
                    }),
                    "message_id"
                  )
                : !isEmpty(data.attachment)
                ? uniqBy(
                    this.state.messages.concat({
                      message: null,
                      message_id: uniqueId("MSG"),
                      attachments: data.attachment_url,
                      is_owner: data.sender_id === user.id,
                    }),
                    "message_id"
                  )
                : this.state.messages,
            },
            () => {
              this.scrollToBottom();
            }
          );
        },
        speak: function (data) {
          console.log("Speak Called ", data, self.state.chatType2);
          return this.perform("speak", {
            ...data,
            message_type: "Admin",
          });
        },
        read: function (data) {
          // console.log("DATA",data)
          self.setState(
            (state) => {
              console.log("Read Called ==>");
              let foundValue = state.chatGroups.find((d) => d.id === data.id);
              if (foundValue) {
                foundValue.unread_count = 0;
              }
              return {
                chatGroups: state.chatGroups,
                chatGroups2: state.chatGroups,
              };
            },
            () => {}
          );
          return this.perform("read", data);
        },
      }
    );

    await this.setState({subscribedChannel: channel}, async () => {
      // if (params.unread_count > 0) {
      //   console.log("params", params, channel);
      //   await channel.subscripread({
      //     id: user.id,
      //     auth_token: store.getState().localStore.token,
      //   });
      // }
    });

    // return Promise(channel);
  };

  _clearComponents = () => {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(null);
      onSetSidebarKey(null);
    }
  };

  GetMessagesGroups = async () => {
    let {
      user: {id},
    } = this.props;
    try {
      await this.setState({gettingAllgroups: true});
      let response = await getAllChatGroups({
        user_id: id,
      });
      let chats1 = verifyObject(response, "data.data.doctor_messages", []);
      let chats2 = verifyObject(response, "data.data.cs_messages", []);
      let combine = [...chats1, ...chats2];
      let sorted = combine
        .map((obj) => {
          return {
            ...obj,
            date: new Date(
              obj.status === "active" &&
              obj.last_message_at !== null &&
              obj.last_message !== null
                ? obj.last_message_at
                : obj.created_at
            ),
          };
        })
        .sort((a, b) => b.date - a.date);
      console.log("get sorted-->", sorted);
      await this.setState({
        chatGroups: sorted,
        // [
        //   ...verifyObject(response, "data.data.doctor_messages", []),
        //   ...verifyObject(response, "data.data.cs_messages", []),
        // ],
        chatGroups2: [
          ...verifyObject(response, "data.data.doctor_messages", []),
          ...verifyObject(response, "data.data.cs_messages", []),
        ],
        gettingAllgroups: false,
      });

      // console.log("Response", response);
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({
        gettingAllgroups: false,
        chatGroups: [],
        chatGroups2: [],
      });
      // errorToast({ content: message });
    }
  };

  GetAllMessages = async (channelID, messageUser) => {
    // let { chatType } = this.state;

    let chatType = verifyObject(messageUser, "chat_type", null);

    console.log("chatType", chatType);
    this.setState({chatType2: chatType});

    if (chatType === "Patient") {
      let {
        user: {id},
      } = this.props;
      let {selectedUser} = this.state;
      try {
        await this.setState({chatLoading: true});
        let response = await getAllMessagesOfUser({
          user_id: id,
          channel_id: channelID,
        });
        let msgArray = [];
        let allMsgs = verifyObject(response, "data.data", []);

        allMsgs.map((msg) => {
          msgArray.push({
            message_id: uniqueId("MSG"),
            ...msg,
          });
          return null;
        });

        // message_id: uniqueId("MSG"),
        await this.setState(
          {
            messages: msgArray,
            chatStatus: selectedUser.status,
            chatLoading: false,
          },
          () => {
            this.scrollToBottom();
          }
        );
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({searching: false, chatLoading: false});
        errorToast({content: message});
      }
    } else {
      console.log("IN");
      let {
        user: {id},
      } = this.props;
      let {selectedUser} = this.state;
      try {
        await this.setState({chatLoading: true});
        let response = await getAllCSMessagesOfUser({
          user_id: id,
          channel_id: channelID,
        });
        let msgArray = [];
        let allMsgs = verifyObject(response, "data.data", []);

        allMsgs.map((msg) => {
          msgArray.push({
            message_id: uniqueId("MSG"),
            ...msg,
          });
          return null;
        });

        // message_id: uniqueId("MSG"),
        await this.setState(
          {
            messages: msgArray,
            chatStatus: selectedUser.status,
            chatLoading: false,
          },
          () => {
            this.scrollToBottom();
          }
        );
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({searching: false, chatLoading: false});
        errorToast({content: message});
      }
    }
  };

  _getUserMessages = (id, user) => {
    this.setState(
      {
        selectedKey: [`${id}`],
        receiverId: user.member_id,
        selectedUser: user,
        isFirstMessage: user.last_message === null,
      },
      async () => {
        // let { subscribedChannel } = this.state;
        let unread_count = user.unread_count;
        if (
          this.state.subscribedChannel &&
          typeof this.state.subscribedChannel.unsubscribe === "function"
        ) {
          console.log("chatCable");
          await this.state.subscribedChannel.unsubscribe();
        }
        console.log("this.state.chatType2", user);
        await this.connecteSocket({
          id,
          message_type: user.chat_type,
          unread_count,
        });

        await this.GetAllMessages(id, user);
        await this.scrollToBottom();
      }
    );
  };

  // callReadSocket = async (user) => {
  //   if (user && user.unread_count > 0) {
  //     let { subscribedChannel } = this.state;
  //     console.log("subscribedChannel", subscribedChannel);
  //     await
  //   }
  // };

  SendFirstMessage = async (isImage) => {
    let {
      user: {id},
    } = this.props;
    let {selectedKey, receiverId, message, chat_attachments} = this.state;

    if (this.state.chatType2 === "Patient") {
      try {
        let formData = new FormData();
        formData.set("messages[sender_id]", id);
        formData.set("message_channel_id", selectedKey[0]);
        formData.set("messages[receiver_id]", receiverId);
        if (!isImage) {
          formData.set("messages[body]", message);
        } else {
          // console.log("Chat Attachments", chat_attachments);
          this.setState({hasAttachment: true});
          if (chat_attachments && isObject(chat_attachments)) {
            Object.keys(chat_attachments).map((key, index) => {
              formData.set(`chat_attachments[${index}]`, chat_attachments[key]);
              return null;
            });
          }
        }
        await this.setState({sendingImage: true});
        let response = await sendFirstMessageAPI(formData);
        console.log("image send response", response);
        if (response.status === 200) {
          await this.setState({sendingImage: false});
        }
        let selectedKEY =
          selectedKey && isArray(selectedKey) ? selectedKey[0] : null;
        await this.GetAllMessages(selectedKEY, this.state.selectedUser);
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({searching: false});
        errorToast({content: message});
      }
    } else if (this.state.chatType2 === "Admin") {
      try {
        let formData = new FormData();
        formData.set("messages[person_id]", id);
        formData.set("messages[corporate_id]", this.state.receiver_id);
        formData.set("messages[sender_id]", id);
        formData.set("message_channel_id", selectedKey[0]);

        if (!isImage) {
          formData.set("messages[body]", message);
        } else {
          // console.log("Chat Attachments", chat_attachments);
          this.setState({hasAttachment: true});
          if (chat_attachments && isObject(chat_attachments)) {
            Object.keys(chat_attachments).map((key, index) => {
              formData.set(`chat_attachments[${index}]`, chat_attachments[key]);
              return null;
            });
          }
        }
        await this.setState({sendingImage: true});
        let response = await sendFirstCSMessageAPI(formData);
        console.log("image send response", response);
        if (response.status === 200) {
          await this.setState({sendingImage: false});
        }
        let selectedKEY =
          selectedKey && isArray(selectedKey) ? selectedKey[0] : null;

        await this.GetAllMessages(selectedKEY, this.state.selectedUser);
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({searching: false});
        errorToast({content: message});
      }
    }
  };
  onSendMessage = () => {
    let {isFirstMessage, messages} = this.state;
    console.log("Messages Length", messages.length);
    if (isFirstMessage || messages.length === 0) {
      console.log("Messages Length In", messages.length);

      this.SendFirstMessage(false);
      this.setState({
        message: "",
        isFirstMessage: false,
        hasAttachment: false,
      });
    } else {
      let {selectedKey, message, subscribedChannel} = this.state;
      console.log("message", message);
      subscribedChannel.speak({
        id: selectedKey[0],
        message: message,
      });
      this.setState({message: "", hasAttachment: false});
    }
  };

  componentWillUnmount() {
    // if (
    //   this.state.subscribedChannel &&
    //   typeof this.state.subscribedChannel.unsubscribe === "function"
    // ) {
    //   console.log("chatCable");
    //   this.state.subscribedChannel.unsubscribe();
    // }
  }

  onSendFile = (event) => {
    this.setState({chat_attachments: event.target.files}, () => {
      this.SendFirstMessage(true);
    });
  };
  async _handleSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          snomedCodes: [],
          search: value,
        },
        async () => {
          this._getPatients(false);
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  async _getPatients(isLoadMore) {
    try {
      let response = await getPatients({
        page: this.state.page,
        search: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let patients = verifyObject(response, "data.data", []);
      this.setState({
        patients: isLoadMore ? [...this.state.patients, ...patients] : patients,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }
  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getPatients(true);
      }
    );
  };
  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };

  renderFile(fileUrl) {
    let url1 = typeof fileUrl.split === "function" && fileUrl.split("?");
    let fileType = url1[0].split(/[#?]/)[0].split(".").pop().trim();
    let docFiles = ["pdf", "doc", "docx", "csv", "xl", "xls", "xlsx"];
    let imageFiles = ["jpg", "jpeg", "png", "svg", "gif", "bmp", "webp", ""];

    if (docFiles.includes(fileType.toLowerCase())) {
      return (
        <a href={fileUrl} target="__blank">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="40"
            height="40"
            viewBox="0 0 40 40"
          >
            <defs>
              <path
                id="a"
                d="M10 7h13.249a2 2 0 0 1 1.317.495l6.751 5.907A2 2 0 0 1 32 14.908V35a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2z"
              />
              <path
                id="b"
                d="M23.5 7c.322 0 .634.117.876.33l6.941 6.072A2 2 0 0 1 32 14.908V15a1 1 0 0 1-1 1h-6a2 2 0 0 1-2-2V7.5a.5.5 0 0 1 .5-.5z"
              />
            </defs>
            <g fill="none" fill-rule="evenodd">
              <use fill="#FFF" xlinkHref="#a" />
              <path
                stroke="#1445b7"
                d="M10 7.5A1.5 1.5 0 0 0 8.5 9v26a1.5 1.5 0 0 0 1.5 1.5h20a1.5 1.5 0 0 0 1.5-1.5V14.908a1.5 1.5 0 0 0-.512-1.13l-6.752-5.907a1.5 1.5 0 0 0-.987-.371H10z"
              />
              <use fill="#E8EFFF" xlinkHref="#b" />
              <path
                stroke="#1445b7"
                d="M23.5 7.5V14a1.5 1.5 0 0 0 1.5 1.5h6a.5.5 0 0 0 .5-.5v-.092a1.5 1.5 0 0 0-.512-1.13l-6.941-6.072A.83.83 0 0 0 23.5 7.5z"
              />
              <rect
                width="15"
                height="8"
                x="12.5"
                y="23.5"
                stroke="#1445b7"
                rx="1"
              />
              <path
                fill="#1445b7"
                d="M12.5 27h15a.5.5 0 1 1 0 1h-15a.5.5 0 1 1 0-1z"
              />
              <path
                fill="#1445b7"
                d="M17.5 23a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8a.5.5 0 0 1 .5-.5zM22.5 23a.5.5 0 0 1 .5.5v8a.5.5 0 0 1-1 0v-8a.5.5 0 0 1 .5-.5z"
              />
            </g>
          </svg>
        </a>
      );
    } else if (imageFiles.includes(fileType.toLowerCase())) {
      return (
        // <a href={fileUrl} target="__blank">
        <Image width={100} alt="" src={fileUrl} />
        // </a>
      );
    } else {
      return (
        <a
          style={{
            color: "white",
            textDecoration: "underline",
            fontWeight: 600,
          }}
          target="__blank"
          href={fileUrl}
        >
          {" "}
          {fileUrl}
        </a>
      );
    }
  }
  _handleDropDownChange(value, key) {
    this.setState({[key]: value ? value : ""}, () => {});
  }
  _hideModal = () => {
    this.setState({
      visible: false,
      receiver_id: null,
      reason: "",
      chatType: "Patient",
    });
  };
  _handleSubmit = async () => {
    if (this.state.chatType === "Patient") {
      let {
        user: {id},
      } = this.props;
      let {receiver_id, reason} = this.state;
      let formData = new FormData();
      formData.set("message_channel[sender_id]", id);
      formData.set("message_channel[receiver_id]", receiver_id);
      formData.set("message_channel[reason]", reason);
      try {
        await this.setState({intiating: true});
        let response = await initiateChat(formData);
        await this.GetMessagesGroups();
        successToast({content: verifyObject(response, "data.message", "")});
        await this.setState({
          visible: false,
          receiver_id: null,
          reason: "",
          intiating: false,
        });
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({intiating: false});
        errorToast({content: message});
      }
    } else {
      let {
        user: {id},
      } = this.props;
      let {receiver_id, reason} = this.state;
      let formData = new FormData();
      formData.set("cs_message_channel[person_id]", id);
      formData.set("cs_message_channel[corporate_id]", receiver_id);
      formData.set("cs_message_channel[reason]", reason);
      formData.set("cs_message_channel[chat_mode]", "doctor_to_corporate");

      try {
        await this.setState({intiating: true});
        let response = await initiateCSChat(formData);
        await this.GetMessagesGroups();
        successToast({content: verifyObject(response, "data.message", "")});
        await this.setState({
          visible: false,
          receiver_id: null,
          reason: "",
          intiating: false,
        });
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({intiating: false});
        errorToast({content: message});
      }
    }
  };

  _endChat = async (status) => {
    // Modal.confirm()
    let statusText = "";
    if (status === "archived") {
      statusText = "end";
    }
    if (status === "active") {
      statusText = "resume";
    }
    if (status === "pause") {
      statusText = "pause";
    }
    Modal.confirm({
      title: `Are you sure want to ${statusText} this chat ?`,
      okButtonProps: {loading: this.state.loading},
      className: `chat_status`,
      onOk: async () => {
        await this.setState((state) => {
          let selectedUserLocal = state.selectedUser;
          selectedUserLocal.status = status;
          return {selectedUser: selectedUserLocal};
        });

        if (this.state.chatType2 === "Patient") {
          console.log("this.state.selectedKey[0]", this.state.selectedKey);
          console.log("this.state.selectedKey[0]", this.state.selectedUser);

          await endChat({
            id: this.state.selectedKey[0],
            status: status,
            chatStatus: status,
          })
            .then(async (response) => {
              await this.setState({loading: true});

              await this.setState(
                {
                  visible: false,
                  // receiver_id: null,
                  reason: "",
                  // selectedKey: null,
                  loading: false,
                },
                () => {
                  this.GetMessagesGroups();
                  this.GetAllMessages(
                    this.state.selectedKey[0],
                    this.state.selectedUser
                  );
                  successToast({
                    content: verifyObject(response, "data.message", ""),
                  });
                }
              );
            })
            .catch((error) => {
              const {message} = getErrorObject(error);
              this.setState({loading: false});
              errorToast({content: message});
            });
        } else {
          await endChatCS({
            id: this.state.selectedKey[0],
            status: status,
          })
            .then(async (response) => {
              await this.setState({loading: true});

              await this.setState(
                {
                  visible: false,
                  receiver_id: null,
                  reason: "",
                  // selectedKey: null,
                  loading: false,
                  chatStatus: status,
                },
                () => {
                  this.GetMessagesGroups();
                  this.GetAllMessages(
                    this.state.selectedKey[0],
                    this.state.selectedUser
                  );
                  successToast({
                    content: verifyObject(response, "data.message", ""),
                  });
                }
              );
            })
            .catch((error) => {
              const {message} = getErrorObject(error);
              this.setState({loading: false});
              errorToast({content: message});
            });
        }

        // try {
        //   let response = await endChat({ id: this.state.selectedKey[0] });
        //   await this.GetMessagesGroups();
        //   successToast({ content: verifyObject(response, "data.message", "") });
        //   await this.setState({
        //     visible: false,
        //     receiver_id: null,
        //     reason: "",
        //     selectedKey: null,
        //   });
        // } catch (error) {
        //   const { message } = getErrorObject(error);
        //   await this.setState({ searching: false });
        //   errorToast({ content: message });
        // }
      },
    });
  };

  scrollToBottom = async () => {
    if (this.messagesEnd) {
      await this.messagesEnd.scrollIntoView({behavior: "smooth"});
    }

    // await this.setState({ hasAttachment: false });
    // this._scrollRef.scrollTop = Number.MAX_SAFE_INTEGER;
  };
  handleRadio = (e) => {
    if (e.target.value === "Admin") {
      let {corporate_name, corporate_id} = this.props.user;
      this.setState({
        receiver_id: corporate_id,
        corporateName: corporate_name,
        reason: "",
      });
    } else {
      this.setState({
        receiver_id: null,
        corporateName: null,
        reason: "",
      });
    }
    this.setState({chatType: e.target.value});
  };
  render() {
    let {
      chatGroups,
      selectedKey,
      searching,
      isLoadmore,
      patients,
      visible,
      reason,
      messages,
      selectedUser,
      hasAttachment,
      gettingAllgroups,
      sendingImage,
      // isCountRead,
    } = this.state;
    console.log("updated chat group--->", chatGroups);

    return (
      <div className="messages-wrapper padding-72-t">
        <div className="container-fluid">
          <div className="col-md-12 selection_container padding-right-10 padding-left-10">
            <Prompt
              message={(location, action) => {
                if (action === "PUSH") {
                  this._clearComponents();
                  return true;
                }
                if (action === "POP") {
                  this._clearComponents();
                  return true;
                }
                return true;
              }}
            />
            {gettingAllgroups && <CommonLoader message="Loading chats ..." />}
            {!gettingAllgroups && chatGroups.length !== 0 && (
              <Fragment>
                <div className="row align-items-center">
                  <div className="col-md-5">
                    <h3 class="font-14 weight-500 text-default-color m-0">
                      Messages
                    </h3>
                  </div>
                  <div
                    style={{
                      display:
                        this.state.chatStatus !== "archived" &&
                        this.state.chatStatus !== null &&
                        "flex",
                      justifyContent:
                        this.state.chatStatus !== "archived" &&
                        this.state.chatStatus !== null &&
                        "flex-end",
                    }}
                    className={`col-md-7 text-right`}
                  >
                    <Button
                      onClick={() => this.setState({visible: true})}
                      className="btn btn-green btn-custom margin-right-12 width-135px height-40-imp"
                    >
                      Initiate Chat
                    </Button>
                    {this.state.selectedKey &&
                      this.state.chatStatus !== "archived" && (
                        <Select
                          className={`form-control  margin-0 chat_dropdown custom-arrow`}
                          name="receiver_id"
                          id="scrollableDiv"
                          onChange={this._endChat}
                          placeholder="Change Chat Status"
                        >
                          {this.state.chatStatus === "active" && (
                            <Fragment>
                              <Option value="archived">End Chat</Option>
                              <Option value="pause">Pause Chat</Option>
                            </Fragment>
                          )}
                          {this.state.chatStatus === "pause" && (
                            <Fragment>
                              <Option value="archived">End Chat</Option>
                              <Option value="active">Resume Chat</Option>
                            </Fragment>
                          )}
                        </Select>
                      )}
                  </div>
                </div>
                <div className="messages-inner-wrapper">
                  <div className="user-groups-wrapper">
                    <div className="messages-search">
                      <Input
                        allowClear={true}
                        placeholder="Search"
                        prefix={<SearchOutlined />}
                        onChange={(e) => {
                          let searchValue = e.target.value;

                          if (spaceRegex.test(searchValue) === false) {
                            this.setState({
                              isSideSearch: true,
                              chatGroups: this.state.chatGroups2,
                            });
                          } else {
                            let chatGroups = this.state.chatGroups2.filter(
                              (d) =>
                                d.channel_member
                                  .toLowerCase()
                                  .includes(searchValue.toLowerCase())
                            );

                            if (chatGroups.length === 0) {
                              this.setState({
                                chatGroups: this.state.chatGroups,
                              });
                            } else {
                              this.setState({chatGroups: chatGroups});
                            }
                          }
                        }}
                      />
                    </div>
                    <Sider
                      style={{
                        overflow: "auto",
                        height: "70vh",
                        // position: "fixed",
                        left: 0,
                      }}
                      theme="light"
                      width="250"
                      collapsedWidth="50"
                      collapsible={false}
                      //   collapsed={collapsed}
                      //   onCollapse={onCollapse}
                    >
                      <Scrollbars>
                        <Menu
                          theme="light"
                          mode="inline"
                          selectedKeys={selectedKey}
                        >
                          {chatGroups.map((user) => {
                            return (
                              <Menu.Item
                                onClick={() =>
                                  this._getUserMessages(user.id, user)
                                }
                                key={user.id}
                                className={`chat-user-item ${
                                  user.status === "archived" && "archived-chat"
                                }`}
                                // icon={}
                              >
                                <div className="message-user-wrapper">
                                  <div className="message-user-wrapper-left">
                                    <span className="chat-user-name chat-user-name-side">
                                      {user && (
                                        <Image
                                          alt=""
                                          src={
                                            user.member_image
                                              ? user.member_image
                                              : doctorProfile
                                          }
                                          className="chat-user-img"
                                          fallback={doctorProfile}
                                          preview={false}
                                        ></Image>
                                      )}
                                      <div
                                        style={{
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <span>{user.channel_member}</span>
                                        {user.status !== "archived" ? (
                                          <Fragment>
                                            {user.unread_count <= 0 &&
                                              (this.state[
                                                `lastMessage${user.id}`
                                              ] ? (
                                                <span className="chat-user-time chat-read-message">
                                                  {
                                                    this.state[
                                                      `lastMessage${user.id}`
                                                    ]
                                                  }
                                                </span>
                                              ) : (
                                                <span className="chat-user-time chat-read-message">
                                                  {user && user.last_message
                                                    ? user.last_message
                                                    : ""}
                                                </span>
                                              ))}
                                            {user.unread_count > 0 && (
                                              <span className="chat-user-time chat-unread-message">
                                                {user &&
                                                user.last_unread_message
                                                  ? user.last_unread_message
                                                  : ""}
                                              </span>
                                            )}
                                          </Fragment>
                                        ) : (
                                          <span></span>
                                        )}
                                        <span>
                                          <Tag size="small" color="#007bff">
                                            {user.chat_type}
                                          </Tag>
                                        </span>
                                      </div>
                                    </span>
                                  </div>
                                  {user.status !== "archived" &&
                                    user.unread_count !== 0 && (
                                      <div className="message-user-wrapper-right">
                                        <span className="chat-user-unreadmsgs">
                                          {verifyObject(
                                            user,
                                            "unread_count",
                                            ""
                                          )}
                                        </span>
                                      </div>
                                    )}
                                  {user.status === "archived" && (
                                    <div className="message-user-wrapper-right">
                                      {
                                        <Tag
                                          className="font-size-mini"
                                          color="red"
                                        >
                                          Ended
                                        </Tag>
                                      }
                                    </div>
                                  )}
                                </div>
                              </Menu.Item>
                            );
                          })}
                        </Menu>
                      </Scrollbars>
                    </Sider>
                  </div>
                  {selectedKey && (
                    <div className="message-conversations-wrapper">
                      <div className="message-conversations-userdata">
                        <div className="message-user-wrapper">
                          <div className="message-user-wrapper-left">
                            <span className="chat-user-name">
                              <Image
                                alt=""
                                src={
                                  selectedUser.member_image
                                    ? selectedUser.member_image
                                    : doctorProfile
                                }
                                className="chat-user-img"
                                fallback={doctorProfile}
                                preview={
                                  selectedUser.member_image ? true : false
                                }
                              ></Image>
                              {selectedUser.channel_member}
                            </span>
                          </div>
                        </div>

                        {/* <span className="chat-user-name">
                         
                        </span> */}
                      </div>
                      <Scrollbars
                        containerRef={(ref) => {
                          this._scrollRef = ref;
                        }}
                        className="msgs_chat_scrolling"
                        // style={{ height: "85%" }}
                      >
                        <div
                          style={{height: "100%"}}
                          className="chat-bubble-container"
                        >
                          {this.state.chatLoading && (
                            <span className="notification_loader_section ng-scope">
                              {/* <PlaceholderLoader contentName="Notifications..." /> */}
                              <div className="noti-loader-wrapper">
                                <div className="double-bounce1"></div>
                                <div className="double-bounce2"></div>
                                {/* <span>Loading Messages...</span> */}
                              </div>
                            </span>
                          )}
                          {!this.state.chatLoading && messages.length === 0 && (
                            <div className="no_message">
                              <span>No messages available</span>
                            </div>
                          )}
                          {!this.state.chatLoading &&
                            messages.map((message) => {
                              if (message.is_owner) {
                                return (
                                  <Linkify>
                                    {message.attachments &&
                                    message.attachments.length !== 0 ? (
                                      <div className="chat-bubble-right chat-right">
                                        {message.attachments.map(
                                          (attachment) => {
                                            return this.renderFile(attachment);
                                          }
                                        )}
                                      </div>
                                    ) : (
                                      !isEmpty(message.message) && (
                                        <div className="chat-bubble-right chat-right">
                                          <span className="chat-msg">
                                            {message.message}
                                          </span>
                                          <span className="chat-date">
                                            {moment(message.created_at).format(
                                              "DD MMM, HH:mm"
                                            )}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </Linkify>
                                );
                              } else {
                                return (
                                  <Linkify>
                                    {message.attachments &&
                                    message.attachments.length !== 0 ? (
                                      <div className="chat-bubble-left chat-left">
                                        {message.attachments.map(
                                          (attachment) => {
                                            return this.renderFile(attachment);
                                          }
                                        )}
                                      </div>
                                    ) : (
                                      !isEmpty(message.message) && (
                                        <div className="chat-bubble-left chat-left">
                                          <span className="chat-msg">
                                            {message.message}
                                          </span>
                                          <span className="chat-date">
                                            {moment(message.created_at).format(
                                              "DD MMM, HH:mm"
                                            )}
                                          </span>
                                        </div>
                                      )
                                    )}
                                  </Linkify>
                                );
                              }
                            })}

                          <div
                            // style={{ float: "left", clear: "both" }}
                            style={{paddingTop: hasAttachment ? 100 : 30}}
                            ref={(el) => {
                              this.messagesEnd = el;
                            }}
                            // ref={}
                          ></div>
                        </div>
                      </Scrollbars>
                      {/* <span>{this.state.chatStatus}</span> */}
                      {this.state.selectedUser.status === "archived" && (
                        <div className="chat_ended_div">
                          <span>Chat has been ended</span>
                        </div>
                      )}
                      {this.state.selectedUser.status === "pause" && (
                        <div className="chat_ended_div">
                          <span>
                            {" "}
                            You can not message to this chat temporarily
                          </span>
                        </div>
                      )}

                      {this.state.selectedUser.status === "active" && (
                        <div className="chat_bottom_part">
                          <Search
                            placeholder="Type your message here..."
                            enterButton={sendingImage ? "Sending..." : "Send"}
                            size="large"
                            disabled={sendingImage}
                            loading={sendingImage}
                            suffix={
                              <div>
                                <label htmlFor="file">
                                  <img alt="" src={file_upload}></img>
                                </label>
                                <input
                                  onChange={this.onSendFile}
                                  id="file"
                                  type="file"
                                  multiple={true}
                                  ref={this.fileRef}
                                  accept={allowedFiletypes}
                                  style={{display: "none"}}
                                  onClick={() => {
                                    console.log("FIle Ref", this.fileRef);
                                    if (
                                      this.fileRef &&
                                      this.fileRef.current &&
                                      this.fileRef.current.value
                                    ) {
                                      this.fileRef.current.value = null;
                                    }
                                  }}
                                />
                              </div>
                            }
                            name="message"
                            value={this.state.message}
                            autoFocus
                            // onSearch={(value) => {
                            //   // alert("In")
                            //   if (spaceRegex.test(value) === true) {
                            //     this.onSendMessage(value, "text");
                            //   }
                            // }}
                            onSearch={(value) => {
                              // alert("In")
                              if (spaceRegex.test(value) === true) {
                                this.setState({
                                  [`lastMessage${selectedUser.id}`]: value,
                                });
                                this.onSendMessage(value, "text");
                              }
                            }}
                            onChange={(e) => {
                              this.setState({message: e.target.value});
                            }}
                          />
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Fragment>
            )}

            {!gettingAllgroups && chatGroups.length === 0 && (
              <Result
                status="404"
                title="You have no active chats"
                subTitle="You can intiate chat from here"
                extra={
                  <Button
                    onClick={() => this.setState({visible: true})}
                    className="btn btn-green btn-custom margin-right-12 width-135px height-40-imp"
                  >
                    Initiate Chat
                  </Button>
                }
              />
            )}

            <ModalPopUp
              title={"Initiate Chat"}
              handleCancel={this._hideModal}
              visible={visible}
              footer={true}
              handleOk={this._handleSubmit}
              okButtonProps={{
                disabled:
                  this.state.receiver_id === null ||
                  this.state.intiating ||
                  this.state.reason === "",
              }}
              //   okText={editId ? "Update" : "Save"}
              loading={this.state.intiating}
              cancelBtnClass="custom_cancel_btn"
              closable={false}
              maskClosable={false}
            >
              <form>
                <div className="form-group">
                  <Radio.Group
                    style={{
                      display: "flex",
                    }}
                    defaultValue="Patient"
                    buttonStyle="solid"
                    value={this.state.chatType}
                    onChange={this.handleRadio}
                  >
                    <Radio.Button value="Patient">For Patient</Radio.Button>
                    {!this.props.isChildCorporte && (
                      <Radio.Button value="Admin">For Admin</Radio.Button>
                    )}
                  </Radio.Group>
                </div>
                {this.state.chatType === "Patient" && (
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12" onCopy={disableCopy}>
                        <label for="sel1">Select Patient</label>
                        <Select
                          autoFocus
                          className={`form-control  margin-0 placeholder-10`}
                          name="receiver_id"
                          id="scrollableDiv"
                          onPopupScroll={this.handleScroll}
                          placeholder="Select patient"
                          allowClear={searching ? false : true}
                          value={this.state.receiver_id}
                          showSearch={true}
                          onChange={(value) =>
                            this._handleDropDownChange(value, "receiver_id")
                          }
                          onSearch={(value) => {
                            let searchValue = value;
                            this.searchUpdate(searchValue);
                          }}
                          listItemHeight={10}
                          listHeight={150}
                          suffixIcon={
                            !isLoadmore && searching && <Spin size="small" />
                          }
                          notFoundContent={
                            !searching &&
                            patients.length === 0 && (
                              <span>No patients available</span>
                            )
                          }
                          filterOption={false}
                          dropdownRender={(menus) => {
                            return (
                              <div ref={this.scrollDiv}>
                                {menus}
                                {isLoadmore && searching && (
                                  <div
                                    style={{
                                      textAlign: "center",
                                      padding: 10,
                                    }}
                                  >
                                    <Spin size="small" />
                                  </div>
                                )}
                              </div>
                            );
                          }}
                        >
                          {patients.map((k) => {
                            return (
                              <Option value={k.id}>
                                {verifyObject(k, "first_name", null) !== null
                                  ? `${k.first_name} ${k.last_name} (${k.email})`
                                  : verifyObject(k, "name", null) !== null
                                  ? `${k.name}  (${k.email})`
                                  : `-`}
                              </Option>
                            );
                          })}
                        </Select>
                      </div>
                    </div>
                  </div>
                )}
                {this.state.chatType === "Admin" && (
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <label for="sel1">GP Practice</label>
                        {this.state.corporateName}
                      </div>
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label for="sel1">Reason</label>
                      <Input.TextArea
                        autoSize={true}
                        type="text"
                        className={`form-control-textarea input-bg-f9f9f9 custom-input weight-400 no-focus`}
                        name="reason"
                        value={reason}
                        onChange={(e) => {
                          this.setState({reason: e.target.value});
                        }}
                        placeholder="Write reason here"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </ModalPopUp>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateProps = (state) => {
  return {
    componentName: verifyObject(
      state.localStore,
      "componentName",
      "UserProfile"
    ),
    user: verifyObject(state, "localStore.user", null),
    isChildCorporte: verifyObject(
      state.localStore,
      "user.add_child_corporate_cs",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {onRequestSetComponent, onSetSidebarKey},
      dispatch
    ),
    actionCableActions: bindActionCreators({chatMessagesChannel}, dispatch),

    // navigateTo: bindActionCreators({ navigateTo }, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(MessagesContainer);

export default withRouter(ConnectedComponent);

import React from 'react';

const IconThreeDotsVertical = (props) => {
    const { className } = props
    return (
        <svg className={className} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22" height="22">
            <path fillRule="evenodd" class="s0" d="m11 5.5c-0.8 0-1.6-0.3-2.1-0.8-0.6-0.5-0.9-1.2-0.9-1.9 0-0.8 0.3-1.5 0.9-2 0.5-0.5 1.3-0.8 2.1-0.8 0.8 0 1.6 0.3 2.1 0.8 0.6 0.5 0.9 1.2 0.9 1.9 0 0.8-0.3 1.5-0.9 2-0.5 0.5-1.3 0.8-2.1 0.8zm0 8.3c-0.8-0.1-1.6-0.3-2.1-0.9-0.6-0.5-0.9-1.2-0.9-1.9 0-0.7 0.3-1.4 0.9-1.9 0.5-0.6 1.3-0.9 2.1-0.9 0.8 0 1.6 0.3 2.1 0.9 0.6 0.5 0.9 1.2 0.9 1.9 0 0.7-0.3 1.4-0.9 1.9-0.5 0.6-1.3 0.8-2.1 0.8zm0 8.3c-0.8 0-1.6-0.3-2.1-0.8-0.6-0.5-0.9-1.2-0.9-2 0-0.7 0.3-1.4 0.9-1.9 0.5-0.5 1.3-0.8 2.1-0.8 0.8 0 1.6 0.3 2.1 0.8 0.6 0.5 0.9 1.2 0.9 1.9 0 0.8-0.3 1.5-0.9 2-0.5 0.5-1.3 0.8-2.1 0.8z"/>
        </svg>
    )
}

export { IconThreeDotsVertical }

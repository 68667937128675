/* eslint-disable array-callback-return */
import store from "app/store";
import {
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "constants/common";
import {
  getProfileApi,
  updateProfileApi,
  updateProfileImageApi,
  getCompanyBankDetailsApi,
  addCompanyDetailsApi,
  updateCompanyDetailsApi,
  addBankDetailsApi,
  updateBankDetailsApi,
} from "services/userProfile";

import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import {
  updateSignatureApi,
  uploadCertificatesApi,
} from "../services/userProfile";

const initialState = {
  isLoading: false,
  profile: {},
};

const types = {
  ON_REQUEST: "user.ON_REQUEST",
  ON_REQUEST_SUCCESS: "user.ON_REQUEST_SUCCESS",
  ON_REQUEST_FAILURE: "user.ON_REQUEST_FAILURE",
  GET_PROFILE: "user.GET_PROFILE",
  UPDATE_PROFILE: "user.UPDATE_PROFILE",
  ADD_COMPANY_DETAILS: "user.ADD_COMPANY_DETAILS",
  UPDATE_COMPANY_DETAILS: "user.UPDATE_COMPANY_DETAILS",
  ADD_BANK_DETAILS: "user.ADD_BANK_DETAILS",
  UPDATE_BANK_DETAILS: "user.UPDATE_BANK_DETAILS",
};

export const userProfile = (state = initialState, action) => {
  switch (action.type) {
    case types.ON_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.ON_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.ON_REQUEST_FAILURE:
      return {
        ...state,
        isLoading: false,
      };
    case types.GET_PROFILE:
      return {
        ...state,
        profile: {
          ...action.payload,
        },
      };
    case types.UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };
    case types.ADD_COMPANY_DETAILS:
      return {
        ...state,
        profile: {
          ...state.profile,
          company_details: action.payload,
        },
      };
    case types.UPDATE_COMPANY_DETAILS:
      return {
        ...state,
      };
    case types.ADD_BANK_DETAILS:
      return {
        ...state,
        profile: {
          ...state.profile,
          bank_details: action.payload,
        },
      };
    case types.UPDATE_BANK_DETAILS:
      return {
        ...state,
      };
    default:
      return state;
  }
};

const getProfile = (userId) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    const state = store.getState();
    const role = verifyObject(state, "localStore.user.role", null);
    console.log("store--", role);
    try {
      let response = await getProfileApi(userId);
      let updatedAddress = [{}, {}];

      let address =
        response.data.data &&
        response.data.data.profile &&
        response.data.data.profile.address &&
        response.data.data.profile.address !== null &&
        response.data.data.profile.address !== ""
          ? [...response.data.data.profile.address]
          : [];
      let foundUsualAddress = address.find((item) => {
        if (item.label === "primary" && item.name === "Usual Address") {
          return item;
        }
      });
      let foundPermanentAddress = address.find((item) => {
        if (item.label === "primary" && item.name === "Permanent Address") {
          return item;
        }
      });
      console.log("found ususla-->", foundUsualAddress);
      console.log("found permane-->", foundPermanentAddress);
      if (
        foundPermanentAddress
        // (address && address[0] && address[0].name === "Permanent Address") ||
        // (address && address[0] && address[0].name === "Primary Address")
      ) {
        updatedAddress[1]["name"] = "Permanent Address";
        updatedAddress[1]["line1"] = foundPermanentAddress.line1
          ? foundPermanentAddress.line1
          : "";
        updatedAddress[1]["line2"] = foundPermanentAddress.line2
          ? foundPermanentAddress.line2
          : "";
        updatedAddress[1]["line3"] = foundPermanentAddress.line3
          ? foundPermanentAddress.line3
          : "";
        updatedAddress[1]["town"] = foundPermanentAddress.town
          ? foundPermanentAddress.town
          : "";
        updatedAddress[1]["pincode"] = foundPermanentAddress.pincode
          ? foundPermanentAddress.pincode
          : "";
        updatedAddress[1]["country"] = foundPermanentAddress.country
          ? foundPermanentAddress.country
          : "";
        updatedAddress[1]["county"] = foundPermanentAddress.county
          ? foundPermanentAddress.county
          : "";

        updatedAddress[0]["name"] = "Usual Address";
      }
      if (
        foundUsualAddress
        // address && address[1] && address[1].name === "Usual Address"
      ) {
        updatedAddress[0]["name"] = "Usual Address";
        updatedAddress[0]["line1"] = foundUsualAddress.line1
          ? foundUsualAddress.line1
          : "";
        updatedAddress[0]["line2"] = foundUsualAddress.line2
          ? foundUsualAddress.line2
          : "";
        updatedAddress[0]["line3"] = foundUsualAddress.line3
          ? foundUsualAddress.line3
          : "";
        updatedAddress[0]["town"] = foundUsualAddress.town
          ? foundUsualAddress.town
          : "";
        updatedAddress[0]["pincode"] = foundUsualAddress.pincode
          ? foundUsualAddress.pincode
          : "";
        updatedAddress[0]["country"] = foundUsualAddress.country
          ? foundUsualAddress.country
          : "";
        updatedAddress[0]["county"] = foundUsualAddress.county
          ? foundUsualAddress.county
          : "";

        updatedAddress[1]["name"] = "Permanent Address";
      }
      if (
        !foundPermanentAddress &&
        !foundUsualAddress
        // address &&
        // address[0] &&
        // address[0].name !== "Permanent Address" &&
        // address &&
        // address[1] &&
        // address[1].name !== "Usual Address"
      ) {
        updatedAddress = [...address];
      }
      if (
        role === ROLE_CLINICAL_PHARMACIST ||
        role === ROLE_SUPERINTENDENT_PHARMACIST
      ) {
      }
      let companyBankDetailsResponse = await getCompanyBankDetailsApi({
        user_id: userId.id,
      });
      let {user_details, bank_details, company_details} =
        companyBankDetailsResponse.data.data;

      let updatedResponse = await {
        profile: JSON.parse(
          JSON.stringify({
            ...response.data.data.profile,
            address: [...updatedAddress],
          })
        ),
        specialities: JSON.parse(JSON.stringify(user_details.specialities)),
        tax_code: JSON.parse(JSON.stringify(user_details.tax_code)),
        bank_details: JSON.parse(JSON.stringify(bank_details)),
        company_details: JSON.parse(JSON.stringify(company_details)),
      };

      dispatch({type: types.GET_PROFILE, payload: updatedResponse});
      dispatch({type: types.ON_REQUEST_SUCCESS});
    } catch (error) {
      let err = getErrorObject(error);
      dispatch({type: types.ON_REQUEST_FAILURE});
      errorToast({content: err.message});
    }
  };
};

const updateProfile = (formData) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    try {
      let response = await updateProfileApi(formData);
      // dispatch({ type: types.UPDATE_PROFILE, payload: response.data.data.user })
      dispatch({type: types.ON_REQUEST_SUCCESS});
      console.log("response on update------>", response);
      successToast({content: response.data.message});
    } catch (error) {
      dispatch({type: types.ON_REQUEST_FAILURE});
      let {message} = getErrorObject(error);

      errorToast({content: message});
    }
  };
};

const updateProfilePic = (formData) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    try {
      let response = await updateProfileImageApi(formData);
      // dispatch({ type: types.UPDATE_PROFILE, payload: response.data.data.user })
      dispatch({type: types.ON_REQUEST_SUCCESS});
      successToast({content: response.data.message});
    } catch (error) {
      dispatch({type: types.ON_REQUEST_FAILURE});
      let message = getErrorObject(error);
      errorToast({content: message});
    }
  };
};

const updateSignature = (formData) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    try {
      let response = await updateSignatureApi(formData);
      // dispatch({ type: types.UPDATE_PROFILE, payload: response.data.data.user })
      dispatch({type: types.ON_REQUEST_SUCCESS});
      successToast({content: response.data.message});
    } catch (error) {
      dispatch({type: types.ON_REQUEST_FAILURE});
      let message = getErrorObject(error);
      console.log("error message", message);
      errorToast({content: message});
    }
  };
};

const uploadCertificates = (formData) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    try {
      let response = await uploadCertificatesApi(formData);
      // dispatch({ type: types.UPDATE_PROFILE, payload: response.data.data.user })
      console.log("response message", response);

      dispatch({type: types.ON_REQUEST_SUCCESS});
      successToast({content: response.data.message});
    } catch (error) {
      dispatch({type: types.ON_REQUEST_FAILURE});
      let message = getErrorObject(error);
      console.log("error message", message);

      errorToast({content: message});
    }
  };
};

const addBankDetails = (formData) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    try {
      let response = await addBankDetailsApi(formData);
      dispatch({type: types.ADD_BANK_DETAILS, payload: response});
      dispatch({type: types.ON_REQUEST_SUCCESS});
      successToast({content: response.data.message});
    } catch (error) {
      dispatch({type: types.ON_REQUEST_FAILURE});
      let message = getErrorObject(error);
      errorToast({content: message});
    }
  };
};

const updateBankDetails = (formData) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    try {
      let response = await updateBankDetailsApi(formData);
      dispatch({type: types.UPDATE_COMPANY_DETAILS, payload: response});
      dispatch({type: types.ON_REQUEST_SUCCESS});
      successToast({content: response.data.message});
    } catch (error) {
      dispatch({type: types.ON_REQUEST_FAILURE});
      let message = getErrorObject(error);
      errorToast({content: message});
    }
  };
};

const addCompanyDetails = (formData) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    try {
      let response = await addCompanyDetailsApi(formData);
      dispatch({type: types.ADD_COMPANY_DETAILS, payload: response});
      dispatch({type: types.ON_REQUEST_SUCCESS});
      successToast({content: response.data.message});
    } catch (error) {
      dispatch({type: types.ON_REQUEST_FAILURE});
      let message = getErrorObject(error);
      console.log("Error: ", error);
      errorToast({content: message});
    }
  };
};

const updateCompanyDetails = (formData) => {
  return async (dispatch) => {
    dispatch({type: types.ON_REQUEST});
    try {
      let response = await updateCompanyDetailsApi(formData);
      dispatch({type: types.UPDATE_COMPANY_DETAILS, payload: response});
      dispatch({type: types.ON_REQUEST_SUCCESS});
      successToast({content: response.data.message});
    } catch (error) {
      dispatch({type: types.ON_REQUEST_FAILURE});
      console.log("Error: ", error);

      let message = getErrorObject(error);
      errorToast({content: message});
    }
  };
};

export const actions = {
  getProfile,
  updateProfile,
  updateProfilePic,
  uploadCertificates,
  updateSignature,
  addBankDetails,
  updateBankDetails,
  addCompanyDetails,
  updateCompanyDetails,
};

import validator from "validator";
import isEmpty from "lodash/isEmpty";

export function ValidateInput(data) {
    let errors = {};
    if (validator.isEmpty(data.snomedCodeText) && data.snomedCodeId !== undefined && validator.isEmpty(data.snomedCodeId)) {
        errors.snomedCodeId = "Please select diseases name";
    }

    if (validator.isEmpty(data.snomedCodeId) && data.snomedCodeText !== undefined && validator.isEmpty(data.snomedCodeText)) {
        errors.snomedCodeId = "Please select diseases name";
    }

    if (!validator.isEmpty(data.snomedCodeText) && !validator.isEmpty(data.snomedCodeId)) {
        errors.snomedCodeId = "You cant select both";
    }
   
    return { errors, isValid: isEmpty(errors) };
}

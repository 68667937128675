import React, { Fragment, useState } from "react";
import { Table, Space, Spin, Timeline } from "antd";
import { verifyObject } from "utilities/utils";
import { Dropdown, Menu } from "antd";
import moment from "moment";
import { DATE_FORMAT } from "../../../constants/common";
import CommonLoader from "../../../components/common/CommonLoader";
import three_dot from "assets/images/common/ic-menu-dots.svg";
import { getUserMedicalHistoryApi } from "../../../services/patientDetail";
import { getErrorObject, errorToast } from "../../../utilities/utils";
import ModalPopUp from "../../../components/common/ModalPopUp";
import NoFoundMessage from "../../../components/common/NoFoundMessage";

export default function MedicalHistoryTable({
  dataSource,
  isLoading,
  _onRequestList,
  searching,
  _handleEdit,
  _handleDelete,
}) {
  let activeMedicalHistories1 = verifyObject(dataSource, ["true"], []);
  let pastMedicalHistories1 = verifyObject(dataSource, ["false"], []);
  let activeMedicalHistories = [];
  activeMedicalHistories1.map((d) =>
    activeMedicalHistories.push({ ...d, key: d.id })
  );
  let pastMedicalHistories = [];
  pastMedicalHistories1.map((d) =>
    pastMedicalHistories.push({ ...d, key: d.id })
  );
  let [visibleHistory, setVisible] = useState(false);
  let [histories, setHistories] = useState([]);
  let [historyLoading, setLoader] = useState(false);

  const _showModal = (record) => {
    setVisible(true);
    _handleHistoryModal(record);
  };
  const _handleHistoryModal = async (record) => {
    try {
      await setLoader(true);
      let response = await getUserMedicalHistoryApi({ id: record.id });
      let histories = verifyObject(
        response,
        "data.data.medical_history.history",
        []
      );
      await setHistories(histories);
      await setLoader(false);
    } catch (error) {
      const { message } = getErrorObject(error);
      setLoader(false);
      errorToast({ content: message });
    }
  };

  const _hideHistoryModal = () => {
    setVisible(false);
    setLoader(false);
    setHistories([]);
  };

  const renderActionMenus = (record) => {
    return (
      <Menu className="width-115px">
        <Menu.Item onClick={() => _showModal(record)}>History</Menu.Item>
        <Menu.Item disabled={searching} onClick={() => _handleEdit(record)}>
          {searching ? <Spin size="small" /> : "Edit"}
        </Menu.Item>
        <Menu.Item onClick={() => _handleDelete(record)}>Delete</Menu.Item>
      </Menu>
    );
  };

  const renderActionMenus2 = (record) => {
    return (
      <Menu className="width-115px">
        <Menu.Item onClick={() => _showModal(record)}>History</Menu.Item>
        {record.status === "unverified" && <Menu.Item>Verify</Menu.Item>}
      </Menu>
    );
  };

  const columns = [
    {
      title: "",
      key: "history_problem",
      dataIndex: "history_problem",
      className: "width-50",
      render: (history_problem, item) => (
        <Fragment>
          {verifyObject(history_problem, "description", null) !== null
            ? history_problem.description
            : "--"}
        </Fragment>
      ),
    },
    {
      title: "",
      key: "latest_reported_by",
      align: "right",
      dataIndex: "latest_reported_by",
      className: "width-35",
      render: (latest_reported_by, item) => {
        return (
          <Fragment>
            <p className="text-capitalize">
              <span className="text-normal">by</span> {latest_reported_by},
              &nbsp;
              {verifyObject(item, "latest_reported_on", null) !== null
                ? moment(item.latest_reported_on).format(DATE_FORMAT)
                : "--"}
            </p>
          </Fragment>
        );
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      className: "width-15",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            trigger={["click"]}
            overlay={() => renderActionMenus(record)}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {/* <EllipsisOutlined style={{ fontSize: "30px" }} /> */}
              <img alt="" src={three_dot} className="opacity-05"></img>
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];

  const columns2 = [
    {
      title: "",
      key: "history_problem",
      dataIndex: "history_problem",
      className: "width-50",
      render: (history_problem, item) => (
        <Fragment>
          {verifyObject(history_problem, "description", null) !== null
            ? history_problem.description
            : "--"}
        </Fragment>
      ),
    },
    {
      title: "",
      key: "latest_reported_by",
      align: "right",
      dataIndex: "latest_reported_by",
      className: "width-35",
      render: (latest_reported_by, item) => {
        return (
          <Fragment>
            <p className="text-capitalize">
              <span className="text-normal">by</span> {latest_reported_by},
              &nbsp;
              {verifyObject(item, "latest_reported_on", null) !== null
                ? moment(item.latest_reported_on).format(DATE_FORMAT)
                : "--"}
            </p>
          </Fragment>
        );
      },
    },
    {
      title: "",
      key: "action",
      align: "right",
      className: "width-15",
      render: (text, record) => (
        <Space size="middle">
          <Dropdown
            trigger={["click"]}
            overlay={() => renderActionMenus2(record)}
          >
            <a
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
            >
              {/* <EllipsisOutlined style={{ fontSize: "30px" }} /> */}
              <img alt="" src={three_dot} className="opacity-05"></img>
            </a>
          </Dropdown>
        </Space>
      ),
    },
  ];
  if (isLoading) {
    return <CommonLoader />;
  }
  return (
    <Fragment>
      <div className="medicalwrapper">
        <Table
          locale={{
            emptyText: (
              <NoFoundMessage message="No Active Medical Conditions" />
            ),
          }}
          pagination={false}
          loading={isLoading}
          columns={columns}
          dataSource={activeMedicalHistories}
          className="font-12 no_table_header border-spacing-5"
        />
        <h6 className="font-14 weight-500 text-light-black push-20-t">
          Past Medical Conditions
        </h6>
        <Table
          pagination={false}
          locale={{
            emptyText: <NoFoundMessage message="No Past Medical Conditions" />,
          }}
          loading={false}
          columns={columns2}
          dataSource={pastMedicalHistories}
          className="font-12 no_table_header border-spacing-5"
        />
      </div>
      <ModalPopUp
        title={"Medical History"}
        handleCancel={_hideHistoryModal}
        visible={visibleHistory}
        footer={false}
        className="width_850px"
        maskClosable={false}
      >
        {historyLoading && <CommonLoader />}
        {!historyLoading && (
          <Timeline>
            {histories.map((d) => {
              return (
                <Timeline.Item color="gray">
                  <div>
                    <p className="font-10 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                      {d.event} on {moment(d.reported_on).format(DATE_FORMAT)}
                    </p>
                    <div className="border-box col-md-12 input-bg-f9f9f9">
                      <div className="form-row">
                        <div className="col-md-6">
                          <p className="font-10 weight-400 text-dark-gray margin-0">
                            {d.snomed_code}
                          </p>
                        </div>
                        <div className="col-md-6 text-right">
                          <p className="font-10 weight-400 text-dark-gray margin-0">
                            {" "}
                            Reported By {d.reported_by} on{" "}
                            {moment(d.reported_on).format(DATE_FORMAT)}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </Timeline.Item>
              );
            })}
          </Timeline>
        )}
      </ModalPopUp>
    </Fragment>
  );
}

import React, { Fragment } from "react";
// import { Checkbox, Input, DatePicker } from "antd";
import { isArray } from "lodash";
// import moment from "moment";
// import { DATE_FORMAT } from "constants/common";
import SingleChoiceQuestion from "./QuestionsTypes/SingleChoiceQuestion";
import QuestionsChildOfChild from "./QuestionsChildOfChild";
import NumericalQuestion from "./QuestionsTypes/NumericalQuestion";
import DateRangeQuestion from "./QuestionsTypes/DateRangeQuestion";
import TextBoxQuestion from "./QuestionsTypes/TextBoxQuestion";
import DatePickerQuestion from "./QuestionsTypes/DatePickerQuestion";
import CheckAgreeQuestion from "./QuestionsTypes/CheckAgreeQuestion";
import CheckboxGroupQuestion from "./QuestionsTypes/CheckboxGroupQuestion";
import TextAreaQuestion from "./QuestionsTypes/TextAreaQuestion";
import FileInputQuestion from "./QuestionsTypes/FileInputQuestion";

export default function QuestionsChildren({
  childQuestions,
  handleAnswerChange,
  parentQuestion,
  gender,
}) {
  console.log("parentQues", parentQuestion);
  console.log("childQuestions", childQuestions);

  return (
    <div className="form-row child-questions ml-2">
      <div className="col-md-12">
        {/* {childQuestions?.is_conditional === false && (
          <div className="weight-400 font-14 margin-bottom-10">
            Q.{childQuestions.title}
            {!childQuestions.is_required && <b>(Optional)</b>}
          </div>
        )} */}
        {childQuestions.category === "text_area" && (
          <div>
            <TextAreaQuestion
              handleAnswerChange={handleAnswerChange}
              childQuestions={childQuestions}
              parentQuestion={parentQuestion}
              question={childQuestions}
              gender={gender}
            />
          </div>
        )}
        {childQuestions.category === "text" && (
          <div>
            <TextBoxQuestion
              handleAnswerChange={handleAnswerChange}
              childQuestions={childQuestions}
              childOfQuestions={null}
              parentQuestion={parentQuestion}
              question={childQuestions}
              gender={gender}
            />
          </div>
        )}
        {childQuestions.category === "check_agree" && (
          <Fragment>
            <CheckAgreeQuestion
              handleAnswerChange={handleAnswerChange}
              childQuestions={childQuestions}
              parentQuestion={parentQuestion}
              question={childQuestions}
              gender={gender}
            />
          </Fragment>
        )}

        {childQuestions.category === "single_choice" &&
          isArray(childQuestions.question_options) && (
            <SingleChoiceQuestion
              handleAnswerChange={handleAnswerChange}
              childQuestions={childQuestions}
              parentQuestion={parentQuestion}
              question={childQuestions}
              gender={gender}
            />
          )}

        {childQuestions.category === "multi_choice" &&
          childQuestions.question_options &&
          isArray(childQuestions.question_options) && (
            <Fragment>
              <CheckboxGroupQuestion
                handleAnswerChange={handleAnswerChange}
                childQuestions={childQuestions}
                parentQuestion={parentQuestion}
                question={childQuestions}
                gender={gender}
              />
            </Fragment>
          )}
       
        {childQuestions.category === "date" && (
          <DatePickerQuestion
            handleAnswerChange={handleAnswerChange}
            childQuestions={childQuestions}
            parentQuestion={parentQuestion}
            question={childQuestions}
            gender={gender}
          />
        )}
        {childQuestions.category === "date_range" && (
          <DateRangeQuestion
            handleAnswerChange={handleAnswerChange}
            childQuestions={childQuestions}
            parentQuestion={parentQuestion}
            question={childQuestions}
            gender={gender}
          />
        )}
        {childQuestions.category === "numerical" && (
          <NumericalQuestion
            handleAnswerChange={handleAnswerChange}
            childQuestions={childQuestions}
            parentQuestion={parentQuestion}
            question={childQuestions}
            gender={gender}
          />
        )}
         {childQuestions.category === "file" && (
          <FileInputQuestion
            handleAnswerChange={handleAnswerChange}
            childQuestions={childQuestions}
            parentQuestion={parentQuestion}
            question={childQuestions}
            gender={gender}
          />
        )}
      </div>
      {childQuestions?.children?.map((childOfQuestions, index) => {
        console.log("childOfQuestions", childOfQuestions.title);
        return (
          <QuestionsChildOfChild
            parentQuestion={parentQuestion}
            childQuestions={childQuestions}
            childOfQuestions={childOfQuestions}
            handleAnswerChange={handleAnswerChange}
            gender={gender}
          />
        );
      })}
    </div>
  );
}

import isEmpty from "lodash/isEmpty";

export function ValidateInput(data) {
  let errors = {};

  if (isEmpty(data.result)) {
    errors.result = "Please choose result value.";
  }

//   if (
//     !isEmpty(data.result) &&
//     data.result !== "comment" &&
//     isEmpty(data.covid_email_template_id)
//   ) {
//     errors.covid_email_template_id = "Please select email templates.";
//   }

  if (
    !isEmpty(data.result) &&
    data.result === "comment" &&
    isEmpty(data.comment)
  ) {
    errors.comment = "Please enter comment.";
  }

  return { errors, isValid: isEmpty(errors) };
}

import React, {Fragment} from "react";
import logo from "assets/images/header/logo.jpg";
import imgCallBig from "assets/images/header/call_big.svg";
import {DashboardLink} from "app/navigation";
const Header = () => {
  return (
    <Fragment>
      <header className="header-main container-fluid d-flex flex-column flex-md-row align-items-center bg-white sticky-top">
        <div className="col-md-2 text-center padding-left-0 my-0 mr-md-auto">
          <h5 className="my-0 mr-md-auto">
            <DashboardLink title="healthya">
              <img src={logo} alt="logo" className="Healthyalogo" />
            </DashboardLink>
          </h5>
        </div>
        <div className="right-contact-detail">
          <img
            src={imgCallBig}
            alt="call"
            className="img-responsive center-block in-block margin-right-15 float-left margin-top-8"
          />
          <div className="in-block">
            <p className="font-14 weight-700 text-dark-blue push-5-b">
              Customer Support?
            </p>
            <p className="font-12 weight-400 text-dark-gray">
              Call on +0208 0166 020
            </p>
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default Header;

import React from "react";

export default function SafariSetup() {
  return (
    <div className="browser_setup safari_setup">
      <div className="margin-top-80 pt-45">
        <h3 className="font-16 weight-500 text-default-color margin-bottom-15">
          Allow AllDayDr to access your camera and microphone
        </h3>
        <ol className="m-0 font-14 weight-400 text-gray">
          <li className="push-10-b">
            Choose{" "}
            <span className="weight-500 text-default-color ">
              “Preferences”
            </span>{" "}
            from the safari menu. This will bring up your preferences pop-up.
          </li>
          <li className="push-10-b">
            {" "}
            Click{" "}
            <span className="weight-500 text-default-color ">
              “Websites”
            </span>{" "}
            at the top of the pop-up.
          </li>
          <li className="push-10-b">
            On the left sidebar, click{" "}
            <span className="weight-500 text-default-color ">
              “Microphone”.
            </span>
          </li>
          <li className="push-10-b">
            Click the drop-down list next https://doctor.alldaydr.com and choose{" "}
            <span className="weight-500 text-default-color ">“Allow”.</span>
          </li>
        </ol>
        <div className="setup-img-container mt-40">
          <img
            alt=""
            src={require("../../assets/images/video_call_setup/safari.jpg").default}
          />
        </div>
      </div>
    </div>
  );
}
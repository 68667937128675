import React, { Fragment } from "react";
// import {
//  Checkbox, Input, Radio,
// DatePicker,
// } from "antd";
import { isArray } from "lodash";
// import moment from "moment";
// import { DATE_FORMAT } from "constants/common";
import QuestionsChildren from "./QuestionsChildren";
import TextBoxQuestion from "./QuestionsTypes/TextBoxQuestion";
import SingleChoiceQuestion from "./QuestionsTypes/SingleChoiceQuestion";
import CheckboxGroupQuestion from "./QuestionsTypes/CheckboxGroupQuestion";
import FileInputQuestion from "./QuestionsTypes/FileInputQuestion";
import DatePickerQuestion from "./QuestionsTypes/DatePickerQuestion";
import NumericalQuestion from "./QuestionsTypes/NumericalQuestion";
import CheckAgreeQuestion from "./QuestionsTypes/CheckAgreeQuestion";
import TextAreaQuestion from "./QuestionsTypes/TextAreaQuestion";
import DateRangeQuestion from "./QuestionsTypes/DateRangeQuestion";
// import QuestionsAdditionalInformation from "./QuestionsAdditionalInformation";
// const { RangePicker } = DatePicker;

export default function QuestionnaireFillUpItem({
  questions,
  handleAnswerChange,
  gender,
}) {
  if (questions) {
    // console.log("questoins ---->", questions);

    return (
      <div
        id={`scroll_to_${questions?.id}`}
        className="questionnaire_answer_wrapper"
        key={questions.id}
      >
          {/* <div className="weight-400 font-14 margin-bottom-10">
            Q.{questions.title}
            {!questions.is_required && ""}
          </div> */}
          {/* //? Textbox Questions */}
          {questions.category === "text" && (
            <div>
              <TextBoxQuestion
                handleAnswerChange={handleAnswerChange}
                question={questions}
                parentQuestion={questions}
                childOfQuestions={null}
                childQuestions={null}
                gender={gender}
              />
            </div>
          )}
          {questions.category === "text_area" && (
            <div>
              <TextAreaQuestion
                handleAnswerChange={handleAnswerChange}
                question={questions}
                parentQuestion={questions}
                gender={gender}
              />
            </div>
          )}
          {questions.category === "check_agree" && (
            <Fragment>
              {/* Render Checkbox component */}
              <CheckAgreeQuestion
                handleAnswerChange={handleAnswerChange}
                question={questions}
                parentQuestion={questions}
                gender={gender}
              />
            </Fragment>
          )}
          {questions.category === "single_choice" &&
            questions.question_options &&
            isArray(questions.question_options) && (
              <SingleChoiceQuestion
                handleAnswerChange={handleAnswerChange}
                question={questions}
                parentQuestion={questions}
                gender={gender}
              />
            )}

          {questions.category === "multi_choice" &&
            questions.question_options &&
            isArray(questions.question_options) && (
              <Fragment>
                {/* Render CheckboxGroupQuestion component */}
                <CheckboxGroupQuestion
                  handleAnswerChange={handleAnswerChange}
                  question={questions}
                  parentQuestion={questions}
                  gender={gender}
                />
              </Fragment>
            )}
          {questions.category === "file" && (
            <div>
              {/* Render FileInputQuestion component */}
              <FileInputQuestion
                handleAnswerChange={handleAnswerChange}
                question={questions}
                parentQuestion={questions}
                gender={gender}
              />
            </div>
          )}
          {questions.category === "date" && (
            <div>
              {/* Render DatePickerQuestion component */}
              <DatePickerQuestion
                handleAnswerChange={handleAnswerChange}
                question={questions}
                parentQuestion={questions}
                gender={gender}
              />
            </div>
          )}
          {questions.category === "date_range" && (
            <div>
              {/* Render DatePickerQuestion component */}
              <DateRangeQuestion
                handleAnswerChange={handleAnswerChange}
                question={questions}
                parentQuestion={questions}
                gender={gender}
              />
            </div>
          )}
          {questions.category === "numerical" && (
            <div>
              {/* Render NumericalQuestion component */}
              <NumericalQuestion
                handleAnswerChange={handleAnswerChange}
                question={questions}
                parentQuestion={questions}
                gender={gender}
              />
            </div>
          )}

          {/* Render Children Questions  */}
          {questions?.children?.map((childQuestions, index) => {
            return (
              <QuestionsChildren
                parentQuestion={questions}
                childQuestions={childQuestions}
                handleAnswerChange={handleAnswerChange}
                gender={gender}
              />
            );
          })}
      </div>
    );
  }
  return null;
}

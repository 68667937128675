import React from "react";
import { verifyObject } from "utilities/utils";
import PassSVG from "./assets/ic_done.svg";
import ErrorSVG from "./assets/ic_error.svg";
import WarningSVG from "./assets/ic_warning.svg";
export default function PreCallTest({
  videoQualityClass,
  loadingResults,
  audioQualityClass,
  showError,
  showSuccess,
  showWarnig,
  continuteToVideoCallScreen,
  qualityResults,
  videoQualityStatus,
  audioQualityStatus,
  errorMessage,
}) {
  let audioData = verifyObject(qualityResults, "audio", null);
  let videoData = verifyObject(qualityResults, "video", null);
  console.log("VIDEO DATA", videoData);

  return (
    <div>
      {/* <div id="connectivity_status_container" className="prettyBox">
        <h2 className="weight-500">Pre Call Test</h2>
        {loadingResults && (
          <p className="text-light-black weight-400">
            Checking your audio/video quality...
          </p>
        )}
        <p className="text-light-black weight-400 to-be-hide"></p>
        {loadingResults && (
          <img
            alt=""
            src={require("../../assets/images/common/loading.svg").default}
          />
        )}
        {showError && <img alt="" src={ErrorSVG} />}
        {showSuccess && <img alt="" src={PassSVG} />}
        {showWarnig && <img alt="" src={WarningSVG} />}
      </div> */}
      <div id="quality_status_container" className="prettyBox">
        <div id="quality_header" className="push-10-b">
          <h2 className="weight-500">Pre Call Test</h2>
          {loadingResults && (
            <p className="text-light-black weight-400">
              Checking your audio/video quality...
            </p>
          )}
          {errorMessage && (
            <p className="text-light-black weight-400">{errorMessage}</p>
          )}
          <p className="text-light-black weight-400 to-be-hide"></p>
          {loadingResults && (
            <img
              alt=""
              src={require("../../assets/images/common/loading.svg").default}
            />
          )}
          {showError && <img alt="" src={ErrorSVG} />}
          {showSuccess && <img alt="" src={PassSVG} />}
          {showWarnig && <img alt="" src={WarningSVG} />}
        </div>
        {qualityResults && (
          <div className="test_wrapper">
            <div id="audio">
              <h3 className="weight-500 font-16">Audio</h3>
              <div>
                <div className="results">
                  <div className="">
                    {audioData && (
                      <p>
                        Supported:
                        <span id="audio-supported">
                          {" "}
                          {audioData && audioData.supported ? "Yes" : "No"}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="">
                    {audioData && (
                      <p className={`quality ${audioQualityClass}`}>
                        Quality:
                        <span id="audio-mos">
                          {" "}
                          {audioData.mos.toFixed(2)}
                          {` (${audioQualityStatus})`}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="">
                    {audioData && (
                      <p>
                        Bitrate:
                        <span id="audio-bitrate">
                          {" "}
                          {(audioData.bitrate / 1000).toFixed(2) + " kbps"}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="">
                    {audioData && (
                      <p>
                        Packet loss ratio:
                        <span id="audio-plr">
                          {" "}
                          {audioData.packetLossRatio
                            ? (audioData.packetLossRatio * 100).toFixed(2) + "%"
                            : "0.00%"}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div id="video">
              <h3 className="weight-500 font-16">Video</h3>
              <div>
                <div className="results">
                  <div className="">
                    {videoData && (
                      <p>
                        Supported:
                        <span id="audio-supported">
                          {" "}
                          {videoData && videoData.supported ? "Yes" : "No"}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="">
                    {videoData && (
                      <p className={`quality ${videoQualityClass}`}>
                        Quality:
                        <span id="audio-mos">
                          {" "}
                          {videoData.mos.toFixed(2)}
                          {` (${videoQualityStatus})`}
                        </span>
                      </p>
                    )}
                  </div>
                  {/* <div className="">
                    <p id="video-unsupported-reason">
                      Reason:
                      <span></span>
                    </p>
                  </div> */}

                  <div className="">
                    {videoData && (
                      <p className="">
                        Bitrate:
                        <span id="video-bitrate">
                          {" "}
                          {(videoData.bitrate / 1000).toFixed(2) + " kbps"}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="">
                    {videoData && (
                      <p className="">
                        Packet loss ratio:
                        <span id="audio-plr">
                          {" "}
                          {videoData.packetLossRatio
                            ? (videoData.packetLossRatio * 100).toFixed(2) + "%"
                            : "0.00%"}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="">
                    {videoData && (
                      <p className="">
                        Recommended resolution:
                        <span id="video-recommendedResolution">
                          {" "}
                          {videoData.recommendedResolution}
                        </span>
                      </p>
                    )}
                  </div>
                  <div className="">
                    {videoData && (
                      <p className="">
                        Recommended frame rate:
                        <span id="video-recommendedFrameRate">
                          {" "}
                          {videoData.recommendedFrameRate + " fps"}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div id="graphContainer" className="justify-content-center">
        <div id="audioGraph" className="graph"></div>
        <div id="videoGraph" className="graph"></div>
      </div>

      {qualityResults && (
        <div className="text-center push-20-t">
          <button
            className="btn btn-custom btn-blue width-255 font-14 height-big"
            onClick={continuteToVideoCallScreen}
            disabled={qualityResults === null}
          >
            Go To Appointments
          </button>
        </div>
      )}
    </div>
  );
}

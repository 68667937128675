import React, {PureComponent, Fragment} from "react";
import {Input, Button, Spin} from "antd";
import {debounce, isArray, isEmpty} from "lodash";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actions as patientActions, onPatientSelect} from "reducers/patients";
import moment from "moment";

import {
  disableCopy,
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../utilities/utils";
// import {Pagination} from "../../components/common";
import "assets/css/pages/patient-search.css";
import PatientAddForm from "../../components/patientAddForm/PatientAddForm";
import {appRoutesConst, navigateTo} from "../../app/navigation";
import store from "../../app/store";
import {onSetPatient, onSetPatientAppointment} from "reducers/patients";
import search_blue from "assets/images/common/search-blue.svg";
import BookAppointments from "../../components/BookAppointments/BookAppointments";
import {
  bookAppointmentFromPatientAPI,
  startAppointment,
} from "services/appointments";
import PatientList from "./PatientList";
import {DATE_FORMAT} from "constants/common";
import {getTimeSlotsForCorp, getTimeSlotsForDoctor} from "services/rota";
import TagsInput from "react-tagsinput";
import "../../assets/css/components/react-tagsinput.css";

// import CommonLoader from "components/common/CommonLoader";

class PatientSearchContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchedPatients: [],
      paginationData: null,
      search: [],
      patients: null,
      show: false,
      isShowBookAppointment: false,
      patient_id: null,
      isLoading: false,
      selectedSlotTime: null,
      selecetdDate: null,
      selectedAptType: "video",
      appointment_tag: null,
      doctor_id: props.doctor_id,
      booking_reason: [],
      inputValue: "",
    };
    ["_handleSearch", "_onPageChange", "_showModal"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.debouncedCreateTag = debounce(this.createTag, 1000);
  }

  // Method to handle new tag creation with a delay
  createTag = (newTags) => {
    this.setState(
      (prevState) => ({
        search: [...prevState.search, newTags].filter((item) => item !== ""),
        inputValue: "",
      }),
      () => {
        this.searchUpdate(this.state.search);
      }
    );
  };
  async _handleSearch(value) {
    if (!value || (Array.isArray(value) && value.length === 0)) {
      // No search to perform if value is empty or all tags are removed
      this.setState({
        searchedPatients: [],
        paginationData: null,
        ids: [],
        isLoading: false,
      });
      return;
    }

    // Continue with your search logic
    let {onSearch} = this.props.patientActions;
    let {error} = this.props.patients;

    if (typeof onSearch === "function") {
      this.setState({
        search: value,
        searchedPatients: [],
      });

      store.dispatch({
        type: "SET_PATIENT_DETAIL",
        payload: null,
      });
      // debugger;
      await onSearch(
        {
          search: Array.isArray(value)
            ? value.filter((item) => item !== "").join(",")
            : value,
        },
        true
      );

      let data = verifyObject(this.props.patients, "data", null);
      let paginationData = verifyObject(
        this.props,
        "patients.pagination",
        null
      );
      let ids = verifyObject(this.props, "patients.ids", []);
      let isLoading = verifyObject(this.props, "patients.isLoading", false);

      if (data !== null) {
        let finalData = Object.keys(data).map((key) => data[key]);

        if (finalData && Array.isArray(finalData)) {
          this.setState({
            searchedPatients: finalData,
            paginationData: paginationData ? paginationData : null,
            ids,
            isLoading,
          });
        }
      }
    }
  }

  // componentDidUpdate(){

  // }
  componentDidUpdate(prevProps) {
    if (this.props.patients !== prevProps.patients) {
      const data = verifyObject(this.props.patients, "data", null);
      const error = verifyObject(this.props.patients, "error", null);

      let paginationData = verifyObject(
        this.props,
        "patients.pagination",
        null
      );

      if (error !== "REQ_CANCELLED") {
        errorToast({content: error});
      } else {
        this.setState({isLoading: false});
        setTimeout(() => {
          this.setState({isLoading: true});
        }, [1000]);
      }
      let ids = verifyObject(this.props, "patients.ids", []);
      let isLoading = verifyObject(this.props, "patients.isLoading", false);

      if (data !== null) {
        const finalData = Object.keys(data).map((key) => data[key]);

        if (finalData && Array.isArray(finalData)) {
          this.setState({
            searchedPatients: finalData,
            paginationData,
            ids,
            isLoading,
          });
        }
      } else {
        this.setState({
          searchedPatients: [],
          paginationData: null,
          ids: [],
          isLoading: false,
        });
      }
    }
  }
  _onPageChange = (data) => {
    let {
      patientActions: {onPageChange},
    } = this.props;
    const {page, data_count} = data;
    let pageData = {};
    let dataCountData = {};
    if (page) {
      pageData = {
        page: page,
      };
    }
    if (data_count) {
      dataCountData = {
        data_count: data_count,
      };
    }
    if (typeof onPageChange === "function") {
      onPageChange(
        {
          page,
          search: this.state.search,
          ...pageData,
          ...dataCountData,
        },
        true
      ); //!NOTE: passig true for search patient API serialize function to prevent default response
    }
  };

  _showModal() {
    this.setState({show: true});
  }

  _openAppointment = (item) => {
    this.setState({isShowBookAppointment: true, patient_id: item.id});
  };

  _handleAppointmentNavigation(data, fromPresciber) {
    let {onSetPatient} = this.props.onSetPatient;
    let {onSetPatientAppointment} = this.props.onSetPatientAppointment;
    let {navigateTo} = this.props.navigateTo;
    let {patient_id} = data;

    if (typeof onSetPatient === "function") {
      onSetPatient({id: patient_id});
    }

    if (typeof onSetPatientAppointment === "function") {
      onSetPatientAppointment(data);
    }

    if (typeof navigateTo === "function") {
      navigateTo(appRoutesConst.walkinAppointment);
    }
  }

  _startAppointment = async (item) => {
    try {
      await this.setState({isLoading: true});
      let response = await startAppointment({
        patient_id: item.id,
        start_time: moment().format(),
      });
      // await this.setState({
      //   referralNote: verifyObject(response, "data.data.referral_note", null),
      //   isLoading: false,
      // });
      this._handleAppointmentNavigation(response.data.data);
      await this.setState({isLoading: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  };

  _handleNavigation(id, fromSearchListing) {
    // if (fromPrescriber) {
    // } else {
    if (fromSearchListing) {
      store.dispatch({type: "IS_CLICKED_VIEW", payload: true});
    }
    let {onPatientSelect} = this.props.onPatientSelect;
    if (typeof onPatientSelect === "function") {
      onPatientSelect({id});
      store.dispatch({type: "ON_CLICK_NAME_UPCOMING", payload: false});
    }
    // }
  }

  clearSearchedPatients = () => {
    this.setState({searchedPatients: [], search: [], patients: null});
  };

  disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < moment().endOf("day").subtract("day", 1);
  };

  handleCloseModal = () => {
    this.setState({
      isShowBookAppointment: false,
      selecetdDate: null,
      selectedAptType: "video",
      selectedSlotTime: null,
      doctor_id: this.props.doctor_id,
      appointment_tag: null,
    });
    if (this.props.closeModal && typeof this.props.closeModal === "function") {
      this.props.closeModal();
    }
  };

  handleDatepicker = async (date, dateString) => {
    // let isoDate = new Date(date).toISOString();
    let errors = null;
    errors = Object.assign("", this.state.errors);
    delete errors["date"];
    if (date) {
      this.setState(
        {
          date: moment.utc(dateString),
          errors: errors,
          selecetdDate: date,
        },
        () => {
          this.onGetTimeSlots();
        }
      );
    } else {
      this.setState({date: null, selecetdDate: null});
    }
  };

  onGetTimeSlots = async () => {
    const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
    let {corporate_id} = this.props;
    let {doctor_id} = this.state;
    try {
      await this.setState({
        loadingSlots: true,
        timeSlots: [],
        slotDuration: [],
      });
      let docIdParams = {
        doctor_id: doctor_id,
      };
      if (this.state.doctor_id === "all") {
        docIdParams = {};
      }
      let patientIdParams = {};

      if (this.state.patient_id) {
        patientIdParams = {
          patient_id: this.state.patient_id,
        };
      }
      let response =
        this.state.doctor_id === "all"
          ? await getTimeSlotsForCorp({
              // date: this.state.selecetdDate,
              // doctor_id: user.id,
              // zone: time_zone,
              // ...docIdParams,
              session_type:
                this.state.selectedAptType === "video"
                  ? "online_appointment"
                  : "offline_appointment",
              date: moment(this.state.selecetdDate).format(DATE_FORMAT),
              corporate_id: corporate_id,
              ...patientIdParams,
              // session_type: this.state.selectedAptType,
            })
          : await getTimeSlotsForDoctor({
              date: this.state.selecetdDate,
              // doctor_id: user.id,
              zone: time_zone,
              ...docIdParams,
              slot_for:
                this.state.selectedAptType === "video"
                  ? "online_appointment"
                  : "offline_appointment",
              ...patientIdParams,

              // date: moment(this.state.selecetdDate).format(DATE_FORMAT),
              // corporate_id: user?.corporate_id ,
              // session_type: this.state.selectedAptType,
            });
      console.log("Slot Response", response);
      if (response.data.common && response.data.common.slot_durations) {
        let slotDuration = response.data.common.slot_durations.split(",");
        console.log("slotDuration", slotDuration);
        this.setState({
          slotDuration: slotDuration,
        });
      }
      if (response.data.data) {
        const transformedArray = verifyObject(response, "data.data", []).map(
          (item) => {
            const label = moment(item.start_time).format("hh:mm A");
            const value = [item.start_time, item.end_time];

            return {label, value};
          }
        );
        this.setState({timeSlots: transformedArray});
      }
      this.setState({loadingSlots: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({loadingSlots: false});
      errorToast({content: message});
    }
  };

  handleAppointmentSubmission = async () => {
    const {selectedSlotTime, selectedAptType, patient_id} = this.state;
    const {corporate_id} = this.props;
    let {doctor_id, booking_reason} = this.state;

    let formData = new FormData();
    formData.set(`appointment[start_time]`, selectedSlotTime[0]);
    formData.set(`appointment[end_time]`, selectedSlotTime[1]);
    formData.set(`appointment[appointment_type]`, selectedAptType);
    formData.set(`appointment[appointment_tag]`, this.state.appointment_tag);
    booking_reason &&
      booking_reason.length > 0 &&
      formData.set(
        `appointment[comments]`,
        isArray(booking_reason) &&
          booking_reason.map((item) => item.label).join(",")
      );
    formData.set(`duration`, 15);
    formData.set(`corporate_id`, corporate_id);
    formData.set(`book_via`, selectedAptType === "video" ? "ad_hoc" : "slot");
    formData.set(`book_from_dp`, true);
    formData.set(`patient_id`, patient_id);
    if (doctor_id !== "all") {
      formData.set(`doctor_id`, doctor_id);
    }
    try {
      await this.setState({adding: true});
      let response = await bookAppointmentFromPatientAPI(formData);
      if (response.status === 200) {
        if (response.data.message) {
          await this.setState({adding: false});
          successToast({content: response.data.message});

          this.handleCloseModal();
        }
      }
      await this.setState({adding: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({adding: false});
      errorToast({content: message});
    }
    // let formData = new FormData();

    // selectedSlotTime &&
    //   formData.set(`appointment[start_time]`, selectedSlotTime[0]);
    // selectedSlotTime &&
    //   formData.set(`appointment[end_time]`, selectedSlotTime[1]);
    // pre_consult_id &&
    //   formData.set(`appointment[pre_consult_id]`, pre_consult_id);

    // formData.set(`appointment[appointment_type]`, "video");
  };

  handleAptTypeChange = ({target: {value}}) => {
    this.setState(
      {
        selectedAptType: value,
      },
      () => {
        if (this.state.selecetdDate) {
          this.onGetTimeSlots();
        }
      }
    );
  };

  handleSelectChange = (value, key) => {
    this.setState({[key]: value}, () => {
      if (
        this.state.doctor_id &&
        this.state.selecetdDate &&
        this.state.selectedAptType
      ) {
        this.onGetTimeSlots();
      }
    });
  };

  handleSelectChange1 = (value, key) => {
    this.setState({[key]: value}, () => {
      // if (
      //   this.state.doctor_id &&
      //   this.state.selecetdDate &&
      //   this.state.selectedAptType
      // ) {
      //   this.onGetTimeSlots();
      // }
    });
  };

  onChangeSlots = ({target: {value}}) => {
    console.log(
      "radio3 checked",
      value,
      moment(value).add(15, "minutes").format()
    );
    // setSlotTime(value);
    this.setState({
      selectedSlotTime: value,
    });
  };

  render() {
    let {searchedPatients, show, paginationData, ids, isLoading, search} =
      this.state;

    // let {isLoading} = this.props.patients;
    return (
      <Fragment>
        <div className="rota-wrapper patient-search-container">
          <div className="rota-wrapper-row">
            {/* <div className="rota-wrapper-col width-25"></div> */}
            <div className="rota-wrapper-col width-100 padding-bottom-50 padding-left-right-20px">
              <div className="patient-searchbar searchdropdowninputmain">
                <div className="relative searchbar_dropdown w-100">
                  <TagsInput
                    value={this.state.search}
                    inputValue={this.state.inputValue || ""}
                    onChangeInput={(e) => {
                      this.setState({inputValue: e}, () => {
                        if (e !== "" && e.trim() !== "") {
                          this.debouncedCreateTag(e.trim());
                        }
                      });
                    }}
                    onChange={(e) => {
                      if (e && isArray(e) && e.length > 0) {
                        this.searchUpdate(e);
                        this.setState({
                          search: e.filter((item) => item !== ""),
                        });
                      } else if (e && e.length === 0) {
                        // this.searchUpdate("");
                        this.setState((prevState) => {
                          this.searchUpdate(null);

                          return {
                            ...prevState,
                            searchedPatients: [],
                            patients: null,
                            search: [],
                          };
                        });
                      }
                    }}
                    inputProps={{
                      placeholder:
                        "Search Patient (Name, Email, Phone, DOB (dd/mm/yyyy)), NHS number, Postcode)",
                    }}
                  />
                  {/* <Input
                    onCopy={disableCopy}
                    onChange={(e) => {
                      let value = e.target.value;
                      if (!isEmpty(value)) {
                        this.searchUpdate(value);
                        this.setState({search: value});
                      } else {
                        // this.searchUpdate("");
                        this.setState({
                          searchedPatients: [],
                          patients: null,
                          search: "",
                        });
                      }
                    }}
                    id="searchInput"
                    size="large"
                    value={this.state.search}
                    placeholder="Search Patient (id, phone, email, name ,DOB (dd/mm/yyyy)), NHS number"
                    allowClear={true}
                    autoFocus
                  /> */}
                  {/* <img alt="" src={search_blue}></img> */}
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <g
                      fill="none"
                      fill-rule="evenodd"
                      transform="rotate(-45 13.121 4.964)"
                    >
                      <circle cx="7" cy="7" r="6.5" stroke="#1445b7" />
                      <path fill="#1445b7" d="M7 13h1v5.5a.5.5 0 1 1-1 0V13z" />
                    </g>
                  </svg> */}
                </div>
                <Button
                  onClick={this._showModal}
                  className="large-btn"
                  type="primary"
                >
                  {" "}
                  Add Patient
                </Button>
              </div>
              <div className="patient-results">
                {/* {isLoading && <CommonLoader />} */}
                <Spin spinning={isLoading} size="large">
                  {searchedPatients && searchedPatients.length > 0 && (
                    <PatientList
                      onPatientPageChange={this._onPageChange}
                      ids={ids}
                      isLoading={isLoading}
                      patients={searchedPatients}
                      paginationData={paginationData}
                      _startAppointment={this._startAppointment}
                      _openAppointment={this._openAppointment}
                      userRole={this.props.userRole}
                      handleView={async (item, fromSearchListing) => {
                        this._handleNavigation(item.id, fromSearchListing);
                        await localStorage.setItem(
                          "script_review_obj",
                          JSON.stringify(item.patient_profile)
                        );
                      }}
                      clearSearchedPatients={this.clearSearchedPatients}
                    />
                  )}
                </Spin>

                {this.state.isShowBookAppointment && (
                  <BookAppointments
                    visible={this.state.isShowBookAppointment}
                    doctor_id={this.state.doctor_id}
                    disabledDate={this.disabledDate}
                    handleCloseModal={this.handleCloseModal}
                    handleDatepicker={this.handleDatepicker}
                    selecetdDate={this.state.selecetdDate}
                    handleSubmission={this.handleAppointmentSubmission}
                    isFetchingSlot={this.state.loadingSlots}
                    slotsArray={this.state.timeSlots}
                    onChangeSlots={this.onChangeSlots}
                    selectedSlotTime={this.state.selectedSlotTime}
                    patient_id={this.state?.patient_id}
                    bookingAppointment={this.state.adding}
                    handleAptTypeChange={this.handleAptTypeChange}
                    selectedAptType={this.state.selectedAptType}
                    handleSelectChange={this.handleSelectChange}
                    handleSelectChange1={this.handleSelectChange1}
                    appointment_tag={this.state.appointment_tag}
                    // onFetchPatientList={() => {
                    //   this.setState({isShowAdd: false}, () => {
                    //     this.onRequestList();
                    //   });
                    // }}
                    // handleCloseModal={() =>
                    //   this.setState({
                    //     isShowBookAppointment:
                    //       !this.state.isShowBookAppointment,
                    //   })
                    // }p@

                    // patient_id={this.state.patient_id}
                  />
                )}

                {this.state.isShowBookAppointment && (
                  <BookAppointments
                    visible={this.state.isShowBookAppointment}
                    doctor_id={this.state.doctor_id}
                    doctor_name={
                      verifyObject(this.props, "user.first_name") || ""
                    }
                    doctor_role={verifyObject(this.props, "user.role") || ""}
                    disabledDate={this.disabledDate}
                    handleCloseModal={this.handleCloseModal}
                    handleDatepicker={this.handleDatepicker}
                    selecetdDate={this.state.selecetdDate}
                    handleSubmission={this.handleAppointmentSubmission}
                    isFetchingSlot={this.state.loadingSlots}
                    slotsArray={this.state.timeSlots}
                    onChangeSlots={this.onChangeSlots}
                    selectedSlotTime={this.state.selectedSlotTime}
                    patient_id={this.state?.patient_id}
                    bookingAppointment={this.state.adding}
                    handleAptTypeChange={this.handleAptTypeChange}
                    selectedAptType={this.state.selectedAptType}
                    handleSelectChange={this.handleSelectChange}
                    handleSelectChange1={this.handleSelectChange1}
                    appointment_tag={this.state.appointment_tag}
                    // onFetchPatientList={() => {
                    //   this.setState({isShowAdd: false}, () => {
                    //     this.onRequestList();
                    //   });
                    // }}
                    // handleCloseModal={() =>
                    //   this.setState({
                    //     isShowBookAppointment:
                    //       !this.state.isShowBookAppointment,
                    //   })
                    // }p@

                    // patient_id={this.state.patient_id}
                  />
                )}

                {show && (
                  <PatientAddForm
                    handleCancel={() => this.setState({show: false})}
                    show={show}
                    userRole={this.props.userRole}
                    _startAppointment={this._startAppointment}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patients: verifyObject(state, "patients", []),
    userRole: verifyObject(state, "localStore.user.role", null),
    doctor_id: verifyObject(state, "localStore.user.id", null),
    user: verifyObject(state, "localStore.user", null),
    corporate_id: verifyObject(state, "localStore.user.corporate_id", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    patientActions: bindActionCreators(patientActions, dispatch),
    navigationAction: bindActionCreators({navigateTo}, dispatch),
    onPatientSelect: bindActionCreators({onPatientSelect}, dispatch),
    onSetPatientAppointment: bindActionCreators(
      {onSetPatientAppointment},
      dispatch
    ),
    onSetPatient: bindActionCreators({onSetPatient}, dispatch),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PatientSearchContainer);

export default ConnectedComponent;

import {
  loginApi,
  logOutApi,
  ILoginParams,
  forgotPasswordApi,
  resetPasswordApi,
  // sendOTPApi,
} from "services/session.js";
import validator from "validator";

import {
  navigateToIndex,
  appRoutesConst,
  navigateToResetPassOTPScreen,
  navigateToResetPasswordViaMobile,
} from "app/navigation.js";
import {getErrorObject, errorToast, successToast, generateTokenFromJWT} from "utilities/utils";
import {navigateTo} from "../app/navigation";
import {message} from "antd";
import {updatePlayerID} from "../services/session";
import {
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "constants/common";
export const types = {
  LOGIN_REQUEST: "session/LOGIN_REQUEST",
  LOGIN_SUCCESS: "session/LOGIN_SUCCESS",
  LOGIN_FAILURE: "session/LOGIN_FAILURE",
  SEND_OTP_REQUEST: "session/SEND_OTP_REQUEST",
  SEND_OTP_SUCCESS: "session/SEND_OTP_SUCCESS",
  SEND_OTP_FAILURE: "session/SEND_OTP_FAILURE",
  VERIFY_OTP_REQUEST: "session/VERIFY_OTP_REQUEST",
  VERIFY_OTP_SUCCESS: "session/VERIFY_OTP_SUCCESS",
  VERIFY_OTP_FAILURE: "session/VERIFY_OTP_FAILURE",
  RESET_PASS_REQUEST: "session/RESET_PASS_REQUEST",
  RESET_PASS_SUCCESS: "session/RESET_PASS_SUCCESS",
  RESET_PASS_FAILURE: "session/RESET_PASS_FAILURE",
  RESET_FORM_STATE: "session/RESET_FORM_STATE",
  FORGOT_PASSWORD_REQUEST: "session/ FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_SUCCESS: "session/ FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILURE: "session/ FORGOT_PASSWORD_FAILURE",
  LOG_OUT: "session/LOG_OUT",
};

const initialState = {
  isLoading: false,
  error: null,
  loginId: null,
};

export const session = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      return {...state, isLoading: true};
    case types.LOGIN_SUCCESS:
      return {...state, error: null, isLoading: false};
    case types.LOGIN_FAILURE: {
      const {error} = action.payload;
      return {...state, error, isLoading: false};
    }
    case types.RESET_FORM_STATE:
      return {...state, error: null};
    case types.LOG_OUT:
      return initialState;
    case types.FORGOT_PASSWORD_REQUEST:
      return {...state, isLoading: true};
    case types.FORGOT_PASSWORD_SUCCESS:
      const {data} = action.payload;
      return {...state, error: null, isLoading: false, role: data};

    case types.FORGOT_PASSWORD_FAILURE: {
      const {error} = action.payload;
      return {...state, error, isLoading: false};
    }

    case types.RESET_PASS_REQUEST:
      return {...state, isLoading: true};
    case types.RESET_PASS_SUCCESS:
      return {...state, error: null, isLoading: false};

    case types.RESET_PASS_FAILURE: {
      const {error} = action.payload;
      return {...state, error, isLoading: false};
    }

    default:
      return state;
  }
};

export const login = (data: ILoginParams) => {
  return async (dispatch) => {
    dispatch({type: types.LOGIN_REQUEST});

    const customJWToken = generateTokenFromJWT()
    try {
      let response = await loginApi({
        device_detail: data.device_detail,
        user: data.user,
        role: "doctor",
      },customJWToken);
      const user = response.data.data;
      let tokenparams = {};
      if (user.auth_token) {
        tokenparams = {token: user.auth_token};
      }

      if (!user.password_expired) {
        localStorage.setItem("otp-phone", JSON.stringify(user.phone));
        if (user.phone) {
          dispatch({
            type: types.LOGIN_SUCCESS,
            payload: {user, ...tokenparams},
          });
          successToast({content: "OTP sent successfully"});
          await data.props.history.push(`${appRoutesConst.verfifyotp}`);
        }
      } else {
        let tokenparams = {reset_pass_token: ""};
        if (user.auth_token) {
          tokenparams = {reset_pass_token: user.auth_token};
        }
        dispatch({
          type: types.LOGIN_SUCCESS,
          payload: {...tokenparams},
        });
        successToast({
          content: "Your password is expired, Please change you password",
        });
        await data.props.history.push(`${appRoutesConst.changepassword}`);
      }

      // dispatch(navigateToCustomerSelection());
    } catch (error) {
      const {message} = getErrorObject(error);
      dispatch({type: types.LOGIN_FAILURE, payload: {error: message}});
      errorToast({content: message});
    }
  };
};

export const navigateToResetPassword = (token) => {
  return (dispatch) => {
    dispatch(navigateToResetPasswordViaMobile(token));
  };
};
export const navigateToindexFromToken = (token) => {
  return (dispatch) => {
    dispatch(navigateToIndex());
  };
};
export const navigateToCustomerSelectionViaOTP = (
  response,
  patientId,
  fromPharmsmart
) => {
  return async (dispatch) => {
    dispatch({type: types.LOGIN_REQUEST});
    try {
      // response.role = role;
      const user = response.data.data.user;
      const token = user.auth_token;
      dispatch({type: types.LOGIN_SUCCESS, payload: {user, token}});
      dispatch({type: "SWITCHING_CORPORATE", payload: false}); //for switching corporate loading

      if (user.password_expiry_due) {
        message.warning(
          `Your password will expire in ${user.days_left_for_current_password} day/s, please change before it gets expired`,
          7
        );
      }
      var OneSignal = window.OneSignal || [];
      console.log("OneSignal==>", await OneSignal.getUserId());

      // successToast({ content: "Login success" });
      let player_id = localStorage.getItem("player_id");
      if (!player_id) {
        player_id = await OneSignal.getUserId();
      }
      if (player_id) {
        updatePlayerID({player_id: player_id});
      }
      await console.log("Local Player ID", player_id);
      console.log("user==========>", user);

      if (!patientId) {
        if (fromPharmsmart) {
          //commented as prescriber and doctor have same all access
          dispatch(navigateTo(appRoutesConst.patientSearch));
        } else {
          if (
            user.role === ROLE_CLINICAL_PHARMACIST ||
            user.role === ROLE_SUPERINTENDENT_PHARMACIST
          ) {
            dispatch(navigateTo(appRoutesConst.patientSearch));
          } else {
            dispatch(navigateTo(appRoutesConst.dashboard));
          }
        }
      }
    } catch (error) {
      console.log("ERROR OS", error);
      const {message} = getErrorObject(error);
      dispatch({type: types.LOGIN_FAILURE, payload: {error: message}});
      errorToast({content: message});
    }
  };
};

export const navigateToCustomerSelectionViaOTPF2F = (response, patientData) => {
  console.log("F2F Called");
  return async (dispatch) => {
    dispatch({type: types.LOGIN_REQUEST});
    try {
      const user = response.data.data.user;
      const token = user.auth_token;
      dispatch({type: types.LOGIN_SUCCESS, payload: {user, token}});
      if (user.password_expiry_due) {
        message.warning(
          `Your password will expire in ${user.days_left_for_current_password} day/s, please change before it gets expired`,
          7
        );
      }
      dispatch({type: "ON_PATIENT_SELECT", payload: {id: patientData.id}});
      dispatch(navigateTo(appRoutesConst.patientDetail));
      console.log("patient selected", patientData);

      var OneSignal = window.OneSignal || [];
      // successToast({ content: "Login success" });
      let player_id = localStorage.getItem("player_id");
      if (!player_id) {
        player_id = await OneSignal.getUserId();
      }
      if (player_id) {
        updatePlayerID({player_id: player_id});
      }
      // console.log("user==========>", user);
      // onSetPatient({ id: patientData.id });
      // onSetPatientAppointment(patientData);

      // if (!patientId) {
      //   if (user.role === "prescriber") {
      //     dispatch(navigateTo(appRoutesConst.patientSearch));
      //   } else {
      //     dispatch(navigateTo(appRoutesConst.dashboard));
      //   }
      // }
    } catch (error) {
      console.log("ERROR OS", error);
      const {message} = getErrorObject(error);
      dispatch({type: types.LOGIN_FAILURE, payload: {error: message}});
      errorToast({content: message});
    }
  };
};
export const forgotPassword = (data, fromVerifyOtp) => {
  return async (dispatch) => {
    dispatch({type: types.FORGOT_PASSWORD_REQUEST});
    try {
      const response = await forgotPasswordApi({
        email: data.email,
        role: "doctor",
      });
      dispatch({
        type: types.FORGOT_PASSWORD_SUCCESS,
        payload: {data: response.data.data},
      });
      let isEmail = true;
      if (validator.isEmail(data.email)) {
        isEmail = true;
      } else {
        isEmail = false;
      }
      if (response.data && response.data.message) {
        successToast({
          content: response.data.message,
        });
      }
      if (!fromVerifyOtp) {
        if (isEmail) {
          dispatch(navigateToIndex());
        } else {
          let phone = data.email;
          // let newphone = phone.replace("+91", "");
          // newphone = "+91" + newphone;
          localStorage.setItem("otp-phone", JSON.stringify(phone));
          dispatch(navigateToResetPassOTPScreen());
        }
      }
    } catch (error) {
      const {message} = getErrorObject(error);
      dispatch({
        type: types.FORGOT_PASSWORD_FAILURE,
        payload: {error: message},
      });
      errorToast({content: message});
    }
  };
};

export const resetPassword = (data: IResetPasswordParams) => {
  return async (dispatch) => {
    dispatch({type: types.RESET_PASS_REQUEST});
    try {
      const response = await resetPasswordApi(data);
      dispatch({
        type: types.RESET_PASS_SUCCESS,
        payload: {data},
      });
      successToast({
        content: response.data.message,
      });
      dispatch(navigateToIndex());
    } catch (error) {
      const {message} = getErrorObject(error);
      dispatch({
        type: types.RESET_PASS_FAILURE,
        payload: {error: message},
      });
      errorToast({content: message});
    }
  };
};

export const logOut = () => {
  return (dispatch) => {
    logOutApi();
    dispatch({
      type: types.LOG_OUT,
    });
    // localStorage.removeItem("player_id");

    dispatch(navigateToIndex());
    dispatch({type: "CLEAR_LOCAL_STATE"});
    // localStorage.clear();
  };
};

export const resetFormState = () => {
  return (dispatch) => {
    dispatch({
      type: types.RESET_FORM_STATE,
    });
  };
};

import { get } from "./index";

export const genhsMedicationsApi = (params) => {
  const request = {
    subUrl: `/repeat_medications.json`,
    params,
  };
  return get(request);
};

export const getPastMedications = (params) => {
  const request = {
    subUrl: `/orders/past_medication_orders.json`,
    params,
  };
  return get(request);
};

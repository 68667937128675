import React, { Fragment, PureComponent } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { actions as staffIntranetActions } from "reducers/staffIntranet";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../utilities/utils";
import moment from "moment";
import MordenCalendar from "../../components/common/controls/MordenCalendar";
import {
  Button,
  Dropdown,
  Menu,
  Space,
  Modal,
  Empty,
  Upload,
  Progress,
  Select,
} from "antd";
import {
  // ClockCircleTwoTone,
  ExclamationCircleOutlined,
  FileFilled,
  CloseCircleFilled,
} from "@ant-design/icons";
import { CloseOutlined } from "@ant-design/icons";
import ModalPopUp from "../../components/common/ModalPopUp";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
// import {Calendar, utils} from "react-modern-calendar-datepicker";
import { Calendar, utils } from "@hassanmojab/react-modern-calendar-datepicker";
import { List } from "antd";
import {
  getTimeSlots,
  addRotaAPI,
  updateRotaAPI,
  deleteRotaAPI,
  addMultipleRotaApi,
} from "../../services/rota";
import { getBaseUrl } from "../../services";
import three_dots from "assets/images/common/three_dots.svg";
import time from "assets/images/common/ic-time.svg";
import { ReloadOutlined } from "@ant-design/icons";
import { DATE_FORMAT, T24_HR_FORMAT } from "../../constants/common";

import { PlusSquareOutlined, MinusSquareOutlined } from "@ant-design/icons";

import refresh from "assets/images/common/refresh.svg";
import { appRoutesConst } from "app/navigation";
import store from "app/store";

const { confirm } = Modal;

const { Option } = Select;
const time_slots_array = [];
// const today_date = this.props.passedDate;
var date = new Date();
date.setHours(8);
date.setMinutes(0);
date.setSeconds(0);
// console.log("Time Slots", this.props.passedDate)

time_slots_array.push(moment(date).format());

for (var i = 0; i < 56; i++) {
  date = moment(date).add(15, "minutes").format();
  time_slots_array.push(date);
}

class RotaContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      online_appointment: false,
      phone_call_appointment: false,
      offline_appointment: false,

      selectedDay: {
        year: parseInt(moment().format("YYYY"), 10),
        month: parseInt(moment().format("MM"), 10),
        day: parseInt(moment().format("DD"), 10),
      },
      date:
        verifyObject(moment(), "_d", null) !== ""
          ? moment()._d.toString()
          : null,
      visible: false,
      start_time_slots: [],
      end_time_slots: [],
      start_time: "",
      end_time: "",
      timeSlots: [],
      loading: false,
      selectedRota: null,
      editId: null,
      udating: false,
      adding: false,
      file_name: "",
      uploading: false,
      uploadPercent: 0,
      inputList: [
        {
          start_time: "",
          end_time: "",
          start_time_slots: time_slots_array,
          end_time_slots: [],
        },
      ],
      for_dates: null,
      doctor_ids: [],
      multiDateInputValue: "",
      startTimeSlots: time_slots_array,
      dates: [],
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleAddRota",
      "_handleCancel",
      "_handleDate",
      "_handleShowModal",
      "_handleDropDown",
      "_renderActionMenus",
      "_handleEditModal",
      "_handleUpdateRota",
      "_handleDelete",
      "_submitDelete",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.dynamicBox = React.createRef();
  }

  componentDidMount() {
    // this._onRequestList();
  }



  async _onRequestList() {
    try {
      let { date } = this.state;
      await this.setState({ loading: true });
      let response = await getTimeSlots({ date });
      if (response.data.data) {
        this.setState({ timeSlots: response.data.data });
      }
      this.setState({ loading: false });
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({ loading: false });
      errorToast({ content: message });
    }
  }

  _onPageChange(data) {
    let {
      staffIntranetActions: { onPageChange },
      staffIntranet: { search },
    } = this.props;
    const { page } = data;
    if (typeof onPageChange === "function") {
      onPageChange({ page, search });
    }
  }
  handleInputChange = (e, index) => {
    const { name, value } = e;
    let { inputList } = this.state;
    const list = [...inputList];

    list[index][name] = value;
    if (name === "start_time") {
      let singleEndTimeSlots = [...this.state.startTimeSlots];
      singleEndTimeSlots.splice(0, this.state.startTimeSlots.indexOf(value));
      list[index]["end_time_slots"] = singleEndTimeSlots;
    }
    if (name === "end_time") {
      let singleStartTimeSlots = [...this.state.startTimeSlots];
      if (list.length > 1 && list[index + 1]) {
        singleStartTimeSlots.splice(
          0,
          singleStartTimeSlots.indexOf(list[index]["end_time"])
        );
        list[index + 1]["start_time_slots"] = singleStartTimeSlots;
      }
    }

    this.setState({ inputList: list });
    // setInputList(list);
  };
  handleRemoveClick = (index) => {
    let { inputList } = this.state;
    const list = [...inputList];
    let startTimeSlots = [...this.state.startTimeSlots];
    if (list[index + 1] && list[index - 1]) {
      startTimeSlots.splice(
        0,
        startTimeSlots.indexOf(list[index - 1].end_time)
      );
      list[index + 1]["start_time_slots"] = startTimeSlots;
    }
    if (index === 0) {
      list[index + 1]["start_time_slots"] = startTimeSlots;
    }
    list.splice(index, 1);

    this.setState({ inputList: list });
  };
  handleAddClick = async () => {
    const inputList = [...this.state.inputList];
    let updatedStartTimeSlots = [...this.state.startTimeSlots];
    if (inputList[inputList.length - 1].end_time !== undefined) {
      updatedStartTimeSlots.splice(
        0,
        updatedStartTimeSlots.indexOf(inputList[inputList.length - 1].end_time)
      );
    }
    await this.setState({
      inputList: [
        ...this.state.inputList,
        {
          start_time: "",
          end_time: "",
          start_time_slots: updatedStartTimeSlots,
          // slots_for: this._getSessionTypes(),
          end_time_slots: [],
        },
      ],
    });
    // // const inputList = [...this.state.inputList]
    // let updatedStartTimeSlots = [...this.state.startTimeSlots]
    // // if (inputList[inputList.length - 1].end_time !== undefined) {
    // // 	updatedStartTimeSlots.splice(
    // // 		0,
    // // 		updatedStartTimeSlots.indexOf(inputList[inputList.length - 1].end_time)
    // // 	)
    // // }
    // await this.setState({
    // 	inputList: [
    // 		...this.state.inputList,
    // 		{
    // 			start_time: '',
    // 			end_time: '',
    // 			start_time_slots: updatedStartTimeSlots,
    // 			end_time_slots: [],
    // 		},
    // 	],
    // })
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value }, () => {});
  };
  handleSubmit = async () => {
    let { user } = this.props;
    let formData = new FormData();
    let sessionType = this._getSessionTypes();
    formData.set("is_range", this.state.is_range);
    formData.set("for_dates", this.state.for_dates);
    if (user) {
      formData.set("doctor_ids", user.id);
    }
    if (this.state.inputList) {
      this.state.inputList.map((k, i) => {
        k.start_time &&
          formData.set(`time_slots[${i}][start_time]`, k.start_time);
        k.end_time && formData.set(`time_slots[${i}][end_time]`, k.end_time);
        formData.set(`time_slots[${i}][slot_for]`, sessionType.toString());

        return null;
      });
    }

    try {
      await this.setState({ adding: true });
      let response = await addMultipleRotaApi(formData);
      if (response.data.data) {
        this.setState({ timeSlots: response.data.data });
      }
      successToast({
        content: response.data.message,
      });
      await this._handleCancel();
      await store.dispatch({
        type: "SET_ROTA_ADDED",
        payload: true,
      });
      //   if(this.props?.defaultDates){
      //     await this.props._fetchAppointmentCalendarSlots(this.props?.defaultDates?.toString())
      //   }else{
      //     await this.props._fetchAppointmentCalendarSlots()
      //   }

      this.setState({ adding: false });
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({
        selectedDay: {
          year: parseInt(moment().format("YYYY"), 10),
          month: parseInt(moment().format("MM"), 10),
          day: parseInt(moment().format("DD"), 10),
        },
        date:
          verifyObject(moment(), "_d", null) !== ""
            ? moment()._d.toString()
            : null,
        visible: false,
        start_time_slots: [],
        end_time_slots: [],
        start_time: "",
        end_time: "",
        timeSlots: [],
        loading: false,
        selectedRota: null,
        editId: null,
        udating: false,
        adding: false,
        file_name: "",
        uploading: false,
        uploadPercent: 0,
        inputList: [
          {
            start_time: "",
            end_time: "",
            start_time_slots: time_slots_array,
            end_time_slots: [],
          },
        ],
        for_dates: null,
        doctor_ids: [],
        multiDateInputValue: "",
        startTimeSlots: time_slots_array,
        dates: [],
      });
      await this._handleCancel();
      errorToast({ content: message });
    }
  };

  _handleDate(dates) {
    let day =
      dates.day.toString().length >= 2 ? dates.day.toString() : `0${dates.day}`;
    let month =
      dates.month.toString().length >= 2
        ? dates.month.toString()
        : `0${dates.month}`;

    let fullDate = `${dates.year}-${month}-${day}`.toString();
    this.setState({
      selectedDay: dates,
      date:
        verifyObject(moment(fullDate), "_d", null) !== ""
          ? moment(fullDate)._d.toString()
          : null,
    });
  }

  _getSessionTypes = () => {
    let { online_appointment, offline_appointment, phone_call_appointment } =
      this.state;
    let types = [];

    if (online_appointment) {
      types.push("online_appointment");
    }

    if (offline_appointment) {
      types.push("offline_appointment");
    }

    if (phone_call_appointment) {
      types.push("phone_call_appointment");
    }

    return types;
  };

  _updateSessionTypes = (e) => {
    this.setState(
      {
        [e.target.name]: this.state.editId
          ? !e.target.checked
          : e.target.checked,
      },
      (_) => {
        let sessionTypes = this._getSessionTypes();
        let updatedList = [...this.state.inputList];

        updatedList.forEach((i) => {
          i.slots_for = sessionTypes;
        });

        this.setState({
          inputList: updatedList,
        });
      }
    );
  };

  _handleDelete(record) {
    confirm({
      title: "Delete Time Slot",
      content: "Are you sure you want to delete this time slot?",
      icon: <ExclamationCircleOutlined />,

      onOk: async () => {
        await this._submitDelete(record);
      },
      onCancel() {},
      okText: "confirm",
      className: "rota-confirm-delete",

      cancelButtonProps: {
        className: "rota-cancel-btn",
      },
      okButtonProps: {
        className: "rota-ok-btn",
        type: "ghost",
      },
    });
  }

  async _submitDelete(record) {
    try {
      await this.setState({ deleting: true });
      let response = await deleteRotaAPI({ id: record.id });
      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      this._handleCancel();
      // this._onRequestList();
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({ adding: false });
      errorToast({ content: message });
    }
  }

  _handleCancel() {
    let time_slots_array = [];
    // const today_date = this.props.passedDate;
    var date = new Date();
    date.setHours(8);
    date.setMinutes(0);
    date.setSeconds(0);
    time_slots_array.push(moment(date).format());
    for (var i = 0; i < 56; i++) {
      date = moment(date).add(15, "minutes").format();
      time_slots_array.push(date);
    }

    this.setState(
      {
        is_range: 3,
        dates: [],
        showDate: false,
        visible: false,
        inputList: [
          {
            start_time: "",
            end_time: "",
            start_time_slots: time_slots_array,
            end_time_slots: [],
          },
        ],
        for_dates: null,
        doctor_ids: [],
        loading: false,
        multiDateInputValue: "",
        startTimeSlots: time_slots_array,
        editId: null,
        online_appointment: null,
        offline_appointment: null,
        phone_call_appointment: null,
        udating: false,
        deleting: false,
        file_name: "",
      },
      () => !this.props.fromDrawer && this._onRequestList()
    );
  }
  datePickerChange = () => {
    this.setState({ showDate: true });
  };
  hideDateChange = () => {
    this.setState({ showDate: false });
  };
  async _handleAddRota() {
    try {
      let sessionTypes = this._getSessionTypes();

      let { date, start_time, end_time } = this.state;
      await this.setState({ adding: true });
      let ReqObj = {
        start_time: new Date(start_time).toISOString(),
        end_time: new Date(end_time).toISOString(),
        for_date: date,
        slot_for: sessionTypes.toString(),
      };

      let response = await addRotaAPI({ time_slot: { ...ReqObj } });
      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      this._handleCancel();
      // this._onRequestList();
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({ adding: false });
      errorToast({ content: message });
    }
  }

  _handleDropDown(e) {
    let name = e.target.name;
    this.setState({ [name]: e.target.value });
  }

  async componentDidUpdate(prevProps, nextState) {
    //validate end time slots
    if (nextState.start_time !== this.state.start_time) {
      let indexOfStartTime = await nextState.end_time_slots.indexOf(
        this.state.start_time
      );
      let finalArray = await nextState.end_time_slots.slice(
        indexOfStartTime + 1
      );
      await this.setState({ end_time_slots: finalArray });
    }

    // callback after change date
    if ((nextState.date !== this.state.date || this.props.isShowModal) && !this.props.fromDrawer) {
      await this._onRequestList();
    }

    //
    if (nextState.selectedRota !== this.state.selectedRota) {
      if (this.state.selectedRota !== null) {
        let { start_time, end_time, id } = this.state.selectedRota;
        this.setState({
          start_time: moment(start_time).format(),
          end_time: moment(end_time).format(),
          editId: id,
        });
      }
    }
  }

  _handleShowModal() {
    const time_slots_array = [];
    let { date } = this.state;

    var rotaDate = new Date(date);
    rotaDate.setHours(8);
    rotaDate.setMinutes(0);
    rotaDate.setSeconds(0);

    time_slots_array.push(moment(rotaDate).format());

    for (let i = 0; i < 56; i++) {
      rotaDate = moment(rotaDate).add(15, "minutes").format();
      time_slots_array.push(rotaDate);
    }

    this.setState({
      start_time_slots: time_slots_array,
      end_time_slots: time_slots_array,
      visible: true,
    });
  }

  _renderActionMenus(record) {
    return (
      <Menu className="width-115px small_dropdown">
        <Menu.Item onClick={() => this._handleEditModal(record)}>
          Edit
        </Menu.Item>
        <Menu.Item onClick={() => this._handleDelete(record)}>Delete</Menu.Item>
      </Menu>
    );
  }
  handeDateRange = (date, params) => {
    if (date) {
      let element = document.getElementById(`rota-range-picker`);
      if (element) {
        element.classList.add("datepicker-custom");
      }
      if (
        date[0] &&
        moment(date[0]).format(DATE_FORMAT) !==
          moment(date[1]).format(DATE_FORMAT)
      ) {
        this.setState({
          for_dates2: [
            date[0],
            date[1],
            // moment(date[0]).format("DD-MM-YYYY"),
            // moment(date[1]).format("DD-MM-YYYY"),
          ],
          for_dates: [
            moment(date[0]).format(DATE_FORMAT),
            moment(date[1]).format(DATE_FORMAT),
          ].toString(),
        });
      } else {
        errorToast({
          content: "Please select end date grater than start date",
        });
      }
    } else {
      let element = document.getElementById(`rota-range-picker`);
      if (element) {
        element.classList.remove("datepicker-custom");
      }
      this.setState({ for_dates: null, for_dates2: null });
    }
  };
  _renderActionMenuForBunchUpload(record) {
    let { token } = this.props;
    let baseURL = getBaseUrl();
    let action = `${baseURL}/time_slots/batch_upload.json`;
    const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
    let uploadProps = {
      action: action,
      data: { time_zone: time_zone },
      headers: {
        AUTH_TOKEN: token,
      },
      onStart: (file) => {
        this.setState({
          uploading: true,
          file_name: file.name,
          uploadPercent: 30,
        });
      },
      onSuccess: (res, file) => {
        this.setState({ uploading: false, uploadPercent: 100 });
        successToast({ content: verifyObject(res, "message", "Success") });
      },
      onError: (err) => {
        this.setState({ uploading: false });
      },
      onProgress: ({ percent }, file) => {
        this.setState({ uploadPercent: percent });
      },
    };
    return (
      <Menu className="width-190">
        <Menu.Item onClick={() => {}}>
          <a
            download="https://d358rbzc7edgv0.cloudfront.net/time_slots.xlsx"
            href="https://d358rbzc7edgv0.cloudfront.net/time_slots.xlsx"
          >
            Download Format
          </a>
        </Menu.Item>
        <Menu.Item onClick={() => {}}>
          <Upload {...uploadProps} showUploadList={false} accept=".xl,.xlsx">
            Upload
          </Upload>
        </Menu.Item>
      </Menu>
    );
  }

  _handleEditModal(record) {
    const time_slots_array = [];
    let { date } = this.state;

    var rotaDate = new Date(date);
    rotaDate.setHours(8);
    rotaDate.setMinutes(0);
    rotaDate.setSeconds(0);

    time_slots_array.push(moment(rotaDate).format());

    for (let i = 0; i < 56; i++) {
      rotaDate = moment(rotaDate).add(15, "minutes").format();
      time_slots_array.push(rotaDate);
    }

    this.setState({
      visible: true,
      start_time_slots: time_slots_array,
      end_time_slots: time_slots_array,
      selectedRota: record,
      online_appointment: record.slot_for
        ? record.slot_for.includes("online_appointment")
        : false,
      offline_appointment: record.slot_for
        ? record.slot_for.includes("offline_appointment")
        : false,
      phone_call_appointment: record.slot_for
        ? record.slot_for.includes("phone_call_appointment")
        : false,
    });
  }

  async _handleUpdateRota() {
    let sessionType = this._getSessionTypes();
    try {
      let { date, start_time, end_time, editId } = this.state;
      await this.setState({ updating: true });
      let ReqObj = {
        start_time: new Date(start_time).toISOString(),
        end_time: new Date(end_time).toISOString(),
        for_date: date,
        id: editId,
        slot_for: sessionType.toString(),
      };

      let response = await updateRotaAPI({ time_slot: { ...ReqObj } });
      successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      await this._handleCancel();
      // await this._onRequestList();
      await this.setState({ updating: false });
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({ updating: false });
      errorToast({ content: message });
    }
  }

  render() {
    let {
      selectedDay,
      uploading,
      uploadPercent,
      file_name,
      updating,
      adding,
      loading,
      visible,
      start_time_slots,
      editId,
      timeSlots,
      end_time_slots,
      start_time,
      end_time,
    } = this.state;
    let { inputList } = this.state;

    let isDisabled =
      this.state.inputList &&
      this.state.inputList[0]["start_time"] !== "" &&
      this.state.inputList &&
      this.state.inputList[0]["end_time"] !== "" &&
      this.state.for_dates !== null &&
      (this.state.online_appointment ||
        this.state.offline_appointment ||
        this.state.phone_call_appointment);
    if (this.state.editId) {
      isDisabled =
        this.state.start_time !== "" &&
        this.state.end_time !== "" &&
        (this.state.online_appointment ||
          this.state.offline_appointment ||
          this.state.phone_call_appointment);
    }
    // let { visible } = this.props
    return (
      <div>
        <div className="d-flex align-items-center justify-content-end gapx-4">
          <button
            onClick={this._handleShowModal}
            className="menu-link border-0 csbtn-1 mr-0"
          >
            Add ROTA
          </button>
          <CloseOutlined
            className="cursor-pointer"
            onClick={this.props.onCloseDrawer}
          />
        </div>
        {
          <ModalPopUp
            title="Add Hours"
            handleCancel={this._handleCancel}
            visible={visible}
            // closable={false}
            handleOk={this.handleSubmit}
            okText={editId ? "Update" : "Add"}
            okButtonProps={{
              disabled: !isDisabled,
            }}
            disabled={!isDisabled}
            cancelText="Cancel"
            loading={adding}
            footer={true}
            className="rota_modal"
            maskClosable={false}
          >
            <Fragment>
              <div className="form-group">
                <label htmlFor="">Session Type</label>
                <div className="session_btn">
                  <label
                    className={`font-14 btn ${
                      this.state.online_appointment ? "btn-blue" : "btn-border"
                    } btn-custom d-inline-block`}
                    for="online_appointment"
                  >
                    <input
                      type="checkbox"
                      name="online_appointment"
                      id="online_appointment"
                      onClick={this._updateSessionTypes}
                      className="d-none"
                    />
                    Video Call
                  </label>
                  <label
                    className={`font-14 btn ${
                      this.state.phone_call_appointment
                        ? "btn-blue"
                        : "btn-border"
                    } btn-custom d-inline-block`}
                    for="phone_call_appointment"
                  >
                    <input
                      type="checkbox"
                      name="phone_call_appointment"
                      id="phone_call_appointment"
                      className="d-none"
                      onClick={this._updateSessionTypes}
                    />
                    Phone Call
                  </label>
                  <label
                    className={`font-14 btn ${
                      this.state.offline_appointment ? "btn-blue" : "btn-border"
                    } btn-custom d-inline-block`}
                    for="offline_appointment"
                  >
                    <input
                      onClick={this._updateSessionTypes}
                      type="checkbox"
                      name="offline_appointment"
                      className="d-none"
                      id="offline_appointment"
                    />
                    Face to Face
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="sel1">Date Selection Options</label>
                <select
                  className="form-control text-capitalize"
                  name={"is_range"}
                  onChange={(e) => this.setState({ is_range: e.target.value })}
                  value={this.state.is_range}
                >
                  <option disabled="disabled" selected="selected" value={3}>
                    Select Date Selection Option
                  </option>
                  <option value={0}>Manual</option>
                  <option value={1}>Range</option>
                </select>
              </div>
              {this.state.is_range && (
                <div className="form-group">
                  {this.state.is_range === "0" ||
                  this.state.is_range === "1" ? (
                    <label htmlFor="sel1">Select Date</label>
                  ) : (
                    ""
                  )}

                  {this.state.is_range === "0" && (
                    <Fragment>
                      {this.state.showDate ? (
                        <div className="custom-calendar">
                          <Calendar
                            value={this.state.dates}
                            minimumDate={utils().getToday()}
                            onChange={(dates) => {
                              let DateArray = [];
                              let dateStringValue = "";
                              dates.map((d) => {
                                let day =
                                  d.day.toString().length >= 2
                                    ? d.day.toString()
                                    : `0${d.day}`;
                                let month =
                                  d.month.toString().length >= 2
                                    ? d.month.toString()
                                    : `0${d.month}`;

                                let fullDate = `${day}-${month}-${d.year}`;
                                DateArray.push(fullDate);
                                dateStringValue =
                                  dateStringValue.length !== 0
                                    ? dateStringValue + "," + fullDate
                                    : fullDate;
                                return null;
                              });

                              this.setState({
                                dates: dates,
                                customDates: DateArray,
                                multiDateInputValue: dateStringValue,
                              });
                            }}
                            renderFooter={() => (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  padding: "1rem 2rem",
                                }}
                              >
                                <Button
                                  style={{
                                    background: "#096dd9",
                                  }}
                                  type="button"
                                  onClick={() => {
                                    this.setState({
                                      showDate: false,
                                      customDates: [],
                                      dates: [],
                                      multiDateInputValue: "",
                                    });
                                  }}
                                >
                                  Cancel
                                </Button>
                                <Button
                                  type="button"
                                  onClick={() => {
                                    this.setState({
                                      showDate: false,
                                      for_dates: this.state.multiDateInputValue,
                                    });
                                  }}
                                  style={{
                                    background: "#096dd9",
                                  }}
                                >
                                  Submit
                                </Button>
                              </div>
                            )}
                          />
                        </div>
                      ) : (
                        <input
                          onFocus={this.datePickerChange}
                          name="discount_code"
                          type="text"
                          value={this.state.multiDateInputValue}
                          placeholder="Select dates"
                          className="form-control"
                        ></input>
                      )}
                    </Fragment>
                  )}

                  <div id="rota-range-picker">
                    {this.state.is_range === "1" && (
                      <DateRangePicker
                        clearIcon={
                          this.state.for_dates === null ? null : (
                            <CloseCircleFilled
                              style={{
                                marginBottom: "4px",
                                marginRight: "1px",
                                color: "#2072bb",
                              }}
                            />
                          )
                        }
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        format="dd-MM-yyyy"
                        rangeDivider="To"
                        onClickMonth={async () => {
                          await this.setState({ isDateRange: true });
                        }}
                        onClickYear={() => {
                          this.setState({ isDateRange: true });
                        }}
                        style={{
                          width: "100%",
                          background: "#f7f7f7",
                        }}
                        minDate={new Date()}
                        onChange={this.handeDateRange}
                        className="date-range-1111"
                        // value={[
                        // 	this.state.for_dates && this.state.for_dates[0]
                        // 		? this.state.for_dates[0]
                        // 		: null,
                        // 	this.state.for_dates && this.state.for_dates[1]
                        // 		? this.state.for_dates[1]
                        // 		: null,
                        // ]}
                        value={[
                          this.state.for_dates2 && this.state.for_dates2[0]
                            ? this.state.for_dates2[0]
                            : null,
                          this.state.for_dates2 && this.state.for_dates2[1]
                            ? this.state.for_dates2[1]
                            : null,
                        ]}
                      />
                    )}
                  </div>
                </div>
              )}
              {inputList.map((x, i) => {
                return (
                  <div className="box">
                    <div className="form-group">
                      <label htmlFor="sel1">Start time</label>
                      <Select
                        className="form-control"
                        name="start_time"
                        value={x.start_time}
                        onChange={(e) => {
                          let data = {
                            name: "start_time",
                            value: e,
                          };
                          this.handleInputChange(data, i);
                        }}
                      >
                        <Option
                          value=""
                          disabled="disabled"
                          selected="selected"
                          className="rota-select-option"
                        >
                          Select start time
                        </Option>
                        {x.start_time_slots.map((t, i) => {
                          return (
                            <Option
                              key={i}
                              value={t}
                              className="rota-select-option"
                            >
                              {moment(t).format(T24_HR_FORMAT)}
                            </Option>
                          );
                        })}
                      </Select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="sel1">End time</label>
                      <Select
                        className="form-control"
                        name="end_time"
                        value={x.end_time}
                        onChange={(e) => {
                          let data = {
                            name: "end_time",
                            value: e,
                          };
                          this.handleInputChange(data, i);
                        }}
                      >
                        <Option
                          value=""
                          disabled="disabled"
                          selected="selected"
                          className="rota-select-option"
                        >
                          Select end time
                        </Option>
                        {x.end_time_slots.map((t, i) => (
                          <Option
                            key={i}
                            value={t}
                            className="rota-select-option"
                          >
                            {moment(t).format(T24_HR_FORMAT)}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    {/* <span
                                        style={{
                                            fontSize: "13px", fontStyle: "italic", color: "red"
                                        }}
                                    ><sup>*</sup>End time should be greater than start time. </span> */}

                    <div className="btn-box text-right">
                      {inputList.length !== 1 && (
                        <button
                          className="red-btn remove_btn btn font-13 weight-500 text-light-red p-0"
                          onClick={() => this.handleRemoveClick(i)}
                        >
                          {/* <img alt='' src={minusicon}></img> */}
                          <MinusSquareOutlined /> Remove
                        </button>
                      )}
                      {inputList.length - 1 === i && (
                        <button
                          className="add_btn blue-btn btn font-13 weight-500 text-blue p-0"
                          style={{ marginLeft: 10 }}
                          onClick={this.handleAddClick}
                        >
                          {/* <img alt='' src={plusicon}></img>  */}
                          <PlusSquareOutlined /> Add
                        </button>
                      )}
                    </div>
                  </div>
                );
              })}
            </Fragment>
          </ModalPopUp>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    staffIntranet: verifyObject(state, "staffIntrant", []),
    token: verifyObject(state, "localStore.token", ""),
    user: verifyObject(state, "localStore.user", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    staffIntranetActions: bindActionCreators(staffIntranetActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(RotaContainer);

export default withRouter(ConnectedComponent);

import React from "react";
// import { Calendar, utils } from "react-modern-calendar-datepicker";
import {Calendar,utils} from '@hassanmojab/react-modern-calendar-datepicker';
import moment from "moment";
import { Button } from "antd";
export default function MordenCalendar({
  selectedDay,
  className,
  todayClassName,
  onsetSelectedDay,
  date,
  isFutureDateDisabled,
  isPastDateDisabled,
  handleClear,
  fromSidebar,
}) {
  console.log("DATE", moment(date).format("DD-MM-YYYY"), selectedDay);
  return (
    <div>
      {selectedDay !== null && fromSidebar && (
        <Button
          onClick={() => {
            if (typeof handleClear === "function") {
              handleClear(null);
            }
          }}
          className="btn btn-blue btn-custom"
        >
          Clear
        </Button>
      )}
      <Calendar
        value={selectedDay}
        calendarClassName={className}
        onChange={onsetSelectedDay}
        calendarTodayClassName={todayClassName}
        maximumDate={isFutureDateDisabled ? utils().getToday() : ""}
        minimumDate={isPastDateDisabled ? utils().getToday() : ""}
        // minimumDate={utils().getToday()}
      />
    </div>
  );
}

import {listingReducer} from "reducers/utils/listingReducer";
import { getShortCodesAPI } from "services/shortCodes";

const {reducer, actions, types} = listingReducer({
  name: `shortCodes`,
  getApi: getShortCodesAPI,
});

const shortCodes = reducer;
export {shortCodes, actions, types};

import React, {Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as reportsActions} from "../../../reducers/reports";
import {
  verifyObject,
  // modifiedDataObjectToArray,
  getErrorObject,
  errorToast,
  successToast,
  modifiedDataObjectToArrayByIDs,
} from "../../../utilities/utils";
import {Tag, Button, Radio, Image, Collapse, Select} from "antd";
import {withRouter} from "react-router-dom";
import {isArray, debounce, uniqueId} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import {Pagination} from "../../../components/common";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {getEmailTemplatesApi} from "../../../services/emailTemplates";
import {ValidateInput} from "./ReportsValidations";
import {addReports} from "../../../services/reports";
import {DATE_FORMAT_24_HR} from "../../../constants/common";
import {Input} from "antd";
import {actions as actionCableActions} from "reducers/actionCableData";
import store from "app/store";
import actionCable from "actioncable";
import {baseWebSocketURL} from "services";
import {appRoutesConst} from "app/navigation";

// import { SearchOutlined } from "@ant-design/icons";
let {Panel} = Collapse;
class ReportsContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: ["1"],

      result: null,
      positiveTemplates: [],
      negativeTemplates: [],
      comment: "",
      covid_email_template_id: null,
      errors: null,
      fileUrl: null,
      document_id: null,
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleSearch",
      "_handleCancel",
      "_handleRadio",
      "_showModal",
      "_GetEmailTemplates",
      "_handleChange",
      "_handleSubmit",
      "_clearData",
      "_handleDropDownChange",
      "_onChangeComment",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, reportsActions} = this.props;
    let {onSearch} = reportsActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({
        search: value,
        patient_id: patientId,
        page: 1,
        // document_type: "Prescription",
      });
    }
  }

  _handleRadio(e) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
      delete errors[`covid_email_template_id`];
      delete errors[`comment`];
    }
    this.setState({result: e.target.value, fileUrl: null, errors: errors});
  }

  _handleChange(e) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors});
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
    }
    let mergeArray = [
      ...this.state.negativeTemplates,
      ...this.state.positiveTemplates,
    ];
    let fileUrl = null;
    // eslint-disable-next-line eqeqeq
    let found = mergeArray.find((d) => d.id == value);
    if (found) {
      fileUrl = found.file_url;
    }

    this.setState({
      covid_email_template_id: value,
      fileUrl: fileUrl,
      errors: errors,
    });
  }

  _showModal(k) {
    this.setState({visible: true, document_id: k.id}, () => {
      this._GetEmailTemplates();
    });
  }

  componentDidMount() {
    this._onRequestList();
  }

  _onRequestList() {
    let {patientId, reportsActions} = this.props;
    let directPatientId = localStorage.getItem("patient_id")
      ? localStorage.getItem("patient_id")
      : patientId;
    let {onRequest} = reportsActions;
    if (typeof onRequest === "function") {
      onRequest({
        patient_id: directPatientId,
        page: 1,
      });
    }
  }

  async _GetEmailTemplates() {
    try {
      let response = await getEmailTemplatesApi({type: "report"});
      let templates = verifyObject(response, "data.data", []);

      let negativeArray = templates.filter((d) => d.flag === "negative");
      let positiveArray = templates.filter((d) => d.flag === "positive");
      this.setState({
        negativeTemplates: negativeArray,
        positiveTemplates: positiveArray,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      reportsActions: {onPageChange},
      reports: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page: page,
        patient_id: patientId,
        search: search,
        // document_type: "Prescription",
      });
    }
  }

  _handleCancel() {
    this.setState({visible: false}, () => {
      this._clearData();
    });
  }
  _onChangeComment(e) {
    this.setState({
      comment: e.target.value,
    });
  }

  async _handleSubmit(e) {
    let {comment, result, covid_email_template_id, document_id} = this.state;

    let {patientId} = this.props;
    let payload = {
      comment: comment,
      covid_email_template_id: covid_email_template_id,
      document_id: document_id,
      patient_id: patientId,
      result: result,
    };

    const errors = ValidateInput(payload);
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      try {
        await this.setState({changing: true});
        let response = await addReports({...payload});
        let counterChannle = actionCable.createConsumer(
          `${baseWebSocketURL}/cable`
        );
        counterChannle.subscriptions.create(
          {
            channel: "PendingQuestionnaireChannel",
            auth_token: store.getState().localStore.token,
          },
          {
            connected: (d) => {
              console.log("-------IN REPORTS CONNECTED-------");
            },
          }
        );
        await this._clearData();
        this.props.history.goBack();

        await successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({changing: false});
        errorToast({content: message});
      }
    }
  }
  _clearData() {
    this.setState({
      result: null,
      positiveTemplates: [],
      negativeTemplates: [],
      comment: "",
      covid_email_template_id: null,
      errors: null,
      changing: false,
      visible: false,
      fileUrl: null,
    });
  }

  render() {
    let {reports} = this.props;
    let {result, negativeTemplates, positiveTemplates, comment, fileUrl} =
      this.state;
    let {
      isLoading,
      pagination: {total},
    } = reports;
    let reportsArray = modifiedDataObjectToArrayByIDs(reports);

    let {visible, changing, activeKey} = this.state;
    let selectedReportReview = JSON.parse(
      localStorage.getItem("selectedReportReview")
    );
    return (
      <Fragment>
        <div className="push-20-b reportscont_wrapper">
          <div className="form-row align-items-center push-10-b">
            <div className="col-md-6">
              <h6 className="font-14 text-light-black margin-0 weight-500">
                Reports {isLoading ? "" : `(${total})`}{" "}
              </h6>
            </div>
            {/* <div className="col-md-6 text-right">
                <div className="relative searchbar_dropdown medical_Search">
                  <Input allowClear={true} size="large" placeholder="Search" prefix={<SearchOutlined />}/>
                </div>
            </div> */}
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}

          <div className="prescription-array">
            {!isLoading &&
              reportsArray &&
              isArray(reportsArray) &&
              reportsArray.length === 0 && (
                <NoFoundMessage message="No reports found." />
              )}
            {!isLoading &&
              reportsArray &&
              isArray(reportsArray) &&
              reportsArray.map((k, index) => {
                return (
                  <Collapse
                    expandIconPosition="right"
                    onChange={(key) => {
                      this.setState({activeKey: key});
                    }}
                    activeKey={activeKey}
                    className="pre_quitionare"
                    key={uniqueId("REPO")}
                  >
                    <Panel
                      key={[index + 1]}
                      header={
                        <div className="col-md-12 prl-5">
                          <div className="form-row align-items-center">
                            <div className="col-md-5">
                              <p className="m-0 font-12 weight-400 text-fff padding-5 text-capitalize">
                                Uploaded On{" "}
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={
                                    verifyObject(k, "created_at", "") !== ""
                                      ? `${moment(k.created_at).format(
                                          DATE_FORMAT_24_HR
                                        )}`
                                      : "-"
                                  }
                                />{" "}
                                &nbsp;{" "}
                                {k.review_status === "reviewed" ? (
                                  <Fragment>
                                    {verifyObject(k, "review_status", null) !==
                                      null &&
                                    k.review_status === "reviewed" &&
                                    verifyObject(k, "result", null) !== null &&
                                    k.result === "positive" ? (
                                      <Tag className="white-tag">Positive</Tag>
                                    ) : (
                                      <Tag className="white-tag">Negative</Tag>
                                    )}{" "}
                                  </Fragment>
                                ) : (
                                  <Tag className="white-tag">Pending</Tag>
                                )}
                                {k.child_info !== null && (
                                  <Fragment>
                                    <div className="font-14 weight-400 in-block push-5-r">
                                      &nbsp; | &nbsp;
                                    </div>

                                    <p className="in-block font-14 weight-400 margin-0 normal-lineheight">
                                      <span className="ant-tag white-tag text-capitalize">
                                        Child
                                      </span>
                                    </p>
                                  </Fragment>
                                )}
                              </p>
                            </div>
                            {verifyObject(k, "review_status", null) !== null &&
                              k.review_status === "reviewed" && (
                                <div className="col-md-7 text-right">
                                  <p className="font-12 weight-400 margin-0 normal-lineheight text-fff padding-5 in-block">
                                    <span className="vertical-middle">
                                      {verifyObject(k, "review_by", null) !==
                                        null &&
                                        verifyObject(k, "reviewed_at", null) !==
                                          null &&
                                        `By ${k.review_by} On ${moment(
                                          k.reviewed_at
                                        ).format(DATE_FORMAT_24_HR)}`}{" "}
                                    </span>
                                  </p>
                                  {verifyObject(
                                    k,
                                    "email_template_name",
                                    null
                                  ) !== null && (
                                    <Fragment>
                                      <div className="font-12 weight-400 in-block push-5-r text-fff">
                                        |
                                      </div>
                                      <p className="in-block font-12 weight-400 margin-0 normal-lineheight">
                                        {verifyObject(
                                          k,
                                          "email_template_name",
                                          null
                                        ) !== null &&
                                          verifyObject(
                                            k,
                                            "email_template_url",
                                            null
                                          ) !== null && (
                                            <span className="text-fff">
                                              Email Template :{" "}
                                              <a href={k.email_template_url}>
                                                {k.email_template_name}
                                              </a>{" "}
                                            </span>
                                          )}
                                      </p>
                                    </Fragment>
                                  )}
                                </div>
                              )}
                          </div>
                        </div>
                      }
                    >
                      <div className="col-md-12 p-0">
                        <div className="form-row">
                          <div className="col-md-6">
                            <div className="border">
                              <Image
                                style={{width: "100%"}}
                                src={k.image_url}
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            {(verifyObject(k, "review_status", null) === null ||
                              k.review_status === "pending") &&
                              window.location.pathname ===
                                appRoutesConst.reportsReviewDetail && (
                                <Button
                                  className="btn-green btn btn-custom width-130 weight-500"
                                  onClick={() => this._showModal(k)}
                                >
                                  Review
                                </Button>
                              )}
                            {k.child_info && k.child_info !== null && (
                              <div className="push-20-b push-10-t">
                                <p className="font-14 weight-400  push-5-b normal-lineheight">
                                  <p className="font-12 weight-500 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                    Child Detail
                                  </p>
                                  {k.child_info && k.child_info !== null && (
                                    <Fragment>
                                      {" "}
                                      <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                        Name :{" "}
                                        {`${
                                          k.child_info &&
                                          k.child_info.first_name
                                        } ${
                                          k.child_info &&
                                          k.child_info.middle_name
                                        } ${
                                          k.child_info && k.child_info.last_name
                                        } `}
                                      </p>
                                      {k.child_info && k.child_info.dob && (
                                        <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                          DOB:{" "}
                                          {moment(k.child_info.dob).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </p>
                                      )}
                                      {k.child_info &&
                                        k.child_info.guardian_authority ===
                                          true && (
                                          <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                            Guardian Authority : To Parent
                                            Account
                                          </p>
                                        )}
                                    </Fragment>
                                  )}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                );
              })}
          </div>
          <div className="col-md-12">
            {!isLoading && (
              <Pagination data={reports} onPageChange={this._onPageChange} />
            )}
          </div>
          <ModalPopUp
            title="Review Report"
            handleCancel={this._handleCancel}
            visible={visible}
            handleOk={this._handleSubmit}
            cancelBtnclassName="custom_cancel_btn"
            okText={"ADD"}
            cancelText="Cancel"
            loading={changing}
            footer={true}
            maskClosable={false}
          >
            <div className="form-group">
              {selectedReportReview && selectedReportReview.child_info && (
                <div className="parent-auth">
                  {selectedReportReview.child_info &&
                    selectedReportReview.child_info !== null && (
                      <div className="push-20-b push-10-t">
                        <p className="font-14 weight-400  push-5-b normal-lineheight">
                          <p className="font-12 weight-500 text-dark-gray text-left dis-block push-5-b text-capitalize">
                            Child Detail
                          </p>
                          {selectedReportReview.child_info &&
                            selectedReportReview.child_info !== null && (
                              <Fragment>
                                {" "}
                                <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                  Name :{" "}
                                  {`${
                                    selectedReportReview.child_info &&
                                    selectedReportReview.child_info.first_name
                                  } ${
                                    selectedReportReview.child_info &&
                                    selectedReportReview.child_info.middle_name
                                  } ${
                                    selectedReportReview.child_info &&
                                    selectedReportReview.child_info.last_name
                                  } `}
                                </p>
                                {selectedReportReview.child_info &&
                                  selectedReportReview.child_info.dob && (
                                    <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                      DOB:{" "}
                                      {moment(
                                        selectedReportReview.child_info.dob
                                      ).format("DD-MM-YYYY")}
                                    </p>
                                  )}
                                {selectedReportReview.child_info &&
                                  selectedReportReview.child_info
                                    .guardian_authority === true && (
                                    <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                      Guardian Authority : To Parent Account
                                    </p>
                                  )}
                              </Fragment>
                            )}
                        </p>
                      </div>
                    )}
                </div>
              )}
              <label htmlFor="sel1">Result Value</label>
              <Radio.Group
                name="result"
                onChange={this._handleRadio}
                value={result}
                className="dis-bock reports_radio"
              >
                <Radio value={"negative"}>Negative</Radio>
                <Radio value={"positive"}>Positive</Radio>
                <Radio value={"comment"}>Comment</Radio>
              </Radio.Group>
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.result}
                </span>
              )}
            </div>
            {this.state.result === "comment" && (
              <div
                style={{
                  marginBottom: 10,
                }}
                className="comment mt-2"
              >
                <label htmlFor="comment">Comment</label>
                <Input
                  type="text"
                  name="comment"
                  value={comment}
                  className="d-block font-10 placeholder-10 form-control margin-0"
                  placeholder="Please write in your comment"
                  onChange={this._onChangeComment}
                />
              </div>
            )}
            {(result === "negative" || result === "positive") && (
              <div className="form-group">
                <label htmlFor="sel1">Select Email Template</label>
                {result === "negative" && (
                  <Select
                    onChange={(v) =>
                      this._handleDropDownChange(v, "covid_email_template_id")
                    }
                    name="covid_email_template_id"
                    className="form-control margin-0 font-10"
                    placeholder="Select Templates"
                  >
                    <Select.Option disabled>Select Templates</Select.Option>
                    {negativeTemplates.map((n) => {
                      return (
                        <Select.Option value={`${n.id}`}>
                          {n.template_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
                {result === "positive" && (
                  <Select
                    onChange={(v) =>
                      this._handleDropDownChange(v, "covid_email_template_id")
                    }
                    name="covid_email_template_id"
                    className="form-control margin-0 font-10"
                    placeholder="Select Templates"
                  >
                    <Select.Option disabled>Select Templates</Select.Option>
                    {positiveTemplates.map((n) => {
                      return (
                        <Select.Option value={`${n.id}`}>
                          {n.template_name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                )}
                {this.state.errors && (
                  <span className="validate-danger">
                    {this.state.errors.covid_email_template_id}
                  </span>
                )}
                {fileUrl && (
                  <a className="font-13" href={fileUrl} target="__blank">
                    Click here to view template
                  </a>
                )}
              </div>
            )}
          </ModalPopUp>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    reports: verifyObject(state, "reports", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    reportsActions: bindActionCreators(reportsActions, dispatch),
    actionCableActions: bindActionCreators(actionCableActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportsContainer);

export default withRouter(ConnectedComponent);

import { get, post } from "./index";


export const getAcuteMedicationsApi = (params) => {
    const request = {
        subUrl: `/medications/acute_medications.json`,
        params
    };
    return get(request);
};


export const addAcuteMedicationsApi = (data) => {
    const request = {
        subUrl: `/medications.json`,
        data
    };
    return post(request);
};


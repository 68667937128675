import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as formReducer } from "redux-form";
import { types as sessionTypes } from "reducers/session";
import {
  localStore,
  session,
  actionCableData,
  rightToWork,
  staffIntranet,
  patients,
  dynamicTab,
  medicalHistory,
  patientDetails,
  referralNotes,
  preEnccounter,
  specialNotes,
  reports,
  testInvestigations,
  familyRecords,
  nhsRepeatMedications,
  nhsAcuteMedications,
  upcomingAppointments,
  approveRejectAppointments,
  previousAppointments,
  reportsReview,
  newReportsReview,
  questionnaireReview,
  appointmentPayments,
  orderPayments,
  userProfile,
  preQuestionnaire,
  // onlineOfflineCall,
  emergencyAlert,
  safeguardAlert,
  scriptReview,
  pastOrders,
  dynamicQuestionnaireReview,
  patientQuestionnaireReview,
  symptomsChecker,
  membersContacts,
  shortCodes,
  prescriptionAbbreviation,
  consultationReview,
  preConsultationReview,
  preConsultationSubmitLogs,
  kioskPreConsult,
} from "../reducers";

const appReducer = combineReducers({
  routing: routerReducer,
  form: formReducer,
  localStore,
  session,
  actionCableData,
  rightToWork,
  staffIntranet,
  patients,
  medicalHistory,
  patientDetails,
  referralNotes,
  preEnccounter,
  specialNotes,
  reports,
  testInvestigations,
  familyRecords,
  nhsRepeatMedications,
  nhsAcuteMedications,
  upcomingAppointments,
  approveRejectAppointments,
  previousAppointments,
  reportsReview,
  newReportsReview,
  questionnaireReview,
  appointmentPayments,
  orderPayments,
  userProfile,
  preQuestionnaire,
  dynamicTab,
  shortCodes,
  // onlineOfflineCall,
  emergencyAlert,
  safeguardAlert,
  scriptReview,
  pastOrders,
  dynamicQuestionnaireReview,
  patientQuestionnaireReview,
  symptomsChecker,
  membersContacts,
  prescriptionAbbreviation,
  consultationReview,
  preConsultationReview,
  preConsultationSubmitLogs,
  kioskPreConsult,
});

const rootReducers = (state, action) => {
  if (action.type === sessionTypes.LOG_OUT) {
    return {};
  }
  return appReducer(state, action);
};
export default rootReducers;

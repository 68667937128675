import React, {Component} from "react";

import {
  Button,
  Image,
  Input,
  // Modal,
  // Radio,
  // Input,
  Select,
  Spin,
} from "antd";
// import {ROLE_CLINICAL_PHARMACIST} from "constants/common";
// import {Image} from "antd";
// import AutoTextArea from "components/AutoTextArea";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
// import NoFoundMessage from "../../components/common/NoFoundMessage";
import {
  // errorToast, getErrorObject,
  verifyObject,
} from "../../utilities/utils";
// import SymptomSearchModal from "./SymptomSearchModal";
import {bindActionCreators} from "redux";
import {onSetDynamicInput} from "../../reducers/dynamicTab";
import {isArray, isEmpty} from "lodash";
// import {fetchDiagnosisApi, fetchSymptomsApi} from "services/symptoms";
import SymmtomDiagnosisTabs from "./SymmtomDiagnosisTabs";
import ModalPopUp from "components/common/ModalPopUp";
import {PictureFilled} from "@ant-design/icons";
class AppointmentReasonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      reason_text: [],
      pageSymptom: 1,
      searchSymptom: "",
      paginationSymptom: "",
      isLoadmoreSymptom: false,
      symptomsData: [],
      searchingSymptom: false,
      is_pregnant: null,
      getDiagnosisModal: false,
      // reason_text: this.props.reason_text !== "" ? this.props.reason_text : "",
    };
  }
  componentDidMount() {
    let {dynamicInputs} = this.props;
    this.setState({
      ...dynamicInputs,
    });
  }
  // onGetSymptoms = async () => {
  //   try {
  //     this.setState({searchingSymptom: true});

  //     let response = await fetchSymptomsApi({});

  //     let newSymptomsData = verifyObject(response, "data.data", []);
  //     if (response.data.data) {
  //       this.setState({
  //         symptomsData: newSymptomsData,
  //         searchingSymptom: false,
  //       });
  //     }
  //     this.setState({searchSymptom: false});
  //   } catch (error) {
  //     const {message} = getErrorObject(error);
  //     await this.setState({searchingSymptom: false});
  //     errorToast({content: message});
  //   }
  // };
  // handleGetDiagnosis = async () => {
  //   let findName = this.state.symptomsData.filter((item) =>
  //     this.state.reason_text.includes(item.id)
  //   );
  //   if (findName) {
  //     // setFindedName(findName.map((item) => item.name).join(","));
  //     console.log("filterd names--->", findName);
  //     let payload = {
  //       patient_id: this.props.patient_id,
  //       search: findName.map((item) => item.name).join(","),
  //       symptom_id: this.state.reason_text.join(","),
  //       is_pregnant: "",
  //     };

  //     try {
  //       await this.setState({searchingDiagnose: true});

  //       let response = await fetchDiagnosisApi(payload);
  //       console.log("payload------------>", response.data.data);

  //       // next();
  //       if (response.data.data) {
  //         this.setState(
  //           {
  //             diagnosisData: response.data.data,
  //             // reason_text: this.state.reason_text,
  //           },
  //           () => {
  //             let {onSetDynamicInput} = this.props.dynamicTabAction;
  //             let {dynamicInputs} = this.props;

  //             if (typeof onSetDynamicInput === "function") {
  //               let payload = {
  //                 ...dynamicInputs,
  //                 reason_text: this.state.reason_text,
  //                 reason_text_string: findName
  //                   .map((item) => item.name)
  //                   .join(","),

  //                 diagnosisData: this.state.diagnosisData,
  //               };
  //               onSetDynamicInput(payload);
  //             }
  //           }
  //         );
  //       }

  //       await this.setState(
  //         {
  //           searchingDiagnose: false,
  //           // reason_text: findName.map((item) => item.name).join(","),
  //         },
  //         () => {
  //           let {onSetDynamicInput} = this.props.dynamicTabAction;
  //           let {dynamicInputs} = this.props;

  //           if (typeof onSetDynamicInput === "function") {
  //             let payload = {
  //               ...dynamicInputs,
  //               reason_text: this.state.reason_text,
  //               reason_text_string: findName.map((item) => item.name).join(","),

  //               diagnosisData: this.state.diagnosisData,
  //             };
  //             onSetDynamicInput(payload);
  //           }
  //         }
  //       );
  //     } catch (error) {
  //       const {message} = getErrorObject(error);
  //       await this.setState({searchingDiagnose: false});
  //       errorToast({content: message});
  //     }
  //   }
  // };
  // handleGetDiagnosisFemale = async () => {
  //   let findName = this.state.symptomsData.filter((item) =>
  //     this.state.reason_text.includes(item.id)
  //   );
  //   if (findName) {
  //     // setFindedName(findName.map((item) => item.name).join(","));
  //     console.log("filterd names--->", findName);
  //     let payload = {
  //       patient_id: this.props.patient_id,
  //       search: findName.map((item) => item.name).join(","),
  //       symptom_id: this.state.reason_text.join(","),
  //       is_pregnant: this.state.is_pregnant,
  //     };

  //     try {
  //       await this.setState({searchingDiagnose: true});

  //       let response = await fetchDiagnosisApi(payload);
  //       console.log("payload------------>", response.data.data);
  //       if (response.data.data) {
  //         this.setState(
  //           {
  //             diagnosisData: response.data.data,
  //             // reason_text: findName.map((item) => item.name).join(","),
  //             getDiagnosisModal: false,
  //           },
  //           () => {
  //             let {onSetDynamicInput} = this.props.dynamicTabAction;
  //             let {dynamicInputs} = this.props;

  //             if (typeof onSetDynamicInput === "function") {
  //               let payload = {
  //                 ...dynamicInputs,
  //                 reason_text: this.state.reason_text,
  //                 reason_text_string: findName
  //                   .map((item) => item.name)
  //                   .join(","),

  //                 diagnosisData: this.state.diagnosisData,
  //               };
  //               onSetDynamicInput(payload);
  //             }
  //           }
  //         );
  //       }

  //       await this.setState(
  //         {
  //           searchingDiagnose: false,
  //           // reason_text: findName.map((item) => item.name).join(","),
  //           getDiagnosisModal: false,
  //         },
  //         () => {
  //           let {onSetDynamicInput} = this.props.dynamicTabAction;
  //           let {dynamicInputs} = this.props;

  //           if (typeof onSetDynamicInput === "function") {
  //             let payload = {
  //               ...dynamicInputs,
  //               reason_text: this.state.reason_text,
  //               reason_text_string: findName.map((item) => item.name).join(","),

  //               diagnosisData: this.state.diagnosisData,
  //             };
  //             onSetDynamicInput(payload);
  //           }
  //         }
  //       );
  //     } catch (error) {
  //       const {message} = getErrorObject(error);
  //       await this.setState({searchingDiagnose: false});
  //       errorToast({content: message});
  //     }
  //   }
  // };
  handleClear = () => {
    this.setState({reason_text: [], diagnosisData: {}}, () => {
      let {onSetDynamicInput} = this.props.dynamicTabAction;
      let {dynamicInputs} = this.props;

      if (typeof onSetDynamicInput === "function") {
        let payload = {
          ...dynamicInputs,
          reason_text: this.state.reason_text,
          reason_text_string: "",

          diagnosisData: this.state.diagnosisData,
        };
        onSetDynamicInput(payload);
      }
    });
  };
  customAbbrivationChange = (e) => {
    this.setState(
      {
        isCustomAbbrevation: true,
        symptomsData: [
          ...this.state.symptomsData,
          {
            id: this.state.custom_text,
            name: this.state.custom_text,
          },
        ],
      },
      () => {
        this.setState({custom_text: ""});
        let {onSetDynamicInput} = this.props.dynamicTabAction;
        let {dynamicInputs} = this.props;

        if (typeof onSetDynamicInput === "function") {
          let payload = {
            ...dynamicInputs,
            reason_text: this.state.reason_text,
            reason_text_string: "",
            symptomsData: this.state.symptomsData,
            diagnosisData: this.state.diagnosisData,
          };
          onSetDynamicInput(payload);
        }
      }
    );
  };
  showpresentingModal = () => {
    this.setState({getPresentingComModal: true});
  };
  handleOk = () => {
    this.setState({getPresentingComModal: false});
  };
  handleCancel = () => {
    this.setState({getPresentingComModal: false});
    // this.setState({ getPresentingInfoModal: false });
  };
  render() {
    let {appointmentObj, isFromConsultation, reason_text, prev_appt_tab_key} =
      this.props;
    if (this.props.prev_appt_tab_key === "unfinished") {
      return (
        <div
          style={{
            marginTop: "40px",
          }}
        ></div>
      );
    } else if (appointmentObj) {
      if (
        appointmentObj.reason_image === "" &&
        appointmentObj.reason_text === "" &&
        prev_appt_tab_key === "pending"
      ) {
        return (
          <div
            style={{
              marginTop:
                isFromConsultation ||
                prev_appt_tab_key === "pending" ||
                (appointmentObj.reason_image === "" &&
                  appointmentObj.reason_text === "")
                  ? "none"
                  : "5%",
            }}
          ></div>
        );
      }
      return (
        <div className="form-group padding-10-b relative padding-45-t border-bottom reason_wrapper padding-left-10 padding-right-10">
          <div className="form-row">
            <div className="col-md-8 push-5-b d-flex align-items-center">
              <p className="font-12 weight-500 text-left text-blue in-block margin-0 text-capitalize">
                Presenting Complaint
              </p>
              {!isEmpty(this.props.appointmentObj.reason_image) && (
                <div className="pres_icon ml-3">
                  <Button
                    className="imagebtn test"
                    onClick={this.showpresentingModal}
                  >
                    <PictureFilled />
                  </Button>

                  <ModalPopUp
                    title={"Presenting Complaint"}
                    className="presentingcomp_popup"
                    visible={this.state.getPresentingComModal}
                    handleOk={this.handleOk}
                    handleCancel={this.handleCancel}
                    okText="Okay"
                    cancelText="Cancel"
                    bodyStyle={{height: 400}}
                  >
                    {this.props.appointmentObj.reason_image !== "" &&
                    this.props.appointmentObj.reason_image !== undefined ? (
                      <Image
                        src={
                          this.props.appointmentObj.reason_image !== ""
                            ? this.props.appointmentObj.reason_image
                            : ""
                        }
                        className="cursor-pointer"
                        alt="Patient"
                        width="100%"
                      ></Image>
                    ) : (
                      <img
                        src={
                          require("../../assets/images/common/img-placeholder.png")
                            .default
                        }
                        alt="Patient"
                        className="cursor-pointer"
                      ></img>
                    )}
                  </ModalPopUp>
                </div>
              )}
            </div>
            <div className="col-md-12">
              {/* <div className="in-block width-60px vertical-top reasonimg"> */}

              {/* {this.props.appointmentObj.reason_image !== "" &&
                this.props.appointmentObj.reason_image !== undefined ? (
                  <Image
                    src={
                      this.props.appointmentObj.reason_image !== ""
                        ? this.props.appointmentObj.reason_image
                        : ""
                    }
                    className="cursor-pointer rounded-circle"
                    alt="Patient"
                    height="50"
                    width="50"
                  ></Image>
                ) : (
                  <img
                    src={
                      require("../../assets/images/common/img-placeholder.png")
                        .default
                    }
                    alt="Patient"
                    className="cursor-pointer rounded-circle"
                    height="50"
                    width="50"
                  ></img>
                )} */}
              {/* </div> */}
              <div className="in-block customblock vertical-top">
                {this.props.appointmentObj.reason_text !== "" &&
                !this.props.fromOngoingConsultation ? (
                  <p className="font-11 weight-400 text-left margin-0 text-dark-gray ">
                    {this.props &&
                      this.props.appointmentObj &&
                      this.props.appointmentObj.reason_text &&
                      this.props.appointmentObj.reason_text
                        .split("\n")
                        .map((text) => {
                          return <p>{text}</p>;
                        })}
                  </p>
                ) : this.props.fromOngoingConsultation &&
                  !this.props.is_from_patient_search_listing &&
                  this.props.appointmentObj.reason_text !== "" ? (
                  <p className="font-11 weight-400 text-left margin-0 text-dark-gray ">
                    {this.props &&
                      this.props.appointmentObj &&
                      this.props.appointmentObj.reason_text &&
                      this.props.appointmentObj.reason_text
                        .split("\n")
                        .map((text) => {
                          return <p>{text}</p>;
                        })}
                  </p>
                ) : this.props.fromOngoingConsultation ? (
                  // <Input.TextArea
                  //   autoSize={true}
                  //   type="text"
                  //   className={`form-control-textarea input-bg-f9f9f9 form-control h-10 custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus min-height-50`}
                  //   name="reason_text"
                  //   value={this.props.reason_text}
                  //   onChange={this.props._handleTextChange}
                  //   placeholder="Write presenting complaint here"
                  // />
                  <div className="row">
                    <div className="col-md-8">
                      <Select
                        disabled={this.state.searchingSymptom}
                        // loading={this.state.searchingSymptom}
                        showSearch
                        name="receiver_id"
                        id="scrollableDiv"
                        //   onPopupScroll={handleScroll}
                        className={`form-control-textarea input-bg-f9f9f9 form-control h-10 custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus min-height-50`}
                        placeholder="Search and Select complaint"
                        optionFilterProp="children"
                        value={
                          this.state.searchingSymptom
                            ? []
                            : this.state.reason_text
                        }
                        // allowClear={this.state.searchingSymptom ? false : true}
                        onChange={(values) =>
                          // handleSelectChange(value, "patient_id")
                          this.setState({reason_text: values})
                        }
                        mode="multiple"
                        // onSearch={(value) => {
                        //   let searchValue = value;
                        //   this.searchUpdate(searchValue);
                        // }}
                        listItemHeight={10}
                        listHeight={150}
                        suffixIcon={
                          !this.state.isLoadmoreSymptom &&
                          this.state.searchingSymptom && <Spin size="small" />
                        }
                        //   notFoundContent={
                        //     !state.searchingSymptom &&
                        //     isArray(state.symptomsData) &&
                        //     state.symptomsData.length === 0 && (
                        //       <span>No symptoms available</span>
                        //     )
                        //   }
                        notFoundContent={
                          this.state.searchingSymptom ? (
                            <Spin size="small" />
                          ) : null
                        }
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        dropdownRender={(menu) => (
                          <div>
                            {menu}
                            {/* <Divider style={{ margin: "4px 0" }} /> */}
                            <div
                              style={{
                                display: "flex",
                                flexWrap: "nowrap",
                                padding: 8,
                              }}
                            >
                              <Input
                                style={{flex: "auto"}}
                                value={`${
                                  this.state.custom_text
                                    ? this.state.custom_text
                                    : ""
                                }`}
                                name={"custom_text"}
                                placeholder="Add custom abbreviation here"
                                // onClick={this.customAbbrivationChange}
                                // readOnly
                                onChange={(e) =>
                                  this.setState({
                                    custom_text: e.target.value,
                                  })
                                }
                              />
                              <Button onClick={this.customAbbrivationChange}>
                                {" "}
                                Add Abbrevation
                              </Button>
                            </div>
                          </div>
                        )}
                      >
                        {this.state.symptomsData &&
                          isArray(this.state.symptomsData) &&
                          this.state.symptomsData.map((d, i) => (
                            <Select.Option key={i} value={d.id}>
                              {d.name}
                              {/* (
                                {d.role_name}) */}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>
                    <div className="col-md-4">
                      {this.state.diagnosisData &&
                        !isEmpty(this.state.diagnosisData) && (
                          // this.state.diagnosisData.length > 0 &&
                          <SymmtomDiagnosisTabs
                            gender={this.props.gender}
                            diagnosisData={this.state.diagnosisData}
                          />
                        )}
                    </div>
                  </div>
                ) : (
                  <p className="font-11 weight-400 text-left margin-0 text-dark-gray ">
                    No reason provided
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <p className="font-11 weight-400 text-left margin-0 text-dark-gray ">
          No reason provided
        </p>
      );
    }
    // }
  }
}

const mapStateProps = (state) => {
  return {
    isWaitingPatient: verifyObject(
      state.actionCableData,
      "WaitingRoomSocketData.isLoading",
      false
    ),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    userRole: verifyObject(state, "localStore.user.role", null),
    is_from_patient_search_listing: verifyObject(
      state.localStore,
      "is_from_patient_search_listing",
      null
    ),
    gender: verifyObject(state.localStore, "patient_detail_obj.gender", ""),
    patient_id: verifyObject(
      state.localStore,
      "patient_detail_obj.user.id",
      ""
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    reason_text: verifyObject(
      state,
      "dynamicTab.dynamicInputs.reason_text",
      ""
    ),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {
        onSetDynamicInput,
      },
      dispatch
    ),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(AppointmentReasonContainer);

export default withRouter(ConnectedComponent);

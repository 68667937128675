/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, {Fragment} from "react";
import {verifyObject} from "../../../utilities/utils";
import photo_id from "assets/images/profile_header/id-card.svg";
import location from "assets/images/profile_header/ic-location.svg";
import {Ethnicity, GenderPreference} from "constants/common";

export default function PatientDetailHeader({
  patientDetail,
  openPhotoID,
  openLocation,
}) {
  if (!patientDetail) {
    return <span>No Data Found</span>;
  }
  const findEthnicity = Ethnicity.find(
    (eth) => eth.value === verifyObject(patientDetail, "ethnicity")
  );
  const findGenderPreference = GenderPreference.find(
    (pre) => pre.value === verifyObject(patientDetail, "gender_preference")
  );
  return (
    <div>
      <div className="collapseprofiledetail">
        <div className="font-12 weight-400 text-dark-gray patient_dropdown_data">
          <div className="patient_dropdown_inner">
            <span className="text-capitalize">
              <span className="text-black weight-300">ID :</span>{" "}
              {verifyObject(patientDetail, "id", "-")}
            </span>
          </div>
          <div className="patient_dropdown_inner">
            <span className="">
              <span className="text-black weight-300">Email :</span>{" "}
              {verifyObject(patientDetail, "email", "-")}
            </span>
          </div>
          <div className="patient_dropdown_inner">
            <span className="text-capitalize">
              <span className="text-black weight-300">Mobile :</span>{" "}
              {verifyObject(patientDetail, "phone", "-")}
            </span>
          </div>
          <div className="patient_dropdown_inner">
            {verifyObject(patientDetail, "height_value1", null) !== null &&
              verifyObject(patientDetail, "height_value2", null) !== null && (
                <Fragment>
                  <span className="text-capitalize">
                    <span className="text-black weight-300">Height :</span>{" "}
                    {patientDetail.height_value1} {patientDetail.height_value2}
                  </span>
                </Fragment>
              )}
          </div>
          <div className="patient_dropdown_inner">
            {verifyObject(patientDetail, "weight_value1", null) !== null &&
              verifyObject(patientDetail, "weight_value1", null) !== "" &&
              verifyObject(patientDetail, "weight_value2", null) !== null &&
              verifyObject(patientDetail, "weight_value2", null) !== "" && (
                <Fragment>
                  <span className="text-capitalize">
                    <span className="text-black weight-300">Weight :</span>{" "}
                    {patientDetail.weight_value1} {patientDetail.weight_value2}
                  </span>
                </Fragment>
              )}

            {verifyObject(patientDetail, "weight_category", null) !== null &&
              patientDetail.weight_category === "metric" &&
              verifyObject(patientDetail, "weight_value1", null) !== null &&
              verifyObject(patientDetail, "weight_value1", null) !== "" && (
                <Fragment>
                  <span className="text-capitalize">
                    <span className="text-black weight-300">Weight :</span>{" "}
                    {patientDetail.weight_value1}
                  </span>
                </Fragment>
              )}
          </div>
          <div className="patient_dropdown_inner">
            {verifyObject(patientDetail, "bmi", null) !== null && (
              <Fragment>
                <span className="text-capitalize">
                  <span className="text-black weight-300">BMI :</span>{" "}
                  {patientDetail.bmi}
                </span>
              </Fragment>
            )}
          </div>

          <div className="patient_dropdown_inner">
            {verifyObject(patientDetail, "smoke", null) !== null && (
              <Fragment>
                <span className="text-capitalize">
                  <span className="text-black weight-300">Smoke :</span>{" "}
                  {patientDetail.smoke}
                  {verifyObject(patientDetail, "smoke", null) !== "No smoker"}
                </span>
              </Fragment>
            )}
          </div>
          <div className="patient_dropdown_inner">
            {verifyObject(patientDetail, "alcohol", null) !== null && (
              <Fragment>
                <span className="text-capitalize">
                  <span className="text-black weight-300">Alcohol :</span>{" "}
                  {patientDetail.alcohol}{" "}
                  {verifyObject(patientDetail, "alcohol", null) !==
                    "No alcoholic"}
                </span>
              </Fragment>
            )}
          </div>
          <div className="patient_dropdown_inner">
            {
              <Fragment>
                <span className="text-capitalize">
                  <span className="text-black weight-300">
                    Gender Preference :{" "}
                    {`${
                      findGenderPreference ? findGenderPreference.label : "-"
                    }`}
                  </span>{" "}
                </span>
              </Fragment>
            }
          </div>
          <div className="patient_dropdown_inner">
            {
              <Fragment>
                <span className="text-capitalize">
                  <span className="text-black weight-300">
                    Ethnicity : {`${findEthnicity ? findEthnicity.label : "-"}`}
                  </span>{" "}
                </span>
              </Fragment>
            }
          </div>
        </div>

        <div className="patient_dropdown_dataright">
          {openPhotoID && (
            <div className="in-block">
              <a
                onClick={openPhotoID}
                className="text-black weight-400 text-capitalize photo_id font-12 cursor-pointer hover-blue"
              >
                <img alt="Photo ID" title="Photo ID" src={photo_id}></img> Photo
                ID
              </a>
            </div>
          )}
          {openLocation && (
            <div className="in-block margin-left-30">
              <a
                onClick={openLocation}
                className="text-black weight-400 text-capitalize location_id font-12 cursor-pointer hover-blue"
              >
                <img alt="Location" title="Location" src={location}></img>{" "}
                Location
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Modal, Select, Input, Tabs, Spin} from "antd";
import {reviewScript} from "../../services/orders";
import {actions as preQuestionnaireActions} from "../../reducers/preQuestionnaire";
import {
  getErrorObject,
  successToast,
  errorToast,
  verifyObject,
} from "../../utilities/utils";
import {withRouter} from "react-router-dom";
import {debounce, isEmpty} from "lodash";
import Highlighter from "react-highlight-words";
import {appRoutesConst} from "../../app/navigation";
import {
  getAnsweredQuestions,
  pageBlocksForScriptReview,
} from "../../services/scriptReview";
import CommonLoader from "../../components/common/CommonLoader";
import CoreQuestions from "./CoreQuestions";
import {createCustomAbbrivations, getAbbrivations} from "services/drugs";
import {getShortCodesAPI} from "services/shortCodes";
const {Option} = Select;
const {TabPane} = Tabs;

class ScriptReviewContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      modalOpen: false,
      radio: null,
      select: null,
      selectedOptions: [],
      doc_link: null,
      comment: null,
      questionnaire_id: null,
      dosage_description: "",
      reason: "",
      doc_template: null,
      showApprove: false,
      showDecline: false,
      templates: [],
      questionAnswerArray: [],
      options: {
        approved: [],
        rejected: [],
      },
      isPageBlocked: false,
      searchAbbrevation: "",
      abbrevationsArray: [],
      searchingAbbrevation: false,
      abbrevationId: null,
      selectedAbbrevationsValue: null,
      total_quantity_per_day: null,
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_onChange",
      "_onChangeText",
      // "_handleTextChange",
      // "_getAbbrivations",
      // "_handleAbbrevationSearch",
      // "_handleAbbrevationChange",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.searchAbbrevationsnUpdate = debounce(
      this._handleAbbrevationSearch,
      1000
    );

    this.dosageDescriptionRef = React.createRef();
    this.noofdaysRef = React.createRef();
  }

  async componentDidMount() {
    await this._onRequestList();
  }

  async _onRequestList() {
    this._getQuestionsAnswer();
  }

  async _getTemplates() {
    try {
      let response = await getShortCodesAPI({
        search: this.state.search,
      });

      let templates = verifyObject(response, "data.data", []);

      this.setState({templates});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }

  async _handleSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          searching: true,
          templates: [],
          search: value,
        },
        async () => {
          this._getTemplates();
        }
      );
    } else {
      await this.setState({templates: []});
    }
  }

  _onPageChange(data) {
    let {
      patientId,
      preQuestionnaireActions: {onPageChange},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, patient_id: patientId});
    }
  }

  showDecline = () => {
    this.setState({
      showDecline: true,
      showApprove: false,
      dosage_description: "",
      doc_template: null,
      reason: "",
    });
  };

  showApprove = () => {
    this.setState({showApprove: true});
  };

  _onChangeText = (e, inputNumber) => {
    if (e.target.value.includes("e") && inputNumber) {
      e.preventDefault();
    } else {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
  };
  _handleAbbrevationChange = (value, key) => {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }
    if (this.noofdaysRef && this.noofdaysRef.current) {
      this.noofdaysRef.current.focus();
    }

    // eslint-disable-next-line eqeqeq
    if (value) {
      // eslint-disable-next-line eqeqeq
      let found = this.state.abbrevationsArray.find((d) => d.id == value);
      // console.log("FOUND", found);
      this.setState(
        {
          abbrevationId: value,
          selectedAbbrevationsValue: found,
          total_quantity_per_day: found ? found.total_quantity_per_day : 0,
          dosage_description: found ? found.meaning : 0,
          // dosage_reason: found ? found.meaning : "",
          // per_day:this.state.s
        },
        () => {
          if (this.state.no_of_days) {
            this.setState({
              total_qty_supply: parseInt(
                this.state.no_of_days * this.state.total_quantity_per_day
              ),
            });
          }
        }
      );
    } else {
      if (this.noofdaysRef && this.noofdaysRef.current) {
        this.noofdaysRef.current.focus();
      }
      this.setState({
        abbrevationId: null,
        selectedAbbrevationsValue: null,
        total_quantity_per_day: null,
        dosage_description: null,
      });
    }
  };
  _handleAbbrevationSearch = async (value) => {
    if (!isEmpty(value)) {
      // this.setState(
      //   {
      //     searchAbbrevation: value,
      //   },
      //   async () => {
      this._getAbbrivations(value);
      //   }
      // );
    }
    //  else {
    //   this.setState({abbrevationsArray: []});
    // }
  };
  _getAbbrivations = async (value) => {
    try {
      await this.setState({searchingAbbrevation: true});
      let response = await getAbbrivations({
        term: value,
        // `${this.state.searchAbbrevation}`,
      });

      let abbrevationsArray = verifyObject(response, "data.data", []);
      this.setState(
        {
          abbrevationsArray: abbrevationsArray,
          searchAbbrevation: value,
          searchingAbbrevation: false,
        },
        () => {
          if (
            verifyObject(response, "data.data", []) &&
            verifyObject(response, "data.data", []).length === 1
          ) {
            if (
              this.dosageDescriptionRef.current &&
              this.dosageDescriptionRef.current
            ) {
              this.dosageDescriptionRef.current.blur();
            }

            if (this.noofdaysRef.current) {
              this.noofdaysRef.current.focus();
            }
            // this._handleAbbrevationChange(
            //   verifyObject(response, "data.data", [])[0].id,
            //   "abbrevationId"
            // );
            // this.setState({
            //   no_of_days: null,
            //   total_qty_supply: null,
            //   packSizeArray: [],
            //   packSizeId: null,
            // });
            this.setState(
              {
                searchAbbrevation: "",
                abbrevationId: verifyObject(response, "data.data", [])[0].id,
                selectedAbbrevationsValue: verifyObject(
                  response,
                  "data.data",
                  []
                )[0],
                total_quantity_per_day: verifyObject(
                  response,
                  "data.data",
                  []
                )[0]
                  ? verifyObject(response, "data.data", [])[0]
                      .total_quantity_per_day
                  : 0,
                dosage_description: verifyObject(response, "data.data", [])[0]
                  ? verifyObject(response, "data.data", [])[0].meaning
                  : 0,
                // per_day:this.state.s
              },
              () => {
                if (this.state.no_of_days) {
                  this.setState({
                    total_qty_supply: parseInt(
                      this.state.no_of_days * this.state.total_quantity_per_day
                    ),
                  });
                }
                if (this.noofdaysRef && this.noofdaysRef.current) {
                  this.noofdaysRef.current.focus();
                }
                if (this.state.abbrevationsArray.length !== 0) {
                  this.setState({isCustomAbbrevation: false});
                }
              }
            );
          } else if (verifyObject(response, "data.data", []).length === 0) {
            this.setState({
              abbrevationId: null,
              searchingAbbrevation: false,
              searchAbbrevation: value,
              abbrevationsArray: [],
            });
          }
        }
      );
    } catch (error) {
      // const { message } = getErrorObject(error);
      await this.setState({
        searchingAbbrevation: false,
        abbrevationsArray: [],
      });
      // errorToast({ content: message });
    }
  };
  _onChange(e) {
    this.setState({
      radio: e.target.value,
      select: null,
      doc_link: null,
      selectedOptions: this.state.options[e.target.value],
    });
  }
  _getQuestionsAnswer = async () => {
    console.log(
      "pastCoreDrugId-------------------->",
      this.props.pastCoreDrugId,
      localStorage.getItem("script_review_obj")
    );
    try {
      let scriptReviewData =
        localStorage.getItem("script_review_obj") !== "undefined"
          ? JSON.parse(localStorage.getItem("script_review_obj"))
          : false;
      if (scriptReviewData) {
        let payload = {
          user_id:
            verifyObject(scriptReviewData, "patient_id", null) !== null
              ? scriptReviewData.patient_id
              : scriptReviewData.user_id,
          order_id:
            this.props.pastOrderId !== undefined
              ? this.props.pastOrderId
              : verifyObject(scriptReviewData, "order_id", null),
          core_drug_id:
            this.props.pastCoreDrugId !== undefined
              ? this.props.pastCoreDrugId
              : verifyObject(scriptReviewData, "order_item.core_drug_id", null),
        };
        await this.setState({isLoading: true});
        let response = await getAnsweredQuestions(payload);
        await this.setState({
          questionAnswerArray: verifyObject(response, "data.data", []),
          isLoading: false,
        });
      } else if (this.props.pastCoreDrugId) {
        let payload = {
          user_id: this.props.patientId,
          order_id: this.props.pastOrderId,
          core_drug_id: this.props.pastCoreDrugId,
        };
        await this.setState({isLoading: true});
        let response = await getAnsweredQuestions(payload);
        await this.setState({
          questionAnswerArray: verifyObject(response, "data.data", []),
          isLoading: false,
        });
      } else {
        errorToast({content: "Script data not intilized"});
        await this.setState({
          isLoading: false,
        });
      }
    } catch (error) {
      console.log("Error", getErrorObject(error));
      const {
        data: {status},
      } = error;

      const {message} = getErrorObject(error);
      await this.setState({searching: false, isLoading: false});
      errorToast({content: message});
      if (status && status === 801) {
        this.setState({isPageBlocked: true});
        this.props.history.push(appRoutesConst.scriptReview);
      }
    }
  };

  _pageBlocks = async () => {
    try {
      await pageBlocksForScriptReview({
        type: "order_questionnaire_review",
      });

      // successToast({
      //   content: verifyObject(response, "data.message", "Success"),
      // });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  };

  componentWillUnmount() {
    if (!this.state.isPageBlocked) {
      let status = localStorage.getItem("scriptReviewStatus");
      if (
        status === "new" &&
        window.location.pathname !== "/doctor/script_review/detail"
      ) {
        this._pageBlocks();
      }
    }
  }

  approveScript = async () => {
    let {recordId} = this.props;

    let reqObj = {
      doc_template: {id: "", text: this.state.reason},
      dosage_description: this.state.dosage_description.trim(),
      reason: this.state.reason,
      id: recordId,
      status: "doctor_approved",
    };
    try {
      this.setState({
        isLoading: true,
      });
      let response = await reviewScript(reqObj);
      this.setState({
        showApprove: false,
        dosage_description: "",
        doc_template: null,
        reason: "",
      });
      successToast({content: response.data.message});
      this.props.history.push(appRoutesConst.scriptReview);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      let err = getErrorObject(error);
      errorToast({content: err.message});
    }
  };

  declineScript = async () => {
    let {recordId} = this.props;
    let reqObj = {
      status: "doctor_declined",
      reason: this.state.reason.trim(),
      id: recordId,
    };
    try {
      this.setState({
        isLoading: true,
      });
      let response = await reviewScript(reqObj);
      this.setState({
        showApprove: false,
        dosage_description: "",
        doc_template: null,
        reason: "",
      });
      successToast({content: response.data.message});
      this.props.history.push(appRoutesConst.scriptReview);
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      let err = getErrorObject(error);
      errorToast({content: err.message});
    }
  };

  _handleDropDownChange(value, key) {
    // eslint-disable-next-line eqeqeq
    let found = this.state.templates.find((d) => d.id == value);
    let text;
    if (found) {
      text = found.description;
    }
    console.log("value", value);
    this.setState({[key]: value ? value : "", reason: text});
  }
  //abbrevation changes
  customAbbrivationChange = (e) => {
    this.setState({
      isCustomAbbrevation: true,
      abbreviation: this.state.searchAbbrevation,
    });
  };
  handleCustomAbbreviationSubmit = async () => {
    let {
      // abbreviation,
      searchAbbrevation,
      meaning,
      numeric_meaning,
    } = this.state;
    try {
      let formData = new FormData();

      formData.set("prescription_abbrevation[abbreviation]", searchAbbrevation);
      formData.set("prescription_abbrevation[meaning]", meaning);
      formData.set(
        "prescription_abbrevation[numeric_meaning]",
        numeric_meaning
      );

      this.setState({isLoadingCustomAbbreviation: true});
      let response = await createCustomAbbrivations(formData);
      successToast({content: response.data.message});
      this.setState({isLoadingCustomAbbreviation: false}, () => {
        let newState = this.state;
        Object.keys(newState).map((key) => {
          if (key === "searchAbbrevation") delete newState["searchAbbrevation"];
          if (key === "meaning") delete newState["meaning"];
          if (key === "numeric_meaning") delete newState["numeric_meaning"];
        });
        this.setState(
          {
            ...newState,
            isCustomAbbrevation: false,
            abbrevationsArray: [
              verifyObject(response, "data.data.prescription_abbreviation", []),
            ],
            abbrevationId: verifyObject(
              response,
              "data.data.prescription_abbreviation.id",
              null
            ),
            selectedAbbrevationsValue: verifyObject(
              response,
              "data.data.prescription_abbreviation",
              null
            ),
            total_quantity_per_day: verifyObject(
              response,
              "data.data.prescription_abbreviation.total_quantity_per_day",
              null
            )
              ? verifyObject(
                  response,
                  "data.data.prescription_abbreviation.total_quantity_per_day",
                  null
                )
              : 0,
            dosage_description: verifyObject(
              response,
              "data.data.prescription_abbreviation.meaning",
              null
            )
              ? verifyObject(
                  response,
                  "data.data.prescription_abbreviation.meaning",
                  null
                )
              : 0,
            searchAbbrevation: "",
          },
          () => {
            if (this.noofdaysRef && this.noofdaysRef.current) {
              this.noofdaysRef.current.focus();
            }
          }
        );
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({isLoadingCustomAbbreviation: false});

      errorToast({content: message});
    }
  };
  handleCancelCustomAbbreviation = () => {
    let newState = this.state;
    Object.keys(newState).map((key) => {
      if (key === "searchAbbrevation") delete newState["searchAbbrevation"];
      if (key === "meaning") delete newState["meaning"];
      if (key === "numeric_meaning") delete newState["numeric_meaning"];
    });
    this.setState({
      ...newState,
      isCustomAbbrevation: false,
      searchAbbrevation: "",
      abbrevationsArray: [],
      abbrevationId: null,
    });
  };
  render() {
    let {
      searching,
      templates,
      questionAnswerArray,
      isLoading,
      meaning,
      // abbreviation,
      searchAbbrevation,
      numeric_meaning,
    } = this.state;
    console.log(
      "condition values--->",
      this.state.dosage_description,
      this.state.reason,
      isEmpty(this.state.dosage_description) ||
        isEmpty(this.state.reason) ||
        /^(?=.*\S).+$/.test(this.state.reason) === false ||
        /^(?=.*\S).+$/.test(this.state.dosage_description) === false
    );
    let scriptReviewStatus = localStorage.getItem("scriptReviewStatus");
    let customAbbreviationButtonConsdition =
      meaning &&
      meaning !== "" &&
      meaning.trim().length !== 0 &&
      searchAbbrevation &&
      searchAbbrevation !== "" &&
      searchAbbrevation.trim().length !== 0 &&
      numeric_meaning &&
      numeric_meaning !== "" &&
      numeric_meaning.trim().length !== 0;
    return (
      <Fragment>
        <div className="push-30-b">
          <Tabs defaultActiveKey="1">
            <TabPane tab="Part 1" key="1">
              <div className="scriptreviewquestionaireinner">
                {!isLoading &&
                  questionAnswerArray &&
                  questionAnswerArray.core_questions &&
                  questionAnswerArray.core_questions.length === 0 && (
                    <p
                      className="font-12 weight-400 text-dark-blue"
                      ng-if="!$ctrl.reviewObj.is_loading && !$ctrl.reviewObj.questionnaire_list_obj.current_array.length"
                    >
                      No questions answered
                    </p>
                  )}
                {isLoading && <CommonLoader />}
                {questionAnswerArray &&
                  questionAnswerArray.core_questions &&
                  questionAnswerArray.core_questions.length !== 0 && (
                    <CoreQuestions
                      questionAnswerArray={questionAnswerArray.core_questions}
                      isLoading={isLoading}
                    />
                  )}
              </div>
            </TabPane>
            <TabPane tab="Part 2" key="2">
              {!isLoading &&
                questionAnswerArray &&
                questionAnswerArray.conditional_questions &&
                questionAnswerArray.conditional_questions.length === 0 && (
                  <p
                    className="font-12 weight-400 text-dark-blue"
                    ng-if="!$ctrl.reviewObj.is_loading && !$ctrl.reviewObj.questionnaire_list_obj.current_array.length"
                  >
                    No questions answered
                  </p>
                )}
              {questionAnswerArray &&
                questionAnswerArray.conditional_questions &&
                questionAnswerArray.conditional_questions.length !== 0 && (
                  <CoreQuestions
                    questionAnswerArray={
                      questionAnswerArray.conditional_questions[0]
                    }
                    isLoading={isLoading}
                  />
                )}
            </TabPane>
          </Tabs>
          <div className="push-20-t push-20-b">
            {!isLoading &&
              !this.props.pastOrderId &&
              questionAnswerArray &&
              Object.keys(questionAnswerArray).length !== 0 &&
              scriptReviewStatus !== "doctor_approved" &&
              scriptReviewStatus !== "doctor_declined" && (
                <div className="form-row">
                  <div className="col-md-12">
                    {/* <Button className="btn-custom btn-blue height-30 font-14 weight-500 width-136 push-10-r">
                      Query
                    </Button> */}
                    <Button
                      className="btn-custom btn-danger font-14 weight-500 width-136 push-10-r"
                      onClick={this.showDecline}
                    >
                      Decline
                    </Button>
                    <Button
                      className="btn-custom btn-green font-14 weight-500 width-136 push-10-r"
                      onClick={this.showApprove}
                    >
                      Approve
                    </Button>
                  </div>
                </div>
              )}
            <div className="col-md-12">
              {/* <Pagination
                data={this.props.preQuestionnaire}
                onPageChange={this._onPageChange}
              /> */}
            </div>
          </div>
        </div>
        {/* // )} */}

        <Modal
          visible={this.state.showApprove}
          onCancel={() => {
            this.setState({
              showApprove: false,
              dosage_description: "",
              abbrevationsArray: [],
              searchAbbrevation: "",
              reason: "",
              abbrevationId: null,
            });
          }}
          title="Approve Note"
          footer={null}
          maskClosable={false}
          destroyOnClose={true}
        >
          <div className="comment">
            <label htmlFor="sel1">Select Template</label>
            <Select
              className={`form-control margin-0 placeholder-10 custom-input font-11 weight-400 input-sm-28 input-bg-f9f9f9`}
              name="snomedCodeId"
              autoFocus
              id="scrollableDiv"
              onPopupScroll={this.handleScroll}
              placeholder="Select Template"
              allowClear={searching ? false : true}
              value={this.state.doc_template}
              showSearch={true}
              onChange={(value) =>
                this._handleDropDownChange(value, "doc_template")
              }
              onSearch={(value) => {
                let searchValue = value;
                this.searchUpdate(searchValue);
              }}
              listItemHeight={10}
              listHeight={150}
              filterOption={false}
            >
              {templates.map((k) => {
                return (
                  <Option value={k.id}>
                    <Highlighter
                      highlightClassName="search-highlighter"
                      searchWords={[this.state.search]}
                      autoEscape={true}
                      textToHighlight={
                        verifyObject(k, "description", null) !== null
                          ? `${k.code}-${k.description}`
                          : `-`
                      }
                    />
                  </Option>
                );
              })}
            </Select>
          </div>
          <div className="comment mt-3">
            <label htmlFor="comment" className="font-12">
              Approve Note
            </label>
            <Input
              type="text"
              value={this.state.reason}
              name="reason"
              className="d-block placeholder-10 custom-input font-11 weight-400 input-sm-28 input-bg-f9f9f9"
              placeholder="Enter Accept Note"
              onChange={this._onChangeText}
            />
          </div>
          <div className="comment mt-3">
            <label htmlFor="comment" className="font-12">
              Search Dosage Description
            </label>
            {/* <Input
              type="text"
              value={this.state.dosage_description}
              className="d-block placeholder-10 custom-input font-11 weight-400 input-sm-28 input-bg-f9f9f9"
              name="dosage_description"
              placeholder="Enter Instructions"
              onChange={this._onChangeText}
            /> */}
            <Select
              className="d-block placeholder-10 custom-input font-11 weight-400 input-sm-28 input-bg-f9f9f9"
              name="dosage_description"
              ref={this.dosageDescriptionRef}
              placeholder="Enter Instructions"
              allowClear={!this.state.searchingAbbrevation ? true : false}
              disabled={this.state.drugId === null}
              value={this.state.abbrevationId}
              showSearch={true}
              dropdownClassName="drop_name"
              onChange={(value) =>
                this._handleAbbrevationChange(value, "abbrevationId")
              }
              onSearch={(value) => {
                let searchValue = value;
                this.searchAbbrevationsnUpdate(searchValue);
              }}
              suffixIcon={
                this.state.searchingAbbrevation && <Spin size="small" />
              }
              notFoundContent={<span> {"  "}</span>}
              // notFoundContent={
              //   !searchingAbbrevation &&
              //   abbrevationsArray.length === 0 && (
              //     <span>No dosage description available</span>
              //   )
              // }
              filterOption={false}
              dropdownRender={(menu) => (
                <div>
                  {this.state.abbrevationsArray.length !== 0 &&
                    this.state.abbrevationsArray.length > 1 &&
                    menu}

                  {/* <Divider style={{ margin: "4px 0" }} /> */}
                  {!isEmpty(this.state.searchAbbrevation) &&
                    !this.state.searchingAbbrevation &&
                    this.state.abbrevationsArray.length === 0 && (
                      <div className="abbreviaiondrop">
                        <h6>Add Custom Abbreviation</h6>
                        <div className="group">
                          <div className="form-row">
                            <div className="col-md-6">
                              <label htmlFor="sel1">
                                Abbreviation (short text)
                              </label>
                              <Input
                                className="form-control margin-0 placeholder-10 addcomment"
                                name="searchAbbrevation"
                                value={this.state.searchAbbrevation}
                                onChange={(e) => this._onChangeText(e, false)}
                                // disabled={isEmpty(dosage_description)}
                                placeholder="Enter Abbreviation"
                                autoFocus
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="sel1">Quantity</label>
                              <Input
                                className="form-control margin-0 placeholder-10 addcomment"
                                name="numeric_meaning"
                                value={this.state.numeric_meaning}
                                onChange={(e) => this._onChangeText(e, true)}
                                // disabled={isEmpty(dosage_description)}
                                placeholder="Enter Quantity"
                                type="number"
                              />
                            </div>
                            <div className="col-md-12 mt-2">
                              <label htmlFor="sel1">Full text</label>
                              <Input
                                className="form-control margin-0 placeholder-10 addcomment"
                                name="meaning"
                                value={this.state.meaning}
                                onChange={(e) => this._onChangeText(e, false)}
                                // disabled={isEmpty(dosage_description)}
                                placeholder="Enter Full text"
                              />
                            </div>
                            <div className="col-md-12 mt-2 d-flex abberbtn">
                              <div className="">
                                <Button
                                  type="primary"
                                  onClick={this.handleCustomAbbreviationSubmit}
                                  disabled={
                                    !customAbbreviationButtonConsdition ||
                                    this.state.isLoadingCustomAbbreviation
                                  }
                                  loading={
                                    this.state.isLoadingCustomAbbreviation
                                  }
                                >
                                  Add
                                </Button>
                              </div>
                              <div className="ml-2">
                                <Button
                                  onClick={this.handleCancelCustomAbbreviation}
                                >
                                  Cancel
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              )}
            >
              {this.state.abbrevationsArray.map((k) => {
                return (
                  <Option value={k.id}>
                    <Highlighter
                      highlightClassName="search-highlighter"
                      searchWords={[this.state.searchAbbrevation]}
                      autoEscape={true}
                      textToHighlight={
                        verifyObject(k, "meaning", null) !== null
                          ? `${k.meaning}`
                          : `-`
                      }
                    />
                  </Option>
                );
              })}
            </Select>
          </div>
          {/* <div className="comment mt-3">
            <label htmlFor="comment" className="font-12">
              Dosage Note
            </label>
            <Input
              type="text"
              value={this.state.dosage_reason}
              name="dosage_reason"
              className="d-block placeholder-10 custom-input font-11 weight-400 input-sm-28 input-bg-f9f9f9"
              placeholder="Enter Accept Note"
              onChange={this._onChangeText}
            />
          </div> */}

          <div className="push-10-t">
            <Button
              onClick={this.approveScript}
              disabled={
                isEmpty(this.state.dosage_description) ||
                isEmpty(this.state.reason) ||
                /^(?=.*\S).+$/.test(this.state.reason) === false ||
                /^(?=.*\S).+$/.test(this.state.dosage_description) === false
              }
              loading={this.state.isLoading}
              className="btn btn-custom btn-green font-14 weight-400"
            >
              Approve
            </Button>
          </div>
          {/* {this.state.isCustomAbbrevation && (
            <Modal
              visible={this.state.isCustomAbbrevation}
              title="Add custom abbreviation"
              onOk={this.handleCustomAbbreviationSubmit}
              onCancel={this.handleCancelCustomAbbreviation}
              okButtonProps={{
                disabled:
                  !customAbbreviationButtonConsdition ||
                  this.state.isLoadingCustomAbbreviation,
                loading: this.state.isLoadingCustomAbbreviation,
              }}
              okText="Add"
              destroyOnClose={true}
            >
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-12">
                    <label htmlFor="sel1">Abbreviation</label>
                    <Input
                      className="form-control margin-0 placeholder-10 addcomment"
                      name="abbreviation"
                      value={this.state.abbreviation}
                      onChange={(e) => this._onChangeText(e, false)}
                      // disabled={isEmpty(dosage_description)}
                      placeholder="Enter Abbreviation"
                      autoFocus
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="sel1">Full text (short text)</label>
                    <Input
                      className="form-control margin-0 placeholder-10 addcomment"
                      name="meaning"
                      value={this.state.meaning}
                      onChange={(e) => this._onChangeText(e, false)}
                      // disabled={isEmpty(dosage_description)}
                      placeholder="Enter Full text (short text)"
                    />
                  </div>
                  <div className="col-md-12 mt-2">
                    <label htmlFor="sel1">Quantity</label>
                    <Input
                      className="form-control margin-0 placeholder-10 addcomment"
                      name="numeric_meaning"
                      value={this.state.numeric_meaning}
                      onChange={(e) => this._onChangeText(e, true)}
                      // disabled={isEmpty(dosage_description)}
                      placeholder="Enter Quantity"
                      type="number"
                    />
                  </div>
                </div>
              </div>
            </Modal>
          )} */}
        </Modal>

        <Modal
          visible={this.state.showDecline}
          onCancel={() => {
            this.setState({
              showDecline: false,
              reason: "",
            });
          }}
          title="Decline Note"
          footer={null}
          maskClosable={false}
        >
          <div className="comment">
            <label htmlFor="comment" className="font-12">
              Clinical Reasoning for declining the doctor{" "}
            </label>
            <Input
              type="text"
              name="reason"
              value={this.state.reason}
              className="d-block custom-input font-11 weight-400 input-sm-28 placeholder-10 input-bg-f9f9f9"
              placeholder="Enter Reason"
              onChange={this._onChangeText}
              autoFocus
            />
          </div>

          <div className="push-10-t">
            <Button
              onClick={this.declineScript}
              disabled={
                isEmpty(this.state.reason) ||
                /^(?=.*\S).+$/.test(this.state.reason) === false
              }
              loading={this.state.isLoading}
              className="mr-2"
              danger
            >
              Decline
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    componentName: verifyObject(state.localStore, "componentName", null),
    preQuestionnaire: verifyObject(state, "preQuestionnaire", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    preQuestionnaireActions: bindActionCreators(
      preQuestionnaireActions,
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ScriptReviewContainer);

export default withRouter(ConnectedComponent);

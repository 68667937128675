/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from "moment";
import React, {Fragment} from "react";

const Footer = () => {
  const currentYear = moment().format("YYYY");

  return (
    <Fragment>
      <footer className="footer_custom">
        <p className="margin-0 font-12 weight-400 text-dark-gray in-block margin-right-10">
          © {currentYear} ADDVantage Technologies &nbsp;
        </p>
        <p className="margin-0 font-12 weight-400 text-dark-gray in-block margin-right-10">
          <span className="hide_outline">
            <a>Terms &amp; Conditions</a>{" "}
          </span>{" "}
          &nbsp; |{" "}
        </p>
        <p className="margin-0 font-12 weight-400 text-dark-gray in-block">
          <span className="hide_outline">
            &nbsp; <a>Privacy Policy</a>
          </span>
        </p>
      </footer>
    </Fragment>
  );
};

export default Footer;

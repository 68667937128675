import { get, Irequest, post } from "./index";

export type RightToWorkParams = {
  page?: number,
  category_id?: number,
  status?: string,
  from_date?: string,
  to_date?: string,
  download_data?: string
}

export type UpdateDocumentParams = {
  id?: number,
  document_status?: string,
  expiry_date?: string,
}


export const getDocumentsApi = (params: RightToWorkParams) => {
  const request: Irequest = {
    subUrl: `/right_to_works/list_all_documents.json`,
    params,
  };
  return get(request);
};

export const updateDocumentApi = (data: UpdateDocumentParams) => {
  const request: Irequest = {
    subUrl: `/right_to_works/upload_document.json`,
    data,
  };
  return post(request);
};

import { getReportsApi } from 'services/reportsReview'
import { listingReducer } from 'reducers/utils/listingReducer'

const { reducer, actions, types } = listingReducer({
	name: `newReportsReview`,
	getApi: getReportsApi,
})

const newReportsReview = reducer
export { newReportsReview, actions, types }

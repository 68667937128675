/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useRef, useState} from "react";
// import {Button} from "react-bootstrap";
import {
  // ReactToPrint,
  useReactToPrint,
} from "react-to-print";
import ComponentToPrint from "./ComponentToPrint";
// import { Spinner } from "components/common";

export default function PrintComponent(props) {
  let componentRef = useRef();
  const [printing, setPrinting] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (props.pdfURL) {
        handlePrint();
      }
    }, 2000);
    if (!props.pdfURL) {
      setPrinting(false);
    }
    // Cleanup function to clear the timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, [props.pdfURL]);
  //   setTimeout(() => {
  //     handlePrint();
  //   }, [3000]);
  //   useEffect(() => {}, []);
  const handlePrint = useReactToPrint({
    content: () => componentRef,
    onAfterPrint: () => {
      setPrinting(false);
    },
    onBeforePrint: () => {
      setPrinting(true);
    },

    removeAfterPrint: true,
    onPrintError: (err) => {
      console.log("onPrintError ", err);
    },
  });
  const handlePrint2 = () => {
    handlePrint();
    // window.open().print()
  };

  return (
    <>
      <div>
        {printing && <span className="text-bold"> Printing...</span>}
        <ComponentToPrint
          pdfURL={props.pdfURL}
          ref={(el) => (componentRef = el)}
          handlePrint={handlePrint2}
        />
      </div>
    </>
  );
}

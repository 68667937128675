/* eslint-disable react-hooks/exhaustive-deps */
import { isArray } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import Highlighter from "react-highlight-words";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import {
  CloseCircleOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";
import CommonLoader from "components/common/CommonLoader";
import NoFoundMessage from "components/common/NoFoundMessage";
import { ValidateInput } from "./SortCodeAddValidations";
import { updateShortCodeAPI } from "services/consultations";

/**
 * Renders a table of short codes.
 *
 * @param {object} props - The properties object.
 * @param {string} props.activeKey - The active key.
 * @param {boolean} props.isLoading - A flag indicating if the data is loading.
 * @param {Array} props.shortCodes - An array of short codes.
 * @param {function} props._handleDelete - The delete handler function.
 * @param {function} props.handleEdit - The edit handler function.
 * @returns {JSX.Element} - The rendered table.
 */
export default function ShortCodesTable({
  activeKey,
  isLoading,
  shortCodes,
  _handleDelete,
  handleEdit,
  state,
  ids,
  selectedRecord,
  _onRequestList,
  _clearSelectedRecords,
  showShortCodeModal,
}) {
  const [shortCodesState, setState] = useState({
    changing: false,
    reason: "",
    patient_sought: false,
    code: "",
    code_description: "",
    errors: null,
    code_key: null,
    fieldName: null,
  });

  const _handleTextChange = (e) => {
    let errors = null;
    if (shortCodesState.errors) {
      errors = Object.assign("", shortCodesState.errors);
      delete errors[e.target.name];
    }
    console.log("errors", errors);
    setState({
      ...shortCodesState,
      [e.target.name]: e.target.value,
      errors: errors,
    });
  };
  useEffect(() => {
    if (selectedRecord) {
      console.log("Selected Record", selectedRecord);
      setState({
        ...shortCodesState,
        code: selectedRecord.code,
        code_description: selectedRecord.description,
        code_key: selectedRecord.code_key,
      });
    }
  }, [selectedRecord]);

  useEffect(() => {
    console.log("showShortCodeModal", showShortCodeModal);
    if (showShortCodeModal === false) {
      _clearSelectedRecords();
    }
  }, [showShortCodeModal]);

  const _updateShortCode = async () => {
    // alert("in");
    let { code, code_description, code_key } = shortCodesState;
    // let { _onRequestList } = this.props;

    let dataTobeValidate = {
      code: code,
      code_description: code_description,
      code_key: code_key,
    };
    const errors = ValidateInput(dataTobeValidate);
    if (!errors.isValid) {
      setState({ ...shortCodesState, errors: errors.errors, adding: false });
    } else {
      let payload = {
        code: code,
        description: code_description,
        code_key: code_key,
      };

      // await this._SaveComponentStateToRedux(datesParams);

      try {
        setState({ ...shortCodesState, changing: true });
        let response = await updateShortCodeAPI({
          id: selectedRecord.id,
          short_code: {
            ...payload,
          },
        });

        successToast({ content: verifyObject(response, "data.message", "") });
        if (_onRequestList) {
          if (typeof _onRequestList === "function") {
            _onRequestList();
          }
        }
        if (_clearSelectedRecords) {
          if (typeof _clearSelectedRecords === "function") {
            _clearSelectedRecords();
          }
        }
        setState({
          ...shortCodesState,
          fieldName: null,
        });
      } catch (error) {
        const { message } = getErrorObject(error);
        await setState({ ...shortCodesState, changing: false });
        errorToast({ content: message });
      }
    }
  };
  console.log("selectedRecord", selectedRecord);
  return (
    <Fragment>
      {isLoading && (
        <div className="shortcodeloader">
          <CommonLoader />
        </div>
      )}

      {!isLoading && ids && ids.length === 0 && (
        <NoFoundMessage message="No short codes found" />
      )}
      {!isLoading &&
        shortCodes &&
        isArray(shortCodes) &&
        shortCodes.length > 0 && (
          <table className="w-100 shortcodetable">
            <thead className="w-100 upcoming_booking_tableheader relative bg-gray border">
              <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 text-light-black width-8">
                Code
              </th>
              <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 text-light-black width-82">
                Description
              </th>
              <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-22 text-light-black width-10">
                Actions
              </th>
            </thead>
            <tbody className="pre_quitionare">
              {!isLoading &&
                shortCodes &&
                isArray(shortCodes) &&
                shortCodes.map((k, index) => {
                  return (
                    <tr key={index}>
                      <td className="text-left padding-left-10 width-8">
                        {selectedRecord &&
                        selectedRecord.id === k.id &&
                        shortCodesState?.fieldName === "code" ? (
                          <input
                            className="form-control margin-0 font-10"
                            readOnly=""
                            name="code"
                            value={shortCodesState.code}
                            onChange={_handleTextChange}
                            autoFocus
                          />
                        ) : (
                          <span
                            onDoubleClick={(e) => {
                              e.stopPropagation();
                              handleEdit(k);
                              setState({
                                ...shortCodesState,
                                fieldName: "code",
                              });
                            }}
                            className="in-block shortscodepanel cursor-pointer"
                            style={{
                              pointerEvents:
                                k.code_type === "general" && "none",
                            }}
                          >
                            <Highlighter
                              highlightClassName="search-highlighter"
                              searchWords={[state.search]}
                              autoEscape={true}
                              textToHighlight={
                                verifyObject(k, "code", "") !== ""
                                  ? `${k.code}`
                                  : "-"
                              }
                            />
                            {/* {k.code} */}
                          </span>
                        )}
                        {selectedRecord &&
                          selectedRecord.id === k.id &&
                          shortCodesState?.errors && (
                            <span className="validate-danger">
                              {shortCodesState?.errors.code}
                            </span>
                          )}
                      </td>
                      {selectedRecord &&
                      selectedRecord.id === k.id &&
                      shortCodesState?.fieldName === "description" ? (
                        <td className="text-left padding-left-10 width-82 cursor-pointer">
                          <textarea
                            className="form-control margin-0 font-10 h-auto"
                            name="code_description"
                            value={shortCodesState.code_description}
                            onChange={_handleTextChange}
                            rows={"3"}
                          />
                        </td>
                      ) : (
                        <td
                          onDoubleClick={(e) => {
                            e.stopPropagation();
                            handleEdit(k);
                            setState({
                              ...shortCodesState,
                              fieldName: "description",
                            });
                          }}
                          className="text-left padding-left-10 width-82 cursor-pointer" 
                        >
                          <span
                            style={{
                              pointerEvents:
                                k.code_type === "general" && "none",
                              whiteSpace: "pre-line",
                            }}
                            className="font-11 in-block cursor-pointer"
                          >
                            {k.description}
                          </span>
                          {selectedRecord &&
                            selectedRecord.id === k.id &&
                            shortCodesState?.errors && (
                              <span className="validate-danger">
                                {shortCodesState?.errors.code_description}
                              </span>
                            )}
                        </td>
                      )}

                      <td className="padding-left-10 width-10">
                        <div className="flex flex-center">
                          {k.code_type !== "general" ? (
                            <Fragment>
                              {selectedRecord && selectedRecord.id === k.id ? (
                                <Fragment>
                                  {shortCodesState?.fieldName !== null && (
                                    <div className="editicon mr-2">
                                      <CloseCircleOutlined
                                        onClick={() => {
                                          // _updateShortCode();
                                          setState({
                                            ...shortCodesState,
                                            fieldName: null,
                                          });
                                          if (_clearSelectedRecords) {
                                            if (
                                              typeof _clearSelectedRecords ===
                                              "function"
                                            ) {
                                              _clearSelectedRecords();
                                            }
                                          }
                                        }}
                                        className="font-13-imp text-primary text-danger  weight-400 text-white tablet-font-10 font-16"
                                      >
                                        Close
                                      </CloseCircleOutlined>
                                    </div>
                                  )}
                                  <div className="editicon mr-2">
                                    <CheckCircleOutlined
                                      onClick={() => {
                                        _updateShortCode();
                                      }}
                                      className="font-13-imp text-primary  weight-400 text-white tablet-font-10 font-16"
                                    >
                                      Submit
                                    </CheckCircleOutlined>
                                  </div>
                                </Fragment>
                              ) : (
                                <></>
                              )}
                              <div className="editicon delete">
                                <DeleteOutlined
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    _handleDelete(k);
                                  }}
                                  className="font-13-imp text-danger weight-400 text-white tablet-font-10 font-16"
                                >
                                  Delete
                                </DeleteOutlined>
                              </div>
                            </Fragment>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        )}
    </Fragment>
  );
}

import validator from "validator";
import isEmpty from "lodash/isEmpty";
import {
  // DATE_FORMAT,
  ROLE_DOCTOR,
  ROLE_CLINICAL_PHARMACIST,
  ROLE_SUPERINTENDENT_PHARMACIST,
} from "constants/common";
import moment from "moment";
import {
  formatPhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

// first_name: "",
//     last_name: "",
//     phone: "",
//     email: "",
//     gender: "",
//     address_name: "",
//     addres_line_1: "",
//     addres_line_2: "",
//     addres_line_3: "",
//     city: "",
//     postal_code: "",
//     weight: "",
//     height: "",
//     dob: "",
export function ValidateInput(data, page, role) {
  console.log("DATA-------", data);
  const optionalFieldCondition =
    (role && role === ROLE_CLINICAL_PHARMACIST) ||
    (role && role === ROLE_DOCTOR) ||
    (role && role === ROLE_SUPERINTENDENT_PHARMACIST);

  let dateEmptyConsition = !(data.dob === undefined) && data.dob === "";

  let filledDateCOndition =
    data.dob !== "" && moment(data.dob, "DD-MM-YYYY").isAfter(moment());

  let errors = {};

  if (data.first_name !== undefined && validator.isEmpty(data.first_name)) {
    errors.first_name = "First name  is required";
  }
  if (data.last_name !== undefined && validator.isEmpty(data.last_name)) {
    errors.last_name = "Last name  is required";
  }
  // if (
  //   data.phone !== undefined &&
  //   validator.isEmpty(formatPhoneNumber(data.phone))
  // ) {
  //   errors.phone = "Mobile no is required";
  // }
  // if (
  //   data.phone !== undefined &&
  //   validator.isEmpty(formatPhoneNumber(data.phone))
  // ) {
  //   errors.phone = "Mobile no is required";
  // }
  console.log("data. phone==========", data.phone === "undefined");
  if (
    (data.phone !== undefined ||
      data.phone === undefined ||
      data.phone === null) &&
    validator.isEmpty(formatPhoneNumber(data.phone))
  ) {
    if (data.reasonCheckedPhone) {
      if (data.reasonPhoneOption === null) {
        errors.reasonPhoneOption = "Select reason";
      } else if (
        data.reasonPhoneOption === "other" &&
        data.reasonPhoneText !== undefined &&
        (validator.isEmpty(data.reasonPhoneText) ||
          data.reasonPhoneText.trim().length === 0)
      ) {
        errors.reasonPhoneText = "Reason required";
      }
    } else {
      if (
        data.phone ||
        data.phone === null ||
        data.phone === "" ||
        !data.phone
      ) {
        if (data.reasonPhoneOption) {
        } else {
          errors.phone = "Phone is required";
        }
      }
    }
  } else if (
    data.phone !== undefined &&
    !validator.isEmpty(data.phone) &&
    (isValidPhoneNumber(data.phone) === false ||
      isPossiblePhoneNumber(data.phone) === false)
  ) {
    console.log(
      "isValidPhoneNumber(data.phone)",
      isValidPhoneNumber(data.phone)
    );
    console.log(
      "isPossiblePhoneNumber(data.phone)",
      isPossiblePhoneNumber(data.phone)
    );
    if (data.phone === "undefined") {
      //make phone email optiona changes
    } else {
      errors.phone = "Please enter valid mobile number";
    }
  } else {
  }
  if (data.reasonCheckedPhone) {
    if (data.reasonPhoneOption === null) {
      errors.reasonPhoneOption = "Select reason";
    } else if (
      data.reasonPhoneOption === "other" &&
      data.reasonPhoneText !== undefined &&
      (validator.isEmpty(data.reasonPhoneText) ||
        data.reasonPhoneText.trim().length === 0)
    ) {
      errors.reasonPhoneText = "Reason required";
    }
  }
  // if (data.phone !== undefined && validator.isEmpty(data.phone) === false) {
  //   if (data.is_onchange_phone !== undefined) {
  //     console.log("validate phone11111==>", data.phone);

  //     if (!validator.isLength(data.phone, {min: 10, max: 10})) {
  //       errors.phone = "Phone no should be of 10 digit";
  //     }
  //   } else {
  //     console.log("validate phone222222==>", data.phone, data.phone.length);

  //     if (!validator.isLength(data.phone, {min: 13, max: 13})) {
  //       errors.phone = "Phone no should be of 10 digit";
  //     }
  //   }
  // }
  if (
    (data.email !== undefined || data.email === undefined) &&
    data.email !== undefined &&
    isEmpty(data.email)
    // &&
    // !forPDS
  ) {
    console.log("MB check email", data);
    if (data.reasonCheckedEmail) {
      if (data.reasonEmailOption === null) {
        errors.reasonEmailOption = "Select reason";
      } else if (
        (data.reasonEmailOption === "other" && data.reasonEmailText === "") ||
        (data.reasonEmailOption === "other" &&
          data.reasonPhoneText !== undefined &&
          (validator.isEmpty(data.reasonEmailText) ||
            data.reasonEmailText.trim().length === 0))
      ) {
        errors.reasonEmailText = "Reason required";
      }
    } else {
      if (
        data.email ||
        data.email === null ||
        data.email === "" ||
        (data.email && data.email.trim().length === 0)
      ) {
        errors.email = "Email is required";
      }
    }
  } else if (data.email && data.email.trim().length === 0) {
    errors.email = "Email is required";
  }
  if (data.email !== undefined && validator.isEmpty(data.email) === false) {
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is invalid";
    }
  }

  if (dateEmptyConsition) {
    // errors.dob = "DOB cannot be a future date.";
    errors.dob = "Date of birth is required";
  } else if (filledDateCOndition) {
    // errors.dob = "Date of birth is required";
    errors.dob = "DOB cannot be a future date.";
  }

  // child account validation start
  // if (data.is_child_account) {
  //   if (
  //     data.guardian_name !== undefined &&
  //     validator.isEmpty(data.guardian_name)
  //   ) {
  //     errors.guardian_name = "Guardian name  is required";
  //   }
  //   if (
  //     (data.guardian_phone !== undefined ||
  //       data.guardian_phone === undefined) &&
  //     validator.isEmpty(formatPhoneNumber(data.guardian_phone))
  //   ) {
  //     if (data.reasonCheckedPhone) {
  //       if (data.reasonPhoneOption === null) {
  //         errors.reasonPhoneOption = "Select reason";
  //       } else if (
  //         data.reasonPhoneOption === "other" &&
  //         data.reasonPhoneText !== undefined &&
  //         (validator.isEmpty(data.reasonPhoneText) ||
  //           data.reasonPhoneText.trim().length === 0)
  //       ) {
  //         errors.reasonPhoneText = "Reason required";
  //       }
  //     } else {
  //       errors.guardian_phone = "Phone is required";
  //     }
  //   }
  //   if (
  //     data.guardian_phone !== undefined &&
  //     data.guardian_phone !== null &&
  //     !validator.isEmpty(data.guardian_phone) &&
  //     (isValidPhoneNumber(data.guardian_phone) === false ||
  //       isPossiblePhoneNumber(data.guardian_phone) === false)
  //   ) {
  //     errors.guardian_phone = "Please enter valid mobile number";
  //   } else {
  //   }
  //   if (
  //     data.guardian_email !== undefined &&
  //     validator.isEmpty(data.guardian_email)
  //   ) {
  //     if (data.reasonCheckedEmail) {
  //       if (data.reasonEmailOption === null) {
  //         errors.reasonEmailOption = "Select reason";
  //       } else if (
  //         (data.reasonEmailOption === "other" && data.reasonEmailText === "") ||
  //         (data.reasonEmailOption === "other" &&
  //           data.reasonPhoneText !== undefined &&
  //           (validator.isEmpty(data.reasonEmailText) ||
  //             data.reasonEmailText.trim().length === 0))
  //       ) {
  //         errors.reasonEmailText = "Reason required";
  //       }
  //     } else {
  //       errors.guardian_email = "Email is required";
  //     }
  //   }
  //   if (
  //     data.guardian_email !== undefined &&
  //     validator.isEmpty(data.guardian_email) === false
  //   ) {
  //     if (!validator.isEmail(data.guardian_email)) {
  //       errors.guardian_email = "Email is invalid";
  //     }
  //   }
  // }
  if (
    data.emergency_number !== undefined &&
    data.emergency_number !== null &&
    !validator.isEmpty(data.emergency_number) &&
    (isValidPhoneNumber(data.emergency_number) === false ||
      isPossiblePhoneNumber(data.emergency_number) === false)
  ) {
    errors.emergency_number = "Please enter valid mobile number";
  }
  // child account validation end
  // if (data.gender !== undefined && validator.isEmpty(data.gender)) {
  //   errors.gender = "Gender is required";
  // }
  // if (data.city !== undefined && validator.isEmpty(data.city)) {
  //   errors.city = "City is required";
  // }
  // if (data.postal_code !== undefined && validator.isEmpty(data.postal_code)) {
  //   errors.postal_code = "Postal code is required";
  // }
  // if (data.weight !== undefined && validator.isEmpty(data.weight)) {
  //   errors.weight = "Weight is required";
  // }
  // if (data.height !== undefined && validator.isEmpty(data.height)) {
  //   errors.height = "Height is required";
  // }
  // if (data.height !== undefined && validator.isEmpty(data.height)) {
  //   errors.height = "Height is required";
  // }

  // if (
  //   data.security_question !== undefined &&
  //   validator.isEmpty(data.security_question)
  // ) {
  //   errors.security_question = "Please select security question.";
  // }

  // if (
  //   data.security_answer !== undefined &&
  //   validator.isEmpty(data.security_answer)
  // ) {
  //   errors.security_answer = "Please select security answer.";
  // }
  if (
    data.address_line_1 !== undefined &&
    validator.isEmpty(data.address_line_1)
  ) {
    errors.address_line_1 = "Line 1 is required";
  }
  if (data.city !== undefined && validator.isEmpty(data.city)) {
    errors.city = "City is required";
  }

  if (data.postal_code !== undefined && validator.isEmpty(data.postal_code)) {
    errors.postal_code = "Postal Code is required";
  }

  // if (
  //   data.security_question !== undefined &&
  //   validator.isEmpty(data.security_question)
  // ) {
  //   errors.security_question = "Please select security question.";
  // }

  // if (
  //   data.security_answer !== undefined &&
  //   validator.isEmpty(data.security_answer)
  // ) {
  //   errors.security_answer = "Please select security answer.";
  // }
  // if (data.bmi !== undefined && validator.isEmpty(data.bmi)) {
  //   errors.bmi = "BMI is required";
  // }
  // if (data.do_you_drink !== undefined && validator.isEmpty(data.do_you_drink)) {
  //   errors.do_you_drink = "Do you Drink?";
  // }
  // if (data.do_you_smoke !== undefined && validator.isEmpty(data.do_you_smoke)) {
  //   errors.do_you_smoke = "Do you smoke?";
  // }

  // if (
  //   data.blood_pressure !== undefined &&
  //   validator.isEmpty(data.blood_pressure)
  // ) {
  //   errors.blood_pressure = "Blood Pressure is required";
  // }
  console.log("ERRORS-------", errors);
  return {errors, isValid: isEmpty(errors)};
}

import { getErrorObject, errorToast } from 'utilities/utils'
import { getPatientDetail } from '../services/patientDetail'
import { verifyObject } from '../utilities/utils'

export const types = {
	ON_FETCH_REQUEST: 'customer/details/ON_FETCH_REQUEST',
	ON_FETCH_SUCCESS: 'customer/details/ON_FETCH_SUCCESS',
	ON_FETCH_FAILURE: 'customer/details/ON_FETCH_FAILURE',
	ON_STATUS_UPDATE_REQUEST: 'customer/details/ON_STATUS_UPDATE_REQUEST',
	ON_STATUS_UPDATE_SUCCESS: 'customer/details/ON_STATUS_UPDATE_SUCCESS',
	ON_STATUS_UPDATE_FAILURE: 'customer/details/ON_STATUS_UPDATE_FAILURE',
	ON_PATIENT_UNLOCK_ACCOUNT: 'customers/ON_PATIENT_UNLOCK_ACCOUNT',
	ON_PATIENT_UNLOCK_RESET_PASSWORD: 'customers/ON_PATIENT_UNLOCK_RESET_PASSWORD',
	ON_PATIENT_UNLOCK_ERROR: 'customers/ON_PATIENT_UNLOCK_ERROR',
	RESET_STATE: 'customer/details/RESET_STATE',
	SET_PATIENT_DETAIL: 'SET_PATIENT_DETAIL',
	UNSET_PATIENT_DETAIL: 'UNSET_PATIENT_DETAIL',
}

const initialState = {
	data: {},
	isLoading: false,
	error: null,
	status: {
		isLoading: false,
	},
}

export const patientDetails = (state = initialState, action) => {
	switch (action.type) {
		case types.ON_FETCH_REQUEST: {
			return {
				...initialState,
				isLoading: true,
			}
		}
		case types.ON_FETCH_SUCCESS: {
			const { data } = action.payload
			return {
				...state,
				isLoading: false,
				error: null,
				data,
			}
		}
		case types.ON_FETCH_FAILURE: {
			const { error } = action.payload
			return {
				...state,
				isLoading: false,
				error,
			}
		}
		case types.ON_STATUS_UPDATE_REQUEST: {
			return {
				...state,
				status: {
					isLoading: true,
				},
			}
		}
		case types.ON_STATUS_UPDATE_SUCCESS: {
			const { status } = action.payload
			return {
				...state,
				data: {
					...state.data,
					status,
				},
				status: {
					isLoading: false,
				},
			}
		}
		case types.ON_STATUS_UPDATE_FAILURE: {
			return {
				...state,
				status: {
					isLoading: false,
				},
			}
		}
		case types.ON_PATIENT_UNLOCK_ACCOUNT: {
			return {
				...state,
				data: {
					...state.data,
					is_account_locked: false,
				},
			}
		}
		case types.ON_PATIENT_UNLOCK_RESET_PASSWORD: {
			return {
				...state,
				data: {
					...state.data,
					is_reset_password_locked: false,
				},
			}
		}
		case types.ON_PATIENT_UNLOCK_ERROR: {
			const { error } = action.payload

			return {
				...state,
				isLoading: false,
				error,
			}
		}
		case types.RESET_STATE: {
			return initialState
		}

		case types.UNSET_PATIENT_DETAIL: {
			return initialState
		}

		default:
			return state
	}
}

const onRequest = (params, props) => {
	return async (dispatch) => {
		dispatch({
			type: types.ON_FETCH_REQUEST,
			payload: {},
		})
		try {
			const response = await getPatientDetail(params)
			const data = verifyObject(response, 'data.data.patient', null)
			dispatch({
				type: types.SET_PATIENT_DETAIL,
				payload: data,
			})
			dispatch({
				type: types.ON_FETCH_SUCCESS,
				payload: { data },
			})
		} catch (error) {
			const { message } = getErrorObject(error)
			if (error && error.data && error.data.status === 801) {
				errorToast({ content: message })
			}
			dispatch({
				type: types.ON_FETCH_FAILURE,
				payload: { error: message },
			})
		}
	}
}
const onDisableDetailPage = (params: ICustomerDetailsParams) => {
	return async (dispatch) => {
		dispatch({
			type: 'SET_DISABLE',
			// payload: {}
		})
	}
}
const onEnableDetailPage = (params: ICustomerDetailsParams) => {
	return async (dispatch) => {
		dispatch({
			type: 'SET_ENABLE',
			// payload: {}
		})
	}
}

const resetState = () => {
	return {
		type: types.RESET_STATE,
	}
}

const resetLocalState = () => {
	return {
		type: types.UNSET_PATIENT_DETAIL,
	}
}

export const actions = {
	onRequest,
	onDisableDetailPage,
	onEnableDetailPage,
	resetState,
	resetLocalState,
	patientDetails,
}

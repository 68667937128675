/* eslint-disable eqeqeq */
import { Image } from "antd";
import React, { Fragment } from "react";
const allowedFileTypes =
  "image/jpg,image/jpg,image/png,application/pdf,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export default function FileInputQuestion({
  handleAnswerChange,
  childQuestions,
  parentQuestion,
  question,
  childOfQuestions,
  gender,
}) {
  console.log("parentQuestion?.category", parentQuestion?.category);
  console.log("question", question);
  console.log(
    "question?.conditional_text_values",
    question?.conditional_text_values
  );
  if (
    question.is_conditional &&
    childQuestions &&
    (parentQuestion.category === "single_choice" ||
      parentQuestion.category === "multi_choice" ||
      parentQuestion.category === "numerical") &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    //TODO: Handle conditional

    let selectedOption = parentQuestion.answer;
    if (parentQuestion?.category === "single_choice") {
      if (
        question?.conditional_text_values &&
        question?.conditional_text_values[0] === selectedOption
      ) {
        return (
          <Fragment>
            <div className="margin-t-15 margin-bottom-10">
              <div
                className={`form-row ${
                  question.question_options.length > 2 ? "full_box" : ""
                }`}
              >
                <div className="col-md-10">
                  <div className="weight-500 font-14 d-flex">
                    <span className="dot"></span>
                    <span
                      dangerouslySetInnerHTML={{ __html: question.title }}
                      className="Questions"
                    />
                    {question.is_required ? (
                      <sup className="text-danger">*</sup>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <input
                    // name={`selectedAnswer${questions.id}`}
                    // value={this.state[`selectedAnswer${index}`]}
                    onChange={(e) => {
                      const val = e.target.files && e.target.files[0];
                      handleAnswerChange(
                        val,
                        parentQuestion,
                        childQuestions,
                        childOfQuestions
                      );
                    }}
                    accept={allowedFileTypes}
                    type="file"
                    placeholder={
                      question.placeholder !== null ? question.placeholder : ""
                    }
                    className="form-control fileupload"
                  />
                </div>
              </div>
              <div className="errorandinfo">
                {question.imageURL && (
                  <Image width={100} src={question.imageURL} />
                )}
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }
    if (parentQuestion?.category === "numerical") {
      let selectedValue = parseInt(parentQuestion.answer, 10);
      const { conditional_max_value, conditional_min_value } = question;
      console.log("selectedValue", selectedValue);
      console.log("Max", conditional_max_value);
      console.log("Min", conditional_min_value);
      if (
        selectedValue <= conditional_max_value &&
        selectedValue >= conditional_min_value
      ) {
        return (
          <Fragment>
            <div className="margin-t-15  margin-bottom-10">
              <div
                className={`form-row ${
                  question.question_options.length > 2 ? "full_box" : ""
                }`}
              >
                <div className="col-md-10">
                  <div className="weight-500 font-14 d-flex">
                    <span className="dot"></span>
                    <span
                      dangerouslySetInnerHTML={{ __html: question.title }}
                      className="Questions"
                    />
                    {question.is_required ? (
                      <sup className="text-danger">*</sup>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <input
                    // name={`selectedAnswer${questions.id}`}
                    // value={this.state[`selectedAnswer${index}`]}
                    onChange={(e) => {
                      const val = e.target.files && e.target.files[0];
                      handleAnswerChange(
                        val,
                        parentQuestion,
                        childQuestions,
                        childOfQuestions
                      );
                    }}
                    accept={allowedFileTypes}
                    type="file"
                    placeholder={
                      question.placeholder !== null ? question.placeholder : ""
                    }
                    className="form-control fileupload"
                  />
                </div>
              </div>
              <div className="errorandinfo">
                {question.imageURL && (
                  <Image width={100} src={question.imageURL} />
                )}
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }
  } else if (
    !childOfQuestions &&
    childQuestions &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 margin-bottom-10">
          <div
            className={`form-row ${
              question.question_options.length > 2 ? "full_box" : ""
            }`}
          >
            <div className="col-md-10">
              <div className="weight-500 font-14  d-flex">
                <span className="dot"></span>
                <span
                  dangerouslySetInnerHTML={{ __html: question.title }}
                  className="Questions"
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
            </div>
            <div className="col-md-2">
              <input
                // name={`selectedAnswer${questions.id}`}
                // value={this.state[`selectedAnswer${index}`]}
                onChange={(e) => {
                  const val = e.target.files && e.target.files[0];
                  handleAnswerChange(
                    val,
                    parentQuestion,
                    childQuestions,
                    childOfQuestions
                  );
                }}
                accept={allowedFileTypes}
                type="file"
                placeholder={
                  question.placeholder !== null ? question.placeholder : ""
                }
                className="form-control fileupload"
              />
            </div>
          </div>
          <div className="errorandinfo">
            {question.imageURL && <Image width={100} src={question.imageURL} />}
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else if (
    childOfQuestions &&
    childQuestions.answer &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 margin-bottom-10">
          <div
            className={`form-row ${
              question.question_options.length > 2 ? "full_box" : ""
            }`}
          >
            <div className="col-md-10">
              <div className="weight-500 font-14  d-flex">
                <span className="dot"></span>
                <span
                  dangerouslySetInnerHTML={{ __html: question.title }}
                  className="Questions"
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
            </div>
            <div className="col-md-2">
              <input
                // name={`selectedAnswer${questions.id}`}
                // value={this.state[`selectedAnswer${index}`]}
                onChange={(e) => {
                  const val = e.target.files && e.target.files[0];
                  handleAnswerChange(
                    val,
                    parentQuestion,
                    childQuestions,
                    childOfQuestions
                  );
                }}
                type="file"
                // accept="jpg,jpeg,png,pdf,xls"
                accept={allowedFileTypes}
                placeholder={
                  question.placeholder !== null ? question.placeholder : ""
                }
                className="form-control fileupload"
              />
            </div>
          </div>
          <div className="errorandinfo">
            {question.imageURL && <Image width={100} src={question.imageURL} />}
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else if (
    !childOfQuestions &&
    !childQuestions &&
    question &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 margin-bottom-10">
          <div
            className={`form-row ${
              question.question_options.length > 2 ? "full_box" : ""
            }`}
          >
            <div className="col-md-10">
              <div className="weight-500 font-14  d-flex">
                <span className="dot"></span>
                <span
                  dangerouslySetInnerHTML={{ __html: question.title }}
                  className="Questions"
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
            </div>
            <div className="col-md-2">
              <input
                // name={`selectedAnswer${questions.id}`}
                // value={this.state[`selectedAnswer${index}`]}
                onChange={(e) => {
                  const val = e.target.files && e.target.files[0];
                  handleAnswerChange(
                    val,
                    parentQuestion,
                    childQuestions,
                    childOfQuestions
                  );
                }}
                accept={allowedFileTypes}
                type="file"
                placeholder={
                  question.placeholder !== null ? question.placeholder : ""
                }
                className="form-control fileupload"
              />
            </div>
          </div>
          <div className="errorandinfo">
            {question.imageURL && <Image width={100} src={question.imageURL} />}
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <></>;
  }
}

import React, { Component, Fragment } from "react";
import { FormGroup } from "reactstrap";
import imgEyeClosed from "assets/images/login/security-uncheck.svg";
import imgEyeOpen from "assets/images/login/security-check.svg";
import { ValidateInput } from "./validateChangePassword";
import {
  getErrorObject,
  errorToast,
  successToast,
} from "../../utilities/utils";
import { changePasswordApi } from "../../services/session";
import { withRouter } from "react-router-dom";
import { get } from "lodash";
// import ChangePasswordPage from "../../pages/Login/ChangePasswordPage";

class ChangePassword extends Component {
  state = {
    passwordVisible: false,
    confirmpasswordVisible: false,
    oldpasswordVisible: false,
    existing_password: "",
    password: "",
    confirmpassword: "",
    submitting: false,
    errors: null,
  };
  handleChange = (e) => {
    let errors = null;
    let name = e.target.name;
    let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({ [e.target.name]: e.target.value, errors: errors }, () => {
      if (this.state[name] !== "") {
        let data = {
          [name]: value,
        };
        const errors = ValidateInput(data);
        if (!errors.isValid) {
          this.setState({ errors: errors.errors });
        }
      }
    });
  };
  togglePasswordVisiblity = () => {
    this.setState((prevState) => {
      return { passwordVisible: !prevState.passwordVisible };
    });
  };
  toggleConfirmPasswordVisiblity = () => {
    this.setState((prevState) => {
      return { confirmpasswordVisible: !prevState.confirmpasswordVisible };
    });
  };
  toggleOldPasswordVisiblity = () => {
    this.setState((prevState) => {
      return { oldpasswordVisible: !prevState.oldpasswordVisible };
    });
  };
  handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      existing_password: this.state.existing_password,
      password: this.state.password,
      confirmpassword: this.state.confirmpassword,
    };
    const errors = ValidateInput(data);

    if (!errors.isValid) {
      this.setState({ errors: errors.errors, submitting: false });
    } else {
      let reqObj = {
        existing_password: data.existing_password,
        password: data.password,
        confirmpassword: data.confirmpassword,
      };
      try {
        await this.setState({ submitting: true });
        const response = await changePasswordApi(reqObj);
        await this.setState({ submitting: false, isOpen: false });
        await successToast({
          content: get(
            response,
            "data.message",
            "Password Changed Successfully"
          ),
        });
        await this.props.history.push("/");
      } catch (error) {
        const { message } = getErrorObject(error);
        await this.setState({ submitting: false });
        errorToast({ content: message });
      }
    }
  };
  render() {
    const {
      existing_password,
      confirmpassword,
      password,
      submitting,
    } = this.state;
    return (
      <Fragment>
        {/* <Form onSubmit={this.handleSubmit}> */}
        <FormGroup>
          <label className="font-12 weight-400 text-left push-5-b dis-block text-dark-gray">
            Old Password
          </label>
          <div className="relative">
            <input
              className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
              type={this.state.oldpasswordVisible ? "text" : "password"}
              name="existing_password"
              placeholder="Enter Old Password"
              label="Password"
              onChange={this.handleChange}
              value={existing_password}
            />
            <img
              src={this.state.oldpasswordVisible ? imgEyeOpen : imgEyeClosed}
              alt=""
              className="cursor password_show"
              onClick={this.toggleOldPasswordVisiblity}
            />
          </div>
          {this.state.errors && (
            <span className="validate-danger">
              {this.state.errors.existing_password}
            </span>
          )}
        </FormGroup>
        <FormGroup>
          <label className="font-12 weight-400 text-left push-5-b dis-block text-dark-gray">
            Set New Password
          </label>
          <div className="relative">
            <input
              className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
              type={this.state.passwordVisible ? "text" : "password"}
              name="password"
              placeholder="Enter Password"
              label="Password"
              onChange={this.handleChange}
              value={password}
            />
            <img
              src={this.state.passwordVisible ? imgEyeOpen : imgEyeClosed}
              alt=""
              className="cursor password_show"
              onClick={this.togglePasswordVisiblity}
            />
          </div>
          {this.state.errors && (
            <span className="validate-danger">
              {this.state.errors.password}
            </span>
          )}
        </FormGroup>

        <FormGroup>
          <label className="font-12 weight-400 text-left push-5-b dis-block text-dark-gray">
            Re-enter Password
          </label>
          <div className="relative">
            <input
              className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
              type={this.state.confirmpasswordVisible ? "text" : "password"}
              name="confirmpassword"
              placeholder=" Re Enter Password"
              onChange={this.handleChange}
              label="Password"
              value={confirmpassword}
            />
            <img
              src={
                this.state.confirmpasswordVisible ? imgEyeOpen : imgEyeClosed
              }
              alt=""
              className="cursor password_show"
              onClick={this.toggleConfirmPasswordVisiblity}
            />
          </div>
          {this.state.errors && (
            <span className="validate-danger">
              {this.state.errors.confirmpassword}
            </span>
          )}
        </FormGroup>
        <FormGroup className="align-items-center push-10-t">
          <button
            type="submit"
            className="btn btn-custom btn-blue height-big font-14 weight-400 width-255px"
            //   disabled={submitting}
            onClick={this.handleSubmit}
          >
            Reset Password&nbsp;
            {submitting && <i className="fa fa-spinner fa-spin" />}
          </button>

          {/* <ForgotPasswordLink>
              <u className=" font-15 weight-500 push-20-l text-left text-blue hover-blue hide_outlinetext-blue ">
                Back
              </u>
            </ForgotPasswordLink> */}
        </FormGroup>
        {/* </Form> */}
      </Fragment>
    );
  }
}

export default withRouter(ChangePassword);

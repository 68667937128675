import React, { Fragment } from "react";
type ITabKey = string;
type ITabs = {
  [key: ITabKey]: {
    key: ITabKey,
    name: string
  }
};

type IProps = {
  tabs: ITabs,
  selectedTab: string,
  onTabChange: () => {}
};
const Tabs = (props: IProps) => {
  const { tabs, selectedTab, onTabChange } = props;
  return (
    <Fragment>
      <ul className="nav nav-tabs" role="tablist">
        {Object.keys(tabs).map(key => {
          let tab = tabs[key];
          let activeClass = "";
          if (tab.key === selectedTab) {
            activeClass = "active";
          }
          return (
            <li
              key={tab.key}
              className={`cursor nav-item mt-3`}
              onClick={() => {
                onTabChange(tab.key);
              }}
            >
              <span className={`nav-link tabs__link ${activeClass}`}>
                {tab.name}
              </span>
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
};

export default Tabs;

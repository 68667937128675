/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  verifyObject,
  successToast,
  getErrorObject,
  errorToast,
} from "../../utilities/utils";
import { approveRejectAppointmentsActions } from "../../reducers/appointments";
import { isArray } from "lodash";
import { Pagination } from "../../components/common";
import CommonLoader from "../../components/common/CommonLoader";
import NoFoundMessage from "../../components/common/NoFoundMessage";
import { acceptRejectAppointments } from "../../services/appointments";
import ApproveRejectAppointmentsItem from "./ApproveRejectAppointmentsItem";
import { onPatientSelectFromAppointments as onPatientSelect } from "../../reducers/patients";
import FilterComponent from "../../components/FIlterComponent/FilterComponent";
import { ApprovedRejectApptFilters } from "../../constants/filterConstants";
import refresh from "assets/images/common/refresh.svg";
// import ascendingsort from 'assets/images/common/ascending-sort.svg'

class ApproveRejectAppointmentContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptance: null,
      comment: "",
      visible: false,
      changing: true,
      appointmentId: null,
    };
    [
      "_onRequestList",
      "_acceptRejectAppointments",
      "_clearData",
      "_handleTextChange",
      "_hideModal",
      "_showModal",
      "_acceptAppointment",
      "_handleNavigation",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  componentDidMount() {
    this._onRequestList();
  }

  _onRequestList() {
    let { approveRejectAppointmentsActions } = this.props;
    let { onRequest } = approveRejectAppointmentsActions;
    if (typeof onRequest === "function") {
      let filters = JSON.parse(localStorage.getItem("approveRejectFilters"))
        ? JSON.parse(localStorage.getItem("approveRejectFilters"))
        : {};
      onRequest({ page: 1, ...filters });
    }
  }
  _onRefresh = () => {
    localStorage.removeItem("approveRejectFilters");
    let { approveRejectAppointmentsActions } = this.props;
    let { onRequest } = approveRejectAppointmentsActions;
    if (typeof onRequest === "function") {
      onRequest({ page: 1 });
    }
  };

  _acceptAppointment(id) {
    this.setState({ acceptance: "true", appointmentId: id }, () => {
      this._acceptRejectAppointments();
    });
  }

  async _acceptRejectAppointments() {
    let { acceptance, appointmentId, comment } = this.state;

    let payload = {
      acceptance: acceptance,
      comment: comment,
      id: appointmentId,
    };

    try {
      await this.setState({ changing: true });
      let response = await acceptRejectAppointments(payload);
      await this._clearData();
      await this._onRequestList();
      await successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({ changing: false });
      errorToast({ content: message });
    }
  }

  _handleTextChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  _hideModal() {
    this.setState({ visible: false }, () => {
      this._clearData();
    });
  }

  _showModal(id) {
    this.setState({ visible: true, acceptance: "false", appointmentId: id });
  }

  _clearData() {
    this.setState({
      acceptance: null,
      comment: "",
      visible: false,
      changing: false,
    });
  }

  _handleNavigation(id) {
    let { onPatientSelect } = this.props.onPatientSelect;
    if (typeof onPatientSelect === "function") {
      onPatientSelect({ id });
    }
  }
  saveFilters = (filters) => {
    console.log("Saved filters", filters);
    localStorage.setItem("approveRejectFilters", JSON.stringify(filters));
  };
  componentWillUnmount() {
    localStorage.removeItem("approveRejectFilters");
  }
  render() {
    let {
      approveRejectAppointments: { data, ids, isLoading },
    } = this.props;
    return (
      <div className="push-10-t">
        <div className="upcoming_appt_list margin-bottom-15">
          <div className="form-row push-20-b">
            <div className="col-md-4">
              <a
                onClick={this._onRefresh}
                className="refresh_wrapper vertical-middle text-light-black margin-top-8 in-block"
                title="Refresh"
              >
                <img
                  src={refresh}
                  className="height-15"
                  alt="Refresh"
                  title="Refresh"
                ></img>{" "}
                <span className="push-2-l font-14 weight-400">Refresh</span>
              </a>
            </div>
            <div className="col-md-8">
            <FilterComponent
                  isLoading={isLoading}
                  defaultRequest={{}}
                  onFilterChange={
                    this.props.approveRejectAppointmentsActions.onFilterChange
                  }
                  componentName={this.props.componentName}
                  resetFilters={
                    this.props.approveRejectAppointmentsActions.resetFilterState
                  }
                  filter_report_type="Appointment"
                  filterArray={ApprovedRejectApptFilters}
                  saveFilters={this.saveFilters}
                  selectedFilters={
                    JSON.parse(localStorage.getItem("approveRejectFilters"))
                      ? {
                          ...JSON.parse(
                            localStorage.getItem("approveRejectFilters")
                          ),
                        }
                      : {}
                  }
                  dataArray={ids}
                />
              {/* {JSON.parse(localStorage.getItem("approveRejectFilters")) !==
                null && (
              
              )}
              {JSON.parse(localStorage.getItem("approveRejectFilters")) ===
                null && (
                <FilterComponent
                  isLoading={isLoading}
                  defaultRequest={{}}
                  onFilterChange={
                    this.props.approveRejectAppointmentsActions.onFilterChange
                  }
                  componentName={this.props.componentName}
                  resetFilters={
                    this.props.approveRejectAppointmentsActions.resetFilterState
                  }
                  filter_report_type="Appointment"
                  filterArray={ApprovedRejectApptFilters}
                  saveFilters={this.saveFilters}
                  selectedFilters={{}}
                  dataArray={ids}
                />
              )} */}
            </div>
          </div>

          <div className="tableheader">
            <table className="w-100 upcoming_booking_tableheader relative bg-gray border border-bottom-none-imp">
              <thead>
                <tr>
                  <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-21 md-td-20 text-light-black">
                    Patient Name
                    {/* <img src={ascendingsort} className="push-3-l" alt="Ascending Sort" title=''></img> */}
                  </th>
                  <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-9 md-td-10 text-light-black">
                    Corporate
                    {/* <img src={ascendingsort} className="push-3-l" alt="Ascending Sort" title=''></img> */}
                  </th>
                  <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-7 md-td-8 text-light-black">
                    Gender
                    {/* <img src={ascendingsort} className="push-3-l" alt="Ascending Sort" title=''></img> */}
                  </th>
                  <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-8 md-td-9 text-light-black">
                    DOB
                    {/* <img src={ascendingsort} className="push-3-l" alt="Ascending Sort" title=''></img> */}
                  </th>
                  <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-21 md-td-20 text-light-black">
                    Reason for Call
                    {/* <img src={ascendingsort} className="push-3-l" alt="Ascending Sort" title=''></img> */}
                  </th>
                  <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-8 md-td-9 text-light-black">
                    Date
                    {/* <img src={ascendingsort} className="push-3-l" alt="Ascending Sort" title=''></img> */}
                  </th>
                  <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-9 md-td-9 text-light-black">
                    Time
                    {/* <img src={ascendingsort} className="push-3-l" alt="Ascending Sort" title=''></img> */}
                  </th>
                  <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 padding-right-10 lg-td-15 md-td-16 text-light-black">
                    Action
                  </th>
                </tr>
              </thead>
            </table>
          </div>

          <div className="upcoming_appt_data">
            {isLoading && <CommonLoader />}
            {!isLoading && ids && ids.length === 0 && (
              <NoFoundMessage message="No appointments available" />
            )}
            {!isLoading &&
              ids &&
              isArray(ids) &&
              ids.map((id) => {
                return (
                  <ApproveRejectAppointmentsItem
                    key={id}
                    data={data}
                    id={id}
                    _onRequestList={this._onRequestList}
                    _clearData={this._clearData}
                    _handleNavigation={this._handleNavigation}
                    {...this.state}
                  />
                );
              })}
          </div>
        </div>

        <div className="col-md-12">
          {!isLoading && (
            <Pagination
              data={this.props.approveRejectAppointments}
              onPageChange={this._onPageChange}
            />
          )}
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    approveRejectAppointments: verifyObject(
      state,
      "approveRejectAppointments",
      []
    ),
    componentName: verifyObject(state, "localStore.componentName", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    approveRejectAppointmentsActions: bindActionCreators(
      approveRejectAppointmentsActions,
      dispatch
    ),
    onPatientSelect: bindActionCreators({ onPatientSelect }, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApproveRejectAppointmentContainer);

export default ConnectedComponent;

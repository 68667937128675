import { getScriptReviewApi } from "../services/scriptReview";
import { listingReducer } from "reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: `scriptReview`,
  getApi: getScriptReviewApi,
});

const scriptReview = reducer;
export { scriptReview, actions, types };

import React, { PureComponent, Fragment } from "react";
import { bindActionCreators } from "redux"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { actions as staffIntranetActions } from "reducers/staffIntranet";
import { verifyObject } from "../../utilities/utils";
import { Pagination } from "../../components/common";
import StaffIntranetTable from "./StaffIntranetTable";

class StaffintranetContainer extends PureComponent {

    constructor(props) {
        super(props);
        [
            "_onRequestList",
            "_onPageChange"
        ].map((fn) => this[fn] = this[fn].bind(this));
    }

    componentDidMount() {
        this._onRequestList()
    }

    _onRequestList() {
        let { staffIntranetActions: { onRequest } } = this.props
        if (typeof onRequest === "function") {
            onRequest({ page: 1 })
        }
    }

    _onPageChange(data) {
        let { staffIntranetActions: { onPageChange }, staffIntranet: { search } } = this.props
        const { page } = data;
        if (typeof onPageChange === "function") {
            onPageChange({ page, search })
        }
    }

    render() {
        let { staffIntranet } = this.props
        return (
            <Fragment>
                <div className="push-20-b push-10-t">
                    {/* <div className="row align-items-center">
                        <div className="col-xl-12 col-md-12">
                            <nav aria-label="breadcrumb" className="breadcrub_wrapper">
                                <BreadCrumb data={breadCrumbData} />
                            </nav>
                        </div>
                    </div> */}
                    <div className="">
                        <StaffIntranetTable _onRequestList={this._onRequestList} dataSource={staffIntranet} />
                        <div className="col-md-12">
                            <Pagination
                                data={staffIntranet}
                                onPageChange={this._onPageChange}
                            />
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    }
}


const mapStateToProps = (state) => {
    return {
        staffIntranet: verifyObject(state, "staffIntranet", [])
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        staffIntranetActions: bindActionCreators(staffIntranetActions, dispatch)
    };
};

const ConnectedComponent = connect(
    mapStateToProps,
    mapDispatchToProps
)(StaffintranetContainer);

export default withRouter(ConnectedComponent);

import {Button, Input} from "antd";
import ModalPopUp from "components/common/ModalPopUp";
import {isNumber} from "lodash";
import React, {useEffect, useState} from "react";
import {isNumeric} from "validator";
export default function EditAbbreviationModal({
  isEditModalOpen,
  handleEdit,
  closeEditModal,
  isLoading,
  selectedItem,
  forAddAbbreviation,
}) {
  const [state, setState] = useState({
    abbreviation: "",
    numeric_meaning: "",
    meaning: "",
    id: null,
  });
  useEffect(() => {
    if (!isEditModalOpen) {
      setState({
        abbreviation: "",
        numeric_meaning: "",
        meaning: "",
        id: null,
      });
    }
  }, [isEditModalOpen]);
  useEffect(() => {
    if (selectedItem) {
      setState({
        ...state,
        abbreviation: selectedItem.abbreviation
          ? selectedItem.abbreviation
          : "",
        numeric_meaning: selectedItem.total_quantity_per_day
          ? selectedItem.total_quantity_per_day
          : "",
        meaning: selectedItem.meaning ? selectedItem.meaning : "",
        id: selectedItem.id ? selectedItem.id : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);
  const onChangeText = (e, inputNumber) => {
    if (e.target.value.includes("e") && inputNumber) {
      e.preventDefault();
    } else {
      setState({
        ...state,
        [e.target.name]: !inputNumber
          ? e.target.value
          : isNumeric(e.target.value)
          ? +e.target.value
          : "",
        // inputNumber&&isNumeric(e.target.value) ? +e.target.value : e.target.value,
      });
    }
  };
  let customAbbreviationButtonConsdition =
    state.meaning &&
    state.meaning !== "" &&
    state.meaning.trim().length !== 0 &&
    state.abbreviation &&
    state.abbreviation !== "" &&
    state.abbreviation.trim().length !== 0 &&
    state.numeric_meaning &&
    state.numeric_meaning !== undefined &&
    state.numeric_meaning !== "" &&
    isNumber(state.numeric_meaning)
      ? true
      : false;

  return (
    <ModalPopUp
      title={`${forAddAbbreviation ? "Add" : "Edit"} Abbreviation`}
      visible={isEditModalOpen}
      handleCancel={closeEditModal}
      destroyOnClose={true}
    >
      <div className="group">
        <div className="form-row">
          <div className="col-md-6">
            <label htmlFor="sel1">Abbreviation (short text)</label>
            <Input
              className="form-control margin-0 placeholder-10 addcomment"
              name="abbreviation"
              value={state.abbreviation}
              onChange={(e) => onChangeText(e, false)}
              // disabled={isEmpty(dosage_description)}
              placeholder="Enter Abbreviation"
              autoFocus
            />
          </div>
          <div className="col-md-6">
            <label htmlFor="sel1">Quantity</label>
            <input
              className="form-control margin-0 placeholder-10 addcomment"
              name="numeric_meaning"
              value={state.numeric_meaning}
              onChange={(e) => onChangeText(e, true)}
              // disabled={isEmpty(dosage_description)}
              placeholder="Enter Quantity"
              type="number"
            />
          </div>
          <div className="col-md-12 mt-2">
            <label htmlFor="sel1">Full text</label>
            <Input
              className="form-control margin-0 placeholder-10 addcomment"
              name="meaning"
              value={state.meaning}
              onChange={(e) => onChangeText(e, false)}
              // disabled={isEmpty(dosage_description)}
              placeholder="Enter Full text"
            />
          </div>
          <div className="col-md-12 mt-2 d-flex abberbtn">
            <div className="">
              <Button
                type="primary"
                onClick={() => handleEdit(state)}
                disabled={!customAbbreviationButtonConsdition}
                loading={isLoading}
              >
                {forAddAbbreviation ? "Add" : "Edit"}
              </Button>
            </div>
            <div className="ml-2">
              <Button onClick={closeEditModal}>Cancel</Button>
            </div>
          </div>
        </div>
      </div>
    </ModalPopUp>
  );
}

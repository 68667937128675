import React from 'react';

const IconChevronDown = (props) => {
    const { className } = props
    return (
        <svg className={className} version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" width="22" height="22">
            <path d="m1.9 6.5q0.2-0.2 0.4-0.3 0.2-0.1 0.5-0.1 0.2 0 0.4 0.1 0.2 0.1 0.4 0.3l7.4 7.5 7.4-7.5c0.2-0.3 0.5-0.4 0.8-0.4 0.4 0 0.7 0.1 0.9 0.3 0.2 0.3 0.3 0.6 0.3 0.9 0 0.3-0.1 0.6-0.3 0.8l-8.3 8.4q-0.1 0.2-0.4 0.3-0.2 0.1-0.4 0.1-0.2 0-0.5-0.1-0.2-0.1-0.3-0.3l-8.3-8.4q-0.1-0.1-0.2-0.3-0.1-0.3-0.1-0.5 0-0.2 0.1-0.5 0.1-0.2 0.2-0.3z" />
        </svg>
    )
}

export { IconChevronDown }

import React, { Fragment } from "react";
import { Wizard, Steps, Step } from "react-albus";
import { Spin, Divider, Button, Checkbox, Input } from "antd";
import AnswerItem from "./AnswerItem";
import { EditOutlined } from "@ant-design/icons";
import TemplateDropDown from "./TemplatesDropDown";
import { isArray } from "highcharts";
export default function AnswerReview({
  templatename,
  createPreConsultation,
  questionnaireWithAnswers,
  seekToQuestionnare,
  gender,
  passedToken,
  _handleTemplateChange,
  setHappyToReviewSubmit,
  happy_to_review_submit,
  setOutComeComment,
  outcomeComment,
  comment_input,
  handleCommentInputChange,
  template_id,
  setFinalStep,
  isEditMode,
  setFinalOutCome,
  preConsultStatus,
  outCome,
  finalOutCome,
  nextQuestionnaireID,
  setFromAnswerView,
  fromAnswerView,
  setIsSafetyNetting,
  is_saftey_netting,
  setServiceName,
  serviceName,
  isGateWayPoint,
  selectedItem,
}) {
  console.log(
    "🚀 ~ file: AnswerReview.jsx:13 ~ questionnaireWithAnswers:",
    questionnaireWithAnswers
  );
  console.log("outCome", outCome);
  return (
    <div className="questionanspart">
      <Spin spinning={false}>
        <Wizard>
          <Steps>
            <Step
              id="final"
              render={({ next, previous }) => (
                <div className="mt-3">
                  <div className="form-group answer-review_wrapper">
                    <div className="form-row">
                      <div className="col-md-12">
                        <Divider orientation="left" className="pr-2">
                          {" "}
                          <b>Answer Review</b>{" "}
                        </Divider>
                        <div className="add-scroll-to-answereview">
                          {questionnaireWithAnswers?.map((d, index) => {
                            if (d.id) {
                              return (
                                <div
                                  id={`answer_review_${index}`}
                                  defaultActiveKey={["1"]}
                                  key={d.id}
                                >
                                  <Divider orientation="right">
                                    <span
                                      style={{
                                        marginRight: 6,
                                      }}
                                    >
                                      {`Section ${index + 1}`}
                                    </span>
                                    <EditOutlined
                                      onClick={() => {
                                        seekToQuestionnare(d, index);
                                      }}
                                      style={{ fontSize: 22 }}
                                    >
                                      <span>Edit</span>
                                    </EditOutlined>
                                  </Divider>
                                  {d.question_details
                                    ?.filter(
                                      (d) =>
                                        d.category !== "information_text" &&
                                        d.pre_consult_answer !== null &&
                                        (d.gender_preference === null ||
                                          d.gender_preference === gender ||
                                          d.gender_preference === "both")
                                    )
                                    .map((d, index) => {
                                      return (
                                        <div className="questionnaire_answer_wrapper">
                                          {preConsultStatus ===
                                          "kiosk_pending" ? (
                                            <AnswerItem
                                              d={{
                                                ...d,
                                                answer: d.answer
                                                  ? d.answer
                                                  : d.pre_consult_answer
                                                      ?.actual_answer[0]
                                                  ? d.pre_consult_answer
                                                      ?.actual_answer?.length >
                                                    0
                                                    ? d.pre_consult_answer?.actual_answer?.toString()
                                                    : d.pre_consult_answer
                                                        ?.actual_answer[0]
                                                  : d.pre_consult_answer
                                                      ?.actual_answer,
                                              }}
                                              index={index}
                                            />
                                          ) : (
                                            <AnswerItem
                                              d={{
                                                ...d,
                                                answer: d.pre_consult_answer
                                                  ?.actual_answer[0]
                                                  ? d.pre_consult_answer
                                                      ?.actual_answer?.length >
                                                    0
                                                    ? d.pre_consult_answer?.actual_answer?.toString()
                                                    : d.pre_consult_answer
                                                        ?.actual_answer[0]
                                                  : d.pre_consult_answer
                                                      ?.actual_answer,
                                              }}
                                              index={index}
                                            />
                                          )}
                                          {d.children &&
                                            d.children.length > 0 && (
                                              <div className="questionnaire_answer_child_wrapper position-relative">
                                                {d.children
                                                  .filter(
                                                    (d) =>
                                                      d.category !==
                                                        "information_text" &&
                                                      d.pre_consult_answer !==
                                                        null &&
                                                      (d.gender_preference ===
                                                        null ||
                                                        d.gender_preference ===
                                                          gender ||
                                                        d.gender_preference ===
                                                          "both")
                                                  )
                                                  .map((child, childIndex) => {
                                                    if (
                                                      child.pre_consult_answer
                                                    ) {
                                                      return (
                                                        <div
                                                          className="questionnaire_answer"
                                                          key={child.id}
                                                        >
                                                          <AnswerItem
                                                            d={{
                                                              ...child,
                                                              answer: child
                                                                .pre_consult_answer
                                                                ?.actual_answer[0]
                                                                ? child
                                                                    .pre_consult_answer
                                                                    ?.actual_answer
                                                                    ?.length > 0
                                                                  ? child.pre_consult_answer?.actual_answer?.toString()
                                                                  : child
                                                                      .pre_consult_answer
                                                                      ?.actual_answer[0]
                                                                : child
                                                                    .pre_consult_answer
                                                                    ?.actual_answer,
                                                            }}
                                                            index={childIndex}
                                                          />

                                                          {child.children &&
                                                            child.children
                                                              .length > 0 && (
                                                              <div className="questionnaire_answer_child_wrapper position-relative">
                                                                {child.children
                                                                  .filter(
                                                                    (d) =>
                                                                      d.category !==
                                                                        "information_text" &&
                                                                      d.pre_consult_answer !==
                                                                        null &&
                                                                      (d.gender_preference ===
                                                                        null ||
                                                                        d.gender_preference ===
                                                                          gender ||
                                                                        d.gender_preference ===
                                                                          "both")
                                                                  )
                                                                  .map(
                                                                    (
                                                                      child2,
                                                                      child2Index
                                                                    ) => {
                                                                      if (
                                                                        child2.pre_consult_answer
                                                                      ) {
                                                                        return (
                                                                          <AnswerItem
                                                                            d={{
                                                                              ...child2,
                                                                              answer:
                                                                                child2
                                                                                  .pre_consult_answer
                                                                                  ?.actual_answer[0]
                                                                                  ? child2
                                                                                      .pre_consult_answer
                                                                                      ?.actual_answer
                                                                                      ?.length >
                                                                                    0
                                                                                    ? child2.pre_consult_answer?.actual_answer?.toString()
                                                                                    : child2
                                                                                        .pre_consult_answer
                                                                                        ?.actual_answer[0]
                                                                                  : child2
                                                                                      .pre_consult_answer
                                                                                      ?.actual_answer,
                                                                            }}
                                                                            index={
                                                                              child2Index
                                                                            }
                                                                          />
                                                                        );
                                                                      }
                                                                      return null;
                                                                    }
                                                                  )}
                                                              </div>
                                                            )}
                                                        </div>
                                                      );
                                                    }
                                                    return null;
                                                  })}
                                              </div>
                                            )}
                                        </div>
                                      );
                                    })}
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  {!isEditMode &&
                    // preConsultStatus === "pending" &&
                    outCome !== null &&
                    nextQuestionnaireID === null && (
                      <Fragment>
                        <div className="d-flex flex-column form-group cheboxrow mb-3">
                          <div>
                            <Checkbox
                              name="office_action_needed"
                              onChange={(e) => {
                                setFromAnswerView(true);
                                setHappyToReviewSubmit(!happy_to_review_submit);
                                if (e?.target?.checked) {
                                  setFinalOutCome(outCome);
                                  setIsSafetyNetting(!is_saftey_netting);
                                } else {
                                  setFinalOutCome(null);
                                }
                                // setFinalStep(false);
                              }}
                            >
                              Happy to review and submit
                            </Checkbox>
                          </div>
                          {/* {`nextQuestionnaireID:${nextQuestionnaireID}`} */}
                          {preConsultStatus !== "pending" &&
                            preConsultStatus !== "reviewed" &&
                            selectedItem.services &&
                            isArray(selectedItem.services) &&
                            selectedItem.services.length === 2 && (
                              <div className="form-group">
                                <label>Service</label>

                                <div className="d-flex">
                                  <Checkbox
                                    name="office_action_needed"
                                    onChange={() => {
                                      setServiceName("NHS");
                                    }}
                                    checked={serviceName === "NHS"}
                                  >
                                    NHS
                                  </Checkbox>

                                  <Checkbox
                                    onChange={() => {
                                      setServiceName("Private");
                                    }}
                                    checked={serviceName === "Private"}
                                  >
                                    Private
                                  </Checkbox>
                                </div>
                              </div>
                            )}

                          {/* <Checkbox
                      onChange={() => {
                        setIsSafetyNetting(!is_saftey_netting);
                        setFinalStep(false);
                      }}
                    >
                      Patient has been provided safety netting as recommended
                    </Checkbox> */}
                        </div>
                        <div>
                          <div className="form-row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="sel1" className="font-12">OutCome</label>
                                <select
                                  onChange={(e) => {
                                    console.log("SSSSSS", e?.target?.value);
                                    setOutComeComment(e?.target?.value);
                                  }}
                                  name="comment"
                                  // value={comment}
                                  className={`form-control height-30 custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                                >
                                  <option
                                    value=""
                                    disabled="disabled"
                                    selected="selected"
                                  >
                                    Select Option
                                  </option>
                                  <option value="Advice given only (no medicine supply)">
                                    Advice given only (no medicine supply)
                                  </option>
                                  <option value="Sale of an Over the Counter (OTC) medicine">
                                    Sale of an Over the Counter (OTC) medicine
                                  </option>
                                  <option value="Referral into a pharmacy local minor ailments service (MAS)">
                                    Referral into a pharmacy local minor ailments
                                    service (MAS)
                                  </option>
                                  <option value="Referral into an appropriate locally commissioned NHS service, such as a patient group direction">
                                    Referral into an appropriate locally
                                    commissioned NHS service, such as a patient
                                    group direction
                                  </option>
                                  <option value="Onward referral to another pharmacy">
                                    Onward referral to another pharmacy
                                  </option>
                                  <option value="Non-urgent signposting to another service">
                                    Non-urgent signposting to another service
                                  </option>
                                  <option value="Urgent escalation to another service">
                                    Urgent escalation to another service
                                  </option>
                                  <option value="Clinical pathways Consultation">
                                    Clinical pathways Consultation
                                  </option>
                                  <option value="Medicines supply">
                                    Medicines supply
                                  </option>
                                  <option value={true}>Other</option>
                                </select>
                                {outcomeComment === "true" && (
                                  <Input.TextArea
                                    autoSize={true}
                                    rows="2"
                                    onChange={handleCommentInputChange}
                                    form="consultation"
                                    value={comment_input}
                                    className={`form-control mt-2 height-auto custom-input ng-dirty ng-valid-parse ng-not-empty ng-valid ng-valid-required ng-touched`}
                                    name={"comment_input"}
                                    placeholder="Comment"
                                  />
                                )}
                              </div>
                            </div>
                            <div className="col-md-12">
                              <TemplateDropDown
                                _handleTemplateChange={_handleTemplateChange}
                                template_id={template_id}
                              />
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    )}
                  {/* {fromAnswerView ? "Y" : "N"} */}
                  {(isEditMode || nextQuestionnaireID !== null) &&
                    preConsultStatus !== "kiosk_pending" && (
                      <div className="text-left">
                        <Button
                          className="mb-3 mt-3"
                          type="primary"
                          onClick={createPreConsultation}
                        >
                          Confirm
                        </Button>
                      </div>
                    )}
                </div>
              )}
            />
          </Steps>
        </Wizard>
      </Spin>
    </div>
  );
}

/* eslint-disable react-hooks/exhaustive-deps */
import { Button, DatePicker, Radio, Spin, Select, Calendar } from "antd";
import { DATE_FORMAT } from "constants/common";
import moment from "moment";
import React, { Fragment, useEffect, useState } from "react";
import { allAppointmentUsersApi } from "services/allAppointmentUsers";
import { bookAppointmentFromPatientAPI } from "services/appointments";
import { getTimeSlotsForCorp, getTimeSlotsForDoctor } from "services/rota";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
// import noslot from "../../assets/images/common/not_time_slot.png";
import BookAppointmentFullCalendar from "./BookAppointmentFullCalendar";
import AppointmentBookedModal from "./AppinmentBookedModal";
import { map, groupBy } from "lodash";
import AppinmentBookedModal from "./AppinmentBookedModal";
import MordenCalendar from "components/common/controls/MordenCalendar";

// import noslot from "../../assets/images/common/not_time_slot.png";

export default function BookAppointmentReuse({
  user,
  patient_id,
  doctor_id,
  pre_consult_id,
  handleCloseModal,
  preConsultationReviewActions,
  statusParam,
  fromReviewPreConsult,
  slotType,
  handleToggle,
  handleCloseModal2,
  changeSlotType,
  refreshPreConsultList,
  newSelecteddHealthCondition,
  newPatientDetail,
  fromNotificationModal,
  handleCancelBookingAppt,
  health_scan_log_id,
  isFullscreen,
  toggleFullScreen,
}) {
  const [selectedAptType, setSelectedAptType] = useState(null);
  const [selecetdDate, setSelectedDate] = useState(null);
  const [isFetchingSlot, setIsFetchingSlot] = useState(false);
  const [slotsArray, setSlotArray] = useState(null);
  const [selectedSlotTime, setSelectedSlotTime] = useState(null);
  const [isBooking, setIsBooking] = useState(false);
  const [isBooked, setIsBooked] = useState(false);

  const [clinicians, setClinicians] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [value4, setValue4] = useState("Slots View");
  const [state, setState] = useState({
    isBooked: false,
    isBookingFromCalendar: false,
  });
  const [allDoctorIds, setAllDoctorIds] = useState([]);
  let [selectedDay, setSelectedDay] = useState(null);

  useEffect(() => {
    onGetAllClinicians(false);
  }, []);
  const handleAptTypeChange = ({ target: { value } }) => {
    setSelectedAptType(value);
    if (selectedSlotTime) {
      setSelectedSlotTime(null);
    }
  };

  // useEffect(() => {
  //   if (selecetdDate) {
  //     _getTimeSlots();
  //   }
  // }, [selecetdDate]);

  // useEffect(() => {
  //   if (selecetdDate && selectedAptType) {
  //     _getTimeSlots();
  //   }
  // }, [selectedAptType]);
  useEffect(() => {
    if (selecetdDate && selectedAptType && state.doctor_id?.length > 0) {
      _getTimeSlots();
      handleToggle(false);
    } else {
      handleToggle(true);
    }
  }, [selectedAptType, state.doctor_id, selecetdDate]);
  const onGetAllClinicians = async () => {
    try {
      await setLoading(true);
      let response = await allAppointmentUsersApi({
        search: search,
        page: 1,
      });
      if (response.data.data) {
        // let pagination =
        //   verifyObject(response, "headers['x-pagination']", null) !== null
        //     ? JSON.parse(response.headers["x-pagination"])
        //     : null;

        let doctors = response?.data?.data;
        let found = doctors.find((d) => d.id === doctor_id);
        let newArra = doctors.filter((d) => d.id !== doctor_id);

        setClinicians([found, ...newArra]);
        let mapIds = map([found, ...newArra], "id");
        setAllDoctorIds(mapIds);
        // setPagination(pagination);
      }
      await setLoading(false);
      await setSearching(false);
      await setLoading(false);
    } catch (error) {
      const { message } = getErrorObject(error);
      console.log("ERR", error);
      await setLoading(false);
      errorToast({ content: message });
    }
  };

  const handleSelectChange = (value, key) => {
    console.log("values", value);
    if (value?.includes("all")) {
      setState({ ...state, [key]: ["all"] });
    } else {
      if (value.length === 0) {
        setSlotArray(null);
        changeSlotType(false);
      }
      setState({ ...state, [key]: value });
    }
  };
  const _getTimeSlots = async () => {
    const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
    // let { user } = props;
    changeSlotType();

    setIsFetchingSlot(true);

    try {
      //   await this.setState({
      //     loadingSlots: true,
      //     timeSlots: [],
      //     slotDuration: [],
      //   });
      console.log("fromReviewPreConsult", fromReviewPreConsult);
      let corporatIdParams = {
        corporate_id: user?.corporate_id,
        doctor_ids: fromReviewPreConsult
          ? `${state.doctor_id?.toString()}`
          : doctor_id,
      };
      if (state.doctor_id && state.doctor_id[0] === "all") {
        corporatIdParams = {
          corporate_id: user?.corporate_id,
          // doctor_ids:"all"
        };
      }
      let patientIdParams = {};

      if (patient_id) {
        patientIdParams = {
          patient_id: patient_id,
        };
      }

      let response =
        // state.doctor_id === "all"
        await getTimeSlotsForCorp({
          session_type:
            selectedAptType === "video"
              ? "online_appointment"
              : "offline_appointment",
          date: moment(selecetdDate).format(DATE_FORMAT),
          corporate_id: user?.corporate_id,
          ...corporatIdParams,
          ...patientIdParams,
          // session_type: this.state.selectedAptType,
        });
      // : await getTimeSlotsForDoctor({
      //     date: moment(selecetdDate).format(DATE_FORMAT),
      //     doctor_id: fromReviewPreConsult ? state.doctor_id : doctor_id,
      //     zone: time_zone,
      //     slot_for:
      //       selectedAptType === "video"
      //         ? "online_appointment"
      //         : "offline_appointment",
      //   });
      console.log("Slot Response", response);

      if (response.data.data) {
        const transformedArray = verifyObject(response, "data.data", []).map(
          (item) => {
            const label = moment(item.start_time).format("hh:mm A");
            const value = [item.start_time, item.end_time];

            return { label, value };
          }
        );
        // this.setState({ timeSlots: transformedArray })
        console.log(
          "transformedArray",
          groupBy(transformedArray, (d) => moment(d.value[0]).format("hh"))
        );
        const groupByhour = groupBy(transformedArray, (d) =>
          moment(d.value[0]).format("hh")
        );
        setSlotArray(groupByhour);
        setIsFetchingSlot(false);
      }
      //   this.setState({ loadingSlots: false });
    } catch (error) {
      const { message } = getErrorObject(error);
      setIsFetchingSlot(false);
      setSlotArray(null);

      errorToast({ content: message });
    }
  };

  const disabledDate = (current) => {
    return current && current < moment().endOf("day").subtract("day", 1);
  };

  const handleDatepicker = (date) => {
    let day =
      date.day.toString().length >= 2 ? date.day.toString() : `0${date.day}`;
    let month =
      date.month.toString().length >= 2
        ? date.month.toString()
        : `0${date.month}`;

    let fullDate = `${date.year}-${month}-${day}`.toString();
    if (fullDate) {
      setSelectedDay(date);
      setSelectedDate(fullDate);
      if (selectedSlotTime) {
        setSelectedSlotTime(null);
      }
      changeSlotType();
    } else {
      setSelectedDate(null);
    }
  };

  const onChangeSlots = ({ target: { value } }) => {
    setSelectedSlotTime(value);
  };

  const handleAppointmentSubmission = async (modalConfig) => {
    console.log("patient id", patient_id);
    setIsBooking(true);
    let formData = new FormData();
    formData.set(
      `appointment[start_time]`,
      moment(selectedSlotTime[0]).toISOString()
    );
    formData.set(
      `appointment[end_time]`,
      moment(selectedSlotTime[1]).toISOString()
    );
    if (
      statusParam === "kiosk_pending" ||
      statusParam === "kiosk_reviewed"
    ) {
      // For kiosk pre consult selectedpreConsultID is health scan log id
      formData.set(`appointment[health_scan_log_id]`, health_scan_log_id);
    } else {
      formData.set(`appointment[pre_consult_id]`, pre_consult_id);
    }
    formData.set(`appointment[appointment_type]`, selectedAptType);
    formData.set(`duration`, 15);
    formData.set(`corporate_id`, user?.corporate_id);
    formData.set(`book_from_dp`, true);
    formData.set(`patient_id`, patient_id);
    formData.set(`book_via`, selectedAptType === "video" ? "ad_hoc" : "slot");
    console.log("DOCTOR ID", state.doctor_id);
    if (state?.doctor_id[0] !== "all") {
      console.log("DOCTOR ID", state.doctor_id);
      if (state.doctor_id?.length > 1) {
        formData.set(
          `doctor_ids`,
          fromReviewPreConsult ? state.doctor_id : doctor_id
        );
      } else {
        formData.set(
          `doctor_id`,
          fromReviewPreConsult ? state.doctor_id : doctor_id
        );
      }
    }
    try {
      let response = await bookAppointmentFromPatientAPI(formData);
      if (response.status === 200) {
        if (response.data.message) {
          refreshPreConsultList();
          console.log("selectedSlotTime", selectedSlotTime[0]);
          handleCloseModal(true, selectedSlotTime[0]);
          if (handleCloseModal2 && typeof handleCloseModal2 === "function") {
            handleCloseModal2();
          }

          setIsBooking(false);
          console.log("Modal closed");
          // successToast({
          //   content: response.data.message,
          // });
          if (
            modalConfig &&
            modalConfig?.destroyAll &&
            typeof modalConfig?.destroyAll === "function"
          ) {
            modalConfig.destroyAll();
            setState({
              ...state,
              fromCalendar: null,
              modalConfig: null,
            });
          }
        }
        setIsBooked(true);
        changeSlotType();
      }
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      setIsBooking(false);
      setState({
        ...state,
        fromCalendar: null,
        modalConfig: null,
      });
    }
  };
  const handleAppointmentSubmissionFromCalendar = async (
    doctor_id,
    selectedSlotTime
  ) => {
    setIsBooking(true);

    let formData = new FormData();
    formData.set(`appointment[start_time]`, selectedSlotTime[0]);
    formData.set(`appointment[end_time]`, selectedSlotTime[1]);
    if (
      statusParam === "kiosk_pending" ||
      statusParam === "kiosk_reviewed"
    ) {
      // For kiosk pre consult selectedpreConsultID is health scan log id
      formData.set(`appointment[health_scan_log_id]`, health_scan_log_id);
    } else {
      formData.set(`appointment[pre_consult_id]`, pre_consult_id);
    }
    formData.set(`appointment[appointment_type]`, selectedAptType);
    formData.set(`duration`, 15);
    formData.set(`corporate_id`, user?.corporate_id);
    formData.set(`book_from_dp`, true);
    formData.set(`patient_id`, patient_id);
    formData.set(`book_via`, selectedAptType === "video" ? "ad_hoc" : "slot");

    if (doctor_id !== "all") {
      console.log("DOCTOR ID", state.doctor_id);
      if (state.doctor_id?.length > 1) {
        formData.set(`doctor_ids`, doctor_id);
      } else {
        formData.set(`doctor_id`, doctor_id);
      }
    }
    try {
      let response = await bookAppointmentFromPatientAPI(formData);
      if (response.status === 200) {
        refreshPreConsultList();
        console.log("selectedSlotTime", selectedSlotTime);
        handleCloseModal(true, selectedSlotTime);
        if (handleCloseModal2 && typeof handleCloseModal2 === "function") {
          handleCloseModal2();
        }
        setIsBooking(false);
        // successToast({
        //   content: response.data.message,
        // });
        // setIsBooked(true);
        setState({ ...state, isBooked: true });
        // if (
        //   modalConfig &&
        //   modalConfig?.destroyAll &&
        //   typeof modalConfig?.destroyAll === "function"
        // ) {
        //   modalConfig.destroyAll();
        //   setState({
        //     ...state,
        //     fromCalendar: null,
        //     modalConfig: null,
        //   });
        // }
        changeSlotType();
      }
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      // if (slotType === "slot_view") {
      //   changeSlotType();
      // } else {
      //   changeSlotType(true);
      // }
      setIsBooking(false);
    }
  };
  // useEffect(() => {
  //   if (state.fromCalendar && state.modalConfig) {
  //     handleAppointmentSubmission(state.modalConfig);
  //   }
  // }, [state.fromCalendar, state.modalConfig]);

  console.log("Doctor ID", isBooking);
  return (
    // <div className="shadow bg-white p-16 radius-12">
    <div className="">
      <div style={{ maxWidth: "unset" }} className="bookappoinmentsection">
        {/* <div className="ant-modal-title mb-2 mt-3" id="rcDialogTitle1">
          Book an Appointment
        </div> */}
        {/* <div className="d-flex align-items-center justify-content-between bookingapthead">
            <h3>Book Appointment</h3>
            <div className="bookingoptionradiogrup">
              <Radio.Group
                options={slotoptions}
                onChange={onChange4}
                value={value4}
                optionType="button"
                buttonStyle="solid"
              />
            </div>
          </div> */}
        <div className="bookingmain">
          <div className="row">
            {!isFullscreen && (
              <div className="col-md-4">
                {
                  <div className="form-group">
                    <label htmlFor="sel1">Book With </label>{" "}
                    {loading ? (
                      <div className="text-center">
                        <Spin size="small" />
                      </div>
                    ) : (
                      <Select
                        showSearch
                        className="form-control margin-0 selected_doctor_for_appointment"
                        placeholder="Select doctor name"
                        optionFilterProp="children"
                        value={state.doctor_id}
                        mode="multiple"
                        onChange={(value) => {
                          if (!value) {
                            setSlotArray(null);
                          }
                          handleSelectChange(value, "doctor_id");
                        }}
                        loading={loading}
                        // onPopupScroll={handleScroll}
                        onSearch={(value) => setSearch(value)}
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        allowClear
                        dropdownRender={(menus) => {
                          return (
                            <div>
                              {menus}
                              {searching && (
                                <div
                                  style={{
                                    textAlign: "center",
                                    padding: 10,
                                  }}
                                >
                                  <Spin size="small" />
                                </div>
                              )}
                            </div>
                          );
                        }}
                      >
                        <Select.Option value={"all"}>{`All`}</Select.Option>
                        {clinicians.map((user) => {
                          return (
                            <Select.Option
                              disabled={state.doctor_id?.includes("all")}
                              value={user?.id}
                            >{`${user?.name} (${user?.role?.replaceAll(
                              "_",
                              " "
                            )})`}</Select.Option>
                          );
                        })}
                      </Select>
                    )}
                    {/* {this.state.errors && (
                  <span className="validate-danger">
                    {this.state.errors.last_name}
                  </span>
                )} */}
                  </div>
                }
                <div className="form-group m-0 appointment-type">
                  <label htmlFor="sel1" className="dis-block">
                    {/* Type */}
                    Appointment Type
                  </label>
                  <Radio.Group
                    className="d-flex radiogroupbtn"
                    value={selectedAptType}
                    onChange={handleAptTypeChange}
                  >
                    <Radio.Button value={"video"}>Video</Radio.Button>
                    <Radio.Button value="face_to_face">
                      Face to Face
                    </Radio.Button>
                  </Radio.Group>
                </div>
                <div className="form-group">
                  {/* <label htmlFor="sel1" className="dis-block">
                  Date
                </label>
                <DatePicker
                  inline={true}
                  // placeholder="Select Date"
                  placeholder="DD-MM-YYYY"
                  className="form-control"
                  format={DATE_FORMAT}
                  value={selecetdDate && moment(selecetdDate, DATE_FORMAT)}
                  disabledDate={disabledDate}
                  onChange={handleDatepicker}
                  suffixIcon={<Calendericon />}
                /> */}
                  {/* <Calendar
                  className="inline-calendar"
                  fullscreen={false}
                  value={selecetdDate && moment(selecetdDate, DATE_FORMAT)}
                  // dateCellRender={dateCellRender}
                  // monthCellRender={monthCellRender}
                  // onSelect={onSelect}
                  disabledDate={disabledDate}
                  onChange={handleDatepicker}
                  // onPanelChange={onPanelChange}
                /> */}

                  <MordenCalendar
                    selectedDay={selectedDay}
                    onsetSelectedDay={handleDatepicker}
                    // date={date}
                    date={selecetdDate && moment(selecetdDate, DATE_FORMAT)}
                    className="morden-cl-wrapper inline-calendar"
                    // isFutureDateDisabled={
                    //   selectedSidebarKey && selectedSidebarKey[0] === "APPT2"
                    // }
                    // isPastDateDisabled={
                    //   (selectedSidebarKey &&
                    //     selectedSidebarKey[0] === "APPT1") ||
                    //   selectedSidebarKey === null
                    // }
                    todayClassName={"remove-selected-date"}
                    // handleClear={handleClear}
                    // fromSidebar={true}
                  />
                </div>
                <div className="ant-modal-footer border-0 d-flex p-0">
                  {/* <Button
                  className="cancelbtn"
                  onClick={() => {
                    // setSelectedAptType(null);
                    handleCancelBookingAppt();
                    // setIsShowBookingAppt(false);
                  }}
                >
                  Cancel
                </Button> */}

                  {/* <MordenCalendar
                    selectedDay={selectedDay}
                    onsetSelectedDay={handleDatepicker}
                    // date={date}
                    date={selecetdDate && moment(selecetdDate, DATE_FORMAT)}
                    className="morden-cl-wrapper inline-calendar"
                    // isFutureDateDisabled={
                    //   selectedSidebarKey && selectedSidebarKey[0] === "APPT2"
                    // }
                    // isPastDateDisabled={
                    //   (selectedSidebarKey &&
                    //     selectedSidebarKey[0] === "APPT1") ||
                    //   selectedSidebarKey === null
                    // }
                    todayClassName={"remove-selected-date"}
                    // handleClear={handleClear}
                    // fromSidebar={true}
                  /> */}
                </div>
                <div className="ant-modal-footer border-0 d-flex p-0">
                  <Button
                    className="cancelbtn"
                    onClick={() => {
                      // setSelectedAptType(null);
                      handleCancelBookingAppt();
                      // setIsShowBookingAppt(false);
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    className="ant-btn ant-btn-primary "
                    onClick={handleAppointmentSubmission}
                    disabled={
                      selecetdDate === null ||
                      selectedAptType === null ||
                      selectedSlotTime === null ||
                      isBooking === true ||
                      state.doctor_id === null ||
                      state.doctor_id?.length === 0
                    }
                  >
                    {/* Book An Appointment */}
                    Book
                  </Button>
                </div>
              </div>
            )}
            {slotType === "calendar_view" && (
              <div className={isFullscreen ? "col-md-12" : "col-md-8"}>
                <BookAppointmentFullCalendar
                  selectedAptType={selectedAptType}
                  selecetdDate={selecetdDate}
                  isBooking={isBooking}
                  isBookingFromCalendar={state.isBookingFromCalendar}
                  slotType={slotType}
                  pre_consult_id={pre_consult_id}
                  patient_id={patient_id}
                  corporate_id={user?.corporate_id}
                  doctor_ids={
                    fromReviewPreConsult
                      ? state?.doctor_id && state?.doctor_id[0] === "all"
                        ? allDoctorIds?.toString()
                        : `${state.doctor_id?.toString()}`
                      : doctor_id
                  }
                  handleAppointmentSubmission={(
                    slot,
                    doctor_id,
                    modalConfig
                  ) => {
                    handleAppointmentSubmissionFromCalendar(doctor_id, slot);
                  }}
                  {...state}
                  isFullscreen={isFullscreen}
                  toggleFullScreen={toggleFullScreen}
                />
              </div>
            )}
            {slotType === "slot_view" && (
              <Fragment>
                {selecetdDate && value4 === "Slots View" && (
                  <div className="col-md-8">
                    <Spin spinning={isFetchingSlot} className="h-100">
                      <div className="commonfield h-100 d-flex flex-column">
                        <label>Select Time Slot </label>
                        {!isFetchingSlot && slotsArray ? (
                          Object.keys(slotsArray).map((key) => {
                            return (
                              <Fragment>
                                {/* <p className="pb-2 pt-1 font-13-imp weight-500">
                                  {moment(slotsArray[key][0].value[0])
                                    .subtract(
                                      "minutes",
                                      parseInt(
                                        moment(
                                          slotsArray[key][0].value[0]
                                        ).format("mm")
                                      )
                                    )
                                    .format("hh:mm A")}
                                   - 
                                  {moment(
                                    slotsArray[key][slotsArray[key].length - 1]
                                      .value[0]
                                  )
                                    .add("minutes", 14)
                                    .format("hh:mm A")}
                                </p> */}
                                <Radio.Group
                                  options={slotsArray[key] || []}
                                  onChange={onChangeSlots}
                                  value={selectedSlotTime}
                                  optionType="button"
                                  className="available_rota_list"
                                />
                                <br />
                              </Fragment>
                            );
                          })
                        ) : (
                          <div className="noslotdiv bg-gray rounded border-box rounded flex-1 d-flex align-items-center justify-content-center">
                            <p className="text-center font-weight-700 text-black-70">
                              {isFetchingSlot
                                ? ""
                                : !slotsArray
                                ? "No time slots available"
                                : slotsArray &&
                                  slotsArray?.length === 0 &&
                                  "No time slots available"}
                            </p>
                          </div>
                        )}

                        {/* {!isFetchingSlot &&
                        slotsArray &&
                        Object.keys.length > 0 ? (
                          <Radio.Group
                            options={slotsArray || []}
                            onChange={onChangeSlots}
                            value={selectedSlotTime}
                            optionType="button"
                            className="available_rota_list"
                          />
                        ) : (
                          <div className="noslotdiv bg-gray rounded border-box rounded flex-1 d-flex align-items-center justify-content-center">
                            <p className="text-center font-weight-700 text-black-70">
                              {isFetchingSlot
                                ? ""
                                : slotsArray &&
                                  slotsArray.length === 0 &&
                                  "No time slots available"}
                            </p>
                          </div>
                        )} */}
                      </div>
                    </Spin>
                  </div>
                )}
                {!selecetdDate && (
                  <div className="col-md-8">
                    <div className="nottimeslot d-flex align-items-center justify-content-center h-100 bg-gray rounded border-box">
                      {/* <img src={"./"} alt="No slot" /> */}
                      {/* <h6>No Time Slots Available</h6> */}
                      <p className="font-weight-700 text-black-70">
                        Select Time Slots
                      </p>
                    </div>
                  </div>
                )}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      {/* <AppinmentBookedModal
        isBookModalVisible={isBooked}
        selecteddHealthCondition={newSelecteddHealthCondition}
        patientDetail={newPatientDetail}
        hideModal={() => setIsBooked(false)}
        dateAndTime={selectedSlotTime && selectedSlotTime[0]}
      /> */}
    </div>
  );
}

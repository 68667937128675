import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, resetFormState } from "reducers/session";
import LoginForm from "containers/session/LoginForm";

// import OTPContainer from "./OTPContainer";
// import { Modal } from "reactstrap";
import { withRouter } from "react-router-dom";

class LoginContainer extends Component {
  state = {
    showOTP: false,
    phone: null,
    submitting: false,
  };
  componentDidMount() {
    // this.props.actions.resetFormState();
  }
  onSubmit = async (values) => {
    const { username, password, corporate_organization_id } = values;
    await this.setState({
      submitting: true,
    });
    await this.props.actions.login({
      user: { email: username, password },
      device_detail: {
        corporate_organization_id: corporate_organization_id,
        device_type: "web",
        player_id: "",
      },
      props: this.props,
    });
    await this.setState({
      submitting: false,
    });
    // try {
    //   await this.setState({ adding: true });
    //   let response = await loginApi({ user: { email: username, password } });
    //   const user = response.data.data;

    //   if (!user.password_expired) {
    //     await message.loading("Sending OTP...");
    //     localStorage.setItem("otp-phone", JSON.stringify(user.phone));
    //     if (user.phone) {
    //       let otpResponse = await sendOTPApi({
    //         phone: user.phone,
    //       });
    //       if (otpResponse.data.message) {
    //         successToast({ content: otpResponse.data.message });
    //         await this.props.history.push(`${appRoutesConst.verfifyotp}`);
    //       }
    //     }
    //   } else {
    //     await this.props.history.push(`${appRoutesConst.changepassword}`);
    //   }
    // } catch (error) {
    //   const { message } = getErrorObject(error);
    //   errorToast({ content: message });
    // }
  };
  render() {
    console.log("this.state.submitting", this.state.submitting);
    return (
      <Fragment>
        <LoginForm
          onSubmit={this.onSubmit}
          submitting={this.state.submitting}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ login, resetFormState }, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginContainer);

export default withRouter(ConnectedComponent);

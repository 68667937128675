import {listingReducer} from "reducers/utils/listingReducer";
import {getPatients} from "services/patients";
import {navigateTo, appRoutesConst} from "../app/navigation";

const actionTypes = {
  ON_PATIENT_SELECT: "ON_PATIENT_SELECT",
  ON_SET_PATIENT_APPOINTMENT: "ON_SET_PATIENT_APPOINTMENT",
};
const {reducer, actions, types} = listingReducer({
  name: `patients`,
  getApi: getPatients,
});

const onPatientSelect = (data) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ON_PATIENT_SELECT, payload: data});
    dispatch(navigateTo(appRoutesConst.patientDetail));
  };
};
const onPatientSelectFromAppointments = (data) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ON_PATIENT_SELECT, payload: data});
    dispatch(navigateTo(`${appRoutesConst.patientDetail}?q=from_appointments`));
  };
};

const onPatientSelectReview = ({id, type}) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ON_PATIENT_SELECT, payload: {id: id}});
    dispatch(navigateTo(`${appRoutesConst.reportsReviewDetail}?type=${type}`));
  };
};

const onPatientSelectQuestionnaire = ({id, type}) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ON_PATIENT_SELECT, payload: {id: id}});
    dispatch(
      navigateTo(`${appRoutesConst.questionnaireReviewDetail}?type=${type}`)
    );
  };
};

const onPatientSelectScriptReview = (data) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ON_PATIENT_SELECT, payload: data});
    dispatch(navigateTo(appRoutesConst.scriptReviewDetail));
  };
};

const onSetPatient = (data) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ON_PATIENT_SELECT, payload: data});
  };
};

const onSetPatientAppointment = (data) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ON_SET_PATIENT_APPOINTMENT, payload: data});
  };
};
const onPatientSelectDynamicQuestionnaire = ({id, status}) => {
  return (dispatch) => {
    dispatch({type: actionTypes.ON_PATIENT_SELECT, payload: {id: id}});
    dispatch(
      navigateTo(
        `${appRoutesConst.dynamicQuestionnaireReviewDetail}?status=${status}`
      )
    );
  };
};
const patients = reducer;
export {
  patients,
  actions,
  types,
  onPatientSelect,
  onSetPatient,
  onSetPatientAppointment,
  onPatientSelectReview,
  onPatientSelectQuestionnaire,
  onPatientSelectScriptReview,
  onPatientSelectFromAppointments,
  onPatientSelectDynamicQuestionnaire,
};

/* eslint-disable eqeqeq */
import {debounce, isArray, isEmpty, map} from "lodash";
import React, {Component, Fragment} from "react";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {
  addReferralNote,
  getReferralSpecialities,
  addConsultationSickNote,
} from "../../../services/consultations";
import {
  checkboxArray,
  disableCopy,
  errorToast,
  getErrorObject,
  showToast,
  successToast,
  verifyObject,
} from "../../../utilities/utils";
import {Radio, Input, DatePicker, Checkbox} from "antd";
import SingleCheckBox from "../../../components/common/controls/SingleCheckBox";
import moment from "moment";
import {ValidateInput} from "./SickNoteAddValidations";
import {
  DATE_FORMAT,
  InformGPStaticArrayDEV,
  InformGPStaticArrayLIVE,
  ROLE_SUPERINTENDENT_PHARMACIST,
  savedToast,
  savingToast,
} from "../../../constants/common";
import store from "app/store";
import {ValidatePrescriptionInput} from "../PrescriptionContainer/ValidatePrescriptionInput";
import PhoneInput, {isValidPhoneNumber} from "react-phone-number-input";
import {
  handleAutoSaveConsultationAPI,
  updateConsultationDetailAPI,
} from "services/patientConsultation";

export default class SickNoteAddModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //
      isShowBenefitFrom: true,
      advise: "fit",
      benefit_from: null,
      case_from: null,
      case_to: null,
      need_fitness_assessment: false,
      assessed_on: null,
      assessment_reason: "",
      follow_up_on: null,
      date_type: 1,
      no_of: null,
      isIndefinitely: false,
      duration_type: "",
      comment: "",
      start_date: null,
      end_date: null,
      errors: null,
      newSelectedCodesArray: [],
      selectedAll: false,
      selectedCodesArray: [],
      contact_info: null,
      contact_type: null,
    };
    [
      "_handleSpecialitySearch",
      "handleCheckBox",
      "_handleTextChange",
      "_submitReferralNote",
      "_clearData",
      "_handleShowModal",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSpecialitySearch, 1000);
  }

  componentDidMount() {
    let {consultationObj} = this.props;
    let SnomedCodes = verifyObject(
      consultationObj,

      "snomed_codes",
      []
    );

    SnomedCodes = map(
      SnomedCodes.filter((item1) => {
        return ![
          {snomed_code: 768361000000103},
          {snomed_code: 768321000000106},
          ...checkboxArray,
        ].some((item2) => +item2.snomed_code === +item1.code);
      }),
      "description"
    );
    this.setState({assessment_reason: SnomedCodes.join(",\n")});
  }

  async _handleSpecialitySearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          specialityArray: [],
          speciality_id: "",
          page: 1,
          searching: true,
          search: value,
        },
        async () => {
          this._getSpecialites(false);
        }
      );
    } else {
      await this.setState({specialityArray: []});
    }
  }

  async _getSpecialites(isLoadMore) {
    try {
      let response = await getReferralSpecialities({
        page: this.state.page,
        search: this.state.search,
      });

      let specialityArray = verifyObject(response, "data.data", []);
      this.setState({
        specialityArray: isLoadMore
          ? [...this.state.specialityArray, ...specialityArray]
          : specialityArray,
        searching: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }
  _handleShowModal() {
    this.setState({visible: true});
  }

  _handleTextChange(e) {
    let errors = null;
    let name = e.target.name;
    let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors}, () => {
      if (this.state[name]) {
        let data = {
          date_type: this.state.date_type,
          isIndefinitely: this.state.isIndefinitely,
          [name]: value,
        };
        const errors2 = ValidateInput(data);
        if (!errors2.isValid) {
          this.setState({errors: errors2.errors});
        }
      }
    });
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = this.state.specialityArray.find((d) => d.id == value);
    // let ids = map(this.state.selectedCodesArray, "id");

    if (found) {
      this.setState({
        specialityId: found.id,
        fieldsArray: verifyObject(
          found,
          "specialty_details.field_of_practice",
          []
        ),
      });
    }
  }
  async _submitReferralNote() {
    let {appointmentId, patientId, gpService, _onRequestList} = this.props;
    let {
      specialityId,
      field_of_practice,
      referral_reason,
      additional_note,
      referred_own_gp,
    } = this.state;
    let surgeryParams = {};
    if (gpService !== null && referred_own_gp === true) {
      surgeryParams = {
        surgery_id: gpService.id,
      };
    }

    let payload = {
      speciality_id: specialityId,
      field_of_practice: field_of_practice,
      referral_reason: referral_reason,
      additional_note: additional_note,
      referred_own_gp: referred_own_gp,
      appointment_id: appointmentId,
      patient_id: patientId,
      ...surgeryParams,
    };

    try {
      this.setState({changing: true});
      let response = await addReferralNote(payload);
      successToast({content: verifyObject(response, "data.message", "")});
      this._clearData();
      _onRequestList();
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({changing: false});
      errorToast({content: message});
    }
  }
  handleCheckBox(e) {
    this.setState({[e.target.name]: e.target.checked});
  }

  _handleRadioGroup = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      isShowBenefitFrom: e.target.value !== "not_fit" ? true : false,
    });
  };

  _handleDateRadioGroup = (e) => {
    let {start_date, end_date} = this.state;
    this.setState({
      [e.target.name]: e.target.value,
      isIndefinitely: false,
      start_date: e.target.value == 1 ? null : start_date,
      end_date: e.target.value == 1 ? null : end_date,
    });
  };

  _handleCheckboxGroup = (values) => {
    this.setState({
      benefit_from: values,
    });
  };

  _handleNumberChange(e) {
    // let number = parseInt(e.target.value, 10);
    // if (number <= 999 && number > 0) {
    //   this.setState({})
    // }
  }
  _handleDatepicker = (date, name) => {
    // let isoDate = new Date(date).toISOString();
    let errors = null;
    errors = Object.assign("", this.state.errors);
    delete errors[name];
    delete errors["dateError"];

    this.setState({[name]: date, errors: errors}, () => {
      if (this.state.start_date !== null && this.state.end_date !== null) {
        let data = {
          date_type: this.state.date_type,
          isIndefinitely: this.state.isIndefinitely,
          start_date: this.state.start_date,
          end_date: this.state.end_date,
        };
        const errors2 = ValidateInput(data);
        if (!errors2.isValid) {
          this.setState({errors: errors2.errors});
        }
      }
    });
  };
  handleAutoSaveConsultationTrigger = async (data) => {
    const {appointmentId} = this.props;
    const formData = new FormData();

    // if (isAutoSaveConsultationData) {
    this.setState({isTriggeredAutoSave: true});

    setTimeout(async () => {
      showToast(savingToast, {
        id: "handleAutoSaveConsultationAPI",
        duration: 2000,
      });
      formData.set("temp_consultation_info[appointment_id]", appointmentId);

      for (const [key, value] of Object.entries(data)) {
        if (key !== "snomed_code_data") {
          formData.set(`temp_consultation_info[${key}]`, value);
        }
      }

      try {
        this.setState({isTriggeredAutoSave: true});
        let response = await handleAutoSaveConsultationAPI(formData);
        if (response.data.status === 200) {
          showToast(savedToast, {
            id: "handleAutoSaveConsultationAPI",
          });
        }
      } catch (error) {
        console.error("Auto-save failed:", error);
      } finally {
        this.setState({isTriggeredAutoSave: false});
      }
    }, 1000);
    // }
  };
  _SaveComponentStateToRedux = ({case_from, case_to}) => {
    let {onSetDynamicInput} = this.props.dynamicTabAction;

    if (typeof onSetDynamicInput === "function") {
      let {dynamicInputs, is_autosave_consultation_data} = this.props;
      let managementPlanParams = {};

      if (this.state.isIndefinitely) {
        managementPlanParams = {
          managementPlan:
            dynamicInputs && dynamicInputs.managementPlan
              ? `${dynamicInputs.managementPlan}\nSicknote issued for indefinite period`
              : `Sicknote issued for indefinite period`,
        };
      }
      if (!this.state.isIndefinitely) {
        managementPlanParams = {
          managementPlan:
            dynamicInputs && dynamicInputs.managementPlan
              ? `${dynamicInputs.managementPlan}\nSicknote issued from ${case_from} to ${case_to}`
              : `Sicknote issued from ${case_from} to ${case_to}`,
        };
      }
      if (is_autosave_consultation_data) {
        this.handleAutoSaveConsultationTrigger({
          management_plan: this.state.isIndefinitely
            ? dynamicInputs && dynamicInputs.managementPlan
              ? `${dynamicInputs.managementPlan}\nSicknote issued for indefinite period`
              : `Sicknote issued for indefinite period`
            : dynamicInputs && dynamicInputs.managementPlan
            ? `${dynamicInputs.managementPlan}\nSicknote issued from ${case_from} to ${case_to}`
            : `Sicknote issued from ${case_from} to ${case_to}`,
        });
      }
      let payload = {
        ...dynamicInputs,
        examination: dynamicInputs ? dynamicInputs.examination : "",
        history: dynamicInputs ? dynamicInputs.history : "",
        barcodeNumber: dynamicInputs ? dynamicInputs.barcodeNumber : "",
        selectedSnomedCodesArray: dynamicInputs
          ? dynamicInputs.selectedSnomedCodesArray
          : [],
        isShowSubmit: dynamicInputs ? dynamicInputs.isShowSubmit : "",
        isConsentGiven: dynamicInputs ? dynamicInputs.isConsentGiven : "",
        reason_text: dynamicInputs ? dynamicInputs.reason_text : [],

        ...managementPlanParams,
      };

      onSetDynamicInput(payload);
    }
  };
  componentWillReceiveProps(nextProps) {
    let {dynamicInputs, is_gp_informed} = nextProps;
    let consentArray =
      is_gp_informed &&
      verifyObject(dynamicInputs, "isConsentGiven", []).length === 0
        ? process.env.REACT_APP_ENV === "dev" ||
          process.env.REACT_APP_ENV === "stage"
          ? InformGPStaticArrayDEV
          : InformGPStaticArrayLIVE
        : verifyObject(dynamicInputs, "isConsentGiven", []);
    this.setState(
      {
        selectedCodesArray:
          dynamicInputs &&
          dynamicInputs.selectedSnomedCodesArray &&
          isArray(dynamicInputs.selectedSnomedCodesArray) &&
          consentArray &&
          isArray(consentArray) &&
          consentArray.length !== 0
            ? this.removeDuplicates(
                dynamicInputs.selectedSnomedCodesArray.filter(
                  (item) =>
                    ![
                      ...consentArray,
                      {snomed_code: 768361000000103},
                      {snomed_code: 768321000000106},
                      ...checkboxArray,
                    ].some(
                      (item2) =>
                        +item.code === +item2.code ||
                        +item.code === +item2.snomed_code
                    )
                )
              )
            : dynamicInputs &&
              isArray(dynamicInputs.selectedSnomedCodesArray) &&
              dynamicInputs.selectedSnomedCodesArray.length !== 0
            ? this.removeDuplicates(
                dynamicInputs.selectedSnomedCodesArray.filter(
                  (item) =>
                    ![
                      {snomed_code: 768361000000103},
                      {snomed_code: 768321000000106},
                      ...checkboxArray,
                    ].some(
                      (item2) =>
                        +item.code === +item2.code ||
                        +item.code === +item2.snomed_code
                    )
                )
              )
            : [],
      },
      () => {
        this.removeDuplicates(this.state.selectedCodesArray);
      }
    );
    if (dynamicInputs && dynamicInputs.selectedSnomedCodesArray) {
      // let descriptions = map(
      //   dynamicInputs.selectedSnomedCodesArray.filter((item1) => {
      //     return ![
      //       {snomed_code: 768361000000103},
      //       {snomed_code: 768321000000106},
      //       ...checkboxArray,
      //     ].some((item2) => +item2.snomed_code === +item1.code);
      //   }),
      //   "description"
      // );
      // this.setState({
      //   assessment_reason: descriptions ? descriptions.join(",\n") : "",
      // });
    }
  }
  componentDidUpdate(prevProps) {
    const {
      dynamicInputs,
      //  is_gp_informed
    } = this.props;

    if (
      dynamicInputs !== prevProps?.dynamicInputs &&
      // ||
      // is_gp_informed !== prevProps?.is_gp_informed ||
      this.state.visible &&
      dynamicInputs?.selectedCodesArray &&
      this.state.selectedCodesArray.length === 0
    ) {
      let consentArray =
        process.env.REACT_APP_ENV === "dev" ||
        process.env.REACT_APP_ENV === "stage"
          ? InformGPStaticArrayDEV
          : InformGPStaticArrayLIVE;

      const updatedCodesArray = (
        dynamicInputs?.selectedSnomedCodesArray || []
      ).filter(
        (item) =>
          ![...consentArray, ...checkboxArray].some(
            (item2) =>
              +item.code === +item2.code || +item.code === +item2.snomed_code
          )
      );

      this.setState({
        selectedCodesArray: this.removeDuplicates(updatedCodesArray),
      });
    }
  }
  removeDuplicates = (stateArray) => {
    const uniqueItems = {};
    const newArray = [];
    if (stateArray) {
      for (const item of stateArray) {
        if (
          item !== undefined &&
          item.code !== undefined &&
          item.code !== null &&
          !uniqueItems[item.code]
        ) {
          if (!uniqueItems[item.code]) {
            uniqueItems[item.code] = true;
            newArray.push(item);
          }
        }
      }
      return newArray;
    }
  };
  handleDynamicKey = (key, page) => {
    let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;
    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];

        onSetSidebarKey(payload);
      }
    }
    store.dispatch({
      type: "SAVE_SELECTED_KEY",
      payload: [1],
    });

    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }
  };
  _addSickNote = async () => {
    // alert("in");
    let {
      assessment_reason,
      comment,
      isIndefinitely,
      no_of,
      duration_type,
      start_date,
      end_date,
      date_type,
      advise,
    } = this.state;
    let {_onRequestList, prev_appt_tab_key} = this.props;
    let commentParams = {};
    if (!isEmpty(comment)) {
      commentParams = {comment: comment};
    }
    let dataTobeValidate = {
      assessment_reason: assessment_reason,
      ...commentParams,
      date_type: date_type,
      isIndefinitely: isIndefinitely,
      duration_type: duration_type,
      start_date: start_date,
      end_date: end_date,
      no_of: no_of,
      advise: advise,
    };
    const errors = ValidateInput(
      dataTobeValidate,
      this.state.newSelectedCodesArray
    );
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let datesParams = {};
      if (
        dataTobeValidate.date_type == 1 &&
        dataTobeValidate.isIndefinitely === false
      ) {
        let start_date = moment().format(DATE_FORMAT);
        let end_date = null;
        if (dataTobeValidate.duration_type === "day") {
          end_date = moment().add(no_of, "day").format(DATE_FORMAT);
        }
        if (dataTobeValidate.duration_type === "week") {
          end_date = moment().add(no_of, "week").format(DATE_FORMAT);
        }
        if (dataTobeValidate.duration_type === "month") {
          end_date = moment().add(no_of, "month").format(DATE_FORMAT);
        }
        datesParams = {
          case_from: start_date,
          case_to: end_date,
        };
      }

      if (
        dataTobeValidate.date_type == 2 &&
        dataTobeValidate.isIndefinitely === false
      ) {
        datesParams = {
          case_from: dataTobeValidate.start_date.format(DATE_FORMAT),
          case_to: dataTobeValidate.end_date.format(DATE_FORMAT),
        };
      }

      if (dataTobeValidate.isIndefinitely === true) {
        datesParams = {
          case_from: moment().format(DATE_FORMAT),
          case_to: "",
        };
      }

      let {appointmentId, patientId, actual_start_time} = this.props;
      let {
        advise,
        assessment_reason,
        benefit_from,
        comment,
        need_fitness_assessment,
      } = this.state;
      let commentParams = {};
      if (!isEmpty(comment)) {
        commentParams = {comment: comment};
      }
      let downloadParams = {};
      if (this.state.contact_type === "app") {
        downloadParams = {
          contact_type: this.state.contact_type,
        };
      } else {
        downloadParams = {
          contact_type: this.state.contact_type,
          contact_info:
            this.state.contact_type === "email"
              ? this.state.email
              : this.state.phone,
        };
      }
      let payload = {
        patient_id: patientId,
        appointment_id: appointmentId,
        advise: advise,
        assessed_on: moment(actual_start_time).format(DATE_FORMAT),
        assessment_reason:
          isArray(this.state.newSelectedCodesArray) &&
          this.state.newSelectedCodesArray.length !== 0
            ? !isEmpty(assessment_reason)
              ? `${assessment_reason},${this.state.newSelectedCodesArray
                  .map((i) => i.description)
                  .join(",")}`.trim()
              : this.state.newSelectedCodesArray
                  .map((i) => i.description)
                  .join(",")
                  .trim()
            : assessment_reason.trim(),
        benefit_from: benefit_from,
        ...datesParams,
        ...commentParams,
        // follow_up_on: "Invalid Date",
        need_fitness_assessment: need_fitness_assessment,
        ...downloadParams,
      };

      // await this._SaveComponentStateToRedux(datesParams);

      try {
        this.setState({changing: true});
        let response = await addConsultationSickNote({
          sick_note: {
            // id:"",
            ...payload,
          },
        });
        if (
          (prev_appt_tab_key === "finished" ||
            prev_appt_tab_key === "reviewed" ||
            prev_appt_tab_key === "pre_consult_reviewed" ||
            prev_appt_tab_key === "kiosk_reviewed") &&
          this.props.consultation_id
        ) {
          let payload = {
            consultation_id: this.props.consultation_id,
            management_plan: this.state.isIndefinitely
              ? "Sicknote issued for indefinite period"
              : `Sicknote issued from ${datesParams.case_from} to ${datesParams.case_to}`,
          };
          if (
            prev_appt_tab_key === "in_review" ||
            prev_appt_tab_key === "reviewed" ||
            prev_appt_tab_key === "pre_consult_reviewed" ||
            prev_appt_tab_key === "kiosk_reviewed"
          ) {
            payload = {
              ...payload,
              from_review_section: true,
            };
          }
          let res = await updateConsultationDetailAPI(payload);
          console.log("UPDATE CONSULTATION---", res);
        }
        let {fromSidebar} = this.props;

        if (
          ((prev_appt_tab_key === "finished" ||
            prev_appt_tab_key === "unfinished" ||
            prev_appt_tab_key === "reviewed") &&
            !this.props.consultation_id) ||
          prev_appt_tab_key === "in_review" ||
          fromSidebar
        ) {
          await this._SaveComponentStateToRedux(datesParams);
        }
        successToast({content: verifyObject(response, "data.message", "")});
        this._clearData();
        if (_onRequestList) {
          if (typeof _onRequestList === "function") {
            _onRequestList();
          }
        }
        if (
          prev_appt_tab_key === "in_review" &&
          window.location.pathname !== "/doctor/walkin-appointment"
        ) {
          this.handleDynamicKey("1", "PatientConsultationContainer");
        }
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({changing: false});
        errorToast({content: message});
      }
    }
  };
  _clearData() {
    this.setState(
      {
        isShowBenefitFrom: true,
        advise: "not_fit",
        benefit_from: null,
        case_from: null,
        case_to: null,
        need_fitness_assessment: false,
        assessed_on: null,
        assessment_reason: "",
        follow_up_on: null,
        date_type: 1,
        no_of: null,
        isIndefinitely: false,
        duration_type: "",
        comment: "",
        start_date: null,
        end_date: null,
        changing: false,
        errors: null,
        visible: this.props.visible || false,
        selectedAll: false,
        // benefit_from:[]
      },
      () => {
        let pathName =
          window.location.pathname === "/patient/consultation_detail";
        if (pathName) {
          let {prev_appt_tab_key} = this.props;
          if (prev_appt_tab_key === "unfinished") {
            this.handleDynamicKey("90", "WalkinAppointmentContainer");
          } else {
            this.handleDynamicKey("1", "PatientConsultationContainer");
          }
        }
      }
    );
  }
  handleDynamicKey = (key, page) => {
    let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;
    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];

        onSetSidebarKey(payload);
      }
    }
    store.dispatch({
      type: "SAVE_SELECTED_KEY",
      payload: [key === "90" ? "90" : 1],
    });

    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }
  };
  handleCheckbox = (value, item) => {
    if (value) {
      this.setState(
        {
          newSelectedCodesArray: [
            item,
            ...(this.state.newSelectedCodesArray || []),
          ],
        },
        () => {}
      );
    } else {
      this.setState(
        {
          newSelectedCodesArray: this.state.newSelectedCodesArray.filter(
            (i) => +i.id !== +item.id
          ),
        },
        () => {}
      );
    }
  };
  handleSelectAll = (value) => {
    if (value) {
      this.setState(
        {
          newSelectedCodesArray: [...(this.state.selectedCodesArray || [])],
          selectedAll: value,
        },
        () => {}
      );
    } else {
      this.setState(
        {
          newSelectedCodesArray: [],
          selectedAll: value,
        },
        () => {}
      );
    }
  };
  handleChangeEmail = (e) => {
    let errors = null;
    let name = "email";
    let value = e.target.value;

    if (this.state.errors) {
      errors = Object.assign({}, this.state.errors);
      delete errors[name];
    }

    this.setState({[name]: value, errors: errors}, () => {
      if (value !== "") {
        let data = {[name]: value};
        const errors = ValidatePrescriptionInput(data);

        if (!errors.isValid) {
          this.setState({errors: errors.errors});
        }
      }
    });
  };
  handlePhone = (val) => {
    if (val) {
      let errors = null;
      let name = "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
      }
      this.setState(
        {
          [name]: value,
          errors: errors,
        },
        () => {
          if (this.state[name] !== "") {
            let data = {
              [name]: value,
            };
            const errors = ValidatePrescriptionInput(data);
            if (!errors.isValid) {
              if (errors["phone"] !== "") {
                this.setState({errors: errors.errors});
              } else {
                // this.setState({  : errors.errors });
                delete errors["phone"];
                this.setState({errors: errors});
              }
            }
          }
        }
      );
    } else {
      let errors = null;
      let name = "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
      }
      this.setState({
        errors: errors,
        [name]: value,
      });
    }
  };
  render() {
    let {
      changing,
      visible,
      advise,
      isShowBenefitFrom,
      date_type,
      no_of,
      isIndefinitely,
      errors,
      comment,
      duration_type,
      start_date,
      end_date,
      need_fitness_assessment,
      assessment_reason,
      benefit_from,
      selectedCodesArray,
    } = this.state;

    let {
      appointmentObj,
      fromSidebar,
      userRole,
      patient_detail_obj,

      // consultationObj,
    } = this.props;
    // let SnomedCodes = verifyObject(consultationObj, "snomed_codes", []);
    // SnomedCodes = map(SnomedCodes, "description");

    return (
      <div>
        {!fromSidebar && userRole !== ROLE_SUPERINTENDENT_PHARMACIST && (
          <div className="margin-top-15 dis-block text-center">
            <a
              onClick={this._handleShowModal}
              className="font-12-imp btn-custom btn-blue weight-400 in-block"
            >
              Add SickNote
            </a>
          </div>
        )}
        <ModalPopUp
          title={"Add Sick note"}
          handleCancel={changing ? false : this._clearData}
          visible={this.props.visible ? this.props.visible : visible}
          styleProps={{width: 620}}
          footer={true}
          closable={false}
          handleOk={() => {
            this._addSickNote();
          }}
          okText={"Save"}
          loading={changing}
          disabled={
            verifyObject(this.props.appointmentObj, "appointment_type") ===
              "face_to_face" ||
            verifyObject(this.props.appointmentObj, "offline_appointment") ===
              true
              ? !this.state.contact_type
                ? true
                : this.state.contact_type === "app"
                ? false
                : this.state.contact_type === "email" &&
                  (this.state.email === "" ||
                    (this.state.errors && this.state.errors.email))
                ? true
                : this.state.contact_type === "phone" &&
                  (this.state.phone === "" ||
                    !this.state.phone ||
                    (this.state.errors && this.state.errors.phone))
                ? true
                : false
              : false
          }
          cancelBtnclassName="custom_cancel_btn"
          className="width_850px sicknotepopup"
          maskClosable={false}
        >
          <div className="sick-modal-container">
            <div className="referral-form">
              <form>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">Patient Name</label>
                      <input
                        className="form-control margin-0 font-10"
                        defaultValue={verifyObject(
                          appointmentObj,
                          "booked_by",
                          ""
                        )}
                        readOnly=""
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">I assessed your case on</label>
                      <input
                        className="form-control margin-0 font-10"
                        defaultValue={
                          appointmentObj.start_time
                            ? moment(appointmentObj.start_time).format(
                                DATE_FORMAT
                              )
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">
                        And because of the following condition(s)
                      </label>

                      <div className="bg-white push-10-b coding_modal_table push-10-t">
                        <table className="table live-talk2-table">
                          <thead>
                            <tr>
                              <th className="font-10 weight-500 text-black w-33">
                                Code
                              </th>
                              <th className="font-10 weight-500 text-black w-53">
                                Description
                              </th>
                              <th className="font-10 weight-500 text-black w-13">
                                <Checkbox
                                  onClick={(e) =>
                                    this.handleSelectAll(e.target.checked)
                                  }
                                  checked={this.state.selectedAll}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {selectedCodesArray &&
                              isArray(selectedCodesArray) &&
                              selectedCodesArray.length === 0 && (
                                <tr className="ng-hide">
                                  <td
                                    colSpan="3"
                                    className="text-center text-dark-gray font-10 weight-400 default-padding"
                                  >
                                    No codes selected
                                  </td>
                                </tr>
                              )}
                            {selectedCodesArray &&
                            isArray(selectedCodesArray) &&
                            selectedCodesArray.length !== 0
                              ? (
                                  this.removeDuplicates(selectedCodesArray) ||
                                  []
                                ).map((code) => {
                                  return (
                                    <tr>
                                      <td className="font-10 weight-400 text-light-black default-padding w-33">
                                        {code.code}
                                      </td>
                                      <td className="font-10 weight-400 text-light-black default-padding w-53">
                                        {code.description}
                                      </td>
                                      <td className="font-10 weight-400 text-light-black default-padding relative w-13">
                                        {" "}
                                        <Checkbox
                                          onClick={(e) =>
                                            this.handleCheckbox(
                                              e.target.checked,
                                              code
                                            )
                                          }
                                          checked={this.state.newSelectedCodesArray.some(
                                            (item) => +item.code === +code.code
                                          )}
                                        />
                                      </td>
                                    </tr>
                                  );
                                })
                              : null}
                          </tbody>
                        </table>
                      </div>
                      <Input.TextArea
                        className={`form-control height-auto textarea font-10 placeholder-10 margin-0 ${
                          errors && errors.assessment_reason && "danger-border"
                        }`}
                        name="assessment_reason"
                        rows="1"
                        placeholder="Approach to organ, Periodic ataxia, Cerebral pyogenic abscess"
                        value={assessment_reason}
                        onChange={this._handleTextChange}
                        autoSize={true}
                      />
                      {errors && (
                        <span className="validate-danger">
                          {errors.assessment_reason}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      <label htmlFor="sel1">I advise you that</label>
                      <Radio.Group
                        onChange={this._handleRadioGroup}
                        name="advise"
                        value={advise}
                        className="dis-block-imp"
                      >
                        <Radio value={"not_fit"} className="padding-left-0-imp">
                          You are not fit for work
                        </Radio>
                        <Radio value={"fit"}>
                          You may be fit for work taking account of the
                          following advice
                        </Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                {
                  <Fragment>
                    {isShowBenefitFrom && (
                      <div className="form-group">
                        <div className="form-row">
                          <div className="col-md-12">
                            <label htmlFor="sel1" className="margin-bottom-10">
                              If available, and with your employer’s agreement,
                              you may benifit from
                            </label>
                            <Checkbox.Group
                              style={{width: "100%"}}
                              onChange={this._handleCheckboxGroup}
                              value={benefit_from}
                              className="font-10 text-dark-gray custom_check"
                            >
                              <Checkbox value="a phased return to work">
                                a phased return to work
                              </Checkbox>
                              <Checkbox value="amended duties">
                                amended duties
                              </Checkbox>
                              <Checkbox value="altered hours">
                                altered hours
                              </Checkbox>
                              <Checkbox value="workplace adaptations">
                                workplace adaptations
                              </Checkbox>
                            </Checkbox.Group>
                          </div>
                        </div>
                      </div>
                    )}
                    {advise === "not_fit" && (
                      <div className="form-group">
                        <div className="form-row">
                          <div className="col-md-12">
                            <label htmlFor="sel1">
                              Comments, including functional effects of your
                              condition(s)
                            </label>
                            <input
                              className={`form-control placeholder-10 margin-0 ${
                                errors && errors.comment && "danger-border"
                              }`}
                              name="comment"
                              value={comment}
                              placeholder="Write condition(s) here"
                              onChange={this._handleTextChange}
                            />
                            {errors && (
                              <span className="validate-danger">
                                {errors.comment}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </Fragment>
                }

                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12">
                      {/* <label htmlFor="sel1">I advise you that</label> */}
                      <Radio.Group
                        onChange={this._handleDateRadioGroup}
                        value={date_type}
                        name="date_type"
                        className="margin-top--10-imp"
                      >
                        <Radio value={1} className="padding-left-0-imp">
                          This will be the case for
                        </Radio>
                        <Radio value={2}>Or From</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-4">
                      <Input
                        className={`form-control textarea font-10 placeholder-10 margin-0 ${
                          errors && errors.no_of && "danger-border"
                        }`}
                        name="no_of"
                        type="Number"
                        disabled={date_type == 2 || isIndefinitely === true}
                        placeholder="Enter Number"
                        value={no_of}
                        onChange={this._handleTextChange}
                      />
                      {errors && (
                        <span className="validate-danger">{errors.no_of}</span>
                      )}
                    </div>

                    <div className="col-md-4">
                      <select
                        className={`form-control font-10 margin-0 ${
                          errors && errors.duration_type && "danger-border"
                        }`}
                        onChange={this._handleTextChange}
                        name="duration_type"
                        value={duration_type}
                        disabled={date_type == 2 || isIndefinitely === true}
                      >
                        <option value={""} disabled>
                          Select Type
                        </option>
                        <option value="day"> Day(s)</option>
                        <option value="week"> Week(s)</option>
                        <option value="month"> Month(s)</option>
                      </select>
                      {errors && (
                        <span className="validate-danger">
                          {errors.duration_type}
                        </span>
                      )}
                    </div>
                    <div className="col-md-4">
                      <SingleCheckBox
                        className="textarea font-10"
                        disabled={date_type == 2}
                        label="Indefinitely"
                        name="isIndefinitely"
                        checked={isIndefinitely}
                        onChange={this.handleCheckBox}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-6">
                      <DatePicker
                        className={`form-control font-10 placeholder-10 margin-0 ${
                          errors && errors.start_date && "danger-border"
                        }`}
                        format={DATE_FORMAT}
                        placeholder="Start Date"
                        value={start_date}
                        disabled={date_type == 1 || isIndefinitely === true}
                        onChange={(d) =>
                          this._handleDatepicker(d, "start_date")
                        }
                      />
                      {errors && (
                        <span className="validate-danger">
                          {errors.start_date}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        className={`form-control font-10 placeholder-10 margin-0 ${
                          errors && errors.end_date && "danger-border"
                        }`}
                        format={DATE_FORMAT}
                        placeholder="End Date"
                        disabled={date_type == 1 || isIndefinitely === true}
                        value={end_date}
                        onChange={(d) => this._handleDatepicker(d, "end_date")}
                      />
                      {errors && (
                        <span className="validate-danger">
                          {errors.end_date}
                        </span>
                      )}
                    </div>
                  </div>
                  {errors && (
                    <span className="validate-danger">{errors.dateError}</span>
                  )}
                </div>
                <div className="form-group">
                  <div className="form-row">
                    <div className="col-md-12 font-10">
                      <SingleCheckBox
                        name="need_fitness_assessment"
                        checked={need_fitness_assessment}
                        onChange={this.handleCheckBox}
                        label="I will need to assess your fitness for work again at the end of this period"
                      />
                    </div>
                  </div>
                </div>
                {
                  // verifyObject(appointmentObj, "appointment_type", false) ===
                  //   "face_to_face" &&
                  <div className="form-group">
                    <div className="form-row">
                      <div className="col-md-12">
                        <label htmlFor="sel1">Purchase via</label>

                        <div className="col-md-6 mb-3 p-0">
                          <div>
                            <Radio.Group
                              onChange={(e) =>
                                // console.log("Radio via--->", e)
                                this.setState(
                                  {
                                    contact_type: e.target.value,
                                    contact_info: null,
                                    errors: null,
                                  },
                                  () => {
                                    let {patient_detail_obj} = this.props;

                                    if (this.state.contact_type === "email") {
                                      this.setState({
                                        email: verifyObject(
                                          patient_detail_obj,
                                          "email",
                                          ""
                                        ),
                                      });
                                    } else if (
                                      this.state.contact_type === "phone"
                                    ) {
                                      this.setState({
                                        phone: verifyObject(
                                          patient_detail_obj,
                                          "phone",
                                          ""
                                        ),
                                      });
                                    }
                                  }
                                )
                              }
                              value={this.state.contact_type}
                              className="d-flex"
                            >
                              {verifyObject(
                                patient_detail_obj,
                                "is_app_user",
                                null
                              ) && <Radio value="app">Push</Radio>}

                              <Radio value="email">Email</Radio>
                              <Radio value="phone">SMS</Radio>
                            </Radio.Group>
                          </div>

                          {this.state.contact_type === "email" && (
                            <div>
                              <Input
                                value={
                                  this.state.email ? this.state.email : null
                                }
                                onChange={(e) => this.handleChangeEmail(e)}
                                placeholder="Enter valid email"
                              />
                              {this.state.errors && (
                                <span className="validate-danger">
                                  {this.state.errors.email}
                                </span>
                              )}
                              {this.state.email === "" && (
                                <span className="validate-danger">
                                  Email is required
                                </span>
                              )}
                            </div>
                          )}
                          {this.state.contact_type === "phone" && (
                            <div>
                              <PhoneInput
                                // name="phone"
                                onChange={(d) => this.handlePhone(d)}
                                value={
                                  this.state.phone ? this.state.phone : null
                                }
                                placeholder="+44 070 3298 1042"
                                defaultCountry="GB"
                                // country="GB"
                                // countries={["GB"]}
                                countryCallingCodeEditable={false}
                                international
                                addInternationalOption={false}
                                internationalIcon={null}
                                error={
                                  this.state.phone
                                    ? isValidPhoneNumber(this.state.phone)
                                      ? undefined
                                      : "Invalid mobile number"
                                    : "Mobile number required"
                                }
                                limitMaxLength={14}
                                numberInputProps={{
                                  className: `d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0`,
                                  onCopy: disableCopy,
                                  onCut: disableCopy,
                                }}
                              />
                              {this.state.errors && (
                                <span className="validate-danger">
                                  {this.state.errors.phone}
                                </span>
                              )}
                              {(this.state.phone === "" ||
                                !this.state.phone) && (
                                <span className="validate-danger">
                                  Phone is required
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </ModalPopUp>
      </div>
    );
  }
}

import React, {Fragment, useEffect, useMemo, useRef, useState} from "react";
import "@fullcalendar/react/dist/vdom";
import FullCalendar from "@fullcalendar/react";
import scrollgridPlugin from "@fullcalendar/scrollgrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimeGridPlugin from "@fullcalendar/resource-timegrid";
// import momentPlugin from "@fullcalendar/moment";
import {
  fetchAppointmentCalendarSlots,
  fetchAppointmentSlotsTypes,
  updateAppointmentFromPatientAPI,
} from "services/appointments";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import moment, {weekdays} from "moment";
import {DATE_FORMAT, DATE_FORMAT_12_HR} from "constants/common";
import {Button, Calendar, message, Modal, Popover, Radio, Tag} from "antd";
import {map, uniqBy} from "lodash";
import "./FullCalendar.css";
import TableLoader from "components/TableLoader/TableLoader";
import FullScreenHook from "components/FullScreenHook/FullScreenHook";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import BookAppointmentForPatientContainer from "./BookAppointmentForPatientContainer";
import store from "app/store";
import MordenCalendar from "components/common/controls/MordenCalendar";
import {FullscreenOutlined, FullscreenExitOutlined} from "@ant-design/icons";

import {
  ReloadOutlined,
  EditFilled,
  CopyFilled,
  CloseOutlined,
  CheckCircleFilled,
} from "@ant-design/icons";
import {IconChevronDown} from "assets/icon/IconChevronDown";
import {v4 as uuidv4, v4} from "uuid";
import isEmpty from "lodash/isEmpty";

const time_zone = Math.abs(new Date().getTimezoneOffset() * 60);
// const events = [{ title: "Meeting", start: new Date() }];

const bgColors = [
  "#eeeeee",
  "#fff1d5",
  "#e2f0ff",
  "#fce4f6",
  "#ffdfdf",
  "#dfe3e8",
  "#d6f3eb",
  "#d6defe",
  "#e2ffff",
];
const textColor = [
  "#003162",
  "#4a3710",
  "#1c4665",
  "#82266c",
  "#671818",
  "#343638",
  "#124c3b",
  "#172043",
  "#0271bd",
];

export const appointmentsStatus = {
  attended: {
    key: "attended",
    value: "Attended",
    color: "success",
  },
  not_attended: {
    key: "not_attended",
    value: "Not attended",
    color: "danger",
  },
  upcoming: {
    key: "upcoming",
    value: "Upcoming",
    color: "info",
  },
  cancelled: {
    key: "cancelled",
    value: "Cancelled",
    color: "danger",
  },
  booked: {
    key: "booked",
    value: "Booked",
    color: "primary",
  },
  ready_for_call: {
    key: "ready_for_call",
    value: "Ready for call",
    color: "warning",
  },
  on_call: {
    key: "on_call",
    value: "On call",
    color: "primary",
  },
  finished: {
    key: "finished",
    value: "Finished",
    color: "success",
  },
  patient_arrived: {
    key: "patient_arrived",
    value: "Patient Arrived",
    color: "success",
  },
  patient_not_arrived: {
    key: "patient_not_arrived",
    value: "Patient Not Arrived",
    color: "warning",
  },
  null: {
    key: "null",
    value: "-",
    color: "light",
  },
  unavailable: {
    key: "null",
    value: "-",
    color: "light",
  },
  unavailable_days: {
    key: "null",
    value: "Day Not Available",
    color: "info",
  },
  finished_oos: {
    key: "finished_oos",
    value: "Finished OOS",
    color: "danger",
  },
  submitted_to_review: {
    key: "submitted_to_review",
    value: "In Review",
    color: "warning",
  },
  call_ended: {
    key: "call_ended",
    value: "Call Ended",
    color: "success",
  },
};

class AppointmentsFullCalendarContainer extends React.Component {
  calendarComponentRef = React.createRef();

  fullScreenHookRef = React.createRef();
  state = {
    dateStart: "",
    dateEnd: "",
    fullClear: "",
    changedYes: "",
    images: null,
    appDate: new Date(),
    finalDoctorList: [],
    updatedEvent: [],
    evented_resourceIds: [],
    doctors: [],
    ids: null,
    date: null,
    selectedEvent: null,
    passedDate: null,
    updating: false,
    corporate_ids: null,
    organizations: [],
    dates: [],
    for_dates: null,
    for_dates2: null,
    is_range: null,
    isFilterOpen: false,
    isPopoverOpen: false,
    isFullScreen: false,
    bookAppointmentModalOpen: false,
    hideEventModal: false,
    editAppointmentDetails: null,
    eventDetail: null,
    info: null,
    isClear: "",
    gridDayState: "resourceTimeGridDay",
    calendarType: "day",
    selectedDay: {
      year: parseInt(moment().format("YYYY"), 10),
      month: parseInt(moment().format("MM"), 10),
      day: parseInt(moment().format("DD"), 10),
    },
    defaultDates: [],
    rangeDates: null,
    isActiveFullscreen: false,
    isShowBookNow: false,
  };

  componentDidMount() {
    this._fetchAppointmentCalendarSlots();
  }

  handleCopy = (e, link) => {
    // Prevent the span click event from triggering
    e.stopPropagation();
    // Copy link to clipboard
    navigator.clipboard
      .writeText(link)
      .then(() => {
        message.success("Text copied to clipboard");
        this.setState({isCopied: true}, () => {
          setTimeout(() => {
            this.setState({isCopied: false});
          }, 3000);
        });
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard", err);
        message.error("Failed to copy text to clipboard");
      });
  };
  closePopover = () => {
    this.setState({
      openPopover: false,
      eventId: null,
    });
  };
  renderEventDetais = (eventObj) => (
    <div>
      <ul className="list-unstyled d-flex flex-column gapy-1">
        <li className="d-flex flex-wrap align-items-center justify-content-between gapx-2">
          <div className="d-flex flex-wrap gapx-2">
            <b>Appointment ID:</b>
            <span className="flex-1">#{eventObj.eventId}</span>
          </div>
          <div className="d-flex flex-wrap gapx-2">
            <CloseOutlined
              onClick={() => {
                this.setState({
                  openPopover: false,
                });
              }}
              className="cursor-pointer text-gray"
            />
          </div>
        </li>
        <li className="d-flex flex-wrap gapx-2">
          <b>Patient Name:</b>
          <span className="flex-1">{eventObj.title}</span>
        </li>
        <li className="d-flex flex-wrap gapx-2">
          <b>Healthcare Provider:</b>
          <span className="flex-1">
            {eventObj.corporate_name?.replaceAll("(", "")?.replaceAll(")", "")}
          </span>
        </li>
        <li className="d-flex flex-wrap justify-content-between gapx-2">
          <span className="d-flex flex-wrap gapx-2">
            <b>Date/Time:</b>
            <span className="d-flex flex-wrap gapx-2 flex-1">
              <span>{moment(eventObj.start).format(DATE_FORMAT)}</span>
              <span>{moment(eventObj.start).format("hh:mm A")}</span>
              <span>-</span>
              <span>{moment(eventObj.end).format("hh:mm A")}</span>
            </span>
          </span>
          {eventObj.appointment_status === "Booked" &&
            // eventObj.appointment_type === "video" &&
            (
              <EditFilled
                className="text-blue"
                size={"large"}
                onClick={() =>
                  this.setState({
                    resourceID: eventObj.doctor_id,
                    showBookingModal: true,
                    selectedPatientId: 12990,
                    selectedPatientName: eventObj.title,
                    inEditMode: true,

                    bookingRecord: {
                      // apptDate: eventObj.start,
                      selectedAptType: eventObj.appointment_type,
                      doctor_name: eventObj.doctor_name,
                      resourceID: eventObj.doctor_id,
                      wholeSlot: [eventObj.start, eventObj.end],
                      eventId: eventObj.eventId,
                    },
                    openPopover: false,
                    eventId: null,
                  })
                }
              />
            )}
        </li>
        <li className="d-flex flex-wrap gapx-2">
          <b>Duration:</b>
          <span className="flex-1">
            {moment
              .duration(moment(eventObj.end).diff(moment(eventObj.start)))
              .asMinutes()}{" "}
            mins
          </span>
        </li>
        <li className="d-flex flex-wrap gapx-2">
          <b>Type:</b>
          <Tag className="text-capitalize ant-tag-blue">
            {eventObj.appointment_type?.replaceAll("_", " ")}
          </Tag>
        </li>
        {!isEmpty(eventObj?.reason_text) && (
          <li className="d-flex flex-wrap gapx-2">
            <b>Reason:</b>
            <span className="flex-1">
              {eventObj?.reason_text}{" "}
              {eventObj.appointment_tag && eventObj.appointment_tag !== "" ? (
                <Tag className="text-capitalize ant-tag-blue">
                  {eventObj.appointment_tag.replaceAll("_", " ")}
                </Tag>
              ) : null}
            </span>
          </li>
        )}

        {(eventObj.apptStatus === "booked" ||
          eventObj.apptStatus === "ready_for_call") &&
          // eventObj.appointment_type === "video" &&
           (
            <li className="d-flex flex-wrap gapx-2">
              <b>Patient Link:</b>
              {/*This is a dummy link for UI purpose*/}
              <a
                className="flex-1 popup-text-overflow"
                href={eventObj.patient_joining_url}
                target="__blank"
              >
                {eventObj.patient_joining_url}
              </a>
              {!this.state.isCopied && (
                <CopyFilled
                  onClick={(e) =>
                    this.handleCopy(e, eventObj.patient_joining_url)
                  }
                  className="cursor-pointer"
                />
              )}
              {this.state.isCopied && (
                <CheckCircleFilled className="text-success" />
              )}
            </li>
          )}
        <li className="d-flex flex-wrap gapx-2">
          <b>Clinician Name:</b>
          <span className="flex-1">{eventObj.doctor_name}</span>
        </li>
      </ul>
    </div>
  );

  renderEmptyEventDetais = (eventObj) => {
    console.log("🚀 ~ AppointmentsFullCalendarContainer ~ eventObj:", eventObj);
    return (
      <div>
        <ul className="list-unstyled d-flex flex-column gapy-1">
          <li className="d-flex flex-wrap align-items-center justify-content-between gapx-2">
            <a
              onClick={() => this.bookAppointmentFromEmptySlot(eventObj)}
              className="d-flex flex-wrap gapx-2"
            >
              Book Now
            </a>
          </li>
        </ul>
      </div>
    );
  };
  bookAppointmentFromEmptySlot = async (e) => {
    const slotTime = moment(e?.start).format(`hh:mm A`);
    const slotTimeStart = moment(e?.start).format();
    console.log("slotTimeStart:", e);
    const now = new Date();
    const passedDate = e?.start;
    if (now > passedDate) {
      message.destroy();
      message.info({
        content: "You can't book an appointment for past date/time",
        duration: 1,
      });
      this.setState({
        isOutFromFullscreen: true,
      });
      return;
    }

    const slotTimeEnd = moment(e?.start).add(15, "minutes").format();
    const wholeSlot = [slotTimeStart, slotTimeEnd];
    try {
      const response = await fetchAppointmentSlotsTypes({
        doctor_id: e.resourceId,
        start_time: slotTimeStart,
      });
      console.log("response", response);
      const slotTypes = response.data.data?.split(",");
      let newSlotTypes = [];
      slotTypes.map((slot) => {
        if (slot === "online_appointment") {
          newSlotTypes.push("video");
        } else if (slot === "offline_appointment") {
          newSlotTypes.push("face_to_face");
        }
        return null;
      });
      this.setState({
        inEditMode: false,
        selectedPatientId: null,
        selectedPatientName: null,
        isOutFromFullscreen: true,
        resourceID: e.resourceId,
        showBookingModal: true,
        dateEventClicked: true,
        bookingRecord: {
          apptDate: e.start,
          apptTime: slotTime,
          selectedAptType: this.props.selectedAptType,
          doctor_name: e.doctor_name,
          resourceID: e.resourceId,
          wholeSlot: wholeSlot,
        },
        allowedSlotTypes: newSlotTypes,
      });
    } catch {}
  };
  renderEventContent = (eventInfo) => {
    console.log("eventInfo", eventInfo.event._def?.extendedProps);
    console.log("eventInfo", eventInfo);

    const eventObj = eventInfo.event._def?.extendedProps;
    console.log("eventObj.title", eventObj.title);
    // if (eventObj.isEmptyEvent) {
    //   return (
    //     <div
    //       className="empty-slot-event"
    //       style={{ display: "flex", justifyContent: "center", paddingTop: 10 }}
    //       onClick={() => this.bookAppointmentFromEmptySlot(eventObj)}
    //       // onMouseEnter={() => {
    //       //   this.setState({
    //       //     isShowBookNow: !this.state.isShowBookNow,
    //       //     eventId: eventObj.eventId,
    //       //   });
    //       // }}
    //       // onMouseLeave={() => {
    //       //   this.setState({
    //       //     isShowBookNow: false,
    //       //   });
    //       // }}
    //     >
    //       {
    //         // <Button
    //         //   type="primary"
    //         //   style={{
    //         //     marginTop: "10px",
    //         //     width: 105,
    //         //   }}
    //         //   onClick={() => this.bookAppointmentFromEmptySlot(eventObj)}
    //         // >
    //         //   Book Now
    //         // </Button>
    //       }
    //     </div>
    //   );
    // } else {
    return (
      <Popover
        // visible={
        //   this.state.openPopover && eventObj.eventId === this.state.eventId
        // }
        // trigger={"hover"}
        overlayClassName="appointment-calendar-popover"
        content={this.renderEventDetais(eventObj)}
        // autoAdjustOverflow
      >
        <div
          style={{height: "inherit"}}
          onClick={() => {
            this.setState({
              openPopover: true,
              eventId: eventObj.eventId,
              isOutFromFullscreen: true,
            });
          }}
          className="d-flex flex-column gapy-1 event-slot overflow-hidden"
        >
          <b
            title={eventObj.title}
            className="d-flex flex-wrap align-items-center gapx-1"
          >
            <span
              className={`event-slote-title${
                eventObj.appointment_status
                  ? ` ${eventObj.appointment_status
                      .toLowerCase()
                      .replace(/\s/g, "-")}`
                  : ""
              }`}
            >
              <span className="ellipsis-1-line">{eventObj.title}</span>
            </span>
            <span>-</span>
            <span className="event-slote-status">{eventObj.appointment_status}</span>
            {eventObj.appointment_status === "Booked" &&
              // eventObj.appointment_type === "video" &&
               (
                <EditFilled
                  className="text-blue"
                  size={"large"}
                  onClick={() =>
                    this.setState({
                      resourceID: eventObj.doctor_id,
                      showBookingModal: true,
                      selectedPatientId: 12990,
                      selectedPatientName: eventObj.title,
                      inEditMode: true,

                      bookingRecord: {
                        // apptDate: eventObj.start,
                        selectedAptType: eventObj.appointment_type,
                        doctor_name: eventObj.doctor_name,
                        resourceID: eventObj.doctor_id,
                        wholeSlot: [eventObj.start, eventObj.end],
                        eventId: eventObj.eventId,
                      },
                      openPopover: false,
                      eventId: null,
                    })
                  }
                />
              )}
          </b>
          <div className="tag-parent">
            <Tag className="text-capitalize ant-tag-blue">
              {eventObj.appointment_type?.replaceAll("_", " ")}
            </Tag>
            {/* {""} */}
            {/* {!isEmpty(eventObj?.reason_text) && (
              <Fragment>
                | <b>{eventObj?.reason_text}</b>
              </Fragment>
            )} */}
          </div>
        </div>
      </Popover>
    );
    //
  };

  componentDidUpdate(prevProps, prevState) {
    console.log(
      "this.props.isRotaAdded",
      this.props.isRotaAdded,
      prevProps,
      this.props
    );
    // console.log("prevProps",prevProps)

    if (this.props.isRotaAdded) {
      // alert("Rota Added");
      store.dispatch({
        type: "SET_ROTA_ADDED",
        payload: false,
      });
      let {defaultDates} = prevState;
      this._fetchAppointmentCalendarSlots(null, defaultDates.toString());
    }
  }

  _fetchAppointmentCalendarSlots = async (passedate, dates) => {
    console.log(
      "🚀 ~ AppointmentsFullCalendarContainer ~ _fetchAppointmentCalendarSlots= ~ dates:",
      dates
    );
    console.log(
      "🚀 ~ AppointmentsFullCalendarContainer ~ _fetchAppointmentCalendarSlots= ~ passedate:",
      passedate
    );
    try {
      // setLoadingCalendarAPI(true);
      this.setState(
        {
          loadingCalendarAPI: true,
        },
        async () => {
          let response = await fetchAppointmentCalendarSlots({
            date: dates
              ? dates
              : moment(passedate ? passedate : this.props.selecetdDate).format(
                  DATE_FORMAT
                ),
            zone: time_zone,
            is_zone: true,
            doctor_ids: this.props.user?.id,
          });
          console.log(
            "🚀 ~ const_fetchAppointmentCalendarSlots= ~ response:",
            response?.data?.data
          );
          let allEvents = [];
          let finalDoctorList = [];
          let id = "";
          let title = "";
          let start = "";
          let newKey = 0;
          await Object.keys(response?.data?.data).map((key) => {
            // console.log("response")
            response?.data?.data[key].map((evt) => {
              if (evt.resourceId == null) {
                id = "all";
                title = "Unassigned Appointments";
              } else {
                const CounterValues = map(
                  Object.values(response?.data?.data[key]).filter((d) => {
                    if (evt?.resourceId) {
                      return (
                        d.resourceId === evt.resourceId &&
                        d.max_appointment_counter !== undefined &&
                        d.max_appointment_counter !== null
                      );
                    }
                  }),
                  "max_appointment_counter"
                );
                console.log("MAXVAL", CounterValues);
                console.log("MAXVAL 1", CounterValues);

                const maxCounterValues =
                  CounterValues?.length > 0 ? Math.max(...CounterValues) : 0;

                id = evt.resourceId;
                title = `${evt.doctor_name} - ${maxCounterValues}`;
                start = evt.start;
              }
              if (!finalDoctorList.some((doc) => doc.title === title)) {
                finalDoctorList.push({
                  id: id,
                  title: title,
                  eventTextColor: textColor[newKey],
                  eventBackgroundColor: bgColors[newKey],
                  eventBorderColor: bgColors[newKey],
                  extendedProps: {
                    appointment_status: evt.status,
                    gender_preference: evt.doctor_preference,
                    is_event_rendered:
                      start !== "" && start !== undefined ? start : null,
                  },
                });
                newKey >= textColor.length - 1 ? (newKey = 0) : newKey++;
              }
              console.log(
                "🚀 ~ const_fetchAppointmentCalendarSlots= ~ finalDoctorList:",
                finalDoctorList
              );
            });
          });
          await Object.keys(response?.data?.data).map((key) => {
            response?.data?.data[key].map((evt) => {
              if (evt.resourceId == null) {
                id = "all";
                title = "Unassigned Appointments";
              } else {
                const CounterValues = [];
                const maxCounterValues = Math.max(...CounterValues);
                id = evt.resourceId;
                title =
                  evt.max_appointment_counter !== undefined &&
                  evt.max_appointment_counter !== Infinity
                    ? `${evt.doctor_name} - ${maxCounterValues}`
                    : `${evt.doctor_name}`;
                start = evt.start;
              }
              // if (evt?.id) {
              console.log("EEVENT START", evt);
              // }
              let eventObj = {
                // id: getUniqueDomId(),
                resourceId: evt.resourceId ? evt.resourceId : "all",
                title: evt.title
                  ? `${evt.title} - ${
                      appointmentsStatus[evt.status] &&
                      appointmentsStatus[evt.status].value
                        ? appointmentsStatus[evt.status].value
                        : appointmentsStatus[evt.status].replaceAll(/_/g, " ")
                    }
              ${
                evt.corporate_name
                  ? `(${evt.corporate_name}) \n #${evt.id}`
                  : ""
              }
              `
                  : "",
                id: evt.id ? evt.id : null,
                start: evt.start ? new Date(evt.start) : null,
                end: evt.end ? new Date(evt.end) : null,
                startStr: evt.start
                  ? moment(evt.start).format(DATE_FORMAT_12_HR)
                  : null,
                endStr: evt.start
                  ? moment(evt.end).format(DATE_FORMAT_12_HR)
                  : null,
                editable:
                  evt.status === "unavailable" ||
                  evt.status === "unavailable_days"
                    ? false
                    : true,
                eventTextColor: textColor[newKey],
                eventBackgroundColor: bgColors[newKey],
                eventBorderColor: bgColors[newKey],
                eventDurationEditable: true,
                className:
                  (evt.status === "unavailable" ||
                    evt.status === "unavailable_days") &&
                  "disbaled-event",
                durationEditable: false,
                resourceEditable: true,
                overlap:
                  evt.status === "unavailable" ||
                  evt.status === "unavailable_days"
                    ? false
                    : true,
                recordId: `${uuidv4()}_${Math.random()}`,
                apptStatus: evt.status,
                extendedProps: {
                  appointment_status: evt.status
                    ? appointmentsStatus[evt.status].value
                    : null,
                  gender_preference: evt.doctor_preference,
                  is_event_rendered: evt.start,
                  start: evt.start,
                  end: evt.end,
                  title: evt.title,
                  corporate_name: evt.corporate_name
                    ? `(${evt.corporate_name})`
                    : null,
                  appointment_type: evt.appointment_type
                    ? evt.appointment_type
                    : null,
                  doctor_name: evt?.doctor_name,
                  doctor_id: evt.resourceId,
                  eventId: evt.id,
                  patient_joining_url: evt.patient_joining_url,
                  reason_text: evt?.reason_text,
                  appointment_tag: evt?.appointment_tag,

                  apptStatus: evt.status,
                },
              };
              if (eventObj.className === "disbaled-event") {
                console.log("eventObj s", eventObj.start.toDateString());
                console.log("eventObj e", eventObj.end.toDateString());
              }
              newKey >= textColor.length - 1 ? (newKey = 0) : newKey++;
              allEvents.push(eventObj);
            });
            // console.log("updatedEvent", updatedEvent);
          });
          // const dates = [];
          console.log(
            "All Filterd Dates",
            allEvents.filter(
              (d) => d.resourceId !== null && d.resourceId !== undefined
            )[0]?.resourceId
          );
          const allEmptyEvents = [];
          const allDates = [];
          const allBookedUnATimes = map(
            allEvents.filter(
              (d) =>
                d.apptStatus === "unavailable" ||
                d.apptStatus === "unavailable_days" ||
                d.apptStatus === "booked"
            ),
            "startStr"
          );

          const now = moment()
            .hour(new Date().getHours() - 2)
            .minute(0)
            .seconds(0);
          const deadline = moment().hour(23).minute(0).seconds(0);

          while (now.diff(deadline) < 0) {
            if (now > moment(now).hour(new Date().getHours())) {
              allDates.push(now.toDate());
            }
            now.add(15, "minutes");
          }
          allDates.map((d, i) => {
            console.log("ALL", moment(d).format(DATE_FORMAT_12_HR));
            if (
              !allBookedUnATimes.includes(moment(d).format(DATE_FORMAT_12_HR))
            ) {
              allEmptyEvents.push({
                start: allDates[i],
                end: allDates[i + 1],
                title: "Empty Event",
                className: "empty-event",
                editable: false,
                resourceId: allEvents.filter(
                  (d) => d.resourceId !== null && d.resourceId !== undefined
                )[0]?.resourceId,
                id: v4(),
                isEmptyEvent: true,
                startStr: allDates[i]
                  ? moment(allDates[i]).format(DATE_FORMAT_12_HR)
                  : null,
                endStr: allDates[i + 1]
                  ? moment(allDates[i + 1]).format(DATE_FORMAT_12_HR)
                  : null,
                doctor_name: allEvents.filter(
                  (d) => d.resourceId !== null && d.resourceId !== undefined
                )[0]?.extendedProps?.doctor_name,
                extendedProps: {
                  isEmptyEvent: true,
                  appointment_status: "empty",
                  gender_preference: null,
                  is_event_rendered: now.toDate(),
                  start: allDates[i],
                  end: allDates[i + 1],
                  title: "Empty Event",
                  corporate_name: null,
                  appointment_type: "empty",
                  doctor_name: null,
                  doctor_id: null,
                  eventId: v4(),
                  patient_joining_url: null,
                  resourceId: allEvents.filter(
                    (d) => d.resourceId !== null && d.resourceId !== undefined
                  )[0]?.resourceId,
                },
                recordId: `${uuidv4()}_${Math.random()}`,
              });
            }
            return null;
          });
          this.setState(
            {
              updatedEvent:
                allEvents.length > 0
                  ? [
                      // ...allEmptyEvents,
                      ...uniqBy(allEvents, "id"),
                      ...uniqBy(
                        allEvents.filter(
                          (d) =>
                            d.id === null &&
                            d.end_time !== null &&
                            d.start_time !== null
                        ),
                        "endStr"
                      ),
                    ]
                  : [
                      {
                        resourceId: "all",
                        id: null,
                        start: new Date().setUTCHours(0, 0, 0, 0),
                        end: new Date().setUTCHours(23, 59, 59, 999),
                        editable: false,
                        eventTextColor: textColor[newKey],
                        eventBackgroundColor: bgColors[newKey],
                        eventBorderColor: bgColors[newKey],
                        eventDurationEditable: true,
                        className: "disbaled-event",
                        durationEditable: false,
                        resourceEditable: true,
                        overlap: false,
                        title: "No Rotas Available",
                      },
                    ],
              finalDoctorList:
                finalDoctorList.length > 0
                  ? finalDoctorList
                  : [
                      {
                        id: "all",
                        title: "No Clinician Available",
                        eventTextColor: `rgba(220, 53, 69, 0.7)`,
                        eventBackgroundColor: `#ffd9db`,
                        eventBorderColor: `#ffd9db`,
                      },
                    ],
              loadingCalendarAPI: false,
            },
            () => {
              const allClasess = document.querySelectorAll(
                ".fc-timegrid-slots > table > tbody"
              );
              allClasess.forEach((clasess) => {
                clasess?.childNodes?.forEach((d) => {
                  d.childNodes.forEach((v) => {
                    console.log(
                      "this.state.calendarType",
                      this.state.calendarType
                    );
                    if (
                      this.state.calendarType === "days" ||
                      this.state.calendarType === "day"
                    ) {
                      // v.style.backgroundColor ="#1445b7";
                      v?.classList?.add("empty-slot-event");
                    } else {
                      v?.classList?.add("empty-slot-event-week");
                      v?.classList?.remove("empty-slot-event");
                    }
                  });
                });
              });
            }
          );
        }
      );
    } catch (e) {
      console.log("🚀 ~ const_fetchAppointmentCalendarSlots= ~ e:", e);
      const {message} = getErrorObject(e);
      errorToast({
        content: message,
      });
      // setLoadingCalendarAPI(false);
    }
  };

  handleDirection = async (direction) => {
    const calendarAPI = this.calendarComponentRef?.current.getApi();
    if (direction === "next") {
      await calendarAPI.next();
      await calendarAPI.changeView(this.state.gridDayState);

      let defaultDates = [];

      let currDate = moment(calendarAPI.view.currentStart);
      let lastDate = moment(calendarAPI.view.currentEnd);
      if (
        this.state.calendarType === "week" ||
        this.state.calendarType === "days"
      ) {
        if (this.state.calendarType === "days") {
          defaultDates.push(calendarAPI.view.currentStart);
        } else {
          defaultDates.push(currDate.format(DATE_FORMAT));
        }
      }
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        defaultDates.push(currDate.format(DATE_FORMAT));
      }
      if (this.state.calendarType === "week_days") {
        defaultDates.pop();
      }
      console.log("defaultDates", defaultDates);
      if (
        this.state.calendarType === "week_days" ||
        this.state.calendarType === "week"
      ) {
        if (this.state.defaultDates.length === 1) {
          this._fetchAppointmentCalendarSlots(defaultDates.toString(), null);
        } else {
          this._fetchAppointmentCalendarSlots(null, defaultDates.toString());
        }
        this.setState({
          defaultDates: defaultDates,
          // calendarType:
        });
      } else {
        console.log("currDate", moment(currDate._d).format(DATE_FORMAT));
        const nextDate = calendarAPI.getCurrentData().currentDate;
        this._fetchAppointmentCalendarSlots(
          null,
          moment(nextDate).format(DATE_FORMAT)
        );
        this.setState({
          defaultDates: [moment(nextDate).format(DATE_FORMAT)],
          calendarType:
            moment(nextDate).format(DATE_FORMAT) ===
            moment().format(DATE_FORMAT)
              ? "day"
              : undefined,
        });
      }
      console.log(
        "Next defaultDates",
        moment(calendarAPI.view.currentStart).format(DATE_FORMAT)
      );
    } else {
      await calendarAPI.prev();
      await calendarAPI.changeView(this.state.gridDayState);
      let defaultDates = [];

      let currDate = moment(calendarAPI.view.currentStart);
      let lastDate = moment(calendarAPI.view.currentEnd);

      if (
        this.state.calendarType === "week" ||
        this.state.calendarType === "days"
      ) {
        if (this.state.calendarType === "days") {
          defaultDates.push(calendarAPI.view.currentStart);
        } else {
          defaultDates.push(currDate.format(DATE_FORMAT));
        }
      }
      while (currDate.add(1, "days").diff(lastDate) < 0) {
        defaultDates.push(currDate.format(DATE_FORMAT));
      }
      if (this.state.calendarType === "week_days") {
        defaultDates.pop();
      }
      if (
        this.state.calendarType === "week_days" ||
        this.state.calendarType === "week"
      ) {
        if (this.state.defaultDates.length === 1) {
          this._fetchAppointmentCalendarSlots(defaultDates.toString(), null);
        } else {
          this._fetchAppointmentCalendarSlots(null, defaultDates.toString());
        }
        this.setState({
          defaultDates: defaultDates,
        });
      } else {
        console.log("currDate", moment(lastDate._d).format(DATE_FORMAT));
        const preVDate = calendarAPI.getCurrentData().currentDate;
        this._fetchAppointmentCalendarSlots(
          null,
          moment(preVDate).format(DATE_FORMAT)
        );
        this.setState({
          defaultDates: [moment(preVDate).format(DATE_FORMAT)],
          calendarType:
            moment(preVDate).format(DATE_FORMAT) ===
            moment().format(DATE_FORMAT)
              ? "day"
              : undefined,
        });
      }
    }
  };

  handleCalendarType = ({target: {value}}) => {
    console.log("🚀 ~ handleCalendarType ~ type:", value);
    const calendarAPI = this.calendarComponentRef?.current.getApi();
    // setCalendarType(value);
    this.setState(
      {
        calendarType: value,
      },
      () => {
        switch (value) {
          case "day":
            calendarAPI?.changeView("resourceTimeGridDay");
            calendarAPI.gotoDate(new Date());
            // let currDate = moment(calendarAPI.view.currentStart);
            console.log(
              "🚀 ~ AppointmentsFullCalendarContainer ~ calendarAPI.view.currentStart:",
              calendarAPI.view.currentStart
            );
            // let lastDate = moment(calendarAPI.view.currentEnd);
            // let defaultDates = [];

            // defaultDates.push(currDate.format(DATE_FORMAT));
            // while (currDate.add(1, "days").diff(lastDate) < 0) {
            //   defaultDates.push(currDate.clone().format(DATE_FORMAT));
            // }
            this.setState({
              gridDayState: "resourceTimeGridDay",
              defaultDates: [moment().format(DATE_FORMAT)],
            });

            this._fetchAppointmentCalendarSlots(
              null,
              moment().format(DATE_FORMAT)
            );
            break;
          case "week_days":
            calendarAPI?.changeView("resourceTimeGridWeek");
            let currDateWeekDays = moment(calendarAPI.view.currentStart);
            let lastDateWeekDays = moment(calendarAPI.view.currentEnd);
            console.log(
              "🚀 ~ handleCalendarType ~ lastDateWeek:",
              lastDateWeekDays
            );
            console.log(
              "🚀 ~ handleCalendarType ~ currDateWeek:",
              currDateWeekDays
            );

            let defaultDatesWeekDays = [];
            let arrayWeekDays = new Array(5);
            console.log("🚀 ~ handleCalendarType ~ array:", arrayWeekDays);
            // defaultDates.push(currDateWeek.format(DATE_FORMAT));
            // defaultDatesWeekDays.push(currDateWeekDays.format(DATE_FORMAT));
            for (let index = 0; index < 5; index++) {
              defaultDatesWeekDays.push(
                currDateWeekDays.add(1, "days").format(DATE_FORMAT)
              );
            }
            console.log("defaultDatesWeekDays", defaultDatesWeekDays);

            this._fetchAppointmentCalendarSlots(
              null,
              defaultDatesWeekDays?.toString()
            );
            this.setState(
              {
                gridDayState: "resourceTimeGridWeek",
                defaultDates: defaultDatesWeekDays,
              },
              () => {
                let {defaultDates} = this.state;
                let rangeDates = null;
                if (defaultDates.length > 0) {
                  rangeDates = {
                    from: {
                      year: parseInt(defaultDates[0]?.split("-")[2], 10),
                      month: parseInt(defaultDates[0]?.split("-")[1], 10),
                      day: parseInt(defaultDates[0]?.split("-")[0], 10),
                    },
                    to: {
                      year: parseInt(
                        defaultDates[defaultDates.length - 1]?.split("-")[2],
                        10
                      ),
                      month: parseInt(
                        defaultDates[defaultDates.length - 1]?.split("-")[1],
                        10
                      ),
                      day: parseInt(
                        defaultDates[defaultDates.length - 1]?.split("-")[0],
                        10
                      ),
                    },
                  };
                }
                this.setState({
                  rangeDates,
                });
              }
            );
            break;
          case "week":
            calendarAPI?.changeView("resourceTimeGridWeek");
            let currDateWeek = moment(calendarAPI.view.currentStart);
            let lastDateWeek = moment(calendarAPI.view.currentEnd);
            console.log(
              "🚀 ~ handleCalendarType ~ lastDateWeek:",
              lastDateWeek
            );
            console.log(
              "🚀 ~ handleCalendarType ~ currDateWeek:",
              currDateWeek
            );

            let defaultDates = [];
            let array = new Array(7);
            console.log("🚀 ~ handleCalendarType ~ array:", array);
            // defaultDates.push(currDateWeek.format(DATE_FORMAT));
            defaultDates.push(currDateWeek.format(DATE_FORMAT));
            for (let index = 0; index < 6; index++) {
              defaultDates.push(
                currDateWeek.add(1, "days").format(DATE_FORMAT)
              );
            }
            console.log("defaultDates", defaultDates);

            this._fetchAppointmentCalendarSlots(null, defaultDates?.toString());
            this.setState(
              {
                gridDayState: "resourceTimeGridWeek",
                defaultDates: defaultDates,
              },
              () => {
                let {defaultDates} = this.state;

                let rangeDates = null;
                if (defaultDates.length > 0) {
                  rangeDates = {
                    from: {
                      year: parseInt(defaultDates[0]?.split("-")[2], 10),
                      month: parseInt(defaultDates[0]?.split("-")[1], 10),
                      day: parseInt(defaultDates[0]?.split("-")[0], 10),
                    },
                    to: {
                      year: parseInt(
                        defaultDates[defaultDates.length - 1]?.split("-")[2],
                        10
                      ),
                      month: parseInt(
                        defaultDates[defaultDates.length - 1]?.split("-")[1],
                        10
                      ),
                      day: parseInt(
                        defaultDates[defaultDates.length - 1]?.split("-")[0],
                        10
                      ),
                    },
                  };
                }
                this.setState({
                  rangeDates,
                });
              }
            );
            break;
          default:
            break;
        }
      }
    );
  };

  eventDrop = async (e) => {
    let reqObj = {
      id: e.event.id,
      start_time: e.event.start,
      user_id: e.event?._def?.resourceIds[0],
    };
    let messageConfig = Modal;
    // Modal.de
    console.log("e.event", e.event);
    try {
      // messageConfig.info({
      //   content: "Updating Appointment...",
      //   closable: false,
      //   cancelButtonProps: {
      //     style: {
      //       display: "none",
      //     },
      //   },
      //   okButtonProps: {
      //     style: {
      //       display: "none",
      //     },
      //   },
      // });
      this.setState({
        updatingApt: true,
      });
      let response = await updateAppointmentFromPatientAPI(reqObj);
      console.log("response", response);
      // setCalendarEvents(allEvents);
      if (response.data.status === 200) {
        successToast({
          content: response.data.message,
        });
        messageConfig.destroyAll();
        // if (this.state.defaultDates.length === 1) {
        //   this._fetchAppointmentCalendarSlots(
        //     this.state.defaultDates.toString(),
        //     null
        //   );
        // } else {
        //   this._fetchAppointmentCalendarSlots(
        //     null,
        //     this.state.defaultDates.toString()
        //   );
        // }
        this._fetchAppointmentCalendarSlots(
          null,
          this.state.defaultDates?.toString()
        );

        this.setState({
          updatingApt: false,
        });
      }
    } catch (e) {
      const {message} = getErrorObject(e);
      // if (this.state.defaultDates.length === 1) {
      //   this._fetchAppointmentCalendarSlots(
      //     this.state.defaultDates.toString(),
      //     null
      //   );
      // } else {
      //   this._fetchAppointmentCalendarSlots(
      //     null,
      //     this.state.defaultDates.toString()
      //   );
      // }
      this._fetchAppointmentCalendarSlots(
        null,
        this.state.defaultDates?.toString()
      );

      errorToast({
        content: message,
      });
      // messageConfig.destroyAll();
      this.setState({
        updatingApt: false,
      });
    }
    console.log("reqObj", reqObj);
  };
  _onRefresh = () => {
    let {defaultDates} = this.state;
    console.log(
      "🚀 ~ AppointmentsFullCalendarContainer ~ defaultDates:",
      defaultDates
    );
    // if (defaultDates.length === 1) {
    //   this._fetchAppointmentCalendarSlots(null,defaultDates.toString());
    // } else {
    // }
    this.setState(
      {
        openPopover: false,
      },
      () => {
        this._fetchAppointmentCalendarSlots(null, defaultDates.toString());
      }
    );
  };

  _handleDate = (dates) => {
    console.log("dates", dates);
    // if (this.state.calendarType === "day") {
    let day =
      dates.day.toString().length >= 2 ? dates.day.toString() : `0${dates.day}`;
    let month =
      dates.month.toString().length >= 2
        ? dates.month.toString()
        : `0${dates.month}`;

    let fullDate = `${dates.year}-${month}-${day}`.toString();
    this.setState(
      {
        selectedDay: dates,
        date:
          verifyObject(moment(fullDate), "_d", null) !== ""
            ? moment(fullDate)._d.toString()
            : null,
        calendarType:
          moment().format(DATE_FORMAT) === `${day}-${month}-${dates.year}`
            ? "day"
            : undefined,
        defaultDates: [`${day}-${month}-${dates.year}`],
        gridDayState: "resourceTimeGridDay",
      },
      () => {
        const calendarAPI = this.calendarComponentRef?.current.getApi();
        calendarAPI.changeView("resourceTimeGridDay");
        calendarAPI.gotoDate(new Date(this.state.date));
        this._fetchAppointmentCalendarSlots(this.state.date);
      }
    );
    // } else if (this.state.calendarType === "week_days") {
    // } else {
    //   //week
    // }
  };
  render() {
    let {loadingCalendarAPI, selectedDay, defaultDates, rangeDates} =
      this.state;
    const calendarAPI = this.calendarComponentRef?.current?.getApi();
    if (calendarAPI) {
      console.log("calendarAPI", calendarAPI?.getCurrentData());
    }
    // console.log(
    //   "Resource ID",
    //   document
    //     ?.getElementsByClassName("fullscreen")[0]
    //     ?.classList.contains("fullscreen-enabled")
    // );

    return (
      <div className="push-10-t">
        <div className="upcoming_appt_list">
          <div className="bookaptcalenderwrap h-100 d-flex flex-column appointment-calendar_wrapper">
            {/* <FullScreenHook
              CloseFullScreen={() => {
                this.setState({ isOutFromFullscreen: false });
              }}
              isOutFromFullscreen={this.state.isOutFromFullscreen}
              toggleFullScreen={() => {
                this.setState({
                  isActiveFullscreen: !this.state.isActiveFullscreen,
                });
              }}
            > */}

            <div className="d-flex flex-wrap justify-content-end gapx-1 mb-3">
              {calendarAPI && (
                <Popover
                  content={
                    <MordenCalendar
                      selectedDay={selectedDay}
                      onsetSelectedDay={this._handleDate}
                      date={this.state.date}
                      className="morden-cl-wrapper"
                    />
                  }
                  // trigger={
                  //   !this.state.isOutFromFullscreen ? "hover" : "click"
                  // }
                  // onVisibleChange={(d) => {
                  //   if (this.state.isOutFromFullscreen === false && d) {
                  //     this.setState({ isOutFromFullscreen: true });
                  //   }
                  // }}
                  // getPopupContainer={() =>
                  //   this.state.isActiveFullscreen
                  //     ? document.getElementsByClassName("fullscreen")[0]
                  //     : document.body
                  // }
                  autoAdjustOverflow={true}
                >
                  <a
                    style={{
                      pointerEvents: this.state.isActiveFullscreen
                        ? "none"
                        : "all",
                      cursor: this.state.isActiveFullscreen
                        ? "not-allowed"
                        : "pointer",
                    }}
                    className="mr-auto d-flex flex-wrap align-items-center gapx-2"
                  >
                    <span className="calendar-title">
                      {this.state.calendarType === "week" ||
                      this.state.calendarType === "week_days"
                        ? `${
                            calendarAPI
                              ?.getCurrentData()
                              ?.viewTitle?.split(",")?.[0]
                          } ${calendarAPI
                            ?.getCurrentData()
                            ?.viewTitle?.slice(12)}`
                        : moment(
                            calendarAPI?.getCurrentData()?.viewTitle
                          ).format("Do MMM , YYYY")}
                    </span>
                    {!this.state.isActiveFullscreen && (
                      <IconChevronDown className="calendar-icon" />
                    )}
                  </a>
                </Popover>
              )}
              <div className="refresh-btn">
                {!this.props.isShowFullScreen && (
                  <span className="calendercontrols full-screen-parent d-inline-flex">
                    <button
                      type="button"
                      className="ant-btn full-screen-button"
                      title="Enter Full Screen"
                      onClick={() => {
                        this.props.toggleFullScreen();
                      }}
                    >
                      <FullscreenOutlined />
                    </button>
                  </span>
                )}

                {this.props.isShowFullScreen && (
                  <span className="calendercontrols full-screen-parent  d-inline-flex">
                    <button
                      type="button"
                      className="ant-btn full-screen-button"
                      title="Exit Full Screen"
                      onClick={() => {
                        this.props.toggleFullScreen();
                      }}
                    >
                      <FullscreenExitOutlined />
                    </button>
                  </span>
                )}
              </div>
              <div className="refresh-btn">
                <button onClick={this._onRefresh} className="ant-btn">
                  <ReloadOutlined className="" />
                  {/* <img src={refreshpic} alt="refresh" /> */}
                </button>
              </div>
              <div className="d-flex flex-wrap gapx-1 calendercontrols">
                <Button
                  title="Previous"
                  onClick={() => this.handleDirection("prev")}
                  className="push-r-12"
                >
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M7.87007 0.214784C8.17664 0.501169 8.17664 0.96549 7.87007 1.25187L2.7 6.08145C2.39342 6.36784 2.39342 6.83216 2.7 7.11854L7.87007 11.9481C8.17664 12.2345 8.17664 12.6988 7.87007 12.9852C7.56349 13.2716 7.06643 13.2716 6.75986 12.9852L1.58979 8.15563C0.670068 7.29648 0.670066 5.90352 1.58979 5.04436L6.75986 0.214784C7.06644 -0.0716 7.56349 -0.0716001 7.87007 0.214784Z"
                      fill="#808396"
                    />
                  </svg>
                </Button>
                <Button
                  title="Next"
                  onClick={() => this.handleDirection("next")}
                >
                  <svg
                    width="9"
                    height="14"
                    viewBox="0 0 9 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M1.12993 0.214784C0.82335 0.501169 0.82335 0.96549 1.12993 1.25187L6.3 6.08145C6.60657 6.36784 6.60657 6.83216 6.29999 7.11854L1.12993 11.9481C0.823351 12.2345 0.823351 12.6988 1.12993 12.9852C1.4365 13.2716 1.93356 13.2716 2.24013 12.9852L7.4102 8.15563C8.32992 7.29648 8.32993 5.90352 7.4102 5.04436L2.24013 0.214784C1.93356 -0.0716 1.4365 -0.0716001 1.12993 0.214784Z"
                      fill="#808396"
                    />
                  </svg>
                </Button>
              </div>

              <Radio.Group
                onChange={this.handleCalendarType}
                defaultValue="day"
                className="change_calendar_view_btns"
                value={this.state.calendarType}
              >
                <Radio.Button value="day">Today</Radio.Button>
                <Radio.Button value="week_days">Week Days</Radio.Button>
                <Radio.Button value="week">Week</Radio.Button>
              </Radio.Group>
            </div>
            {(loadingCalendarAPI || this.state.updatingApt) && (
              <div className="full-loader-div text-center mt-40 flex-1 d-flex align-items-center justify-content-center">
                <TableLoader isNormal={true} />
              </div>
            )}
            <FullCalendar
              plugins={
                moment(this.props.selecetdDate).format("YYYY-MM-DD") >=
                moment(new Date()).format("YYYY-MM-DD")
                  ? [
                      resourceTimeGridPlugin,
                      interactionPlugin,
                      scrollgridPlugin,
                    ]
                  : [resourceTimeGridPlugin, scrollgridPlugin]
              }
              initialView="resourceTimeGridDay"
              height={900}
              // loading={true}
              weekends={this.state.calendarType !== "week_days"}
              events={uniqBy(this.state.updatedEvent, "recordId")}
              allDaySlot={false}
              eventContent={this.renderEventContent}
              resourceAreaColumns={{}}
              headerToolbar={{
                left: "",
                right: "",
                center: "",
                end: "",
              }}
              viewDidMount={(view, elem) => {
                console.log("viewDidMount", view, elem);
                // this.setState({calendarView: view.viewName});
              }}
              views={{
                week: {
                  titleFormat: {
                    dateStyle: "medium",
                  },
                },
              }}
              // columnHeader={true}
              // columnHeaderFormat={{
              //   weekday: "long",
              // }}
              resourceAreaHeaderContent={(dd) => {
                console.log("resource dd", dd);
              }}
              initialDate={new Date(this.props.selecetdDate)}
              ref={this.calendarComponentRef}
              className="appoinmentcalender"
              droppable={true}
              editable={true}
              dropAccept={true}
              eventDrop={this.eventDrop}
              resourcesInitiallyExpanded={true}
              selectable={true}
              timeZone="local"
              slotMinWidth="14%"
              dayMinWidth="14%"
              eventMinHeight={60}
              resources={this.state.finalDoctorList}
              // eventStartEditable={true}
              slotDuration="00:15:00"
              slotLabelInterval={10}
              slotLabelFormat={{
                hour: "2-digit",
                minute: "2-digit",
              }}
              // eventMouseEnter={()=>{
              //    alert("Please enter")
              // }}
              slotMinTime="8:00 AM"
              slotMaxTime="22:00 PM"
              resourceLabelDidMount={async (resourceObj, lableTds, bodyTds) => {
                if (resourceObj.resource.id === "all") {
                  resourceObj.el.style.backgroundColor = "grey";
                  resourceObj.el.style.color = "#fff";
                } else {
                  resourceObj.el.style.backgroundColor =
                    resourceObj.resource.eventBackgroundColor;
                  resourceObj.el.style.color =
                    resourceObj.resource.eventTextColor;
                  console.log(
                    "🚀 ~ AppointmentsFullCalendarContainer ~ render ~ resourceObj.el:",
                    resourceObj.el
                  );
                }
              }}
              dateClick={async (e) => {
                this.closePopover();

                const slotTime = moment(e?.date).format(`hh:mm A`);
                const slotTimeStart = moment(e?.date).format();
                console.log("slotTimeStart:", e);
                const now = new Date();
                const passedDate = new Date(e.dateStr);
                if (now > passedDate) {
                  message.destroy();
                  message.info({
                    content: "You can't book an appointment for past date/time",
                    duration: 1,
                  });
                  this.setState({
                    isOutFromFullscreen: true,
                  });
                  return;
                }

                if (e?.resource._resource.id !== "all") {
                  const slotTimeEnd = moment(e?.date)
                    .add(15, "minutes")
                    .format();
                  const wholeSlot = [slotTimeStart, slotTimeEnd];

                  // console.log(
                  //   "🚀 ~ BookAppointmentFullCalendar ~ render ~ slotTime:",
                  //   slotTime
                  // );
                  const resourceName = e.resource?._resource?.title;

                  const resourceID = e.resource?._resource?.id;
                  try {
                    const response = await fetchAppointmentSlotsTypes({
                      doctor_id: resourceID,
                      start_time: slotTimeStart,
                    });
                    console.log("response", response);
                    const slotTypes = response.data.data?.split(",");
                    let newSlotTypes = [];
                    slotTypes.map((slot) => {
                      if (slot === "online_appointment") {
                        newSlotTypes.push("video");
                      } else if (slot === "offline_appointment") {
                        newSlotTypes.push("face_to_face");
                      }
                      return null;
                    });
                    this.setState({
                      inEditMode: false,
                      selectedPatientId: null,
                      selectedPatientName: null,
                      isOutFromFullscreen: true,
                      resourceID: resourceID,
                      showBookingModal: true,
                      dateEventClicked: true,
                      bookingRecord: {
                        apptDate: e.date,
                        apptTime: slotTime,
                        selectedAptType: this.props.selectedAptType,
                        doctor_name: resourceName?.split("-")[0],
                        resourceID: resourceID,
                        wholeSlot: wholeSlot,
                      },
                      allowedSlotTypes: newSlotTypes,
                    });
                  } catch {}
                }
              }}
              dayHeaderDidMount={(d) => {
                console.log("🚀 ~ dayHeaderDidMount ~ render ~ d:", d);
                if (
                  moment(d.date).format(DATE_FORMAT) ===
                  moment().format(DATE_FORMAT)
                ) {
                  d.el.classList.add("today-date-header");
                }
                d.el.innerText = moment(d.date).format("ddd DD/MM");
              }}
            />
            {/* </FullScreenHook> */}
            {this.state.showBookingModal && !this.props.forWholebooking && (
              <BookAppointmentForPatientContainer
                showBookingModal={this.state.showBookingModal}
                doctor_id={this.state.resourceID}
                patient_id={this.state.selectedPatientId}
                passedPatientName={this.state.selectedPatientName}
                inEditMode={this.state.inEditMode}
                bookingRecord={this.state.bookingRecord}
                allowedSlotTypes={this.state.allowedSlotTypes}
                fetchAllRotas={() => {
                  if (
                    this.state.defaultDates &&
                    this.state.defaultDates.length &&
                    this.state.defaultDates.length > 0
                  ) {
                    this._fetchAppointmentCalendarSlots(
                      null,
                      this.state.defaultDates.toString()
                    );
                  } else {
                    this._fetchAppointmentCalendarSlots(
                      this.state.defaultDates
                        ? this.state.defaultDates.toString()
                        : new Date()
                    );
                  }
                }}
                closeBookingModal={() => {
                  this.setState({
                    isOutFromFullscreen: false,
                    resourceID: null,
                    showBookingModal: false,
                    bookingRecord: null,
                    inEditMode: false,
                    allowedSlotTypes: null,
                  });
                  // if (
                  //   this.state.defaultDates &&
                  //   this.state.defaultDates.length &&
                  //   this.state.defaultDates.length > 0
                  // ) {
                  //   this._fetchAppointmentCalendarSlots(
                  //     null,
                  //     this.state.defaultDates.toString()
                  //   );
                  // } else {
                  //   this._fetchAppointmentCalendarSlots(
                  //     this.state.defaultDates
                  //       ? this.state.defaultDates.toString()
                  //       : new Date()
                  //   );
                  // }
                }}
              />
            )}
            {this.props.showBookingModal && this.props.forWholebooking && (
              <BookAppointmentForPatientContainer
                showBookingModal={this.props.showBookingModal}
                doctor_id={this.state.resourceID}
                forWholebooking={this.props.forWholebooking}
                bookingRecord={this.state.bookingRecord}
                allowedSlotTypes={this.state.allowedSlotTypes}
                fetchAllRotas={() => {
                  if (
                    this.state.defaultDates &&
                    this.state.defaultDates.length &&
                    this.state.defaultDates.length > 0
                  ) {
                    this._fetchAppointmentCalendarSlots(
                      null,
                      this.state.defaultDates.toString()
                    );
                  } else {
                    this._fetchAppointmentCalendarSlots(
                      this.state.defaultDates
                        ? this.state.defaultDates.toString()
                        : new Date()
                    );
                  }
                }}
                closeBookingModal={(isCancel) => {
                  this.setState({
                    isOutFromFullscreen: false,
                    resourceID: null,
                    showBookingModal: false,
                    bookingRecord: null,
                    inEditMode: false,
                    allowedSlotTypes: null,
                  });
                  this.props.closeBookingModal(); // this for close modal from boook appointment modal
                }}
              />
            )}
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isRotaAdded: verifyObject(state, "dynamicTab.isRotaAdded", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // staffIntranetActions: bindActionCreators(staffIntranetActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AppointmentsFullCalendarContainer);

export default withRouter(ConnectedComponent);

import validator from 'validator'
import isEmpty from 'lodash/isEmpty'
import moment from 'moment'

export function ValidateInput(data) {
	let errors = {}
	if (
		validator.isEmpty(data.snomedCodeText) &&
		data.snomedCodeId !== undefined &&
		validator.isEmpty(data.snomedCodeId)
	) {
		errors.snomedCodeId = 'Please select diseases name'
	}

	if (
		validator.isEmpty(data.snomedCodeId) &&
		data.snomedCodeText !== undefined &&
		validator.isEmpty(data.snomedCodeText)
	) {
		errors.snomedCodeId = 'Please select diseases name'
	}

	if (
		validator.isEmpty(data.snomedCodeId) &&
		data.snomedCodeText !== undefined &&
		!validator.isEmpty(data.snomedCodeText) &&
		/^(?=.*\S).+$/.test(data.snomedCodeText) === false
	) {
		errors.snomedCodeId = 'Please select diseases name'
	}
	if (!validator.isEmpty(data.snomedCodeText) && !validator.isEmpty(data.snomedCodeId)) {
		errors.snomedCodeId = 'You cant select both'
	}
	if (data.startDateYear !== undefined && validator.isEmpty(data.startDateYear)) {
		errors.startDateYear = 'Please Select start date year'
	}
	if (data.startDateMonth !== undefined && validator.isEmpty(data.startDateMonth)) {
		errors.startDateMonth = 'Please Select start date month '
	}
	if (
		data.currentlyActive === false &&
		data.endDateYear !== undefined &&
		validator.isEmpty(data.endDateYear)
	) {
		errors.endDateYear = 'Please Select end date year'
	}
	if (
		data.currentlyActive === false &&
		data.endDateMonth !== undefined &&
		validator.isEmpty(data.endDateMonth)
	) {
		errors.endDateMonth = 'Please Select end date month'
	}
	if (
		data.currentlyActive === false &&
		data.startDate !== null &&
		data.endDate !== null &&
		moment(data.startDate).isSameOrAfter(moment(data.endDate))
	) {
		errors.endDate = 'End date should be greater than start date. '
	}

	if (
		data.currentlyActive === false &&
		data.startDate !== null &&
		data.endDate !== null &&
		moment(data.endDate).isSameOrAfter(moment())
	) {
		errors.endDate = 'End date should not be future date'
	}

	if (
		data.currentlyActive === false &&
		data.startDate !== null &&
		data.endDate !== null &&
		moment(data.startDate).isSameOrAfter(moment())
	) {
		errors.startDate = 'Start date should not be future date'
	}

	return { errors, isValid: isEmpty(errors) }
}

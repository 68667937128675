import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {Prompt, withRouter} from "react-router-dom";
import Layout from "../../Layouts/PageLayout";
import {
  onRequestSetComponent,
  onSetSidebarKey,
  onSetDynamicInput,
  onSetDynamicHealthInput,
} from "../../reducers/dynamicTab";
import {appRoutesConst, navigateTo} from "../../app/navigation";
import {verifyObject} from "../../utilities/utils";
// import { CalendarOutlined } from "@ant-design/icons";
import {Beforeunload} from "react-beforeunload";
import {actions as ConsultationReviewActions} from "../../reducers/consultationReview";
import ConsultationReviewContainer from "containers/ConsultationReview/ConsultationReviewContainer";
import store from "app/store";

// import Fade from "react-reveal/Fade";
// import queried from "assets/images/sidebar/ic-queried.svg";
// import new_script from "assets/images/sidebar/ic-upcoming.svg";
// import reject from "assets/images/sidebar/ic-reject.svg";
// import approve from "assets/images/sidebar/ic-approve.svg";

//svg icons functions
export const NewscriptmenuIcon = (props) => {
  const {width, fill} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={fill} fill-rule="nonzero">
          <g>
            <g>
              <g transform="translate(-10 -65) translate(0 56) translate(1) translate(9 9)">
                <circle cx="8.8" cy="5.2" r="1" />
                <path d="M10.125.938h-.61v-.47C9.516.21 9.306 0 9.048 0c-.259 0-.469.21-.469.469v.469H6.445v-.47C6.445.21 6.235 0 5.977 0c-.26 0-.47.21-.47.469v.469H3.399v-.47C3.398.21 3.188 0 2.93 0c-.26 0-.47.21-.47.469v.469h-.585C.841.938 0 1.777 0 2.813v7.312C0 11.159.841 12 1.875 12h3.586c.259 0 .469-.21.469-.469s-.21-.469-.47-.469H1.876c-.517 0-.938-.42-.938-.937V2.812c0-.516.421-.937.938-.937h.586v.469c0 .259.21.469.469.469s.468-.21.468-.47v-.468h2.11v.469c0 .259.21.469.469.469.258 0 .468-.21.468-.47v-.468h2.133v.469c0 .259.21.469.469.469s.469-.21.469-.47v-.468h.609c.517 0 .938.42.938.938v2.671c0 .26.21.47.468.47.26 0 .469-.21.469-.47V2.813c0-1.034-.841-1.876-1.875-1.876z" />
                <path d="M9.2 6.4c-1.544 0-2.8 1.256-2.8 2.8 0 1.544 1.256 2.8 2.8 2.8 1.544 0 2.8-1.256 2.8-2.8 0-1.544-1.256-2.8-2.8-2.8zm0 4.674c-1.034 0-1.874-.84-1.874-1.874 0-1.034.84-1.874 1.874-1.874 1.034 0 1.874.84 1.874 1.874 0 1.034-.84 1.874-1.874 1.874z" />
                <path d="M9.936 8.612h-.208v-.518c0-.273-.208-.494-.464-.494s-.464.221-.464.494v1.012c0 .273.208.494.464.494h.672c.256 0 .464-.221.464-.494s-.208-.494-.464-.494z" />
                <circle cx="6.8" cy="5.2" r="1" />
                <circle cx="4.8" cy="7.2" r="1" />
                <circle cx="2.8" cy="5.2" r="1" />
                <circle cx="2.8" cy="7.2" r="1" />
                <circle cx="2.8" cy="9.2" r="1" />
                <circle cx="4.8" cy="9.2" r="1" />
                <circle cx="4.8" cy="5.2" r="1" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const QueriedmenuIcon = (props) => {
  const {width, fill} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={fill} fill-rule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M8.61 5.435c0 .194-.156.352-.35.352-.195 0-.352-.158-.352-.352 0-.194.157-.351.351-.351.195 0 .352.157.352.351zM8.259 1.695c-.714 0-1.293.577-1.293 1.292 0 .195.158.352.352.352.194 0 .352-.158.352-.352 0-.325.262-.59.59-.59.325 0 .59.265.59.59 0 .326-.265.59-.59.59-.195 0-.352.158-.352.352v.376c0 .195.157.352.351.352.195 0 .352-.157.352-.352v-.073c.543-.154.941-.653.941-1.244 0-.713-.58-1.293-1.293-1.293zM3.374 8.972c.436-.34.718-.87.718-1.466 0-1.024-.833-1.858-1.858-1.858-1.024 0-1.857.834-1.857 1.858 0 .596.281 1.126.718 1.466C.48 9.34 0 10.032 0 10.895v.753c0 .195.157.352.352.352h3.765c.194 0 .352-.157.352-.352v-.753c0-.863-.479-1.556-1.095-1.923zM1.08 7.506c0-.636.518-1.154 1.154-1.154.637 0 1.155.518 1.155 1.154 0 .637-.518 1.155-1.155 1.155-.636 0-1.154-.518-1.154-1.155zm2.686 3.79H.703v-.4c0-.846.684-1.532 1.531-1.532.846 0 1.532.684 1.532 1.531v.402z"
                  transform="translate(-10 -1024) translate(-1 56) translate(1 956) translate(10 12)"
                />
                <path
                  d="M10.519 0H6c-.818 0-1.481.662-1.481 1.481V8.26c0 .288.33.456.562.282l1.413-1.06h4.025C11.337 7.481 12 6.82 12 6V1.481C12 .663 11.338 0 10.519 0zm.778 6c0 .43-.348.778-.778.778H6.377c-.077 0-.15.025-.211.07l-.944.708V1.481c0-.43.348-.778.778-.778h4.519c.43 0 .778.348.778.778V6z"
                  transform="translate(-10 -1024) translate(-1 56) translate(1 956) translate(10 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const AcceptmenuIcon = (props) => {
  const {width, fill} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={fill} fill-rule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M8.996 4.027c-.184-.194-.492-.203-.687-.018L5.203 6.955 3.72 5.434c-.187-.193-.495-.197-.687-.01-.193.188-.197.496-.01.689l1.817 1.864c.095.098.221.147.348.147.12 0 .24-.044.335-.133l3.454-3.276c.195-.185.203-.493.018-.688z"
                  transform="translate(-10 -1060) translate(-1 56) translate(1 992) translate(10 12)"
                />
                <path
                  d="M6 0C2.692 0 0 2.692 0 6s2.692 6 6 6 6-2.692 6-6-2.692-6-6-6zm0 11.027C3.228 11.027.973 8.772.973 6 .973 3.228 3.228.973 6 .973c2.772 0 5.027 2.255 5.027 5.027 0 2.772-2.255 5.027-5.027 5.027z"
                  transform="translate(-10 -1060) translate(-1 56) translate(1 992) translate(10 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
export const RejectmenuIcon = (props) => {
  const {width, fill} = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height="12"
      viewBox="0 0 12 12"
    >
      <g fill="none" fill-rule="evenodd">
        <g fill={fill} fill-rule="nonzero">
          <g>
            <g>
              <g>
                <path
                  d="M6 0C2.684 0 0 2.684 0 6s2.684 6 6 6 6-2.684 6-6-2.684-6-6-6zm0 11.063C3.202 11.063.937 8.797.937 6 .938 3.202 3.203.937 6 .937c2.798 0 5.063 2.265 5.063 5.063 0 2.798-2.265 5.063-5.063 5.063z"
                  transform="translate(-10 -1096) translate(-1 56) translate(1 1028) translate(10 12)"
                />
                <path
                  d="M8.053 7.39L6.663 6l1.39-1.39c.183-.183.183-.48 0-.663-.183-.183-.48-.183-.663 0L6 5.337l-1.39-1.39c-.183-.183-.48-.183-.663 0-.183.183-.183.48 0 .663L5.337 6l-1.39 1.39c-.183.183-.183.48 0 .663.183.183.48.183.663 0L6 6.663l1.39 1.39c.183.183.48.183.663 0 .183-.183.183-.48 0-.663z"
                  transform="translate(-10 -1096) translate(-1 56) translate(1 1028) translate(10 12)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};
const sideBarArray = [
  {
    label: "In Review",
    path: appRoutesConst.consultationReview,
    icon: <NewscriptmenuIcon width="12px" fill="#757575" />,
    page: "ConsultationReviewContainerPendingContainer",
    key: `CR1`,
    useDynamicComponent: true,
  },
  {
    label: "Reviewed",
    path: appRoutesConst.consultationReview,
    icon: <QueriedmenuIcon width="12px" fill="#757575" />,
    key: `CR2`,
    page: "ConsultationReviewContainerReviewedContainer",
    useDynamicComponent: true,
  },
];

class ConsultationReviewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: "ConsultationReviewContainerPendingContainer",
    };
    ["_getIndexRoute", "_renderDynamicComponent"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  componentDidMount() {
    this._getIndexRoute();
    let {componentName} = this.props;
    this.setState({componentName});
    // this.props.ConsultationReviewActions.onRequest({
    //   appointment_type: "in_review",
    //   page: 1,
    // });
  }

  _getIndexRoute() {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent("ConsultationReviewContainerPendingContainer");
    }
    if (typeof onSetSidebarKey === "function") {
      // alert("ConsultationReviewContainerPending")
      onSetSidebarKey([`CR1`]);
    }

    // let status = localStorage.getItem("scriptReviewStatus");
    // if (status === "doctor_queried") {
    //   onRequestSetComponent("ScriptReviewContainerQueried");
    //   if (typeof onSetSidebarKey === "function") {
    //     onSetSidebarKey([`SR2`]);
    //   }
    // } else if (status === "doctor_approved") {
    //   onRequestSetComponent("ScriptReviewContainerApproved");
    //   if (typeof onSetSidebarKey === "function") {
    //     onSetSidebarKey([`SR3`]);
    //   }
    // } else if (status === "doctor_declined") {
    //   onRequestSetComponent("ScriptReviewContainerRejected");
    //   if (typeof onSetSidebarKey === "function") {
    //     onSetSidebarKey([`SR4`]);
    //   }
    // } else if (status === "new") {
    //   onRequestSetComponent("ScriptReviewContainerNew");
    //   if (typeof onSetSidebarKey === "function") {
    //     onSetSidebarKey([`SR1`]);
    //   }
    // }
  }

  _renderDynamicComponent() {
    let {componentName} = this.props;
    let {onSetDynamicInput, onSetDynamicHealthInput} =
      this.props.dynamicTabAction;

    switch (componentName) {
      case "ConsultationReviewContainerPendingContainer":
        store.dispatch({type: "PREV_APPT_TAB_KEY", payload: "in_review"});

        if (
          typeof onSetDynamicInput === "function" ||
          typeof onSetDynamicHealthInput === "function"
        ) {
          onSetDynamicInput(null);
          onSetDynamicHealthInput(null);
        }
        localStorage.setItem("consultationReviewStatus", "in_review");
        this.props.ConsultationReviewActions.onRequest({
          appointment_type: "in_review",
          page: 1,
        });
        return <ConsultationReviewContainer appointment_type="in_review" />;
      case "ConsultationReviewContainerReviewedContainer":
        store.dispatch({type: "PREV_APPT_TAB_KEY", payload: "reviewed"});

        if (
          typeof onSetDynamicInput === "function" ||
          typeof onSetDynamicHealthInput === "function"
        ) {
          onSetDynamicInput(null);
          onSetDynamicHealthInput(null);
        }
        localStorage.setItem("consultationReviewStatus", "reviewed");
        this.props.ConsultationReviewActions.onRequest({
          page: 1,
          appointment_type: "reviewed",
        });
        return <ConsultationReviewContainer appointment_type="reviewed" />;

      default:
        return <ConsultationReviewContainer appointment_type="in_review" />;
    }
  }

  componentWillUnmount() {}
  _clearComponents = () => {
    let {onRequestSetComponent, onSetSidebarKey} = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(null);
      onSetSidebarKey(null);
    }
  };

  render() {
    return (
      <Beforeunload
        onBeforeunload={(event) => {
          this._clearComponents();
        }}
      >
        <Layout title="Reports Review" sideBarArray={sideBarArray}>
          <Prompt
            message={(location, action) => {
              if (action === "PUSH") {
                this._clearComponents();
                return true;
              }
              if (action === "POP") {
                this._clearComponents();
                return true;
              }
              return true;
            }}
          />
          <div className="rightmainsection ques_review_section">
            <div className="container-fluid right_wrapper p-0">
              <div className="row m-0">
                <div className="col-md-12 selection_container padding-right-10 padding-left-10">
                  {this._renderDynamicComponent()}
                </div>
              </div>
            </div>
          </div>
        </Layout>
      </Beforeunload>
    );
  }
}

const mapStateProps = (state) => {
  return {
    selectedKey: verifyObject(state.dynamicTab, "sidebar_key", "CR1"),
    componentName: verifyObject(
      state.localStore,
      "componentName",
      "ConsultationReviewContainer"
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {
        onRequestSetComponent,
        onSetSidebarKey,
        onSetDynamicInput,
        onSetDynamicHealthInput,
      },
      dispatch
    ),
    ConsultationReviewActions: bindActionCreators(
      ConsultationReviewActions,
      dispatch
    ),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(ConsultationReviewPage);

export default withRouter(ConnectedComponent);

import {Paginate} from "components/common";
import React, {useState} from "react";
import {Document, Page, pdfjs} from "react-pdf";
// import { useReactToPrint } from "react-to-print";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export default function PDFViewer({pdf, printPdf, handlePrint}) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({numPages}) {
    setNumPages(numPages);
    // handlePrint();
  }

  //   const nextPage = () => {
  //     setPageNumber(pageNumber + 1);
  //   };

  //   const prevPage = () => {
  //     setPageNumber(pageNumber - 1);
  //   };
  const onPageChange = ({page}) => {
    setPageNumber(page);
  };
  return (
    <div>
      {/* {pageNumber !== numPages && <a onClick={nextPage}> Next</a>} */}
      <Document file={pdf} onLoadSuccess={onDocumentLoadSuccess}>
        <Page width={800} scale={1} pageNumber={pageNumber} />
      </Document>
      <div style={{display: "flex", justifyContent: "center"}}>
        <Paginate
          onPageChange={onPageChange}
          page={pageNumber}
          pageCount={numPages}
        />
      </div>
    </div>
  );
}

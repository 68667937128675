import React, { PureComponent, Fragment } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { actions as MembersContactActions } from "../../reducers/MembersContacts";
import { verifyObject } from "../../utilities/utils";

import { Pagination } from "../../components/common";
import MembersContactTable from "./MembersContactTable";

class MembersContactContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    ["_onReuestList", "_onPageChange"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }
 
  componentDidMount() {
    this._onReuestList();
  }

  _onReuestList() {
    let {
      MembersContactActions: { onRequest },
      appointmentId,
    } = this.props;
    console.log("Appointment ID", this.props.appointmentId);
    if (typeof onRequest === "function") {
      onRequest({ page: 1, appointment_id: appointmentId });
    }
  }

  _onPageChange(data) {
    let {
      MembersContactActions: { onPageChange },
      membersContacts: { search },
      appointmentId,
    } = this.props;
    const { page } = data;
    if (typeof onPageChange === "function") {
      onPageChange({ page, search, appointment_id: appointmentId });
    }
  }

  render() {
    let { membersContacts } = this.props;

    return (
      <Fragment>
        <div className="push-10-t push-20-b ">
          <div className="">
            <MembersContactTable
              _onReuestList={this._onReuestList}
              dataSource={membersContacts}
              {...this.props}
            />
            <Pagination
              data={membersContacts}
              onPageChange={this._onPageChange}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    membersContacts: verifyObject(state, "membersContacts", []),
    appointmentId: verifyObject(state, "localStore.appointment_obj.id", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    MembersContactActions: bindActionCreators(MembersContactActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MembersContactContainer);

export default withRouter(ConnectedComponent);

import { getAppointmentPaymentsApi } from 'services/payments'
import { listingReducer } from 'reducers/utils/listingReducer'

const { reducer, actions, types } = listingReducer({
	name: `appointmentPayments`,
	getApi: getAppointmentPaymentsApi,
})

const appointmentPayments = reducer
export { appointmentPayments, actions, types }

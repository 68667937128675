import React, {Fragment} from "react";
import logo from "assets/images/common/main_logo.svg";
import "assets/css/pages/login.css";
import SignupContainer from "../../containers/session/SignupContainer";
import {Link} from "react-router-dom";
import {appRoutesConst} from "../../app/navigation";

const SignupPage = () => {
  return (
    <Fragment>
      <Fragment>
        <div className="login-wrapper">
          <div className="container-fluid">
            <div className="login-wrapper-logo">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <Link to="/" className="logo-link">
                    <a title="healthya.co.uk">
                      <img
                        src={logo}
                        alt="ADD logo"
                        className="img-responsive"
                      />
                    </a>{" "}
                  </Link>
                </div>
                <div className="col-md-8 col-lg-8 d-flex align-items-center justify-content-end">
                  <div className="in-block margin-right-30 ">
                    <span className="font-14 weight-400 text-blue">
                      Already Registered ?
                    </span>
                  </div>
                  <div className="in-block">
                    <Link
                      to={appRoutesConst.signin}
                      className="btn weight-400 text-white btn-custom btn-blue height-big width-220px line-1-imp"
                    >
                      Sign In
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="login-title-wrapper">
            <div className="container-fluid">
              <h1 className="login-title-h1">SIGN UP FOR FREE</h1>
            </div>
          </div>

          <div className="container-fluid">
            <div className="signup-form-wrapper">
              {/* <LoginHeading /> */}
              <SignupContainer />
            </div>
          </div>
        </div>
      </Fragment>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default SignupPage;

import React, { useRef, useState } from "react";
import { Drawer } from "antd";
import AppointmentsFullCalendarContainer from "containers/AppointmentsContainers/AppointmentsFullCalendarContainer";
import "./fullCalendarDrawer.css";
import AddRotaModal from "containers/Rota/AddRotaModal";
import refresh from "assets/images/common/refresh.svg";
// import PatientSearchContainer from "containers/PatientSearch/PatientSearchContainer.js";
// import BookAppointmentForPatient from "./BookAppointmentForPatient";
 import BookAppointmentForPatientContainer from "containers/AppointmentsContainers/BookAppointmentForPatientContainer.jsx"
export default function FullCalendarDrawer({ open, onCloseDrawer, user }) {
  console.log("🚀 ~ FullCalendarDrawer ~ open:", open);
  const apptCalendarRef = useRef();
  console.log("apptCalendarRef", apptCalendarRef?.current);
  const [isShowModal, setShowModal] = useState(false);
  const [isShowFullScreen, setIsShowFullScreen] = useState(false);


  return (
    <div>
      <Drawer
        title={
          <div className="d-flex flex-wrap align-items-center justify-content-between gapx-2 gapy-1">
            <p>Appointment Calendar</p>
            <div className="d-flex flex-wrap align-items-center justify-content-between gapx-2 gapy-1">
              <button
                onClick={()=>setShowModal(true)}
                className="menu-link border-0 csbtn-1 mr-0 px-3"
              >
                Book Appointment
              </button>
              {open && (
                <AddRotaModal
                  _fetchAppointmentCalendarSlots={
                    apptCalendarRef?.current?._fetchAppointmentCalendarSlots
                  }
                  onCloseDrawer={onCloseDrawer}
                  defaultDates={apptCalendarRef?.current?.defaultDates}
                  fromDrawer={true}
                />
              )}
            </div>
          </div>
        }
        placement="left"
        visible={open}

        width={isShowFullScreen?"100%":"50%"}
        onClose={onCloseDrawer}
        className="appointment-cal-header"
        closable={false}
        mask={false}
        // getContainer={false}
      >
        {open && (
          <AppointmentsFullCalendarContainer
            selecetdDate={new Date()}
            user={user}
            ref={apptCalendarRef}
            showBookingModal={isShowModal}
            closeBookingModal={() => {
              setShowModal(false);
            }}
            toggleFullScreen={()=>{
                console.log("toggle fullscreen");
                setIsShowFullScreen(!isShowFullScreen);
            }}
            isShowFullScreen={isShowFullScreen}
            forWholebooking={isShowModal}
          />
        )}
      </Drawer>
    </div>
  );
}

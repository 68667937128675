import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as sickNotesActions} from "../../../reducers/refferalNotes";
import {
  verifyObject,
  getErrorObject,
  errorToast,
} from "../../../utilities/utils";
import {debounce} from "lodash";
import moment from "moment";
import CommonLoader from "components/common/CommonLoader";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "components/common/NoFoundMessage";
import {getConsultationSickNote} from "../../../services/consultations";
import SickNoteAddModal from "./SickNoteAddModal";
import {DATE_FORMAT} from "constants/common";
// import Scrollbars from "react-custom-scrollbars";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import {
  onSetDynamicInput,
  onSetSidebarKey,
  onRequestSetComponent,
} from "../../../reducers/dynamicTab";

class SickNoteContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      sickNote: null,
      search: "",
    };
    ["_onRequestList", "_onPageChange", "_handleSearch"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.sickNoteModal = React.createRef();
  }

  async _handleSearch(value) {
    let {patientId, sickNotesActions} = this.props;
    let {onSearch} = sickNotesActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({
        search: value,
        patient_id: patientId,
        page: 1,
        document_type: "SickNote",
      });
    }
  }

  componentDidMount() {
    this._onRequestList();
  }

  async _onRequestList() {
    let {appointmentId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getConsultationSickNote({
        appointment_id: appointmentId,
      });

      await this.setState(
        {
          sickNote: verifyObject(response, "data.data.sick_note", []),
          isLoading: false,
        },
        () => {
          if (!this.state.sickNote) {
            this.setState({visible: true});
          }
        }
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      sickNotesActions: {onPageChange},
      sickNotes: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page: page,
        patient_id: patientId,
        search: search,
        document_type: "SickNote",
      });
    }
  }

  render() {
    let {sickNote, isLoading} = this.state;
    return (
      <Fragment>
        <div className="push-15-t push-20-b sicknotepage">
          <div className="form-row align-items-end push-10-b">
            <div className="col-md-6">
              <h6 className="font-14 text-light-black margin-0">
                {/* Sick Note {isLoading ? "" : `(${total})`}{" "} */}
              </h6>
            </div>
            <div className="col-md-6 text-right">
              <div className="relative searchbar_dropdown medical_Search">
                {/* <Input
                  onChange={(e) => {
                    let value = e.target.value;
                    this.searchUpdate(value);
                  }}
                  size="large"
                  placeholder="Search Sick Note"
                />
                <img alt="" src={search_blue}></img> */}
              </div>
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          <div className="sectionsubscroll">
            <PerfectScrollbar>
              <>
                {!isLoading && sickNote === null && (
                  <div className="sicknotenodata">
                    <NoFoundMessage message="No sicknote was provided" />
                  </div>
                )}

                {!isLoading && sickNote === null && (
                  <SickNoteAddModal
                    // ref={this.sickNoteModal}
                    _onRequestList={this._onRequestList}
                    {...this.props}
                    {...this.state}
                    is_gp_informed={this.props.is_gp_informed}
                    userRole={this.props.userRole}
                    visible={this.state.visible}
                    prev_appt_tab_key={this.props.prev_appt_tab_key}
                    consultation_id={this.props.consultation_id}
                    is_autosave_consultation_data={
                      this.props.is_autosave_consultation_data
                    }
                  />
                )}

                {!isLoading && sickNote && (
                  <div className="form-row">
                    <div className="col-md-12 sicknote_cotent push-t-45">
                      <div className="form-row push-10-b">
                        <div className="col-md-12">
                          {/* <div className="border-box col-md-12"> */}
                          <div className="form-row">
                            <div className="col-md-4">
                              <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                I assessed your case on
                              </p>
                              <div className="border-box col-md-12 input-bg-f9f9f9">
                                <p className="font-11 weight-400 text-light-black text-left margin-0">
                                  <Highlighter
                                    highlightClassName="search-highlighter"
                                    searchWords={[this.state.search]}
                                    autoEscape={true}
                                    textToHighlight={
                                      verifyObject(
                                        sickNote,
                                        "assessed_on",
                                        null
                                      ) !== null
                                        ? `${moment(
                                            sickNote.assessed_on
                                          ).format(DATE_FORMAT)}`
                                        : "-"
                                    }
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                and beacause of the following condition(s)
                              </p>
                              <div className="border-box col-md-12 input-bg-f9f9f9">
                                <p className="font-11 weight-400 text-light-black text-left margin-0">
                                  <Highlighter
                                    highlightClassName="search-highlighter"
                                    searchWords={[this.state.search]}
                                    autoEscape={true}
                                    textToHighlight={
                                      verifyObject(
                                        sickNote,
                                        "assessment_reason",
                                        ""
                                      ) !== ""
                                        ? `${sickNote.assessment_reason}`
                                        : "-"
                                    }
                                  />
                                </p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                I advice you that
                              </p>
                              <div className="border-box col-md-12 input-bg-f9f9f9">
                                <p className="font-11 weight-400 text-light-black text-left margin-0">
                                  <Highlighter
                                    highlightClassName="search-highlighter"
                                    searchWords={[this.state.search]}
                                    autoEscape={true}
                                    textToHighlight={
                                      verifyObject(sickNote, "advise", "") !==
                                      ""
                                        ? `${sickNote.advise}`
                                        : "-"
                                    }
                                  />
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>

                          <div className="form-row push-10-t">
                            <div className="col-md-6">
                              <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                If available , and with your employer's
                                agreement, you may benifit from
                              </p>
                              <div className="border-box col-md-12 input-bg-f9f9f9">
                                <div className="form-row">
                                  <div className="col-md-12">
                                    <p className="font-11 weight-400 text-light-black text-left margin-0">
                                      <Highlighter
                                        highlightClassName="search-highlighter"
                                        searchWords={[this.state.search]}
                                        autoEscape={true}
                                        textToHighlight={
                                          verifyObject(
                                            sickNote,
                                            "benefit_from",
                                            null
                                          ) !== null
                                            ? `${sickNote.benefit_from}`
                                            : "-"
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                Comments , including functional effects of your
                                condition(s)
                              </p>
                              <div className="border-box col-md-12 input-bg-f9f9f9">
                                <div className="form-row">
                                  <div className="col-md-12">
                                    <p className="font-11 weight-400 text-light-black text-left margin-0">
                                      <Highlighter
                                        highlightClassName="search-highlighter"
                                        searchWords={[this.state.search]}
                                        autoEscape={true}
                                        textToHighlight={
                                          verifyObject(
                                            sickNote,
                                            "comment",
                                            ""
                                          ) !== null
                                            ? `${sickNote.comment}`
                                            : "-"
                                        }
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>

                          <div className="form-row push-10-t">
                            <div className="col-lg-6 col-md-6 col-sm-6">
                              <div className="form-row">
                                <div className="col-md-6">
                                  <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                    Start Date
                                  </p>
                                  <div className="border-box col-md-12 input-bg-f9f9f9">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                sickNote,
                                                "case_from",
                                                null
                                              ) !== null
                                                ? `${moment(
                                                    sickNote.case_from
                                                  ).format(DATE_FORMAT)}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <p className="font-12 weight-400 text-left dis-block push-5-b text-dark-gray">
                                    End Date
                                  </p>
                                  <div className="border-box col-md-12 input-bg-f9f9f9">
                                    <div className="row">
                                      <div className="col-md-4">
                                        <p className="font-11 weight-400 text-light-black text-left margin-0">
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                sickNote,
                                                "case_to",
                                                null
                                              ) !== null
                                                ? `${moment(
                                                    sickNote.case_to
                                                  ).format(DATE_FORMAT)}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="clearfix"></div>
                          <span className="font-12 weight-400 text-dark-gray line-1-43 push-10-t dis-block">
                            {verifyObject(
                              sickNote,
                              "need_fitness_assessment",
                              null
                            ) !== null
                              ? `${sickNote.need_fitness_assessment}`
                              : "-"}
                          </span>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            </PerfectScrollbar>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    patient_detail_obj: verifyObject(
      state.localStore,
      "patient_detail_obj",
      null
    ),
    consultationObj: verifyObject(state.localStore, "consultation_obj", null),
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    is_gp_informed: verifyObject(state, "localStore.is_gp_informed", null),
    userRole: verifyObject(state.localStore, "user.role", null),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    consultation_id: verifyObject(
      state.localStore,
      "consultation_obj.consultation_log.consultation_id",
      null
    ),
    is_autosave_consultation_data: verifyObject(
      state.localStore,
      "is_autosave_consultation_data",
      false
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sickNotesActions: bindActionCreators(sickNotesActions, dispatch),
    dynamicTabAction: bindActionCreators(
      {onSetDynamicInput, onSetSidebarKey, onRequestSetComponent},
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SickNoteContainer);

export default ConnectedComponent;

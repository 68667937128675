import React from "react";
import {Link, NavLink} from "react-router-dom";
import {push} from "react-router-redux";
import backBlue from "assets/images/common/back-arrow.svg";
import store from "./store";
export const appRoutesConst = {
  //doctor routes
  dashboard: "/doctor/dashboard",
  doctor: "/doctor",
  rota: "/doctor/rota",
  callSetup: "/doctor/callsetup",
  doctorchangepassword: "/doctor/change-password",
  rightToWork: "/doctor/right_to_work",
  staffInfranet: "/doctor/staff_intranet",
  // reportsReview: "/doctor/reports_review",
  // questionnaireReview: "/doctor/questionnaire_review",
  dynamicQuestionnaireReview: "/doctor/dynamicQuestionnaire_review",
  payments: "/doctor/payments",
  orderPayments: "/doctor/order_payments",
  paymentSettings: "/doctor/payment_settings",
  // reportsReviewDetail: "/doctor/reports_review/detail",
  // questionnaireReviewDetail: "/doctor/questionnaire_review/detail",
  dynamicQuestionnaireReviewDetail:
    "/doctor/dynamicQuestionnaire_review/detail",
  profile: "/doctor/profile",
  walkinAppointment: "/doctor/walkin-appointment",
  videoAppointment: "/doctor/video-appointment",
  messages: "/doctor/messages",
  scriptReview: "/doctor/script_review",
  consultationReview: "/doctor/consultation_review",
  preconsultationReview: "/doctor/pre_consultation_review",
  scriptReviewDetail: "/doctor/script_review/detail",
  callNetworkTest: "/doctor/call-networktest",
  loginFromCS: "/sso-access/:cs_token",
  loginFromPharmaSmart: "/pharmsmart-sso-access/:cs_token",
  pharmSmartLogout: "/consult-smart-logout",

  //patients routes
  patient: "/patient",
  patientSearch: "/patient/search",
  patientDetail: "/patient/detail",
  patientConsultationDetail: "/patient/consultation_detail",

  //genral routes
  verfifyotp: "/verify-otp",
  verfifyresetotp: "/verify-otp-for-password",
  resetpasswordViaMobile: "/resetpassword/:reset_token",
  verfifyotpphone: "/verify-otp/:phone",
  forgotpassword: "/forgotpassword",
  changepassword: "/change_password",
  index: "/",
  signin: "/signin",
  signup: "/signup",
};

const config = {
  activeClassName: "active",
};

export const navigateToIndex = () => {
  return (dispatch) => {
    dispatch(push(appRoutesConst.index));
  };
};

export const navigateToRightToWork = () => {
  return (dispatch) => {
    dispatch(push(appRoutesConst.rightToWork));
  };
};

export const navigateTo = (path) => {
  return (dispatch) => {
    dispatch(push(path));
  };
};

export const IndexLink = (props) => {
  return (
    <Link {...props} to={appRoutesConst.index}>
      {props.children}
    </Link>
  );
};
export const navigateToResetPasswordViaMobile = (token) => {
  return (dispatch) => {
    dispatch(push(`/resetpassword/${token}`));
  };
};
export const BackButton = ({className}) => {
  return (
    <Link to={appRoutesConst.newCustomerSelection}>
      <img
        src={backBlue}
        className={className + " push-20-t nav-link padding-0"}
        alt="<-"
      />
    </Link>
  );
};
export const navigateToCustomerSelection = () => {
  return (dispatch) => {
    dispatch(push(appRoutesConst.newCustomerSelection));
  };
};
export const navigateToOTPScreen = (phone) => {
  return (dispatch) => {
    dispatch(push(`${appRoutesConst.verfifyotp}`));
  };
};
export const navigateToResetPassOTPScreen = (phone) => {
  return (dispatch) => {
    dispatch(push(`${appRoutesConst.verfifyresetotp}`));
  };
};
export const CustomerSelectionLink = (props) => {
  return (
    <Link {...props} to={appRoutesConst.customerSelection}>
      {props.children}
    </Link>
  );
};

export const PatientDetailLink = (props) => {
  return (
    <Link
      onClick={() =>
        store.dispatch({type: "IS_FROM_PATIENT_LISTING", payload: false})
      }
      {...props}
      to={appRoutesConst.patientsDetails}
    >
      {props.children}
    </Link>
  );
};
export const AppointmentDetaislLink = (props) => {
  return <Link {...props}>{props.children}</Link>;
};
export const DashboardLink = (props) => {
  return (
    <Link {...props} to={appRoutesConst.newCustomerSelection}>
      {props.children}
    </Link>
  );
};

export const navigateToCustomerDetails = () => {
  return (dispatch) => {
    dispatch(push(appRoutesConst.customerDetails));
  };
};
export const navigateToPatientDetails = () => {
  return (dispatch) => {
    dispatch(push(appRoutesConst.patientsDetails));
  };
};
export const navigateToPatientList = () => {
  return (dispatch) => {
    dispatch(push(appRoutesConst.patientslist));
  };
};
export const navigateToAppointments = () => {
  return (dispatch) => {
    dispatch(push(appRoutesConst.appointments));
  };
};

export const CustomerDetailsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.customerDetails}
    >
      {props.children}
    </NavLink>
  );
};
export const patientPaymentsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={`/patientpayments/${props.id}?is_patient=true`}
    >
      {props.children}
    </NavLink>
  );
};

export const OrdersLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.orders}
    >
      {props.children}
    </NavLink>
  );
};

export const OrdersDetailsLink = (props) => {
  const {id, type, order_id, ...restProps} = props;
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...restProps}
      to={`${appRoutesConst.orders}/${order_id}/${id}/${type}`}
    >
      {props.children}
    </NavLink>
  );
};

export const PatientOrdersDetailsLink = (props) => {
  const {id, type, order_id, ...restProps} = props;
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...restProps}
      to={`/customer/patientorders/${order_id}/${id}/${type}`}
    >
      {props.children}
    </NavLink>
  );
};

export const NotesLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.notes}
    >
      {props.children}
    </NavLink>
  );
};

export const AppointmentsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.appointments}
    >
      {props.children}
    </NavLink>
  );
};
export const PatientsListingLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.patientslist}
    >
      {props.children}
    </NavLink>
  );
};
export const AppointmentsCalendarLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.appointmentCalendar}
    >
      {props.children}
    </NavLink>
  );
};

export const AppointmentDetailsLink = (props) => {
  const {id} = props;
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={`${appRoutesConst.appointments}/${id}`}
    >
      {props.children}
    </NavLink>
  );
};

export const ConsultationLink = (props) => {
  const {id} = props;
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={`${appRoutesConst.appointments}/${id}/consultation`}
    >
      {props.children}
    </NavLink>
  );
};

export const ReferralsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.referrals}
    >
      {props.children}
    </NavLink>
  );
};

export const PaymentsNavLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.payments}
    >
      {props.children}
    </NavLink>
  );
};

export const PaymentsLink = (props) => {
  return (
    <Link {...props} to={appRoutesConst.payments}>
      {props.children}
    </Link>
  );
};

export const PaymentsDetailsLink = (props) => {
  const {id} = props;
  return (
    <Link {...props} to={`${appRoutesConst.payments}/${id}`}>
      {props.children}
    </Link>
  );
};

export const PaymentMethodLink = (props) => {
  return (
    <Link {...props} to={appRoutesConst.paymentMethod}>
      {props.children}
    </Link>
  );
};

export const SubscriptionLink = (props) => {
  return (
    <Link {...props} to={appRoutesConst.subscription}>
      {props.children}
    </Link>
  );
};

export const SubscriptionDetailsLink = (props) => {
  const {id} = props;
  return (
    <Link {...props} to={`${appRoutesConst.subscription}/${id}`}>
      {props.children}
    </Link>
  );
};

export const SubscriptionInvoiceLink = (props) => {
  const {id} = props;
  return (
    <Link {...props} to={`${appRoutesConst.subscription}/invoice/${id}`}>
      {props.children}
    </Link>
  );
};

export const ClinicalNavLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.clinical}
    >
      {props.children}
    </NavLink>
  );
};
export const MarketingLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.marketing}
    >
      {props.children}
    </NavLink>
  );
};

export const RefundsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.refunds}
    >
      {props.children}
    </NavLink>
  );
};
export const TestingSessionLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.testingSessions}
    >
      {props.children}
    </NavLink>
  );
};
export const DiscountCodesLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.discountcodes}
    >
      {props.children}
    </NavLink>
  );
};
export const FeedBackLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.feedbacks}
    >
      {props.children}
    </NavLink>
  );
};
export const CallLogsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.callogs}
    >
      {props.children}
    </NavLink>
  );
};
export const NotificationsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.notifications}
    >
      {props.children}
    </NavLink>
  );
};
export const SubscriptionsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.subscription}
    >
      {props.children}
    </NavLink>
  );
};
export const CreateUserLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.newUser}
    >
      {props.children}
    </NavLink>
  );
};
export const CovidReportsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.covidreports}
    >
      {props.children}
    </NavLink>
  );
};
export const SendEmailLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.sendemail}
    >
      {props.children}
    </NavLink>
  );
};
export const WaitingRoomLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.waitingRoom}
    >
      {props.children}
    </NavLink>
  );
};
export const PrescriptionLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.prescription}
    >
      {props.children}
    </NavLink>
  );
};
export const navigateToClinicalDetails = () => {
  return (dispatch) => {
    dispatch(push(appRoutesConst.clinicalDetails));
  };
};
export const navigateToClinicalDetailswithPatient = (id) => {
  return (dispatch) => {
    dispatch(push(`/customer/clinical/details/${id}?is_patient=true`));
  };
};
export const UnverifiedUsersLink = (props) => {
  return <Link to={appRoutesConst.unverifiedUsers}>{props.children}</Link>;
};
export const RotaDoctorsLink = (props) => {
  return <Link to={appRoutesConst.availableDoctors}>{props.children}</Link>;
};
export const PatientsLink = (props) => {
  return <Link to={appRoutesConst.patients}>{props.children}</Link>;
};
export const DoctorsLink = (props) => {
  return <Link to={appRoutesConst.doctors}>{props.children}</Link>;
};

export const NhsOrdersLink = (props) => {
  return <Link to={appRoutesConst.nhsOrders}>{props.children}</Link>;
};

export const ForgotPasswordLink = (props) => {
  return <Link to={appRoutesConst.forgotpassword}>{props.children}</Link>;
};

//Added by rohan
export const SummaryLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.newCustomerDetails}
    >
      {props.children}
    </NavLink>
  );
};

export const OnlinePaitentsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.patients}
    >
      {props.children}
    </NavLink>
  );
};
export const CounsultingDoctorsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.doctors}
    >
      {props.children}
    </NavLink>
  );
};
export const NewUsersLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.unverifiedUsers}
    >
      {props.children}
    </NavLink>
  );
};
export const RotaOfDoctoersLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.availableDoctors}
    >
      {props.children}
    </NavLink>
  );
};
export const DownloadsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.downloads}
    >
      {props.children}
    </NavLink>
  );
};
export const BusinessPartnersLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.businessPartners}
    >
      {props.children}
    </NavLink>
  );
};
export const CovidTestkitOrdersLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.covidtestkitorders}
    >
      {props.children}
    </NavLink>
  );
};
export const InformGpLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.informGp}
    >
      {props.children}
    </NavLink>
  );
};
export const CorporateSubscriptionsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.corporateSubscriptions}
    >
      {props.children}
    </NavLink>
  );
};
export const EmergencyAlertsLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.emergencyAlerts}
    >
      {props.children}
    </NavLink>
  );
};
export const CorporateSubscriptionsDetailLink = (props) => {
  let {id} = props;
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={`${appRoutesConst.corporateSubscriptions}/${id}`}
    >
      {props.children}
    </NavLink>
  );
};
export const RightToWorkLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.rightToWork}
    >
      {props.children}
    </NavLink>
  );
};
export const StaffIntranetLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.staffIntranet}
    >
      {props.children}
    </NavLink>
  );
};

export const ProfileLink = (props) => {
  return (
    <NavLink
      activeClassName={config.activeClassName}
      {...props}
      to={appRoutesConst.profile}
    >
      {props.children}
    </NavLink>
  );
};

export const SignupLink = (props) => {
  return <Link to={appRoutesConst.signup}>{props.children}</Link>;
};

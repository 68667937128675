import React, {Component} from "react";
import {Tabs, Image} from "antd";
import {getConsultationScreenshots} from "../../services/consultations";
import {errorToast, getErrorObject, verifyObject} from "../../utilities/utils";
import {Collapse} from "antd";
import {connect} from "react-redux";
import CommonLoader from "../../components/common/CommonLoader";
import moment from "moment";
import {isArray} from "lodash";
import NoFoundMessage from "../../components/common/NoFoundMessage";
import {DATE_FORMAT, T24_HR_FORMAT} from "../../constants/common";
// import Scrollbars from "react-custom-scrollbars";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
let {Panel} = Collapse;
const {TabPane} = Tabs;
class CallScreenShotContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshots: [],
      isLoading: false,
      uploadPercent: 0,
      uploading: false,
      list_type: "current",
      screenShotTakenCount: null,
    };
  }
  componentDidMount() {
    this._onRequestList();
  }
  async _onRequestList() {
    let {appointmentId, patient_id} = this.props;
    let idParams = {};
    if (appointmentId) {
      idParams = {appointment_id: appointmentId};
    } else if (patient_id) {
      idParams = {patient_id: patient_id};
    }
    try {
      await this.setState({isLoading: true});
      let response = await getConsultationScreenshots({
        ...idParams,
        list_type: this.state.list_type,
      });
      this.setState({
        screenshots: verifyObject(response, "data.data", []),
      });
      await this.setState({isLoading: false});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }

  onTabChange = (tabName) => {
    this.setState({list_type: tabName}, () => {
      this._onRequestList();
    });
  };

  componentWillReceiveProps(nextProps) {
    let {screenShotTakenCount} = nextProps;
    this.setState({
      screenShotTakenCount: screenShotTakenCount,
    });
  }

  componentDidUpdate(prevProps, nextState) {
    if (
      this.state.screenShotTakenCount !== nextState.screenShotTakenCount &&
      nextState.screenShotTakenCount
    ) {
      this._onRequestList();
    }
  }

  render() {
    let {screenshots, isLoading} = this.state;

    return (
      <div className="push-20-b">
        <Tabs
          defaultActiveKey="1"
          onChange={this.onTabChange}
          className="screenshort_tabs"
        >
          <TabPane tab="Current" key="current">
            {isLoading && <CommonLoader />}

            {!isLoading && screenshots.length === 0 && (
              <div className="sectionsubscroll">
                <PerfectScrollbar>
                  <div className="left-right-space">
                    <NoFoundMessage message="No current screenshots available" />
                  </div>
                </PerfectScrollbar>
              </div>
            )}

            <div className="sectionsubscroll">
              <PerfectScrollbar>
                <div className="left-right-space">
                  {screenshots.map((s, index) => {
                    return (
                      <Collapse
                        defaultActiveKey={["1"]}
                        expandIconPosition="right"
                        className="pre_quitionare"
                      >
                        <Panel
                          key={index + 1}
                          header={
                            <div>
                              <p className="font-12 weight-400 line-1-43 margin-bottom-0 text-fff padding-5">
                                {moment(s.created_at).format(DATE_FORMAT)} |{" "}
                                {moment(s.created_at).format(T24_HR_FORMAT)}
                              </p>
                            </div>
                          }
                        >
                          <div className="col-md-12 p-0">
                            <div className="form-row">
                              <div className="col-md-6">
                                <div className="div-border padding-6-imp">
                                  <Image alt="" src={s.file}></Image>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    );
                  })}
                </div>
              </PerfectScrollbar>
            </div>
          </TabPane>
          <TabPane tab="Past" key="past">
            {isLoading && <CommonLoader />}
            <div className="sectionsubscroll">
              <PerfectScrollbar>
                <div className="left-right-space">
                  {!isLoading && screenshots.length === 0 && (
                    <NoFoundMessage message="No past screenshots available" />
                  )}
                </div>
              </PerfectScrollbar>
            </div>
            <div className="screenshort_tabs_list walkingappointmentsecscroll">
              <div className="sectionsubscroll">
                <PerfectScrollbar>
                  <div className="left-right-space">
                    {screenshots &&
                      isArray(screenshots) &&
                      screenshots.map((s, index) => {
                        return (
                          <Collapse
                            className="pt-0"
                            expandIconPosition="right"
                            className="pre_quitionare"
                            defaultActiveKey={["1"]}
                          >
                            <Panel
                              key={index + 1}
                              header={
                                <div>
                                  <p className="font-12 weight-400 line-1-43 margin-bottom-0 text-fff padding-5">
                                    {moment(s.created_at).format(DATE_FORMAT)} |{" "}
                                    {moment(s.created_at).format(T24_HR_FORMAT)}
                                  </p>
                                </div>
                              }
                            >
                              <div className="col-md-12">
                                <div className="form-row">
                                  <div className="col-md-6">
                                    <div className="div-border padding-6-imp">
                                      <Image alt="" src={s.file}></Image>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        );
                      })}
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    patient_id: verifyObject(
      state.localStore,
      "patient_detail_obj.user.id",
      null
    ),

    token: verifyObject(state, "localStore.token", ""),
    screenShotTakenCount: verifyObject(
      state.localStore,
      "screenShotTakenCount",
      null
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  null
)(CallScreenShotContainer);
export default ConnectedComponent;

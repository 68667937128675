/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { Fragment, useState, useEffect } from "react";
import { Tabs } from "antd";
// import height from "assets/images/sidebar/ic-height.svg";
// import weight from "assets/images/sidebar/ic-weight.svg";
// import bmi from "assets/images/sidebar/ic-bmi.svg";
// import blood_pressure from "assets/images/sidebar/ic-bloodpressure.svg";
// import heartrate from "assets/images/sidebar/ic-heartrate.svg";
// import bloodoxygen from "assets/images/sidebar/ic-bloodoxygen.svg";
// import musclerate from "assets/images/sidebar/ic-musclerate.svg";
// import bonemineral from "assets/images/sidebar/ic-bonemineral.svg";
// import watercontent from "assets/images/sidebar/ic-watercontent.svg";
// import visceralfatrating from "assets/images/sidebar/ic-visceralfatrating.svg";
// import bodyfat from "assets/images/sidebar/ic-bodyfat.svg";
// import bmr from "assets/images/sidebar/ic-bmr.svg";
// import bodytemperature from "assets/images/sidebar/ic-bodytemperature.svg";
// import HeightContainer from "./HeightContainer";
// import WeightContainer from "./WeightContainer";
// import BMIContainer from "./BMIContainer";
// import BloodPressureContainer from "./BloodPressureContainer";
// import HeartRateContainer from "./HeartRateContainer";
// import BloodOxygenContainer from "./BloodOxygenContainer";
// import MuscleRateContainer from "./MuscleRateContainer";
// import BoneMineralContainer from "./BoneMineralContainer";
// import WaterContentContainer from "./WaterContentContainer";
// import VisceralFatRatingContainer from "./VisceralFatRatingContainer";
// import BodyFatContainer from "./BodyFatContainer";
// import BMRContainer from "./BMRContainer";
// import BodyTemperatureContainer from "./BodyTemperatureContainer";
// import {Select} from "antd";
//import TableLoader from "../TableLoader/TableLoader";

import {
  getHealthSummary,
  getHealthSummaryDetails,
  getHealthSummaryGraph,
} from "services/healthAssessment";
import { errorToast, getErrorObject, verifyObject } from "utilities/utils";
import { isArray, isObject, round, uniq } from "lodash";
import HealthAssessmentDetailContainer from "./HealthAssessmentDetailContainer";
//import CommonLoader from "components/common/CommonLoader";
import moment from "moment";
import { connect } from "react-redux";
import NoFoundMessage from "components/common/NoFoundMessage";
// import {usersApi} from "../../services/unverifiedUsers";

import CommonLoader from "components/common/CommonLoader";
// import TableLoader from "../../../components/TableLoader/TableLoader";
// import TableLoader from "components/TableLoader/TableLoader";
// import { useSelector } from "react-redux";

const { TabPane } = Tabs;

function HealthAssessmentContainer({ patient_id }) {
  const [loading, setLoading] = useState(false);
  const [loadingDetails, setDetailLoading] = useState(false);
  const [healhSummaries, setHealthSummary] = useState([]);
  const [healthSummaryDetail, setHealthSummaryDetail] = useState(null);
  const [healthSummaryGraphDetail, setHealthSummaryGraphDetail] = useState([]);
  // const [patientList, setPatientList] = useState([]);
  const [state] = useState({
    loadingPatient: false,
    patient_id: patient_id,
  });
  // const patient_id = useSelector((state) =>
  //   verifyObject(state, "localStore.patientId", null)
  // );

  const [selectedKey, setSelectedKey] = useState("1");

  useEffect(() => {
    if (state.patient_id === undefined) {
      setHealthSummary([]);
      setHealthSummaryDetail(null);
      setHealthSummaryGraphDetail([]);
    } else if (state.patient_id !== undefined && state.patient_id !== null) {
      _getHealthSummary(state.patient_id);
    }
  }, [state.patient_id]);

  // const onGetPatients = async (search) => {
  //   // let { patientId,  } = props;

  //   if (!isEmpty(search) && search.trim().length !== 0) {
  //     try {
  //       await setState({...state, loadingPatient: true});
  //       let response = await usersApi({
  //         role: "patient",
  //         search: search,
  //       });
  //       if (response.data.data) {
  //         setPatientList(response.data.data);
  //         setState(
  //           {
  //             ...state,
  //             patientList: response.data.data,
  //             loadingPatient: false,
  //           },
  //           () => {}
  //         );
  //       }
  //     } catch (error) {
  //       const {message} = getErrorObject(error);
  //       await setState({...state, loadingPatient: false});
  //       errorToast({content: message});
  //     }
  //   }
  // };
  // const searchUpdate = useCallback(debounce(onGetPatients, 1000), []);

  const _getHealthSummary = async (id) => {
    setHealthSummary([]);
    setHealthSummaryDetail(null);
    setHealthSummaryGraphDetail([]);
    try {
      await setLoading(true);
      let response = await getHealthSummary({ patient_id: id });
      console.log("response===========>", response);
      console.log("first calllll====>");

      const summaryData = verifyObject(response, "data.data.summary", null);

      if (summaryData) {
        let heightData = summaryData.find((d) => d.point_type === "height");
        //("heightData", heightData);

        await _getSummaryDetail(heightData, id);
      }
      if (response.data.status === 200) {
        setHealthSummary(summaryData);
      } else {
        setHealthSummary([]);
        setHealthSummaryDetail(null);
        setHealthSummaryGraphDetail([]);
      }

      await setLoading(false);
    } catch (error) {
      const { message } = getErrorObject(error);
      await setLoading(false);
      errorToast({ content: message });
    }
  };

  const _getSummaryDetail = async (d, id) => {
    try {
      await setDetailLoading(true);
      let detailresponse = await getHealthSummaryDetails({
        health_summary_id: d.health_summary_id,
        health_data_point_type_id: d.health_data_point_type_id,
        patient_id: state.patient_id ? state.patient_id : id,
      });

      let graphresponse = await getHealthSummaryGraph({
        id: d.health_data_point_type_id,
        patient_id: state.patient_id ? state.patient_id : id,
      });
      const summaryDetails = verifyObject(detailresponse, "data.data", null);
      const summaryGraphDetailsObj = verifyObject(
        graphresponse,
        "data.data",
        null
      );
      // let graphValuesXAxis = [];
      // let graphValuesYAxis = [];
      // let graphValuesBothAxis = [];

      let name = "";
      //     	[Date.UTC(2007, 0, 1), 0.7537],
      // [Date.UTC(2007, 0, 2), 0.7537],
      // //("summaryDetails", summaryDetails);
      let graphXAxis = [];
      let graphYAxis = [];
      let diastoGraphXAxis = [];
      let diastoGraphYAxis = [];
      let systolicGraphXAxis = [];
      let systolicGraphYAxis = [];

      let systolicBp = summaryGraphDetailsObj.filter(
        (d) => d.name === "systolic_bp"
      );

      let diastolicBp = summaryGraphDetailsObj.filter(
        (d) => d.name === "diastolic_bp"
      );

      if (systolicBp && isArray(systolicBp) && systolicBp[0] !== null) {
        uniq(systolicBp).map(async (d) => {
          systolicGraphXAxis = [d.multiplied_value, ...systolicGraphXAxis];
          systolicGraphYAxis = [
            moment(d.reading_date).format("DD MMM YYYY"),
            ...systolicGraphYAxis,
          ];
        });
      }

      if (diastolicBp && isArray(diastolicBp) && diastolicBp[0] !== null) {
        uniq(diastolicBp).map(async (d) => {
          diastoGraphXAxis = [d.multiplied_value, ...diastoGraphXAxis];
          diastoGraphYAxis = [
            moment(d.reading_date).format("DD MMM YYYY"),
            ...diastoGraphYAxis,
          ];
        });
      }
      console.log("systolicBp", uniq(systolicBp));
      console.log("diastolicBp", uniq(diastolicBp));

      if (summaryGraphDetailsObj) {
        summaryGraphDetailsObj.map(async (d, index) => {
          // let year = moment(d.reading_date).year();
          // let month = moment(d.reading_date).month();
          // let day = moment(d.reading_date).date();
          if (d.name === "systolic_bp" || d.name === "diastolic_bp") {
            // graphXAxis = [d.multiplied_value, ...graphXAxis];
            // graphYAxis = [
            //   moment(d.reading_date).format("DD MMM "),
            //   ...graphYAxis,
            // ];
          } else {
            // console.log("d.reading_date", d.reading_date);
            graphXAxis = [d.multiplied_value, ...graphXAxis];
            graphYAxis = [
              moment(d.reading_date).format("DD MMM YYYY"),
              ...graphYAxis,
            ];
          }

          // // ...graphValuesBothAxis,
          // graphValuesBothAxis = [

          //   [parsed, d.multiplied_value],
          // ];

          name = d.name;
          return null;
        });
      }
      // console.log("parsed", name);

      setHealthSummaryDetail(summaryDetails);
      // console.log("graphValuesBothAxis", graphValuesBothAxis);
      // setHealthSummaryGraphDetail([graphXAxis, graphYAxis]);

      if (name === `systolic_bp` || name === `diastolic_bp`) {
        setHealthSummaryGraphDetail({
          systolic: [systolicGraphXAxis, systolicGraphYAxis],
          diastolic: [diastoGraphXAxis, diastoGraphYAxis],
          name: "blood_pressure",
        });
      } else {
        setHealthSummaryGraphDetail([graphXAxis, graphYAxis]);
      }

      await setDetailLoading(false);
    } catch (error) {
      // const { message } = getErrorObject(error);
      await setDetailLoading(false);
      setHealthSummaryDetail(null);
      setHealthSummaryGraphDetail([]);
      // errorToast({ content: message });
    }
  };

  const renderValues = (d, index) => {
    let className = `text-blue`;
    let bmiLable = ``;

    if (selectedKey == index) {
      className = `text-white`;
    }
    if (d.point_type === "height") {
      if (
        d.full_ranges &&
        d.value < d.full_ranges.max &&
        d.value > d.full_ranges.min
      ) {
        if (selectedKey == index) {
          className = `text-white`;
        } else {
          className = `health-green`;
        }
      }
    }
    if (d.point_type === "bmi") {
      if (d.ranges) {
        d.ranges.map((e) => {
          if (e.value === "underweight") {
            if (d.value > e.min && d.value < e.max) {
              // //("underweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `text-blue`;
              }
              bmiLable = "Underweight";
            }
          }

          if (e.value === "healthy") {
            if (d.value > e.min && d.value < e.max) {
              // //("healthy", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-green`;
              }
              bmiLable = "Healthy";
            }
          }

          if (e.value === "overweight") {
            if (d.value > e.min && d.value < e.max) {
              //("overweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-orange`;
              }

              bmiLable = "Overweight";
            }
          }

          if (e.value === "obese") {
            if (d.value > e.min && d.value <= e.max) {
              // //("obese", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-red`;
              }

              bmiLable = "Obese";
            }
          }
          return null;
        });
      }
    }
    if (d.point_type === "pulse_per_minute") {
      if (d.ranges) {
        d.ranges.map((e) => {
          if (e.value === "low") {
            if (d.value > e.min && d.value < e.max) {
              // //("underweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `text-blue`;
              }
              // bmiLable = "Low";
            }
          }

          if (e.value === "ideal") {
            if (d.value > e.min && d.value < e.max) {
              // //("healthy", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-green`;
              }
              // bmiLable = "Healthy";
            }
          }

          if (e.value === "high") {
            if (d.value > e.min && d.value < e.max) {
              // //("overweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-orange`;
              }

              // bmiLable = "Overweight";
            }
          }

          // if (e.value === "obese") {
          //   if (d.value > e.min && d.value <= e.max) {
          //     //("obese", e);
          //     if (selectedKey == index) {
          //       className = `text-white`;
          //     } else {
          //       className = `health-red`;
          //     }

          //     bmiLable = "Obese";
          //   }
          // }
          return null;
        });
      }
    }
    if (d.point_type === "blood_oxygen_saturation") {
      if (d.ranges) {
        d.ranges.map((e) => {
          if (e.value === "low") {
            if (d.value > e.min && d.value < e.max) {
              // //("underweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `text-blue`;
              }
              // bmiLable = "Low";
            }
          }

          if (e.value === "ideal") {
            if (d.value > e.min && d.value < e.max) {
              // //("healthy", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-green`;
              }
              // bmiLable = "Healthy";
            }
          }

          if (e.value === "high") {
            if (d.value > e.min && d.value < e.max) {
              // //("overweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-orange`;
              }

              // bmiLable = "Overweight";
            }
          }

          // if (e.value === "obese") {
          //   if (d.value > e.min && d.value <= e.max) {
          //     //("obese", e);
          //     if (selectedKey == index) {
          //       className = `text-white`;
          //     } else {
          //       className = `health-red`;
          //     }

          //     bmiLable = "Obese";
          //   }
          // }
          return null;
        });
      }
    }
    if (d.point_type === "fat_rate") {
      if (d.ranges) {
        d.ranges.map((e) => {
          if (e.value === "low") {
            if (d.value > e.min && d.value < e.max) {
              //("underweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `text-blue`;
              }
              // bmiLable = "Low";
            }
          }

          if (e.value === "ideal") {
            if (d.value > e.min && d.value < e.max) {
              //("healthy", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-green`;
              }
              // bmiLable = "Healthy";
            }
          }

          if (e.value === "high") {
            if (d.value > e.min && d.value < e.max) {
              // //("overweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-orange`;
              }

              // bmiLable = "Overweight";
            }
          }

          // if (e.value === "obese") {
          //   if (d.value > e.min && d.value <= e.max) {
          //      ("obese", e);
          //     if (selectedKey == index) {
          //       className = `text-white`;
          //     } else {
          //       className = `health-red`;
          //     }

          //     bmiLable = "Obese";
          //   }
          // }
          return null;
        });
      }
    }

    if (d.point_type === "visceral_fat_index") {
      if (d.ranges) {
        d.ranges.map((e) => {
          if (e.value === "healthy") {
            if (d.value > e.min && d.value < e.max) {
              //("healthy", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-green`;
              }
              // bmiLable = "Healthy";
            }
          }

          if (e.value === "excessive") {
            if (d.value > e.min && d.value < e.max) {
              //("overweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-orange`;
              }

              // bmiLable = "Overweight";
            }
          }

          // if (e.value === "obese") {
          //   if (d.value > e.min && d.value <= e.max) {
          //     //("obese", e);
          //     if (selectedKey == index) {
          //       className = `text-white`;
          //     } else {
          //       className = `health-red`;
          //     }

          //     bmiLable = "Obese";
          //   }
          // }
          return null;
        });
      }
    }
    if (d.point_type === "body_moisture_rate") {
      if (d.ranges) {
        d.ranges.map((e) => {
          if (e.value === "low") {
            if (d.value > e.min && d.value < e.max) {
              //("underweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `text-blue`;
              }
              // bmiLable = "Low";
            }
          }

          if (e.value === "ideal") {
            if (d.value > e.min && d.value < e.max) {
              //("healthy", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-green`;
              }
              // bmiLable = "Healthy";
            }
          }

          if (e.value === "high") {
            if (d.value > e.min && d.value < e.max) {
              //("overweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-orange`;
              }

              // bmiLable = "Overweight";
            }
          }

          // if (e.value === "obese") {
          //   if (d.value > e.min && d.value <= e.max) {
          //     //("obese", e);
          //     if (selectedKey == index) {
          //       className = `text-white`;
          //     } else {
          //       className = `health-red`;
          //     }

          //     bmiLable = "Obese";
          //   }
          // }
          return null;
        });
      }
    }

    if (d.point_type === "skeletal_muscle_score") {
      if (d.ranges) {
        d.ranges.map((e) => {
          if (e.value === "low") {
            if (d.value > e.min && d.value < e.max) {
              //("underweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `text-blue`;
              }
              // bmiLable = "Low";
            }
          }

          if (e.value === "ideal") {
            if (d.value > e.min && d.value < e.max) {
              //("healthy", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-green`;
              }
              // bmiLable = "Healthy";
            }
          }

          if (e.value === "high") {
            if (d.value > e.min && d.value < e.max) {
              //("overweight", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-orange`;
              }

              // bmiLable = "Overweight";
            }
          }

          // if (e.value === "obese") {
          //   if (d.value > e.min && d.value <= e.max) {
          //     //("obese", e);
          //     if (selectedKey == index) {
          //       className = `text-white`;
          //     } else {
          //       className = `health-red`;
          //     }

          //     bmiLable = "Obese";
          //   }
          // }
          return null;
        });
      }
    }
    if (d.point_type === "blood_pressure") {
      if (d.ranges) {
        d.ranges.systolic.map((e) => {
          //("low");

          if (e.value === "low") {
            if (d.value.systolic_bp > e.min && d.value.systolic_bp < e.max) {
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `text-blue`;
              }
              //   bmiLable = "Low";
            }
          }

          if (e.value === "ideal") {
            if (d.value.systolic_bp > e.min && d.value.systolic_bp < e.max) {
              //("ideal", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-green`;
              }
              //   bmiLable = "Ideal";
            }
          }

          if (e.value === "high") {
            if (d.value.systolic_bp > e.min && d.value.systolic_bp < e.max) {
              //("high", e);
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-orange`;
              }

              //   bmiLable = "High";
            }
          }
          if (e.value === "very_high") {
            //("overweight", e);
            if (d.value.systolic_bp > e.min && d.value.systolic_bp < e.max) {
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-red`;
              }
            }
            //   // bmiLable = "Overweight";
          }
          if (e.value === "at_risk") {
            //("overweight", e);
            if (d.value.systolic_bp > e.min && d.value.systolic_bp < e.max) {
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-at_risk`;
              }
            }
            //   // bmiLable = "Overweight";
          }
          if (e.value === "severe") {
            //("overweight", e);
            if (d.value.systolic_bp > e.min && d.value.systolic_bp < e.max) {
              if (selectedKey == index) {
                className = `text-white`;
              } else {
                className = `health-severe`;
              }
            }
            //   // bmiLable = "Overweight";
          }
          // if (e.value === "obese") {
          //   if (d.value > e.min && d.value <= e.max) {
          //     //("obese", e);
          //     if (selectedKey == index) {
          //       className = `text-white`;
          //     } else {
          //       className = `health-red`;
          //     }

          //     bmiLable = "Obese";
          //   }
          // }
          return null;
        });
      }
    }

    return (
      <span className={`icons_wrapper_data ${className}`}>
        {!isObject(d.value) && `${round(d.value, 2)}  ${d.unit ? d.unit : ""}`}{" "}
        {isObject(d.value) &&
          `${d.value && round(d.value.systolic_bp, 2)}  ${
            d.unit ? d.unit : ""
          }`}{" "}
        {isObject(d.value) &&
          `/ ${d.value && round(d.value.diastolic_bp, 2)}  ${
            d.unit ? d.unit : ""
          }`}{" "}
        {/* {d && d.value} {d && d.unit} */}
        {bmiLable}
      </span>
    );
  };

  const handleTabChange = (key) => {
    setSelectedKey(key);
  };

  console.log("health summaries===>", healhSummaries);
  return (
    <Fragment>
      <div className="rightmainsection healthassesmentpage">
        <div className="padding-bottom-120 right_wrapper">
          <div className="container-fluid padding-55-t selection_container padding-left-0 padding-right-0">
            <div className="healthassesment-list">
              <div class="weight-500 font-14 text-light-black push-10-b">
                My Health
              </div>

              {/* <div className="form-row">
                <div className="relative searchbar_dropdown w-100">
                  <Select
                    showSearch
                    allowClear
                    className="form-control margin-0 custom-arrow"
                    placeholder="Search Patient (id, phone, email, name ,DOB (dd/mm/yyyy))"
                    filterOption={false}
                    value={state.patient_id ? state.patient_id : null}
                    onChange={async (value) => {
                      console.log("valuee====>", value);
                      if (value !== undefined) {
                        

                        setState({...state, patient_id: value});
                      } else {
                        setState({...state, patient_id: value});
                      }
                    }}
                    onSearch={(value) => {
                      searchUpdate(value);
                    }}
                    loading={state.loadingPatient}
                  >
                    {patientList.map((patient) => {
                      return (
                        <Select.Option value={patient.id}>
                          {`${patient.first_name} ${patient.last_name} `}
                          <span style={{textTransform: "lowercase"}}>
                            ({patient.email} )
                          </span>
                        </Select.Option>
                      );
                    })}
                  </Select>
                  
                </div>
              </div> */}

              <div className="form-row">
                <div className="col-md-12">
                  {loading && (
                    <div>
                      <CommonLoader />
                    </div>
                  )}

                  {!loading && (
                    <Tabs
                      onChange={handleTabChange}
                      defaultActiveKey="1"
                      className="healthassement_tabs healthassement_tabs_health"
                    >
                      {healhSummaries &&
                        healhSummaries.length > 0 &&
                        healhSummaries.map((d, index) => {
                          return (
                            <TabPane
                              tab={
                                <div
                                  onClick={() => _getSummaryDetail(d)}
                                  className="healthassement_box"
                                >
                                  <span className="icons_wrapper">
                                    <span className="icons_wrapper_img_box">
                                      <img
                                        style={{ height: 25 }}
                                        src={d.icon}
                                        alt="Height"
                                      />
                                    </span>
                                    <span className="icons_wrapper_title">
                                      {d.name}
                                    </span>
                                  </span>
                                  {renderValues(d, index + 1)}
                                </div>
                              }
                              key={index + 1}
                            >
                              {loadingDetails && (
                                <div>
                                  <CommonLoader />
                                </div>
                              )}
                              {!loadingDetails && (
                                <HealthAssessmentDetailContainer
                                  healthSummaryDetail={healthSummaryDetail}
                                  healthSummaryGraphDetail={
                                    healthSummaryGraphDetail
                                  }
                                  record={d}
                                />
                              )}
                            </TabPane>
                          );
                        })}

                      {/* 
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={weight} alt="Weight" />
                      </span>
                      <span className="icons_wrapper_title">Weight</span>
                    </span>
                    <span className="icons_wrapper_data text-orange">
                      64 kg
                    </span>
                  </div>
                }
                key="2"
              >
                <WeightContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={bmi} alt="BMI" />
                      </span>
                      <span className="icons_wrapper_title">BMI</span>
                    </span>
                    <span className="icons_wrapper_data text-orange">
                      25 Obese
                    </span>
                  </div>
                }
                key="3"
              >
                <BMIContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={blood_pressure} alt="Blood Pressure" />
                      </span>
                      <span className="icons_wrapper_title">
                        Blood Pressure
                      </span>
                    </span>
                    <span className="icons_wrapper_data text-green">
                      60 mmHg
                    </span>
                  </div>
                }
                key="4"
              >
                <BloodPressureContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={heartrate} alt="Blood Pressure" />
                      </span>
                      <span className="icons_wrapper_title">Heart Rate</span>
                    </span>
                    <span className="icons_wrapper_data text-green">88</span>
                  </div>
                }
                key="5"
              >
                <HeartRateContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={bloodoxygen} alt="Blood Oxygen" />
                      </span>
                      <span className="icons_wrapper_title">Blood Oxygen</span>
                    </span>
                    <span className="icons_wrapper_data text-green">91%</span>
                  </div>
                }
                key="6"
              >
                <BloodOxygenContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={musclerate} alt="Blood Pressure" />
                      </span>
                      <span className="icons_wrapper_title">Muscle Rate</span>
                    </span>
                    <span className="icons_wrapper_data text-green">39%</span>
                  </div>
                }
                key="7"
              >
                <MuscleRateContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={bonemineral} alt="Bone Mineral" />
                      </span>
                      <span className="icons_wrapper_title">Bone Mineral</span>
                    </span>
                    <span className="icons_wrapper_data text-red">39%</span>
                  </div>
                }
                key="8"
              >
                <BoneMineralContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={watercontent} alt="Water Content" />
                      </span>
                      <span className="icons_wrapper_title">Water Content</span>
                    </span>
                    <span className="icons_wrapper_data text-red">50%</span>
                  </div>
                }
                key="9"
              >
                <WaterContentContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={visceralfatrating} alt="Water Content" />
                      </span>
                      <span className="icons_wrapper_title">
                        Visceral Fat Rating
                      </span>
                    </span>
                    <span className="icons_wrapper_data text-green">6</span>
                  </div>
                }
                key="10"
              >
                <VisceralFatRatingContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={bodyfat} alt="Body Fat" />
                      </span>
                      <span className="icons_wrapper_title">Body Fat</span>
                    </span>
                    <span className="icons_wrapper_data text-orange">14%</span>
                  </div>
                }
                key="11"
              >
                <BodyFatContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={bmr} alt="BMR" />
                      </span>
                      <span className="icons_wrapper_title">BMR</span>
                    </span>
                    <span className="icons_wrapper_data text-red">
                      1620 Kcal
                    </span>
                  </div>
                }
                key="12"
              >
                <BMRContainer />
              </TabPane>
              <TabPane
                tab={
                  <div className="healthassement_box">
                    <span className="icons_wrapper">
                      <span className="icons_wrapper_img_box">
                        <img src={bodytemperature} alt="Body Temperature" />
                      </span>
                      <span className="icons_wrapper_title">
                        Body Temperature
                      </span>
                    </span>
                    <span className="icons_wrapper_data text-red">38°C</span>
                  </div>
                }
                key="13"
              >
                <BodyTemperatureContainer />
              </TabPane> */}
                    </Tabs>
                  )}
                  {!loading &&
                    healhSummaries &&
                    healhSummaries.length === 0 && (
                      <div className="no_healthdata">
                        <NoFoundMessage message={"No health data found"} />
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    patient_id: verifyObject(state, "localStore.patientId", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // actions: bindActionCreators({ onPatientAdd }, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HealthAssessmentContainer);

/* eslint-disable eqeqeq */
import React, { Fragment } from "react";
import TextBoxInputItem from "./TextBoxInputItem";

export default function TextBoxQuestion({
  handleAnswerChange,
  childQuestions,
  parentQuestion,
  question,
  childOfQuestions,
  gender,
  currentIndex,
}) {
  if (
    question.is_conditional &&
    childQuestions &&
    (parentQuestion.category === "single_choice" ||
      parentQuestion.category === "multi_choice" ||
      parentQuestion.category === "numerical") &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    console.log(
      "🚀 ~ file: TextBoxQuestion.jsx:21 ~ question.is_conditional:",
      question.is_conditional
    );
    //TODO: Handle conditional
    let selectedOption = parentQuestion.answer;
    if (parentQuestion.category === "single_choice") {
      if (question?.conditional_text_values && question?.conditional_text_values[0] === selectedOption) {
        return (
          <Fragment>
            <TextBoxInputItem
              childOfQuestions={childOfQuestions}
              childQuestions={childQuestions}
              handleAnswerChange={handleAnswerChange}
              parentQuestion={parentQuestion}
              question={question}
              currentIndex={currentIndex}
            />
          </Fragment>
        );
      } else {
        return <div></div>;
      }
    } else if (parentQuestion.category === "multi_choice") {
      console.log("Please selectedOption", selectedOption);
      console.log("Please multi_choice", question.conditional_text_values);

      if (question?.conditional_text_values && question?.conditional_text_values[0] === selectedOption) {
        return (
          <Fragment>
            <TextBoxInputItem
              childOfQuestions={childOfQuestions}
              childQuestions={childQuestions}
              handleAnswerChange={handleAnswerChange}
              parentQuestion={parentQuestion}
              question={question}
              currentIndex={currentIndex}
            />
          </Fragment>
        );
      } else {
        return <div></div>;
      }
    } else if (parentQuestion.category === "numerical") {
      let selectedValue = parseInt(parentQuestion.answer, 10);
      const { conditional_max_value, conditional_min_value } = question;
      console.log("selectedValue", selectedValue);
      console.log("Max", conditional_max_value);
      console.log("Min", conditional_min_value);
      if (
        selectedValue <= conditional_max_value &&
        selectedValue >= conditional_min_value
      ) {
        return (
          <Fragment>
            <TextBoxInputItem
              childOfQuestions={childOfQuestions}
              childQuestions={childQuestions}
              handleAnswerChange={handleAnswerChange}
              parentQuestion={parentQuestion}
              question={question}
              currentIndex={currentIndex}
            />
          </Fragment>
        );
      } else {
        return <div></div>;
      }
    } else {
      <TextBoxInputItem
        childOfQuestions={childOfQuestions}
        childQuestions={childQuestions}
        handleAnswerChange={handleAnswerChange}
        parentQuestion={parentQuestion}
        question={question}
        currentIndex={currentIndex}
      />;
    }
  } else if (
    !childOfQuestions &&
    childQuestions &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    console.log(
      "🚀 ~ file: TextBoxQuestion.jsx:75 ~ childQuestions:",
      childQuestions
    );
    return (
      <Fragment>
        <TextBoxInputItem
          childOfQuestions={childOfQuestions}
          childQuestions={childQuestions}
          handleAnswerChange={handleAnswerChange}
          parentQuestion={parentQuestion}
          question={question}
          currentIndex={currentIndex}
        />
      </Fragment>
    );
  } else if (
    childOfQuestions &&
    childQuestions.answer &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    console.log(
      "🚀 ~ file: TextBoxQuestion.jsx:94 ~ childOfQuestions:",
      childOfQuestions
    );

    return (
      <Fragment>
        <TextBoxInputItem
          childOfQuestions={childOfQuestions}
          childQuestions={childQuestions}
          handleAnswerChange={handleAnswerChange}
          parentQuestion={parentQuestion}
          question={question}
          currentIndex={currentIndex}
        />
      </Fragment>
    );
  } else if (
    !childOfQuestions &&
    !childQuestions &&
    question &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    console.log("🚀 ~ file: TextBoxQuestion.jsx:115 ~ question:", question);
    return (
      <TextBoxInputItem
        childOfQuestions={childOfQuestions}
        childQuestions={childQuestions}
        handleAnswerChange={handleAnswerChange}
        parentQuestion={parentQuestion}
        currentIndex={currentIndex}
        question={question}
      />
    );
  } else {
    return <div></div>;
  }
}

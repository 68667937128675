import React from "react";

import Layout from "../../Layouts/PageLayout";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  onRequestSetComponent,
  onSetSidebarKey,
} from "../../reducers/dynamicTab";
import { Prompt } from "react-router-dom";
import ChangePasswordContainer from "../../containers/session/ChangePasswordContainer";

class ChangePasswordLoggedInPage extends React.Component {
  render() {
    return (
      <Layout title="Right To Work">
        <Prompt
          message={(location, action) => {
            if (action === "PUSH") {
              let {
                onRequestSetComponent,
                onSetSidebarKey,
              } = this.props.dynamicTabAction;
              if (typeof onRequestSetComponent === "function") {
                onRequestSetComponent(null);
                onSetSidebarKey(null);
              }
              return true;
            }
            if (action === "POP") {
              let {
                onRequestSetComponent,
                onSetSidebarKey,
              } = this.props.dynamicTabAction;
              if (typeof onRequestSetComponent === "function") {
                onRequestSetComponent(null);
                onSetSidebarKey(null);
              }
              return true;
            }
            return true;
          }}
        />
        <div className="padding-44-t">
          <div className="login-title-wrapper">
            <div className="container-fluid">
              <h1 className="login-title-h1">Change Password</h1>
            </div>
          </div>

          <div className="container-fluid">
            <div className="login-form-wrapper">
              <ChangePasswordContainer />
            </div>
          </div>
        </div>
      </Layout>
    );
  }
}
const mapStateProps = (state) => {
  return {};
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      { onRequestSetComponent, onSetSidebarKey },
      dispatch
    ),
  };
};
const ConnectedComponent = connect(
  mapStateProps,
  mapDispatchToProps
)(ChangePasswordLoggedInPage);
export default ConnectedComponent;

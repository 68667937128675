import React from "react";
import Layout from "Layouts/PageLayout";
import PatientSearchContainer from "containers/PatientSearch/PatientSearchContainer";

class PatientSearchPage extends React.Component {
    render() {
        return (
            <Layout  >
                <PatientSearchContainer />
            </Layout>
        );
    }
}

export default PatientSearchPage

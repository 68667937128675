import React from "react";
import { DatePicker } from "antd";
import { DATE_FORMAT } from "constants/common";
import moment from "moment/moment";

export default function DatePickerItem({
  question,
  handleAnswerChange,
  parentQuestion,
  childQuestions,
  childOfQuestions,
}) {
  return (
    <div className="margin-t-15">
      <div className="form-row">
        <div className="col-md-10">
          <div className="weight-500 font-14 margin-bottom-10 d-flex">
            <span className="dot"></span>
            <span
              dangerouslySetInnerHTML={{ __html: question.title }}
              className="Questions"
            />
            {question.is_required ? <sup className="text-danger">*</sup> : null}
          </div>
        </div>
        <div className="col-md-2">
          <DatePicker
            allowClear={false}
            onChange={(date) => {
              console.log("date", moment(date).format(DATE_FORMAT));
              const val = moment(date).format(DATE_FORMAT);
              handleAnswerChange(
                val,
                parentQuestion,
                childQuestions,
                childOfQuestions
              );
            }}
            format="DD-MM-YYYY"
            className="form-control"
            placeholder={
              question.placeholder !== null ? question.placeholder : ""
            }
          />
        </div>
      </div>
      <div className="errorandinfo">
        {question.error && (
          <span className="text-danger margin-top-10 weight-400 font-12 in-block">
            {question.error}
          </span>
        )}
      </div>
    </div>
  );
}

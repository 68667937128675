import validator from "validator";
import isEmpty from "lodash/isEmpty";
import {passwordRegex, spaceRegex} from "../../constants/common";

export function ValidateInput(data, isPharmSmartUser) {
  let errors = {};

  if (data.first_name !== undefined && isEmpty(data.first_name)) {
    errors.first_name = "First name  is required";
  }

  if (
    data.first_name !== undefined &&
    !isEmpty(data.first_name) &&
    data.first_name.length > 40
  ) {
    errors.first_name = "First name should be less than  of 40 characters only";
  }

  if (
    data.first_name !== undefined &&
    !isEmpty(data.first_name) &&
    spaceRegex.test(data.first_name) === false
  ) {
    errors.first_name = "First name  is invalid";
  }

  if (data.last_name !== undefined && isEmpty(data.last_name)) {
    errors.last_name = "Last name  is required";
  }
  if (
    data.last_name !== undefined &&
    !isEmpty(data.last_name) &&
    data.last_name.length > 40
  ) {
    errors.last_name = "Last name should be less than  of 40 characters only";
  }

  if (
    data.last_name !== undefined &&
    !isEmpty(data.last_name) &&
    spaceRegex.test(data.last_name) === false
  ) {
    errors.last_name = "Last name  is invalid";
  }

  if (data.phone !== undefined && isEmpty(data.phone)) {
    errors.phone = "Mobile no is required";
  }

  if (data.phone !== undefined && isEmpty(data.phone) === false) {
    if (isPharmSmartUser) {
      if (!validator.isLength(data.phone, {min: 13, max: 14})) {
        errors.phone = "Mobile no should be of 14 digit";
      }
    } else if (!validator.isLength(data.phone, {min: 13, max: 13})) {
      errors.phone = "Mobile no should be of 13 digit";
    }
  }

  if (data.phone !== undefined && isEmpty(data.phone) === false) {
    if (!validator.isNumeric(data.phone)) {
      errors.phone = "Please enter valid mobile number";
    }
  }

  if (data.email !== undefined && isEmpty(data.email)) {
    errors.email = "Email is required";
  }

  if (data.email !== undefined && isEmpty(data.email) === false) {
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is invalid";
    }
  }

  if (
    data.email !== undefined &&
    !isEmpty(data.email) &&
    data.email.length > 60
  ) {
    errors.email = "Email should be less than  of 60 characters only";
  }

  if (data.gender === null) {
    errors.gender = "Gender is required";
  }

  if (
    data.usual_town !== undefined &&
    isEmpty(data.usual_town) &&
    !isPharmSmartUser
  ) {
    errors.usual_town = "Town is required";
  }
  if (
    data.usual_line1 !== undefined &&
    isEmpty(data.usual_line1) &&
    !isPharmSmartUser
  ) {
    errors.usual_line1 = "Address Line 1   is required";
  }
  if (
    data.usual_line1 !== undefined &&
    !isEmpty(data.usual_line1) &&
    data.usual_line1.length > 120 &&
    !isPharmSmartUser
  ) {
    errors.usual_line1 =
      "Address line 1 should be less than  of 120 characters only";
  }

  if (
    data.usual_line2 !== undefined &&
    isEmpty(data.usual_line2) &&
    !isPharmSmartUser
  ) {
    errors.usual_line2 = "Address Line 2 is required";
  }
  if (
    data.usual_line2 !== undefined &&
    !isEmpty(data.usual_line2) &&
    data.usual_line2.length > 120 &&
    !isPharmSmartUser
  ) {
    errors.usual_line2 =
      "Address line 2 should be less than  of 120 characters only";
  }

  // if (data.usual_line3 !== undefined && isEmpty(data.usual_line3)) {
  // 	errors.usual_line3 = 'Address Line 3 is required'
  // }
  if (
    data.usual_line3 !== undefined &&
    !isEmpty(data.usual_line3) &&
    data.usual_line3.length > 120 &&
    !isPharmSmartUser
  ) {
    errors.usual_line3 =
      "Address line 3 should be less than  of 120 characters only";
  }
  if (
    data.primary_pincode !== undefined &&
    isEmpty(data.primary_pincode) &&
    !isPharmSmartUser
  ) {
    errors.primary_pincode = "Pincode is required";
  }

  if (
    data.usual_pincode !== undefined &&
    isEmpty(data.usual_pincode) &&
    !isPharmSmartUser
  ) {
    errors.usual_pincode = "Pincode is required";
  }

  if (
    data.primary_town !== undefined &&
    isEmpty(data.primary_town) &&
    !isPharmSmartUser
  ) {
    errors.primary_town = "Town is required";
  }
  if (
    data.professional_registration_number !== undefined &&
    isEmpty(data.professional_registration_number)
  ) {
    errors.professional_registration_number =
      "Professional Registration Number is required";
  }
  if (
    data.nmprofessional_registration_numberc !== undefined &&
    isEmpty(data.professional_registration_number)
  ) {
    errors.professional_registration_number =
      "Professional Registration Number is required";
  }

  if (
    data.primary_line1 !== undefined &&
    isEmpty(data.primary_line1) &&
    !isPharmSmartUser
  ) {
    errors.primary_line1 = "Address line 1  is required";
  }

  if (data.bio !== undefined && isEmpty(data.bio)) {
    errors.bio = "Bio is required";
  }
  if (data.bio !== undefined && !isEmpty(data.bio) && data.bio.length > 250) {
    errors.bio = "Bio should be less than  of 250 characters only";
  }
  if (
    data.primary_line1 !== undefined &&
    !isEmpty(data.primary_line1) &&
    data.primary_line1.length > 120 &&
    !isPharmSmartUser
  ) {
    errors.primary_line1 =
      "Address line 1 should be less than  of 120 characters only";
  }

  if (
    data.primary_line2 !== undefined &&
    isEmpty(data.primary_line2) &&
    !isPharmSmartUser
  ) {
    errors.primary_line2 = "Address Line 2  is required";
  }
  if (
    data.primary_line2 !== undefined &&
    !isEmpty(data.primary_line2) &&
    data.primary_line2.length > 120 &&
    !isPharmSmartUser
  ) {
    errors.primary_line2 =
      "Address line 2 should be less than  of 120 characters only";
  }
  // if (data.primary_line3 !== undefined && isEmpty(data.primary_line3)) {
  // 	errors.primary_line3 = 'Address Line 3  is required'
  // }
  if (
    data.primary_line3 !== undefined &&
    !isEmpty(data.primary_line3) &&
    data.primary_line3.length > 120 &&
    !isPharmSmartUser
  ) {
    errors.primary_line3 =
      "Address line 3 should be less than  of 120 characters only";
  }

  if (data.corporate_organization_id === null) {
    console.log(
      "data.corporate_organization_id",
      data.corporate_organization_id
    );
    errors.corporate_organization_id = "Coroprate is required";
  }

  if (data.password !== undefined && isEmpty(data.password)) {
    errors.password = "Password is required";
  }

  if (data.password !== undefined && isEmpty(data.password) === false) {
    let test = passwordRegex.test(data.password);
    if (!test) {
      errors.password =
        "Password should be atleast 8 characters including a number,an uppercase,one special character and lowercase letter";
    }
  }
  if (data.password !== undefined && data.password.length > 25) {
    errors.password = "Password should be max 25 characters";
  }

  if (
    data.password !== undefined &&
    !isEmpty(data.password) &&
    spaceRegex.test(data.password) === false
  ) {
    errors.password = "Password  is invalid";
  }

  if (
    data.confirm_password !== undefined &&
    !isEmpty(data.confirm_password) &&
    spaceRegex.test(data.confirm_password) === false
  ) {
    errors.confirm_password = "Confirm password  is invalid";
  }

  if (data.confirm_password !== undefined && isEmpty(data.confirm_password)) {
    errors.confirm_password = "Enter Confirm password";
  }

  if (
    data.password !== undefined &&
    data.confirm_password &&
    data.password !== data.confirm_password
  ) {
    errors.confirm_password = "Confirm password should be same as New Password";
  }

  if (
    data.postCode !== undefined &&
    !isEmpty(data.postCode) &&
    spaceRegex.test(data.postCode) === false
  ) {
    errors.postCode = "Post code  is invalid";
  }

  if (
    data.line1 !== undefined &&
    !isEmpty(data.line1) &&
    spaceRegex.test(data.line1) === false &&
    !isPharmSmartUser
  ) {
    errors.line1 = "Address line 1  is invalid";
  }

  if (
    data.line2 !== undefined &&
    !isEmpty(data.line2) &&
    spaceRegex.test(data.line2) === false &&
    !isPharmSmartUser
  ) {
    errors.line2 = "Address line 2  is invalid";
  }
  // if (data.line3 !== undefined && !isEmpty(data.line2) && spaceRegex.test(data.line2) === false) {
  // 	errors.line3 = 'Address line 2  is invalid'
  // }

  if (
    data.town !== undefined &&
    !isEmpty(data.town) &&
    spaceRegex.test(data.town) === false &&
    !isPharmSmartUser
  ) {
    errors.town = "Town/City is invalid";
  }

  if (
    data.primary_town !== undefined &&
    !isEmpty(data.primary_town) &&
    data.primary_town.length > 60 &&
    !isPharmSmartUser
  ) {
    errors.primary_town =
      "Town/City should be less than  of 45 characters only";
  }

  if (
    data.usual_town !== undefined &&
    !isEmpty(data.usual_town) &&
    data.usual_town.length > 45 &&
    !isPharmSmartUser
  ) {
    errors.usual_town = "Town/City should be less than  of 45 characters only";
  }

  if (data.tc_accepted === false) {
    errors.tc_accepted = "Please accept terms and conditions";
  }
  return {errors, isValid: isEmpty(errors)};
}

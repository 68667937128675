import React, { Component } from 'react'
import { Line } from 'react-chartjs-2'
import moment from 'moment'
import { Select, Button } from 'antd'
import { connect } from 'react-redux'

import { getDoctorSummaryApi } from 'services/payments'
import { currencies, errorToast, getErrorObject } from '../../utilities/utils'
import payment_icon from 'assets/images/common/payment-icon.svg'
import payment_time from 'assets/images/common/payment-time.svg'
import doctor_profile from 'assets/images/common/doctor_profile.svg'

let { Option } = Select
class PaymentsSettingsContainer extends Component {
	constructor(props) {
		super(props)
		;['_onFilterChange', '_getStats'].map((fn) => (this[fn] = this[fn].bind(this)))
	}
	state = {
		chart_data: null,
		stats: null,
		defaultFilter: 'this_week',
	}
	async componentDidMount() {
		await this._getStats()
	}

	_getStats = async (_) => {
		try {
			let response = await getDoctorSummaryApi({
				filter_by: this.state.defaultFilter,
			})
			await this.setState({
				chart_data: response.data.data,
				stats: response.data.common,
			})
		} catch (error) {
			const err = getErrorObject(error)
			errorToast({ content: err.message })
		}
	}

	_onFilterChange = (val) => {
		this.setState(
			{
				defaultFilter: val,
			},
			async (_) => {
				await this._getStats()
			}
		)
	}

	render() {
		let { stats, chart_data } = this.state
		let { user } = this.props
		let labels = chart_data
			? chart_data.map((c) =>
					c.start_date && c.end_date
						? `${moment(c.start_date).format('DD-MM')}/${moment(c.end_date).format(
								'DD-MM'
						  )}`
						: moment(c.date).format('DD-MM')
			  )
			: null
		let dataSet = chart_data ? chart_data.map((c) => c.amount) : null

		let data = {
			labels,
			datasets: [
				{
					type: 'line',
					label: 'Payment',
					data: dataSet,
					backgroundColor: '#29abe1',
					borderColor: '#29abe1',
					pointBorderColor: '#29abe1',
					pointBackgroundColor: '#29abe1',
				},
			],
		}

		const options = {
			legend: {
				display: false,
				labels: {
					defaultFontSize: 10,
				},
			},
			maintainAspectRatio: true,
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							max: this.state.defaultFilter === 'last_month' ? 300 : 20,
							min: 0,
							stepSize: this.state.defaultFilter === 'last_month' ? 50 : 2,
						},
					},
				],
			},
		}
		return (
			<div className='custom-col-10px padding-10-t'>
				<div className='row m-0'>
					<div className='col-lg-6 col-md-12 col-sm-6 col-xs-12 customcolinpad padding-0-l-imp padding-7-5-r-imp'>
						<div className='card h-100'>
							<div className='card-head d-flex align-items-center'>
								<div className='card-head-6'>
									<h6 className='m-0 font-13 weight-400 text-black'>
										Payment History
									</h6>
								</div>
								<div className='card-head-6 d-flex justify-content-end'>
									<Select
										value={this.state.defaultFilter}
										onChange={this._onFilterChange}
									>
										<Option value='this_week'>This Week</Option>
										<Option value='last_week'>Last Week</Option>
										<Option value='last_month'>Last Month</Option>
									</Select>
								</div>
							</div>
							<div className='card-body payments-chart'>
								<Line options={options} height='118' data={data} />
							</div>
						</div>
					</div>
					<div className='col-lg-6 col-md-12 col-sm-6 col-xs-12 customcolinpad padding-0-r-imp padding-7-5-l-imp'>
						<div className='row m-0'>
							<div className='col-md-5 col-sm-6 padding-0-l-imp padding-7-5-l-imp margin-bottom-15'>
								<div className='card h-100'>
									<div className='payment_card-body'>
										<h6 className='m-0 font-14 weight-400 text-black'>
											Total Earnings
										</h6>
										<div className='d-flex align-items-center'>
											<h3 className='font-24 weight-500 text-black text-left margin-bottom-0 push-5-t'>
												<img
													src={payment_icon}
													className='push-10-r vertical-bottom top--1 relative'
													alt='Total Earnings'
													title='Total Earnings'
													width='25'
													height='25'
												></img>
												{stats !== null
													? `${currencies.pound.symbol}${stats.total_earned}`
													: ''}
											</h3>
										</div>
										<div className='margin-top-15'>
											<Button
												disabled
												type='primary'
												className='width-180 height-big font-14 weight-500 height-30 line-1-imp'
											>
												Withdraw
											</Button>
										</div>
									</div>
								</div>
							</div>
							<div className='col-md-7 col-sm-6 padding-0-r-imp padding-0-l-imp margin-bottom-15'>
								<div className='card h-100'>
									<div className='card-head d-flex align-items-center height-auto padding-5-t padding-5-b'>
										<div className='card-head-12'>
											<h6 className='m-0 font-14 weight-400 text-black'>
												Configuration
											</h6>
										</div>
									</div>

									<div className='payment_card-body'>
										<div className='d-flex align-items-center push-10-t'>
											<p className='font-12 weight-400 text-dark-gray text-left margin-bottom-10 w-100'>
												Appointment Rate
												<h6 className='float-right font-14 weight-500 text-black float-right m-0'>
													{user
														? `${currencies.pound.symbol}${user.per_appointment_rate} /10 mins`
														: '-'}
												</h6>
											</p>
										</div>
										<div className='d-flex align-items-center'>
											<p className='font-12 weight-400 text-dark-gray text-left push-10-t w-100 margin-bottom-0'>
												Per Prescription verification rate
												<h6 className='font-14 weight-500 text-black float-right m-0'>
													{user && user.per_prescription_rate
														? `${
																currencies.pound.symbol
														  }${user.per_prescription_rate.toFixed(
																2,
																10
														  )}`
														: '-'}
												</h6>
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='row m-0'>
							<div className='col-md-5 col-sm-6 padding-0-l-imp padding-7-5-l-imp'>
								<div className='card h-100'>
									<div className='payment_card-body'>
										<h6 className='font-14 weight-400 text-black text-left margin-top-15 margin-bottom-0'>
											Total Consultation Time
										</h6>
										<div className='d-flex align-items-center'>
											<h3 className='font-24 weight-500 text-black text-left margin-top-25 margin-bottom-10'>
												<img
													src={payment_time}
													className='push-10-r vertical-bottom'
													alt='Total Earnings'
													title='Total Earnings'
													width='25'
													height='25'
												></img>
												{stats !== null
													? stats.total_consultation_time
													: ''}{' '}
												mins
											</h3>
										</div>
									</div>
								</div>
							</div>
							<div className='col-md-7 col-sm-6 padding-0-r-imp padding-0-l-imp'>
								<div className='card h-100'>
									<div className='card-head d-flex align-items-center height-auto padding-5-t padding-5-b'>
										<div className='card-head-12'>
											<h6 className='m-0 font-14 weight-400 text-black'>
												Stripe Account
											</h6>
										</div>
									</div>
									<div className='payment_card-body push-5-t'>
										<div className='in-block vertical-middle'>
											<div className='doctor_profile_div margin-right-20'>
												<div className='cell'>
													<img
														src={doctor_profile}
														alt='Doctor Profile'
														title='Doctor Profile'
													></img>
												</div>
											</div>
										</div>

										<div className='in-block text-left vertical-middle'>
											<p className='font-14 weight-400  text-black margin-bottom-10'>
												No Account Configured
											</p>
											<Button
												disabled
												type='primary'
												className='width-136px height-30 font-14 weight-500 line-1-imp'
											>
												Configure Now
											</Button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => {
	return {
		user: state.localStore.user,
	}
}

export default connect(mapStateToProps)(PaymentsSettingsContainer)

import React, {Component, Fragment} from "react";
import {
  addConsultationDetailAPI,
  getConsultationDetailAPI,
  updateConsultationDetailAPI,
} from "../../services/patientConsultation";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../utilities/utils";
import {connect} from "react-redux";
import {Select, Spin, Input} from "antd";
import {searchSnomedCode} from "../../services/snomedCodes";
import {isEmpty, debounce, map, uniq, uniqBy, isArray} from "lodash";
import CommonLoader from "../../components/common/CommonLoader";
import store from "../../app/store";
import addImage from "../../assets/images/common/add-ic.png";
import deleteImage from "../../assets/images/common/delete-ic.png";

import {
  onRequestSetComponent,
  onSetSidebarKey,
  onSetDynamicInput,
} from "../../reducers/dynamicTab";
import {bindActionCreators} from "redux";
import ModalPopUp from "../../components/common/ModalPopUp";
import Highlighter from "react-highlight-words";
import {ValidateInput} from "../PatientConsultationConatainers/PatientConsultationValidations";
import {appRoutesConst, navigateTo} from "../../app/navigation";
import InformationalOutcomeModal from "../PatientConsultationConatainers/InformationalOutcomeModal";

const {Option} = Select;
const SET_CONSULTATION_OBJ = "SET_CONSULTATION_OBJ";

class BarcodeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      consultationDetail: null,
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      managementPlan: "",
      editManagementPlan: false,
      barcodeNumber: "",
      editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      selectedCodesArray: [],
      selectedSnomedCodesArray: [],
      addingDetail: false,
      updatingDetail: false,
      isLoading: false,
      isShowSubmit: false,
      componentId: null,
      pulseObject: {},
      pulse_per_minute: "",
      height: "",
      heightObject: {},
      weight: "",
      weightObject: {},
      body_temperature: "",
      body_temperatureObject: {},
      systolic_bp: "",
      systolic_bpObject: {},
      diastolic_bp: "",
      diastolic_bpObject: {},
      blood_oxygen_saturation: "",
      blood_oxygen_saturationObject: {},
      bmiObject: {},
      bmi: "",
    };
    [
      "_getPatientConultationDetails",
      "_handleSubmit",
      "_handleTextChange",
      "_hideModal",
      "_showCodeModal",
      "_hideCodeModal",
      "_handleSnomedCodeSearch",
      "_getSnomedCodes",
      "_handleDropDownChange",
      "_handleCustomCodeAdd",
      "_handleModalSubmission",
      "_handleEdit",
      "_handleUpdateoutcome",
      "_handleUpdate",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSnomedCodeSearch, 1000);
  }

  componentDidMount() {
    // this._getPatientConultationDetails();
    let {dynamicInputs, dynamicHealthInputs} = this.props;
    this.setState({
      ...dynamicInputs,
      ...dynamicHealthInputs,
    });
  }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodes: [],
          snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          search: value,
        },
        async () => {
          this._getSnomedCodes(false);
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  async _getSnomedCodes(isLoadMore) {
    try {
      let response = await searchSnomedCode({
        page: this.state.page,
        search: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...snomedCodes]
          : snomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getSnomedCodes(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;
    let {
      page,
      pagination: {total_pages},
    } = this.state;
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  async _getPatientConultationDetails() {
    let {appointmentId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getConsultationDetailAPI({
        appointment_id: appointmentId,
      });
      let consultationDetail = verifyObject(response, "data.data", null);
      store.dispatch({
        type: SET_CONSULTATION_OBJ,
        payload: consultationDetail,
      });
      await this.setState({
        consultationDetail: consultationDetail,
        selectedSnomedCodesArray:
          verifyObject(consultationDetail, "snomed_codes", []).length !== 0
            ? uniqBy(consultationDetail.snomed_codes, "description")
            : [],
        isLoading: false,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({isLoading: false});
      errorToast({content: message});
    }
  }

  _handleTextChange(e) {
    let errors = null;
    // let name = e.target.name;
    // let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors}, () => {
      console.log("this.state.managementPlan", this.state.managementPlan);
      if (
        isEmpty(this.state.history) ||
        isEmpty(this.state.examination) ||
        isEmpty(this.state.managementPlan)
      ) {
        console.log("IS SHOW SUBMIT");
        this.setState({isShowSubmit: false}, () => {
          this._SaveComponentStateToRedux();
        });
      } else {
        this._SaveComponentStateToRedux();
      }
    });
  }

  _SaveComponentStateToRedux = () => {
    let {onSetDynamicInput} = this.props.dynamicTabAction;
    if (typeof onSetDynamicInput === "function") {
      let {
        history,
        examination,
        managementPlan,
        barcodeNumber,
        selectedSnomedCodesArray,
        isShowSubmit,
        isConsentGiven,
      } = this.state;
      let Inputs = {
        history: history,
        examination: examination,
        managementPlan: managementPlan,
        barcodeNumber: barcodeNumber,
        selectedSnomedCodesArray: selectedSnomedCodesArray,
        isShowSubmit: isShowSubmit,
        isConsentGiven: isConsentGiven,
      };
      onSetDynamicInput(Inputs);
    }
  };

  _handleCustomCodeAdd() {
    let {snomedCodeText} = this.state;
    let Obj = {
      id: "",
      code: "",
      description: snomedCodeText,
    };
    this.setState({
      snomedCodeText: "",
      selectedCodesArray: this.state.selectedCodesArray.concat(Obj),
    });
  }

  async _handleSubmit(e) {
    // let { history, examination, managementPlan, barcodeNumber } = this.state;

    // let toBeValidateObj = {
    //   history: history,
    //   examination: examination,
    //   managementPlan: managementPlan,
    //   barcodeNumber: barcodeNumber,
    // };

    // const errors = ValidateInput(toBeValidateObj);
    // if (!errors.isValid) {
    //   this.setState({ errors: errors.errors, adding: false });
    // } else {
    this.setState(
      {
        visible: true,
      },
      () => {}
    );
    // }
  }

  async _handleModalSubmission() {
    let {
      treatment,
      followUp,
      referral,
      comment,
      history,
      examination,
      managementPlan,
      barcodeNumber,
    } = this.state;

    let toBeValidateObj2 = {
      treatment: treatment,
      followUp: followUp,
      referral: referral,
      comment: comment,
      visible: true,
    };
    const errors = ValidateInput(toBeValidateObj2);

    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let {appointmentId, patientId} = this.props;
      let {selectedSnomedCodesArray} = this.state;

      let payload = {
        consultation: {
          appointment_id: appointmentId,
          history: history,
          examination: examination,
          management_plan: managementPlan,
          barcode: barcodeNumber,
          patient_id: patientId,
          outcome: {
            treatment: treatment,
            follow_up: followUp,
            referral: referral,
            comment: comment,
          },
        },
        snomed_codes: selectedSnomedCodesArray,
      };

      try {
        this.setState({addingDetail: true});
        let response = await addConsultationDetailAPI(payload);
        successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
        this.setState({
          addingDetail: false,
        });
        let {navigateTo} = this.props.navigationActions;
        if (typeof navigateTo === "function") {
          await navigateTo(appRoutesConst.dashboard);
        }
        this._clearData();
      } catch (error) {
        const {message} = getErrorObject(error);
        this.setState({addingDetail: false});
        errorToast({content: message});
      }
    }

    // }
  }

  _clearData() {
    this.setState({
      history: "",
      editHistory: false,
      examination: "",
      editExamination: false,
      managementPlan: "",
      editManagementPlan: false,
      barcodeNumber: "",
      editBarcodeNumber: false,
      editOutcome: false,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      snomedCodes: [],
      searching: false,
      isLoadmore: false,
      search: false,
      snomedCodeText: "",
      showCodeModal: false,
      // p@
      addingDetail: false,
      updatingDetail: false,
      visible: false,
    });
    store.dispatch({type: "SHOW_SUBMIT_FROM_NOTES", payload: false});
    store.dispatch({
      type: "ON_CALL_FROM_PATIENT_SEARCH_LISTING",
      payload: null,
    });
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    // eslint-disable-next-line eqeqeq
    let found = this.state.snomedCodes.find((d) => d.id == value);
    let ids = map(this.state.selectedCodesArray, "id");

    if (found) {
      if (ids.includes(found.id) === false) {
        this.setState({
          selectedCodesArray: this.state.selectedCodesArray.concat({
            id: found.id,
            code: found.code,
            description: found.description,
          }),
          snomedCodeId: null,
        });
      }
    }
  }
  _hideModal() {
    this.setState({
      visible: false,
      errors: null,
      treatment: "",
      followUp: "",
      referral: "",
      comment: "",
      editOutcome: false,
      changing: false,
    });
  }
  _showCodeModal() {
    this.setState({showCodeModal: true});
  }
  _hideCodeModal() {
    this.setState({showCodeModal: false});
  }
  _removeCode = (record) => {
    this.setState({
      selectedCodesArray: this.state.selectedCodesArray.filter(
        (d) => d.id !== record.id || d.description !== record.description
      ),
    });
  };

  _handleEdit(state) {
    this.setState({[state]: !this.state[state]}, () => {
      if (this.state.editOutcome) {
        this.setState({visible: true});
      }
    });
  }

  async _handleUpdateoutcome() {
    let {
      treatment,
      followUp,
      referral,
      comment,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;

    let payload = {
      consultation: {
        outcome: {
          treatment: treatment,
          follow_up: followUp,
          referral: referral,
          comment: comment,
        },
      },
      consultation_id: consultation_id,
    };

    try {
      this.setState({changing: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Outcome.`,
        });
        this.setState({
          changing: false,
          consultationDetail: response.data.data,
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }
  async _handleSnomedCodeUpdate() {
    let {
      selectedCodesArray,
      selectedSnomedCodesArray,
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;
    let payload = {
      snomed_codes: uniq([...selectedSnomedCodesArray, ...selectedCodesArray]),
      consultation_id: consultation_id,
    };
    try {
      this.setState({updatingDetail: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated Snomed Codes.`,
        });
        this.setState({
          updatingDetail: false,
          consultationDetail: response.data.data,
          selectedSnomedCodesArray:
            verifyObject(response, "data.data.snomed_codes", []).length !== 0
              ? uniqBy(response.data.data.snomed_codes, "description")
              : [],
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }

  handleDynamicKey = (key, page) => {
    let {onSetSidebarKey, onRequestSetComponent} = this.props.dynamicTabAction;
    if (typeof onSetSidebarKey === "function") {
      if (key) {
        let payload = [`${key}`];
        onSetSidebarKey(payload);
      }
      if (key === null) {
        this.setState({isShowSubmit: true});
      }
    }
    // if (typeof onRequestSetComponentID === "function") {
    //   onRequestSetComponentID(componentId);
    // }
    // let { onRequestSetComponent } = this.props.dynamicTabAction;
    if (typeof onRequestSetComponent === "function") {
      onRequestSetComponent(page);
    }
  };

  async _handleUpdate(param, state) {
    let {
      consultationDetail: {
        consultation_log: {consultation_id},
      },
    } = this.state;
    let payload = {
      consultation: {
        [param]: this.state[param],
      },
      consultation_id: consultation_id,
    };

    if (param === "barcode") {
      payload = {
        consultation: {
          barcode: this.state.barcodeNumber,
        },
        consultation_id: consultation_id,
      };
    }

    if (param === "managementPlan") {
      payload = {
        consultation: {
          management_plan: this.state.managementPlan,
        },
        consultation_id: consultation_id,
      };
    }
    try {
      this.setState({updatingDetail: true});
      let response = await updateConsultationDetailAPI(payload);
      if (response.data && response.data.message) {
        successToast({
          content: `Updated ${param}`,
        });
        this.setState({
          updatingDetail: false,
          consultationDetail: response.data.data,
          // [state]: false,
        });
      }

      // this._getPatientConultationDetails();
      this._clearData();
    } catch (error) {
      const {message} = getErrorObject(error);
      this.setState({updatingDetail: false});
      errorToast({content: message});
    }
  }

  //   componentWillReceiveProps(prevProps) {
  //     let { dynamicInputs, componentId } = prevProps;

  //     this.setState({ componentId });

  //     if (dynamicInputs) {
  //       let {
  //         history,
  //         examination,
  //         managementPlan,
  //         selectedSnomedCodesArray,
  //         barcodeNumber,
  //       } = dynamicInputs;
  //       this.setState({
  //         history: history,
  //         isShowSubmit:
  //           !isEmpty(history) &&
  //           !isEmpty(examination) &&
  //           !isEmpty(managementPlan),
  //         examination: examination,
  //         managementPlan: managementPlan,
  //         barcodeNumber: barcodeNumber,
  //         selectedSnomedCodesArray: selectedSnomedCodesArray,
  //       });
  //     }
  //   }

  componentDidUpdate(prevProps) {}

  render() {
    let {
      history,
      examination,
      managementPlan,
      barcodeNumber,
      isLoading,
      errors,
      isShowSubmit,
      selectedSnomedCodesArray,
      showCodeModal,
      selectedCodesArray,
      changing,
      searching,
      isLoadmore,
      snomedCodeText,
      snomedCodes,
      visible,
      addingDetail,
    } = this.state;
    let {dynamicInputs} = this.props;

    // let { componentId } = this.props;
    let isAllEmpty =
      isEmpty(history) || isEmpty(managementPlan) || isEmpty(examination);
    if (isLoading) {
      return <CommonLoader />;
    }
    return (
      <Fragment>
        <div className="push-20-b barcode_wrapper padding-50-t">
          <div className="selection_container">
            <div className="form-group push-10-b relative">
              {/* {------History-----} */}
              {!isEmpty(history) && (
                <div className="form-row push-10-b">
                  <div className="col-md-12 consultant_review">
                    <p className="font-11 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                      History
                    </p>

                    <Input.TextArea
                      autoSize={true}
                      type="text"
                      className={`form-control-textarea input-bg-f9f9f9 form-control ${
                        errors && errors.history && "danger-border"
                      } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus`}
                      name="history"
                      value={history}
                      onChange={this._handleTextChange}
                      placeholder="Write patient history here"
                    />
                    {errors && (
                      <span className="validate-danger font-14">
                        {errors.history}
                      </span>
                    )}
                  </div>
                </div>
              )}

              {!isEmpty(examination) && (
                <div className="form-row push-10-b">
                  <div className="col-md-12 consultant_review">
                    <p className="font-11 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                      Examination
                    </p>

                    <Input.TextArea
                      autoSize={true}
                      type="text"
                      className={`form-control-textarea input-bg-f9f9f9 form-control ${
                        errors && errors.examination && "danger-border"
                      } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus`}
                      name="examination"
                      value={examination}
                      onChange={this._handleTextChange}
                      placeholder="Write examination detail here"
                    />
                    {errors && (
                      <span className="validate-danger font-14">
                        {errors.examination}
                      </span>
                    )}
                  </div>
                </div>
              )}

              {selectedSnomedCodesArray.length !== 0 && (
                <div className="form-row push-10-b">
                  <div className="col-md-12">
                    <p className="font-11 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                      Diagnosis / Clinical Impressions
                    </p>
                    {dynamicInputs &&
                      dynamicInputs.isConsentGiven &&
                      isArray(dynamicInputs.isConsentGiven) &&
                      dynamicInputs.isConsentGiven.map((code) => {
                        return (
                          <div className="padding-5 margin-right-10 margin-bottom-6 input-bg-f9f9f9 in-block div-border line-1">
                            <p className="margin-bottom-0 font-11 weight-500 in-block text-light-black margin-right-5">
                              <span>
                                {verifyObject(code, "code", null) !== null
                                  ? code.code
                                  : "--"}
                              </span>
                            </p>
                            <p className="margin-0 font-11 weight-400 in-block text-dark-gray">
                              {code.description}
                            </p>
                          </div>
                        );
                      })}
                    <div className="consultaiondetail-main">
                      {selectedSnomedCodesArray &&
                        isArray(selectedSnomedCodesArray) &&
                        selectedSnomedCodesArray.map((code) => {
                          return (
                            <div className="padding-5 margin-right-10 margin-bottom-6 input-bg-f9f9f9 in-block div-border line-1">
                              <p className="margin-bottom-0 font-11 weight-500 in-block text-light-black margin-right-5">
                                <span>
                                  {verifyObject(code, "code", null) !== null
                                    ? code.code
                                    : "--"}
                                </span>
                              </p>
                              <p className="margin-0 font-11 weight-400 in-block text-dark-gray">
                                {code.description}
                              </p>
                            </div>
                          );
                        })}
                      {
                        <div className="in-block">
                          <span
                            onClick={this._showCodeModal}
                            className="in-block height-auto-imp font-12-imp weight-400 padding-5-imp btn-custom btn-blue"
                            ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                            role="button"
                            tabIndex="0"
                          >
                            Add Code
                          </span>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}

              {!isEmpty(managementPlan) && (
                <div className="form-row push-10-b">
                  <div className="col-md-12 consultant_review">
                    <p className="font-11 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                      Management Plan
                    </p>

                    <Input.TextArea
                      autoSize={true}
                      type="text"
                      className={`form-control-textarea input-bg-f9f9f9 form-control ${
                        errors && errors.managementPlan && "danger-border"
                      } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus`}
                      name="managementPlan"
                      value={managementPlan}
                      onChange={this._handleTextChange}
                      placeholder="Add your Management Plan"
                    />
                    {errors && (
                      <span className="validate-danger font-14">
                        {errors.managementPlan}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="form-row push-10-b">
                <div className="col-md-12 consultant_review">
                  <p className="font-11 weight-400 text-left text-high-dark-blue-imp in-block push-5-b text-capitalize">
                    BARCODE NUMBER
                  </p>
                  <Input.TextArea
                    autoSize={true}
                    type="text"
                    className={`form-control-textarea input-bg-f9f9f9 form-control ${
                      errors && errors.barcodeNumber && "danger-border"
                    } custom-input font-10 weight-400 placeholder-10 min-height-26-imp no-focus`}
                    name="barcodeNumber"
                    value={barcodeNumber}
                    onChange={this._handleTextChange}
                    placeholder="Enter BARCODE Number"
                  />
                  {errors && (
                    <span className="validate-danger font-14">
                      {errors.barcodeNumber}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {(isAllEmpty || isShowSubmit === false || isShowSubmit === "") && (
              <div className="push-60-b push-10-t">
                <button
                  onClick={() => {
                    // if (isEmpty(history)) {
                    //   this.handleDynamicKey(
                    //     90,
                    //     "WalkinAppointmentContainer"
                    //   );
                    // }
                    if (isEmpty(history)) {
                      this.handleDynamicKey(90, "WalkinAppointmentContainer");
                    } else if (isEmpty(examination)) {
                      this.handleDynamicKey(91, "ExaminationContainer");
                    } else if (isEmpty(managementPlan)) {
                      this.handleDynamicKey(93, "ManagementPlanContainer");
                    } else {
                      this.handleDynamicKey(92, "DignosisContainer");
                    }

                    if (
                      !isEmpty(history) &&
                      !isEmpty(examination) &&
                      !isEmpty(managementPlan)
                    ) {
                      this.setState({isShowSubmit: true}, () => {
                        this._SaveComponentStateToRedux();
                      });
                    }
                    // if(isEmpty())
                  }} //91 is sidebar menu key
                  // disabled={isEmpty(history)}
                  className="btn btn-custom btn-blue font-10 weight-500 submitBtn width-100px height-30 p-0"
                >
                  Next
                </button>
              </div>
            )}
            {isShowSubmit &&
              !isEmpty(history) &&
              !isEmpty(examination) &&
              !isEmpty(managementPlan) && (
                <div className="push-60-b push-10-t">
                  <button
                    disabled={this.props.offlineCallStatus !== "finish"}
                    onClick={this._handleSubmit}
                    className="btn btn-custom btn-blue font-10 weight-500 submitBtn width-100px height-30 p-0"
                  >
                    Submit
                  </button>
                </div>
              )}
            {/* <div className="exmainationTextboxGroup">
                  {componentId === "examination" && isEmpty(examination) && (
                    <div className="form-row">
                      <div className="col-md-12 consultant_review">
                        <p className="font-12 weight-400 text-light-black text-left gray-tag in-block margin-0 text-capitalize">
                          Examination
                        </p>

                        <input
                          type="text"
                          className={`form-control ${
                            errors && errors.examination && "danger-border"
                          } custom-input input-sm-32 font-14 weight-400 no-focus`}
                          name="examination"
                          value={examination}
                          onChange={this._handleTextChange}
                          placeholder="Write patient examination here"
                        ></input>
                        {errors && (
                          <span className="validate-danger font-14">
                            {errors.examination}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {!isEmpty(examination) && (
                    <div className="form-row">
                      <div className="col-md-12 consultant_review">
                        <p className="font-12 weight-400 text-light-black text-left gray-tag in-block margin-0 text-capitalize">
                          Examination
                        </p>

                        <input
                          type="text"
                          className={`form-control ${
                            errors && errors.examination && "danger-border"
                          } custom-input input-sm-32 font-14 weight-400 no-focus`}
                          name="examination"
                          value={examination}
                          onChange={this._handleTextChange}
                          placeholder="Write patient examination here"
                        ></input>
                        {errors && (
                          <span className="validate-danger font-14">
                            {errors.examination}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  {!isShowSubmit &&
                    componentId === "examination" &&
                    selectedSnomedCodesArray.length === 0 && (
                      <div className="push-60-b margin-top-15">
                        <button
                          onClick={() =>
                            this.handleDynamicKey(93, "management_plan")
                          } //91 is sidebar menu key
                          disabled={isEmpty(examination)}
                          className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-100px"
                        >
                          Next
                        </button>
                      </div>
                    )}
                </div>

                <div className="dignosisSection">
                  {componentId === "dignosis_clinical_impression" &&
                    selectedSnomedCodesArray.length === 0 && (
                      <div className="form-group push-10-b">
                        <div className="form-row">
                          <div className="col-md-12">
                            <p className="font-12 weight-400 text-light-black text-left gray-tag in-block margin-0 text-capitalize">
                              Diagnosis / Clinical Impressions
                            </p>
                            <span
                              onClick={this._showCodeModal}
                              className="text-right float-right text-dark-blue weight-400 hide_outline add-coding"
                              ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                              role="button"
                              tabIndex="0"
                            >
                              <img
                                src={addImage}
                                alt="Add Coding"
                                height="12"
                                width="12"
                              />
                            </span>
                            <br />
                            {selectedSnomedCodesArray.length === 0 && (
                              <a className="text-dark-blue font-11 text-left weight-400 push-10-t">
                                No snomed codes
                              </a>
                            )}
                            <div
                              className="coding_table"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {selectedSnomedCodesArray &&
                                isArray(selectedSnomedCodesArray) &&
                                selectedSnomedCodesArray.map((code) => {
                                  return (
                                    <div>
                                      <p className="margin-0 font-11-imp weight-500 width-10 float-left text-light-black">
                                        <span className="ng-binding ng-scope">
                                          {verifyObject(code, "code", null) !==
                                          null
                                            ? code.code
                                            : "--"}
                                        </span>
                                      </p>
                                      <p className="margin-0 font-11-imp weight-400 float-left ng-binding text-dark-gray">
                                        {code.description}
                                      </p>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                  {selectedSnomedCodesArray.length !== 0 && (
                    <div className="form-group push-10-b">
                      <div className="form-row">
                        <div className="col-md-12">
                          <p className="font-12 weight-400 text-light-black text-left gray-tag in-block margin-0 text-capitalize">
                            Diagnosis / Clinical Impressions
                          </p>
                          <span
                            onClick={this._showCodeModal}
                            className="text-right float-right text-dark-blue weight-400 hide_outline add-coding"
                            ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                            role="button"
                            tabIndex="0"
                          >
                            <img
                              src={addImage}
                              alt="Add Coding"
                              height="12"
                              width="12"
                            />
                          </span>
                          <br />
                          {selectedSnomedCodesArray.length === 0 && (
                            <a className="text-dark-blue font-11 text-left weight-400 push-10-t">
                              No snomed codes
                            </a>
                          )}
                          <div
                            className="coding_table"
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}
                          >
                            {selectedSnomedCodesArray &&
                              isArray(selectedSnomedCodesArray) &&
                              selectedSnomedCodesArray.map((code) => {
                                return (
                                  <div>
                                    <p className="margin-0 font-11-imp weight-500 width-10 float-left text-light-black">
                                      <span className="ng-binding ng-scope">
                                        {verifyObject(code, "code", null) !==
                                        null
                                          ? code.code
                                          : "--"}
                                      </span>
                                    </p>
                                    <p className="margin-0 font-11-imp weight-400 float-left ng-binding text-dark-gray">
                                      {code.description}
                                    </p>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {!isShowSubmit &&
                    componentId === "dignosis_clinical_impression" && (
                      <div className="push-60-b margin-top-15">
                        <button
                          onClick={() => this.handleDynamicKey(90, "history")} //91 is sidebar menu key
                          disabled={selectedSnomedCodesArray.length == 0}
                          className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-100px"
                        >
                          Next
                        </button>
                      </div>
                    )}
                  {!isShowSubmit &&
                    componentId === "history" &&
                    selectedSnomedCodesArray.length !== 0 && (
                      <div className="push-60-b margin-top-15">
                        <button
                          onClick={() =>
                            this.handleDynamicKey(92, "examination")
                          } //91 is sidebar menu key
                          disabled={isEmpty(history)}
                          className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-100px"
                        >
                          Next
                        </button>
                      </div>
                    )}
                  {!isShowSubmit &&
                    componentId === "examination" &&
                    selectedSnomedCodesArray.length !== 0 && (
                      <div className="push-60-b margin-top-15">
                        <button
                          onClick={() =>
                            this.handleDynamicKey(93, "management_plan")
                          } //91 is sidebar menu key
                          disabled={isEmpty(examination)}
                          className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-100px"
                        >
                          Next
                        </button>
                      </div>
                    )}
                </div>

                <div className="managementPlanTextboxGroup">
                  {componentId === "management_plan" &&
                    isEmpty(managementPlan) && (
                      <div className="form-row">
                        <div className="col-md-12 consultant_review">
                          <p className="font-12 weight-400 text-light-black text-left gray-tag in-block margin-0 text-capitalize">
                            Management Plan
                          </p>

                          <input
                            type="text"
                            className={`form-control ${
                              errors && errors.managementPlan && "danger-border"
                            } custom-input input-sm-32 font-14 weight-400 no-focus`}
                            name="managementPlan"
                            value={managementPlan}
                            onChange={this._handleTextChange}
                            placeholder="Write patient management plan here"
                          ></input>
                          {errors && (
                            <span className="validate-danger font-14">
                              {errors.managementPlan}
                            </span>
                          )}
                        </div>
                      </div>
                    )}
                  {!isEmpty(managementPlan) && (
                    <div className="form-row">
                      <div className="col-md-12 consultant_review">
                        <p className="font-12 weight-400 text-light-black text-left gray-tag in-block margin-0 text-capitalize">
                          Management Plan
                        </p>

                        <input
                          type="text"
                          className={`form-control ${
                            errors && errors.managementPlan && "danger-border"
                          } custom-input input-sm-32 font-14 weight-400 no-focus`}
                          name="managementPlan"
                          value={managementPlan}
                          onChange={this._handleTextChange}
                          placeholder="Write patient management plan here"
                        ></input>
                        {errors && (
                          <span className="validate-danger font-14">
                            {errors.managementPlan}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {!isShowSubmit && componentId === "management_plan" && (
                    <div className="push-60-b margin-top-15">
                      <button
                        onClick={() => this.handleDynamicKey(null, null)} //91 is sidebar menu key
                        disabled={isEmpty(managementPlan)}
                        className="btn btn-custom btn-blue font-14 weight-500 submitBtn width-100px"
                      >
                        Next
                      </button>
                    </div>
                  )}
                </div>
                <div className="barcodeNumberTextboxGroup">
                  {componentId === "barcode_number" && isEmpty(barcodeNumber) && (
                    <div className="form-row">
                      <div className="col-md-12 consultant_review">
                        <p className="font-12 weight-400 text-light-black text-left gray-tag in-block margin-0 text-capitalize">
                          Barcode Number
                        </p>

                        <input
                          type="text"
                          className={`form-control ${
                            errors && errors.barcodeNumber && "danger-border"
                          } custom-input input-sm-32 font-14 weight-400 no-focus`}
                          name="barcodeNumber"
                          value={barcodeNumber}
                          onChange={this._handleTextChange}
                          placeholder="Write patient barcode number  here"
                        ></input>
                        {errors && (
                          <span className="validate-danger font-14">
                            {errors.barcodeNumber}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                  {!isEmpty(barcodeNumber) && (
                    <div className="form-row">
                      <div className="col-md-12 consultant_review">
                        <p className="font-12 weight-400 text-light-black text-left gray-tag in-block margin-0 text-capitalize">
                          BARCODE NUMBER
                        </p>

                        <input
                          type="text"
                          className={`form-control ${
                            errors && errors.managementPlan && "danger-border"
                          } custom-input input-sm-32 font-14 weight-400 no-focus`}
                          name="barcodeNumber"
                          value={barcodeNumber}
                          onChange={this._handleTextChange}
                          placeholder="Write patient management plan here"
                        ></input>
                        {errors && (
                          <span className="validate-danger font-14">
                            {errors.barcodeNumber}
                          </span>
                        )}
                      </div>
                    </div>
                  )}
                </div> */}
          </div>
          <InformationalOutcomeModal
            _handleSubmit={this._handleModalSubmission}
            _hideModal={this._hideModal}
            visible={visible}
            changing={addingDetail}
            _handleTextChange={this._handleTextChange}
            _handleUpdateoutcome={this._handleUpdateoutcome}
            {...this.state}
          />
          <ModalPopUp
            title={"Code Selection"}
            handleCancel={this._hideCodeModal}
            visible={showCodeModal}
            footer={true}
            closable={false}
            maskClosable={false}
            handleOk={() => {
              // if (consultationDetail === null) {
              this.setState(
                {
                  showCodeModal: false,
                  selectedSnomedCodesArray: uniq([
                    ...selectedSnomedCodesArray,
                    ...selectedCodesArray,
                  ]),
                },
                () => {
                  let {onSetDynamicInput} = this.props.dynamicTabAction;

                  let {
                    history,
                    examination,
                    managementPlan,
                    barcodeNumber,
                    // selectedSnomedCodesArray,
                  } = this.state;

                  let Inputs = {
                    history: history,
                    examination: examination,
                    managementPlan: managementPlan,
                    barcodeNumber: barcodeNumber,
                    selectedSnomedCodesArray: selectedCodesArray,
                  };
                  onSetDynamicInput(Inputs);
                }
              );
              // }
            }}
            okText={"Save"}
            okButtonProps={{disabled: selectedCodesArray.length === 0}}
            loading={changing}
            cancelBtnclassName="custom_cancel_btn"
          >
            <form>
              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-12">
                    <label htmlFor="sel1">Indication</label>
                    <Select
                      className={`form-control margin-0 ${
                        errors && errors.snomedCodeId && "danger-border"
                      } indication-dropdown`}
                      name="snomedCodeId"
                      autoFocus
                      onPopupScroll={this.handleScroll}
                      allowClear={searching ? false : true}
                      value={this.state.snomedCodeId}
                      showSearch={true}
                      onChange={(value) =>
                        this._handleDropDownChange(value, "snomedCodeId")
                      }
                      onSearch={(value) => {
                        let searchValue = value;
                        this.searchUpdate(searchValue);
                      }}
                      suffixIcon={
                        !isLoadmore &&
                        searching && (
                          <Spin
                            className="custom-dropdown-spinner"
                            size="small"
                          />
                        )
                      }
                      notFoundContent={
                        !searching &&
                        snomedCodes.length === 0 && (
                          <span>No codes available</span>
                        )
                      }
                      filterOption={false}
                      dropdownRender={(menus) => {
                        return (
                          <div ref={this.scrollDiv}>
                            {menus}
                            {isLoadmore && searching && (
                              <div style={{textAlign: "center", padding: 10}}>
                                <Spin size="small" />
                              </div>
                            )}
                          </div>
                        );
                      }}
                    >
                      {snomedCodes.map((k) => {
                        return (
                          <Option value={k.id}>
                            <Highlighter
                              highlightClassName="search-highlighter"
                              searchWords={[this.state.search]}
                              autoEscape={true}
                              textToHighlight={
                                verifyObject(k, "full_code", null) !== null
                                  ? `${k.full_code}`
                                  : `-`
                              }
                            />
                          </Option>
                        );
                      })}
                    </Select>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 bg-white push-10-b coding_modal_table push-10-t">
                <table className="table live-talk2-table">
                  <thead>
                    <tr>
                      <th className="font-10 weight-500 text-black">Code</th>
                      <th className="font-10 weight-500 text-black">
                        Description
                      </th>
                      <th className="font-10 weight-500 text-black"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {selectedCodesArray.length === 0 && (
                      <tr className="ng-hide">
                        <td
                          colSpan="3"
                          className="text-center text-dark-gray font-12 weight-400 default-padding"
                        >
                          No codes selected
                        </td>
                      </tr>
                    )}
                    {selectedCodesArray.map((code) => (
                      <tr>
                        <td className="font-10 weight-400 default-padding text-light-black">
                          {code.code}
                        </td>
                        <td className="font-10 weight-400 default-padding text-light-black">
                          {code.description}
                        </td>
                        <td className="font-10 weight-400 default-padding text-light-black text-right relative">
                          {" "}
                          <span
                            onClick={() => this._removeCode(code)}
                            className="text-right text-dark-blue weight-400 hide_outline"
                            ng-click="$ctrl.consultationDetailObj.funcs.openCodingModal();"
                            role="button"
                            tabIndex="0"
                          >
                            <img
                              src={deleteImage}
                              alt="Add Coding"
                              height="12"
                              width="12"
                            />
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              <div className="form-group">
                <div className="form-row">
                  <div className="col-md-12">
                    <label htmlFor="sel1">
                      Feel free to freetext your Clinical Impressions
                    </label>
                    <Input
                      className={`form-control margin-0 ${
                        errors && errors.snomedCodeId && "danger-border"
                      }`}
                      name="snomedCodeText"
                      value={snomedCodeText}
                      onChange={this._handleTextChange}
                      suffix={
                        !isEmpty(snomedCodeText) && (
                          <img
                            width={12}
                            className="cursor-pointer"
                            src={addImage}
                            alt="Add"
                            onClick={this._handleCustomCodeAdd}
                          />
                        )
                      }
                    />
                    {this.state.errors && (
                      <span className="validate-danger font-14">
                        {this.state.errors.snomedCodeId}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </ModalPopUp>
        </div>
      </Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    // dynamicInputs: verifyObject(state.localStore, "dynamicInputs", null),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    dynamicHealthInputs: verifyObject(
      state,
      "dynamicTab.dynamicHealthInputs",
      null
    ),
    offlineCallStatus: verifyObject(
      state.localStore,
      "offline_call_status",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    dynamicTabAction: bindActionCreators(
      {onRequestSetComponent, onSetSidebarKey, onSetDynamicInput},
      dispatch
    ),
    navigationActions: bindActionCreators({navigateTo}, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(BarcodeContainer);

export default ConnectedComponent;

import { listingReducer } from "reducers/utils/listingReducer";
import { fetchPreConsultations } from "services/preConsultConditions";

const { reducer, actions, types } = listingReducer({
  name: `preConsultationReview`,
  getApi: fetchPreConsultations,
});

const preConsultationReview = reducer;


export { preConsultationReview, actions, types };

import { get } from "./index";

export const searchSnomedCode = (params, cancelToken) => {
  const request = {
    subUrl: `/consultations/search_snomed_code.json`,
    params,
    cancelToken,
  };
  return get(request);
};

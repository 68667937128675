import { get, patch } from "./index";


export const getReports = (params) => {
    const request = {
        subUrl: `/admin/documents/list_corona_virus_report.json`,
        params
    };
    return get(request);
};

export const addReports = (data) => {
    const request = {
        subUrl: `/admin/documents/store_covid_report_result.json`,
        data
    };
    return patch(request);
};
export const generateFilterReportsAPI = (params: IReportsParams) => {
    const request: Irequest = {
      subUrl: `/priory/doctors/users/generate_filter_report.json`,
      params,
    };
    return get(request);
  };
  
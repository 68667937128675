import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as testInvestigationActions} from "../../../reducers/testInvestigations";
import {
  verifyObject,
  // modifiedDataObjectToArray,
  successToast,
  getErrorObject,
  errorToast,
  modifiedDataObjectToArrayByIDs,
} from "../../../utilities/utils";
import {isArray, debounce, isEmpty} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import {Pagination} from "../../../components/common";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {addtestInvestigationsApi} from "../../../services/testInvestigations";
import {Collapse, Select, DatePicker, Spin} from "antd";
import Highlighter from "react-highlight-words";
import {ValidateInput} from "./TestInvestigationValidations";
import {searchSnomedCode} from "../../../services/snomedCodes";
import {Editor} from "@tinymce/tinymce-react";
import {PlusSquareOutlined} from "@ant-design/icons";
import {DATE_FORMAT} from "constants/common";
// import { Input } from "antd";

const {Option} = Select;
const {Panel} = Collapse;
class TestInvestigationContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      message: "",
      errors: null,
      snomedCodes: [],
      snomedCodeId: "",
      snomedCodeText: "",
      date: "",
      description: "",
      pagination: null,
      page: 1,
      isLoadmore: false,
      taken_on: null,
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleCancel",
      "_handleSnomedCodeSearch",
      "_hideModal",
      "_handleSubmit",
      "_handleDropDownChange",
      "_handleDateChange",
      "_handleEditorChange",
      "_handleTextChange",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchInvestigationUpdate = debounce(
      this._handleSnomedCodeSearch,
      1000
    );
  }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodes: [],
          snomedCodeId: "",
          isLoadmore: false,
          page: 1,
          searching: true,
          search: value,
        },
        async () => {
          this._getSnomedCodes(false);
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  async _getSnomedCodes(isLoadMore) {
    try {
      let response = await searchSnomedCode({
        page: this.state.page,
        search: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...snomedCodes]
          : snomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getSnomedCodes(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  componentDidMount() {
    this._onRequestList();
  }

  _onRequestList() {
    let {patientId, testInvestigationActions} = this.props;
    let {onRequest} = testInvestigationActions;
    if (typeof onRequest === "function") {
      onRequest({patient_id: patientId, page: 1});
    }
  }

  _onPageChange(data) {
    let {
      patientId,
      testInvestigationActions: {onPageChange},
      testInvestigations: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, patient_id: patientId, search: search});
    }
  }

  _handleCancel() {
    this.setState({visible: false, message: "", adding: false});
  }
  _showModal() {
    this.setState({visible: true});
  }
  _hideModal() {
    this.setState({visible: false}, () => {
      this._clearData();
    });
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }
    this.setState({[key]: value ? value : "", errors: errors}, () => {
      if (!isEmpty(this.state.snomedCodeId.toString())) {
        this.setState({snomedCodeText: ""});
      }
    });
  }

  _handleTextChange(e) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
      delete errors["snomedCodeId"];
    }
    if (!isEmpty(this.state.snomedCodeText)) {
      this.setState({snomedCodeId: ""});
    }
    this.setState({[e.target.name]: e.target.value, errors: errors});
  }

  async _handleSubmit(e) {
    e.preventDefault();
    let {snomedCodeId, description, snomedCodeText, taken_on} = this.state;
    let toBeValidateObj = {
      snomedCodeId: snomedCodeId.toString(),
      snomedCodeText: snomedCodeText.toString(),
      description: description,
      taken_on: taken_on,
    };

    const errors = ValidateInput(toBeValidateObj);
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let {patientId} = this.props;
      let payload = {};

      payload = {
        snomed_code_id: !isEmpty(toBeValidateObj.snomedCodeId)
          ? toBeValidateObj.snomedCodeId
          : toBeValidateObj.snomedCodeText,
        patient_id: patientId,
        taken_on: toBeValidateObj.taken_on,
        description: toBeValidateObj.description,
      };
      try {
        await this.setState({changing: true});
        let response = await addtestInvestigationsApi({
          investigation: {...payload},
        });
        await this._onRequestList();
        await this._clearData();
        await successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({changing: false});
        errorToast({content: message});
      }
    }
  }

  _handleEditorChange(content) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[`description`];
    }
    this.setState({description: content, errors: errors});
  }

  _handleDateChange = (date) => {
    if (date) {
      let errors = null;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["taken_on"];
      }
      this.setState({
        taken_on: date ? moment(date).format(DATE_FORMAT) : null,
        errors: errors,
      });
    } else {
      let errors = null;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["taken_on"];
      }
      this.setState({taken_on: null, errors: errors});
    }
  };

  _clearData() {
    this.setState({
      snomedCodes: [],
      currentlyActive: false,
      snomedCodeId: "",
      snomedCodeText: "",
      startDateYear: "",
      endDateYear: "",
      startDateMonth: "",
      endDateMonth: "",
      search: "",
      errors: {},
      selectedHistoryProblem: null,
      editId: null,
      visible: false,
      changing: false,
      description: "",
      taken_on: null,
    });
  }

  render() {
    let {testInvestigations} = this.props;
    let {
      // data,
      isLoading,
      pagination: {total},
    } = testInvestigations;
    let {
      visible,
      snomedCodeText,
      changing,
      searching,
      snomedCodes,
      description,
      isLoadmore,
      errors,
      taken_on,
    } = this.state;
    let testInvestigationsArray =
      modifiedDataObjectToArrayByIDs(testInvestigations);

    return (
      <Fragment>
        <div className="padding-55-t push-20-b testinvest_wrapper">
          <div className="form-row align-items-center">
            <div className="col-md-6">
              <h6 className="font-14 weight-500 text-light-black m-0">
                Investigation {isLoading ? "" : `(${total})`}{" "}
              </h6>
            </div>
            {/* <div className="col-md-6 text-right">
              <div className="relative searchbar_dropdown medical_Search">
                <Input allowClear={true} size="large" placeholder="Search" prefix={<SearchOutlined />}/>
              </div>
          </div> */}
          </div>

          <div className="form-row margin-bottom-10 push-10-t">
            <div className="col-md-12 text-right">
              <a
                onClick={() =>
                  this.setState({
                    visible: true,
                  })
                }
                className="font-13 weight-500 text-blue"
              >
                <PlusSquareOutlined
                  style={{
                    cursor: "pointer",
                    color: "#1445b7",
                    fontSize: "15px",
                  }}
                />{" "}
                Add{" "}
              </a>
              {/* {!isLoading && (
                <img
                  src={plus_blue}
                  width="14"
                  height="14"
                  onClick={() =>
                    this.setState({
                      visible: true,
                    })
                  }
                  alt=""
                  style={{ cursor: "pointer" }}
                ></img>
              )} */}
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}

          <div className="prescription-array test_investigation_content">
            {!isLoading &&
              testInvestigationsArray &&
              isArray(testInvestigationsArray) &&
              testInvestigationsArray.length > 0 && (
                <Collapse
                  // className="collapse-reverse-array"
                  expandIconPosition="right"
                >
                  {testInvestigationsArray.map((k, index) => {
                    return (
                      <Panel
                        header={
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <span className="text-light-black">
                              {verifyObject(k, `snomed_code`, null) !== null
                                ? k.snomed_code
                                : "-"}
                            </span>
                            <span className="text-gray">
                              {verifyObject(k, "taken_on", null) !== null
                                ? moment(k.taken_on).format(DATE_FORMAT)
                                : "-"}
                            </span>
                          </div>
                        }
                        key={index + 1}
                      >
                        {verifyObject(k, "description", null) !== null ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: k.description,
                            }}
                            className="test_investigation_detail"
                          ></div>
                        ) : (
                          "-"
                        )}
                      </Panel>
                    );
                  })}
                </Collapse>
              )}
            {!isLoading &&
              testInvestigationsArray &&
              isArray(testInvestigationsArray) &&
              testInvestigationsArray.length === 0 && (
                <NoFoundMessage message="No Test/Investigations  found." />
              )}
          </div>

          <div className="col-md-12">
            {!isLoading && (
              <Pagination
                data={testInvestigations}
                onPageChange={this._onPageChange}
              />
            )}
          </div>
        </div>
        <ModalPopUp
          title={"Add Test/Investigation"}
          handleCancel={this._hideModal}
          visible={visible}
          footer={true}
          closable={false}
          handleOk={this._handleSubmit}
          okText={"Save"}
          loading={changing}
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
          className="testinvitigationpopup"
        >
          <form>
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Name</label>
                  <Select
                    className={`form-control ${
                      errors && errors.snomedCodeId && "danger-border"
                    } margin-0`}
                    name="snomedCodeId"
                    autoFocus
                    allowClear={searching ? false : true}
                    onPopupScroll={this.handleScroll}
                    value={this.state.snomedCodeId}
                    showSearch={true}
                    onChange={(value) =>
                      this._handleDropDownChange(value, "snomedCodeId")
                    }
                    onSearch={(value) => {
                      let searchValue = value;
                      this.searchInvestigationUpdate(searchValue);
                    }}
                    suffixIcon={
                      !isLoadmore && searching && <Spin size="small" />
                    }
                    // loading={searching}
                    notFoundContent={
                      !searching &&
                      snomedCodes.length === 0 && (
                        <span>No codes available</span>
                      )
                    }
                    filterOption={false}
                    dropdownRender={(menus) => {
                      return (
                        <div ref={this.scrollDiv}>
                          {menus}
                          {isLoadmore && searching && (
                            <div style={{textAlign: "center", padding: 10}}>
                              <Spin size="small" />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  >
                    {snomedCodes.map((k) => {
                      return (
                        <Option value={k.id}>
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(k, "full_code", null) !== null
                                ? `${k.full_code}`
                                : `-`
                            }
                          />
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">
                    Feel free to freetext your Clinical Impressions
                  </label>
                  <input
                    className={`form-control ${
                      errors && errors.snomedCodeId && "danger-border"
                    } margin-0 placeholder-10`}
                    name="snomedCodeText"
                    value={snomedCodeText}
                    onChange={this._handleTextChange}
                    placeholder="Add custom"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.snomedCodeId}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Date</label>
                  <DatePicker
                    className={`form-control ${
                      errors && errors.taken_on && "danger-border"
                    } margin-0`}
                    name="taken_on"
                    format={DATE_FORMAT}
                    value={taken_on ? moment(taken_on, DATE_FORMAT) : null}
                    onChange={this._handleDateChange}
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.taken_on}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Description</label>
                  <Editor
                    className={`form-control ${
                      errors && errors.description && "danger-border"
                    } margin-0`}
                    //   initialValue="<p>This is the initial content of the editor</p>"
                    value={description}
                    apiKey="myyycsys79xq8qtyn80kicgl66gxeu2bh72dqwwkjdgi1eja"
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar: [
                        // eslint-disable-next-line no-multi-str
                        `undo redo | formatselect | link | image | bold italic backcolor | fullscreen | selectall | subscript,superscript anchor | underline | strikethrough |
                                                alignleft | aligncenter alignright alignjustify | 
                                                bullist | numlist | outdent | indent | removeformat | help |`,
                      ],
                    }}
                    onEditorChange={this._handleEditorChange}
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.description}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </form>
        </ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    testInvestigations: verifyObject(state, "testInvestigations", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    testInvestigationActions: bindActionCreators(
      testInvestigationActions,
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(TestInvestigationContainer);

export default ConnectedComponent;

// import moment from "moment";
// import moment from "moment";
import React from "react";
// import DatePicker from "react-datepicker";
// import InputMask from "react-input-mask";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";

import moment from "moment";

import {message} from "antd";

// const CustomInput = forwardRef((props, ref) => {
//   // MB try to use custom masking input for Input box so that format will be auto-populated
//   return (
//     <InputMask
//       mask="99-99-9999"
//       placeholder="DD-MM-YYYY"
//       {...props}
//       ref={ref}
//     />
//   );
// });
export default function MaskDateInputPicker(props) {
  // const [startDate, setStartDate] = useState(new Date());
  // const inputRef = useRef(null);
  // const handleChangeRaw = (date) => {
  //   const newRaw = new Date(date.currentTarget.value);

  //   if (newRaw instanceof Date && !isNaN(newRaw)) {
  //     // setStartDate(newRaw);
  //     console.log("newRaw", newRaw);
  //     if (typeof props.handleDatepicker === "function") {
  //       props.handleDatepicker(newRaw);
  //     }
  //   }
  // };
  console.log("selected date: ", props.selectedDate);
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          format="DD-MM-YYYY"
          onChange={(ddd) => {
            if (ddd && typeof props.handleDatepicker === "function") {
              const date = ddd.toDate();
              props.handleDatepicker(date);
            }
          }}
          className={`placeholder-10 ${
            props.errors && props.errors.dob && "danger-border"
          }`}
          disableFuture={true}
          fullWidth={true}
          size="small"
          value={moment(props.selectedDate)}
          slotProps={{
            field: {
              onKeyDown: (event) => {
                // Disable Ctrl+C (Cmd+C on macOS)
                if ((event.ctrlKey || event.metaKey) && event.keyCode === 67) {
                  message.error({
                    content: "Copy is not allowed",
                    duration: 3,
                  });
                  event.preventDefault();
                }
              },
            },
          }}
        />
      </LocalizationProvider>
      {/* <DatePicker
        // placeholderText="DD-MM-YYYY"
        dateFormat="dd/MM/yyyy"
        className={`form-control placeholder-10 ${
          props.errors && props.errors.dob && "danger-border"
        }`}
        allowClear={true}
        selected={props.selectedDate}
        customInput={<CustomInput ref={inputRef} />}
        // onChangeRaw={(e) => handleChangeRaw(e)}
        onChange={(date) => {
          if (typeof props.handleDatepicker === "function") {
            props.handleDatepicker(date);
          }

          // setStartDate(date);
        }}
        // onBlur={(ddd) => {
        //   console.log("Blurred", ddd);
        // }}
        maxDate={
          props.maxDate
            ? new Date(props.maxDate)
            : new Date(moment(moment()).subtract(1, "d"))
        }
      /> */}
    </div>
  );
}

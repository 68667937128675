import isEmpty from "lodash/isEmpty";
import moment from "moment";
// first_name: "",

//     last_name: "",
//     phone: "",
//     email: "",
//     gender: "",
//     address_name: "",
//     addres_line_1: "",
//     addres_line_2: "",
//     addres_line_3: "",
//     city: "",
//     postal_code: "",
//     weight: "",
//     height: "",
//     dob: "",
export function ValidateInput(data, selectedArray) {
  console.log("DATA", data);
  let errors = {};
  if (
    data.assessment_reason !== undefined &&
    isEmpty(data.assessment_reason) &&
    selectedArray.length === 0
  ) {
    errors.assessment_reason = "Please provide the reason";
  }

  // if (/^(?=.*\S).+$/.test(data.assessment_reason) === false) {
  //   errors.assessment_reason = "Please provide the reason";
  // }

  if (
    data.advise === "not_fit" &&
    data.comment !== undefined &&
    isEmpty(data.comment)
  ) {
    errors.comment = "Please provide the comment";
  }

  if (
    data.advise === "not_fit" &&
    data.comment !== undefined &&
    /^(?=.*\S).+$/.test(data.comment) === false
  ) {
    errors.comment = "Please provide the comment";
  }
  if (data.date_type === 1 && data.isIndefinitely === false) {
    if (data.no_of !== undefined && isEmpty(data.no_of)) {
      errors.no_of = "Please provide the number value";
    }

    if (data.no_of !== undefined && isEmpty(data.no_of) === false) {
      // errors.no_of = "Please provide the valid value";
      let number = parseInt(data.no_of, 10);

      if (number > 999 || number < 0) {
        errors.no_of = "Please provide the valid number value";
      }
    }
    if (data.duration_type !== undefined && isEmpty(data.duration_type)) {
      errors.duration_type = "Please select type.";
    }
  }

  if (data.date_type === 2 && data.isIndefinitely === false) {
    if (data.start_date !== undefined && isEmpty(data.start_date)) {
      errors.start_date = "Please select start date";
    }
    if (data.end_date !== undefined && isEmpty(data.end_date)) {
      errors.end_date = "Please select end date";
    }
    if (
      data.start_date !== undefined &&
      !isEmpty(data.start_date) &&
      data.end_date !== undefined &&
      !isEmpty(data.end_date)
    ) {
      let date1 = data.start_date.format("YYYY-MM-DD");
      let date2 = data.end_date.format("YYYY-MM-DD");
      if (moment(date1).isBefore(date2) === false) {
        errors.dateError = "To date can't be less than from date";
      }
    }
  }

  console.log("Sick Note Error", errors);

  return {errors, isValid: isEmpty(errors)};
}

import React, { Fragment, useEffect, useState } from "react";
import CommonLoader from "../../components/common/CommonLoader";
import NoFoundMessage from "../../components/common/NoFoundMessage";
// import ascendingsort from "assets/images/common/ascending-sort.svg";
// import three_vertical_dots from "assets/images/common/payment_option.svg";
import {
  Popover,
  Button,
  Tooltip,
  Checkbox,
  Typography,
  Timeline,
  Tag,
  Divider,
} from "antd";
import { verifyObject } from "utilities/utils";
import moment from "moment";
import { isArray, isEmpty } from "lodash";
import { CaretDownOutlined, CaretUpOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { DATE_FORMAT, DATE_FORMAT_12_HR } from "constants/common";
// import ModalPopUp from "components/common/ModalPopUp";
import ascendingsort from "assets/images/common/ascending-sort.svg";
const { Text } = Typography;

function PreConsultationReviewTable(props) {
  const [chekdIds, setCheckboxIds] = useState([]);
  const [state, setState] = useState({});
  const parseAddress = (addressString) => {
    const line1 = addressString?.line1 ? addressString?.line1 : "-";
    const town = addressString?.town ? addressString?.town : "-";
    const pincode = addressString?.pincode ? addressString?.pincode : "-";

    const addressNewLine = [town, pincode].filter((d) => d).join(", ");
    if (!addressString) {
      return "-";
    }
    return { line1, line2: addressNewLine };
  };
  const addresscontent = (line1, line2) => (
    <div className="address_contentbox">
      <span>{line1 ? line1 : "-"}</span>
      <span>{line2 ? line2 : "-"}</span>
    </div>
  );
  const patientcontent = (line1, email, line2, line3, date) => {
    // console.log("LINE2---", line2);
    const emailContent = email ? <Text copyable>{email}</Text> : null;
    return (
      <div className="patient_contentbox">
        <span className="mr-1 d-block">
          <b className="weight-400 mr-1">Email:</b>{" "}
          {emailContent ? emailContent : null}
        </span>
      </div>
    );
  };
  const videoAppointmentcontent = (
    id,
    serialNumber,
    date,
    type,
    name,
    role,
    bookedAt,
    bookedBy
  ) => {
    return (
      <div className="patient_contentbox">
        <span className="mr-1 d-block">
          <b className="weight-400 mr-1">Date:</b> {date ? date : "-"}{" "}
          {type && type !== "-" ? (
            <span className="ant-tag ant-tag-blue margin-right-0-imp text-capitalize ml-2">
              {type.replace(/_/g, " ")}
            </span>
          ) : (
            ""
          )}
        </span>
        <span className="d-block">
          <b className="weight-400 mr-1">Clinician info:</b>{" "}
          {name ? (
            <>
              {name}{" "}
              {role && role !== "-" ? (
                <span className="text-capitalize">
                  {" ("}
                  {role.replace("_", " ")}
                  {")"}
                </span>
              ) : null}
            </>
          ) : (
            "-"
          )}
        </span>
        <div
          className={`secondrowdiv ${
            state && state[`showBookedDetails_${id}`]
              ? "up-arrow"
              : "down-arrow"
          } mt-4 mb-2`}
        >
          <div className="dragbtn">
            <a className="d-flex btn-updown" onClick={() => handleButtonClick(id)}>
              <span className="uprrow">
                <CaretUpOutlined />
              </span>
              <span className="downarrow">
                <CaretDownOutlined />
              </span>
            </a>
          </div>
          {state && state[`showBookedDetails_${id}`] && (
            <div className="mt-2 d-block">
              <span className="d-block">
                <b className="weight-400 mr-1">Booked by:</b>{" "}
                {bookedBy ? <>{bookedBy}</> : "-"}
              </span>
              <span className="mr-1 d-block">
                <b className="weight-400 mr-1">Booked at:</b>{" "}
                {bookedAt ? bookedAt : "-"}
              </span>
            </div>
          )}
        </div>
        {serialNumber > 1 && null}
      </div>
    );
  };
  const handleButtonClick = (id) => {
    setState({
      ...state,
      [`showBookedDetails_${id}`]: !state[`showBookedDetails_${id}`],
    });
  };

  const onCheckAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      let ids = props.ids;
      setCheckboxIds(ids);
      props.handleDeleteCheckbox(ids);
    } else {
      setCheckboxIds([]);
      props.handleDeleteCheckbox([]);
    }
  };
  useEffect(() => {
    if (
      props.toBeDeleteIds &&
      isArray(props.toBeDeleteIds) &&
      props.toBeDeleteIds.length === 0
    ) {
      setCheckboxIds([]);
    }
  }, [props.toBeDeleteIds]);

  const handleCheckBox = (e, id) => {
    if (e.target.checked) {
      console.log("ON CHANGE ", id);

      setCheckboxIds([...chekdIds, id]);
      props.handleDeleteCheckbox([...chekdIds, id]);
    } else {
      console.log("ON CHANGE DELETE", id);
      let newArray = chekdIds.filter((d) => d !== id);
      console.log("ON CHANGE NEW", newArray);

      setCheckboxIds(newArray);
      props.handleDeleteCheckbox(newArray);
    }
  };
  // const urlParams = new URLSearchParams(window.location.search);
  // const isReviewed = urlParams.get("reviewed");

  return (
    <div className={`PreConsultationReviewTable_Wrapper table-responsive${props.isLoading ? ' isLoading-table' : ''}`}>
      <table className="w-100 upcoming_booking_tableheader relative bg-gray border border-bottom-none-imp preconsulttable min-table-w-1280 pre_consultation_review_table">
        <thead>
          <tr>
            {!props.isLoading && props.isAllowDelete && (
              <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-2 md-td-2 text-light-black">
                <Checkbox
                  checked={
                    chekdIds.length !== 0 &&
                    chekdIds.length === props?.ids?.length
                  }
                  onChange={onCheckAll}
                  className="m-0"
                ></Checkbox>
              </th>
            )}
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 text-light-black [w-200]">
              Patient Name
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 text-light-black [w-260]">
              Contact
            </th>
            {/* {(props.status === "pending" ||
              props.prev_appt_tab_key === "pre_consult_reviewed") && (
              <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-6 md-td-6 text-light-black">
                Service
              </th>
            )} */}

            {/* <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-10 md-td-10 text-light-black">
            <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-5 md-td-6 text-light-black">
              Gender
            </th>
            <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-6 md-td-8 text-light-black">
              DOB
            </th> */}
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 text-light-black [w-290]">
              Address
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 padding-right-10 text-light-black [w-290]">
              Condition
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left text-light-black">
              OutCome
            </th>
            {/* <th className="font-12 padding-10-t padding-10-b text-left lg-td-14 md-td-14 text-light-black">
              Consent Shared ?
            </th> */}
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-20 padding-right-10 text-light-black [w-150]">
              Date
              <img
                src={ascendingsort}
                alt="Ascending Sort"
                className="push-3-l cursor-pointer sort-icon"
                // alt="Ascending Sort"
                title=""
                id="1"
                onClick={() => props.sortRecordBy("created_at", "1")}
              />
            </th>
            <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 text-light-black [w-250]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="upcoming_booking_table font-14-imp">
          {props.isLoading && (
            <tr>
              <td colSpan={8} className="border-0">
                <CommonLoader />
              </td>
            </tr>
          )}
          {!props.isLoading && props.ids && props.ids.length === 0 && (
            <tr>
              <td colSpan={8} className="border-0">
                <NoFoundMessage message="No pre consults available" />
              </td>
            </tr>
          )}
          {!props.isLoading &&
            props.ids.map((id) => {
              const phoneContent = verifyObject(
                props.data[id],
                "patient.phone",
                null
              ) ? (
                <span className="d-flex align-items-center">
                  <Text copyable className="text-black font-14-imp">
                    {verifyObject(props.data[id], "patient.phone", null)}
                  </Text>
                </span>
              ) : null;

              return (
                <tr key={id}>
                  {props.isAllowDelete && (
                    <td className="text-left padding-left-10 lg-td-2 md-td-2 overflow_td">
                      <Checkbox
                        onChange={(e) => handleCheckBox(e, id)}
                        checked={chekdIds.includes(id)}
                      />
                    </td>
                  )}
                  <td
                    className="text-left padding-left-10 overflow_td [w-200]"
                    valign="middle"
                  >
                    <div
                      className={`d-flex align-items-center gap-6px justify-content-between ${
                        props.prev_appt_tab_key === "link_sent" ||
                        props.prev_appt_tab_key === "in_progress"
                          ? "py-2"
                          : ""
                      }`}
                    >
                      <div className="text_col patient_name_info d-flex flex-column gap-6px">
                        {verifyObject(props.data[id], "patient.age", null) ? (
                          <span className="ellipsis-text font-14-imp text-black">
                            {verifyObject(
                              props.data[id],
                              "patient.child_user.is_child_account",
                              false
                            ) ? (
                              <Tooltip placement="top" title="Child Account">
                                <span className="mr-1 custom-bluelabel font-10 in-block text-capitalize">
                                  C
                                </span>
                              </Tooltip>
                            ) : null}
                            {`${verifyObject(
                              props.data[id],
                              "patient.name",
                              "-"
                            )}`}
                          </span>
                        ) : (
                          "-"
                        )}
                        <div className="d-flex align-items-center gap-6px text-nowrap">
                          {verifyObject(props.data[id], "patient.age", "-") ? (
                            <span>
                              {verifyObject(props.data[id], "patient.age", "-")}
                              yrs
                            </span>
                          ) : (
                            ""
                          )}
                          {verifyObject(
                            props.data[id],
                            "patient.gender",
                            "-"
                          ) === "male" ? (
                            <span className="gender_span male">M</span>
                          ) : verifyObject(
                              props.data[id],
                              "patient.gender",
                              "-"
                            ) === "female" ? (
                            <span className="gender_span female">F</span>
                          ) : (
                            "-"
                          )}{" "}
                          |{" "}
                          {verifyObject(props.data[id], "patient.dob", null) ? (
                            <span className="weight-400 font-14-imp text-black">
                              {moment(
                                verifyObject(
                                  props.data[id],
                                  "patient.dob",
                                  null
                                )
                              ).format(DATE_FORMAT)}
                            </span>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td
                    className="text-left padding-left-10 overflow_td [w-260]"
                    valign="middle"
                  >
                    <div
                      className={`d-flex align-items-center gap-6px justify-content-between ${
                        props.prev_appt_tab_key === "link_sent" ||
                        props.prev_appt_tab_key === "in_progress"
                          ? "py-2"
                          : ""
                      }`}
                    >
                      <div className="text_col patient_name_info d-flex flex-column gap-6px">
                        {verifyObject(props.data[id], "patient.phone", null) ? (
                          <span className="ellipsis-text">{phoneContent}</span>
                        ) : (
                          "-"
                        )}
                        {verifyObject(props.data[id], "patient.email", null) ? (
                          <span className="ellipsis-text email-ellipsis parent-emain-copy">
                            <Text copyable>
                              {verifyObject(
                                props.data[id],
                                "patient.email",
                                null
                              )}
                            </Text>
                          </span>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </td>
                  {/* <td className="text-center padding-left-10 lg-td-10 md-td-10">
                  <td className="text-center padding-left-10 lg-td-5 md-td-6">
                    {verifyObject(props.data[id], "patient.gender", "-") ===
                    "male"
                      ? "M"
                      : verifyObject(
                          props.data[id],
                          "patient.gender",
                          "-"
                        ) === "female"
                      ? "F"
                      : "-"}
                  </td>
                  <td className="text-center padding-left-10 lg-td-6 md-td-8  ">
                    {verifyObject(props.data[id], "patient.dob", null)
                      ?
                      <span className="weight-400 font-14-imp text-black">
                        {moment(
                            verifyObject(props.data[id], "patient.dob", null)
                          ).format(DATE_FORMAT)}
                      </span>
                      : "-"}
                  </td> */}
                  <td className="text-left padding-left-10 overflow_td [w-290]">
                    <Popover
                      overlayClassName="address_contentbox_popover"
                      content={() =>
                        addresscontent(
                          parseAddress(props.data[id]?.patient?.address)?.line1,
                          parseAddress(props.data[id]?.patient?.address)?.line2
                        )
                      }
                    >
                      <p className="padding-left-0 cursor-pointer font-14-imp tablet-font-9 weight-400 text-black margin-0 text-capitalize d-flex flex-column gap-6px custom-text-width address_td">
                        {!isEmpty(props.data[id]?.patient?.address?.line1) ? (
                          <span className="ellipsis-text">
                            {
                              parseAddress(props.data[id]?.patient?.address)
                                ?.line1
                            }
                          </span>
                        ) : (
                          "-"
                        )}
                        {!isEmpty(props.data[id]?.patient?.address?.pincode) ? (
                          <span className="ellipsis-text">
                            {
                              parseAddress(props.data[id]?.patient?.address)
                                ?.line2
                            }
                          </span>
                        ) : (
                          "-"
                        )}
                      </p>
                    </Popover>
                  </td>
                  {/* <td className="text-left padding-left-10 lg-td-20 md-td-20">
                    <div className="d-flex">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {props.data[id]?.health_condition?.health_condition
                          ?.name === "Uncomplicated Urinary Tract Infection"
                          ? "UTI"
                          : props.data[id]?.health_condition?.health_condition
                              ?.name
                          ? props.data[id]?.health_condition?.health_condition
                              ?.name
                          : "-"}
                      </p>
                      {!isEmpty(
                        verifyObject(
                          props.data[id],
                          "video_f2f_appointments",
                          []
                        )
                      ) ? (
                        <div className="infoicon ml-2">
                          {props.data[id].video_f2f_appointments.map(
                            (appointment, index) => (
                              <Popover
                                key={index}
                                content={() =>
                                  videoAppointmentcontent(
                                    index + 1,
                                    appointment.start_time
                                      ? moment(appointment.start_time).format(
                                          DATE_FORMAT_12_HR
                                        )
                                      : null,
                                    appointment.appointment_type || "-",
                                    appointment.clinician_info.name || null,
                                    appointment.clinician_info.designation
                                      ? appointment.clinician_info.designation
                                      : appointment.clinician_info.role ||
                                          null
                                  )
                                }
                              >
                                <Button className="infobtn">
                                  <InfoCircleOutlined />
                                </Button>
                              </Popover>
                            )
                          )}
                        </div>
                      ) : null}
                    </div>
                  </td> */}
                  <td className="text-left padding-left-10 td_condition padding-right-10 [w-290]">
                  {process.env.REACT_APP_ENV !== "prod" &&
                    <div className="d-flex align-items-center gap-6px mb-2">
                        <p className="font-14-imp tablet-font-9 weight-600 text-black margin-0 text-capitalize in-block font-weight-bold text-nowrap">
                          {(props.status === "pending" ||
                            props.prev_appt_tab_key === "pre_consult_reviewed") &&
                          props.data[id]?.category?.category
                            ? props.data[id]?.category?.category?.name
                            : "-"}
                          {/* Pharmacy First */}
                        </p>
                      {/* {(props.status === "pending" ||
                        props.prev_appt_tab_key === "pre_consult_reviewed") &&
                        (props.data[id]?.service ? (
                          <Tag
                            className={`m-0 text-capitalize mr-1 ${props.data[
                              id
                            ]?.service?.toLowerCase()}`}
                            // color="#2db7f5"
                          >
                            {props.data[id]?.service
                              ? props.data[id]?.service
                              : "-"}
                          </Tag>
                        ) : (
                          "-"
                        ))} */}
                    </div>
                    }
                    <div className="d-flex align-items-start">
                      <p className="font-12-imp tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {props.data[id]?.health_condition?.health_condition
                          ?.name === "Uncomplicated Urinary Tract Infection"
                          ? "UTI"
                          : props.data[id]?.health_condition?.health_condition
                              ?.name
                          ? props.data[id]?.health_condition?.health_condition
                              ?.name
                          : "-"}
                      </p>

                      {props.data[id].video_f2f_appointments?.length > 0 && (
                        <div className="infoicon ml-2">
                          <Popover
                            overlayClassName="patient_contentbox_popover"
                            content={
                              <div className="overflow-y-scroll max-height-550">
                                {props.data[id].video_f2f_appointments.map(
                                  (appointment, index) => {
                                    // console.log("---->", appointment);
                                    return (
                                      <div key={index}>
                                        {videoAppointmentcontent(
                                          appointment.id,
                                          index + 1,
                                          appointment.start_time
                                            ? moment(
                                                appointment.start_time
                                              ).format(DATE_FORMAT_12_HR)
                                            : null,
                                          appointment.appointment_type || "-",
                                          appointment.clinician_info.name ||
                                            null,
                                          appointment.clinician_info.designation
                                            ? appointment.clinician_info
                                                .designation
                                            : appointment.clinician_info.role ||
                                                null,
                                          verifyObject(
                                            appointment,
                                            "booked_at",
                                            null
                                          )
                                            ? moment(
                                                verifyObject(
                                                  appointment,
                                                  "booked_at",
                                                  null
                                                )
                                              ).format(DATE_FORMAT_12_HR)
                                            : null,
                                          verifyObject(
                                            appointment,
                                            "booked_by",
                                            null
                                          ) || null
                                        )}
                                        {index !==
                                          props.data[id].video_f2f_appointments
                                            .length -
                                            1 && ""}
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            }
                          >
                            <Button className="infobtn">
                              <InfoCircleOutlined />
                            </Button>
                          </Popover>
                        </div>
                      )}
                    </div>
                  </td>

                  <td
                    className={`text-left padding-left-10 lg-td-8 md-td-11  ${
                      verifyObject(
                        props.data[id],
                        "outcome.pre_consult_outcome.color_code",
                        null
                      )
                        ? "color_box"
                        : "text-black"
                    } `}
                    style={{
                      backgroundColor:
                        verifyObject(
                          props.data[id],
                          "outcome.pre_consult_outcome.color_code",
                          null
                        ) &&
                        (props.prev_appt_tab_key === "pending" ||
                          props.prev_appt_tab_key === "pre_consult_reviewed")
                          ? verifyObject(
                              props.data[id],
                              "outcome.pre_consult_outcome.color_code",
                              null
                            )
                          : "",
                      color:
                        verifyObject(
                          props.data[id],
                          "outcome.pre_consult_outcome.color_code",
                          null
                        ) &&
                        (props.prev_appt_tab_key === "pending" ||
                          props.prev_appt_tab_key === "pre_consult_reviewed")
                          ? "white"
                          : "",
                    }}
                  >
                    <p className="font-14-imp tablet-font-9 weight-400 margin-0 text-capitalize in-block">
                      {props.data[id]?.outcome?.pre_consult_outcome?.outcome
                        ? props.data[id]?.outcome?.pre_consult_outcome?.outcome
                        : "-"}
                    </p>
                  </td>
                  {/* <td className="text-left padding-left-10 lg-td-14 md-td-14">
                    {props.data[id]?.consent_shared ? "Yes" : "No"}
                  </td> */}
                  <td
                    className={`text-left padding-left-20 padding-right-10 [w-150]`}
                  >
                    <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block text-nowrap">
                      {props.prev_appt_tab_key === "pre_consult_reviewed" ? (
                        <Timeline>
                          <Timeline.Item>
                            <b className="mr-1">Updated at:</b>
                            {moment(props.data[id]?.updated_at).format("lll")}
                          </Timeline.Item>
                          <Timeline.Item>
                            <b className="mr-1">Created at:</b>
                            {moment(props.data[id]?.created_at).format("lll")}
                          </Timeline.Item>
                        </Timeline>
                      ) : (
                        <span className="font-14-imp ">
                          {moment(props.data[id]?.created_at).format("lll")}
                        </span>
                      )}
                    </p>
                  </td>

                  {/* )} */}
                  <td className="text-center autoheightbtn p-2 [w-250]">
                    {props.status === "pending" ||
                    props.status === "in_progress" ||
                    props.status === "link_sent" ? (
                      <button
                        onClick={() =>
                          props._handleNavigation(props.data[id], props.status)
                        }
                        className="arrive_btn btn btn-custom btn-blue w-100 font-14-imp weight-400 text-white tablet-font-10"
                      >
                        {props.status === "in_progress" ||
                        props.status === "link_sent"
                          ? "Resume"
                          : "Review"}
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          props._handleNavigation(props.data[id], false)
                        }
                        className="arrive_btn btn btn-custom btn-blue w-100 font-14-imp weight-400 text-white tablet-font-10"
                      >
                        View
                      </button>
                    )}
                    {(props.status === "pending" ||
                      props.prev_appt_tab_key === "pre_consult_reviewed") && (
                      <Fragment>
                        {props.data[id]?.video_f2f_appointments?.filter(
                          (d) => d.appointment_type === "video"
                        ).length === 0 ? (
                          <button
                            onClick={() =>
                              props._handleAppointment(props.data[id], false)
                            }
                            style={{
                              fontSize: 11,
                            }}
                            className="arrive_btn mt-2 btn btn-custom btn-blue w-100 font-14-imp weight-400 text-white tablet-font-10"
                          >
                            Book An Appointment
                          </button>
                        ) : (
                          <Fragment>
                            <Tag
                              className={`m-0 text-capitalize py-1 mt-2 blue-border-box w-100`}
                              // color="#2db7f5"
                            >
                              {props.data[id].video_f2f_appointments
                                .filter((d) => d?.appointment_type === "video")
                                .map((appointment, index) => {
                                  // console.log("---->", appointment);
                                  if (index === 0) {
                                    return (
                                      <div className="d-flex flex-column">
                                        <span className="d-flex align-items-center gapx-2 font-12">
                                          <b className="weight-400">Date:</b>{" "}
                                          {appointment?.start_time
                                            ? moment(
                                                appointment.start_time
                                              ).format(DATE_FORMAT_12_HR)
                                            : "-"}{" "}
                                          {appointment?.appointment_type !==
                                          "-" ? (
                                            <span className="ant-tag ant-tag-blue margin-right-0-imp text-capitalize">
                                              {appointment?.appointment_type.replace(
                                                /_/g,
                                                " "
                                              )}
                                            </span>
                                          ) : (
                                            ""
                                          )}
                                        </span>
                                        <span className="d-flex flex-wrap align-items-center gapx-1 font-12">
                                          <b className="weight-400">
                                            Clinician info:
                                          </b>{" "}
                                          {appointment?.clinician_info ? (
                                            <>
                                              {appointment?.clinician_info
                                                ?.role &&
                                              appointment?.clinician_info
                                                ?.role !== "-" ? (
                                                <span className="text-capitalize infoicon">
                                                  {/* {" ("}
                                                  {appointment?.clinician_info?.role.replace(
                                                    "_",
                                                    " "
                                                  )}
                                                  {")"} */}
                                                  <Popover
                                                    overlayClassName="patient_contentbox_popover"
                                                    content={
                                                      <div className="overflow-y-scroll max-height-550 p-2">
                                                        <div className="d-flex flex-wrap gapx-1">
                                                          <b className="weight-400">
                                                            Clinician info:
                                                          </b>
                                                          <span className="d-flex flex-column flex-1 gapx-1">
                                                            <span>
                                                              {
                                                                appointment
                                                                  ?.clinician_info
                                                                  ?.name
                                                              }
                                                            </span>
                                                            {appointment
                                                              ?.clinician_info
                                                              ?.role && (
                                                              <span className="text-capitalize">
                                                                {`(${appointment?.clinician_info?.role.replace(
                                                                  "_",
                                                                  " "
                                                                )})`}
                                                              </span>
                                                            )}
                                                          </span>
                                                        </div>
                                                      </div>
                                                    }
                                                  >
                                                    <Button className="infobtn">
                                                      {appointment
                                                        ?.clinician_info
                                                        ?.name && (
                                                        <span className="text-dark">
                                                          {appointment
                                                            ?.clinician_info
                                                            ?.name.length > 18
                                                            ? `${appointment?.clinician_info?.name.substring(
                                                                0,
                                                                18
                                                              )}...`
                                                            : appointment
                                                                ?.clinician_info
                                                                ?.name}
                                                        </span>
                                                      )}
                                                    </Button>
                                                  </Popover>
                                                </span>
                                              ) : null}
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </span>
                                      </div>
                                    );
                                  }
                                  return null;
                                })}
                            </Tag>
                            {/* <Divider className="ant-divider-dark my-3" /> */}
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
}

export default PreConsultationReviewTable;

import { get, Irequest } from "./index";

export type IGetReferralsParams = {
    patient_id: number,
    page?: number,
    date_period?: {
        start_date: string,
        end_date: string
    },
    created_at?: string,
    search: string
};

export const getReferralsApi = (params: IGetReferralsParams) => {
    const request: Irequest = {
        subUrl: `/doctor/documents.json?`,
        params
    };
    return get(request);
};
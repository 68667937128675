import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as nhsMedicationsActions} from "../../../reducers/nhsRepeatMedications";
import {actions as pastOrdersActions} from "../../../reducers/pastOrders";
import {Pagination} from "../../../components/common";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../../utilities/utils";
import {isArray, debounce, isEmpty} from "lodash";
// import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {
  // Table,
  Select,
  Spin,
  Collapse,
  Tooltip,
  Tag,
  // Col
} from "antd";
// import {DATE_FORMAT} from "../../../constants/common";
import {addAcuteMedicationsApi} from "../../../services/acuteMedications";
import ModalPopUp from "../../../components/common/ModalPopUp";
import Highlighter from "react-highlight-words";
import {getDrugsApi} from "../../../services/drugs";
// import {getOrders} from "../../../services/orders";
import {InfoCircleOutlined} from "@ant-design/icons";
// import {getAnsweredQuestions} from "services/scriptReview";
import ScriptReviewContainer from "../../ScriptReviewDetailContainer/ScriptReviewDetailContainer";
import moment from "moment";
import {
  // DATE_FORMAT,
  DATE_FORMAT_24_HR,
} from "constants/common";
// import PastScriptReviewDeatailContainer from "./PastScriptReviewDeatailContainer";

// import { SearchOutlined } from "@ant-design/icons";
// import { Input } from "antd";
const status = {
  rejected: "Rejected",
  system_rejected: "System Rejected",
  approved: "Approved",
};
const {Option} = Select;
const {Panel} = Collapse;
class PastOrdersContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      message: "",
      pastOrders: null,
      isLoading: false,
      drugsArray: [],
      drugId: null,
      activeKey: [""],
      search: "",
      pastCoreDrugId: "",
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleSearch",
      "_handleCancel",
      "_hideModal",
      "_handleDropDownChange",
      "_handleSubmit",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.searchDrugUpdate = debounce(this._handleDrugSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, nhsMedicationsActions} = this.props;
    let {onSearch} = nhsMedicationsActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({search: value, patient_id: patientId, page: 1});
    }
  }

  async _handleDrugSearch(value) {
    if (!isEmpty(value)) {
      if (!isEmpty(value)) {
        this.setState(
          {
            drugsArray: [],
            page: 1,
            searching: true,
            isLoadmore: false,
            search: value,
          },
          async () => {
            this._getDrugs(false);
          }
        );
      } else {
        await this.setState({snomedCodes: []});
      }
      // try {
      //     this.setState({ searching: true, search: value }, async () => {
      //         let response = await getDrugsApi({ page: 1, term: value });
      //         let drugsArray = verifyObject(response, "data.data", [])
      //         this.setState({ drugsArray: drugsArray, searching: false })
      //     });

      // } catch (error) {
      //     const { message } = getErrorObject(error);
      //     await this.setState({ searching: false });
      //     errorToast({ content: message });
      // }
    } else {
      await this.setState({searching: false, drugsArray: []});
    }
  }
  _getDrugs = async (isLoadMore) => {
    try {
      let response = await getDrugsApi({
        page: this.state.page,
        term: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let drugsArray = verifyObject(response, "data.data", []);
      this.setState({
        drugsArray: isLoadMore
          ? [...this.state.drugsArray, ...drugsArray]
          : drugsArray,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  };

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getDrugs(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  componentDidMount() {
    this._onRequestList();
  }

  // _onRequestList() {
  //     let { patientId, nhsMedicationsActions } = this.props
  //     let { onRequest } = nhsMedicationsActions
  //     if (typeof onRequest === "function") {
  //         onRequest({ patient_id: patientId, page: 1 })
  //     }
  // }

  async _onRequestList() {
    let {patientId, pastOrdersActions} = this.props;
    let {onRequest} = pastOrdersActions;
    if (typeof onRequest === "function") {
      onRequest({user_id: patientId, page: 1});
    }
    // try {
    //   await this.setState({isLoading: true});
    //   let response = await getOrders({
    //     user_id: patientId,
    //     page: 1,
    //     // order_type: "private",
    //   });
    //   let pastOrders1 = verifyObject(response, "data.data", []);
    //   let pastOrders = [];
    //   pastOrders1.map((d) => pastOrders.push({...d, key: uniqueId("PORDER_")}));
    //   await this.setState({
    //     isLoading: false,
    //     pastOrders: pastOrders,
    //   });
    // } catch (error) {
    //   const {message} = getErrorObject(error);
    //   await this.setState({isLoading: false});
    //   errorToast({content: message});
    // }
  }
  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    this.setState({[key]: value ? value : "", errors: errors}, () => {});
  }
  _onPageChange(data) {
    this.setState({activeKey: [""]});
    let {
      patientId,
      pastOrdersActions: {onPageChange},
      // nhsMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, user_id: patientId});
    }
  }

  _handleCancel() {
    this.setState({
      visible: false,
      message: "",
      adding: false,
      drugsArray: [],
    });
  }

  _hideModal() {
    this.setState({visible: false, drugsArray: [], drugId: null});
  }

  async _handleSubmit(e) {
    e.preventDefault();
    let {drugId} = this.state;
    let {patientId} = this.props;
    let payload = {
      drug_id: drugId,
      user_id: patientId,
    };
    try {
      await this.setState({changing: true});
      let response = await addAcuteMedicationsApi({
        medication: {...payload},
      });
      await this._clearData();
      await successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      await this._onRequestList();
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({changing: false});
      errorToast({content: message});
    }
  }

  _clearData = () => {
    this.setState({
      visible: false,
      message: "",
      pastOrders: null,
      isLoading: false,
      drugsArray: [],
      drugId: null,
      changing: false,
    });
  };

  render() {
    let {
      // pastOrders,
      changing,
      // isLoading,
      searching,
      drugId,
      drugsArray,
      visible,
      isLoadmore,
      activeKey,
      pastCoreDrugId,
    } = this.state;
    let {data, isLoading, ids} = this.props.pastOrdersProps;
    // console.log("props", pastOrdersProps);
    // let {isLoading, ids} = pastOrdersProps;
    // console.log("render", pastOrdersProps.data);
    let pastOrders =
      data &&
      // !isEmpty(pastOrdersProps) &&
      // pastOrdersProps !== null &&
      // pastOrdersProps !== undefined &&
      Object.values(data);
    console.log("render-->", pastOrders);

    return (
      <Fragment>
        <div className="push-20-b pastorders_wrapper">
          {!this.props.fromPrivatePSQ && (
            <div className="form-row align-items-center push-10-b">
              <div className="col-md-6">
                <h6 className="font-14 weight-500 text-light-black m-0">
                  Past Orders
                </h6>
              </div>
              {/* <div className="col-md-6 text-right">
              <div className="relative searchbar_dropdown medical_Search">
                <Input allowClear={true} size="large" placeholder="Search" prefix={<SearchOutlined />}/>
              </div>
           
            </div> */}
            </div>
          )}
          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          {!isLoading && ids && ids.length === 0 && (
            <NoFoundMessage message="No past orders found" />
          )}
          {!isLoading &&
            pastOrders &&
            isArray(pastOrders) &&
            pastOrders.length > 0 && (
              <div className="prescription-array">
                <Collapse
                  expandIconPosition="right"
                  onChange={async (key) => {
                    await this.setState((prevState) => {
                      return {
                        ...prevState,
                        activeKey: key,
                      };
                    });
                  }}
                  activeKey={activeKey}
                  className="pre_quitionare"
                >
                  {!isLoading &&
                    pastOrders &&
                    isArray(pastOrders) &&
                    pastOrders.map((k, index) => {
                      return (
                        <Panel
                          key={[index]}
                          header={
                            <div
                              onClick={() =>
                                this.setState((prevState) => {
                                  return {
                                    ...prevState,
                                    pastCoreDrugId:
                                      k &&
                                      verifyObject(k, "core_drug_id", "") !== ""
                                        ? `${k.core_drug_id}`
                                        : "-",
                                  };
                                })
                              }
                            >
                              <p className="font-12 weight-400 text-left margin-0 text-capitalize padding-5 text-fff">
                                <div className="form-row">
                                  <div className="col-md-3">
                                    <span className="in-block">
                                      <Highlighter
                                        highlightClassName="search-highlighter"
                                        searchWords={[this.state.search]}
                                        autoEscape={true}
                                        textToHighlight={
                                          verifyObject(k, "drug_name", "") !==
                                          ""
                                            ? `${k.drug_name}`
                                            : "-"
                                        }
                                      />{" "}
                                      {k.prescription_type ===
                                      "private_medication" ? (
                                        <span className="ml-2 custom-bluelabel font-10">
                                          Private
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                  </div>
                                  <div className="col-md-4">
                                    <span className="in-block">
                                      <Fragment>
                                        {k && k.dosage_description
                                          ? k.dosage_description
                                          : "-"}
                                      </Fragment>
                                    </span>
                                  </div>
                                  <div className="col-md-3">
                                    <span className="ml-2 font-10 in-block">
                                      {k &&
                                      k.order_item_status.status !==
                                        "approved" ? (
                                        <Tag className="bg-red">
                                          <span className="text-white">
                                            {status[k.order_item_status.status]}{" "}
                                          </span>
                                        </Tag>
                                      ) : (
                                        <span className="custom-bluelabel">
                                          {status[k.order_item_status.status]}{" "}
                                        </span>
                                      )}
                                      {k.order_item_status.status ===
                                      "rejected" ? (
                                        <Tooltip
                                          title={
                                            k.order_item_status.notes !== null
                                              ? k.order_item_status.notes
                                              : "No reason available"
                                          }
                                        >
                                          <span className="info_icon">
                                            <InfoCircleOutlined />
                                          </span>
                                        </Tooltip>
                                      ) : (
                                        ""
                                      )}
                                    </span>
                                    <span className="ml-2 custom-bluelabel font-10 in-block text-capitalize">
                                      {verifyObject(k, "quantity", "") !== ""}
                                      {k.quantity}{" "}
                                      {verifyObject(k, "formulation")
                                        ? verifyObject(k, "formulation")
                                        : ""}
                                    </span>
                                  </div>
                                  <div className="col-md-2 text-right">
                                    <span className="ml-2 font-12">
                                      {verifyObject(k, "created_at", "") !== ""
                                        ? `${moment(k.created_at).format(
                                            DATE_FORMAT_24_HR
                                          )}`
                                        : "-"}
                                    </span>
                                  </div>
                                </div>
                              </p>
                            </div>
                          }
                        >
                          {k.core_drug_id !== null ? (
                            <ScriptReviewContainer
                              pastOrderId={k.order_id}
                              pastCoreDrugId={pastCoreDrugId}
                            />
                          ) : (
                            <NoFoundMessage message="No data found" />
                          )}
                          {/* <PastScriptReviewDeatailContainer /> */}
                        </Panel>
                      );
                    })}
                </Collapse>
                {/* <Table
                  pagination={false}
                  columns={columns}
                  dataSource={pastOrders}
                  className="font-12 no_table_header border-spacing-5"
                  expandable={{
                    expandedRowRender: async (record) => {
                      if (record) {
                        let payload = {
                          user_id: this.props.patientId,
                          order_id: verifyObject(record, "order_id", null),
                          core_drug_id: verifyObject(
                            record,
                            "core_drug_id",
                            null
                          ),
                        };
                        const response = await getAnsweredQuestions(payload);
                        console.log("response", response);
                        return <div>{record.drug_name}</div>;
                      }
                    },
                  }}
                /> */}
              </div>
            )}
        </div>

        {!isLoading && this.props.pastOrdersProps && (
          <div className="col-md-12">
            <Pagination
              data={this.props.pastOrdersProps}
              onPageChange={this._onPageChange}
            />
          </div>
        )}
        <ModalPopUp
          title={"Add Current Acute Medication"}
          handleCancel={this._hideModal}
          visible={visible}
          footer={true}
          handleOk={this._handleSubmit}
          okText={"Save"}
          loading={changing}
          okButtonProps={{disabled: drugId === null}}
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
        >
          <form>
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Drug Name</label>
                  <Select
                    name="snomedCodeId"
                    autoFocus
                    className="form-control margin-0"
                    allowClear={searching ? false : true}
                    value={drugId}
                    showSearch={true}
                    onPopupScroll={this.handleScroll}
                    onChange={(value) =>
                      this._handleDropDownChange(value, "drugId")
                    }
                    onSearch={(value) => {
                      let searchValue = value;
                      this.searchDrugUpdate(searchValue);
                    }}
                    suffixIcon={
                      !isLoadmore && searching && <Spin size="small" />
                    }
                    notFoundContent={
                      !searching &&
                      drugsArray.length === 0 && <span>No drugs available</span>
                    }
                    filterOption={false}
                    dropdownRender={(menus) => {
                      return (
                        <div ref={this.scrollDiv}>
                          {menus}
                          {isLoadmore && searching && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: 10,
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  >
                    {drugsArray.map((k) => {
                      return (
                        <Option value={k.id}>
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(k, "supplier_name", null) !== null
                                ? `${k.supplier_name}`
                                : `-`
                            }
                          />
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </form>
        </ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("past ordere state", state);
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    nhsMedications: verifyObject(state, "nhsMedications", []),
    pastOrdersProps: verifyObject(state, "pastOrders", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nhsMedicationsActions: bindActionCreators(nhsMedicationsActions, dispatch),
    pastOrdersActions: bindActionCreators(pastOrdersActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PastOrdersContainer);

export default ConnectedComponent;

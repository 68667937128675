/* eslint-disable react-hooks/exhaustive-deps */
import {Modal, Select, Spin} from "antd";
import React, {useEffect, useState} from "react";
import {Radio, DatePicker} from "antd";
import {DATE_FORMAT, appointmentTags} from "constants/common";
import moment from "moment";
import {errorToast, getErrorObject, verifyObject} from "utilities/utils";
import {allAppointmentUsersApi} from "services/allAppointmentUsers";
import {isArray} from "lodash";
import CustomCreatableSelect from "components/common/CustomCreatableSelect";
import {fetchSymptomsApi} from "services/symptoms";
import Asterisk from "components/common/AsteriskComponent/Asterisk";
// import { remove } from "lodash";

export default function PreConsultBookAppointmentModal({
  visible,
  handleCloseModal,
  handleSubmission,
  selecetdDate,
  disabledDate,
  handleDatepicker,
  slotsArray,
  onChangeSlots,
  selectedSlotTime,
  isFetchingSlot,
  bookingAppointment,
  handleAptTypeChange,
  selectedAptType,
  handleSelectChange,
  doctor_id,
  doctor_name,
  doctor_role,
  appointment_tag,
  handleSelectChange1,
}) {
  const [clinicians, setClinicians] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [pagination, setPagination] = useState(null);
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(1);
  const [symptomsData, setSymptomsData] = useState([]);

  const [searchingSymptom, setSearchingSymptom] = useState(false);

  useEffect(() => {
    onGetAllClinicians(false);
  }, []);

  const onGetAllClinicians = async () => {
    try {
      await setLoading(true);
      let response = await allAppointmentUsersApi({
        search: search,
        page,
      });
      if (response.data.data) {
        let pagination =
          verifyObject(response, "headers['x-pagination']", null) !== null
            ? JSON.parse(response.headers["x-pagination"])
            : null;

        let doctors = response?.data?.data;
        let found = doctors.find((d) => d.id === doctor_id);
        let newArray = doctors.filter((d) => d.id !== doctor_id);

        console.log("New Array", newArray);

        if (newArray.length > 0) {
          if (found) {
            setClinicians([found, ...newArray]);
          } else {
            let currentUser = {
              id: doctor_id,
              name: doctor_name || "",
              role: doctor_role || "",
            };
            setClinicians([currentUser, ...newArray]);
          }
        } else {
          if (found) {
            setClinicians([found]);
          } else {
            let currentUser = {
              id: doctor_id,
              name: doctor_name || "",
              role: doctor_role || "",
            };

            setClinicians([currentUser]);
          }
        }

        setPagination(pagination);
      }
      await setLoading(false);
      await setSearching(false);
      await setLoading(false);
    } catch (error) {
      const {message} = getErrorObject(error);
      await setLoading(false);
      errorToast({content: message});
    }
  };
  console.log("New clinician", clinicians);

  const fetchMoreData = () => {
    console.log("calling fethc more data ");
    setIsLoadMore(true);
    setPage(page + 1);
    setSearching(true);
  };
  const handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    // let { page, pagination } = initialValues;
    let total_pages = verifyObject(pagination, "total_pages", 0);
    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      fetchMoreData();
    }
  };
  const onGetSymptoms = async (value) => {
    try {
      setSearchingSymptom(true);
      let response = await fetchSymptomsApi({search: value});

      let newSymptomsData = verifyObject(response, "data.data", []);
      if (response.data.data) {
        setSymptomsData(
          newSymptomsData.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        );
      }
      setSearchingSymptom(false);
    } catch (error) {
      const {message} = getErrorObject(error);
      setSearchingSymptom(false);
      errorToast({content: message});
    }
  };
  return (
    <div>
      <Modal
        visible={visible}
        keyboard={false} // Prevents closing on Esc key
        maskClosable={false} // Prevents closing on outside click
        onCancel={handleCloseModal}
        onOk={handleSubmission}
        okButtonProps={{
          disabled:
            bookingAppointment === true ||
            selecetdDate === null ||
            selectedAptType === null ||
            selectedSlotTime === null ||
            appointment_tag === null ||
            doctor_id === null ||
            doctor_id === undefined,
          loading: bookingAppointment === true,
        }}
        okText="Book an Appointment"
        title={"Book Appointment"}
        width={900}
        destroyOnClose
        className="newpre_questionairepopup"
      >
        <div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="sel1">
                  Book With
                  <Asterisk />
                  {/* {!loading && (
                    <a
                      className="link blue"
                      onClick={() => {
                        handleSelectChange("all", "doctor_id");
                      }}
                      style={{
                        color: "#1445b7",
                        fontWeight: 600,
                      }}
                    >
                      (Click for all doctor)
                    </a>
                  )} */}
                </label>{" "}
                {loading ? (
                  <div className="text-center">
                    <Spin size="small" />
                  </div>
                ) : (
                  <Select
                    showSearch
                    className="form-control margin-0"
                    placeholder="Select doctor name"
                    optionFilterProp="children"
                    value={doctor_id}
                    onChange={(value) => handleSelectChange(value, "doctor_id")}
                    loading={loading}
                    onPopupScroll={handleScroll}
                    onSearch={(value) => setSearch(value)}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    allowClear
                    dropdownRender={(menus) => {
                      return (
                        <div>
                          {menus}
                          {searching && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: 10,
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  >
                    {isArray(clinicians) && clinicians.length > 1 && (
                      <Select.Option value={"all"}>{`All`}</Select.Option>
                    )}
                    {clinicians.map((user) => {
                      return (
                        <Select.Option key={user.id} value={user.id}>{`${
                          user.name
                        } (${user.role?.replaceAll("_", " ")})`}</Select.Option>
                      );
                    })}
                  </Select>
                )}
                {/* {this.state.errors && (
                  <span className="validate-danger">
                    {this.state.errors.last_name}
                  </span>
                )} */}
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row ">
              <div className="col-md-12">
                <label htmlFor="sel1" className="dis-block">
                  Type
                  <Asterisk />
                </label>
                {/* <select
                  value={selectedAptType}
                  className="form-control"
                  onChange={handleAptTypeChange}
                >
                  <option selected disabled value={null}>
                    Select Appointment Type
                  </option>
                  <option value={"video"}>Video</option>
                  <option value="face_to_face">Face to Face</option>
                </select> */}
                <Radio.Group
                  className="d-flex radiogroupbtn"
                  value={selectedAptType}
                  onChange={handleAptTypeChange}
                >
                  <Radio.Button value={"video"}>Video</Radio.Button>
                  <Radio.Button value="face_to_face">Face to Face</Radio.Button>
                </Radio.Group>
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row ">
              <div className="col-md-12">
                <label htmlFor="sel1" className="dis-block">
                  Date
                  <Asterisk />
                </label>
                <DatePicker
                  placeholder="Select Date"
                  className="form-control"
                  format={DATE_FORMAT}
                  value={selecetdDate && moment(selecetdDate, DATE_FORMAT)}
                  disabledDate={disabledDate}
                  onChange={handleDatepicker}
                  disabled={doctor_id === null || doctor_id === undefined}
                />
              </div>
            </div>
          </div>

          {selecetdDate && (
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <Spin spinning={isFetchingSlot}>
                    <div className="commonfield select-slot-group">
                      <label>
                        Select Time Slot
                        <Asterisk />
                      </label>

                      {!isFetchingSlot &&
                      slotsArray &&
                      slotsArray.length > 0 ? (
                        <Radio.Group
                          options={slotsArray || []}
                          onChange={onChangeSlots}
                          value={selectedSlotTime}
                          optionType="button"
                        />
                      ) : (
                        <p className="text-center text-danger">
                          {isFetchingSlot
                            ? ""
                            : slotsArray &&
                              slotsArray.length === 0 &&
                              "No time slots available"}
                        </p>
                      )}
                    </div>
                  </Spin>
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="sel1"> Appointment Reason</label>
                <CustomCreatableSelect
                  placeholder="Search and select or create reason"
                  handleUpdateBookingReason={(value) => {
                    // const value = e.target.value;
                    handleSelectChange1(value, "booking_reason");
                  }}
                  optionData={symptomsData}
                  onSearchFunction={onGetSymptoms}
                  isLoading={searchingSymptom}
                  selectClassName="custom-tag-dropdown"
                />
              </div>
            </div>
          </div>
          <div className="form-group">
            <div className="form-row">
              <div className="col-md-12">
                <label htmlFor="sel1">
                  {" "}
                  Appointment For
                  <Asterisk />
                </label>
                <Select
                  className="form-control margin-0"
                  placeholder="Select appointment for"
                  value={appointment_tag}
                  onChange={(value) => {
                    // const value = e.target.value;
                    handleSelectChange1(value, "appointment_tag");
                  }}
                >
                  {appointmentTags.map((d, index) => {
                    return (
                      <Select.Option key={index} value={d.value}>
                        {d.name}
                      </Select.Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
import { getConsultationReviewApi } from "services/consultationReview";
import { listingReducer } from "reducers/utils/listingReducer";

const { reducer, actions, types } = listingReducer({
  name: `consultationReview`,
  getApi: getConsultationReviewApi,
});

const consultationReview = reducer;
export { consultationReview, actions, types };

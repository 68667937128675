import { listingReducer } from "reducers/utils/listingReducer";
import { fetchPreConsultationSubmitLogs } from "services/preConsultConditions";

const { reducer, actions, types } = listingReducer({
  name: `preConsultationSubmitLogs`,
  getApi: fetchPreConsultationSubmitLogs,
});

const preConsultationSubmitLogs = reducer;
export { preConsultationSubmitLogs, actions, types };

import React from "react";
import {Input} from "antd";

export default function TextBoxInputItem({
  question,
  handleAnswerChange,
  parentQuestion,
  childQuestions,
  childOfQuestions,
}) {
  return (
    <div className="TextBoxInputItem_Wrapper">
      <div className="weight-500 font-14 d-flex">
        <span className="dot"></span>{" "}
        <span
          dangerouslySetInnerHTML={{ __html: question.title }}
          className="Questions"
        />
        {question.is_required ? <sup className="text-danger">*</sup> : null}
      </div>
      <div className="form-row mt-2">
        <div className="col-md-4">
          <div className="push-12-l not-p-l">
            <Input
              // name={`selectedAnswer${questions.id}`}
              // value={this.state[`selectedAnswer${index}`]}
              value={question.answer}
              onChange={(e) => {
                const val = e.target.value;
                handleAnswerChange(
                  val,
                  parentQuestion,
                  childQuestions,
                  childOfQuestions
                );
              }}
              type="text"
              placeholder={
                question.placeholder !== null ? question.placeholder : ""
              }
              className="form-control"
            />
          </div>
        </div>
      </div>
      <div className="errorandinfo">
        {question.error && (
          <span className="text-danger margin-top-10 weight-400 font-12 in-block">
            {question.error}
          </span>
        )}
      </div>
    </div>
  );
}

import {deletee, get, patch, post, put} from "./index";

export const getUpcomingAppointmentsApi = (params) => {
  const request = {
    subUrl: `/appointments/accepted_appointments.json`,
    params,
  };
  return get(request);
};
export const bookAppointmentFromPatientAPI = (data, customJWToken) => {
  const request = {
    subUrl: `/appointments/create_corporate_appointment`,
    data: data,
  };
  return post(request);
};
export const fetchSlotsAPI = (params) => {
  const request = {
    subUrl: `/patients/time_slots/get_corporate_slots`,
    params,
  };
  return get(request);
};
export const fetchAppointmentCalendarSlots = (params) => {
  const request = {
    subUrl: `/doctor/appointments/appointment_calendar.json`,
    params,
  };
  return get(request);
};
export const fetchAppointmentSlotsTypes = (params) => {
  const request = {
    subUrl: `/time_slots/fetch_slot_type.json`,
    params,
  };
  return get(request);
};
export const updateAppointmentFromPatientAPI = (data, customJWToken) => {
  const request = {
    subUrl: `/customer_support/appointments/${data.id}`,
    data: data,
  };
  return patch(request);
};

export const getPreviousAppointmentsApi = (params) => {
  const request = {
    subUrl: `/doctor/appointments.json`, //added doctor as new API from BE
    params,
  };
  return get(request);
};

export const getApproveRejectAppointmentsApi = (params) => {
  const request = {
    subUrl: `/appointments/booked_appointments.json`,
    params,
  };
  return get(request);
};

export const acceptRejectAppointments = (data) => {
  const request = {
    subUrl: `/appointments/${data.id}/accept_reject_appointment`,
    data,
  };
  return post(request);
};

export const updateAppointmentStatusAPI = (data) => {
  const request = {
    subUrl: `/appointments/${data.id}/update_status.json`,
    data: {
      appointment: data.appointment,
    },
  };
  return put(request);
};

export const cancelAppointmentAPI = (data) => {
  const request = {
    subUrl: `/appointments/${data.id}.json`,
  };
  return deletee(request);
};

export const markAsOOSAppointment = (data: updateEMDRparams) => {
  const request: Irequest = {
    subUrl: `/appointments/${data.id}/mark_appointment_as_finished_oos`,
  };
  return post(request);
};

export const getTokens = (params) => {
  const request = {
    subUrl: `/calls/get_tokens`,
    params,
  };
  return get(request);
};

export const startAppointment = (data) => {
  const request = {
    subUrl: `/prescribers/appointments`,
    data,
  };
  return post(request);
};

export const sendAppointmentInviteAPI = (params: IGetAppoinmentsParams) => {
  // console.log("Paramas In Appoitn ", params);
  const request: Irequest = {
    subUrl: `/healthya/customer_support/appointments/send_invitation.json
    `,
    params,
  };
  return get(request);
};
//  extend time integration
export const extendAppointmentDurationAPI = (data) => {
  const request = {
    subUrl: `/appointments/extend_appointment_duration`,
    data,
  };
  return patch(request);
};
export const softDeleteAppointments = (params) => {
  const request = {
    subUrl: `/doctor/appointments/destroy_appointments`,
    params,
  };
  return deletee(request);
};

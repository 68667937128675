import React from "react";
import { Space, Spin } from "antd";
export default function CommonLoader({ size, text }) {
  return (
    <div style={{ textAlign: "center", marginTop: 100, marginBottom: 100 }}>
      <Space size="middle">
        {text && <span>{text}</span>}
        <Spin size={size ? size : "large"} />
      </Space>
    </div>
  );
}

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { login, resetFormState, resetPassword } from "reducers/session";
// import ResetPassword from "containers/session/ResetPassword";
import ChangePassword from "./ChangePassword";

class ChangePasswordContaner extends Component {

  render() {
    return (
      <Fragment>
        <ChangePassword {...this.props} />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    session: state.session,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { login, resetFormState, resetPassword },
      dispatch
    ),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContaner);

/* eslint-disable eqeqeq */
import {
  // Input,
  Checkbox,
} from "antd";
import React, { Fragment, useEffect, useRef } from "react";

export default function CheckAgreeQuestion({
  handleAnswerChange,
  childQuestions,
  parentQuestion,
  question,
  childOfQuestions,
  gender,
}) {
  console.log("question checked", question.answer);
  const questionDivRef = useRef(null);

  useEffect(() => {
    const questionDiv = questionDivRef.current;

    if (questionDiv) {
      const nodeList = questionDiv.querySelectorAll("ul li, p");

      nodeList.forEach((element) => {
        // Remove the existing inline style with !important
        element.style.removeProperty("font-size");

        // Add your new style without !important
        element.style.fontSize = "16px"; // Set your desired font size
      });
    }
  }, []);
  if (
    question.is_conditional &&
    childQuestions &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    //TODO: Handle conditional
    let selectedOption = parentQuestion.answer;
    if (parentQuestion?.category === "single_choice") {
      if (
        question?.conditional_text_values &&
        question?.conditional_text_values[0] === selectedOption
      ) {
        return (
          <Fragment>
            <div className="check-agree-question_wrapper check-agree-question_wrapper_1">
              <div className="weight-500 font-14 margin-bottom-10 d-flex">
                <span className="dot"></span>
                <span
                  dangerouslySetInnerHTML={{ __html: question.title }}
                  className="Questions"
                  ref={questionDivRef}
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
              <div className="push-12-l">
                <Checkbox
                  onChange={(e) => {
                    const val = e.target.checked
                      ? "Yes agree with this"
                      : "No i dont agree with this";
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions
                    );
                  }}
                  checked={
                    question.answer === "Yes agree with this" ? true : false
                  }
                >
                  Please check
                </Checkbox>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }

    if (parentQuestion?.category === "numerical") {
      let selectedValue = parseInt(parentQuestion.answer, 10);
      const { conditional_max_value, conditional_min_value } = question;
      console.log("selectedValue", selectedValue);
      console.log("Max", conditional_max_value);
      console.log("Min", conditional_min_value);
      if (
        selectedValue <= conditional_max_value &&
        selectedValue >= conditional_min_value
      ) {
        return (
          <Fragment>
            <div className="check-agree-question_wrapper check-agree-question_wrapper_2">
              <div className="weight-500 font-14 margin-bottom-10 d-flex">
                <span className="dot"></span>
                <span
                  dangerouslySetInnerHTML={{ __html: question.title }}
                  className="Questions"
                  ref={questionDivRef}
                />
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
                {/* {!question.is_required ? "" : <b>(Required)</b>} */}
              </div>
              <div className="push-12-l">
                <Checkbox
                  onChange={(e) => {
                    const val = e.target.checked
                      ? "Yes agree with this"
                      : "No i dont agree with this";
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions
                    );
                  }}
                  checked={
                    question.answer === "Yes agree with this" ? true : false
                  }
                >
                  Please check
                </Checkbox>
              </div>
            </div>
            <div className="errorandinfo">
              {question.error && (
                <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                  {question.error}
                </span>
              )}
            </div>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }
  } else if (
    !childOfQuestions &&
    childQuestions &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="check-agree-question_wrapper check-agree-question_wrapper_3">
          <div className="weight-500 font-14 margin-bottom-10 d-flex">
            <span className="dot"></span>
            <span
              dangerouslySetInnerHTML={{ __html: question.title }}
              className="Questions"
              ref={questionDivRef}
            />
            {question.is_required ? (
              <sup className="text-danger">*</sup>
            ) : null}
            {/* {!question.is_required ? "" : <b>(Required)</b>} */}
          </div>
          <div className="push-12-l">
            <Checkbox
              onChange={(e) => {
                const val = e.target.checked
                  ? "Yes agree with this"
                  : "No i dont agree with this";
                handleAnswerChange(
                  val,
                  parentQuestion,
                  childQuestions,
                  childOfQuestions
                );
              }}
              checked={
                question.answer === "Yes agree with this" ? true : false
              }
            >
              Please check
            </Checkbox>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else if (
    childOfQuestions &&
    childQuestions.answer &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="check-agree-question_wrapper check-agree-question_wrapper_4">
          <div className="weight-500 font-14 margin-bottom-10 d-flex">
            <span className="dot"></span>
            <span
              dangerouslySetInnerHTML={{ __html: question.title }}
              className="Questions"
              ref={questionDivRef}
            />
            {question.is_required ? (
              <sup className="text-danger">*</sup>
            ) : null}
            {/* {!question.is_required ? "" : <b>(Required)</b>} */}
          </div>
          <div className="push-12-l">
            <Checkbox
              onChange={(e) => {
                const val = e.target.checked
                  ? "Yes agree with this"
                  : "No i dont agree with this";
                handleAnswerChange(
                  val,
                  parentQuestion,
                  childQuestions,
                  childOfQuestions
                );
              }}
              checked={
                question.answer === "Yes agree with this" ? true : false
              }
            >
              Please check
            </Checkbox>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else if (
    !childOfQuestions &&
    !childQuestions &&
    question &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="check-agree-question_wrapper check-agree-question_wrapper_5">
          <div className="weight-500 font-14 margin-bottom-10 d-flex">
            <span className="dot"></span>
            <span
              dangerouslySetInnerHTML={{ __html: question.title }}
              className="Questions"
              ref={questionDivRef}
            />
            {question.is_required ? (
              <sup className="text-danger">*</sup>
            ) : null}
            {/* {!question.is_required ? "" : <b>(Required)</b>} */}
          </div>
          <div className="push-12-l">
            <Checkbox
              onChange={(e) => {
                const val = e.target.checked
                  ? "Yes agree with this"
                  : "No i dont agree with this";
                handleAnswerChange(
                  val,
                  parentQuestion,
                  childQuestions,
                  childOfQuestions
                );
              }}
              checked={
                question.answer === "Yes agree with this" ? true : false
              }
            >
              Please check
            </Checkbox>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <></>;
  }
}

import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as refferalNotesActions} from "reducers/refferalNotes";
import {verifyObject} from "utilities/utils";
import CommonLoader from "components/common/CommonLoader";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "components/common/NoFoundMessage";
import {getConsultationRefferalNotes} from "../../../services/consultations";
import {errorToast, getErrorObject} from "../../../utilities/utils";
import ReferralAddModal from "./ReferralAddModal";
import {
  onSetDynamicInput,
  onSetSidebarKey,
  onRequestSetComponent,
} from "../../../reducers/dynamicTab";

class ReferralNoteContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      referralNote: null,
      visible: false,
      gpService: null,
    };
    ["_onRequestList"].map((fn) => (this[fn] = this[fn].bind(this)));
    this.reffralModal = React.createRef();
  }

  componentDidMount() {
    this._onRequestList();
  }

  async _onRequestList() {
    let {appointmentId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getConsultationRefferalNotes({
        appointment_id: appointmentId,
      });
      await this.setState(
        {
          referralNote: verifyObject(response, "data.data.referral_note", null),
          isLoading: false,
        },
        () => {
          if (!this.state.referralNote) {
            if (
              this.reffralModal &&
              this.reffralModal.current &&
              typeof this.reffralModal.current._handleShowModal === "function"
            ) {
              this.reffralModal.current._handleShowModal();
            }
          }
        }
      );
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }

  render() {
    let {referralNote, isLoading} = this.state;
    return (
      <Fragment>
        <div className="padding-55-t push-20-b">
          {/* <div className="form-row align-items-end margin-bottom-8">
            <div className="col-md-6 text-right">
              <div className="relative searchbar_dropdown medical_Search"></div>
            </div>
          </div> */}

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          {!isLoading && referralNote === null && (
            <NoFoundMessage message="No Referral was provided" />
          )}

          <ReferralAddModal
            ref={this.reffralModal}
            _onRequestList={this._onRequestList}
            {...this.state}
            {...this.props}
            consultation_id={this.props.consultation_id}
            is_autosave_consultation_data={
              this.props.is_autosave_consultation_data
            }
          />

          {!isLoading && referralNote && (
            <div className="form-row">
              <div className="col-md-6">
                <div className="margin-bottom-15">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    GP Info
                  </p>
                  <div className="border-box col-md-12 input-bg-f9f9f9">
                    <div className="form-row">
                      <div className="col-md-12">
                        <p className="font-11 weight-400 text-dark-gray text-left">
                          Name :{" "}
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(
                                referralNote,
                                "referral_by.name",
                                null
                              ) !== null
                                ? `Dr ${referralNote.referral_by.name}`
                                : "-"
                            }
                          />
                        </p>
                        <p className="font-11 weight-400 text-dark-gray text-left margin-0">
                          Professional Registration Number :{" "}
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(
                                referralNote,
                                "referral_by.professional_registration_number",
                                null
                              ) !== null
                                ? `${referralNote.referral_by.professional_registration_number}`
                                : `-`
                            }
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-15">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    Text explanation by GP
                  </p>
                  <div className="border-box col-md-12 input-bg-f9f9f9">
                    <div className="form-row">
                      <div className="col-md-12">
                        <p className="font-11 weight-400 text-dark-gray text-left margin-0">
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(
                                referralNote,
                                "referral_reason",
                                ""
                              ) !== ""
                                ? `${referralNote.referral_reason}`
                                : "-"
                            }
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="margin-bottom-15">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    Additional Note
                  </p>
                  <div className="border-box col-md-12 input-bg-f9f9f9">
                    <div className="form-row">
                      <div className="col-md-12">
                        <p className="font-11 weight-400 text-dark-gray text-left margin-0">
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(
                                referralNote,
                                "additional_note",
                                ""
                              ) !== ""
                                ? `${referralNote.additional_note}`
                                : "-"
                            }
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="margin-bottom-15">
                  <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                    Referral Detail
                  </p>
                  <div className="border-box col-md-12 input-bg-f9f9f9">
                    {referralNote.specialty && referralNote.specialty.name && (
                      <p className="font-11 weight-400 text-dark-gray text-left">
                        Speciality :
                        <Highlighter
                          highlightClassName="search-highlighter"
                          searchWords={[this.state.search]}
                          autoEscape={true}
                          textToHighlight={
                            verifyObject(referralNote, "specialty.name", "") !==
                            ""
                              ? `${referralNote.specialty.name}`
                              : "-"
                          }
                        />
                      </p>
                    )}
                    {referralNote.specialty &&
                      referralNote.specialty.field_of_practice && (
                        <p className="font-11 weight-400 text-dark-gray text-left">
                          Fields of speciality practice :
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(
                                referralNote,
                                "specialty.field_of_practice",
                                ""
                              ) !== ""
                                ? `${referralNote.specialty.field_of_practice}`
                                : "-"
                            }
                          />
                        </p>
                      )}
                    <p className="font-11 weight-500 text-light-black text-left">
                      <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[this.state.search]}
                        autoEscape={true}
                        textToHighlight={
                          verifyObject(
                            referralNote,
                            "surgery.practice_name",
                            ""
                          ) !== ""
                            ? `${referralNote.surgery.practice_name}`
                            : "-"
                        }
                      />
                    </p>
                    <p className="font-11 weight-400 text-dark-gray text-left">
                      <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[this.state.search]}
                        autoEscape={true}
                        textToHighlight={
                          verifyObject(
                            referralNote,
                            "surgery.address",
                            null
                          ) !== null
                            ? `${referralNote.surgery.address} ,`
                            : ""
                        }
                      />
                      <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[this.state.search]}
                        autoEscape={true}
                        textToHighlight={
                          verifyObject(
                            referralNote,
                            "surgery.address",
                            null
                          ) !== null
                            ? `${referralNote.surgery.city} ,`
                            : ""
                        }
                      />
                      <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[this.state.search]}
                        autoEscape={true}
                        textToHighlight={
                          verifyObject(
                            referralNote,
                            "surgery.address",
                            null
                          ) !== null
                            ? `${referralNote.surgery.county} ,`
                            : ""
                        }
                      />
                      <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[this.state.search]}
                        autoEscape={true}
                        textToHighlight={
                          verifyObject(
                            referralNote,
                            "surgery.address",
                            null
                          ) !== null
                            ? `${referralNote.surgery.post_code} ,`
                            : ""
                        }
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    gpService: verifyObject(
      state.localStore,
      "appointment_obj.gp_service",
      null
    )
      ? verifyObject(state.localStore, "appointment_obj.gp_service", null)
      : verifyObject(state.localStore, "patient_detail_obj.gp_service", null)
      ? verifyObject(state.localStore, "patient_detail_obj.gp_service", null)
      : null,
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    patient_detail_obj: verifyObject(
      state.localStore,
      "patient_detail_obj",
      null
    ),
    prev_appt_tab_key: verifyObject(
      state,
      "localStore.prev_appt_tab_key",
      "finished"
    ),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    consultation_id: verifyObject(
      state.localStore,
      "consultation_obj.consultation_log.consultation_id",
      null
    ),
    dynamicInputs: verifyObject(state, "dynamicTab.dynamicInputs", null),
    is_autosave_consultation_data: verifyObject(
      state.localStore,
      "is_autosave_consultation_data",
      false
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refferalNotesActions: bindActionCreators(refferalNotesActions, dispatch),
    dynamicTabAction: bindActionCreators(
      {onSetDynamicInput, onSetSidebarKey, onRequestSetComponent},
      dispatch
    ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralNoteContainer);

export default ConnectedComponent;

import React from 'react'

import PaymentsSettingsContainer from '../../containers/Payments/PaymentsSettingsContainer'
import Layout from '../../Layouts/PageLayout'
import { appRoutesConst } from '../../app/navigation'

import "assets/css/pages/payment.css";

const sideBarArray = [
	{
		label: 'Appointments',
		path: appRoutesConst.payments,
		icon: null,
		key: 1,
	},
	{
		label: 'Orders',
		path: appRoutesConst.orderPayments,
		icon: null,
		key: 2,
	},
	{
		label: 'Settings',
		path: appRoutesConst.paymentSettings,
		icon: null,
		key: 3,
	},
]
class PaymentSettingsPage extends React.Component {
	render() {
		return (
			<Layout title='Payments' sideBarArray={sideBarArray}>
				<div className='rightmainsection payment_setting'>
					<div className='container-fluid right_wrapper p-0'>
						{/* <div className='row'> */}
							<div className='col-md-12 selection_container p-0'>
								<PaymentsSettingsContainer />
							</div>
						{/* </div> */}
					</div>
				</div>
			</Layout>
		)
	}
}

export default PaymentSettingsPage

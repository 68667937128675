import React, {Fragment} from "react";
import "assets/css/pages/login.css";
import {Button, Result} from "antd";
import {
  PHARAM_SMART_DEV_URL,
  PHARAM_SMART_PROD_URL,
} from "../../constants/common";
import PharmaSmartLogo from "../../assets/images/header/pharm-smart-logo.png";
/**
 * PharmSmartLogout function.
 *
 * @return {type} the JSX element representing the logout screen.
 */
const PharmSmartLogout = () => {
  //   useEffect(() => {
  //     localStorage.clear();
  //     store.dispatch({ type: "CLEAR_LOCAL_STATE" });
  //   }, []);
  return (
    <Fragment>
      <Fragment>
        <div className="login-wrapper">
          <div className="container-fluid">
            <div className="login-wrapper-logo">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <a title="healthya.co.uk">
                    <img
                      src={PharmaSmartLogo}
                      alt="Pharma Smart logo"
                      className="img-responsive"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <Result
              status="warning"
              title="Your session has expired for security reason"
              subTitle="Please login again via PharmSmart to continue accessing our services and features."
              extra={
                <>
                  <Button
                    onClick={() => {
                      if (process.env.REACT_APP_ENV === "dev") {
                        window.location.href = PHARAM_SMART_DEV_URL;
                      } else {
                        window.location.href = PHARAM_SMART_PROD_URL;
                      }
                    }}
                    type="primary"
                    key="console"
                  >
                    Login
                  </Button>
                </>
              }
            />
          </div>
        </div>
      </Fragment>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default PharmSmartLogout;

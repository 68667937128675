import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import { ExclamationCircleOutlined } from "@ant-design/icons";
import {debounce, isEmpty} from "lodash";
import {Select, Modal, Spin} from "antd";
import moment from "moment";

import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../../utilities/utils";
import {actions as medicalHistoryActions} from "../../../reducers/medicalHistory";
import {
  getMedicalHistoryApi,
  deleteMedicalHistories,
  updateMedicalHistories,
  addMedicalHistories,
} from "../../../services/patientDetail";
import {ValidateInput} from "./MedicalHistoryValidations";
import {searchSnomedCode} from "../../../services/snomedCodes";

import ModalPopUp from "../../../components/common/ModalPopUp";
import SingleCheckBox from "../../../components/common/controls/SingleCheckBox";
import Highlighter from "react-highlight-words";
import MedicalHistoryTable from "./MedicalHistoryTable";
import "assets/css/pages/medical-history.css";
// import search_blue from "assets/images/common/search-blue.svg";
// import { Input } from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";
// import InfiniteScroll from "react-infinite-scroll-component";

const {confirm} = Modal;
const {Option} = Select;

let monthArray = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class MedicalHistoryContainer extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      medicalHistory: [],
      isLoading: false,
      snomedCodes: [],
      currentlyActive: false,
      snomedCodeId: "",
      snomedCodeText: "",
      startDateYear: "",
      endDateYear: "",
      startDateMonth: "",
      endDateMonth: "",
      search: "",
      errors: {},
      selectedHistoryProblem: null,
      editId: null,
      changing: false,
      page: 1,
      total: 0,
      pagination: null,
      isLoadmore: false,
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_showModal",
      "_hideModal",
      "_handleCheckBox",
      "_handleDropDownChange",
      "_handleSubmit",
      "_handleTextChange",
      "_handleEdit",
      "_clearData",
      "_handleDelete",
      "_submitDelete",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.scrollDiv = React.createRef();
  }

  componentDidMount() {
    this._onRequestList();
  }

  async _handleSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          snomedCodes: [],
          search: value,
        },
        async () => {
          this._getSnomedCodes(false);
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  async _getSnomedCodes(isLoadMore) {
    try {
      let response = await searchSnomedCode({
        page: this.state.page,
        search: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...snomedCodes]
          : snomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getSnomedCodes(true);
      }
    );
  };

  async _onRequestList() {
    let {patientId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getMedicalHistoryApi({user_id: patientId});
      let medicalHistory = verifyObject(
        response,
        "data.data.medical_history",
        []
      );
      await this.setState({isLoading: false, medicalHistory: medicalHistory});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }

  _showModal() {
    this.setState({visible: true});
  }

  _hideModal() {
    this.setState({visible: false}, () => {
      this._clearData();
    });
  }
  _onPageChange(data) {
    let {
      medicalHistoryActions: {onPageChange},
      rightToWork: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page, search});
    }
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }
    this.setState({[key]: value ? value : "", errors: errors}, () => {
      if (!isEmpty(this.state.snomedCodeId.toString())) {
        this.setState({snomedCodeText: ""});
      }
    });
  }

  async _handleEdit(record) {
    let {
      history_problem: {id, description},
      is_active,
      start_date,
      end_date,
    } = record;
    let selectedStartMonth = moment(start_date).format("MMMM");
    let selectedStartYear = moment(start_date).format("YYYY");
    let selectedEndMonth = "";
    let selectedEndYear = "";

    if (end_date) {
      selectedEndMonth = moment(end_date).format("MMMM");
      selectedEndYear = moment(end_date).format("YYYY");
    }
    // await this._handleSearch()
    await this._handleSearch(description);
    await this.setState({
      startDateMonth: selectedStartMonth,
      startDateYear: selectedStartYear,
      endDateYear: selectedEndYear,
      endDateMonth: selectedEndMonth,
      currentlyActive: is_active,
      snomedCodeId: id,
      editId: record.id,
      selectedHistoryProblem: {
        id: id,
        description: description,
      },
      visible: true,
    });
  }

  _handleTextChange(e) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
      delete errors["snomedCodeId"];
    }
    if (!isEmpty(this.state.snomedCodeText)) {
      this.setState({snomedCodeId: "", snomedCodes: []});
    }
    this.setState({[e.target.name]: e.target.value, errors: errors});
  }
  _handleCheckBox(e) {
    let checked = e.target.checked;
    if (checked) {
      this.setState({endDateMonth: "", endDateYear: ""});
    }
    this.setState({currentlyActive: checked});
  }
  handleSnomed = (value) => {
    this.setState({snomed_code_id: value});
  };

  async _handleSubmit(e) {
    e.preventDefault();
    let {
      snomedCodeId,
      currentlyActive,
      editId,
      snomedCodeText,
      startDateYear,
      startDateMonth,
      endDateMonth,
      endDateYear,
    } = this.state;

    let startDateString = `02-${startDateMonth}-${startDateYear}`;
    let startDate = moment(startDateString).format("YYYY-MM-DD");

    let endDateString = `02-${endDateMonth}-${endDateYear}`;
    let endDate = moment(endDateString).format("YYYY-MM-DD");

    let toBeValidateObj = {
      snomedCodeId: snomedCodeId.toString(),
      snomedCodeText: snomedCodeText.toString(),
      startDateYear: startDateYear.toString(),
      startDateMonth: startDateMonth.toString(),
      endDateMonth: endDateMonth.toString(),
      endDateYear: endDateYear.toString(),
      currentlyActive: currentlyActive,
      startDate: startDate,
      endDate: currentlyActive ? moment().toISOString() : endDate,
    };

    const errors = ValidateInput(toBeValidateObj);
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let {patientId} = this.props;

      let payload = {};
      if (editId) {
        payload = {
          is_active: toBeValidateObj.currentlyActive,
          end_date: toBeValidateObj.currentlyActive
            ? null
            : toBeValidateObj.endDate,
          start_date: toBeValidateObj.startDate,
          snomed_code_id: !isEmpty(toBeValidateObj.snomedCodeId)
            ? toBeValidateObj.snomedCodeId
            : toBeValidateObj.snomedCodeText,
          user_id: patientId,
        };

        updateMedicalHistories({
          id: editId,
          medical_history: {...payload},
        })
          .then((response) => {
            this._clearData();
            this._onRequestList();
            successToast({
              content: verifyObject(response, "data.message", "Success"),
            });
          })
          .catch((error) => {
            const {message} = getErrorObject(error);
            this.setState({changing: false});
            errorToast({content: message});
          });
      } else {
        payload = {
          is_active: toBeValidateObj.currentlyActive,
          end_date: toBeValidateObj.currentlyActive
            ? null
            : toBeValidateObj.endDate,
          start_date: toBeValidateObj.startDate,
          snomed_code_id: !isEmpty(toBeValidateObj.snomedCodeId)
            ? toBeValidateObj.snomedCodeId
            : toBeValidateObj.snomedCodeText,
          user_id: patientId,
        };
        try {
          await this.setState({changing: true});
          let response = await addMedicalHistories({
            medical_history: {...payload},
          });
          await this._clearData();
          await successToast({
            content: verifyObject(response, "data.message", "Success"),
          });
          await this._onRequestList();
        } catch (error) {
          const {message} = getErrorObject(error);
          await this.setState({changing: false});
          errorToast({content: message});
        }
      }
    }
  }

  _handleDelete(record) {
    confirm({
      title: "Are you sure you want to remove?",
      // icon: <ExclamationCircleOutlined />,

      onOk: async () => {
        await this._submitDelete(record);
      },
      onCancel() {},
    });
  }

  async _submitDelete(record) {
    try {
      await this.setState({deleting: true});
      let response = await deleteMedicalHistories({id: record.id});
      await this.setState({deleting: false}, async () => {
        await this._clearData();
        await successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
        await this._onRequestList();
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({deleting: false});
      errorToast({content: message});
    }
  }
  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };

  _clearData() {
    this.setState({
      snomedCodes: [],
      currentlyActive: false,
      snomedCodeId: "",
      snomedCodeText: "",
      startDateYear: "",
      endDateYear: "",
      startDateMonth: "",
      endDateMonth: "",
      search: "",
      errors: {},
      selectedHistoryProblem: null,
      editId: null,
      visible: false,
      changing: false,
      searching: false,
    });
  }

  render() {
    let {
      medicalHistory,
      isLoading,
      visible,
      snomedCodes,
      searching,
      currentlyActive,
      snomedCodeText,
      editId,
      startDateMonth,
      startDateYear,
      endDateMonth,
      endDateYear,
      changing,
      isLoadmore,
      errors,
    } = this.state;
    let startYear = 1900;
    let currentYear = new Date().getFullYear();
    let years = [];
    startYear = startYear || 1980;
    while (startYear <= currentYear) {
      years.unshift(startYear++);
    }

    return (
      <Fragment>
        <div className="padding-55-t push-20-b medicalhistory_wrapper">
          <div className="form-row align-items-center">
            <div className="col-md-6">
              <h6 className="font-14 weight-500 text-light-black m-0">
                Active Medical Conditions
              </h6>
            </div>
            <div className="col-md-6 text-right">
              {/* <div className="relative searchbar_dropdown medical_Search">
                <Input
                  allowClear={true}
                  size="large"
                  placeholder="Search"
                  prefix={<SearchOutlined />}
                />
            
              </div> */}
            </div>
            {/* <div className="col-md-4 search_filter">
              <Input placeholder="Search" prefix={<SearchOutlined />} className="form-control"/>
            </div> */}
          </div>

          <div className="form-row margin-bottom-10 push-10-t">
            <div className="col-md-12 text-right">
              <a
                onClick={this._showModal}
                className="font-13 weight-500 text-blue"
              >
                <PlusSquareOutlined
                  style={{
                    cursor: "pointer",
                    color: "#1445b7",
                    fontSize: "15px",
                  }}
                />{" "}
                Add{" "}
              </a>
              {/* <img
                alt=""
                src={plus_blue}
                width="14"
                height="14"
                onClick={this._showModal}
                style={{ cursor: "pointer" }}
              ></img> */}
            </div>
          </div>
          <MedicalHistoryTable
            searching={searching}
            _handleDelete={this._handleDelete}
            _handleEdit={this._handleEdit}
            isLoading={isLoading}
            _onRequestList={this._onRequestList}
            dataSource={medicalHistory}
          />
        </div>
        <ModalPopUp
          title={
            editId
              ? "Update Problems / Conditions"
              : "Add Problems / Conditions"
          }
          handleCancel={this._hideModal}
          visible={visible}
          footer={true}
          closable={false}
          handleOk={this._handleSubmit}
          okText={editId ? "Update" : "Save"}
          loading={changing}
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
        >
          <form>
            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Diseases Name</label>
                  <Select
                    className={`form-control ${
                      errors && errors.snomedCodeId && "danger-border"
                    } margin-0 placeholder-10`}
                    autoFocus
                    name="snomedCodeId"
                    id="scrollableDiv"
                    onPopupScroll={this.handleScroll}
                    allowClear={searching ? false : true}
                    value={this.state.snomedCodeId}
                    showSearch={true}
                    onChange={(value) =>
                      this._handleDropDownChange(value, "snomedCodeId")
                    }
                    onSearch={(value) => {
                      let searchValue = value;
                      this.searchUpdate(searchValue);
                    }}
                    listItemHeight={10}
                    listHeight={150}
                    suffixIcon={
                      !isLoadmore && searching && <Spin size="small" />
                    }
                    notFoundContent={
                      !searching &&
                      snomedCodes.length === 0 && (
                        <span>No codes available</span>
                      )
                    }
                    filterOption={false}
                    dropdownRender={(menus) => {
                      return (
                        <div ref={this.scrollDiv}>
                          {menus}
                          {isLoadmore && searching && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: 10,
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  >
                    {snomedCodes.map((k) => {
                      return (
                        <Option value={k.id}>
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(k, "full_code", null) !== null
                                ? `${k.full_code}`
                                : `-`
                            }
                          />
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="form-row">
                <div className="col-md-12">
                  <label htmlFor="sel1">
                    Feel free to freetext your Clinical Impressions
                  </label>
                  <input
                    className={`form-control placeholder-10 ${
                      errors && errors.snomedCodeId && "danger-border"
                    } margin-0 placeholder-10`}
                    name="snomedCodeText"
                    value={snomedCodeText}
                    onChange={this._handleTextChange}
                    placeholder="Add custom"
                  />
                  {errors && (
                    <span className="validate-danger">
                      {errors.snomedCodeId}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <label htmlFor="sel1">Start Date</label>
              <div className="form-row">
                <div className="col-md-6">
                  <Select
                    className={`form-control ${
                      errors && errors.startDateMonth && "danger-border"
                    } margin-0`}
                    name="startDateMonth"
                    onChange={(value) =>
                      this._handleDropDownChange(value, "startDateMonth")
                    }
                    placeholder="Month"
                    value={startDateMonth}
                  >
                    <Option value={""} disabled>
                      Select start month
                    </Option>

                    {monthArray.map((y) => {
                      return (
                        <Option key={y} value={y}>
                          {y}
                        </Option>
                      );
                    })}
                  </Select>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.startDateMonth}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <Select
                    className={`form-control ${
                      errors && errors.startDateYear && "danger-border"
                    } margin-0`}
                    name="startDateYear"
                    onChange={(value) =>
                      this._handleDropDownChange(value, "startDateYear")
                    }
                    placeholder="Year"
                    value={startDateYear}
                  >
                    <Option value={""} disabled>
                      Select start year
                    </Option>

                    {years.map((y) => {
                      return (
                        <Option key={y} value={y}>
                          {y}
                        </Option>
                      );
                    })}
                  </Select>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.startDateYear}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {!currentlyActive && (
              <div className="form-group">
                <label htmlFor="sel1">End Date</label>
                <div className="form-row">
                  <div className="col-md-6">
                    <Select
                      className={`form-control ${
                        errors && errors.endDateMonth && "danger-border"
                      } margin-0`}
                      name="endDateMonth"
                      onChange={(value) =>
                        this._handleDropDownChange(value, "endDateMonth")
                      }
                      placeholder="Month"
                      value={endDateMonth}
                    >
                      <Option value={""} disabled>
                        Select end month
                      </Option>
                      {monthArray.map((y) => {
                        return (
                          <Option key={y} value={y}>
                            {y}
                          </Option>
                        );
                      })}
                    </Select>
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.endDateMonth}
                      </span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <Select
                      className={`form-control ${
                        errors && errors.endDateYear && "danger-border"
                      } margin-0`}
                      name="endDateYear"
                      onChange={(value) =>
                        this._handleDropDownChange(value, "endDateYear")
                      }
                      placeholder="Year"
                      value={endDateYear}
                    >
                      <Option value={""} disabled>
                        Select end year
                      </Option>
                      {years.map((y) => {
                        return (
                          <Option key={y} value={y}>
                            {y}
                          </Option>
                        );
                      })}
                    </Select>
                    {this.state.errors && (
                      <span className="validate-danger">
                        {this.state.errors.endDateYear}
                      </span>
                    )}
                  </div>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.endDate}
                    </span>
                  )}
                </div>
              </div>
            )}

            <div className="form-group">
              <div className="row">
                <div className="col-md-6">
                  <SingleCheckBox
                    checked={currentlyActive}
                    onChange={this._handleCheckBox}
                    label="Active Currently"
                  />
                </div>
              </div>
            </div>
          </form>
        </ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state, "localStore.patientId", null),
    medicalHistory: verifyObject(state, "medicalHistory", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    medicalHistoryActions: bindActionCreators(medicalHistoryActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(MedicalHistoryContainer);

export default ConnectedComponent;

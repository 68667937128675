import {
  createEmergencyAlertApi,
  IEmergencyAlertParams
} from "services/alerts";
import { getErrorObject, errorToast, successToast } from "utilities/utils";

export const types = {
  ON_CREATE_EMERGENCY_MODAL_OPEN:
    "emergencyAlert/ON_CREATE_EMERGENCY_MODAL_OPEN",
  ON_CREATE_EMERGENCY_MODAL_CLOSE:
    "emergencyAlert/ON_CREATE_EMERGENCY_MODAL_CLOSE",
  CREATE_EMERGENCY_REQUEST: "emergencyAlert/CREATE_EMERGENCY_REQUEST",
  CREATE_EMERGENCY_SUCCESS: "emergencyAlert/CREATE_EMERGENCY_SUCCESS",
  CREATE_EMERGENCY_FAILURE: "emergencyAlert/CREATE_EMERGENCY_FAILURE",
  RESET_STATE: "emergencyAlert/RESET_STATE"
};

const initialState = {
  modal: {
    isOpen: false
  },
  isLoading: false,
  error: null
};

export const emergencyAlert = (state = initialState, action) => {
  switch (action.type) {
    case types.ON_CREATE_EMERGENCY_MODAL_OPEN: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: true
        }
      };
    }
    case types.ON_CREATE_EMERGENCY_MODAL_CLOSE: {
      return {
        ...state,
        modal: {
          ...state.modal,
          isOpen: false
        }
      };
    }
    case types.CREATE_EMERGENCY_REQUEST: {
      return {
        ...state,
        isLoading: true
      };
    }
    case types.CREATE_EMERGENCY_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        modal: {
          ...state.modal,
          isOpen: false
        }
      };
    }
    case types.CREATE_EMERGENCY_FAILURE: {
      const { error } = action.payload;
      return {
        ...state,
        isLoading: false,
        error
      };
    }
    case types.RESET_STATE: {
      return initialState;
    }
    default:
      return state;
  }
};

const onCreateEmergency = (params: IEmergencyAlertParams) => {
  return async (dispatch, getState) => {
    const { customerId } = getState().localStore;
    dispatch({
      type: types.CREATE_EMERGENCY_REQUEST,
      payload: {}
    });
    try {
      await createEmergencyAlertApi({
        emergency_alert: { patient_id: customerId, ...params.emergency_alert }
      });
      dispatch({
        type: types.CREATE_EMERGENCY_SUCCESS,
        payload: {}
      });
      successToast({ content: "Emergency Alert created!" });
    } catch (error) {
      const { message } = getErrorObject(error);
      dispatch({
        type: types.CREATE_EMERGENCY_FAILURE,
        payload: { error: message }
      });
      errorToast({ content: message });
    }
  };
};

const onOpenModal = () => {
  return {
    type: types.ON_CREATE_EMERGENCY_MODAL_OPEN
  };
};

const onCloseModal = () => {
  return {
    type: types.ON_CREATE_EMERGENCY_MODAL_CLOSE
  };
};

const resetState = () => {
  return {
    type: types.RESET_STATE
  };
};

export const actions = {
  onCreateEmergency,
  onOpenModal,
  onCloseModal,
  resetState
};

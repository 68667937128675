import React, { Fragment } from "react";
import { Paginate } from "components/common/index";
import { Row, Col } from "reactstrap";

const Pagination = props => {
    const { data, onPageChange } = props;
    const { pagination } = data;

    return (
        <Fragment>
            <Row>
                <Col xs="12" className="d-flex justify-content-center">
                    {pagination.total_pages ? (
                        <Paginate
                            pageCount={pagination.total_pages}
                            onPageChange={onPageChange}
                            page={pagination.next_page ? pagination.next_page - 1 : pagination.total_pages}
                        />
                    ) : null}
                </Col>
                {/* <Col className="d-flex justify-content-center">
          {isLoading ? <Spinner className="" /> : null}
        </Col> */}
            </Row>
        </Fragment>
    );
};

export default Pagination;

import React, { Component } from "react";
// import logo from "assets/images/common/alldaydrlogo.png";
import mobileicon from "assets/images/common/mobile.svg";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { validateOTP } from "./validateChangePassword";
import { Form, FormGroup } from "reactstrap";
import { isNumeric } from "validator";
import {
  login,
  resetFormState,
  navigateToCustomerSelectionViaOTP,
} from "reducers/session";
import {
  successToast,
  getErrorObject,
  errorToast,
  verifyObject,
  generateTokenFromJWT,
} from "../../utilities/utils";
import {
  sendOTPApi,
  verifyUserApi,
  getOTPApi,
  // updatePlayerID,
} from "../../services/session";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";
import SingleCheckBox from "../../components/common/controls/SingleCheckBox";

import "assets/css/pages/verify-otp.css";
import store from "app/store";

class OTPContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sending: false,
      verifying: false,
      code: "",
      errorMessage: "",
      errors: null,
      isAccepted: false,
      time: 30,
    };
    ["_handleCheckbox", "_startTimer"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  componentDidMount() {
    this.timerId = setInterval(() => {
      this.setState((prevState) => ({ time: prevState.time - 1 }));
    }, 1000);
  }

  _startTimer() {
    this.setState({ time: 30 }, () => {
      this.resendOTP();
      this.timerId = setInterval(() => {
        this.setState((prevState) => ({ time: prevState.time - 1 }));
      }, 1000);
    });
  }

  componentDidUpdate(prev, nextState) {
    if (nextState.time === 0) {
      clearInterval(this.timerId);
    }
  }
  getOTP = async () => {
    try {
      let phone = JSON.parse(localStorage.getItem("otp-phone"));
      let response = await getOTPApi({
        phone: phone,
        role: this.props.user.role,
      });
      await this.setState({ verifying: true }, async () => {
        // if (response.data.message) {
        successToast({ content: response.data.message });
        this.setState({ code: response.data.data }, () => {
          // localStorage.removeItem("otp-phone");
        });
        // }
      });
      //   }
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      this.setState({ verifying: false });
    }
  };

  _handleCheckbox(e) {
    let checked = e.target.checked;
    this.setState({ isAccepted: checked });
  }
  verifyUser = async (e) => {
    e.preventDefault();
    let data = {
      code: this.state.code,
    };
    const errors = validateOTP(data);

    if (!errors.isValid) {
      this.setState({ errors: errors.errors, verifying: false });
    } else {
      try {
        let phone = JSON.parse(localStorage.getItem("otp-phone"));
        this.setState({ verifying: true });
        const customJWToken = generateTokenFromJWT();
        let response = await verifyUserApi(
          {
            otp: this.state.code,
            phone: phone,
            role: this.props.user.role,
          },
          customJWToken,
          this.props.user.api_token
        );
        if (response.data.message) {
          successToast({ content: response.data.message });
          this.setState({ verifying: false }, async () => {
            await localStorage.removeItem("otp-phone");
            store.dispatch({ type: "OTP_EMAIL", payload: null });

            // var OneSignal = window.OneSignal || [];

            // // successToast({ content: "Login success" });
            // let player_id = localStorage.getItem("player_id");
            // if (!player_id) {
            //   player_id = await OneSignal.getUserId();
            // }
            // if (player_id) {
            //   await updatePlayerID({ player_id: player_id });
          // }
            await console.log(
              "INNNNNN.........LOCAL STORAGE SET..................."
            );
            var bc = new BroadcastChannel("test_channel");

            bc.postMessage("This is a test message.");
            localStorage.setItem("login_message", "is_success_login");
            await this.props.actions.navigateToCustomerSelectionViaOTP(
              response
            );
          });
        }
      } catch (error) {
        const { message } = getErrorObject(error);
        errorToast({ content: message });
        this.setState({ verifying: false });
      }
    }
  };
  handleChange = (e) => {
    let errors = null;
    let name = e.target.name;
    let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    if (isNumeric(e.target.value)) {
      this.setState({ [e.target.name]: e.target.value, errors: errors }, () => {
        if (this.state[name] !== "") {
          let data = {
            [name]: value,
          };
          const errors = validateOTP(data);
          if (!errors.isValid) {
            this.setState({ errors: errors.errors });
          }
        }
      });
    } else {
      if (value === "") {
        this.setState({ [e.target.name]: "" });
      } else {
        this.setState({ [e.target.name]: this.state.code });
      }
    }
  };
  resendOTP = async () => {
    // let data = {
    //   code: this.state.code,
    // };
    // const errors = validateOTP(data);

    // if (!errors.isValid) {
    //   this.setState({ errors: errors.errors, submitting: false });
    // } else {
    try {
      //   if (!user.password_expired) {
      await this.setState({ sending: true });
      let phone = JSON.parse(localStorage.getItem("otp-phone"));
      let state = JSON.parse(localStorage.getItem("state"));
      let user =
        state && state.localStore && state.localStore.user
          ? state.localStore.user
          : null;
      if (phone) {
        const customJWToken = generateTokenFromJWT()
        let otpResponse = await sendOTPApi(
          {
            phone: phone,
            role: user && user.role ? user.role : null,
          },
          customJWToken,
          this.props.user.api_token
        );
        await this.setState({ sending: true }, async () => {
          if (otpResponse.data.message) {
            successToast({ content: otpResponse.data.message });
            await this.setState({ sending: false });
            // await this.getOTP()
          }
        });
      }
    } catch (error) {
      const { message } = getErrorObject(error);
      errorToast({ content: message });
      this.setState({ sending: false });
    }
    // }
  };
  render() {
    // let phone = JSON.parse(localStorage.getItem("otp-phone"));
    // let {otp_email} = this.props;
    let { isAccepted } = this.state;
    return (
      <div className="container-fluid">
        <div className="otp-content-wrapper">
          {/* <div className="header-content">
              <h3>Verify Account</h3>
            </div> */}
          <div className="body-content">
            <div className="body-verify-content">
              <div className="body-verify-img">
                <img alt="" src={mobileicon} />
              </div>
              <div className="body-verify-text">
                <p>
                  Please enter the verification code we sent to your registered
                  email or mobile number
                </p>
                {/* <p>Registered Email Id</p>
                <p>{otp_email && <b>{otp_email}</b>}</p> */}
              </div>
            </div>
            <Form onSubmit={this.verifyUser}>
              <div className="otp-form">
                <FormGroup>
                  <div className="otp-text-input-form">
                    <label
                      htmlFor="sel1"
                      className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                    >
                      Enter Verification Code
                    </label>
                    <input
                      className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                      name="code"
                      placeholder="Enter verification code"
                      // style={{
                      //   width: "50%",
                      //   marginLeft: "23%",
                      // }}
                      value={this.state.code}
                      style={{ marginBottom: 2 }}
                      onChange={this.handleChange}
                      autoFocus
                    />
                    {this.state.errors && (
                      <span className="text-danger">
                        {this.state.errors.code}
                      </span>
                    )}
                    <p className="rcvd_code font-12">
                      Verification code not received?{" "}
                      {this.state.time < 0 ? (
                        <a onClick={this._startTimer}>
                          <b> Resend Code</b>
                        </a>
                      ) : (
                        <b>
                          Resend code in{" "}
                          {this.state.time < 10
                            ? `00:0${this.state.time}`
                            : `00:${this.state.time}`}
                        </b>
                      )}
                    </p>
                    <p className="rcvd_code_checkbox">
                      <SingleCheckBox
                        label="Please confirm you are upto date with all safety alerts"
                        onChange={this._handleCheckbox}
                        checked={isAccepted}
                        className="font-12"
                      />
                    </p>
                  </div>
                </FormGroup>
                <button
                  disabled={isEmpty(this.state.code) || isAccepted === false}
                  type="submit"
                  className="btn push-10-t weight-500 text-white btn-custom btn-blue height-big width-255px"
                >
                  {this.state.verifying ? "Logging In.." : "Continue"}
                  {this.state.verifying && (
                    <i className="fa fa-spinner fa-spin" />
                  )}
                </button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    session: state.session,
    user: state.localStore.user,
    otp_email: verifyObject(state, "localStore.otp_email", ""),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      { login, resetFormState, navigateToCustomerSelectionViaOTP },
      dispatch
    ),
  };
};
const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(OTPContainer);
export default withRouter(ConnectedComponent);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { actions as PaymentActions } from "reducers/appointmentPayments";
import AppointmentPaymentList from "../../components/Payments/AppointmentPaymentList";
import { Pagination } from "components/common";
import refresh from "assets/images/common/refresh.svg";
import { currencies, verifyObject } from "../../utilities/utils";

class PaymentsContainer extends Component {
  async componentDidMount() {
    this.props.PaymentActions.onRequest({});
  }
  render() {
    let { appointmentPayments } = this.props;
    let earnedMoney = verifyObject(
      appointmentPayments,
      "extraData.common.total",
      null
    );
    console.log("earnedMoney", earnedMoney);
    return (
      <Fragment>
        <div className="custom-col-10px padding-10-t">
          <div className="form-row push-20-b">
            <div className="col-md-8">
              <a
                // href="javascript:;"
                className="refresh_wrapper vertical-middle text-light-black margin-top-8 in-block"
                title="Refresh"
              >
                <img
                  src={refresh}
                  className="height-15"
                  alt="Refresh"
                  title="Refresh"
                ></img>{" "}
                <span className="push-2-l font-14 weight-400">Refresh</span>
              </a>
            </div>
            {earnedMoney ? (
              <div
                style={{ textAlign: "right" }}
                className="col-md-4 font-14 weight-400 margin-top-8"
              >
                Earned Money :{" "}
                {earnedMoney
                  ? `${currencies.pound.symbol}${
                      earnedMoney && earnedMoney.toFixed(2, 10)
                    }`
                  : "--"}
              </div>
            ) : (
              <div
                style={{ textAlign: "right" }}
                className="col-md-4 font-14 weight-400 margin-top-8"
              >
                Earned Money : 0
              </div>
            )}
          </div>

          <div className="payment_wrapper">
            <AppointmentPaymentList
              _onReuestList={this.props.PaymentActions.onRequest}
              dataSource={this.props.appointmentPayments}
            />
            <div className="col-md-12">
              <Pagination
                data={this.props.appointmentPayments}
                onPageChange={this.props.PaymentActions.onPageChange}
              />
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointmentPayments: state.appointmentPayments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    PaymentActions: bindActionCreators(PaymentActions, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentsContainer);

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {login, resetFormState, navigateToResetPassword} from "reducers/session";
import {
  successToast,
  getErrorObject,
  errorToast,
  generateTokenFromJWT,
} from "../../utilities/utils";
import {Upload, Button, Input, Spin, Select} from "antd";

import {signUpDoctor} from "../../services/session";
import {withRouter} from "react-router-dom";
import "react-phone-input-2/lib/style.css";
import SingleCheckBox from "../../components/common/controls/SingleCheckBox";
import {ValidateSignupInput} from "./ValidateSignupForm";
import ValidationMessage from "../../components/ValidationMessage/ValidationMessage";
import {EyeInvisibleOutlined, EyeTwoTone} from "@ant-design/icons";
import {
  // ALPHANUMERIC,
  devCorporateArray,
  prodCorporateArray,
  // devCorporateArray,
  // prodCorporateArray,
  // stageCorporateArray,
  stageCorporateArray,
} from "../../constants/common";
import {appRoutesConst} from "../../app/navigation";
import {Popover} from "antd";
import {getpostalCodesData} from "../../services/patients";
import {debounce, isEmpty} from "lodash";
import ImgCrop from "antd-img-crop";
import store from "../../app/store";
import {Alert} from "antd";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
import PhoneInput, {
  // formatPhoneNumber,
  // formatPhoneNumberIntl,
  isValidPhoneNumber,
  // isPossiblePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

// import moment from "moment";
// import { v4 as uuidv4 } from "uuid";
// import { customAlphabet } from "nanoid";
// import jwt from "jsonwebtoken";

// const { Option } = Select;

const password_info = (
  <div className="forgot_wrapper">
    <p className="m-0 font-13 weight-400">
      Password should be atleast 8 characters including a number,an
      uppercase,one special character and lowercase letter
    </p>
  </div>
);

const mobilenumber = (
  <div className="forgot_wrapper">
    <p className="m-0 font-13 weight-400">Mobile no should be of 10 digit</p>
  </div>
);

class SignupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: null,
      first_name: "",
      last_name: "",
      password: "",
      confirm_password: "",
      email: "",
      phone: null,
      image: "",
      image_file_name: "",
      corporate_organization_id: null,
      role: null,
      line1: "",
      line2: "",
      line3: "",
      town: "",
      gender: null,
      tc_accepted: false,
      county: "",
      country: "",
      county_code: "",
      country_code: "+44",
      postCode: null,
      postcodesValues: [],
      loading: false,
      postcodeSearchValue: null,
      loadingPostCodes: false,
      professional_registration_number: "",
      new_phone: null,
      // deviceType: 'web',
      // playerID: '',
    };
    this.getPostalCodeDatatDebounced = debounce(this.getPostalCodes, 1000);
  }

  componentDidMount() {
    // let payload = { timestamp: "AddVantageDr-#{Date.today}", secret_code: SecureRandom.hex(10), exp: (10).minutes.from_now.to_i, code: SecureRandom.uuid } }
    // console.log("tokennn", generateTokenFromJWT());

    let corporateID = devCorporateArray[2].id;

    if (process.env.REACT_APP_ENV === "stage") {
      corporateID = stageCorporateArray[2].id;
    }
    if (process.env.REACT_APP_ENV === "prod") {
      corporateID = prodCorporateArray[2].id;
    }
    this.setState({
      corporate_organization_id: corporateID,
    });
  }

  _handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      password: this.state.password,
      confirm_password: this.state.confirm_password,
      email: this.state.email,
      phone: this.state.phone ? this.state.phone : "",
      image: this.state.image,

      gender: this.state.gender,
      tc_accepted: this.state.tc_accepted,
      role: this.state.role,
      // county: this.state.county,
      // country: this.state.country,
      professional_registration_number:
        this.state.professional_registration_number,
    };
    let organizationObj = {};
    if (this.state.role === "clinical_pharmacist") {
      organizationObj = {
        line1: this.state.line1 ? this.state.line1 : "",
        line2: this.state.line2 ? this.state.line2 : "",
        line3: this.state.line3 ? this.state.line3 : "",
        town: this.state.town ? this.state.town : "",
        county: this.state.county ? this.state.county : "",
        country: this.state.country ? this.state.country : "",
        organization_name: this.state.organization_name
          ? this.state.organization_name
          : "",
        organization_email: this.state.organization_email
          ? this.state.organization_email
          : "",
        organization_phone: this.state.organization_phone
          ? this.state.organization_phone
          : "",
        pharmacy_registration_number: this.state.pharmacy_registration_number
          ? this.state.pharmacy_registration_number
          : "",
      };
    }
    data = {
      ...data,
      ...organizationObj,
    };
    const errors = ValidateSignupInput(data);

    if (!errors.isValid) {
      this.setState({errors: errors.errors, submitting: false});
    } else {
      let formData = new FormData();
      formData.set("user[first_name]", this.state.first_name);
      formData.set("user[last_name]", this.state.last_name);
      formData.set("user[email]", this.state.email);
      formData.set("user[phone]", `${this.state.phone}`);
      formData.set("user[gender]", this.state.gender);
      this.state.image && formData.set("user[image]", this.state.image);
      this.state.image &&
        this.state.image_file_name &&
        formData.set("user[image_file_name]", this.state.image_file_name);
      formData.set("user[password]", this.state.password);
      formData.set("user[role]", this.state.role);
      formData.set("user[tc_accepted]", this.state.tc_accepted);

      if (this.state.role === "clinical_pharmacist") {
        formData.set(
          "organization[name]",
          this.state.organization_name ? this.state.organization_name : ""
        );

        formData.set(
          "organization[email]",
          this.state.organization_email ? this.state.organization_email : ""
        );

        formData.set(
          "organization[phone]",
          this.state.organization_phone ? this.state.organization_phone : ""
        );

        formData.set(
          "organization[unique_number]",
          this.state.pharmacy_registration_number
            ? this.state.pharmacy_registration_number
            : ""
        );
        formData.set(
          "organization[line1]",
          this.state.line1 ? this.state.line1 : ""
        );
        formData.set(
          "organization[line2]",
          this.state.line2 ? this.state.line2 : ""
        );
        formData.set(
          "organization[line3]",
          this.state.line3 ? this.state.line3 : ""
        );
        formData.set(
          "organization[town]",
          this.state.town ? this.state.town : ""
        );
        formData.set(
          "organization[pincode]",
          this.state.postCode ? this.state.postCode : ""
        );
        formData.set(
          "organization[country]",
          this.state.country ? this.state.country : ""
        );
        formData.set(
          "organization[county]",
          this.state.county ? this.state.county : ""
        );
      }
      formData.set(
        "profile[professional_registration_number]",
        this.state.professional_registration_number
      );

      formData.set(
        "device_detail[corporate_organization_id]",
        this.state.corporate_organization_id
      );
      const customJWToken = generateTokenFromJWT();
      try {
        await this.setState({loadingSubmit: true});
        let response = await signUpDoctor(formData, customJWToken);
        if (response.data.message) {
          const {user} = response.data.data;
          localStorage.setItem("otp-phone", JSON.stringify(user.phone));
          console.log("SIgned up success", user);
          await store.dispatch({
            type: "SIGNUP_SUCCESS",
            payload: response.data.data,
          });
          store.dispatch({type: "OTP_EMAIL", payload: this.state.email});

          successToast({content: response.data.message});
          this.props.history.push(appRoutesConst.verfifyotp);
          await this.setState({loadingSubmit: false});
        }
      } catch (error) {
        const {message} = getErrorObject(error);
        errorToast({content: message});
        this.setState({loadingSubmit: false});
      }
    }
  };

  handleProfileImageUpload = ({fileList, file}) => {
    if (fileList && fileList[0]) {
      this.setState({
        image: fileList[0].originFileObj,
        image_file_name: file.name,
      });
    }
  };

  getPostalCodes = async (value) => {
    if (value.length > 5) {
      try {
        await this.setState({loadingPostCodes: true});
        let response = await getpostalCodesData({search_code: value});
        await this.setState({
          postcodesValues: response.data.result,
          loading: false,
        });
      } catch (error) {
        await this.setState({loadingPostCodes: false});
        errorToast({content: "No postal code found"});
      }
    }
  };

  handleCustomPhone = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        let data = {
          phone: this.state.phone ? this.state.phone : "",
        };
        const errors = ValidateSignupInput(data);

        if (!errors.isValid) {
          this.setState({
            errors: {...errors.errors},
          });
        } else {
          let newErrors = errors.errors;
          delete newErrors.phone;
          this.setState({
            errors: {...newErrors},
          });
        }
      }
    );
  };

  handlePhone = (val, organization_phone) => {
    if (val) {
      let errors = null;
      let name = organization_phone ? "organization_phone" : "phone";
      let value = val;
      if (this.state.errors) {
        errors = Object.assign("", this.state.errors);
        delete errors["phone"];
        delete errors["organization_phone"];
      }
      this.setState(
        {
          [`${organization_phone ? "organization_phone" : "phone"}`]: value,
          errors: errors,
        },
        () => {
          if (this.state[name] !== "") {
            let data = {
              [name]: value,
            };
            const errors = ValidateSignupInput(data);
            if (!errors.isValid) {
              if (errors["phone"] !== "") {
                this.setState({errors: errors.errors});
              } else {
                // this.setState({  : errors.errors });
                delete errors["phone"];
                this.setState({errors: errors});
              }
            }
          }
        }
      );
    }
  };

  handleDropDownChange = (v, k) => {
    let errors = null;

    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[k];
    }
    this.setState({[k]: v, errors: errors}, () => {
      if (this.state.role !== "clinical_pharmacist") {
        this.setState({
          organization_email: "",
          organization_name: "",
          organization_phone: "",
          line1: "",
          postCode: "",
          line2: "",
          line3: "",
          town: "",
          pharmacy_registration_number: "",
        });
      }
      if (this.state[k] !== "") {
        let data = {
          [k]: v,
        };
        const errors = ValidateSignupInput(data);
        if (!errors.isValid) {
          this.setState({errors: errors.errors});
        }
      }
    });
  };
  _onPreview = async (file) => {
    console.log("file", file);
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow.document.write(image.outerHTML);
  };

  handleChange = (e) => {
    let errors = null;
    let name = e.target.name;
    let value = e.target.value;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
    }
    this.setState({[e.target.name]: e.target.value, errors: errors}, () => {
      if (this.state[name] !== "") {
        let data = {
          [name]: value,
        };
        const errors = ValidateSignupInput(data);
        if (!errors.isValid) {
          this.setState({errors: errors.errors});
        }
      }
    });
  };

  _handleCheckbox = (e) => {
    let {checked} = e.target;
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors["tc_accepted"];
    }
    this.setState({tc_accepted: checked, errors: errors});
  };
  renderClinicalPharmacistFields = () => {
    return (
      <Fragment>
        <div className="form-group margin-bottom-25-imp">
          <div className="form-row">
            <p>
              <Alert
                message="If are you an Owner / Partner? Please enter your pharmacy details to
              be listed on the app / website"
                type="info"
                showIcon
                className="signup-alert-box"
              />
            </p>

            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Pharmacy Name
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="organization_name"
                value={this.state.organization_name}
                onChange={this.handleChange}
                placeholder="Pharmacy Name"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.organization_name}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Pharmacy Premesis Number
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="pharmacy_registration_number"
                value={this.state.pharmacy_registration_number}
                onChange={this.handleChange}
                placeholder="GPhC Premesis Number"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.pharmacy_registration_number}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="form-group margin-bottom-25-imp SignupContainer_warpper">
          <div className="form-row">
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-dark-gray"
              >
                Search Postcode e.g. (BL1 1ET)
              </label>
              <div className="relative">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  className="searchicon"
                >
                  <g fill="none" fill-rule="evenodd">
                    <g fill="#808396" fill-rule="nonzero">
                      <g>
                        <path
                          d="M26.006 42.805h-.633l-.224-.216c.785-.913 1.257-2.097 1.257-3.386 0-2.874-2.33-5.203-5.203-5.203C18.329 34 16 36.33 16 39.203c0 2.874 2.33 5.203 5.203 5.203 1.289 0 2.473-.472 3.386-1.257l.216.224v.633L28.807 48 30 46.807l-3.994-4.002zm-4.803 0c-1.993 0-3.602-1.609-3.602-3.602 0-1.993 1.609-3.602 3.602-3.602 1.993 0 3.602 1.609 3.602 3.602 0 1.993-1.609 3.602-3.602 3.602z"
                          transform="translate(-231 -675) translate(215 641)"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
                <Select
                  showSearch
                  placeholder="Search postcode"
                  className="margin-0 postcode-input form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                  loading={this.state.loading}
                  suffixIcon={this.state.loading && <Spin size="small" />}
                  value={this.state.postcodeSearchValue}
                  onChange={(value) => {
                    this.setState({postcodeSearchValue: value});
                    let foundValue = this.state.postcodesValues.find(
                      (d) => d.udprn === value
                    );

                    if (foundValue) {
                      this.setState({
                        postCode: foundValue.postcode,
                        line1: foundValue.line_1,
                        line2: foundValue.line_2,
                        line3: foundValue.line_3,
                        town: foundValue.post_town,
                        county: foundValue.county,
                        country: foundValue.country,
                      });
                    }
                  }}
                  onSearch={(value) =>
                    this.setState({search: value}, () => {
                      if (!isEmpty(value) && value.trim().length >= 5) {
                        this.getPostalCodeDatatDebounced(value);
                      }
                    })
                  }
                  filterOption={false}
                >
                  {this.state.postcodesValues.length > 0 &&
                    this.state.postcodesValues.map((record, index) => {
                      return (
                        <Select.Option
                          value={record.udprn}
                        >{`${record.line_1}  ${record.post_town} ${record.postcode}`}</Select.Option>
                      );
                    })}
                </Select>
              </div>
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.postCode}
                </span>
              )}
            </div>
          </div>
        </div>

        <div className="form-group margin-bottom-25-imp">
          <div className="form-row">
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Address Line 1
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="line1"
                value={this.state.line1}
                onChange={this.handleChange}
                placeholder="Address line 1"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.line1}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Address Line 2
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="line2"
                value={this.state.line2}
                onChange={this.handleChange}
                placeholder="Address line 2"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.line2}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="form-group margin-bottom-25-imp">
          <div className="form-row">
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Address Line 3
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="line3"
                value={this.state.line3}
                onChange={this.handleChange}
                placeholder="Address line 3"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.line3}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Town/City
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="town"
                value={this.state.town}
                onChange={this.handleChange}
                placeholder="Town/City"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.town}
                </span>
              )}
            </div>

            {/* <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Gender
              </label>
              <Select
                placeholder="Select Gender"
                className="custom-arrow form-control input-sm-28 input-bg-f9f9f9 font-11 p-0"
                name="gender"
                value={this.state.gender}
                onChange={(v) => this.handleDropDownChange(v, "gender")}
              >
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
                <Select.Option value="transgender">Transgender</Select.Option>
                <Select.Option value="gender_neutral">
                  Gender Neutral
                </Select.Option>
                <Select.Option value="gender_fluid">Gender Fluid</Select.Option>
                <Select.Option value="prefer_not_to_say">
                  Prefer not to say
                </Select.Option>
                <Select.Option value="other">Other</Select.Option>
              </Select>
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.gender}
                </span>
              )}
            </div> */}
          </div>
        </div>

        <div className="form-group margin-bottom-25-imp">
          <div className="form-row">
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                County
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="county"
                value={this.state.county}
                onChange={this.handleChange}
                placeholder="County"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.county}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Country
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="country"
                value={this.state.country}
                onChange={this.handleChange}
                placeholder="Country"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.country}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="form-group margin-bottom-25-imp">
          <div className="form-row">
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Pharmacy Phone
                {/* <Popover
                  content={mobilenumber}
                  placement="right"
                  className="infowrapper"
                >
                  <Button className="noborder no-background p-0 line-1-imp height-auto-imp push-8-l">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g fill="#1445b7" fill-rule="nonzero">
                          <g>
                            <g>
                              <path
                                d="M6.5 3c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM6 9.5c-.207 0-.375-.168-.375-.375V5.25h-.5c-.207 0-.375-.168-.375-.375s.168-.375.375-.375H6c.207 0 .375.168.375.375v4.25c0 .207-.168.375-.375.375z"
                                transform="translate(-329 -442) translate(215 442) translate(114)"
                              />
                              <path
                                d="M6 12c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zM6 .75C3.105.75.75 3.105.75 6S3.105 11.25 6 11.25 11.25 8.895 11.25 6 8.895.75 6 .75z"
                                transform="translate(-329 -442) translate(215 442) translate(114)"
                              />
                              <path
                                d="M7.125 9.5h-2.25c-.207 0-.375-.168-.375-.375s.168-.375.375-.375h2.25c.207 0 .375.168.375.375s-.168.375-.375.375z"
                                transform="translate(-329 -442) translate(215 442) translate(114)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </Button>
                </Popover> */}
              </label>

              <div className="">
                <PhoneInput
                  placeholder="Landline Number"
                  value={this.state.organization_phone}
                  onChange={(value) => {
                    this.handlePhone(value, true);
                  }}
                  limitMaxLength={14}
                  defaultCountry="GB"
                  country="GB"
                  countries={["GB"]}
                  countryCallingCodeEditable={false}
                  international={false}
                  addInternationalOption={false}
                  labels={{GB: "United KingDom  +44"}}
                  internationalIcon={null}
                  error={
                    this.state.organization_phone
                      ? isValidPhoneNumber(this.state.organization_phone)
                        ? undefined
                        : "Invalid phone number"
                      : "Phone number required"
                  }
                  numberInputProps={{
                    className:
                      "d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0",
                  }}
                />
              </div>

              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.organization_phone}
                </span>
              )}
            </div>
            <div className="col-md-6">
              <label
                htmlFor="sel1"
                className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
              >
                Pharmacy Email
              </label>
              <Input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                name="organization_email"
                value={this.state.organization_email}
                onChange={this.handleChange}
                placeholder="Pharmacy Email"
              />
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.organization_email}
                </span>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  render() {
    console.log("state-->", this.state);
    return (
      <div className="body-content">
        <form onSubmit={this._handleSubmit}>
          <div style={{maxWidth: 676}} className="add-pateint-form-container">
            <div className="form-group push-35-b">
              <div className="form-row">
                <div className="col-md-12 file-upload-control">
                  <img
                    width={64}
                    height={64}
                    src={
                      this.state.previewImage
                        ? this.state.previewImage
                        : require("../../assets/images/common/doctor_profile.svg")
                            .default
                    }
                    alt="Profile Pic"
                    className="border-radius-50 margin-right-12 object-fit-cover"
                  />
                  <ImgCrop
                    beforeCrop={(data) => {
                      if (!data.type.includes("image")) {
                        errorToast({
                          content: "Select an image, please.",
                        });
                      }
                      return data.type.includes("image");
                    }}
                    grid
                  >
                    <Upload
                      listType="text"
                      multiple={false}
                      name="image"
                      accept="image/*"
                      onPreview={this._onPreview}
                      showUploadList={false}
                      onChange={this.handleProfileImageUpload}
                      className="photo_pic"
                      beforeUpload={async (file) => {
                        const reader = new FileReader();
                        reader.onload = (e) => {
                          this.setState({
                            previewImage: e.target.result,
                          });
                        };
                        reader.readAsDataURL(file);
                        return false;
                      }}
                      action={"no"}
                    >
                      <Button className="no-border">Upload Photo</Button>
                    </Upload>
                  </ImgCrop>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.address_line_3}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    First Name
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="first_name"
                    value={this.state.first_name}
                    onChange={this.handleChange}
                    placeholder="Enter first name"
                    autoFocus
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.first_name}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Last Name
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="last_name"
                    value={this.state.last_name}
                    onChange={this.handleChange}
                    placeholder="Enter last name"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.last_name}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Email Address
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    placeholder="Enter email address"
                  />
                  {this.state.errors && (
                    <ValidationMessage>
                      {this.state.errors.email}
                    </ValidationMessage>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Mobile Number
                    <Popover
                      content={mobilenumber}
                      placement="right"
                      className="infowrapper"
                    >
                      <Button className="noborder no-background p-0 line-1-imp height-auto-imp push-8-l">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <g fill="#1445b7" fill-rule="nonzero">
                              <g>
                                <g>
                                  <path
                                    d="M6.5 3c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM6 9.5c-.207 0-.375-.168-.375-.375V5.25h-.5c-.207 0-.375-.168-.375-.375s.168-.375.375-.375H6c.207 0 .375.168.375.375v4.25c0 .207-.168.375-.375.375z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                  <path
                                    d="M6 12c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zM6 .75C3.105.75.75 3.105.75 6S3.105 11.25 6 11.25 11.25 8.895 11.25 6 8.895.75 6 .75z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                  <path
                                    d="M7.125 9.5h-2.25c-.207 0-.375-.168-.375-.375s.168-.375.375-.375h2.25c.207 0 .375.168.375.375s-.168.375-.375.375z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Button>
                    </Popover>
                  </label>

                  <div className="">
                    <PhoneInput
                      placeholder="Mobile Number"
                      value={this.state.phone}
                      onChange={(value) => {
                        this.handlePhone(value);
                      }}
                      limitMaxLength={14}
                      // countrySelectComponent={<span>+44</span>}
                      labels={{GB: "United KingDom  +44"}}
                      // countrySelectProps={
                      //   {
                      //     d:""
                      //   }
                      // }
                      defaultCountry="GB"
                      country="GB"
                      countries={["GB"]}
                      countryCallingCodeEditable={false}
                      international={false}
                      addInternationalOption={false}
                      internationalIcon={null}
                      error={
                        this.state.phone
                          ? isValidPhoneNumber(this.state.phone)
                            ? undefined
                            : "Invalid phone number"
                          : "Phone number required"
                      }
                      numberInputProps={{
                        className:
                          "d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0 font-12",
                      }}
                    />
                    {/* <PhoneInput
                      country={"gb"}
                      // onlyCountries={["us"]}
                      // preferredCountries={[""]}
                      inputClass="d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0"
                      countryCodeEditable={false}
                      onChange={(d) => {
                        console.log("phone", d);
                        // setFieldValue("phone", `+${d}`);
                        this.handlePhone(d);
                      }}
                      // placeholder="+1 (324) 242-3423"
                      // onFocus={() =>
                      //   setState({ ...state, isCountryEditable: false })
                      // }
                      specialLabel=""
                      // value={values?.phone}
                    /> */}
                    {/* <Select
                      className="border-none country-code-dropdown custom-arrow"
                      value={this.state.country_code}
                      name="country_code"
                      onChange={(val) =>
                        this.handleCustomPhone({
                          target: {name: "country_code", value: val},
                        })
                      }
                    >
                      <Option value="+44">+44</Option>
                      <Option value="+91">+91</Option>
                    </Select>
                    <div className="seperator"></div>
                    <input
                      type="text"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleCustomPhone}
                      placeholder="Enter Mobile Number"
                      className="border-none border-none w-100 no-background weight-400"
                    /> */}
                  </div>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.phone}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Password
                    <Popover
                      content={password_info}
                      placement="right"
                      className="infowrapper"
                    >
                      <Button className="noborder no-background p-0 line-1-imp height-auto-imp push-8-l">
                        <span className="cursor">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                          >
                            <g fill="none" fill-rule="evenodd">
                              <g fill="#1445b7" fill-rule="nonzero">
                                <g>
                                  <g>
                                    <path
                                      d="M6.5 3c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM6 9.5c-.207 0-.375-.168-.375-.375V5.25h-.5c-.207 0-.375-.168-.375-.375s.168-.375.375-.375H6c.207 0 .375.168.375.375v4.25c0 .207-.168.375-.375.375z"
                                      transform="translate(-329 -442) translate(215 442) translate(114)"
                                    />
                                    <path
                                      d="M6 12c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zM6 .75C3.105.75.75 3.105.75 6S3.105 11.25 6 11.25 11.25 8.895 11.25 6 8.895.75 6 .75z"
                                      transform="translate(-329 -442) translate(215 442) translate(114)"
                                    />
                                    <path
                                      d="M7.125 9.5h-2.25c-.207 0-.375-.168-.375-.375s.168-.375.375-.375h2.25c.207 0 .375.168.375.375s-.168.375-.375.375z"
                                      transform="translate(-329 -442) translate(215 442) translate(114)"
                                    />
                                  </g>
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                      </Button>
                    </Popover>
                  </label>
                  <Input.Password
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    type="password"
                    name="password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    placeholder="Enter password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.password}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Confirm Password
                  </label>
                  <Input.Password
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9 pwd-input"
                    type="password"
                    name="confirm_password"
                    value={this.state.confirm_password}
                    onChange={this.handleChange}
                    placeholder="Confirm Password"
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.confirm_password}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/* <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Mobile Number
                    <Popover
                      content={mobilenumber}
                      placement="right"
                      className="infowrapper"
                    >
                      <Button className="noborder no-background p-0 line-1-imp height-auto-imp push-8-l">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <g fill="#1445b7" fill-rule="nonzero">
                              <g>
                                <g>
                                  <path
                                    d="M6.5 3c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM6 9.5c-.207 0-.375-.168-.375-.375V5.25h-.5c-.207 0-.375-.168-.375-.375s.168-.375.375-.375H6c.207 0 .375.168.375.375v4.25c0 .207-.168.375-.375.375z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                  <path
                                    d="M6 12c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zM6 .75C3.105.75.75 3.105.75 6S3.105 11.25 6 11.25 11.25 8.895 11.25 6 8.895.75 6 .75z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                  <path
                                    d="M7.125 9.5h-2.25c-.207 0-.375-.168-.375-.375s.168-.375.375-.375h2.25c.207 0 .375.168.375.375s-.168.375-.375.375z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Button>
                    </Popover>
                  </label> */}
            {/* <PhoneInput
										country={'gb'}
										onlyCountries={['gb', 'in']}
										placeholder='Enter mobile number'
										value={this.state.phone}
										countryCodeEditable={false}
										onChange={this.handlePhone}
										alwaysDefaultMask={false}
										masks={{ gb: '..........', in: '..........' }}
										// className="phone_number danger-border"
										inputclassName={`phone-drop-down-input ${
											this.state.errors &&
											this.state.errors.phone &&
											'danger-border'
										}`}
										disableCountryCode={true}
										countryCodeEditable={true}
									/> */}
            {/* <div className="d-flex custom-phone-input align-items-center form-control input-sm-28 input-bg-f9f9f9 weight-400 margin-0">
                    <Select
                      className="border-none country-code-dropdown custom-arrow"
                      value={this.state.country_code}
                      name="country_code"
                      onChange={(val) =>
                        this.handleCustomPhone({
                          target: {name: "country_code", value: val},
                        })
                      }
                    >
                      <Option value="+44">+44</Option>
                      <Option value="+91">+91</Option>
                    </Select>
                    <div className="seperator"></div>
                    <input
                      type="text"
                      name="phone"
                      value={this.state.phone}
                      onChange={this.handleCustomPhone}
                      placeholder="Enter Mobile Number"
                      className="border-none border-none w-100 no-background weight-400"
                    />
                  </div>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.phone}
                    </span>
                  )}
                </div>
              </div>
            </div> */}

            {/* <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Postcode
                  </label>

                  <div className="relative">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      className="searchicon"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g fill="#808396" fill-rule="nonzero">
                          <g>
                            <path
                              d="M26.006 42.805h-.633l-.224-.216c.785-.913 1.257-2.097 1.257-3.386 0-2.874-2.33-5.203-5.203-5.203C18.329 34 16 36.33 16 39.203c0 2.874 2.33 5.203 5.203 5.203 1.289 0 2.473-.472 3.386-1.257l.216.224v.633L28.807 48 30 46.807l-3.994-4.002zm-4.803 0c-1.993 0-3.602-1.609-3.602-3.602 0-1.993 1.609-3.602 3.602-3.602 1.993 0 3.602 1.609 3.602 3.602 0 1.993-1.609 3.602-3.602 3.602z"
                              transform="translate(-231 -675) translate(215 641)"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                    <Select
                      showSearch
                      placeholder="Search postcode"
                      className="margin-0 postcode-input form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                      loading={this.state.loading}
                      suffixIcon={this.state.loading && <Spin size="small" />}
                      value={this.state.postcodeSearchValue}
                      onChange={(value) => {
                        this.setState({postcodeSearchValue: value});
                        let foundValue = this.state.postcodesValues.find(
                          (d) => d.udprn === value
                        );

                        if (foundValue) {
                          this.setState({
                            postCode: foundValue.postcode,
                            line1: foundValue.line_1,
                            line2: foundValue.line_2,
                            line3: foundValue.line_3,
                            town: foundValue.post_town,
                            county: foundValue.county,
                            country: foundValue.country,
                          });
                        }
                      }}
                      onSearch={(value) =>
                        this.setState({search: value}, () => {
                          this.getPostalCodeDatatDebounced(value);
                        })
                      }
                      filterOption={false}
                    >
                      {this.state.postcodesValues.length > 0 &&
                        this.state.postcodesValues.map((record, index) => {
                          return (
                            <Select.Option
                              value={record.udprn}
                            >{`${record.line_1}  ${record.postal_county} ${record.postcode}`}</Select.Option>
                          );
                        })}
                    </Select>
                  </div>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.postCode}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Address Line 1
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="line1"
                    value={this.state.line1}
                    onChange={this.handleChange}
                    placeholder="Address line 1"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.line1}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Address Line 2
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="line2"
                    value={this.state.line2}
                    onChange={this.handleChange}
                    placeholder="Address line 2"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.line2}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Address Line 3
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="line3"
                    value={this.state.line3}
                    onChange={this.handleChange}
                    placeholder="Address line 3"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.line3}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Town/City
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="town"
                    value={this.state.town}
                    onChange={this.handleChange}
                    placeholder="Town/City"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.town}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    County
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="county"
                    value={this.state.county}
                    onChange={this.handleChange}
                    placeholder="County"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.county}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Country
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="country"
                    value={this.state.country}
                    onChange={this.handleChange}
                    placeholder="Country"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.country}
                    </span>
                  )}
                </div>
              </div>
            </div> */}
            <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6 genderselect">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Gender
                  </label>
                  <Select
                    placeholder="Select Gender"
                    className="custom-arrow form-control input-sm-28 input-bg-f9f9f9 font-12 p-0"
                    name="gender"
                    value={this.state.gender}
                    onChange={(v) => this.handleDropDownChange(v, "gender")}
                  >
                    <Select.Option value="male">Male</Select.Option>
                    <Select.Option value="female">Female</Select.Option>
                    <Select.Option value="transgender">
                      Transgender
                    </Select.Option>
                    <Select.Option value="gender_neutral">
                      Gender Neutral
                    </Select.Option>
                    <Select.Option value="gender_fluid">
                      Gender Fluid
                    </Select.Option>
                    <Select.Option value="prefer_not_to_say">
                      Prefer not to say
                    </Select.Option>
                    <Select.Option value="other">Other</Select.Option>
                  </Select>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.gender}
                    </span>
                  )}
                </div>
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Professional Registration Number
                  </label>
                  <Input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    name="professional_registration_number"
                    value={this.state.professional_registration_number}
                    onChange={this.handleChange}
                    placeholder="GPhC number"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.professional_registration_number}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6 roleselect">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Role
                  </label>
                  <Select
                    placeholder="Select Role"
                    className="custom-arrow form-control input-sm-28 input-bg-f9f9f9 font-12"
                    name="role"
                    value={this.state.role}
                    onChange={(v) => this.handleDropDownChange(v, "role")}
                  >
                    <Select.Option value="doctor">Doctor</Select.Option>
                    <Select.Option value="clinical_pharmacist">
                      Clinical Pharmacist
                    </Select.Option>
                  </Select>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.role}
                    </span>
                  )}
                </div>
              </div>
            </div>
            {/* new flow changes start */}
            {this.state.role === "clinical_pharmacist" &&
              this.renderClinicalPharmacistFields()}

            {/* new flow changes end */}

            {/* <div className="form-group margin-bottom-25-imp">
              <div className="form-row">
                <div className="col-md-6">
                  <label
                    htmlFor="sel1"
                    className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray"
                  >
                    Role
                  </label>
                  <Select
                    placeholder="Select Role"
                    className="custom-arrow form-control input-sm-28 input-bg-f9f9f9 font-11"
                    name="role"
                    value={this.state.role}
                    onChange={(v) => this.handleDropDownChange(v, "role")}
                  >
                    <Select.Option value="doctor">Doctor</Select.Option>
                    <Select.Option value="nurse">Nurse</Select.Option>
                    <Select.Option value="health_care_support_worker">
                      HCSW
                    </Select.Option>
                    <Select.Option value="customer_support_executive">
                      Customer Support Executive
                    </Select.Option>
                  </Select>
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.role}
                    </span>
                  )}
                </div>
              </div>
            </div> */}

            {/* <div className='form-group margin-bottom-25-imp'>
							<div className='row'>
								<div className='col-md-6'>
									<label
										htmlFor='sel1'
										className='font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray'
									>
										Select Corporate
									</label>
									<Select
										placeholder='Select Corporate'
										onChange={(v) =>
											this.handleDropDownChange(
												v,
												'corporate_organization_id'
											)
										}
										className={`custom-arrow form-control height-36 input-bg-f9f9f9 ${
											this.state.errors &&
											this.state.errors.corporate_organization_id &&
											'danger-border'
										}`}
										name={'corporate_organization_id'}
										value={this.state.corporate_organization_id}
									>

										{corporateArray.map((c) => {
											return (
												<Select.Option value={c.id}>
													{c.corporate_name}
												</Select.Option>
											)
										})}
									</Select>
									{this.state.errors && (
										<span className='validate-danger'>
											{this.state.errors.corporate_organization_id}
										</span>
									)}
								</div>
							</div>
						</div> */}

            <p className="rcvd_code_checkbox font-12 weight-400 margin-bottom-6 checkcondition d-flex align-items-center">
              <SingleCheckBox
                label="I confirm that I have read the allDayDr"
                onChange={this._handleCheckbox}
                checked={this.state.tc_accepted}
              />
              <a
                href="https://www.healthya.co.uk/terms_and_conditions"
                target="__blank"
                className="font-12 push-n-5-l text-blue margin-bottom-0 weight-500 line-1 in-block push-5-t"
              >
                Terms & Conditions
              </a>
              <p className="font-12 push-n-5-l margin-bottom-0 weight-500 line-1 in-block push-5-t ml-1">
                and
              </p>
              <a
                href="https://www.healthya.co.uk/privacy-policy"
                target="__blank"
                className="font-12 push-n-5-l text-blue margin-bottom-0 weight-500 line-1 in-block push-5-t ml-1"
              >
                Privacy policy.
              </a>
            </p>
            <p className="margin-bottom-25">
              {" "}
              {this.state.errors && (
                <span className="validate-danger">
                  {this.state.errors.tc_accepted}
                </span>
              )}
            </p>

            <button
              onClick={this._handleSubmit}
              type="submit"
              className="btn weight-500 text-white btn-custom btn-blue height-big width-255px "
              disabled={this.state.loadingSubmit}
            >
              {this.state.loadingSubmit ? (
                <span>
                  <Spin /> Signing Up...
                </span>
              ) : (
                "Sign Up"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    session: state.session,
    user: state.localStore.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(
      {login, resetFormState, navigateToResetPassword},
      dispatch
    ),
  };
};
const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(SignupContainer);
export default withRouter(ConnectedComponent);

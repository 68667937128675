import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as refferalNotesActions} from "../../../reducers/refferalNotes";
import {
  verifyObject,
  // modifiedDataObjectToArray,
  modifiedDataObjectToArrayByIDs,
} from "../../../utilities/utils";
import {Collapse, Input} from "antd";
import {isArray, debounce} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import {Pagination} from "../../../components/common";
// import search_blue from "assets/images/common/search-blue.svg";
import Highlighter from "react-highlight-words";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {DATE_FORMAT_24_HR} from "../../../constants/common";
import {SearchOutlined} from "@ant-design/icons";
// import Scrollbars from "react-custom-scrollbars";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
let {Panel} = Collapse;
class ReferralNotesContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      activeKey: this.props.fromConsultation ? null : ["1"],
    };
    ["_onRequestList", "_onPageChange", "_handleSearch"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.searchUpdate = debounce(this._handleSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, refferalNotesActions} = this.props;
    let {onSearch} = refferalNotesActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({
        search: value,
        patient_id: patientId,
        page: 1,
        document_type: "ReferralNote",
      });
    }
  }

  componentDidMount() {
    this._onRequestList();
  }

  _onRequestList() {
    let {patientId, refferalNotesActions} = this.props;
    let {onRequest} = refferalNotesActions;
    if (typeof onRequest === "function") {
      onRequest({
        patient_id: patientId,
        page: 1,
        document_type: "ReferralNote",
      });
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      refferalNotesActions: {onPageChange},
      referralNotes: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page: page,
        patient_id: patientId,
        search: search,
        document_type: "ReferralNote",
      });
    }
  }

  render() {
    let {referralNotes} = this.props;
    let {activeKey} = this.state;

    let {
      // data,
      isLoading,
      // pagination: {total},
    } = referralNotes;
    let referralNotesArray = modifiedDataObjectToArrayByIDs(referralNotes);

    return (
      <Fragment>
        <div className="push-20-b">
          <div className="form-row align-items-center push-10-b left-right-space">
            <div className="col-md-4">
              {/* <h6 className="font-14 text-light-black margin-0 weight-500">
                Referral Notes {isLoading ? "" : `(${total})`}{" "}
              </h6> */}
            </div>
            <div className="col-md-8 text-right">
              <div className="d-flex align-items-center justify-content-end stickfilter">
                {/* <div className="dragbtn">
                  <a>
                    <span className="uprrow">
                      <CaretUpOutlined />
                    </span>
                    <span className="downarrow">
                      <CaretDownOutlined />
                    </span>
                  </a>
                </div> */}
                <div className="filtercomponent">
                  <div className="relative searchbar_dropdown medical_Search">
                    <Input
                      onChange={(e) => {
                        let value = e.target.value;
                        this.searchUpdate(value);
                      }}
                      allowClear={true}
                      //   suffix={"close"}
                      size="large"
                      placeholder="Search Referral Note"
                      prefix={<SearchOutlined />}
                    />
                    {/* <img alt="" src={search_blue}></img> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          <div className="sectionsubscroll">
            <PerfectScrollbar>
              <div className="left-right-space">
                {!isLoading &&
                  referralNotesArray &&
                  isArray(referralNotesArray) &&
                  referralNotesArray.length === 0 && (
                    <NoFoundMessage message="No referral notes found." />
                  )}
                <Collapse
                  expandIconPosition="right"
                  onChange={(key) => {
                    this.setState({activeKey: key});
                  }}
                  activeKey={activeKey}
                  className="pre_quitionare"
                >
                  {!isLoading &&
                    referralNotesArray &&
                    isArray(referralNotesArray) &&
                    referralNotesArray.map((k, index) => {
                      return (
                        <Panel
                          key={[index + 1]}
                          header={
                            <div>
                              <p className="font-12 weight-400 line-1-43 margin-bottom-0 text-fff padding-5">
                                Given by{" "}
                                <Highlighter
                                  highlightClassName="search-highlighter"
                                  searchWords={[this.state.search]}
                                  autoEscape={true}
                                  textToHighlight={verifyObject(
                                    k,
                                    "referral_by.name",
                                    ""
                                  )}
                                />{" "}
                                On{" "}
                                {verifyObject(k, "created_at", null) !== null &&
                                  moment(k.created_at).format(
                                    DATE_FORMAT_24_HR
                                  )}
                              </p>
                            </div>
                          }
                        >
                          <div className="form-row ">
                            <div className="col-md-12">
                              <div className="form-row">
                                <div className="col-md-6">
                                  <div className="push-10-b push-5-t">
                                    <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                                      GP Info
                                    </p>
                                    <div className="border-box col-md-12 input-bg-f9f9f9">
                                      <div className="form-row">
                                        <div className="col-md-12">
                                          <p className="font-11 weight-400 text-dark-gray text-left push-5-b">
                                            Name :{" "}
                                            <Highlighter
                                              highlightClassName="search-highlighter"
                                              searchWords={[this.state.search]}
                                              autoEscape={true}
                                              textToHighlight={
                                                verifyObject(
                                                  k,
                                                  "referral_by.name",
                                                  null
                                                ) !== null
                                                  ? `Dr ${k.referral_by.name}`
                                                  : "-"
                                              }
                                            />
                                          </p>
                                          <p className="font-11 weight-400 text-dark-gray text-left margin-0">
                                            Professional Registration Number :{" "}
                                            <Highlighter
                                              highlightClassName="search-highlighter"
                                              searchWords={[this.state.search]}
                                              autoEscape={true}
                                              textToHighlight={
                                                verifyObject(
                                                  k,
                                                  "referral_by.professional_registration_number",
                                                  null
                                                ) !== null
                                                  ? `${k.referral_by.professional_registration_number}`
                                                  : `-`
                                              }
                                            />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="push-10-b">
                                    <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                                      Text explanation by GP
                                    </p>
                                    <div className="border-box col-md-12 input-bg-f9f9f9">
                                      <div className="form-row">
                                        <div className="col-md-12">
                                          <p className="font-11 weight-400 text-dark-gray text-left margin-0">
                                            <Highlighter
                                              highlightClassName="search-highlighter"
                                              searchWords={[this.state.search]}
                                              autoEscape={true}
                                              textToHighlight={
                                                verifyObject(
                                                  k,
                                                  "referral_reason",
                                                  ""
                                                ) !== ""
                                                  ? `${k.referral_reason}`
                                                  : "-"
                                              }
                                            />
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="push-10-b push-5-t">
                                    <p className="font-12 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                                      Referral Detail
                                    </p>
                                    <div className="border-box col-md-12 input-bg-f9f9f9">
                                      {k.specialty && k.specialty.name && (
                                        <p className="font-11 weight-400 text-dark-gray text-left  push-5-b">
                                          Speciality :{" "}
                                          <Highlighter
                                            highlightClassName="search-highlighter"
                                            searchWords={[this.state.search]}
                                            autoEscape={true}
                                            textToHighlight={
                                              verifyObject(
                                                k,
                                                "specialty.name",
                                                ""
                                              ) !== ""
                                                ? `${k.specialty.name}`
                                                : "-"
                                            }
                                          />
                                        </p>
                                      )}
                                      {k.specialty &&
                                        k.specialty.field_of_practice && (
                                          <p className="font-11 weight-400 text-dark-gray text-left push-5-b">
                                            Fields of speciality practice :{" "}
                                            <Highlighter
                                              highlightClassName="search-highlighter"
                                              searchWords={[this.state.search]}
                                              autoEscape={true}
                                              textToHighlight={
                                                verifyObject(
                                                  k,
                                                  "specialty.field_of_practice",
                                                  ""
                                                ) !== ""
                                                  ? `${k.specialty.field_of_practice}`
                                                  : "-"
                                              }
                                            />
                                          </p>
                                        )}
                                      <p className="font-11 weight-500 text-light-black text-left push-5-b">
                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={
                                            verifyObject(
                                              k,
                                              "surgery.practice_name",
                                              ""
                                            ) !== ""
                                              ? `${k.surgery.practice_name}`
                                              : "-"
                                          }
                                        />
                                      </p>
                                      <p className="font-11 weight-400 text-dark-gray text-left m-0">
                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={
                                            verifyObject(
                                              k,
                                              "surgery.address",
                                              null
                                            ) !== null
                                              ? `${k.surgery.address} ,`
                                              : ""
                                          }
                                        />
                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={
                                            verifyObject(
                                              k,
                                              "surgery.city",
                                              null
                                            ) !== null
                                              ? `${k.surgery.city} ,`
                                              : ""
                                          }
                                        />
                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={
                                            verifyObject(
                                              k,
                                              "surgery.county",
                                              null
                                            ) !== null
                                              ? `${k.surgery.county} ,`
                                              : ""
                                          }
                                        />
                                        <Highlighter
                                          highlightClassName="search-highlighter"
                                          searchWords={[this.state.search]}
                                          autoEscape={true}
                                          textToHighlight={
                                            verifyObject(
                                              k,
                                              "surgery.post_code",
                                              null
                                            ) !== null
                                              ? `${k.surgery.post_code} ,`
                                              : ""
                                          }
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Panel>
                      );
                    })}
                </Collapse>

                <div className="col-md-12">
                  {!isLoading && (
                    <Pagination
                      data={referralNotes}
                      onPageChange={this._onPageChange}
                    />
                  )}
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    referralNotes: verifyObject(state, "referralNotes", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    refferalNotesActions: bindActionCreators(refferalNotesActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReferralNotesContainer);

export default ConnectedComponent;

export const types = {
  SET_COMPONENT: "SET_COMPONENT",
  SET_COMPONENT_ID: "SET_COMPONENT_ID",
  SET_SIDEBAR_KEY: "SET_SIDEBAR_KEY",
  SET_DYNAMIC_INPUT: "SET_DYNAMIC_INPUT",
  IS_SCREENSHOT_TAKEN: "IS_SCREENSHOT_TAKEN",
  IS_SUBSCRIBER_CONNECTED: "IS_SUBSCRIBER_CONNECTED",
  IS_COLLAPSED_SIDEBAR: "IS_COLLAPSED_SIDEBAR",
  CALL_API_TIMER: "CALL_API_TIMER",
  ON_CONSULTATION_SUBMITTED_BY_PRESCRIBER:
    "ON_CONSULTATION_SUBMITTED_BY_PRESCRIBER",
  SHOW_PRESCRIPTION_MODAL_FROM_NOTES: "SHOW_PRESCRIPTION_MODAL_FROM_NOTES",
  SHOW_SUBMIT_FROM_NOTES: "SHOW_SUBMIT_FROM_NOTES",
  SET_DYNAMIC_HEALTH_INPUT: "SET_DYNAMIC_HEALTH_INPUT",
  SET_SYMPTOMP_FETCHED_LINKS: "SET_SYMPTOMP_FETCHED_LINKS",
  SET_SYMPTOMP_FETCHING: "SET_SYMPTOMP_FETCHING",
  IS_CONSULTATION_API_STARTED: "IS_CONSULTATION_API_STARTED",
  IS_APPT_FILTER_APPLIED: "IS_APPT_FILTER_APPLIED",
  SET_API_CANCEL_TOKEN: "SET_API_CANCEL_TOKEN",
  SET_ROTA_ADDED:"SET_ROTA_ADDED"
  
};

const initialState = {
  componentName: "PatientDetailPage",
  componentId: null,
  sidebar_key: null,
  dynamicInputs: null,
  dynamicHealthInputs: null,
  is_subscriberConnected: false,
  isCollapsed: false,
  user: null,
  timerSocket: null,
  ApiCallTimerMins: 0,
  isPrescriberSubmittedConsultation: false,
  show_prescriotion_modal_from_notes: null,
  show_submit_from_notes: false,
  symptompFetchedLinks: null,
  symtopmpLinkFecthing: false,
  is_consultation_api_started: false,
  isAppointmentFilterApplied: false,
  apiCancelToken: null,
  isRotaAdded: false,
};

export const dynamicTab = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_SIDEBAR_KEY:
      return { ...state, sidebar_key: action.payload };
    case types.SET_ROTA_ADDED:
        return { ...state, isRotaAdded: action.payload };
    case types.SET_API_CANCEL_TOKEN:
      return { ...state, apiCancelToken: action.payload };
    case types.IS_APPT_FILTER_APPLIED:
      return { ...state, isAppointmentFilterApplied: action.payload };
    case types.SHOW_PRESCRIPTION_MODAL_FROM_NOTES:
      return { ...state, show_prescriotion_modal_from_notes: action.payload };
    case types.SHOW_SUBMIT_FROM_NOTES:
      return { ...state, show_submit_from_notes: action.payload };
    case types.SET_DYNAMIC_INPUT:
      return { ...state, dynamicInputs: action.payload };
    case types.SET_DYNAMIC_HEALTH_INPUT:
      return { ...state, dynamicHealthInputs: action.payload };
    case types.IS_SUBSCRIBER_CONNECTED:
      return { ...state, is_subscriberConnected: action.payload };
    case types.IS_COLLAPSED_SIDEBAR:
      return { ...state, isCollapsed: action.payload };
    case types.CALL_API_TIMER: {
      return { ...state, ApiCallTimerMins: action.payload };
    }
    case types.SET_SYMPTOMP_FETCHED_LINKS:
      return { ...state, symptompFetchedLinks: action.payload };
    case types.SET_SYMPTOMP_FETCHING:
      return { ...state, symtopmpLinkFecthing: action.payload };
    case types.ON_CONSULTATION_SUBMITTED_BY_PRESCRIBER: {
      return { ...state, isPrescriberSubmittedConsultation: action.payload };
    }
    case types.IS_CONSULTATION_API_STARTED: {
      return { ...state, is_consultation_api_started: action.payload };
    }
    
    case "SET_TIMER_SOCKET":
      return { ...state, timerSocket: action.payload };
    case "UPDATE_USER_SUCCESS":
      return { ...state, user: action.payload };

    default:
      return state;
  }
};

export const onRequestSetComponent = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_COMPONENT, payload: data });
  };
};

export const onRequestSetComponentID = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_COMPONENT_ID, payload: data });
  };
};

export const onSetSidebarKey = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_SIDEBAR_KEY, payload: data });
  };
};

export const onSetDynamicInput = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_DYNAMIC_INPUT, payload: data });
  };
};
export const onSetDynamicHealthInput = (data) => {
  return (dispatch) => {
    dispatch({ type: types.SET_DYNAMIC_HEALTH_INPUT, payload: data });
  };
};
export const onSubscriberConnected = (data) => {
  return (dispatch) => {
    dispatch({ type: types.IS_SUBSCRIBER_CONNECTED, payload: data });
  };
};

export const onCollapseExpandSidebar = (data) => {
  return (dispatch) => {
    dispatch({ type: types.IS_COLLAPSED_SIDEBAR, payload: data });
  };
};

import React, {Component, Fragment} from "react";
// import { reduxForm } from "redux-form";
// import { renderTextField } from "components/form/renderField";
import {Form, FormGroup} from "reactstrap";
import imgEyeClosed from "assets/images/login/security-uncheck.svg";
import imgEyeOpen from "assets/images/login/security-check.svg";
// import info from "assets/images/login/ic-info.svg";
import {ForgotPasswordLink} from "app/navigation";
import {Popover, Button, Select, Checkbox, Spin} from "antd";
import validator from "validator";
import {passwordRegex} from "../../constants/common";
import store from "app/store";
import {fetchCorporatesFromEmail} from "services/session";
import {debounce, isEmpty} from "lodash";
import {
  errorToast,
  generateTokenFromJWT,
  getErrorObject,
} from "utilities/utils";
import CommonLoader from "components/common/CommonLoader";
import {Axios} from "axios";

const forgot_info = (
  <div className="forgot_wrapper">
    <p className="m-0 font-13 weight-400">Click here to reset your password</p>
  </div>
);

const email_info = (
  <div className="forgot_wrapper">
    <p className="m-0 font-13 weight-400">
      Please enter valid email address/phone number
    </p>
  </div>
);

// const validate = (values) => {
//   const errors = {};
//   if (!values.username) {
//     errors.username = "Required";
//   }
//   if (!values.password) {
//     errors.password = "Required";
//   }

//   return errors;
// };

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      passwordVisible: false,
      username: "",
      password: "",
      errors: {
        username: null,
        password: null,
      },
      selectedCorporate: null,
      terms_condition: false,
      privacy_policy: false,
    };
    [("handleChange", "validateAndSubmit", "validateInputs")].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
    this.passwordRef = React.createRef();
    this.corporateRef = React.createRef();
  }

  togglePasswordVisiblity = () => {
    this.setState((prevState) => {
      return {passwordVisible: !prevState.passwordVisible};
    });
  };
  validateInputs = (isFromSubmit) => {
    let {
      username,
      password,
      selectedCorporate,
      terms_condition,
      privacy_policy,
    } = this.state;
    let usernameError,
      corporateError,
      passwordError,
      privacy_policyError,
      terms_conditionError = null;
    if (!username) {
      usernameError = "Please enter an email address or phone number";
    } else {
      console.log(
        "validator.isMobilePhone(username)",
        validator.isMobilePhone(username)
      );
      if (validator.isEmail(username)) {
        if (username && username.length > 60) {
          usernameError = "Email should be less than  of 60 characters only";
        } else {
          usernameError = null;
        }
      } else if (validator.isMobilePhone(username)) {
        if (username && username.length > 14) {
          usernameError = "Please enter a valid phone number";
        } else {
          usernameError = null;
        }
      } else {
        usernameError = "Please enter a valid email or phone number";
      }
    }
    // else if (!validator.isEmail(username)) {
    //   if (username && username.length > 60) {
    //     usernameError = "Email should be less than  of 60 characters only";
    //   }
    //   usernameError = "Please enter a valid email";
    // } else if (username && username.length > 60) {
    //   usernameError = "Email should be less than  of 60 characters only";
    // } else {
    //   usernameError = null;
    // }

    if (!password) {
      passwordError = "Please enter a password";
    } else if (password) {
      let test = passwordRegex.test(password);
      if (!test) {
        passwordError =
          "Password should be atleast 8 characters including a number,an uppercase,one special character and lowercase letter";
      }
      if (password.length > 25) {
        passwordError = "Password should be max 25 characters";
      }
    } else {
      passwordError = null;
    }
    if (!selectedCorporate && isFromSubmit) {
      corporateError = "Please select healthcare provider";
    }
    if (isFromSubmit && selectedCorporate) {
      if (!terms_condition) {
        terms_conditionError = "Please select terms & condition";
      } else {
        terms_conditionError = null;
      }
      if (!privacy_policy) {
        privacy_policyError = "Please select privacy policy";
      } else {
        privacy_policyError = null;
      }
    }

    this.setState({
      errors: {
        username: usernameError,
        password: passwordError,
        selectedCorporate: corporateError,
        terms_condition: terms_conditionError,
        privacy_policy: privacy_policyError,
      },
    });
  };
  validateAndSubmit = async (e) => {
    e.preventDefault();
    await this.validateInputs(true); // is from submit to show errors on click signin
    let {errors, username, password, terms_condition, privacy_policy} =
      this.state;
    // console.log("errors", errors);
    if (
      (errors.username === null || errors.username === undefined) &&
      (errors.password === null || errors.password === undefined) &&
      (errors.selectedCorporate === null ||
        errors.selectedCorporate === undefined) &&
      terms_condition &&
      privacy_policy
    ) {
      this.props.onSubmit({
        username,
        password,
        corporate_organization_id: this.state.selectedCorporate,
      });
      store.dispatch({type: "OTP_EMAIL", payload: username});
      // localStorage.setItem("otp-email", JSON.stringify(username));
    }
  };
  handleChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
      },
      () => {
        if (e.target.name === "username") {
          this.setState(
            {
              selectedCorporate: null,
              corporateList: [],
            },
            () => {
              this.debounceUsernameValidation();
            }
          );
        }
        this.setState({
          errors: {
            ...this.state.errors,
            selectedCorporate: null,
            username: null,
          },
        });
      }
    );
  };
  debounceUsernameValidation = debounce(() => {
    const {username} = this.state;
    let usernameError = null;

    if (validator.isEmail(username)) {
      if (username.length > 60) {
        usernameError = "Email should be less than 60 characters.";
      }
    } else if (validator.isMobilePhone(username)) {
      if (username.length > 14) {
        usernameError = "Please enter a valid phone number.";
      }
    } else {
      usernameError = "Please enter a valid email or phone number.";
    }

    // Call handleEmailChange only if there are no errors
    if (usernameError === null) {
      this.handleEmailChange(false, true); // Pass true for not validating other fields
    }

    // Update errors in state
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        username: usernameError,
      },
    }));
  }, 500); // 500ms debounce delay
  handleEmailChange = async (isFromSearch, isFromDebounce = false) => {
    if (!isFromDebounce) await this.validateInputs(false); //passing false for hide initial error show
    let {errors} = this.state;
    if (errors.username === null) {
      if (!isEmpty(this.state.username)) {
        if (isFromSearch) {
          this.setState({fetchingCorporatesSearching: true});
        } else {
          this.setState({fetchingCorporates: true});
        }

        try {
          if (this.state.cancelToken && this.state.cancelToken.token) {
            this.state.cancelToken.cancel("Canceling previous request");
          }
          let cancelToken = Axios?.CancelToken?.source();
          const customJWToken = generateTokenFromJWT();
          let response = await fetchCorporatesFromEmail(
            {
              email: this.state.username,
              portal: "doctor",
            },
            customJWToken,
            cancelToken
          );
          if (response) {
            if (isFromSearch) {
              this.setState({fetchingCorporatesSearching: false});
            } else {
              this.setState({fetchingCorporates: false});
            }
            this.setState(
              {
                corporateList: response?.data?.data?.corporate_lists,
              },
              () => {
                if (this.corporateRef && this.corporateRef.current) {
                  this.corporateRef.current.focus();
                }
                if (
                  this.state.corporateList &&
                  this.state.corporateList.length > 0 &&
                  this.state.corporateList.length === 1
                ) {
                  this.setState({
                    selectedCorporate: this.state.corporateList[0].id,
                    errors: {
                      ...this.state.errors,
                      selectedCorporate: null,
                      username: null,
                      terms_condition: null,
                      privacy_policy: null,
                    },
                  });
                }
              }
            );
          }
        } catch (e) {
          let {message} = getErrorObject(e);
          errorToast({
            content: message,
          });
          if (this.corporateRef && this.corporateRef.current) {
            this.corporateRef.current.focus();
          }
          if (isFromSearch) {
            this.setState({fetchingCorporatesSearching: false});
          } else {
            this.setState({fetchingCorporates: false});
          }
        }
      }
    }
  };
  handleSelectChange = (value, key) => {
    this.setState({[key]: value}, () => {
      if (key === "selectedCorporate" && this.state.selectedCorporate) {
        if (this.passwordRef && this.passwordRef.current) {
          this.passwordRef.current.focus();
        }
      }
      this.setState({
        errors: {
          ...this.state.errors,
          selectedCorporate: null,
        },
      });
    });
  };
  handleKeyPress = (e) => {
    if (e.key === "Enter" || e.key === "Tab") {
      this.handleEmailChange(false);
    } else if (e.key === "Backspace") {
      this.setState({
        newPackPrice: null,
      });
    }
  };
  render() {
    const {submitting} = this.props;
    const {errors, username, password} = this.state;

    return (
      <Fragment>
        <Spin
          spinning={
            submitting ||
            this.state.fetchingCorporates ||
            this.state.fetchingCorporatesSearching ||
            false
          }
        >
          <Form onSubmit={this.validateAndSubmit}>
            <FormGroup>
              <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                Email/Phone
                <Popover
                  content={email_info}
                  placement="right"
                  className="infowrapper"
                >
                  <Button className="noborder no-background p-0 line-1-imp height-auto-imp push-8-l">
                    {/* <img src={info} alt="" className="cursor" /> */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="12"
                      viewBox="0 0 12 12"
                    >
                      <g fill="none" fill-rule="evenodd">
                        <g fill="#1445B7" fill-rule="nonzero">
                          <g>
                            <g>
                              <path
                                d="M6.5 3c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM6 9.5c-.207 0-.375-.168-.375-.375V5.25h-.5c-.207 0-.375-.168-.375-.375s.168-.375.375-.375H6c.207 0 .375.168.375.375v4.25c0 .207-.168.375-.375.375z"
                                transform="translate(-329 -442) translate(215 442) translate(114)"
                              />
                              <path
                                d="M6 12c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zM6 .75C3.105.75.75 3.105.75 6S3.105 11.25 6 11.25 11.25 8.895 11.25 6 8.895.75 6 .75z"
                                transform="translate(-329 -442) translate(215 442) translate(114)"
                              />
                              <path
                                d="M7.125 9.5h-2.25c-.207 0-.375-.168-.375-.375s.168-.375.375-.375h2.25c.207 0 .375.168.375.375s-.168.375-.375.375z"
                                transform="translate(-329 -442) translate(215 442) translate(114)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </Button>
                </Popover>
              </label>
              <input
                className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                type="text"
                name="username"
                placeholder="Enter email address / phone number"
                onChange={this.handleChange}
                value={username}
                autoFocus
                onKeyPress={this.handleKeyPress}
                // onBlur={this.handleEmailChange}
              />
              {errors && errors.username && (
                <p className="form-error">{errors.username}</p>
              )}
            </FormGroup>
            {/* {this.state.fetchingCorporates && <CommonLoader size={"small"} />} */}

            <Fragment>
              <FormGroup className="selectcorporate">
                <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                  Select Healthcare Provider
                </label>
                <Select
                  ref={this.corporateRef}
                  showSearch
                  className="margin-0 form-control input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                  placeholder="Select Healthcare Provider"
                  optionFilterProp="children"
                  value={this.state.selectedCorporate}
                  onChange={(value) =>
                    this.handleSelectChange(value, "selectedCorporate")
                  }
                  disabled={
                    this.state.fetchingCorporates ||
                    (this.state.username && this.state.errors.username)
                      ? true
                      : this.username === "" || !this.state.username
                      ? true
                      : false
                  }
                  loading={this.state.fetchingCorporatesSearching}
                  onSearch={(value) =>
                    this.setState({search: value}, () => {
                      this.handleEmailChange(true);
                    })
                  }
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {this.state.corporateList &&
                  this.state.corporateList.length > 0
                    ? this.state.corporateList.map((user) => {
                        return (
                          <Select.Option
                            value={user.id}
                          >{`${user.name}`}</Select.Option>
                        );
                      })
                    : null}
                </Select>
                {errors && errors.selectedCorporate && (
                  <p className="form-error">{errors.selectedCorporate}</p>
                )}
              </FormGroup>

              <FormGroup>
                <label className="font-12 weight-400 text-left push-5-b dis-block text-capitalize text-dark-gray">
                  Password
                </label>
                <div className="relative">
                  {/* <Field
                className="font-14 weight-400 input-bg-f9f9f9 height-36-imp"
                type={this.state.passwordVisible ? "text" : "password"}
                name="password"
                placeholder="Enter Password"
                label="Password"
                component={renderTextField}
              /> */}
                  <input
                    className="form-control custom-input input-sm-28 font-11 weight-400 no-focus input-bg-f9f9f9"
                    type={this.state.passwordVisible ? "text" : "password"}
                    name="password"
                    placeholder="Enter password"
                    onChange={this.handleChange}
                    value={password}
                    ref={this.passwordRef}
                  />
                  <img
                    src={this.state.passwordVisible ? imgEyeOpen : imgEyeClosed}
                    alt=""
                    className="cursor password_show"
                    onClick={this.togglePasswordVisiblity}
                  />
                  {errors && errors.password && (
                    <p className="form-error">{errors.password}</p>
                  )}
                </div>
                <div>
                  <div className="checkbox">
                    <Checkbox
                      className=" alldayDr"
                      name="terms"
                      onChange={(e) => {
                        this.setState({terms_condition: e.target.checked});
                      }}
                      checked={this.state.terms_condition}
                    >
                      Please agree to our{" "}
                      <a
                        href="https://www.healthya.co.uk/terms_and_conditions"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Terms & Conditions
                      </a>
                    </Checkbox>
                    {errors && errors.terms_condition && (
                      <p className="form-error">{errors.terms_condition}</p>
                    )}
                  </div>
                  <div className="checkbox">
                    <Checkbox
                      className="alldayDr"
                      name="policy"
                      // onBlur={handleBlur}
                      onChange={(e) => {
                        // console.log("eeee", e);
                        this.setState({privacy_policy: e.target.checked});
                      }}
                      checked={this.state.privacy_policy}
                    >
                      Please agree to our{" "}
                      <a
                        href="https://www.healthya.co.uk/privacy-policy"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Privacy Policy
                      </a>
                    </Checkbox>
                    {errors && errors.privacy_policy && (
                      <p className="form-error">{errors.privacy_policy}</p>
                    )}
                  </div>
                </div>
              </FormGroup>
            </Fragment>

            <FormGroup className="align-items-center push-30-t form-group margin-bottom-10">
              <button
                type="submit"
                className="btn btn-custom btn-blue height-big weight-500 text-white width-255px"
                disabled={submitting}
              >
                {submitting ? "Sending OTP..." : "Sign In"} &nbsp;
                {submitting && <i className="fa fa-spinner fa-spin" />}
              </button>
              <div className="push-20-t">
                <ForgotPasswordLink>
                  <span className="text-blue weight-500 font-12">
                    Forgot Password?
                    <Popover
                      content={forgot_info}
                      placement="right"
                      className="infowrapper"
                    >
                      <Button className="noborder no-background p-0 line-1-imp height-auto-imp push-8-l">
                        {/* <img src={info} alt="" className="cursor" /> */}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                        >
                          <g fill="none" fill-rule="evenodd">
                            <g fill="#1445B7" fill-rule="nonzero">
                              <g>
                                <g>
                                  <path
                                    d="M6.5 3c0 .276-.224.5-.5.5s-.5-.224-.5-.5.224-.5.5-.5.5.224.5.5zM6 9.5c-.207 0-.375-.168-.375-.375V5.25h-.5c-.207 0-.375-.168-.375-.375s.168-.375.375-.375H6c.207 0 .375.168.375.375v4.25c0 .207-.168.375-.375.375z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                  <path
                                    d="M6 12c-3.309 0-6-2.691-6-6s2.691-6 6-6 6 2.691 6 6-2.691 6-6 6zM6 .75C3.105.75.75 3.105.75 6S3.105 11.25 6 11.25 11.25 8.895 11.25 6 8.895.75 6 .75z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                  <path
                                    d="M7.125 9.5h-2.25c-.207 0-.375-.168-.375-.375s.168-.375.375-.375h2.25c.207 0 .375.168.375.375s-.168.375-.375.375z"
                                    transform="translate(-329 -442) translate(215 442) translate(114)"
                                  />
                                </g>
                              </g>
                            </g>
                          </g>
                        </svg>
                      </Button>
                    </Popover>
                  </span>
                </ForgotPasswordLink>
              </div>
            </FormGroup>
          </Form>
        </Spin>
      </Fragment>
    );
  }
}

export default LoginForm;

import React, { useState } from "react";
import { List, Avatar, Tooltip } from "antd";

import mute from "assets/images/video_call_setup/mute.svg";
import no_mute from "assets/images/video_call_setup/no_mute.svg";
import chat from "assets/images/video_call_setup/chat.svg";
import chatfilled from "assets/images/video_call_setup/chatfilled.svg";
import fileshare from "assets/images/video_call_setup/fileshare.svg";
import filesharefilled from "assets/images/video_call_setup/filesharefilled.svg";
import ss_share from "assets/images/video_call_setup/ss_share.svg";
import ss_sharefilled from "assets/images/video_call_setup/ss_sharefilled.svg";
import member from "assets/images/video_call_setup/member.svg";

// import {
//   AudioFilled,
//   MessageOutlined,
//   MessageFilled,
//   FundProjectionScreenOutlined,
//   FundFilled,
//   FileTextOutlined,
//   FileTextFilled,
//   AudioMutedOutlined,
// } from "@ant-design/icons";

export default function UserItem({ stream, session }) {
  let [showAudio, setAudioState] = useState(true);
  let [showChat, setChatState] = useState(true);
  let [showScreenShare, setScreenShareState] = useState(true);
  let [showFileShare, setFileShareState] = useState(true);

  const handleAudioSignal = () => {
    console.log("Stream", stream);
    console.log("session", session);

    if (showAudio) {
      setAudioState(false);

      session.signal(
        {
          to: stream.connection,
          type: "audio-enable-disable",
          data: {
            text: "off",
          },
        },
        (error) => {
          if (error) {
            console.log(
              "signal audio off error (" + error.name + "): " + error.message
            );
          } else {
            console.log("signal audio off sent.");
          }
        }
      );
    } else {
      setAudioState(true);
      session.signal(
        {
          to: stream.connection,
          type: "audio-enable-disable",
          data: {
            text: "on",
          },
        },
        (error) => {
          if (error) {
            console.log(
              "signal audio on error (" + error.name + "): " + error.message
            );
          } else {
            console.log("signal audio on sent.");
          }
        }
      );
    }
  };

  const handleChatSignal = () => {
    console.log("Stream", stream);
    console.log("session", session);

    if (showChat) {
      setChatState(false);

      session.signal(
        {
          to: stream.connection,
          type: "chat-enable-disable",
          data: {
            text: "off",
          },
        },
        (error) => {
          if (error) {
            console.log(
              "signal chat off error (" + error.name + "): " + error.message
            );
          } else {
            console.log("signal chat off sent.");
          }
        }
      );
    } else {
      setChatState(true);
      session.signal(
        {
          to: stream.connection,
          type: "chat-enable-disable",
          data: {
            text: "on",
          },
        },
        (error) => {
          if (error) {
            console.log(
              "signal chat on error (" + error.name + "): " + error.message
            );
          } else {
            console.log("signal chat on sent.");
          }
        }
      );
    }
  };

  const handleScreenShareSignal = () => {
    console.log("Stream", stream);
    console.log("session", session);

    if (showScreenShare) {
      setScreenShareState(false);

      session.signal(
        {
          to: stream.connection,
          type: "ss-enable-disable",
          data: {
            text: "off",
          },
        },
        (error) => {
          if (error) {
            console.log(
              "signal ss off error (" + error.name + "): " + error.message
            );
          } else {
            console.log("signal ss off sent.");
          }
        }
      );
    } else {
      setScreenShareState(true);
      session.signal(
        {
          to: stream.connection,
          type: "ss-enable-disable",
          data: {
            text: "on",
          },
        },
        (error) => {
          if (error) {
            console.log(
              "signal ss on error (" + error.name + "): " + error.message
            );
          } else {
            console.log("signal ss on sent.");
          }
        }
      );
    }
  };

  const handleFileShareSignal = () => {
    console.log("Stream", stream);
    console.log("session", session);

    if (showFileShare) {
      setFileShareState(false);

      session.signal(
        {
          to: stream.connection,
          type: "fs-enable-disable",
          data: {
            text: "off",
          },
        },
        (error) => {
          if (error) {
            console.log(
              "signal fs off error (" + error.name + "): " + error.message
            );
          } else {
            console.log("signal fs off sent.");
          }
        }
      );
    } else {
      setFileShareState(true);
      session.signal(
        {
          to: stream.connection,
          type: "fs-enable-disable",
          data: {
            text: "on",
          },
        },
        (error) => {
          if (error) {
            console.log(
              "signal fs on error (" + error.name + "): " + error.message
            );
          } else {
            console.log("signal fs on sent.");
          }
        }
      );
    }
  };

  return (
    <List.Item
      style={{
        borderBottom: "1px solid rgb(0 0 0 / 12%)",
      }}
    >
      <List.Item.Meta
        avatar={<Avatar src={member} />}
        title={stream.name}
        description={
          <div className="drawer_icons">
            {showAudio ? (
              <Tooltip placement="bottom" title={"No Mute"}>
                <div onClick={handleAudioSignal} className="drawer_icons_inner">
                  <img src={no_mute} alt="No mute" />
                </div>
              </Tooltip>
            ) : (
              <Tooltip placement="bottom" title={"Mute"}>
                <div
                  onClick={handleAudioSignal}
                  className="drawer_icons_inner_active"
                >
                  <img src={mute} alt="mute" />
                </div>
              </Tooltip>
            )}

            {showChat ? (
              <Tooltip placement="bottom" title={"Chat"}>
                <div onClick={handleChatSignal} className="drawer_icons_inner">
                  <img src={chat} alt="Chat" />
                </div>
              </Tooltip>
            ) : (
              <Tooltip placement="bottom" title={"Disable Chat"}>
                <div
                  onClick={handleChatSignal}
                  className="drawer_icons_inner_active"
                >
                  <img src={chatfilled} alt="No Chat" />
                </div>
              </Tooltip>
            )}

            {showScreenShare ? (
              <Tooltip placement="bottom" title={"Start Screen Share"}>
                <div
                  onClick={handleScreenShareSignal}
                  className="drawer_icons_inner"
                >
                  <img src={ss_share} alt="ss share" />
                </div>
              </Tooltip>
            ) : (
              <Tooltip placement="bottom" title={"Stop Screen Share"}>
                <div
                  onClick={handleScreenShareSignal}
                  className="drawer_icons_inner_active"
                >
                  <img src={ss_sharefilled} alt="ss share filled" />
                </div>
              </Tooltip>
            )}

            {showFileShare ? (
              <Tooltip placement="bottom" title={"File Share"}>
                <div
                  onClick={handleFileShareSignal}
                  className="drawer_icons_inner"
                >
                  <img src={fileshare} alt="ss share" />
                </div>
              </Tooltip>
            ) : (
              <Tooltip placement="bottom" title={"Stop File Share"}>
                <div
                  onClick={handleFileShareSignal}
                  className="drawer_icons_inner_active"
                >
                  <img src={filesharefilled} alt="ss share" />
                </div>
              </Tooltip>
            )}
          </div>
        }
      />
    </List.Item>
  );
}

import React from "react";
import MessagesContainer from "../../containers/Messages/MessagesContainer";
import Layout from "../../Layouts/PageLayout";

class MessagesPage extends React.Component {
  render() {
    return (
      <Layout title="My Profile">
        <MessagesContainer />
      </Layout>
    );
  }
}

export default MessagesPage;

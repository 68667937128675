import React from "react";

const ConditionLinks = ({
  conditionFetchedLinks,

  onSetDynamicInput,
  dynamicInputs,
}) => (
  <div className="links-main-container">
    <div
      className="links-container"
      style={{display: "flex", flexDirection: "column"}}
    >
      <div className="s-header-link-item preconsultview">
        <ul>
          {conditionFetchedLinks.map((item, index) => (
            <li key={item.id}>
              <a
                href={item.link ? item.link : item.file_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.name}
              </a>
            </li>
          ))}
        </ul>
        {/* Render other components using dynamicInputs or handle other logic */}
      </div>
    </div>
  </div>
);

export default ConditionLinks;

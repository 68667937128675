import { get, Irequest, deletee } from "./index";

export const getScriptReviewApi = (params) => {
  const request: Irequest = {
    subUrl: `/doctor_orders.json`,
    params,
  };
  return get(request);
};

export const getAnsweredQuestions = (params) => {
  const request: Irequest = {
    subUrl: `/questions/answered_questions.json`,
    params,
  };
  return get(request);
};

export const deleteQuestionnaireApi = (params) => {
  const request: Irequest = {
    subUrl: `/new_questionnaire/questions/${params.id}.json`,
  };
  return deletee(request);
};

export const pageBlocksForScriptReview = (params) => {
  const request: Irequest = {
    subUrl: `/page_blocks.json`,
    params,
  };
  return deletee(request);
};

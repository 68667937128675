import React from "react";
export const Spinner = props => {
  return (
    <React.Fragment>
      {props.children}
      <i
        {...props}
        className={`fa fa-spinner fa-pulse ${props.className || ""}`}
      />
    </React.Fragment>
  );
};
export const OverlaySpinner = props => {
  return (
    <div className="overlay">
      <div className="lds-eclipse">
        <div />
      </div>
    </div>
  );
};

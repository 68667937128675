import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import {actions as nhsMedicationsActions} from "../../../reducers/nhsMedications";
import {actions as prescriptionsActions} from "../../../reducers/refferalNotes";

import {verifyObject} from "../../../utilities/utils";
import {
  isArray,
  // debounce,
  // isEmpty,
  // uniqueId
} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {Table} from "antd";
import {DATE_FORMAT} from "../../../constants/common";
import {Pagination} from "components/common";

// import search_blue from "assets/images/common/search-blue.svg";
// import { Input } from "antd";

class PrivateAcuteContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
    };
    ["_onRequestList", "_onPageChange"].map(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  componentDidMount() {
    this._onRequestList();
  }

  // _onRequestList() {
  //     let { patientId, nhsMedicationsActions } = this.props
  //     let { onRequest } = nhsMedicationsActions
  //     if (typeof onRequest === "function") {
  //         onRequest({ patient_id: patientId, page: 1 })
  //     }
  // }
  async _onRequestList() {
    let {patientId, prescriptionsActions} = this.props;
    let {onRequest} = prescriptionsActions;
    if (typeof onRequest === "function") {
      await onRequest({
        patient_id: patientId,
        page: 1,
        document_type: "Prescription",
      });
    }
  }
  // async _onRequestList() {
  //   let {patientId} = this.props;
  //   try {
  //     await this.setState({isLoading: true});
  //     let response = await getAcuteMedicationsApi({user_id: patientId});
  //     let accuteMedications1 = verifyObject(response, "data.data.current", []);
  //     let accuteMedications = [];
  //     accuteMedications1.map((d) =>
  //       accuteMedications.push({...d, key: uniqueId("ACCUTE_MED_")})
  //     );
  //     await this.setState({
  //       isLoading: false,
  //       accuteMedications: accuteMedications,
  //     });
  //   } catch (error) {
  //     const {message} = getErrorObject(error);
  //     await this.setState({isLoading: false});
  //     errorToast({content: message});
  //   }
  // }

  _onPageChange(data) {
    let {
      patientId,
      prescriptionsActions: {onPageChange},
      privateAcute: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({
        page: page,
        patient_id: patientId,
        search: search,
        document_type: "Prescription",
      });
    }
  }

  render() {
    let {data, isLoading: privateAcuteLoading} = this.props.privateAcute;

    let privateData = data ? Object.values(data) : [];
    const columns = [
      {
        title: "",
        key: "prescription_drugs",
        dataIndex: "prescription_drugs",
        className: "width-40 text-black",
        render: (prescription_drugs, item) => (
          <Fragment>
            {prescription_drugs && prescription_drugs.length > 0
              ? prescription_drugs[0].drug_name
              : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "prescription_drugs",
        dataIndex: "prescription_drugs",
        className: "width-15 text-black",
        render: (prescription_drugs, item) => (
          <Fragment>
            {prescription_drugs && prescription_drugs.length > 0
              ? prescription_drugs[0].drug_quantity
                ? `${prescription_drugs[0].drug_quantity} ${
                    verifyObject(prescription_drugs[0], "formulation")
                      ? verifyObject(prescription_drugs[0], "formulation")
                      : "Tablets"
                  }`
                : ""
              : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "prescription_drugs",
        dataIndex: "prescription_drugs",
        className: "width-16 text-black",
        render: (prescription_drugs, item) => (
          <Fragment>
            {prescription_drugs && prescription_drugs.length > 0
              ? prescription_drugs[0].dosage_description
              : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "created_at",
        dataIndex: "created_at",
        className: "width-11 text-dark-gray",
        render: (created_at, item) => (
          <Fragment>
            {created_at ? moment(created_at).format(DATE_FORMAT) : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "prescription_code",
        dataIndex: "prescription_code",
        className: "width-10",
        render: (prescription_code, item) => (
          <Fragment>
            {prescription_code ? (
              <span className="custom-bluelabel">{prescription_code}</span>
            ) : (
              "-"
            )}
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="push-20-b acute_medicate_wrapper">
          {privateAcuteLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          {!privateAcuteLoading &&
            privateData &&
            isArray(privateData) &&
            privateData.length > 0 && (
              <div className="prescription-array">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={privateData}
                  className="font-12 no_table_header border-spacing-5"
                />
              </div>
            )}
          {!privateAcuteLoading &&
            privateData &&
            isArray(privateData) &&
            privateData.length === 0 && (
              <NoFoundMessage message="No orivate acute found" />
            )}
        </div>
        <Pagination
          data={this.props.privateAcute}
          onPageChange={this._onPageChange}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    // nhsMedications: verifyObject(state, "nhsMedications", []),
    privateAcute: verifyObject(state, "referralNotes", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // nhsMedicationsActions: bindActionCreators(nhsMedicationsActions, dispatch),
    prescriptionsActions: bindActionCreators(prescriptionsActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivateAcuteContainer);

export default ConnectedComponent;

import validator from "validator";
import isEmpty from "lodash/isEmpty";
import {
  // formatPhoneNumber,
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

export function ValidatePrescriptionInput(data) {
  console.log("DATA----", data);
  let errors = {};
  if (
    data.phone !== undefined &&
    !validator.isEmpty(data.phone) &&
    (isValidPhoneNumber(data.phone) === false ||
      isPossiblePhoneNumber(data.phone) === false)
  ) {
    console.log(
      "isValidPhoneNumber(data.phone)",
      isValidPhoneNumber(data.phone)
    );
    console.log(
      "isPossiblePhoneNumber(data.phone)",
      isPossiblePhoneNumber(data.phone)
    );
    if (data.phone === "undefined") {
      //make phone email optiona changes
    } else {
      errors.phone = "Please enter valid mobile number";
    }
  } else {
  }

  if (data.email !== undefined && validator.isEmpty(data.email)) {
    errors.email = "Email is required";
  }
  if (data.email !== undefined && validator.isEmpty(data.email) === false) {
    if (!validator.isEmail(data.email)) {
      errors.email = "Email is invalid";
    }
  }

  return {errors, isValid: isEmpty(errors)};
}

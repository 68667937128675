import { get, post, deletee } from "./index";


export const getAllergiesAPI = (params) => {
    const request = {
        subUrl: `/user_allergies.json`,
        params
    };
    return get(request);
};


export const addAllergiesAPI = (data) => {
    const request = {
        subUrl: `/user_allergies.json`,
        data
    };
    return post(request);
};

export const deleteAllergiesAPI = (data) => {
    const request = {
        subUrl: `/user_allergies/${data.id}.json`,
    };
    return deletee(request);
};

export const getUserllergiesAPI = (data) => {
    const request = {
        subUrl: `/user_allergies/${data.id}.json`,
    };
    return get(request);
};


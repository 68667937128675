import React, { Fragment } from "react";
import { Layout } from "antd";
import { Sidebar } from "../components/common/index";
// import { Button, Form, DatePicker, Input } from 'antd';
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

const { Content } = Layout;
const notAllowedArray = [
  "/doctor/rota",
  "/doctor/right_to_work",
  "/patient/search",
  "/patient/detail",
  "/patient/consultation_detail",
  "/doctor/walkin-appointment",
  "/doctor/video-appointment",
  "/doctor/reports_review/detail",
  "/doctor/questionnaire_review/detail",
  "/doctor/callsetup",
  "/doctor/script_review/detail",
  "/doctor/profile",
];
function PageLayout({
  children,
  sideBarArray,
  sideBarArray2,
  title,
  description,
  keywords,
  location,
  isConsentGiven,
  showInformGP,
  isInformRequestSubmitted,
  history
}) {
  let { pathname } = location;
  // const patinetform = (
  //     <div className="patient_form_wrapper">
  //         <Form>
  //             <Form.Item>
  //                 <Input placeholder="Search patient" prefix={<SearchOutlined />} className="font-13 patient_input" />
  //             </Form.Item>
  //         </Form>
  //     </div>
  // );

  // const filterform = (
  //     <div className="filterby_form_wrapper">
  //         <Form
  //             layout="vertical"
  //         >
  //             <Form.Item label="Specific Date">
  //                 <DatePicker />
  //             </Form.Item>
  //             <Form.Item label="Date Period">
  //                 <div className="form-row">
  //                     <div className="col-md-6">
  //                         <DatePicker />
  //                     </div>
  //                     <div className="col-md-6">
  //                         <DatePicker />
  //                     </div>
  //                 </div>
  //             </Form.Item>
  //             <Form.Item>
  //                 <Button type="primary">Apply</Button>
  //             </Form.Item>
  //         </Form>
  //     </div>
  // );
  if (!notAllowedArray.includes(pathname)) {
    return (
      <Fragment>
        <Content className="user-select-none" style={{ margin: "0" }}>
          <Sidebar
            showInformGP={showInformGP}
            isConsentGiven={isConsentGiven}
            sideBarArray2={sideBarArray2}
            sideBarArray={sideBarArray}
            isInformRequestSubmitted={isInformRequestSubmitted}
            history={history}
          />
          {children}
        </Content>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Content className="user-select-none" style={{ margin: "0" }}>
          <Sidebar
            showInformGP={showInformGP}
            isConsentGiven={isConsentGiven}
            sideBarArray2={sideBarArray2}
            sideBarArray={sideBarArray}
            isInformRequestSubmitted={isInformRequestSubmitted}
            history={history}


          />
          {children}
        </Content>
      </Fragment>
    );
  }
}
export default withRouter(PageLayout);
PageLayout.defaultProps = {
  sideBarArray: [],
};

PageLayout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
};

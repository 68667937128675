import { getPreQuestionnaireApi } from 'services/preQuestionnaire'
import { OtherlistingReducer } from '../reducers/utils/otherlistingReducer'

const { reducer, actions, types } = OtherlistingReducer({
	name: `preQuestionnaire`,
	getApi: getPreQuestionnaireApi,
})

const preQuestionnaire = reducer
export { preQuestionnaire, actions, types }

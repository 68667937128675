import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {Button, Modal, Radio, Select, Input, Collapse} from "antd";
import moment from "moment";

import {actions as preQuestionnaireActions} from "../../../reducers/preQuestionnaire";

import {
  updatePreQuestionnaireApi,
  getCovidEmailTemplatesApi,
} from "services/preQuestionnaire";
import {
  getErrorObject,
  successToast,
  errorToast,
  verifyObject,
} from "../../../utilities/utils";

import {Pagination} from "../../../components/common";
import CommonLoader from "../../../components/common/CommonLoader";
import {withRouter} from "react-router-dom";
import {isArray} from "lodash";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {DATE_FORMAT_24_HR} from "../../../constants/common";
import {actions as actionCableActions} from "reducers/actionCableData";
import actionCable from "actioncable";
import {baseWebSocketURL} from "services";
import store from "app/store";
import {appRoutesConst} from "app/navigation";

let {Panel} = Collapse;
// import { appRoutesConst } from '../../../app/navigation'

class PreQuestionnaireContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      activeKey: ["1"],
      preQuestionnaire: [],
      isLoading: false,
      modalOpen: false,
      radio: null,
      select: null,
      selectedOptions: [],
      doc_link: null,
      comment: null,
      questionnaire_id: null,
      options: {
        approved: [],
        rejected: [],
      },
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_onModalOpen",
      "_onModalClose",
      "_onChange",
      "_onChangeComment",
      "_onReviewSubmit",
      "_onSelectUpdate",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
  }

  async componentDidMount() {
    await this._onRequestList();
  }

  async _onRequestList() {
    let {patientId, preQuestionnaireActions} = this.props;
    let directPatientId = localStorage.getItem("patient_id")
      ? localStorage.getItem("patient_id")
      : patientId;
    let {onRequest} = preQuestionnaireActions;
    let questionnaireList = [];
    if (typeof onRequest === "function") {
      await onRequest({patient_id: directPatientId});
    }

    let {keys} = this.props.preQuestionnaire ? this.props.preQuestionnaire : [];
    this.props.preQuestionnaire.ids.map((id) => {
      console.log("KEYS", keys[id]);
      if (keys[id]) {
        let preQ = {...this.props.preQuestionnaire.data[id], id};
        preQ = keys && keys[id] ? {...preQ, key: keys[id]} : preQ;
        questionnaireList.push(preQ);
        return null;
      }
      return null;
    });
    questionnaireList.sort((x, y) =>
      x.answer_set && x.answer_set.status === "pending"
        ? -1
        : y.answer_set.status === "pending"
        ? 1
        : 0
    );
    this.setState({
      preQuestionnaire: questionnaireList,
    });
  }
  async _onReviewSubmit() {
    let reqObj = {
      id: this.state.questionnaire_id,
      comment: this.state.comment ? this.state.comment : "",
      review_status: this.state.radio,
      covid_email_template_id: this.state.select ? this.state.select : "",
    };

    try {
      this.setState({
        isLoading: true,
      });
      let response = await updatePreQuestionnaireApi(reqObj);
      let counterChannle = actionCable.createConsumer(
        `${baseWebSocketURL}/cable`
      );
      counterChannle.subscriptions.create(
        {
          channel: "PendingQuestionnaireChannel",
          auth_token: store.getState().localStore.token,
        },
        {
          connected: (d) => {
            console.log("-------IN PREQUESTIONNAIRE CONNECTED-------");
          },
        }
      );
      // let {
      //   PendingQuestionnaireChannel,
      //   // AppointmentCounterChannel,
      //   // ScriptreviewCounterChannel,
      // } = this.props.actionCableActions;
      // console.log("this.props.actionCableActions", this.props.actionCableActions);
      // if (typeof PendingQuestionnaireChannel === "function") {
      //   console.log("Pending questionnaire called");
      //   await PendingQuestionnaireChannel();
      // }
      successToast({content: response.data.message});
      this.setState({
        isLoading: false,
        modalOpen: false,
        questionnaire_id: null,
        radio: null,
        select: null,
        selectedOptions: [],
        doc_link: null,
        comment: null,
        options: {
          approved: [],
          rejected: [],
        },
      });
      if (this.props.fromVideoAppointment) {
        this._onRequestList();
      } else {
        this.props.history.goBack();
      }
    } catch (error) {
      this.setState({
        isLoading: false,
      });
      let err = getErrorObject(error);
      errorToast({content: err.message});
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      preQuestionnaireActions: {onPageChange},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, patient_id: patientId});
    }
  }
  _onSelectUpdate(val) {
    let selectedOption = this.state.selectedOptions.filter(
      (opt) => opt.value === val
    );
    this.setState({
      select: val,
      doc_link: selectedOption[0].doc_link,
    });
  }
  async _onModalOpen(id) {
    try {
      let response = await getCovidEmailTemplatesApi({
        type: "question",
      });
      response.data.data &&
        response.data.data.forEach((r) => {
          let key = r.flag === "positive" ? "approved" : "rejected";
          this.setState({
            options: {
              ...this.state.options,
              [key]: [
                ...this.state.options[key],
                {value: r.id, label: r.template_name, doc_link: r.file_url},
              ],
            },
          });
        });
    } catch (e) {
      let err = getErrorObject(e);
      errorToast({content: err.message});
    }

    this.setState({
      modalOpen: true,
      questionnaire_id: id,
    });
  }
  _onModalClose() {
    this.setState({
      modalOpen: false,
      radio: null,
      select: null,
      selectedOptions: [],
      doc_link: null,
      comment: null,
      options: {
        approved: [],
        rejected: [],
      },
    });
  }
  _onChangeComment(e) {
    this.setState({
      comment: e.target.value,
    });
  }
  _onChange(e) {
    this.setState({
      radio: e.target.value,
      select: null,
      doc_link: null,
      selectedOptions: this.state.options[e.target.value],
    });
  }
  render() {
    let {isLoading, ids} = this.props.preQuestionnaire;
    let {activeKey} = this.state;
    let selectedQuestionnaire = JSON.parse(
      localStorage.getItem("selectedQuestionnaire")
    );
    return (
      <Fragment>
        {!isLoading && ids && isArray(ids) && ids.length === 0 && (
          <div className="padding-55-t push-30-b">
            <NoFoundMessage message="No pre questionnaire" />
          </div>
        )}
        {isLoading ? (
          <div>
            <CommonLoader />
          </div>
        ) : (
          <div className="push-50-t push-30-b padding-15-lr prequitiorepage">
            <Collapse
              expandIconPosition="right"
              onChange={(key) => {
                this.setState({ activeKey: key });
              }}
              activeKey={activeKey}
              className="pre_quitionare"
            >
              {this.state.preQuestionnaire.map((preQ, index) => {
                let { questions, answer_set, key } = preQ;
                return (
                  <Panel
                    // style={{ background: "black" }}
                    key={[index + 1]}
                    header={
                      <div className="col-md-12 prl-5">
                        <div className="form-row align-items-center">
                          <div className="col-md-6">
                            <div className="m-0 font-12 weight-400 text-fff padding-5 text-capitalize">
                              {key &&
                                `${moment(key).format(DATE_FORMAT_24_HR)} (${
                                  answer_set.questionnaire_type &&
                                  answer_set.questionnaire_type.replace(
                                    /_/g,
                                    " "
                                  )
                                } Test)`}
                              {answer_set.status !== "pending" && (
                                <Fragment>
                                  <div className="font-14 weight-400 in-block push-5-r">
                                    &nbsp; | &nbsp;
                                  </div>

                                  <p className="in-block font-14 weight-400 margin-0 normal-lineheight">
                                    <span className="ant-tag white-tag text-capitalize">
                                      {answer_set.status &&
                                        answer_set.status.replace(/_/g, " ")}
                                    </span>
                                  </p>
                                </Fragment>
                              )}

                              {answer_set.child_info !== null && (
                                <Fragment>
                                  <div className="font-14 weight-400 in-block push-5-r">
                                    &nbsp; | &nbsp;
                                  </div>

                                  <p className="in-block font-14 weight-400 margin-0 normal-lineheight">
                                    <span className="ant-tag white-tag text-capitalize">
                                      Child
                                    </span>
                                  </p>
                                </Fragment>
                              )}
                            </div>
                          </div>
                          {
                            <div className="col-md-6 text-right text-fff">
                              <p className="padding-5 font-12 weight-400 margin-0 normal-lineheight">
                                <span className="vertical-middle">
                                  {verifyObject(
                                    answer_set,
                                    "doctor_name",
                                    null
                                  ) !== null &&
                                    verifyObject(
                                      answer_set,
                                      "doctor_name",
                                      null
                                    ) !== null &&
                                    `By ${answer_set.doctor_name} On ${moment(
                                      answer_set.reviewed_at
                                    ).format(DATE_FORMAT_24_HR)}`}{" "}
                                </span>
                              </p>
                            </div>
                          }
                        </div>
                      </div>
                    }
                  >
                    {/* <div className="form-row" key={id}>
                        <div className="col-md-12 push-10-t">
                          <div className="form-row m-0"> */}
                    <div className="col-md-12 p-0">
                      <div className="form-row">
                        <div className="col-md-8">
                          {questions.map((question) => {
                            return (
                              <div
                                key={question.question.id}
                                className="form-row margin-bottom-10"
                              >
                                <div className="col-md-12">
                                  <p className="font-12 weight-500 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                    {question.question.text}
                                  </p>
                                  {question.question.answer ? (
                                    <div className="border-box col-md-12 input-bg-f9f9f9">
                                      <p className="font-11 weight-400 text-light-black text-left margin-0 text-capitalize">
                                        {question.question.answer === "yes" ? (
                                          <span className="blue-bold text-capitalize">
                                            {question.question.answer}
                                          </span>
                                        ) : (
                                          <span className="text-capitalize">
                                            {question.question.answer
                                              ? question.question.answer
                                              : "---"}
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="border-box col-md-12 input-bg-f9f9f9">
                                      <p className="font-11 weight-400 text-light-black text-left margin-0 text-capitalize">
                                        {question.question.answer === "yes" ? (
                                          <span className="blue-bold text-capitalize">
                                            {question.question.answer}
                                          </span>
                                        ) : (
                                          <span className="text-capitalize">
                                            ---
                                          </span>
                                        )}
                                      </p>
                                    </div>
                                  )}
                                  {question.sub_questions
                                    ? question.sub_questions.map((subQ, i) => {
                                        return (
                                          <div
                                            className="push-10-b field_spacing"
                                            key={i}
                                          >
                                            <p className="font-12 weight-400 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                              {subQ.text}
                                            </p>
                                            {typeof subQ.answer === "object" ? (
                                              <Fragment>
                                                <div className="form-row">
                                                  <div className="col-md-8">
                                                    <span className="font-11 weight-400 text-light-black">
                                                      1-7 Days
                                                    </span>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <div className="border-box input-sm-28 input-bg-f9f9f9">
                                                      <p className="font-11 weight-400 margin-0">
                                                        {subQ.answer
                                                          .seven_days ===
                                                        "yes" ? (
                                                          <span className="blue-bold text-capitalize">
                                                            {
                                                              subQ.answer
                                                                .seven_days
                                                            }
                                                          </span>
                                                        ) : (
                                                          <span className="text-capitalize">
                                                            {
                                                              subQ.answer
                                                                .seven_days
                                                            }
                                                          </span>
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-row">
                                                  <div className="col-md-8">
                                                    <span className="font-11 weight-400 text-light-black">
                                                      1-3 Weeks
                                                    </span>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <div className="border-box input-sm-28 input-bg-f9f9f9">
                                                      <p className="font-11 weight-400 margin-0">
                                                        {subQ.answer
                                                          .three_weeks ===
                                                        "yes" ? (
                                                          <span className="blue-bold text-capitalize">
                                                            {
                                                              subQ.answer
                                                                .three_weeks
                                                            }
                                                          </span>
                                                        ) : (
                                                          <span className="text-capitalize">
                                                            {
                                                              subQ.answer
                                                                .three_weeks
                                                            }
                                                          </span>
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                <div className="form-row">
                                                  <div className="col-md-8">
                                                    <span className="font-11 weight-400 text-light-black">
                                                      &gt;3 Weeks
                                                    </span>
                                                  </div>
                                                  <div className="col-md-4">
                                                    <div className="border-box input-sm-28 input-bg-f9f9f9">
                                                      <p className="font-11 weight-400 margin-0">
                                                        {subQ.answer
                                                          .three_plus_weeks ===
                                                        "yes" ? (
                                                          <span className="blue-bold text-capitalize">
                                                            {
                                                              subQ.answer
                                                                .three_plus_weeks
                                                            }
                                                          </span>
                                                        ) : (
                                                          <span className="text-capitalize">
                                                            {
                                                              subQ.answer
                                                                .three_plus_weeks
                                                            }
                                                          </span>
                                                        )}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Fragment>
                                            ) : (
                                              <div className="border-box col-md-12 input-bg-f9f9f9">
                                                <p className="font-11 weight-400 text-light-black text-left margin-0">
                                                  {}
                                                  {subQ.answer === "yes" ? (
                                                    <span className="blue-bold text-capitalize">
                                                      {subQ.answer}
                                                    </span>
                                                  ) : (
                                                    <span className="text-capitalize">
                                                      {subQ.answer}
                                                    </span>
                                                  )}
                                                </p>
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })
                                    : null}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        <div className="col-md-4">
                          <div className="push-20-b push-10-t">
                            {window.location.pathname ===
                              appRoutesConst.questionnaireReviewDetail && (
                              <p className="font-14 weight-400 pink-text push-5-b normal-lineheight">
                                {answer_set.status &&
                                answer_set.status !== "pending" ? (
                                  <Fragment></Fragment>
                                ) : (
                                  <Button
                                    onClick={(_) =>
                                      this._onModalOpen(answer_set.id)
                                    }
                                    className="btn-custom btn-green width-136"
                                  >
                                    Review
                                  </Button>
                                )}
                              </p>
                            )}
                            {answer_set.status === "review_comment" ? (
                              <p className="d-block mt-1 inline text-blue font-14 weight-400">
                                Comment :{" "}
                                <span className="weight-500">
                                  {answer_set.comment}
                                </span>
                              </p>
                            ) : null}
                          </div>

                          {answer_set.child_info &&
                            answer_set.child_info !== null && (
                              <div className="push-20-b push-10-t">
                                <p className="font-14 weight-400  push-5-b normal-lineheight">
                                  <p className="font-12 weight-500 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                    Child Detail
                                  </p>
                                  {answer_set.child_info &&
                                    answer_set.child_info !== null && (
                                      <Fragment>
                                        {" "}
                                        <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                          Name :{" "}
                                          {`${
                                            answer_set.child_info &&
                                            answer_set.child_info.first_name
                                          } ${
                                            answer_set.child_info &&
                                            answer_set.child_info.middle_name
                                          } ${
                                            answer_set.child_info &&
                                            answer_set.child_info.last_name
                                          } `}
                                        </p>
                                        {answer_set.child_info &&
                                          answer_set.child_info.dob && (
                                            <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                              DOB:{" "}
                                              {moment(
                                                answer_set.child_info.dob
                                              ).format("DD-MM-YYYY")}
                                            </p>
                                          )}
                                        {answer_set.child_info &&
                                          answer_set.child_info
                                            .guardian_authority === true && (
                                            <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                              Guardian Authority : To Parent
                                              Account
                                            </p>
                                          )}
                                      </Fragment>
                                    )}
                                </p>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                    {/* </div>
                        </div>
                      </div> */}
                  </Panel>
                );
              })}
            </Collapse>
            <div className="col-md-12">
              <Pagination
                data={this.props.preQuestionnaire}
                onPageChange={this._onPageChange}
              />
            </div>
          </div>
        )}

        <Modal
          visible={this.state.modalOpen}
          onCancel={this._onModalClose}
          title={`Review Questionnaire`}
          footer={null}
          maskClosable={false}
        >
          <div className="radio questionare_radio">
            {selectedQuestionnaire && selectedQuestionnaire.child_info && (
              <div className="parent-auth">
                {selectedQuestionnaire.child_info &&
                  selectedQuestionnaire.child_info !== null && (
                    <div className="push-20-b push-10-t">
                      <p className="font-14 weight-400  push-5-b normal-lineheight">
                        <p className="font-12 weight-500 text-dark-gray text-left dis-block push-5-b text-capitalize">
                          Child Detail
                        </p>
                        {selectedQuestionnaire.child_info &&
                          selectedQuestionnaire.child_info !== null && (
                            <Fragment>
                              {" "}
                              <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                Name :{" "}
                                {`${
                                  selectedQuestionnaire.child_info &&
                                  selectedQuestionnaire.child_info.first_name
                                } ${
                                  selectedQuestionnaire.child_info &&
                                  selectedQuestionnaire.child_info.middle_name
                                } ${
                                  selectedQuestionnaire.child_info &&
                                  selectedQuestionnaire.child_info.last_name
                                } `}
                              </p>
                              {selectedQuestionnaire.child_info &&
                                selectedQuestionnaire.child_info.dob && (
                                  <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                    DOB:{" "}
                                    {moment(
                                      selectedQuestionnaire.child_info.dob
                                    ).format("DD-MM-YYYY")}
                                  </p>
                                )}
                              {selectedQuestionnaire.child_info &&
                                selectedQuestionnaire.child_info
                                  .guardian_authority === true && (
                                  <p className="font-12 text-dark-gray text-left dis-block push-5-b text-capitalize">
                                    Guardian Authority : To Parent Account
                                  </p>
                                )}
                            </Fragment>
                          )}
                      </p>
                    </div>
                  )}
              </div>
            )}
            <label htmlFor="result">Result Value</label>
            <Radio.Group
              onChange={this._onChange}
              id="result"
              className="d-block"
              value={this.state.radio}
            >
              <Radio value="approved">Approve</Radio>
              <Radio value="rejected">Reject</Radio>
              <Radio value="review_comment">Comment</Radio>
            </Radio.Group>
          </div>
          {this.state.radio === "review_comment" && this.state.radio && (
            <div className="comment mt-2">
              <label htmlFor="comment">Comment</label>
              <Input
                type="text"
                value={this.state.comment}
                className="d-block font-10 placeholder-10 form-control margin-0"
                placeholder="Please write in your comment"
                onChange={this._onChangeComment}
              />
            </div>
          )}
          {this.state.radio !== "review_comment" && this.state.radio && (
            <div className="template-selection mt-2">
              <label htmlFor="sel1">Select Template</label>
              <Select
                className="w-100 font-10 form-control margin-0"
                id="email_template"
                value={this.state.select}
                onChange={this._onSelectUpdate}
                placeholder="Select Templates"
              >
                <Select.Option disabled>Select Templates</Select.Option>

                {this.state.selectedOptions &&
                  this.state.selectedOptions.map((opt) => {
                    return (
                      <Select.Option key={opt.value} value={opt.value}>
                        {opt.label}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
          )}
          {this.state.doc_link && (
            <a
              rel="noreferrer"
              className="mt-3 d-block weight-500 font-11"
              href={this.state.doc_link}
              target="_blank"
            >
              View Template
            </a>
          )}
          <div className="mt-3">
            <Button
              onClick={this._onReviewSubmit}
              disabled={
                this.state.radio === "review_comment"
                  ? !(this.state.radio && this.state.comment) ||
                    /^(?=.*\S).+$/.test(this.state.comment) === false
                  : !this.state.radio
              }
              loading={this.state.isLoading}
              className="mr-2"
              type="primary"
            >
              ADD
            </Button>
            <Button onClick={this._onModalClose} className="cancel_btn">
              Cancel
            </Button>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    preQuestionnaire: verifyObject(state, "preQuestionnaire", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    preQuestionnaireActions: bindActionCreators(
      preQuestionnaireActions,
      dispatch
    ),
    actionCableActions: bindActionCreators(actionCableActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(PreQuestionnaireContainer);

export default withRouter(ConnectedComponent);

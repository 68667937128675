import React, { Component } from "react";
import { connect } from "react-redux";
import CommonLoader from "../../../components/common/CommonLoader";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import { getConsultationScreenshots } from "../../../services/consultations";
import { Image, Upload, Progress } from "antd";
// import PlusImage from "../../../assets/images/common/add-ic.png";
import moment from "moment";
// import Scrollbars from "react-custom-scrollbars";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "../../../utilities/utils";
import { getBaseUrl } from "../../../services";
import { DATE_FORMAT, T24_HR_FORMAT } from "../../../constants/common";
import { PlusSquareOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import { uniqueId } from "lodash";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
let { Panel } = Collapse;

class ScreenshotContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenshots: [],
      isLoading: false,
      uploadPercent: 0,
      uploading: false,
    };
  }
  componentDidMount() {
    this._onRequestList();
  }
  async _onRequestList() {
    let { appointmentId } = this.props;
    try {
      await this.setState({ isLoading: true });
      let response = await getConsultationScreenshots({
        appointment_id: appointmentId,
      });
      this.setState({
        screenshots: verifyObject(response, "data.data", []),
      });
      await this.setState({ isLoading: false });
    } catch (error) {
      const { message } = getErrorObject(error);
      await this.setState({ isLoading: false });
      errorToast({ content: message });
    }
  }

  render() {
    let { screenshots, isLoading, uploadPercent, uploading } = this.state;
    let { token, appointmentId } = this.props;
    let baseURL = getBaseUrl();
    let action = `${baseURL}/screenshots.json`;
    let uploadProps = {
      action: action,
      data: { appointment_id: appointmentId },
      headers: {
        AUTH_TOKEN: token,
      },
      onStart: (file) => {
        this.setState({
          uploading: true,
          file_name: file.name,
          uploadPercent: 30,
        });
      },
      onSuccess: (res, file) => {
        successToast({ content: verifyObject(res, "message", "Success") });
        this.setState({ uploading: false });
        this._onRequestList();
      },
      onError: (err) => {
        this.setState({ uploading: false });
      },
      onProgress: ({ percent }, file) => {
        this.setState({ uploadPercent: percent, uploading: true });
      },
    };
    return (
      <div className="padding-45-t">
        <div className="selection_container">
          <div className="patientdetailsmain padding-15-lr">
            <div className="push-10-b">
              {uploading && (
                <Progress percent={uploadPercent} status="active" />
              )}
              {!isLoading && screenshots.length === 0 && (
                <NoFoundMessage message="No screenshot was provided" />
              )}

              {!isLoading && screenshots.length === 0 && (
                <div className="margin-top-15 dis-block text-center">
                  <Upload
                    {...uploadProps}
                    showUploadList={false}
                    accept=".png,.jpg,.jpeg"
                    className="font-12-imp btn-custom btn-blue weight-400 in-block cursor text-fff"
                  >
                    Upload Screenshot
                  </Upload>
                </div>
              )}

              {!isLoading && screenshots.length !== 0 && (
                <div style={{ textAlign: "end" }} className="upload_btn">
                  <Upload
                    {...uploadProps}
                    showUploadList={false}
                    accept=".png,.jpg,.jpeg"
                  >
                    <PlusSquareOutlined
                      style={{
                        cursor: "pointer",
                        color: "#1445b7",
                        fontSize: "15px",
                      }}
                    />{" "}
                    Add
                  </Upload>
                </div>
              )}
            </div>
            {isLoading && <CommonLoader />}
            <div className="screenshot-container">
              <div className="sectionsubscroll">
                <PerfectScrollbar>
                  <div className="left-right-space">
                    {!isLoading &&
                      screenshots.map((s, index) => {
                        return (
                          <Collapse
                            expandIconPosition="right"
                            className="pre_quitionare"
                            defaultActiveKey={["1"]}
                            key={uniqueId("SSC_")}
                          >
                            <Panel
                              key={index + 1}
                              header={
                                <div>
                                  <p className="font-12 weight-400 line-1-43 margin-bottom-0 text-fff padding-5">
                                    {moment(s.created_at).format(DATE_FORMAT)} |{" "}
                                    {moment(s.created_at).format(T24_HR_FORMAT)}
                                  </p>
                                </div>
                              }
                            >
                              <div className="col-md-12 p-0">
                                <div className="form-row">
                                  <div className="col-md-6">
                                    <div className="div-border padding-6-imp">
                                      <Image alt="" src={s.file} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          </Collapse>
                        );
                      })}
                  </div>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    token: verifyObject(state, "localStore.token", ""),
  };
};

const ConnectedComponent = connect(mapStateToProps, null)(ScreenshotContainer);
export default ConnectedComponent;

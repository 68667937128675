/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useRef} from "react";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import {verifyObject} from "../../utilities/utils";
import {getShortCodesAPI} from "services/shortCodes";
import "./AutoTextArea.css";
import "@webscopeio/react-textarea-autocomplete/style.css";

const AutoTextArea = ({
  className,
  name,
  value,
  onChange,
  id,
  placeholder,
  rows,
  scrollToBottomForTextArea,
  disabled,
}) => {
  const textAreaRef = useRef(null);
  const resizeTextArea = () => {
    if (id && value) {
      let textarea = document.querySelector(`#${id}`);
      if (textarea) {
        console.log("textarea", textarea);
        textarea.addEventListener("change", autoResize, false);
        textarea.addEventListener("input", autoResize, false);
      }
    }
  };

  const autoResize = (e) => {
    console.log("e.target", e);
    e.target.style.height = "auto";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  useEffect(() => {
    console.log("value", value);
    resizeTextArea();
  }, [value]);
  /**
   * Retrieves a list of short codes based on the given search query.
   *
   * @param {string} search - The search query.
   * @returns {Array} An array of objects containing the code and description of each short code.
   */
  const _getShortCodes = async (search) => {
    try {
      // Call the getShortCodesAPI function to retrieve the short codes
      let response = await getShortCodesAPI({
        search: search,
        code_key: name === "managementPlan" ? "management_plan" : name,
      });

      // Verify and extract the short codes from the response
      let shortCodes = verifyObject(response, "data.data", []);

      // Log the retrieved short codes

      // Return the first 10 short codes as an array of objects containing the code and description
      return shortCodes
        .slice(0, 10)
        .map(({code, description}, index) => ({code, description, index}));
    } catch (error) {
      // Handle any errors that occur during the retrieval of short codes
      // const { message } = getErrorObject(error);
      // errorToast({ content: message });
      return [];
    }
  };

  /**
   * Render an item with code and description.
   *
   * @param {Object} entity - The entity object containing code and description.
   * @param {string} entity.code - The code of the item.
   * @param {string} entity.description - The description of the item.
   * @returns {JSX.Element} - The rendered item.
   */

  const Item = ({entity: {code, description, index}}) => (
    <Fragment>
      {index === 0 && (
        <div className="autotextshortcodetable tableHeader">
          <div className="tabledata">
            {/* {`${code}: ${description}`} */}
            <p className="code">{"Code"}</p>
            <p className="description">{"Description"}</p>
          </div>
        </div>
      )}
      <div className="autotextshortcodetable">
        <div className="tabledata">
          {/* {`${code}: ${description}`} */}
          <p className="code">{code}</p>
          <p className="description">{description}</p>
        </div>
      </div>
    </Fragment>
  );
  // const onCaretPositionChange = (position) => {
  //   console.log(`Caret position is equal to ${position}`);
  // };

  // const resetCaretPosition = () => {
  //   this.rta.setCaretPosition(0);
  // };

  // const constprintCurrentCaretPosition = () => {
  //   const caretPosition = this.rta.getCaretPosition();
  //   console.log(`Caret position is equal to ${caretPosition}`);
  // };
  // console.log("snomed values--------------->", value);
  return (
    <Fragment>
      <ReactTextareaAutocomplete
        disabled={disabled}
        className={className}
        ref={textAreaRef}
        id={id}
        // onInput="this.parentNode.dataset.replicatedValue = this.value"
        onChange={(e) => {
          if (typeof onChange === "function") {
            onChange({
              target: {
                value: e.target.value,
                name: name,
              },
            });
            if (typeof scrollToBottomForTextArea === "function") {
              scrollToBottomForTextArea();
            }
            resizeTextArea();
          }
        }}
        value={value}
        loadingComponent={() => (
          <span className="rta_loading">Fetching...</span>
        )}
        // style={{
        //   padding: "2px",
        //   resize: "none",
        //   overflowY: "hidden",
        // }}
        // onCaretPositionChange={onCaretPositionChange}
        // containerStyle={{
        //   width: 400,
        //   height: 100,
        // }}
        trigger={{
          $: {
            // Data provider for triggering autocomplete on space
            dataProvider: (token) => {
              // Splitting the token into an array of words
              const words = token.split(" ");
              // Getting the last word from the array
              const lastWord = words[words.length - 1];
              return _getShortCodes(lastWord);
            },
            component: Item,
            allowWhitespace: true,

            // Output format for selected item
            output: (item, trigger) => {
              return {
                text: item.description + "\n", // Adding new line character at end for formatting.
                caretPosition: "end",
              };
            },
          },
        }}
      />
    </Fragment>
  );
};

export default AutoTextArea;

import React, { Fragment, useEffect } from "react";
import { Tabs, Collapse } from "antd";
import { isArray, isObject } from "lodash";
import { EllipsisOutlined } from "@ant-design/icons";
const { TabPane } = Tabs;
const { Panel } = Collapse;

export default function DynamicPrescriptionTabs({ bnfMetaDataArray }) {
  useEffect(() => {}, [bnfMetaDataArray]);
  const handleMainTab = (key) => {};

  const handleInnerTab = (key) => {};
  // console.log("bnfMetaDataArray", bnfMetaDataArray);

  return (
    <div>
      <div
       className={`${bnfMetaDataArray.length > 0 ? 'form-group' : ''}`}
      >
        <div className="form-row">
          <div className="col-md-12">
            {bnfMetaDataArray.length > 0 && (
              <Tabs
                defaultActiveKey="1"
                onChange={handleMainTab}
                className="prescription_drugs"
              >
                {bnfMetaDataArray &&
                  isArray(bnfMetaDataArray) &&
                  bnfMetaDataArray.length > 0 &&
                  bnfMetaDataArray.map((bnf) => {
                    return (
                      <TabPane tab={bnf.bnf_drug_name} key={bnf.id}>
                        <Collapse
                          className="pre_quitionare1"
                          expandIconPosition="right"
                        >
                          <Panel
                            // style={{
                            //   backgroundColor: isCommon
                            //     ? this.renderBGColor(data[d])
                            //     : "unset",
                            //   color: "black",
                            // }}
                            key={1}
                            header={
                              <Fragment>
                                <div className="prescription_drugs_header">
                                  <p className="prescription_drugs_title">
                                    Vital Drug Information
                                  </p>
                                </div>
                              </Fragment>
                            }
                          >
                            <Tabs
                              onChange={handleInnerTab}
                              className="prescription_drugs_inner"
                              moreIcon={
                                <EllipsisOutlined
                                  onMouseEnter={() => {
                                    // alert("in");
                                    let elm = document.querySelector(
                                      ".ant-tabs-dropdown-menu"
                                    );
                                    if (elm) {
                                      elm.classList.add(
                                        "custom-ul-prescription"
                                      );
                                    }
                                  }}
                                  onClick={() => {
                                    // alert("in");
                                    let elm = document.querySelector(
                                      ".ant-tabs-dropdown-menu"
                                    );
                                    if (elm) {
                                      elm.classList.add(
                                        "custom-ul-prescription"
                                      );
                                    }
                                  }}
                                  className="elipsis-icon-custom"
                                />
                              }
                            >
                              {bnf.meta_data &&
                                isObject(bnf.meta_data) &&
                                Object.keys(bnf.meta_data).map((bnf_meta) => {
                                  const regex = /\\n|\\r\\n|\\n\\r|\\r/g;
                                  let HtmlString = JSON.stringify(
                                    bnf.meta_data[bnf_meta]
                                  ).replace(regex, `<br/>`);
                                  if (bnf.meta_data[bnf_meta]) {
                                    return (
                                      <TabPane
                                        tab={bnf_meta.replace(/_/g, " ")}
                                        key={bnf_meta}
                                      >
                                        {bnf.meta_data[bnf_meta] ? (
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: JSON.parse(HtmlString),
                                            }}
                                          />
                                        ) : (
                                          <span className="no-prescription-data">
                                            No available data please use other
                                            resources
                                          </span>
                                        )}
                                      </TabPane>
                                    );
                                  }
                                  return null;
                                })}
                            </Tabs>
                          </Panel>
                        </Collapse>
                      </TabPane>
                    );
                  })}
              </Tabs>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, {Component, Fragment} from "react";
import {
  errorToast,
  verifyObject,
  warningToast,
  getErrorObject,
} from "../../utilities/utils";
// import TestSpeakers from "./TestSpeakers";
import {Button, Progress} from "antd";
// import CommonLoader from "../../components/common/CommonLoader";
import {
  onRequestSetVideoCallSetup,
  onRequestSetVideoCallStatus,
} from "../../reducers/videoCall";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {navigateTo, appRoutesConst} from "app/navigation";
import store from "../../app/store";
import {Prompt, withRouter} from "react-router-dom";
import {APPT_TYPE_AUDIO} from "../../constants/common";
import camera from "assets/images/common/camera.svg";
import microphone from "assets/images/common/microphone.svg";
import speaker from "assets/images/common/speaker.svg";
// import OTConfig from "./configNetworkTest";
import VideoCallSetupInstructions from "./videoCallSetupInstructions";
import createChart from "./chart";
import * as ConnectivityUI from "./connectivity-ui.js";
import NetworkTest from "opentok-network-test-js";
import {generateOpentokTokenAPI} from "../../services/CallingServices";

import ModalPopUp from "components/common/ModalPopUp";
import PreCallTest from "./PreCallTest";
import AudioTestFile from "../../assets/sounds/auditest.wav";
import {uniqueId} from "lodash";
import bg1 from "assets/images/common/bg1.jpg";
// import bg2 from "assets/images/common/bg2.jpg";
// import bg3 from "assets/images/common/bg3.jpg";
// import bg4 from "assets/images/common/bg4.jpeg";
const OT = window.OT;

let publisher;
class VideoCallSetupContainer extends Component {
  static defaultProps = {};
  constructor(props) {
    super(props);
    this.state = {
      audioInputDevices: [],
      videoInputDevices: [],
      audiLevel: 0,
      qualityResults: null,
      loadingResults: false,
      loadingGraph: false,
      audioDeviceId: null,
      videoDeviceId: null,
      error: true,
      showSteps: false,
      audioFileState: new Audio(AudioTestFile),
      playing: false,
      audioQualityClass: "",
      videoQualityClass: "",
      showSuccess: false,
      showError: false,
      showWarnig: false,
      showPreCallTest: false,
      isQualityPassed: false,
      videoQualityStatus: "",
      audioQualityStatus: "",
      errorInTest: false,
      otNetworkTest: null,
      genrating: false,
      reruntest: false,
      audioOutputDevice: [],
      currentAudioOutputDevice: false,
      blurType: "low",
      changeBackground: false,
      bgImage: bg1,
      isChecked: false,
      isWebMediaProcessorSupport: false,
      speakerSupport: false,
      bgRadio: "none",
      publisherObj: null,
    };
  }

  async componentDidMount() {
    ConnectivityUI.getGraphDefaulStats();
    let {isVideoCallSetup, isVideoCallStarted, appointmentId} = this.props;
    let {navigateTo} = this.props.navigateTo;

    this.state.audioFileState.addEventListener("ended", () =>
      this.setState({playing: false})
    );

    if (appointmentId) {
      if (isVideoCallSetup && !isVideoCallStarted) {
        this.PublishInit();
      } else {
        if (typeof navigateTo === "function") {
          navigateTo(appRoutesConst.dashboard);
        }
      }
    } else {
      warningToast({content: "You have to intilize appointment first"});
      navigateTo(appRoutesConst.dashboard);
    }
    // this.runNetwrokTest();
  }
  async componentWillUnmount() {
    if (
      publisher &&
      publisher.destroy &&
      typeof publisher.destroy === "function"
    ) {
      await publisher.destroy();
    }
  }
  _generateOpentokToken = async () => {
    let otNetworkTest = null;
    if (this.state.reruntest) {
      this.testQuality();
    } else {
      try {
        await this.setState({genrating: true});
        let response = await generateOpentokTokenAPI();
        let {token, session_id} = response.data.data;
        console.log("Response", session_id, token);
        let API_KEY = "45833712"; // 46193392
        if (process.env.REACT_APP_ENV === "stage") {
          API_KEY = "46182402";
        }
        if (process.env.REACT_APP_ENV === "prod") {
          API_KEY = "46193392";
        }
        otNetworkTest = new NetworkTest(
          OT,
          {
            apiKey: API_KEY, // Add the API key for your OpenTok project here.
            sessionId: session_id, // Add a test session ID for that project
            token: token, // Add a token for that sessionp here
          },
          {
            timeout: 10000,
            videoSource: null,
          }
        );
        this.setState({otNetworkTest: otNetworkTest, genrating: false}, () => {
          this.testQuality();
        });
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({
          genrating: false,
          showPreCallTest: false,
          error: false,
          loadingResults: false,
          loadingGraph: false,
          isQualityPassed: false,
        });
        errorToast({content: message});
      }
    }
  };
  PublishInit = async (audioDeviceId, videoDeviceId) => {
    let {appointmentType} = this.props;
    var publisherElement = document.querySelector("#hardware-setup");
    // publisherElement.setAttribute("id", "publisherElement");
    if (appointmentType === APPT_TYPE_AUDIO) {
      // document.getElementById("OT_publisher").style.display = "none";
      document.getElementById("camera_div").style.display = "none";
      let pubDiv = document.querySelector(".OT_publisher");
      if (pubDiv) {
        pubDiv.style.display = "none";
      }
    }
    let videoFilter = {};
    this.setState(
      {isWebMediaProcessorSupport: OT.hasMediaProcessorSupport()},
      () => {}
    );
    if (
      this.state.isWebMediaProcessorSupport &&
      OT.hasMediaProcessorSupport()
    ) {
      videoFilter =
        appointmentType === APPT_TYPE_AUDIO
          ? false
          : this.state.isChecked
          ? {
              type: "backgroundBlur",
              blurStrength: this.state.blurType,
            }
          : this.state.changeBackground &&
            this.state.bgRadio === "bg_replacement"
          ? {
              type: "backgroundReplacement",
              backgroundImgUrl: this.state.bgImage,
            }
          : false;
    }
    const audioOutputs = await OT.getAudioOutputDevices();
    console.log("out put deviceee==>", audioOutputs);
    if (audioOutputs.length === 0) {
      this.setState({speakerSupport: false});
    } else {
      this.setState({speakerSupport: true});
    }
    const currentOutputDevice = await OT.getActiveAudioOutputDevice();
    console.log("out put deviceee==>", currentOutputDevice);
    if (audioOutputs.length !== 0) {
      this.setState({audioOutputDevice: audioOutputs}, () => {});
    }
    if (currentOutputDevice.deviceId) {
      this.setState(
        {currentAudioOutputDevice: currentOutputDevice.deviceId},
        () => {
          // OT.setAudioOutputDevice(this.state.currentAudioOutputDevice);
        }
      );
    }
    let audioSourceParams = {};
    let videoSourceParams = {};
    if (audioDeviceId) {
      audioSourceParams = {
        audioSource: this.state.audioDeviceId
          ? this.state.audioDeviceId
          : audioDeviceId
          ? audioDeviceId
          : "default",
      };
    }
    if (videoDeviceId) {
      videoSourceParams = {
        videoSource: this.state.videoDeviceId
          ? this.state.videoDeviceId
          : videoDeviceId,
      };
    }
    var publisherProperties = {
      resolution: "1280x720",
      width: 250,
      height: 250,
      insertMode: "append",
      ...audioSourceParams,
      ...videoSourceParams,

      style: {
        audioLevelDisplayMode: "on",
      },
      publishVideo: appointmentType === APPT_TYPE_AUDIO ? false : true,
      ...videoFilter,
      // publishVideo: false,
      // Or use another insertMode setting.
    };
    publisher = OT.initPublisher(
      publisherElement,
      publisherProperties,
      (error) => {
        if (error && error.name === "OT_CHROME_MICROPHONE_ACQUISITION_ERROR") {
          this.setState({error: true});

          errorToast({
            content:
              "Failed to acquire microphone. Please completely quit and restart your browser.",
          });
        } else if (error && error.name === "OT_USER_MEDIA_ACCESS_DENIED") {
          this.setState({error: true});

          //   errorToast({
          //     content:
          //       "Please allow access to the Camera and Microphone and try publishing again.",
          //   });
        }
      }
    );

    publisher.on({
      accessAllowed: (event) => {
        this.setState({error: false});
        var audioInputDevices;
        var videoInputDevices;
        OT.getDevices((error, devices) => {
          audioInputDevices = devices.filter(function (element) {
            return element.kind === "audioInput";
          });
          videoInputDevices = devices.filter((element) => {
            return element.kind === "videoInput";
          });

          this.setState({
            audioInputDevices: audioInputDevices,
            videoInputDevices: videoInputDevices,
            audioDeviceId:
              audioInputDevices.length !== 0
                ? this.state.audioDeviceId
                  ? this.state.audioDeviceId
                  : audioInputDevices[0].deviceId
                : null,
            videoDeviceId:
              videoInputDevices.length !== 0
                ? this.state.videoDeviceId
                  ? this.state.videoDeviceId
                  : videoInputDevices[0].deviceId
                : null,
          });
        });
      },
      accessDenied: (event) => {
        this.setState({error: true, showSteps: true});
        errorToast({
          options: {
            autoClose: 10000,
          },
          content:
            "Please allow access to the Camera and Microphone and try publishing again.",
        });
      },
      audioLevelUpdated: (event) => {
        let movingAvg = null;
        if (movingAvg === null || movingAvg <= event.audioLevel) {
          movingAvg = event.audioLevel;
        } else {
          movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
        }
        // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
        var logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
        logLevel = Math.min(Math.max(logLevel, 0), 1);
        this.setState({audiLevel: logLevel * 100});
      },
    });
    var audioInputDevices;
    var videoInputDevices;

    console.log("OT", OT);
    // if (OT.capabilities.publish == 1) {
    // 	this.setState({error:false})
    // 	// The client can publish. See the next section.
    // } else {
    // 	alert("OUT")

    // 	this.setState({error:false})

    // 	// The client cannot publish.
    // 	// You may want to notify the user.
    // }
    OT.getDevices((error, devices) => {
      audioInputDevices = devices.filter(function (element) {
        return element.kind === "audioInput";
      });
      videoInputDevices = devices.filter((element) => {
        return element.kind === "videoInput";
      });

      this.setState({
        audioInputDevices: audioInputDevices,
        videoInputDevices: videoInputDevices,
        audioDeviceId:
          audioInputDevices.length !== 0
            ? this.state.audioDeviceId
              ? this.state.audioDeviceId
              : audioInputDevices[0].deviceId
            : null,
        videoDeviceId:
          videoInputDevices.length !== 0
            ? this.state.videoDeviceId
              ? this.state.videoDeviceId
              : videoInputDevices[0].deviceId
            : null,
      });
    });

    this.setState({
      publisherObj: publisher,
    });
  };

  onDeviceChange = async (value, deviceType) => {
    await publisher.destroy();
    if (deviceType === "audio") {
      await this.PublishInit(value, this.state.videoDeviceId);
    }
    if (deviceType === "video") {
      await this.PublishInit(this.state.audioDeviceId, value);
    }
  };

  continuteToVideoCallScreen = async () => {
    let {onRequestSetVideoCallStatus} = this.props.videoCallActions;
    let payload = {
      videoDeviceId: this.state.videoDeviceId
        ? this.state.videoDeviceId
        : false,
      audioDeviceId: this.state.audioDeviceId
        ? this.state.audioDeviceId
        : false,
      currentAudioOutputDevice: this.state.currentAudioOutputDevice
        ? this.state.currentAudioOutputDevice
        : false,
      blurType:
        this.state.bgRadio === "bg_blur" && this.state.blurType
          ? this.state.blurType
          : false,
      changeBackground: this.state.changeBackground
        ? this.state.changeBackground
        : false,
      isChecked: this.state.isChecked ? this.state.isChecked : false,
      bgImage:
        this.state.bgRadio === "bg_replacement" && this.state.bgImage
          ? this.state.bgImage
          : false,
      isWebMediaProcessorSupport: this.state.isWebMediaProcessorSupport
        ? this.state.isWebMediaProcessorSupport
        : false,
      speakerSupport: this.state.speakerSupport
        ? this.state.speakerSupport
        : false,
      bgRadio: this.state.bgRadio,
    };
    if (typeof onRequestSetVideoCallStatus === "function") {
      await store.dispatch({
        type: "VIDEO_CALL_SETTINGS",
        payload,
      });
      onRequestSetVideoCallStatus(true);
      let {navigateTo} = this.props.navigateTo;
      if (
        publisher &&
        publisher.destroy &&
        typeof publisher.destroy === "function"
      ) {
        await publisher.destroy();
      }
      console.log("this.state.publisherObj", this.state.publisherObj);
      if (
        this.state.publisherObj &&
        this.state.publisherObj.destroy &&
        typeof this.state.publisherObj.destroy === "function"
      ) {
        await this.state.publisherObj.destroy();
      }
      if (typeof navigateTo === "function") {
        let { otNetworkTest } = this.state;

        otNetworkTest.stop();
        this.state.audioFileState.pause();
        if (this.props.pre_consult_id) {
          navigateTo(
            `${appRoutesConst.videoAppointment}?pre_consult_id=${this.props.pre_consult_id}`
          );
        } else {
          navigateTo(appRoutesConst.videoAppointment);
        }
      }
    }
  };
  toggleAudioPlaying = async () => {
    let {audioFileState} = this.state;
    // await OT.setAudioOutputDevice(this.state.currentAudioOutputDevice);
    this.setState({playing: !this.state.playing}, () => {
      if (this.state.playing) {
        audioFileState.play();
      } else {
        audioFileState.pause();
      }
    });
  };
  rateMosScore = (mos, type) => {
    if (type === "audio") {
      if (mos >= 3.8) {
        this.setState({
          audioQualityClass: "excellent_status",
          audioQualityStatus: "Excellent",
        });
        return "Excellent";
      }
      if (mos >= 3.1) {
        this.setState({
          audioQualityClass: "good_status",
          audioQualityStatus: "Good",
        });

        return "Good";
      }
      if (mos >= 2.4) {
        this.setState({
          audioQualityClass: "fair_status",
          audioQualityStatus: "Fair",
        });

        return "Fair";
      }
      if (mos >= 1.7) {
        this.setState({
          audioQualityClass: "poor_status",
          audioQualityStatus: "Poor",
        });

        return "Poor";
      }
      this.setState({
        audioQualityClass: "bed_status",
        audioQualityStatus: "Bad",
      });

      return "Bad";
    } else {
      if (mos >= 3.8) {
        this.setState({
          videoQualityClass: "excellent_status",
          videoQualityStatus: "Excellent",
        });
        return "Excellent";
      }
      if (mos >= 3.1) {
        this.setState({
          videoQualityClass: "good_status",
          videoQualityStatus: "Good",
        });

        return "Good";
      }
      if (mos >= 2.4) {
        this.setState({
          videoQualityClass: "fair_status",
          videoQualityStatus: "Fair",
        });

        return "Fair";
      }
      if (mos >= 1.7) {
        this.setState({
          videoQualityClass: "poor_status",
          videoQualityStatus: "Poor",
        });

        return "Poor";
      }
      this.setState({
        videoQualityClass: "bed_status",
        videoQualityStatus: "Bad",
      });

      return "Bad";
    }
  };
  runNetwrokTest = () => {
    this.setState(
      {
        loadingResults: true,
        showPreCallTest: true,
        errorMessage: null,
      },
      () => {
        this._generateOpentokToken();
      }
    );
  };
  testQuality = () => {
    let {otNetworkTest} = this.state;
    this.setState({
      showWarnig: false,
      showError: false,
    });
    if (!this.state.isQualityPassed) {
      createChart("audio");
      createChart("video");
    }
    ConnectivityUI.init(this.props.appointmentType === APPT_TYPE_AUDIO);

    otNetworkTest
      .testConnectivity()
      .then((results) => {
        console.log("OpenTok connectivity test results", results);
        otNetworkTest
          .testQuality(function updateCallback(stats) {
            console.log("intermediate testQuality stats", stats);
            let elem = document.getElementById("graphContainer");
            if (elem) {
              elem.style.display = "flex";
              ConnectivityUI.graphIntermediateStats("audio", stats);
              ConnectivityUI.graphIntermediateStats("video", stats);
            }
          })
          .then((results) => {
            // This function is called when the quality test is completed.
            console.log("OpenTok quality results", results);

            this.setState({
              qualityResults: results,
              isQualityPassed: results !== null,
              loadingResults: false,
              errorInTest: false,
            });

            this.rateMosScore(results.audio.mos, "audio");
            this.rateMosScore(results.video.mos, "video");
            // ConnectivityUI.displayTestQualityResults(null, results);
            let publisherSettings = {};
            if (results.video.reason) {
              console.log("Video not supported:", results.video.reason);
              publisherSettings.videoSource = null; // audio-only
            } else {
              publisherSettings.frameRate = results.video.recommendedFrameRate;
              publisherSettings.resolution =
                results.video.recommendedResolution;
            }
            if (!results.audio.supported) {
              console.log("Audio not supported:", results.audio.reason);
              publisherSettings.audioSource = null;
              // video-only, but you probably don't want this -- notify the user?
            }
            if (
              !publisherSettings.videoSource &&
              !publisherSettings.audioSource
            ) {
              // Do not publish. Notify the user.
            } else {
              // Publish to the "real" session, using the publisherSettings object.
            }
          })
          .catch((error) => {
            console.log("error", error.message);
            this.setState({
              errorInTest: true,
              loadingResults: false,
              showError: true,
              showSuccess: false,
              showWarnig: false,
              errorMessage: error.message,
              isQualityPassed: false,
            });
            ConnectivityUI.displayTestQualityResults(error);
          });
      })
      .catch((error) => {
        console.log("OpenTok connectivity test error", error);
        this.setState({
          errorMessage: verifyObject(
            error,
            "message",
            "OpenTok connectivity test error"
          ),
        });
      });
  };

  handleRadioChange = (e) => {
    if (e.target.value === "bg_blur") {
      this.setState({bgRadio: e.target.value}, () => {
        if (this.state.bgRadio === "bg_blur") {
          publisher.applyVideoFilter({
            type: "backgroundBlur",
            blurStrength: this.state.blurType,
          });
        } else {
          this.setState({blurType: "low", bgImage: bg1}, () => {
            publisher.clearVideoFilter();
          });
        }
      });
    } else if (e.target.value === "bg_replacement") {
      this.setState({bgRadio: e.target.value}, () => {
        if (this.state.bgRadio === "bg_replacement") {
          publisher.applyVideoFilter({
            type: "backgroundReplacement",
            backgroundImgUrl: this.state.bgImage,
          });
        } else {
          this.setState({blurType: "low"}, () => {
            publisher.clearVideoFilter();
          });
        }
      });
    } else {
      this.setState(
        {bgRadio: e.target.value, blurType: "low", bgImage: bg1},
        () => {
          publisher.clearVideoFilter();
        }
      );
    }
  };

  render() {
    let {error, qualityResults, errorInTest, genrating, reruntest} = this.state;
    let {appointmentType} = this.props;

    // console.log("appointmentType", appointmentType);
    return (
      <div>
        <Prompt
          message={(location, action) => {
            if (action === "PUSH") {
              if (publisher) {
                publisher.destroy();
              }
            }
            if (action === "POP") {
              this.props.history.block((tx) => {});

              var r = window.confirm(
                "Are you sure want to leave this setup screen"
              );
              if (r === true) {
                let {onRequestSetVideoCallSetup} = this.props.videoCallActions;
                if (typeof onRequestSetVideoCallSetup === "function") {
                  onRequestSetVideoCallSetup(false);
                }
                if (publisher) {
                  store.dispatch({
                    type: "ON_SET_PATIENT_APPOINTMENT",
                    payload: null,
                  });
                  publisher.destroy();
                  let {navigateTo} = this.props.navigateTo;
                  if (typeof navigateTo === "function") {
                    navigateTo(appRoutesConst.dashboard);
                  }
                  window.location.reload();
                  return false;
                }
              } else {
                let {navigateTo} = this.props.navigateTo;
                if (typeof navigateTo === "function") {
                  navigateTo(appRoutesConst.callSetup);
                  return false;
                }
              }
            }
          }}
        />
        {!this.state.showSteps && (
          <Fragment>
            <div className="container-fluid">
              <div className="text-center margin-top-80">
                <div className="in-block max-width-630px">
                  {appointmentType === APPT_TYPE_AUDIO ? (
                    <p className="font-14 weight-400 text-default-color">
                      Please select microphone and speakers and we’ll complete
                      the set up process with a quick speed test
                    </p>
                  ) : (
                    <p className="font-14 weight-400 text-light-black">
                      Please select your camera, microphone and speakers and
                      we’ll complete the set up process with a quick speed test
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="row audio-video-setup-container padding-top-90">
              {
                <div
                  id="camera_div"
                  className="camera-container col-md-4 col-sm-6 col-xs-12 push-20-b"
                >
                  <div className="option-bg relative">
                    <span title="Camera">
                      <img alt="" src={camera}></img>
                    </span>
                    <div className="counter">
                      <span className="font-12 weight-500 text-fff">1</span>
                    </div>
                  </div>
                  <p className="text-center font-14 text-light-black margin-top-15 weight-500 ">
                    Setup Camera
                  </p>
                  <div
                    id="hardware-setup"
                    style={{
                      display:
                        appointmentType === APPT_TYPE_AUDIO ? "none" : "flex",
                    }}
                    className="push-20-b push-50-t"
                  ></div>
                  <div className="form-group add-pateint-form-container">
                    <div className="row">
                      <div className="col-md-12">
                        <select
                          onChange={(v) =>
                            this.setState(
                              {videoDeviceId: v.target.value},
                              () => {
                                this.onDeviceChange(v.target.value, "video");
                              }
                            )
                          }
                          className="form-control"
                          value={
                            this.state.videoDeviceId
                              ? this.state.videoDeviceId
                              : "default"
                          }
                        >
                          {this.state.videoInputDevices.map((v) => {
                            return (
                              <option key={uniqueId("VS")} value={v.deviceId}>
                                {v.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    {/* {this.state.isWebMediaProcessorSupport && (
                      <div style={{ marginTop: "10px" }}>
                        <Switch
                          disabled={this.state.changeBackground}
                          checked={this.state.isChecked}
                          onChange={(v) =>
                            this.setState({ isChecked: v }, () => {
                              if (this.state.isChecked) {
                                publisher.applyVideoFilter({
                                  type: "backgroundBlur",
                                  blurStrength: v.target.value,
                                });
                              } else {
                                this.setState({ blurType: "low" }, () => {
                                  publisher.clearVideoFilter();
                                });
                              }
                            })
                          }
                          id="bg_blur"
                        />
                        <label htmlFor="bg_blur">Blur background</label>
                        <div>
                          {this.state.isChecked && (
                            <Radio.Group
                              value={this.state.blurType}
                              onChange={(v) =>
                                this.setState(
                                  { blurType: v.target.value },
                                  () => {
                                    publisher.applyVideoFilter({
                                      type: "backgroundBlur",
                                      blurStrength: v.target.value,
                                    });
                                  }
                                )
                              }
                            >
                              <Radio value="low">Low</Radio>
                              <Radio value="high">High</Radio>
                            </Radio.Group>
                          )}
                        </div>
                      </div>
                    )}
                    {this.state.isWebMediaProcessorSupport && (
                      <div style={{ marginTop: "10px" }}>
                        <Switch
                          disabled={this.state.isChecked}
                          checked={this.state.changeBackground}
                          onChange={(v) =>
                            this.setState({ changeBackground: v }, () => {
                              if (this.state.changeBackground) {
                                publisher.applyVideoFilter({
                                  type: "backgroundReplacement",
                                  backgroundImgUrl: this.state.bgImage,
                                });
                              } else {
                                this.setState({ blurType: "low" }, () => {
                                  publisher.clearVideoFilter();
                                });
                              }
                            })
                          }
                          id="bg_change"
                        />
                        <label htmlFor="bg_change">Change background</label>
                        <div>
                          {this.state.changeBackground && (
                            <Radio.Group
                              value={this.state.bgImage}
                              onChange={(v) =>
                                this.setState(
                                  { bgImage: v.target.value },
                                  () => {
                                    publisher.applyVideoFilter({
                                      type: "backgroundReplacement",
                                      backgroundImgUrl: v.target.value,
                                    });
                                  }
                                )
                              }
                            >
                              <Radio value={bg1}>Bg1</Radio>
                              <Radio value={bg2}>Bg2</Radio>
                              <Radio value={bg3}>Bg3</Radio>
                              <Radio value={bg4}>Bg4</Radio>
                            </Radio.Group>
                          )}
                        </div>
                      </div>
                    )} */}
                  </div>
                </div>
              }
              <div className="audio-container col-md-4 col-sm-6 col-xs-12 push-20-b">
                <div className="option-bg relative">
                  <span title="Microphone">
                    <img alt="" src={microphone}></img>
                  </span>
                  <div className="counter">
                    <span className="font-12 weight-500 text-fff">
                      {appointmentType === APPT_TYPE_AUDIO ? 1 : 2}
                    </span>
                  </div>
                </div>
                <p className="text-center font-14 text-light-black margin-top-15 weight-500 ">
                  Setup Microphone
                </p>
                <div className="height-180 push-20-b push-50-t padding-60-t text-center">
                  <Progress
                    percent={this.state.audiLevel}
                    // percent={50}
                    steps={40}
                    size="large"
                    strokeColor="rgb(46 164 250)"
                    showInfo={false}
                    className="audio-visulizer"
                  />
                  {/* 
	<Progress percent={this.state.audiLevel} steps={2} size="small" strokeColor="#52c41a" /> */}
                </div>
                <div className="form-group add-pateint-form-container">
                  <div className="row">
                    <div className="col-md-12">
                      <select
                        onChange={(v) =>
                          // this.onDeviceChange(v.target.value, "audio")
                          this.setState({audioDeviceId: v.target.value}, () => {
                            publisher
                              .setAudioSource(v.target.value)
                              .then(() => console.log("audio source set"))
                              .catch((error) => console.error(error.name));
                          })
                        }
                        className="form-control"
                      >
                        {this.state.audioInputDevices.map((v) => {
                          return (
                            <option key={v.deviceId} value={v.deviceId}>
                              {v.label}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="speaker-container col-md-4 col-sm-6 col-xs-12 push-20-b">
                <div className="option-bg relative">
                  <span title="Speaker">
                    <img alt="" src={speaker}></img>
                  </span>
                  <div className="counter">
                    <span className="font-12 weight-500 text-fff">
                      {" "}
                      {appointmentType === APPT_TYPE_AUDIO ? 2 : 3}
                    </span>
                  </div>
                </div>
                <p className="text-center font-14 text-light-black margin-top-15 weight-500 ">
                  Test Speakers
                </p>
                <div className="height-180 push-20-b push-50-t padding-60-t text-center">
                  <button
                    className="btn btn-custom btn-gray width-255 font-14 height-big"
                    onClick={this.toggleAudioPlaying}
                  >
                    {this.state.playing ? "Playing..." : "Click To Test"}
                  </button>
                  <p className="font-13 weight-400 text-light-black text-center push-20-t">
                    Can you hear the sound?
                  </p>
                </div>
                {this.state.speakerSupport && (
                  <div className="form-group add-pateint-form-container">
                    <div className="row">
                      <div className="col-md-12">
                        <select
                          onChange={(v) =>
                            this.setState(
                              {currentAudioOutputDevice: v.target.value},
                              async () => {
                                // console.log(
                                //   "audio out devide===>",
                                //   this.state.currentAudioOutputDevice
                                // );
                                await OT.setAudioOutputDevice(
                                  // this.state.currentAudioOutputDevice
                                  v.target.value
                                );

                                // this.cycleAudioOutput();
                                // publisher2
                                //   .setAudioSource(v.target.value)
                                //   .then(() => console.log("audio out source set"))
                                //   .catch((error) => console.error(error.name));
                              }
                            )
                          }
                          className="form-control"
                          value={this.state.currentAudioOutputDevice}
                        >
                          {this.state.audioOutputDevice &&
                            this.state.audioOutputDevice.length > 1 &&
                            this.state.audioOutputDevice.map((v) => {
                              return (
                                <option key={uniqueId("VS")} value={v.deviceId}>
                                  {v.label}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Fragment>
        )}
        {this.state.showSteps && (
          <div className="container-fluid">
            <VideoCallSetupInstructions />
          </div>
        )}

        {!this.state.loadingResults &&
          !this.state.loadingGraph &&
          !this.state.isQualityPassed &&
          !error && (
            <div style={{textAlign: "center"}}>
              <Button
                className="btn btn-custom btn-blue width-255 font-14 height-big"
                onClick={this.runNetwrokTest}
              >
                {errorInTest ? "Re-Run Network Test" : "Run Network Test"}
              </Button>
            </div>
          )}

        <ModalPopUp
          handleCancel={() => {
            console.log("Stoped OT NETWORK");
            let {otNetworkTest} = this.state;
            otNetworkTest.stop();
            this.setState({
              qualityResults: null,
            });
            if (qualityResults !== null || errorInTest === true) {
              ConnectivityUI.getGraphDefaulStats();

              document.getElementById("graphContainer").style.display = "none";
              this.setState({
                showPreCallTest: false,
                closed: true,
              });
            }
          }}
          visible={this.state.showPreCallTest}
          closable={qualityResults !== null || errorInTest === true}
          width={"70%"}
          className="precalltestmodal"
          maskClosable={false}
        >
          <PreCallTest
            {...this.state}
            {...this.props}
            continuteToVideoCallScreen={this.continuteToVideoCallScreen}
            otNetworkTest={this.state.otNetworkTest}
          />
          {!reruntest && genrating && (
            <div
              style={{
                // padding: 30,
                // textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "unset",
                border: "unset",
                boxShadow: "unset",
              }}
              id="quality_header"
              className="push-10-b prettyBox"
            >
              <h2 className="weight-500">Pre Call Test</h2>
              <p className="text-light-black weight-400">Initiating...</p>
              <img
                alt=""
                src={require("../../assets/images/common/loading.svg").default}
              />
            </div>
          )}
        </ModalPopUp>
        {/* <div id="connectivity_status_container" className="prettyBox">
          <h2 className="weight-500">Pre Call Test</h2>
          {this.state.loadingResults && (
            <p className="text-light-black weight-400">
              Checking your audio/video quality...
            </p>
          )}
          <p className="text-light-black weight-400 to-be-hide"></p>
          {this.state.loadingResults && (
            <img
              alt=""
              src={require("../../assets/images/common/loading.svg").default}
            />
          )}
          {showError && <img alt="" src={ErrorSVG} />}
          {showSuccess && <img alt="" src={PassSVG} />}
          {showWarnig && <img alt="" src={WarningSVG} />}
        </div>
        <div id="quality_status_container" className="prettyBox">
          <div id="quality_header" className="push-30-b">
            <h2 className="weight-500">Pre Call Test</h2>
            {this.state.loadingResults && (
              <p className="text-light-black weight-400">
                Checking your audio/video quality...
              </p>
            )}
            <p className="text-light-black weight-400 to-be-hide"></p>
            {this.state.loadingResults && (
              <img
                alt=""
                src={require("../../assets/images/common/loading.svg").default}
              />
            )}
            {showError && <img alt="" src={ErrorSVG} />}
            {showSuccess && <img alt="" src={PassSVG} />}
            {showWarnig && <img alt="" src={WarningSVG} />}
          </div>
          {
            <div className="test_wrapper">
              <div id="audio">
                <h3 className="weight-500 font-16">Audio</h3>
                <div>
                  <div className="results">
                    <div className="">
                      <p>
                        Supported:
                        <span id="audio-supported"></span>
                      </p>
                    </div>
                    <div className="">
                      <p className={`quality ${audioQualityClass}`}>
                        Quality:
                        <span id="audio-mos"></span>
                      </p>
                    </div>
                    <div className="">
                      <p>
                        Bitrate:
                        <span id="audio-bitrate"></span>
                      </p>
                    </div>
                    <div className="">
                      <p>
                        Packet loss ratio:
                        <span id="audio-plr"></span>
                      </p>
                    </div>
                  </div>
                  <div id="audioGraph" className="graph"></div>
                </div>
              </div>
              <div id="video">
                <h3 className="weight-500 font-16">Video</h3>
                <div>
                  <div className="results">
                    <div className="">
                      <p>
                        Supported:
                        <span id="video-supported"></span>
                      </p>
                    </div>
                    <div className="">
                      <p id="video-unsupported-reason">
                        Reason:
                        <span></span>
                      </p>
                    </div>
                    <div className="">
                      <p className={`quality ${videoQualityClass}`}>
                        Quality:
                        <span id="video-mos"></span>
                      </p>
                    </div>
                    <div className="">
                      <p className="">
                        Bitrate:
                        <span id="video-bitrate"></span>
                      </p>
                    </div>
                    <div className="">
                      <p className="">
                        Packet loss ratio:
                        <span id="video-plr"></span>
                      </p>
                    </div>
                    <div className="">
                      <p className="">
                        Recommended resolution:
                        <span id="video-recommendedResolution"></span>
                      </p>
                    </div>
                    <div className="">
                      <p className="">
                        Recommended frame rate:
                        <span id="video-recommendedFrameRate"></span>
                      </p>
                    </div>
                  </div>
                  <div id="videoGraph" className="graph"></div>
                </div>
              </div>
            </div>
          }
        </div> */}

        {
          <div className="text-center padding-bottom-230 push-40-t">
            {!error && this.state.isQualityPassed && (
              <button
                className="btn btn-custom btn-blue width-255 font-14 height-big"
                onClick={this.continuteToVideoCallScreen}
              >
                Continue
              </button>
            )}

            {this.state.showError && (
              <button
                className="btn btn-custom btn-blue width-255 font-14 height-big"
                onClick={this.continuteToVideoCallScreen}
              >
                Skip to Call
              </button>
            )}

            {this.state.closed && !errorInTest && (
              <button
                className="btn btn-custom re-run-btn width-255 font-14 height-big"
                onClick={() => {
                  this.setState({reruntest: true}, () => {
                    this.runNetwrokTest();
                  });
                }}
                // style={{ marginLeft: 10, backgroundColor: "#E9E9E9" }}
              >
                Re-Run Network Test
              </button>
            )}
          </div>
        }
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    isVideoCallSetup: verifyObject(state, "localStore.is_video_call_setup", []),
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
    appointmentType: verifyObject(
      state.localStore,
      "appointment_obj.appointment_type",
      null
    ),
    pre_consult_id: verifyObject(state, "localStore.appointment_obj.pre_consult_id", null),

    isVideoCallStarted: verifyObject(
      state.localStore,
      "is_video_call_start",
      null
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    videoCallActions: bindActionCreators(
      {onRequestSetVideoCallSetup, onRequestSetVideoCallStatus},
      dispatch
    ),
    navigateTo: bindActionCreators({navigateTo}, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(VideoCallSetupContainer);

export default withRouter(ConnectedComponent);

import React, {Component, Fragment} from "react";
import {Modal, Checkbox, Button} from "antd";
import {connect} from "react-redux";
import validator from "validator";
import moment from "moment";
import {disableCopy, verifyObject} from "utilities/utils";

class SafeguardAlertModal extends Component {
  constructor() {
    super();
    this.state = {
      isLoading: false,
      alertDetails: {
        problem: "",
        office_action_needed: false,
        action_detail: "",
      },
      errors: {},
    };
    ["_onInputChange", "_onFormSubmit", "_validateIp"].forEach(
      (fn) => (this[fn] = this[fn].bind(this))
    );
  }

  _onFormSubmit = async (_) => {
    let data = {...this.state.alertDetails};
    await this.setState({
      ...this.state,
      errors: {
        ...this.state.errors,
        problem:
          !data.problem && validator.isEmpty(data.problem)
            ? "Please enter concern summary"
            : null,
        action_detail:
          data.office_action_needed && validator.isEmpty(data.action_detail)
            ? "Please enter action detail"
            : null,
      },
    });

    let errCount = 0;
    Object.values(this.state.errors).forEach(
      (err) => err !== null && errCount++
    );
    if (errCount === 0) {
      this.props.onSubmit(data);
    }
  };
  _validateIp = (e) => {
    switch (e.target.name) {
      case "problem":
        this.setState({
          errors: {
            ...this.state.errors,
            problem: validator.isEmpty(e.target.value)
              ? "Please enter concern summary"
              : null,
          },
        });
        break;
      case "action_detail":
        this.setState({
          errors: {
            ...this.state.errors,
            action_detail:
              validator.isEmpty(e.target.value) &&
              this.state.office_action_needed
                ? "Please enter action details"
                : null,
          },
        });
        break;
      default:
        break;
    }
  };
  _onInputChange = (e) => {
    this.setState({
      ...this.state,
      alertDetails: {
        ...this.state.alertDetails,
        [e.target.name]:
          e.target.name === "office_action_needed"
            ? e.target.checked
            : e.target.value,
      },
    });

    this._validateIp(e);
  };

  render() {
    const {props} = this;
    const {onClose, patientDetails} = props;
    const {modal} = props.emergencyAlert;
    const {isOpen} = modal;
    const {alertDetails, errors} = this.state;

    return (
      <Fragment>
        <Modal
          footer={null}
          title="Emergency Alert"
          visible={isOpen}
          onCancel={this.props.emergencyAlert.isLoading ? false : onClose}
          width={800}
          maskClosable={false}
        >
          <form action="">
            <div className="m-0 form-group">
              <div className="form-row">
                <div className="col-md-12 col-lg-6">
                  <label>Patient Name</label>
                  <div className="col-md-12 p-0">
                    <input
                      onCopy={disableCopy}
                      disabled
                      className="form-control margin-0 placeholder-10 text-capitalize"
                      type="text"
                      placeholder="Patient Name"
                      name="patient_name"
                      value={
                        patientDetails &&
                        patientDetails.first_name &&
                        patientDetails.last_name
                          ? `${patientDetails.first_name} ${patientDetails.last_name}`
                          : ""
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <label>Patient DOB</label>
                  <div className="col-md-12 p-0">
                    <input
                      onCopy={disableCopy}
                      disabled
                      className="form-control margin-0 placeholder-10"
                      type="text"
                      placeholder="Patient DOB"
                      name="patient_dob"
                      value={
                        patientDetails && patientDetails.date_of_birth
                          ? moment(patientDetails.date_of_birth).format(
                              "DD/MM/YYYY"
                            )
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <label>What is the emergency?</label>
                  <div className="col-md-12 p-0">
                    <textarea
                      className="form-control margin-0 placeholder-10 h-100"
                      placeholder="Provide details of emergency"
                      name="problem"
                      value={alertDetails.problem}
                      onChange={this._onInputChange}
                      onBlur={this._validateIp}
                    ></textarea>
                    {errors && errors.problem && (
                      <span className="form-error">{errors.problem}</span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-3">
                <div className="col-md-12">
                  <Checkbox
                    name="office_action_needed"
                    onChange={this._onInputChange}
                    checked={alertDetails.office_action_needed}
                  >
                    Does the office needs to take any immediate actions?
                  </Checkbox>
                </div>
              </div>
              {this.state.alertDetails.office_action_needed && (
                <div className="row mt-3">
                  <div className="col-md-12">
                    <label>Action Detail</label>
                    <div className="col-md-12 p-0">
                      <textarea
                        className="form-control margin-0 placeholder-10 h-100"
                        placeholder="Provide the detail of action"
                        onChange={this._onInputChange}
                        onBlur={this._validateIp}
                        name="action_detail"
                      ></textarea>
                      {errors && errors.action_detail && (
                        <span className="form-error">
                          {errors.action_detail}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              )}
              <div className="row mt-3">
                <div className="col-sm-12">
                  <Button
                    type="primary"
                    disabled={this.props.emergencyAlert.isLoading}
                    loading={this.props.emergencyAlert.isLoading}
                    onClick={this._onFormSubmit}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </div>
          </form>
        </Modal>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientDetails: verifyObject(state.localStore, "patient_detail_obj", null),
  };
};

export default connect(mapStateToProps, null)(SafeguardAlertModal);

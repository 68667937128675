import validator from "validator";
import isEmpty from "lodash/isEmpty";

export function ValidateInput(data, from) {
  console.log("DATA", data);
  console.log("isEmpty(data.review_by_id)", isEmpty(data.review_by_id));
  let errors = {};
  let fromPreConsult = from === "fromPreConsult" ? true : false;
  if (
    data.history !== undefined &&
    data.history !== null &&
    validator.isEmpty(data.history)
  ) {
    errors.history = "Please provide the patient history";
  }

  if (
    data.examination !== undefined &&
    data.examination !== null &&
    validator.isEmpty(data.examination)
  ) {
    errors.examination = "Please provide the patient examination detail";
  }

  if (
    data.managementPlan !== undefined &&
    data.managementPlan !== null &&
    validator.isEmpty(data.managementPlan)
  ) {
    errors.managementPlan = "Please provide the patient management detail";
  }
  // if (
  //   data.prescription_plan !== undefined &&
  //   validator.isEmpty(data.prescription_plan)
  // ) {
  //   errors.prescription_plan =
  //     "Please provide the patient prescription plan detail";
  // }

  if (
    data.visible &&
    data.treatment !== undefined &&
    validator.isEmpty(data.treatment) &&
    !fromPreConsult
  ) {
    errors.treatment = "Select treatment.";
  }
  if (
    data.visible &&
    data.reviewer !== undefined &&
    validator.isEmpty(data.reviewer) &&
    !fromPreConsult
  ) {
    errors.treatment = "Select reviewer.";
  }
  if (
    data.reviewer &&
    !validator.isEmpty(data.reviewer) &&
    data.reviewer === "ip_users" &&
    data.review_by_id !== null &&
    data.review_by_id !== undefined &&
    validator?.isEmpty(data.review_by_id?.toString()) &&
    !fromPreConsult
  ) {
    errors.review_by_id = "Select Ip User ";
  }
  if (
    data.visible &&
    data.followUp !== undefined &&
    validator.isEmpty(data.followUp) &&
    !fromPreConsult
  ) {
    errors.followUp = "Select followUp value.";
  }

  if (
    data.visible &&
    data.referral !== undefined &&
    validator.isEmpty(data.referral) &&
    !fromPreConsult
  ) {
    errors.referral = "Select referral value.";
  }
  // if (
  //   data.visible &&
  //   data.comment !== undefined &&
  //   validator.isEmpty(data.comment)
  // ) {
  //   errors.comment = "Please provide comment.";
  // }

  // if (
  //   data.visible &&
  //   data.comment !== undefined &&
  //   /^(?=.*\S).+$/.test(data.comment) === false
  // ) {
  //   errors.comment = "Please provide comment.";
  // }
  console.log("111---Error---", errors, isEmpty(errors), data);
  return {errors, isValid: isEmpty(errors)};
}

/* eslint-disable react-hooks/exhaustive-deps */
import React, {Fragment, useEffect, useState} from "react";
import {
  Button,
  Popover,
  // Collapse,
  Spin,
  Table,
  // Popover,
} from "antd";

import {
  abbrevationDiagnosis,
  diagnosiColorArray,
  // errorToast,
  // getErrorObject,
  verifyObject,
} from "utilities/utils";
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  // QuestionCircleOutlined,
  MinusOutlined,
  FlagFilled,
  FlagOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";

import {isArray, isEmpty} from "lodash";

// import ModalPopUp from "components/common/ModalPopUp";
import {fetchLinksFromKnowledgeURL} from "../../services/symptoms";
import {errorToast, getErrorObject} from "../../utilities/utils";
import store from "../../app/store";
import {searchSnomedCode} from "../../services/snomedCodes";
// import {fetchWhyKnowledgeAPI} from "services/symptoms";

const SymmtomDiagnosisTabs = (props) => {
  const allData = verifyObject(props.diagnosisData, "all", []);
  const redFlagData = verifyObject(props.diagnosisData, "red_flag", []);
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [clickedFlag, setClickedFlag] = useState(false);
  const [data, setData] = useState(allData);
  useEffect(() => {
    if (!clickedFlag) {
      setData(allData);
    } else {
      setData(redFlagData);
    }
  }, [allData, clickedFlag, redFlagData]);

  const [state, setState] = useState({
    searching: false,
    why_knowledge_data: {},
  });
  const presentingpopover = (
    <div className="checklistinfomain">
      <p>
        <b>Ranking Criteria:</b> The list of diseases displayed are ranked by
        how well the criteria entered (age range, sex, geographical region and
        clinical features together with their associated synonyms) match our
        database of disease presentations.
      </p>
      <div className="rankuingchart">
        <ul>
          <li>
            <span className="more_likely"></span>
            more likely
          </li>
          <li>
            <span className="likely"></span>
            likely
          </li>
          <li>
            <span className="less_likely"></span>
            less likely
          </li>
        </ul>
      </div>
      <p>
        <b>Likelihood Indicator:</b> This is the ranking of the diseases shown
        by the vertical orange bar. The darkest colour being the more likely and
        the faintest being the less likely within all the diagnoses suggested.
        This match is the degree of relevance in percent calculated by
        sophisticated pattern recognition software adjusted by age, sex,
        pregnancy and geographical region prevalence scores.
      </p>
      <span className="smalltext">
        *Please note that the ranking of the list is based purely on the
        criteria entered and is not the same as the clinical probability of a
        specific condition for a specific patient.
      </span>
      <hr />
      <p>
        <b>Drugs:</b> This provides a list of generic drugs where side effects
        could be the cause of the symptoms.
      </p>
      <hr />
      <p>
        <b>Feedback:</b> Is this a good suggestion for your query? If so, select
        the <b>'thumbs up'</b> icon. If not, select the
        <b className="ml-1">'thumbs down'</b> icon. To elaborate on your reasons
        or for any other feedback use the feedback box provided.
      </p>
      <hr />
      <p>
        To review results by{" "}
        <b>
          <u>specialty</u>
        </b>
        , select the specialty you are interested in. This will show all the
        diagnoses for the specialty selected from the original results.
      </p>
    </div>
  );
  // const showModal = () => {
  //   setIsModalOpen(true);
  // };

  // const handleCancel = () => {
  //   setIsModalOpen(false);
  // };

  const handleWindowURL = async (record) => {
    store.dispatch({
      type: "SET_SYMPTOMP_FETCHED_LINKS",
      payload: null,
    });
    store.dispatch({
      type: "SET_SYMPTOMP_FETCHING",
      payload: true,
    });
    try {
      let response = await fetchLinksFromKnowledgeURL({
        url: record.knowledge_window_api_url,
        patient_id: props.patient_id,
      });
      console.log("response", response?.data?.data?.data);
      // Object.keys()

      store.dispatch({
        type: "SET_SYMPTOMP_FETCHED_LINKS",
        payload: {
          data: response?.data?.data?.data,
          diagnosis: record,
        },
      });
      store.dispatch({
        type: "SET_SYMPTOMP_FETCHING",
        payload: false,
      });
      if (record?.snomed_diagnosis_id) {
        let snomedResponse = await searchSnomedCode({
          is_diagnosis: true,
          search: record?.snomed_diagnosis_id,
        });
        let searchedSnomedCodes = snomedResponse?.data?.data;
        let foundSnomedCode = searchedSnomedCodes?.find(
          (d) => d.code === record?.snomed_diagnosis_id
        );
        console.log(
          "snomedResponse",
          searchedSnomedCodes?.find(
            (d) => d.code === record?.snomed_diagnosis_id
          )
        );
        let elem = document.getElementById("diagnosis-fetchedLinks");
        if (elem) {
          elem.scrollIntoView({behavior: "smooth"});
        }
        props.updateSnomedCodes(foundSnomedCode);
      }
    } catch (e) {
      const {message} = getErrorObject(e);
      errorToast({
        content: message,
      });
      store.dispatch({
        type: "SET_SYMPTOMP_FETCHING",
        payload: false,
      });
    }
  };

  const renderBackgroundColor = (value) => {
    if (value) {
      // Parse the percentage value to a number
      const numericPercentage = parseFloat(value);
      // Loop through the diagnosiColorArray to find the matching color range
      for (const range of diagnosiColorArray) {
        if (numericPercentage >= range.min && numericPercentage <= range.max) {
          return range.color;
        }
      }
    }
  };

  const columns = [
    {
      title: (
        <div className="tableheading d-flex align-items-center justify-content-between">
          <div className="leftheading  d-flex align-items-center">
            <b>Differential Diagnoses</b>
            <div className="infoicon ml-2">
              <Popover content={presentingpopover} title="Info">
                <Button className="infobtn">
                  <InfoCircleOutlined />
                </Button>
              </Popover>
            </div>
          </div>
          <div className="rightheading">
            <Button type="link" onClick={() => setClickedFlag(!clickedFlag)}>
              <span style={{color: "red"}}>
                {clickedFlag ? <FlagFilled /> : <FlagOutlined />}
              </span>
            </Button>
          </div>
        </div>
      ),
      dataIndex: "diagnosis_name",
      key: "diagnosis_name",
      render: (text, record) => {
        if (!record) {
          return;
        }
        return (
          <span>
            <div className="panelmainwrap">
              <div className="panelmainbox">
                <div className="panelrow">
                  <div
                    className="vertical-color-fill"
                    style={{
                      backgroundColor: renderBackgroundColor(record?.weightage),
                    }}
                  ></div>
                  <div className="icon">
                    {record?.sub_diagnoses &&
                      record?.sub_diagnoses?.responsible_sub_diagnosis &&
                      isArray(
                        record?.sub_diagnoses?.responsible_sub_diagnosis
                      ) &&
                      record?.sub_diagnoses?.responsible_sub_diagnosis
                        .length !== 0 && (
                        <a>
                          {!state[`panel_${record?.diagnosis_id}`] ? (
                            <PlusCircleOutlined
                              onClick={() => {
                                setState((prevState) => {
                                  return {
                                    ...prevState,
                                    [`panel_${record.diagnosis_id}`]: true,
                                  };
                                });
                              }}
                            />
                          ) : (
                            <MinusCircleOutlined
                              onClick={() => {
                                setState((prevState) => {
                                  return {
                                    ...prevState,
                                    [`panel_${record.diagnosis_id}`]: false,
                                  };
                                });
                              }}
                            />
                          )}
                        </a>
                      )}
                  </div>
                  <div className="symtomsquestion">
                    <h3>
                      <span
                        onClick={() => {
                          if (record)
                            // window.open(record?.knowledge_window_url, "_blank");
                            handleWindowURL(record);
                        }}
                        style={{cursor: "pointer"}}
                      >
                        {" "}
                        {text}
                      </span>{" "}
                      {record?.red_flag === "true" ? (
                        <span style={{color: "red", marginLeft: "5px"}}>
                          <FlagFilled />
                        </span>
                      ) : (
                        ""
                      )}
                    </h3>
                  </div>
                  {record?.specialty &&
                  abbrevationDiagnosis[record?.specialty.replace(/ +/g, "")] ? (
                    <div className="symtomscode">
                      <p>
                        {record?.specialty &&
                          abbrevationDiagnosis[
                            record?.specialty.replace(/ +/g, "")
                          ]}
                      </p>
                    </div>
                  ) : (
                    <div className="symtomscode">
                      <p>{record?.specialty}</p>
                    </div>
                  )}
                  {/* <div className="symtomsinfoicon">
                    <Popover content={content} placement="rightBottom">
                      <Button>
                        <QuestionCircleOutlined
                          onClick={() => {
                            if (record?.why_diagnosis_url) {
                              handleQuestionIcon(
                                record.why_diagnosis_url,
                                record.diagnosis_id
                              );
                            }
                          }}
                        />
                      </Button>
                    </Popover>
                  </div> */}
                </div>
                {/* question show div */}
                {/* {state[`searching${record?.diagnosis_id}`] ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <Spin />
                  </div>
                ) : (
                  <div
                    className={`questionbox ${
                      state[`open${record.diagnosis_id}`] ? "show" : "hide"
                    }`}
                  >
                    <div className="questionhead d-flex justify-content-between text-capitalize">
                      <h3>{`Why did this come up? ${
                        state[`why_knowledge_data${record.diagnosis_id}`]?.data
                          ? state[
                              `why_knowledge_data${record.diagnosis_id}`
                            ]?.data
                              .split(",")
                              .join("|")
                          : ""
                      }`}</h3>
                      <button
                        type="button"
                        aria-label="Close"
                        class="ant-modal-close"
                        onClick={() => {
                          setState({
                            ...state,
                            [`open${record?.diagnosis_id}`]: false,
                          });
                        }}
                      >
                        <span class="ant-modal-close-x">
                          <span
                            role="img"
                            aria-label="close"
                            class="anticon anticon-close ant-modal-close-icon"
                          >
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="close"
                              width="1em"
                              height="1em"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                            </svg>
                          </span>
                        </span>
                      </button>
                    </div>
                    <div className="questionboxcontent">
                      <p>
                        {`Degree of match or relevance score between query entered
                      and Isabel database: ${
                        record?.weightage ? record?.weightage : ""
                      } (NB: this is not clinical
                      probability)`}
                      </p>
                    </div>
                  </div>
                )} */}
                {/* question show div */}
                {/* child div */}
                <div
                  className={`"panelrow_child ${
                    state[`panel_${record.diagnosis_id}`] ? "show" : "hide"
                  }`}
                >
                  <div className="panelrow">
                    <div className="icon"></div>
                    <div className="childsystomquestion">
                      {record?.sub_diagnoses &&
                        !isEmpty(record?.sub_diagnoses) &&
                        isArray(
                          record?.sub_diagnoses?.responsible_sub_diagnosis
                        ) &&
                        record?.sub_diagnoses?.responsible_sub_diagnosis
                          .length > 0 &&
                        record.sub_diagnoses.responsible_sub_diagnosis.map(
                          (res) => {
                            return (
                              <div className="symtomsquestion">
                                <h3>
                                  <span
                                    onClick={() => {
                                      // handleWindowURL(res);
                                    }}
                                    style={{cursor: "pointer"}}
                                  >
                                    {" "}
                                    <MinusOutlined className="mr-2" />
                                    {`${res?.name ? res?.name : ""}`}
                                  </span>{" "}
                                  {res?.red_flag === "true" ? (
                                    <span
                                      style={{
                                        color: "red",
                                        marginLeft: "5px",
                                      }}
                                    >
                                      <FlagFilled />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </h3>
                              </div>
                            );
                          }
                        )}
                    </div>
                    {}
                    <div className="symtomscode"></div>
                    <div className="symtomsinfoicon"></div>
                  </div>
                </div>
                {/* child div */}
              </div>
            </div>
          </span>
        );
      },
    },
  ];
  // const content = (
  //   <div className="questionpopover">
  //     <p className="m-0">Why did this come up?</p>
  //   </div>
  // );
  return (
    <Fragment className={`${props.disabled ? "disabled-view" : ""}`}>
      <Spin spinning={props.loading}>
        <Table
          dataSource={data}
          columns={columns}
          scroll={{y: "134px"}}
          pagination={false}
          disa
        />
      </Spin>
    </Fragment>
  );
};
export default SymmtomDiagnosisTabs;

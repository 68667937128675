/* eslint-disable eqeqeq */
import {
  // Input,
  Checkbox,
} from "antd";
import {SPLIT_SYMBOL} from "constants/common";
import {isArray} from "lodash";
import React, {Fragment, useEffect, useRef} from "react";

export default function CheckboxGroupQuestion({
  handleAnswerChange,
  childQuestions,
  parentQuestion,
  question,
  childOfQuestions,
  gender,
}) {
  console.log("Checked items------------------------------->", question);
  const questionDivRef = useRef(null);

  useEffect(() => {
    const questionDiv = questionDivRef.current;

    if (questionDiv) {
      const nodeList = questionDiv.querySelectorAll("ul li");

      nodeList.forEach((element) => {
        // Remove the existing inline style with !important
        element.style.removeProperty("font-size");

        // Add your new style without !important
        element.style.fontSize = "16px"; // Set your desired font size
      });
    }
  }, []);
  if (
    question.is_conditional &&
    childQuestions &&
    (parentQuestion.category === "single_choice" ||
      parentQuestion.category === "multi_choice" ||
      parentQuestion.category === "numerical") &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    let selectedOption = parentQuestion.answer;
    if (parentQuestion.category === "single_choice") {
      if (
        question?.conditional_text_values &&
        question?.conditional_text_values[0] === selectedOption
      ) {
        return (
          <Fragment>
            <div className="checkbox-group-question_wrapper checkbox-group-question_1">
              <div
                className={`single_choice_wrapper ${
                  question.question_options.length > 2 ? "full_box" : ""
                }`}
              >
                <div className="weight-500 font-14 margin-bottom-10 d-flex">
                  <span className="dot"></span>
                  <span
                    dangerouslySetInnerHTML={{__html: question.title}}
                    className="Questions"
                    ref={questionDivRef}
                  />
                  {question.is_required ? (
                    <sup className="text-danger">*</sup>
                  ) : null}
                </div>
                <div className="push-12-l">
                  <Fragment>
                    <Checkbox.Group
                      onChange={(checkedItems) => {
                        console.log("checkedItems 4", checkedItems);
                        const val = checkedItems;
                        handleAnswerChange(
                          val,
                          parentQuestion,
                          childQuestions,
                          childOfQuestions,
                          true
                        );
                      }}
                      value={question.chekedAnswer ? question.chekedAnswer : []}
                    >
                      {question.question_options.map((d, index) => {
                        return (
                          <Checkbox
                            key={index}
                            value={`${d.title}${SPLIT_SYMBOL}${d.id}`}
                          >
                            {d.title}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </Fragment>
                </div>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <div></div>;
      }
    } else if (parentQuestion.category === "multi_choice") {
      console.log("Please selectedOption", selectedOption && selectedOption);
      let AllOptions =
        selectedOption &&
        isArray(selectedOption) &&
        selectedOption.map((d) => d.split(SPLIT_SYMBOL)[0]);

      console.log("Please All options", AllOptions);
      console.log(
        "question?.conditional_text_values",
        question?.conditional_text_values
      );

      if (
        question?.conditional_text_values &&
        AllOptions &&
        AllOptions[0] === question?.conditional_text_values[0] &&
        AllOptions.toString() === question?.conditional_text_values?.toString()
      ) {
        return (
          <Fragment>
            <div className="checkbox-group-question_wrapper checkbox-group-question_2">
              <div
                className={`multi_choice_Wrapper multi_choice_Wrapper_one ${
                  question.question_options.length > 2 ? "full_box" : ""
                }`}
              >
                <div className="weight-500 font-14 margin-bottom-10 d-flex">
                  <span className="dot"></span>
                  <span
                    dangerouslySetInnerHTML={{__html: question.title}}
                    className="Questions"
                    ref={questionDivRef}
                  />
                  {question.is_required ? (
                    <sup className="text-danger">*</sup>
                  ) : null}
                </div>
                <div className="push-12-l">
                  <Fragment>
                    <Checkbox.Group
                      onChange={(checkedItems) => {
                        console.log("checkedItems 4", checkedItems);
                        const val = checkedItems;
                        handleAnswerChange(
                          val,
                          parentQuestion,
                          childQuestions,
                          childOfQuestions,
                          true
                        );
                      }}
                      value={question.chekedAnswer ? question.chekedAnswer : []}
                    >
                      {question.question_options.map((d, index) => {
                        return (
                          <Checkbox
                            key={index}
                            value={`${d.title}${SPLIT_SYMBOL}${d.id}`}
                          >
                            {d.title}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </Fragment>
                </div>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        );
      } else if (
        question?.conditional_text_values &&
        AllOptions &&
        AllOptions[0] !== question?.conditional_text_values[0] &&
        AllOptions.reverse().toString() ===
          question?.conditional_text_values?.toString()
      ) {
        return (
          <Fragment>
            <div className="checkbox-group-question_wrapper checkbox-group-question_3">
              <div
                className={`multi_choice_Wrapper multi_choice_Wrapper_two ${
                  question.question_options.length > 2 ? "full_box" : ""
                }`}
              >
                <div className="weight-500 font-14 margin-bottom-10 d-flex">
                  <span className="dot"></span>
                  <span
                    dangerouslySetInnerHTML={{__html: question.title}}
                    className="Questions"
                    ref={questionDivRef}
                  />
                  {question.is_required ? (
                    <sup className="text-danger">*</sup>
                  ) : null}
                </div>
                <div className="push-12-l">
                  <Fragment>
                    <Checkbox.Group
                      onChange={(checkedItems) => {
                        console.log("checkedItems 4", checkedItems);
                        const val = checkedItems;
                        handleAnswerChange(
                          val,
                          parentQuestion,
                          childQuestions,
                          childOfQuestions,
                          true
                        );
                      }}
                      value={question.chekedAnswer ? question.chekedAnswer : []}
                    >
                      {question.question_options.map((d, index) => {
                        return (
                          <Checkbox
                            key={index}
                            value={`${d.title}${SPLIT_SYMBOL}${d.id}`}
                          >
                            {d.title}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </Fragment>
                </div>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <div></div>;
      }
    } else if (parentQuestion.category === "numerical") {
      let selectedValue = parseInt(parentQuestion.answer, 10);
      const {conditional_max_value, conditional_min_value} = question;
      console.log("selectedValue", selectedValue);
      console.log("Max", conditional_max_value);
      console.log("Min", conditional_min_value);
      if (
        selectedValue <= conditional_max_value &&
        selectedValue >= conditional_min_value
      ) {
        return (
          <Fragment>
            <div className="checkbox-group-question_wrapper checkbox-group-question_4">
              <div
                className={`numerical_Wrapper ${
                  question.question_options.length > 2 ? "full_box" : ""
                }`}
              >
                <div className="weight-500 font-14 margin-bottom-10 d-flex">
                  <span className="dot"></span>
                  <span
                    dangerouslySetInnerHTML={{__html: question.title}}
                    className="Questions"
                    ref={questionDivRef}
                  />
                  {question.is_required ? (
                    <sup className="text-danger">*</sup>
                  ) : null}
                </div>
                <div className="push-12-l">
                  <Fragment>
                    <Checkbox.Group
                      onChange={(checkedItems) => {
                        console.log("checkedItems 4", checkedItems);
                        const val = checkedItems;
                        handleAnswerChange(
                          val,
                          parentQuestion,
                          childQuestions,
                          childOfQuestions,
                          true
                        );
                      }}
                      value={question.chekedAnswer ? question.chekedAnswer : []}
                    >
                      {question.question_options.map((d, index) => {
                        return (
                          <Checkbox
                            key={index}
                            value={`${d.title}${SPLIT_SYMBOL}${d.id}`}
                          >
                            {d.title}
                          </Checkbox>
                        );
                      })}
                    </Checkbox.Group>
                  </Fragment>
                </div>
              </div>
              <div className="errorandinfo">
                {question.error && (
                  <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                    {question.error}
                  </span>
                )}
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <div></div>;
      }
    } else {
      <Fragment>
        <div className="checkbox-group-question_wrapper checkbox-group-question_5">
          <div
            className={`both_Wrapper ${
              question.question_options.length > 2 ? "full_box" : ""
            }`}
          >
            <div className="weight-500 font-14 margin-bottom-10 d-flex">
              <span className="dot"></span>
              <span
                dangerouslySetInnerHTML={{__html: question.title}}
                className="Questions"
                ref={questionDivRef}
              />
              {question.is_required ? (
                <sup className="text-danger">*</sup>
              ) : null}
            </div>
            <div className="push-12-l">
              <Fragment>
                <Checkbox.Group
                  onChange={(checkedItems) => {
                    console.log("checkedItems 4", checkedItems);
                    const val = checkedItems;
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions,
                      true
                    );
                  }}
                  value={question.chekedAnswer ? question.chekedAnswer : []}
                >
                  {question.question_options.map((d, index) => {
                    return (
                      <Checkbox
                        key={index}
                        value={`${d.title}${SPLIT_SYMBOL}${d.id}`}
                      >
                        {d.title}
                      </Checkbox>
                    );
                  })}
                </Checkbox.Group>
              </Fragment>
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>;
    }
  } else if (
    !childOfQuestions &&
    childQuestions &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="checkbox-group-question_wrapper checkbox-group-question_6">
          <div
            className={`childQuestions_Wrapper ${
              question.question_options.length > 2 ? "full_box" : ""
            }`}
          >
            <div className="weight-500 font-14 margin-bottom-10 d-flex">
              <span className="dot"></span>
              <span
                dangerouslySetInnerHTML={{__html: question.title}}
                className="Questions"
                ref={questionDivRef}
              />
              {question.is_required ? (
                <sup className="text-danger">*</sup>
              ) : null}
            </div>
            <div className="push-12-l">
              <Fragment>
                <Checkbox.Group
                  onChange={(checkedItems) => {
                    console.log("checkedItems 3", checkedItems);
                    const val = checkedItems;
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions,
                      true
                    );
                  }}
                  value={question.chekedAnswer ? question.chekedAnswer : []}
                >
                  {question.question_options.map((d, index) => {
                    return (
                      <Checkbox
                        key={index}
                        value={`${d.title}${SPLIT_SYMBOL}${d.id}`}
                      >
                        {d.title}
                      </Checkbox>
                    );
                  })}
                </Checkbox.Group>
              </Fragment>
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else if (
    childOfQuestions &&
    childQuestions.answer &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="checkbox-group-question_wrapper checkbox-group-question_7">
          <div
            className={`childOfQuestions_Wrapper ${
              question.question_options.length > 2 ? "full_box" : ""
            }`}
          >
            <div className="weight-500 font-14 margin-bottom-10 d-flex">
              <span className="dot"></span>
              <span
                dangerouslySetInnerHTML={{__html: question.title}}
                className="Questions"
                ref={questionDivRef}
              />
              {question.is_required ? (
                <sup className="text-danger">*</sup>
              ) : null}
            </div>
            <div className="push-12-l">
              <Fragment>
                <Checkbox.Group
                  onChange={(checkedItems) => {
                    console.log("checkedItems 4", checkedItems);
                    const val = checkedItems;
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions,
                      true
                    );
                  }}
                  value={question.chekedAnswer ? question.chekedAnswer : []}
                >
                  {question.question_options.map((d, index) => {
                    return (
                      <Checkbox
                        key={index}
                        value={`${d.title}${SPLIT_SYMBOL}${d.id}`}
                      >
                        {d.title}
                      </Checkbox>
                    );
                  })}
                </Checkbox.Group>
              </Fragment>
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else if (
    !childOfQuestions &&
    !childQuestions &&
    question &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="checkbox-group-question_wrapper checkbox-group-question_8">
          <div
            className={`not_childQuestions_Wrapper ${
              question.question_options.length > 2 ? "full_box" : ""
            }`}
          >
            <div className="weight-500 font-14 margin-bottom-10 d-flex">
              <span className="dot"></span>
              <span
                dangerouslySetInnerHTML={{__html: question.title}}
                className="Questions"
                ref={questionDivRef}
              />
              {question.is_required ? (
                <sup className="text-danger">*</sup>
              ) : null}
            </div>
            <div className="push-12-l">
              <Fragment>
                <Checkbox.Group
                  onChange={(checkedItems) => {
                    console.log("checkedItems 5", checkedItems);
                    const val = checkedItems;
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions,
                      true
                    );
                  }}
                  value={question.chekedAnswer ? question.chekedAnswer : []}
                >
                  {question.question_options.map((d, index) => {
                    return (
                      <Checkbox
                        key={index}
                        value={`${d.title}${SPLIT_SYMBOL}${d.id}`}
                      >
                        {d.title}
                      </Checkbox>
                    );
                  })}
                </Checkbox.Group>
              </Fragment>
            </div>
          </div>
          <div className="errorandinfo">
            {question.error && (
              <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                {question.error}
              </span>
            )}
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <></>;
  }
}

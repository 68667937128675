import React, {Fragment, useEffect, useState} from "react";
import CommonLoader from "components/common/CommonLoader";
import NoFoundMessage from "components/common/NoFoundMessage";
import DeleteAbbreviationModal from "./DeleteAbbreviationModal.";
// import EditAbbreviationModal from "./EditAbbreviationModal";
import {
  errorToast,
  getErrorObject,
  successToast,
  verifyObject,
} from "utilities/utils";
import {deleteAbbrivations, updateAbbrivations} from "services/drugs";

import {isArray, isNumber} from "lodash";
import {
  DeleteOutlined,
  // EditFilled,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {Input} from "antd";
import {isNumeric} from "validator";

const AbbreviationListingModal = ({
  items,
  isLoading,
  ids,
  data,
  onRequestListAbbreviation,
}) => {
  // State to keep track of the selected item for editing
  const [selectedItem, setSelectedItem] = useState(null);
  // const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  // const [newItemName, setNewItemName] = useState("");
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [state, setState] = useState({
    errors: null,
    code_key: null,
    fieldName: null,
    abbreviation: "",
    numeric_meaning: "",
    meaning: "",
    id: null,
  });

  useEffect(() => {
    if (selectedItem) {
      setState({
        ...state,
        abbreviation: selectedItem.abbreviation
          ? selectedItem.abbreviation
          : "",
        numeric_meaning: selectedItem.total_quantity_per_day
          ? selectedItem.total_quantity_per_day
          : "",
        meaning: selectedItem.meaning ? selectedItem.meaning : "",
        id: selectedItem.id ? selectedItem.id : null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItem]);
  // const closeEditModal = () => {
  //   setSelectedItem(null);
  //   setIsEditModalOpen(false);
  // };

  const openConfirmationModal = (item) => {
    setSelectedItem(item);
    setConfirmationModalOpen(true);
  };

  const closeConfirmationModal = () => {
    setSelectedItem(null);
    setConfirmationModalOpen(false);
    clearData();
  };

  const handleEdit = async (data) => {
    // Implement your edit logic here with newItemName
    console.log("Edited:", data);
    try {
      let formData = new FormData();

      formData.set("prescription_abbrevation[abbreviation]", data.abbreviation);
      formData.set("prescription_abbrevation[meaning]", data.meaning);
      formData.set(
        "prescription_abbrevation[numeric_meaning]",
        data.numeric_meaning
      );

      setState({...state, editingingAbbriviation: true});
      const response = await updateAbbrivations(formData, data.id);

      if (typeof onRequestListAbbreviation === "function") {
        await onRequestListAbbreviation({});
      }

      successToast({
        content: verifyObject(response, "data.message", "Updated successfully"),
      });
      clearData();
      setSelectedItem(null);
      setState({...state, editingingAbbriviation: false, fieldName: null});
    } catch (error) {
      const {message} = getErrorObject(error);
      setState({...state, editingingAbbriviation: false});
      errorToast({content: message});
    }
  };
  const clearData = () => {
    return setState({
      ...state,
      errors: null,
      code_key: null,
      fieldName: null,
      abbreviation: "",
      numeric_meaning: "",
      meaning: "",
      id: null,
    });
  };

  // Handle delete button click
  const handleDelete = async (id) => {
    // Implement your delete logic here
    console.log("Deleted:", id);
    try {
      setState({...state, deletingAbbriviation: true});
      const response = await deleteAbbrivations(id);

      if (typeof onRequestListAbbreviation === "function") {
        await onRequestListAbbreviation({});
      }

      successToast({
        content: verifyObject(response, "data.message", "Deleted successfully"),
      });
      setSelectedItem(null);

      closeConfirmationModal();
      clearData();

      setState({...state, deletingAbbriviation: false, fieldName: null});
    } catch (error) {
      const {message} = getErrorObject(error);
      await setState({...state, deletingAbbriviation: false});
      errorToast({content: message});
    }
  };
  const onChangeText = (e, inputNumber) => {
    if (e.target.value.includes("e") && inputNumber) {
      e.preventDefault();
    } else {
      setState({
        ...state,
        [e.target.name]: !inputNumber
          ? e.target.value
          : isNumeric(e.target.value)
          ? +e.target.value
          : "",
        // inputNumber&&isNumeric(e.target.value) ? +e.target.value : e.target.value,
      });
    }
  };
  let customAbbreviationButtonConsdition =
    state.meaning &&
    state.meaning !== "" &&
    state.meaning.trim().length !== 0 &&
    state.abbreviation &&
    state.abbreviation !== "" &&
    state.abbreviation.trim().length !== 0 &&
    state.numeric_meaning &&
    state.numeric_meaning !== undefined &&
    state.numeric_meaning !== "" &&
    isNumber(state.numeric_meaning)
      ? true
      : false;
  console.log("state-->", state);
  console.log("selected item-->", selectedItem);
  console.log("condition-->", customAbbreviationButtonConsdition);
  return (
    <div>
      <div className="abbreviationstable">
        <table className="full-width shortcodetable">
          <thead className="w-100 upcoming_booking_tableheader relative bg-gray border">
            <tr>
              <th
                style={{width: "20%"}}
                className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 text-light-black"
              >
                Abbreviation
              </th>
              <th
                style={{width: "50%"}}
                className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 text-light-black"
              >
                Meaning
              </th>
              <th
                style={{width: "10%"}}
                className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 text-light-black"
              >
                Quantity
              </th>
              <th
                style={{width: "20%"}}
                className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-22 text-light-black"
              >
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {!isLoading && ids && ids.length === 0 && (
              <tr>
                <td colSpan={7}>
                  <NoFoundMessage message="No prescription abbreviation found" />
                </td>
              </tr>
            )}

            {isLoading && (
              <tr>
                <td colSpan={7}>
                  <CommonLoader />
                </td>
              </tr>
            )}
            {ids &&
              !isLoading &&
              isArray(ids) &&
              ids.length > 0 &&
              ids.map((id, index) => (
                <tr key={index}>
                  {/* abbreviation start */}
                  {selectedItem &&
                  selectedItem.id === data[id].id &&
                  state?.fieldName ? (
                    <td
                      className="text-left padding-left-10 cursor-pointer"
                      style={{width: "20%"}}
                    >
                      <input
                        className="form-control margin-0 font-10 h-auto"
                        name="abbreviation"
                        value={state.abbreviation}
                        onChange={(e) => onChangeText(e, false)}
                        rows={"3"}
                      />
                    </td>
                  ) : (
                    <td
                      onDoubleClick={(e) => {
                        e.stopPropagation();
                        setSelectedItem(data[id]);
                        setState({
                          ...state,
                          fieldName: true,
                        });
                      }}
                      className="text-left padding-left-10 cursor-pointer"
                    >
                      <span
                        style={{
                          // pointerEvents:
                          //   data[id].code_type === "general" && "none",
                          whiteSpace: "pre-line",
                        }}
                        className="font-11 in-block cursor-pointer"
                      >
                        {data[id].abbreviation}
                      </span>
                      {/* {selectedRecord &&
                            selectedRecord.id === k.id &&
                            shortCodesState?.errors && (
                              <span className="validate-danger">
                                {shortCodesState?.errors.code_description}
                              </span>
                            )} */}
                    </td>
                  )}
                  {/* abbreviation end */}
                  {/* meaning start */}

                  {selectedItem &&
                  selectedItem.id === data[id].id &&
                  state?.fieldName ? (
                    <td
                      className="text-left padding-left-10 cursor-pointer"
                      style={{width: "50%"}}
                    >
                      <input
                        className="form-control margin-0 font-10 h-auto"
                        name="meaning"
                        value={state.meaning}
                        onChange={(e) => onChangeText(e, false)}
                        rows={"3"}
                      />
                    </td>
                  ) : (
                    <td
                      onDoubleClick={(e) => {
                        e.stopPropagation();
                        setSelectedItem(data[id]);
                        setState({
                          ...state,
                          fieldName: true,
                        });
                      }}
                      className="text-left padding-left-10 cursor-pointer"
                    >
                      <span
                        style={{
                          // pointerEvents:
                          //   data[id].code_type === "general" && "none",
                          whiteSpace: "pre-line",
                        }}
                        className="font-11 in-block cursor-pointer"
                      >
                        {data[id].meaning}
                      </span>
                      {/* {selectedRecord &&
                            selectedRecord.id === k.id &&
                            shortCodesState?.errors && (
                              <span className="validate-danger">
                                {shortCodesState?.errors.code_description}
                              </span>
                            )} */}
                    </td>
                  )}
                  {/* meaning end */}
                  {/* numeric start */}

                  {selectedItem &&
                  selectedItem.id === data[id].id &&
                  state?.fieldName ? (
                    <td
                      className="text-left padding-left-10 cursor-pointer"
                      style={{width: "10%"}}
                    >
                      <Input
                        className="form-control margin-0 font-10 h-auto"
                        name="numeric_meaning"
                        value={state.numeric_meaning}
                        onChange={(e) => onChangeText(e, true)}
                        // type="number"

                        // onInput={(e) => {
                        //   const inputValue = e.target.value;

                        //   if (inputValue === "0" || isNaN(Number(inputValue))) {
                        //     e.target.setCustomValidity(
                        //       "Please enter a positive numeric value."
                        //     );
                        //     e.target.classList.add("invalid-input");
                        //   } else {
                        //     e.target.setCustomValidity("");
                        //     e.target.classList.remove("invalid-input");
                        //   }
                        // }}
                        // required
                      />
                    </td>
                  ) : (
                    <td
                      onDoubleClick={(e) => {
                        e.stopPropagation();
                        setSelectedItem(data[id]);
                        setState({
                          ...state,
                          fieldName: true,
                        });
                      }}
                      className="text-left padding-left-10 cursor-pointer"
                    >
                      <span
                        style={{
                          // pointerEvents:
                          //   data[id].code_type === "general" && "none",
                          whiteSpace: "pre-line",
                        }}
                        className="font-11 in-block cursor-pointer"
                      >
                        {data[id].total_quantity_per_day}
                      </span>
                      {/* {selectedRecord &&
                            selectedRecord.id === k.id &&
                            shortCodesState?.errors && (
                              <span className="validate-danger">
                                {shortCodesState?.errors.code_description}
                              </span>
                            )} */}
                    </td>
                  )}
                  {/* numeric end */}

                  {/* <td
                    style={{width: "20%"}}
                    className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 text-light-black"
                  >
                    {data[id].meaning}
                  </td>
                  <td
                    style={{width: "50%"}}
                    className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 text-light-black"
                  >
                    {data[id].meaning}
                  </td>
                  <td
                    style={{width: "10%"}}
                    className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-22 text-light-black"
                  >
                    {data[id].total_quantity_per_day}
                  </td> */}
                  <td className="padding-left-10" style={{width: "20%"}}>
                    <div className="flex flex-center">
                      <Fragment>
                        {selectedItem && selectedItem.id === data[id].id ? (
                          <Fragment>
                            {state?.fieldName !== null && (
                              <div className="editicon mr-2">
                                <CloseCircleOutlined
                                  onClick={() => {
                                    // _updateShortCode();
                                    setSelectedItem(null);
                                    clearData();

                                    // setState({
                                    //   ...state,
                                    //   fieldName: null,
                                    // });
                                    // if (_clearSelectedRecords) {
                                    //   if (
                                    //     typeof _clearSelectedRecords ===
                                    //     "function"
                                    //   ) {
                                    //     _clearSelectedRecords();
                                    //   }
                                    // }
                                  }}
                                  className="font-13-imp text-primary text-danger  weight-400 text-white tablet-font-10 font-16"
                                >
                                  Close
                                </CloseCircleOutlined>
                              </div>
                            )}

                            {!confirmationModalOpen && (
                              <div className={`editicon mr-2`}>
                                <CheckCircleOutlined
                                  onClick={() => {
                                    // _updateShortCode();
                                    handleEdit(state);
                                  }}
                                  className="font-13-imp text-primary  weight-400 text-white tablet-font-10 font-16"
                                  disabled={!customAbbreviationButtonConsdition}
                                  style={{
                                    cursor: `${
                                      !customAbbreviationButtonConsdition ||
                                      state.editingingAbbriviation
                                        ? "not-allowed"
                                        : ""
                                    }`,
                                  }}
                                >
                                  Submit
                                </CheckCircleOutlined>
                              </div>
                            )}
                          </Fragment>
                        ) : (
                          <></>
                        )}
                        <div className="editicon delete">
                          <DeleteOutlined
                            onClick={(e) => {
                              e.stopPropagation();
                              openConfirmationModal(data[id]);
                            }}
                            className="font-13-imp text-danger weight-400 text-white tablet-font-10 font-16"
                          >
                            Delete
                          </DeleteOutlined>
                        </div>
                      </Fragment>
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {confirmationModalOpen && (
        <DeleteAbbreviationModal
          confirmationModalOpen={confirmationModalOpen}
          closeConfirmationModal={closeConfirmationModal}
          handleDelete={handleDelete}
          selectedItem={selectedItem}
          isLoading={state.deletingAbbriviation}
        />
      )}
    </div>
  );
};

export default AbbreviationListingModal;

import {deletee, get, patch, post} from "./index";

export const getDrugsApi = (params, cancelToken) => {
  const request = {
    subUrl: `/drugs/search.json`,
    params,
    cancelToken: cancelToken,
  };
  return get(request);
};
export const getDrugsApiForPatient = async (params, customJWToken, APIURL) => {
  // console.log("🚀 ~ getDrugsApiForPatient ~ APIURL:", APIURL);

  const request = {
    subUrl: APIURL,
    params,
    customJWToken: customJWToken,
  };
  const response = await get(request);
  return response;
};

export const getDrugsByConditionApi = (params, cancelToken) => {
  const request = {
    subUrl: `/drugs/search_by_condition.json`,
    params,
    cancelToken: cancelToken,
  };
  return get(request);
};

export const getAbbrivations = (params) => {
  const request = {
    subUrl: `/prescriptions/abbreviations.json`,
    params,
  };
  return get(request);
};
export const createCustomAbbrivations = (params) => {
  const request = {
    subUrl: `/prescription_abbreviations`,
    data: params,
  };
  return post(request);
};
export const updateAbbrivations = (params, id) => {
  const request = {
    subUrl: `/prescription_abbreviations/${id}`,
    data: params,
  };
  return patch(request);
};
export const deleteAbbrivations = (id) => {
  const request = {
    subUrl: `/prescription_abbreviations/${id}`,
    // params,
  };
  return deletee(request);
};
export const getPharamciesApi = (params) => {
  const request = {
    subUrl: `/organizations.json`,
    params,
  };
  return get(request);
};

import React, {Component} from "react";
import Timer from "react-compound-timer";
import {ProgressBar} from "react-bootstrap";
import store from "../../app/store";
import moment from "moment";

export default class ConnectionStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      seconds: 0,
      totalSeconds:
        this.props.duration &&
        this.props.duration > 0 &&
        parseInt(this.props.duration * 60, 10),
      // triggeredSpeakFunction: false,
      triggeredShowButton: false,
      end_time: null,
      triggered5MinSpeakFunction: false,
      triggered2MinSpeakFunction: false,
    };
    this.interval = setInterval(() => this.tick(), 1000);
    this.TimerRef = React.createRef();
  }
  handleAddExtraTime = (value) => {
    // Get the remaining time
    // const remainingTime =
    //   this.TimerRef && this.TimerRef.current
    //     ? this.TimerRef.current.getTime()
    //     : null;

    // Add 5 minutes to the remaining time

    if (value) {
      const newTime = +value * 1000;

      // Set the updated time
      if (this.TimerRef && this.TimerRef.current) {
        this.TimerRef.current.setTime(newTime);
      }
    }
  };
  handleOnMountExtendButton = () => {
    let {appointment_end_time} = this.props;

    // Convert appointment_end_time from UK time to UTC time
    const appointmentEndTimeUTC = moment
      .utc(appointment_end_time)
      .clone()
      .toDate();

    // Get the current time in India time zone
    const end_time = moment().add(
      parseInt(this.props.intialTime / 60, 10),
      "minutes"
    );

    // Compare end_time with appointmentEndTimeUTC
    const endTimeBeforeAppointments = end_time.isBefore(appointmentEndTimeUTC);

    let triggerTime1, triggerTime2, hideButton;

    // Calculate trigger times based on whether end_time is before or after appointment end time
    if (endTimeBeforeAppointments) {
      const timeDifferenceInMinutes = end_time.diff(moment(), "minutes");
      const adjustedTimeDifference1 = timeDifferenceInMinutes - 5;
      const adjustedTimeDifference2 = timeDifferenceInMinutes - 2.5;
      const adjustedTimeDifference3 = timeDifferenceInMinutes - 1;

      triggerTime1 = parseInt(adjustedTimeDifference1 * 60, 10); // Convert to seconds
      triggerTime2 = parseInt(adjustedTimeDifference2 * 60, 10); // Convert to seconds
      hideButton = parseInt(adjustedTimeDifference3 * 60, 10); // Convert to seconds
    } else {
      const timeDifferenceInMinutes = moment(appointmentEndTimeUTC)
        .clone()
        .diff(moment(), "minutes");

      const adjustedTimeDifference1 = timeDifferenceInMinutes - 5;
      const adjustedTimeDifference2 = timeDifferenceInMinutes - 2.5;
      const adjustedTimeDifference3 = timeDifferenceInMinutes - 1;

      triggerTime1 = parseInt(adjustedTimeDifference1 * 60, 10); // Convert to seconds
      triggerTime2 = parseInt(adjustedTimeDifference2 * 60, 10); // Convert to seconds
      hideButton = parseInt(adjustedTimeDifference3 * 60, 10); // Convert to seconds
    }

    // Update state with trigger times and end_time
    this.setState(
      {
        triggerTime1,
        triggerTime2,
        hideButton,
        end_time: endTimeBeforeAppointments
          ? end_time
          : moment(appointmentEndTimeUTC),
      },
      () => {
        const currentTime = moment.utc();
        const {handleSpeakFunction} = this.props;

        if (
          this.state.end_time.diff(currentTime, "minutes") > 1 &&
          this.state.end_time.diff(currentTime, "minutes") < 5 &&
          !this.state.triggeredShowButton
        ) {
          this.triggerSpeakFunction(handleSpeakFunction, false, false, true);
          this.setState({triggeredShowButton: true});
        } else if (this.state.triggeredShowButton) {
          this.setState({triggeredShowButton: false});
        }
      }
    );
  };

  componentDidMount() {
    let initialTime = 0;

    if (this.props.duration > 0) {
      // console.log("duration==>", this.props.duration);
      let totalDuration = this.props.duration * 60;
      let usedTime = totalDuration - this.props.intialTime;
      if (this.props.intialTime > 0) {
        initialTime = usedTime;
      }
      // console.log("totalDuration", totalDuration);
      // console.log("initialTime", initialTime);
    }
    this.setState({seconds: initialTime}, () => {
      this.handleOnMountExtendButton();
    });
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      // intialTime: nextProps.intialTime,
      totalSeconds:
        nextProps.duration &&
        nextProps.duration > 0 &&
        parseInt(nextProps.duration * 60, 10),
    });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.duration !== this.props.duration) {
      // Check if the duration prop has changed
      let durationDifference = parseInt(
        // (this.props.duration - prevProps.duration) * 60,
        +this.props.intialTime,
        10
      );

      this.handleAddExtraTime(+durationDifference);

      this.setState(
        (prevState) => ({
          seconds: prevState.seconds - durationDifference,
        }),
        () => {
          // console.log("TIMER DID UPDATE---", this.state.seconds);
        }
      );
    }

    const triggerPoint1 = this.state.seconds === this.state.triggerTime1;
    const triggerPoint2 = this.state.seconds === this.state.triggerTime2;

    // const hideButton = this.state.seconds === this.state.showButton;
    let {seconds, triggerTime1, triggerTime2, hideButton} = this.state;
    if (seconds === triggerTime1 && !this.state.triggered5MinSpeakFunction) {
      if (typeof this.props.handleSpeakFunction === "function") {
        let payload = {
          trigger5minAlert: triggerPoint1,
          trigger2minAlert: triggerPoint2,
          showExtendButton: true,
        };

        this.props.handleSpeakFunction(payload); //passing three params
        this.setState({triggered5MinSpeakFunction: true});
      }
    } else if (!triggerPoint1 && this.state.triggered5MinSpeakFunction) {
      this.setState({triggered5MinSpeakFunction: false});
    }
    if (seconds === triggerTime2 && !this.state.triggered2MinSpeakFunction) {
      if (typeof this.props.handleSpeakFunction === "function") {
        let payload = {
          trigger5minAlert: triggerPoint1,
          trigger2minAlert: triggerPoint2,
          showExtendButton: true,
        };

        this.props.handleSpeakFunction(payload); //passing three params
        this.setState({triggered2MinSpeakFunction: true});
      }
    } else if (!triggerPoint2 && this.state.triggered2MinSpeakFunction) {
      this.setState({triggered2MinSpeakFunction: false});
    }
    if (seconds === hideButton && !this.state.triggeredShowButton) {
      if (typeof this.props.handleSpeakFunction === "function") {
        let payload = {
          trigger5minAlert: false,
          trigger2minAlert: false,
          showExtendButton: false,
        };

        this.props.handleSpeakFunction(payload); //passing three params
        this.setState({triggeredShowButton: true});
      }
    } else if (!hideButton && this.state.triggeredShowButton) {
      this.setState({triggeredShowButton: false});
    }
  }
  triggerSpeakFunction = (
    handleSpeakFunction,
    trigger5minAlert,
    trigger2minAlert,
    showExtendButton
  ) => {
    const payload = {
      trigger5minAlert,
      trigger2minAlert,
      showExtendButton,
    };
    handleSpeakFunction(payload);
  };
  tick = () => {
    if (this.state.seconds < this.state.totalSeconds) {
      this.setState((prevState) => ({
        seconds: prevState.seconds + 1,
      }));
    } else {
      // console.log("Timer Finished..");

      if (this.props.callEndUsingTimer) {
        if (typeof this.props.callEndUsingTimer === "function") {
          store.dispatch({
            type: "ON_CALL_TIME_FINISHED",
            payload: true,
          });
          this.props.callEndUsingTimer(false);
        }
      }
      clearInterval(this.interval);
    }
  };
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    let remainigSeconds = this.state.totalSeconds - this.state.seconds;
    let startCallTime = this.props.duration
      ? 60 * this.props.duration * 1000
      : null;
    let initialTime = 0;
    // console.log(
    //   "TIMER RENDER_---",
    //   this.state.seconds,
    //   this.state.totalSeconds,
    //   this.state.triggerTime1,
    //   this.state.triggerTime2,
    //   this.state.hideButton
    // );

    if (this.props.duration > 0) {
      // console.log("this.props.duration", this.props.duration);
      // let totalDuration = this.props.duration * 60;
      let usedTime = this.props.intialTime;
      if (this.props.intialTime > 0) {
        initialTime = usedTime * 1000;
      }
      // console.log("totalDuration", totalDuration);
      // console.log("(this.props.intialTime",initialTime);
    }
    return (
      <div>
        {
          <ProgressBar
            min={0}
            max={60 * this.props.duration}
            now={this.state.seconds}
            status="active"
            variant="success"
          />
        }
        <div className="calling-uper-container">
          {/* {status === "Connected" && (
            <div className="calling-status-container">{status}</div>
          )} */}
          <div className="calling-timer-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              className="push-5-r"
            >
              <g fill="none" fill-rule="evenodd">
                <g
                  fill={`${remainigSeconds <= 60 ? "red" : "#808396"}`}
                  fill-rule="nonzero"
                >
                  <g>
                    <g>
                      <path
                        d="M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8zm0 14.832c-3.767 0-6.832-3.065-6.832-6.832S4.233 1.168 8 1.168 14.832 4.233 14.832 8 11.767 14.832 8 14.832z"
                        transform="translate(-1067 -143) translate(967 109) translate(100 34)"
                      />
                      <path
                        d="M10.725 8h-2.53V4.496c0-.322-.262-.584-.584-.584-.323 0-.584.262-.584.584v4.088c0 .322.261.584.584.584h3.114c.323 0 .584-.262.584-.584 0-.323-.261-.584-.584-.584z"
                        transform="translate(-1067 -143) translate(967 109) translate(100 34)"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </svg>

            <span
              style={{
                color: remainigSeconds <= 60 ? "red" : "#808396",
              }}
              className="calling-timer-text"
            >
              {startCallTime !== null && (
                <Timer
                  direction="backward"
                  initialTime={initialTime}
                  ref={this.TimerRef}
                >
                  <React.Fragment>
                    <Timer.Minutes
                      formatValue={(v) =>
                        `${v.toString().length > 1 ? v : `0${v}`}`
                      }
                    />
                    :
                    <Timer.Seconds
                      formatValue={(v) =>
                        `${v.toString().length > 1 ? v : `0${v}`}`
                      }
                    />
                  </React.Fragment>{" "}
                  / {this.props.duration}:00
                </Timer>
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

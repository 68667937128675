import {baseWebSocketURL} from "services/index";
import actionCable from "actioncable";
const channels = {
  patientsCounter: "PatientCounterChannel",
  waitingTime: "WaitingTimeChannel",
  customerSupportChannel: "CustomerSupportChannel",
  covidReviewChannel: "CovidReviewChannel",
  PendingQuestionnaireChannel: "PendingQuestionnaireChannel",
  ScriptreviewCounterChannel: "ScriptreviewCounterChannel",
  AppointmentCounterChannel: "AppointmentCounterChannel",
  WaitingRoomChannel: "WaitingRoomChannel",
  AppointmentLocationChannel: "AppointmentLocationChannel",
  AppointmentChannel: "AppointmentChannel",
  DoctorReviewChannel: "DoctorReviewChannel",
  ConsultationReviewCounterChannel: "ConsultationReviewCounterChannel",
};
let cable = {};
let subscribedChannels = [];
cable = actionCable.createConsumer(`${baseWebSocketURL}/cable`);
const initialState = {
  waitingTime: {
    data: null,
    isLoading: false,
  },
  patientsCounter: {
    data: {},
    isLoading: false,
  },
  customerSupportChannel: {
    data: {},
    isLoading: false,
  },
  covidReviewChannel: {
    data: {},
    isLoading: false,
  },
  DoctorReviewChannel: {
    data: {},
    isLoading: false,
  },
  PendingQuestionnaireChannel: {
    data: {},
    isLoading: false,
  },
  ScriptreviewCounter: {
    data: {},
    isLoading: false,
  },
  AppointmentCounter: {
    data: {},
    isLoading: false,
  },
  WaitingRoomSocketData: {
    data: {},
    isLoading: false,
  },
  AppointmentLocationData: {
    data: {},
    isLoading: false,
  },
  refreshAppointment: {
    data: {},
    isLoading: false,
  },
};
const types = {
  WAITING_TIME: "actionCable/WAITING_TIME",
  WAITING_TIME_SUCCESS: "actionCable/WAITING_TIME_SUCCESS",
  PATIENTS_COUNTER: "actionCable/PATIENTS_COUNTER",
  PATIENTS_COUNTER_SUCCESS: "actionCable/PATIENTS_COUNTER_SUCCESS",
  CUSTOMER_SUPPORT_STATS: "actionCable/CUSTOMER_SUPPORT_STATS",
  CUSTOMER_SUPPORT_STATS_SUCCESS: "actionCable/CUSTOMER_SUPPORT_STATS_SUCCESS",
  COVID_REVIEW: "actionCable/COVID_REVIEW",
  COVID_REVIEW_SUCCESS: "actionCable/COVID_REVIEW_SUCCESS",
  SCRIPT_REVIEW: "actionCable/SCRIPT_REVIEW",
  SCRIPT_REVIEW_SUCCESS: "actionCable/SCRIPT_REVIEW_SUCCESS",
  PENDING_QUESTIONNAIRE: "actionCable/PENDING_QUESTIONNAIRE",
  PENDING_QUESTIONNAIRE_SUCCESS: "actionCable/PENDING_QUESTIONNAIRE_SUCCESS",
  SCRIPT_COUNTER: "actionCable/SCRIPT_COUNTER",
  SCRIPT_COUNTER_SUCCESS: "actionCable/SCRIPT_COUNTER_SUCCESS",
  APPOINTMENT_COUNTER: "actionCable/APPOINTMENT_COUNTER",
  APPOINTMENT_COUNTER_SUCCESS: "actionCable/APPOINTMENT_COUNTER_SUCCESS",
  WAITING_ROOM_ENTER_START: "actionCable/WAITING_ROOM_ENTER_START",
  WAITING_ROOM_ENTER_SUCCESS: "actionCable/WAITING_ROOM_ENTER_SUCCESS",
  FETCH_APPOINTMENT_LOCATION_START:
    "actionCable/FETCH_APPOINTMENT_LOCATION_START",
  FETCH_APPOINTMENT_LOCATION_SUCCESS:
    "actionCable/FETCH_APPOINTMENT_LOCATION_SUCCESS",
  REFRESH_APPOINTMENTS_START: "actionCable/REFRESH_APPOINTMENTS_START",
  REFRESH_APPOINTMENTS_SUCCESS: "actionCable/REFRESH_APPOINTMENTS_SUCCESS",
  REFRESH_APPOINTMENTS_CLEAR: "actionCable/REFRESH_APPOINTMENTS_CLEAR",
};
export const actionCableData = (state = initialState, action) => {
  switch (action.type) {
    case types.WAITING_TIME:
      return {
        ...state,
        waitingTime: {
          ...state.waitingTime,

          isLoading: true,
        },
      };
    case types.WAITING_TIME_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        waitingTime: {
          ...state.waitingTime,
          data,
          isLoading: false,
        },
      };
    }

    case types.REFRESH_APPOINTMENTS_START:
      return {
        ...state,
        refreshAppointment: {
          ...state.refreshAppointment,
          isLoading: true,
        },
      };
    case types.REFRESH_APPOINTMENTS_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        refreshAppointment: {
          ...state.refreshAppointment,
          data,
          isLoading: false,
        },
      };
    }

    case types.REFRESH_APPOINTMENTS_CLEAR: {
      return {
        ...state,
        refreshAppointment: {
          data: null,
          isLoading: false,
        },
      };
    }
    case types.WAITING_ROOM_ENTER_START:
      return {
        ...state,
        WaitingRoomSocketData: {
          ...state.WaitingRoomSocketData,

          isLoading: true,
        },
      };
    case "CLEAR_INIT_SOCKET_DATA": {
      return {
        ...state,
        WaitingRoomSocketData: null,
      };
    }

    case types.WAITING_ROOM_ENTER_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        WaitingRoomSocketData: {
          ...state.WaitingRoomSocketData,
          data,
          isLoading: false,
        },
      };
    }

    case types.FETCH_APPOINTMENT_LOCATION_START:
      return {
        ...state,
        AppointmentLocationData: {
          ...state.AppointmentLocationData,

          isLoading: true,
        },
      };

    case types.FETCH_APPOINTMENT_LOCATION_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        AppointmentLocationData: {
          ...state.AppointmentLocationData,
          data,
          isLoading: false,
        },
      };
    }
    case types.PATIENTS_COUNTER:
      return {
        ...state,
        patientsCounter: {
          ...state.patientsCounter,

          isLoading: true,
        },
      };
    case types.PATIENTS_COUNTER_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        patientsCounter: {
          ...state.patientsCounter,
          data,
          isLoading: false,
        },
      };
    }
    case types.SCRIPT_COUNTER:
      return {
        ...state,
        ScriptreviewCounter: {
          ...state.ScriptreviewCounter,
          isLoading: true,
        },
      };
    case types.SCRIPT_COUNTER_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        ScriptreviewCounter: {
          ...state.ScriptreviewCounter,
          data,
          isLoading: false,
        },
      };
    }
    case types.APPOINTMENT_COUNTER:
      return {
        ...state,
        AppointmentCounter: {
          ...state.AppointmentCounter,
          isLoading: true,
        },
      };
    case types.APPOINTMENT_COUNTER_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        AppointmentCounter: {
          ...state.AppointmentCounter,
          data,
          isLoading: false,
        },
      };
    }
    case types.PENDING_QUESTIONNAIRE:
      return {
        ...state,
        PendingQuestionnaireChannel: {
          ...state.PendingQuestionnaireChannel,

          isLoading: true,
        },
      };
    case types.PENDING_QUESTIONNAIRE_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        PendingQuestionnaireChannel: {
          ...state.PendingQuestionnaireChannel,
          data,
          isLoading: false,
        },
      };
    }
    case types.CUSTOMER_SUPPORT_STATS:
      return {
        ...state,
        customerSupportChannel: {
          ...state.customerSupportChannel,

          isLoading: true,
        },
      };
    case types.CUSTOMER_SUPPORT_STATS_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        customerSupportChannel: {
          ...state.customerSupportChannel,
          data,
          isLoading: false,
        },
      };
    }
    case types.COVID_REVIEW: {
      return {
        ...state,
        covidReviewChannel: {
          ...state.covidReviewChannel,

          isLoading: true,
        },
      };
    }
    case types.COVID_REVIEW_SUCCESS: {
      const data = action.payload.data;
      return {
        ...state,
        covidReviewChannel: {
          ...state.covidReviewChannel,
          data,
          isLoading: false,
        },
      };
    }
    default:
      return state;
  }
};
export const waitingRoom = () => {
  return async (dispatch, getState) => {
    dispatch({type: types.WAITING_TIME});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.waitingTime,
          auth_token: getState().localStore.token,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (message) {
            dispatch({
              type: types.WAITING_TIME_SUCCESS,
              payload: {data: message.waiting_time},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};
export const patientsCounter = () => {
  return async (dispatch, getState) => {
    dispatch({type: types.PATIENTS_COUNTER});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.patientsCounter,
          auth_token: getState().localStore.token,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (message) {
            dispatch({
              type: types.PATIENTS_COUNTER_SUCCESS,
              payload: {data: message.counter},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};
export const customerSupportChannel = () => {
  return async (dispatch, getState) => {
    dispatch({type: types.CUSTOMER_SUPPORT_STATS});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.customerSupportChannel,
          auth_token: getState().localStore.token,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (data) {
            dispatch({
              type: types.CUSTOMER_SUPPORT_STATS_SUCCESS,
              payload: {data: data.statistic_count},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};

export const covidReviewChannel = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.COVID_REVIEW});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.covidReviewChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (data) {
            dispatch({
              type: types.COVID_REVIEW_SUCCESS,
              payload: {data: data},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};

export const ScriptReviewChannel = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.SCRIPT_REVIEW});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.DoctorReviewChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (data) {
            dispatch({
              type: types.SCRIPT_REVIEW_SUCCESS,
              payload: {data: data},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};

export const waitingRoomChannle = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.WAITING_ROOM_ENTER_START});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.WaitingRoomChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {
            console.log("connected");
          },
          disconnected: function () {
            console.log("Disconnected");
            cable = {};
          },
          received: function (data) {
            console.log("IN WAITNG ROOM RECEIVED DATA", data);
            dispatch({
              type: types.WAITING_ROOM_ENTER_SUCCESS,
              payload: {data: data},
            });
            dispatch({
              type: "SET_INTIAL_SOCKET_DATA",
              payload: data,
            });
            dispatch({
              type: "SET_APPOINTMENT_REASON_DATA",
              payload: data,
            });
            dispatch(AppointmentLocationChannel(params));
          },
        }
      );

      subscribedChannels.push(channel);
    }
  };
};

export const AppointmentLocationChannel = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.FETCH_APPOINTMENT_LOCATION_START});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.AppointmentLocationChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {
            console.log("connected");
          },
          disconnected: function () {
            console.log("Disconnected");

            cable = {};
          },
          received: function (data) {
            console.log("Location Data", data);
            dispatch({
              type: types.FETCH_APPOINTMENT_LOCATION_SUCCESS,
              payload: {data: data},
            });
            dispatch({
              type: "SET_APPOINTMENT_LOCATION_DATA",
              payload: data,
            });
          },
        }
      );

      subscribedChannels.push(channel);
    }
  };
};

export const PendingQuestionnaireChannel = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.PENDING_QUESTIONNAIRE});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.PendingQuestionnaireChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (data) {
            dispatch({
              type: types.PENDING_QUESTIONNAIRE_SUCCESS,
              payload: {data: data},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};
export const chatMessagesChannel = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.CHAT_MESSAGES_START});
    console.log("params", params);
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.DirectMessageChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {
            console.log("chatMessagesChannel Socket connected");
          },
          disconnected: function () {
            console.log("chatMessagesChannel Socket disconnected");

            cable = {};
          },
          speak: function () {
            console.log("Speak");
            if (params.message) {
              return this.perform("speak", {message: params.message});
            }
          },
          received: function (data) {
            console.log("Received messages", data);
            dispatch({
              type: types.RECEIVED_CHAT_MESSAGES,
              payload: {data: data},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};
export const ScriptreviewCounterChannel = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.SCRIPT_COUNTER});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.ScriptreviewCounterChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (data) {
            dispatch({
              type: types.SCRIPT_COUNTER_SUCCESS,
              payload: {data: data},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};
export const ConsultationReviewCounterChannel = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.SCRIPT_COUNTER});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.ConsultationReviewCounterChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (data) {
            dispatch({
              type: types.SCRIPT_COUNTER_SUCCESS,
              payload: {data: data},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};

export const AppointmentCounterChannel = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.APPOINTMENT_COUNTER});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.AppointmentCounterChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {},
          disconnected: function () {
            cable = {};
          },
          received: function (data) {
            dispatch({
              type: types.APPOINTMENT_COUNTER_SUCCESS,
              payload: {data: data},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};

export const refreshAppointments = (params) => {
  return async (dispatch, getState) => {
    dispatch({type: types.REFRESH_APPOINTMENTS_START});
    if (cable && cable.subscriptions) {
      const channel = cable.subscriptions.create(
        {
          channel: channels.AppointmentChannel,
          auth_token: getState().localStore.token,
          ...params,
        },
        {
          connected: function () {
            console.log("Connected");
          },
          disconnected: function () {
            console.log("Disconnected");
            cable = {};
          },
          received: function (data) {
            dispatch({
              type: types.REFRESH_APPOINTMENTS_SUCCESS,
              payload: {data: data},
            });
          },
        }
      );
      subscribedChannels.push(channel);
    }
  };
};

export const unsubscribe = () => {
  return async (dispatch, getState) => {
    subscribedChannels.forEach((channel) => {
      channel.unsubscribe();
    });
  };
};
export const actions = {
  waitingRoom,
  patientsCounter,
  customerSupportChannel,
  covidReviewChannel,
  PendingQuestionnaireChannel,
  ScriptreviewCounterChannel,
  AppointmentCounterChannel,
  waitingRoomChannle,
  refreshAppointments,
  ScriptReviewChannel,
  AppointmentLocationChannel,
  unsubscribe,
  chatMessagesChannel,
  ConsultationReviewCounterChannel,
};

import React from "react";
import ModalPopUp from "../../components/common/ModalPopUp";
import {Button, Image} from "antd";
import {isArray} from "lodash";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NoFoundMessage from "../../components/common/NoFoundMessage";
import {verifyObject} from "utilities/utils";
export default function ImageSliderModal({
  patientDetail,
  visible,
  handleCancel,
  checkIdentity,
  isDocumentViewed,
  isClosableImageModal,
  verifyPatientFunction,
  notFromOngoingConsultation,
  verifyingPatient,
}) {
  console.log("PHOTO ID----", patientDetail);
  let documents = patientDetail ? patientDetail.documents : [];
  //   let { documents } = patientDetail;

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <ModalPopUp
      title="User Documents"
      footer={false}
      visible={visible}
      handleCancel={handleCancel}
      className="width_850px check_identity_modal"
      closable={isClosableImageModal}
      maskClosable={false}
    >
      <div style={{textAlign: "center"}}>
        {documents && isArray(documents) && documents.length !== 0 && (
          <Slider {...settings}>
            {documents &&
              isArray(documents) &&
              documents.map((d, index) => {
                return (
                  <div
                    key={index}
                    style={contentStyle}
                    className="slider-img-container walkin_in_img padding-20-b"
                  >
                    <Image src={d.file_url} />
                  </div>
                );
              })}
          </Slider>
        )}
        {documents && isArray(documents) && documents.length === 0 && (
          <NoFoundMessage message="No documents available" />
        )}
        {verifyObject(patientDetail, "user.status", null) &&
        verifyObject(patientDetail, "user.status", null) === "unverified" &&
        notFromOngoingConsultation ? (
          <Button
            onClick={() =>
              verifyPatientFunction(
                verifyObject(patientDetail, "user.id", null)
              )
            }
            style={{marginTop: 10}}
            className="btn btn-custom btn-blue font-14 weight-500  width-100px"
            disabled={verifyingPatient}
          >
            Verify Patient
          </Button>
        ) : null}
        {!isDocumentViewed && !notFromOngoingConsultation && (
          <Button
            onClick={checkIdentity}
            style={{marginTop: 10}}
            className="btn btn-custom btn-blue font-14 weight-500  width-100px"
          >
            Viewed
          </Button>
        )}
      </div>
    </ModalPopUp>
  );
}

/* eslint-disable eqeqeq */
import {DatePicker} from "antd";
import {DATE_FORMAT} from "constants/common";
import moment from "moment";
import React, {Fragment} from "react";
const {RangePicker} = DatePicker;

export default function DateRangeQuestion({
  handleAnswerChange,
  childQuestions,
  parentQuestion,
  question,
  childOfQuestions,
  gender,
}) {
  // console.log
  if (
    question.is_conditional &&
    childQuestions &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    //TODO: Handle conditional
    let selectedOption = parentQuestion.answer;
    if (parentQuestion?.category === "single_choice") {
      let selectedValue = parseInt(parentQuestion.answer, 10);
      const {conditional_max_value, conditional_min_value} = question;
      console.log("selectedValue", selectedValue);
      console.log("Max", conditional_max_value);
      console.log("Min", conditional_min_value);
      if (
        selectedValue <= conditional_max_value &&
        selectedValue >= conditional_min_value
      ) {
        return (
          <Fragment>
            <div className="margin-t-15 form-row">
              <div className="col-md-7">
                <div
                  dangerouslySetInnerHTML={{__html: question.title}}
                  className="weight-500 font-14 margin-bottom-10"
                ></div>
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
              <div className="col-md-5">
                <RangePicker
                  onChange={(d) => {
                    const val = [
                      moment(d[0]).format(DATE_FORMAT),
                      moment(d[1]).format(DATE_FORMAT),
                    ]; //`${moment(d[0]).format(DATE_FORMAT)} To ${moment(d[1]).format(DATE_FORMAT)}`;
                    console.log("range picker consitional", val);
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions
                    );
                  }}
                  placeholder={
                    question.placeholder !== null ? question.placeholder : ""
                  }
                  className="form-control"
                />

                <div className="col-md-12">
                  {question.error && (
                    <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                      {question.error}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }
    if (parentQuestion?.category === "numerical") {
      if (question?.conditional_text_values && question?.conditional_text_values[0] === selectedOption) {
        console.log("IN");
        return (
          <Fragment>
            <div className="margin-t-15 form-row">
              <div className="col-md-7">
                <div
                  dangerouslySetInnerHTML={{__html: question.title}}
                  className="weight-500 font-14 margin-bottom-10"
                ></div>
                {question.is_required ? (
                  <sup className="text-danger">*</sup>
                ) : null}
              </div>
              <div className="col-md-5">
                <RangePicker
                  onChange={(d) => {
                    const val = [
                      moment(d[0]).format(DATE_FORMAT),
                      moment(d[1]).format(DATE_FORMAT),
                    ]; //`${moment(d[0]).format(DATE_FORMAT)} To ${moment(d[1]).format(DATE_FORMAT)}`;
                    console.log("range picker consitional", val);
                    handleAnswerChange(
                      val,
                      parentQuestion,
                      childQuestions,
                      childOfQuestions
                    );
                  }}
                  placeholder={
                    question.placeholder !== null ? question.placeholder : ""
                  }
                  className="form-control"
                />
                <div className="col-md-12">
                  {question.error && (
                    <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                      {question.error}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        );
      } else {
        return <></>;
      }
    }
  } else if (
    !childOfQuestions &&
    childQuestions &&
    !question.is_conditional &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 form-row">
          <div className="col-md-7">
            <div
              dangerouslySetInnerHTML={{__html: question.title}}
              className="weight-500 font-14 margin-bottom-10"
            ></div>
            {question.is_required ? <sup className="text-danger">*</sup> : null}
          </div>
          <div className="col-md-5">
            <RangePicker
              onChange={(d) => {
                const val = [
                  moment(d[0]).format(DATE_FORMAT),
                  moment(d[1]).format(DATE_FORMAT),
                ]; //`${moment(d[0]).format(DATE_FORMAT)} To ${moment(d[1]).format(DATE_FORMAT)}`;
                console.log("range picker consitional", val);
                handleAnswerChange(
                  val,
                  parentQuestion,
                  childQuestions,
                  childOfQuestions
                );
              }}
              placeholder={
                question.placeholder !== null ? question.placeholder : ""
              }
              className="form-control"
            />
            <div className="">
              {question.error && (
                <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                  {question.error}
                </span>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else if (
    childOfQuestions &&
    childQuestions.answer &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 form-row">
          <div className="col-md-7">
            <div
              dangerouslySetInnerHTML={{__html: question.title}}
              className="weight-500 font-14 margin-bottom-10"
            ></div>
            {question.is_required ? <sup className="text-danger">*</sup> : null}
          </div>
          <div className="col-md-5">
            <RangePicker
              onChange={(d) => {
                const val = [
                  moment(d[0]).format(DATE_FORMAT),
                  moment(d[1]).format(DATE_FORMAT),
                ]; //`${moment(d[0]).format(DATE_FORMAT)} To ${moment(d[1]).format(DATE_FORMAT)}`;
                console.log("range picker consitional", val);
                handleAnswerChange(
                  val,
                  parentQuestion,
                  childQuestions,
                  childOfQuestions
                );
              }}
              placeholder={
                question.placeholder !== null ? question.placeholder : ""
              }
              className="form-control"
            />
            <div className="">
              {question.error && (
                <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                  {question.error}
                </span>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else if (
    !childOfQuestions &&
    !childQuestions &&
    question &&
    (question.gender_preference === null ||
      question.gender_preference === gender ||
      question.gender_preference === "both")
  ) {
    return (
      <Fragment>
        <div className="margin-t-15 form-row">
          <div className="col-md-7">
            <div
              dangerouslySetInnerHTML={{__html: question.title}}
              className="weight-500 font-14 margin-bottom-10"
            ></div>
            {question.is_required ? <sup className="text-danger">*</sup> : null}
          </div>
          <div className="col-md-5">
            <RangePicker
              onChange={(d) => {
                const val = [
                  moment(d[0]).format(DATE_FORMAT),
                  moment(d[1]).format(DATE_FORMAT),
                ]; //`${moment(d[0]).format(DATE_FORMAT)} To ${moment(d[1]).format(DATE_FORMAT)}`;
                console.log("range picker consitional", val);
                handleAnswerChange(
                  val,
                  parentQuestion,
                  childQuestions,
                  childOfQuestions
                );
              }}
              placeholder={
                question.placeholder !== null ? question.placeholder : ""
              }
              className="form-control"
            />
            <div className="">
              {question.error && (
                <span className="text-danger margin-top-10 weight-400 font-12 in-block">
                  {question.error}
                </span>
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  } else {
    return <></>;
  }
}

import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as nhsMedicationsActions} from "../../../reducers/nhsRepeatMedications";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../../utilities/utils";
import {isArray, debounce, isEmpty, uniqueId} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {
  Table,
  Space,
  Dropdown,
  Menu,
  Select,
  Modal,
  Timeline,
  Spin,
} from "antd";
import {DATE_FORMAT} from "../../../constants/common";
import {
  getAllergiesAPI,
  addAllergiesAPI,
  deleteAllergiesAPI,
  getUserllergiesAPI,
} from "../../../services/allergies";
import SingleCheckBox from "../../../components/common/controls/SingleCheckBox";
import ModalPopUp from "../../../components/common/ModalPopUp";
import Highlighter from "react-highlight-words";
import {searchSnomedCode} from "../../../services/snomedCodes";
import {ValidateInput} from "./AllergiesValidations";
import three_dot from "assets/images/common/ic-menu-dots.svg";

// import search_blue from "assets/images/common/search-blue.svg";
// import { Input } from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";

const {Option} = Select;
const {confirm} = Modal;
class AllergiesContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      message: "",
      nhsRepeatMedications: null,
      isLoading: false,
      snomedSearch: false,
      snomedCodeId: "",
      snomedCodeText: "",
      snomedCodes: [],
      currentlyActive: false,
      errors: null,
      visibleHistory: false,
      histories: [],
      historyLoading: false,
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleSearch",
      "_handleCancel",
      "_renderActionMenus",
      "_showModal",
      "_hideModal",
      "_handleCheckBox",
      "_handleDropDownChange",
      "_handleSubmit",
      "_handleTextChange",
      "_handleEdit",
      "_handleSnomedCodeSearch",
      "_clearData",
      "_handleDelete",
      "_submitDelete",
      "_handleHistoryModal",
      "_hideHistoryModal",
      "_getSnomedCodes",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.searchSnomedCodeUpdate = debounce(this._handleSnomedCodeSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, nhsMedicationsActions} = this.props;
    let {onSearch} = nhsMedicationsActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({search: value, patient_id: patientId, page: 1});
    }
  }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodes: [],
          snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          snomedSearch: value,
        },
        async () => {
          this._getSnomedCodes(false);
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  async _getSnomedCodes(isLoadMore) {
    try {
      let response = await searchSnomedCode({
        page: this.state.page,
        search: this.state.snomedSearch,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...snomedCodes]
          : snomedCodes,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getSnomedCodes(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };

  componentDidMount() {
    this._onRequestList();
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    this.setState({[key]: value ? value : "", errors: errors}, () => {
      if (!isEmpty(this.state.snomedCodeId.toString())) {
        this.setState({snomedCodeText: ""});
      }
    });
  }

  _handleEdit(record) {
    let {
      history_problem: {id, description},
      is_active,
      start_date,
      end_date,
    } = record;
    let selectedStartMonth = moment(start_date).format("MMMM");
    let selectedStartYear = moment(start_date).format("YYYY");
    let selectedEndMonth = "";
    let selectedEndYear = "";

    if (end_date) {
      selectedEndMonth = moment(end_date).format("MMMM");
      selectedEndYear = moment(end_date).format("YYYY");
    }

    this.setState({
      startDateMonth: selectedStartMonth,
      startDateYear: selectedStartYear,
      endDateYear: selectedEndYear,
      endDateMonth: selectedEndMonth,
      currentlyActive: is_active,
      snomedCodeId: id,
      editId: record.id,
      selectedHistoryProblem: {
        id: id,
        description: description,
      },
      visible: true,
    });
  }
  _showModal() {
    this.setState({visible: true});
  }
  _hideModal() {
    this.setState({
      visible: false,
      isLoading: false,
      snomedSearch: false,
      snomedCodeId: "",
      snomedCodeText: "",
      snomedCodes: [],
      currentlyActive: false,
      errors: null,
    });
  }

  _handleTextChange(e) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
      delete errors["snomedCodeId"];
    }
    if (!isEmpty(this.state.snomedCodeText)) {
      this.setState({snomedCodeId: ""});
    }
    this.setState({[e.target.name]: e.target.value, errors: errors});
  }
  _handleCheckBox(e) {
    let checked = e.target.checked;
    if (checked) {
      this.setState({endDateMonth: "", endDateYear: ""});
    }
    this.setState({currentlyActive: checked});
  }
  handleSnomed = (value) => {
    this.setState({snomed_code_id: value});
  };

  async _handleSubmit(e) {
    e.preventDefault();
    let {snomedCodeId, currentlyActive, snomedCodeText} = this.state;
    let toBeValidateObj = {
      snomedCodeId: snomedCodeId.toString(),
      snomedCodeText: snomedCodeText.toString(),
      currentlyActive: currentlyActive,
    };

    const errors = ValidateInput(toBeValidateObj);
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let {patientId} = this.props;

      let payload = {
        is_active: toBeValidateObj.currentlyActive,
        snomed_code_id: !isEmpty(toBeValidateObj.snomedCodeId)
          ? toBeValidateObj.snomedCodeId
          : toBeValidateObj.snomedCodeText,
        user_id: patientId,
      };
      try {
        await this.setState({changing: true});
        let response = await addAllergiesAPI({user_allergy: {...payload}});
        await this._clearData();
        await successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
        await this._onRequestList();
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({changing: false});
        errorToast({content: message});
      }
    }
  }

  _handleHistoryModal(record) {
    this.setState({visibleHistory: true, historyLoading: true}, async () => {
      try {
        let response = await getUserllergiesAPI({id: record.id});
        let histories = verifyObject(response, "data.data.history.history", []);
        this.setState({histories: histories, historyLoading: false});
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({historyLoading: false});
        errorToast({content: message});
      }
    });
  }

  _hideHistoryModal() {
    this.setState({visibleHistory: false});
  }

  _renderActionMenus(record) {
    return (
      <Menu className="width-115px">
        <Menu.Item onClick={() => this._handleHistoryModal(record)}>
          History
        </Menu.Item>
        <Menu.Item onClick={() => this._handleDelete(record)}>Delete</Menu.Item>
      </Menu>
    );
  }

  _renderActionMenus2(record) {
    return (
      <Menu className="width-115px">
        <Menu.Item onClick={() => this._handleHistoryModal(record)}>
          History
        </Menu.Item>
        {/* <Menu.Item onClick={() => this._handleDelete(record)} >
                    Delete
                </Menu.Item> */}
      </Menu>
    );
  }

  _handleDelete(record) {
    confirm({
      title: "Are you sure you want to remove?",
      icon: <ExclamationCircleOutlined />,

      onOk: async () => {
        await this._submitDelete(record);
      },
      okText: "Confirm",
      cancelText: "Close",
      onCancel() {},
    });
  }

  async _submitDelete(record) {
    try {
      let response = await deleteAllergiesAPI({id: record.id});
      await this._clearData();
      await successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      await this._onRequestList();
    } catch (error) {
      const {message} = getErrorObject(error);
      errorToast({content: message});
    }
  }

  async _onRequestList() {
    let {patientId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getAllergiesAPI({user_id: patientId});
      let allergies = verifyObject(response, "data.data", []);
      await this.setState({isLoading: false, allergies: allergies});
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      nhsMedicationsActions: {onPageChange},
      nhsMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, user_id: patientId, search: search});
    }
  }

  _handleCancel() {
    this.setState({visible: false, message: "", adding: false});
  }
  _clearData() {
    this.setState({
      snomedCodeText: "",
      snomedCodeId: "",
      currentlyActive: false,
      changing: false,
      visible: false,
    });
  }

  render() {
    let {
      allergies,
      isLoading,
      historyLoading,
      editId,
      visible,
      visibleHistory,
      errors,
      snomedCodeId,
      histories,
      snomedCodeText,
      snomedCodes,
      searching,
      changing,
      currentlyActive,
      isLoadmore,
    } = this.state;
    let activeAllergies1 = verifyObject(allergies, ["true"], []);
    let pastAllergies1 = verifyObject(allergies, ["false"], []);
    let activeAllergies = [];
    activeAllergies1.map((d) => activeAllergies.push({...d, key: d.id}));

    let pastAllergies = [];
    pastAllergies1.map((d) => pastAllergies.push({...d, key: d.id}));
    const columns = [
      {
        title: "",
        key: "allergy",
        dataIndex: "allergy",
        className: "width-50 text-black",
        render: (allergy, item) => (
          <Fragment>
            {verifyObject(allergy, "name", null) !== null ? allergy.name : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "latest_reported_on",
        dataIndex: "latest_reported_on",
        align: "right",
        className: "width-45",
        render: (latest_reported_on, item) => (
          <Fragment>
            <span className="text-normal">by</span>{" "}
            {verifyObject(item, "latest_reported_by", null) !== null
              ? ` ${item.latest_reported_by} on, ${moment(
                  latest_reported_on
                ).format(DATE_FORMAT)}`
              : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "action",
        align: "right",
        className: "width-5",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              trigger={["click"]}
              overlay={() => this._renderActionMenus(record)}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <img alt="" src={three_dot} className="opacity-05"></img>
              </a>
            </Dropdown>
          </Space>
        ),
      },
    ];
    const columns1 = [
      {
        title: "",
        key: "allergy",
        dataIndex: "allergy",
        className: "width-50 text-black",
        render: (allergy, item) => (
          <Fragment>
            {verifyObject(allergy, "name", null) !== null ? allergy.name : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "latest_reported_on",
        dataIndex: "latest_reported_on",
        align: "right",
        className: "width-45",
        render: (latest_reported_on, item) => (
          <Fragment>
            <span className="text-normal">by</span>{" "}
            {verifyObject(item, "latest_reported_by", null) !== null
              ? ` ${item.latest_reported_by} on, ${moment(
                  latest_reported_on
                ).format(DATE_FORMAT)}`
              : "-"}
          </Fragment>
        ),
      },
      {
        title: "",
        key: "action",
        align: "right",
        className: "width-5",
        render: (text, record) => (
          <Space size="middle">
            <Dropdown
              trigger={["click"]}
              overlay={() => this._renderActionMenus2(record)}
            >
              <a
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
              >
                <img alt="" src={three_dot} className="opacity-05"></img>
              </a>
            </Dropdown>
          </Space>
        ),
      },
    ];
    return (
      <Fragment>
        <div className="padding-55-t push-20-b allergies_wrapper">
          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}

          <div className="form-row align-items-center">
            <div className="col-md-6">
              {!isLoading && (
                <h6 className="font-14 weight-500 text-light-black m-0">
                  Active
                </h6>
              )}
            </div>
            {/* {!isLoading && (
              <div className="col-md-6 text-right">
                <div className="relative searchbar_dropdown medical_Search">
                  <Input allowClear={true} size="large" placeholder="Search" prefix={<SearchOutlined />}/>
                  
                </div>
              </div>
            )} */}
          </div>

          <div className="form-row margin-bottom-10 push-10-t">
            <div className="col-md-12 text-right">
              <a
                onClick={this._showModal}
                className="font-13 weight-500 text-blue"
              >
                <PlusSquareOutlined
                  style={{
                    cursor: "pointer",
                    color: "#1445b7",
                    fontSize: "15px",
                  }}
                />{" "}
                Add{" "}
              </a>
              {/* <img
                  alt=""
                  src={plus_blue}
                  width="14"
                  height="14"
                  onClick={this._showModal}
                  style={{ cursor: "pointer" }}
                ></img> */}
            </div>
          </div>

          {!isLoading &&
            activeAllergies &&
            isArray(activeAllergies) &&
            activeAllergies.length > 0 && (
              <div className="prescription-array">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={activeAllergies}
                  className="font-12 no_table_header border-spacing-5"
                />
              </div>
            )}
          {!isLoading &&
            activeAllergies &&
            isArray(activeAllergies) &&
            activeAllergies.length === 0 && (
              <NoFoundMessage message="No active allergies  found" />
            )}

          <div className="form-row align-items-center push-20-t">
            <div className="col-md-12">
              {!isLoading && (
                <h6 className="font-14 weight-500 text-light-black">Past</h6>
              )}
            </div>
          </div>
          {!isLoading &&
            pastAllergies &&
            isArray(pastAllergies) &&
            pastAllergies.length > 0 && (
              <div className="prescription-array">
                <Table
                  pagination={false}
                  columns={columns1}
                  dataSource={pastAllergies}
                  className="font-12 no_table_header border-spacing-5"
                />
              </div>
            )}
          {!isLoading &&
            pastAllergies &&
            isArray(pastAllergies) &&
            pastAllergies.length === 0 && (
              <NoFoundMessage message="No past allergies  found" />
            )}
        </div>
        <ModalPopUp
          title={"Allergy History"}
          handleCancel={this._hideHistoryModal}
          visible={visibleHistory}
          footer={false}
          className="width_850px"
          maskClosable={false}
        >
          {historyLoading && <CommonLoader />}
          {!historyLoading && (
            <Timeline>
              {histories.map((d) => {
                return (
                  <Timeline.Item key={uniqueId("ALLERGY_")} color="gray">
                    <div>
                      <p className="font-10 weight-400 text-left dis-block push-5-b text-capitalize text-dark-gray">
                        Created on {moment(d.reported_on).format(DATE_FORMAT)}
                      </p>
                      <div className="border-box col-md-12 input-bg-f9f9f9">
                        <div className="form-row">
                          <div className="col-md-6">
                            <p className="font-10 weight-400 text-dark-gray margin-0">
                              {d.allergy_name}
                            </p>
                          </div>
                          <div className="col-md-6 text-right">
                            <p className="font-10 weight-400 text-dark-gray margin-0">
                              {" "}
                              Reported By {d.reported_by} on{" "}
                              {moment(d.reported_on).format(DATE_FORMAT)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Timeline.Item>
                );
              })}
            </Timeline>
          )}
        </ModalPopUp>
        <ModalPopUp
          title={editId ? "Update Allergy" : "Add Allergy"}
          handleCancel={this._hideModal}
          visible={visible}
          footer={true}
          closable={false}
          handleOk={this._handleSubmit}
          okText={editId ? "Update" : "Save"}
          loading={changing}
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
        >
          <form>
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Allergie Name</label>
                  <Select
                    className={`form-control placeholder-10 ${
                      errors && errors.snomedCodeId && "danger-border"
                    } margin-0`}
                    name="snomedCodeId"
                    autoFocus
                    allowClear={searching ? false : true}
                    onPopupScroll={this.handleScroll}
                    value={snomedCodeId}
                    showSearch={true}
                    onChange={(value) =>
                      this._handleDropDownChange(value, "snomedCodeId")
                    }
                    onSearch={(value) => {
                      let searchValue = value;
                      this.searchSnomedCodeUpdate(searchValue);
                    }}
                    suffixIcon={
                      !isLoadmore && searching && <Spin size="small" />
                    }
                    notFoundContent={
                      !searching &&
                      snomedCodes.length === 0 && (
                        <span>No codes available</span>
                      )
                    }
                    filterOption={false}
                    dropdownRender={(menus) => {
                      return (
                        <div ref={this.scrollDiv}>
                          {menus}
                          {isLoadmore && searching && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: 10,
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  >
                    {snomedCodes.map((k) => {
                      return (
                        <Option key={k.id} value={k.id}>
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.snomedSearch]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(k, "full_code", null) !== null
                                ? `${k.full_code}`
                                : `-`
                            }
                          />
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">
                    Feel free to freetext your Clinical Impressions
                  </label>
                  <input
                    className={`form-control placeholder-10 ${
                      errors && errors.snomedCodeId && "danger-border"
                    } margin-0 placeholder-10`}
                    name="snomedCodeText"
                    value={snomedCodeText}
                    onChange={this._handleTextChange}
                    placeholder="Add custom"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.snomedCodeId}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="form-row">
                <div className="col-md-6">
                  <SingleCheckBox
                    checked={currentlyActive}
                    onChange={this._handleCheckBox}
                    label="Active Currently"
                  />
                </div>
              </div>
            </div>
          </form>
        </ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    nhsMedications: verifyObject(state, "nhsMedications", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    nhsMedicationsActions: bindActionCreators(nhsMedicationsActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllergiesContainer);

export default ConnectedComponent;

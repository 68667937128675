import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {actions as safeguardAlertActions} from "reducers/safeguardAlert";
import SafeguardAlertModal from "containers/alerts/SafeguardAlertModal";
import {verifyObject} from "../../utilities/utils";

class SafeguardAlertContainer extends Component {
  componentDidMount() {}
  componentWillUnmount() {
    //this.props.safeguardAlertActions.resetState();
  }
  onSubmit = (values) => {
    return this.props.safeguardAlertActions.onCreateSafeguard({
      safeguard_alert: {
        ...values,
        appointment_id: this.props.appointmentId,
        patient_id: this.props.patientId,
      },
    });
  };
  onClose = () => {
    this.props.safeguardAlertActions.onCloseModal();
  };
  render() {
    const {safeguardAlert} = this.props;
    return (
      <Fragment>
        {safeguardAlert.modal.isOpen ? (
          <SafeguardAlertModal
            onSubmit={this.onSubmit}
            safeguardAlert={safeguardAlert}
            onClose={this.onClose}
          />
        ) : null}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    safeguardAlert: state.safeguardAlert,
    patientId: verifyObject(
      state.localStore,
      "appointment_obj.patient_id",
      null
    ),
    appointmentId: verifyObject(state.localStore, "appointment_obj.id", null),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    safeguardAlertActions: bindActionCreators(safeguardAlertActions, dispatch),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SafeguardAlertContainer);

import React from "react";

import ProfileContainer from "../../containers/Profile/ProfileContainer"
import Layout from "../../Layouts/PageLayout"

class ProfilePage extends React.Component {
    render() {
        return (
            <Layout title="My Profile">
                {/* <div className="rightmainsection padding-70-t">
                    <div className="container-fluid right_wrapper p-0">
                         <div className="row">
                            <div className="container-fluid selection_container "> */}
                                <ProfileContainer />
                            {/* </div>
                        </div> 
                     </div>
                </div> */}
            </Layout>
        );
    }
}

export default ProfilePage; 
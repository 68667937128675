import React, {PureComponent, Fragment} from "react";
// import {bindActionCreators} from "redux";
import {connect} from "react-redux";
// import {actions as nhsAcuteMedicationsActions} from "../../../reducers/nhsAcuteMedications";
import {
  verifyObject,
  getErrorObject,
  errorToast,
  successToast,
} from "../../../utilities/utils";
import {isArray, debounce, isEmpty, uniqueId} from "lodash";
import moment from "moment";
import CommonLoader from "../../../components/common/CommonLoader";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import {Table, Select, Spin} from "antd";
import {DATE_FORMAT} from "../../../constants/common";
import {
  getAcuteMedicationsApi,
  addAcuteMedicationsApi,
} from "../../../services/acuteMedications";
import ModalPopUp from "../../../components/common/ModalPopUp";
import Highlighter from "react-highlight-words";
import {getDrugsApi} from "../../../services/drugs";

// import search_blue from "assets/images/common/search-blue.svg";
// import { Input } from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";

const {Option} = Select;
class NHSAcuteContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      message: "",
      accuteMedications: null,
      isLoading: false,
      drugsArray: [],
      drugId: null,
      search: "",
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleSearch",
      "_handleCancel",
      "_hideModal",
      "_handleDropDownChange",
      "_handleSubmit",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSearch, 1000);
    this.searchDrugUpdate = debounce(this._handleDrugSearch, 1000);
  }

  async _handleSearch(value) {
    let {patientId, nhsAcuteMedicationsActions} = this.props;
    let {onSearch} = nhsAcuteMedicationsActions;
    if (typeof onSearch === "function") {
      this.setState({search: value});
      onSearch({search: value, user_id: patientId, page: 1});
    }
  }

  async _handleDrugSearch(value) {
    if (!isEmpty(value)) {
      if (!isEmpty(value)) {
        this.setState(
          {
            drugsArray: [],
            page: 1,
            searching: true,
            isLoadmore: false,
            search: value,
          },
          async () => {
            this._getDrugs(false);
          }
        );
      } else {
        await this.setState({snomedCodes: []});
      }
      // try {
      //     this.setState({ searching: true, search: value }, async () => {
      //         let response = await getDrugsApi({ page: 1, term: value });
      //         let drugsArray = verifyObject(response, "data.data", [])
      //         this.setState({ drugsArray: drugsArray, searching: false })
      //     });

      // } catch (error) {
      //     const { message } = getErrorObject(error);
      //     await this.setState({ searching: false });
      //     errorToast({ content: message });
      // }
    } else {
      await this.setState({searching: false, drugsArray: []});
    }
  }
  _getDrugs = async (isLoadMore) => {
    try {
      let response = await getDrugsApi({
        page: this.state.page,
        term: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let drugsArray = verifyObject(response, "data.data", []);
      this.setState({
        drugsArray: isLoadMore
          ? [...this.state.drugsArray, ...drugsArray]
          : drugsArray,
        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  };

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getDrugs(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;
    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };
  componentDidMount() {
    this._onRequestList();
  }

  // _onRequestList() {
  //   let {patientId, nhsAcuteMedicationsActions} = this.props;
  //   let {onRequest} = nhsAcuteMedicationsActions;
  //   if (typeof onRequest === "function") {
  //     onRequest({user_id: patientId});
  //   }
  // }

  async _onRequestList() {
    let {patientId} = this.props;
    try {
      await this.setState({isLoading: true});
      let response = await getAcuteMedicationsApi({user_id: patientId});
      let accuteMedications1 = verifyObject(response, "data.data.current", []);
      let accuteMedications = [];
      accuteMedications1.map((d) =>
        accuteMedications.push({...d, key: uniqueId("ACCUTE_MED_")})
      );
      await this.setState({
        isLoading: false,
        accuteMedications: accuteMedications,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({isLoading: false});
      errorToast({content: message});
    }
  }
  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }

    this.setState({[key]: value ? value : "", errors: errors}, () => {});
  }
  _onPageChange(data) {
    let {
      patientId,
      nhsAcuteMedicationsActions: {onPageChange},
      nhsAcuteMedications: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, user_id: patientId, search: search});
    }
  }

  _handleCancel() {
    this.setState({
      visible: false,
      message: "",
      adding: false,
      drugsArray: [],
    });
  }

  _hideModal() {
    this.setState({visible: false, drugsArray: [], drugId: null});
  }

  async _handleSubmit(e) {
    e.preventDefault();
    let {drugId} = this.state;
    let {patientId} = this.props;
    let payload = {
      drug_id: drugId,
      user_id: patientId,
    };
    try {
      await this.setState({changing: true});
      let response = await addAcuteMedicationsApi({
        medication: {...payload},
      });
      await this._clearData();
      await successToast({
        content: verifyObject(response, "data.message", "Success"),
      });
      await this._onRequestList();
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({changing: false});
      errorToast({content: message});
    }
  }

  _clearData = () => {
    this.setState({
      visible: false,
      message: "",
      accuteMedications: null,
      isLoading: false,
      drugsArray: [],
      drugId: null,
      changing: false,
    });
  };
  render() {
    let {
      accuteMedications,
      changing,
      isLoading,
      searching,
      drugId,
      drugsArray,
      visible,
      isLoadmore,
    } = this.state;
    // let {data, isLoading: acuteLoading} = this.props.nhsAcuteMedications;
    // let acuteData = data && data.current ? data.current : [];
    // console.log("data for acute==>", data.current, data);
    const columns = [
      {
        title: "",
        key: "drug",
        dataIndex: "drug",
        className: "width-70 text-black",
        render: (drug, item) => <Fragment>{drug ? drug : "-"}</Fragment>,
      },
      {
        title: "",
        key: "date",
        dataIndex: "date",
        className: "width-15 text-dark-gray",
        render: (date, item) => (
          <Fragment>{date ? moment(date).format(DATE_FORMAT) : "-"}</Fragment>
        ),
      },
      {
        title: "",
        key: "quantity",
        dataIndex: "quantity",
        className: "width-15",
        render: (quantity, item) => (
          <Fragment>
            {quantity ? (
              <span className="custom-bluelabel text-capitalize">
                {quantity}{" "}
                {verifyObject(item, "formulation")
                  ? verifyObject(item, "formulation")
                  : "Tablets"}
              </span>
            ) : (
              "-"
            )}
          </Fragment>
        ),
      },
    ];

    return (
      <Fragment>
        <div className="push-20-b">
          <div className="form-row margin-bottom-10 push-10-t">
            <div className="col-md-12 text-right">
              <a
                onClick={() => this.setState({visible: true})}
                className="font-13 weight-500 text-blue"
              >
                <PlusSquareOutlined
                  style={{
                    cursor: "pointer",
                    color: "#1445b7",
                    fontSize: "15px",
                  }}
                  className="mr-1"
                />
                Add
              </a>
              {/* <img
                alt=""
                src={plus_blue}
                width="14"
                height="14"
                onClick={() => this.setState({ visible: true })}
                style={{ cursor: "pointer" }}
              ></img> */}
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}
          {!isLoading &&
            accuteMedications &&
            isArray(accuteMedications) &&
            accuteMedications.length > 0 && (
              <div className="prescription-array">
                <Table
                  pagination={false}
                  columns={columns}
                  dataSource={accuteMedications}
                  className="font-12 no_table_header border-spacing-5"
                />
              </div>
            )}
          {!isLoading &&
            accuteMedications &&
            isArray(accuteMedications) &&
            accuteMedications.length === 0 && (
              <NoFoundMessage message="No acute medications found" />
            )}
        </div>
        <ModalPopUp
          title={"Add Current Acute Medication"}
          handleCancel={this._hideModal}
          visible={visible}
          footer={true}
          handleOk={this._handleSubmit}
          okText={"Save"}
          loading={changing}
          closable={false}
          okButtonProps={{disabled: drugId === null}}
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
        >
          <form>
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Drug Name</label>
                  <Select
                    autoFocus
                    name="snomedCodeId"
                    className="form-control margin-0"
                    allowClear={searching ? false : true}
                    value={drugId}
                    showSearch={true}
                    onPopupScroll={this.handleScroll}
                    onChange={(value) =>
                      this._handleDropDownChange(value, "drugId")
                    }
                    onSearch={(value) => {
                      let searchValue = value;
                      this.searchDrugUpdate(searchValue);
                    }}
                    suffixIcon={
                      !isLoadmore && searching && <Spin size="small" />
                    }
                    notFoundContent={
                      !searching &&
                      drugsArray.length === 0 && <span>No drugs available</span>
                    }
                    filterOption={false}
                    dropdownRender={(menus) => {
                      return (
                        <div ref={this.scrollDiv}>
                          {menus}
                          {isLoadmore && searching && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: 10,
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  >
                    {drugsArray.map((k) => {
                      return (
                        <Option key={k.id} value={k.id}>
                          <Highlighter
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(k, "supplier_name", null) !== null
                                ? `${k.supplier_name}`
                                : `-`
                            }
                          />
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>
          </form>
        </ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  console.log("state for acute==>", state);
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    // nhsAcuteMedications: verifyObject(state, "nhsAcuteMedications", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // nhsAcuteMedicationsActions: bindActionCreators(
    //   nhsAcuteMedicationsActions,
    //   dispatch
    // ),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(NHSAcuteContainer);

export default ConnectedComponent;

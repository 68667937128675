import React, {PureComponent, Fragment} from "react";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {actions as familyRecordsActions} from "../../../reducers/familyRecords";
import {
  verifyObject,
  // modifiedDataObjectToArray,
  successToast,
  getErrorObject,
  errorToast,
  modifiedDataObjectToArrayByIDs,
} from "../../../utilities/utils";
import {isArray, debounce, isEmpty, uniqueId} from "lodash";
import {Pagination} from "../../../components/common";
import NoFoundMessage from "../../../components/common/NoFoundMessage";
import ModalPopUp from "../../../components/common/ModalPopUp";
import {addfamilyRecordsApi} from "../../../services/familyRecords";
import {Select, Spin} from "antd";
import Highlighter from "react-highlight-words";
import CommonLoader from "../../../components/common/CommonLoader";
import {searchSnomedCode} from "../../../services/snomedCodes";
import {ValidateInput} from "./FamilyRecordsValidations";
// import plus_blue from "assets/images/common/add-ic.png";
// import search_blue from "assets/images/common/search-blue.svg";
// import { Input } from "antd";
import {PlusSquareOutlined} from "@ant-design/icons";

const {Option} = Select;

class FamilyRecordsContainer extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      message: "",
      snomedCodes: [],
      snomedCodeId: "",
      snomedCodeText: "",
      search: "",
      errors: {},
      changing: false,
    };
    [
      "_onRequestList",
      "_onPageChange",
      "_handleSnomedCodeSearch",
      "_handleCancel",
      "_showModal",
      "_hideModal",
      "_handleDropDownChange",
      "_handleTextChange",
      "_clearData",
      "_handleSubmit",
    ].map((fn) => (this[fn] = this[fn].bind(this)));
    this.searchUpdate = debounce(this._handleSnomedCodeSearch, 1000);
  }

  componentDidMount() {
    this._onRequestList();
  }

  _onRequestList() {
    let {patientId, familyRecordsActions} = this.props;
    let {onRequest} = familyRecordsActions;
    if (typeof onRequest === "function") {
      onRequest({patient_id: patientId, page: 1});
    }
  }
  _onPageChange(data) {
    let {
      patientId,
      familyRecordsActions: {onPageChange},
      familyRecords: {search},
    } = this.props;
    const {page} = data;
    if (typeof onPageChange === "function") {
      onPageChange({page: page, patient_id: patientId, search: search});
    }
  }

  _handleCancel() {
    this.setState({visible: false, message: "", adding: false});
  }

  // async _handleSnomedCodeSearch(value) {
  //     if (!isEmpty(value)) {
  //         try {
  //             this.setState({ searching: true, search: value }, async () => {
  //                 let response = await searchSnomedCode({ page: 1, search: value });
  //                 let snomedCodes = verifyObject(response, "data.data", [])
  //                 this.setState({ snomedCodes: snomedCodes, searching: false })
  //             });
  //         } catch (error) {
  //             const { message } = getErrorObject(error);
  //             await this.setState({ searching: false });
  //             errorToast({ content: message });
  //         }
  //     } else {
  //     }
  // }

  async _handleSnomedCodeSearch(value) {
    if (!isEmpty(value)) {
      this.setState(
        {
          snomedCodes: [],
          snomedCodeId: "",
          page: 1,
          searching: true,
          isLoadmore: false,
          search: value,
        },
        async () => {
          this._getSnomedCodes(false);
        }
      );
    } else {
      await this.setState({snomedCodes: []});
    }
  }

  async _getSnomedCodes(isLoadMore) {
    try {
      let response = await searchSnomedCode({
        page: this.state.page,
        search: this.state.search,
      });
      let pagination =
        verifyObject(response, "headers['x-pagination']", null) !== null
          ? JSON.parse(response.headers["x-pagination"])
          : null;
      let snomedCodes = verifyObject(response, "data.data", []);
      this.setState({
        snomedCodes: isLoadMore
          ? [...this.state.snomedCodes, ...snomedCodes]
          : snomedCodes,

        searching: false,
        pagination: pagination,
      });
    } catch (error) {
      const {message} = getErrorObject(error);
      await this.setState({searching: false});
      errorToast({content: message});
    }
  }

  fetchMoreData = async () => {
    await this.setState(
      (prevState) => {
        return {
          page: prevState.page + 1,
          readMarked: false,
          searching: true,
          isLoadmore: true,
        };
      },
      async () => {
        await this._getSnomedCodes(true);
      }
    );
  };

  handleScroll = (e) => {
    let element = e.target;

    let scrollHeight = element.scrollHeight;
    let scrollTop = element.scrollTop;
    let clientHeight = element.clientHeight;

    let {
      page,
      pagination: {total_pages},
    } = this.state;

    if (scrollHeight - scrollTop === clientHeight && page < total_pages) {
      this.fetchMoreData();
    }
  };

  _showModal() {
    this.setState({visible: true});
  }
  _hideModal() {
    this.setState({visible: false}, () => {
      this._clearData();
    });
  }

  _handleDropDownChange(value, key) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[key];
      delete errors["endDate"];
    }
    this.setState({[key]: value ? value : "", errors: errors}, () => {
      if (!isEmpty(this.state.snomedCodeId.toString())) {
        this.setState({snomedCodeText: ""});
      }
    });
  }

  _handleTextChange(e) {
    let errors = null;
    if (this.state.errors) {
      errors = Object.assign("", this.state.errors);
      delete errors[e.target.name];
      delete errors["snomedCodeId"];
    }
    if (!isEmpty(this.state.snomedCodeText)) {
      this.setState({snomedCodeId: "", snomedCodes: []});
    }
    this.setState({[e.target.name]: e.target.value, errors: errors});
  }
  async _handleSubmit(e) {
    e.preventDefault();
    let {snomedCodeId, snomedCodeText} = this.state;
    let toBeValidateObj = {
      snomedCodeId: snomedCodeId.toString(),
      snomedCodeText: snomedCodeText.toString(),
    };

    const errors = ValidateInput(toBeValidateObj);
    if (!errors.isValid) {
      this.setState({errors: errors.errors, adding: false});
    } else {
      let {patientId} = this.props;
      let payload = {};

      payload = {
        snomed_code_id: !isEmpty(toBeValidateObj.snomedCodeId)
          ? toBeValidateObj.snomedCodeId
          : toBeValidateObj.snomedCodeText,
        user_id: patientId,
      };
      try {
        await this.setState({changing: true});
        let response = await addfamilyRecordsApi({...payload});
        await this._onRequestList();
        await this._clearData();
        await successToast({
          content: verifyObject(response, "data.message", "Success"),
        });
      } catch (error) {
        const {message} = getErrorObject(error);
        await this.setState({changing: false});
        errorToast({content: message});
      }
    }
  }
  _clearData() {
    this.setState({
      snomedCodes: [],
      currentlyActive: false,
      snomedCodeId: "",
      snomedCodeText: "",
      startDateYear: "",
      endDateYear: "",
      startDateMonth: "",
      endDateMonth: "",
      search: "",
      errors: {},
      selectedHistoryProblem: null,
      editId: null,
      visible: false,
      changing: false,
      description: "",
      taken_on: null,
    });
  }
  render() {
    let {familyRecords} = this.props;
    let {
      // data,
      isLoading,
      pagination: {total},
    } = familyRecords;
    let {
      visible,
      snomedCodeText,
      changing,
      searching,
      snomedCodes,
      isLoadmore,
      errors,
    } = this.state;
    let familyRecordsArray = modifiedDataObjectToArrayByIDs(familyRecords);
    // console.log("familyRecordsArray", familyRecordsArray);

    return (
      <Fragment>
        <div className="padding-55-t push-20-b familyrecords_wrapper">
          <div className="form-row align-items-center">
            <div className="col-md-6">
              <h6 className="font-14 weight-500 text-light-black m-0">
                Family Records {isLoading ? "" : `(${total})`}{" "}
              </h6>
            </div>
          </div>

          <div className="form-row margin-bottom-10 push-10-t">
            <div className="col-md-12 text-right">
              <a
                onClick={() =>
                  this.setState({
                    visible: true,
                  })
                }
                className="font-13 weight-500 text-blue"
              >
                <PlusSquareOutlined
                  style={{
                    cursor: "pointer",
                    color: "#1445b7",
                    fontSize: "15px",
                  }}
                />{" "}
                Add{" "}
              </a>
            </div>
          </div>

          {isLoading && (
            <div>
              <CommonLoader />
            </div>
          )}

          <div className=" family_record_content">
            {!isLoading &&
              familyRecordsArray &&
              isArray(familyRecordsArray) &&
              familyRecordsArray.map((k, index) => {
                return (
                  <table
                    key={uniqueId("FAMILY_RECORDS_")}
                    className="w-100 border custom_table push-5-b bg-f9f9f9"
                  >
                    <tbody>
                      <tr>
                        <td>
                          <p className="padding-3-t padding-3-b padding-left-10 padding-right-10 font-12 weight-400 margin-0 text-dark-gray">
                            {verifyObject(k, "disorder", null) !== null
                              ? k.disorder
                              : "--"}
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                );
              })}
            {!isLoading &&
              familyRecordsArray &&
              isArray(familyRecordsArray) &&
              familyRecordsArray.length === 0 && (
                <NoFoundMessage message="No family records found." />
              )}
          </div>

          <div className="col-md-12">
            {!isLoading && (
              <Pagination
                data={familyRecords}
                onPageChange={this._onPageChange}
              />
            )}
          </div>
        </div>
        <ModalPopUp
          title={"Add Family History"}
          handleCancel={this._hideModal}
          visible={visible}
          closable={false}
          footer={true}
          handleOk={this._handleSubmit}
          okText={"Save"}
          loading={changing}
          cancelBtnclassName="custom_cancel_btn"
          maskClosable={false}
        >
          <form>
            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">Diseases Name</label>
                  <Select
                    className={`form-control ${
                      errors && errors.snomedCodeId && "danger-border"
                    } margin-0`}
                    autoFocus
                    name="snomedCodeId"
                    onPopupScroll={this.handleScroll}
                    allowClear={searching ? false : true}
                    value={this.state.snomedCodeId}
                    showSearch={true}
                    onChange={(value) =>
                      this._handleDropDownChange(value, "snomedCodeId")
                    }
                    onSearch={(value) => {
                      let searchValue = value;
                      this.searchUpdate(searchValue);
                    }}
                    suffixIcon={
                      !isLoadmore && searching && <Spin size="small" />
                    }
                    notFoundContent={
                      !searching &&
                      snomedCodes.length === 0 && (
                        <span>No codes available</span>
                      )
                    }
                    filterOption={false}
                    dropdownRender={(menus) => {
                      return (
                        <div ref={this.scrollDiv}>
                          {menus}
                          {isLoadmore && searching && (
                            <div
                              style={{
                                textAlign: "center",
                                padding: 10,
                              }}
                            >
                              <Spin size="small" />
                            </div>
                          )}
                        </div>
                      );
                    }}
                  >
                    {snomedCodes.map((k) => {
                      return (
                        <Option value={k.id}>
                          <Highlighter
                            key={k.id}
                            highlightClassName="search-highlighter"
                            searchWords={[this.state.search]}
                            autoEscape={true}
                            textToHighlight={
                              verifyObject(k, "full_code", null) !== null
                                ? `${k.full_code}`
                                : `-`
                            }
                          />
                        </Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
            </div>

            <div className="form-group">
              <div className="row">
                <div className="col-md-12">
                  <label htmlFor="sel1">
                    Feel free to freetext your Clinical Impressions
                  </label>
                  <input
                    className={`form-control placeholder-10 ${
                      errors && errors.snomedCodeId && "danger-border"
                    } margin-0 placeholder-10`}
                    name="snomedCodeText"
                    value={snomedCodeText}
                    onChange={this._handleTextChange}
                    placeholder="Add custom"
                  />
                  {this.state.errors && (
                    <span className="validate-danger">
                      {this.state.errors.snomedCodeId}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="form-group">
                            <div className="form-row">
                                <div className="col-md-12">
                                    <label htmlFor="sel1">Feel free to freetext your Clinical Impressions</label>
                                    <input
                                        className="form-control margin-0"
                                        name="snomedCodeText"
                                        value={snomedCodeText}
                                        onChange={this._handleTextChange}
                                    />
                                    {this.state.errors && (
                                        <span className="validate-danger">
                                            {this.state.errors.snomedCodeId}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div> */}
          </form>
        </ModalPopUp>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    patientId: verifyObject(state.localStore, "patientId", null),
    familyRecords: verifyObject(state, "familyRecords", []),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    familyRecordsActions: bindActionCreators(familyRecordsActions, dispatch),
  };
};

const ConnectedComponent = connect(
  mapStateToProps,
  mapDispatchToProps
)(FamilyRecordsContainer);

export default ConnectedComponent;

import React, {Fragment, useEffect, useState} from "react";
import moment from "moment";
import CommonLoader from "../../components/common/CommonLoader";
import NoFoundMessage from "../../components/common/NoFoundMessage";
import {Checkbox, Popover, Spin} from "antd";
import LightBoxComponent from "../../components/common/LightBoxComponent";
import ascendingsort from "assets/images/common/ascending-sort.svg";
import {isArray, isEmpty} from "lodash";
import {
  Dropdown as DropdownEmdr,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  APPT_TYPE_WALKIN,
  DATE_FORMAT,
  T24_HR_FORMAT,
} from "../../constants/common";
import {verifyObject} from "utilities/utils";

const Prevtextover = ({text}) => (
  <div className="view_popover">
    <span className="font-12 weight-400 margin-0 text-black inline-block">
      {text}
    </span>
  </div>
);
function PreviousAppointmentContainerDynamic(props) {
  const [state, setState] = useState({});
  const [chekdIds, setCheckboxIds] = useState([]);

  const toggleThreeDots = (id) => {
    setState((prevState) => {
      return {[`isShow${id}`]: !state[`isShow${id}`]};
    });
  };
  const handleEndAppointment = async (id) => {
    if (typeof props.endAppointment === "function") {
      return props.endAppointment(id);
    }
    return null;
  };
  const onCheckAll = (e) => {
    let checked = e.target.checked;
    if (checked) {
      let ids = props.ids;
      setCheckboxIds(ids);
      props.handleDeleteCheckbox(ids);
    } else {
      setCheckboxIds([]);
      props.handleDeleteCheckbox([]);
    }
  };
  useEffect(() => {
    if (
      props.toBeDeleteIds &&
      isArray(props.toBeDeleteIds) &&
      props.toBeDeleteIds.length === 0
    ) {
      setCheckboxIds([]);
    }
  }, [props.toBeDeleteIds]);

  const handleCheckBox = (e, id) => {
    if (e.target.checked) {
      console.log("ON CHANGE ", id);

      setCheckboxIds([...chekdIds, id]);
      props.handleDeleteCheckbox([...chekdIds, id]);
    } else {
      console.log("ON CHANGE DELETE", id);
      let newArray = chekdIds.filter((d) => d !== id);
      console.log("ON CHANGE NEW", newArray);

      setCheckboxIds(newArray);
      props.handleDeleteCheckbox(newArray);
    }
  };
  // const urlParams = new URLSearchParams(window.location.search);
  // const isReviewed = urlParams.get("reviewed");
  console.log("CEHCKED IDS---", chekdIds);
  return (
    <div>
      <table className="w-100 upcoming_booking_tableheader relative bg-gray border border-bottom-none-imp">
        <thead>
          <tr>
            {!props.isLoading && props.isAllowDelete && (
              <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-2 md-td-2 text-light-black">
                <Checkbox
                  checked={
                    chekdIds.length !== 0 &&
                    chekdIds.length === props?.ids?.length
                  }
                  onChange={onCheckAll}
                  className="m-0"
                ></Checkbox>
              </th>
            )}
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-15 md-td-15 text-light-black">
              Patient Name{" "}
              <img
                src={ascendingsort}
                className="push-3-l cursor-pointer sort-icon"
                alt="Ascending Sort"
                title=""
                id="1"
                onClick={() => props.sortRecordBy("patient_name", "1")}
              ></img>
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-13 md-td-10 text-light-black">
              Corporate{" "}
              <img
                src={ascendingsort}
                className="push-3-l cursor-pointer sort-icon"
                alt="Ascending Sort"
                title=""
                id="2"
                onClick={() => props.sortRecordBy("corporate_name", "2")}
              ></img>
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-7 md-td-8 text-light-black">
              Gender{" "}
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-8 md-td-8 text-light-black">
              DOB{" "}
              <img
                src={ascendingsort}
                className="push-3-l cursor-pointer sort-icon"
                alt="Ascending Sort"
                title=""
                id="4"
                onClick={() => props.sortRecordBy("date_of_birth", "4")}
              ></img>
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-10 md-td-12 text-light-black">
              Prescription code
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-13 md-td-10 text-light-black">
              Reason for Call
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-9 md-td-9 text-light-black">
              Date{" "}
              <img
                src={ascendingsort}
                className="push-3-l cursor-pointer sort-icon"
                alt="Ascending Sort"
                title=""
                id="6"
                onClick={() => props.sortRecordBy("created_at", "6")}
              ></img>
            </th>
            <th className="font-12 padding-10-t padding-10-b text-left padding-left-10 lg-td-9 md-td-8 text-light-black">
              Time{" "}
            </th>
            {props.tabKey === "finished" && (
              <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 lg-td-8 md-td-10 text-light-black">
                Call Summary
              </th>
            )}
            <th className="font-12 padding-10-t padding-10-b text-center padding-left-10 padding-right-10 lg-td-8 md-td-10 text-light-black">
              Action
            </th>
          </tr>
        </thead>
      </table>
      <div className="upcoming_appt_data">
        {props.isLoading && <CommonLoader />}
        {!props.isLoading && props.ids && props.ids.length === 0 && (
          <NoFoundMessage message="No previous appointments available" />
        )}
        {!props.isLoading &&
          props.ids.map((id) => {
            return (
              <table key={id} className="w-100 upcoming_booking_table relative">
                <tbody>
                  <tr>
                    {props.isAllowDelete && (
                      <td className="text-left padding-left-10 lg-td-2 md-td-2 overflow_td">
                        <Checkbox
                          onChange={(e) => handleCheckBox(e, id)}
                          checked={chekdIds.includes(id)}
                        />
                      </td>
                    )}
                    <td className="text-left padding-left-10 lg-td-15 md-td-15">
                      <div className="d-flex report-user align-items-center">
                        <div className="up_doctor_profile in-block vertical-middle">
                          {!isEmpty(props.data[id].patient_image) ? (
                            <img
                              src={verifyObject(
                                props.data[id],
                                "patient_image",
                                require("../../assets/images/common/doctor_profile.svg")
                                  .default
                              )}
                              alt=""
                              className="width-30px"
                            ></img>
                          ) : (
                            <img
                              src={
                                require("../../assets/images/common/doctor_profile.svg")
                                  .default
                              }
                              alt=""
                              className="width-30px"
                            ></img>
                          )}
                        </div>
                        <p className="padding-left-10 cursor-pointer font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block custom-text-width">
                          {props.data[id].booked_by}
                        </p>
                        &nbsp;
                        <span className="ant-tag ant-tag-blue margin-right-0-imp">
                          {props.data[id].appointment_type === "face_to_face"
                            ? "Walk-In"
                            : props.data[id].appointment_type === "phone_call"
                            ? "Phone"
                            : "Video"}
                        </span>
                      </div>
                    </td>
                    <td className="text-left padding-left-10 lg-td-13 md-td-10">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {verifyObject(props.data[id], "corporate_name", "-")}
                      </p>
                    </td>
                    <td className="text-left padding-left-10 lg-td-7 md-td-8">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {verifyObject(
                          props.data[id],
                          "patient_profile.gender",
                          ""
                        ) === "male" && "M"}
                        {verifyObject(
                          props.data[id],
                          "patient_profile.gender",
                          ""
                        ) === "female" && "F"}
                        {verifyObject(
                          props.data[id],
                          "patient_profile.gender",
                          ""
                        ) === "" && "-"}
                      </p>
                    </td>
                    <td className="text-left padding-left-10 lg-td-8 md-td-8">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {props.data[id].patient_profile &&
                          props.data[id].patient_profile.date_of_birth &&
                          moment(
                            props.data[id].patient_profile.date_of_birth
                          ).format(DATE_FORMAT)}
                      </p>
                    </td>
                    <td className="text-left padding-left-10 lg-td-10 md-td-12">
                      <p className="font-12 tablet-font-9 weight-400 text-black margin-0 text-capitalize in-block">
                        {verifyObject(props.data[id], "prescription_code", null)
                          ? verifyObject(
                              props.data[id],
                              "prescription_code",
                              null
                            )
                          : "-"}
                      </p>
                    </td>
                    <td className="text-left padding-left-10 lg-td-13 md-td-10 ellipsis">
                      {!isEmpty(props.data[id].reason_image) ? (
                        <img
                          src={props.data[id].reason_image}
                          onClick={() =>
                            props.setState({
                              [`visible${id}`]: true,
                            })
                          }
                          alt="]"
                          className="cursor-pointer width-30px height-30px object-fit-cover border-radius-4"
                        ></img>
                      ) : (
                        <span className="lb-p"> -</span>
                      )}
                      <LightBoxComponent
                        visible={props[`visible${id}`]}
                        image={verifyObject(props.data[id], "reason_image", "")}
                      />

                      {verifyObject(props.data[id], "reason_text", "") !==
                      "" ? (
                        <Popover
                          content={
                            <Prevtextover
                              text={
                                props.data[id] &&
                                props.data[id].reason_text &&
                                props.data[id].reason_text
                                  .split("\n")
                                  .map((text) => {
                                    return <p>{text}</p>;
                                  })
                              }
                            />
                          }
                          trigger="hover"
                          placement="bottom"
                          className="prev_imgover ellipsis-text"
                        >
                          {verifyObject(props.data[id], "reason_text", "") !==
                          "" ? (
                            props.data[id].reason_text
                          ) : (
                            <span className="lb-p">-</span>
                          )}
                        </Popover>
                      ) : (
                        <span className="lb-p">-</span>
                      )}
                    </td>
                    <td className="text-left padding-left-10 lg-td-9 md-td-9">
                      <p className="font-12 tablet-font-9 weight-400 margin-0 text-black">
                        {moment(props.data[id].start_time).format(DATE_FORMAT)}
                      </p>
                    </td>
                    {}

                    <td className="text-left padding-left-10 lg-td-9 md-td-8">
                      <p className="font-12 tablet-font-9 weight-400 margin-0 text-light-black">
                        {moment(props.data[id].start_time).format(
                          T24_HR_FORMAT
                        )}{" "}
                        -{" "}
                        {moment(props.data[id].end_time).format(T24_HR_FORMAT)}
                      </p>
                    </td>
                    {props.tabKey === "finished" && (
                      <td className="text-left padding-left-10 lg-td-9 md-td-10">
                        <div className="d-flex align-items-center justify-content-center">
                          {verifyObject(
                            props.data[id],
                            "call_summary_pdf",
                            null
                          ) !== null ? (
                            <a
                              onClick={() =>
                                window.open(
                                  verifyObject(
                                    props.data[id],
                                    "call_summary_pdf",
                                    null
                                  ),
                                  "_blank"
                                )
                              }
                              className="text-blue text-underline"
                            >
                              View
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      </td>
                    )}
                    <td className={`text-center padding-left-10 lg-td-8 md-td-10${props.tabKey === "finished" ? " padding-right-10" : ""}`}>
                      {props.data[id].status === "patient_not_arrived" && (
                        <label className="custom-label warning inline text-capitalize m-0">
                          DNA
                        </label>
                      )}
                      {props.data[id].status === "cancelled" && (
                        <label className="custom-label danger inline text-capitalize m-0">
                          Cancelled
                        </label>
                      )}
                      <div className="d-flex align-items-center">
                        {props.data[id].status !== "patient_not_arrived" &&
                          props.data[id].status !== "cancelled" &&
                          (props.tabKey === "finished" ||
                            props.tabKey === "unfinished") && (
                            <button
                              onClick={() =>
                                props._handleNavigation(props.data[id])
                              }
                              className="arrive_btn btn btn-custom btn-blue w-100 font-13-imp weight-400 text-white tablet-font-10"
                            >
                              View
                            </button>
                          )}
                        {props.data[id].status !== "patient_not_arrived" &&
                          props.data[id].status !== "cancelled" &&
                          props.tabKey === "in_review" && (
                            <button
                              onClick={() =>
                                props._handleNavigation(
                                  props.data[id],
                                  props.fromUnderReview
                                )
                              }
                              className="arrive_btn btn btn-custom btn-blue w-100 font-13-imp weight-400 text-white tablet-font-10"
                            >
                              View
                            </button>
                          )}
                        {props.data[id].status !== "patient_not_arrived" &&
                          props.data[id].status !== "cancelled" &&
                          props.tabKey === "unfinished" && (
                            <Fragment>
                              {props[`endingAppointment${id}`] ? (
                                <Spin size={"small"} />
                              ) : (
                                <DropdownEmdr
                                  isOpen={state[`isShow${id}`]}
                                  toggle={() => toggleThreeDots(id)}
                                  className="text-right in-block ml-1"
                                >
                                  <DropdownToggle
                                    tag="span"
                                    data-toggle="dropdown"
                                  >
                                    <button
                                      className="padding-0 no-background border-0 no-focus"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="8"
                                        height="20"
                                        viewBox="0 0 8 20"
                                      >
                                        <path
                                          fill="#1445b7"
                                          fill-rule="evenodd"
                                          d="M4 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4z"
                                        />
                                      </svg>
                                    </button>
                                  </DropdownToggle>
                                  <DropdownMenu
                                    right
                                    className="width-115px font-12 p-0-imp border border-radius-0-imp"
                                  >
                                    <DropdownItem
                                      onClick={() => handleEndAppointment(id)}
                                      className="font-12 margin-0 padding-6-imp weight-400"
                                    >
                                      End Appointment
                                    </DropdownItem>
                                  </DropdownMenu>
                                </DropdownEmdr>
                              )}
                            </Fragment>
                          )}
                        {props.statusArray.includes(props.data[id].status) ===
                          false &&
                          props.data[id].appointment_type !==
                            APPT_TYPE_WALKIN && (
                            <div className="mr-1">
                              <DropdownEmdr
                                isOpen={props[`isShow${id}`]}
                                toggle={() => props.toggleThreeDots(id)}
                                className="text-right in-block"
                              ></DropdownEmdr>
                            </div>
                          )}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            );
          })}
      </div>
    </div>
  );
}

export default PreviousAppointmentContainerDynamic;

import React, {Fragment, useEffect} from "react";
import LoginContainer from "containers/session/LoginContainer";
import logo from "assets/images/common/main_logo.svg";
import "assets/css/pages/login.css";
import {Link} from "react-router-dom";
import {appRoutesConst} from "app/navigation";
import {ErrorBoundary} from "app/ErrorBoundary";
const LoginPage = () => {
  useEffect(() => {
    const handleInvalidToken = (e) => {
      requestAnimationFrame(() => {
        if (e.key === "login_message") {
          window.location.reload();
          localStorage.removeItem("login_message");
        }
      });
    };
    window.addEventListener("storage", handleInvalidToken);
    return function cleanup() {
      window.removeEventListener("storage", handleInvalidToken);
    };
  });
  return (
    <Fragment>
      <Fragment>
        <div className="login-wrapper">
          <div className="container-fluid">
            <div className="login-wrapper-logo">
              <div className="row">
                <div className="col-md-4 col-lg-4">
                  <a title="healthya.co.uk">
                    <img src={logo} alt="ADD logo" className="img-responsive" />
                  </a>
                </div>
                <div className="col-md-8 col-lg-8 d-flex align-items-center justify-content-end">
                  <div className="in-block margin-right-30 ">
                    <span className="font-14 weight-400 text-blue">
                      Don't have an account?
                    </span>
                  </div>
                  <div className="in-block">
                    <Link
                      to={appRoutesConst.signup}
                      className="btn btn-custom btn-blue height-big weight-500 text-white width-220px line-1-imp"
                    >
                      Create Account
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="login-title-wrapper">
            <div className="container-fluid">
              <h1 className="login-title-h1">Sign In</h1>
            </div>
          </div>
          <ErrorBoundary>
            <div className="container-fluid">
              <div className="login-form-wrapper">
                <LoginContainer />
              </div>
            </div>
          </ErrorBoundary>
        </div>
      </Fragment>
      {/* <Footer /> */}
    </Fragment>
  );
};

export default LoginPage;

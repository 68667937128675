import React, {Component, Fragment} from "react";
import {Route} from "react-router-dom";
import {connect} from "react-redux";
import {replace} from "react-router-redux";
import {Switch} from "react-router-dom";

//common constanst
// import { patientsRoutes } from "../../constants/appRoutesConstants";

//components
import {Header} from "../../components/session";
import RedirectComponent from "../../components/RedirectComponent/RedirectComponent";

//hoc
import PrivateRoutes from "../../hoc/PrivateRoutes";

//pages
import PatientSearchPage from "../PatientSearch/PatientSearchPage";
import PatientDetailPage from "../PatientDetail/PatientDetail";
import PatientConsultationDetails from "../PatientConsultationDetails/PatientConsultationDetails";
import {appRoutesConst} from "../../app/navigation";
// import {ROLE_DOCTOR, ROLE_CLINICAL_PHARMACIST} from "../../constants/common";
import {verifyObject} from "../../utilities/utils";
import {ErrorBoundary} from "app/ErrorBoundary";
import CommonLoader from "components/common/CommonLoader";
import WalkinAppointmentPage from "../WalkinAppointments/WalkinAppointmentPage";

const components = {
  PatientSearch: PatientSearchPage,
  PatientDetail: PatientDetailPage,
  PatientConsultationDetails: PatientConsultationDetails,
  WalkinAppointmentPage: WalkinAppointmentPage,
};
class PatientsRouter extends Component {
  constructor(props) {
    super(props);
    if (!props.token) {
      props.navigateToIndex();
    }
  }
  componentDidMount() {
    if (process.env.NODE_ENV === "production") {
      // document.oncontextmenu = document.oncontextmenu = function () {
      //   alert("Copying is not allowed on this webpage");
      //   return false;
      // };
      // document.oncopy = document.oncopy = function () {
      //   alert("Copying is not allowed on this webpage");
      //   return false;
      // };
      // document.ondragstart = document.ondragstart = function (e) {
      //   e.preventDefault();
      // };
    }
  }

  render() {
    // let {user} = this.props;
    const patientsRoutes = [
      {
        path: appRoutesConst.patientSearch,
        exact: true,
        component:
          // user && (user.role === ROLE_DOCTOR || user.role === ROLE_CLINICAL_PHARMACIST)
          // ?
          "PatientSearch",
        // : "AppointmentsPage",
      },
      {
        path: appRoutesConst.patientDetail,
        exact: true,
        component: "PatientDetail",
      },
      {
        path: appRoutesConst.patientConsultationDetail,
        exact: true,
        component:
          verifyObject(this.props, "prev_appt_tab_key", null) ===
            "unfinished" &&
          verifyObject(this.props, "appointmentObj.appointment_type", null) ===
            "face_to_face"
            ? "WalkinAppointmentPage"
            : "PatientConsultationDetails",
      },
    ];
    return (
      this.props.token && (
        <Fragment>
          {this.props.switching_corporate && (
            <CommonLoader text="Switching corporate..." />
          )}

          {!this.props.switching_corporate && <Header />}
          {!this.props.switching_corporate && (
            <ErrorBoundary>
              <Switch>
                {patientsRoutes.map(({component, ...rest}, key) => {
                  const RouteComponent = components[component] || "";
                  if (
                    typeof RouteComponent !== "undefined" &&
                    RouteComponent !== ""
                  ) {
                    return (
                      <PrivateRoutes
                        key={key}
                        {...rest}
                        component={RouteComponent}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
                <Route exact path="/*" component={RedirectComponent} />
              </Switch>
            </ErrorBoundary>
          )}
        </Fragment>
      )
    );
  }
}
const mapStateToProps = (state) => {
  return {
    is_detail_disabled: state.localStore.is_detail_disabled,
    token: state.localStore.token,
    user: verifyObject(state, "localStore.user", null),
    switching_corporate: verifyObject(
      state.localStore,
      "switching_corporate",
      false
    ),
    appointmentObj: verifyObject(state.localStore, "appointment_obj", null),
    prev_appt_tab_key: verifyObject(state, "localStore.prev_appt_tab_key", ""),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    navigateToIndex: () => dispatch(replace("/")),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(PatientsRouter);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect } from "react";
import { Route } from "react-router-dom";

import { appRoutesConst } from "app/navigation";
import ForgotPasswordPage from "pages/Login/ForgotPasswordPage";
import SignupPage from "pages/Signup/SignupPage";
import ChangePasswordPage from "pages/Login/ChangePasswordPage";
import Dashboard from "../pages/Dashboard/index";
// import RedirectComponent from "../components/RedirectComponent/RedirectComponent";
// import OTPContainer from "../containers/session/OTPContainer";
import { Switch } from "react-router-dom";
import ResetPasswordWithTokenPage from "../pages/Login/ResetPasswordWithTokenPage";
import DoctorsRouter from "../pages/DoctorsRouter/DoctorsRouter";
import RedirectComponent from "../components/RedirectComponent/RedirectComponent";
import RestrictedRoutes from "../hoc/RestrictedRoutes";
import PrivateRoutes from "../hoc/PrivateRoutes";
import PatientsRouter from "../pages/PatientsRouter/PatientsRouter";
import metadata from "../metadata.json";
import { withSnackbar } from "notistack";
import { Button } from "@material-ui/core";

// import { ErrorBoundary } from "./ErrorBoundary";
import ResetPasswordOTPPageWithContainer from "pages/Login/ResetPasswordOTPPageWithContainer";
import OTPPageWithContainer from "pages/Login/OTPPageWithContainer";
import { verifyObject } from "utilities/utils";
import store from "./store";
import CallFromCSPage from "pages/CallFromCS/CallFromCS";
import CallFromPharmaSmart from "pages/CallFromPharmaSmart/CallFromPharmaSmart";
import PharmSmartLogout from "../pages/Login/PharmSmartLogout";

const AppRoutes = (props) => {
  const { localStore } = store.getState();
  console.log("localStore", localStore);

  useEffect(() => {
    const action = (key) => (
      <Fragment>
        <Button
          onClick={() => {
            window.location.reload(true);
          }}
          className="met-refresh"
        >
          Refresh
        </Button>
        <Button
          onClick={() => {
            props.closeSnackbar(key);
          }}
          className="met-refresh"
        >
          Dismiss
        </Button>
      </Fragment>
    );

    if (
      process.env.REACT_APP_ENV === "prod" &&
      process.env.NODE_ENV === "production"
    ) {
      let VERSION = `${verifyObject(
        metadata,
        "prod.prodBuildMajor",
        ""
      )}.${verifyObject(metadata, "prod.prodBuildMinor", "")}.${verifyObject(
        metadata,
        "prod.prodBuildRevision",
        ""
      )} | ${verifyObject(metadata, "prod.prodBuildTag", "")}`;

      console.log("APP CURRENT VERSION", VERSION);
      if (JSON.parse(localStorage.getItem("APP_VERSION")) === null) {
        localStorage.setItem("APP_VERSION", JSON.stringify(VERSION));
        // setVersion(VERSION);
      } else {
        let currentAppVersion = JSON.parse(localStorage.getItem("APP_VERSION"));
        if (currentAppVersion !== VERSION) {
          props.enqueueSnackbar("New app update is available !", {
            //   autoHideDuration: 3000,
            variant: "success",
            persist: true,
            action,
          });
          localStorage.setItem("APP_VERSION", JSON.stringify(VERSION));
          // setVersion(VERSION);
        }
      }
    }

    if (
      process.env.REACT_APP_ENV === "dev" &&
      process.env.NODE_ENV === "production"
    ) {
      localStorage.removeItem("APP_VERSION");

      let VERSION = `${verifyObject(
        metadata,
        "dev.devBuildMajor",
        ""
      )}.${verifyObject(metadata, "dev.devBuildMinor", "")}.${verifyObject(
        metadata,
        "dev.devBuildRevision",
        ""
      )} | ${verifyObject(metadata, "dev.devBuildTag", "")}`;
      console.log("APP CURRENT VERSION", VERSION);
      if (JSON.parse(localStorage.getItem("APP_VERSION")) === null) {
        localStorage.setItem("APP_VERSION", JSON.stringify(VERSION));
        // setVersion(VERSION);
      } else {
        let currentAppVersion = JSON.parse(localStorage.getItem("APP_VERSION"));
        if (currentAppVersion !== VERSION) {
          props.enqueueSnackbar("New app update is available !", {
            //   autoHideDuration: 3000,
            variant: "success",
            persist: true,
            action,
          });
          localStorage.setItem("APP_VERSION", JSON.stringify(VERSION));
          // setVersion(VERSION);
        }
      }
    }

    if (
      process.env.REACT_APP_ENV === "stage" &&
      process.env.NODE_ENV === "production"
    ) {
      let VERSION = `${verifyObject(
        metadata,
        "stage.stageBuildMajor",
        ""
      )}.${verifyObject(metadata, "stage.stageBuildMinor", "")}.${verifyObject(
        metadata,
        "stage.stageBuildRevision",
        ""
      )} | ${verifyObject(metadata, "stage.stageBuildTag", "")}`;

      // console.log("APP CURRENT VERSION", versionDetail, VERSION);
      if (JSON.parse(localStorage.getItem("APP_VERSION")) === null) {
        localStorage.setItem("APP_VERSION", JSON.stringify(VERSION));
        // setVersion(VERSION);
      } else {
        let currentAppVersion = JSON.parse(localStorage.getItem("APP_VERSION"));
        console.log("APP CURRENT VERSION", currentAppVersion);
        console.log("APP NEW VERSION", VERSION);

        if (currentAppVersion !== VERSION) {
          props.enqueueSnackbar("New app update is available !", {
            //   autoHideDuration: 3000,
            variant: "success",
            persist: true,
            action,
          });
          localStorage.setItem("APP_VERSION", JSON.stringify(VERSION));
          // setVersion(VERSION);
        }
      }
    }

    return () => {};
  }, []);
  return (
    <Fragment>
      {/* {isServiceWorkerInitialized && (
          <Alert text="Service Worker is initialized for the first time" type={SW_INIT} />
        )}
        {isServiceWorkerUpdated && (
          <Alert
            text="There is a new version available."
            buttonText="Update"
            type={SW_UPDATE}
            onClick={updateServiceWorker}
          />
        )} */}
      <Switch>
        <Route exact path="/" component={Dashboard} />

        <PrivateRoutes
          path={`${appRoutesConst.doctor}`}
          component={DoctorsRouter}
        />
        <PrivateRoutes
          path={`${appRoutesConst.patient}`}
          component={PatientsRouter}
        />

        <RestrictedRoutes exact path="/signin" component={Dashboard} />
        <RestrictedRoutes exact path="/signup" component={SignupPage} />
        <RestrictedRoutes
          path={`${appRoutesConst.forgotpassword}`}
          component={ForgotPasswordPage}
        />
        <RestrictedRoutes
          path={`${appRoutesConst.verfifyotp}`}
          component={OTPPageWithContainer}
        />

        <RestrictedRoutes
          path={`${appRoutesConst.pharmSmartLogout}`}
          component={PharmSmartLogout}
        />
        <RestrictedRoutes
          path={`${appRoutesConst.changepassword}`}
          component={ChangePasswordPage}
        />
        {/* 
        <RestrictedRoutes
          path={`${appRoutesConst.loginFromCS}`}
          component={CallFromCSPage}
        /> */}

        <Route
          path={`${appRoutesConst.loginFromCS}`}
          component={CallFromCSPage}
        />

        <Route
          path={`${appRoutesConst.loginFromPharmaSmart}`}
          component={CallFromPharmaSmart}
        />

        <RestrictedRoutes
          path={`${appRoutesConst.verfifyresetotp}`}
          component={ResetPasswordOTPPageWithContainer}
        />

        <Route
          path={`${appRoutesConst.resetpasswordViaMobile}`}
          component={ResetPasswordWithTokenPage}
        />
        <Route exact path="*" component={RedirectComponent} />
      </Switch>
    </Fragment>
  );
};

export default withSnackbar(AppRoutes);

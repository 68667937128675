import React from "react";

import Layout from "../../Layouts/PageLayout";
import RotaContainer from "../../containers/Rota/RotaContainer";


class RotaPage extends React.Component {
    render() {
        return (
            <Layout title="Right To Work" sideBarArray={[]} >
               
                <RotaContainer/>
                     
            </Layout>
        );
    }
}

export default RotaPage; 